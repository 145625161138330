import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useBaseContext } from 'context/base'
import { Back } from 'component/button'
import { avatarDefaultUser } from 'util/constant'

export default function AktivitasSiswaProfileInfo() {
  /* ========================================== Helpers ========================================= */
  const param = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()
  
  /* ======================================= Local States ======================================= */
  const [dataSiswa, setdataSiswa] = useState()
  const [windowWidth, setWidth] = useState(window.innerWidth)

  /* ========================================= Functions ======================================== */
  async function getSiswaById() {
    const response = await getRequest(`siswaById?id=${param.siswaId}`)
    if (response) {
      setdataSiswa(response.data)
    } else {
      console.error('ERROR', response)
    }
  }

  function updateDimensions() {
    setWidth(window.innerWidth)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getSiswaById()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-4">
                <div
                  className="col-12"
                  style={{
                    display: 'flex',
                    flexDirection: windowWidth >= 768 ? 'row' : 'column',
                    alignItems: windowWidth >= 768 ? 'start' : 'center',
                  }}
                >
                  <img
                    className="img-fluid card-img"
                    style={{
                      width: '220px',
                      height: '220px',
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                    src={dataSiswa ? dataSiswa.linkAvatar : avatarDefaultUser}
                    alt={dataSiswa ? dataSiswa.name : 'User'}
                    onError={(e) => (e.currentTarget.src = avatarDefaultUser)}
                  />
                  <div
                    className="ml-md-4"
                    style={{ width: '100%' }}
                  >
                    <div className="row">
                      <label className="col-4 col-md-3 col-xl-2 col-form-label">
                        Nama
                      </label>
                      <div className="col-8 p-2">
                        <p
                          style={{ fontSize: '16px' }}
                          className="m-0 "
                        >
                          <span className="mr-3">:</span>
                          {dataSiswa
                            ? dataSiswa.name
                              ? dataSiswa.name
                              : ''
                            : ''}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-4 col-md-3 col-xl-2 col-form-label">
                        Deskripsi
                      </label>
                      <div className="col-8 p-2">
                        <p
                          style={{ fontSize: '16px' }}
                          className="m-0 "
                        >
                          <span className="mr-3">:</span>
                          {dataSiswa
                            ? dataSiswa.description
                              ? dataSiswa.description
                              : ''
                            : ''}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-4 col-md-3 col-xl-2  col-form-label">
                        No. Induk
                      </label>
                      <div className="col-8 col-md-9 col-xl-10 p-2">
                        <p
                          style={{ fontSize: '16px' }}
                          className="m-0 "
                        >
                          <span className="mr-3">:</span>
                          {dataSiswa
                            ? dataSiswa.nim
                              ? dataSiswa.nim
                              : ''
                            : ''}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-4 col-md-3 col-xl-2 col-form-label">
                        Status
                      </label>
                      <div className="col-8 col-md-9 col-xl-10 p-2">
                        <p
                          style={{ fontSize: '16px' }}
                          className="m-0 "
                        >
                          <span className="mr-3">:</span>
                          {dataSiswa ? (
                            dataSiswa.active ? (
                              <span className="badge bg-primary text-white">
                                Aktif
                              </span>
                            ) : (
                              <span className="badge bg-danger text-white">
                                Tidak aktif
                              </span>
                            )
                          ) : (
                            ''
                          )}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <label className="col-4 col-md-3 col-xl-2 col-form-label">
                        Jenis Kelamin
                      </label>
                      <div className="col-8 col-md-9 col-xl-10 p-2">
                        <p
                          style={{ fontSize: '16px' }}
                          className="m-0 "
                        >
                          <span className="mr-3">:</span>
                          {dataSiswa ? (
                            <>
                              {dataSiswa.gender === 'L' && 'Laki-laki'}
                              {dataSiswa.gender === 'P' && 'Perempuan'}
                            </>
                          ) : (
                            ''
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-4 col-md-3 col-xl-2 col-md-3 col-form-label">
                        Tanggal Lahir
                      </label>
                      <div className="col-8 col-md-9 col-xl-10 p-2">
                        <p
                          style={{ fontSize: '16px' }}
                          className="m-0 "
                        >
                          <span className="mr-3">:</span>
                          {dataSiswa
                            ? dataSiswa.birthdate
                              ? dataSiswa.birthdate
                              : ''
                            : ''}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-4 col-md-3 col-xl-2 col-form-label">
                        Alamat
                      </label>
                      <div className="col-8 col-md-9 col-xl-10 p-2">
                        <p
                          style={{ fontSize: '16px' }}
                          className="m-0 "
                        >
                          <span className="mr-3">:</span>
                          {dataSiswa
                            ? dataSiswa.location
                              ? dataSiswa.location
                              : ''
                            : ''}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <label className="col-4 col-md-3 col-xl-2 col-form-label">
                        Username
                      </label>
                      <div className="col-8 col-md-9 col-xl-10 p-2">
                        <p
                          style={{ fontSize: '16px' }}
                          className="m-0 "
                        >
                          <span className="mr-3">:</span>
                          {dataSiswa
                            ? dataSiswa.username
                              ? dataSiswa.username
                              : ''
                            : ''}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-4 col-md-3 col-xl-2 col-form-label">
                        Email
                      </label>
                      <div className="col-8 col-md-9 col-xl-10 p-2">
                        <p
                          style={{ fontSize: '16px' }}
                          className="m-0 "
                        >
                          <span className="mr-3">:</span>
                          {dataSiswa
                            ? dataSiswa.email
                              ? dataSiswa.email
                              : ''
                            : ''}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-4 col-md-3 col-xl-2 col-form-label">
                        No. Telepon
                      </label>
                      <div className="col-8 col-md-9 col-xl-10 p-2">
                        <p
                          style={{ fontSize: '16px' }}
                          className="m-0 "
                        >
                          <span className="mr-3">:</span>
                          {dataSiswa
                            ? dataSiswa.phone
                              ? dataSiswa.phone
                              : ''
                            : ''}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <Back />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

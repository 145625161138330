import React from 'react'
import { useParams } from 'react-router-dom'
import NotFound from 'component/not-found'
import useOverviewGradeContext, { OverviewGradeContextWrapper } from './context/overviewGrade.context'
import BaseModalSelectedStudentV2 from 'component/modal/BaseModalSelectedStudentV2'
import DataTableWithFilter from 'component/data-table/indexWithFilter'
import OverviewGradeListItem from './components/OverviewGradeListItem'

function OverviewGrade({ kelasId, access }) {
  /* ========================================== Helpers ========================================= */
  const params = useParams()

  /* ====================================== Consume Context ===================================== */
  const overviewGradeContext = useOverviewGradeContext()

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card user-profile-list  ">
            <div className="card-header d-flex justify-content-between">
              <div className="btn-group mr-2">
                <button
                  className={`btn btn-primary ${
                    access ? 'btn-forbidden' : ''
                  }`}
                  type="button"
                  disabled={access}
                  onClick={overviewGradeContext.handler.onClickAddGradeHandler}
                >
                  <i className="feather icon-plus-circle mr-1" />
                  <span>Tambah Grade</span>
                </button>
              </div>
            </div>
            <div className="card-body">
              <ul className="list-unstyled">
                {Array.isArray(overviewGradeContext.data?.data) ? (
                  <DataTableWithFilter
                    show={[10, 20, 30, 40]}
                    column={['NAMA', 'JUMLAH SISWA', 'JUMLAH MATERI', 'ACTION']}
                    totalRows={overviewGradeContext.data.meta?.totalData}
                    onChangeSearch={(val) => overviewGradeContext.handler.onSetMetaHandler({ search: val })}
                    onChangeLimit={(val) => overviewGradeContext.handler.onSetMetaHandler({ limit: val })}
                    onChangePage={(val) => overviewGradeContext.handler.onSetMetaHandler({ offset: val })}
                    className={'table table-striped table-bordered nowrap'}
                    data={overviewGradeContext.data.data}
                    renderItem={(item, index) => (
                      <OverviewGradeListItem
                        key={`content-grade-item-index${index}`}
                        item={item}
                        access={access}
                        onClickGradeStudentHandler={overviewGradeContext.handler.onClickGradeStudentHandler}
                        onClickGradeEditHandler={overviewGradeContext.handler.onClickGradeEditHandler}
                        onClickGradeRemoveHandler={overviewGradeContext.handler.onClickGradeRemoveHandler}
                        onClickGradeListContentHandler={overviewGradeContext.handler.onClickGradeListContentHandler}
                      />
                    )}
                  />
                ) : (
                  <div className="row">
                    <NotFound
                      icon="fas fa-file"
                      text="Grade belum ada"
                    />
                  </div>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <BaseModalSelectedStudentV2
        show={overviewGradeContext.data.showModalSelectedStudent}
        onCloseHandler={overviewGradeContext.handler.onCloseGradeStudentHandler}
        selectedGrade={overviewGradeContext.data.selectedGrade}
        subMapelId={params?.kelasId}
        title={overviewGradeContext.data.selectedContent?.renderTitle}
        listSelectedStudentsProps={{
          listStudentsActions: []
        }}
      />
    </>
  )
}


export default function OverviewGradeWrapper({ kelasId, access }) {
  return <OverviewGradeContextWrapper>
    <OverviewGrade kelasId={kelasId} access={access} />
  </OverviewGradeContextWrapper>
}
import React from 'react'
import useChatContext from 'context/chat'
import MessageItem from './message-item'
import ListContainer from './partials/list-container'

const MessageList = () => {
  const { messages } = useChatContext()

  return (
    <ListContainer>
      {messages.list.length !== 0
        ? messages.list.map((message) => (
            <MessageItem
              key     = {message._id}
              message = {message}
            />
          ))
        : null}
    </ListContainer>
  )
}

export default MessageList

import React, { useEffect, useState } from 'react'
import ReactSelect from 'react-select'
import moment from 'moment'
import 'moment/locale/id'
import 'datatables.net'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'

export default function VideoConferenceOrtu() {
	/* =================================== Consume Local Storage =================================== */
  const { id } = JSON.parse(localStorage.getItem('user'))

	/* ====================================== Consume Context ====================================== */
  const { getRequest } = useBaseContext()

	/* ======================================== Local States ======================================= */
  const [limit, setLimit]               = useState(10)
  const [offset, setOffset]             = useState(0)
  const [search, setSearch]             = useState('')
  const [data, setData]                 = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [listSiswa, setListSiswa]       = useState([])
  const [filterSiswa, setFilterSiswa]   = useState('')

	/* ========================================== Contants ========================================= */
  const currentDateTime = Date.now()

	/* ========================================= Functions ========================================= */
  async function getData() {
    let filter = ''
    if (filterSiswa?.value) {
      filter = `&user_id=${filterSiswa?.value}`
    }
    const response = await getRequest(
      `webinars-siswa-by-ortu?limit=${limit}&offset=${offset}&search=${search}${filter}`,
    )
    if (response) {
      setData(response.data)
      setTotalRecords(response.totalRecords)
    }
  }

  async function getSiswa() {
    const response = await getRequest(`parent-to-siswa?user_id=${id}`)
    if (response) {
      let tempArr = []
      response.data.map((item) =>
        tempArr.push({ label: item?.user?.name, value: item?.user?.id }),
      )
      setListSiswa(tempArr)
    }
  }

	/* ========================================= Components ======================================== */
  const RenderItem = ({ item }) => {
    const duration = item.duration * 60 * 1000
    const webinarTime = new Date(item.start_time).getTime()
    const webinarDuration = duration + webinarTime

    return (
      <tr>
        <td>
          <ul>
            {item?.webinar_profiles?.map((it, key2) =>
              it?.group?.groupusers.map((itx, idx) => (
                <li key={idx}>{itx?.user?.name}</li>
              )),
            )}
          </ul>
        </td>
        <td title={item.topic}>
          {webinarDuration < currentDateTime && (
            <span className="badge badge-secondary mr-1">Selesai</span>
          )}
          {item.topic.slice(0, 25)}
          {item.topic.length > 25 ? '...' : ''}
        </td>
        <td title={item.agenda}>
          {item.agenda ? item.agenda.slice(0, 25) : '-'}
          {item.agenda.length > 25 ? '...' : ''}
        </td>
        <td>
          {moment(item.start_time).format('dddd, DD/MM/YYYY HH:mm')}
        </td>
        <td>{item.duration + ' menit'}</td>
        <td>
          {item.active &&
          item.zoom_status &&
          webinarDuration >= currentDateTime ? (
            <span className="badge badge-success">Aktif</span>
          ) : (
            <span className="badge badge-secondary">Tidak Aktif</span>
          )}
        </td>
      </tr>
    )
  }

	/* ========================================== Effects ========================================== */
  useEffect(() => {
    getData()
  }, [limit, offset, search, filterSiswa])

  useEffect(() => {
    id && getSiswa()
  }, [id])

	/* =========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Video Konferensi Siswa" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-body">
                <div className="row">
                  <div className="col-6">
                    <label className="col-form-label">Nama Siswa</label>
                    <ReactSelect
                      options={listSiswa}
                      value={filterSiswa}
                      onChange={(e) => setFilterSiswa(e)}
                      placeholder="Filter berdasarkan siswa"
                    />
                  </div>
                </div>
                <hr />
                <div className="dt-responsive table-responsive">
                  <DataTable
                    className={'table table-striped table-bordered nowrap'}
                    data={data}
                    totalRows={totalRecords}
                    show={[10, 20, 30, 40, 50]}
                    onChangeSearch={(val) => {
                      setSearch(val)
                    }}
                    onChangeLimit={(val) => {
                      setLimit(val)
                    }}
                    onChangePage={(val) => {
                      setOffset(val)
                    }}
                    column={[
                      'Nama Siswa',
                      'Topik',
                      'Agenda',
                      'Waktu',
                      'Durasi',
                      'Status',
                    ]}
                    renderItem={(item) => (
                      <RenderItem
                        item={item}
                        key={item.id}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import { useCallback, useEffect, useReducer, useState } from "react"
import { useBaseContext } from "context/base"

import * as yup from 'yup';
import Swal from "sweetalert2";

/**
 * 
 * @param {any} state 
 * @param {{
*  type: 'type' | 'academic-year' | 'class' | 'semester',
*  value: string,
* }} action 
*/
function reactSelectReducer(state, action) {
 const { type, value } = action

 switch (type) {
   case 'type':
     return {
       ...state,
       selectedType: value,
       selectedClass: null,
     }
   case 'academic-year':
     return {
       ...state,
       selectedAcademicYear: value,
     }
   case 'class':
     return {
       ...state,
       selectedClass: value,
     }
   case 'semester':
     return {
       ...state,
       selectedSemester: value
     }
   case 'name':
     return {
       ...state,
       name: value
     }
   default:
     throw Error('Unknown reactSelect action')
 }
}

export default function useOrderSubjectReportModalAdd({ DNDListHook, headerHook }) {

  /* --------------------------------- CONTEXT -------------------------------- */
  const { getRequest, postRequest } = useBaseContext()

  async function getActiveYear() {
    let active = localStorage.getItem('semester')
    if (active) {
      const json = JSON.parse(active)
      return json.ta_tahun_code
    }
  }

  /* ---------------------------------- STATE ---------------------------------- */
  const [isShowModalAddOrderSubjectReport, setIsShowModalAddOrderSubjectReport] = useState(false)
  const [isCreatedNewSubjectGroup, setIsCreatedNewSubjectGroup] = useState(false)

  const [optionsType, setOptionsType] = useState([
    {
      label: 'Semua Kelas',
      value: 'all',
    },
    {
      label: 'Kelas',
      value: 'class'
    }
  ])

  const [optionsAcademicYear, setOptionsAcademicYear] = useState([])

  const [optionsClass, setOptionsClass] = useState([])

  const [optionsSemester, setOptionsSemester] = useState([])

  const [reactSelectState, reactSelectDispatch] = useReducer(reactSelectReducer, {
    selectedType: optionsType?.[1],
    selectedAcademicYear: null,
    selectedSemester: null,
    selectedClass: null,
    name: '',
  })

  /* --------------------------------- HANDLER -------------------------------- */
  const setSelectedOptionsHeader = useCallback(() => {
    if (headerHook?.customRef?.selectedAcademicYearRef.current) {
      headerHook?.customRef?.selectedAcademicYearRef.current.select.selectOption(reactSelectState?.selectedAcademicYear)
    }

    if (headerHook?.customRef?.selectedSemesterRef.current) {
      headerHook?.customRef?.selectedSemesterRef.current.select.selectOption(reactSelectState?.selectedSemester)
    }

    if (headerHook?.customRef?.selectedClassRef.current) {
      headerHook?.customRef?.selectedClassRef.current.select.selectOption(reactSelectState?.selectedClass)
    }
  }, [
    headerHook?.customRef?.selectedAcademicYearRef,
    headerHook?.customRef?.selectedSemesterRef,
    headerHook?.customRef?.selectedClassRef,
    reactSelectState,
  ])

  const toggleModalAddOrderSubjectReportHandler = (condition) => {
    setIsShowModalAddOrderSubjectReport(condition)
  }

  /**
   * Reset State Handler
   * 
   * @param {'academic-year' | 'class' | 'semester'} type 
   */
  const resetStateHandler = (type) => {
    switch (type) {
      case 'academic-year':
        setOptionsAcademicYear([])
        reactSelectDispatch({ type: 'academic-year', value: null })
        break;
      case 'class':
        setOptionsClass([])
        reactSelectDispatch({ type: 'class', value: null })
        break;
      case 'semester':
        setOptionsSemester([])
        reactSelectDispatch({ type: 'semester', value: null })
        break;
      default:
        throw Error('Unknown Type')
    }
  }

  async function getAcademicYearHandler() {
    const semesterActiveYearCode = await getActiveYear()

    const response = await getRequest(`academic-year`)
    if (response) {
      const manipulatedReactSelectData = response?.data?.map(item => {
        const data = {
          label: `${item?.description}${semesterActiveYearCode == item?.code ? ' (Aktif)' : ''}`,
          value: item?.code
        }

        if (semesterActiveYearCode == item?.code) {
          reactSelectDispatch({ type: 'academic-year', value: data })
        }

        return data
      })

      setOptionsAcademicYear(manipulatedReactSelectData)
    }
  }

  async function getClassHandler() {
    resetStateHandler('class')
    if (reactSelectState?.selectedType?.value === 'class' && reactSelectState?.selectedAcademicYear) {
      const response = await getRequest(`groups?ta_tahun_code=${reactSelectState?.selectedAcademicYear?.value}`)
      if (response) {
        const manipulatedReactSelectData = response?.data?.map(item => {
          const data = {
            label: item?.group,
            value: item?.id
          }
  
          return data
        })
  
        setOptionsClass(manipulatedReactSelectData)

        getSemesterHandler()
      }
    }
  }

  async function getSemesterHandler() {
    resetStateHandler('semester')
    if (reactSelectState?.selectedType?.value === 'class' && reactSelectState?.selectedAcademicYear) {
      const response = await getRequest(`semester?ta_tahun_code=${reactSelectState?.selectedAcademicYear?.value}`)
      if (response) {
        const manipulatedReactSelectData = response?.data?.map(item => {
          const isActiveLabel = item?.status ? ' (Aktif)' : ''
          const label = `${item?.tahun_mulai}/${item?.tahun_selesai} - ${item?.title_render}${isActiveLabel}`
          const data = {
            label,
            value: item?.code
          }
  
          if (item?.status) {
            reactSelectDispatch({ type: 'semester', value: data })
          }

          return data
        })
  
        setOptionsSemester(manipulatedReactSelectData)
      }
    }
  }

  const addNewSubjectCategoryMapelHandler = async () => {
    toggleModalAddOrderSubjectReportHandler(false)
    Swal.fire({
      title: 'Loading',
      text: 'Harap Tunggu...',
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {
        Swal.hideLoading()
      }
    })

    const payload = {
      name: reactSelectState?.name,
      group_id: reactSelectState?.selectedClass?.value,
      ta_semester_code: reactSelectState?.selectedSemester?.value,
      ta_tahun_code: reactSelectState?.selectedAcademicYear?.value
    }

    const response = await postRequest('add-kategori-mapel-rapor', payload)

    if (response) {
      Swal.close()
      
      Swal.fire('Berhasil!', 'Kategori mapel baru berhasil ditambahkan!.', 'success')
      .then(() => {
        setSelectedOptionsHeader()
        DNDListHook.handler.getListHandler(reactSelectState)
      })
    } else {
      Swal.close()

      Swal.fire('Gagal!', 'Kategori mapel baru gagal ditambahkan!.', 'error')
    }
  }

  /* --------------------------------- SCHEMA --------------------------------- */
  const addHeaderGroupSchema = yup.object().shape({
    selectedAcademicYear: yup.object().required('Silahkan pilih tahun ajaran terlebih dahulu.'),
    selectedSemester: yup.object().required('Silahkan pilih semester terlebih dahulu.'),
    selectedClass: yup.object().required('Silahkan pilih kelas terlebih dahulu.'),
    name: yup.string().required('Silahkan masukkan nama kategori mapel terlebih dahulu.'),
  });

  /* ---------------------------------- HOOK ---------------------------------- */
  useEffect(() => {
    getAcademicYearHandler()
  }, [])

  useEffect(() => {
    getClassHandler()
  }, [reactSelectState?.selectedType, reactSelectState?.selectedAcademicYear?.value])

  return {
    schema: {
      addHeaderGroupSchema,
    },
    data: {
      optionsType,
      optionsClass,
      optionsSemester,
      optionsAcademicYear,
      isShowModalAddOrderSubjectReport,
      isCreatedNewSubjectGroup
    },
    handler: {
      getSemesterHandler,
      addNewSubjectCategoryMapelHandler,
      toggleModalAddOrderSubjectReportHandler
    },
    customState: {
      reactSelectState,
    },
    customSetState: {
      setIsCreatedNewSubjectGroup
    },
    customDispatch: {
      reactSelectDispatch,
    },
  }
}
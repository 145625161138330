import React, {  useRef, useState } from 'react'
import { Helmet } from 'react-helmet'

import { useBaseContext } from 'context/base'
import Logo from './components/logo'

export default function Reset() {
  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [loading, setLoading]           = useState(false)
  const [email, setEmail]               = useState('')
  const [isEmailEmpty, setIsEmailEmpty] = useState(false)

  /* =========================================== Refs =========================================== */
  const refEmail = useRef()

  /* ========================================= Functions ======================================== */
  async function handleResetPassword() {
    setLoading(true)
    if (email) {
      setIsEmailEmpty(false)
      var form = new FormData()
      form.append('email', email)

      let response = await postRequest('forgot-password', form)
      if (response) {
        setLoading(false)
        setTimeout(function () {
          window.location.replace('/login')
        }, 3000)
        window.notification(
          'Berhasil',
          'Permintaan reset berhasil, verifikasi akan kami kirim melalui email',
          'success',
        )
      } else {
        setLoading(false)
        window.notification('Gagal', 'Email tidak terdaftar', 'error')
      }
    } else {
      setIsEmailEmpty(true)
      setLoading(false)
      refEmail.current.focus()
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <div className="auth-wrapper bg-primary">
      <Helmet>
        <title>LMS · Reset Password</title>
      </Helmet>
      <div className="auth-content align-items-center text-center">
        <Logo />
        <div className="card mb-5">
          <div className="row align-items-center text-center">
            <div className="col-md-12">
              <div className="card-body">
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    handleResetPassword()
                  }}
                >
                  <div className="form-group mb-3">
                    <label
                      className="floating-label"
                      htmlFor="Email"
                    >
                      Email
                    </label>
                    <input
                      ref={refEmail}
                      type="text"
                      className="form-control"
                      id="Email"
                      onChange={(e) => {
                        if (e.target.value) {
                          setIsEmailEmpty(false)
                        }
                        setEmail(e.target.value)
                      }}
                    />
                    {isEmailEmpty ? (
                      <span className="col small form-text text-danger text-left">
                        Email tidak boleh kosong.
                      </span>
                    ) : null}
                  </div>
                  <button
                    disabled={loading}
                    type="button"
                    onClick={() => {
                      handleResetPassword()
                    }}
                    className="btn btn-block btn-primary mt-4 mb-4"
                  >
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                    )}
                    <span>Reset Password</span>
                  </button>
                  <p className="mb-2 text-muted">
                    Sudah memiliki akun?{' '}
                    <a
                      href="/login"
                      className="f-w-400"
                    >
                      Login
                    </a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span
        className="text-light"
        style={{
          position: 'absolute',
          bottom: '7px',
          fontSize: '13px',
          fontWeight: '400',
        }}
      >
        <span>Copyright &copy; 2021</span>
        <a
          className="text-light ml-1"
          href="https://millennia-solusi.id"
          target="_blank"
          style={{
            fontWeight: '300',
          }}
          rel="noreferrer"
        >
          Millennia Solusi Informatika
        </a>
      </span>
    </div>
  )
}

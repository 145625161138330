import debounce from 'lodash.debounce'
import React, { useState, useEffect, Fragment } from 'react'

export default function DataTable(props) {
    const isWithHeader = new Object(props).hasOwnProperty('withHeader') ? props?.withHeader : true
    const show = props.show !== undefined ? props.show : [10, 20, 30, 40, 50]
    const [limit, setLimit] = useState(show[0])
    const [start, setStart] = useState(0)
    const [totalPage, setTotalPage] = useState(0)
    const [activePage, setActivePage] = useState(1)

    const data = props.data
    const className = props.className !== undefined ? props.className : "table"

    /* -------------------------------- HANDLERS -------------------------------- */
    const debouncedSearch = debounce(async (value) => {
        setStart(0)
        props.onChangeSearch(value)
    }, 600);

    const onSearchHandler = (val) => {
        debouncedSearch(val.target.value)
    }

    useEffect(() => {
        var tp = props.totalRows / limit
        setTotalPage(Math.ceil(tp))
        var act = (start / limit) + 1
        setActivePage(act)
    }, [limit, props.totalRows]);

    return (
        <>
            {isWithHeader
            ?
            <div className="row justify-content-between mb-4 ml-2 mr-2">
                <div>
                    <select onChange={(val) => {
                        setLimit(val.target.value)
                        setStart(0)
                        // setData('')
                        return props.onChangeLimit(val.target.value)
                    }} id="inputState" className="form-control">
                        {show.map((val, i) => (
                            <option key={i} value={val}>{val}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <input type="text" onChange={onSearchHandler} className="form-control" placeholder="Search" />
                </div>
            </div>
            : null }
            <table className={className}>
                <thead>
                    <tr>
                        {props.column.map((val, i) => (
                            <th key={"th" + i}>{val}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <RenderContent />
                </tbody>
            </table>
            <div className="row justify-content-between mt-4 ml-2 mr-2">
                <div>
                    Showing {((activePage - 1) * limit) + 1} to {(activePage * limit) >= props.totalRows ? props.totalRows : activePage * limit} of {props.totalRows} entries
                </div>
                <div>
                    <RenderPagination />
                </div>
            </div>
        </>
    )
    function RenderContent() {
        if (Array.isArray(data)) {
            return props.data.length === 0 ? (
                <tr>
                    <td colSpan={props.column.length} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>Tidak ada data yang ditemukan</td>
                </tr>
            ) : props.data.map((val, i) => {
                return (
                    <Fragment key={`data-table__render-content__` + i}>
                        {props.renderItem(val, i)}
                    </Fragment>
                )
            })
        } else {
            return (
                <tr>
                    <td colSpan={props.column.length} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <h4 className='mb-3'>Memuat...</h4>
                        <div className="spinner-border text-warning" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <h5 className='mt-3'>Ditunggu ya!</h5>
                    </td>
                </tr>

            )
        }


    }
    function RenderPagination() {
        var sisa = totalPage - activePage
        var kanan = sisa >= 1 ? activePage + 1 : 0
        var kiri = activePage === 1 ? 0 : activePage - 1

        return (
            <ul className="pagination justify-content-end">
                {activePage === 1 ? (<li className="page-item disabled"><button className="page-link" type="button">Previous</button></li>) : <li className="page-item" onClick={() => {
                    var a = activePage
                    var k = --a
                    setActivePage(k)
                    // setData('')
                    return props.onChangePage((k * limit) - limit)
                }}><button className="page-link" type="button">Previous</button></li>}
                {kiri > 0 ? (<li className="page-item"><button onClick={() => {
                    setActivePage(kiri)
                    // setData('')
                    return props.onChangePage((kiri * limit) - limit)
                }} className="page-link" type="button">{kiri}</button></li>) : null}

                <li className="page-item active"><span className="page-link">{activePage}<span className="sr-only">(current)</span></span>
                </li>

                {kanan > 0 ? (<li className="page-item"><button onClick={() => {
                    setActivePage(kanan)
                    // setData('')
                    return props.onChangePage((kanan * limit) - limit)
                }} className="page-link" type="button">{kanan}</button></li>) : null}
                {/* <li className="page-item"><a className="page-link" href="#!">1</a></li>
                <li className="page-item active"><span className="page-link">2<span className="sr-only">(current)</span></span>
                </li>
                <li className="page-item"><a className="page-link" href="#!">3</a></li> */}

                {activePage === totalPage ? (<li className="page-item disabled"><button className="page-link" type="button">Next</button></li>) : (<li className="page-item" onClick={() => {
                    var a = activePage
                    var k = ++a
                    setActivePage(k)
                    // setData('')
                    return props.onChangePage((k * limit) - limit)
                }}><button className="page-link" type="button">Next</button></li>)}
            </ul>
        )

    }
}
import React, { useState, useEffect } from 'react'
import { useBaseContext } from 'context/base'
import TableSurvey from 'component/content/questions/pilihan-group-survey'
import QuestionSurvey from 'component/content/questions/question-survey'
import BarChart from 'component/highchart/bar-chart'
import WordCloud from 'component/highchart/word-cloud-chart'

export const SurveyDone = ({ content, generatedKey }) => {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [showMyResult, setShowMyResult]       = useState(false)
  const [showGraphResult, setShowGraphResult] = useState(false)
  const [table, setTable]                     = useState(null)
  const [questions, setQuestions]             = useState([])
  const [dashboardSurvey, setDashboardSurvey] = useState([])
  const [noData, setNoData]                   = useState(false)
  const [loadingResult, setLoadingResult]     = useState(false)
  const [loadingGraph, setLoadingGraph]       = useState(false)

  /* ========================================= Functions ======================================== */
  async function handleShowResult() {
    if (!showMyResult) {
      await getSurveyResult()
    }
    setShowMyResult(!showMyResult)
  }

  async function handleShowDashboard() {
    if (!showGraphResult) {
      await getSurveyDashboard()
    }
    setShowGraphResult(!showGraphResult)
  }

  async function getSurveyResult() {
    setLoadingResult(true)
    const response = await getRequest(
      `survey-detail-user?&survey_id=${content.contents_url.survey_id}&content_id=${content.id}&get_by=user`,
    )
    setLoadingResult(false)
    if (response) {
      const pilihanGroupQuestions = response.kolombaris
        ? response.kolombaris
        : null
      const otherQuestions = response.data
      setQuestions(otherQuestions)
      setTable(pilihanGroupQuestions)

      if (
        response.kolombaris.baris.length === 0 &&
        response.data.length === 0
      ) {
        setNoData(true)
      }
    }
  }

  async function getSurveyDashboard() {
    setLoadingGraph(true)
    const response = await getRequest(
      `survey-dashboard?survey_id=${content.contents_url.survey_id}&content_id=${content.id}`,
    )
    setLoadingGraph(false)
    if (response) {
      setDashboardSurvey(response.data)
    }
  }

  function RenderGroupPg() {
    if (
      dashboardSurvey.length > 0 &&
      dashboardSurvey[0].group.categories.length > 0 &&
      dashboardSurvey[0].group.series.length > 0
    ) {
      var item = dashboardSurvey[0].group
      return (
        <BarChart
          categories={item.categories}
          series={item.series}
        />
      )
    }
    return null
  }

  function RenderWordCloud() {
    if (dashboardSurvey.length > 0 && dashboardSurvey[0].essay.length > 0) {
      var i = dashboardSurvey[0].essay
      return i.map((item, idx) => {
        return (
          <WordCloud
            string={item.str}
            label={item.label}
            key={idx}
          />
        )
      })
    }
    return null
  }

  function RenderPg() {
    if (dashboardSurvey.length > 0) {
      return (
        <div className={'row'}>
          {dashboardSurvey[0].pg.map((val, i) => {
            return (
              <div
                key={i}
                className={'col-md-6'}
              >
                <BarChart
                  categories={val.categories}
                  series={val.series}
                />
              </div>
            )
          })}
        </div>
      )
    }
    return null
  }

  useEffect(() => {
    setShowMyResult(false)
    setShowGraphResult(false)
    setTable(null)
    setQuestions([])
    setNoData(false)
  }, [content.id])

  return (
    <>
      <div className="d-flex justify-content-center">
        <div
          className="rounded-circle p-2 my-3"
          style={{
            border: '3px solid #9ccc65',
          }}
        >
          <i className="fas fa-check text-success f-48" />
        </div>
      </div>
      <p className="text-center f-20 mb-0">Selamat!</p>
      <p className="text-center f-20">Anda telah menyelesaikan survey ini</p>
      <div className="row">
        <div className="col-12 offset-md-3 col-md-6 offset-lg-4 col-lg-4 mb-3">
          <button
            onClick={handleShowResult}
            className={`${
              loadingResult ? 'btn-forbidden' : ''
            } btn btn-primary w-100`}
            disabled={loadingResult}
          >
            {loadingResult && (
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
              />
            )}
            {showMyResult ? 'Sembunyikan Hasil' : 'Lihat Survey Saya'}
          </button>
        </div>
        {showMyResult && (
          <div className="col-12 mb-3">
            <div className="border rounded p-3">
              {noData ? (
                <p className="m-0 text-center">
                  Tidak dapat menampilkan hasil survey
                </p>
              ) : (
                <>
                  {table ? (
                    <TableSurvey
                      table={table}
                      generatedKey={generatedKey}
                      readOnly={true}
                    />
                  ) : null}
                  <ul className="list-unstyled p-2 mb-0">
                    {questions.length !== 0
                      ? questions.map((question, index) => (
                          <QuestionSurvey
                            index={index}
                            question={question}
                            key={question.id}
                            readOnly={true}
                          />
                        ))
                      : null}
                  </ul>
                </>
              )}
            </div>
          </div>
        )}
        <div className="col-12 offset-md-3 col-md-6 offset-lg-4 col-lg-4 mb-3">
          <button
            onClick={handleShowDashboard}
            className={`${
              loadingGraph ? 'btn-forbidden' : ''
            } btn btn-primary w-100`}
            disabled={loadingGraph}
          >
            {loadingGraph && (
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
              />
            )}
            {showGraphResult ? 'Sembunyikan Grafik' : 'Lihat Data Grafik'}
          </button>
        </div>
        {showGraphResult && (
          <div className="col-12 mb-3">
            <div className="border rounded p-3">
              <RenderGroupPg />
              <RenderWordCloud />
              <RenderPg />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

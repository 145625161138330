import React, { useState } from 'react'
import { useBaseContext } from 'context/base'
import useContentPlayerContext from 'context/content-player'

const ModalFile = () => {
  /* ====================================== Consume Context ===================================== */
  const { files } = useContentPlayerContext()

  /* ========================================== Output ========================================== */
  return (
    <div
      id="exampleModalLong"
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="exampleModalLongTitle"
            >
              File Pendukung
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="collapse show">
              <ul className="list-unstyled mb-0">
                {files.data.length ? (
                  files.data.map((file, index) => (
                    <RenderFile
                      key={file.id}
                      item={file}
                      index={index}
                    />
                  ))
                ) : (
                  <div className="row">
                    <div className="col d-flex flex-column justify-content-center">
                      <i className="fa fa-folder-open f-80 text-muted text-center" />
                      <span className="f-14 text-center">Belum ada file</span>
                    </div>
                  </div>
                )}
              </ul>
            </div>
          </div>
          <div className="modal-footer p-2">
            <button
              type="button"
              className="btn btn-outline-danger"
              data-dismiss="modal"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const RenderFile = ({ item }) => {
  const { getRequest } = useBaseContext()
  const [isHovered, setIsHovered] = useState(false)

  function handleTypeFile(type) {
    if (type == 'mp4') {
      return 'fas fa-file-video'
    } else if (type == 'mp3') {
      return 'fas fa-file-audio'
    } else if (type == 'jpg') {
      return 'fas fa-file-image'
    } else if (type == 'pdf') {
      return 'fas fa-file-pdf'
    } else {
      return 'fas fa-file'
    }
  }

  async function handleDownload(item) {
    const checkFile = await getRequest(
      `checkfile?url=${item.download_file}`,
    )
    if (checkFile) {
      window.location.href = item.download_file
    }
  }

  return (
    <li>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="p-3 f-16 text-left text-dark"
        style={{
          width: '100%',
          height: '75px',
          backgroundColor: isHovered
            ? 'rgba(221, 221, 221, 0.2)'
            : 'transparent',
        }}
      >
        <div className="row">
          <div className="col d-flex justify-content-between align-items-center">
            <span
              style={{
                display: 'inline-block',
              }}
            >
              <span className={`text-dark d-block f-17 p-1`}>
                <i className={`${handleTypeFile(item.type)} f-30 mr-2`} />
                {`${item.nama_file}.${item.type}`}
              </span>
            </span>
            <span
              style={{
                display: isHovered ? 'inline-block' : 'none',
              }}
            >
              <button
                type="button"
                className="btn btn-sm btn-primary"
                title="Download"
                onClick={() => handleDownload(item)}
              >
                <i className="fas fa-download mr-1" />
                <span>Download</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </li>
  )
}

export default ModalFile

import React from 'react'
import PathwayItem from './pathway-item'

export default function PathwayList({ classes }) {
  return (
    <div className="row">
      <div className="col">
        <div className="rounded border p-3">
          <h5>Rekomendasi belajar</h5>
          <div className="mb-0 mt-3 px-3 row">
            {classes.length > 0
              ? classes.map((item, index) => (
                  <PathwayItem
                    key={index}
                    pathwayItem={item}
                  />
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import BackButton from 'component/button/Back'
import SubmitButton from 'component/button/Submit'
import {
  baseStyle,
  acceptStyle,
  activeStyle,
  rejectStyle,
  thumb,
  thumbsContainer,
  thumbInner,
  img,
} from './dropzone-css'
import { appendScript } from 'util/global'
import { getBase64 } from 'util/base64'

export default function AddUser() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [allRoles, setRoles]              = useState([])
  const [roles, setUserRoles]             = useState([])
  const [hidePass, setHidePass]           = useState(true)
  const [academicYears, setAcademicYears] = useState([])

  const [name, setName]                   = useState('')
  const [description, setDesc]            = useState('')
  const [nim, setNim]                     = useState('')
  const [gender, setGender]               = useState('')
  const [birthdate, setBirth]             = useState('')
  const [location, setLocation]           = useState('')
  const [email, setEmail]                 = useState('')
  const [phone, setPhone]                 = useState('')
  const [username, setUsername]           = useState('')
  const [password, setPassword]           = useState('')
  const [active, setActive]               = useState('')
  const [avatar, setAvatar]               = useState(null)
  const [files, setFiles]                 = useState([])
  const [rolesChecked, setRolesChecked]   = useState([])
  const [isRoleSiswa, setIsRoleSiswa]     = useState(false)
  const [taTahunCode, setTaTahunCode]     = useState('')
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  // Validation States
  const [isvalidname, setValidName]         = useState(false)
  const [isvalidnim, setValidNim]           = useState(false)
  const [isvalidemail, setValidEmail]       = useState(false)
  const [isvalidrole, setValidRole]         = useState(false)
  const [isvalidbirth, setValidBirth]       = useState(false)
  const [isvalidusername, setValidUsername] = useState(false)
  const [isvalidpassword, setValidPassword] = useState(false)
  
  /* =========================================== Refs =========================================== */
  const refInputName      = useRef()
  const refInputNim       = useRef()
  const refInputBirthdate = useRef()
  const refInputEmail     = useRef()
  const refInputUsername  = useRef()
  const refInputPassword  = useRef()
  
  /* ========================================= Constants ======================================== */
  const links = [{ url: '/users', name: 'Users' }]

  /* ======================================== Components ======================================== */
  function Dropzone() {
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      accept: 'image/*',
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        )
        getBase64(acceptedFiles[0])
          .then((result) => {            
            let avatarClass = result.split(',')
            setAvatar({ name: acceptedFiles[0].name, data: avatarClass[1] })
          })
          .catch((err) => {
            console.error(err)
          })
      },
    })

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isDragActive, isDragReject, isDragAccept],
    )

    const thumbs = files.map((file) => (
      <div
        style={thumb}
        key={file.name}
      >
        <div style={thumbInner}>
          <img
            src={file.preview}
            alt=""
            style={img}
          />
        </div>
      </div>
    ))
    useEffect(
      () => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file) => URL.revokeObjectURL(file.preview))
      },
      [files],
    )

    return (
      <section className="container">
        <label className="row pl-3">Avatar</label>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p className="mt-5">Drag or click to select file for add avatar</p>
        </div>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </section>
    )
  }

  async function getRoles() {
    let response = await getRequest('roles')
    if (response != null) {      
      setRoles(response.data)
      initCheckedRoles(response.data)
    } else {
      setRoles(null)
    }
  }

  function initCheckedRoles(roles) {
    const initRoles = roles.map((role) => ({
      id: role.id,
      role_type: role.role_type,
      checked: false,
    }))
    setRolesChecked(initRoles)
  }

  async function getAcademicYears() {
    const response = await getRequest('academic-year')    
    if (response) {
      if (response.data.length > 0) {
        const options = response.data.map((item) => {
          const startYear = `20${item.code[0]}${item.code[1]}`
          return {
            value: item.code,
            label: `${startYear}`,
          }
        })
        setAcademicYears(options)
      }
    }
  }

  function handleChangeRole(status, roleId) {
    let updateRoleChecked = []
    let updateRoles = []
    rolesChecked.forEach((item) => {
      if (roleId === item.id) {
        if (status) {
          updateRoleChecked.push({
            id: item.id,
            role_type: item.role_type,
            checked: true,
          })
          updateRoles.push(item.id)
        } else {
          updateRoleChecked.push({
            id: item.id,
            role_type: item.role_type,
            checked: false,
          })
        }
      } else {
        updateRoleChecked.push(item)
        if (item.checked === true) updateRoles.push(item.id)
      }
    })
    setRolesChecked(updateRoleChecked)
    setUserRoles(updateRoles)
  }

  function checkIfRoleIsSiswa() {
    for (let role of rolesChecked) {
      if (role.role_type === 'siswa') {
        if (role.checked === true) {
          setIsRoleSiswa(true)
          break
        } else {
          setIsRoleSiswa(false)
          break
        }
      }
    }
  }

  function handleChangeTahunCode(event) {
    setTaTahunCode(event.target.value)
  }

  function handleFormPhoneNumber(val) {
    var x = val.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/)
    setPhone(!x[2] ? x[1] : `${x[1]}-${x[2]}` + (x[3] ? '-' + x[3] : ''))
  }

  function handleChangeUsername(event) {
    setUsername(event.target.value.toLowerCase())
  }

  async function submit(e) {
    if (name && email && username && password && birthdate && nim) {
      setLoadingSubmit(true)
      var form = new FormData()
      form.append('name', name)
      form.append('description', description)
      form.append('nim', nim)
      form.append('gender', gender)
      form.append('birthdate', birthdate)
      form.append('location', location)
      form.append('role_id', JSON.stringify(roles))
      form.append('email', email)
      form.append('phone', phone)
      form.append('username', username)
      form.append('password', password)
      form.append('active', active)
      if (avatar) {
        form.append('avatar', JSON.stringify(avatar))
      }
      if (taTahunCode) {
        form.append('ta_tahun_code', taTahunCode)
      }
      let response = await postRequest('users', form)
      setLoadingSubmit(false)      
      if (response) {
        if (response.message === '') {
          window.notification('User', 'berhasil disimpan', 'success')
          history.push('/users')
        }

        if (response.message === 'username sudah digunakan') {
          window.notification(
            'Username sudah digunakan,',
            'mohon masukan username berbeda',
            'warning',
          )
        } else if (response.message === 'nim sudah digunakan') {
          window.notification(
            'No. Induk sudah digunakan,',
            'mohon masukan No. Induk berbeda',
            'warning',
          )
        } else if (response.message === 'Gagal insert data') {
          window.notification(
            'Terjadi kesalahan',
            'Coba beberapa saat lagi',
            'error',
          )
        }
      }
    } else {
      if (roles.length === 0) {
        setValidRole(true)
      } else {
        setValidRole(false)
      }

      if (!password) {
        setValidPassword(true)
        refInputPassword.current.focus()
      } else {
        setValidPassword(false)
      }

      if (!username) {
        setValidUsername(true)
        refInputUsername.current.focus()
      } else {
        setValidUsername(false)
      }

      if (!email) {
        setValidEmail(true)
        refInputEmail.current.focus()
      } else {
        setValidEmail(false)
      }

      if (!birthdate) {
        setValidBirth(true)
        refInputBirthdate.current.focus()
      } else {
        setValidBirth(false)
      }

      if (!nim) {
        setValidNim(true)
        refInputNim.current.focus()
      } else {
        setValidNim(false)
      }

      if (!name) {
        setValidName(true)
        refInputName.current.focus()
      } else {
        setValidName(false)
      }

      window.notification('Mohon', 'lengkapi data', 'warning')
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getRoles()
    getAcademicYears()
    appendScript('/admin/assets/js/plugins/jquery.validate.min.js')
    appendScript('/admin/assets/js/pages/form-validation.js')
  }, [])

  useEffect(() => {    
    checkIfRoleIsSiswa()
  }, [rolesChecked])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Tambah User"
          links={links}
        />

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Informasi User</h5>
              </div>
              <div className="card-body">
                <form
                  autoComplete="off"
                  id="validation-form123"
                >
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="mb-0">
                          <span>Nama</span>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <input
                          ref={refInputName}
                          value={name}
                          onChange={(val) => setName(val.target.value)}
                          type="text"
                          className={`form-control ${
                            isvalidname ? 'is-invalid' : ''
                          }`}
                          placeholder="Enter name"
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-0">Deskripsi</label>
                        <textarea
                          value={description}
                          onChange={(val) => setDesc(val.target.value)}
                          rows={3}
                          className="form-control"
                          placeholder="Enter description"
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <label className="mb-0">
                          <span>No. Induk</span>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <input
                          ref={refInputNim}
                          value={nim}
                          onChange={(val) =>
                            // setNim(val.target.value.replace(/\D/g, ''))
                            setNim(val.target.value)
                          }
                          type="text"
                          className={`form-control ${
                            isvalidnim ? 'is-invalid' : ''
                          }`}
                          placeholder="Enter NIM"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="gender">Gender</label>
                        <select
                          value={gender}
                          onChange={(val) => setGender(val.target.value)}
                          className="form-control"
                          id="gender"
                        >
                          <option value="">Pilih Gender ...</option>
                          <option value="L">Laki-laki</option>
                          <option value="P">Perempuan</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="mb-0">
                          <span>Tanggal Lahir</span>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <input
                          ref={refInputBirthdate}
                          value={birthdate}
                          onChange={(val) => setBirth(val.target.value)}
                          type="date"
                          className={`form-control ${
                            isvalidbirth ? 'is-invalid' : ''
                          }`}
                          placeholder="Enter birthdate"
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-0">Alamat</label>
                        <textarea
                          value={location}
                          onChange={(val) => setLocation(val.target.value)}
                          rows={3}
                          className="form-control"
                          placeholder="Enter detail address"
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <label htmlFor="role">
                          <span>Role</span>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        {allRoles.map((val, i) => {
                          return (
                            <div
                              key={i}
                              className="form-check"
                            >
                              <input
                                onChange={(ev) => {
                                  handleChangeRole(ev.target.checked, val.id)
                                }}
                                className={`form-check-input ${
                                  isvalidrole ? 'is-invalid' : ''
                                }`}
                                type="checkbox"
                                name="active"
                                id={i}
                                defaultValue="true"
                              />
                              <label
                                className="form-check-label"
                                htmlFor={i}
                              >
                                {val.role === 'Instruktur' ? 'Guru' : val.role}{' '}
                                {isvalidrole && (
                                  <i
                                    style={{ color: '#ff5252' }}
                                    className="fas fa-times"
                                  />
                                )}
                              </label>
                            </div>
                          )
                        })}
                      </div>
                      {isRoleSiswa ? (
                        <div className="form-group my-4">
                          <label htmlFor="academicYearUser">
                            <span>Tahun Masuk/Angkatan</span>
                          </label>
                          <select
                            value={taTahunCode}
                            onChange={handleChangeTahunCode}
                            id="academicYearUser"
                            className="form-control"
                          >
                            <option value="">Pilih tahun masuk...</option>
                            {academicYears.length > 0 ? (
                              academicYears.map((item, index) => (
                                <option
                                  key={index}
                                  value={item.value}
                                >
                                  {item.label}
                                </option>
                              ))
                            ) : (
                              <option value="">Data tidak ditemukan</option>
                            )}
                          </select>
                        </div>
                      ) : null}
                      <div className="form-group">
                        <label className="mb-0">
                          <span>Email</span>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <input
                          ref={refInputEmail}
                          value={email}
                          onChange={(val) => setEmail(val.target.value)}
                          type="email"
                          className={`form-control ${
                            isvalidemail ? 'is-invalid' : ''
                          }`}
                          placeholder="Example: example@mail.com"
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-0">Telepon</label>
                        <input
                          value={phone}
                          onChange={(val) =>
                            handleFormPhoneNumber(val.target.value)
                          }
                          type="text"
                          className="form-control"
                          placeholder="Example: 08XX-XXXX-XXXX"
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-0">
                          <span>Username</span>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <input
                          autoComplete="new-username"
                          ref={refInputUsername}
                          value={username}
                          onChange={handleChangeUsername}
                          type="text"
                          className={`form-control ${
                            isvalidusername ? 'is-invalid' : ''
                          }`}
                          placeholder="Enter username"
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-0">
                          <span>Password</span>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <div className="input-group mb-3">
                          <input
                            autoComplete="new-password"
                            ref={refInputPassword}
                            value={password}
                            onChange={(val) => setPassword(val.target.value)}
                            type={hidePass ? 'password' : 'text'}
                            className={`form-control ${
                              isvalidpassword ? 'is-invalid' : ''
                            }`}
                            placeholder="Enter password"
                          />
                          <div className="input-group-append">
                            <button
                              onClick={() =>
                                setHidePass(!hidePass)
                              }
                              className="btn btn-light rounded-circle btn-sm"
                              type="button"
                            >
                              {hidePass ? (
                                <i
                                  style={{ fontSize: '17px' }}
                                  className="fa fa-eye p-1"
                                />
                              ) : (
                                <i
                                  style={{ fontSize: '17px' }}
                                  className="fa fa-eye-slash p-1"
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                      <fieldset className="form-group">
                        <div className="row">
                          <label className="col-sm-3 col-form-label">
                            Status
                          </label>
                          <div className="col-sm-9">
                            <div className="form-check">
                              <input
                                onChange={(val) => setActive(val.target.value)}
                                className="form-check-input"
                                type="radio"
                                name="active"
                                id="active"
                                defaultValue="true"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="active"
                              >
                                Aktif
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                onChange={(val) => setActive(val.target.value)}
                                className="form-check-input"
                                type="radio"
                                name="active"
                                id="no-active"
                                defaultValue="false"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="no-active"
                              >
                                Tidak Aktif
                              </label>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-md-6">
                      <Dropzone />
                    </div>
                  </div>
                  <div className="row ml-1">
                    <BackButton />
                    {loadingSubmit ? (
                      <button
                        disabled
                        type="button"
                        className="btn btn-success"
                      >
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                        />
                        Simpan
                      </button>
                    ) : (
                      <SubmitButton
                        onClick={() => submit()}
                        text="Simpan"
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React, { useEffect, useMemo, useState } from "react";

import LoadingSpinner from "component/loading";
import LoadingSpinnerV2 from "component/loadingV2";
import BaseReactBootstrapModal from "component/modal/BaseReactBootstrapModal";
import FormPenilaianEdit from "./form-penilaian-edit";
import FormPenilaian from './form-penilaian'
import FormPenilaianDisabled from './form-penilaian-disabled'

import { useBaseContext } from "context/base";
import { avatarDefaultUser } from "util/constant";
import { createQueryUrl } from "util/string";
import PreviewContent from "pages/kelas/content-dashboard/preview-content";


export default function ModalDetailPeriksa({
  show = false,
  selectedUser,
  contentId,
  content,
  handleCloseModal = () => {},
  getAllUsers = () => {},
}) {
  const { getRequest } = useBaseContext()

  const [userDetail, setUserDetail] = useState({
    loading: false,
    data: null,
    preview: {},
  })

  const assignmentUser = {
    ...userDetail.data,
    assigment_users_histories: userDetail.data?.content_score_users_histories,
    content_id: contentId,
    user_id: selectedUser?.id,
  }

  /**
   * Set Is Loading
   * 
   * @param {boolean} condition 
   */
  const setIsLoading = (condition) => {
    setUserDetail(prevState => ({
      ...prevState,
      loading: condition
    }));
  }

  /**
   * Set Detail Data
   * 
   * @param {Object} data 
   */
  const setDetailData = (data) => {
    setUserDetail(prevState => ({
      ...prevState,
      data
    }));
  }

  /**
   * Set Preview Data
   * 
   * @param {Object} data 
   */
  const setPreviewData = (preview) => {
    setUserDetail(prevState => ({
      ...prevState,
      preview
    }));
  }

  const getDetailUserHandler = async () => {
    setIsLoading(true)
    const queryContentId = createQueryUrl({ queryName: 'content_id', value: contentId, seperator: '?' })
    const queryUserId = createQueryUrl({ queryName: 'user_id', value: selectedUser?.id })

    const response = await getRequest(
      `content-score-detail-siswa${queryContentId}${queryUserId}`,
    )

    if (response) {
      setIsLoading(false)
      setDetailData(response.data)
      setPreviewData(response?.preview)
    } else {
      setIsLoading(false)
      setDetailData(null)
    }
  }

  const renderHeaderTitle = useMemo(() => {
    if (userDetail.loading) {
      return <LoadingSpinner />
    } else {
      return (
        <div className="d-flex align-items-center">
          <img
            src={`${selectedUser?.linkAvatar}`}
            alt={selectedUser?.name ? selectedUser?.name : 'User'}
            className="img-radius"
            style={{ height: '30px', width: '30px' }}
            onError={e => e.currentTarget.src = avatarDefaultUser}
          />
          <p className="m-0 ml-2 f-w-700 f-18 d-flex align-items-center">
            {selectedUser?.name}
          </p>
          {userDetail.preview?.content_grade
          ?
            <span key={`list-grade-student${userDetail.preview?.content_grade?.id}`} className="badge badge-primary ml-1" style={{ fontSize: '.8rem' }}>Grade {userDetail.preview?.content_grade?.name}</span>
          : null}
        </div>
      )
    }
  }, [userDetail])

  useEffect(() => {
    if (selectedUser) {
      getDetailUserHandler()
    }
  }, [selectedUser])

  // : 
  //     <NotFound
  //       text="Data tidak ditemukan / Siswa belum selesai mengerjakan"
  //       icon="fas fa-user"
  //     />
  return (
    <BaseReactBootstrapModal show={show} enforceFocus={false} 
      headerTitle={renderHeaderTitle}
      size='lg' isWithFooter={true} closeTitle='Tutup' handleClose={handleCloseModal} isWithOkButton={false}
      centered
     >
      {userDetail.loading
      ?
      <>
        <LoadingSpinnerV2 size={5} />
        <h4 className="text-center mt-2">Harap Tunggu Sebentar...</h4>
      </>
      :
      <>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <PreviewContent {...userDetail?.preview} selectedUser={selectedUser} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          
          <div className="col-12">
            {(content?.feedback || content?.group_status) && (
              <>
                <div
                  className="mt-2 border rounded alert alert-warning w-100"
                  role="alert"
                >
                  <strong>
                    <i className="fas fa-info-circle mr-1" />
                    Siswa wajib
                    {content?.feedback && ` memberikan feedback`}
                    {content?.feedback &&
                      content?.group_status &&
                      ` dan`}
                    {content?.group_status &&
                      ` terlibat dalam diskusi kecil`}{' '}
                    pada materi.
                  </strong>
                </div>
                {selectedUser?._isFeedbackCompleted?.length ? (
                  <div
                    className="mt-2 border rounded alert alert-success w-100"
                    role="alert"
                  >
                    <strong>
                      <i className="fas fa-check-circle mr-1" />
                      Siswa sudah memberikan feedback pada materi.
                    </strong>
                  </div>
                ) : (
                  content?.feedback && (
                    <div
                      className="mt-2 border rounded alert alert-danger w-100"
                      role="alert"
                    >
                      <strong>
                        <i className="fas fa-info-circle mr-1" />
                        Siswa belum memberikan feedback pada materi.
                      </strong>
                    </div>
                  )
                )}
                {selectedUser?._isGrupKecilPassed ? (
                  <div
                    className="mt-2 border rounded alert alert-success w-100"
                    role="alert"
                  >
                    <strong>
                      <i className="fas fa-check-circle mr-1" />
                      Siswa sudah terlibat dalam diskusi kecil pada
                      materi.
                    </strong>
                  </div>
                ) : (
                  !selectedUser?.grup_kecil_passed && (
                    <div
                      className="mt-2 border rounded alert alert-danger w-100"
                      role="alert"
                    >
                      <strong>
                        <i className="fas fa-info-circle mr-1" />
                        Siswa belum terlibat dalam diskusi kecil pada
                        materi.
                      </strong>
                    </div>
                  )
                )}
              </>
            )}
          </div>
          <div className="col-12">
            {selectedUser?.score !== null ? (
              <FormPenilaianEdit
                assignmentUser={assignmentUser}
                handleCloseModal={handleCloseModal}
                getAllUsers={getAllUsers}
              />
            ) : (
              <>
                {content?.feedback ? (
                  <>
                    {content?.feedback ? (
                      <>
                        {selectedUser?._isGrupKecilPassed &&
                          selectedUser?._isFeedbackCompleted?.length > 0 ? (
                          <FormPenilaian
                            getAssignmentUsers={getAllUsers}
                            assignmentUser={assignmentUser}
                            handleCloseModal={handleCloseModal}
                          />
                        ) : (
                          <FormPenilaianDisabled />
                        )}
                      </>
                    ) : (
                      <FormPenilaian
                        getAssignmentUsers={getAllUsers}
                        assignmentUser={assignmentUser}
                        handleCloseModal={handleCloseModal}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {!selectedUser?._isGrupKecilPassed || (content.feedback &&
                        selectedUser?._isFeedbackCompleted?.length === 0) ? (
                      <FormPenilaianDisabled />
                    ) : (
                      <FormPenilaian
                        getAssignmentUsers={getAllUsers}
                        assignmentUser={assignmentUser}
                        handleCloseModal={handleCloseModal}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </>
      }
    </BaseReactBootstrapModal>
  )
}
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'

export default function ZoomPlayer() {
  /* ========================================== Helpers ========================================= */
  const location = useLocation()

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (stream) {
      })
      .catch(function (err) {
        console.error('Error', err);
      })
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Zoom" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Zoom</h5>
              </div>
              <div className="card-body">
                <iframe
                  allowusermedia
                  allow="camera *;microphone *"
                  src={
                    'https://zoom.pintaredu.id/meeting.html?' +
                    location.state.token
                  }
                  style={{ width: '100%', height: 800 }}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

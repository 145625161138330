import TemplateAdmin from 'component/admin/TemplateAdmin'
import BaseButton from 'component/button/BaseButton'
import ContentType from 'component/content/content-type'
import FormInputContent from 'component/content/FormInputContent'
import Keterangan from 'component/info/Keterangan'
import { Container, Header } from 'component/template'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useGradeListSelectedContentCreate } from './hooks/useGradeListSelectedContentCreate'
import { CONTENT_TYPE_TEXT } from 'util/enum'
import ContentIcon from 'component/content/ContentIcon'

/**
 * Grade List Selected Content Create
 * 
 * @param {{
 *  isOnEdit?: boolean
 * }} props 
 */
export default function GradeListSelectedContentCreate({ isOnEdit }) {
  const params = useParams()
  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm()

  const { data, handler } = useGradeListSelectedContentCreate()

  const kelasId = params?.kelasId

  /**
   * @type {ListBaseFormInputContentProps}
   */
  const formInputContentProps = {
    data: data.necessaryFormInputByContentTypeId.map(type => {
      let value

      if (isOnEdit) {
        value = watch(type, handler.getDefaultValueByFormInputTypeEditHandler({ type }))
      } else {
        value = watch(type, handler.getDefaultValueByFormInputTypeCreateHandler({ type }))
      }

      /**
       * @type {BaseFormInputContentProps}
       */
      const baseData = {
        type,
        control,
        props: {
          value,
          kelasId,
          isInvalid: errors?.[type],
          isRequired: true,
          setValue: () => {}
        },
      }

      switch (type) {
        case 'survey':
        case 'quiz':
          if (isOnEdit) {
            baseData.props.id = data.detailGradeContent?.contents_url?.[`${type}_id`]
            baseData.props.isInitSelectedValueFromFormSelect = true
          }
          break
        case 'graphic-organizer':
        case 'tugas':
          if (isOnEdit) {
            baseData.props.id = data.detailGradeContent?.contents_url
            baseData.props.isInitSelectedValueFromFormSelect = true
          }
          baseData.props.graphor_type = data.detailGradeContent?.graphor_type
          break
        default:
          break
      }

      return baseData
    })
  }

  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName={isOnEdit ? `Edit Materi ${CONTENT_TYPE_TEXT[data.detailGradeContent?.content_type_id]}` : `Buat Materi ${CONTENT_TYPE_TEXT[data.detailGradeContent?.content_type_id]}`}
          links={data.links}
          linkName={`Materi ${CONTENT_TYPE_TEXT[data.detailGradeContent?.content_type_id]}`}
        />

        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5>
                      <ContentIcon id={data.detailGradeContent?.content_type_id} />
                      Grade {data.gradeName} - {CONTENT_TYPE_TEXT[data.detailGradeContent?.content_type_id]}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <form onSubmit={handleSubmit(handler.onSubmitHandler)}>
                      <FormInputContent {...formInputContentProps} />

                      <Keterangan className="mb-4" />
                      <div className="form-group">
                        <BaseButton
                          props={{
                            className: "btn btn-outline-danger mr-2"
                          }}
                          variant='transparent'
                          onClick={handler.onBackHandler}
                        >
                          Kembali
                        </BaseButton>
                        <BaseButton
                          variant='success'
                          type='submit'
                        >
                          Simpan
                        </BaseButton>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}
import React, { useState } from 'react'
import { InfoAudio } from 'component/content-form'

const AssignmentAudio = ({
  handleFile,
  currentFilename,
  isFileTypeInvalid,
  isFileEmpty,
  previewFile,
}) => {
  /* ======================================= Local States ======================================= */
  const [isFileBroken, setIsFileBroken] = useState(false)

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="custom-file">
        <input
          type="file"
          onChange={(event) => handleFile(event.target.files[0], 'audio')}
          className={
            isFileEmpty ? 'custom-file-input is-invalid' : 'custom-file-input'
          }
          id="upload-audio"
          style={{ position: 'absolute' }}
        />
        <label
          className="custom-file-label"
          htmlFor="upload-audio"
          style={{ position: 'relative', width: '100%' }}
        >
          {currentFilename ? currentFilename : 'Pilih file mp3'}
        </label>
        {isFileTypeInvalid ? (
          <div>
            <label className="col small form-text text-danger">
              Type file audio harus mp3.
            </label>
            <InfoAudio />
          </div>
        ) : null}
        {isFileEmpty ? (
          <label className="col small form-text text-danger">
            File tidak boleh kosong.
          </label>
        ) : null}
        {previewFile ? (
          <>
            <p className="mt-3">Preview:</p>
            <audio
              key={previewFile}
              style={{ width: '100%' }}
              controls
              controlsList="nodownload"
              onContextMenu={(event) => event.preventDefault()}
              onError={() => setIsFileBroken(true)}
            >
              {/* <source src={`data:audio/mpeg;base64,${currentFileBase64}`} type="audio/mpeg" /> */}
              <source
                src={`${previewFile}`}
                type="audio/mpeg"
              />
              Your browser does not support the audio tag.
            </audio>
            {isFileBroken ? (
              <label className="col small form-text text-danger">
                File tidak terbaca, pastikan file tidak rusak.
              </label>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  )
}

export default AssignmentAudio

import React, { useEffect, useState } from 'react'
import { useBaseContext } from 'context/base'
import PathwayFormAdd from './add'
import PathwayFormEdit from './edit'

export default function PathwayModal({
  userId,
  classId,
  siswaName,
  classTitle,
  modalEvent,
  setModalEvent,
  pathwayType,
  modalId,
}) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [isUserHasPathway, setIsUserHasPathway] = useState(false)
  const [isAdd, setIsAdd]                       = useState(false)
  const [eventChange, setEventChange]           = useState(null)

  /* ========================================= Functions ======================================== */
  async function getClassPathway() {
    const response = await getRequest(
      `class-pathway?user_id=${userId}&class_id=${classId}&type=${pathwayType}`,
    )
    if (response) {
      if (response.data.length !== 0) {
        setIsUserHasPathway(true)
      } else {
        setIsUserHasPathway(false)
      }
      setEventChange(null)
    } else {
      console.error('ERROR', response)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (modalEvent !== null) {
      if (modalEvent === 'open') {
        getClassPathway()
      }
    }
  }, [modalEvent, userId])

  useEffect(() => {
    if (eventChange === 'create') {
      getClassPathway()
    }
  }, [eventChange])

  /* ========================================== Output ========================================== */
  return (
    <div
      className="modal fade"
      id={modalId}
      data-backdrop="static"
      data-keyboard="false"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myExtraLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <div>
              <p className="mb-0 f-w-700 f-14">Pathway {classTitle}</p>
              <p className="modal-title mb-0 text-secondary">{siswaName}</p>
            </div>
            <button
              type="button"
              className="close text-danger"
              data-dismiss="modal"
              aria-label="Close"
              title="Tutup"
              onClick={() => {
                setIsAdd(false)
                setModalEvent(null)
              }}
            >
              <i className="feather icon-x" />
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              {isUserHasPathway ? (
                <div className="col">
                  <PathwayFormEdit
                    pathwayType={pathwayType}
                    setIsAdd={setIsAdd}
                    classId={classId}
                    userId={userId}
                    setEventChange={setEventChange}
                    modalEvent={modalEvent}
                  />
                </div>
              ) : (
                <>
                  <div className="col-12">
                    <PathwayFormAdd
                      pathwayType={pathwayType}
                      setIsAdd={setIsAdd}
                      classId={classId}
                      userId={userId}
                      setEventChange={setEventChange}
                      modalEvent={modalEvent}
                    />
                  </div>
                  {/* {isAdd ?
                    <>
                    </>
                    :
                    <>
                      <NotFound text="Siswa belum memiliki pathway kelas." />
                      <div className="col-12 d-flex justify-content-center my-2">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={() => setIsAdd(true)}
                        >
                          <i className="fas fa-plus-circle mr-1" />
                          <span>Tambah Pathway</span>
                        </button>
                      </div>
                    </>
                  } */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

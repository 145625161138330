import React, { useState } from 'react'
import CkeditorReadonly from '../ckeditor-readonly'

const Answer = ({ answer, handleChangeAnswer, isPreview = true }) => {
  /* ======================================= Local States ======================================= */
  const [isHover, setIsHover] = useState(false)
  
  /* ========================================== Output ========================================== */
  return (
    <li
      className="d-flex align-items-center mt-2 pl-2 rounded"
      style={{
        position: 'relative',
        backgroundColor: isHover ? 'lightyellow' : 'transparent',
        border: isHover ? '1px solid #e3eaef' : '1px solid transparent',
      }}
    >
      <input
        onChange={() => (isPreview ? {} : handleChangeAnswer(answer))}
        type="radio"
        name={answer.question_id}
        id={answer.id}
        value={answer.text}
      />
      <CkeditorReadonly data={answer.text} />
      <label
        className="rounded"
        htmlFor={answer.id}
        style={{
          position: 'absolute',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          backgroundColor: 'transparent',
          cursor: isHover ? 'pointer' : 'default',
        }}
        onMouseEnter={(e) => setIsHover(true)}
        onMouseLeave={(e) => setIsHover(false)}
      ></label>
    </li>
  )
}

export default Answer

import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'

import useContentPlayerContext, {
  ContentPlayerWrapper,
} from 'context/content-player'
import { QuizEvalWrapper } from 'context/quiz-eval'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import AssignmentUsers from 'component/assignment-users/assignment-users'
import GroupContent from './group/group-content'
import GroupDisabled from './group/group-disabled'
import ReportFeedback from './feedback-siswa/feedback-report'
import FeedbackDisabled from './feedback-siswa/feedback-disabled'
import PreviewContent from './preview-content'
import QuizEval from './quiz-eval/quiz-eval'
import SurveyDashboard from './survey-dashboard'
import RatingMateri from './rating-materi'
import TabPeriksa from './partials/tab-periksa'
import { useHistory, useLocation } from 'react-router-dom'
import useQuery from 'hooks/useQuery'
import GlobalContentDashboardPeriksa from 'component/content/dashboard/global-content-dashboard-periksa'

function ContentDashboardWithoutContext() {
  /* ========================================== Helpers ========================================= */
  const params = useParams()
  const history = useHistory()
  const location = useLocation();
  const query = useQuery();

  /* ====================================== Consume Context ===================================== */
  const { content } = useContentPlayerContext()

  /* ========================================= Constants ======================================== */
  const { contentId, kelasId } = params
  const links = [
    { url: '/kelas', name: 'Sub Mata Pelajaran' },
    { url: `/kelas/${kelasId}`, name: 'Overview' },
  ]

  const tab = query.get('tab');


  /* ======================================= Local States ======================================= */
  const [view, setView] = useState([
    { content: false },
    { feedback: false },
    { group: false },
    { result: false },
    { ['result-survey']: false },
    { rating: false },
  ])

  /* ========================================= Functions ======================================== */
  function handleView(viewKey) {
    let newView = view.map((item) => {
      const name = Object.keys(item)[0]
      if (name === viewKey) {
        return {
          [name]: true,
        }
      } else {
        return {
          [name]: false,
        }
      }
    })
    setView(newView)
    const path = `${location.pathname}?tab=${viewKey}`;
    history.replace(path, { content: { ...location?.state?.content, listSiswaPath: path } })
  }

  function tabActive(tabName) {
    if (tabName === tab) {
      return ['active', 'true'];
    } else {
      return ['', 'false'];
    }
  }

  /* ======================================== Components ======================================== */
  const CurrentView = () => {
    let noView = false
    for (let item of view) {
      if (Object.keys(item)[0] === 'content' && item.content === true) {
        noView = true
        return <PreviewContent />
      } else if (
        Object.keys(item)[0] === 'feedback' &&
        item.feedback === true
      ) {
        noView = true
        return (
          <>
            {content?.feedback ? (
              <ReportFeedback />
            ) : (
              <FeedbackDisabled message="Feedback tidak aktif" />
            )}
          </>
        )
      } else if (Object.keys(item)[0] === 'group' && item.group === true) {
        noView = true
        return (
          <>
            {content?.group_status === true ? (
              <GroupContent />
            ) : (
              <GroupDisabled message="Group Tidak Aktif" />
            )}
          </>
        )
      } else if (Object.keys(item)[0] === 'result' && item.result === true) {
        noView = true

        if (content?.content_type_id === 6) {
          return (
            <QuizEvalWrapper
              quizId={content?.contents_url.quiz_id}
              contentId={contentId}
              kelasId={kelasId}
            >
              <QuizEval />
            </QuizEvalWrapper>
          )
        } else if (content?.content_type_id === 7) {
          return <SurveyDashboard />
        } else if (content?.content_type_id === 8) {
          return (
            <div className="user-profile-list">
              <AssignmentUsers content={content} />
            </div>
          )
        } else if ([1,2,3,4,5,9,10].includes(content?.content_type_id) && content?.is_score) {
          return (
            <div className="user-profile-list">
              <GlobalContentDashboardPeriksa content={content} />
            </div>
          )
        }

        return null
      } else if (Object.keys(item)[0] === 'result-survey' && item?.['result-survey'] === true) {
        noView = true
        return (
          <div className="user-profile-list">
            <GlobalContentDashboardPeriksa content={content} />
          </div>
        )
      } else if (Object.keys(item)[0] === 'rating' && item.rating === true) {
        noView = true
        return <RatingMateri contentId={content?.id} />
      }
    }

    if (noView === false) {
      return null
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    let isSubscribed = true

    if (isSubscribed) {
      if (tab?.length) {
        handleView(tab)
      } else {
        handleView('content')
      }
    }

    return () => (isSubscribed = false)
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName={content?.title || ''}
          linkName="Dashboard Materi"
          links={links}
        />

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h4>{content?.title}</h4>
              </div>
              <div className="card-body">
                <div className="row mb-4">
                  <div className="col">
                    <ul
                      className="nav nav-pills bg-white"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          onClick={() => handleView('content')}
                          className={`nav-link text-uppercase ${tabActive('content')?.[0]}`}
                          data-toggle="tab"
                          href="#materi"
                          role="tab"
                          aria-controls="materi"
                          aria-selected={tabActive('content')?.[1]}
                        >
                          Materi
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          onClick={() => handleView('feedback')}
                          className={`nav-link text-uppercase ${tabActive('feedback')?.[0]}`}
                          data-toggle="tab"
                          href="#feedbackSiswa"
                          role="tab"
                          aria-controls="feedbackSiswa"
                          aria-selected={tabActive('feedback')?.[1]}
                        >
                          Feedback Materi
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          onClick={() => handleView('rating')}
                          className={`nav-link text-uppercase ${tabActive('rating')?.[0]}`}
                          data-toggle="tab"
                          href="#ratingMateri"
                          role="tab"
                          aria-controls="ratingMateri"
                          aria-selected={tabActive('rating')?.[1]}
                        >
                          Rating Materi
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          onClick={() => handleView('group')}
                          className={`nav-link text-uppercase ${tabActive('group')?.[0]}`}
                          data-toggle="tab"
                          href="#groupKecil"
                          role="tab"
                          aria-controls="groupKecil"
                          aria-selected={tabActive('group')?.[1]}
                        >
                          Group Kecil
                        </a>
                      </li>
                      <TabPeriksa
                        content={content}
                        handleView={handleView}
                        tabActive={tabActive}
                      />
                    </ul>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-12">
                    <CurrentView />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <Link
                      to={`/kelas/${kelasId}`}
                      className="btn btn-outline-danger"
                    >
                      <i className="feather icon-chevron-left mr-1" />
                      <span>Kembali</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

const ContentDashboard = () => (
  <ContentPlayerWrapper>
    <ContentDashboardWithoutContext />
  </ContentPlayerWrapper>
)

export default ContentDashboard

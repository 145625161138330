import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import AddDiskusi from './add-diskusi'
import EditDiskusi from './edit-diskusi'
import DetailDiskusi from './detail-diskusi'
import ForumTopicItem from '../forum/forum-topic-item'

export default function ForumDiskusi({ classId }) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, putRequest, deleteRequest } = useBaseContext()

  /* =================================== Consume Local Storage ================================== */
  const { role_type } = JSON.parse(localStorage.getItem('role_active'));
  const { id } = JSON.parse(localStorage.getItem('user'));

  /* ========================================= Constants ======================================== */
  const participants = [{ type: 'mata-pelajaran', value_id: classId }]

  /* ======================================= Local States ======================================= */
  const [forums, setForums]         = useState([])
  const [forumId, setForumId]       = useState('')
  const [showDetail, setShowDetail] = useState(false)
  const [forumEdit, setForumEdit]   = useState(null)

  /* ========================================= Functions ======================================== */
  async function getForum() {
    const response = await getRequest(`forum?class_id=${classId}`)
    if (response) {
      if (response.data.length !== 0) {
        setForums(response.data)
      }
    }
  }

  function handleDetail(forumId) {
    setForumId(forumId)
    setShowDetail(true)
  }

  function handleCloseDetail() {
    setShowDetail(false)
    setForumId('')
  }

  function handleDeleteTopic(id) {
    Swal.fire({
      title: 'Apa anda yakin?',
      text: "Anda akan menghapus topik ini!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus',
      cancelButtonText: 'Batal',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest('forum/' + id);
        if (response) {
          Swal.fire(
            'Berhasil',
            'Topik berhasil dihapus.',
            'success'
          ).then(async () => await getForum())
        } else {
          Swal.fire(
            'Oppsss!',
            'Terjadi kesalahan coba beberapa saat lagi.',
            'error'
          )
        }
      }
    })
  }

  async function handlePinForum(item, pinned) {
    let form = new FormData()
    form.append('title', item.title)
    form.append('description', item.description)
    form.append('participants', JSON.stringify(participants))
    form.append('content_id', classId)
    form.append('pinned', pinned)
    form.append('id', item.id)

    const response = await putRequest('forum', form)
    if (response) {
      window.notification("Berhasil", pinned === 1 ? "Topik berhasil ditandai" : "Tanda berhasil dihapus", 'success')
      await getForum()
    } else {
      window.notification("Terjadi kesalahan", "Coba beberapa saat lagi", 'error')
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getForum()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div className='col row mx-0 px-0'>
      {showDetail ?
        <div className='col-12'>
          <div className='d-flex justify-content-end mb-3'>
            <button onClick={handleCloseDetail} className='btn btn-outline-info' type='button'>
              <i className='fas fa-comments mr-1' />
              Daftar Topik
            </button>
          </div>
          <hr />
          <DetailDiskusi forumId={forumId} />
        </div>
        :
        <div className='col-12'>
          <button className='btn btn-primary' type='button' data-toggle="modal" data-target="#addForumModal">
            <i className='feather icon-plus-circle mr-1' />
            Buat Topik Baru
          </button>
          <hr />
          <AddDiskusi classId={classId} getForum={getForum} />
          <EditDiskusi classId={classId} getForum={getForum} forumEdit={forumEdit} setForumEdit={setForumEdit} />
          {forums.length !== 0 ?
            <div>
              {forums.map((item) => {
                let dd = moment(item.createdAt).add(30, "days").valueOf();
                let date = dd < moment().valueOf() ? moment(item.createdAt).format("DD MMM YYYY") : moment(item.createdAt).fromNow()
                return (
                  <ForumTopicItem 
                    key={item.id}
                    item={item}
                    date={date}
                    roleType={role_type}
                    userId={id}
                    handlePinTopic={handlePinForum}
                    handleDetailTopic={handleDetail}
                    handleEditTopic={setForumEdit}
                    handleDeleteTopic={handleDeleteTopic}
                  />
                )
              })}
            </div>
            :
            <div className="d-flex flex-column justify-content-center mt-4">
              <i
                className="fas fa-comments f-60 text-center mb-3"
                style={{
                  color: "#ccc"
                }}
              />
              <h6
                className="text-center"
                style={{
                  color: "#bbb"
                }}
              >Belum ada topik diskusi</h6>
            </div>
          }
        </div>
      }
    </div>
  );
}

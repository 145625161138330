import React from 'react'
import RenderBadgesItem from './RenderBadgesItem'

const RenderBadge = ({ item }) => {
  return (
    <fieldset className="mb-2 p-2 col-sm-12">
      <div className="row costumize-header m-0 justify-content-between p-2">
        <h5 className="m-0 p-2">{item.desc.toUpperCase()}</h5>
      </div>
      <div className="row justify-content-left p-3 mb-2">
        {item.badges.length !== 0
          ? item.badges.map((badge, idx) => (
              <RenderBadgesItem
                item={badge}
                key={idx}
              />
            ))
          : null}
      </div>
    </fieldset>
  )
}

export default RenderBadge

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DetailAssignmentModal from './detail-modal'
import ModalMetaKelola from 'component/modal/meta-kelola'
import DataTableWithFilter from 'component/data-table/indexWithFilter'
import useGetData from 'hooks/useGetData'

const KelolaAssignment = () => {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, deleteRequest } = useBaseContext()
  const { subMapel } = useGetData({ getListSubMapel: true })

  /* ======================================= Local States ======================================= */
  const [assignmentList, setAssignmentList] = useState([])
  const [assignmentLength, setAssignmentLength] = useState(0)
  const [assignmentSelected, setAssignmentSelected] = useState(null)
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [search, setSearch] = useState('')
  const [subMapelReactSelectOptions, setSubMapelReactSelectOptions] = useState([])
  const [selectedSubMapel, setSelectedSubMapel] = useState({})

  const [detailAssignment, setDetailAssignment] = useState(null)

  /* ========================================= Functions ======================================== */
  async function getAssignmentList() {
    const filterSubMapel = selectedSubMapel?.value ? `&class_id=${selectedSubMapel?.value}` : ''

    const response = await getRequest(
      `assignment?limit=${limit}&offset=${offset}&search=${search}${filterSubMapel}`,
    )
    if (response) {
      setAssignmentList(response.data)
      setAssignmentLength(response.totalRecord)
    } else {
      console.error('ERROR', response)
    }
  }

  function handleAddAssignment() {
    history.push('/kelola-assignment/add')
  }

  function handleEditAssignment(assignmentData) {
    history.push(`/kelola-assignment/edit/${assignmentData.id}`, {
      existingData: assignmentData,
    })
  }

  function handleDeleteAssignment(assignmentId, assignmentTitle) {
    Swal.fire({
      title: 'Apa kamu yakin?',
      text: `Kamu akan menghapus ${assignmentTitle}!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(
          `assignment?id=${assignmentId}`,
        )
        if (response) {
          Swal.fire({
            icon: 'success',
            text: 'Assignment berhasil dihapus.',
          }).then(() => history.go(0))
        } else {
          Swal.fire(
            'Oppsss!',
            'Terjadi kesalahan coba beberapa saat lagi.',
            'error',
          )
        }
      }
    })
  }

  const manipulatedListSubMapelReactSelectOptionsHandler = () => {
    const data = subMapel.data.map(s => ({ label: s?.title, value: s?.id }))

    setSubMapelReactSelectOptions(data);
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getAssignmentList()
  }, [limit, offset, search, selectedSubMapel])

  useEffect(() => {
    if (!subMapel.loading && subMapel.data.length && !subMapelReactSelectOptions.length) {
      manipulatedListSubMapelReactSelectOptionsHandler()
    }
  }, [subMapel])

  /* ======================================== Components ======================================== */
  const RenderItem = ({
    item,
    handleEditAssignment,
    handleDeleteAssignment,
  }) => {
    return (
      <tr>
        <td>{item.title ? item.title : ''}</td>
        <td>
          {item.active ? (
            <span className="badge badge-success">Aktif</span>
          ) : (
            <span className="badge badge-secondary">Tidak Aktif</span>
          )}
        </td>
        <td>
          {item.reply_type === '1' ? (
            <div>
              <i className="fas fa-file-alt" />
              <span className="ml-1">Teks</span>
            </div>
          ) : null}
          {item.reply_type === '2' ? (
            <div>
              <i className="fas fa-file-audio" />
              <span className="ml-1">Audio</span>
            </div>
          ) : null}
          {item.reply_type === '3' ? (
            <div>
              <i className="fas fa-file-video" />
              <span className="ml-1">Video</span>
            </div>
          ) : null}
          {item.reply_type === '4' ? (
            <div>
              <i className="fas fa-file" />
              <span className="ml-1">Dokumen</span>
            </div>
          ) : null}
          {item.reply_type === '5' ? (
            <div>
              <i className="fab fa-youtube" />
              <span className="ml-1">Youtube</span>
            </div>
          ) : null}
          {item.reply_type === '6' ? (
            <div>
              <i className="fas fa-link" />
              <span className="ml-1">Url</span>
            </div>
          ) : null}
        </td>
        <td>
          {item.type_score ? (
            <>
              {item.type_score.active ? (
                <span>{item.type_score.type_score}</span>
              ) : (
                <span>Tidak aktif</span>
              )}
            </>
          ) : (
            <span>Tidak aktif</span>
          )}
        </td>
        <td>
          <button
            onClick={() => setAssignmentSelected(item)}
            type="button"
            className="btn btn-sm mr-2 btn-dark"
            data-toggle="modal"
            data-target="#kelola-meta-modal"
          >
            <i className="feather icon-info mr-1" />
            Info
          </button>
          <button
            onClick={() => handleEditAssignment(item)}
            type="button"
            className="btn btn-sm btn-success mr-2"
            data-toggle="modal"
            data-target="#quiz-edit-modal"
          >
            <i className="feather icon-edit mr-1" />
            <span>Edit</span>
          </button>
          <button
            type="button"
            className="btn btn-sm btn-warning mr-2"
            data-toggle="modal"
            data-target="#detail-assignment-modal"
            onClick={() => setDetailAssignment(item)}
          >
            <i className="fas fa-eye mr-1" />
            <span>Preview</span>
          </button>
          <button
            type="button"
            className="btn btn-sm btn-info mr-2"
            onClick={() => history.push(`/kelola-assignment/${item.id}`)}
          >
            <i className="feather icon-check-square mr-1" />
            <span>Periksa</span>
          </button>
          <button
            onClick={() => handleDeleteAssignment(item.id, item.title)}
            type="button"
            title="Hapus"
            className="btn btn-sm btn-danger"
          >
            <i className="feather icon-trash-2 mr-1" />
            <span>Hapus</span>
          </button>
        </td>
      </tr>
    )
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Kelola Tugas" />
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header">
                <div className="row">
                  <div className="col">
                    <button
                      onClick={() => handleAddAssignment()}
                      type="button"
                      className="btn btn-primary"
                    >
                      <i className="feather icon-plus-circle mr-1" />
                      <span>Tambah Tugas</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <>
                  <div className="row">
                    <div className="col dt-responsive table-responsive">
                      <DataTableWithFilter
                        className={
                          'table table-striped table-bordered nowrap'
                        }
                        data={assignmentList}
                        totalRows={assignmentLength}
                        show={[10, 20, 30, 40, 50]}
                        onChangeSearch={(val) => {
                          setSearch(val)
                        }}
                        onChangeLimit={(val) => {
                          setLimit(val)
                        }}
                        onChangePage={(val) => {
                          setOffset(val)
                        }}
                        column={[
                          'Nama Tugas',
                          'Status',
                          'Jenis Jawaban',
                          'Jenis Penilaian',
                          'Actions',
                        ]}
                        renderItem={(assignment) => (
                          <RenderItem
                            item={assignment}
                            key={assignment.id}
                            handleEditAssignment={handleEditAssignment}
                            handleDeleteAssignment={handleDeleteAssignment}
                          />
                        )}
                        filter1={{
                          options: subMapelReactSelectOptions,
                          placeholder: 'Filter submapel...',
                          onChange: (choice) => setSelectedSubMapel(choice),
                          isClearable: true,
                        }}
                      />
                    </div>
                  </div>
                </>

                <DetailAssignmentModal
                  dataAssignment={detailAssignment}
                  setDetailAssignment={setDetailAssignment}
                />
              </div>
            </div>
          </div>
        </div>

        <ModalMetaKelola
          type='tugas'
          title={assignmentSelected?.title}
          id={assignmentSelected?.id}
        />
      </Container>
    </TemplateAdmin>
  )
}

export default KelolaAssignment

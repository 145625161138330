import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'
import ScoresRecapV2 from './scores-recap-v2'
import Ledger from 'pages/scores/tabContent/Ledger'
import { avatarDefaultUser } from 'util/constant'

export default function Scores() {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()
  
  /* ======================================= Local States ======================================= */
  const [users, setUsers]             = useState([])
  const [limit, setLimit]             = useState(10)
  const [offset, setOffset]           = useState(0)
  const [search, setSearch]           = useState('')
  const [totalRecord, setTotalRecord] = useState(0)

  /* =========================================== Refs =========================================== */
  let mounted = useRef(true)

  /* ========================================= Functions ======================================== */
  const reset = () => {
    setLimit(10)
    setOffset(0)
    setSearch('')
    setTotalRecord(0)
  }
  async function getUsers() {
    const response = await getRequest(
      `siswa-all?limit=${limit}&offset=${offset}&search=${search}`,
    )
    if (response) {
      if (mounted.current === true) {
        setUsers(response.data)
        setTotalRecord(response.totalRow)
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getUsers()
  }, [limit, offset, search])

  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])
  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Nilai Siswa" />

        <div className="card">
          <div className="card-body">
            <ul
              className="nav nav-tabs mb-3"
              id="tabScores"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className="nav-link active text-uppercase"
                  id="scoreIndividuTab"
                  data-toggle="tab"
                  href="#scoreIndividu"
                  role="tab"
                  aria-controls="scoreIndividu"
                  aria-selected="true"
                >
                  Nilai Individu
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-uppercase"
                  id="scoreRecapTab"
                  data-toggle="tab"
                  href="#scoreRecap"
                  role="tab"
                  aria-controls="scoreRecap"
                  aria-selected="false"
                >
                  Rekap Nilai
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-uppercase"
                  id="ledgerTab"
                  data-toggle="tab"
                  href="#ledger"
                  role="tab"
                  aria-controls="ledger"
                  aria-selected="false"
                >
                  Ledger
                </a>
              </li>
            </ul>
            <div
              className="tab-content"
              id="tabScoresContent"
            >
              <div
                className="tab-pane fade show active user-profile-list"
                id="scoreIndividu"
                role="tabpanel"
                aria-labelledby="scoreIndividuTab"
              >
                <Users
                  users={users}
                  totalRecords={totalRecord}
                  setLimit={setLimit}
                  setOffset={setOffset}
                  setSearch={setSearch}
                />
              </div>
              <div
                className="tab-pane fade"
                id="scoreRecap"
                role="tabpanel"
                aria-labelledby="scoreRecapTab"
              >
                <ScoresRecapV2 />
              </div>
              <div
                className="tab-pane fade"
                id="ledger"
                role="tabpanel"
                aria-labelledby="ledgerTab"
              >
                <Ledger
                  users={users}
                  totalRecords={totalRecord}
                  setLimit={setLimit}
                  setOffset={setOffset}
                  setSearch={setSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

const Users = ({ users, totalRecords, setSearch, setLimit, setOffset }) => {
  return (
    <div className="dt-responsive table-responsive">
      <DataTable
        className={'table table-striped table-bordered nowrap'}
        data={users}
        totalRows={totalRecords}
        show={[10, 20, 30, 40, 50]}
        onChangeSearch={(val) => {
          setSearch(val)
        }}
        onChangeLimit={(val) => {
          setLimit(val)
        }}
        onChangePage={(val) => {
          setOffset(val)
        }}
        column={['nama', 'no. induk', 'actions']}
        renderItem={(item) => (
          <UserItem
            user={item}
            key={item.id}
          />
        )}
      />
    </div>
  )
}

const UserItem = ({ user }) => {
  const { id, name, nim, avatar, linkAvatar } = user
  return (
    <tr>
      <td>
        <div className="d-inline-block align-middle">
          <img
            src={avatar ? `${linkAvatar}` : avatarDefaultUser}
            alt={name}
            className="img-radius align-top m-r-15"
            style={{ width: '40px', height: '40px' }}
            onError={(event) => {
              event.currentTarget.src = avatarDefaultUser
            }}
          />
          <div className="d-inline-block">
            <h6 className="m-b-0 mt-2">{name}</h6>
          </div>
        </div>
      </td>
      <td>{nim}</td>
      <td>
        <Link to={`/scores/${id}`}>
          <button
            type="button"
            className="btn btn-sm btn-primary"
          >
            <i className="fas fa-book mr-2" />
            Lihat Nilai
          </button>
        </Link>
      </td>
    </tr>
  )
}

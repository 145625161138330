import React, { useState, useRef, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { Back, Submit } from 'component/button'
import { FormSwitch } from 'component/forms'
import useEditContent from './hook'
import {
  FormInputName,
  FormInputUrutan,
  FormSelectSection,
  FormSelectQuiz,
  FormSelectPrecontent,
  FormInputTanggal,
  FormActiveStatus,
  FormSelectFeedback,
  FormGroupKecil,
  FormActiveKey,
  FormInputDesc,
  FormInputEndedAt,
  FormTextArea,
  FormCheckTypeRapor,
  FormSelectStudent,
} from 'component/content-form'
import { getUsersContentSpesificStudent } from 'util/array'

const EditContentQuiz = () => {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const location = useLocation()
  const { contentId, kelasId } = useParams()

  /* ======================================= Consume Hook ======================================= */
  const { getContentTakeAway } = useEditContent()
  
  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const links = [
    { url: '/kelas', name: 'Sub Mata Pelajaran' },
    { url: `/kelas/${location.state.kelasId}`, name: 'Overview' },
  ]
  const { sectionList, existingData } = location.state
  const existingContentsUrl = existingData.contents_url
  const contentType = 6

  /* ======================================= Local States ======================================= */
  const [isContentTakeAwayExist, setIsContentTakeAwayExist] = useState(false)

  // FORM INPUT STATES
  const [existingContents, setExistingContents] = useState([])
  const [quizList, setQuizList]                 = useState([])
  const [title, setTitle]                       = useState(existingData?.title || '')
  const [urutan, setUrutan]                     = useState(existingData?.urutan || '')
  const [sectionId, setSectionId]               = useState(existingData?.section_id || '')
  const [preContentId, setPrecontentId]         = useState(existingData?.precontent_id || '')
  const [status, setStatus]                     = useState(existingData?.active ? 1 : 0)
  const [displayAt, setDisplayAt]               = useState(existingData?.display_at || '',)
  const [quizId, setQuizId]                     = useState(null)
  const [durasi, setDurasi]                     = useState(existingData?.durasi ? existingData.durasi / 60 : '')
  const [feeds, setFeeds]                       = useState([])
  const [feedId, setFeedId]                     = useState(null)
  const [isFeedActive, setIsFeedActive]         = useState(0)
  const [enableGroup, setEnableGroup]           = useState(!!existingData?.group_status)
  const [maxGroup, setMaxGroup]                 = useState(existingData?.group_max || '')
  const [descGroup, setDescGroup]               = useState(existingData?.group_description || '')
  const [endedAt, setEndedAt]                   = useState(existingData?.ended_at || '')
  const [description, setDescription]           = useState(existingData?.deskripsi || '')
  const [apresiasi, setApresiasi]               = useState(existingData?.apresiasi || '')
  const [rating, setRating]                     = useState(existingData?.rating ? 1 : 0)
  const [isPengetahuan, setIsPengetahuan]       = useState(!!existingData?.is_pengetahuan)
  const [isKeterampilan, setIsKeterampilan]     = useState(!!existingData?.is_keterampilan)
  const [selectedStudents, setSelectedStudents] = useState(() => getUsersContentSpesificStudent(existingData))

  // FORM VALIDATION STATES
  const [isTitleInvalid, setIsTitleInvalid]     = useState(false)
  const [isUrutanInvalid, setIsUrutanInvalid]   = useState(false)
  const [isSectionInvalid, setIsSectionInvalid] = useState(false)
  const [isQuizInvalid, setIsQuizInvalid]       = useState(false)
  const [isMaxGroupValid, setIsMaxGroupValid]   = useState(true)
  const [loadingSubmit, setLoadingSubmit]       = useState(false)

  const [enableGroupKey, setEnableGroupKey] = useState(false)
  const [prerequisite, setPrerequisite]     = useState('')

  /* =========================================== Refs =========================================== */
  const refInputTitle    = useRef()
  const refSelectSection = useRef()
  const refInputUrutan   = useRef()
  const refSelectQuiz    = useRef()
  const refMaxGroup      = useRef()

  /* ========================================= Functions ======================================== */
  function getExistingContentsBySelectedSection() {
    if (!sectionId) {
      setExistingContents([])
    } else {
      sectionList.forEach((section) => {
        if (section.id === sectionId) {
          setExistingContents(section.contents)
        }
      })
    }
  }

  function handleChangeEnableGroupKey() {
    if (enableGroupKey) {
      setEnableGroupKey(false)
    } else {
      setEnableGroupKey(true)
    }
  }

  async function getQuizList() {
    const response = await getRequest('quiz')
    if (response) {
      const options = response.data.map((item) => ({
        value: item.id,
        label: item.title,
      }))
      setQuizList(options)
      initSelectedQuiz(options)
    }
  }

  function initSelectedQuiz(quizList) {
    for (let quiz of quizList) {
      if (quiz.value === existingContentsUrl.quiz_id) {
        setQuizId({ value: quiz.value, label: quiz.label })
        break
      }
    }
  }

  async function getFeedsList() {
    const response = await getRequest('feed')
    if (response) {
      const options = response.data.map((item) => ({
        value: item.id,
        label: item.description,
      }))
      setFeeds(options)
      initSelectedFeed(options)
    }
  }

  function initSelectedFeed(feedList) {
    for (let feed of feedList) {
      if (feed.value === existingData.feedback) {
        setFeedId({ value: feed.value, label: feed.label })
        break
      }
    }
  }

  function handleChangeTitle(event) {
    setTitle(event.target.value)
  }

  function handleChangeUrutan(event) {
    setUrutan(Math.abs(event.target.value))
  }

  function handleChangeSection(event) {
    setSectionId(event.target.value)
  }

  function handleChangePrecontent(event) {
    setPrecontentId(event.target.value)
  }

  function handleChangeDate(event) {
    setDisplayAt(event.target.value)
  }

  function handleChangeStatus() {
    if (status === 1) {
      setStatus(0)
    }
    if (status === 0) {
      setStatus(1)
    }
  }

  function handleChangeQuiz(val) {
    setQuizId(val)
  }

  function handleChangeFeedback(val) {
    setFeedId(val)
  }

  function handleChangeEnableGroup() {
    if (enableGroup) {
      setEnableGroup(false)
      setMaxGroup(existingData.group_max ? existingData.group_max : '')
      setIsMaxGroupValid(true)
    } else {
      setEnableGroup(true)
      setMaxGroup(existingData.group_max ? existingData.group_max : '')
      if (existingData.group_max) {
        setIsMaxGroupValid(true)
      } else {
        setIsMaxGroupValid(false)
      }
    }
  }

  function handleChangeMaxGroup(event) {
    if (event.target.value) {
      const absolute = Math.abs(event.target.value)
      const integer = Math.round(absolute)
      setMaxGroup(integer)
      if (integer <= 0) {
        setIsMaxGroupValid(false)
      } else {
        setIsMaxGroupValid(true)
      }
    } else {
      setMaxGroup('')
      setIsMaxGroupValid(false)
    }
  }

  function handleChangeDescGroup(event) {
    setDescGroup(event.target.value)
  }

  function handleChangeDesc(event) {
    setDescription(event.target.value)
  }

  function handleChangeEndedAt(event) {
    setEndedAt(event.target.value)
  }

  function handleChangeApresiasi(event) {
    setApresiasi(event.target.value)
  }

  function handleChangeRating(value) {
    setRating(value ? 1 : 0)
  }

  function handleChangeIsPengetahuan(event) {
    setIsPengetahuan(event.target.checked)
  }

  function handleChangeIsKeterampilan(event) {
    setIsKeterampilan(event.target.checked)
  }

  function handleFormValidation() {
    const durasiDetik = durasi * 60
    const feedback = isFeedActive === 1 ? feedId : ''

    if (title && urutan && sectionId && quizId && isMaxGroupValid) {
      const contentsUrl = { quiz_id: quizId.value }
      setIsTitleInvalid(false)
      setIsSectionInvalid(false)
      setIsUrutanInvalid(false)
      setIsQuizInvalid(false)
      handleFormSubmit(
        contentId,
        title,
        contentType,
        sectionId,
        urutan,
        status,
        displayAt,
        preContentId,
        contentsUrl,
        durasiDetik,
        feedback.value,
        maxGroup,
        descGroup,
        endedAt,
        description,
        apresiasi,
        rating,
        isPengetahuan,
        isKeterampilan,
      )
    } else {
      if (!isMaxGroupValid) {
        if (refMaxGroup) {
          refMaxGroup.current.focus()
        }
      }

      if (!sectionId) {
        setIsSectionInvalid(true)
        refSelectSection.current.focus()
      } else {
        setIsSectionInvalid(false)
      }

      if (!urutan) {
        setIsUrutanInvalid(true)
        refInputUrutan.current.focus()
      } else {
        setIsUrutanInvalid(false)
      }

      if (!quizId) {
        setIsQuizInvalid(true)
        refSelectQuiz.current.focus()
      } else {
        setIsQuizInvalid(false)
      }

      if (!title) {
        setIsTitleInvalid(true)
        refInputTitle.current.focus()
      } else {
        setIsTitleInvalid(false)
      }

      window.notification('Mohon', 'lengkapi data')
    }
  }

  async function handleFormSubmit(
    contentId,
    title,
    contentType,
    sectionId,
    urutan,
    active,
    displayAt,
    preContentId,
    contentsUrl,
    durasi,
    feedId,
    maxGroup,
    descGroup,
    endedAt,
    description,
    apresiasi,
    rating,
    isPengetahuan,
    isKeterampilan,
  ) {
    let form = new FormData()
    form.append('id', contentId)
    form.append('title', title)
    form.append('content_type', contentType)
    form.append('section_id', sectionId)
    form.append('urutan', urutan)
    form.append('active', active)
    form.append('contents_url', JSON.stringify(contentsUrl))
    form.append('rating', rating)
    form.append('precontent_id', preContentId)
    form.append('display_at', displayAt)
    form.append('ended_at', endedAt)
    form.append('deskripsi', description)
    form.append('apresiasi', apresiasi)
    form.append('is_pengetahuan', isPengetahuan)
    form.append('is_keterampilan', isKeterampilan)

    if (Array.isArray(selectedStudents) && !!selectedStudents.length) {
      selectedStudents.forEach((selectedStudent, selectedStudentIndex) => {
        form.append(`spesifik_user_id[${selectedStudentIndex}]`, selectedStudent.id)
      })
    }

    if (feedId) {
      form.append('feedback', feedId)
    } else {
      form.append('feedback', '')
    }

    if (enableGroup) {
      form.append('group_status', 1)
    } else {
      form.append('group_status', 0)
    }

    if (maxGroup) {
      form.append('group_max', maxGroup)
    }

    if (descGroup) {
      form.append('group_description', descGroup)
    }

    setLoadingSubmit(true)

    const response = await putRequest('contents', form)
    if (response) {
      if (enableGroupKey) {
        let formkey = new FormData()
        formkey.append('description', prerequisite)
        formkey.append('content_id', contentId)

        const response1 = isContentTakeAwayExist
          ? await putRequest('contentTakeAway', formkey)
          : await postRequest('contentTakeAway', formkey)

        if (response1) {
          window.notification('Materi', 'berhasil diubah', 'success')
          history.goBack()
        }
      } else {
        window.notification('Materi', 'berhasil diubah', 'success')
        history.goBack()
      }
    }
  }

  useEffect(() => {
    getExistingContentsBySelectedSection()
  }, [sectionId])

  useEffect(() => {
    getContentTakeAway(contentId).then((res) => {
      if (res.data) {
        setPrerequisite(res.data.description)
        setEnableGroupKey(true)
        setIsContentTakeAwayExist(true)
      }
    })

    getQuizList()
    getFeedsList()
  }, [])

  useEffect(() => {
    if (existingData.feedback) {
      setIsFeedActive(1)
    }
  }, [existingData.feedback])

  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Edit Quiz"
          linkName="Quiz"
          links={links}
        />
        
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <h5>
                  <i className="fas fa-question-circle mr-1" />
                  Informasi Quiz
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <form>
                      {/* Input Title */}
                      <FormInputName
                        label="Nama Quiz"
                        placeholder="Nama Quiz"
                        namaKonten={title}
                        isNamaInvalid={isTitleInvalid}
                        handleChangeName={handleChangeTitle}
                        refInputName={refInputTitle}
                      />

                      {/* Select Quiz */}
                      <FormSelectQuiz
                        existingQuiz={quizList}
                        handleChangeQuiz={handleChangeQuiz}
                        isQuizInvalid={isQuizInvalid}
                        refSelectQuiz={refSelectQuiz}
                        existingQuizId={quizId}
                        getQuizList={getQuizList}
                      />

                      <FormCheckTypeRapor
                        handleChangeIsPengetahuan={handleChangeIsPengetahuan}
                        handleChangeIsKeterampilan={handleChangeIsKeterampilan}
                        isPengetahuan={isPengetahuan}
                        isKeterampilan={isKeterampilan}
                      />

                      {/* Input Urutan */}
                      <FormInputUrutan
                        urutan={urutan}
                        handleChangeUrutan={handleChangeUrutan}
                        isUrutanInvalid={isUrutanInvalid}
                        refInputUrutan={refInputUrutan}
                      />

                      {/* Input deskripsi */}
                      <FormInputDesc
                        description={description}
                        handleChangeDesc={handleChangeDesc}
                      />

                      {/* Input Apresiasi */}
                      <FormTextArea
                        id="formInputApresiasi"
                        label="Apresiasi"
                        placeholder="Apresiasi/pesan setelah siswa menyelesaikan materi (opsional)"
                        className="mb-5"
                        value={apresiasi}
                        handleChange={handleChangeApresiasi}
                      />

                      {/* Select section */}
                      <FormSelectSection
                        sections={sectionList}
                        handleChangeSection={handleChangeSection}
                        isSectionInvalid={isSectionInvalid}
                        existingSectionId={sectionId}
                        refSelectSection={refSelectSection}
                      />

                      {/* Select Precontent */}
                      <FormSelectPrecontent
                        existingContents={existingContents}
                        handleChangePrecontent={handleChangePrecontent}
                        existingContentId={contentId}
                        existingPrecontentId={preContentId}
                      />

                      {/* Input display at */}
                      <FormInputTanggal
                        handleChangeDate={handleChangeDate}
                        existingDisplayAt={displayAt}
                      />

                      {/* Input ended at */}
                      <FormInputEndedAt
                        endedAt={endedAt}
                        handleChangeEndedAt={handleChangeEndedAt}
                      />

                      {/* Rating */}
                      <FormSwitch
                        id="formRating"
                        label="Rating Materi"
                        value={rating}
                        handleChange={handleChangeRating}
                      />

                      {/* Select feedback */}
                      <FormSelectFeedback
                        existingFeeds={feeds}
                        existingFeedId={feedId}
                        isFeedActive={isFeedActive}
                        setIsFeedActive={setIsFeedActive}
                        handleChangeFeeds={handleChangeFeedback}
                        getFeedList={getFeedsList}
                      />

                      {/* Swicth Group Kecil */}
                      <FormGroupKecil
                        enableGroup={enableGroup}
                        handleChangeEnableGroup={handleChangeEnableGroup}
                        maxGroup={maxGroup}
                        descGroup={descGroup}
                        handleChangeMaxGroup={handleChangeMaxGroup}
                        handleChangeDescGroup={handleChangeDescGroup}
                        isMaxGroupValid={isMaxGroupValid}
                        refMaxGroup={refMaxGroup}
                      />

                      {/* Select Specific Students */}
                      <FormSelectStudent 
                        selectedStudents={selectedStudents}
                        setSelectedStudents={setSelectedStudents}
                        subMapelId={kelasId}
                        originalSelectedStudents={getUsersContentSpesificStudent(existingData)}
                        isDirectSave={false}
                        isWithFooter={false}
                        listSelectedStudentsProps={{
                          listStudentsActions: ['rollback', 'clear-all'],
                        }}
                      />

                      {/* Select Status */}
                      <FormActiveStatus
                        existingStatus={status}
                        handleChangeStatus={handleChangeStatus}
                      />

                      <FormActiveKey
                        enableGroupKey={enableGroupKey}
                        handleChangeEnableGroupKey={handleChangeEnableGroupKey}
                        prerequisite={prerequisite}
                        setPrerequisite={setPrerequisite}
                      />

                      {/* Form button  */}
                      <div className="form-group">
                        <Back />
                        {loadingSubmit ? (
                          <button
                            disabled={true}
                            type="button"
                            className="btn btn-success mr-2"
                          >
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                            />
                            <span>Simpan</span>
                          </button>
                        ) : (
                          <Submit
                            onClick={() => handleFormValidation()}
                            text="Simpan"
                          />
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

export default EditContentQuiz

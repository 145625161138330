import React, { useEffect, useState, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ReactSelect from 'react-select'
import moment from 'moment'
import Swal from 'sweetalert2'
import _ from 'lodash'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import SubmitButton from 'component/button/Submit'

export default function VideoConferenceAdd() {
  /* ========================================== Helpers ========================================= */
  const param   = useParams()
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [arrayProfile, setArrayProfile]    = useState([])
  const [topik, setTopik]                  = useState('')
  const [agenda, setAgenda]                = useState('')
  const [waktu, setWaktu]                  = useState('')
  const [password, setPassword]            = useState('')
  const [status, setStatus]                = useState(null)
  const [group, setGroup]                  = useState([])
  const [durasi, setDurasi]                = useState('')
  const [peserta, setPeserta]              = useState('')
  const [formValidate, setFormValidate]    = useState([])
  const [showGrup, setShowGrup]            = useState(false)
  const [loadingSubmit, setLoadingSubmit]  = useState(false)
  const [listProfile, setListProfile]      = useState([])
  const [groupByProfile, setGrupByProfile] = useState([])
  const [isLoadingGetGroupByProfile, setIsLoadingGetGroupByProfile] = useState(false)
  const [idProfile, setIdProfile]          = useState('')
  const [profileName, setProfileName]      = useState('')
  const [idProf, setIdProf]                = useState('')
  const [alert, setAlert]                  = useState(null)

  /* ========================================= Constants ======================================== */
  const validation = {
    topik    : ['required'],
    password : ['required'],
    status   : ['required'],
    waktu    : ['required'],
    durasi   : ['required'],
  }

  /* ========================================= Functions ======================================== */
  const getGroupAllProfile = useCallback(async () => {
    try {
      setIsLoadingGetGroupByProfile(true)
      let response = await getRequest(
        'all-group-profile?profile_id=' + idProf,
      )
      if (response) {
        let arr = []
        response.data.map((item) => {
          let arr1 = []
          item.data.map((itm) => arr1.push({ value: itm.id, label: itm.group }))
          arr.push({ label: item.key, options: arr1 })
        })
        setGrupByProfile(arr)
        setIsLoadingGetGroupByProfile(false)
      } else {
        setIsLoadingGetGroupByProfile(false)
      }
    } catch (error) {
      setIsLoadingGetGroupByProfile(false)
    }
  }, [idProf])
  
  async function getDataConference() {
    let response = await getRequest(`webinars?id=${param.id}`)
    if (response) {
      var data = response.data
      const dateTime = moment(data.start_time).format('YYYY-MM-DDTHH:mm:ss')
      setTopik(data.topic)
      setAgenda(data.agenda)
      setWaktu(dateTime)
      setPassword(data.password)
      setStatus(data.active ? '1' : '0')
      setDurasi(data.duration)
      setShowGrup(data.group_id.length > 0)
      setPeserta(data.group_id.length > 0 ? '1' : '0')
      if (data.group_id.length > 0) {
        let arr = []
        data.group_id.map((ii) => arr.push({ value: ii.id, label: ii.name }))
        setGroup(arr)
      }
      if (response.share) {
        let arr = []
        response.share.map((i) => {
          let arr2 = []
          i.group.map((ii) => arr2.push({ value: ii.id, label: ii.name }))
          arr.push({
            profile_id: { profile_id: i.profile_id, profile_name: i.profile },
            status: i.status,
            group: arr2,
          })
        })
        setArrayProfile(arr)
      }
    }
  }

  async function getProfile() {
    let res = await getRequest('get-profile-roles')
    if (res) {
      setListProfile(res.data)
    }
  }

  async function simpan() {
    var validate = await validationForm()
    setFormValidate(validate)
    if (isObject(validate)) {
      if (arrayProfile.length > 0) {
        var form = new FormData()
        if (param.id) {
          form.append('id', param.id)
        }
        let arr = []
        let arr1 = []
        arrayProfile.map((i) => {
          let newGroup = i.group.map((i) => i.value)
          arr.push({
            profile_id: i.profile_id.profile_id,
            status: i.status,
            group: newGroup,
          })
        })
        group.map((i) => arr1.push(i.value))

        form.append('share', JSON.stringify(arr))
        form.append('topic', topik)
        form.append('agenda', agenda)
        form.append('type', 2)
        form.append('start_time', waktu)
        form.append('duration', durasi)
        form.append('password', password)
        form.append('group_id', [])

        form.append('active', status)
        var response
        if (param.id) {
          setLoadingSubmit(true)
          response = await putRequest('webinars', form)
        } else {
          setLoadingSubmit(true)
          response = await postRequest('webinars', form)
        }

        if (response) {
          history.push('/video-conference')
          window.notification('Conference', 'berhasil disimpan', 'success')
        }

        setLoadingSubmit(false)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Profile',
          text: 'Tambahkan Profile terlebih dahulu dengan menekan tombol tambah!',
        })
      }
    }
  }

  function isObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }

  function validationForm() {
    var frm = {}
    for (const [key, value] of Object.entries(validation)) {
      var a = eval(key)
      value.map((val) => {
        if (val == 'required') {
          var h = required(a)
          if (!h) {
            frm[key] = `${key} harus diisi`
          }
        } else if (val == 'only-letter') {
          if (!h) {
            frm[key] = `${key} hanya dibolehkan huruf`
          }
        }
      })
    }
    return frm
  }

  function required(value) {
    if (value) {
      if (typeof value === 'string') {
        var a = value.trim()
        if (a == '') {
          return false
        }
        return true
      } else {
        return true
      }
    }
    return false
  }

  function onlyLetter(value) {
    var letters = /^[A-Za-z]+$/
    if (value.match(letters)) {
      return true
    } else {
      return false
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (idProf && showGrup) {
      getGroupAllProfile()
    }
  }, [getGroupAllProfile, idProf, showGrup])

  useEffect(() => {
    if (param.id) {
      getDataConference()
    }
    getProfile()
    getGroupAllProfile()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName={`${param.id ? 'Edit' : 'Tambah'} Conference`}
          links={[{ url: '/video-conference', name: 'Video Conference' }]}
        />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list  ">
              <div className="card-header d-flex justify-content-between">
                <h5>Informasi Conference</h5>
              </div>
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row mb-4">
                        <label
                          className="col-sm-3 col-form-label"
                          htmlFor="nama-conference"
                        >
                          Topik
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            value={topik}
                            onChange={(event) => {
                              setTopik(event.target.value)
                            }}
                            className={
                              formValidate.topik
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                            id="nama-conference"
                            placeholder="Input topik conference"
                          />
                          <label className="col small form-text text-danger">
                            {formValidate.topik}
                          </label>
                        </div>
                      </div>
                      <div className="form-group row mb-4">
                        <label
                          className="col-sm-3 col-form-label"
                          htmlFor="nama-conference"
                        >
                          Agenda
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            value={agenda}
                            onChange={(event) => {
                              setAgenda(event.target.value)
                            }}
                            className="form-control"
                            id="nama-conference"
                            placeholder="Input agenda conference"
                          />
                        </div>
                      </div>
                      <div className="form-group row mb-4">
                        <label
                          className="col-sm-3 col-form-label"
                          htmlFor="waktu-mulai"
                        >
                          Waktu mulai
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="datetime-local"
                            value={waktu}
                            onChange={(event) => setWaktu(event.target.value)}
                            className={
                              formValidate.waktu
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                            id="waktu-mulai"
                          />
                          <label className="col small form-text text-danger">
                            {formValidate.waktu}
                          </label>
                        </div>
                      </div>
                      <div className="form-group row mb-4">
                        <label
                          className="col-sm-3 col-form-label"
                          htmlFor="pesan-conference"
                        >
                          Password
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            maxLength={10}
                            value={password}
                            onChange={(event) =>
                              setPassword(event.target.value)
                            }
                            className={
                              formValidate.password
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                          />
                          <label className="col small form-text text-danger">
                            {formValidate.password}
                          </label>
                        </div>
                      </div>
                      <div className="form-group row mb-4">
                        <label
                          className="col-sm-3 col-form-label"
                          htmlFor="pesan-conference"
                        >
                          Status
                        </label>
                        <div className="col-sm-9">
                          <select
                            name=""
                            value={status}
                            onChange={(event) => setStatus(event.target.value)}
                            className={
                              formValidate.status
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                          >
                            <option value="">- Status -</option>
                            <option value="1">Aktif</option>
                            <option value="0">Tidak Aktif</option>
                          </select>
                          <label className="col small form-text text-danger">
                            {formValidate.status}
                          </label>
                        </div>
                      </div>

                      <div className="form-group row mb-4">
                        <label
                          className="col-sm-3 col-form-label"
                          htmlFor="durasi-conference"
                        >
                          Durasi
                        </label>
                        <div className="col-sm-3">
                          <input
                            type="number"
                            className="form-control"
                            max={120}
                            value={durasi}
                            placeholder="Dalam menit"
                            onChange={(e) => {
                              if (e.target.value) {
                                setDurasi(Math.abs(e.target.value))
                              } else {
                                setDurasi('')
                              }
                            }}
                          />
                        </div>
                        <label className="col-sm-3 col-form-label">menit</label>
                        <div className="offset-sm-3 col-12">
                          <label className="small form-text text-danger">
                            {formValidate.durasi}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card p-3 rounded">
                        <div className="form-group row mb-4">
                          <label
                            className="col-sm-3 col-form-label"
                            htmlFor="profile-conference"
                          >
                            Profile
                          </label>
                          <div className="col-sm-9">
                            <select
                              name=""
                              value={JSON.stringify(idProfile)}
                              className={'form-control'}
                              onChange={(e) => {
                                setIdProfile(JSON.parse(e.target.value))
                                setIdProf(JSON.parse(e.target.value).profile_id)
                                setGroup([])
                              }}
                            >
                              <option
                                value={JSON.stringify({
                                  profile_id: null,
                                  profile_name: '- Pilih Profile -',
                                })}
                              >
                                - Pilih Profile -
                              </option>
                              {listProfile.length > 0 &&
                                listProfile.map((item, indx) => (
                                  <option
                                    key={indx}
                                    value={JSON.stringify({
                                      profile_id: item.id,
                                      profile_name: item.name,
                                    })}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div className="form-group row mb-4">
                          <label
                            className="col-sm-3 col-form-label"
                            htmlFor="durasi-conference"
                          >
                            Peserta
                          </label>
                          <div className="col-sm-9">
                            <select
                              disabled={!idProf}
                              name=""
                              value={peserta}
                              onChange={(e) => {
                                if (e.target.value == '1') {
                                  setShowGrup(true)
                                } else {
                                  setShowGrup(false)
                                  setGroup([])
                                }
                                setPeserta(e.target.value)
                              }}
                              className="form-control"
                            >
                              <option value="">- Pilih peserta -</option>
                              <option value={'0'}>Umum</option>
                              <option value={'1'}>Kelas/Group</option>
                            </select>
                          </div>
                        </div>
                        {showGrup && idProf ? (
                          <div className="form-group row mb-4">
                            <label
                              className="col-sm-3 col-form-label"
                              htmlFor="durasi-conference"
                            >
                              Kelas/Group
                            </label>
                            <div className="col-sm-9">
                              <ReactSelect
                                isDisabled={isLoadingGetGroupByProfile}
                                isLoading={isLoadingGetGroupByProfile}
                                options={groupByProfile}
                                isMulti={true}
                                placeholder="-- pilih group --"
                                value={group}
                                onChange={(e) => setGroup(e)}
                              />
                            </div>
                          </div>
                        ) : null}

                        <div className="d-flex justify-content-end">
                          <button
                            className="btn btn-outline-danger mr-2"
                            type="button"
                            onClick={() => {
                              setIdProfile('')
                              setPeserta('0')
                              setGroup([])
                              setShowGrup(false)
                            }}
                          >
                            Reset
                          </button>
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => {
                              if (!idProfile) {
                                setAlert(
                                  <div className="text-center">
                                    Harap <strong>Pilih Profile</strong> dulu!
                                    🙏
                                  </div>,
                                )
                                return
                              } else if (peserta === '1' && !group.length) {
                                setAlert(
                                  <div className="text-center">
                                    Mohon tambahkan minimal{' '}
                                    <strong>satu kelas/group</strong>! 🙏
                                  </div>,
                                )
                                return
                              }
                              setAlert(null)
                              let obj = {
                                profile_id: idProfile,
                                status: peserta,
                                group: group,
                                profile_name: profileName,
                              }
                              let arr = [].concat(arrayProfile)
                              const existProfile = arr.find(
                                (x) =>
                                  x.profile_id.profile_id ===
                                  idProfile.profile_id,
                              )
                              const existProfileIdx = arr.findIndex(
                                (x) =>
                                  x.profile_id.profile_id ===
                                  idProfile.profile_id,
                              )

                              if (!existProfile) {
                                arr.push(obj)
                                setArrayProfile(arr)
                              } else {
                                const isDeepEqual = _(group)
                                  .differenceWith(existProfile.group, _.isEqual)
                                  .isEmpty()
                                if (
                                  existProfile.status === '1' &&
                                  peserta === '0'
                                ) {
                                  arr[existProfileIdx] = obj
                                  setArrayProfile(arr)
                                } else if (
                                  existProfile.status === '0' ||
                                  isDeepEqual
                                ) {
                                  setAlert(
                                    <div className="text-center">
                                      <strong>
                                        Perhatian! {idProfile.profile_name}
                                      </strong>{' '}
                                      sudah ada di list! 🙏
                                    </div>,
                                  )
                                } else {
                                  group.forEach((g) => {
                                    const existInArr = existProfile.group.find(
                                      (x) => x.value === g.value,
                                    )
                                    if (!existInArr) existProfile.group.push(g)
                                  })
                                  setArrayProfile(arr)
                                }
                              }
                              setIdProf('')
                              setIdProfile('')
                              setPeserta('')
                              setGroup([])
                              setShowGrup(false)
                            }}
                          >
                            Tambah
                          </button>
                        </div>
                      </div>
                      {alert && (
                        <div
                          className="alert alert-block alert-danger alert-dismissible fade show m-2"
                          role="alert"
                        >
                          {alert}
                        </div>
                      )}
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Profile</th>
                              <th scope="col">Peserta</th>
                              <th scope="col">Kelas/Grup</th>
                              <th scope="col">Aksi</th>
                            </tr>
                          </thead>
                          <tbody>
                            {arrayProfile.length > 0 ? (
                              arrayProfile.map((item, key) => (
                                <tr key={key}>
                                  <td>{item.profile_id.profile_name}</td>
                                  <td>
                                    {item.status == 0 ? 'Umum' : 'Kelas/Grup'}
                                  </td>
                                  <td>
                                    {item.status == 1 ? (
                                      <ul>
                                        {item.group.map((ii, idx) => (
                                          <li key={idx}>{ii.label}</li>
                                        ))}
                                      </ul>
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() => {
                                        let arr = [].concat(arrayProfile)
                                        arr.splice(key, 1)
                                        setArrayProfile(arr)
                                      }}
                                    >
                                      <i className="fas fa-minus" />
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={4}
                                  className="text-center"
                                >
                                  zoom tidak dishare
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <button
                          type="button"
                          onClick={() => history.push('/video-conference')}
                          className="btn btn-outline-danger mr-2"
                        >
                          Kembali
                        </button>
                        {loadingSubmit ? (
                          <button
                            disabled={true}
                            type="button"
                            className="btn btn-success"
                          >
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                            />
                            <span>Simpan</span>
                          </button>
                        ) : (
                          <SubmitButton
                            onClick={() => simpan()}
                            text="Simpan"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

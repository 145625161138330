import React from 'react'

const KelasTestCompletion = () => (
  <div className="card">
    <div className="card-body">
      <div className="row">
        <div className="col-md-6 d-flex flex-column my-3">
          <div
            id="chart-pie-kelas"
            style={{ width: '100%', height: '400px' }}
          />
          <div className="d-flex justify-content-around mt-3">
            <div className="text-center">
              <i className="fas fa-book-open h1 text-muted" />
              <h6 className="my-1 h2 text-primary">7</h6>
              <p className="mb-0 h4 text-muted">Kelas</p>
            </div>
            <div className="text-center">
              <i className="fas fa-check-square h1 text-muted" />
              <h6 className="my-1 h2 text-primary">0</h6>
              <p className="mb-0 h4 text-muted">Selesai</p>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex flex-column my-3">
          <div
            id="chart-pie-test"
            style={{ width: '100%', height: '400px' }}
          />
          <div className="d-flex justify-content-around mt-3">
            <div className="text-center">
              <i className="fas fa-pencil-alt h1 text-muted" />
              <h6 className="my-1 h2 text-primary">5</h6>
              <p className="mb-0 h4 text-muted">Test dikerjakan</p>
            </div>
            <div className="text-center">
              <i className="fas fa-percent h1 text-muted" />
              <h6 className="my-1 h2 text-primary">7.5</h6>
              <p className="mb-0 h4 text-muted">Nilai rata-rata</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default KelasTestCompletion

import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import BackButton from 'component/button/Back'
import SubmitButton from 'component/button/Submit'

export default function ProfileSuperAdminAdd() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [name, setName]                       = useState('')
  const [domain, setDomain]                   = useState('')
  const [email, setEmail]                     = useState('')
  const [username, setUsername]               = useState('')
  const [password, setPassword]               = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  // Validation States
  const [isValidDomain, setValidDomain]                     = useState(false)
  const [isValidName, setValidName]                         = useState(false)
  const [isValidEmail, setValidEmail]                       = useState(false)
  const [isCheckEmail, setCheckEmail]                       = useState(false)
  const [isCheckPassword, setCheckPassword]                 = useState(false)
  const [isLengthPassword, setLengthPassword]               = useState(false)
  const [isLengthConfirmPassword, setLengthConfirmPassword] = useState(false)
  const [isValidUsername, setValidUsername]                 = useState(false)
  const [isValidPassword, setValidPassword]                 = useState(false)
  const [isValidConfirmPassword, setValidConfirmPassword]   = useState(false)

  /* ========================================= Functions ======================================== */
  async function submit(e) {
    if (
      domain &&
      name &&
      email &&
      username &&
      password &&
      confirmPassword &&
      isCheckEmail == false &&
      isCheckPassword == false &&
      isLengthPassword == false &&
      isLengthConfirmPassword == false
    ) {
      var form = new FormData()
      form.append('name', domain)
      form.append(
        'users',
        JSON.stringify({
          name: name,
          email: email,
          username: username,
          password: password,
        }),
      )
      let response = await postRequest('profile', form)
      if (response) {
        window.notification(
          'Success !',
          'Data profile berhasil di tambahkan',
          'success',
        )
        history.push('profiles')
      } else {
        console.error('ERROR', response)
        window.notification(
          'Terjadi kesalahan',
          'Coba beberapa saat lagi',
          'error',
        )
      }
    } else {
      if (!domain) {
        setValidDomain(true)
      }
      if (!name) {
        setValidName(true)
      }
      if (!email) {
        setValidEmail(true)
      }
      if (!username) {
        setValidUsername(true)
      }
      if (!password) {
        setValidPassword(true)
      }
      if (!confirmPassword) {
        setValidConfirmPassword(true)
      }
      window.notification(
        'Terjadi kesalahan',
        'Mohon lengkapi form data',
        'error',
      )
    }
  }

  function checkEmail(email) {
    var regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    setEmail(email)
    if (email.match(regex) == null) {
      setCheckEmail(true)
    } else {
      setCheckEmail(false)
    }
  }

  function checkPassword(pw) {
    setPassword(pw)
    if (pw.length >= 6 || pw == '') {
      setLengthPassword(false)
    } else {
      setLengthPassword(true)
    }
  }

  function checkConfirmPassword(pw) {
    setConfirmPassword(pw)
    if (pw.length >= 6 || pw == '') {
      setLengthConfirmPassword(false)
      if (pw == password || pw == '') {
        setCheckPassword(false)
      } else {
        setCheckPassword(true)
      }
    } else {
      setLengthConfirmPassword(true)
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Tambah Profile"
          links={[{ url: '/profiles', name: 'Profile' }]}
        />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Tambah Profile</h5>
              </div>
              <div className="card-body">
                <form id="validation-form123">
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <div className="form-group row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Profile
                        </label>
                        <div className="col-sm-9">
                          <input
                            value={domain}
                            onChange={(val) => setDomain(val.target.value)}
                            type="text"
                            className={`form-control ${
                              isValidDomain && domain == '' ? 'is-invalid' : ''
                            }`}
                            placeholder="Profile"
                          />
                        </div>
                      </div>
                      <div className="form-group row mb-4">
                        <label className="col-sm-3 col-form-label">Nama</label>
                        <div className="col-sm-9">
                          <input
                            value={name}
                            onChange={(val) => setName(val.target.value)}
                            type="text"
                            className={`form-control ${
                              isValidName && name == '' ? 'is-invalid' : ''
                            }`}
                            placeholder="Nama"
                          />
                        </div>
                      </div>
                      <div className="form-group row mb-4">
                        <label className="col-sm-3 col-form-label">Email</label>
                        <div className="col-sm-9">
                          <input
                            value={email}
                            onChange={(val) => checkEmail(val.target.value)}
                            type="email"
                            className={`form-control ${
                              isValidEmail && email == '' ? 'is-invalid' : ''
                            }`}
                            placeholder="Email"
                          />
                          {isCheckEmail ? (
                            <label
                              className=""
                              style={{ color: 'red', fontSize: 12 }}
                            >
                              Email Anda tidak valid!
                            </label>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Username
                        </label>
                        <div className="col-sm-9">
                          <input
                            value={username}
                            onChange={(val) => setUsername(val.target.value)}
                            type="text"
                            className={`form-control ${
                              isValidUsername && username == ''
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Username"
                          />
                        </div>
                      </div>
                      <div className="form-group row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Password
                        </label>
                        <div className="col-sm-9">
                          <input
                            value={password}
                            onChange={(val) => checkPassword(val.target.value)}
                            type="password"
                            className={`form-control ${
                              isValidPassword && password == ''
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Password"
                          />
                          {isLengthPassword ? (
                            <label
                              className=""
                              style={{ color: 'red', fontSize: 12 }}
                            >
                              Password minimal memiliki 6 karakter
                            </label>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Konfirmasi Password
                        </label>
                        <div className="col-sm-9">
                          <input
                            value={confirmPassword}
                            onChange={(val) =>
                              checkConfirmPassword(val.target.value)
                            }
                            type="password"
                            className={`form-control ${
                              isValidConfirmPassword && confirmPassword == ''
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Konfirmasi Password"
                          />
                          {isLengthConfirmPassword ? (
                            <label
                              className=""
                              style={{ color: 'red', fontSize: 12 }}
                            >
                              Password minimal memiliki 6 karakter
                            </label>
                          ) : isCheckPassword ? (
                            <label
                              className=""
                              style={{ color: 'red', fontSize: 12 }}
                            >
                              Password Anda tidak sama!
                            </label>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">{/* <Dropzone /> */}</div>
                  </div>
                  <div className="row ml-1">
                    <BackButton />
                    <SubmitButton
                      onClick={() => submit()}
                      text="Simpan"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

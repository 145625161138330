import React, { useState } from 'react'
import CkeditorReadonly from 'component/ckeditor-readonly'
import './question.css'

const Answer = ({
  answerFind,
  answer,
  handleChangeAnswer,
  readOnly = false,
}) => {
  /* ======================================= Local States ======================================= */
  const [isHover, setIsHover] = useState(false)
  const preventKeydownArrowKeyAnswer = (e) => {
    if (e?.keyCode === 37 || e?.keyCode === 38 || e?.keyCode === 39 || e?.keyCode === 40) {
      e?.preventDefault();
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <>
      {readOnly ? (
        <li
          className="d-flex mb-2 pl-2 pb-2 rounded"
          style={{
            position: 'relative',
            backgroundColor:
              answerFind === answer?.id ? 'lightyellow' : 'transparent',
            border:
              answerFind === answer?.id
                ? '1px solid #e3eaef'
                : '1px solid transparent',
          }}
        >
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              checked={answerFind === answer?.id}
              disabled={true}
              name={answer?.question_id}
              id={answer?.id}
              value={answer?.text}
            />
            <CkeditorReadonly
              className="form-check-label"
              data={answer?.text}
            />
          </div>
          <label
            className="rounded"
            htmlFor={answer?.id}
            style={{
              position: 'absolute',
              top: '0',
              bottom: '0',
              left: '0',
              right: '0',
              backgroundColor: 'transparent',
              cursor: 'default',
            }}
          ></label>
        </li>
      ) : (
        <li
          className="d-flex pl-2 pb-2 rounded"
          style={{
            position: 'relative',
            backgroundColor: isHover ? 'lightyellow' : 'transparent',
            border: isHover ? '1px solid #e3eaef' : '1px solid transparent',
          }}
        >
          {
            <>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  defaultChecked={answerFind === answer?.id}
                  onChange={handleChangeAnswer}
                  name={answer?.question_id}
                  id={answer?.id}
                  value={answer?.id}
                  onKeyDown={preventKeydownArrowKeyAnswer}
                />
                <CkeditorReadonly
                  className="form-check-label"
                  data={answer?.text}
                />
                <label
                  className="rounded"
                  htmlFor={answer?.id}
                  style={{
                    position: 'absolute',
                    top: '0',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    backgroundColor: 'transparent',
                    cursor: isHover ? 'pointer' : 'default',
                  }}
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                ></label>
              </div>
            </>
          }
        </li>
      )}
    </>
  )
}

export default Answer

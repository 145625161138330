import { createContext, useContext, useEffect, useState } from 'react'
import { useBaseContext } from 'context/base'
import useContentPlayerContext from 'context/content-player'
import useMainContent from 'context/main-content'

const FeedbackFormContext = createContext()

export function FeedbackFormWrapper({ children }) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest }        = useBaseContext()
  const { content, activity } = useContentPlayerContext()
  const { shouldSendCCA }     = useMainContent()

  /* ============================================================================================ */
  /*                                             FEED                                             */
  /* ============================================================================================ */
  /* ========================================== States ========================================== */
  const [feed, setFeed]           = useState(null)
  const [eventFeed, setEventFeed] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  /* ========================================= Functions ======================================== */
  async function getFeed() {
    setIsLoading(true)
    const response = await getRequest(
      `feed?feed_id=${content.feedback}&content_id=${content.id}`,
    )
    setIsLoading(false)
    if (response) {
      activity.markFeedback(response.data)
      setFeed(response.data)
      setEventFeed('')
    } else {
      console.error('ERROR', response)
    }
  }

  function reportEvent(contentId) {
    shouldSendCCA && activity.setIdDone(contentId)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    content.feedback && content.id && getFeed()
  }, [content.feedback, content.id])

  useEffect(() => {
    eventFeed === 'submit-feed' && getFeed()
  }, [eventFeed])

  /* ======================================= Shared Props ======================================= */
  const sharedProps = {
    event: {
      set     : setEventFeed,
      loading : isLoading,
      report  : reportEvent,
    },
    feed: {
      data      : feed,
      type      : feed?.feed_type,
      temps     : feed?.feed_temps,
      responses : feed?.feed_respons,
    },
  }

  /* ====================================== Return Provider ===================================== */
  return (
    <FeedbackFormContext.Provider value={sharedProps}>
      {children}
    </FeedbackFormContext.Provider>
  )
}

/* ========================================= Return Hook ======================================== */
export default function useFeedbackForm() {
  return useContext(FeedbackFormContext)
}

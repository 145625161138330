import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { shuffle } from 'lodash'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'
import EvaluasiContentList from './evaluasi-content-list'

export default function Evaluasi() {
  /* ========================================== Helpers ========================================= */
  const params = useParams()
  const graphorId = params.graphorId

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/graphic-organizer', name: 'Graphic Organizer' }]

  /* ======================================= Local States ======================================= */
  const [users, setUsers]           = useState([])
  const [totalUsers, setTotalUsers] = useState(0)
  const [graphor, setGraphor]       = useState(null)
  const [limit, setLimit]           = useState(10)
  const [offset, setOffset]         = useState(0)
  const [search, setSearch]         = useState('')

  // Evaluasi Per Content
  const [user, setUser]               = useState(null)
  const [contents, setContents]       = useState([])
  const [realAnswers, setRealAnswers] = useState([])

  /* ========================================= Functions ======================================== */
  async function getUsers() {
    const response = await getRequest(
      `graphor-answers-teacher?graphor_id=${graphorId}&limit=${limit}&offset=${offset}&search=${search}`,
    )
    if (response) {
      setUsers(response.data)
      setTotalUsers(response.totalRecords)
      setGraphor(response.graphor)
    }
  }

  async function getContentList() {
    const response = await getRequest(
      `graphor-answers-teacher?graphor_id=${graphorId}&list_content=true`,
    )
    if (response) {
      setContents(response.data)
    }
  }

  async function getGraphor() {
    const response = await getRequest('graphors?id=' + graphorId)
    if (response) {      
      if (response.data.graphor_type == 1) {
        var dt = []
        var s = shuffle(response.answer)
        s.map((val) => {
          dt.push({
            text: val,
          })
        })
        setRealAnswers(response.answer)
      }
    }
  }

  async function handleModalUser(user) {
    setUser(user)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getGraphor()
    getContentList()
  }, [])

  useEffect(() => {
    getUsers()
  }, [limit, offset, search])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName={graphor?.description || ''}
          linkName="Evaluasi"
          links={links}
        />

        <div className="card user-profile-list  ">
          <div className="card-body dt-responsive table-responsive">
            <DataTable
              className={'table table-striped table-bordered nowrap'}
              data={users}
              totalRows={totalUsers}
              show={[10, 20, 30, 40, 50]}
              onChangeSearch={(val) => {
                setSearch(val)
              }}
              onChangeLimit={(val) => {
                setLimit(val)
              }}
              onChangePage={(val) => {
                setOffset(val)
              }}
              column={['Siswa', 'No. Induk', 'Actions']}
              renderItem={(item, index) => (
                <RenderItem
                  item={item}
                  key={index}
                  handleModalUser={handleModalUser}
                />
              )}
            />
          </div>
          <EvaluasiContentList
            user={user}
            contents={contents}
            answers={realAnswers}
          />
        </div>
      </Container>
    </TemplateAdmin>
  )
}

/* ========================================= Components ========================================= */
const RenderItem = ({ item, handleModalUser }) => {
  return (
    <tr>
      <td>{item.name}</td>
      <td>{item.nim}</td>
      <td>
        <button
          onClick={() => handleModalUser(item)}
          className="btn btn-sm btn-info"
          data-toggle="modal"
          data-target="#evaluasiList"
        >
          <i className="feather icon-check-square mr-1" />
          <span>Periksa</span>
        </button>
      </td>
    </tr>
  )
}

import React from 'react'

const FormStatusKomentarTugas = ({
  status,
  handleChange,
  komentarWajib,
  handleChangeKomentarWajib,
}) => (
  <div className="form-group row mb-5">
    <label className="col-sm-3 col-form-label">Komentar Tugas</label>
    <div className="form-group col-sm-9">
      <div className="switch switch-primary d-inline m-r-10">
        <input
          onChange={() => handleChange()}
          checked={status === 1}
          type="checkbox"
          id="komentarTugasSwitch"
        />
        <label
          htmlFor="komentarTugasSwitch"
          className="cr"
        ></label>
      </div>
      <label htmlFor="komentarTugasSwitch">
        {status === 1 ? 'Aktif' : 'Tidak aktif'}
      </label>
    </div>
    {status === 1 ? (
      <div className="offset-3 col-9 row pl-0">
        <label
          htmlFor="minimalKomentar"
          className="col-sm-3 col-form-label"
        >
          Wajib Komentar sebanyak
        </label>
        <div className="col-sm-3">
          <input
            id="minimalKomentar"
            className="form-control"
            type="number"
            placeholder="Komentar wajib"
            value={komentarWajib}
            onChange={handleChangeKomentarWajib}
          />
        </div>
      </div>
    ) : null}
  </div>
)

export default FormStatusKomentarTugas

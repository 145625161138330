import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import Swal from 'sweetalert2'
import usePreviewRapor from 'context/preview-rapor'

export default function Buttons() {
  /* ====================================== Consume Context ===================================== */
  const { rapor } = usePreviewRapor()

  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ========================================= Constants ======================================== */
  const roleActive = JSON.parse(localStorage.getItem('role_active'))?.role_type

  /* ======================================= Local States ======================================= */
  const [approved, setApproved] = useState(false)

  /* ========================================= Functions ======================================== */
  const handleApproveBtn = async () => {
    const confirm = await Swal.fire({
      icon: 'warning',
      title: `Approve Rapor?`,
      confirmButtonText: 'Ya!',
      cancelButtonText: 'Tidak',
      showCancelButton: true,
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
    })

    if (confirm.isConfirmed) {
      let res = await rapor.approve()
      if (res) {
        await Swal.fire({
          icon: 'success',
          title: `Rapor telah disetujui`,
        })
        history.push(`/input-rapor-block-all`)
      }
    }
  }

  const handleApproveOrtuBtn = async () => {
    const confirm = await Swal.fire({
      icon: 'warning',
      title: `Anda akan menyetujui rapor ini, apakah Anda yakin?`,
      confirmButtonText: 'Ya!',
      cancelButtonText: 'Tidak',
      showCancelButton: true,
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
    })

    if (confirm.isConfirmed) {
      let res = await rapor.approveOrtu()
      if (res) {
        await Swal.fire({
          icon: 'success',
          title: `Rapor berhasil disetujui`,
        }).then(() => location.reload())
      }
    }
  }

  const handleRejectBtn = async () => {
    const confirm = await Swal.fire({
      icon: 'warning',
      title: `Tolak Rapor?`,
      confirmButtonText: 'Ya, tolak!',
      confirmButtonColor: '#ff5252',
      cancelButtonText: 'Tidak',
      showCancelButton: true,
      input: 'textarea',
      inputPlaceholder: 'Catatan untuk Walikelas...',
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
    })
    if (confirm.isConfirmed) {
      let res = await rapor.reject(confirm.value)
      if (res) {
        await Swal.fire({
          icon: 'success',
          title: `Rapor telah ditolak`,
        })
        history.push(`/input-rapor-block-all`)
      }
    }
  }

  const handleUbahBtn = () => {
    history.push(
      '/input-rapor-block-all/' +
        rapor.user?.id +
        '?period_id=' +
        rapor.period.data?.id +
        '&semester=' +
        rapor.period.data?.semester_code,
    )
  }

  const handleAjukanBtn = async () => {
    const confirm = await Swal.fire({
      icon: 'warning',
      title: `Ajukan Rapor?`,
      confirmButtonText: 'Ya!',
      cancelButtonText: 'Tidak',
      showCancelButton: true,
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
    })

    if (confirm.isConfirmed) {
      let res = await rapor.submit()
      if (res) {
        await Swal.fire({
          icon: 'success',
          title: `Rapor telah diajukan`,
        })
        setApproved(true)
        // history.push(`/input-rapor-block-all`)
      }
    }
  }

  const isOrtuNotYetApproved = !rapor.period?.transaction?.ortu

  /* ========================================== Output ========================================== */
  return (
    <div className="col-12 d-flex justify-content-center">
      {['administrator', 'kepalasekolah', 'adminprofile'].includes(roleActive) && (
        <>
          <button
            className="btn btn-danger"
            disabled={
              !['approved', 'submission'].includes(
                rapor.period.transaction?.status,
              )
            }
            onClick={handleRejectBtn}
          >
            <i
              className="fa fa-times mr-2"
              aria-hidden="true"
            ></i>
            Tolak Rapor
          </button>
          <button
            disabled={rapor.period.transaction?.status !== 'submission'}
            className="btn btn-success"
            onClick={handleApproveBtn}
          >
            <i
              className="fa fa-check mr-2"
              aria-hidden="true"
            ></i>
            Approve Rapor
          </button>
        </>
      )}

      {['instruktur'].includes(roleActive) && rapor.res?.isWaliKelas && (
        <>
          <button
            className="btn btn-info"
            disabled={['approved'].includes(rapor.period.transaction?.status)}
            onClick={handleUbahBtn}
          >
            <i
              className="fa fa-pencil-alt mr-2"
              aria-hidden="true"
            />
            Ubah Rapor
          </button>
          <button
            disabled={!['progress'].includes(rapor.period.transaction?.status)}
            className="btn btn-success"
            onClick={handleAjukanBtn}
          >
            <i
              className="fas fa-check mr-2"
              aria-hidden="true"
            />
            Ajukan Rapor
          </button>
        </>
      )}

      {
        rapor.period.transaction?.status === 'approved'
        && roleActive === 'orangtua'
        && isOrtuNotYetApproved
        ?
          <button
            className="btn btn-success mr-3"
            onClick={handleApproveOrtuBtn}
          >
            <i
              className="fa fa-check mr-2"
              aria-hidden="true"
            ></i>
            Setujui
          </button>
        :null
      }

      {((!['siswa'].includes(roleActive) && rapor.res?.isWaliKelas) ||
        ['approved'].includes(rapor.period.transaction?.status) ||
        approved) && (
        <ReactToPrint
          copyStyles={true}
          trigger={() => (
            <button className="btn btn-primary">
              <i
                className="fa fa-print mr-2"
                aria-hidden="true"
              ></i>
              Print
            </button>
          )}
          content={() => rapor.printEl.current}
        />
      )}
    </div>
  )
}

import Linkify from "linkify-react"

export default function BaseLinkify({ children }) {
  const options = {
    className: 'custom-linkify',
    target: '_blank'
  };
  return (
    <div className="formatted-text">
      <Linkify options={options}>
        {children}
      </Linkify>
    </div>
  );
}
import moment from 'moment/moment'
import React from 'react'
import CkeditorReadonly from 'component/ckeditor-readonly'
import NotFound from 'component/not-found'

export default function PersonalNotes({ content }) {
  return content?.content_user_personal_notes?.length ? (
    content?.content_user_personal_notes?.map((personal_notes, index_pn) => (
      <div
        key={`notes_map_lv3_` + index_pn}
        className="accordion-collapse collapse show"
      >
        <div
          className="accordion-body mr-2 ml-2"
          key={index_pn}
        >
          <div
            className="card text-dark mb-2"
            style={styles.card}
          >
            <div
              className="card-header"
              style={styles.header}
            >
              Catatan {++index_pn}
            </div>
            <div className="card-body">
              <CkeditorReadonly data={personal_notes.note} />
              <p className="text-right f-w-700 f-12">
                <i className="fas fa-calendar-alt mr-1" />
                {moment(personal_notes.updatedAt).format('dddd, DD MMMM YYYY')}
              </p>
            </div>
          </div>
        </div>
      </div>
    ))
  ) : (
    <div className="accordion-collapse collapse show">
      <div className="accordion-body p-5">
        <NotFound
          text={'Tidak ada catatan'}
          icon="fas fa-file"
        />
      </div>
    </div>
  )
}

/* =========================================== Styles =========================================== */
const styles = {
  card: {
    border: '1px solid #4680fe',
  },
  header: {
    paddingTop    : '10px',
    paddingBottom : '10px',
    borderBottom  : '1px solid #4680fe',
  }
}

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/id'
import 'datatables.net'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'
import { openUrlNewTab } from 'util/global'

export default function VideoConferenceSiswa() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [limit, setLimit]               = useState(10)
  const [offset, setOffset]             = useState(0)
  const [search, setSearch]             = useState('')
  const [data, setData]                 = useState([])
  const [totalRecords, setTotalRecords] = useState(0)

  /* ========================================= Constants ======================================== */
  const currentDateTime = Date.now()

  /* ========================================= Functions ======================================== */
  async function getData() {
    const response = await getRequest(
      `webinars-siswa?limit=${limit}&offset=${offset}&search=${search}`,
    )
    if (response) {
      setData(response.data)
      setTotalRecords(response.totalRecords)
    }
  }

  async function joinMeeting(id) {
    const response = await getRequest('join-meeting?meeting_id=' + id)
    if (response) {
      history.push('zoom-player', { token: response.data.token })
    }
  }

  async function joinViaUrl(id) {
    const response = await getRequest('join-meeting?meeting_id=' + id)
    if (response) {
      if (response.join_url) {
        openUrlNewTab(response.join_url)
      }
    }
  }

  const RenderItem = ({ item }) => {
    const duration = item.duration * 60 * 1000
    const webinarTime = new Date(item.start_time).getTime()
    const webinarDuration = duration + webinarTime
    
    return (
      <tr>
        <td title={item.topic}>
          {webinarDuration < currentDateTime && (
            <span className="badge badge-secondary mr-1">Selesai</span>
          )}
          {item.topic.slice(0, 25)}
          {item.topic.length > 25 ? '...' : ''}
        </td>
        <td title={item.agenda}>
          {item.agenda ? item.agenda.slice(0, 25) : '-'}
          {item.agenda.length > 25 ? '...' : ''}
        </td>
        <td>
          {moment(item.start_time).format('dddd, DD/MM/YYYY HH:mm')}
        </td>
        <td>{item.duration + ' menit'}</td>
        <td>{item.password}</td>
        <td>{item.group ? item.group.group : 'Umum'}</td>
        <td>
          {item.active &&
          item.zoom_status &&
          webinarDuration >= currentDateTime ? (
            <>
              <button
                type="button"
                onClick={() => joinMeeting(item.id)}
                className="btn btn-sm btn-info mr-2"
              >
                <i className="feather icon-video mr-1" />
                Join
              </button>
              <button
                type="button"
                onClick={() => joinViaUrl(item.id)}
                className="btn btn-sm btn-primary"
              >
                <i className="feather icon-video mr-1" />
                Join via zoom
              </button>
            </>
          ) : (
            <span className="badge badge-secondary">Tidak Aktif</span>
          )}
        </td>
      </tr>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getData()
  }, [limit, offset, search])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Video Konferensi Siswa" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-body">
                <div className="dt-responsive table-responsive">
                  <DataTable
                    className={'table table-striped table-bordered nowrap'}
                    data={data}
                    totalRows={totalRecords}
                    show={[10, 20, 30, 40, 50]}
                    onChangeSearch={(val) => {
                      setSearch(val)
                    }}
                    onChangeLimit={(val) => {
                      setLimit(val)
                    }}
                    onChangePage={(val) => {
                      setOffset(val)
                    }}
                    column={[
                      'Topik',
                      'Agenda',
                      'Waktu',
                      'Durasi',
                      'Password',
                      'Peserta',
                      'Join',
                    ]}
                    renderItem={(item) => (
                      <RenderItem
                        item={item}
                        key={item.id}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React from 'react'

export default function ModalForumBtn() {
  return (
    <button
      data-toggle="modal"
      data-target="#modalForum"
      className="btn btn-primary mr-3 w-100"
    >
      <i className="fas fa-comments mr-2" />
      <span>Forum Diskusi</span>
    </button>
  )
}

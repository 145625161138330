import BaseInputTextAreaWithoutGroup from 'component/input/BaseInputTextAreaWithoutGroup';
import BaseInputWithoutGroup from 'component/input/BaseInputWithoutGroup';
import useInputKindness from 'context/input-kindness';
import React from 'react'

const FormInputDraftItem = ({ typeScoreIndex, item, onUpdateDataHandler, projectIndex }) => {
  /* --------------------------------- CONTEXT -------------------------------- */
  const { handlers } = useInputKindness();
  const nomor = typeScoreIndex + 1;


  const optionsKeterangan = [
    { value: 'BB', label: 'BB - Belum Berkembang' },
    { value: 'MB', label: 'MB - Mulai Berkembang' },
    { value: 'BSH', label: 'BSH - Berkembang Sesuai Harapan' },
    { value: 'SB', label: 'SB - Sangat Berkembang' },
  ]
  return (
    <>
      <tr>
        <td>{nomor}</td>
        <td>
          <div className='d-flex align-items-center justify-content-between'>
            <div>
              <b>{item?.dimensi?.name || '-'}</b>
            </div>
            <div>
            </div>
          </div>

          <BaseInputTextAreaWithoutGroup
            className="form-control mt-2 p-2"
            placeholder="Deskripsi"
            props={{
              style: {
                padding: '0px'
              },
              value: item?.capaian?.name || '-',
              rows: 5,
            }}
            isWithLabel={false}
            isDisabled={true}
          />
        </td>
        <td className="px-5">
          {Array.isArray(optionsKeterangan) && optionsKeterangan.length ? optionsKeterangan.map((opt, optIndex) => {
            const uniqueId = `radio-${typeScoreIndex}-${opt.value}-${optIndex}`;
            return (
              <div key={uniqueId}>
                <BaseInputWithoutGroup className='' isFormGroup={false} isWithLabel={false} id={uniqueId} type='radio' name={`choice-${typeScoreIndex}`} props={{
                  value: opt.value,
                  onChange: e => {
                    onUpdateDataHandler({
                      projectIndex,
                      projectScoreData: {
                        score: e.target.value,
                        index: typeScoreIndex,
                        proyek_type_score_id: item?.proyek_type_score_id
                      },
                      type: 'proyek_scores'
                    })
                  },
                  checked: opt.value === item?.score
                }}
                  access={['instruktur']}
                  isDisabled={handlers.saveStudentScores.loading || item?.isStatusSubmissionOrApproved}
                />
                <label htmlFor={uniqueId} className='p-1'>{opt.label}</label>
              </div>
            );
          }) : null}
        </td>
      </tr>
    </>
  )
}

export default FormInputDraftItem
import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import './highchart.css'

export default function pieChart({ series }) {
  const optionsBar = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },    
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: '',
        colorByPoint: true,
        size: '90%',
        innerSize: '60%',
        data: series,
      },
    ],
  }

  return (
    <div className="m-2">
      <HighchartsReact
        highcharts={Highcharts}
        options={optionsBar}
      />
    </div>
  )
}

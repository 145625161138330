import React, { useEffect, useState } from 'react'
import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'

export default function MenuTable() {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [data, setData] = useState([])

  /* ========================================= Functions ======================================== */
  async function getMenuRole() {
    let response = await getRequest('menu-role')
    if (response) {
      setData(response.data)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getMenuRole()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Menus" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header"></div>
              <div className="card-body">
                <a
                  className="btn btn-primary"
                  href="/menus-add"
                >
                  tambah
                </a>
                &nbsp;&nbsp;&nbsp;
                <a
                  className="btn btn-primary"
                  href="/menus"
                >
                  order
                </a>
                <br />
                <br />
                <table
                  id="tables-group"
                  className="table table-striped table-bordered nowrap"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nama Menu</th>
                      <th>Menu Key</th>
                      <th>Url</th>
                      <th>Type</th>
                      <th>Icon</th>
                      <th>Tipe Role</th>
                      <th style={{ width: 80 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((val, i) => (
                      <RenderItem
                        item={val}
                        i={i}
                        defno={''}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )

  function RenderItem({ item, i, defno }) {
    var no = ++i
    var str = defno + '' + no
    var rt = JSON.parse(item.role_type).join(',')
    return (
      <>
        <tr>
          <td>{str}</td>
          <td>{item.menu}</td>
          <td>{item.menu_key}</td>
          <td>{item.url}</td>
          <td>{item.type}</td>
          <td>{item.icon}</td>
          <td>{rt}</td>
          <td>
            <a href={'/menus-edit/' + item.id}>edit</a>
          </td>
        </tr>
        {item.children.map((val, i) => (
          <RenderItem
            item={val}
            i={i}
            defno={str + '.'}
          />
        ))}
      </>
    )
  }
}

import React from 'react'
import usePreviewRapor from 'context/preview-rapor'

export default function Bottom() {
  /* ====================================== Consume Context ===================================== */
  const { rapor } = usePreviewRapor()

  /* ========================================== Output ========================================== */
  return (
    <div
      className="card"
      style={{ height: '70px' }}
    >
      <div className="card-body">
        <div className="row">
          <div className="col-5 d-flex align-items-center justify-content-start">
            {rapor?.profile?.website && (
              <h5 className="text-uppercase mb-0">{rapor?.profile?.website}</h5>
            )}
          </div>
          <div className="col-7 text-center d-flex align-items-center justify-content-end">
            {rapor?.profile?.instagram && (
              <div className="mr-4 d-flex align-items-center">
                <i className="fab fa-2x fa-instagram mr-2"></i>
                <span>{ rapor?.profile?.instagram }</span>
              </div>
            )}
            {rapor?.profile?.twitter && (
              <div className="mr-4 d-flex align-items-center">
                <i
                  className="fab fa-2x fa-twitter-square text-dark mr-2"
                  aria-hidden="true"
                ></i>
                { rapor?.profile?.twitter }
              </div>
            )}
            {rapor?.profile?.facebook && (
              <div className="mr-4 d-flex align-items-center">
                <i className="fab fa-2x fa-facebook-square mr-2"></i>
                { rapor?.profile?.facebook }
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

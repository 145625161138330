import { useBaseContext } from 'context/base'
import { createContext, useContext, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { createQueryUrl } from 'util/string'
import { useGlobalGradeHandlerHook } from '../hooks/useGlobalGradeHandlerHook'

const OverviewGradeContext = createContext()

export function OverviewGradeContextWrapper({ children }) {
  const { deleteRequest } = useBaseContext()
  const { data: globalGradeData, handler } = useGlobalGradeHandlerHook()
  const params = useParams()
  const history = useHistory()

  const [data, setData] = useState([])
  const [meta, setMeta] = useState({
    search: '',
    limit: 10,
    offset: 0,
    totalData: 0,
  })

  const [selectedGrade, setSelectedGrade] = useState(null)
  const [showModalSelectedStudent, setShowModalSelectedStudent] = useState(false)

  async function getListGradesContentHandler() {
    const response = await handler.getListGradesContentHandler({ kelasId: params?.kelasId, offset: meta.offset, limit: meta.limit, search: meta.search })

    if (response) {
      setMeta(prevState => ({
        ...prevState,
        ...response.meta
      }))
      setData(response.data)
    }
  }

  /**
   * Click Grade Add Handler
   * 
   */
  const onClickAddGradeHandler = () => {
    history.push(`${params?.kelasId}/grade/add`)
  }

  /**
   * Click Grade Edit Handler
   * 
   * @param {GradeItem} gradeItem 
  */
  const onClickGradeEditHandler = (gradeItem) => {
    history.push(`/kelas/${params?.kelasId}/grade/${gradeItem.id}/edit`)
  }

  /**
   * Click Grade List Content Handler
   * 
   * @param {GradeItem} gradeItem 
  */
  const onClickGradeListContentHandler = (gradeItem) => {
    history.push(`/kelas/${params?.kelasId}/grade/${gradeItem.id}/list-content?grade-name=${gradeItem.name}`)
  }

  const onSetMetaHandler = partialReplaceItem => setMeta(prevState => ({
    ...prevState,
    ...partialReplaceItem,
  }))

  /**
   * Remove Grade Handler
   * 
   * @param {GradeItem} item 
   */
  const onClickGradeRemoveHandler = async (item) => {
    Swal.fire({
      title: 'Apakah Anda Yakin?',
      text:`Anda akan menghapus Grade ${item.name}!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, yakin!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Sedang Menghapus...',
          html: `<div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>`,
          allowOutsideClick: false,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
        })

        const response = await deleteRequest(`content-grade?id=${item.id}`)
        if (response) {
          getListGradesContentHandler()
          window.notification(
            'Berhasil',
            `menghapus grade ${item.name}`,
            'success',
          )
          Swal.close()
        } else {
          Swal.close()
        }
      }
    })
  }

  /**
   * Grade Student Handler
   * 
   * @param {GradeItem} item 
   */
  const onClickGradeStudentHandler = async (item) => {
    setSelectedGrade(item)
    setShowModalSelectedStudent(true)
  }

  /**
   * Close Grade Student Handler
   * 
   */
  const onCloseGradeStudentHandler = async () => {
    getListGradesContentHandler()
    setSelectedGrade(null)
    setShowModalSelectedStudent(false)
  }
  

  useEffect(() => {
    const data = {
      ...meta,
      offset: 0
    }

    onSetMetaHandler(data)
    getListGradesContentHandler()
  }, [meta.search, meta.limit])

  /* ======================================= Shared Props ======================================= */
  const sharedProps = {
    data: {
      isLoadingData: globalGradeData.isLoadingListGradesContent,
      data,
      meta,
      selectedGrade,
      showModalSelectedStudent,
    },
    set: {
      setMeta,
      setSelectedGrade,
      setShowModalSelectedStudent,
    },
    handler: {
      getListGradesContentHandler,
      onClickAddGradeHandler,
      onClickGradeEditHandler,
      onClickGradeListContentHandler,
      onSetMetaHandler,
      onClickGradeRemoveHandler,
      onClickGradeStudentHandler,
      onCloseGradeStudentHandler,
    },
  }

  /* ====================================== Return Provider ===================================== */
  return (
    <OverviewGradeContext.Provider value={sharedProps}>
      {children}
    </OverviewGradeContext.Provider>
  )
}

/* ========================================= Return Hook ======================================== */
export default function useOverviewGradeContext() {
  return useContext(OverviewGradeContext)
}

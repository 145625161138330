import React from 'react'
import TableHead from './partials/block/table-head'
import './style.css'

export default function RaporBlock({ dataSection, dataTranscript, studi }) {
  let contentsArr = dataSection
    .flatMap((data) =>
      data.contents.map((x) => ({ ...x, section_name: data.title })),
    )
    // .filter((ds) => [6, 8].includes(ds.content_type_id))
    .map((content, cidx) => ({ ...content, idx: cidx })) // numbering

  // Untuk kebutuhan simulasi, jgn dihapus
  // contentsArr = [
  //   ...contentsArr,
  //   ...contentsArr.map((c) => ({ ...c, idx: contentsArr.length + c.idx })),
  //   ...contentsArr.map((c) => ({ ...c, idx: contentsArr.length * 2 + c.idx })),
  // ].slice(0, 7)

  // Asumsi pembagian halaman rapor period
  // Request dari Bp. Ali
  // 20 -> 20
  // 21 -> 21 (pas)
  // 22 -> 15 + 7
  // 23 -> 15 + 8
  // 24 -> 15 + 9
  // 25 -> 22 + 3
  // 26 -> 22 + 4
  // 42 -> 22 + 20
  // 43 -> 22 + 21 (pas)
  // 44 -> 22 + 15 + 7
  // 45 -> 22 + 15 + 8
  // 46 -> 22 + 15 + 9
  // 47 -> 22 + 22 + 3
  // 48 -> 22 + 22 + 4
  // 49 -> 22 + 22 + 5
  // 50 -> 22 + 22 + 6
  // 65 -> 22 + 22 + 21
  // 66 -> 22 + 22 + 15 + 7
  // dst...

  const blockPartials = contentsArr.reduce(
    (init, next, idx) => {
      // constants
      let { tables, count, isSeparated } = init
      const minPageLen = Math.floor(contentsArr.length / 22)
      const lastPageSize = contentsArr.length - (minPageLen - 1) * 22
      const remaining = contentsArr.length - (idx + 1)
      const isInLastPage = remaining < lastPageSize

      if (lastPageSize >= 22 && lastPageSize <= 24) {
        isSeparated = true
      }

      const newTables = () => {
        count++
        tables.push([])
      }

      // cek apakah tables.length < count, kalau true maka push arr kosong
      tables.length < count && tables.push([])

      // cek apakah tables[count].length > 21, kalau true maka tambah count + push tables arr kosong
      if (tables[count - 1].length > 21) newTables()
      // asdfghjkl ... patch biar halaman dinamis
      if (isSeparated && tables[count - 1].length >= 15 && isInLastPage)
        newTables()

      // masukin next ke dalam init.tables[init.count]
      tables[count - 1].push(next)

      // return ke init
      return {
        tables,
        count,
        isSeparated,
      }
    },
    {
      tables: [],
      count: 1,
      isSeparated: false,
    },
  )

  return (
    <>
      {blockPartials.tables.map((partial, partialIdx) => {
        return (
          <div
            key={`block_partials_` + partialIdx}
            className="pt-5 pl-5 pr-5 pb-0"
            style={{
              // pageBreakBefore: 'always',
              // pageBreakAfter: partial.length > 7 ? 'always' : 'auto',
              pageBreakInside: 'avoid',
            }}
          >
            {partialIdx === 0 && <h5>Submapel: {studi}</h5>}
            <div className="table-responsive">
              <table className="table table-bordered">
                <TableHead />
                <tbody>
                  {partial.map((content, index) => {
                    const isAssignment = content.assigment_users?.length > 0 || parseInt(content?.content_type_id) === 8
                    const assignmentScore = content?.assigment_users[0]?.score
                    const isQuiz = parseInt(content?.content_type_id) === 6
                    const quizScore = content?.quiz_score
                    const isSubmitted =
                      content?.is_quiz_evaluated === false ||
                      content?.is_assignment_evaluated === false ||
                      [1,2,3,4,5,9,10].includes(content?.content_type_id) && content?.is_content_done === true && !content?.content_score

                    function getScore() {
                      if (isAssignment) {
                        return assignmentScore
                      } else if (isQuiz) {
                        return quizScore
                      } else {
                        return content?.content_score
                      }
                    }
                    const score = getScore()
                    return (
                      <tr 
                        key={`partial_` + index} 
                        onClick={
                          () => { 
                            alert(`Score: ${score || (isSubmitted ? 'Belum Dikoreksi' : 'Belum Mengerjakan')}`)
                          }
                        }
                      >
                        <th
                          scope="row"
                          className="text-center"
                        >
                          {content.idx + 1}
                        </th>
                        <td style={{ maxWidth: '500px', wordWrap: 'break-word' }}>
                          {content.title.length > 80 ? content.title.slice(0,80) + '...' : content.title}
                        </td>
                        <td className="text-center">
                          {score > 87.5 ? (
                            <i className="fas fa-check text-success"></i>
                          ) : null}
                        </td>
                        <td className="text-center">
                          {score <= 87.5 && score > 75 ? (
                            <i className="fas fa-check text-warning"></i>
                          ) : null}
                        </td>
                        <td className="text-center">
                          {score <= 75 && score > 0 ? (
                            <i className="fas fa-check text-danger"></i>
                          ) : null}
                        </td>
                        <td className="text-center">
                          {!score ? (
                            <i 
                              className="fas fa-check" 
                              style={{ color: isSubmitted ? '#16a085' : '#c0392b' }}
                            ></i>
                          ) : null}
                        </td>
                        <td className="text-center">
                          {content.is_pengetahuan ? (
                            <i className="fas fa-check text-primary"></i>
                          ) : null}
                        </td>
                        <td className="text-center">
                          {content.is_keterampilan ? (
                            <i className="fas fa-check text-primary"></i>
                          ) : null}
                        </td>
                      </tr>
                    )
                  })}

                  {partialIdx === blockPartials.tables.length - 1 && (
                    <tr>
                      <td
                        colSpan="6"
                        className="text-center f-w-700"
                      >
                        Nilai Akhir
                      </td>
                      <td className="text-center">
                        {(dataTranscript?.nilai_pengetahuan === null || !dataTranscript)
                          ? '-'
                          : dataTranscript?.nilai_pengetahuan}
                      </td>
                      <td className="text-center">
                        {(dataTranscript?.nilai_keterampilan === null || !dataTranscript)
                          ? '-'
                          : dataTranscript?.nilai_keterampilan}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default function BadgeProgress({ content }) {
  /* ========================================= Constants ======================================== */
  const isStarted   = content.class_content_activities?.length
  const isCompleted = content.class_content_activities?.some(cca => cca.completed_at)
  const isSubmitted = content.is_assignment_evaluated === false || content.is_quiz_evaluated === false
  const isEvaluated = content.is_assignment_evaluated || content.is_quiz_evaluated

  /* ========================================== Output ========================================== */
  if (isEvaluated || isCompleted) {
    return (
      <span
        className="badge badge-success ml-2"
        style={{ fontSize: '12px' }}
      >
        Selesai
      </span>
    )
  }

  else if (isSubmitted) {
    return (
      <span
        style={{ fontSize: '12px' }}
        className="badge badge-pill badge-warning"
      >
        Belum Dikoreksi
    </span>
    )
  }

  else if (isStarted) {
    return (
      <span
        className="badge badge-info ml-2"
        style={{ fontSize: '12px' }}
      >
        Sedang Dikerjakan
      </span>
    )
  }
  
  else {
    return (
      <span
        className="badge badge-secondary ml-2"
        style={{ fontSize: '12px' }}
      >
        Belum Dikerjakan
      </span>
    )
  }
}

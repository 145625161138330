
/**
 * Form Input Web Address Props
 * 
 * @param {BaseContentFormProps} props
 */
export default function FormInputWebAddress({ 
  value, 
  setValue, 
  isInvalid, 
  isRequired, 
  errorMessage
}) {
  return (
    <div className="form-group mb-5 row">
      <label className="col-sm-3 col-form-label" htmlFor='inputWebAddress'>
        <span className="mr-1">Web Address</span>
        {isRequired
        ?
          <span className="text-danger">*</span>
        : null }
      </label>
      <div className="col-sm-9">
        <input
          type="text"
          className={`${
            isInvalid ? 'is-invalid' : ''
          } form-control`}
          id="inputWebAddress"
          value={value}
          onChange={(event) =>
            setValue(event.target.value)
          }
          placeholder="URL web: https://example.com"
        />

        {isInvalid ? (
          <label className="small form-text text-danger">
            {errorMessage || 'Web Address tidak boleh kosong.'}
          </label>
        ) : null}

        {value ? (
          <iframe
            src={value}
            className="rounded mt-3"
            style={{
              border: '1px solid #cccccc',
              backgroundColor: '#eeeeee',
              width: '100%',
              height: '650px',
              display: 'block',
            }}
          />
        ) : null}
      </div>
    </div>
  )
}
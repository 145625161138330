import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import DataTable from 'component/data-table'

export default function MataPelajaranInstruktur({ selectAngkatan }) {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, deleteRequest }= useBaseContext()

  /* ======================================= Local States ======================================= */
  const [kelasList, setKelasList]       = useState([])
  const [totalRecords, setTotalRecords] = useState(0)

  const [limit, setLimit]     = useState(10)
  const [offset, setOffset]   = useState(0)
  const [keyword, setKeyword] = useState('')

  /* ========================================= Functions ======================================== */
  async function getKelas() {
    var { role_type } = JSON.parse(localStorage.getItem('role_active'))

    if (keyword === '') {
      const response = await getRequest(
        `classes?role_type=${role_type}&limit=${limit}&offset=${offset}&ta_semester_code=${
          selectAngkatan.value ? selectAngkatan.value : ''
        }`,
      )
      if (response) {
        setKelasList(response.data)
        setTotalRecords(response.totalRecords)
      }
    } else {
      const response = await getRequest(
        `classes?role_type=${role_type}&limit=${limit}&offset=${offset}&keyword=${keyword}&ta_semester_code=${
          selectAngkatan.value ? selectAngkatan.value : ''
        }`,
      )
      if (response) {
        setKelasList(response.data)
        setTotalRecords(response.totalRecords)
      }
    }
  }

  const RenderItem = ({ item }) => {
    const { id } = JSON.parse(localStorage.getItem('user'))

    async function deleteItem(id) {
      Swal.fire({
        title: 'Apa anda yakin?',
        text: `Anda akan menghapus ${item.title}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Tidak',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await deleteRequest(`classes?id=${id}`)
          if (response) {
            if (response.message === 'Anda tidak memiliki akses') {
              Swal.fire(
                'Gagal hapus Mata Pelajaran!',
                'Anda tidak memiliki akses',
                'info',
              )
            }
            if (response.message === 'Berhasil delete data') {
              Swal.fire(
                'Berhasil!',
                'Mata Pelajaran berhasil dihapus',
                'success',
              ).then(() => window.location.reload())
            }
          } else {
            Swal.fire(
              'Oppsss!',
              'Terjadi kesalahan coba beberapa saat lagi.',
              'error',
            )
          }
        }
      })
    }

    const isShared = item.class_profiles.is_owner == false && item.class_profiles.status == 'approved'
    const isOwner = item.class_profiles.is_owner
    item.is_owner = isOwner
    return (
      <tr>
        <td>
          {isShared && (
              <span className="badge badge-dark">Bersama</span>
            )}
          {item.class_profiles.is_owner == false &&
            item.class_profiles.status == 'duplicate' && (
              <span className="badge badge-info">Duplicate</span>
            )}{' '}
          {item.title ? item.title : ''}
        </td>
        <td>{item.category.title ? item.category.title : ''}</td>
        <td>
          {item.active ? (
            <span className="badge badge-success">Aktif</span>
          ) : (
            <span className="badge badge-secondary">Tidak Aktif</span>
          )}
        </td>
        <td style={{ verticalAlign: 'inherit', padding: '14px' }}>
          <button
            onClick={() => history.push('/kelas-edit', { dataKelas: item })}
            className="btn btn-sm btn-success mr-2"
          >
            <i className="feather icon-edit mr-1" />
            <span>Edit</span>
          </button>
          <button
            onClick={() =>
              history.push(`/kelas/${item.id}`, {
                dataKelas: item,
                kelasId: item.id,
              })
            }
            className="btn btn-sm btn-warning mr-2"
          >
            <i className="fas fa-eye mr-1" />
            <span>Overview</span>
          </button>
          {(item.mentor_id === id && item.class_profiles.is_owner != false) ||
          (item.mentor_id === id &&
            item.class_profiles.is_owner == false &&
            item.class_profiles.status == 'duplicate') ? (
            <button
              onClick={() => deleteItem(item.id)}
              className="btn btn-sm btn-danger mr-2"
            >
              <i className="feather icon-trash-2 mr-1" />
              <span>Hapus</span>
            </button>
          ) : (
            <button
              disabled
              className="btn btn-sm btn-danger btn-forbidden mr-2"
              title="Anda tidak memiliki akses"
            >
              <i className="feather icon-trash-2 mr-1" />
              <span>Hapus</span>
            </button>
          )}
        </td>
      </tr>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getKelas()
  }, [limit, offset, keyword, selectAngkatan])

  /* ========================================== Output ========================================== */
  return (
    <div className="dt-responsive table-responsive">
      <DataTable
        className={'table table-striped table-bordered nowrap'}
        data={kelasList}
        totalRows={totalRecords}
        show={[10, 20, 30, 40, 50]}
        onChangeSearch={(val) => {
          setKeyword(val)
        }}
        onChangeLimit={(val) => {
          setLimit(val)
        }}
        onChangePage={(val) => {
          setOffset(val)
        }}
        column={['Sub Mata Pelajaran', 'Mata Pelajaran', 'Status', 'Actions']}
        renderItem={(item, index) => (
          <RenderItem
            item={item}
            key={index}
          />
        )}
      />
    </div>
  )
}

import React from 'react'
import useChatContext from 'context/chat'

export default function BtnGetOlderMessage() {
  /* ====================================== Consume Context ===================================== */
  const { current, messages } = useChatContext()

  // ENDYAR_TODO: pas ambil messages.fetch.older() ada remaining, itu dipakai buat perbandingan needMoreChats
  const needMoreChats = current.data.chats.length < current.data.total_chats

  /* ======================================== Components ======================================== */
  const Loader = () => (
    <div
      className="spinner-border text-primary"
      role="status"
    ></div>
  )

  /* ========================================== Output ========================================== */
  return messages.loading.older ? (
    <Loader />
  ) : needMoreChats ? (
    <h3
      className="badge badge-pill badge-primary"
      style={{ cursor: 'pointer' }}
      onClick={() => messages.fetch.older()}
    >
      Load Older Message...
    </h3>
  ) : null
}

import React, { useEffect, useState } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import DataTable from 'component/data-table'
import LoadingSpinner from 'component/loading'
import { avatarDefaultUser } from 'util/constant'
import { Container } from 'component/template'
import Header from 'component/admin/Header'

export default function ManageSiswa() {
  /* ========================================== Helpers ========================================= */
  const location = useLocation()
  const { user } = location.state

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/users', name: 'Users' }]

  /* ======================================= Local States ======================================= */
  const [isLoading, setIsLoading]     = useState(false)
  const [addedSiswas, setAddedSiswas] = useState([])
  const [siswaList, setSiswaList]     = useState([])
  const [totalRecord, setTotalRecord] = useState(0)

  // Seacrh States
  const [keyword, setKeyword] = useState('')
  const [limit, setLimit]     = useState(10)
  const [offset, setOffset]   = useState(0)

  /* ========================================= Functions ======================================== */
  async function getParentToSiswa() {
    const response = await getRequest(
      `parent-to-siswa?user_id=${user.id}`,
    )
    if (response) {
      setAddedSiswas(response.data)
    }
  }

  async function getUserTypeSiswa() {
    const response = await getRequest(
      `users?options=siswa&limit=${limit}&offset=${offset}&keyword=${keyword}`,
    )
    if (response) {
      setIsLoading(false)
      setSiswaList(response.data)
      setTotalRecord(response.totalRecords)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    setIsLoading(true)
    getParentToSiswa()
  }, [])

  useEffect(() => {
    getUserTypeSiswa()
  }, [limit, offset, keyword])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Kelola Siswa"
          links={links}
        />
        
        <div className="card user-profile-list">
          <div className="card-header">
            <h5>Kelola siswa (Orang tua murid)</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col">
                <p
                  className="mb-2"
                  style={{ fontWeight: '500', fontSize: '14px' }}
                >
                  Orang tua:{' '}
                </p>
                <div className="align-middle mb-4 p-3 border rounded bg-light">
                  <img
                    src={`${user.linkAvatar}`}
                    alt={user.name}
                    className="img-radius align-top m-r-20"
                    style={{ width: '65px', height: '65px' }}
                    onError={(event) => {
                      event.currentTarget.src = avatarDefaultUser
                    }}
                  />
                  <div className="d-inline-block">
                    <h6
                      style={{ fontWeight: '500' }}
                      className="mb-1 mt-1"
                    >
                      {user.name}
                    </h6>
                    <p className="m-b-0">
                      <i className="fas text-primary fa-envelope mr-1" />
                      {user.email ? user.email : '-'}
                    </p>
                    <p className="m-b-0">
                      <i className="fas text-primary fa-phone mr-1" />
                      {user.phone ? user.phone : '-'}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {addedSiswas.length !== 0 ? (
                <>
                  <div className="col-12">
                    <p
                      className="mb-2"
                      style={{ fontWeight: '500', fontSize: '14px' }}
                    >
                      Siswa:{' '}
                    </p>
                  </div>
                  {addedSiswas.map((item, index) => {
                    return (
                      <div
                        className="col-12 col-md-6 col-xl-4"
                        key={index}
                      >
                        <div className="align-middle mb-4 p-3 border rounded bg-light">
                          <img
                            src={`${item.user.linkAvatar}`}
                            alt={item.user.name}
                            className="img-radius align-top m-r-20"
                            style={{ width: '65px', height: '65px' }}
                            onError={(event) => {
                              event.currentTarget.src = avatarDefaultUser
                            }}
                          />
                          <div className="d-inline-block">
                            <h6
                              style={{ fontWeight: '500' }}
                              className="mb-0 mt-1"
                            >
                              {item.user.name}
                            </h6>
                            <p
                              className="mb-1 text-muted f-8"
                              style={{ fontStyle: 'italic' }}
                            >
                              {item.user.nim ? item.user.nim : '-'}
                            </p>
                            <p className="m-b-0">
                              <i className="fas fa-envelope text-primary mr-1" />
                              {item.user.email ? item.user.email : '-'}
                            </p>
                            <p className="m-b-0">
                              <i className="fas fa-phone text-primary mr-1" />
                              {item.user.phone ? item.user.phone : '-'}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
              ) : null}
            </div>

            {isLoading ? (
              <div className="row">
                <div className="col">
                  <LoadingSpinner />
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col dt-responsive table-responsive">
                  <DataTable
                    className={'table table-striped table-bordered nowrap'}
                    data={siswaList}
                    totalRows={totalRecord}
                    show={[10, 20, 30, 40, 50]}
                    onChangeSearch={(val) => setKeyword(val)}
                    onChangeLimit={(val) => setLimit(val)}
                    onChangePage={(val) => setOffset(val)}
                    column={[
                      'Nama Siswa',
                      'No. Induk',
                      'Gender',
                      'Email',
                      'Telepon',
                      'Actions',
                    ]}
                    renderItem={(siswa, index) => (
                      <SiswaItem
                        key={siswa.id + index}
                        siswa={siswa}
                        user={user}
                      />
                    )}
                  />
                </div>
              </div>
            )}

            <div className="row">
              <div className="col">
                <Link to="/users">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                  >
                    <i className="fas fa-chevron-left mr-1" />
                    <span>Kembali</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

const SiswaItem = ({ siswa, user }) => {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { postRequest, deleteRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [hasParent, setHasParent] = useState(false)

  /* ========================================= Functions ======================================== */
  function checkIsHasParent() {
    if (siswa.parent_to_siswas.length !== 0) {
      for (const item of siswa.parent_to_siswas) {
        if (item.user_id === user.id) {
          setHasParent(true)
          break
        }
      }
    }
  }

  async function addSiswaToOrangtua() {
    Swal.fire({
      html: `
        <h6 class="f-18">Tambah siswa ke orang tua?</h6>
        <div class="row">
          <div class="offset-2 offset-lg-3 col-3 col-lg-2 p-0">
            <p class="f-12 mb-1 text-left">Siswa</p>
          </div>
          <div class="col-7 col-lg-6">
            <p class="f-12 mb-1 text-left f-w-500">: ${siswa.name}</p>
          </div>
        </div>
        <div class="row">
          <div class="offset-2 offset-lg-3 col-3 col-lg-2 p-0">
            <p class="f-12 mb-1 text-left">No. Induk</p>
          </div>
          <div class="col-7 col-lg-6">
            <p class="f-12 mb-1 text-left f-w-500">: ${
              siswa.nim ? siswa.nim : '-'
            }</p>
          </div>
        </div>
        <div class="row">
          <div class="offset-2 offset-lg-3 col-3 col-lg-2 p-0">
            <p class="f-12 mb-1 text-left">Orang tua</p>
          </div>
          <div class="col-7 col-lg-6">
            <p class="f-12 mb-1 text-left f-w-500">: ${user.name}</p>
          </div>
        </div>
      `,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Post Parent To Siswa
        let form = new FormData()
        form.append('user_id', user.id)
        form.append('siswa_id', siswa.id)

        const response = await postRequest('parent-to-siswa', form)
        if (response) {
          Swal.fire('Berhasil!', 'Siswa berhasil ditambahkan.', 'success').then(
            () => history.go(0),
          )
        } else {
          Swal.fire(
            'Oppsss!',
            'Terjadi kesalahan coba beberapa saat lagi.',
            'error',
          )
        }
      }
    })
  }

  async function removeSiswaFromOrangtua() {
    Swal.fire({
      html: `
        <h6 class="f-18">Hapus siswa dari orang tua?</h6>
        <div class="row">
          <div class="offset-2 offset-lg-3 col-3 col-lg-2 p-0">
            <p class="f-12 mb-1 text-left">Siswa</p>
          </div>
          <div class="col-7 col-lg-6">
            <p class="f-12 mb-1 text-left f-w-500">: ${siswa.name}</p>
          </div>
        </div>
        <div class="row">
          <div class="offset-2 offset-lg-3 col-3 col-lg-2 p-0">
            <p class="f-12 mb-1 text-left">No. Induk</p>
          </div>
          <div class="col-7 col-lg-6">
            <p class="f-12 mb-1 text-left f-w-500">: ${
              siswa.nim ? siswa.nim : '-'
            }</p>
          </div>
        </div>
        <div class="row">
          <div class="offset-2 offset-lg-3 col-3 col-lg-2 p-0">
            <p class="f-12 mb-1 text-left">Orang tua</p>
          </div>
          <div class="col-7 col-lg-6">
            <p class="f-12 mb-1 text-left f-w-500">: ${user.name}</p>
          </div>
        </div>
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Post Parent To Siswa
        let form = new FormData()
        form.append('user_id', user.id)
        form.append('siswa_id', siswa.id)

        const response = await deleteRequest(
          `parent-to-siswa?user_id=${user.id}&siswa_id=${siswa.id}`,
        )
        if (response) {
          Swal.fire(
            'Berhasil!',
            'Siswa berhasil dihapus dari orang tua.',
            'success',
          ).then(() => history.go(0))
        } else {
          Swal.fire(
            'Oppsss!',
            'Terjadi kesalahan coba beberapa saat lagi.',
            'error',
          )
        }
      }
    })
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    checkIsHasParent()
  }, [siswa])

  /* ========================================== Output ========================================== */
  return (
    <tr>
      <td>
        <div className="d-inline-block align-middle">
          <img
            src={`${siswa.linkAvatar}`}
            alt={siswa.name}
            className="img-radius align-top m-r-15"
            style={{ width: '40px', height: '40px' }}
            onError={(event) => {
              event.currentTarget.src = avatarDefaultUser
            }}
          />
          <div className="d-inline-block">
            <h6 className="m-b-0 mt-2">{siswa.name}</h6>
            {/* <p className="m-b-0">as@domain.com</p> */}
          </div>
        </div>
      </td>
      <td>{siswa.nim ? siswa.nim : <i className="fas fa-minus" />}</td>
      <td>
        {!siswa.gender ? (
          <i className="fas fa-minus" />
        ) : (
          <>
            {siswa.gender === 'L' ? 'Laki-laki' : ''}
            {siswa.gender === 'P' ? 'Perempuan' : ''}
          </>
        )}
      </td>
      <td>{siswa.email ? siswa.email : <i className="fas fa-minus" />}</td>
      <td>{siswa.phone ? siswa.phone : <i className="fas fa-minus" />}</td>
      <td>
        {hasParent ? (
          <button
            type="button"
            title="Hapus siswa"
            className="btn btn-icon btn-danger"
            onClick={() => removeSiswaFromOrangtua()}
          >
            <i className="fas fa-times" />
          </button>
        ) : (
          <button
            type="button"
            title="Tambah siswa"
            className="btn btn-icon btn-success"
            onClick={() => addSiswaToOrangtua()}
          >
            <i className="fas fa-plus" />
          </button>
        )}
        {/* <div className="overlay-edit d-flex justify-content-start">

        </div> */}
      </td>
    </tr>
  )
}

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'
import 'moment/locale/id'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import LoadingSpinner from 'component/loading'
import ForumTopicItem from 'component/forum/forum-topic-item'

export default function Forum() {
  /* ========================================== Helpers ========================================== */
  const history = useHistory()

  /* ====================================== Consume Context ====================================== */
  const { getRequest, putRequest, deleteRequest } = useBaseContext()

  /* ======================================== Local States ======================================= */
  const [data, setData]                 = useState([])
  const [user, setUser]                 = useState({})
  const [isLoading, setIsLoading]       = useState(false)
  const [isForumEmpty, setIsForumEmpty] = useState(false)

  /* =================================== Consume Local Storage =================================== */
  const { role_type } = JSON.parse(localStorage.getItem('role_active'))

  /* ========================================= Functions ========================================= */
  async function getForum() {
    setIsLoading(true)
    let user = localStorage.getItem('user')
    let response = await getRequest('forum')
    setIsLoading(false)
    if (response) {
      setData(response.data)
      if (response.data.length === 0) {
        setIsForumEmpty(true)
      }
    }
    setUser(JSON.parse(user))
  }

  function detailTopic(id) {
    history.push(`/forum/${id}`)
  }

  function editTopic(item) {
    history.push(`/forum-edit/${item.id}`, {
      id: item.id,
    })
  }

  async function pinTopic(item, pinned) {
    let form = new FormData()
    form.append('pinned', pinned)
    form.append('id', item.id)

    const response = await putRequest('forum', form)
    if (response) {
      window.notification(
        'Berhasil',
        pinned === 1 ? 'Topik berhasil ditandai' : 'Tanda berhasil dihapus',
        'success',
      )
      await getForum()
    } else {
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }

  function deleteTopic(id) {
    Swal.fire({
      title: 'Apa anda yakin?',
      text: 'Anda akan menghapus topik ini!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus',
      cancelButtonText: 'Batal',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await deleteRequest('forum/' + id)
        if (response) {
          Swal.fire('Berhasil', 'Topik berhasil dihapus.', 'success').then(() =>
            history.go(0),
          )
        } else {
          Swal.fire(
            'Oppsss!',
            'Terjadi kesalahan coba beberapa saat lagi.',
            'error',
          )
        }
      }
    })
  }

  /* ========================================== Effects ========================================== */
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      getForum()
    }

    return () => (isSubscribed = false)
  }, [])

  /* =========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Forum" />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header">
                <h5>Forum diskusi</h5>
              </div>
              <div className="card-body">
                <button
                  onClick={() => {
                    history.push('./forum-add')
                  }}
                  type="button"
                  className="btn btn-primary"
                >
                  <i className="feather icon-plus-circle"></i> Buat Topik Baru
                </button>
                {isLoading ? (
                  <div className="row">
                    <div className="col">
                      <LoadingSpinner />
                    </div>
                  </div>
                ) : (
                  <>
                    {isForumEmpty ? (
                      <div className="row">
                        <div className="col">
                          <div className="d-flex flex-column justify-content-center mt-4">
                            <i
                              className="fas fa-comments f-60 text-center mb-3"
                              style={{
                                color: '#ccc',
                              }}
                            />
                            <h6
                              className="text-center"
                              style={{
                                color: '#bbb',
                              }}
                            >
                              Belum ada topik diskusi
                            </h6>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {data.map((item) => {
                          let dd = moment(item.createdAt)
                            .add(30, 'days')
                            .valueOf()
                          let date =
                            dd < moment().valueOf()
                              ? moment(item.createdAt).format('DD MMM YYYY')
                              : moment(item.createdAt).fromNow()
                          return (
                            <ForumTopicItem
                              key={item.id}
                              item={item}
                              date={date}
                              roleType={role_type}
                              userId={user.id}
                              handleDetailTopic={detailTopic}
                              handleEditTopic={editTopic}
                              handlePinTopic={pinTopic}
                              handleDeleteTopic={deleteTopic}
                            />
                          )
                        })}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

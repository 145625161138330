import Swal from 'sweetalert2'

const submitHandler = (note, score, putRequest, setLoading, setIsEdit, assignmentData) => {
  const { assignmentUser, getAssignmentUsers, handleCloseModal } = assignmentData

  const swalConfig = {
    title: '<h4>Apa anda akan mengubah nilai?</h4>',
    html: `
      <p class = "f-16 f-w-500">Nilai: <span class = "text-dark f-w-700">${score}</span><p>
      ${note
        ? `<p class="f-16 f-w-500">Catatan: <span class="text-dark f-w-300">${note}</span><p>`
        : ''
      }
    `,
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Ya, ubah!',
    cancelButtonText: 'Periksa kembali',
    customClass: {
      content: 'd-flex'
    },
  }

  Swal.fire(swalConfig).then(async (result) => {
    if (result.isConfirmed) {
      let form = new FormData()
      form.append('assignment_users_id', assignmentUser.id)
      form.append('score', Math.abs(score))
      form.append('note', note)
      setLoading(true)
      const response = await putRequest('assignment-siswa-answer', form)
      setLoading(false)
      if (response) {
        window.$('#tugasKomentar').modal('hide')
        setIsEdit(false)
        handleCloseModal()
        await getAssignmentUsers()
        window.notification('Nilai', 'berhasil diubah!', 'success')
      }
    }
  })
}

export default submitHandler

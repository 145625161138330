import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import ReactSelect from 'react-select'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'

export default function KindnessProgram() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  let { getRequest, deleteRequest} = useBaseContext()
  
  /* ======================================= Local States ======================================= */
  const [data, setData]           = useState([])
  const [totalData, setTotalData] = useState(0)
  const [limit, setLimit]         = useState(10)
  const [offset, setOffset]       = useState(0)
  const [search, setSearch]       = useState('')
  const [type, setType]           = useState({})

  /* ========================================= Functions ======================================== */
  async function getKindness() {
    let tipe = ''
    if (type?.value) {
      tipe = type.value
    }
    let res = await getRequest(
      `kindness?limit${limit}&offset=${offset}&search=${search}&type=${tipe}`,
    )
    if (res) {
      setTotalData(res.totalData)
      setData(res.data)
    }
  }

  async function handleDelete(id) {
    Swal.fire({
      title: 'Konfirmasi',
      text: 'Anda akan menghapus kindness program ini?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await deleteRequest(`kindness-delete?id=${id}`)
        if (response) {
          getKindness()
          Swal.fire('Berhasil', 'Kindness program dihapus!', 'success')
        } else {
          Swal.fire(
            'Oppsss!',
            'Terjadi kesalahan coba beberapa saat lagi.',
            'error',
          )
        }
      }
    })
  }

  /* ========================================= Constants ======================================== */
  const optionType = [
    { label: 'image', value: 'image' },
    { label: 'video', value: 'video' },
    { label: 'text', value: 'text' },
  ]

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getKindness()
  }, [limit, offset, search, type?.value])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Kindness Program"
          linkName="Mata Pelajaran / Kindness Program"
        />

        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header d-flex justify-content-end">
                <Link to="/kindness-program/add">
                  <button
                    type="button"
                    className="btn btn-primary"
                  >
                    <i className="feather icon-plus-circle mr-1" />
                    <span>Tambah Kindness Program</span>
                  </button>
                </Link>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="form-group col-6">
                    <label className="f-w-700 f-14">Pilih Tipe</label>
                    <ReactSelect
                      placeholder="Filter berdasarkan tipe"
                      options={optionType}
                      onChange={(e) => setType(e)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 dt-responsive table-responsive">
                    <DataTable
                      totalRows={totalData}
                      data={data}
                      className={'table table-striped table-bordered nowrap'}
                      show={[10, 20, 30, 40, 50]}
                      onChangeSearch={(val) => {
                        setSearch(val)
                      }}
                      onChangeLimit={(val) => {
                        setLimit(val)
                      }}
                      onChangePage={(val) => {
                        setOffset(val)
                      }}
                      column={['Judul', 'Tipe', 'Status', 'Actions']}
                      renderItem={(item, indx) => {
                        return (
                          <tr key={indx}>
                            <td>{item.title}</td>
                            <td>{item.type}</td>
                            <td>
                              {item.status === true ? (
                                <span className="badge bg-success text-light">
                                  Aktif
                                </span>
                              ) : (
                                <span className="badge bg-secondary text-light">
                                  Tidak aktif
                                </span>
                              )}
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-success mr-2"
                                onClick={() =>
                                  history.push(
                                    '/kindness-program/edit/' + item.id,
                                  )
                                }
                              >
                                <i className="far fa-edit mr-1"></i>Edit
                              </button>
                              <button
                                type="button"
                                className="btn btn-warning mr-2"
                                onClick={() =>
                                  history.push({
                                    pathname:
                                      '/kindness-program/overview/' + item.id,
                                    state: {
                                      detail: item,
                                    },
                                  })
                                }
                              >
                                <i className="far fa-eye mr-1"></i>Overview
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => handleDelete(item.id)}
                              >
                                <i className="far fa-trash-alt mr-1"></i>Hapus
                              </button>
                            </td>
                          </tr>
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'
import ModalMetaKelola from 'component/modal/meta-kelola'
import { textElipsis } from 'util/string'
import useGetData from 'hooks/useGetData'
import DataTableWithFilter from 'component/data-table/indexWithFilter'

export default function Feedback() {
  /* ========================================= Helpers ======================================== */
  const history = useHistory()

  /* ===================================== Consume Context ==================================== */
  const { getRequest, deleteRequest } = useBaseContext()
  const { subMapel } = useGetData({ getListSubMapel: true })

  /* ========================================= States ========================================= */
  const [feedbackList, setfeedbackList] = useState([])
  const [feedbackSelected, setFeedbackSelected] = useState(null)
  const [totalRows, setTotalRows] = useState(0)
  const [subMapelReactSelectOptions, setSubMapelReactSelectOptions] = useState([])
  const [selectedSubMapel, setSelectedSubMapel] = useState({})

  // DATATABLE STATE
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [keyword, setKeyword] = useState('')

  /* ======================================== Functions ======================================= */
  async function getFeedbackList() {
    const filterSubMapel = selectedSubMapel?.value ? `&class_id=${selectedSubMapel?.value}` : ''

    if (keyword === '') {
      const response = await getRequest(
        `feed?limit=${limit}&offset=${offset}${filterSubMapel}`,
      )
      if (response) {
        setfeedbackList(response.data)
        setTotalRows(response.totalRecord)
      }
    } else {
      const response = await getRequest(
        `feed?limit=${limit}&offset=${offset}&search=${keyword}${filterSubMapel}`,
      )
      if (response) {
        setfeedbackList(response.data)
        setTotalRows(response.totalRecord)
      }
    }
  }

  function handleAddFeedback(type) {
    history.push(`/kelola-feedback-add?typefeed=${type}`)
  }

  function handleReportFeedback(feedData) {
    history.push(`/kelola-feedback-report/${feedData.id}`)
  }

  function handleEditFeedback(feedData) {
    let arr = []
    feedData.feed_respons.forEach((item) => {
      arr.push({ text: item.text, active: item.active ? 1 : 0 })
      feedData.feed_respons = arr
    })

    history.push(`/kelola-feedback-edit/${feedData.id}`, {
      existingData: feedData,
      typeFeedback: feedData.feed_type,
    })
  }

  function handleDeleteFeedback(item) {
    Swal.fire({
      title: 'Apa anda yakin?',
      text: `Anda akan menghapus "${item.description}"!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await deleteRequest(`feed?id=${item.id}`)
        if (response) {
          getFeedbackList()
          Swal.fire('Berhasil!', 'Data berhasil dihapis.', 'success')
        } else {
          Swal.fire(
            'Oppsss!',
            'Terjadi kesalahan coba beberapa saat lagi.',
            'error',
          )
        }
      }
    })
  }

  const RenderTypeFeed = (type) => {
    if (type == 1) {
      return <span className="badge badge-primary">Essay</span>
    } else if (type == 2) {
      return <span className="badge badge-success">Pilihan Ganda</span>
    } else if (type == 3) {
      return <span className="badge badge-info">Pilihan Ganda Kompleks</span>
    } else if (type == 4) {
      return <span className="badge badge-primary">Word Cloud</span>
    } else {
      return <i className="feather icon-minus" />
    }
  }

  const manipulatedListSubMapelReactSelectOptionsHandler = () => {
    const data = subMapel.data.map(s => ({ label: s?.title, value: s?.id }))

    setSubMapelReactSelectOptions(data);
  }

  /* --------------------------------- EFFECTS -------------------------------- */
  useEffect(() => {
    getFeedbackList()
  }, [limit, offset, keyword, selectedSubMapel])

  useEffect(() => {
    if (!subMapel.loading && subMapel.data.length && !subMapelReactSelectOptions.length) {
      manipulatedListSubMapelReactSelectOptionsHandler()
    }
  }, [subMapel])

  const RenderItem = ({
    item,
    handleEditFeedback,
  }) => {
    return (
      <tr>
        <td title={item?.description}>
          {item.description ? (
            textElipsis(item.description, 75)
          ) : (
            <i className="feather icon-minus" />
          )}
        </td>
        <td>{RenderTypeFeed(item.feed_type)}</td>
        <td>
          {item.active ? (
            <span className="badge badge-success">Aktif</span>
          ) : (
            <span className="badge badge-secondary">Tidak Aktif</span>
          )}
        </td>
        <td>
          <button
            onClick={() => setFeedbackSelected(item)}
            type="button"
            className="btn btn-sm mr-2 btn-dark"
            data-toggle="modal"
            data-target="#kelola-meta-modal"
          >
            <i className="feather icon-info mr-1" />
            Info
          </button>
          <button
            onClick={() => handleEditFeedback(item)}
            type="button"
            className="btn btn-sm mr-2 btn-success"
          >
            <i className="feather icon-edit mr-1" />
            <span>Edit</span>
          </button>
          <button
            onClick={() => handleReportFeedback(item)}
            type="button"
            title="Laporan feedback"
            className="btn btn-sm mr-2 btn-warning"
          >
            <i className="fas fa-chart-bar mr-1" />
            <span>Laporan</span>
          </button>
          <button
            onClick={() => handleDeleteFeedback(item)}
            type="button"
            title="Hapus feedback"
            className="btn btn-sm mr-2 btn-danger"
          >
            <i className="feather icon-trash-2 mr-1" />
            <span>Hapus</span>
          </button>
        </td>
      </tr>
    )
  }

  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Kelola Feedback" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list  ">
              <div className="card-header">
                <div className="row">
                  <div className="col">
                    <div className="btn-group mr-2">
                      <button
                        className="btn  btn-primary dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="feather icon-plus-circle mr-1" />
                        <span>Tambah Feedback</span>
                      </button>
                      <div className="dropdown-menu">
                        <a
                          onClick={() => {
                            handleAddFeedback(1)
                          }}
                          className="dropdown-item"
                          type="button"
                        >
                          <i className="fas fa-pen mr-1" />
                          <span>Essay</span>
                        </a>
                        <a
                          onClick={() => {
                            handleAddFeedback(2)
                          }}
                          className="dropdown-item"
                          type="button"
                        >
                          <i className="fas fa-list-ul mr-1" />
                          <span>Pilihan Ganda</span>
                        </a>
                        <a
                          onClick={() => {
                            handleAddFeedback(3)
                          }}
                          className="dropdown-item"
                          type="button"
                        >
                          <i className="fas fa-check-square mr-1" />
                          <span>Pilihan Ganda Kompleks</span>
                        </a>
                        <a
                          onClick={() => {
                            handleAddFeedback(4)
                          }}
                          className="dropdown-item"
                          type="button"
                        >
                          <i className="fas fa-cloud mr-1" />
                          <span>Word Cloud</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {/* Data table list feedback */}
                <div className="row">
                  <div className="col dt-responsive table-responsive">
                    <DataTableWithFilter
                      className={'table table-striped table-bordered nowrap'}
                      data={feedbackList}
                      totalRows={totalRows}
                      show={[10, 20, 30, 40, 50]}
                      onChangeSearch={(val) => {
                        setKeyword(val)
                      }}
                      onChangeLimit={(val) => {
                        setLimit(val)
                      }}
                      onChangePage={(val) => {
                        setOffset(val)
                      }}
                      column={['feedback', 'type', 'status', 'actions']}
                      renderItem={(dataSurvey) => (
                        <RenderItem
                          item={dataSurvey}
                          key={dataSurvey.id}
                          handleEditFeedback={handleEditFeedback}
                        />
                      )}
                      filter1={{
                        options: subMapelReactSelectOptions,
                        placeholder: 'Filter submapel...',
                        onChange: (choice) => setSelectedSubMapel(choice),
                        isClearable: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalMetaKelola
          type='feedback'
          title={feedbackSelected?.title}
          id={feedbackSelected?.id}
        />
      </Container>
    </TemplateAdmin>
  )
}

import React from 'react'
import FeedForm from './feed-form'
import FeedFormAlert from './feed-form-alert'
import useFeedbackForm, { FeedbackFormWrapper } from 'context/feedback-form'

function FeedbackFormWithoutContext() {
  /* ====================================== Consume Context ===================================== */
  const { feed } = useFeedbackForm()

  /* ======================================== Components ======================================== */
  const FeedFormContainer = ({ children }) => (
    <div className="feed-container">{children}</div>
  )

  /* ========================================== Output ========================================== */
  return (
    <>
      {feed.data && (
        <FeedFormContainer>
          <FeedFormAlert />
          <FeedForm />
        </FeedFormContainer>
      )}
    </>
  )
}

/* ================================ Output With Context Provider ================================ */
export default function FeedbackForm() {
  return (
    <FeedbackFormWrapper>
      <FeedbackFormWithoutContext />
    </FeedbackFormWrapper>
  )
}

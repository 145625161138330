import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import Overview from 'component/report-siswa/dashboard'
import Kelas from 'component/report-siswa/sub-mata-pelajaran'
import AktifitasBelajar from 'component/report-siswa/aktifitas-belajar'
import Badge from './Badge'
import Timeline from './Timeline'

export default function AktifitasSiswaProgress() {
  /* ========================================== Helpers ========================================= */
  const location = useLocation()

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/laporan-aktifitas-siswa', name: 'Aktivitas Siswa' }]

  /* =========================================== Refs =========================================== */
  const refProgressKelas = useRef()

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (location.hash.includes('#progress-kelas')) {
      refProgressKelas.current.click()
    }
  }, [location])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Progress"
          links={links}
        />
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <ul
                  className="nav nav-pills bg-white"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      id="beranda-tab"
                      className="nav-link active text-uppercase"
                      data-toggle="tab"
                      href="#beranda"
                      role="tab"
                      aria-controls="beranda"
                      aria-selected="true"
                    >
                      Dashboard
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      ref={refProgressKelas}
                      id="progress-kelas-tab"
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#progress-kelas"
                      role="tab"
                      aria-controls="progress-kelas"
                      aria-selected="false"
                    >
                      Sub Mata Pelajaran
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      id="activity-tab"
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#activity"
                      role="tab"
                      aria-controls="activity"
                      aria-selected="false"
                    >
                      Aktivitas Belajar
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      id="badges-tab"
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#badges"
                      role="tab"
                      aria-controls="badges"
                      aria-selected="false"
                    >
                      Badge
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      id="timeline-tab"
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#timeline"
                      role="tab"
                      aria-controls="timeline"
                      aria-selected="false"
                    >
                      Timeline
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="tab-content"
              id="myTabContent"
            >
              <div
                className="tab-pane fade show active"
                id="beranda"
                role="tabpanel"
                aria-labelledby="beranda-tab"
              >
                <Overview />
              </div>
              <div
                className="tab-pane fade show"
                id="progress-kelas"
                role="tabpanel"
                aria-labelledby="progress-kelas-tab"
              >
                <Kelas />
              </div>
              <div
                className="tab-pane fade show"
                id="activity"
                role="tabpanel"
                aria-labelledby="activity-tab"
              >
                <AktifitasBelajar />
              </div>
              <div
                className="tab-pane fade show"
                id="badges"
                role="tabpanel"
                aria-labelledby="badges-tab"
              >
                <Badge />
              </div>
              <div
                className="tab-pane fade show"
                id="timeline"
                role="tabpanel"
                aria-labelledby="timeline-tab"
              >
                <Timeline />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

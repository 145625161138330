import { isFileSizeInvalid, isFileTypeInvalid } from "util/file"

/**
 * Is File Type Invalid (For Form Input Content)
 * 
 * @param {any} value 
 * @param {FormInputType} type 
 */
export function isFileTypeInvalidHandler(value, type) {
  if (value?.file && value?.content_source === '0') {
    return isFileTypeInvalid({ file: value?.file, type })
  }
}

export default function useFormInputContent() {
  /**
   * Is Rule Required By Input Type Handler
   * 
   * @param {{
   *  type: FormInputType
   *  isRequiredProp: boolean
   *  value: any,
   * }} params
   * 
   * @return {boolean}
   */
  const isValidByInputTypeHandler = ({ type, isRequiredProp, value }) => {
    function isValid() {
      switch (type) {
        case 'audio':
        case 'video':
        case 'document':
          const internalSource = '0'
          const umumSource = '1'

          const isInternalUpload = value?.file && value?.content_source === internalSource
          const isInvalidType = isInternalUpload && isFileTypeInvalid({ file: value?.file, type })

          if (value?.content_source === umumSource) {
            return isRequiredProp && !value?.link
          } else if (value?.content_source === internalSource) {
            if (type === 'document') {
              const isInvalidSize = isInternalUpload && isFileSizeInvalid({ file: value?.file, type })

              return (isRequiredProp && !value?.file?.name) || isInvalidType || isInvalidSize
            } else {
              return (isRequiredProp && !value?.file?.name) || isInvalidType
            }
          }
      
          return isRequiredProp
        default:
          return !isRequiredProp
      }
    }

    const isInvalid = !isValid()

    return isInvalid
  }

  return {
    handler: {
      isValidByInputTypeHandler
    }
  }
}
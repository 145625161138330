import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/id'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { Back } from 'component/button'
import LoadingSpinner from 'component/loading'
import WordCloud from 'component/highchart/word-cloud-chart'
import BarChart from 'component/highchart/bar-chart'
import { avatarDefaultUser } from 'util/constant'

export default function ReportFeedback() {
  /* ========================================== Helpers ========================================= */
  const param = useParams()
  
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [typeFeed, setTypeFeed]         = useState(null)
  const [feedData, setFeedData]         = useState(null)
  const [dataReal, setDataReal]         = useState([])
  const [feedQuestion, setFeedQuestion] = useState('')

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/kelola-feedback', name: 'Kelola Feedback' }]

  /* ========================================= Functions ======================================== */
  async function getReportData() {
    const response = await getRequest(
      `feed-dashboard?feed_id=${param.idFeed}`,
    )
    if (response) {
      setTypeFeed(response.data.feed_type)
      setFeedData(response.data.dashboard)
      setDataReal(response.data.data)

      if (response.data.dashboard.label) {
        setFeedQuestion(response.data.dashboard.label)
      }

      if (response.data.dashboard.categories) {
        if (response.data.dashboard.categories[0]) {
          setFeedQuestion(response.data.dashboard.categories[0])
        }
      }
    } else {
      console.error('ERROR', response)
    }
  }

  const HandleHighChart = ({ data, typeFeed }) => {
    if (typeFeed === 4) {
      return (
        <WordCloud
          string={data.str}
          label={data.label}
        />
      )
    } else if (typeFeed === 2) {
      return (
        <BarChart
          categories={data.categories}
          series={data.series}
        />
      )
    } else if (typeFeed === 3) {
      return (
        <BarChart
          categories={data.categories}
          series={data.series}
        />
      )
    } else {
      return (
        <div className="text-center p-4">
          <p>Data Tidak Valid</p>
        </div>
      )
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getReportData()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Laporan Feedback" links={links} />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list  ">
              <div className="card-header">
                <h5 className="mb-0">
                  {typeFeed === 1 ? 'Data' : 'Grafik'} Laporan Feedback
                </h5>
                {feedQuestion ? <p className="my-0">{feedQuestion}</p> : null}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="tab-content"
                      id="myTabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="chart"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div className="col">
                          {feedData == null ? (
                            <>
                              <br />
                              <LoadingSpinner />
                              <br />
                            </>
                          ) : (
                            <>
                              {typeFeed === 2 ||
                              typeFeed === 3 ||
                              typeFeed === 4 ? (
                                <HandleHighChart
                                  data={feedData}
                                  typeFeed={typeFeed}
                                />
                              ) : null}
                              {typeFeed === 1 ? (
                                <>
                                  {dataReal.length !== 0 ? (
                                    <>
                                      {dataReal.map((val, index) => {
                                        let dd = moment(val.updatedAt)
                                          .add(30, 'days')
                                          .valueOf()
                                        let date =
                                          dd < moment().valueOf()
                                            ? moment(val.updatedAt).format(
                                                'DD MMM YYYY',
                                              )
                                            : moment(val.updatedAt).fromNow()
                                        return (
                                          <div
                                            key={index}
                                            className="media mt-3 mb-0 border-bottom pb-3"
                                          >
                                            <img
                                              src={`${val.user.linkAvatar}`}
                                              onError={(e) =>
                                                (e.currentTarget.src =
                                                  avatarDefaultUser)
                                              }
                                              alt="avatar"
                                              className="img-radius align-top m-r-15"
                                              style={{
                                                width: '30px',
                                                height: '30px',
                                              }}
                                            />
                                            <div className="media-body">
                                              <h6 className="mb-0 text-dark">
                                                {val.user.name}
                                              </h6>
                                              <p
                                                className="text-muted mb-2"
                                                style={{ fontSize: '10px' }}
                                              >
                                                <i className="feather icon-clock mr-1" />
                                                {date}
                                              </p>
                                              <p className="m-b-0">
                                                {val.feed_respon_text}
                                              </p>
                                            </div>
                                          </div>
                                        )
                                      })}
                                    </>
                                  ) : (
                                    <div className="d-flex flex-column justify-content-center">
                                      <i
                                        className="fas fa-user-slash f-60 text-center mb-3"
                                        style={{
                                          color: '#ccc',
                                        }}
                                      />
                                      <h6
                                        className="text-center"
                                        style={{
                                          color: '#ddd',
                                        }}
                                      >
                                        Belum ada feedback siswa
                                      </h6>
                                    </div>
                                  )}
                                </>
                              ) : null}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="row">
                  <div className="col">
                    <Back />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React from 'react'

export default function LoadingButton({ role }) {
  const loading_color = role === 'Guru Utama' ? 'text-danger' : 'text-warning'
  return (
    <div
      className={`spinner-border ${loading_color}`}
      role="status"
    ></div>
  )
}

import React, { useEffect, useState } from 'react'
import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { StyleButton } from 'component/button'
import DataTableGroupInstructor from 'component/group-teacher/datatable-group-instructor'
import DatatableInstructors from 'component/group-teacher/datatable-instructors'
import TableSettingRoles from 'component/group-teacher/table-setting-roles'

export default function GroupTeachers() {
  /* ===================================== Consume Context ==================================== */
  const { getRequest } = useBaseContext()

  /* ====================================== Teacher State ===================================== */
  const [dataTeachers, setDataTeachers]               = useState(null)
  const [totalRecordsTeacher, setTotalRecordsTeacher] = useState(0)
  const [searchTeacher, setSearchTeacher]             = useState('')
  const [limitTeacher, setLimitTeacher]               = useState(10)
  const [offsetTeacher, setOffsetTeacher]             = useState(0)

  /* ======================================= Other State ====================================== */
  const [detailGroup, setDetailGroup]     = useState('')
  const [isShowRole, setIsShowRole]       = useState(false)
  const [isShowPreview, setIsShowPreview] = useState(false)

  /* ======================================== Functions ======================================= */
  async function getDataTeacher(idGroup) {
    const response = await getRequest(
      `group-get-teachers?id=${idGroup}&limit=${limitTeacher}&offset=${offsetTeacher}&search=${searchTeacher}`,
    )
    if (response) {
      setDataTeachers(response.data)
      setTotalRecordsTeacher(response.totalRecords)
    }
  }

  /* ======================================== Handlers ======================================== */
  function handleTeachers(item) {
    setDetailGroup({ name: item.group, idGroup: item.id })
    setIsShowPreview(true)
    getDataTeacher(item.id)
  }
  
  function handleShowRole(item) {
    setDetailGroup({ nameGroup: item.group, idGroup: item.id })
    setIsShowRole(true)
  }

  function handleBackToGroup() {
    setDataTeachers(null)
    setTotalRecordsTeacher(0)
    setSearchTeacher('')
    setLimitTeacher(10)
    setOffsetTeacher(0)
    setDetailGroup('')
    setIsShowPreview(false)
    setIsShowRole(false)
  }

  /* ========================================= Effects ======================================== */
  useEffect(() => {
    if (typeof detailGroup === 'object') {
      getDataTeacher(detailGroup.idGroup)
    }
  }, [searchTeacher, limitTeacher, offsetTeacher])

  /* ========================================= Output ========================================= */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Grup Guru" />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header">
                {!isShowPreview && (
                  <button
                    type="button"
                    className="btn btn-primary has-ripple mr-2"
                    data-toggle="modal"
                    data-target="#add-modal-group"
                  >
                    <i className="feather icon-user-plus mr-1" />
                    <span>Tambah Grup</span>
                    <StyleButton />
                  </button>
                )}
                {(isShowPreview || isShowRole) && (
                  <div className="d-flex justify-content-between">
                    <h4 className="mb-0 mt-2">
                      {detailGroup ? detailGroup.name : '-'}
                    </h4>
                    &nbsp;&nbsp;&nbsp;
                    <br />
                    <br />
                    <button
                      onClick={handleBackToGroup}
                      type="button"
                      className="btn btn-outline-primary"
                    >
                      <i className="feather icon-chevron-left mr-1" />
                      <span>Kembali ke list group</span>
                      <StyleButton />
                    </button>
                  </div>
                )}
              </div>
              <div className="card-body">
                <div className="dt-responsive table-responsive">
                  {!isShowPreview && !isShowRole && (
                    <DataTableGroupInstructor
                      groupActive    = {false}
                      handleTeachers = {handleTeachers}
                      handleShowRole = {handleShowRole}
                    />
                  )}
                  {isShowPreview && (
                    <DatatableInstructors
                      detailGroup         = {detailGroup}
                      dataTeachers        = {dataTeachers}
                      totalRecordsTeacher = {totalRecordsTeacher}
                      getDataTeacher      = {getDataTeacher}
                      setSearch           = {setSearchTeacher}
                      setLimit            = {setLimitTeacher}
                      setOffset           = {setOffsetTeacher}
                    />
                  )}
                  {isShowRole && (
                    <TableSettingRoles
                      detailGroup       = {detailGroup}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

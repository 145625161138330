import useGetRoleActive from 'hooks/useGetRoleActive'
import React from 'react'
import Select from 'react-select'

/**
 * Base Select
 * 
 * @param {{
 *  access: Array,
 *  isDisabled: boolean,
 *  styles: Object
 * }} props
 */
const BaseSelect = ({ isDisabled = false, access = [], isInvalid, customRef, ...props }) => {
  const roleActive = useGetRoleActive()

  const isNotAccess = access.length ? !access.includes(roleActive.type) : false

  return (
    <Select {...props} ref={customRef} isDisabled={isNotAccess || isDisabled} styles={{
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: isInvalid ? 'red' : 'grey',
      }),
      ...props.styles
    }} />
  )
}

export default BaseSelect

export function reorder(list, newOrder) {
  return list
    .reduce((init, next, index) => {
      Number.isFinite(newOrder[index]) && (init[newOrder[index]] = next)
      return init
    }, [])
    .filter((x) => !!x)
}

/**
 * Compares two user objects to determine if they are the same user.
 * 
 * @param {{ name?: string, id?: number }} a - The first user object to compare.
 * @param {{ name?: string, id?: number }} b - The second user object to compare.
 * @returns {boolean} `true` if both users have the same name and ID, otherwise `false`.
 */
export const isSameUser = (a, b) => a?.name === b?.name && a?.id === b?.id;


/**
 * Get Users From content_specific_users
 * 
 * @param {object} content 
 * @returns 
 */
export const getUsersContentSpesificStudent = (content) => {
  if (Array.isArray(content?.content_specific_users) && content?.content_specific_users?.length) {
    return content?.content_specific_users?.map(c => c?.user)
  }

  return []
}

/**
 * Get items that only occur in the left array,
 * using the compareFunction to determine equality.
 * 
 * @template T
 * @param {Array<T>} left - The array to be compared for items not present in the right array.
 * @param {Array<T>} right - The array to compare against.
 * @param {(a: T, b: T) => boolean} compareFunction - A function to determine if two items are equal.
 * 
 * @returns {Array<T>} An array containing items that are only present in the left array.
 */
export const compareArrayOnlyInLeft = (left, right, compareFunction) => {
  if (Array.isArray(left) && Array.isArray(right)) {
    return left?.filter(leftValue =>
      !right?.some(rightValue => 
        compareFunction(leftValue, rightValue)));
  }
}


export default {
  reorder,
}

import React from 'react'

export default function InfoGdrive() {
  return (
    <div
      className="mt-2 border rounded alert alert-warning w-100"
      role="alert"
    >
      <h6>
        <i className="fas fa-info-circle mr-1" />
        Mendapatkan link file dari Google Drive
      </h6>
      <ol className="mb-0 pl-4">
        <li>
          Pilih file di{' '}
          <a
            href="https://drive.google.com/"
            target="__blank"
          >
            Google Drive
          </a>
          .
        </li>
        <li>
          Klik kanan pada file, pilih <strong>Dapatkan link</strong>/
          <strong>Get link</strong>
        </li>
        <li>Set link Public, salin/copy link</li>
        <li>
          Hilangkan <strong>/view?usp=sharing</strong> dari link
        </li>
      </ol>
    </div>
  )
}

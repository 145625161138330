import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import BackButton from 'component/button/Back'
import SubmitButton from 'component/button/Submit'
import { FormSwitch } from 'component/forms'
import {
  FormInputName,
  FormInputUrutan,
  FormSelectSection,
  FormSelectPrecontent,
  FormInputTanggal,
  FormActiveStatus,
  FormSelectFeedback,
  FormActiveKey,
  FormInputEndedAt,
  FormInputDesc,
  FormTextArea,
  FormSetToBeScore,
  FormSelectStudent,
} from 'component/content-form'

export default function AddContentWhiteboard() {
  /* ========================================== Helpers ========================================= */
  const history  = useHistory()
  const location = useLocation()
  const params   = useParams()
  
  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()
  
  /* ========================================= Constants ======================================== */
  const sections = location.state.sectionList ? location.state.sectionList : []
  const links = [
    { url: '/kelas', name: 'Mata Pelajaran' },
    { url: `/kelas/${location.state.kelasId}`, name: 'Overview' },
  ]

  /* ======================================= Local States ======================================= */
  const [namaKonten, setNamaKonten]             = useState('')
  const [sectionId, setSectionId]               = useState('')
  const [preContentId, setPrecontentId]         = useState('')
  const [active, setActive]                     = useState(0)
  const [displayAt, setDisplayAt]               = useState('')
  const [urutan, setUrutan]                     = useState('')
  const [existingContents, setExistingContents] = useState([])
  const [feeds, setFeeds]                       = useState([])
  const [feedId, setFeedId]                     = useState(null)
  const [isFeedActive, setIsFeedActive]         = useState(0)
  const [endedAt, setEndedAt]                   = useState('')
  const [description, setDescription]           = useState('')
  const [apresiasi, setApresiasi]               = useState('')
  const [rating, setRating]                     = useState(1)
  const [toBeScore, setToBeScore]   = useState({
    isScore: false,
    isKeterampilan: false,
    isPengetahuan: false,
    selectedTypeScore: '',
    isTypeScoreActive: true,
  })
  const [selectedStudents, setSelectedStudents] = useState([])

  const [enableGroupKey, setEnableGroupKey] = useState(false)
  const [prerequisite, setPrerequisite]     = useState('')

    // FORM VALIDATION STATES
  const [isNamaInvalid, setIsNamaInvalid]       = useState(false)
  const [isUrutanInvalid, setIsUrutanInvalid]   = useState(false)
  const [isSectionInvalid, setIsSectionInvalid] = useState(false)
  const [loadingSubmit, setLoadingSubmit]       = useState(false)

    // FORM REF
  const refInputName     = useRef()
  const refSelectSection = useRef()
  const refInputUrutan   = useRef()

  /* ========================================= Functions ======================================== */
  function getExistingContentsBySelectedSection() {
    if (!sectionId) {
      setExistingContents([])
    } else {
      sections.forEach((section) => {
        if (section.id === sectionId) {
          setExistingContents(section.contents)
        }
      })
    }
  }

  async function getFeedsList() {
    const response = await getRequest('feed')
    if (response) {
      const options = response.data.map((item) => ({
        value: item.id,
        label: item.description,
      }))
      setFeeds(options)
    } else {
      console.error('ERROR', response)
    }
  }

  function handleChangeName(event) {
    setNamaKonten(event.target.value)
  }

  function handleChangeUrutan(event) {
    setUrutan(event.target.value)
  }

  function handleChangeSection(event) {
    setSectionId(event.target.value)
  }

  function handleChangePrecontent(event) {
    setPrecontentId(event.target.value)
  }

  function handleChangeActive() {
    if (active === 1) {
      setActive(0)
    }
    if (active === 0) {
      setActive(1)
    }
  }

  function handleChangeEnableGroupKey() {
    if (enableGroupKey) {
      setEnableGroupKey(false)
    } else {
      setEnableGroupKey(true)
    }
  }

  function handleChangeDate(event) {
    setDisplayAt(event.target.value)
  }

  function handleChangeFeedback(val) {
    setFeedId(val)
  }

  function handleChangeDesc(event) {
    setDescription(event.target.value)
  }

  function handleChangeEndedAt(event) {
    setEndedAt(event.target.value)
  }

  function handleChangeApresiasi(event) {
    setApresiasi(event.target.value)
  }

  function handleChangeRating(value) {
    setRating(value ? 1 : 0)
  }

  function toBeScoreValidation() {
    if (toBeScore.isScore) {
      return (toBeScore.isKeterampilan === true || toBeScore.isPengetahuan === true) && toBeScore.selectedTypeScore
    }

    return true
  }

  function handleValidation() {
    const newUrutan = urutan ? Math.abs(parseInt(urutan)) : 0
    const feedback = isFeedActive === 1 ? feedId : ''

    if (namaKonten && sectionId && toBeScoreValidation()) {
      setIsNamaInvalid(false)
      setIsUrutanInvalid(false)
      setIsSectionInvalid(false)
      handleSubmitForm(
        namaKonten,
        sectionId,
        newUrutan,
        active,
        displayAt,
        preContentId,
        feedback.value,
        endedAt,
        description,
        apresiasi,
        rating,
      )
    } else {
      if (!sectionId) {
        setIsSectionInvalid(true)
        refSelectSection.current.focus()
      } else {
        setIsSectionInvalid(false)
      }

      if (!urutan) {
        setIsUrutanInvalid(true)
        refInputUrutan.current.focus()
      } else {
        setIsUrutanInvalid(false)
      }

      if (!namaKonten) {
        setIsNamaInvalid(true)
        refInputName.current.focus()
      } else {
        setIsNamaInvalid(false)
      }

      window.notification('Mohon', 'lengkapi data', 'warning')

      if (!toBeScoreValidation()) {
        window.notification('Mohon', 'lengkapi juga data "Jadikan Sebagai Penilaian"')
      }
    }
  }

  async function handleSubmitForm(
    title,
    sectionId,
    urutan,
    active,
    displayAt,
    preContentId,
    feedId,
    endedAt,
    description,
    apresiasi,
    rating,
  ) {
    let form = new FormData()
    form.append('content_type', 10)
    form.append('title', title)
    form.append('section_id', sectionId)
    form.append('urutan', urutan)
    form.append('active', active)
    form.append('rating', rating)
    form.append('class_id', params.kelasId)

    form.append('is_score', !!toBeScore.isScore)

    if (toBeScore.isScore) {
      form.append('is_keterampilan', !!toBeScore.isKeterampilan)
      form.append('is_pengetahuan', !!toBeScore.isPengetahuan)
      form.append('type_score_id', toBeScore.selectedTypeScore)
    }

    if (Array.isArray(selectedStudents) && !!selectedStudents.length) {
      selectedStudents.forEach((selectedStudent, selectedStudentIndex) => {
        form.append(`spesifik_user_id[${selectedStudentIndex}]`, selectedStudent.id)
      })
    }

    if (feedId) {
      form.append('feedback', feedId)
    }

    if (displayAt) {
      form.append('display_at', displayAt)
    }

    if (endedAt) {
      form.append('ended_at', endedAt)
    }

    if (description) {
      form.append('deskripsi', description)
    }

    if (preContentId) {
      form.append('precontent_id', preContentId)
    }

    if (apresiasi) {
      form.append('apresiasi', apresiasi)
    }

    setLoadingSubmit(true)
    const response = await postRequest('contents', form)
    if (response) {
      if (enableGroupKey) {
        let formkey = new FormData()
        formkey.append('description', prerequisite)
        formkey.append('content_id', response.data.id)

        const response1 = await postRequest('contentTakeAway', formkey)
        if (response1) {
          window.notification('Materi', 'berhasil ditambahkan', 'success')
          history.goBack()
        }
      } else {
        window.notification('Materi', 'berhasil ditambahkan', 'success')
        history.goBack()
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getFeedsList()
  }, [])

  useEffect(() => {
    getExistingContentsBySelectedSection()
  }, [sectionId])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Tambah Whiteboard"
          linkName="Whiteboard"
          links={links}
        />
        
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <h5>
                  <i className="fas fa-chalkboard mr-1" />
                  Whiteboard
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <form>
                      {/* Input title */}
                      <FormInputName
                        label="Judul"
                        placeholder="Judul whiteboard"
                        namaKonten={namaKonten}
                        refInputName={refInputName}
                        isNamaInvalid={isNamaInvalid}
                        handleChangeName={handleChangeName}
                      />

                      {/* Input urutan */}
                      <FormInputUrutan
                        urutan={urutan}
                        isUrutanInvalid={isUrutanInvalid}
                        refInputUrutan={refInputUrutan}
                        handleChangeUrutan={handleChangeUrutan}
                      />

                      {/* Input deskripsi */}
                      <FormInputDesc
                        description={description}
                        handleChangeDesc={handleChangeDesc}
                      />

                      {/* Input Apresiasi */}
                      <FormTextArea
                        id="formInputApresiasi"
                        label="Apresiasi"
                        placeholder="Apresiasi/pesan setelah siswa menyelesaikan materi (opsional)"
                        className="mb-5"
                        value={apresiasi}
                        handleChange={handleChangeApresiasi}
                      />

                      {/* Select section */}
                      <FormSelectSection
                        sections={sections}
                        isSectionInvalid={isSectionInvalid}
                        handleChangeSection={handleChangeSection}
                        existingSectionId={sectionId}
                        refSelectSection={refSelectSection}
                      />

                      {/* Select precontent */}
                      <FormSelectPrecontent
                        existingContents={existingContents}
                        handleChangePrecontent={handleChangePrecontent}
                        existingPrecontentId={preContentId}
                      />

                      {/* Input display at */}
                      <FormInputTanggal
                        handleChangeDate={handleChangeDate}
                        existingDisplayAt={displayAt}
                      />

                      {/* Input ended at */}
                      <FormInputEndedAt
                        endedAt={endedAt}
                        handleChangeEndedAt={handleChangeEndedAt}
                      />

                      {/* Rating */}
                      <FormSwitch
                        id="formRating"
                        label="Rating Materi"
                        value={rating}
                        handleChange={handleChangeRating}
                      />

                      {/* Select Feedback */}
                      <FormSelectFeedback
                        existingFeeds={feeds}
                        existingFeedId={feedId}
                        isFeedActive={isFeedActive}
                        setIsFeedActive={setIsFeedActive}
                        handleChangeFeeds={handleChangeFeedback}
                        getFeedList={getFeedsList}
                      />

                      {/* Select Specific Students */}
                      <FormSelectStudent 
                        selectedStudents={selectedStudents}
                        setSelectedStudents={setSelectedStudents}
                        subMapelId={params?.kelasId}
                        isWithCheckSavedOrNot={false}
                      />

                      {/* Select To Be Score Or Not */}
                      <FormSetToBeScore
                        value={toBeScore}
                        handleChange={setToBeScore}
                      />

                      {/* Select active status */}
                      <FormActiveStatus
                        existingStatus={active}
                        handleChangeStatus={handleChangeActive}
                      />

                      <FormActiveKey
                        enableGroupKey={enableGroupKey}
                        handleChangeEnableGroupKey={
                          handleChangeEnableGroupKey
                        }
                        prerequisite={prerequisite}
                        setPrerequisite={setPrerequisite}
                      />

                      <div className="form-group">
                        <BackButton />
                        {loadingSubmit ? (
                          <button
                            className="btn btn-success"
                            disabled={true}
                          >
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                            />
                            Simpan
                          </button>
                        ) : (
                          <SubmitButton
                            onClick={() => handleValidation()}
                            text="Simpan"
                          />
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React from 'react'
import CkeditorComponent from 'component/ckeditor'

export default function FormActiveKey({
  enableGroupKey,
  handleChangeEnableGroupKey,
  prerequisite,
  setPrerequisite,
}) {
  return (
    <div className="mb-5">
      <div className="form-group row mb-2">
        <label className="col-sm-3 col-form-label">Key Take Away</label>
        <div className="form-group col-sm-9">
          <div className="switch switch-primary d-inline m-r-10">
            <input
              onChange={handleChangeEnableGroupKey}
              checked={enableGroupKey}
              type="checkbox"
              id="switch-key"
            />
            <label
              htmlFor="switch-key"
              className="cr"
            ></label>
          </div>
          <label htmlFor="switch-key">
            {enableGroupKey ? 'Aktif' : 'Tidak aktif'}
          </label>
        </div>
      </div>
      {enableGroupKey ? (
        <>
          <div className="form-group row">
            <label className="offset-sm-2 col-sm-2 col-form-label"></label>
            <div className="col-sm-8">
              <CkeditorComponent
                onChange={(data) => setPrerequisite(data)}
                data={prerequisite}
              ></CkeditorComponent>
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}

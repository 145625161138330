import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import DataTable from 'component/data-table'
import { noImage } from 'util/constant'

export default function GroupDetailKelas({ idGroup }) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, deleteRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [data, setData]               = useState([])
  const [limitClass, setLimitClass]   = useState(10)
  const [offsetClass, setOffsetClass] = useState(0)
  const [searchClass, setSearchClass] = useState('')
  const [totalRecord, setTotalRecord] = useState(0)
  const [isLoading, setIsLoading]     = useState(false)

  const [data1, setData1]               = useState([])
  const [limitClass1, setLimitClass1]   = useState(10)
  const [offsetClass1, setOffsetClass1] = useState(0)
  const [searchClass1, setSearchClass1] = useState('')
  const [totalRecord1, setTotalRecord1] = useState(0)

  const [dataSemester, setDataSemester]     = useState([])
  const [dataBlock, setDataBlock]           = useState([])
  const [semester, setSemester]             = useState('')
  const [block, setBlock]                   = useState('')
  const [activeSemester, setActiveSemester] = useState('')
  const [activeBlock, setActiveBlock]       = useState('')
  const [taTahunCode, setTaTahunCode]       = useState('')

  /* ========================================= Functions ======================================== */
  async function getClassRequired() {
    setIsLoading(true)
    let response = await getRequest(
      `groups-class?group_id=${idGroup}&period_id=${block}&limit=${limitClass}&offset=${offsetClass}&search=${searchClass}&ta_semester_code=${semester}&required=1`,
    )
    setIsLoading(false)
    if (response) {
      setData(() => [...response.data])
      setTotalRecord(response.totalRecords)
    }
  }

  async function getClass() {
    let response = await getRequest(
      `groups-class?group_id=${idGroup}&limit=${limitClass1}&offset=${offsetClass1}&search=${searchClass1}&required=0`,
    )
    if (response) {
      setData1(() => [...response.data])
      setTotalRecord1(response.totalRecords)
    }
  }

  async function getSemester() {
    let response = await getRequest(
      `semester?ta_tahun_code=${taTahunCode}`,
    )
    if (response) {
      setDataSemester(response.data)
    }
  }

  async function getBlock() {
    let response = await getRequest(
      `reportPeriod?semester_code=${semester}`,
    )
    if (response) {
      setDataBlock(response?.data?.filter((d) => d.status))
    }
  }

  async function getActiveSemester() {
    let sms = localStorage.getItem('semester')
    if (sms) {
      var json = JSON.parse(sms)
      setActiveSemester(json.code)
      setSemester(json.code)
    }

    let thc = localStorage.getItem('group_ta_tahun_code')
    if (thc) {
      setTaTahunCode(thc)
    }
  }

  async function closeFromGroup(id) {
    Swal.fire({
      title: 'Kamu yakin?',
      text: 'Kamu ingin mengeluarkan sub mata pelajaran ini ke dari group?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, keluarkan!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await deleteRequest('groups-class?id=' + id)
        if (response) {
          window.notification(
            'Sukses',
            'Berhasil menghapus dari kelas',
            'success',
          )
          getClass()
          getClassRequired()
        }
      }
    })
  }
  
  async function addToGroup(class_id) {
    if (semester != '') {
      Swal.fire({
        title: 'Kamu yakin?',
        text: 'Kamu ingin menambahkan sub mata pelajaran ini ke dalam group?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, masukan!',
        cancelButtonText: 'Tidak',
      }).then(async (result) => {
        if (result.isConfirmed) {
          var form = new FormData()
          form.append('group_id', idGroup)
          form.append('class_id', class_id)
          form.append('period_id', block)
          form.append('ta_semester_code', semester)
          let response = await postRequest('groups-class', form)
          if (response) {
            window.notification(
              'Sukses',
              'Berhasil menambahkan ke kelas',
              'success',
            )
            getClass()
            getClassRequired()
          }
        }
      })
    } else {
      Swal.fire(
        'Anda ingin menambahkan sub mata pelajaran?',
        'pastikan anda telah memilih semester',
        'question',
      )
    }
  }

  /* ======================================== Components ======================================== */
  function RenderItem({ item }) {    
    var ta = null
    if (item.groupclass) {
      ta = item.groupclass.ta_semester
    }
    return (
      <tr>
        <td style={{ whiteSpace: 'normal' }}>
          <div style={{ width: 150, textAlign: 'justify' }}>
            {ta
              ? `${ta.tahun_mulai}/${ta.tahun_selesai} ${ta.title_render}`
              : null}{' '}
            {ta && ta.code == activeSemester ? '(Aktif)' : null}
          </div>
        </td>
        <td>
          <div>
            <img
              src={item.linkImg}
              alt={item.title}
              className="img-radius mr-2 bg-light"
              style={{ width: 40, height: 40 }}
              onError={(e) => (e.currentTarget.src = noImage)}
            />
            <span>
              {!item.class_profiles[0].is_owner &&
                item.class_profiles[0].status == 'approved' && (
                  <span className="badge badge-secondary">Bersama</span>
                )}
              {!item.class_profiles[0].is_owner &&
                item.class_profiles[0].status == 'duplicate' && (
                  <span className="badge badge-info">Duplicate</span>
                )}{' '}
              {item.title}
            </span>
          </div>
        </td>
        <td style={{ whiteSpace: 'normal' }}>
          <div style={{ width: 200, textAlign: 'justify' }}>
            {item.class_profiles[0].category?.title}
          </div>
        </td>
        <td style={{ whiteSpace: 'normal' }}>
          {item?.groupclasses?.every((gcs) => !gcs.report_period) && (
            <span className="badge badge-danger badge-small">
              <i className="fas fa-exclamation-circle" /> Belum ada
            </span>
          )}

          {item?.groupclasses?.map((gcs, idx) => {
            return gcs.report_period?.name_period ? (
              <span
                key={`item_badge_` + gcs.id + idx}
                className="badge badge-primary badge-small mr-1"
              >
                {gcs.report_period.name_period}
              </span>
            ) : null
          })}
        </td>
        <td>
          <Button item={item} />
        </td>
      </tr>
    )
  }

  function Button({ item }) {
    const isAdded = item?.groupclasses?.some(
      (gcs) => gcs.ta_semester_code === semester && gcs.period_id === block,
    )
    if (semester != activeSemester) {
      return null
    } else if (
      item.groupclass &&
      item.groupclass.ta_semester_code == semester
    ) {
      return (
        <>
          {isAdded || !block ? (
            <button
              onClick={() => {
                closeFromGroup(item.groupclass.id)
              }}
              className="btn btn-danger btn-sm mr-1"
            >
              <i className="fas fa-minus" />
            </button>
          ) : (
            <button
              onClick={() => {
                addToGroup(item.id)
              }}
              className="btn btn-success btn-sm mr-1"
            >
              <i className="fas fa-plus" />
            </button>
          )}
        </>
      )
    } else {
      return (
        <button
          onClick={() => {
            addToGroup(item.id)
          }}
          className="btn btn-success btn-sm mr-1"
        >
          <i className="fas fa-plus" />
        </button>
      )
    }
  }

  const Loader = () => (
    <div
      className="spinner-border text-white"
      role="status"
    ></div>
  )

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getActiveSemester()
    getSemester()
  }, [taTahunCode])

  useEffect(() => {
    getClass()
  }, [limitClass1, offsetClass1, searchClass1])

  useEffect(() => {
    ;(async () => {
      await getClassRequired()
    })()
  }, [limitClass, offsetClass, searchClass, block])

  useEffect(() => {
    ;(async () => {
      await getBlock()
      await getClassRequired()
    })()
  }, [semester])

  /* ========================================== Output ========================================== */
  return (
    <div className="dt-responsive table-responsive">
      <div className="position-sticky">
        <div className="row">
          <div className="col-md-2 col-sm-12">
            <button
              disabled={isLoading}
              className="btn btn-primary float-left"
              data-toggle="modal"
              data-target=".bd-class-modal-lg"
            >
              {isLoading ? <Loader /> : 'Kelompokkan Submapel ke dalam Periode'}
            </button>
          </div>
          <div className="col-md-5 col-sm-12">
            <select
              onChange={(val) => {
                setData([])
                setTotalRecord(0)
                setSearchClass('')
                setLimitClass(10)
                setOffsetClass(0)
                setSemester(val.target.value)
              }}
              className="form-control col-md-6 float-right"
            >
              <option value="">- Tampilkan semua -</option>
              {dataSemester.map((val, i) => {
                return (
                  <option
                    selected={val.code == activeSemester}
                    value={val.code}
                  >
                    {`${val.tahun_mulai}/${val.tahun_selesai} ${val.title_render}`}{' '}
                    {val.code == activeSemester ? '(Aktif)' : null}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="col-md-5 col-sm-12">
            <select
              onChange={(val) => {
                setData([])
                setBlock(val.target.value)
              }}
              className="form-control col-md-6 float-right"
            >
              <option value="">- Tampilkan semua -</option>
              {dataBlock.map((val, i) => {
                return (
                  <option
                    selected={val.id == activeBlock}
                    value={val.id}
                  >{`${val.name_period}`}</option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
      <br />

      <DataTable
        className={'table table-striped table-bordered nowrap'}
        data={data}
        totalRows={totalRecord}
        show={[10, 20, 30, 40, 50]}
        onChangeSearch={(val) => {
          setSearchClass(val)
        }}
        onChangeLimit={(val) => {
          setLimitClass(val)
        }}
        onChangePage={(val) => {
          setOffsetClass(val)
        }}
        column={[
          'Semester',
          'Sub Mata Pelajaran',
          'Mata Pelajaran',
          'Period',
          'Aksi',
        ]}
        renderItem={(item, i) => (
          <RenderItem
            item={item}
            key={i}
          />
        )}
      />
      <div
        className="modal fade bd-class-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title h4"
                id="myLargeModalLabel"
              >
                Daftar Sub Matapelajaran
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <DataTable
                  className={'table table-striped table-bordered nowrap'}
                  data={data1}
                  totalRows={totalRecord1}
                  show={[10, 20, 30, 40, 50]}
                  onChangeSearch={(val) => {
                    setSearchClass1(val)
                  }}
                  onChangeLimit={(val) => {
                    setLimitClass1(val)
                  }}
                  onChangePage={(val) => {
                    setOffsetClass1(val)
                  }}
                  column={[
                    'Semester',
                    'Sub Mata Pelajaran',
                    'Mata Pelajaran',
                    'Period',
                    'Aksi',
                  ]}
                  renderItem={(item, i) => (
                    <RenderItem
                      item={item}
                      key={i}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

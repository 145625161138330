import { useEffect, useState } from 'react'
import { useBaseContext } from 'context/base'
import useContentPlayerContext from 'context/content-player'

export default function useQuizComponent() {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()
  const { content } = useContentPlayerContext()

  /* ========================================= Constants ======================================== */
  const { id } = JSON.parse(localStorage.getItem('user'))
  const quizId = content.contents_url.quiz_id

  /* ========================================== States ========================================== */
  const [shownIndex, setShownIndex] = useState(0)
  const [quizUser, setQuizUser] = useState(null)
  const [quizHistory, setQuizHistory] = useState([])
  const [questions, setQuestions] = useState([])
  const [questionOrders, setQuestionsOrders] = useState([])
  const [isQuizOnlyPG, setIsQuizOnlyPG] = useState(false)
  const [showMyQuiz, setShowMyQuiz] = useState(false)
  const [showMyScore, setShowMyScore] = useState(false)
  const [loadingMyQuiz, setLoadingMyQuiz] = useState(false)
  const [loadingMyScore, setLoadingMyScore] = useState(false)

  /* ========================================= Functions ======================================== */
  async function handleShowMyQuiz() {
    if (!quizUser) {
      setLoadingMyQuiz(true)
      await getQuizUserAndAnswers({ withQuestion: true })
      setLoadingMyQuiz(false)
    }
    setShowMyQuiz(!showMyQuiz)
  }

  async function handleShowMyScore() {
    if (!showMyScore) {
      setLoadingMyScore(true)
      await getQuizUserOnly()
      setLoadingMyScore(false)
    }
    setShowMyScore(!showMyScore)
  }

  async function getQuizUserAndAnswers({ withQuestion = true }) {
    const response = await getRequest(
      `quiz-detail-user?id=${id}&quiz_id=${quizId}&content_id=${content.id}`,
    )
    if (response) {
      if (withQuestion) {
        function processHistory(history) {
          const temp = [...history].map(h => ({ ...h, isRemidi: true }))
          if (response.data.quiz_questions[0].quiz_temps.length) {
            temp.push({ ...response.data.quiz_users, isRemidi: false })
          }
          setQuizHistory(temp)
          setShownIndex(temp.length - 1)
        }

        function processQuestions(questions) {
          const temp = [...questions].map(q => {
            if (response.data.quiz_questions[0].quiz_temps.length) {
              q.quiz_match_answers.push(q.quiz_temps[0])
            }
            return q
          });

          setQuestions(temp)
        }

        setQuizUser(response.data.quiz_users)
        setQuestionsOrders(response.data?.question_orders)
        processQuestions(response.data.quiz_questions)
        processHistory(response.data.quiz_users_history)
        checkIsOnlyPG(response.data.quiz_questions)
      } else {
        setQuizUser(response.data.quiz_users)
      }
    }
  }

  function updateAnswers(index) {
    let temp = [...questions]
    temp = questions.map((q) => ({
      ...q,
      quiz_temps: [q.quiz_match_answers[index]]
    }))
    setQuestions(temp)
  }

  async function getQuizUserOnly() {
    const response = await getRequest(
      `quiz-user-only?user_id=${id}&quiz_id=${quizId}&content_id=${content.id}`,
    )
    response && response.data && setQuizUser(response.data)
  }

  function checkIsOnlyPG(questions) {
    const isPgOnly = questions.every((q) => q.quest_type === 0)
    setIsQuizOnlyPG(isPgOnly)
  }

  function getQuestionOrder() {
    if (questionOrders) {
      return questionOrders?.[shownIndex] || []
    }
    return []
  }

  useEffect(() => {
    updateAnswers(shownIndex)
  }, [shownIndex])

  return {
    questions,
    questionOrders,
    getQuestionOrder,
    history: quizHistory,
    shown: {
      data: showMyQuiz,
      score: showMyScore,
      index: shownIndex,
    },
    user: quizUser,
    isPgOnly: isQuizOnlyPG,
    loading: {
      data: loadingMyQuiz,
      score: loadingMyScore,
    },
    toggle: {
      data: () => handleShowMyQuiz(),
      score: () => handleShowMyScore(),
      index: (index) => setShownIndex(index)
    },
  }
}

import React from "react";

export default function FormInput({ 
  id = 'formTextarea',
  labelForm = 'Label',
  value = '', 
  type = "text", //Form input types
  placeholder = '',
  onChange = () => {}, 
  minLength = 0,
  maxLength = 255,
  disabled = false,
  required = false,
  ...rest
}) {
  if (typeof id === "string" && id !== "") {
    return (
      <div className={`form-group row`}>
        <label className="col-sm-3 col-form-label" htmlFor={id}>
          <span className="mr-1">{labelForm}{required ? <span className='text-danger'>*</span> : null }</span>
        </label>
        <div className="col-sm-9">
          <input  
            className="form-control"
            id={id}
            value={value}
            type={type}
            disabled={disabled}
            onChange={onChange}
            placeholder={placeholder}
            minLength={minLength}
            maxLength={maxLength}
            {...rest}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
}
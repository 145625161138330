import React, { useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { Back, Submit } from 'component/button'
import MediaNotice from 'pages/quiz/media-notice'
import { editorConfiguration } from 'util/ckeditor-config'

const FormAddUraian = () => {
  /* ========================================== Helpers ========================================= */
  const history    = useHistory()
  const { quizId } = useParams()

  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const questType = 1
  const links = [
    { url: '/kelola-quiz', name: 'Kelola QUiz' },
    { url: `/kelola-quiz/${quizId}`, name: 'Daftar Pertanyaan ' },
  ]

  /* ======================================= Local States ======================================= */
  const [textPertanyaan, setTextPertanyaan] = useState('')
  const [nomorUrut, setNomorUrut]           = useState('')
  const [status, setStatus]                 = useState(0)
  const [jawaban, setJawaban]               = useState('')
  const [pembahasan, setPembahasan]         = useState('')
  const [bobotNilai, setBobotNilai]         = useState('')

  // FORM VALIDATION STATE
  const [isUrutanInvalid, setIsUrutanInvalid]                 = useState(false)
  const [isTextPertanyaanInvalid, setIsTextPertanyaanInvalid] = useState(false)
  const [isBobotNilaiInvalid, setIsBobotNilaiInvalid]         = useState(false)

  /* =========================================== Refs =========================================== */
  const refNomorUrut  = useRef()
  const refBobotNilai = useRef()

  /* ========================================= Functions ======================================== */
  function handleChangeUrutan(event) {
    setNomorUrut(Math.abs(event.target.value))
  }

  function handleChangeStatus(event) {
    switch (event.target.checked) {
      case true:
        setStatus(1)
        break
      case false:
        setStatus(0)
        break
      default:
        break
    }
  }

  function handleChangeBobotNilai(event) {
    if (event.target.value) {
      if (event.target.value <= 100) {
        const num = Math.abs(event.target.value)
        setBobotNilai(num)
      }
    } else {
      setBobotNilai('')
    }
  }

  function handleFormValidation() {
    if (nomorUrut && textPertanyaan && bobotNilai) {
      const arrayJawaban = [
        {
          text: jawaban ? jawaban : '<p></p>',
          correct: 1,
          active: 1,
          nilai: bobotNilai,
        },
      ]
      setIsUrutanInvalid(false)
      setIsTextPertanyaanInvalid(false)
      handleFormSubmit(
        quizId,
        nomorUrut,
        textPertanyaan,
        questType,
        status,
        arrayJawaban,
        pembahasan,
      )
    } else {
      if (!bobotNilai) {
        setIsBobotNilaiInvalid(true)
        refBobotNilai.current.focus()
      } else {
        setIsBobotNilaiInvalid(false)
      }

      if (!textPertanyaan) {
        setIsTextPertanyaanInvalid(true)
      } else {
        setIsTextPertanyaanInvalid(false)
      }

      if (!nomorUrut) {
        setIsUrutanInvalid(true)
        refNomorUrut.current.focus()
      } else {
        setIsUrutanInvalid(false)
      }

      window.notification('Data belum lengkap', 'Mohon lengkapi data')
    }
  }

  async function handleFormSubmit(
    quiz_id,
    urutan,
    text,
    quest_type,
    active,
    jawaban,
    pembahasan,
  ) {
    let form = new FormData()
    form.append('quiz_id', quiz_id)
    form.append('urutan', urutan)
    form.append('text', text)
    form.append('quest_type', quest_type)
    form.append('active', active)
    form.append('jawaban', JSON.stringify(jawaban))
    if (pembahasan) {
      form.append('pembahasan', pembahasan)
    }

    const response = await postRequest('questions', form)
    if (response) {
      window.notification(
        'Sukses',
        'Pertanyaan berhasil ditambahkan',
        'success',
      )
      history.goBack()
    } else {
      console.error('ERROR', response)
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Tambah Pertanyaan" links={links} />
        
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <h5 className="modal-title">
                  <i className="fas fa-pen mr-1" />
                  Informasi Uraian
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <form>
                      {/* Input urutan pertanyaan */}
                      <div className="form-group mb-3 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="urutan-uraian"
                        >
                          <span>Nomor urut</span>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <div className="col-sm-2">
                          <input
                            type="number"
                            min={0}
                            value={nomorUrut}
                            onChange={(event) => handleChangeUrutan(event)}
                            ref={refNomorUrut}
                            className={`${
                              isUrutanInvalid ? 'is-invalid' : ''
                            } form-control`}
                            id="urutan-uraian"
                            placeholder="Nomor urut"
                          />
                        </div>
                        {isUrutanInvalid ? (
                          <div className="offset-sm-3 col-sm-9">
                            <span className="col small form-text text-danger">
                              Nomor urut tidak boleh kosong.
                            </span>
                          </div>
                        ) : null}
                      </div>

                      {/* Select status pertanyaan */}
                      <div className="form-group mb-3 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="status-uraian"
                        >
                          <span className="mr-1">Status</span>
                        </label>
                        <div className="col-sm-9">
                          <div className="switch switch-primary d-inline m-r-10">
                            <input
                              onChange={(event) => handleChangeStatus(event)}
                              checked={status === 1}
                              type="checkbox"
                              id="switch-status-uraian"
                            />
                            <label
                              htmlFor="switch-status-uraian"
                              className="cr"
                            ></label>
                          </div>
                          <label htmlFor="switch-status-uraian">
                            {status === 1 ? 'Aktif' : 'Tidak Aktif'}
                          </label>
                        </div>
                      </div>

                      {/* Pertanyaan uraian*/}
                      <div className="form-group row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Pertanyaan
                          <span className="text-danger ml-1">*</span>
                        </label>

                        {/* input pertanyaan */}
                        <div className="col-sm-9">
                          <MediaNotice />
                          <CKEditor
                            editor={Editor}
                            data={textPertanyaan}
                            config={{...editorConfiguration, placeholder: 'Tulis pertanyaan...'}}
                            onChange={(_, editor) => {
                              const data = editor.getData()
                              setTextPertanyaan(data)
                            }}
                          />

                          {isTextPertanyaanInvalid ? (
                            <span className="col small form-text text-danger">
                              Pertanyaan tidak boleh kosong.
                            </span>
                          ) : null}
                        </div>
                      </div>

                      {/* Input Pembahasan */}
                      <div className="form-group row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Pembahasan
                        </label>
                        <div className="col-sm-9">
                          <CKEditor
                            editor={Editor}
                            data={pembahasan}
                            config={{...editorConfiguration, placeholder: 'Tulis pembahasan...'}}
                            onChange={(_, editor) => {
                              const data = editor.getData()
                              setPembahasan(data)
                            }}
                          />
                        </div>
                      </div>

                      {/* Input jawaban */}
                      <div className="form-group mb-5 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="jawaban-uraian"
                        >
                          <span>Jawaban</span>
                        </label>
                        <div className="col-sm-9">
                          <CKEditor
                            editor={Editor}
                            data={jawaban}
                            config={{...editorConfiguration, placeholder: 'Tulis jawaban...'}}
                            onChange={(_, editor) => {
                              const data = editor.getData()
                              setJawaban(data)
                            }}
                          />
                        </div>
                      </div>

                      {/* Bobot nilai soal */}
                      <div className="form-group mb-5 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="bobotNilai"
                        >
                          <span>Bobot Soal</span>
                          <span className="text-danger ml-1">*</span>
                          <button
                            type="button"
                            className="btn p-0 ml-2"
                            data-toggle="popover"
                            data-placement="top"
                            data-content="Bobot nilai per-soal yang akan diakumulasi dengan seluruh soal"
                            data-original-title=""
                          >
                            <i className="fas fa-question-circle" />
                          </button>
                        </label>
                        <div className="col-sm-9">
                          <input
                            value={bobotNilai}
                            onChange={(event) => handleChangeBobotNilai(event)}
                            ref={refBobotNilai}
                            type="number"
                            min={0}
                            max={100}
                            className={`${
                              isBobotNilaiInvalid ? 'is-invalid' : ''
                            } form-control`}
                            style={{
                              width: '150px',
                            }}
                            placeholder="Bobot penilaian"
                            id="bobotNilai"
                          />
                          {isBobotNilaiInvalid ? (
                            <span className="col small form-text text-danger">
                              Bobot nilai tidak boleh kosong.
                            </span>
                          ) : null}
                        </div>
                      </div>

                      {/* Form keterangan */}
                      <div className="form-group d-flex">
                        <label className="text-muted mr-2">Keterangan:</label>
                        <span className="text-danger mr-1">*</span>
                        <label className="text-muted">Wajib diisi</label>
                      </div>

                      {/* Form button */}
                      <div className="form-group">
                        <Back />
                        <Submit
                          onClick={handleFormValidation}
                          text="Simpan"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

export default FormAddUraian

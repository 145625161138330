import React from 'react';

const LoadingSpinner = () => {
  return (
    <div className="d-flex justify-content-center">
      <div className="spinner-border text-primary" style={{width: "2rem", height: "2rem"}} role="status"><span className="sr-only">Loading...</span></div>
    </div>
  );
}

export default LoadingSpinner;
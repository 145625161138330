import React, { useEffect, useState, useRef } from 'react'
import Select from 'react-select'
import { useBaseContext } from 'context/base'

export default function SelectSemester({
  label = '',
  allSemester = false, // booelan
  ta_tahun_code = '', // code tahun ajaran
  value, // { label: <label>, value:  <value>}
  onChange = function (value) {}, // function with parameter value
  isLoading = false, // boolean
  isDisabled = false, // boolean
}) {
  /* =========================================== Refs =========================================== */
  let isMounted = useRef(true)

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [options, setOptions] = useState([])

  /* ========================================= Functions ======================================== */
  const getSemesters = async (ta_tahun_code) => {
    const response = await getRequest(
      `semester?ta_tahun_code=${ta_tahun_code}`,
    )
    if (response) {
      const options = response.data.map((item) => ({
        value: item.code,
        label: `${item.tahun_mulai}/${item.tahun_selesai} - ${
          item.title_render
        }${item.status ? ' (Aktif)' : ''}`,
      }))
      if (isMounted.current === true) setOptions(options)
    }
  }

  const getSemesterAll = async () => {
    const response = await getRequest('semester')
    if (response) {
      const options = response.data.map((item) => ({
        value: item.code,
        label: `${item.tahun_mulai}/${item.tahun_selesai} - ${
          item.title_render
        }${item.status ? ' (Aktif)' : ''}`,
      }))
      if (isMounted.current === true) setOptions(options)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (allSemester) {
      getSemesterAll()
    } else {
      if (ta_tahun_code) getSemesters(ta_tahun_code)
    }
  }, [ta_tahun_code, allSemester])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <>
      {label ? <span className="f-w-700 f-14">Semester</span> : null}
      <Select
        className={label ? 'mt-2' : ''}
        placeholder="Pilih semester..."
        isMulti={false}
        options={options}
        value={value}
        onChange={onChange}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
    </>
  )
}

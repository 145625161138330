import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useBaseContext } from 'context/base'
import Content from './content'

const Section = ({ item, index, sections, access }) => {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const { kelasId } = useParams()

  /* ====================================== Consume Context ===================================== */
  const { deleteRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [isHovered, setIsHovered] = useState(false)

  /* ========================================= Functions ======================================== */
  function handleDeleteSection() {
    Swal.fire({
      title: 'Apa anda yakin?',
      text: `Anda akan menghapus ${item.title}!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`sections?id=${item.id}`)
        if (response) {
          Swal.fire('Berhasil', 'Section berhasil dihapus.', 'success').then(
            () => history.go(0),
          )
        } else {
          Swal.fire(
            'Oppsss!',
            'Terjadi kesalahan coba beberapa saat lagi.',
            'error',
          )
        }
      }
    })
  }

  /* ========================================== Output ========================================== */
  return (
    <div className="mb-0 border-top border-right border-left border-bottom">
      <div
        id={item.id}
        style={{
          backgroundColor: isHovered ? '#f6f6f6' : '#f8f9fa',
        }}
      >
        <h5 className="row m-0 border-bottom">
          <div
            className="col row text-dark py-2 m-0"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={(event) => event.preventDefault()}
          >
            <div
              className="col px-0 d-flex justify-content-between align-items-center"
              style={{ height: '50px' }}
            >
              <span className="f-16">
                {item.urutan}
                {'. '}
                {item.title}{' '}
                {item.active ? (
                  <span
                    className="badge badge-success ml-2"
                    style={{ fontSize: '12px' }}
                  >
                    Aktif
                  </span>
                ) : (
                  <span
                    className="badge badge-secondary ml-2"
                    style={{ fontSize: '12px' }}
                  >
                    Tidak Aktif
                  </span>
                )}
              </span>
              <span
                style={{
                  display: isHovered ? 'none' : 'inline-block',
                  fontWeight: '400',
                }}
              >
                {item.contents.length}
                {' materi'}
              </span>
              <span
                style={{
                  display: isHovered ? 'inline-block' : 'none',
                }}
              >
                <button
                  type="button"
                  className={`btn btn-sm btn-success ml-2 ${
                    access ? 'btn-forbidden ' : ''
                  }`}
                  title="Edit"
                  disabled={access}
                  onClick={() =>
                    history.push(`/kelas/${kelasId}/edit-section/${item.id}`, {
                      dataSection: item,
                      kelasId: item.class_id,
                    })
                  }
                >
                  <i className="feather icon-edit mr-1" />
                  <span>Edit</span>
                </button>
                <button
                  type="button"
                  className={`btn btn-sm btn-danger ml-2 ${
                    access ? 'btn-forbidden ' : ''
                  }`}
                  title="Hapus"
                  disabled={access}
                  onClick={handleDeleteSection}
                >
                  <i className="feather icon-trash-2 mr-1" />
                  <span>Hapus</span>
                </button>
              </span>
            </div>
          </div>
        </h5>
      </div>
      <div className="collapse show">
        <ul className="list-unstyled mb-0">
          {item.contents.length !== 0 ? (
            item.contents.map((content, index) => (
              <Content
                key={content.id}
                item={content}
                index={index}
                classId={item.class_id}
                sections={sections}
                access={access}
              />
            ))
          ) : (
            <p className="text-muted p-3 mb-0">Belum ada materi.</p>
          )}
        </ul>
      </div>
    </div>
  )
}

export default Section

import React from 'react'
import Swal from 'sweetalert2'
import { useBaseContext } from 'context/base'

export default function AssignmentSiswaItem({
  answer,
  setAssignmentModal,
  getDetailAssignmentSiswa,
}) {
  /* ====================================== Consume Context ===================================== */
  const { putRequest } = useBaseContext()

  /* ========================================= Functions ======================================== */
  async function handleDeleteAssignmentUsers(answer) {
    Swal.fire({
      icon: 'warning',
      title: `Tolak tugas?`,
      confirmButtonText: 'Ya, tolak!',
      confirmButtonColor: '#ff5252',
      cancelButtonText: 'Tidak',
      showCancelButton: true,
      input: 'textarea',
      inputPlaceholder: 'Catatan untuk siswa...',
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      preConfirm: (inputValue) => {
        return new Promise(async (resolve) => {
          let form = new FormData()
          if (inputValue) form.append('note', inputValue)
          const response = await putRequest(
            `assignment-siswa/${answer.id}/reject`,
            form,
          )
          if (response) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'success',
          title: `Tugas berhasil ditolak`,
        }).then(async () => await getDetailAssignmentSiswa())
      }
    })
  }

  /* ========================================== Output ========================================== */
  return (
    <tr>
      <td>{answer.content ? answer.content.title : '-'}</td>
      <td>
        {answer.score === null ? (
          <span className="badge badge-secondary">Belum diperiksa</span>
        ) : (
          <strong>{answer.score}</strong>
        )}
      </td>
      <td>
        <button
          className="btn btn-sm btn-primary mr-2"
          data-toggle="modal"
          data-target="#detail-assignment-modal"
          onClick={() => setAssignmentModal(answer)}
        >
          <i className="fas fa-eye mr-1" />
          Detail
        </button>
        <button
          className="btn btn-sm btn-danger"
          onClick={() => handleDeleteAssignmentUsers(answer)}
        >
          <i className="feather icon-x-circle mr-1" />
          Tolak Tugas
        </button>
      </td>
    </tr>
  )
}

import { useEffect, useState } from 'react';


const useGetSemesterType = (params) => {
  const [semesterType, setSemesterType] = useState('');

  useEffect(() => {
    /*
    EXAMPLE
      url= http://localhost:3000/input-rapor-all/f394a54c-e111-4f93-b666-53a47166e6d9/22231
      result in params?.semester example is = "22231" or "22232"
  
      if we write (params?.semester?.slice(-1)) We get last word. So it can be 1 or 2
  
      1 is mean odd (ganjil)
      2 is mean even (genap)
      */
    const semester = params?.semester?.slice(-1);

    if (semester == 1) {
      setSemesterType('odd');
    } else if (semester == 2) {
      setSemesterType('even');
    }
  }, [params]);

  return {
    semesterType
  }

}

export default useGetSemesterType
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/id'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import DataTable from 'component/data-table'
import { Container, Header } from 'component/template'

export default function AktifitasSiswa() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [allSiswa, setAllSiswa]   = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [limit, setLimit]         = useState(10)
  const [offset, setOffset]       = useState(0)
  const [search, setSearch]       = useState('')

  /* ========================================= Functions ======================================== */
  async function getAllSiswa() {
    const response = await getRequest(
      `siswa-all?limit=${limit}&offset=${offset}&search=${search}`,
    )
    if (response) {
      setAllSiswa(response.data)
      setTotalRows(response.totalRow)
    } else {
      console.error('ERROR', response)
    }
  }

  function handleConvertDateMoment(item) {
    if (item != null) {
      let date = moment(item).add(30, 'days').valueOf()
      let resultDateMoment =
        date < moment().valueOf()
          ? moment(item).format('DD MMM YYYY')
          : moment(item).fromNow()
      return resultDateMoment
    } else {
      return '-'
    }
  }

  const RenderItem = ({ item }) => {
    return (
      <tr>
        <td>{item.name}</td>
        <td>{handleConvertDateMoment(item.terakhir_login)}</td>
        <td>{item.tugas_kelas}</td>
        <td>{item.kelas_selesai}</td>
        <td>{item.poin == null ? 0 : item.poin}</td>
        <td>
          <button
            onClick={() => {
              history.push(`/laporan-aktifitas-siswa-profile/${item.id}`, {
                dataSiswa: item,
              })
            }}
            className="btn btn-sm mr-2 btn-success"
          >
            <i className="fas fa-user-circle mr-1" />
            Profile
          </button>
          <button
            onClick={() => {
              history.push(`/laporan-aktifitas-siswa-progress/${item.id}`, {
                dataSiswa: item,
              })
            }}
            className="btn btn-sm mr-2 btn-danger"
          >
            <i className="fas fa-chart-line mr-1" />
            Progress
          </button>
          <button
            onClick={() => {
              history.push(`/laporan-aktifitas-siswa-infografik/${item.id}`, {
                dataSiswa: item,
              })
            }}
            className="btn btn-sm btn-warning"
          >
            <i className="fas fa-info-circle mr-1" />
            Infografik
          </button>
        </td>
      </tr>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      getAllSiswa()
    }
    return () => (isSubscribed = false)
  }, [limit, offset, search])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Laporan" linkName="Aktivitas Siswa" />
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header">
                <h5 className="m-0">Laporan Aktivitas Siswa</h5>
              </div>
              <div className="card-body">
                <div className="dt-responsive table-responsive">
                  <DataTable
                    className={'table table-striped table-bordered nowrap'}
                    data={allSiswa}
                    totalRows={totalRows}
                    show={[10, 20, 30, 40, 50]}
                    onChangeSearch={(val) => {
                      setSearch(val)
                    }}
                    onChangeLimit={(val) => {
                      setLimit(val)
                    }}
                    onChangePage={(val) => {
                      setOffset(val)
                    }}
                    column={[
                      'nama siswa',
                      'terakhir login',
                      'tugas dikerjakan',
                      'sub mata pelajaran selesai',
                      'poin',
                      'actions',
                    ]}
                    renderItem={(item, i) => (
                      <RenderItem
                        item={item}
                        index={i}
                        key={item.id}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

/*
In this version changes:
1. Move <video><source /></video> to <video src=''></video>
*/

import React, { useEffect, useState } from 'react'
import YouTube from 'react-youtube'

import { useBaseContext } from 'context/base'
import useContentPlayerContext from 'context/content-player'
import useMainContent from 'context/main-content'

const Video = ({ isPreview = false, content_text, contents_url, content_source }) => {
  /* ====================================== Consume Context ===================================== */
  const { putRequest }                              = useBaseContext()
  const { content, kelas, activity: activityStore } = useContentPlayerContext()
  const { shouldSendCCA }                           = useMainContent()

  const using_contents_url = contents_url || content.contents_url

  /* ======================================= Local States ======================================= */
  const [class_id, setClassId]        = useState('')
  const [contentsUrl, setContentsUrl] = useState('')

    // CLASS ACTIVITIES STATE
  const [classActivities, setClassActivities] = useState([])
  const [lastActivity, setLastActivity]       = useState({})
  const [lastPosition, setLastPosition]       = useState(0)

    // VIDEO CONTROL STATE
  const [vidshowNav, setVidshowNav] = useState(false)
  const [isPlay, setIsPlay]         = useState(false)

  /* ========================================= Functions ======================================== */
  async function completeActivities() {
    if (content.feedback === null) {
      let form = new FormData()
      form.append('activity_id', activityStore.id)
      form.append('content_id', content.id)
      form.append('class_id', class_id)
      form.append('is_complete', shouldSendCCA ? 1 : 0)
      const response = await putRequest(
        'class-content-activities',
        form,
      )
      if (response) {
        shouldSendCCA && activityStore.setIdDone(content.id)
      } else {
        console.error('ERROR', response)
      }
    } else {
      activityStore.setMediaIdDone(content.id)
    }
  }

  function checkLastActivity() {
    let updated = []
    let lastUpdated = ''
    classActivities.forEach((activity) => {
      const miliseconds = new Date(activity.updated_at).getTime()
      updated.push(miliseconds)
      if (activity.completed_at !== null) {
        activityStore.setMediaIdDone(content.id)
      }
    })
    lastUpdated = new Date(Math.max(...updated)).toISOString()
    classActivities.forEach((activity) => {
      if (activity.updated_at === lastUpdated) {
        setLastActivity(activity)
      }
    })
  }

  function checkVidshowNav() {
    if (content.vidshow_nav === null) {
      setVidshowNav(true)
    } else {
      if (content.vidshow_nav === true) {
        setVidshowNav(true)
      } else {
        setVidshowNav(false)
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    window.intervalID = 0
  }, [])

  useEffect(() => {
    if (isPreview === false) {
      setClassId(kelas.id)
    }
  }, [kelas.id])

  useEffect(() => {
    if (isPreview === false) {
      setClassActivities(content.class_content_activities)
    }
    checkVidshowNav()
    setContentsUrl(using_contents_url?.url)
  }, [content.id])

  useEffect(() => {
    if (isPreview === false) {
      if (classActivities.length !== 0) {
        checkLastActivity()
      }
    }
  }, [classActivities])

  useEffect(() => {
    if (isPreview === false) {
      if (Object.keys(lastActivity).length !== 0) {
        setLastPosition(lastActivity.last_position)
      }
    }
  }, [lastActivity])

  /* ======================================== Components ======================================== */
  const DriveVideoPreview = () => {
    const [loadType, setLoadType] = useState('videoWithoutToken')

    return (
      <>
        {loadType === 'videoWithoutToken' && (
          <video
            id="mp4Player"
            key={content.id + `videoWithoutToken`}
            width="100%"
            controls={vidshowNav}
            muted={false}
            disablePictureInPicture
            controlsList="nodownload"
            onContextMenu={(event) => event.preventDefault()}
            onEnded={() => {
              if (isPreview === false) {
                completeActivities()
              }
            }}
            onTimeUpdate={(event) => {
              localStorage.setItem(
                'last_position',
                Math.round(event.currentTarget.currentTime),
              )
            }}
            src={`https://drive.google.com/uc?export=view&id=${
                using_contents_url?.url?.split('/').reverse()[0] ||
                using_contents_url?.data?.split('/').reverse()[0]
              }`}
            onError={() => setLoadType(`videoWithToken`)}
          >
          </video>
        )}

        {loadType === 'videoWithToken' && (
          <video
            id="mp4Player"
            key={content.id + `videoWithToken`}
            width="100%"
            controls={vidshowNav}
            muted={false}
            disablePictureInPicture
            controlsList="nodownload"
            onContextMenu={(event) => event.preventDefault()}
            onEnded={() => {
              if (isPreview === false) {
                completeActivities()
              }
            }}
            onTimeUpdate={(event) => {
              localStorage.setItem(
                'last_position',
                Math.round(event.currentTarget.currentTime),
              )
            }}
            src={`https://www.googleapis.com/drive/v3/files/${
              using_contents_url?.url?.split('/').reverse()[0] ||
              using_contents_url?.data?.split('/').reverse()[0]
            }?alt=media&key=AIzaSyAg5af5CXA2BVQzTgVxre_oNk9pdI60IjM`}
            onError={() => setLoadType(`iframe`)}
          >
          </video>
        )}

        {loadType === 'iframe' && (
          <iframe
            key={content.id + `iframe`}
            src={`${contentsUrl}/preview`}
            style={{
              width: '100%',
              height: '400px',
              border: 'none',
              backgroundColor: '#ddd',
            }}
            onLoad={() => completeActivities()}
          />
        )}
      </>
    )
  }

  /* ========================================== Output ========================================== */
  return (
    <>
      {using_contents_url?.source === 'youtube' ? (
        <YouTube
          opts={{
            width: '100%',
            height: '450px',
            playerVars: {
              modestbranding: 1,
              rel: 0,
              controls: vidshowNav ? 1 : 0,
              disablekb: vidshowNav ? 0 : 1,
            },
          }}
          videoId={contentsUrl}
          onEnd={() => {
            if (isPreview === false) {
              completeActivities()
            }
          }}
          onPlay={(event) => {
            window.intervalID = setInterval(() => {
              localStorage.setItem(
                'last_position',
                Math.round(event.target.getCurrentTime()),
              )
            }, 1000)
          }}
          onPause={() => {
            clearInterval(window.intervalID)
          }}
        />
      ) : null}
      {using_contents_url?.source === 'internal' ? (
        <div>
          <video
            id="mp4Player"
            key={content.id}
            width="100%"
            style={{ maxHeight: '100vh' }}
            controls={vidshowNav}
            muted={false}
            disablePictureInPicture
            controlsList="nodownload"
            onContextMenu={(event) => event.preventDefault()}
            onEnded={() => {
              if (isPreview === false) {
                completeActivities()
              }
            }}
            onTimeUpdate={(event) => {
              localStorage.setItem(
                'last_position',
                Math.round(event.currentTarget.currentTime),
              )
            }}
            src={contentsUrl}
            itemType='video/mp4'
          >
            Your browser does not support the video tag.
          </video>
          {vidshowNav === false ? (
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => {
                const vid = document.getElementById('mp4Player')
                if (vid.paused) {
                  vid.play()
                  setIsPlay(true)
                } else {
                  vid.pause()
                  setIsPlay(false)
                }
              }}
            >
              {isPlay ? (
                <>
                  <i className="fas fa-pause mr-1 f-12" />
                  <span>Pause</span>
                </>
              ) : (
                <>
                  <i className="fas fa-play mr-1 f-12" />
                  <span>Play</span>
                </>
              )}
            </button>
          ) : null}
        </div>
      ) : null}
      {using_contents_url?.source === 'gdrive' ? <DriveVideoPreview /> : null}
      {using_contents_url?.source === 'umum' ? (
        <div>
          <video
            id="mp4Player"
            key={content.id}
            width="100%"
            controls={vidshowNav}
            muted={false}
            disablePictureInPicture
            controlsList="nodownload"
            onContextMenu={(event) => event.preventDefault()}
            onEnded={() => {
              if (isPreview === false) {
                completeActivities()
              }
            }}
            onTimeUpdate={(event) => {
              localStorage.setItem(
                'last_position',
                Math.round(event.currentTarget.currentTime),
              )
            }}
            src={`${contentsUrl}`}
            itemType="video/mp4"
          >
            Your browser does not support the video tag.
          </video>
          {vidshowNav === false ? (
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => {
                const vid = document.getElementById('mp4Player')
                if (vid.paused) {
                  vid.play()
                  setIsPlay(true)
                } else {
                  vid.pause()
                  setIsPlay(false)
                }
              }}
            >
              {isPlay ? (
                <>
                  <i className="fas fa-pause mr-1 f-12" />
                  <span>Pause</span>
                </>
              ) : (
                <>
                  <i className="fas fa-play mr-1 f-12" />
                  <span>Play</span>
                </>
              )}
            </button>
          ) : null}
        </div>
      ) : null}
    </>
  )
}

export default Video

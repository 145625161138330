import React from 'react'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import BadgesCollections from 'component/badges-collections'

export default function BadgesCollectionSiswa() {
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Badge Collections" />
        <BadgesCollections apiUrl={'badge-siswa'} />
      </Container>
    </TemplateAdmin>
  )
}

import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import RaporBlock from './preview/block'
import InputRaporBlockAllNote from './note'
import ProfileUser from './profile-user'

export default function InputRaporBlockAll() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()

  /* ========================================== States ========================================== */
  const [data, setData]       = useState([])
  const [note, setNote]       = useState('')
  const [loading, setLoading] = useState(false)
  const [transcriptScoresPeriod, setTranscriptScoresPeriod] = useState([])
  
  /* ========================================== Helpers ========================================= */
  const { userId } = useParams()
  const useQuery   = () => new URLSearchParams(useLocation().search)
  const query      = useQuery()

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/input-rapor-block-all', name: 'Rapor Block All' }]

  /* ========================================= Functions ======================================== */
  async function getRapotBlock() {
    const res = await getRequest(`get-rapot-siswa?user_id=${userId}&class_id=${query.get('classId')}&period_id=${query.get('period_id')}&semester_code=${query.get('semester')}`)
    if (res) {
      res.reportTransactionsperiod.length &&
        setNote(res.reportTransactionsperiod[0].note)
      setData(res.data)
      setTranscriptScoresPeriod(res.TranscriptScoresPeriod)
    }
  }

  async function handleSave() {
    setLoading(true)
    let form = new FormData()
    form.append('note', note)
    form.append('user_id', userId)
    form.append('period_id', query.get('period_id'))
    const res = await postRequest('insertNoteReportPeriod', form)
    if (res) window.notification('Berhasil', 'disimpan', 'success')
    setLoading(false)
    history.push(
      '/preview-rapor-block/' +
        userId +
        '?period_id=' +
        query.get('period_id') +
        '&semester=' +
        query.get('semester'),
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getRapotBlock()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Set Rapor" links={links} />
        
        <div className="card">
          <div className="card-body">
            <ProfileUser
              userId={userId}                
            />
            {data.map((dt, dt_idx) => (
              <>
                <RaporBlock
                  key={`rapor_block_` + dt_idx}
                  dataSection={dt.class.sections}
                  dataTranscript={
                    transcriptScoresPeriod.length > 0
                      ?  transcriptScoresPeriod.find(
                        (tsp) => tsp.class_id === dt.class.id,
                      )
                      : null
                  }
                  studi={dt.class.title}
                />
              </>
            ))}
            <InputRaporBlockAllNote
              userId={userId}                
              note={note}
              setNote={setNote}
            />

            <button
              type="button"
              disabled={loading}
              onClick={handleSave}
              className="btn btn-primary float-right"
            >
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                />
              ) : null}
              Simpan & Tinjau
            </button>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

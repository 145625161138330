import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import { useBaseContext } from 'context/base'
import DataTable from 'component/data-table'

export default function FileSiswa() {
  /* ========================================== Helpers ========================================= */
  const params = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [listFile, setListFile]         = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [limit, setLimit]               = useState(10)
  const [offset, setOffset]             = useState(0)
  const [search, setSearch]             = useState('')

  /* =================================== Consume Local Storage ================================== */
  const { role_type } = JSON.parse(localStorage.getItem('role_active'))

  /* ========================================= Functions ======================================== */
  async function getFileList() {
    let response = null
    if (
      role_type === 'administrator' ||
      role_type === 'instruktur' ||
      role_type === 'adminprofile'
    ) {
      response = await getRequest(
        `file-siswa?limit=${limit}&offset=${offset}&search=${search}&id=${params.siswaId}`,
      )
    } else if (role_type === 'siswa') {
      response = await getRequest(
        `file-siswa?limit=${limit}&offset=${offset}&search=${search}`,
      )
    }
    if (response) {
      setListFile(response.data)
      setTotalRecords(response.totalRecords)
    }
  }

  const RenderItem = ({ item }) => {
    async function handleDownload(item) {
      const response = await getRequest(
        `checkfile?url=${item.url?.data || item.url}`,
      )
      if (response) {
        window.location.href = `${item.url?.data || item.url}`
      }
    }

    const RenderTypeFile = (item) => {
      return (
        <>
          {item.reply_type == 2 ? (
            <div>
              <i className="fas fa-file-audio" />
              <span className="ml-1">{item.type}</span>
            </div>
          ) : null}
          {item.reply_type == 3 ? (
            <div>
              <i className="fas fa-file-video" />
              <span className="ml-1">{item.type}</span>
            </div>
          ) : null}
          {item.reply_type == 4 ? (
            <div>
              <i className="fas fa-file" />
              <span className="ml-1">{item.type}</span>
            </div>
          ) : null}
        </>
      )
    }

    return (
      <tr>
        <td>
          {item.url ? (
            RenderTypeFile(item.url)
          ) : (
            <div>
              <i className="fas fa-file-alt" />
              <span className="ml-1">Text</span>
            </div>
          )}
        </td>
        <td>
          {item.assigment ? (
            item.assigment.title
          ) : (
            <i className="feather icon-minus" />
          )}
        </td>
        <td>{item.content.title}</td>
        <td>{item.content.section.class.title}</td>
        <td>
          {item.login ? (
            moment(item.login.login_at).format('DD MMMM YYYY')
          ) : (
            <i className="feather icon-minus" />
          )}
        </td>
        <td>
          {item.url
            ? item.url.reply_type != 5 && (
                <button
                  onClick={() => handleDownload(item)}
                  type="button"
                  title="Download"
                  className="btn btn-icon btn-info"
                >
                  <i className="fas fa-download" />
                </button>
              )
            : null}
        </td>
      </tr>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getFileList()
  }, [limit, offset, search])

  /* ========================================== Output ========================================== */
  return (
    <div className="dt-responsive table-responsive">
      <DataTable
        className={'table table-striped table-bordered nowrap'}
        data={listFile}
        totalRows={totalRecords}
        show={[10, 20, 30, 40, 50]}
        onChangeSearch={(val) => {
          setSearch(val)
        }}
        onChangeLimit={(val) => {
          setLimit(val)
        }}
        onChangePage={(val) => {
          setOffset(val)
        }}
        column={[
          'File',
          'Tugas',
          'Materi',
          'Sub Mata Pelajaran',
          'Tanggal Upload',
          'Download',
        ]}
        renderItem={(listFile, index) => (
          <RenderItem
            item={listFile}
            index={offset != 0 ? index + offset : index}
            key={index}
          />
        )}
      />
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { useBaseContext } from 'context/base'
import LoadingSpinner from 'component/loading'
import Sections from './sections'
import NotFound from 'component/not-found'
import { noImage } from 'util/constant'

export default function DetailKelas() {
  /* ========================================== Helpers ========================================= */
  const params  = useParams()
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* =================================== Consume Local Storage ================================== */
  const { id }        = JSON.parse(localStorage.getItem('user'))
  const { role_type } = JSON.parse(localStorage.getItem('role_active'))

  /* ========================================= Constants ======================================== */
  const classId = params.kelasId
  const siswaId = params.siswaId || id

  /* ======================================= Local States ======================================= */
  const [windowWidth, setWidth]   = useState(window.innerWidth)
  const [windowHeight, setHeight] = useState(window.innerHeight)

  // VARIABLE STATE
  const [links, setLinks] = useState([])

  const [isLoading, setIsLoading]     = useState(false)
  const [siswa, setSiswa]             = useState(null)
  const [kelas, setKelas]             = useState(null)
  const [contents, setContents]       = useState([])
  const [instructors, setInstructors] = useState([])
  const [sections, setSections]       = useState([])

  // KELAS ACTIVITY
  const [isStarted, setIsStarted]         = useState(false)
  const [isCompleted, setIsCompleted]     = useState(false)
  const [progress, setProgress]           = useState(0)
  const [contentStatus, setContentStatus] = useState([])


  /* ========================================= Functions ======================================== */
  function checkRole() {
    if (role_type === 'orangtua') {
      setLinks([
        { url: '/siswa', name: 'Siswa' },
        {
          url: `/siswa/${siswaId}`,
          name: siswa ? siswa.name : '',
          state: { siswa: siswa ? siswa : null },
        },
      ])
      getDetailSiswa(siswaId)
      getDetailKelas(siswaId)
    } else if (role_type === 'siswa') {
      setLinks([{ url: '/progress-siswa', name: 'Progress Siswa' }])
      getDetailSiswa(id)
      getDetailKelas(id)
    }
  }

  async function getDetailSiswa(id) {
    const response = await getRequest(`siswaById?id=${id}`)
    if (response) {
      setSiswa(response.data)
    } else {
      console.error({ response })
    }
  }

  async function getDetailKelas(id) {
    const response = await getRequest(
      `class-detail-siswa?class_id=${classId}&user_id=${id}`,
    )
    setIsLoading(false)
    if (response) {
      setKelas(response.dataClass)
      if (response.data.length !== 0) {
        initContents(response.data)
        setSections(response.data)
      }
      if (response.dataClass.class_activity !== null) {
        setIsStarted(true)
        if (response.dataClass.class_activity.completed_at !== null) {
          setIsCompleted(true)
        }
      } else {
        setIsStarted(false)
      }
      setInstructors(response.instructors)
    } else {
      console.error({ response })
    }
  }

  function initContents(sections) {
    let contentList = []
    sections.forEach((section) => {
      if (section.contents.length !== 0) {
        section.contents.forEach((content) => {
          contentList.push(content)
        })
      }
    })
    setContents(contentList)
  }

  function initContentsCompleteStatus() {
    const contentStatus = contents.map((content) => {
      let isComplete = false
      if (content.class_content_activities.length !== 0) {
        content.class_content_activities.forEach((activity) => {
          if (activity.completed_at !== null) {
            isComplete = true
          }
        })
      }
      return {
        ...content,
        isComplete,
      }
    })
    setContentStatus(contentStatus)
  }

  async function checkKelasStatus() {
    const res = await getRequest(
      `report-kelas-users?id=${classId}&user_id=` + siswaId,
    )
    setProgress(res.data[0]?.progress_percent || 0)
  }

  function thumbnailStyles() {
    if (windowWidth >= 1280) {
      return {
        height: '250px',
        width: '100%',
        objectFit: 'cover',
      }
    } else if (windowWidth >= 768) {
      return {
        height: '200px',
        width: '100%',
        objectFit: 'cover',
      }
    } else {
      return {
        width: '100%',
        objectFit: 'cover',
      }
    }
  }

  function updateDimensions() {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    setIsLoading(true)
    checkRole()

    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  useEffect(() => {
    if (siswa && role_type === 'orangtua') {
      setLinks([
        { url: '/siswa', name: 'Siswa' },
        {
          url: `/siswa/${siswaId}`,
          name: siswa ? siswa.name : '',
          state: { siswa: siswa ? siswa : null },
        },
      ])
    }
  }, [siswa])

  useEffect(() => {
    if (contents.length !== 0) {
      initContentsCompleteStatus()
    }
  }, [contents])

  useEffect(() => {
    if (contentStatus.length !== 0) {
      checkKelasStatus()
    }
  }, [contentStatus])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName={`Progress ${kelas?.title || ''}`}
          linkName={kelas?.title}
          links={links}
        />
        <div className="card user-profile-list">
          <div className="card-header">
            <p className="f-w-700 f-18 mb-0">{siswa ? siswa.name : '-'}</p>
            <p className="text-muted mb-0">
              {'No. Induk: '}
              {siswa ? siswa.nim : 'tidak terdaftar'}
            </p>
          </div>
          <div className="card-body">
            {isLoading ? (
              <div className="row mb-3">
                <div className="col-12">
                  <LoadingSpinner />
                </div>
              </div>
            ) : (
              <>
                <div
                  className="tab-content"
                  id="pills-tabContent"
                >
                  {/* Progress Siswa Current */}
                  <div
                    className="tab-pane fade active show"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-progress-saya"
                  >
                    <div className="row mb-3">
                      <div className="col-12 mb-2">
                        <h2 className="mb-2">{kelas ? kelas.title : '-'}</h2>
                      </div>
                      {kelas && (
                        <div className="col-md-4 col-xl-3 mb-3 d-flex justify-content-center">
                          <img
                            src={`${kelas.linkImg}`}
                            className="img-fluid rounded bg-light"
                            style={thumbnailStyles()}
                            alt={kelas.title}
                            onError={(e) => (e.currentTarget.src = noImage)}
                          />
                        </div>
                      )}
                      <div className="col-md-8 mb-2">
                        {/* Progress kelas */}
                        <div className="d-flex align-items-center mb-2">
                          <i className="fas fa-chart-line mr-2" />
                          <span className="d-flex align-items-center w-100">
                            Progress:{' '}
                            {isStarted ? (
                              <span className="progress ml-1 w-100 hei-20">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${progress}%`,
                                    backgroundColor:
                                      progress == 100 ? '#9ccc65' : '#00bcd4',
                                  }}
                                  aria-valuenow={`${progress}`}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <span
                                    className="text-light"
                                    style={{
                                      fontSize: '12px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {`${progress}`}%
                                  </span>
                                </div>
                              </span>
                            ) : (
                              <span className="badge badge-secondary ml-1">
                                Belum mulai
                              </span>
                            )}
                          </span>
                        </div>

                        {/* Kategori */}
                        <div className="d-flex align-items-center mb-2">
                          <i className="feather icon-tag mr-2" />
                          <span>
                            Mata Pelajaran:{' '}
                            {kelas ? kelas?.category?.title : '-'}
                          </span>
                        </div>

                        {/* Instruktur */}
                        <div className="d-flex align-items-center mb-2">
                          <i className="fas fa-chalkboard-teacher mr-1" />
                          <span>
                            Guru:
                            {instructors.map((val, index) => {
                              return (
                                <span key={index}>
                                  {index !== 0 ? <span>,</span> : null}
                                  <span className="ml-1">{val.name}</span>
                                </span>
                              )
                            })}
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="bg-light border rounded p-3">
                          <p className="f-16 mb-1 f-w-500">Deskripsi: </p>
                          <p className="m-0 f-16">
                            {kelas ? kelas.description : '-'}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {sections.length !== 0 ? (
                          sections.map((section) => (
                            <Sections
                              item={section}
                              key={section.id}
                              siswaId={siswaId}
                              classId={classId}
                            />
                          ))
                        ) : (
                          <NotFound text="Belum ada materi" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="card-footer">
            <button
              onClick={() => history.goBack()}
              type="button"
              className="btn btn-outline-danger"
            >
              <span>Kembali</span>
            </button>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

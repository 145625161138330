import React from 'react'
import useForumDetail from 'context/forum-detail'
import useForumDetailItem from 'context/forum-detail-item'

export default function ForumDetailBtnLike() {
  const { like } = useForumDetail()
  const { item } = useForumDetailItem()

  async function handleSendLike() {
    item.like.setLoading(true)
    await like.send({ id: item.id, like: item.likes })
    item.like.setLoading(false)
  }

  return (
    <button
      disabled={item.like.loading}
      onClick={handleSendLike}
      type="button"
      className={`btn ${
        item.likes == 1 ? 'btn-success' : 'btn-outline-success'
      } btn-sm`}
    >
      <i className="fas fa-thumbs-up mr-1" />
      {item.likes}
    </button>
  )
}

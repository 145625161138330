import React, { useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d'
import usePreviewRapor from 'context/preview-rapor'

highcharts3d(Highcharts)

export default function DiscussChartV2() {
  /* ====================================== Consume Context ===================================== */
  const { rapor } = usePreviewRapor()

  const [ellipsis, setEllipsis] = useState(false)

  const toggleEllipsis = () => {
    setEllipsis(!ellipsis)
  }

  const raw = [
    ...rapor.discuss?.categories_ikuti_grup?.map((d, didx) => [
      d,
      rapor.discuss?.series_ikuti_grup[0].data[didx],
    ]),
  ]?.sort((a, b) => b[1] - a[1])

  const data = raw.reduce((init, _, idx) => {
    const ln = raw.length
    idx % 2
      ? init.push(raw[ln - parseInt(idx / 2) - 1])
      : init.push(raw[parseInt(idx / 2)])
    return init
  }, [])

  /* ========================================== Options ========================================= */
  const chartOpt = {
    chart: {
      type: 'pie',
      options3d: {
        enabled: true,
        alpha: 45,
      },
      height: 200,
      width: 350,
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      categories: rapor.discuss?.categories_ikuti_grup,
      crosshair: true,
      title: {
        text: '',
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>&nbsp;{point.y}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        innerSize: 40,
        size: 130,
        depth: 25,
      },
    },
    series: [
      {
        name: 'Keaktifan Diskusi',
        data,
        colorByPoint: true,
        dataLabels: {
          crop: false,
          distance: 5,
          overflow: 'allow',
          padding: 3,
          style: {
            textOverflow: ellipsis ? 'ellipsis' : 'none',
            fontSize: '9px',
          },
        },
        label: {
          maxFontSize: 5,
        },
      },
    ],
  }

  /* ========================================== Output ========================================== */
  return (
    <div className="col-4 discuss-v2 m-0 p-0">
      <div className="d-flex align-items-center justify-content-center">
        <div
          className={`noprint badge ${
            ellipsis ? 'badge-success' : 'badge-warning'
          } toggle-ellipsis`}
          onClick={toggleEllipsis}
        >
          {ellipsis ? 'Ellipsis' : 'No Ellipsis'}
        </div>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOpt}
        />
      </div>
    </div>
  )
}

import React from 'react'
import { useParams } from 'react-router-dom'
import BadgesCollections from 'component/badges-collections'

export default function Badge() {
  /* ========================================== Helpers ========================================= */
  const params = useParams()

  /* ========================================== Output ========================================== */
  return <BadgesCollections apiUrl={`badge-siswa?id=${params.siswaId}`} />
}

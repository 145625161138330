import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Select from 'react-select'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import RaporTable from './components/rapor-table'
import loadingTypes from 'globals/loading-types'
import DeskripsiProyek from './components/deskripsi-proyek'
import { InputKindnessWrapper } from 'context/input-kindness'

export default function InputKindness() {
  /* ========================================== Helpers ========================================= */
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [categories, setCategories] = useState([])
  const [categorySelect, setCategorySelect] = useState(null)
  const [groups, setGroups] = useState([])
  const [groupSelect, setGroupSelect] = useState(null)
  const [semester, setSemester] = useState([])
  const [semesterSelect, setSemesterSelect] = useState(null)
  const [loadingSearch, setLoadingSearch] = useState(loadingTypes.idle)  // idle / start / success / error
  const [showMapel, setShowMapel] = useState(false)
  const [showKelas, setShowKelas] = useState(false)

  /* ========================================= Functions ======================================== */
  const getDataOptions = async (url = '') => {
    const response = await getRequest(url)
    if (response) return response.data
    return []
  }

  const handleChangeCategory = async (value) => {
    setCategorySelect(value)
    await initDropdownSelect(
      'groups-user-active?group_id=' +
      value.value +
      '&ta_semester_code=' +
      query.get('semester_id'),
      'group',
    )

    let searchQuery = ''
    if (value) {
      searchQuery = `category_id=${value.value}&`
    }
    if (query.get('semester_id')) {
      searchQuery = `${searchQuery}semester_id=${query.get('semester_id')}&`
    }
    if (query.get('group_id')) {
      searchQuery = `${searchQuery}group_id=${query.get('group_id')}`
    }
    history.push(`/input-rapor-p5?${searchQuery}`)
  }

  const handleChangeGroup = (value) => {
    setGroupSelect(value)
    let searchQuery = ''
    if (query.get('semester_id')) {
      searchQuery = `semester_id=${query.get('semester_id')}&`
    }
    if (value) {
      searchQuery = `${searchQuery}group_id=${value.value}`
    }
    history.push(`/input-rapor-p5?${searchQuery}`)
  }

  const handleChangeSemester = async (value) => {
    if (query.get('category_id')) {
      await initDropdownSelect(
        'groups-user-active?group_id=' +
        query.get('category_id') +
        '&ta_semester_code=' +
        value.value,
        'group',
      )

      setSemesterSelect(value)
      let searchQuery = ''
      if (query.get('category_id'))
        searchQuery = `category_id=${query.get('category_id')}&`
      if (value) searchQuery = `${searchQuery}semester_id=${value.value}`
      history.push(`/input-rapor-p5?${searchQuery}`)
    } else {
      await initDropdownSelect(`semester/${value.value}/groups-wali`, 'group')
      setSemesterSelect(value)
      let searchQuery = ''
      if (query.get('category_id'))
        searchQuery = `category_id=${query.get('category_id')}&`
      if (value) searchQuery = `${searchQuery}semester_id=${value.value}`
      history.push(`/input-rapor-p5?${searchQuery}`)
    }
  }

  const initSelectedItem = (array = [], id, type = '') => {
    for (let item of array) {
      if (item.value == id) {
        if (type === 'category') {
          setCategorySelect({ label: item.label, value: id })
        }
        if (type === 'group') {
          setGroupSelect({ label: item.label, value: id })
        }
        if (type === 'semester') {
          setSemesterSelect({ label: item.label, value: id })
        }
        break
      }
    }
  }

  const initDropdownSelect = async (url, type) => {
    let options
    const array = await getDataOptions(url)
    if (type == 'group') {
      options = array.map((item) => ({
        label: item.group || item.title,
        value: item.id,
      }))
    } else {
      options = array.map((item) => ({
        label: item.title,
        value: item.id,
      }))
    }
    const renderSemester = array.map((val) => ({
      label: `${val.tahun_mulai}/${val.tahun_selesai} - ${val.title_render} ${val.status ? '(Aktif)' : ''
        }`,
      value: val.code,
    }))

    switch (type) {
      case 'category':
        setCategories(options)
        if (query.get('category_id'))
          initSelectedItem(options, query.get('category_id'), type)
        break
      case 'group':
        setGroups(options)
        if (query.get('group_id'))
          initSelectedItem(options, query.get('group_id'), type)
        break
      case 'semester':
        setSemester(renderSemester)
        if (query.get('semester_id'))
          initSelectedItem(renderSemester, query.get('semester_id'), type)
        break
      default:
        setGroups([])
        setCategories([])
        setSemester([])
        break
    }
  }

  const init = async () => {
    await initDropdownSelect('categories-active', 'category')
    await initDropdownSelect('groups-user-active?group_id=', 'group')
    await initDropdownSelect('semester', 'semester')
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (semesterSelect) {
      setShowKelas(true)
    }
  }, [semesterSelect])



  /* ========================================== Output ========================================== */
  return (
    <InputKindnessWrapper selectedClass={groupSelect} selectedSemester={semesterSelect}>
      <TemplateAdmin>
        <Container>
          <Header pageName="Input Rapor Mata Pelajaran" />

          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 mb-3 mb-md-0">
                  <span className="f-w-700 f-14">Semester</span>
                  <Select
                    className="mt-2"
                    placeholder="Pilih semester"
                    isMulti={false}
                    isDisabled={
                      loadingSearch === loadingTypes.start
                    }
                    onChange={handleChangeSemester}
                    value={semesterSelect}
                    options={semester}
                  />
                </div>
                {showMapel && (
                  <div className="col-md-4 mb-3 mb-md-0">
                    <span className="f-w-700 f-14">Mata Pelajaran</span>
                    <Select
                      className="mt-2"
                      placeholder="Pilih mata pelajaran"
                      isMulti={false}
                      isDisabled={
                        loadingSearch === loadingTypes.start
                      }
                      value={categorySelect}
                      options={categories}
                      onChange={handleChangeCategory}
                    />
                  </div>
                )}
                {showKelas && (
                  <div className="col-md-4 mb-3 mb-md-0">
                    <span className="f-w-700 f-14">Kelas</span>
                    <Select
                      className="mt-2"
                      placeholder="Pilih kelas"
                      isMulti={false}
                      isClearable={true}
                      isDisabled={
                        loadingSearch === loadingTypes.start
                      }
                      value={groupSelect}
                      options={groups}
                      onChange={handleChangeGroup}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <DeskripsiProyek selectedSemester={semesterSelect} selectedClass={groupSelect} />

          <RaporTable
            loadingTypes={loadingTypes}
            category_id={query.get('category_id')}
            group_id={query.get('group_id')}
            semester_id={query.get('semester_id')}
            semester={semesterSelect}
            kelas={groupSelect}
            loadingSearch={loadingSearch}
            setLoadingSearch={setLoadingSearch}
          />
        </Container>
      </TemplateAdmin>
    </InputKindnessWrapper>
  )
}

import React from 'react'

export default function ForumDetailNoDiscussion() {
  return (
    <div className="d-flex flex-column justify-content-center my-3">
      <i className="fas fa-comments f-80 text-muted text-center" />
      <span className="f-14 text-center text-muted mt-2">
        Belum ada diskusi
      </span>
    </div>
  )
}

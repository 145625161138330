import React from 'react'
import { Modal } from 'react-bootstrap'

const BaseReactBootstrapModal = ({ show, handleClose, children, headerTitle, isWithFooter = false, handleOk, okTitle = 'Ok', closeTitle = 'Close', isWithOkButton = true, isWithCloseButton = true, isWithHeader = true, isWithXButton = true, ...props }) => {
  return (
    <Modal {...props} show={show} backdrop='static'>
      {isWithHeader ?
        <Modal.Header>
          <Modal.Title>{headerTitle}</Modal.Title>
        </Modal.Header>
        : null}
      {isWithXButton ?
        <button
          type="button"
          className="close text-danger position-absolute"
          data-dismiss="modal"
          aria-label="Close"
          style={{ right: 20, top: 20, zIndex: 99999999999 }}
          onClick={handleClose}
        >
          <i className="feather icon-x" />
        </button>
        : null}

      <Modal.Body>
        {children}
      </Modal.Body>

      {isWithFooter ?
        <Modal.Footer>
          {isWithCloseButton ?
            <button className="btn btn-outline-danger" onClick={handleClose}>{closeTitle}</button>
            : null}
          {isWithOkButton ?
            <button className="btn btn-outline-primary" {...props?.okButtonProps} onClick={handleOk}>{okTitle}</button>
            : null}
        </Modal.Footer>
        : null}
    </Modal>
  )
}

export default BaseReactBootstrapModal
import React from 'react'

export default function ContentTitle({ text }) {
  return (
    <h2 className="accordion-header">
      <a
        className="col collapsed text-light p-3 border-bottom f-18 f-w-500 bg-primary"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        {text}
      </a>
    </h2>
  )
}

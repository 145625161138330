import React from 'react'
import useContentContainerContext from 'context/content-container'

export default function BadgeProgress() {
  const contentContainer = useContentContainerContext()
  const { content } = useContentContainerContext()
  // content.is_assignment_evaluated / content.is_quiz_evaluated =>
  // : null kalau blm ada tugas submit
  // : false kalau belum dikoreksi

  /* ========================================= Constants ======================================== */
  const isAssignment = content.content_type_id === 8
  const isQuiz       = content.content_type_id === 6

  const isSubmitted = contentContainer.allContents
    ? contentContainer.allContents?.find((ac) => ac.id === content?.id)
        ?.isSubmitted
    : content.is_assignment_evaluated === false ||
      content.is_quiz_evaluated === false
      
  const isEvaluated = content.is_assignment_evaluated || content.is_quiz_evaluated

  return (isAssignment || isQuiz) && isSubmitted && !isEvaluated ? (
    <span
      style={{ fontSize: '10px' }}
      className="badge badge-pill badge-warning"
    >
      Belum Dikoreksi
    </span>
  ) : null
}

import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { Submit, Back } from 'component/button'

export default function AddForumTopic() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()

  /* =========================================== Refs =========================================== */
  const mounted = useRef(true)

  /* =================================== Consume Local Storage ================================== */
  const { role_type } = JSON.parse(localStorage.getItem('role_active'))

  /* ======================================= Local States ======================================= */
  const [titleForum, setTitleForum]                 = useState('')
  const [descForum, setDescForum]                   = useState('')
  const [typeForum, setTypeForum]                   = useState('')
  const [valueTypeGroup, setValueTypeGroup]         = useState(null)
  const [valueTypeUser, setValueTypeUser]           = useState(null)
  const [valueTypeGroupGuru, setValueTypeGroupGuru] = useState(null)
  const [validateTitle, setValidateTitle]           = useState(false)
  const [validateDesc, setValidateDesc]             = useState(false)
  const [validateType, setValidateType]             = useState(false)
  const [user, setUser]                             = useState([])
  const [group, setGroup]                           = useState([])
  const [groupGuru, setGroupGuru]                   = useState([])
  const [loadingSubmit, setLoadingSubmit]           = useState(false)

  /* ========================================= Functions ======================================== */
  async function getUser() {
    let optionsUser = []
    const response = await getRequest('users')
    if (response) {
      let dd = response.data
      if (dd.length >= 0) {
        dd.map((item) => {
          optionsUser.push({
            label: item.name,
            value: item.id,
            value_id: item.id,
            type: 'user',
          })
        })
      }
      if (mounted.current === true) setUser(optionsUser)
    }
  }

  async function getGroupSiswa() {
    const response = await getRequest('groups?active=true')
    let optionsGroup = []
    if (response) {
      let dd = response.data      
      if (dd.length >= 0) {
        dd.map((item) => {
          optionsGroup.push({
            label: item.group,
            value: item.id,
            value_id: item.id,
            type: 'group',
          })
        })
      }
      if (mounted.current === true) setGroup(optionsGroup)
    }
  }

  async function getGroupGuru() {
    const response = await getRequest('group-teachers?active=true')
    let optionsGroup = []
    if (response) {
      let dd = response.data
      if (dd.length >= 0) {
        dd.map((item) => {
          optionsGroup.push({
            label: item.group,
            value: item.id,
            value_id: item.id,
            type: 'group',
          })
        })
      }
      if (mounted.current === true) setGroupGuru(optionsGroup)
    }
  }

  function handleChangeTitle(e) {
    setTitleForum(e.target.value)
  }

  function handleChangeDescription(e) {
    setDescForum(e.target.value)
  }

  function handleChangePengaturan(e) {
    setTypeForum(e.target.value)
    setValidateType(false)
    setValueTypeGroup(null)
    setValueTypeUser(null)
    setValueTypeGroupGuru(null)
  }

  function combineValue(groupSiswa, groupGuru, users) {
    let values = []
    if (groupSiswa) values = [...values, ...groupSiswa]
    if (groupGuru) values = [...values, ...groupGuru]
    if (users) values = [...values, ...users]
    return values
  }

  async function handleSubmit() {
    if (titleForum && typeForum && descForum) {
      var form = new FormData()
      form.append('title', titleForum)
      form.append('description', descForum)
      if (typeForum == 'umum' || typeForum == 'private') {
        form.append('participants', JSON.stringify([{ type: typeForum }]))
      } else {
        form.append(
          'participants',
          JSON.stringify(
            combineValue(valueTypeGroup, valueTypeGroupGuru, valueTypeUser),
          ),
        )
      }
      setLoadingSubmit(true)
      let response = await postRequest('forum', form)
      setLoadingSubmit(false)
      if (response) {
        window.notification('Berhasil', 'membuat topik baru', 'success')
        history.push('forum')
      }
    } else {
      if (!titleForum) {
        setValidateTitle(true)
      }
      if (!descForum) {
        setValidateDesc(true)
      }
      if (!typeForum) {
        setValidateType(true)
      }
      window.notification('Mohon', 'lengkapi data', 'warning')
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (
      role_type === 'administrator' ||
      role_type === 'instruktur' ||
      role_type === 'adminprofile'
    ) {
      getUser()
      getGroupSiswa()
      getGroupGuru()
    } else if (role_type === 'siswa') {
      getUser()
      getGroupSiswa()
    }

    return () => {
      mounted.current = false
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Forum"
          linkName="Tambah Topik"
          links={[{ url: '/Forum', name: 'Forum' }]}
        />

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Buat Topik Baru</h5>
              </div>
              <div className="card-body">
                <form>
                  <div className="form-group row">
                    <label
                      htmlFor="forumTopicTitle"
                      className="col-sm-2 col-form-label"
                    >
                      Judul Topik
                    </label>
                    <div className="col-sm-10">
                      <input
                        onChange={handleChangeTitle}
                        value={titleForum}
                        type="text"
                        className={`form-control ${
                          validateTitle ? 'is-invalid' : ''
                        }`}
                        id="forumTopicTitle"
                        placeholder="Judul Topik"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="forumTopicDescription"
                      className="col-sm-2 col-form-label"
                    >
                      Deskripsi
                    </label>
                    <div className="col-sm-10">
                      <textarea
                        onChange={handleChangeDescription}
                        value={descForum}
                        type="text"
                        className={`form-control ${
                          validateDesc ? 'is-invalid' : ''
                        }`}
                        id="forumTopicDescription"
                        placeholder="Deskripsi"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Pengaturan Akses
                    </label>
                    <div className="col-sm-10 col-xl-6">
                      <select
                        onChange={handleChangePengaturan}
                        className={`form-control ${
                          validateType ? 'is-invalid' : ''
                        }`}
                      >
                        <option value="">-- Pengaturan Akses Forum --</option>
                        <option value="umum">Terbuka untuk semua orang</option>
                        <option value="user">Custom</option>
                      </select>
                      {typeForum == 'user' ? (
                        <div className="mt-3">
                          {role_type === 'administrator' ||
                          role_type === 'instruktur' ||
                          role_type === 'adminprofile' ? (
                            <div className="row">
                              <label className="col-4 col-md-3 col-form-label">
                                Group Guru
                              </label>
                              <div className="col-8 col-md-9">
                                <Select
                                  isMulti
                                  closeMenuOnSelect={false}
                                  name="colors"
                                  options={groupGuru}
                                  className="basic-multi-select mt-2"
                                  classNamePrefix="select"
                                  value={valueTypeGroupGuru}
                                  onChange={(v) => setValueTypeGroupGuru(v)}
                                  noOptionsMessage={() =>
                                    'Group tidak ditemukan'
                                  }
                                  placeholder={'Pilih group'}
                                />
                              </div>
                            </div>
                          ) : null}
                          <div className="row">
                            <label className="col-4 col-md-3 col-form-label">
                              Kelas Siswa
                            </label>
                            <div className="col-8 col-md-9">
                              <Select
                                isMulti
                                closeMenuOnSelect={false}
                                name="colors"
                                options={group}
                                className="basic-multi-select mt-2"
                                classNamePrefix="select"
                                value={valueTypeGroup}
                                onChange={(v) => setValueTypeGroup(v)}
                                noOptionsMessage={() => 'Kelas tidak ditemukan'}
                                placeholder={'Pilih kelas'}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <label className="col-4 col-md-3 col-form-label">
                              User
                            </label>
                            <div className="col-8 col-md-9">
                              <Select
                                isMulti
                                closeMenuOnSelect={false}
                                name="colors"
                                options={user}
                                className="basic-multi-select mt-2 "
                                classNamePrefix="select"
                                value={valueTypeUser}
                                onChange={(v) => setValueTypeUser(v)}
                                noOptionsMessage={() => 'User tidak ditemukan'}
                                placeholder={'Pilih user'}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row mb-0">
                    <div className="offset-sm-2 col-sm-10">
                      <Back text="Kembali" />
                      {loadingSubmit ? (
                        <button
                          type="button"
                          className="btn btn-success"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm mr-1"
                            role="status"
                          />
                          Simpan
                        </button>
                      ) : (
                        <>
                          {titleForum && typeForum && descForum ? (
                            <>
                              {typeForum === 'umum' ? (
                                <Submit
                                  onClick={handleSubmit}
                                  text="Simpan"
                                />
                              ) : (
                                <>
                                  {valueTypeGroup ||
                                  valueTypeUser ||
                                  valueTypeGroupGuru ? (
                                    <Submit
                                      onClick={handleSubmit}
                                      text="Simpan"
                                    />
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-success"
                                      disabled
                                    >
                                      Simpan
                                    </button>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-success"
                              disabled
                            >
                              Simpan
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React, { useEffect, useState } from 'react'
import RaporUserItem from './rapor-user-item'
import moment from 'moment'
import Modal from 'component/modal'
import useGetRoleActive from 'hooks/useGetRoleActive'
import CheckboxBase from 'component/checkbox/CheckboxBase'
import BaseButton from 'component/button/BaseButton'
import MultipleRejectRaporP5Modal from './modal/MultipleRejectRaporP5Modal'
import Swal from 'sweetalert2'
import useInputKindness from 'context/input-kindness'
import { useBaseContext } from 'context/base'

export default function RaporSuccess({
  tableConfig = { headers: [], totalColumns: 0 },
  result,
  category_id,
  semester_id,
  semester,
  kelas,
  setSearch,
  setOffset,
  setLimit,
  setStart,
  setTotalPage,
  setActivePage,
  limit,
  start,
  totalPage,
  activePage,
}) {
  /* ========================================= Constants ======================================== */
  const emptyPlaceholder = 'Tidak ada siswa terdaftar di mata pelajaran'

  /* --------------------------------- STATES --------------------------------- */
  const [historyRapor, setHistoryRapor] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [isShowMultipleRejectRaporModal, setIsShowMultipleRejectRaporModal] = useState(false)
  const [totalRaporStatusSubmissionOrApproved, setTotalRaporStatusSubmissionOrApproved] = useState(0);
  const [isLoadingMultipleApprovedRaporP5, setisLoadingMultipleApprovedRaporP5] = useState(false)

  /* --------------------------------- CONTEXT -------------------------------- */
  const { utils: { setTriggerGetStudentScores }, data: { selectedClass, selectedSemester } } = useInputKindness()
  const { putRequest } = useBaseContext()

  /* ---------------------------------- HOOKS --------------------------------- */
  const roleActive = useGetRoleActive();

  /* -------------------------------- CONSTANT -------------------------------- */
  const isCheckboxAllChecked = selectedCheckbox.length === (totalRaporStatusSubmissionOrApproved === 0 ? false : totalRaporStatusSubmissionOrApproved)
  const isAdminOrHeadmaster = ['administrator', 'kepalasekolah'].includes(roleActive.type);
  const isRaporUserStatusApprovedOrSubmission = (i) => ['submission', 'approved'].includes(i?.status);


  /* -------------------------------- HANDLERS -------------------------------- */
  /**
   * On Change Checkbox Handler
   * 
   * @param {{
   *  type: 'selectAll' | 'selectOne',
   *  value: object,
   *  isChecked: boolean,
   * }} params
   */
  const onChangeChecboxHandler = ({ type, value = null, isChecked }) => {
    const commonParams = { value, isChecked }

    switch (type) {
      case 'selectAll':
        selectAllCheckboxHandler(commonParams);
        break;
      case 'selectOne':
        selectOneCheckboxHandler(commonParams);
        break;
      default:
        break;
    }
  }

  const getRaporStatusSubmissionOrApprovedHandler = () => {
    const necessarySelectAll = [];

    result?.users?.forEach(r => {
      if (isRaporUserStatusApprovedOrSubmission(r)) {
        necessarySelectAll.push(r)
      }
    })

    return necessarySelectAll
  }

  /**
  * Select All Checkbox Handler
  * 
  * @param {{
  *  isChecked: boolean,
  * }} params
  */
  const selectAllCheckboxHandler = ({ isChecked }) => {
    if (isChecked) {
      const necessarySelectAll = getRaporStatusSubmissionOrApprovedHandler();


      setSelectedCheckbox(necessarySelectAll);
    } else {
      setSelectedCheckbox([]);
    }
  }

  /**
  * Select One Checkbox Handler
  * 
  * @param {{
  *  value: object,
  *  isChecked: boolean,
  * }} params
  */
  const selectOneCheckboxHandler = ({ isChecked, value }) => {
    if (isChecked) {
      setSelectedCheckbox([...selectedCheckbox, value]);
    } else {
      const isItemCheckedExist = selectedCheckbox.find(c => c?.id === value?.id);

      if (isItemCheckedExist) {
        const data = selectedCheckbox.filter(c => c?.id !== isItemCheckedExist?.id);

        setSelectedCheckbox(data);
      }
    }
  }

  const multipleApprovedRaporP5Handler = async () => {
    const listStudentsName = selectedCheckbox.map(s => s.name)

    Swal.fire({
      title: `Approve rapor untuk (${listStudentsName.join(', ')})?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2778c4',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Ya!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setTriggerGetStudentScores(false);
        setisLoadingMultipleApprovedRaporP5(true)
        Swal.fire({
          title: 'Approved rapor...',
          html: `<div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                  </div>`,
          allowOutsideClick: false,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
        })

        await Promise.all(selectedCheckbox.map(async (s) => {
          const response = await putRequest(`report-p5/${selectedClass.value}/${selectedSemester.value}/${s?.id}/approve-rapor-siswa`)

          if (response) {
            window.notification(`Berhasil menyetujui rapor ${s?.name}!`, '', 'success')
          } else {
            window.notification(`Approved rapor ${s?.name} gagal diproses.`, '', 'error')
          }
        })
        )

        Swal.fire({
          title: 'Proses approved rapor p5 selesai!',
          icon: 'info',
          allowOutsideClick: false,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
          confirmButtonColor: '#2778c4',
        }).then(result => {
          if (result.isConfirmed) {
            setTriggerGetStudentScores(true);
            setisLoadingMultipleApprovedRaporP5(false);
          }
        })
      }
    });
  }

  const multipleRejectedRaporP5Handler = async () => {
    const listStudentsName = selectedCheckbox.map(s => s.name)

    Swal.fire({
      title: `Tolak rapor untuk (${listStudentsName.join(', ')})?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2778c4',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Ya!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setTriggerGetStudentScores(false);
        setisLoadingMultipleApprovedRaporP5(true)
        Swal.fire({
          title: 'Rejected rapor...',
          html: `<div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                  </div>`,
          allowOutsideClick: false,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
        })

        await Promise.all(selectedCheckbox.map(async (s) => {
          const response = await putRequest(`report-p5/${selectedClass.value}/${selectedSemester.value}/${s?.id}/reject-rapor-siswa`, { note: s.note || '' })

          if (response) {
            window.notification(`Berhasil menolak rapor ${s?.name}!`, '', 'success')
          } else {
            window.notification(`Rejected rapor ${s?.name} gagal diproses.`, '', 'error')
          }
        })
        )


        Swal.fire({
          title: 'Proses rejected rapor p5 selesai!',
          icon: 'info',
          allowOutsideClick: false,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
          confirmButtonColor: '#2778c4',
        }).then(result => {
          if (result.isConfirmed) {
            setTriggerGetStudentScores(true);
            setisLoadingMultipleApprovedRaporP5(false);
          }
        })
      }
    });
  }

  const noteRejectHandler = ({ itemIndex, value }) => {
    const copySelectedCheckbox = [...selectedCheckbox]; // We copy the state because do not direct changed in state

    const isDataExist = copySelectedCheckbox?.[itemIndex]

    isDataExist.note = value

    copySelectedCheckbox[itemIndex] = isDataExist

    setSelectedCheckbox(copySelectedCheckbox)
  }

  useEffect(() => {
    var tp = result?.users?.length / limit
    setTotalPage(Math.ceil(tp))
    var act = (start / limit) + 1
    setActivePage(act)
  }, [limit]);


  useEffect(() => {
    const necessarySelectAll = getRaporStatusSubmissionOrApprovedHandler();

    setTotalRaporStatusSubmissionOrApproved(necessarySelectAll.length);
  }, []);

  /* --------------------------------- RENDERS -------------------------------- */
  const renderTable = ({ datas, isWithCheckbox = true, isMultipleReject = false }) => {
    const data = datas ? { users: datas } : result;

    return <table className="table nowrap">
      <thead>
        <tr>
          {isAdminOrHeadmaster && isWithCheckbox ?
            <th>
              <CheckboxBase onChange={(e) => onChangeChecboxHandler({ type: 'selectAll', value: result, isChecked: e.target.checked })} isChecked={isCheckboxAllChecked} isDisabled={!totalRaporStatusSubmissionOrApproved} />
            </th>
            : null}
          {tableConfig.headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.users?.length > 0 ? (
          <>
            {data.users.map((user, index) => (
              <RaporUserItem
                key={`${index}-${user.user_id}-${user.categories_id}-keterampilan`}
                totalColumns={tableConfig.totalColumns}
                item={user}
                number={index + 1}
                index={index}
                setHistoryRapor={setHistoryRapor}
                onChangeChecboxHandler={onChangeChecboxHandler}
                selectedCheckbox={selectedCheckbox}
                isWithCheckbox={isWithCheckbox}
                isMultipleReject={isMultipleReject}
                noteRejectHandler={noteRejectHandler}
              />
            ))}
          </>
        ) : (
          <tr>
            <td
              colSpan={tableConfig.totalColumns}
              className="text-center"
            >
              {emptyPlaceholder}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  }

  /* ========================================== Output ========================================== */
  return (
    <>
      {isAdminOrHeadmaster ?
        <>
          <BaseButton props={{ className: 'btn-sm' }} variant='success' isDisabled={!selectedCheckbox.length} onClick={multipleApprovedRaporP5Handler} isLoading={isLoadingMultipleApprovedRaporP5}>
            <i className="fas fa-check-circle mr-1" />
            Approve
          </BaseButton>
          <BaseButton props={{ className: 'btn-sm' }} variant='danger' isDisabled={!selectedCheckbox.length} onClick={() => setIsShowMultipleRejectRaporModal(true)}>
            <i className="fas fa-times-circle mr-1" />
            Tolak
          </BaseButton>
        </>
        : null}
      <div className="row mt-3">
        <div className="col">
          <div className="table-responsive rounded mt-2">

            {renderTable({ isWithCheckbox: true, isMultipleReject: false })}

            <Modal
              id="modalHistoryRapor"
              title="Riwayat rapor"
              containerStyle={{
                'zIndex': 9999999999999999999999999999n,
              }}
            >
              <div
                className="hd-body pr-2"
                style={{
                  maxHeight: '350px',
                  overflowY: 'auto',
                }}
              >
                {historyRapor.length ? (
                  <>
                    {historyRapor.map((history, index) => (
                      <div
                        key={index}
                        className="excerpt rounded mb-3"
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <span>
                            <i className="feather icon-calendar mr-1 f-14" />
                            {history.createdAt
                              ? moment(history.createdAt).format('H:MM, DD-MM-YYYY')
                              : '-'}
                          </span>
                          <span className="badge badge-danger">Ditolak</span>
                        </div>
                        <h6 className="mb-1">Catatan:</h6>
                        <p className="m-0">{history.note ? history.note : '-'}</p>
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="text-muted mb-0">Belum ada riwayat</p>
                )}
              </div>
            </Modal>


            {/* MODAL MULTIPLE REJECT */}
            <MultipleRejectRaporP5Modal
              show={isShowMultipleRejectRaporModal}
              handleOk={() => setIsShowMultipleRejectRaporModal(false)}
              handleClose={multipleRejectedRaporP5Handler}
            >
              {renderTable({ datas: selectedCheckbox, isWithCheckbox: false, isMultipleReject: true })}
            </MultipleRejectRaporP5Modal>
          </div>
        </div>
      </div>
    </>
  )
}



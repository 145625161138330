import React from 'react'
import ChatRoom from 'component/chat/chat-room'
import useChatContext from 'context/chat'

export default function ChatPane() {
  const { current } = useChatContext()

  if (current.data) {
    return <ChatRoom />
  }
  return null
}

import React from 'react'

export default function TabPeriksa({ content, handleView, tabActive }) {
  /* =================================== Consume Localstorage =================================== */
  const _isAdministrator = JSON.parse(localStorage.getItem('role_active'))?.role_type === 'administrator' || JSON.parse(localStorage.getItem('role_active')).role_type === 'adminprofile'
  const _isGuruUtama     = JSON.parse(localStorage.getItem('guru_utama'))

  /* ========================================== Output ========================================== */
  if (_isAdministrator || _isGuruUtama) {
    if (content.content_type_id === 6 ||
        content.content_type_id === 7 ||
        content.content_type_id === 8) {
      return (
        <>
          <li className="nav-item">
            <a
              onClick={() => handleView('result')}
              className={`nav-link text-uppercase ${tabActive('result')?.[0]}`}
              data-toggle="tab"
              href="#result"
              role="tab"
              aria-controls="result"
              aria-selected={tabActive('result')?.[1]}
            >
              {content.content_type_id === 6 && 'Periksa Quiz'}
              {content.content_type_id === 7 && 'Hasil Survey'}
              {content.content_type_id === 8 && 'Periksa Tugas'}
            </a>
          </li>
          {content.content_type_id === 7 && content?.is_score
          ?
            <li className="nav-item">
              <a
                onClick={() => handleView('result-survey')}
                className={`nav-link text-uppercase ${tabActive('result-survey')?.[0]}`}
                data-toggle="tab"
                href='#result-survey'
                role="tab"
                aria-controls='result-survey'
                aria-selected={tabActive('result-survey')?.[1]}
              >
                Periksa
              </a>
            </li>
          : null}
        </>
      )
    } else if ([1,2,3,4,5,9,10].includes(content.content_type_id) && content?.is_score) {
      return (
        <li className="nav-item">
          <a
            onClick={() => handleView('result')}
            className={`nav-link text-uppercase ${tabActive('result')?.[0]}`}
            data-toggle="tab"
            href="#result"
            role="tab"
            aria-controls="result"
            aria-selected={tabActive('result')?.[1]}
          >
            Periksa
          </a>
        </li>
      )
    }
    return null
  } else {
    return null
  }
}

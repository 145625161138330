import React, { useState, useRef, useEffect } from 'react'
import { useBaseContext } from 'context/base'

export default function EditDiskusi({
  classId,
  getForum,
  forumEdit,
  setForumEdit,
}) {
  /* ========================================= Constants ======================================== */
  const typeForum = 'mata-pelajaran'
  const participants = [{ type: typeForum, value_id: classId }]
  
  /* ====================================== Consume Context ===================================== */
  const { putRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [titleForum, setTitleForum]       = useState('')
  const [descForum, setDescForum]         = useState('')
  const [validateTitle, setValidateTitle] = useState(false)
  const [validateDesc, setValidateDesc]   = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  /* =========================================== Refs =========================================== */
  const closeBtn = useRef()

  /* ========================================= Functions ======================================== */
  async function formValidation() {
    if (titleForum && descForum) {
      setValidateTitle(false)
      setValidateDesc(false)
      await submitForm()
    } else {
      if (!titleForum) {
        setValidateTitle(true)
      }

      if (!descForum) {
        setValidateDesc(true)
      }
    }
  }

  async function submitForm() {
    setLoadingSubmit(true)
    let form = new FormData()
    form.append('title', titleForum)
    form.append('description', descForum)
    form.append('participants', JSON.stringify(participants))
    form.append('content_id', classId)
    form.append('id', forumEdit.id)

    const response = await putRequest('forum', form)
    setLoadingSubmit(false)
    if (response) {
      window.notification('Berhasil', 'Topik berhasil diubah', 'success')
      await getForum()
      closeBtn.current.click()
    } else {
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }

  function resetForm() {
    setTitleForum('')
    setDescForum('')
    setForumEdit(null)
    setValidateTitle(false)
    setValidateDesc(false)
    setLoadingSubmit(false)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (forumEdit) {
      setTitleForum(forumEdit.title)
      setDescForum(forumEdit.description)
    }
  }, [forumEdit])

  /* ========================================== Output ========================================== */
  return (
    <div
      id="editForumModal"
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="editForumModalTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="editForumModalTitle"
            >
              Edit Topik
            </h5>
            <button
              ref={closeBtn}
              onClick={() => {
                window.$('#editForumModal').modal('hide')
                resetForm()
              }}
              type="button"
              className="close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group row">
                <label
                  htmlFor="editJudul"
                  className="col-sm-2 col-form-label"
                >
                  Judul Topik
                </label>
                <div className="col-sm-10">
                  <input
                    value={titleForum}
                    onChange={(e) => {
                      setTitleForum(e.target.value)
                      setValidateTitle(titleForum == '')
                    }}
                    type="text"
                    className={`form-control ${
                      validateTitle ? 'is-invalid' : ''
                    }`}
                    id="editJudul"
                    placeholder="Judul Topik"
                  />
                  {validateTitle ? (
                    <span className="small form-text text-danger">
                      Judul tidak boleh kosong.
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="editDesc"
                  className="col-sm-2 col-form-label"
                >
                  Deskripsi
                </label>
                <div className="col-sm-10">
                  <textarea
                    value={descForum}
                    onChange={(e) => {
                      setDescForum(e.target.value)
                      setValidateDesc(descForum == '')
                    }}
                    type="text"
                    className={`form-control ${
                      validateDesc ? 'is-invalid' : ''
                    }`}
                    id="editDesc"
                    placeholder="Deskripsi"
                  />
                  {validateDesc ? (
                    <span className="small form-text text-danger">
                      Deskripsi tidak boleh kosong.
                    </span>
                  ) : null}
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer p-2">
            <button
              onClick={() => {
                window.$('#editForumModal').modal('hide')
                resetForm()
              }}
              type="button"
              className="btn btn-outline-danger mr-2"
            >
              Batal
            </button>
            {loadingSubmit ? (
              <button
                disabled={true}
                type="button"
                className="btn btn-success btn-forbidden"
              >
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                />
                <span>Simpan</span>
              </button>
            ) : (
              <button
                onClick={formValidation}
                type="button"
                className="btn btn-success"
              >
                Simpan
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import './highchart.css'

export default function LineChart({categories,series,yTitle}) {
    const optionsBar = {
        chart: {
            type: 'spline',
            // Edit chart spacing
            spacingBottom: 15,
            spacingTop: 10,
            spacingLeft: 15,
            spacingRight: 15,
            // Explicitly tell the width and height of a chart
            width: null,
            height: null
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: categories,
            title: {
                text: null
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: yTitle ? yTitle : '',
                align: 'middle'
            },
            labels: {
                overflow: 'justify'
            }
        },
        tooltip: {
            // valueSuffix: ' responder'
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true
                }
            }
        },
        credits: {
            enabled: false
        },
        series: series
    };





    return (
        <div className="m-2">
            <HighchartsReact highcharts={Highcharts} options={optionsBar} />
        </div>
    )
}

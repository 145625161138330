import React from 'react'
import { avatarDefaultUser } from 'util/constant'
import useForumDetail from 'context/forum-detail'
import useForumDetailItem from 'context/forum-detail-item'

export default function ForumDetailReplies() {
  /* ====================================== Consume Context ===================================== */
  const { tanggal } = useForumDetail()
  const { item } = useForumDetailItem()

  /* ========================================== Output ========================================== */
  return item.forums.map((val) => {
    const date = tanggal.generate(val.updatedAt)

    return (
      <div className="d-flex flex-column w-100 col-12">
        <div
          key={val.id}
          className="row mt-3 ml-3 mr-1 pt-2 bg-light"
        >
          <div className="col-2 col-xl-1">
            <img
              src={`${val.login.user.linkAvatar}`}
              onError={(e) => (e.currentTarget.src = avatarDefaultUser)}
              alt={val.login.user.name}
              className="img-radius mb-2"
              style={{
                height: '50px',
                width: '50px',
              }}
              data-toggle="tooltip"
              title={val.login.user.name}
            />
          </div>
          <div className="col-10 col-xl-11">
            <p
              style={{ fontWeight: '500' }}
              className="mb-0"
            >
              {val.login.user.name}
            </p>
            <p
              className="text-muted"
              style={{ fontSize: '10px' }}
            >
              <i className="feather icon-clock" /> {date}
            </p>
            <p>{val.description}</p>
          </div>
        </div>
      </div>
    )
  })
}

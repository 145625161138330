import React from 'react'
import DataTable from 'component/data-table'
import SiswaItem from './siswa-item'
import './siswa-list.css'

export default function SiswaList({
  siswaList,
  assignmentId,
  totalRecord,
  setLimit,
  setOffset,
  setKeyword,
}) {
  return (
    <DataTable
      className={'table table-striped table-bordered nowrap'}
      data={siswaList}
      totalRows={totalRecord}
      show={[10, 20, 30, 40, 50]}
      onChangeSearch={(val) => setKeyword(val)}
      onChangeLimit={(val) => setLimit(val)}
      onChangePage={(val) => setOffset(val)}
      column={['No. Induk', 'Siswa', 'Actions']}
      renderItem={(siswa, index) => (
        <SiswaItem
          key={siswa.id + index}
          siswa={siswa}
          assignmentId={assignmentId}
        />
      )}
    />
  )
}

import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export default function PgChart({ series, categories }) {
  /* ========================================== Options ========================================= */
  const chartOpt = {
    chart: {
      type: 'pie',
      height: 500,
      width: 500,
    },
    title: {
      text: categories[0],
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      categories,
      crosshair: true,
      title: {
        text: '',
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"> <b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        innerSize: 50,
        depth: 45,
      },
    },
    series: [
      {
        name: 'Jumlah Jawaban',
        data: series?.map(s => [s?.name, s.data[0]]) || [],
        colorByPoint: true,
      },
    ],
  }

  /* ========================================== Output ========================================== */
  return (
    <div className="">
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOpt}
      />
    </div>
  )
}

import React, { useState } from 'react'
import useFeedbackForm from 'context/feedback-form'
import InvalidWarning from 'component/invalid/warning'
import CommentFeed from './feed-comment'
import CkeditorReadonly from 'component/ckeditor-readonly'
import { avatarDefaultUser } from 'util/constant'

export default function EditFeedEssay({ sharedProps }) {
  /* =================================== Consume Shared Props =================================== */
  const {
    refFeedText,
    feedText,
    handleChangeFeedText,
    isFeedTextEmpty,
    isLoading,
    formValidation,
    feedComment,
    masterFeedComment,
    getFeedComment,
    listSuggest,
  } = sharedProps

  /* ====================================== Consume Context ===================================== */
  const { feed, event } = useFeedbackForm()

  /* ========================================== Helpers ========================================= */
  const { name, linkAvatar } = JSON.parse(localStorage.getItem('user'))
  const fallbackAvatar = avatarDefaultUser

  /* ========================================== States ========================================== */
  const [isEdit, setIsEdit] = useState(false)

  /* ========================================== Output ========================================== */
  return (
    <>
      <label>{feed.data ? feed.data.description : ''}</label>
      {isEdit ? (
        <>
          <div className="form-group">
            <textarea
              ref={refFeedText}
              value={feedText}
              onChange={(event) => handleChangeFeedText(event.target.value)}
              placeholder="Tulis feedback anda..."
              className={`${isFeedTextEmpty ? 'is-invalid' : ''} form-control`}
            />
            {isFeedTextEmpty && (
              <InvalidWarning text="Feedback tidak boleh kosong" />
            )}
          </div>
          <div className="form-group row">
            <div className="col-12 offset-md-8 col-md-2 mb-2 mb-md-0 px-md-0">
              {isLoading ? (
                <button
                  type="button"
                  className="btn btn-sm btn-primary w-100"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  />
                  <span>Ubah</span>
                </button>
              ) : (
                <button
                  onClick={formValidation}
                  type="button"
                  className="btn btn-sm btn-primary w-100"
                >
                  <i className="fas fa-paper-plane mr-1" />
                  <span>Ubah</span>
                </button>
              )}
            </div>
            <div className="col-12 col-md-2">
              <button
                onClick={() => {
                  setIsEdit(false)
                  event.set('submit-feed')
                }}
                type="button"
                className="btn btn-sm btn-danger w-100"
              >
                <span>Batal</span>
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="media mt-2 mb-0">
          <img
            src={`${linkAvatar}`}
            alt={name}
            className="img-radius align-top m-r-15"
            style={{
              width: '30px',
              height: '30px',
            }}
            onError={(event) => (event.currentTarget.src = fallbackAvatar)}
          />
          <div className="media-body">
            <h6 className="mb-0 text-dark">{name}</h6>
            <p className="m-b-0">
              <CkeditorReadonly
                data={feedText}
                disabled="true"
              />
            </p>

            <CommentFeed
              stateComment={feedComment}
              masterFeedComment={masterFeedComment}
              getFeedComment={getFeedComment}
              listSuggest={listSuggest}
            />
          </div>
        </div>
      )}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/id'

import { useBaseContext } from 'context/base'
import LoadingSpinner from 'component/loading'
import { RenderIcon } from './RenderIconTimeline'

export default function Timeline() {
  /* ========================================== Helpers ========================================= */
  const param = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [timelineSiswa, setTimelineSiswa] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [offset, setOffset] = useState(0)

  /* ========================================= Functions ======================================== */
  async function getTimelineSiswa(offset) {
    setIsLoading(true)
    const response = await getRequest(
      `report-timeline-siswa?user_id=${param.siswaId}&offset=${offset}`,
    )
    setIsLoading(false)
    if (response) {
      setTimelineSiswa([...timelineSiswa, ...response.data])
    } else {
      console.error('ERROR', response)
    }
  }

  function atEnd() {
    var c = [
      document.scrollingElement.scrollHeight,
      document.body.scrollHeight,
      document.body.offsetHeight,
    ].sort(function (a, b) {
      return b - a
    }) // select longest candidate for scrollable length
    return window.innerHeight + window.scrollY + 2 >= c[0] // compare with scroll position + some give
  }

  async function checkScrollPosition() {
    if (atEnd()) {
      setOffset(10 + offset)
    }
  }

  /* ======================================== Components ======================================== */
  const RenderTimeline = ({ item }) => {
    function handleConvertDateMoment(item) {
      return moment(item).format('DD MMM YYYY HH:mm:ss')
    }

    if (item.type == 'logins') {
      return (
        <div className="row p-b-30">
          <div className="col-auto text-right update-meta">
            <p
              className="text-muted m-b-0 d-inline-flex"
              style={{ width: '6rem' }}
            >
              {handleConvertDateMoment(item.login_at)}
            </p>
            <i className="fas fa-unlock-alt bg-twitter update-icon" />
          </div>
          <div className="col">
            <h6>Login</h6>
            <p className="text-muted m-b-0">{item.text}</p>
          </div>
        </div>
      )
    } else if (item.type == 'class_content_activities') {
      let timeline = item.text.split(',')

      return (
        <div className="row p-b-30">
          <div className="col-auto text-right update-meta">
            <p
              className="text-muted m-b-0 d-inline-flex"
              style={{ width: '6rem' }}
            >
              {handleConvertDateMoment(item.login_at)}
            </p>
            <RenderIcon item={timeline[0]} />
          </div>
          <div className="col">
            <h6>{timeline[0]}</h6>
            <p className="text-muted m-b-0">{timeline[1]}</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className="row p-b-30">
          <div className="col-auto text-right update-meta">
            <p
              className="text-muted m-b-0 d-inline-flex"
              style={{ width: '6rem' }}
            >
              ERROR
            </p>
            <i className="fas fa-lock-alt bg-dark update-icon" />
          </div>
          <div className="col">
            <a href="#!">
              <h6>TIDAK DI KENAL</h6>
            </a>
            <p className="text-muted m-b-0">Terjadi kesalahan</p>
          </div>
        </div>
      )
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      getTimelineSiswa(offset)
      window.addEventListener('scroll', checkScrollPosition)
    }
    return () => {
      isSubscribed = false
      window.removeEventListener('scroll', checkScrollPosition)
    }
  }, [offset])

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="card latest-update-card">
        <div className="card-header">
          <h5>Aktivitas Terkini</h5>
        </div>
        <div className={'card-body'}>
          <br />
          <br />
          <div className="latest-update-box">
            {timelineSiswa.length === 0 && (
              <p className="text-center f-18 f-w-700 text-secondary">
                Tidak ada aktifitas terkini
              </p>
            )}
            {timelineSiswa.map((item, idx) => (
              <RenderTimeline
                item={item}
                key={idx}
              />
            ))}
            {isLoading && (
              <div className="col-sm-12 text-center">
                <br />
                <LoadingSpinner />
                <br />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

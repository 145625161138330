import React from 'react'
import _ from 'lodash'
import FileChatPreviewer from './file-chat-previewer'

export default function ChatReplyPreviewer({ chatItem, isMessageFromMe }) {
  /* ========================================= Constants ======================================== */
  const { id } = JSON.parse(localStorage.getItem('user'))
  const replyData     = chatItem
  const isReplyFromMe = replyData.user_id === id

  /* ========================================== Styles ========================================== */
  const styles = {
    reply: {
      background   : 'rgba(0,0,0,.15)',
      borderRadius : '5px',
      padding      : '0px',
      minWidth     : '250px',
      marginBottom : '3px',
      borderLeft   : !isMessageFromMe && '5px solid rgb(255,195,18)',
      borderRight  : isMessageFromMe && '5px solid rgb(255,195,18)',
    },
    container : {
      flexDirection: isMessageFromMe ? 'row-reverse' : 'row',
    },
    content : {
      padding  : '5px',
      maxWidth : '500px',
    },
    previewer : {
      maxHeight: '75px'
    }
  }

  const Icon = () => {
    if (replyData.type_text?.includes('image')) {
      return <i className='fas fa-image' /> 
    }
    else if (replyData.type_text?.includes('audio')) {
      return <i className='fas fa-music' /> 
    }
    else if (replyData.type_text?.includes('video')) {
      return <i className='fas fa-video' /> 
    }
    else {
      return <i className='fas fa-list' /> 
    }
  }

  const Text = () => {
    if (!replyData?.text) {
      return `File: ` + replyData?.type_text
    }
    return _.chunk(replyData.text, 50)[0] || null
  }

  const Epsilon = () => replyData.text?.length > 50 ? '...' : null

  /* ========================================== Output ========================================== */
  return (
    <div style={styles.reply}>
      <div className='d-flex justify-content-between' style={styles.container}>
        <div style={styles.content}>
          <p className="mb-0 mt-1 f-w-700 f-10">{isReplyFromMe ? 'Anda' : replyData.name}</p>
          <p className="mb-1 mt-0 f-w-400 f-10">
            <Icon /> <Text /> <Epsilon />
          </p>
        </div>
        { replyData.media && <FileChatPreviewer style={styles.previewer} link={replyData.media} mimeTypes={replyData.type_text} isReply={true} /> }
      </div>
    </div>
  )  
}

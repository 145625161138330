import React from 'react'
import PathwayList from './pathway-list'
import SyaratMatpel from './syarat-matpel'

export default function Informasi({ classData, pathwayAfters = [] }) {
  return (
    <>
      {classData?.classStatus?.status === 'done' && (
        <div className="mb-4">
          <div className="d-flex justify-content-center">
            <div
              className="rounded-circle p-2 my-3"
              style={{
                border: '3px solid #9ccc65',
              }}
            >
              <i className="fas fa-check text-success f-48" />
            </div>
          </div>
          <p className="text-center f-20 mb-0">Selamat!</p>
          <p className="text-center f-20">
            Anda telah menyelesaikan sub mata pelajaran ini
          </p>
        </div>
      )}
      <div className="mb-3">
        <div className="rounded border p-3">
          <h5>Deskripsi :</h5>
          <p
            className="mb-0"
            style={{
              fontSize: '16px',
            }}
          >
            {classData?.data?.description}
          </p>
        </div>
      </div>
      {classData?.data?.prerequisite ? (
        <div className="mb-3">
          <SyaratMatpel prerequisite={classData.data.prerequisite} />
        </div>
      ) : null}
      {pathwayAfters.length > 0 && (
        <div className="">
          <PathwayList classes={pathwayAfters} />
        </div>
      )}
    </>
  )
}

import React from 'react'
import useForumDetail from 'context/forum-detail'
import useForumDetailItem from 'context/forum-detail-item'

export default function ForumDetailReplyForm() {
  /* ====================================== Consume Context ===================================== */
  const { reply } = useForumDetail()
  const { item }  = useForumDetailItem()

  /* ========================================== Output ========================================== */
  async function handleSendReply() {
    item.reply.setLoading(true)
    await reply.send(item.id)
    item.reply.setLoading(false)
  }
  
  return (
    <div className="input-group mb-3">
      <input
        value={reply.data}
        onChange={(v) => reply.set(v.target.value)}
        type="text"
        className="form-control"
        placeholder="Berikan balasan"
        aria-label="Recipient's username"
        aria-describedby="basic-addon2"
      />
      <div className="input-group-append">
        <button
          disabled={item.reply.loading}
          onClick={handleSendReply}
          className="btn  btn-primary"
          type="button"
        >
          Kirim
        </button>
      </div>
    </div>
  )
}

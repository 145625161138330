import React from 'react'
import { img } from 'pages/kelas/dropzone-css'

export default function FileChatPreviewer({ mimeTypes, link, isReply = false }) {

  /* ========================================== Styles ========================================== */
  const styles = {
    previewContainer: {
      ...img,
      maxWidth  : '100%',
      maxHeight : isReply ? '75px' : '350px',
    },
  }

  if (isReply) {
    styles.previewContainer.margin = '0px'
  }
  
  /* ======================================= Preview Image ====================================== */
  if (mimeTypes.includes('image')) {
    return (
      <img
        src   = {link}
        alt   = "preview"
        style = {styles.previewContainer}
      />
    )
  } 
  
  /* ======================================= Preview Video ====================================== */
  else if (mimeTypes.includes('video')) {
    return (
      <video
        style = {styles.previewContainer}
        controls
      >
        <source
          src  = {link}
          type = {`${mimeTypes}`}
        />
        Your browser does not support the video tag.
      </video>
    )
  } 
  
  /* ======================================= Preview Audio ====================================== */
  else if (mimeTypes.includes('audio')) {
    return (
      <audio controls>
        <source
          src  = {link}
          type = {`${mimeTypes}`}
        />
        Your browser does not support the audio element.
      </audio>
    )
  } else {
    return null
  }

}

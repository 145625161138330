import React, { useEffect, useState } from 'react'
import { useBaseContext } from 'context/base'
import useMainContent from 'context/main-content'
import useContentPlayerContext from 'context/content-player'

const ContentAudio = ({ isPreview = false, content_text, contents_url, content_source }) => {
  /* ====================================== Consume Context ===================================== */
  const { putRequest }               = useBaseContext()
  const { content, kelas, activity } = useContentPlayerContext()
  const { shouldSendCCA }            = useMainContent()

  const using_contents_url = contents_url || content.contents_url

  /* ======================================= Local States ======================================= */
  const [activity_id, setActivityId]  = useState('')
  const [class_id, setClassId]        = useState('')
  const [contentsUrl, setContentsUrl] = useState('')

  // CLASS ACTIVITIES STATE
  const [classActivities, setClassActivities] = useState([])
  const [lastActivity, setLastActivity]       = useState({})
  const [lastPosition, setLastPosition]       = useState(0)

  // Soundcloud state
  const [isWidgetReady, setIsWidgetReady] = useState(false)

  /* ========================================= Functions ======================================== */
  function addSoundcloudApi() {
    const script = document.createElement('script')
    script.src = 'https://w.soundcloud.com/player/api.js'
    script.async = true
    document.body.appendChild(script)
  }

  function checkIsSoundcloudFinish() {
    if (using_contents_url?.source === 'soundcloud') {
      const widget = window.SC.Widget(document.getElementById('sc-player'))
      widget.bind(window.SC.Widget.Events.FINISH, function () {
        completeActivities()
      })
      widget.bind(window.SC.Widget.Events.PLAY_PROGRESS, function () {
        widget.getPosition(function (position) {
          localStorage.setItem('last_position', Math.round(position))
        })
      })
    }
  }

  function checkAudioMp3Finish() {
    if (isPreview === false) {
      completeActivities()
    }
  }

  async function completeActivities() {
    if (content.feedback === null) {
      let form = new FormData()
      form.append('activity_id', activity_id)
      form.append('content_id', content.id)
      form.append('class_id', class_id)
      form.append('is_complete', shouldSendCCA ? 1 : 0)
      const response = await putRequest(
        'class-content-activities',
        form,
      )
      if (response) {
        shouldSendCCA && activity.setIdDone(content.id)
      } else {
        console.error('ERROR', response)
      }
    } else {
      activity.setMediaIdDone(content.id)
    }
  }

  function checkLastActivity() {
    let updated = []
    let lastUpdated = ''
    classActivities.forEach((activity) => {
      const miliseconds = new Date(activity.updated_at).getTime()
      updated.push(miliseconds)
    })
    lastUpdated = new Date(Math.max(...updated)).toISOString()
    classActivities.forEach((activity) => {
      if (activity.updated_at === lastUpdated) {
        setLastActivity(activity)
      }
    })
  }

  function checkIsCompleted() {
    classActivities.forEach((act) => {
      if (act.completed_at !== null) {
        activity.setMediaIdDone(content.id)
      }
    })
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    addSoundcloudApi()

    return () => {
      setIsWidgetReady(false)
    }
  }, [])

  useEffect(() => {
    if (isPreview === false) {
      setActivityId(activity.id)
    }
  }, [activity.id])

  useEffect(() => {
    if (isPreview === false) {
      setClassId(kelas.id)
    }
  }, [kelas.id])

  useEffect(() => {
    if (isPreview === false) {
      setClassActivities(content.class_content_activities)
    }
    setContentsUrl(using_contents_url?.url)
  }, [content.id, contents_url])
  
  useEffect(() => {
    if (isPreview === false) {
      if (classActivities.length !== 0) {
        checkLastActivity()
        checkIsCompleted()
      }
    }
  }, [classActivities])

  useEffect(() => {
    if (isPreview === false) {
      if (Object.keys(lastActivity).length !== 0) {
        setLastPosition(lastActivity.last_position)
      }
    }
  }, [lastActivity])

  useEffect(() => {
    if (isWidgetReady) {
      if (isPreview === false) {
        checkIsSoundcloudFinish()
      }
    }
  }, [isWidgetReady])

  /* ========================================== Output ========================================== */
  return (
    <>
      {using_contents_url?.source === 'internal' ? (
        <audio
          style={{ width: '100%' }}
          key={content.id}
          controls
          controlsList="nodownload"
          onContextMenu={(event) => event.preventDefault()}
          onEnded={() => checkAudioMp3Finish()}
          // onLoadedData={(event) => {
          //   event.currentTarget.currentTime = lastPosition;
          // }}
          onTimeUpdate={(event) => {
            localStorage.setItem(
              'last_position',
              Math.round(event.currentTarget.currentTime),
            )
          }}
        >
          {contentsUrl && (
            <source
              src={`${contentsUrl}`}
              type="audio/mpeg"
            />
          )}
          Your browser does not support the audio tag.
        </audio>
      ) : null}
      {using_contents_url?.source === 'soundcloud' ? (
        <iframe
          title="sc-widget"
          id="sc-player"
          width="100%"
          height="166"
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src={`https://w.soundcloud.com/player/?url=${contentsUrl}&amp&download=false&sharing=false`}
          onLoad={() => {
            setIsWidgetReady(true)
          }}
        />
      ) : null}
      {using_contents_url?.source === 'gdrive' ? (
        <iframe
          src={`${contentsUrl}/preview`}
          style={{
            width: '100%',
            height: '95px',
            border: 'none',
            backgroundColor: '#ddd',
          }}
          onLoad={() => checkAudioMp3Finish()}
        />
      ) : null}
      {using_contents_url?.source === 'umum' ? (
        <audio
          style={{ width: '100%' }}
          key={content.id}
          controls
          controlsList="nodownload"
          onContextMenu={(event) => event.preventDefault()}
          onEnded={() => checkAudioMp3Finish()}
          // onLoadedData={(event) => {
          //   event.currentTarget.currentTime = lastPosition;
          // }}
          onTimeUpdate={(event) => {
            localStorage.setItem(
              'last_position',
              Math.round(event.currentTarget.currentTime),
            )
          }}
        >
          <source
            src={`${contentsUrl}`}
            type="audio/mpeg"
          />
          Your browser does not support the audio tag.
        </audio>
      ) : null}
    </>
  )
}

export default ContentAudio

import React from 'react'

export default function Tab({ quiz }) {
  return (
    <div className="col-12 d-flex justify-content-center">
      <ul className="nav nav-tabs">
        {quiz.history.map((history_item, history_index) => {
          const isActive = quiz.shown.index === history_index ? 'active' : ''

          return (
            <li 
              className="nav-item"
              key={history_item.id}
            >
              <a
                className={`nav-link ${isActive}`}
                aria-current="page"
                onClick={() => quiz.toggle.index(history_index)}
              >
                Match #{history_index + 1} {history_item.isRemidi && '(Remidi)'}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

import React from 'react'
import { timeConvert } from 'util/global'

const WaktuBelajar = ({ siswa }) => {
  return (
    <div className="card bg-c-green text-white widget-visitor-card">
      <div className="card-body text-center">
        <h2 className="text-white">{timeConvert(siswa?.durasi_kelas)}</h2>
        <h5 className="text-white">Waktu Belajar</h5>
        <i className="fas fa-user-clock"></i>
      </div>
    </div>
  )
}

export default WaktuBelajar

import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import Type1 from './Type1'
import Type2 from './Type2'
import Type3 from './Type3'
import Type4 from './Type4'
import Type5 from './Type5'
import Type6 from './Type6'
import ProfileUser from './profile-user'
import useGetSemesterType from 'hooks/useGetSemesterType'
import Type7 from './Type7'
import DetailNilai from './detailNilai'

export default function InputRaporAllSetScore() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  let { userId, semester } = useParams()
  const { semesterType } = useGetSemesterType({ semester })

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [data, setData] = useState([])
  const [collect, setCollect] = useState([])

  const [isLoadingInsert, setIsLoadingInsert] = useState(false)
  const [responseData, setResponseData] = useState({})

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/input-rapor-all', name: 'Rapor All' }]

  /* ========================================= Functions ======================================== */
  async function getRapotGroup() {
    const response = await getRequest('report-group?ta_semester_code=' + semester + '&user_id=' + userId)
    if (response) {
      setData(response.data)
      setResponseData(response)
    }
  }

  async function simpan() {
    setIsLoadingInsert(true)
    var response = await postRequest('save-rapot', collect)
    if (response) {
      window.notification('Berhasil', 'disimpan', 'success')
      history.push('/preview-rapor/' + userId + '/' + semester)
    }
    setIsLoadingInsert(false)
  }

  function RenderGroup({ item, i }) {
    return (
      <InputTemplate
        type={item.type}
        reportGroupId={item.id}
        item={item}
        i={i}
      />
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getRapotGroup()
  }, [])

  /* ======================================== Components ======================================== */
  function InputTemplate({ type, reportGroupId, i, item }) {

    function response(res) {
      const col = collect
      const filter = col.filter(
        (x) =>
          x.report_group_id == reportGroupId &&
          x.categories_id == res.categories_id,
      )
      if (filter.length == 0) {
        col.push(res)
      } else {
        const index = col.findIndex(
          (x) =>
            x.report_group_id == reportGroupId &&
            x.categories_id == res.categories_id,
        )
        col[index] = res
      }
      setCollect(col)
    }

    if (type == 0) {
      return (
        <div className="mb-5">
          <h5>{`${++i}. ${item.name}`}</h5>
          <Type1
            userId={userId}
            reportGroupId={reportGroupId}
            response={response.bind(this)}
            semesterType={semesterType}
          />
        </div>
      )
    } else if (type == 23) {
      return (
        <div className="mb-5">
          <h5>{`${++i}. ${item.name}`}</h5>
          <Type2
            userId={userId}
            reportGroupId={reportGroupId}
            response={response.bind(this)}
          />
        </div>
      )
    } else if (type == 3) {
      return (
        <div className="mb-5">
          <h5>{`${++i}. ${item.name}`}</h5>
          <Type3
            userId={userId}
            reportGroupId={reportGroupId}
            response={response.bind(this)}
          />
        </div>
      )
    } else if (type == 4) {
      return (
        <div className="mb-5">
          <h5>{`${++i}. ${item.name}`}</h5>
          <Type4
            userId={userId}
            reportGroupId={reportGroupId}
            response={response.bind(this)}
          />
        </div>
      )
    } else if (type == 5) {
      return (
        <div className="mb-5">
          <h5>{`${++i}. ${item.name}`}</h5>
          <Type5
            userId={userId}
            reportGroupId={reportGroupId}
            response={response.bind(this)}
          />
        </div>
      )
    } else if (type == 6) {
      return (
        <div className="mb-5">
          <h5>{`${++i}. ${item.name}`}</h5>
          <Type6
            userId={userId}
            reportGroupId={reportGroupId}
            response={response.bind(this)}
          />
        </div>
      )
    } else if (type == 11 && responseData?.kelas === 202 && semesterType === 'even') { // This mean. type 11 will appear if student class is 12 and semester is even
      return (
        <div className="mb-5">
          <h5>{`${++i}. ${item.name}`}</h5>
          <Type7
            userId={userId}
            reportGroupId={reportGroupId}
            response={response.bind(this)}
          />
        </div>
      )
    }
    return null
  }

  const renderGroupMemo = useMemo(() => {
    return data.map((item, i) => (
      <RenderGroup
        item={item}
        key={`render_group_${i}`}
        i={i}
      />
    ))
  }, [data, responseData])


  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Set Rapor" links={links} />

        <div className="card">
          <div className="card-body">
            <ProfileUser userId={userId} />

            {responseData?.is_template_raport_baru == true ? 
            <DetailNilai />
            : null}
            {renderGroupMemo}

            <button
              type="button"
              disabled={isLoadingInsert}
              onClick={isLoadingInsert ? () => { } : () => simpan()}
              className="btn btn-primary float-right"
            >
              {isLoadingInsert ? (
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                />
              ) : null}
              Simpan & Tinjau
            </button>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React, { useEffect, useState } from 'react'
import FormCheckTypeRapor from 'component/content-form/form-check-type-rapor'
import { useBaseContext } from 'context/base'
import TypeScoreForm from 'component/type-score-form'

const FormSetToBeScore = ({ value, handleChange }) => {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  const [listTypeScore, setListTypeScore] = useState([])

  async function getTypeScore() {
    const response = await getRequest('type-score')
    if (response) {
      setListTypeScore(response.data)
    }
  }

  useEffect(() => {
    getTypeScore()
  }, [])

  return (
    <div className="form-group row mb-5">
      <label className="col-sm-3 col-form-label">Jadikan Sebagai Penilaian</label>
      <div className="form-group col-sm-9">
        <div className="switch switch-primary d-inline m-r-10">
          <input
            onChange={(e) => handleChange({
                ...value,
                isScore: e.target.checked
              })}
            checked={value.isScore}
            type="checkbox"
            id="switch-active-is-tobe-score"
          />
          <label
            htmlFor="switch-active-is-tobe-score"
            className="cr"
          ></label>
        </div>
        <label htmlFor="switch-active-is-tobe-score">
          {value.isScore ? 'Ya' : 'Tidak'}
        </label>

        {value.isScore
        ?
        <>
          <FormCheckTypeRapor 
            isKeterampilan={value.isKeterampilan}
            handleChangeIsKeterampilan={(e) => handleChange({
              ...value,
              isKeterampilan: e.target.checked
            })}
            isPengetahuan={value.isPengetahuan}
            handleChangeIsPengetahuan={(e) => handleChange({
              ...value,
              isPengetahuan: e.target.checked
            })}
          />
          <TypeScoreForm
            listTypeScore={listTypeScore}
            typeScore={value.selectedTypeScore}
            isTypeScoreActive={value.isTypeScoreActive}
            isOptionalJenisPenilaian={false}
            handleActiveTypeScore={(e) => handleChange({
              ...value,
              isTypeScoreActive: e.target.checked
            })}
            handleChangeTypeScore={(e) => handleChange({
              ...value,
              selectedTypeScore: e.target.value
            })}
          />
        </>
        :null}
      </div>
    </div>
  )
}

export default FormSetToBeScore

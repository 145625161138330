import React from 'react'

const RenderBadgesItem = ({ item }) => {
  /* ========================================= Functions ======================================== */
  async function handleDownloadBadge() {
    if (item.image) {
      window.location.href = item.linkImage
    } else {
      window.location.href = '/image/badges/default_badge.png'
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <div
      style={{ width: '140px' }}
      className="text-center m-3"
    >
      <img
        className={
          item.isComplete == null ? 'disabled w-100 mb-2' : 'w-100 mb-2'
        }
        src={item.linkImage}
        alt={item.desc ? item.desc : 'Badge'}
        onError={(event) =>
          (event.currentTarget.src = '/image/badges/default_badge.png')
        }
      />
      <h6>{item.desc}</h6>
      <span className="d-flex align-items-center justify-content-center mb-2">
        {`(${item.value} Poin)`}
        {item.isComplete != null && (
          <i className="fas fa-check-circle ml-1 text-primary" />
        )}
      </span>
      {item.isComplete && (
        <button
          className="btn btn-sm btn-primary"
          style={{ alignSelf: 'center' }}
          onClick={handleDownloadBadge}
        >
          <i className="feather icon-download" />
        </button>
      )}
    </div>
  )
}

export default RenderBadgesItem

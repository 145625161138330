import React, { useEffect, useState } from 'react'
import moment from 'moment'
import useChatContext from 'context/chat'
import ListItemWrapper from './partials/list-item-wrapper'

const MessageItem = ({ message }) => {
  /* ====================================== Consume Context ===================================== */
  const { messages, current, contact } = useChatContext()

  /* ===================================== Chat State & Ref ===================================== */
  const [isHover, setIsHover] = useState(false)
  const [chats, setChats]     = useState([])
  const [chat, setChat]       = useState(null)
  const [title, setTitle]     = useState('')
  const [avatar, setAvatar]   = useState('')

  /* ====================================== Last Chat State ===================================== */
  const [newestChat, setNewestChat]             = useState({})
  const [newestChatText, setNewestChatText]     = useState('')
  const [newestChatTime, setNewestChatTime]     = useState('')
  const [newestChatSender, setNewestChatSender] = useState('')

  /* ========================================= Constants ======================================== */
  const defaultImage = message.type === 'group'
      ? `/admin/assets/images/user/blank-avatar-group.svg`
      :  `/admin/assets/images/user/blank-avatar-user.svg`

  /* ========================================= Functions ======================================== */
  function checkNewestChat() {
    const todayDate = moment(new Date()).format('DD-MM-YYYY')
    const chatsTimes = chats.map((chat) => {
      return new Date(chat.timestamps).getTime()
    })
    const newestTimestamps = new Date(Math.max(...chatsTimes)).toISOString()
    chats.forEach((chat) => {
      if (chat.timestamps === newestTimestamps) {
        setNewestChat(chat)
      }
    })

    const newestChatDate = moment(newestTimestamps).format('DD-MM-YYYY')
    if (newestChatDate === todayDate) {
      setNewestChatTime(moment(newestTimestamps).format('HH:mm'))
    } else {
      setNewestChatTime(moment(newestTimestamps).format('DD/MM/YY'))
    }
  }

  function checkNewestSender() {
    const { id } = JSON.parse(localStorage.getItem('user'))
    if (newestChat.user_id === id) {
      setNewestChatSender('Anda')
    } else {
      setNewestChatSender(newestChat.name)
    }
  }

  function initChat() {
    if (message.type === 'group') {
      setAvatar(defaultImage)
      setChat(message)
      setTitle(message.title)
    } else if (message.type === 'siswa') {
      const { name, id } = JSON.parse(localStorage.getItem('user'))
      let senderName = ''
      let avatar = ''
      message.participants.forEach((participant) => {
        if (participant.name !== name) senderName = participant.name
        if (participant.id !== id) {
          contact.list.forEach((contact) => {
            if (contact.id === participant.id) avatar = contact.image
          })
        }
      })

      if (message.title === name) {
        setTitle(senderName)
      } else {
        setTitle(message.title)
      }
      setChat(message)
      setAvatar(avatar)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    setChats(message.chats)
    initChat()
  }, [message, contact.list])

  useEffect(() => {
    if (chats.length !== 0) {
      checkNewestChat()
    }
  }, [chats, messages.list])

  useEffect(() => {
    if (Object.keys(newestChat).length !== 0) {
      checkNewestSender()
      setNewestChatText(newestChat.text)
    }
  }, [newestChat])

  /* ========================================== Output ========================================== */
  return (
    <ListItemWrapper>
      <button
        className = "px-0 btn d-flex align-items-center text-left"
        style     = {{
          width           : '100%',
          height          : '70px',
          backgroundColor : isHover ? 'rgba(221,221,221,0.2)': 'transparent',
          overflow       : 'hidden',
        }}
        title        = {message.title ? message.title : 'Chat'}
        onMouseEnter = {() => setIsHover(true)}
        onMouseLeave = {() => setIsHover(false)}
        onClick      = {() => {
          current.set(chat)
        }}
      >
        <div className="col-auto p-r-0">
          <div className="u-img">
            <img
              src={
                message.type === 'group'
                  ? defaultImage
                  : `${avatar || defaultImage}`
              }
              onError={(event) => (event.currentTarget.src = defaultImage)}
              alt={message.title ? message.title : 'Message'}
              className="img-radius profile-img"
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex justify-content-between align-items-center mb-1">
            <h6 className="m-0 f-12">{title}</h6>
            <span
              className="text-muted"
              style={{ fontSize: '10px' }}
            >
              {newestChatTime ? newestChatTime : '00:00'}
            </span>
          </div>
          <p
            className="text-muted m-b-0"
            style={{
              height: '20px',
              overflow: 'hidden',
            }}
          >
            {newestChatSender ? newestChatSender : '-'}
            {': '}
            {newestChatText ? newestChatText : '-'}
          </p>
        </div>
      </button>
    </ListItemWrapper>
  )
}

export default MessageItem

import React from 'react'
import useContentPlayerContext from 'context/content-player'

export default function QuizEvalColPrasyarat({ item }) {
  /* ====================================== Consume Context ===================================== */
  const { content } = useContentPlayerContext()

  /* ========================================= Constants ======================================== */
  const _isFeedbackRequired      = content.feedback
  const _isGrupKecilRequired     = content.group_status
  const _isFeedbackPassed        = content.feed?.feed_temps.find((ft) => ft.content_id === content.id && ft.user_id === item.id)
  const _isGrupKecilPassed       = item?.grup_kecil_passed

  /* ========================================== Output ========================================== */
  return (
    (_isFeedbackRequired ||
      _isGrupKecilRequired) && (
      <td>
        {_isFeedbackRequired && (
          <div
            className={`mr-1 badge badge-${
              _isFeedbackPassed ? 'success' : 'danger'
            }`}
          >
            {_isFeedbackPassed ? 'Sudah' : 'Belum'} Feedback
          </div>
        )}
        {_isGrupKecilRequired && (
          <div
            className={`mr-1 badge badge-${
              _isGrupKecilPassed ? 'success' : 'danger'
            }`}
          >
            {_isGrupKecilPassed ? 'Sudah' : 'Belum'} Grup Kecil
          </div>
        )}
      </td>
    )
  )
}

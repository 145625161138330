import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'

export default function KelasKategori() {
	/* ========================================== Helpers ========================================== */
  const history = useHistory()

	/* ====================================== Consume Context ====================================== */
  const { getRequest, deleteRequest } = useBaseContext()

	/* ======================================== Local States ======================================= */
  const [categories, setCategories]     = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  
  const [limit, setLimit]     = useState(10)
  const [offset, setOffset]   = useState(0)
  const [keyword, setKeyword] = useState('')

  /* ========================================= Functions ======================================== */
  async function getCategories() {
    try {
      if (keyword === '') {
        const response = await getRequest(
          `categories?limit=${limit}&offset=${offset}`,
        )
        if (response) {
          setCategories(response.data)
          setTotalRecords(response.totalRecords)
        }
      } else {
        const response = await getRequest(
          `categories?limit=${limit}&offset=${offset}&keyword=${keyword}`,
        )
        if (response) {
          setCategories(response.data)
          setTotalRecords(response.totalRecords)
        }
      }
    } catch (error) {
      console.error('ERROR', error)
    }
  }

	/* ========================================= Components ======================================== */
  const RenderItem = ({ item }) => {
    async function deleteItem(item) {
      Swal.fire({
        title: 'Apa anda yakin?',
        text: `Anda akan menghapus mata pelajaran ${item.title}!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Tidak',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await deleteRequest(`categories?id=${item.id}`)
          if (response) {
            getCategories()
            Swal.fire('Berhasil!', 'Mata pelajaran dihapus.', 'success')
          } else {
            Swal.fire(
              'Oppsss!',
              'Terjadi kesalahan coba beberapa saat lagi.',
              'error',
            )
          }
        }
      })
    }

    return (
      <tr>
        <td>{item.categories_type?.type}</td>
        <td>{item.title ? item.title : ''}</td>
        <td>
          <button
            type="button"
            className="btn btn-sm btn-success mr-2"
            onClick={() =>
              history.push('/kelas-kategori-edit', {
                id: item.id,
                title: item.title,
                parent_id: item.parent_id,
                active: item.active,
                category_type: item.category_type,
              })
            }
          >
            <i className="feather icon-edit mr-1" />
            <span>Edit</span>
          </button>
          <button
            type="button"
            className="btn btn-sm btn-info mr-2"
            onClick={() => history.push(`/kelas-add`, { category: item })}
          >
            <i className="fas fa-plus-circle mr-1" />
            <span>Tambah Sub Mata Pelajaran</span>
          </button>
          <button
            type="button"
            className="btn btn-sm btn-danger mr-2"
            onClick={() => deleteItem(item)}
          >
            <i className="feather icon-trash-2 mr-1" />
            <span>Hapus</span>
          </button>
        </td>
      </tr>
    )
  }

	/* ========================================== Effects ========================================== */
  useEffect(() => {
    getCategories()
  }, [limit])

  useEffect(() => {
    getCategories()
  }, [offset])

  useEffect(() => {
    getCategories()
  }, [keyword])

	/* =========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Mata Pelajaran" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header">
                <Link to="/kelas-kategori-add">
                  <button
                    type="button"
                    className="btn btn-primary"
                  >
                    <i className="feather icon-plus-circle mr-1" />
                    <span>Tambah Mata Pelajaran</span>
                  </button>
                </Link>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="dt-responsive table-responsive">
                      <DataTable
                        className={'table nowrap'}
                        data={categories}
                        totalRows={totalRecords}
                        show={[10, 20, 30, 40, 50]}
                        onChangeSearch={(val) => setKeyword(val)}
                        onChangeLimit={(val) => setLimit(val)}
                        onChangePage={(val) => setOffset(val)}
                        column={['Tipe', 'Mata Pelajaran', 'Actions']}
                        renderItem={(val, index) => (
                          <RenderItem
                            item={val}
                            key={index}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React from 'react'
import { useHistory } from 'react-router-dom'
import { useBaseContext } from 'context/base'

const BackButton = ({ text = 'Kembali' }) => {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ========================================= Functions ======================================== */
  function handleBack() {
    history.goBack()
  }

  /* ========================================== Output ========================================== */
  return (
    <button
      onClick={handleBack}
      type="button"
      className="btn btn-outline-danger mr-2"
    >
      {text}
    </button>
  )
}

export default BackButton

import React from 'react'

const StyleButton = ({ onClick, text }) => {
  return (
    <span
      className="ripple ripple-animate"
      style={{
        height: '109.297px',
        width: '109.297px',
        animationDuration: '0.7s',
        animationTimingFunction: 'linear',
        background: 'rgb(255, 255, 255)',
        opacity: '0.4',
        top: '-40.6485px',
        left: '-1.6485px',
      }}
    />
  )
}

export default StyleButton

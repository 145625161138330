import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { useEffect, useRef, useState } from "react"

const useScrollElementByRef = () => {
  const elementRef = useRef()
  const [elementPosition, setElementPosition] = useState({ x: 20, y: 150})
  const [isScrollToElementReached, setisScrollToElementReached] = useState(false)

  useScrollPosition(
    ({ currPos }) => {
      setElementPosition(currPos)
    }, [], elementRef
  )

  useEffect(() => {
    if (0 > elementPosition?.y) {
      setisScrollToElementReached(true)
    } else {
      setisScrollToElementReached(false)
    }

    return () => {
        setisScrollToElementReached(false)
    }
  }, [elementPosition])

  return {
    currentScrollPosition: elementPosition,
    elementRef,
    isScrollToElementReached,
  }
}

export default useScrollElementByRef
import React from 'react';
import InvalidWarning from 'component/invalid/warning'

export default function FormPenilaian({ 
  handleChangeScore, 
  handleChangeNote, 
  score, 
  note, 
  scoreRef,
  isScoreEmpty,
  isScoreInvalid,
  handleFormValidation
}) {
  return (
    <form className="col-12 border bg-light rounded py-4 mb-4">
      <div className="form-group mb-4">
        <h4 className="text-center">Form Nilai</h4>
      </div>
      <div className="form-group row mb-4">
        <label className="col-form-label col-4 offset-lg-2 col-lg-2">Catatan</label>
        <div className="col-8 col-lg-6">
          <textarea value={note} onChange={(event) => handleChangeNote(event.target.value)} className="form-control bg-light" placeholder="Catatan untuk siswa (opsional)" />
        </div>
      </div>
      <div className="form-group row mb-5">
        <label className="col-form-label col-4 offset-lg-2 col-lg-2">Nilai</label>
        <div className="col-8 col-lg-3">
          <input ref={scoreRef} value={score} onChange={(event) => handleChangeScore(event.target.value)} type="number" min="0" max="100" className="form-control bg-light" placeholder="0 - 100" />
          {isScoreEmpty ?
            <InvalidWarning text="Nilai harus diisi." />
            : null
          }
          {isScoreInvalid ?
            <InvalidWarning text="Nilai berisi angka 0 s.d. 100." />
            : null
          }
        </div>
      </div>
      <div className="form-group d-flex justify-content-center">
        <button onClick={handleFormValidation} type="button" className="btn btn-sm btn-success">
          <span>Terima Tugas</span>
        </button>
      </div>
    </form>
  );
}
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import Select from 'react-select'

import { useBaseContext } from 'context/base'
import DataTable from 'component/data-table'
import PathwayModal from 'component/pathway-modal'
import { popUpWindow, timeConvert } from 'util/global'

export default function ListSiswa({ kelasTitle }) {
  /* ========================================== Helpers ========================================= */
  const param = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [limit, setLimit]             = useState(10)
  const [offset, setOffset]           = useState(0)
  const [search, setSearch]           = useState('')
  const [totalRows, setTotalRows]     = useState(0)
  const [dataSiswa, setDataSiswa]     = useState([])
  const [groups, setGroups]           = useState([])
  const [group, setGroup]             = useState(null)
  const [userId, setUserId]           = useState('')
  const [siswaName, setSiswaName]     = useState('')
  const [pathwayType, setPathwayType] = useState('')
  const [modalEvent, setModalEvent]   = useState(null)

  /* ========================================= Constants ======================================== */
  const classId = param.kelasId

  /* ========================================= Functions ======================================== */
  async function getGroups() {
    const response = await getRequest(
      `group-by-mapel?class_id=${classId}`,
    )
    if (response) {
      if (response.data.length !== 0) {
        const groups = response.data.map((group) => ({
          value: group.id,
          label: group.group,
        }))
        setGroups([{ value: 'all', label: 'Semua Kelas' }, ...groups])
      }
    }
  }

  async function getAllDataSiswa() {
    const group_id = group?.value === 'all' ? null : group.value
    const response = await getRequest(
      `report-kelas-users?limit=${limit}&offset=${offset}&search=${search}&id=${param.kelasId}&group_id=${group_id}`,
    )
    if (response) {
      setDataSiswa(response.data)
      setTotalRows(response.totalRecords)
    } else {
      console.error('ERROR', response)
    }
  }

  function handleConvertDateMoment(item) {
    let date = moment(item).add(30, 'days').valueOf()
    let resultDateMoment =
      date < moment().valueOf()
        ? moment(item).format('DD MMM YYYY')
        : moment(item).fromNow()

    return resultDateMoment
  }

  const RenderItem = ({ item }) => {
    const progress = item.progress_percent || 0

    return (
      <tr>
        <td>{item.nim ? item.nim : <i className="feather icon-minus" />}</td>
        <td>{item.name ? item.name : <i className="feather icon-minus" />}</td>
        <td
          style={{ width: '200px' }}
          title={`Progress ${progress} %`}
        >
          {progress === 0 ? (
            <div
              className="progress-bar bg-light"
              role="progressbar"
              style={{ width: `100%` }}
              aria-valuenow={`100%`}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span
                className="text-dark"
                style={{ fontSize: '12px', fontWeight: 'bold' }}
              >
                {progress}%
              </span>
            </div>
          ) : (
            <>
              {progress === 100 ? (
                <div
                  className="progress"
                  style={{ height: '20px' }}
                >
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                  >
                    <span style={{ fontSize: '12px', fontWeight: '700' }}>
                      {progress} %
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  className="progress"
                  style={{ height: '20px' }}
                >
                  <div
                    className="progress-bar bg-info"
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                  >
                    <span style={{ fontSize: '12px', fontWeight: '700' }}>
                      {progress && `${progress}%`}
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        </td>
        <td>
          {item.terdaftar ? (
            handleConvertDateMoment(item.terdaftar)
          ) : (
            <i className="feather icon-minus" />
          )}
        </td>
        <td>
          {item.selesai ? (
            handleConvertDateMoment(item.selesai)
          ) : (
            <i className="feather icon-minus" />
          )}
        </td>
        <td>
          {item.lama_penyelesaian ? (
            timeConvert(item.lama_penyelesaian)
          ) : (
            <i className="feather icon-minus" />
          )}
        </td>
        <td>
          <button
            type="button"
            className="btn btn-sm btn-info mr-1"
            title="Pathway Kelas Before"
            data-toggle="modal"
            data-target="#pathway-setting-before"
            onClick={() => {
              setUserId(item.id)
              setSiswaName(item.name)
              setModalEvent('open')
              setPathwayType('before')
            }}
          >
            <span>Pathway Before</span>
          </button>
          <button
            type="button"
            className="btn btn-sm btn-warning mr-1"
            title="Pathway Kelas After"
            data-toggle="modal"
            data-target="#pathway-setting-after"
            onClick={() => {
              setUserId(item.id)
              setSiswaName(item.name)
              setModalEvent('open')
              setPathwayType('after')
            }}
          >
            <span>Pathway After</span>
          </button>
          <button
            type="button"
            className="btn btn-sm btn-success"
            onClick={() =>
              popUpWindow(
                `/laporan-aktifitas-siswa-progress/${item.id}/${classId}`,
                window,
                800,
                600,
              )
            }
          >
            <span>Pathway Content</span>
          </button>
        </td>
      </tr>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getGroups()
  }, [])

  useEffect(() => {
    setOffset(0)
  }, [limit, search, group])

  useEffect(() => {
    group && getAllDataSiswa()
  }, [limit, offset, search, group])

  /* ======================================== Components ======================================== */
  const ClassPicker = () => (
    <>
      <h5>Pilih Kelas: </h5>
      <Select
        placeholder="Kelas"
        options={groups}
        onChange={(item) => setGroup(item)}
        className="mb-3"
        defaultValue={group || { value: null, label: 'Pilih Kelas' }}
      />
    </>
  )

  return (
    <>
      <ClassPicker />
      <div className="dt-responsive table-responsive">
        {group && (
          <DataTable
            className={'table table-striped table-bordered nowrap'}
            data={dataSiswa}
            totalRows={totalRows}
            show={[10, 20, 30, 40, 50]}
            onChangeSearch={(val) => {
              setSearch(val)
            }}
            onChangeLimit={(val) => {
              setLimit(val)
            }}
            onChangePage={(val) => {
              setOffset(val)
            }}
            column={[
              'No. Induk',
              'Nama',
              'Progress',
              'Mulai',
              'Selesai',
              'Waktu Pengerjaan',
              'Actions',
            ]}
            renderItem={(list, idx) => (
              <RenderItem
                index={idx}
                item={list}
                key={idx}
              />
            )}
          />
        )}
        <PathwayModal
          userId={userId}
          classId={classId}
          siswaName={siswaName}
          classTitle={kelasTitle}
          modalEvent={modalEvent}
          setModalEvent={setModalEvent}
          pathwayType={pathwayType}
          modalId={'pathway-setting-before'}
        />
        <PathwayModal
          userId={userId}
          classId={classId}
          siswaName={siswaName}
          classTitle={kelasTitle}
          modalEvent={modalEvent}
          setModalEvent={setModalEvent}
          pathwayType={pathwayType}
          modalId={'pathway-setting-after'}
        />
      </div>
    </>
  )
}

import React from 'react'
import useFormPenilaianEdit from './hooks'
import FormPenilaianEditTemplate from './partials/form-template'
import FormPenilaianEditHistory from './partials/form-history'

export default function FormPenilaianEdit(assignmentData) {
  const hooks = useFormPenilaianEdit(assignmentData)
  const { isHistory } = hooks

  const FormEdit = () => <FormPenilaianEditTemplate hooks={hooks} assignmentData={assignmentData} />

  return (
    <form className="col-12 border bg-light rounded py-4 mb-4">
      <FormEdit />
      <div>
        {isHistory && <FormPenilaianEditHistory hooks={hooks} />}
      </div>
    </form>
  )
}

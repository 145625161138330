import React from 'react'

const AnswerSurvey = ({
  answer,
  handleChangeAnswer,
  readOnly = false,
  surveyTemp,
}) =>
  readOnly ? (
    <li
      className="d-flex align-items-center mb-2 pl-2 py-2 rounded"
      style={{
        backgroundColor:
          surveyTemp.survey_answer_id === answer.id
            ? 'lightyellow'
            : 'transparent',
        border:
          surveyTemp.survey_answer_id === answer.id
            ? '1px solid #e3eaef'
            : '1px solid transparent',
      }}
    >
      <input
        className=""
        disabled
        defaultChecked={
          surveyTemp.survey_answer_id === answer.id
        }
        type="radio"
        name={answer.survey_quest_id}
        id={answer.id}
        value={answer.text}
      />
      <label
        className="m-0 ml-2"
        htmlFor={answer.id}
      >
        {answer.text}
      </label>
    </li>
  ) : (
    <li
      id={answer.id + 'container'}
      className="d-flex align-items-center mb-2 pl-2 py-2 rounded"
      style={{ position: 'relative', border: '1px solid transparent' }}
    >
      <input
        onChange={(event) => handleChangeAnswer(event, answer)}
        type="radio"
        name={answer.survey_quest_id}
        id={answer.id}
        value={answer.text}
      />
      <span className="m-0 ml-2">{answer.text}</span>
      <label
        htmlFor={answer.id}
        style={{
          position: 'absolute',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          cursor: 'pointer',
        }}
        onMouseEnter={() => {
          document.getElementById(
            answer.id + 'container',
          ).style.backgroundColor = 'lightyellow'
          document.getElementById(answer.id + 'container').style.border =
            '1px solid #e3eaef'
        }}
        onMouseLeave={() => {
          document.getElementById(
            answer.id + 'container',
          ).style.backgroundColor = 'transparent'
          document.getElementById(answer.id + 'container').style.border =
            '1px solid transparent'
        }}
      />
    </li>
  )

export default AnswerSurvey

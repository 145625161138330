import React from 'react'

export default function ListItemWrapper({ children }) {
  return (
    <li
      className="border-bottom"
      style={{
        width: '100%',
        backgroundColor: '#FFFFFF',
      }}
    >
      {children}
    </li>
  )
}

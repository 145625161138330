import React from 'react'
import useContentPlayerContext from 'context/content-player'

export default function QuizEvalAlert() {
  /* ====================================== Consume Context ===================================== */
  const { content } = useContentPlayerContext()

  /* ========================================== Output ========================================== */
  return (
    (content.feedback || content.group_status) && (
      <div
        className="mt-2 border rounded alert alert-warning w-100"
        role="alert"
      >
        <strong>
          <i className="fas fa-info-circle mr-1" />
          Siswa wajib
          {content.feedback && ` memberikan feedback`}
          {content.feedback && content.group_status && ` dan`}
          {content.group_status && ` terlibat dalam grup kecil`} pada materi.
        </strong>
      </div>
    )
  )
}

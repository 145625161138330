import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'

export default function KelolaGrade() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, deleteRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [limit, setLimit]   = useState(10)
  const [offset, setOffset] = useState(0)
  const [search, setSearch] = useState('')
  const [data, setData]     = useState([])
  const [total, setTotal]   = useState(0)

  /* ========================================= Functions ======================================== */
  async function fetchTypeScores() {
    let response = await getRequest(
      `type-score?limit=${limit}&offset=${offset}&search=${search}`,
    )
    if (response) {
      setData(response.data)
      setTotal(response.total_data)
    }
  }

  async function deleteItem(id) {
    Swal.fire({
      title: 'Apa anda yakin?',
      text: 'Anda akan menghapus Grade ini!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await deleteRequest(`type-score?id=${id}`)
        if (response) {
          if (response.message === 'Anda tidak memiliki akses') {
            Swal.fire(
              'Gagal hapus Mata Pelajaran!',
              'Anda tidak memiliki akses',
              'info',
            )
          }
          if (response.message === 'Berhasil update data') {
            Swal.fire('Berhasil!', 'Grade berhasil dihapus', 'success').then(
              () => window.location.reload(),
            )
          }
        } else {
          Swal.fire(
            'Oppsss!',
            'Terjadi kesalahan coba beberapa saat lagi.',
            'error',
          )
        }
      }
    })
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      fetchTypeScores()
    }

    return () => (isSubscribed = false)
  }, [search, limit, offset])
  
  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Kelola Grade" />

        <div className="row">
          {/* [ Form Validation ] start */}
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header">
                <Link to="/kelola-grade-add">
                  <button
                    type="button"
                    className="btn btn-primary"
                  >
                    <i className="feather icon-plus-circle mr-1" />
                    <span>Tambah Grade</span>
                  </button>
                </Link>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 dt-responsive table-responsive">
                    <DataTable
                      className={'table'}
                      data={data}
                      totalRows={total}
                      show={[10, 20, 30, 40, 50]}
                      onChangeSearch={(val) => {
                        setSearch(val)
                      }}
                      onChangeLimit={(val) => {
                        setLimit(val)
                      }}
                      onChangePage={(val) => {
                        setOffset(val)
                      }}
                      column={['#', 'Grade', 'Persentase', '']}
                      renderItem={(item, i) => (
                        <tr key={i}>
                          <td>{++i}</td>
                          <td>{item.type_score}</td>
                          <td>
                            {item.percentage == null || item.percentage == ''
                              ? 0
                              : item.percentage}
                            %
                          </td>
                          <td>
                            <button
                              onClick={() => {
                                history.push('kelola-grade-edit/' + item.id)
                              }}
                              className="btn btn-sm btn-success mr-2"
                            >
                              <i className="feather icon-edit mr-1" />
                              Edit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault()
                                deleteItem(item.id)
                              }}
                              className="btn btn-sm btn-danger"
                            >
                              <i className="feather icon-trash-2 mr-1" />
                              Hapus
                            </button>
                          </td>
                        </tr>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

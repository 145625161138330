import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'

export default function GroupAdd() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest} = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [group, setGroup]         = useState('')
  const [status, setStatus]       = useState('1')
  const [classType, setClassType] = useState('regular')

  // is invalid forms
  const [isInvalidGroup, setIsInvalidGroup]   = useState(false)
  const [isInvalidStatus, setIsInvalidStatus] = useState(false)
  const [isInvalidYear, setIsInvalidYear]     = useState(false)
  const [isInvalidType, setIsInvalidType]     = useState(false)

  const [year, setYear]                 = useState('')
  const [academicYear, setAcademicYear] = useState([])
  const [activeYear, setActiveYear]     = useState({})

  /* =========================================== Refs =========================================== */
  const refFormGroup = useRef()
  const refFormStatus = useRef()
  const refFormYear = useRef()
  const refFormType = useRef()

  /* ========================================= Functions ======================================== */
  async function getAcademicYear() {
    let response = await getRequest(`academic-year`)
    if (response) {
      setAcademicYear(response.data)
    }
  }

  async function getActiveYear() {
    let active = localStorage.getItem('semester')
    if (active) {
      var json = JSON.parse(active)
      setActiveYear(json.ta_tahun_code)
      setYear(json.ta_tahun_code)
    }
  }

  async function saveGroup() {
    if (group && status && year != 0 && classType) {
      setIsInvalidGroup(false)
      setIsInvalidStatus(false)
      setIsInvalidYear(false)
      
      let form = new FormData()
      form.append('group', group)
      form.append('active', status)
      form.append('group_type', 'users')
      form.append('ta_tahun_code', year)
      form.append('class_type', classType)
      
      let response = await postRequest('groups', form)
      if (response) {
        window.notification('Kelas', 'berhasil dibuat', 'success')
        history.goBack()
      }
    } else {
      if (!status) {
        setIsInvalidStatus(true)
        refFormStatus.current.focus()
      } else {
        setIsInvalidStatus(false)
      }

      if (year == 0) {
        setIsInvalidYear(true)
        refFormYear.current.focus()
      } else {
        setIsInvalidYear(false)
      }

      if (!group) {
        setIsInvalidGroup(true)
        refFormGroup.current.focus()
      } else {
        setIsInvalidGroup(false)
      }

      if (!classType) {
        setIsInvalidType(true)
        refFormType.current.focus()
      } else {
        setIsInvalidType(false)
      }

      window.notification('Mohon', 'lengkapi data', 'warning')
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getAcademicYear()
    getActiveYear()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Kelas"
          linkName="Tambah Kelas"
          links={[{ url: '/group', name: 'Kelas' }]}
        />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Tambah Kelas</h5>
              </div>
              <div className="card-body">
                <form
                  id="validation-form123"
                  action="#!"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <div className="form-group">
                            <label className="form-label">Nama Kelas</label>
                            <input
                              ref={refFormGroup}
                              onChange={(val) => setGroup(val.target.value)}
                              type="text"
                              className={
                                isInvalidGroup
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                              name="role"
                              placeholder="Nama Kelas"
                              required={true}
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-label">Tahun Ajaran</label>
                            <select
                              ref={refFormYear}
                              onChange={(val) => setYear(val.target.value)}
                              defaultValue={activeYear == 0 ? null : activeYear}
                              id="inputState"
                              className={
                                isInvalidYear
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            >
                              <option value="">- Pilih Tahun Ajaran -</option>
                              {academicYear.map((val, i) => {
                                return (
                                  <option
                                    key={val.code}
                                    selected={activeYear == val.code}
                                    value={val.code}
                                  >
                                    {val.description}{' '}
                                    {activeYear == val.code ? '(Aktif)' : null}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                          <div className="form-group">
                            <label className="form-label">Status</label>
                            <select
                              ref={refFormStatus}
                              onChange={(val) => setStatus(val.target.value)}
                              id="inputState"
                              className={
                                isInvalidStatus
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            >
                              <option
                                selected
                                value={'1'}
                              >
                                Aktif
                              </option>
                              <option value={'0'}>Tidak Aktif</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label className="form-label">Tipe Kelas</label>
                            <select
                              ref={refFormType}
                              onChange={(val) => setClassType(val.target.value)}
                              id="inputType"
                              className={
                                isInvalidType
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            >
                              <option value={'regular'}>Regular</option>
                              <option value={'paket'}>Paket</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="btn btn-outline-danger has-ripple"
                  >
                    Kembali
                    <span
                      className="ripple ripple-animate"
                      style={{
                        height: '87.8281px',
                        width: '87.8281px',
                        animationDuration: '0.7s',
                        animationTimingFunction: 'linear',
                        background: 'rgb(255, 255, 255)',
                        opacity: '0.4',
                        top: '-26.9062px',
                        left: '-13.3125px',
                      }}
                    />
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    onClick={() => saveGroup()}
                    className="btn btn-success has-ripple"
                  >
                    Simpan
                    <span
                      className="ripple ripple-animate"
                      style={{
                        height: '90.8281px',
                        width: '90.8281px',
                        animationDuration: '0.7s',
                        animationTimingFunction: 'linear',
                        background: 'rgb(255, 255, 255)',
                        opacity: '0.4',
                        top: '-26.4062px',
                        left: '0.65625px',
                      }}
                    />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React, { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

export default function GraphorsGroup({
  questions,
  handleChangeGraphors,
  setListGroup,
  listGroup,
  group,
}) {
  /* ========================================= Constants ======================================== */
  const grid = 8

  /* ======================================= Local States ======================================= */
  const [randId, setRandId] = useState(makeid(5))

  /* ========================================= Functions ======================================== */
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 1,
    margin: `0 0 ${grid}px 0`,
    borderRadius: '5px',
    background: isDragging ? 'lightyellow' : 'white',
    boxShadow: isDragging
      ? '1px 1px 8px rgba(200,200,200,0.5)'
      : '1px 1px 3px rgba(200,200,200,0.5)',
    border: isDragging ? '1px solid #ddd' : 'none',
    color: 'black',
    ...draggableStyle,
  })

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone   = Array.from(destination)
    const [removed]   = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = listGroup
    result[droppableSource.droppableId]      = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    var source = result.source
    var destination = result.destination
    if (source.droppableId === destination.droppableId) {
      var l = reorder(
        listGroup[source.droppableId],
        source.index,
        destination.index,
      )
      setRandId(makeid(5))
      var a = listGroup
      a[source.droppableId] = l
      setListGroup(a)
      handleChangeGraphors({
        questions,
        l: a,
      })
    } else {
      var listSource = listGroup[source.droppableId]
      var listDestination = listGroup[destination.droppableId]
      const result = move(listSource, listDestination, source, destination)
      
      setListGroup(result)
      setRandId(makeid(5))
      handleChangeGraphors({
        questions,
        l: result,
      })
    }
  }

  function makeid(length) {
    var result = ''
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  return (
    <div className="row px-4">
      {listGroup !== null ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={'list'}>
            {(provided, snapshot) => (
              <div className="col-sm-3 p-1">
                <div
                  className="card text-white bg-success"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <div className="card-header font-weight-bold">LIST</div>
                  <div className="card-body p-2">
                    {listGroup['list'] != undefined
                      ? listGroup['list'].map((item, index) => {
                          return (
                            <Draggable
                              key={randId + makeid(10)}
                              draggableId={item}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                  )}
                                  onMouseEnter={(e) =>
                                    (e.currentTarget.style.backgroundColor =
                                      'lightyellow')
                                  }
                                  onMouseLeave={(e) =>
                                    (e.currentTarget.style.backgroundColor =
                                      '#fff')
                                  }
                                >
                                  <b>{item}</b>
                                </div>
                              )}
                            </Draggable>
                          )
                        })
                      : null}
                    {provided.placeholder}
                  </div>
                </div>
              </div>
            )}
          </Droppable>
          <DRable />
        </DragDropContext>
      ) : null}
    </div>
  )
  function DRable() {
    return group.map((val, i) => {
      return (
        <Droppable
          droppableId={`${val}`}
          key={i}
        >
          {(provided, snapshot) => (
            <div className="col-sm-4 p-1">
              <div
                className="card text-white bg-info"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div className="card-header">
                  <b>{val.toUpperCase()}</b>
                </div>
                <div className="card-body p-2">
                  {listGroup[val] != undefined
                    ? listGroup[val].map((item, index) => (
                        <Draggable
                          key={randId + item}
                          draggableId={item}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}
                              onMouseEnter={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  'lightyellow')
                              }
                              onMouseLeave={(e) =>
                                (e.currentTarget.style.backgroundColor = '#fff')
                              }
                            >
                              <b>{item}</b>
                            </div>
                          )}
                        </Draggable>
                      ))
                    : null}
                  {provided.placeholder}
                </div>
              </div>
            </div>
          )}
        </Droppable>
      )
    })
  }
}

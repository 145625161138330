import { useBaseContext } from "context/base"
import { useReducer } from "react"
import { popUpWindow } from "util/global"

/**
 * @typedef {Object} ListGraphicOrganizerState
 * @property {BaseSelectFormatData} selectedFilter - The selected graphic organizer filter.
 * @property {Array<BaseSelectFormatData>} optionsFilter - The options for the graphic organizer filter.
 * @property {Array} data - The list of contents graphic organizer data.
 * @property {Object} meta - Metadata related to pagination and filtering.
 * @property {boolean} loading - Indicates if the data is currently being loaded.
*/

/**
 * @typedef {Object} Action
 * @property {'set_meta' | string} [type] - The type of action to perform.
 * @property {any} payload - The payload containing the data to update the state.
 */

/**
 * List Contents Reducer
 * @param {ListGraphicOrganizerState} state - The current state of the contents graphic organizer list.
 * @param {Action} action - The action to perform on the state.
 * @returns {ListGraphicOrganizerState} The updated state.
 */
const listGraphicOrganizerReducer = (state, action) => {
  switch (action.type) {
    case 'set_meta':
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload
        }
      }
    default:
      return {
        ...state,
        ...action.payload,
      }
  }
}

export function useGraphicOrganizerHook() {
  const { getRequest } = useBaseContext()

  /**
   * @type {[ListGraphicOrganizerState, (params: Action) => void]}
  */
  const [listGraphicOrganizer, dispatchListGraphicOrganizer] = useReducer(listGraphicOrganizerReducer, {
    selectedFilter: null,
    optionsFilter: [],
    errorMessage: '',
    data: [],
    meta: {
      search: '',
      limit: 10,
      offset: 0,
      totalData: 0,
    },
    loading: false,
  })

  /**
   * Get GraphicOrganizer Handler
   * 
   * @param {{
   *  setInitSelectValue: (listOptions: Array<BaseSelectFormatData>) => void
   *  graphor_type: number
   * }} getGraphicOrganizerParams 
   */
  const getGraphicOrganizerHandler = async (getGraphicOrganizerParams) => {
    dispatchListGraphicOrganizer({ payload: { loading: true } })
    const response = await getRequest('graphors?status=active')
    if (response) {
      dispatchListGraphicOrganizer({ payload: { loading: false } })

      const options = []
      
      response?.data?.forEach((item) => {
        if (item?.graphor_type === getGraphicOrganizerParams?.graphor_type) {
          options.push({
            value: item.id,
            label: item.description,
          })
        }
      })

      const responseData = {
        ...listGraphicOrganizer,
        optionsFilter: getGraphicOrganizerParams?.graphor_type ? options : [],
        data: response?.data,
        meta: {
          ...listGraphicOrganizer.meta,
          ...response.meta
        },
        loading: false,
      }

      if (getGraphicOrganizerParams?.setInitSelectValue && typeof getGraphicOrganizerParams?.setInitSelectValue === 'function') {
        getGraphicOrganizerParams.setInitSelectValue(options)
      }
      dispatchListGraphicOrganizer({ payload: responseData })
    } else {
      dispatchListGraphicOrganizer({ payload: { loading: false } })
    }
  }

  const popUpWindowKelolaGraphicOrganizerHandler = () => popUpWindow('/kelola-assignment/add?popup=true', window, 800, 600)

  return {
    data: {
      listGraphicOrganizer,
    },
    dispatch: {
      dispatchListGraphicOrganizer,
    },
    handler: {
      getGraphicOrganizerHandler,
      popUpWindowKelolaGraphicOrganizerHandler,
    }
  }
}
import React from 'react'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'

export default function DummyDataTable() {
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Dummy Datatable" />
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header"></div>
              <div className="card-body">
                <DataTable
                  className={'table'}
                  data={[1, 2, 3]}
                  totalRows={125}
                  show={[10, 20, 30, 40, 50]}
                  column={['#', 'First Name', 'Last Name', 'Username']}
                  renderItem={(item, i) => (
                    <tr>
                      <td>1</td>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                    </tr>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

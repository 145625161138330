import React from 'react'
import { avatarDefaultUser } from 'util/constant'

const Overview = ({ siswa }) => (
  <div className="card user-card user-card-1">
    <div className="card-header border-0 p-2 pb-0">
      <div className="cover-img-block">
        <div className="card bg-c-red text-white widget-visitor-card">
          <div className="card-body text-center p-5">
            <h2 className="text-white">Infografik Aktivitas Siswa</h2>
            <i className="fas fa-chart-line"></i>
          </div>
        </div>
      </div>
    </div>
    <div
      className="card-body p-0"
      style={{ marginTop: '-65px' }}
    >
      <div className="user-about-block text-center p-3 rounded">
        <div className="row align-items-end">
          <div className="col"></div>
          <div className="col">
            <img
              className="img-radius img-fluid"
              style={{ width: '60px', height: '60px' }}
              src={siswa ? `${siswa.linkAvatar}` : avatarDefaultUser}
              alt={siswa ? siswa.name : 'Siswa'}
              onError={(e) => (e.currentTarget.src = avatarDefaultUser)}
            />
          </div>
          <div className="col"></div>
        </div>
      </div>
      <div className="text-center">
        <div className="d-flex justify-content-center align-items-center">
          <h6 className="mb-1 h5 my-1 mr-2">{siswa ? siswa.name : ''}</h6>
        </div>
      </div>
      <hr className="wid-80 b-wid-3 my-4" />
      <div className="row text-center">
        <div className="col-6 col-md-3 mb-3">
          <i className="fas fa-book-open h1 text-muted" />
          <h6 className="my-1 h2 text-primary">
            {siswa?.kelas_selesai ? siswa.kelas_selesai : '0'}
          </h6>
          <p className="mb-0 h6 text-muted">Sub Mata Pelajaran Selesai</p>
        </div>
        <div className="col-6 col-md-3 mb-3">
          <i className="fas fa-pen-alt h1 text-muted" />
          <h6 className="my-1 h2 text-primary">
            {siswa?.tugas_kelas ? siswa.tugas_kelas : '0'}
          </h6>
          <p className="mb-0 h6 text-muted">Tugas Dikerjakan</p>
        </div>
        <div className="col-6 col-md-3 mb-3">
          <i className="fas fa-trophy h1 text-muted" />
          <h6 className="my-1 h2 text-primary">
            {siswa?.poin ? siswa.poin : '0'}
          </h6>
          <p className="mb-0 h6 text-muted">Poin</p>
        </div>
        <div className="col-6 col-md-3 mb-3">
          <i className="feather icon-award h1 text-muted" />
          <h6 className="my-1 h2 text-primary">
            {siswa?.in_level ? siswa.in_level : '0'}
          </h6>
          <p className="mb-0 h6 text-muted">Level</p>
        </div>
      </div>
    </div>
  </div>
)

export default Overview

import React from 'react'

export default function CardBodyWrapper({ children }) {
  return (
    <div className = "card-body p-0">
      <div
        className = "tab-content p-0"
        id        = "pills-tabContent"
        style     = {{ height: '100%' }}
      >
        {children}
      </div>
    </div>
  )
}

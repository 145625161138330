import React from 'react'

export default function FormSwitch({
  id,
  label,
  value,
  handleChange = (value) => {},
}) {
  if (id && label) {
    return (
      <div className="form-group row mb-5">
        <label className="col-sm-3 col-form-label">{label}</label>
        <div className="form-group col-sm-9">
          <div className="switch switch-primary d-inline m-r-10">
            <input
              onChange={(e) => handleChange(e.target.checked)}
              defaultChecked={!!value}
              type="checkbox"
              id={id}
            />
            <label
              htmlFor={id}
              className="cr"
            ></label>
          </div>
          <label htmlFor={id}>{value ? 'Aktif' : 'Tidak aktif'}</label>
        </div>
      </div>
    )
  } else {
    return (
      <div className="form-group row mb-5">
        <label className="col-sm-3 col-form-label">Form Switch</label>
        <div className="col-sm-9 d-flex">
          <p className="text-danger align-self-center m-0">
            Props cannot be empty: id, label, value
          </p>
        </div>
      </div>
    )
  }
}

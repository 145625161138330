import React, { useState, useEffect } from 'react'
import { useBaseContext } from 'context/base'
import CkeditorReadonly from 'component/ckeditor-readonly'
import {
  GraphorGroupReadonlyAnswer,
  GraphorGroupReadonlyCorrect,
} from 'component/graphor/graphor-group-readonly'
import {
  GraphorSortReadonlyAnswer,
  GraphorSortReadonlyCorrect,
} from 'component/graphor/graphor-sort-readonly'

export const GraphorDone = ({ content }) => {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [graphor, setGraphor]                     = useState(null)
  const [answers, setAnswers]                     = useState([])
  const [showAnswer, setShowAnswer]               = useState(false)
  const [showPembahasan, setShowPembahasan]       = useState(false)
  const [jawabanSiswa, setJawabanSiswa]           = useState([])
  const [realAnswer, setRealAnswer]               = useState([])
  const [isLoading, setIsLoading]                 = useState(false)
  const [loadingPembahasan, setLoadingPembahasan] = useState(false)

  /* ========================================= Functions ======================================== */
  async function handleShowAnswer() {
    if (!showAnswer) {
      setIsLoading(true)
      await getGraphor()
      await getJawabanSiswa()
      setIsLoading(false)
    }
    setShowAnswer(!showAnswer)
  }

  async function handleShowPembahasan() {
    if (!showPembahasan) {
      setLoadingPembahasan(true)
      await getGraphor()
      setLoadingPembahasan(false)
    }
    setShowPembahasan(!showPembahasan)
  }

  async function getJawabanSiswa() {
    const response = await getRequest(`siswa-graphor-hasil?content_id=${content.id}&graphor_id=${content.contents_url}`)
    if (response) {
      setJawabanSiswa(response.data)
      setRealAnswer(response.answer)
    }
  }

  async function getGraphor() {
    const response = await getRequest('graphors?id=' + content.contents_url)
    if (response) {
      setGraphor(response.data)
      if (response.data.graphor_type === 1) {
        setAnswers(response.answer)
      }
    }
  }

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      setShowAnswer(false)
      setShowPembahasan(false)
    }

    return () => {
      isSubscribed = false
    }
  }, [content.id])

  return (
    <div className="col-12">
      <div className="row">
        <div className="col">
          <div className="d-flex justify-content-center">
            <div
              className="rounded-circle p-2 my-3"
              style={{
                border: '3px solid #9ccc65',
              }}
            >
              <i className="fas fa-check text-success f-48" />
            </div>
          </div>
          <p className="text-center f-20 mb-0">Selamat!</p>
          <p className="text-center f-20">Anda telah menyelesaikan soal ini</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 offset-md-3 col-md-6 offset-lg-4 col-lg-4 mb-3">
          <button
            onClick={handleShowAnswer}
            className={`${
              isLoading ? 'btn-forbidden' : ''
            } btn btn-primary w-100`}
            disabled={isLoading}
          >
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
              />
            )}
            {showAnswer ? 'Sembunyikan Jawaban' : 'Lihat Jawaban Saya'}
          </button>
        </div>
        {jawabanSiswa.length !== 0 ? (
          <>
            {showAnswer && (
              <div className="col-12 p-3 border rounded mb-3">
                <ResultGraphor
                  dg={graphor}
                  answers={answers}
                  jawabanSiswa={jawabanSiswa}
                  realAnswer={realAnswer}
                />
              </div>
            )}
          </>
        ) : null}
        <div className="col-12 offset-md-3 col-md-6 offset-lg-4 col-lg-4 mb-3">
          <button
            onClick={handleShowPembahasan}
            className={`${
              loadingPembahasan ? 'btn-forbidden' : ''
            } btn btn-primary w-100`}
            disabled={loadingPembahasan}
          >
            {loadingPembahasan && (
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
              />
            )}
            {showPembahasan ? 'Sembunyikan Pembahasan' : 'Lihat Pembahasan'}
          </button>
        </div>
        {showPembahasan && (
          <div className="col-12 p-3 border rounded">
            {graphor.pembahasan ? (
              <CkeditorReadonly
                data={graphor.pembahasan}
                id={graphor.id}
              />
            ) : (
              <p className="text-center">-</p>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

/* ========================================= Components ========================================= */
function ResultGraphor({ dg, answers, jawabanSiswa, realAnswer }) {
  if (dg.graphor_type === 1) {
    return (
      <>
        <h5 className="text-center mb-3">Jawaban Saya</h5>
        <GraphorSortReadonlyAnswer
          list={jawabanSiswa}
          correctList={answers}
        />
        <br />
        <h5 className="text-center my-3">Jawaban Benar</h5>
        <GraphorSortReadonlyCorrect correctList={answers} />
      </>
    )
  } else if (dg.graphor_type === 2) {
    return (
      <>
        <h5 className="text-center mb-3">Jawaban Saya</h5>
        <GraphorGroupReadonlyAnswer
          groups={jawabanSiswa}
          showList={false}
        />
        <h5 className="text-center mt-4 mb-3">Jawaban Benar</h5>
        <GraphorGroupReadonlyCorrect
          groups={realAnswer}
          showList={false}
        />
      </>
    )
  }
}

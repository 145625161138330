import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { host } from "util/host";
import { noImage } from "util/constant";
import LoadingSpinner from 'component/loading'

export default function CardMeta({ url }) {
  let isMounted = useRef(true);
  const [metadata, setMetadata] = useState(null);
  const [loadingMeta, setLoadingMeta] = useState(false);

  async function getMetadata(url) {
    try {
      setLoadingMeta(true);
      const res = await axios({
        method: "GET",
        url: `${host}url-metadata?url=${url}`,
      });
      setLoadingMeta(false);
      if (isMounted.current === true) setMetadata(res.data.data);
    } catch(error) {
      setLoadingMeta(false);
      console.error("Error get metadata: ", { error });
      if (isMounted.current === true) setMetadata(null);
    }
  }

  useEffect(() => {
    if (isMounted.current === true) {
      if (url) {
        getMetadata(url);
      }
    }

    return () => {
      setMetadata(null);
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      {metadata ? (
        <a href={metadata?.url} target="_blank" className="client-map">
          <div
            className="card-body border rounded"
            style={{ backgroundColor: "#fff", transition: "0.2" }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = "#f8f9fa")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = "#fff")
            }
          >
            <div className="row">
              <div className="col-12 col-md-3 mb-3 mb-md-0">
                <img
                  className="rounded"
                  src={metadata?.image ? metadata.image : noImage}
                  alt="Image Assignment"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              <div className="col-12 col-md-9 d-flex flex-column justify-content-center align-items-start">
                <h5>{metadata?.title}</h5>
                <p className="text-muted m-0 p-t-10">{metadata?.description}</p>
              </div>
            </div>
          </div>
        </a>
      ) : (
        <div className="client-map">
          <div className="card-body border rounded">
            <div className="row">
              <div className="col-12 col-md-3 mb-3 mb-md-0">
                <img
                  className="rounded bg-light"
                  src={noImage}
                  alt="Image Assignment"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              {loadingMeta ? (
                <div className="col-12 col-md-9 d-flex flex-column justify-content-center align-items-center">
                  <LoadingSpinner />
                </div>
              ) : (
                <div className="col-12 col-md-9 d-flex flex-column justify-content-center align-items-center">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => getMetadata(url)}
                  >
                    <i className="fas fa-redo-alt mr-1" />
                    Muat ulang
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import UserItem from './user-item'
import ModalReject from './modal-reject'
import TableIntervalPredikat from 'component/rapor/table-interval-predikat'

export default function RaporSuccess({
  result,
  classId,
  category_id,
  group_id,
  semester_id,
  semester,
  subMapel,
  handleSearch = async (classId, group_id, semester_id) => {},
}) {
  /* ========================================== Helpers ========================================= */
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()

  /* ====================================== Consume Context ===================================== */
  const { putRequest } = useBaseContext()

  /* ========================================= Reducers ========================================= */
  const countSubmission = result?.reduce(
    (acc, cur) => (cur.status === 'submission' ? ++acc : acc),
    0,
  )

  /* ========================================= Constants ======================================== */
  const reportGroupId = {
    keterampilan: 'cc60064e-0b84-4c2d-8afd-4bd9f703694a',
    pengetahuan: '5ebcf01d-9217-4578-a5c6-17f84b28c9ed',
  }

  const modalConfig = {
    pengetahuan: {
      id: 'modalRejectPengetahuan',
      title: '',
    },
    keterampilan: {
      id: 'modalRejectKeterampilan',
      title: 'Keterampilan',
    },
  }

  /* ======================================= Local States ======================================= */
  const [listChecklistPengetahuans, setListChecklistPengetahuans]         = useState([])
  const [checklistAllPengetahuan, setChecklistAllPengetahuan]             = useState(false)
  const [listChecklistDataReject, setListChecklistDataReject]             = useState([])
  const [loadingModalRejectPengetahuan, setLoadingModalRejectPengetahuan] = useState('idle')  // idle / start / success / error
  const [listRejectPengetahuans, setListRejectPengetahuans]               = useState([])

  /* ========================================= Functions ======================================== */
  const calculatePredikat = (score) => {
    score = parseInt(score)
    if (score >= 90) {
      return 'A'
    } else if (score >= 80 && score <= 89) {
      return 'B'
    } else if (score >= 70 && score <= 79) {
      return 'C'
    } else {
      return 'D'
    }
  }

  const handleChecklistAll = ({
    event,
    item = [],
    reportGroupId,
    categoryId,
    setListChecklist,
    setChecklistAll,
  }) => {
    const selected = item.map((items) => ({
      class_id: items.class_id,
      user_id: items.user_id,
      ta_semester_code: items.ta_semester_code,
      ta_tahun_code: items.ta_tahun_code,
      period_id: query.get('period_id'),
    }))
    const newListData = item.map((items) => ({
      idUser: item.user_id,
      name: items.user.name,
      score: items.score_final,
      predicate: calculatePredikat(items.score_final),
      catatan: items.catatan,
      status: items.status,
    }))
    setListChecklist(event.target.checked ? selected : [])
    setListChecklistDataReject(event.target.checked ? newListData : [])
    setChecklistAll(event.target.checked)
  }

  const handleChecklist = ({
    event,
    item,
    taSemesterCode,
    currentListChecklist = [],
    setListChecklist,
    setChecklistAll,
  }) => {
    const checked = event.target.checked
    const list = currentListChecklist
    const listDataReject = listChecklistDataReject
    if (checked) {
      const newList = [
        ...list,
        {
          class_id: item.class_id,
          user_id: item.user_id,
          ta_semester_code: item.ta_semester_code,
          ta_tahun_code: item.ta_tahun_code,
          period_id: query.get('period_id'),
        },
      ]
      const newListData = [
        ...listDataReject,
        {
          idUser: item.user_id,
          name: item.user.name,
          score: item.score_final,
          predicate: calculatePredikat(item.score_final),
          catatan: item.catatan,
          status: item.status,
          period_id: query.get('period_id'),
        },
      ]
      setListChecklist(newList)
      setListChecklistDataReject(newListData)
      if (newList.length === result.length) setChecklistAll(true)
    } else {
      const newList = list.filter(
        (listItem) => listItem.user_id !== item.user_id,
      )
      setListChecklist(newList)
      setChecklistAll(false)
    }
  }

  const handleApprove = (listApprove = []) => {
    Swal.fire({
      title: 'Setujui nilai?',
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Memproses nilai...',
          html: `<div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>`,
          allowOutsideClick: false,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
        })

        let form = new FormData()
        form.append('report_temps', JSON.stringify(listApprove))
        const response = await putRequest('approve-report-block', form)
        if (response) {
          Swal.fire({
            title: 'Nilai berhasil disetujui',
            icon: 'success',
          })
        } else {
          Swal.close()
        }
        await handleSearch(classId, group_id, semester_id)
      }
    })
  }

  const initModalReject = (
    listSelect,
    setListReject,
    setLoadingModalReject,
  ) => {
    setLoadingModalReject('start')
    setListReject(listSelect.map((item) => ({ ...item, catatan: '' })))
  }

  const closeModalReject = (setListReject, setLoadingModalReject) => {
    setLoadingModalReject('idle')
    setListReject([])
  }

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="row">
        <div className="col">
          <h2 className="f-24">{subMapel}</h2>
          <div className="text-secondary">
            <span>
              <i className="fas fa-graduation-cap mr-1" />
              {semester}
            </span>
            <span className="ml-3">
              <i className="fas fa-user-graduate mr-1" />
              {result?.length}
              {' siswa'}
            </span>
            {result?.group_title ? (
              <span className="ml-3">
                <i className="fas fa-user-friends mr-1" />
                Kelas: {result.group_title}
              </span>
            ) : null}
          </div>
          <hr />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h5>A. Tabel Interval Predikat</h5>
          <TableIntervalPredikat />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          {listChecklistPengetahuans.length > 0 ? (
            <div className="w-100">
              <button
                className="btn btn-sm btn-primary mr-2"
                onClick={() => handleApprove(listChecklistPengetahuans)}
              >
                <i className="fas fa-check-circle mr-1" />
                Setuju
              </button>
              <button
                className="btn btn-sm btn-outline-danger"
                data-toggle="modal"
                data-target={`#${modalConfig.pengetahuan.id}`}
                onClick={() =>
                  initModalReject(
                    listChecklistPengetahuans,
                    setListRejectPengetahuans,
                    setLoadingModalRejectPengetahuan,
                  )
                }
              >
                <i className="fas fa-times-circle mr-1" />
                Tolak
              </button>
              <ModalReject
                id={modalConfig.pengetahuan.id}
                title={modalConfig.pengetahuan.title}
                listSelected={listChecklistPengetahuans}
                listRejects={listRejectPengetahuans}
                setListRejects={setListRejectPengetahuans}
                loadingModal={loadingModalRejectPengetahuan}
                setLoadingModalReject={setLoadingModalRejectPengetahuan}
                onClose={closeModalReject}
                listData={listChecklistDataReject}
                category_id={category_id}
                group_id={group_id}
                semester_id={semester_id}
                handleSearch={handleSearch}
                report_group_id={reportGroupId.pengetahuan}
              />
            </div>
          ) : (
            <div className="w-100">
              <button
                className="btn btn-sm btn-primary mr-2"
                disabled={true}
              >
                <i className="fas fa-check-circle mr-1" />
                Setuju
              </button>
              <button
                className="btn btn-sm btn-outline-danger"
                disabled={true}
              >
                <i className="fas fa-times-circle mr-1" />
                Tolak
              </button>
            </div>
          )}
          <div className="table-responsive rounded mt-2">
            <table className="table table-bordered nowrap table-rekap-nilai table-fixed">
              <thead>
                <tr>
                  <th>
                    {result?.length > 0 && countSubmission > 0 ? (
                      <input
                        type="checkbox"
                        className="form-control"
                        style={{ width: 16, height: 16, cursor: 'pointer' }}
                        checked={checklistAllPengetahuan}
                        onChange={(event) => {
                          handleChecklistAll({
                            event: event,
                            item: result,
                            setListChecklist: setListChecklistPengetahuans,
                            setChecklistAll: setChecklistAllPengetahuan,
                          })
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        className="form-control"
                        style={{ width: 16, height: 16 }}
                        disabled={true}
                      />
                    )}
                  </th>
                  <th colSpan={0}>No.</th>
                  <th>Nim</th>
                  <th>Nama</th>
                  <th>Status</th>
                  <th>Nilai Keterampilan</th>
                  <th>Predikat</th>
                  <th>Nilai Pengetahuan</th>
                  <th>Predikat</th>
                </tr>
              </thead>
              <tbody>
                {result?.length > 0 ? (
                  <>
                    {result.map(
                      (user, index) =>
                        user.transcript_scores_periods && (
                          <UserItem
                            key={`${index}-${user.transcript_scores_periods[0]?.user_id}-${user.transcript_scores_periods[0]?.categories_id}-pengetahuan`}
                            number={index + 1}
                            item={user.transcript_scores_periods[0]}
                            user={user}
                            nilai={
                              user.transcript_scores_periods[0]?.score_final
                            }
                            nilaiK={
                              user.transcript_scores_periods[0]
                                ?.nilai_keterampilan
                            }
                            nilaiP={
                              user.transcript_scores_periods[0]
                                ?.nilai_pengetahuan
                            }
                            categoryId={
                              user.transcript_scores_periods[0]?.categories_id
                            }
                            semester_id={semester_id}
                            reportGroupId={reportGroupId.pengetahuan}
                            handleChecklist={handleChecklist}
                            currentListChecklist={listChecklistPengetahuans}
                            setListChecklist={setListChecklistPengetahuans}
                            setChecklistAll={setChecklistAllPengetahuan}
                          />
                        ),
                    )}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      className="text-center"
                    >
                      Tidak ada siswa terdaftar di mata pelajaran
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

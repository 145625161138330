import React, { useState } from 'react'
import InvalidWarning from 'component/invalid/warning'
import { avatarDefaultUser } from 'util/constant'
import useFeedbackForm from 'context/feedback-form'

export default function EditFeedPG({ sharedProps }) {
  /* =================================== Consume Shared Props =================================== */
  const {
    isFeedResponIdEmpty,
    isLoading,
    formValidation,
    handleChangeFeedResponId,
  } = sharedProps

  /* ====================================== Consume Context ===================================== */
  const { feed, event } = useFeedbackForm()

  /* ========================================= Constants ======================================== */
  const feedResponses = feed.responses
  const feedTemp = feed.temps[0] || { feed_respon_id: null }
  const { name, linkAvatar } = JSON.parse(localStorage.getItem('user'))
  const fallbackAvatar = avatarDefaultUser

  /* ========================================== States ========================================== */
  const [isEdit, setIsEdit] = useState(false)

  /* ========================================= Functions ======================================== */
  function CurrentFeedTemp() {
    if (feedResponses.length !== 0) {
      let feedResponText = ''
      feedResponses.forEach((feedRespon) => {
        if (feedRespon.id === feedTemp.feed_respon_id) {
          feedResponText = feedRespon.text
        }
      })
      return <span>{feedResponText}</span>
    } else {
      return <span>""</span>
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <>
      <label>{feed.data ? feed.data.description : ''}</label>
      {isEdit ? (
        <>
          <div className="form-group mb-4">
            {feedResponses.length &&
              feedResponses.map((feedResponse) => (
                <div
                  key={feedResponse.id}
                  className="custom-control custom-radio mb-2"
                >
                  <input
                    type="radio"
                    id={feedResponse.id}
                    name="pg-feedback"
                    className="custom-control-input"
                    value={feedResponse.id}
                    onChange={(event) =>
                      handleChangeFeedResponId(event.target.value)
                    }
                    defaultChecked={
                      feedTemp.feed_respon_id === feedResponse.id
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={feedResponse.id}
                  >
                    {feedResponse.text}
                  </label>
                </div>
              ))}
            {isFeedResponIdEmpty && (
              <InvalidWarning text="Feedback tidak boleh kosong" />
            )}
          </div>
          <div className="form-group row mb-0">
            <div className="col-12 col-md-2 pr-md-0 mb-2 mb-md-0">
              {isLoading ? (
                <button
                  type="button"
                  className="btn btn-sm btn-primary w-100"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  />
                  <span>Ubah</span>
                </button>
              ) : (
                <button
                  onClick={formValidation}
                  type="button"
                  className="btn btn-sm btn-primary w-100"
                >
                  <i className="fas fa-paper-plane mr-1" />
                  <span>Ubah</span>
                </button>
              )}
            </div>
            <div className="col-12 col-md-2">
              <button
                type="button"
                className="btn btn-sm btn-danger w-100"
                onClick={() => {
                  setIsEdit(false)
                  event.set('submit-feed')
                }}
              >
                <span>Batal</span>
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="media mt-2 mb-0">
          <img
            src={`${linkAvatar}`}
            alt={name}
            className="img-radius wid-30 align-top m-r-15"
            onError={(event) => (event.currentTarget.src = fallbackAvatar)}
          />
          <div className="media-body">
            <h6 className="mb-0 text-dark">{name}</h6>
            <div className="m-b-0 mt-2">
              {feedResponses.length !== 0
                ? feedResponses.map((feedResponse) => {
                    return (
                      <div
                        key={feedResponse.id}
                        className="custom-control custom-radio mb-2"
                      >
                        <input
                          type="radio"
                          name="pg-feedback"
                          className="custom-control-input"
                          checked={
                            feedTemp.feed_respon_id === feedResponse.id
                              ? true
                              : false
                          }
                          disabled
                        />
                        <label className="custom-control-label">
                          {feedResponse.text}
                        </label>
                      </div>
                    )
                  })
                : null}
              <a
                onClick={() => setIsEdit(true)}
                type="button"
                className="text-muted text-h-primary"
              >
                <small>
                  <i className="feather icon-edit mx-1" />
                  Ubah
                </small>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

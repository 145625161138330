export function detectionDoc(type, url) {
  return type === 'pdf'
    ? `https://docs.google.com/viewerng/viewer?url=${url}&embedded=true`
    : `https://view.officeapps.live.com/op/view.aspx?src=${url}`
}

export function getMediaDuration(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const media = new Audio(reader.result)
      media.onloadedmetadata = () => resolve(media.duration)
    }
    reader.readAsDataURL(file)
    reader.onerror = (error) => reject(error)
  })
}

export function mapOrder(array, order, key, id) {
  array.sort((a, b) => (order.indexOf(a[key]) > order.indexOf(b[id]) ? 1 : -1))
  return array
}

export function appendScript(scriptToAppend) {
  const script = document.createElement('script')
  script.src = scriptToAppend
  script.async = true
  document.body.appendChild(script)
}

export function appendScriptReturnDoc(scriptToAppend, id) {
  const element = document.getElementById(id);

  if (!element || element == null) {
    const script = document.createElement('script')
    script.src = scriptToAppend
    script.id = id
    document.body.appendChild(script)
  }
}


export function removeScript(id) {
  const element = document.getElementById(id);

  if (id || id !== null) {
    element?.remove();
  }
}

export function blockedNotifPopUp() {
  const container = document.createElement('div')
  container.innerHTML = `
    <i class="fas fa-info-circle text-warning f-64 my-2"></i>
    <p class="f-14 mb-0">Notifikasi diblokir. Izinkan notifikasi di pengaturan browser Anda.</p>
  `
  window.swal({
    content: container,
    closeOnClickOutside: false,
    closeOnEsc: false,
    closeModal: true,
  })
}

export function getTodayDate(miliseconds = false) {
  return miliseconds ? new Date().getTime() : new Date()
}

export function popUpWindow(url, windowParent, width, height) {
  if (url && window && width && height) {
    const y = windowParent.top.outerHeight / 2 + windowParent.top.screenY - height / 2
    const x = windowParent.top.outerWidth / 2 + windowParent.top.screenX - width / 2
    return windowParent.open(url, '', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`)
  } else {
    return null
  }
}

export function openUrlNewTab(url) {
  if (typeof url === 'string' && url) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: url,
    }).click()
  } else {
    return null
  }
}

export function timeConvert(time) {
  // Hours, minutes and seconds
  var hrs = ~~(time / 3600)
  var mins = ~~((time % 3600) / 60)
  var secs = ~~time % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = ''
  if (hrs > 0) {
    ret += `${hrs} : ${mins < 10 ? '0' : ''}`
  }
  ret += `${mins} : ${secs < 10 ? '0' : ''}`
  ret += `${secs}`
  return ret
}

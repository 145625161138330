/*
(Yadi - Team MSI)
This version:
(26-03-24):
- replace with table frontend no more from backend again
*/

import React, { useState, useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { useBaseContext } from 'context/base'

export default function DetailNilai() {
  /* ========================================== Helpers ========================================= */
  const { userId, semester } = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [kindness, setKindness] = useState([])

  /* ========================================= Functions ======================================== */
  async function loadData() {
    let response = await getRequest(
      `report-detail-new?user_id=${userId}&ta_semester_code=${semester}`,
    )
    if (response) {
      setKindness(response.data)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    loadData()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div className="table-responsive mb-5">
      <table className="table table-bordered w-100">
        <thead>
          <tr>
            <th>No</th>
            <th>Mata Pelajaran</th>
            <th>Nilai Akhir</th>
            <th>Capaian Kompetensi</th>
          </tr>
        </thead>
        <tbody>
          {kindness.map((item, index) => {
            const parseValue = JSON.parse(item?.value)

            return (
              <Fragment key={index}>
                {item?.is_group ? (
                  <tr>
                    <td rowSpan={item?.children.length + 1} style={{ width: 20 }}>
                      {index + 1}
                    </td>
                    <td colSpan={3} style={{ width: 200, fontWeight: 700 }}>
                      {item?.name}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td style={{ width: 20 }}>{index + 1}</td>
                    <td style={{ backgroundColor: item?.background_color, width: 200 }}>{item?.name}</td>
                    <td style={{ backgroundColor: item?.background_color, width: 50 }}>{parseValue?.nilai}</td>
                    <td style={{ backgroundColor: item?.background_color, width: 250, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                      {parseValue?.deskripsi}
                    </td>
                  </tr>
                )}
                {item?.children.length > 0 &&
                  item?.children.map((item2, childIndex) => {
                    const parseValue = JSON.parse(item2?.value)

                    return (
                      <tr key={`${index}-${childIndex}`} style={{ backgroundColor: item2?.background_color }}>
                        <td style={{ width: 200 }}>{item2.name}</td>
                        <td style={{ width: 50 }}>{parseValue?.nilai}</td>
                        <td style={{ width: 250, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                          {parseValue?.deskripsi}
                        </td>
                      </tr>
                    )
                  })}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

import React, { useState, useRef, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Select from 'react-select'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { Back, Submit } from 'component/button'
import MediaNotice from 'pages/quiz/media-notice'
import { editorConfiguration } from 'util/ckeditor-config'
import { popUpWindow } from 'util/global'

const selectStyles = {
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
}

export default function FormAddQuestionGraphor() {
  /* ========================================== Helpers ========================================= */
  const history    = useHistory()
  const { quizId } = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const questType = 2
  const links = [
    { url: '/kelola-quiz', name: 'Kelola QUiz' },
    { url: `/kelola-quiz/${quizId}`, name: 'Daftar Pertanyaan ' },
  ]
  
  /* ======================================= Local States ======================================= */
  const [graphors, setGraphors]             = useState([])
  const [textPertanyaan, setTextPertanyaan] = useState('')
  const [nomorUrut, setNomorUrut]           = useState('')
  const [status, setStatus]                 = useState(0)
  const [jawaban, setJawaban]               = useState('')
  const [pembahasan, setPembahasan]         = useState('')
  const [bobotNilai, setBobotNilai]         = useState('')
  const [value, setValue]                   = useState(null)

  // FORM VALIDATION STATE
  const [isUrutanInvalid, setIsUrutanInvalid]                 = useState(false)
  const [isTextPertanyaanInvalid, setIsTextPertanyaanInvalid] = useState(false)
  const [isJawabanInvalid, setIsJawabanInvalid]               = useState(false)
  const [isBobotNilaiInvalid, setIsBobotNilaiInvalid]         = useState(false)
  const [isGraphorInValid, setIsGraphorInvalid]               = useState(false)

  /* =========================================== Refs =========================================== */
  const refNomorUrut  = useRef()
  const refJawaban    = useRef()
  const refBobotNilai = useRef()
  const refGraphor    = useRef()

  /* ========================================= Functions ======================================== */
  async function getGraphors() {
    const response = await getRequest('graphors?status=active')
    if (response) {
      if (response.data.length !== 0) {
        initGroupedOptions(response.data)
      }
    }
  }

  function initGroupedOptions(graphors) {
    let sorting = []
    let grouping = []

    graphors.forEach((graphor) => {
      if (graphor.type === 'sorting') {
        sorting.push({ value: graphor.id, label: graphor.description })
      }
      if (graphor.type === 'group') {
        grouping.push({ value: graphor.id, label: graphor.description })
      }
    })

    setGraphors([
      { label: 'Sorting', options: sorting },
      { label: 'Grouping', options: grouping },
    ])
  }

  function handleChangeUrutan(event) {
    if (event.target.value) {
      const value = Math.floor(Math.abs(event.target.value))
      setNomorUrut(value)
      setIsUrutanInvalid(false)
    } else {
      setNomorUrut('')
    }
  }

  function handleChangeGraphor(value) {
    if (value) {
      setValue(value)
      setIsGraphorInvalid(false)
    } else {
      setValue(null)
    }
  }

  function handleChangeStatus(event) {
    switch (event.target.checked) {
      case true:
        setStatus(1)
        break
      case false:
        setStatus(0)
        break
      default:
        break
    }
  }

  function handleChangeJawaban(event) {
    setJawaban(event.target.value)
  }

  function handleChangeBobotNilai(event) {
    if (event.target.value) {
      if (event.target.value <= 100) {
        const num = Math.abs(event.target.value)
        setBobotNilai(num)
        setIsBobotNilaiInvalid(false)
      }
    } else {
      setBobotNilai('')
    }
  }

  function handleFormValidation() {
    if (nomorUrut && textPertanyaan && bobotNilai && value) {
      const arrayJawaban = [
        { text: textPertanyaan, correct: 1, active: 1, nilai: bobotNilai },
      ]
      setIsUrutanInvalid(false)
      setIsTextPertanyaanInvalid(false)
      setIsJawabanInvalid(false)
      setIsGraphorInvalid(false)
      handleFormSubmit(
        quizId,
        nomorUrut,
        textPertanyaan,
        questType,
        status,
        arrayJawaban,
        pembahasan,
        value.value,
      )
    } else {
      if (!bobotNilai) {
        setIsBobotNilaiInvalid(true)
        refBobotNilai.current.focus()
      } else {
        setIsJawabanInvalid(false)
      }

      if (!textPertanyaan) {
        setIsTextPertanyaanInvalid(true)
      } else {
        setIsTextPertanyaanInvalid(false)
      }

      if (!value) {
        setIsGraphorInvalid(true)
        refGraphor.current.focus()
      } else {
        setIsGraphorInvalid(false)
      }

      if (!nomorUrut) {
        setIsUrutanInvalid(true)
        refNomorUrut.current.focus()
      } else {
        setIsUrutanInvalid(false)
      }

      window.notification('Data belum lengkap', 'Mohon lengkapi data')
    }
  }

  async function handleFormSubmit(
    quiz_id,
    urutan,
    text,
    quest_type,
    active,
    jawaban,
    pembahasan,
    valueId,
  ) {
    let form = new FormData()
    form.append('quiz_id', quiz_id)
    form.append('urutan', urutan)
    form.append('text', text)
    form.append('quest_type', quest_type)
    form.append('active', active)
    form.append('jawaban', JSON.stringify(jawaban))
    form.append('value_id', valueId)
    if (pembahasan) {
      form.append('pembahasan', pembahasan)
    }

    const response = await postRequest('questions', form)
    if (response) {
      window.notification(
        'Sukses',
        'Pertanyaan berhasil ditambahkan',
        'success',
      )
      history.goBack()
    } else {
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getGraphors()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Tambah Pertanyaan"
          linkName="Tambah Soal"
          links={links}
        />

        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <h5 className="modal-title">Informasi Soal</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <form>
                      {/* Input urutan pertanyaan */}
                      <div className="form-group mb-3 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="urutan-uraian"
                        >
                          <span>Nomor Soal</span>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <div className="col-sm-2">
                          <input
                            type="number"
                            min={0}
                            value={nomorUrut}
                            onChange={(event) => handleChangeUrutan(event)}
                            ref={refNomorUrut}
                            className={`${
                              isUrutanInvalid ? 'is-invalid' : ''
                            } form-control`}
                            id="urutan-uraian"
                            placeholder="Misal: 1"
                          />
                        </div>
                        {isUrutanInvalid ? (
                          <div className="offset-sm-3 col-sm-9">
                            <span className="col small form-text text-danger">
                              Nomor urut tidak boleh kosong.
                            </span>
                          </div>
                        ) : null}
                      </div>

                      {/* Select graphor */}
                      <div className="form-group mb-4 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="status-uraian"
                        >
                          <span className="mr-1">
                            Graphic Organizer
                            <span className="text-danger ml-1">*</span>
                          </span>
                        </label>
                        <div className="col-sm-4 col-xl-5">
                          <Select
                            ref={refGraphor}
                            isMulti={false}
                            isSearchable={true}
                            styles={selectStyles}
                            placeholder="Pilih graphic organizer"
                            noOptionsMessage={() =>
                              'Data graphor tidak ditemukan'
                            }
                            isClearable={true}
                            value={value}
                            options={graphors}
                            onMenuOpen={getGraphors}
                            onChange={handleChangeGraphor}
                          />
                        </div>
                        <div className="col-sm-4 col-xl-3 mt-2 mt-sm-1">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={() =>
                              popUpWindow(
                                '/graphic-organizer',
                                window,
                                960,
                                600,
                              )
                            }
                          >
                            <i className="feather icon-settings mr-1" />
                            <span>Kelola Graphic Organizer</span>
                          </button>
                        </div>
                        {isGraphorInValid ? (
                          <div className="offset-sm-3 col-sm-9">
                            <span className="col small form-text text-danger">
                              Harap pilih graphic organizer.
                            </span>
                          </div>
                        ) : null}
                      </div>

                      {/* Pertanyaan uraian*/}
                      <div className="form-group row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Pertanyaan/instruksi
                          <span className="text-danger ml-1">*</span>
                        </label>

                        {/* input pertanyaan */}
                        <div className="col-sm-9">
                          <MediaNotice />
                          <CKEditor
                            editor={Editor}
                            data={textPertanyaan}
                            config={{...editorConfiguration, placeholder: 'Tulis pertanyaan/instruksi...'}}
                            onChange={(_, editor) => {
                              const data = editor.getData()
                              if (data) {
                                setIsTextPertanyaanInvalid(false)
                              }
                              setTextPertanyaan(data)
                            }}
                          />

                          {isTextPertanyaanInvalid ? (
                            <span className="col small form-text text-danger">
                              Pertanyaan/instruksi tidak boleh kosong.
                            </span>
                          ) : null}
                        </div>
                      </div>

                      {/* Input Pembahasan */}
                      <div className="form-group row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Pembahasan
                        </label>
                        <div className="col-sm-9">
                          <CKEditor
                            editor={Editor}
                            data={pembahasan}
                            config={{...editorConfiguration, placeholder: 'Tulis pembahasan...'}}
                            onChange={(_, editor) => {
                              const data = editor.getData()
                              setPembahasan(data)
                            }}
                          />
                        </div>
                      </div>

                      {/* Bobot nilai soal */}
                      <div className="form-group mb-5 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="bobotNilai"
                        >
                          <span>Bobot Soal</span>
                          <span className="text-danger ml-1">*</span>
                          <button
                            type="button"
                            className="btn p-0 ml-2"
                            data-toggle="popover"
                            data-placement="top"
                            data-content="Bobot nilai per-soal yang akan diakumulasi dengan seluruh soal"
                            data-original-title=""
                          >
                            <i className="fas fa-question-circle" />
                          </button>
                        </label>
                        <div className="col-sm-9">
                          <input
                            value={bobotNilai}
                            onChange={(event) => handleChangeBobotNilai(event)}
                            ref={refBobotNilai}
                            type="number"
                            min={0}
                            max={100}
                            className={`${
                              isBobotNilaiInvalid ? 'is-invalid' : ''
                            } form-control`}
                            style={{
                              width: '150px',
                            }}
                            placeholder="Bobot penilaian"
                            id="bobotNilai"
                          />
                          {isBobotNilaiInvalid ? (
                            <span className="col small form-text text-danger">
                              Bobot nilai tidak boleh kosong.
                            </span>
                          ) : null}
                        </div>
                      </div>

                      {/* Select status pertanyaan */}
                      <div className="form-group mb-3 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="status-uraian"
                        >
                          <span className="mr-1">Status</span>
                        </label>
                        <div className="form-group col-sm-9">
                          <div className="switch switch-primary d-inline m-r-10">
                            <input
                              onChange={(event) => handleChangeStatus(event)}
                              checked={status === 1}
                              type="checkbox"
                              id="switch-status-uraian"
                            />
                            <label
                              htmlFor="switch-status-uraian"
                              className="cr"
                            ></label>
                          </div>
                          <label htmlFor="switch-status-uraian">
                            {status === 1 ? 'Aktif' : 'Tidak Aktif'}
                          </label>
                        </div>
                      </div>

                      {/* Form keterangan */}
                      <div className="form-group d-flex">
                        <label className="text-muted mr-2">Keterangan:</label>
                        <span className="text-danger mr-1">*</span>
                        <label className="text-muted">Wajib diisi</label>
                      </div>

                      {/* Form button */}
                      <div className="form-group">
                        <Back />
                        <Submit
                          onClick={handleFormValidation}
                          text="Simpan"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React from 'react'

export default function ContentIcon({ id }) {
  switch (id) {
    case 1:
      return (
          <i className="feather icon-file-text mr-1 f-10" />
      )
    case 2:
      return (
          <i className="feather icon-volume-1 mr-1 f-10" />
      )
    case 3:
      return (
          <i className="feather icon-play-circle mr-1 f-10" />
      )
    case 4:
      return (
          <i className="fas fa-file mr-1 f-10" />
      )
    case 5:
      return (
          <i className="fas fa-code mr-1 f-10" />
      )
    case 6:
      return (
          <i className="fas fa-question-circle mr-1 f-10" />
      )
    case 7:
      return (
          <i className="fas fa-check-double mr-1 f-10" />
      )
    case 8:
      return (
          <i className="fas fa-pen-alt mr-1 f-10" />
      )
    case 9:
      return (
          <i className="fas fa-object-group mr-1 f-10" />
      )
    case 10:
      return (
          <i className="fas fa-chalkboard mr-1 f-10" />
      )
    default:
      return null
  }
}

import React from 'react'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import ModalRejectItem from './modal-reject-item'

export default function ModalReject({
  id,
  title = '',
  loadingModal,
  setLoadingModalReject,
  listSelected = [],
  listRejects = [],
  setListRejects,
  listData,
  context,
  category_id,
  group_id,
  semester_id,
  report_group_id,
  handleSearch = async (category_id, group_id) => {},
  onClose = (setListReject, setLoadingModalReject) => {},
}) {
  /* ====================================== Consume Context ===================================== */
  const { putRequest } = useBaseContext()

  /* ========================================= Functions ======================================== */
  const handleReject = async () => {
    Swal.fire({
      title: 'Tolak nilai?',
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Memproses nilai...',
          html: `<div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>`,
          allowOutsideClick: false,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
        })
        let form = new FormData()
        form.append('report_temps', JSON.stringify(listRejects))
        const response = await putRequest('reject-report-block', form)
        if (response) {
          window.$(`#${id}`).modal('hide')
          Swal.close()
          await handleSearch(category_id, group_id, semester_id)
        }
      }
    })
  }

  /* ========================================== Output ========================================== */
  return (
    <div
      className="modal fade"
      id={id}
      data-backdrop="static"
      data-keyboard="false"
      tabIndex={-1}
      role="dialog"
      aria-labelledby={id}
      aria-hidden="true"
    >
      <div className={`modal-xl modal-dialog modal-dialog-centered`}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="close text-danger"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => onClose(setListRejects, setLoadingModalReject)}
            >
              <i className="feather icon-x" />
            </button>
          </div>
          <div className="modal-body">
            <div className="table-responsive rounded mt-2">
              <table className="table nowrap">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Nama</th>
                    <th>Nilai</th>
                    <th>Predikat</th>
                    <th>Catatan</th>
                  </tr>
                </thead>
                <tbody>
                  {loadingModal === 'start' && (
                    <>
                      {listData
                        .filter(
                          (el) =>
                            el.status === 'approved' ||
                            el.status === 'submission',
                        )
                        .map((selected, index) => (
                          <>
                            <ModalRejectItem
                              key={`${index}-${selected.user_id}-${report_group_id}`}
                              name={selected.name}
                              predicate={selected.predicate}
                              score={selected.score}
                              note={selected.catatan}
                              number={index + 1}
                              userId={selected.idUser}
                              listRejects={listRejects}
                              setListRejects={setListRejects}
                            />
                          </>
                        ))}
                    </>
                  )}
                  {loadingModal === 'idle' && (
                    <tr>
                      <td
                        colSpan={7}
                        className="text-center"
                      >
                        Tidak ada siswa terdaftar di mata pelajaran
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer p-2">
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleReject}
            >
              Tolak
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => onClose(setListRejects, setLoadingModalReject)}
            >
              Batal
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

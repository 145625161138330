import React, { useState, useEffect, useMemo } from 'react'
import { useBaseContext } from 'context/base'
import { useDropzone } from 'react-dropzone'
import {
  baseStyle,
  activeStyle,
  acceptStyle,
  rejectStyle,
  thumbsContainer,
  thumb,
  thumbInner,
  img,
} from './dropzoneCss'
import { getBase64 } from 'util/base64'

export default function DetailProfile({
  data,
  getProfile,
  roleType,
  defaultSignature,
}) {
  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const id = data.id

  /* ======================================= Local States ======================================= */
  const [name, setName]                         = useState(data.name)
  const [gender, setGender]                     = useState(data.gender)
  const [birthdate, setBirthdate]               = useState(data.birthdate)
  const [location, setLocation]                 = useState(data.location)
  const [email, setEmail]                       = useState(data.email)
  const [phone, setPhone]                       = useState(data.phone)
  const [previewSignature, setPreviewSignature] = useState(data?.signature ? data?.linkSignature : null)
  const [previewError, setPreviewError]         = useState(false)
  const [imageSignature, setImageSignature]     = useState(null)
  const [isImageTooLarge, setImageTooLarge]     = useState(false)
  const [roles, setRoles]                       = useState('')
  const [loading, setLoading]                   = useState(false)

  /* ========================================= Functions ======================================== */
  function handleFormPhoneNumber(val) {
    var x = val.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/)
    setPhone(!x[2] ? x[1] : `${x[1]}-${x[2]}` + (x[3] ? '-' + x[3] : ''))
  }

  async function handleUpdate() {
    setLoading(true)

    var form = new FormData()
    form.append('id', id)
    form.append('name', name)
    form.append('gender', gender)
    form.append('birthdate', birthdate)
    form.append('location', location)
    form.append('email', email)
    form.append('phone', phone)
    if (imageSignature) {
      form.append('signature', JSON.stringify(imageSignature))
    }

    let response = await postRequest('profile-update', form)
    if (response) {
      setLoading(false)
      document.getElementById('editButton').click()
      window.notification(
        'Berhasil',
        'Detail informasi berhasil diubah',
        'success',
      )
      getProfile()
    } else {
      setLoading(false)
      window.notification(
        'Terjadi kesalahan',
        'Mohon coba beberapa saat lagi',
        'error',
      )
      console.error('ERROR', response)
    }
  }

  function Dropzone() {
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      accept: 'image/png',
      maxSize: 512000,
      multiple: false,
      onDrop: (acceptedFiles, fileRejections) => {
        if (acceptedFiles.length > 0) {
          getBase64(acceptedFiles[0])
            .then((result) => {
              setImageTooLarge(false)
              let avatarClass = result.split(',')
              setPreviewSignature(`data:image/jpeg;base64,${avatarClass[1]}`)
              setImageSignature({
                name: acceptedFiles[0].name,
                data: avatarClass[1],
              })
            })
            .catch((err) => {
              console.error(err)
            })
        }
        if (fileRejections.length > 0) {
          setImageTooLarge(true)
        }
      },
    })

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isDragActive, isDragReject, isDragAccept],
    )

    const Thumbs = () => {
      return (
        <div style={thumb}>
          <div style={thumbInner}>
            {imageSignature && (
              <img
                src={`data:image/jpeg;base64,${imageSignature.data}`}
                alt="preview"
                style={img}
              />
            )}
          </div>
        </div>
      )
    }

    return (
      <>
        <div className="col-sm-12 p-0">
          <div
            className="border rounded alert alert-warning w-100 d-flex row align-items-center m-0 mb-2"
            role="alert"
          >
            <i className="fas fa-info-circle mr-1" />
            <p className="m-0 ml-1">
              Ukuran gambar maksimal 512kb <br />
              Format file wajib .png <br />
              Background putih/transparant{' '}
            </p>
          </div>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p className="mt-5">Drag or click to select file</p>
          </div>
          <aside style={thumbsContainer}>
            <Thumbs />
          </aside>
        </div>
      </>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    let role = localStorage.getItem('role_active')
    let parse = JSON.parse(role)
    setRoles(parse.role_type)
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="card">
        <div className="card-body d-flex align-items-center justify-content-between">
          <h5 className="mb-0">Detail Informasi</h5>
          {roles !== 'siswa' && (
            <button
              id="editButton"
              type="button"
              className="btn btn-primary btn-sm rounded m-0 float-right"
              data-toggle="collapse"
              data-target=".pro-det-edit"
              aria-expanded="false"
              aria-controls="pro-det-edit-1 pro-det-edit-2"
            >
              <i className="feather icon-edit" />
            </button>
          )}
        </div>
        <div
          className="card-body border-top pro-det-edit collapse show"
          id="pro-det-edit-1"
        >
          <div className="form-group row">
            <label className="col-sm-3 col-form-label font-weight-bolder">
              Nama
            </label>
            <div className="col-sm-9 col-form-label">
              <span>{data.name ? data.name : '-'}</span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label font-weight-bolder">
              Username
            </label>
            <div className="col-sm-9 col-form-label">
              <span>{data.username ? data.username : '-'}</span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label font-weight-bolder">
              NIM
            </label>
            <div className="col-sm-9 col-form-label">
              <span>{data.nim ? data.nim : '-'}</span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label font-weight-bolder">
              Jenis Kelamin
            </label>
            <div className="col-sm-9 col-form-label">
              <span>
                {data.gender
                  ? data.gender == 'L'
                    ? 'Laki-Laki'
                    : 'Perempuan'
                  : '-'}
              </span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label font-weight-bolder">
              Tanggal Lahir
            </label>
            <div className="col-sm-9 col-form-label">
              <span>{data.birthdate ? data.birthdate : '-'}</span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label font-weight-bolder">
              Alamat
            </label>
            <div className="col-sm-9 col-form-label">
              <span>{data.location ? data.location : '-'}</span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label font-weight-bolder">
              Email
            </label>
            <div className="col-sm-9 col-form-label">
              <span>{data.email ? data.email : '-'}</span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label font-weight-bolder">
              Phone
            </label>
            <div className="col-sm-9 col-form-label">
              <span>{data.phone ? data.phone : '-'}</span>
            </div>
          </div>
          {roleType === 'administrator' ||
          roleType === 'orangtua' ||
          roleType === 'instruktur' ||
          roleType === 'kepalasekolah' ||
          roleType === 'adminprofile' ? (
            <div className="form-group row">
              <label className="col-sm-3 col-form-label font-weight-bolder">
                Tanda Tangan Digital
              </label>
              <div className="col-sm-9 col-form-label">
                <div style={thumb}>
                  <div style={thumbInner}>
                    {previewSignature || !previewError ? (
                      previewSignature
                      ?
                        <img
                          src={
                            previewSignature
                          }
                          onError={() => setPreviewError(true)}
                          style={img}
                          alt="Signature"
                        />
                      :
                        <div
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            width: 100,
                          }}
                        >
                          <h5>No Image</h5>
                        </div>
                    ) : (
                      <div
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                          width: 100,
                        }}
                      >
                        <h5>No Image</h5>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {/* FORMS EDIT DATA */}
        <div
          className="card-body border-top pro-det-edit collapse "
          id="pro-det-edit-2"
        >
          <form>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label font-weight-bolder">
                Nama
              </label>
              <div className="col-sm-9">
                <input
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                  value={name}
                  type="text"
                  className="form-control"
                  placeholder="Masukan nama user"
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label font-weight-bolder">
                Username
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  value={data.username}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label font-weight-bolder">
                NIM
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  value={data.nim}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label font-weight-bolder">
                Jenis Kelamin
              </label>
              <div className="col-sm-9">
                <select
                  onChange={(e) => {
                    setGender(e.target.value)
                  }}
                  value={gender}
                  className="form-control"
                  id="exampleFormControlSelect1"
                >
                  <option value={''}>--Pilih jenis kelamin--</option>
                  <option value="L">Laki-laki</option>
                  <option value="P">Perempuan</option>
                </select>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label font-weight-bolder">
                Tanggal Lahir
              </label>
              <div className="col-sm-9">
                <input
                  onChange={(e) => {
                    setBirthdate(e.target.value)
                  }}
                  value={birthdate}
                  type="date"
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label font-weight-bolder">
                Alamat
              </label>
              <div className="col-sm-9">
                <textarea
                  onChange={(e) => {
                    setLocation(e.target.value)
                  }}
                  value={location}
                  className="form-control"
                  placeholder="Masukan alamat"
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label font-weight-bolder">
                Email
              </label>
              <div className="col-sm-9">
                <input
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                  value={email}
                  type="text"
                  className="form-control"
                  placeholder="Contoh: email@gmail.com"
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label font-weight-bolder">
                Phone
              </label>
              <div className="col-sm-9">
                <input
                  onChange={(val) => {
                    handleFormPhoneNumber(val.target.value)
                  }}
                  value={phone}
                  type="text"
                  className="form-control"
                  placeholder="Contoh: 08XX-XXXX-XXXX"
                />
              </div>
            </div>
            {roleType === 'administrator' ||
            roleType === 'orangtua' ||
            roleType === 'instruktur' ||
            roleType === 'kepalasekolah' ||
            roleType === 'adminprofile' ? (
              <div className="form-group row">
                <label className="col-sm-3 col-form-label font-weight-bolder">
                  Tanda Tangan Digital
                </label>
                <div className="col-sm-9">
                  <Dropzone />
                  {isImageTooLarge ? (
                    <span className="col offset-md-3 small form-text text-danger">
                      Ukuran gambar maksimal 512kb
                    </span>
                  ) : null}
                </div>
              </div>
            ) : null}
            <div className="form-group row mt-5">
              <label className="col-sm-3 col-form-label" />
              <div className="col-sm-9 text-right">
                <button
                  disabled={loading}
                  onClick={() => {
                    handleUpdate()
                  }}
                  type="button"
                  className="btn btn-primary"
                >
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                  <span>Simpan</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

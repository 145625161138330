import moment from 'moment/moment'
import React from 'react'
import Modal from 'component/modal'

export default function RaporBlockAllModal({ historyRapor }) {
  return (
    <Modal
        id="modalHistoryRapor"
        title="Riwayat rapor"
      >
        <div
          className="hd-body pr-2"
          style={{
            maxHeight: '350px',
            overflowY: 'auto',
          }}
        >
          {historyRapor?.length ? (
            <>
              {historyRapor.map((history, index) => (
                <div
                  key={index}
                  className="excerpt rounded mb-3"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <span>
                      <i className="feather icon-calendar mr-1 f-14" />
                      {history.cr
                        ? moment(history.cr).format('H:MM, DD-MM-YYYY')
                        : '-'}
                    </span>
                    <span className="badge badge-danger">Ditolak</span>
                  </div>
                  <h6 className="mb-1">Catatan:</h6>
                  <p className="m-0">{history.no || '-'}</p>
                </div>
              ))}
            </>
          ) : (
            <p className="text-muted mb-0">Belum ada riwayat</p>
          )}
        </div>
      </Modal>
  )
}

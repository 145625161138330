import React from 'react'
import { useLocation } from 'react-router-dom';

export default function PreviewQuizAnswerSiswaAlert({ isGrupKecilPassed }) {
  const location  = useLocation()
  const content   = location.state?.content || {};
  const dataSiswa = location.state?.existingData || {};

  const isGivingFeedback = content.feed?.feed_temps.find(
    (ft) => ft.user_id === dataSiswa.id && ft.content_id === dataSiswa.content_id,
  )

  return (
    (content.feedback || content.group_status) ? (
      <>
        <div
          className="mt-2 border rounded alert alert-warning w-100"
          role="alert"
        >
          <strong>
            <i className="fas fa-info-circle mr-1" />
            Siswa wajib
            {content.feedback && ` memberikan feedback`}
            {content.feedback && content.group_status && ` dan`}
            {content.group_status && ` terlibat dalam diskusi kecil`} pada
            materi.
          </strong>
        </div>
        {isGivingFeedback ? (
          <div
            className="mt-2 border rounded alert alert-success w-100"
            role="alert"
          >
            <strong>
              <i className="fas fa-check-circle mr-1" />
              Siswa sudah memberikan feedback pada materi.
            </strong>
          </div>
        ) : (
          content.feedback && (
            <div
              className="mt-2 border rounded alert alert-danger w-100"
              role="alert"
            >
              <strong>
                <i className="fas fa-info-circle mr-1" />
                Siswa belum memberikan feedback pada materi.
              </strong>
            </div>
          )
        )}
        {isGrupKecilPassed ? (
          <div
            className="mt-2 border rounded alert alert-success w-100"
            role="alert"
          >
            <strong>
              <i className="fas fa-check-circle mr-1" />
              Siswa sudah terlibat dalam diskusi kecil pada materi.
            </strong>
          </div>
        ) : (
          <div
            className="mt-2 border rounded alert alert-danger w-100"
            role="alert"
          >
            <strong>
              <i className="fas fa-info-circle mr-1" />
              Siswa belum terlibat dalam diskusi kecil pada materi.
            </strong>
          </div>
        )}
      </>
    ) : null
  )
}

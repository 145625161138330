import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import DataTable from 'component/data-table'
import { avatarDefaultUser } from 'util/constant'

export default function GroupDetailUser({ idGroup }) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, deleteRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [data, setData]                 = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [limit, setLimit]               = useState(10)
  const [offset, setOffset]             = useState(0)
  const [search, setSearch]             = useState('')

  const [data1, setData1]                 = useState([])
  const [totalRecords1, setTotalRecords1] = useState(0)
  const [limit1, setLimit1]               = useState(10)
  const [offset1, setOffset1]             = useState(0)
  const [search1, setSearch1]             = useState('')

  const [year, setYear]                 = useState('')
  const [activeYear, setActiveYear]     = useState('')
  const [academicYear, setAcademicYear] = useState([])
  const [taTahunCode, setTaTahunCode]   = useState('')

  /* ========================================= Functions ======================================== */
  async function getUserGroupRequired() {
    let response = await getRequest(
      'groups-user?id=' +
        idGroup +
        '&limit=' +
        limit +
        '&offset=' +
        offset +
        '&search=' +
        search +
        '&required=1&ta_tahun_code=' +
        taTahunCode +
        '',
    )
    if (response) {
      setData(response.data)
      setTotalRecords(response.totalRecord)
    }
  }
  async function getUserGroup() {
    let response = await getRequest(
      'groups-user?id=' +
        idGroup +
        '&limit=' +
        limit1 +
        '&offset=' +
        offset1 +
        '&search=' +
        search1 +
        '&ta_tahun_code=' +
        year +
        '&required=0',
    )
    if (response) {
      setData1(response.data)
      setTotalRecords1(response.totalRecord)
    }
  }

  async function getAcademicYear() {
    let response = await getRequest(`academic-year`)
    if (response) {      
      setAcademicYear(response.data)
    }
  }

  async function getActiveYear() {
    let dp = localStorage.getItem('semester')
    if (dp) {
      var json = JSON.parse(dp)
      setActiveYear(json.ta_tahun_code)
    }

    let thc = localStorage.getItem('group_ta_tahun_code')
    if (thc) {
      setTaTahunCode(thc)
    }
  }

  async function closeFromGroup(idGroupUser) {
    Swal.fire({
      title: 'Kamu yakin?',
      text: 'Kamu ingin mengeluarkan siswa ini ke dari group?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, keluarkan!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await deleteRequest(
          'group-users?id=' + idGroupUser,
        )
        if (response) {
          window.notification(
            'Berhasil',
            'menghapus siswa dari kelas',
            'success',
          )
          await getUserGroup()
          await getUserGroupRequired()
        }
      }
    })
  }

  async function addToGroup(user_id) {  
    Swal.fire({
      title: 'Kamu yakin?',
      text: 'Kamu ingin menambahkan siswa ini ke dalam group?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, masukan!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        var form = new FormData()
        form.append('group_id', idGroup)
        form.append('user_id', user_id)
        let response = await postRequest('group-users', form)
        if (response) {
          window.notification(
            'Berhasil',
            'menambahkan siswa ke kelas',
            'success',
          )
          await getUserGroup()
          await getUserGroupRequired()
        }
      }
    })
  }

  /* ======================================== Components ======================================== */
  function RenderItem({ item }) {
    return (
      <tr>
        <td>
          <div className="d-inline-block align-middle">
            <img
              src={`${item.linkAvatar}`}
              alt={item.name}
              className="img-radius align-top m-r-15"
              style={{ width: '40px', height: '40px' }}
              onError={(event) => {
                event.currentTarget.src = avatarDefaultUser
              }}
            />
            <div className="d-inline-block">
              <h6 className={`${item.id_group_users ? '' : 'mt-2'} m-b-0`}>
                {item.name}
              </h6>
              {item.id_group_users ? (
                <span className="badge badge-light-warning">member kelas</span>
              ) : null}
            </div>
          </div>
        </td>
        <td>{item.nim ? item.nim : '-'}</td>
        <td>{item.username}</td>
        <td>
          {item.id_group_users ? (
            <button
              onClick={() => {
                closeFromGroup(item.id_group_users)
              }}
              className="btn btn-danger btn-sm mr-1"
            >
              <i className="fas fa-minus" />
            </button>
          ) : (
            <button
              onClick={() => {
                addToGroup(item.id)
              }}
              className="btn btn-success btn-sm mr-1"
            >
              <i className="fas fa-plus" />
            </button>
          )}
        </td>
      </tr>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getAcademicYear()
    getActiveYear()
  }, [])

  useEffect(() => {
    getUserGroupRequired()    
  }, [limit, offset, search, taTahunCode])

  useEffect(() => {
    getUserGroup()
  }, [limit1, offset1, search1, year])

  /* ========================================== Output ========================================== */
  return (
    <div className="dt-responsive table-responsive">
      <div className="position-sticky">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <button
              className="btn btn-primary float-left"
              data-toggle="modal"
              data-target=".bd-example-modal-lg"
            >
              Tambah siswa
            </button>
          </div>
          <div className="col-md-6 col-sm-12"></div>
        </div>
      </div>
      <br />

      <DataTable
        className={'table table-striped table-bordered nowrap'}
        data={data}
        totalRows={totalRecords}
        show={[10, 20, 30, 40, 50]}
        onChangeSearch={(val) => {
          setSearch(val)
        }}
        onChangeLimit={(val) => {
          setLimit(val)
        }}
        onChangePage={(val) => {
          setOffset(val)
        }}
        column={['Nama', 'No. Induk', 'Username', '']}
        renderItem={(item, i) => (
          <RenderItem
            item={item}
            key={i}
          />
        )}
      />
      <div
        className="modal fade bd-example-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title h4"
                id="myLargeModalLabel"
              >
                Daftar siswa
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <select
                onChange={(val) => {
                  setData1([])
                  setTotalRecords1(0)
                  setSearch1('')
                  setLimit1(10)
                  setOffset1(0)
                  setYear(val.target.value)
                }}
                className="form-control col-md-6"
              >
                <option value="">- Pilih semua tahun angkatan -</option>
                {academicYear.map((val, i) => {
                  return (
                    <option value={val.code}>
                      {val.description}{' '}
                      {val.code == activeYear ? '(Aktif)' : null}
                    </option>
                  )
                })}
              </select>
              <DataTable
                className={'table table-striped table-bordered nowrap'}
                data={data1}
                totalRows={totalRecords1}
                show={[10, 20, 30, 40, 50]}
                onChangeSearch={(val) => {
                  setSearch1(val)
                }}
                onChangeLimit={(val) => {
                  setLimit1(val)
                }}
                onChangePage={(val) => {
                  setOffset1(val)
                }}
                column={['Nama', 'No. Induk', 'Username', '']}
                renderItem={(item, i) => (
                  <RenderItem
                    item={item}
                    key={i}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useBaseContext } from 'context/base'
import { useStaleObj } from 'util/use-stale-obj'
import DraftInfo from 'pages/input-rapor/components/draft-info'

function InputRaporAllType4({ userId, reportGroupId, response }) {
  /* ========================================== Helpers ========================================= */
  const params = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= States ========================================= */
  const [res, setRes] = useState(null)
  const [datax, setDatax] = useState([])

  /* ======================================== Functions ======================================= */
  async function getValue() {
    let us = localStorage.getItem('user')
    let usj = JSON.parse(us)
    var rsp = await getRequest(
      `get-value-score-by-group?user_id=${userId}&group_id=${reportGroupId}&semester_code=${params.semester}`,
    )

    if (rsp) {
      var ss = {}
      if (rsp.data) {
        var d = rsp.data
        ss = {
          user_id: d.user_id,
          categories_id: d.categories_id,
          categories_type_id: d.categories_type_id,
          report_group_id: d.report_group_id,
          createdBy: d.createdBy,
          updatedBy: d.updatedBy,
          value: JSON.parse(d.value),
          ta_semester_code: d.ta_semester_code,
          profile_id: d.profile_id,
        }
        setDatax(JSON.parse(d.value))
        setRes(ss)
      } else {
        ss = {
          user_id: userId,
          categories_id: null,
          categories_type_id: null,
          report_group_id: reportGroupId,
          createdBy: usj.id,
          updatedBy: usj.id,
          value: [],
          ta_semester_code: params.semester,
          profile_id: usj.profile_id,
        }
        setRes(ss)
      }
      response(ss)
    }
  }

  useEffect(() => {
    getValue()
  }, [])

  return res && <InputPrestasi initialState={datax} />

  function InputPrestasi({ initialState }) {
    const key = [
      'input_rapor_all_type4',
      params.semester,
      reportGroupId,
      userId,
    ].join('_')

    const { loading, data, isDrafted, invalidate, stale } = useStaleObj(
      initialState,
      key,
    )

    useEffect(() => {
      if (res) {
        res.value = data
        response(res)
      }
    }, [data])

    return (
      !loading &&
      data && (
        <div className="table-responsive">
          <div className="d-flex justify-content-between align-items-end">
            <div>{isDrafted && <DraftInfo onClick={invalidate} />}</div>
            <button
              type="button"
              onClick={() => {
                stale([
                  ...data,
                  {
                    jenis_kegiatan: '',
                    keterangan: '',
                  },
                ])
              }}
              className="btn btn-outline-primary"
            >
              Tambah Prestasi
            </button>
          </div>

          <table className="table mt-2 table-bordered">
            <thead>
              <tr>
                <th style={{ width: 10 }}>No</th>
                <th style={{ width: 300 }}>Jenis Kegiatan</th>
                <th style={{ width: 200 }}>Keterangan</th>
                <th style={{ width: 30 }}></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={item.jenis_kegiatan}
                        onChange={(val) => {
                          data[index].jenis_kegiatan = val.target.value
                          stale([...data])
                        }}
                        className="form-control"
                        placeholder={'Jenis Kegiatan'}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        defaultValue={item.keterangan}
                        onChange={(val) => {
                          data[index].keterangan = val.target.value
                          stale([...data])
                        }}
                        className="form-control"
                        placeholder={'Keterangan'}
                      />
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          data.splice(index, 1)
                          stale([...data])
                        }}
                        className={'btn btn-danger btn-sm'}
                      >
                        Hapus
                      </button>
                    </td>
                  </tr>
                )
              })}
              {data.length === 0 ? (
                <tr>
                  <td colSpan={4}>Tidak ada data</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      )
    )
  }
}

export default React.memo(
  InputRaporAllType4,
  (oldProps, newProps) =>
    oldProps.userId === newProps.userId &&
    oldProps.reportGroupId === newProps.reportGroupId,
)

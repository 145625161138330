import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import Type1 from './Type1'
import Type2 from './Type2'
import Type3 from './Type3'
import Type4 from './Type4'
import Type5 from './Type5'
import Type6 from './Type6'
import Type7 from './Type7'
import ProfileUser from './profile-user'
import KindnessProgramV2 from './KindnessProgramV2'
import DetailNilai from './detailNilai'

import Swal from 'sweetalert2'
import useGetSemesterType from 'hooks/useGetSemesterType'

export default function AllScoresSetScore({ }) {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const { userId, semester } = useParams()
  const { semesterType } = useGetSemesterType({ semester })

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()

  /* ======================================== Constants ======================================= */
  const links = [{ url: '/input-rapor-all', name: 'Rapor All' }]

  /* ========================================= States ========================================= */
  const [data, setData] = useState([])
  const [collect, setCollect] = useState([])
  const [statusApproval, setStatusApproval] = useState([])
  const [isLoadingInsert, setIsLoadingInsert] = useState(false)
  const [roleType, setRoleType] = useState(null)
  const [reportMaster, setReportMaster] = useState(null)
  const [isRaporBaru, setIsRaporBaru] = useState('')
  const [isKelas, setIsKelas] = useState('')
  const [dataKindness, setDataKindness] = useState([])
  const [deskripsiKindness, setdeskripsiKindness] = useState('')
  const [catatanKindness, setcatatanKindness] = useState('')

  /* ======================================== Functions ======================================= */
  async function getRapotGroup() {
    var response = await getRequest(
      `report-group?user_id=${userId}&ta_semester_code=${semester}`,
    )
    if (response) {
      var response2 = await getRequest(
        `getKindness?user_id=${userId}&ta_semester_code=${semester}`,
      )

      setReportMaster(response.reportMaster)
      setData(response.data)
      setIsKelas(response.kelas)

      if (response2.data.length > 0) {
        setDataKindness(response2.data)
        setdeskripsiKindness(response2.data[0].urlFile)
        setcatatanKindness(response2.data[0].catatan)
      }
      setIsRaporBaru(response.is_template_raport_baru)
    }
  }

  async function confirmSubmit(userId) {
    var approved = statusApproval.filter((val, i) => val == 'approved')
    if (approved.length == statusApproval.length) {
      Swal.fire({
        icon: 'warning',
        title: 'Apa Anda yakin akan mengajukan rapor sekarang?',
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: 'Tidak',
        confirmButtonText: 'Ya, ajukan!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Mengajukan rapor...',
            html: `<div class="d-flex justify-content-center">
                      <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                      </div>
                    </div>`,
            allowOutsideClick: false,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
          })
          const isSuccess = await insertToMaster(userId)
          if (isSuccess) {
            Swal.fire({
              title: 'Rapor rapor berhasil diproses',
              icon: 'success',
              allowOutsideClick: false,
              showCloseButton: false,
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: 'Ok',
            })
            history.push('/input-rapor-all')
          }
        }
      })
    } else {
      Swal.fire(
        'Proses Gagal',
        'Generate rapor gagal diproses, semua nilai mata pelajaran harus telah disetujui kepala sekolah',
        'error',
      )
    }
  }

  async function insertToMaster(userId) {
    setIsLoadingInsert(true)
    let form = new FormData()
    form.append('user_id', userId)
    form.append('semester_code', semester)
    const response = await postRequest('report-wali-master', form)
    setIsLoadingInsert(false)
    return !!response
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getRapotGroup()
    let roleAktif = localStorage.getItem('role_active')
    if (roleAktif) {
      let js = JSON.parse(roleAktif)
      setRoleType(js.role_type)
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Preview Rapor" links={links} />
        <div className="card">
          <div className="card-body">
            <ProfileUser userId={userId} />
            {isRaporBaru == true ? (
              <>
                <KindnessProgramV2
                  semester={semester}
                  userId={userId}
                  dataKindness={dataKindness}
                  setDataKindness={setDataKindness}
                  deskripsi={deskripsiKindness}
                  catatan={catatanKindness}
                />
                <DetailNilai />
              </>
            ) : null}
            {data.map((item, i) => (
              <RenderGroup
                item={item}
                i={i}
              />
            ))}
            <hr />
            <div className="d-flex justify-content-end">
              <Button />
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )

  function Button({ item }) {
    if (
      roleType != 'kepalasekolah' &&
      roleType != 'administrator' &&
      roleType != 'adminprofile'
    ) {
      return (
        <>
          <button
            type="button"
            className="btn btn-outline-secondary mr-2"
            onClick={() =>
              history.push(`/input-rapor-all/${userId}/${semester}`)
            }
          >
            Ubah Data
          </button>
          <button
            type="button"
            disabled={isLoadingInsert}
            onClick={isLoadingInsert ? () => { } : () => confirmSubmit(userId)}
            className="btn btn-primary"
          >
            {isLoadingInsert ? (
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
              />
            ) : null}
            Ajukan Rapor
          </button>
        </>
      )
    } else {
      const isDiajukan = reportMaster?.status ? ![
        'progress',
        'draft',
        'rejected',
        'approved',
      ].includes(reportMaster?.status) : false
      const renderClassName =
        'alert ' + (isDiajukan ? 'alert-success' : 'alert-warning')
      return (
        <div>
          <div
            className={renderClassName}
            role="alert"
          >
            <i className="fas fa-exclamation-circle" />{' '}
            <span>
              <span className="f-w-700">Status:</span>{' '}
              {reportMaster?.status === 'draft' && 'Draft'}
              {reportMaster?.status === 'rejected' && 'Ditolak'}
              {reportMaster?.status === 'progress' && 'Progress'}
              {reportMaster?.status === 'approved' && 'Approved'}
              {isDiajukan && <>Diajukan</>}
              {!reportMaster?.status && <>Progress</>}
            </span>
          </div>
          <div>
            <button
              type="button"
              disabled={isLoadingInsert || reportMaster?.status === 'rejected' || !reportMaster?.status}
              onClick={isLoadingInsert ? () => { } : () => rejectedRapor()}
              className="btn btn-danger mr-2"
            >
              {isLoadingInsert ? (
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                />
              ) : null}
              Tolak Rapor
            </button>

            <button
              type="button"
              disabled={isLoadingInsert || !isDiajukan}
              onClick={isLoadingInsert ? () => { } : () => approvedRapor()}
              className="btn btn-success"
            >
              {isLoadingInsert ? (
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                />
              ) : null}
              Approve Rapor
            </button>
          </div>
        </div>
      )
    }
  }

  async function approvedRapor() {
    Swal.fire({
      icon: 'warning',
      title: `Approve rapor?`,
      confirmButtonText: 'Ya!',
      cancelButtonText: 'Tidak',
      showCancelButton: true,
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let form = new FormData()
        form.append('id', reportMaster?.id)

        let res = await postRequest('report-approve', form)
        if (res) {
          Swal.fire({
            icon: 'success',
            title: `Rapor telah disetujui`,
          }).then(async () => history.push(`/input-rapor-all`))
        }
      }
    })
  }

  async function rejectedRapor() {
    let reject = collect.filter((item) => item.categories_id === null)
    Swal.fire({
      icon: 'warning',
      title: `Tolak rapor?`,
      confirmButtonText: 'Ya, tolak!',
      confirmButtonColor: '#ff5252',
      cancelButtonText: 'Tidak',
      showCancelButton: true,
      input: 'textarea',
      inputPlaceholder: 'Catatan untuk walikelas...',
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      preConfirm: (inputValue) => {
        return inputValue
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        let form = new FormData()
        form.append('rejected', JSON.stringify(reject))
        form.append('note', result.value)
        form.append('user_id', reject[0].user_id)
        form.append('ta_semester_code', reject[0].ta_semester_code)

        let res = await postRequest('report-reject', form)
        if (res) {
          Swal.fire({
            icon: 'success',
            title: `Rapor berhasil ditolak`,
          }).then(async () => history.push(`/input-rapor-all`))
        }
      }
    })
  }

  async function simpan() {
    var response = await postRequest('save-rapot', collect)
    if (response) {
      window.notification('Sukses', 'Berhasil disimpan', 'success')
    }
  }

  function RenderGroup({ item, i }) {
    return (
      <InputTemplate
        type={item.type}
        reportGroupId={item.id}
        item={item}
        i={i}
      />
    )
  }

  function InputTemplate({ type, reportGroupId, i, item }) {
    function response(res) {
      var col = collect
      var filter = col.filter(
        (x) =>
          x.report_group_id == reportGroupId &&
          x.categories_id == res.categories_id,
      )
      if (filter.length == 0) {
        col.push(res)
      } else {
        var index = col.findIndex(
          (x) =>
            x.report_group_id == reportGroupId &&
            x.categories_id == res.categories_id,
        )
        col[index] = res
      }
      setCollect(col)
    }

    function responseApproval(res) {
      var r = statusApproval
      r.push(res)
      setStatusApproval(r)
    }

    if (type == 0) {
      return (
        <div className="mb-5">
          <h5>{`${++i}. ${item.name}`}</h5>
          <Type1
            userId={userId}
            reportGroupId={reportGroupId}
            response={response.bind(this)}
            semesterType={semesterType}
          />
        </div>
      )
    } else if (type == 23) {
      return (
        <div className="mb-5">
          <h5>{`${++i}. ${item.name}`}</h5>
          <Type2
            userId={userId}
            responseApproval={responseApproval.bind(this)}
            reportGroupId={reportGroupId}
            response={response.bind(this)}
          />
        </div>
      )
    } else if (type == 3) {
      return (
        <div className="mb-5">
          <h5>{`${++i}. ${item.name}`}</h5>
          <Type3
            userId={userId}
            reportGroupId={reportGroupId}
            response={response.bind(this)}
          />
        </div>
      )
    } else if (type == 4) {
      return (
        <div className="mb-5">
          <h5>{`${++i}. ${item.name}`}</h5>
          <Type4
            userId={userId}
            reportGroupId={reportGroupId}
            response={response.bind(this)}
          />
        </div>
      )
    } else if (type == 5) {
      return (
        <div className="mb-5">
          <h5>{`${++i}. ${item.name}`}</h5>
          <Type5
            userId={userId}
            reportGroupId={reportGroupId}
            response={response.bind(this)}
          />
        </div>
      )
    } else if (type == 6) {
      return (
        <div className="mb-5">
          <h5>{`${++i}. ${item.name}`}</h5>
          <Type6
            userId={userId}
            reportGroupId={reportGroupId}
            response={response.bind(this)}
          />
        </div>
      )
    } else if (type == 11 && isKelas === 202 && semesterType === 'even') {
      return (
        <div className="mb-5">
          <h5>{`${++i}. ${item.name}`}</h5>
          <Type7
            userId={userId}
            reportGroupId={reportGroupId}
            response={response.bind(this)}
          />
        </div>
      )
    }

    return null
  }
}

import React, { useState, useEffect } from 'react'

export default function ModalRejectItem({
  name,
  predicate,
  score,
  note,
  number,
  listRejects,
  setListRejects,
  userId,
}) {
  /* ======================================= Local States ======================================= */
  const [notes, setNotes] = useState(note) // idle / start / success / error

  /* ========================================= Functions ======================================== */
  function updateListRejects(notes, userId, currentRejects = []) {
    const newRejects = currentRejects.map((current) => {
      return current.user_id === userId
        ? {
            class_id: current.class_id,
            user_id: current.user_id,
            ta_semester_code: current.ta_semester_code,
            ta_tahun_code: current.ta_tahun_code,
            catatan: notes,
          }
        : current
    })
    setListRejects(newRejects)
  }

  function handleChangeNotes(event, userId, currentRejects = []) {
    setNotes(event.target.value)
    updateListRejects(event.target.value, userId, currentRejects)
  }

  /* ========================================== Output ========================================== */
  return (
    <>
      <tr>
        <td>{number}</td>
        <td>{name}</td>
        <td>{score}</td>
        <td>{predicate}</td>
        <td>
          <textarea
            className="form-control"
            placeholder="Catatan"
            value={notes}
            onChange={(event) => handleChangeNotes(event, userId, listRejects)}
          />
        </td>
      </tr>
    </>
  )
}

import React from 'react'

export default function ContentType({ id }) {
  switch (id) {
    case 1:
      return (
        <span className="text-muted f-12">
          <i className="feather icon-file-text mr-1 f-10" />
          Teks
        </span>
      )
    case 2:
      return (
        <span className="text-muted f-12">
          <i className="feather icon-volume-1 mr-1 f-10" />
          Audio
        </span>
      )
    case 3:
      return (
        <span className="text-muted f-12">
          <i className="feather icon-play-circle mr-1 f-10" />
          Video
        </span>
      )
    case 4:
      return (
        <span className="text-muted f-12">
          <i className="fas fa-file mr-1 f-10" />
          Dokumen
        </span>
      )
    case 5:
      return (
        <span className="text-muted f-12">
          <i className="fas fa-code mr-1 f-10" />
          iFrame
        </span>
      )
    case 6:
      return (
        <span className="text-muted f-12">
          <i className="fas fa-question-circle mr-1 f-10" />
          Quiz
        </span>
      )
    case 7:
      return (
        <span className="text-muted f-12">
          <i className="fas fa-check-double mr-1 f-10" />
          Survey
        </span>
      )
    case 8:
      return (
        <span className="text-muted f-12">
          <i className="fas fa-pen-alt mr-1 f-10" />
          Tugas
        </span>
      )
    case 9:
      return (
        <span className="text-muted f-12">
          <i className="fas fa-object-group mr-1 f-10" />
          Graphic Organizer
        </span>
      )
    case 10:
      return (
        <span className="text-muted f-12">
          <i className="fas fa-chalkboard mr-1 f-10" />
          Whiteboard
        </span>
      )
    default:
      return null
  }
}

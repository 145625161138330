import React from 'react'
import YouTube from 'react-youtube'

const AssignmentYoutube = ({ youtubeId, handleYoutubeUrl, isYoutubeEmpty }) => (
  <>
    <div className="form-group row">
      <label
        className="col-sm-3 col-form-label"
        htmlFor="input-youtube"
      >
        URL Youtube :
      </label>
      <div className="col-sm-9">
        <input
          type="text"
          className="form-control"
          onChange={(event) => handleYoutubeUrl(event.target.value)}
          placeholder="Contoh URL: https://youtube.com/watch?v=xyz"
          id="input-youtube"
        />
        {isYoutubeEmpty ? (
          <label className="col small form-text text-danger">
            Youtube tidak boleh kosong.
          </label>
        ) : null}
      </div>
    </div>
    {youtubeId ? (
      <>
        <p className="f-14">Preview : </p>
        <YouTube
          key={youtubeId}
          opts={{
            width: '100%',
            height: '450px',
          }}
          videoId={youtubeId}
        />
      </>
    ) : null}
  </>
)

export default AssignmentYoutube

import React from 'react'

const PreviewAudioLink = ({ link = '' }) => {
  if (link.includes('drive.google.com')) {
    return (
      <iframe
        src={`${link}/preview`}
        style={{
          width: '100%',
          height: '95px',
          border: 'none',
          backgroundColor: '#ddd',
        }}
      />
    )
  } else if (link.includes('soundcloud.com')) {
    return (
      <iframe
        title="sc-widget"
        id="sc-player"
        width="100%"
        height="166"
        scrolling="no"
        frameBorder="no"
        src={`https://w.soundcloud.com/player/?url=${link}&amp&download=false&sharing=false`}
      />
    )
  } else {
    return (
      <audio
        style={{ width: '100%' }}
        controls
        controlsList="nodownload"
        onContextMenu={(event) => event.preventDefault()}
      >
        <source
          src={`${link}`}
          type="audio/mpeg"
        />
        Your browser does not support the audio tag.
      </audio>
    )
  }
}

export default PreviewAudioLink

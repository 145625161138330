import React from 'react'
import useForumDetail from 'context/forum-detail'
import useForumDetailItem from 'context/forum-detail-item'

export default function ForumDetailBtnBalasan() {
  const { reply } = useForumDetail()
  const { item }  = useForumDetailItem()
  
  return (
    <button
      onClick={() => {
        reply.setShownId(reply.shownId == item.id ? '' : item.id)
        reply.reset()
      }}
      type="button"
      style={{ marginLeft: 10 }}
      className="btn btn-outline-secondary btn-sm"
    >
      <i className="fas fa-reply" /> {item.forums.length} balasan
    </button>
  )
}

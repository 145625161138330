import React, { useState } from 'react'
import { avatarDefaultUser } from 'util/constant';
import BaseReactBootstrapModal from './BaseReactBootstrapModal';
import BaseButton from 'component/button/BaseButton';
import DataTableWithFilter from 'component/data-table/indexWithFilter';
import { useBaseModalSelectedStudentHookV2 } from './hooks/useBaseModalSelectedStudentHookV2';
import styles from './BaseModalSelectedStudentV2.module.css';

/**
 * Base Modal Selected Student Item Props
 * 
 * @typedef {Object} ListStudentItemProps
 * @property {number} index
 * @property {Student} item
 * @property {boolean} isAdded
 * @property {(student: Student) => void=} onAddStudent 
 * @property {(student: Student) => void=} onRemoveStudent 
 */

/**
 * MODAL SELECTED STUDENT
 * 
 * @param {{
 *  show: boolean
 *  isWithCloseButton?: boolean
 *  isWithXButton?: boolean
 *  isWithHeader?: boolean
 *  title?: String
 *  dataTableProps: import('./hooks/useBaseModalSelectedStudentHookV2').DataTableProps
 *  subMapelId: string
 *  selectedGrade: GradeItem
 *  onCloseHandler: () => void
 * }} props
 */
const BaseModalSelectedStudentV2 = ({ 
    show = false, 
    isWithCloseButton = false,
    isWithXButton = true,
    isWithHeader = false,
    title,
    dataTableProps = {}, 
    subMapelId,
    selectedGrade,
    onCloseHandler = () => {}, 
  }) => {
    const [showModalAddListStudent, setShowModalAddListStudent] = useState(false)
    const { data, set, handler, dispatch } = useBaseModalSelectedStudentHookV2({ dataTableProps, isListStudentHasAdded: true, subMapelId, selectedGrade })

    const onClickAddStudentHandler = () => {
      setShowModalAddListStudent(true)
    }

    const onCloseAddStudentHandler = () => {
      setShowModalAddListStudent(false)
    }

    return (
      <BaseReactBootstrapModal 
        size="lg" show={show} 
        handleClose={onCloseHandler} 
        isWithCloseButton={isWithCloseButton} 
        isWithXButton={isWithXButton} 
        isWithHeader={isWithHeader}
        isWithFooter={false}
        centered
      >
        <h4 className='text-center mb-5'>{title || `Grade ${selectedGrade?.name}`}</h4>
        <div
          className="hd-body pr-2"
          style={{
            maxHeight: '50rem',
            overflowY: 'auto',
          }}
        >
          
          <BaseListSelectedStudentsHeader
            onClickAddStudentHandler={onClickAddStudentHandler}
          />
          <hr />
          <DataTableWithFilter
            show={data.limits}
            column={data.column}
            totalRows={data.listUsersGrade.meta?.totalData || 0}
            onChangeSearch={(val) => dispatch.dispatchListUsersGrade({
              type: 'set_meta',
              payload: {
                search: val
              }
            })}
            onChangeLimit={(val) => dispatch.dispatchListUsersGrade({
              type: 'set_meta',
              payload: {
                limit: val
              }
            })}
            onChangePage={(val) => dispatch.dispatchListUsersGrade({
              type: 'set_meta',
              payload: {
                offset: val
              }
            })}
            className={'table table-striped table-bordered nowrap'}
            data={data.usingData}
            notFoundMessage={'Tidak ada data yang ditemukan'}
            {...dataTableProps}
            renderItem={(student, index) => (
              <ListStudentItemProps
                item={student}
                isAdded={true}
                key={`selected-student-item-index${index}`}
                onRemoveStudent={student => handler.onAddOrRemoveStudentHandler({
                  isAdded: true,
                  student,
                  onSuccessHandler: handler.getListUsersGradeHandler
                })}
              />
            )}
            filter1={{
              defaultValue: data.selectedClass,
              options: data.optionsClass,
              placeholder: 'Filter kelas...',
              onChange: (choice) => {
                set.setSelectedClass(choice)
                if (!choice) {
                  dispatch.dispatchListUsersGrade({
                    type: 'set_data',
                    payload: [],
                  })
                }
              },
              isClearable: true,
            }}
          />
        </div>

        <BaseModalListStudent
          show={showModalAddListStudent}
          subMapelId={subMapelId}
          selectedGrade={selectedGrade}
          onCloseAddStudentHandler={onCloseAddStudentHandler}
          onAddOrRemoveStudentHandler={(student, onSuccessHandler) => handler.onAddOrRemoveStudentHandler({
            student,
            onSuccessHandler: () => {
              handler.getListUsersGradeHandler()
              if (onSuccessHandler) {
                onSuccessHandler()
              }
            }
          })}
        />
      </BaseReactBootstrapModal>
    );
}

export default BaseModalSelectedStudentV2


/**
 * MODAL LIST STUDENT
 * 
 * @param {{
 *  show: boolean
 *  dataTableProps: import('./hooks/useBaseModalSelectedStudentHookV2').DataTableProps
 *  isWithCloseButton?: boolean
 *  isWithXButton?: boolean
 *  isWithHeader?: boolean
 *  subMapelId: string
 *  selectedGrade: object
 *  onCloseAddStudentHandler: () => void
 *  onAddOrRemoveStudentHandler: (student: Student, onSuccessHandler: () => void) => void
 * }} props
*/
export const BaseModalListStudent = ({ 
  show,
  dataTableProps = {}, 
  isWithCloseButton = false, 
  isWithXButton = true, 
  isWithHeader = false,
  subMapelId,
  selectedGrade,
  onCloseAddStudentHandler,
  onAddOrRemoveStudentHandler,
 }) => {
  const { data, set, handler, dispatch } = useBaseModalSelectedStudentHookV2({ dataTableProps, subMapelId, selectedGrade })

  return (
    <BaseReactBootstrapModal 
      size="lg"
      show={show}
      handleClose={onCloseAddStudentHandler} 
      isWithCloseButton={isWithCloseButton} 
      isWithXButton={isWithXButton} 
      isWithHeader={isWithHeader}
      isWithFooter={false}
      centered
      backdropClassName={styles.backdropClassNameListStudent}
      style={{
        zIndex: 1080
      }}
    >
      <h4 className='text-center mb-5'>Tambah Siswa Ke Dalam Grade {selectedGrade?.name}</h4>
      <DataTableWithFilter
        show={data.limits}
        column={data.column}
        totalRows={data.listUsersGrade.meta?.totalData || 0}
        onChangeSearch={(val) => dispatch.dispatchListUsersGrade({
          type: 'set_meta',
          payload: {
            search: val
          }
        })}
        onChangeLimit={(val) => dispatch.dispatchListUsersGrade({
          type: 'set_meta',
          payload: {
            limit: val
          }
        })}
        onChangePage={(val) => dispatch.dispatchListUsersGrade({
          type: 'set_meta',
          payload: {
            offset: val
          }
        })}
        className={'table table-striped table-bordered nowrap'}
        data={data.usingData}
        notFoundMessage={'Tidak ada data yang ditemukan'}
        {...dataTableProps}
        renderItem={(student, index) => (
          <ListStudentItemProps
            item={student}
            key={`list-student-item-index${index}`}
            onAddStudent={student => onAddOrRemoveStudentHandler(student, handler.getListUsersGradeHandler)}
            onRemoveStudent={student => onAddOrRemoveStudentHandler(student, handler.getListUsersGradeHandler)}
          />
        )}
        filter1={{
          defaultValue: data.selectedClass,
          options: data.optionsClass,
          placeholder: 'Filter kelas...',
          onChange: (choice) => {
            set.setSelectedClass(choice)
            if (!choice) {
              dispatch.dispatchListUsersGrade({
                type: 'set_data',
                payload: [],
              })
            }
          },
          isClearable: true,
        }}
      />
    </BaseReactBootstrapModal>
  )
}

/**
 * List Selected Students Header
 * 
 * @param {{
 *  onClickAddStudentHandler: () => void
 * }} props
*/
export const BaseListSelectedStudentsHeader = ({ 
  onClickAddStudentHandler, 
}) => {
  return (
    <div className='d-flex align-items-center justify-content-between mb-2' style={{ columnGap: 5 }}>
      <div>
        <BaseButton onClick={onClickAddStudentHandler}>
          <i className="feather icon-plus-circle mr-1" />
          Tambah Siswa
        </BaseButton>
      </div>
    </div>
  )
}

/**
 * Base Modal Selected Student Item
 * 
 * @param {ListStudentItemProps} props
*/
export const ListStudentItemProps = ({ item, isAdded, onAddStudent, onRemoveStudent }) => {
  return (
    <tr>
      <td>{item.nim}</td>
      <td>
        <div className="d-inline-block align-middle">
          <img
            src={`${item?.linkAvatar}`}
            alt={item.name}
            className="img-radius align-top m-r-15"
            style={{ width: '40px', height: '40px' }}
            onError={(event) => {
              event.currentTarget.src = avatarDefaultUser
            }}
          />
          <div className="d-inline-block">
            <h6 className="m-b-0 mt-2">{item.name}</h6>
          </div>
        </div>
      </td>
      <td>
        {isAdded
        ?
          <BaseButton 
            props={{
              className: 'btn-sm btn-danger'
            }}
            onClick={() => onRemoveStudent(item)}
          >
            <i className="fas fa-minus" />
          </BaseButton>
        :
          <BaseButton 
            props={{
              className: 'btn-sm btn-success'
            }}
            onClick={() => onAddStudent(item)}
          >
            <i className="fas fa-plus" />
          </BaseButton>
        }
      </td>
    </tr>
  )
}
function detect(userAgent) {
  const isAndroid = Boolean(userAgent.match(/Android/i));
  const isIos = Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = Boolean(userAgent.match(/IEMobile/i));
  const isSSR = Boolean(userAgent.match(/SSR/i));
  const isMobile = isAndroid || isIos || isOpera || isWindows;
  const isDesktop = !isMobile && !isSSR;

  return {
    isOpera,
    isWindows,
    isSSR,
    isMobile,
    isDesktop
  };
}
const newDeviceDetector = () => {
  const cache = {};
  return {
    detect: (userAgent) => {
      if (cache[userAgent]) return cache[userAgent];
      const result = detect(userAgent);
      cache[userAgent] = result;
      return result;
    }
  };
};
const deviceDetector = newDeviceDetector();
const useDeviceDetect = () => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  return deviceDetector.detect(userAgent);
};

export default useDeviceDetect
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import ModalMetaKelola from 'component/modal/meta-kelola'
import useGetData from 'hooks/useGetData'
import DataTableWithFilter from 'component/data-table/indexWithFilter'

const SurveyEngine = () => {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, deleteRequest } = useBaseContext()
  const { subMapel } = useGetData({ getListSubMapel: true })

  /* ======================================= Local States ======================================= */
  const [surveyList, setSurveyList] = useState([])
  const [surveyLength, setSurveyLength] = useState(0)
  const [surveySelected, setSurveySelected] = useState(null)
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [search, setSearch] = useState('')
  const [subMapelReactSelectOptions, setSubMapelReactSelectOptions] = useState([])
  const [selectedSubMapel, setSelectedSubMapel] = useState({})


  /* ========================================= Functions ======================================== */
  async function getSurveyList() {
    const filterSubMapel = selectedSubMapel?.value ? `&class_id=${selectedSubMapel?.value}` : ''

    const response = await getRequest(
      `survey?limit=${limit}&offset=${offset}&search=${search}${filterSubMapel}`,
    )
    if (response) {
      setSurveyList(response.data)
      setSurveyLength(response.totalRecords)
    } else {
      console.error('ERROR', response)
    }
  }

  function handleHasilSurvey(surveyData) {
    history.push(`/survey-engine/survey-result/${surveyData.id}`, {
      existingData: surveyData,
    })
  }

  function handleAddSurvey() {
    history.push('/survey-engine/add')
  }

  function handleEditSurvey(surveyData) {
    history.push(`/survey-engine/edit/${surveyData.id}`, {
      existingData: surveyData,
    })
  }

  function handleDetailSurvey(surveyData) {
    history.push(`/survey-engine/${surveyData.id}`, {
      existingData: surveyData,
    })
  }

  function handleDeleteSurvey(surveyId, surveyTitle) {
    Swal.fire({
      title: 'Apa anda yakin?',
      text: `Anda akan menghapus ${surveyTitle}!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`survey?id=${surveyId}`)
        if (response) {
          Swal.fire({
            icon: 'success',
            text: 'Survey berhasil dihapus.',
          }).then(() => history.go(0))
        } else {
          Swal.fire(
            'Oppsss!',
            'Terjadi kesalahan coba beberapa saat lagi.',
            'error',
          )
        }
      }
    })
  }

  const manipulatedListSubMapelReactSelectOptionsHandler = () => {
    const data = subMapel.data.map(s => ({ label: s?.title, value: s?.id }))

    setSubMapelReactSelectOptions(data);
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getSurveyList()
  }, [limit, offset, search, selectedSubMapel])

  useEffect(() => {
    if (!subMapel.loading && subMapel.data.length && !subMapelReactSelectOptions.length) {
      manipulatedListSubMapelReactSelectOptionsHandler()
    }
  }, [subMapel])

  /* ======================================== Components ======================================== */
  const RenderItem = ({
    item,
    handleEditSurvey,
    handleDetailSurvey,
    handleDeleteSurvey,
  }) => {
    return (
      <tr>
        <td>{item.title ? item.title : ''}</td>
        <td>
          {item.active ? (
            <span className="badge badge-success">Aktif</span>
          ) : (
            <span className="badge badge-secondary">Tidak Aktif</span>
          )}
        </td>
        <td>
          <button
            onClick={() => setSurveySelected(item)}
            type="button"
            className="btn btn-sm mr-2 btn-dark"
            data-toggle="modal"
            data-target="#kelola-meta-modal"
          >
            <i className="feather icon-info mr-1" />
            Info
          </button>
          <button
            onClick={() => handleEditSurvey(item)}
            type="button"
            className="btn btn-sm btn-success mr-2"
            data-toggle="modal"
            data-target="#quiz-edit-modal"
          >
            <i className="feather icon-edit mr-1" />
            <span>Edit</span>
          </button>
          <button
            onClick={() => handleHasilSurvey(item)}
            type="button"
            className="btn btn-sm btn-warning mr-2"
          >
            <i className="fas fa-chart-bar mr-1" />
            <span>Hasil</span>
          </button>
          <button
            onClick={() => handleDetailSurvey(item)}
            type="button"
            className="btn btn-sm btn-info mr-2"
          >
            <i className="fas fa-list-ol mr-1" />
            <span>Pertanyaan</span>
          </button>
          <button
            onClick={() => handleDeleteSurvey(item.id, item.title)}
            type="button"
            className="btn btn-sm btn-danger"
          >
            <i className="feather icon-trash-2 mr-1" />
            <span>Hapus</span>
          </button>
        </td>
      </tr>
    )
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Kelola Survey" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list  ">
              <div className="card-header">
                <div className="row">
                  <div className="col">
                    <button
                      onClick={() => handleAddSurvey()}
                      type="button"
                      className="btn btn-primary"
                    >
                      <i className="feather icon-plus-circle mr-1" />
                      <span>Tambah Survey</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {/* Data table list survey */}
                <div className="row">
                  <div className="col dt-responsive table-responsive">
                    <DataTableWithFilter
                      className={'table table-striped table-bordered nowrap'}
                      data={surveyList}
                      totalRows={surveyLength}
                      show={[10, 20, 30, 40, 50]}
                      onChangeSearch={(val) => {
                        setSearch(val)
                      }}
                      onChangeLimit={(val) => {
                        setLimit(val)
                      }}
                      onChangePage={(val) => {
                        setOffset(val)
                      }}
                      column={['Survey', 'Status', 'Actions']}
                      renderItem={(dataSurvey) => (
                        <RenderItem
                          item={dataSurvey}
                          key={dataSurvey.id}
                          handleEditSurvey={handleEditSurvey}
                          handleDetailSurvey={handleDetailSurvey}
                          handleDeleteSurvey={handleDeleteSurvey}
                        />
                      )}
                      filter1={{
                        options: subMapelReactSelectOptions,
                        placeholder: 'Filter submapel...',
                        onChange: (choice) => setSelectedSubMapel(choice),
                        isClearable: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalMetaKelola
          type='survey'
          title={surveySelected?.title}
          id={surveySelected?.id}
        />
      </Container>
    </TemplateAdmin>
  )
}

export default SurveyEngine

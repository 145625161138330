import React, { useEffect, useState } from "react";

import LoadingSpinner from "component/loading";
import NotFound from "component/not-found";
import BaseTableSemester from "pages/scores/tabContent/tabContentLedger/BaseTableSemester";
import { useBaseContext } from "context/base";

export default function Semester4({ semester, ta_tahun_code }) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  const [isLoading, setIsLoading] = useState(false)

  const [data, setData] = useState([])
  const [listMapel, setListMapel] = useState([])

  const getData = async () => {
    setIsLoading(true)

    try {
      const response = await getRequest(
        `ledger-semester?ta_tahun_code=${ta_tahun_code}&ta_semester_code=${semester?.code}`,
      )
      if (response) {
        const data = response?.data
        const mapel = response?.mapel

        if (Array.isArray(data) && !!data.length) {
          setData(data)
        }

        if (Array.isArray(mapel) && !!mapel.length) {
          const getListMapelName = mapel.map(m => m?.title)
          setListMapel(getListMapelName)
        }
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (Array.isArray(data) && !data.length && semester) {
      getData()
    }
  }, [semester])

  return (
    <>
      {isLoading
      ?
      <LoadingSpinner />
      : !isLoading && Array.isArray(data) && !!data.length
      ?
      <BaseTableSemester semesterName={4} data={data} listMapel={listMapel} />
      :
      <NotFound
        text="Data Tidak Ditemukan"
        icon="fas fa-question"
      />
      }
    </>
  )
}
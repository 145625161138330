import React, { useEffect, useState } from 'react'
import { useBaseContext } from 'context/base'
import loadingTypes from 'globals/loading-types'

export default function ProfileUser({ userId }) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= Constant ========================================= */
  const initUser = {
    name: '',
    nim: '',
  }

  /* ======================================= Local States ======================================= */
  const [loadingUser, setLoadingUser] = useState(loadingTypes.idle)
  const [user, setUser]               = useState(initUser)

  /* ========================================= Functions ======================================== */
  const getProfile = async (userId) => {
    const response = await getRequest(`profile-user?user_id=${userId}`)
    return response
      ? { name: response.data.name, nim: response.data.nim }
      : null
  }

  const init = async (userId, initUser) => {
    const userProfile = await getProfile(userId)
    setUser(userProfile === null ? initUser : userProfile)
    setLoadingUser(
      userProfile === null ? loadingTypes.error : loadingTypes.success,
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (userId) init(userId, initUser)
  }, [userId])

  /* ========================================== Output ========================================== */
  return (
    <div>
      <h5 className="mb-0">{user.name}</h5>
      <p className="text-secondary">No. Induk: {user.nim}</p>
      <hr />
    </div>
  )
}

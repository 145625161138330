import React, { useState, useEffect } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import BreadCrumb from 'component/breadcrumb'
import { Back } from 'component/button'
import LoadingSpinner from 'component/loading'
import Question from './component-quiz-answer-siswa/component'
import PreviewQuizAnswerSiswaAlert from './partials/preview-quiz-answer-siswa-alert'
import { formatDate } from 'util/date'

export default function PreviewQuizAnswerSiswa() {
  /* ====================================== Consume Context ====================================== */
  const { getRequest, putRequest } = useBaseContext()

  /* ========================================== Helpers ========================================== */
  const history  = useHistory()
  const params   = useParams()
  const location = useLocation()

  /* ========================================= Constants ========================================= */
  const dataSiswa = location.state?.existingData || {}
  const content = location.state?.content || {}
  const links = [
    { url: '/kelola-quiz', name: 'Kelola Quiz' },
    {
      url: `/kelola-quiz/preview-quiz/${params.quizId}`,
      name: 'Daftar Quiz Siswa',
      state: { existingData: location.state?.quizData },
    },
  ]

  const isGivingFeedback = content.feed?.feed_temps.find(
    (ft) =>
      ft.user_id === dataSiswa.id && ft.content_id === dataSiswa.content_id,
  )

  /* =========================================== States ========================================== */
  const [loadingData, setLoadingData]                   = useState(false)
  const [quizDetail, setQuizDetail]                     = useState(null)
  const [questionsAndAnswer, setQuestionsAndAnswer]     = useState([])
  const [loadingSubmit, setLoadingSubmit]               = useState(false)
  const [essayAndGraphorTemps, setEssayAndGraphorTemps] = useState([])
  const [isGrupKecilPassed, setIsGrupKecilPassed]       = useState(null)
  const [pickedMatch, setPickedMatch]                   = useState(null)
  const [grade, setGrade] = useState(false)

  /* ========================================= Functions ========================================= */
  async function getQuestions() {
    setLoadingData(true)
    if (dataSiswa.content_id) {
      const response = await getRequest(
        `quiz-detail-user?id=${params.siswaId}&quiz_id=${params.quizId}&content_id_teacher=${dataSiswa.content_id}`,
      )
      if (response) {
        setQuizDetail(response.data)
        setGrade(response?.content_grade)
        setLoadingData(false)
      } else {
        setLoadingData(false)
      }
    }
  }

  function initEssayAndGraphorTemps() {
    const array = quizDetail?.questions
      ?.filter(
        (questionItem) =>
          questionItem.quest_type === 1 || questionItem.quest_type === 2,
      )
      ?.map((filtered) => {
        return {
          question_id: filtered.id,
          score:
            filtered.quiz_temps.length > 0
              ? filtered.quiz_temps[0].score
              : null,
          note:
            filtered.quiz_temps.length > 0 ? filtered.quiz_temps[0].note : null,
          bobot_nilai:
            filtered.answers.length > 0 ? filtered.answers[0].nilai : null,
        }
      })
    setEssayAndGraphorTemps(array)
  }

  function initQuestions() {
    const array = quizDetail?.questions?.map((item) => {
      const correctAnswer = item.answers.find((answer) => answer.correct === 1)
      const answerSiswa =
        pickedMatch !== null
          ? item.answers.find(
              (answer) =>
                answer.id === item.quiz_match_answers[pickedMatch].answer_id,
            )
          : item.quiz_temps.length > 0
          ? item.answers.find(
              (answer) => answer.id === item.quiz_temps[0].answer_id,
            )
          : { text: 'Tidak menjawab', correct: 0 }

      return {
        id_pertanyaan: item.id,
        urutan_pertanyaan: item.urutan,
        pertanyaan: item.text,
        type_pertanyaan: item.quest_type,
        pembahasan_soal: item.pembahasan,
        jawaban_benar: correctAnswer?.text,
        jawaban_pg_siswa: item.quest_type === 0 ? answerSiswa?.text : '',
        correct_siswa: item.quest_type === 0 ? answerSiswa?.correct : 0,
        jawaban_essay_siswa:
          pickedMatch !== null
            ? item.quiz_match_answers[pickedMatch]?.answer_text
            : item.quiz_temps.length > 0
            ? item.quiz_temps[0].answer_text
            : null,
        score:
          pickedMatch !== null
            ? item.quiz_match_answers[pickedMatch]?.score
            : item.quiz_temps.length > 0
            ? item.quiz_temps[0].score
            : null,
        bobot_nilai: correctAnswer?.nilai,
        note:
          pickedMatch !== null
            ? item.quiz_match_answers[pickedMatch]?.note
            : item.quiz_temps.length > 0
            ? item.quiz_temps[0].note
            : null,
        content_id: quizDetail.content_id,
        graphor_type: item.graphor_type,
        value_id: item.value_id,
        quiz_match_answers: item.quiz_match_answers,
        answers: item.answers,
      }
    })

    setQuestionsAndAnswer(array)
  }

  function handleChangeJawabanBenarEssay(event, questionData) {
    const updateCorrectAnswer = essayAndGraphorTemps.map((item) => {
      if (questionData.id_pertanyaan == item.question_id) {
        return {
          question_id: item.question_id,
          score: item.score,
          note: event.target.value,
          bobot_nilai: item.bobot_nilai,
        }
      } else {
        return item
      }
    })
    const newData = questionsAndAnswer?.map((item) => {
      if (questionData.id_pertanyaan == item.id_pertanyaan) {
        return {
          ...item,
          correct_siswa: item.correct_siswa,
          id_pertanyaan: item.id_pertanyaan,
          jawaban_benar: item.jawaban_benar,
          jawaban_essay_siswa: item.jawaban_essay_siswa,
          jawaban_pg_siswa: item.jawaban_pg_siswa,
          note: event.target.value,
          pertanyaan: item.pertanyaan,
          score: item.score,
          type_pertanyaan: item.type_pertanyaan,
          urutan_pertanyaan: item.urutan_pertanyaan,
          pembahasan_soal: item.pembahasan_soal,
        }
      } else {
        return item
      }
    })
    setEssayAndGraphorTemps(updateCorrectAnswer)
    setQuestionsAndAnswer(newData)
  }

  function handleChangeScore(event, questionData) {
    const updateScore = essayAndGraphorTemps.map((item) => {
      if (questionData.id_pertanyaan == item.question_id) {
        const newScore = Math.floor(Math.abs(event.target.value))
        return {
          question_id: item.question_id,
          score: newScore > item.bobot_nilai ? item.bobot_nilai : newScore,
          note: item.note,
          bobot_nilai: item.bobot_nilai,
        }
      } else {
        return item
      }
    })
    const newData = questionsAndAnswer?.map((item) => {
      if (questionData.id_pertanyaan == item.id_pertanyaan) {
        return {
          ...item,
          correct_siswa: item.correct_siswa,
          id_pertanyaan: item.id_pertanyaan,
          jawaban_benar: item.jawaban_benar,
          jawaban_essay_siswa: item.jawaban_essay_siswa,
          jawaban_pg_siswa: item.jawaban_pg_siswa,
          note: item.note,
          pertanyaan: item.pertanyaan,
          score: event.target.value,
          type_pertanyaan: item.type_pertanyaan,
          urutan_pertanyaan: item.urutan_pertanyaan,
          pembahasan_soal: item.pembahasan_soal,
        }
      } else {
        return item
      }
    })
    setEssayAndGraphorTemps(updateScore)
    setQuestionsAndAnswer(newData)
  }


  async function submitCorrectAnswerAndScore() {
    function goBack() {
      history.push(location.state.content.listSiswaPath, location.state);
    }


    setLoadingSubmit(true)
    if (essayAndGraphorTemps.length) {
      let form = new FormData()
      form.append('id', params.siswaId)
      form.append('quiz_id', params.quizId)
      form.append('content_id', dataSiswa.content_id)
      form.append('note', JSON.stringify(essayAndGraphorTemps))
      const response = await putRequest('note-quiz', form)
      setLoadingSubmit(false)
      if (response) {
        const swalInput = {
          icon: 'error',
          text: `Quiz otomatis tertolak karena nilainya ${response.nilai}`,
          confirmButtonText: 'Oke!',
        }
        window.notification('Nilai', 'berhasil disimpan', 'success')
        if (response.is_remedial) {
          const result = await Swal.fire(swalInput)
          if (result.isConfirmed) {
            goBack();
          }
        } else {
          goBack();
        }
      }
    } else {
      setLoadingSubmit(false)
      goBack();
    }
  }

  async function getGrupKecilStatus() {
    const isGrupKecilRequired = content.group_status
    if (!isGrupKecilRequired) {
      setIsGrupKecilPassed(true)
    } else {
      const fetchForumGroupTeacher = await getRequest(
        'forum-group-teacher?content_id=' + content.id,
      )
      const participantsId =
        [
          ...new Set(
            fetchForumGroupTeacher.data?.reduce((arr, next) => {
              next.forums &&
                next.forums.forEach((_next) => {
                  _next.forums &&
                    _next.forums.forEach((__next) => {
                      arr.push(__next.login.user_id)
                    })
                  arr.push(_next.login.user_id)
                })
              return arr
            }, []),
          ),
        ] || []

      const isParticipated = participantsId.some(
        (uid) => uid === params.siswaId,
      )
      setIsGrupKecilPassed(isParticipated)
    }
  }

  /* ========================================== Effects ========================================== */
  useEffect(() => {
    getQuestions()
    getGrupKecilStatus()
  }, [])

  useEffect(() => {
    if (quizDetail) {
      initQuestions()
      initEssayAndGraphorTemps()
    }
  }, [quizDetail, pickedMatch])

  const initSelectedGroup = () => {
    let selectedGroup = {
      content_id: content?.id,
    }

    if (content?.selectedStudent) {
      if (content?.selectedStudent?.selectedGroup) {
        selectedGroup = {
          ...selectedGroup,
          ...content?.selectedStudent?.selectedGroup,
        }
      } else {
        selectedGroup = {
          ...selectedGroup,
          value: null,
          label: 'Pilih Kelas',
        }
      }

      localStorage.setItem("periksa-quiz-pilih-group", JSON.stringify(selectedGroup))
    }
  }

  useEffect(() => {
    initSelectedGroup()
  }, [content])
  /* ========================================= Components ======================================== */
  const MatchInfo = () => {
    if (pickedMatch === null) return null

    const quizHistory = quizDetail?.quiz_users_history[pickedMatch]

    return (
      <>
        <p className="f-w-500 mb-0">
          Nilai: {quizHistory?.total_score}{' '}
          <small className="text-muted">(Match #{pickedMatch + 1})</small>
        </p>
        <p className="f-w-500 mb-0">
          Catatan:{' '}
          {quizHistory?.note || (
            <small className="text-muted">(Tidak ada catatan)</small>
          )}
        </p>
        <p className="f-w-500 mb-0">
          Tanggal Tolak:{' '}
          {quizHistory?.tanggal_tolak &&
            formatDate(quizHistory.tanggal_tolak, false)}
        </p>
      </>
    )
  }

  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName={`Periksa Quiz: ${dataSiswa?.name || ''}`}
          linkName="Siswa"
          links={links}
        />

        <div className="row">
          <div className="col">
            <div className="card user-profile-list">
              <div className="card-header">
                <span className="d-flex justify-content-between">
                  <span>
                    <p className="f-w-700 f-16 mb-0">
                      {dataSiswa ? dataSiswa.name : '-'}
                    </p>
                    <p className="f-w-400 f-14 text-muted">
                      {'No. Induk: '}
                      {dataSiswa ? dataSiswa.nim : 'tidak terdaftar'}
                    </p>
                  </span>
                  <ul className="nav nav-tabs">
                    {quizDetail?.quiz_users_history.map((_, history_index) => {
                      const isActive =
                        pickedMatch === history_index ? 'active' : ''

                      return (
                        <li className="nav-item nav-pills">
                          <a
                            className={`nav-link ${isActive}`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => setPickedMatch(history_index)}
                          >
                            Match #{history_index + 1}{' '}
                            <small className="text-muted">(Remidi)</small>
                          </a>
                        </li>
                      )
                    })}

                    <li className="nav-item nav-pills">
                      <a
                        className={`nav-link ${
                          pickedMatch === null && 'active'
                        }`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => setPickedMatch(null)}
                      >
                        Final
                      </a>
                    </li>
                  </ul>
                </span>

                {dataSiswa && dataSiswa.content_title && (
                  <p className="f-w-400 f-14 mb-0">
                    Materi Quiz: {dataSiswa.content_title}
                  </p>
                )}
                {dataSiswa && dataSiswa.class_title && dataSiswa.class_id && (
                  <p className="f-w-400 f-14 mb-0">
                    <span>Sub Mata Pelajaran: </span>
                    <a href={`/kelas/${dataSiswa.class_id}`}>
                      {dataSiswa.class_title}
                    </a>
                  </p>
                )}
                {pickedMatch === null && dataSiswa && dataSiswa.nilai ? (
                  <p className="f-w-500 mb-0">Nilai: {dataSiswa.nilai}</p>
                ) : null}
                <MatchInfo />
                {grade && grade?.id && (
                  <p className="f-w-400 f-14 mb-0">
                    <span key={`list-grade-student${grade?.id}`} className="badge badge-primary" style={{ fontSize: '.8rem' }}>Grade {grade?.name}</span>
                  </p>
                )}
              </div>
              <div className="card-body">
                {loadingData ? (
                  <div className="d-flex flex-column justify-content-center">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <>
                    {questionsAndAnswer?.length ? (
                      <>
                        {questionsAndAnswer?.map((question, idx) => (
                          <Question
                            item={question}
                            index={idx}
                            key={idx}
                            handleChangeJawabanBenarEssay={
                              handleChangeJawabanBenarEssay
                            }
                            handleChangeScore={handleChangeScore}
                          />
                        ))}
                      </>
                    ) : (
                      <div className="d-flex flex-column justify-content-center">
                        <i className="feather icon-file-text f-80 text-muted text-center" />
                        <span className="f-14 f-w-500 text-center text-muted">
                          Siswa belum mengerjakan
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="card-footer">
                <PreviewQuizAnswerSiswaAlert
                  isGrupKecilPassed={isGrupKecilPassed}
                />
                <Back />
                {questionsAndAnswer?.length && (
                  <>
                    {loadingSubmit ? (
                      <button
                        disabled
                        className="btn btn-success mr-1"
                      >
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                        />
                        Simpan
                      </button>
                    ) : (
                      <button
                        // disabled={
                        //   !isGrupKecilPassed ||
                        //   (content.feedback && !isGivingFeedback)
                        // }
                        onClick={() => submitCorrectAnswerAndScore()}
                        className="btn btn-success mr-1"
                      >
                        Simpan
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

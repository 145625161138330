import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { useBaseContext } from 'context/base'
import FloatingContainerTop from 'component/floating-container/floating-container-top'
import FloatingFeedbackAlert from 'component/floating-feedback-alert'
import Navbar from './Navbar'
import Header from './Header'
import { defaultTitle } from 'util/constant'

export default function TemplateAdmin({ children }) {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { login, getRequest } = useBaseContext()

  /* =================================== Consume Local Storage ================================== */
  const roleActive = JSON.parse(localStorage.getItem('role_active'))

  /* ======================================= Local States ======================================= */
  const [totalNotifAssigment, setTotalNotifAssigment] = useState(0)

  /* ========================================= Functions ======================================== */
  async function cekStatusLogin() {
    let login = localStorage.getItem('expired')
    if (!login) {
      history.replace('/login?logout')
      window.location.reload()
    }
  }

  const notifAssigment = async () => {
    let res = await getRequest('check-assignment-null')
    if (res) {
      setTotalNotifAssigment(res.data.length)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (roleActive.role_type === 'siswa') {
      notifAssigment()
    }
  }, [])

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      cekStatusLogin()
    }

    return () => (isSubscribed = false)
  }, [login])

  /* ========================================== Output ========================================== */
  return (
    <div>
      <Helmet>
        <title>{defaultTitle}</title>
        <meta name="description" content="Program Genzy Layanan baru sekolah jenjang SMA yang mengusung konsep Smart Learning High School. Menggunakan sistem blended learning dan bantuan LMS (Learning Management System) berupa aplikasi belajar yang diberi nama PINTAR (Pedagogical Intelligence Architecture) APA ITU SMART LEARNING HIGH SCHOOL" />
        <meta name="author" content="pintaredu.id" />
        <body className="background-blue" />
      </Helmet>
      <div className="loader-bg">
        <div className="loader-track">
          <div className="loader-fill" />
        </div>
      </div>

      <Navbar />
      <Header />

      {children}

      <FloatingContainerTop>
        {roleActive.role_type === 'siswa' ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <FloatingFeedbackAlert />
            {totalNotifAssigment > 0 && (
              <a
                className="alert alert-danger shake-animation fade show shadow"
                role="alert"
                href="/upload-assignment"
              >
                <i className="fas fa-exclamation-circle mr-1" />
                <strong>Gawat!</strong> ada tugas kamu yang bermasalah, silahkan
                klik disini untuk mengupload ulang tugas, terima kasih.
              </a>
            )}
          </div>
        ) : null}
      </FloatingContainerTop>
    </div>
  )
}

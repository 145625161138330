import LoadingSpinnerV2 from 'component/loadingV2';
import useGetRoleActive from 'hooks/useGetRoleActive'
import React from 'react'

/**
 * Base Button
 * 
 * @param {{
 *  onChange: function,
 *  isChecked: boolean,
 *  access: Array,
 *  props: import('react').InputHTMLAttributes,
 *  isDisabled: boolean,
 *  isLoading: boolean,
 *  isShow: boolean,
 *  sizeLoading: number,
 *  className: string,
 * }} props
 */
const CheckboxBase = ({ onChange = () => { }, isChecked, access = [], isDisabled = false, isLoading = false, isShow = true, props, className = '' }) => {
  const roleActive = useGetRoleActive();

  const isNotAccess = access.length ? !access.includes(roleActive.type) : false

  if (isShow) {
    return (
      <input
        {...props}
        type={'checkbox'}
        onChange={onChange}
        checked={isChecked}
        className={className}
        disabled={isNotAccess || isDisabled || isLoading}
      />
    )
  } else {
    return null;
  }
}

export default CheckboxBase

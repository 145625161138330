import BaseReactBootstrapModal from "component/modal/BaseReactBootstrapModal";
import { useMemo, useState } from "react";
import BaseButton from "component/button/BaseButton";

/**
 * 
 * @param {{
 *  list?: Array<import("react").ReactNode>
 *  children?: import("react").ReactNode
 * }} props 
 * @returns 
 */
export default function BaseButtonInformationModal(props) {
  const [isShowModalKeterangan, setIsShowModalKeterangan] = useState(false)

  /**
   * Toggle Modal Keterangan
   * 
   * @param {boolean} condition 
   */
  const toggleModalKeterangan = condition => setIsShowModalKeterangan(condition)

  const renderHeaderTitle = useMemo(() => (
    <div>
      <h5><span className="text-danger">*</span> Keterangan:</h5>
    </div>
  ), [])

  return (
    <div>
      <BaseButton
        props={{
          className: 'rounded-circle btn-outline-primary',
          style: {
            padding: '0.25rem 0.8rem'
          }
        }}
        variant="transparent"
        onClick={() => toggleModalKeterangan(true)}
      >
        <i className={'fas fa-info'} style={{ fontSize: 11 }}></i>
      </BaseButton>
      <BaseReactBootstrapModal show={isShowModalKeterangan} enforceFocus={false} 
        headerTitle={renderHeaderTitle} isWithFooter={true} closeTitle='Tutup' handleClose={() => toggleModalKeterangan(false)} isWithOkButton={false}
        centered
        isWithXButton={false}
      >
        {props?.children
        ?
          props.children
        : props.list.map((item, index) => (
          <div key={`list-keterangan-item-index${index}`} className="mb-2">{item}</div>
        ))}
      </BaseReactBootstrapModal>
    </div>
  )
}
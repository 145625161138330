import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import InvalidWarning from 'component/invalid/warning'
import { editorConfiguration } from 'util/ckeditor-config'

const AssignmentText = ({ text, setText, isTeksInvalid }) => {
  return (
    <>
      <CKEditor
        editor={Editor}
        data={text}
        config={editorConfiguration}
        onChange={(_, editor) => {
          const data = editor.getData()
          setText(data)
        }}
      />
      {isTeksInvalid ? (
        <InvalidWarning text="Tugas tidak boleh kosong" />
      ) : null}
    </>
  )
}

export default AssignmentText

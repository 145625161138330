import React from "react";

export const GraphorSortReadonlyAnswer = ({ list = [], correctList = [] }) => {
  return (
    <div className="">
      {list.length > 0 ?
        list.map((item, index) => {
            let fi = correctList.findIndex((v) => v == item.answer_text);
            let ico = `text-danger fas fa-times-circle mr-1`;
            if (fi == index) {
              ico = `text-success fas fa-check-circle mr-1`;
            }
            return (
              <div 
                key={index} 
                style={{ backgroundColor: "transparent" }}
                className={`border rounded shadow-sm p-3 d-flex align-items-center ${index > 0 ? "mt-2" : ""}`}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "lightyellow"}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}
              >
                <i className={ico} />
                <p className="f-w-700 m-0">{item.answer_text}</p>
              </div>
            );
          }
        )
        :
        null
      }
    </div>
  );
}

export const GraphorSortReadonlyCorrect = ({ correctList = [] }) => {
  return (
    <div className="">
      {correctList.length > 0 ?
        correctList.map((item, index) => {
          return (
            <div 
              key={index} 
              style={{ backgroundColor: "transparent" }}
              className={`border rounded shadow-sm p-3 d-flex align-items-center ${index > 0 ? "mt-2" : ""}`}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "lightyellow"}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}
            >
              <p className="f-w-700 m-0">{item}</p>
            </div>
          );
        })
        :
        null
      }
    </div>
  );
}
import React from 'react'
import useChatContext from 'context/chat'

export default function CancelButton({ handler }) {
  const { current } = useChatContext()
  return (
    <button
      disabled={current.loading.status}
      type="button"
      className="btn btn-danger btn-icon"
      title="Cancel Attach"
      onClick={handler}
    >
      <i className="fas fa-times" />
    </button>
  )
}

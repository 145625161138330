import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import InvalidText from 'component/invalid/text'
import TypeScoreForm from 'component/type-score-form'
import { editorConfiguration } from 'util/ckeditor-config'

const AddAssignment = () => {
  /* ========================================== Helpers ========================================= */
  const history  = useHistory()
  const params   = useParams()
  const location = useLocation()
  const assignmentId = params.assignmentId
  const existingAssignment = location.state.existingData

  /* ====================================== Consume Context ===================================== */
  const { getRequest, putRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [listTypeScore, setListTypeScore] = useState([])

  // FORM INPUT STATE
  const [title, setTitle]                         = useState(existingAssignment.title || "");
  const [assignmentCode, setAssignmentCode]       = useState(existingAssignment.code || "");
  const [status, setStatus]                       = useState(existingAssignment.active ? 1 : 0);
  const [replyType, setReplyType]                 = useState(existingAssignment.reply_type || "")
  const [text, setText]                           = useState(existingAssignment.text || "<p>Tulis detail tugas...</p>")
  const [typeScore, setTypeScore]                 = useState("")
  const [isTypeScoreActive, setIsTypeScoreActive] = useState(false)
  const [pembahasan, setPembahasan]               = useState(existingAssignment.pembahasan || "")

  // FORM VALIDATION STATE
  const [isTitleInvalid, setIsTitleInvalid]             = useState(false)
  const [isAssignCodeInvalid, setIsAssignCodeInvalid]   = useState(false)
  const [isAssignCodeTooShort, setIsAssignCodeTooShort] = useState(false)
  const [isAssignCodeSpecial, setIsAssignCodeSpecial]   = useState(false)
  const [isReplyTypeInvalid, setIsReplyTypeInvalid]     = useState(false)
  const [isTextInvalid, setIsTextInvalid]               = useState(false)

  /* =========================================== Refs =========================================== */
  const refTitle      = useRef()
  const refAssignCode = useRef()
  const refReplyType  = useRef()

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/kelola-assignment', name: 'Kelola Tugas' }]

  /* ========================================= Functions ======================================== */
  async function getTypeScore() {
    const response = await getRequest('type-score')
    if (response) {
      setListTypeScore(response.data)
      initTypeScore()
    }
  }

  function initTypeScore() {
    if (existingAssignment.type_score) {
      setIsTypeScoreActive(true)
      setTypeScore(existingAssignment.type_score.id)
    }
  }

  function handleChangeTitle(event) {
    setTitle(event.target.value)
  }

  function handleChangeStatus(event) {
    switch (event.target.checked) {
      case true:
        setStatus(1)
        break
      case false:
        setStatus(0)
        break
      default:
        break
    }
  }

  function handleChangeAssignmentCode(event) {
    setAssignmentCode(event.target.value.toUpperCase())
  }

  function handleChangeReplyType(event) {
    setReplyType(event.target.value)
  }

  function handleActiveTypeScore() {
    if (isTypeScoreActive) {
      setIsTypeScoreActive(false)
      setTypeScore('')
    } else {
      setIsTypeScoreActive(true)
    }
  }

  function handleChangeTypeScore(event) {
    setTypeScore(event.target.value)
  }

  function checkSpecialChars(string) {
    const specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    return specialChars.test(string) // return true when contains special chars
  }

  function handleFormValidation() {
    if (
      title &&
      assignmentCode.length === 5 &&
      checkSpecialChars(assignmentCode) === false &&
      replyType &&
      text
    ) {
      setIsTitleInvalid(false)
      setIsAssignCodeInvalid(false)
      setIsAssignCodeTooShort(false)
      setIsAssignCodeSpecial(false)
      setIsReplyTypeInvalid(false)
      setIsTextInvalid(false)
      
      handleFormSubmit(
        assignmentId,
        title,
        assignmentCode,
        status,
        replyType,
        text,
        typeScore,
        pembahasan,
      )
    } else {
      if (!assignmentCode) {
        setIsAssignCodeInvalid(true)
        refAssignCode.current.focus()
      } else {
        setIsAssignCodeInvalid(false)
      }

      if (assignmentCode.length !== 5) {
        setIsAssignCodeTooShort(true)
        refAssignCode.current.focus()
      } else {
        setIsAssignCodeTooShort(false)
      }

      if (checkSpecialChars(assignmentCode)) {
        setIsAssignCodeSpecial(true)
        refAssignCode.current.focus()
      } else {
        setIsAssignCodeSpecial(false)
      }

      if (!replyType) {
        setIsReplyTypeInvalid(true)
        refReplyType.current.focus()
      } else {
        setIsReplyTypeInvalid(false)
      }

      if (!text) {
        setIsTextInvalid(true)
      } else {
        setIsTextInvalid(false)
      }

      if (!title) {
        setIsTitleInvalid(true)
        refTitle.current.focus()
      } else {
        setIsTitleInvalid(false)
      }

      window.notification('Data belum lengkap', 'Mohon lengkapi data')
    }
  }

  async function handleFormSubmit(
    assignmentId,
    title,
    assignmentCode,
    active,
    replyType,
    text,
    typeScore,
    pembahasan,
  ) {
    let form = new FormData()
    form.append('id', assignmentId)
    form.append('title', title)
    form.append('text', text)
    form.append('content_id', 'e595e950-6010-4888-a665-efdd1a45e1b1')
    form.append('code', assignmentCode)
    form.append('active', active)
    form.append('reply_type', replyType)
    form.append('pembahasan', pembahasan)

    if (typeScore) {
      form.append('type_score', parseInt(typeScore))
    }

    const response = await putRequest('assignment', form)
    if (response) {
      window.notification('Sukses', 'Tugas berhasil diubah', 'success')
      history.goBack()
    } else {
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getTypeScore()

    return () => {
      setListTypeScore([])
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Edit Tugas" links={links} />
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <h5 className="modal-title">
                  <i className="fas fa-pen-alt mr-1" />
                  Informasi Tugas
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <form>
                      {/* Input Title */}
                      <div className="form-group mb-5 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="title-assignment"
                        >
                          <span className="mr-1">Nama Tugas</span>
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            value={title}
                            onChange={(event) => handleChangeTitle(event)}
                            ref={refTitle}
                            type="text"
                            className={`${
                              isTitleInvalid ? 'is-invalid' : ''
                            } form-control`}
                            id="title-assignment"
                            placeholder="Judul Tugas"
                          />
                          {isTitleInvalid ? (
                            <InvalidText name={'Nama'} />
                          ) : null}
                        </div>
                      </div>

                      {/* Select reply type */}
                      <div className="form-group row mb-5">
                        <label className="col-form-label col-sm-3">
                          Jenis Jawaban
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <div className="col-sm-9">
                          <select
                            value={replyType}
                            onChange={(event) => handleChangeReplyType(event)}
                            ref={refReplyType}
                            className={`${
                              isReplyTypeInvalid ? 'is-invalid' : ''
                            } form-control`}
                          >
                            <option value={''}>-- Jenis jawaban --</option>
                            <option value={'1'}>Teks</option>
                            <option value={'2'}>Audio</option>
                            <option value={'3'}>Video</option>
                            <option value={'4'}>Dokumen</option>
                            <option value={'5'}>Youtube</option>
                            <option value={'6'}>Url</option>
                          </select>
                          {isReplyTypeInvalid ? (
                            <span className="col small form-text text-danger">
                              Harap pilih jenis jawaban.
                            </span>
                          ) : null}
                        </div>
                      </div>

                      {/* Input text */}
                      <div className="form-group mb-5 row">
                        <label className="col-form-label col-sm-3">
                          <span className="mr-1">Detail Tugas</span>
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <CKEditor
                            editor={Editor}
                            data={text}
                            config={editorConfiguration}
                            onChange={(_, editor) => {
                              const data = editor.getData()
                              setText(data)
                            }}
                          />
                          {isTextInvalid ? (
                            <label className="col small form-text text-danger">
                              Detail tugas tidak boleh kosong.
                            </label>
                          ) : null}
                        </div>
                      </div>

                      {/* Input pembahasan */}
                      <div className="form-group mb-5 row">
                        <label className="col-form-label col-sm-3">
                          <span className="mr-1">Pembahasan Tugas</span>
                        </label>
                        <div className="col-sm-9">
                          <CKEditor
                            editor={Editor}
                            data={pembahasan}
                            config={editorConfiguration}
                            onChange={(_, editor) => {
                              const data = editor.getData()
                              setPembahasan(data)
                            }}
                          />
                        </div>
                      </div>

                      {/* Input Assignment Code */}
                      <div className="form-group mb-5 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="kode-survey"
                        >
                          <span className="mr-1">Kode Tugas</span>
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            value={assignmentCode}
                            maxLength={5}
                            onChange={(event) =>
                              handleChangeAssignmentCode(event)
                            }
                            ref={refAssignCode}
                            type="text"
                            className={`${
                              isAssignCodeInvalid ? 'is-invalid' : ''
                            } form-control`}
                            id="kode-survey"
                            placeholder="5 karakter kombinasi huruf/angka"
                            disabled
                          />
                          {isAssignCodeInvalid ? (
                            <InvalidText name={'Kode tugas'} />
                          ) : null}
                          {isAssignCodeTooShort ? (
                            <span className="col small form-text text-danger">
                              Kode tugas minimal 5 karakter.
                            </span>
                          ) : null}
                          {isAssignCodeSpecial ? (
                            <span className="col small form-text text-danger">
                              Kode tugas hanya berisi kombinasi huruf/angka.
                            </span>
                          ) : null}
                        </div>
                      </div>

                      {/* Select type score */}
                      <TypeScoreForm
                        listTypeScore={listTypeScore}
                        typeScore={typeScore}
                        isTypeScoreActive={isTypeScoreActive}
                        handleActiveTypeScore={handleActiveTypeScore}
                        handleChangeTypeScore={handleChangeTypeScore}
                      />

                      {/* Select Status */}
                      <div className="form-group mb-3 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="status-survey"
                        >
                          <span className="mr-1">Status</span>
                        </label>
                        <div className="form-group col-sm-9">
                          <div className="switch switch-primary d-inline m-r-10">
                            <input
                              onChange={(event) => handleChangeStatus(event)}
                              checked={status === 1}
                              type="checkbox"
                              id="switch-status-survey"
                            />
                            <label
                              htmlFor="switch-status-survey"
                              className="cr"
                            ></label>
                          </div>
                          <label>
                            {status === 1 ? 'Aktif' : 'Tidak Aktif'}
                          </label>
                        </div>
                      </div>

                      {/* Form keterangan */}
                      <div className="form-group d-flex">
                        <label className="text-muted mr-2">Keterangan:</label>
                        <span className="text-danger mr-1">*</span>
                        <label className="text-muted">Wajib diisi</label>
                      </div>

                      {/* Form button */}
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-outline-danger mr-2"
                          onClick={() => history.goBack()}
                        >
                          Kembali
                        </button>
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => handleFormValidation()}
                        >
                          Simpan
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

export default AddAssignment

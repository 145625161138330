import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { editorConfiguration } from 'util/ckeditor-config'

export default function AddKindness() {
  /* ========================================== Helpers ========================================= */
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  let history = useHistory()

  /* ====================================== Consume Context ===================================== */
  let { postRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [description, setDescription] = useState('')
  const [isLoading, setIsLoading]     = useState(false)

  /* ========================================= Functions ======================================== */
  const onSubmit = async (data) => {
    setIsLoading(true)
    data.description = description
    data.status = data.status == 'true'
    const response = await postRequest(
      'kindness-create',
      data,
      true,
      'Berhasil',
      'menyimpan data',
    )
    if (response) {
      reset()
      setIsLoading(false)
      history.push('/kindness-program')
    }
    setIsLoading(false)
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Add Kindness Program"
          linkName="Mata Pelajaran / Kindness Program"
        />

        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header">
                <h5>Tambah Kindness Program</h5>
              </div>
              <div className="card-body">
                <form
                  className="ml-1 mr-2"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Judul<span className="text-danger ml-2">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.title && `is-invalid`
                        }`}
                        name="title"
                        placeholder="Title Kindness program"
                        {...register('title', {
                          required: {
                            value: true,
                            message: 'Judul kindness program wajib diisi',
                          },
                        })}
                      />
                      {errors.title && (
                        <div
                          id="invalidCheck3Feedback"
                          className="invalid-feedback"
                        >
                          {errors.title.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Deskripsi</label>
                    <div className="col-md-9">
                      <CKEditor
                        config={editorConfiguration}
                        editor={Editor}
                        onChange={(_, editor) => {
                          const data = editor.getData()
                          setDescription(data)
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Tipe<span className="text-danger ml-2">*</span>
                    </label>
                    <div className="col-md-9">
                      <select
                        className={`form-control ${
                          errors.type && 'is-invalid'
                        }`}
                        {...register('type', {
                          required: {
                            value: true,
                            message: 'Tipe kindness program wajib diisi',
                          },
                        })}
                      >
                        <option
                          disabled
                          selected
                          value={''}
                        >
                          - Pilih tipe -
                        </option>
                        <option value={'video'}>Video</option>
                        <option value={'image'}>Image</option>
                        <option value={'text'}>Text</option>
                      </select>
                      {errors.type && (
                        <div
                          id="invalidCheck3Feedback"
                          className="invalid-feedback"
                        >
                          {errors.type.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Status<span className="text-danger ml-2">*</span>
                    </label>
                    <div className="col-md-9">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="status"
                          id="flexRadioDefault1"
                          value={true}
                          checked
                          {...register('status')}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Aktif
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="status"
                          id="flexRadioDefault2"
                          value={false}
                          {...register('status')}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          Tidak Aktif
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-outline-danger mr-2"
                      type="button"
                      onClick={() => history.push('/kindness-program')}
                    >
                      Kembali
                    </button>
                    <button
                      className="btn btn-outline-primary"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        'Submit'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

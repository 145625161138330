import firebase from 'firebase/app'
import 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyB3G2YWHXwaeK9bQHg0bBs3SDgPD32L2AA',
  authDomain: 'milenial-273722.firebaseapp.com',
  databaseURL: 'https://milenial-273722.firebaseio.com',
  projectId: 'milenial-273722',
  storageBucket: 'milenial-273722.appspot.com',
  messagingSenderId: '415129036295',
  appId: '1:415129036295:web:77e21eb21aa478f4eaaca2',
}

firebase.initializeApp(firebaseConfig)

export const firebaseApp = firebase

export const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null

export const getToken = async () => {
  return messaging
    .getToken({
      vapidKey:
        'BEdqfyzHeciHWMnFJ53etX83-bWZGSyC-clAn-3-1Jo70jDoSM6JXtCIBh6oVsM79Zgyc52GtXe-A7Eg8_vTFDE',
    })
    .then((currentToken) => {
      if (currentToken) {
        console.info('current token for client: ', currentToken)
        return currentToken
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.info(
          'No registration token available. Request permission to generate one.',
        )
        return null
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.error('An error occurred while retrieving token. ', err)
      return null
      // catch error while creating client token
    })
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload)
    })
  })

import React from 'react'

const FormActiveStatus = ({ existingStatus, handleChangeStatus }) => {
  return (
    <div className="form-group row mb-5">
      <label className="col-sm-3 col-form-label">Status</label>
      <div className="form-group col-sm-9">
        <div className="switch switch-primary d-inline m-r-10">
          <input
            onChange={() => handleChangeStatus()}
            checked={existingStatus === 1}
            type="checkbox"
            id="switch-active-status"
          />
          <label
            htmlFor="switch-active-status"
            className="cr"
          ></label>
        </div>
        <label htmlFor="switch-active-status">
          {existingStatus === 1 ? 'Aktif' : 'Tidak aktif'}
        </label>
      </div>
    </div>
  )
}

export default FormActiveStatus

import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import Heatmap from 'highcharts/modules/heatmap'
import HighchartsReact from 'highcharts-react-official'
import { useBaseContext } from 'context/base'

Heatmap(Highcharts)

export default function GroupHeatMap({ idGroup, groupName }) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [data, setData] = useState({
    yCategories: [],
    xCategories: [],
    series: [],
  })

  /* ========================================= Functions ======================================== */
  function getPointCategoryName(point, dimension) {
    var series = point.series,
      isY = dimension === 'y',
      axis = series[isY ? 'yAxis' : 'xAxis']
    return axis.categories[point[isY ? 'y' : 'x']]
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    async function getHeatMap() {
      let response = await getRequest(
        'heatmap-group?groupId=' + idGroup,
      )
      if (response) {
        var d = response.data
        setData({
          yCategories: d.yCategories,
          xCategories: d.xCategories,
          series: d.series,
        })
      }
    }
    getHeatMap()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div className="feedback-heatmap">
      {data.yCategories.length > 0 ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: 'heatmap',
              marginTop: 40,
              marginBottom: 80,
              plotBorderWidth: 1,
            },

            title: {
              text: `Feedback Antar Siswa ${groupName}`,
            },

            xAxis: {
              categories: data.xCategories,
            },

            yAxis: {
              categories: data.yCategories,
              title: null,
              reversed: true,
            },

            accessibility: {
              point: {
                descriptionFormatter: function (point) {
                  var ix = point.index + 1,
                    xName = getPointCategoryName(point, 'x'),
                    yName = getPointCategoryName(point, 'y'),
                    val = point.value
                  return (
                    ix + '. ' + xName + ' sales ' + yName + ', ' + val + '.'
                  )
                },
              },
            },

            colorAxis: {
              min: 0,
              minColor: '#FFFFFF',
              maxColor: Highcharts.getOptions().colors[0],
            },

            legend: {
              align: 'right',
              layout: 'vertical',
              margin: 0,
              verticalAlign: 'top',
              y: 25,
              symbolHeight: 280,
            },

            tooltip: {
              formatter: function () {
                return (
                  '<b>' +
                  getPointCategoryName(this.point, 'x') +
                  '</b>: <br><b>' +
                  '</b>' +
                  getPointCategoryName(this.point, 'y') +
                  ' sebanyak ' +
                  '<b>' +
                  this.point.value +
                  ' kali<b>'
                )
              },
            },

            series: [
              {
                name: 'Feedback',
                borderWidth: 1,
                data: data.series,
                dataLabels: {
                  enabled: true,
                  color: '#000000',
                },
              },
            ],

            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    yAxis: {
                      labels: {
                        formatter: function () {
                          return this.value.charAt(0)
                        },
                      },
                    },
                  },
                },
              ],
            },
          }}
        />
      ) : null}
    </div>
  )
}

import React from 'react'
import CkeditorReadonly from 'component/ckeditor-readonly'
import Answer from 'component/question/answer'
import { ContentGraphor } from 'component/content'

const PreviewQuestionQuiz = ({ dataQuestion }) => {
  return (
    <div
      className="modal fade"
      id="preview-question-quiz-modal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myExtraLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              <i className="fas fa-hashtag mr-1" />
              Pertanyaan {dataQuestion.urutan}
              {dataQuestion.quest_type === 0 && ' (Pilihan Ganda)'}
              {dataQuestion.quest_type === 1 && ' (Uraian)'}
              {dataQuestion.quest_type === 2 && ' (Graphic Organizer)'}
            </h5>
            <button
              type="button"
              className="close text-danger"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="feather icon-x" />
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col">
                <CkeditorReadonly data={dataQuestion.text} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                {/* Daftar jawaban pilihan ganda */}
                {dataQuestion.quest_type === 0 ? (
                  <ul className="list-unstyled">
                    {dataQuestion.answers
                      ? dataQuestion.answers.map((answer) => {
                          return (
                            <Answer
                              key={answer.id}
                              answer={answer}
                            />
                          )
                        })
                      : null}
                  </ul>
                ) : null}

                {dataQuestion.quest_type === 2 ? (
                  <ContentGraphor
                    isPreview={true}
                    content={{
                      id: dataQuestion.id,
                      contents_url: dataQuestion.graphor.id,
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="modal-footer p-2">
            <button
              type="button"
              className="btn btn-outline-danger"
              data-dismiss="modal"
              aria-label="Close"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreviewQuestionQuiz

import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import DataTable from 'component/data-table'
import { Container, Header } from 'component/template'

export default function AktivitasKelas() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [limit, setLimit]         = useState(10)
  const [offset, setOffset]       = useState(0)
  const [search, setSearch]       = useState('')
  const [totalRows, setTotalRows] = useState(0)
  const [dataKelas, setDataKelas] = useState([])

  /* ========================================= Functions ======================================== */
  async function getAllDataKelas() {
    const response = await getRequest(
      `report-kelas?limit=${limit}&offset=${offset}&search=${search}`,
    )
    if (response) {
      setDataKelas(response.data)
      setTotalRows(response.totalRows)
    } else {
      console.error('ERROR', response)
    }
  }

  const RenderItem = ({ item, index }) => {
    function handleOverview(item) {
      history.push(`laporan-aktifitas-kelas-detail/${item.id}`, {
        detailKelas: item,
      })
    }

    return (
      <tr>
        <td>
          {item.title ? item.title : <i className="feather icon-minus" />}
        </td>
        <td>
          {item?.category?.title ||
            item?.class_profiles[0]?.category?.title || (
              <i className="feather icon-minus" />
            )}
        </td>
        <td>
          {item.updatedAt ? (
            moment(item.updatedAt).format('DD MMM YYYY, HH:mm')
          ) : (
            <i className="feather icon-minus" />
          )}
        </td>
        <td>
          <button
            onClick={() => {
              handleOverview(item)
            }}
            className="btn btn-sm btn-info"
          >
            Detail Laporan
          </button>
        </td>
      </tr>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getAllDataKelas()
  }, [limit, offset, search])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Laporan" linkName="Aktivitas Sub Mata Pelajaran" />
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-body">
                <div className="dt-responsive table-responsive">
                  <DataTable
                    className={'table table-striped table-bordered nowrap'}
                    data={dataKelas}
                    totalRows={totalRows}
                    show={[10, 20, 30, 40, 50]}
                    onChangeSearch={(val) => {
                      setSearch(val)
                    }}
                    onChangeLimit={(val) => {
                      setLimit(val)
                    }}
                    onChangePage={(val) => {
                      setOffset(val)
                    }}
                    column={[
                      'Sub Mata Pelajaran',
                      'Mata Pelajaran',
                      'Terakhir Update',
                      'Actions',
                    ]}
                    renderItem={(list, idx) => (
                      <RenderItem
                        index={idx}
                        item={list}
                        key={idx}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

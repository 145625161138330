import React from 'react'
import useForumDetail from 'context/forum-detail'

export default function ForumDetailInputButton() {
  /* ====================================== Consume Context ===================================== */
  const { discussion } = useForumDetail()

  /* ========================================== Output ========================================== */
  return (
    <div className="input-group mb-3">
      <input
        disabled={discussion.loading}
        value={discussion.data}
        type="text"
        onChange={(event) => discussion.set(event.target.value)}
        className="form-control"
        placeholder="Tambah diskusi"
      />
      <div className="input-group-append">
        <button
          disabled={discussion.loading}
          onClick={discussion.send}
          className="btn  btn-primary"
          type="button"
        >
          Kirim
        </button>
      </div>
    </div>
  )
}

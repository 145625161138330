import React from 'react'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import FileSiswa from 'component/file-siswa'
import { Container, Header } from 'component/template'

export default function FileSiswaPage() {
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="File Siswa" />
        <div className="card user-profile-list">
          <div className="card-body">
            <FileSiswa />
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React from 'react'

export default function CardHeader() {
  return (
    <div className = "card-header">
      <ul
        className = "nav nav-pills mr-5"
        id        = "pills-tab"
        role      = "tablist"
      >
        <li className = "nav-item">
          <a
            className     = "nav-link has-ripple active"
            id            = "pills-pesan-tab"
            data-toggle   = "pill"
            href          = "#pills-pesan"
            role          = "tab"
            aria-controls = "pills-pesan"
            aria-selected = "true"
            title         = "Pesan"
          >
            <i className = "fas fa-envelope-open-text" />
          </a>
        </li>
        <li className = "nav-item">
          <a
            className     = "nav-link has-ripple"
            id            = "pills-kontak-tab"
            data-toggle   = "pill"
            href          = "#pills-kontak"
            role          = "tab"
            aria-controls = "pills-kontak"
            aria-selected = "false"
            title         = "Kontak"
          >
            <i className = "fas fa-user-friends" />
          </a>
        </li>
      </ul>
    </div>
  )
}

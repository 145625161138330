import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'

export default function WordCloudDragNDrop() {
  var data = {
    List: [
      { id: 'item-1', content: 'Ayam' },
      { id: 'item-2', content: 'Kelinci' },
      { id: 'item-3', content: 'harimau' },
    ],
    column: ['List', 'Karnivora', 'Herbivora'],
    Karnivora: [],
    Herbivora: [],
  }

  const grid = 8

  /* ======================================= Local States ======================================= */
  const [items, setItems] = useState([
    { id: 'item-1', content: 'Ayam' },
    { id: 'item-2', content: 'Kelinci' },
    { id: 'item-3', content: 'harimau' },
  ])

  /* ========================================= Functions ======================================== */
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    width: 250,
    margin: 10,
  })

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    var source = result.source
    var destination = result.destination
    // data.list.splice(source.index,1)
    var list = data[source.droppableId]
    var item = list[source.index]

    var dest = data[destination.droppableId]
    // dest.push(item)
    dest.splice(destination.index, 0, item)
    delete list[source.index]
  }

  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Drag n Drop" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header"></div>
              <div className="card-body">
                <div className={'row'}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <DRable />
                  </DragDropContext>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )

  function DRable() {
    return data.column.map((val, i) => {
      return (
        <Droppable droppableId={`${val}`}>
          {(provided, snapshot) => (
            <div
              className={'col-md-3'}
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <h5>{val}</h5>
              {data[val].map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      {item.content}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )
    })
  }
}

import { isFileTypeInvalidHandler } from 'component/content/hooks/useFormInputContent'
import { isFileSizeInvalid, isGDrive } from 'util/file'
import InfoDocument from './info-document'
import InfoGdrive from './info-gdrive'

/**
 * Form Input Document Props
 * 
 * @param {BaseContentFormProps} props
 */
export default function FormInputDocument({ 
  value, 
  setValue, 
  isInvalid, 
  isRequired, 
  errorMessage,
}) {
  const internalSource = '0'
  const umumSource = '1'

  return (
    <div className="form-group mb-5 row">
      <label className="col-sm-3 col-form-label">
        <span className="mr-1">Dokumen</span>
        {isRequired
        ?
          <span className="text-danger">*</span>
        : null }
      </label>
      <div className="col-sm-9">
        <div className="col">
          <div className="form-check">
            <input
              onChange={e => setValue({
                ...value,
                content_source: e.target.value
              })}
              className="form-check-input"
              type="radio"
              name="selectDocument"
              id="umumUrl"
              defaultValue={umumSource}
              defaultChecked={value?.content_source == umumSource}
            />
            <label
              className="form-check-label"
              htmlFor="umumUrl"
            >
              <i className="fab fa-google-drive mr-1" />
              <span className="ml-1">
                Google Drive
              </span>
            </label>
          </div>
          {value?.content_source == umumSource ? (
            <div className="mb-4">
              <InfoGdrive />
              <input
                type="text"
                value={value?.link}
                onChange={e => setValue({
                  ...value,
                  link: e.target.value,
                  isChanged: true,
                })}
                className="form-control my-3"
                placeholder="Format link: https://drive.google.com/file/d/xyz"
              />
              {isGDrive(value?.link) ? (
                <iframe
                  src={`${value?.link}/preview`}
                  style={{
                    width: '100%',
                    height: '100vh',
                    border: 'none',
                    backgroundColor: '#ddd',
                  }}
                />
              ) : null}
            </div>
          ) : null}
          <div className="form-check">
            <input
              onChange={e => setValue({
                ...value,
                content_source: e.target.value
              })}
              className="form-check-input"
              type="radio"
              name="selectDocument"
              id="uploadDocument"
              defaultValue={internalSource}
              checked={value?.content_source == internalSource}
            />
            <label
              className="form-check-label"
              htmlFor="uploadDocument"
            >
              <i className="fas fa-file mr-1" />
              <span>Upload file</span>
            </label>
          </div>
          {value?.content_source == internalSource ? (
            <>
              <InfoDocument />
              <div className="custom-file my-3">
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx"
                  onChange={e => setValue({
                    ...value,
                    file: e.target.files?.[0],
                    isChanged: true,
                  })}
                  className="custom-file-input"
                  id="input-upload-document"
                />
                <label
                  className="custom-file-label"
                  htmlFor="input-upload-document"
                >
                  {value?.file?.name
                    ? value?.file?.name
                    : 'Pilih file: pdf/ppt/word/excel'}
                </label>
              </div>
            </>
          ) : null}
          {isInvalid ? (
            <label className="small form-text mt-3 text-danger">
              {isFileTypeInvalidHandler(value, 'document') ? 
              'Hanya mendukung file: pdf, powerpoint, word, dan excel' 
              : isFileSizeInvalid({ file: value?.file, type: 'document' }) ? 
                'Ukuran file melebihi 5 mb.'
              :
              errorMessage || 'Document tidak boleh kosong.'
              }
            </label>
          ) : null}
        </div>
      </div>
    </div>
  )
}
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import BackButton from 'component/button/Back'
import { host } from 'util/host'
import { avatarDefaultUser } from 'util/constant'
import { appendScript } from 'util/global'

export default function UsersAddProfile() {
  /* ========================================== Helpers ========================================= */
  const history  = useHistory()
  const location = useLocation()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/users-add-profile', name: 'Users' }]

  /* ======================================= Local States ======================================= */
  const [isRoleSiswa, setIsRoleSiswa]     = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [allProfile, setAllProfile]       = useState([])
  const [taTahunCode, setTaTahunCode]     = useState(location?.state?.taTahunCode)
  const [dataSubmit, setDataSubmit]       = useState([])
  const [academicYears, setAcademicYears] = useState([])

  /* ========================================= Functions ======================================== */
  function checkRoleSiswa() {
    if (location.state) {
      let roles = location.state.role
      let maping = roles.find((i) => i.role == 'Siswa')
      if (maping) {
        setIsRoleSiswa(true)
      } else {
        setIsRoleSiswa(false)
      }
    }
  }

  async function getAcademicYears() {
    const response = await getRequest('academic-year')
    if (response) {
      if (response.data.length > 0) {
        const options = response.data.map((item) => {
          const startYear = `20${item.code[0]}${item.code[1]}`
          return {
            value: item.code,
            label: `${startYear}`,
          }
        })
        setAcademicYears(options)
      }
    }
  }

  function handleChangeTahunCode(event) {
    setTaTahunCode(event.target.value)
  }

  function getAllProfile() {
    const myArr = location.state.role
    const objIds = myArr.reduce((a, { id, profile_id }) => {
      a[profile_id] = a[profile_id] || { profile_id, id: [] }
      return {
        ...a,
        ...{ [profile_id]: { profile_id, id: a[profile_id].id.concat(id) } },
      }
    }, {})
    const result = Object.values(objIds)
    const newArr = []
    result.map((i) => {
      newArr.push({ profile_id: i.profile_id, role_id: i.id })
    })
    setDataSubmit(newArr)
  }

  async function getProfileRole() {
    const response = await getRequest('get-profile-roles')
    if (response !== null) {
      setAllProfile(response.data)
    } else {
      setAllProfile([])
    }
  }

  async function handleSubmit() {
    setLoadingSubmit(true)
    let form = new FormData()
    form.append('user_id', location.state.id)
    form.append('data', dataSubmit)
    if (taTahunCode) {
      form.append('ta_tahun_code', taTahunCode)
    }
    const response = await postRequest('create-profile-roles', {
      ta_tahun_code: taTahunCode ? taTahunCode : null,
      user_id: location.state.id,
      data: dataSubmit,
    })
    if (response) {
      if (response.message == 'Berhasil menyimpan data') {
        window.notification('User', response.message, 'success')
        history.push('/users')
      }
    }
    setLoadingSubmit(false)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    appendScript('/admin/assets/js/plugins/jquery.validate.min.js')
    appendScript('/admin/assets/js/pages/form-validation.js')
    getAcademicYears()
    getProfileRole()
    getAllProfile()
    checkRoleSiswa()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Multi Profile"
          links={links}
        />
        
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h5>Multi Profile</h5>
              </div>

              <div className="card-body">
                <div className="row mb-4">
                  <div className="col-md-12">
                    {allProfile.map((i) => (
                      <div
                        className="card p-2"
                        key={i.id}
                      >
                        <h5>Akses Profile</h5>
                        <div className="d-flex mb-3">
                          <img
                            src={host + i.logo}
                            onError={(event) =>
                              (event.currentTarget.src = avatarDefaultUser)
                            }
                            className="img-radius"
                            style={{ height: '50px', width: '50px' }}
                          ></img>
                          <p
                            id={i.id}
                            className="ml-2 mt-2"
                          >
                            {i.name}
                          </p>
                        </div>
                        <label
                          htmlFor="role"
                          className="ml-4"
                        >
                          Roles
                        </label>
                        {i.roles.map((item, index) => (
                          <RenderRole
                            item={item}
                            id={i.id}
                            key={index}
                            onChange={(e) => {
                              if (item.role_type == 'siswa') {
                                setIsRoleSiswa(true)
                                if (!e.target.checked) {
                                  setIsRoleSiswa(false)
                                  setTaTahunCode('0')
                                }
                              }
                              const prof = i.id
                              const newar = [].concat(dataSubmit)
                              const a = newar.findIndex(
                                (i) => i.profile_id == prof,
                              )
                              let arr = []
                              if (a > -1) {
                                arr = arr.concat(newar[a].role_id)
                                let find = arr.indexOf(e.target.value)
                                if (find > -1) {
                                  arr.splice(find, 1)
                                } else {
                                  arr.push(e.target.value)
                                }
                                newar[a].role_id = arr
                              } else {
                                arr.push(e.target.value)
                                newar.push({
                                  profile_id: prof,
                                  role_id: arr,
                                })
                              }
                              setDataSubmit(newar)
                            }}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="m-4">
                  {isRoleSiswa || taTahunCode !== '0' ? (
                    <div className="form-group my-4">
                      <label htmlFor="academicYearUser">
                        <span>Tahun Masuk/Angkatan</span>
                      </label>
                      <select
                        value={taTahunCode}
                        onChange={handleChangeTahunCode}
                        id="academicYearUser"
                        className="form-control"
                      >
                        <option value="">Pilih tahun masuk...</option>
                        {academicYears.length > 0 ? (
                          academicYears.map((item, index) => (
                            <option
                              key={index}
                              value={item.value}
                            >
                              {item.label}
                            </option>
                          ))
                        ) : (
                          <option value="">Data tidak ditemukan</option>
                        )}
                      </select>
                    </div>
                  ) : null}
                </div>

                <div className="row ml-1">
                  <BackButton />
                  {loadingSubmit ? (
                    <button
                      disabled
                      type="button"
                      className="btn btn-success"
                    >
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                      />
                      Simpan
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success has-ripple mr-2"
                      onClick={handleSubmit}
                    >
                      Simpan
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
  
  function RenderRole({ item, id, onChange, onClick }) {
    let cekFind = dataSubmit.find((i) => i.profile_id == item.profile_id)
    return (
      <div
        key={item.id}
        className="form-check ml-4"
      >
        <input
          className={`form-check-input`}
          type="checkbox"
          name={item.role_type}
          id={id}
          value={item.id}
          onChange={onChange}
          checked={cekFind?.role_id.includes(item.id)}
          disabled={item.active}
          defaultChecked={cekFind?.role_id.includes(item.id)}
          onClick={onClick}
        />
        <label className="form-check-label">
          {item.role == 'instruktur' ? 'guru' : item.role}
        </label>
      </div>
    )
  }
}

import React from "react";

export default function FormSelect({ 
  id = '',
  labelForm = 'Form Select',
  value = '', 
  options = [],
  placeholder = '',
  onChange = () => {}, 
  disabled = false,
  required = false,
}) {
  if (typeof id === "string" && id !== "") {
    return (
      <div className={`form-group row`}>
        <label className="col-sm-3 col-form-label" htmlFor={id}>
          <span className="mr-1">{labelForm}{required ? <span className='text-danger'>*</span> : null }</span>
        </label>
        <div className="col-sm-9">
          <select  
            className="form-control"
            id={id}
            value={value}
            disabled={disabled}
            onChange={onChange}
          >
            <option value="">{placeholder ? placeholder : "Pilih data..."}</option>
            {options.length > 0 ?
              options.map((option, index) => 
                <option key={index} value={option.value}>{option.label}</option>
              )
              :
              <option disabled value="">Data tidak ditemukan</option>
            }
          </select>
        </div>
      </div>
    );
  } else {
    return <span className="text-danger">Id must be defined</span>;
  }
}
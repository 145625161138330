import { useBaseContext } from "context/base"
import Swal from "sweetalert2"

export function useContentStudentSpesific() {
  const { putRequest } = useBaseContext()

  /**
   * Save Spesific Student
   * 
   * @param {{
   *  classId: string
   *  contentId: string
   *  onSuccessHandler?: () => void
   *  selectedContent: object
   *  selectedStudents: Array<Student>
   * }} params
   */
  const onSaveSpesificStudentHandler = ({ classId, contentId, onSuccessHandler = () => {}, selectedContent, selectedStudents }) => {
    Swal.fire({
      icon: 'warning',
      title: `Apakah anda yakin?`,
      text: `Anda akan memperbarui konten spesifik siswa (${selectedContent?.title})`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ya, perbarui!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData()
        formData.append('class_id', classId)
        formData.append('content_id', contentId || selectedContent?.id)

        if (Array.isArray(selectedStudents) && selectedStudents.length) {
          selectedStudents.forEach((selectedStudent, index) => {
            formData.append(`spesifik_user_id[${index}]`, selectedStudent.id)
          })
        }

        const response = await putRequest(`set-users-content-specific`, formData)
        Swal.fire({
          html: `<div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>`,
          allowOutsideClick: false,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
        })
        if (response) {
          if (onSuccessHandler && typeof onSuccessHandler === 'function') {
            onSuccessHandler()
          }

          Swal.fire({
            icon: 'success',
            text: `Spesifik konten siswa berhasil diperbarui`,
          })
        } else {
          Swal.close()
        }
      }
    })
  }
  return {
    handler: {
      onSaveSpesificStudentHandler,
    }
  }
}
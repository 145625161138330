import React from 'react'

const FormVideoNav = ({ vidshowNav, handleChangeVidNav }) => (
  <div className="form-group row mb-5">
    <label className="col-sm-3 col-form-label">Kontrol Video</label>
    <div className="form-group col-sm-9">
      <div className="switch switch-primary d-inline m-r-10">
        <input
          onChange={() => handleChangeVidNav()}
          checked={vidshowNav === 1}
          type="checkbox"
          id="switchVidshow"
        />
        <label
          htmlFor="switchVidshow"
          className="cr"
        ></label>
      </div>
      <label htmlFor="switchVidshow">
        {vidshowNav === 1 ? 'Aktif' : 'Tidak aktif'}
      </label>
    </div>
  </div>
)

export default FormVideoNav

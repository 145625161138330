import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { daftarKataPenghubung } from 'util/string'
require('highcharts/modules/wordcloud.js')(Highcharts)

export default function WordCloud({ label, string }) {
  var text = string
  const lines = text.split(/[,\. ]+/g)

  const filteredKataPenghubungInLines = lines?.filter(line =>
    !daftarKataPenghubung.includes(line.toLowerCase()) && line.trim() !== ""
  );

  var data = Highcharts.reduce(
    filteredKataPenghubungInLines,
      function (arr, word) {
        var obj = Highcharts.find(arr, function (obj) {
          return obj.name === word
        })
        if (obj) {
          obj.weight += 1
        } else {
          obj = {
            name: word,
            weight: 1,
          }
          arr.push(obj)
        }
        return arr
      },
      [],
    )

  const optionsWorldCloud = {
    chart: {
      spacingBottom: 15,
      spacingTop: 10,
      spacingLeft: 15,
      spacingRight: 15,
      width: null,
      height: null,
    },
    accessibility: {
      screenReaderSection: {
        beforeChartFormat:
          '<h5>{chartTitle}</h5>' +
          '<div>{chartSubtitle}</div>' +
          '<div>{chartLongdesc}</div>' +
          '<div>{viewTableButton}</div>',
      },
    },
    series: [
      {
        type: 'wordcloud',
        data: data,
        name: 'response',
      },
    ],
    title: {
      text: `<strong>${label.toUpperCase()}</strong>`,
    },
    subtitle: {
      text: `Hasil dari pertanyaan "${label}"`,
    },
  }

  return (
    <div className="m-2">
      <br />
      <HighchartsReact
        highcharts={Highcharts}
        options={optionsWorldCloud}
      />
      <br />
    </div>
  )
}

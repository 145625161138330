import React, {
  useCallback,
  useContext,
  createContext,
  useEffect,
  useState,
} from 'react'
import { useBaseContext } from 'context/base'

const ContentSummaryContext = createContext()

export function ContentSummaryWrapper({ children, class_id }) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================== States ========================================== */
  const [data, setData] = useState(null)

  /* ========================================= Functions ======================================== */
  const getListNotes = useCallback(async () => {
    let res = await getRequest('personalNoteByClass?class_id=' + class_id)
    if (res) {
      setData(res.data)
    }
  }, [class_id])

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (class_id) {
      getListNotes()
    }
  }, [getListNotes])

  /* ======================================= Shared States ====================================== */
  const sharedStates = {
    data,
  }

  /* ====================================== Output Provider ===================================== */
  return (
    <ContentSummaryContext.Provider value={sharedStates}>
      {children}
    </ContentSummaryContext.Provider>
  )
}

/* ======================================= Output Wrapper ======================================= */
export default function useContentSummary() {
  return useContext(ContentSummaryContext)
}

import React, { useState, useRef } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

import { useBaseContext } from 'context/base'
import { editorConfiguration } from 'util/ckeditor-config'

export default function AddDiskusi({ classId, getForum }) {
  /* ========================================= Constants ======================================== */
  const typeForum = 'mata-pelajaran'
  const participants = [{ type: typeForum, value_id: classId }]

  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [titleForum, setTitleForum]       = useState('')
  const [descForum, setDescForum]         = useState('')
  const [validateTitle, setValidateTitle] = useState(false)
  const [validateDesc, setValidateDesc]   = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [prerequisite, setPrerequisite]   = useState('')

  /* =========================================== Refs =========================================== */
  const closeBtn = useRef()

  /* ========================================= Functions ======================================== */
  async function formValidation() {
    if (titleForum && prerequisite) {
      setValidateTitle(false)
      setValidateDesc(false)
      await submitForm()
    } else {
      if (!titleForum) {
        setValidateTitle(true)
      }

      if (!prerequisite) {
        setValidateDesc(true)
      }
    }
  }

  async function submitForm() {
    setLoadingSubmit(true)
    let form = new FormData()
    form.append('title', titleForum)
    form.append('description', prerequisite)
    form.append('participants', JSON.stringify(participants))
    form.append('content_id', classId)

    const response = await postRequest('forum', form)
    setLoadingSubmit(false)
    if (response) {
      window.notification('Berhasil', 'Topik berhasil dibuat', 'success')
      closeBtn.current.click()
      await getForum()
    } else {
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }

  function resetForm() {
    setTitleForum('')
    setDescForum('')
    setValidateTitle(false)
    setValidateDesc(false)
    setLoadingSubmit(false)
  }

  /* ========================================== Output ========================================== */
  return (
    <div
      id="addForumModal"
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="addForumModalTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="addForumModalTitle"
            >
              Buat Topik
            </h5>
            <button
              ref={closeBtn}
              onClick={() => {
                window.$('#addForumModal').modal('hide')
                resetForm()
              }}
              type="button"
              className="close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group row">
                <label
                  htmlFor="inputJudul"
                  className="col-sm-2 col-form-label"
                >
                  Judul Topik
                </label>
                <div className="col-sm-10">
                  <input
                    value={titleForum}
                    onChange={(e) => {
                      setTitleForum(e.target.value)
                      setValidateTitle(titleForum == '')
                    }}
                    type="text"
                    className={`form-control ${
                      validateTitle ? 'is-invalid' : ''
                    }`}
                    id="inputJudul"
                    placeholder="Judul Topik"
                  />
                  {validateTitle ? (
                    <span className="small form-text text-danger">
                      Judul tidak boleh kosong.
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="inputDesc"
                  className="col-sm-2 col-form-label"
                >
                  Deskripsi
                </label>
                <div className="col-sm-10">
                  <CKEditor
                    editor={Editor}
                    data={prerequisite}
                    config={editorConfiguration}
                    onChange={(_, editor) => {
                      const data = editor.getData()
                      setPrerequisite(data)
                      setValidateDesc(descForum == '')
                    }}
                  />

                  {validateDesc ? (
                    <span className="small form-text text-danger">
                      Deskripsi tidak boleh kosong.
                    </span>
                  ) : null}
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer p-2">
            <button
              type="button"
              onClick={() => {
                window.$('#addForumModal').modal('hide')
                resetForm()
              }}
              className="btn btn-outline-danger mr-2"
            >
              Batal
            </button>
            {loadingSubmit ? (
              <button
                disabled={true}
                type="button"
                className="btn btn-success btn-forbidden"
              >
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                />
                <span>Simpan</span>
              </button>
            ) : (
              <button
                onClick={formValidation}
                type="button"
                className="btn btn-success"
              >
                Simpan
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

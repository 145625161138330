import React, { Fragment } from "react"

export default function BaseTableSemester({ listMapel = [],  data = [], total_semester = 0 }) {
  // const listMapel = ['PAI', 'PPKN', 'BINDO', 'MTK', 'FISIKA', 'KIMIA', 'BIOLOGI', 'SOSIOLOGI', 'EKONOMI', 'SEJARAH', 'GEOGRAFI', 'BING', 'PJOK', 'INFORMATIKA', 'SBDP', 'BAHASA ASING']

  const totalLengthOtherFeatureForListMapel = listMapel.length
  const totalOtherFeature = 4
  return (
    <div className="dt-responsive table-responsive container-base-table-rekapitulasi overflow-scroll">
      <table id="tables" className="table base-table-rekapitulasi">
        <thead>
          <tr className="base-table-rekapitulasi__first-header">
            <th rowSpan={totalOtherFeature}>No</th>
            <th rowSpan={totalOtherFeature}>NISN / NIPD</th>
            <th rowSpan={totalOtherFeature}>NAMA</th>
          </tr>
          <tr className="base-table-rekapitulasi__mapel-average">
            <th colSpan={totalLengthOtherFeatureForListMapel * totalOtherFeature} className="text-center">Mata Pelajaran</th>
          </tr>
          <tr className="base-table-rekapitulasi__list-mapel">
            {listMapel?.map((mapel, index) => (
              <th
                key={`header-list-mapel-${index}`}
                rowSpan={1} colSpan={totalOtherFeature} className="text-center"
                >
                { mapel }
              </th>
            ))}
          </tr>
          <tr className="base-table-rekapitulasi__list-feature">
            {Array.from({ length: totalLengthOtherFeatureForListMapel })?.map((_, index) => (
              <Fragment
                key={`list-feature-mapel-${index}`}
              >
                <th>RATA-RATA 1-{total_semester}</th>
                <th>Ujian Praktik</th>
                <th>Ujian Tulis</th>
                <th>Nilai Akhir</th>
              </Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((d, index) => (
            <tr key={`list-mapel-${d?.id}-rekapitulasi-index${index}`}>
              <td>{ index + 1}</td>
              <td style={{ wordBreak: 'break-word', whiteSpace: 'normal', width: 180 }}>{d?.nim}</td>
              <td style={{ wordBreak: 'break-word', whiteSpace: 'normal', width: 200 }}>{d?.name}</td>
              {d?.score?.map((sc, scIndex) => (
                <Fragment key={`list-score-mapel-${sc?.id}-rekapitulasi-index${scIndex}-parentIndex${index}`}>
                  <td>{sc?.average_all_semester || '-'}</td>
                  <td>{sc?.ujian_praktik || '-'}</td>
                  <td>{sc?.ujian_tulis || '-'}</td>
                  <td>{sc?.final_score || '-'}</td>
                </Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
import React from 'react'
import DiscussChartV2 from './chart/discuss-v2'
import DurationChart from './chart/duration'
import FeedbackChartV2 from './chart/feedback-v2'
import TimelineChart from './chart/timeline'
import Badges from './partials/header/badges'
import Bottom from './partials/header/bottom'
import Kop from './partials/header/kop'
import Title from './partials/header/title'

export default function HeaderRaporBlock() {
  return (
    <div style={{ backgroundColor: '#c9c9c9' }}>
      <Kop />
      <Title />

      <div className="card mb-1">
        <div className="card-body">
          <div className="row">
            <div className="col-4 d-flex justify-content-center align-items-center">
              <img
                className="img-fluid"
                src="/image/Student.png"
                style={{ width: 50, height: 50 }}
              />
              <h5 className="ml-2 text-uppercase">Perolehan Badge</h5>
            </div>
            <div className="col-4 d-flex justify-content-center align-items-center">
              <img
                className="img-fluid"
                src="/image/Student.png"
                style={{ width: 50, height: 50 }}
              />
              <h5 className="ml-2 text-uppercase">keaktifan berdiskusi</h5>
            </div>
            <div className="col-4 d-flex justify-content-center align-items-center">
              <img
                className="img-fluid"
                src="/image/Student.png"
                style={{ width: 50, height: 50 }}
              />
              <h5 className="ml-2 text-uppercase">Durasi pengerjaan</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-1">
        <div className="card-body">
          <div className="row">
            <Badges />
            <DiscussChartV2 />
            <DurationChart />
          </div>
        </div>
      </div>

      <div className="card mb-1">
        <div className="card-body">
          <div className="row">
            <div className="col-6 d-flex justify-content-center align-items-center">
              <img
                className="img-fluid"
                src="/image/Student.png"
                style={{ width: 50, height: 50 }}
              />
              <h5 className="ml-2 text-uppercase">Waktu mengerjakan tugas</h5>
            </div>
            <div className="col-6 d-flex justify-content-center align-items-center">
              <img
                className="img-fluid"
                src="/image/Student.png"
                style={{ width: 50, height: 50 }}
              />
              <h5 className="ml-2 text-uppercase">
                keaktifan memberikan feedback
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-1">
        <div className="card-body">
          <div className="row">
            <TimelineChart />
            <FeedbackChartV2 />
          </div>
        </div>
      </div>

      <Bottom />
    </div>
  )
}

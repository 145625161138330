import React, { useEffect } from 'react'

import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { appendScript } from 'util/global'
import { host } from 'util/host'

export default function Menus() {
  /* ========================================= Functions ======================================== */
  function getMenus() {
    let token = localStorage.getItem('token')
    if (token) {
      appendScript('pages/menu/index.js?token=' + token + '&host=' + host)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getMenus()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Menu" />

        <div className="row">
          {/* [ Form Validation ] start */}
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Menus</h5>
              </div>
              <div className="card-body">
                <div className="dt-responsive table-responsive">
                  <div id="tree1"></div>
                </div>
                <button
                  type="button"
                  id="getNode"
                  className="btn btn-outline-success has-ripple"
                >
                  Simpan
                  <span
                    className="ripple ripple-animate"
                    style={{
                      height: '90.8281px',
                      width: '90.8281px',
                      animationDuration: '0.7s',
                      animationTimingFunction: 'linear',
                      background: 'rgb(255, 255, 255)',
                      opacity: '0.4',
                      top: '-24.4062px',
                      left: '-11.3438px',
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

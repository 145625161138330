import React from 'react'

export default function TableHead() {
  return (
    <thead>
      <tr>
        <th
          className="text-center align-middle"
          scope="col"
          rowSpan={2}
          style={{ width: '50px' }}
        >
          No
        </th>
        <th
          className="text-center align-middle"
          scope="col"
          rowSpan={2}
        >
          Section/Materi
        </th>
        <th
          className="text-center align-middle"
          scope="col"
          colSpan={4}
        >
          Penilaian
        </th>
        <th
          className="text-center align-middle"
          scope="col"
          rowSpan={2}
          style={{ width: '40px' }}
        >
          P
        </th>
        <th
          className="text-center align-middle"
          scope="col"
          rowSpan={2}
          style={{ width: '40px' }}
        >
          K
        </th>
      </tr>
      <tr>
        <th
          className="text-center"
          style={{ width: '40px' }}
        >
          A
        </th>
        <th
          className="text-center"
          style={{ width: '40px' }}
        >
          D
        </th>
        <th
          className="text-center"
          style={{ width: '40px' }}
        >
          B
        </th>
        <th
          className="text-center"
          style={{ width: '100px' }}
        >
          Belum Selesai
        </th>
      </tr>
    </thead>
  )
}

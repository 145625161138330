import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'

export default function ShareKelas() {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, deleteRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [allProfiles, setAllProfiles]           = useState([])
  const [allClassProfiles, setAllClassProfiles] = useState(null)
  const [profileId, setProfileId]               = useState('')
  const [limit, setLimit]                       = useState(10)
  const [offset, setOffset]                     = useState(0)
  const [keyword, setKeyword]                   = useState('')

  /* ========================================= Functions ======================================== */
  async function getClassProfiles() {
    const response = await getRequest(
      `all-classes-profiles?profile_id=${profileId}&limit=${limit}&offset=${offset}&keyword=${keyword}`,
    )
    if (response) {
      setAllClassProfiles(response)
    }
  }

  async function getProfiles() {
    const response = await getRequest('profile?active=true')
    if (response) {
      let temp = []
      response.data.map((item) => {
        temp.push({ label: item.name, value: item.id })
      })
      setAllProfiles(temp)
    }
  }

  async function handleDelete(profile_id, class_id) {
    Swal.fire({
      title: 'Konfirmasi',
      text: 'Kamu yakin akan menghapus akses kelas ini?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await deleteRequest(
          'delete-classes-profiles?profile_id=' +
            profile_id +
            '&class_id=' +
            class_id,
        )
        if (response) {
          getClassProfiles()
          window.notification(
            'Sub Mata Pelajaran',
            'Akses telah dihapus',
            'success',
          )
        }
      }
    })
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getProfiles()
  }, [])

  useEffect(() => {
    getClassProfiles()
  }, [profileId, limit, offset, keyword])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Bagikan Sub Mata Pelajaran" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-md-6 col-sm-12">
                    <span className="f-w-700 f-14">Profil Sekolah Tujuan</span>
                    <Select
                      className="mt-2"
                      placeholder="Pilih profil sekolah tujuan"
                      isMulti={false}
                      options={allProfiles}
                      onChange={(v) => {
                        setProfileId(v.value)
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <Link to="/kelola-share-kelas">
                      <button
                        type="button"
                        className="btn btn-primary has-ripple float-right"
                      >
                        <i className="feather icon-plus-circle mr-1" />
                        <span>Kelola Sub Mata Pelajaran</span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <DataTable
                  className={'table table-striped table-bordered nowrap'}
                  data={allClassProfiles?.data}
                  totalRows={allClassProfiles?.totalRecords}
                  show={[10, 20, 30, 40, 50]}
                  onChangeSearch={(val) => {
                    setKeyword(val)
                  }}
                  onChangeLimit={(val) => {
                    setLimit(val)
                  }}
                  onChangePage={(val) => {
                    setOffset(val)
                  }}
                  column={[
                    'No',
                    'Sub Mata Pelajaran',
                    'Mata Pelajaran',
                    'Status',
                    'Aksi',
                  ]}
                  renderItem={(item, index) => (
                    <RenderItem
                      val={item}
                      key={index}
                      i={index}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )

  function RenderItem({ val, i }) {
    return (
      <tr>
        <td>{i + 1}</td>
        <td>{val.class.title}</td>
        <td>{val.class.category?.title ? val.class.category.title : '-'}</td>
        <td>
          {val.is_owner && val.status == 'duplicate' ? (
            <span className="badge badge-info">
              Duplicate by {val.login?.user?.name || `???`}
            </span>
          ) : (
            <span className="badge badge-secondary">
              Shared by {val.login?.user?.name || `???`}
            </span>
          )}
        </td>
        <td>
          <button
            onClick={() => handleDelete(profileId, val.class.id)}
            className="btn btn-sm btn-danger mr-2"
          >
            <i className="feather icon-trash-2 mr-1" />
            <span>Hapus Akses</span>
          </button>
        </td>
      </tr>
    )
  }
}

import React from 'react';

/**
 * Loading Spinner V2
 * 
 * @param {{
 *  variant: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info' | 'light' | 'dark'
 *  size: number
 *  type: 'border' | 'grow'
 * }} props
 */
const LoadingSpinnerV2 = ({ variant = 'primary', size = 2, type = 'border' }) => {
  return (
    <div className="d-flex justify-content-center">
      <div className={`spinner-${type} text-${variant}`} style={{ width: `${size}rem`, height: `${size}rem` }} role="status"><span className="sr-only">Loading...</span></div>
    </div>
  );
}

export default LoadingSpinnerV2;
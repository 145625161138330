import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import './gamifikasi.css'

export default function Gamification() {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, putRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [gamification, setGamification] = useState(true)
  const [points, setPoints]             = useState(true)
  const [badges, setBadges]             = useState(true)
  const [levels, setLevels]             = useState(true)
  const [rewards, setRewards]           = useState(false)
  const [leaderboard, setLB]            = useState(true)
  const [dataPoints, setDataPoints]     = useState([])
  const [dataBadges, setDataBadges]     = useState([])
  const [dataLevel, setDataLevel]       = useState([])
  const [key, setKey]                   = useState(Math.random())

  /* ========================================= Functions ======================================== */
  function setGami() {
    if (gamification) {
      setGamification(false)
    } else {
      setGamification(true)
    }
  }

  function setPoin() {
    if (points) {
      setPoints(false)
    } else {
      setPoints(true)
    }
  }

  function setLencana() {
    if (badges) {
      setBadges(false)
    } else {
      setBadges(true)
    }
  }

  function setlevel() {
    if (levels) {
      setLevels(false)
    } else {
      setLevels(true)
    }
  }

  function setReward() {
    if (rewards) {
      setRewards(false)
    } else {
      setRewards(true)
    }
  }

  function setleader() {
    if (leaderboard) {
      setLB(false)
    } else {
      setLB(true)
    }
  }

  async function getData() {
    var response = await getRequest('gamification')
    if (response) {
      setDataPoints(response.data.points)
      setDataBadges(response.data.badges)
      setDataLevel(response.data.level)
    }
  }
  
  function togglePoints(status) {
    var a = dataPoints
    for (var p in dataPoints) {
      a[p].active = status
    }
    setDataPoints(a)
  }

  function toggleBadges(status) {
    var a = dataBadges
    for (var p in dataBadges) {
      a[p].active = status
    }
    setDataBadges(a)
  }

  function toggleLevel(status) {
    var a = dataLevel
    for (var p in dataLevel) {
      a[p].active = status
    }
    setDataLevel(a)
  }

  async function saveSetting() {
    var form = new FormData()
    form.append('points', JSON.stringify(dataPoints))
    form.append('badges', JSON.stringify(dataBadges))
    let response = await putRequest('gamification-setting', form)
    if (response) {
      window.notification(
        'Berhasil',
        'data gamifikasi berhasil disimpan',
        'success',
      )      
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getData()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Gamifikasi Setting" linkName="Gamifikasi" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div
                      style={{ fontSize: '17px' }}
                      className="form-group text-right mr-2"
                    >
                      <div className="switch switch-primary d-inline m-r-10">
                        <input
                          type="checkbox"
                          id="switch-p-1"
                          onClick={(e) => {
                            togglePoints(e.target.checked)
                            toggleBadges(e.target.checked)
                            toggleLevel(e.target.checked)
                            setPoints(e.target.checked)
                            setBadges(e.target.checked)
                            setLevels(e.target.checked)
                            setKey(Math.random())
                          }}
                          defaultChecked={true}
                        />
                        <label
                          htmlFor="switch-p-1"
                          className="cr"
                        />
                      </div>
                      <label htmlFor="switch-p-1">
                        <strong
                          style={{
                            color: `${gamification ? 'lightgreen' : 'red'}`,
                          }}
                        >
                          {gamification ? 'GAMIFIKASI ON' : 'GAMIFIKASI OFF'}
                        </strong>
                      </label>
                    </div>

                    <fieldset className="mb-2">
                      <div className="gamifikasi-header row">
                        <h5 className="m-0 p-2">POINTS</h5>
                        <div className="switch switch-primary d-inline m-r-10">
                          <input
                            type="checkbox"
                            id="points"
                            checked={points}
                            onClick={(e) => {
                              setPoints(e.target.checked)
                              togglePoints(e.target.checked)
                              setKey(Math.random())
                            }}
                            disabled={!gamification}
                          />
                          <label
                            style={{ top: '7px' }}
                            htmlFor="points"
                            className="cr"
                          />
                        </div>
                        <label />
                      </div>
                      {dataPoints.map((item, index) => {
                        let ind = dataPoints.findIndex((v) => v.id == item.id)
                        return (
                          <div
                            key={key + item.id}
                            className="input-group mb-3"
                          >
                            <div className="switch switch-primary d-inline m-r-10">
                              <input
                                type="checkbox"
                                id={`status-${item.id}`}
                                onChange={(e) => {
                                  var a = dataPoints
                                  a[ind].active = e.target.checked
                                  setDataPoints(() => a)
                                }}
                                defaultChecked={item.active}
                              />
                              <label
                                htmlFor={`status-${item.id}`}
                                className="cr"
                              />
                            </div>
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                {item.desc}
                              </span>
                            </div>
                            <input
                              type="number"
                              style={{ paddingLeft: 20, width: 10 }}
                              onChange={(e) => {
                                var a = dataPoints
                                a[ind].value = e.target.value
                                setDataPoints(() => a)
                              }}
                              defaultValue={item.value}
                              className="form-control"
                              placeholder="Recipient's username"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                            />
                            <div className="input-group-append">
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                Poin
                              </span>
                            </div>
                          </div>
                        )
                      })}
                    </fieldset>
                    <fieldset className="mb-2">
                      <div className="gamifikasi-header row justify-content-between">
                        <div className="row ml-1">
                          <h5 className="m-0 p-2">BADGES</h5>
                          <div className="switch switch-primary d-inline m-r-10">
                            <input
                              type="checkbox"
                              id="badges"
                              onClick={(e) => {
                                setBadges(e.target.checked)
                                toggleBadges(e.target.checked)
                                setKey(Math.random())
                              }}
                              checked={badges}
                              disabled={!gamification}
                            />
                            <label
                              style={{ top: '7px' }}
                              htmlFor="badges"
                              className="cr"
                            />
                          </div>
                          <label />
                        </div>
                        <Link to="/costumize-badges">
                          <button className="btn btn-light btn-sm">
                            <i className="fas fa-cog"></i> CUSTOMIZE BADGES
                          </button>
                        </Link>
                      </div>
                      {dataBadges.map((item) => {
                        let ind = dataBadges.findIndex((v) => v.id == item.id)
                        return (
                          <div
                            key={key + item.id}
                            className="form-group"
                            style={{}}
                          >
                            <div className="switch switch-primary d-inline m-r-10">
                              <input
                                type="checkbox"
                                id={`status-${item.id}`}
                                onChange={(e) => {
                                  var a = dataBadges
                                  a[ind].active = e.target.checked
                                  setDataBadges(() => a)
                                }}
                                defaultChecked={item.active}
                              />
                              <label
                                htmlFor={`status-${item.id}`}
                                className="cr"
                              />
                            </div>
                            <label>
                              {item.desc} {strPoint(item.child)}
                            </label>
                          </div>
                        )
                        function strPoint(arr) {
                          var str = '('
                          arr.map((vv, ii) => {
                            var koma = ii == 0 ? '' : ', '
                            str += koma + vv.value
                          })
                          return str + ')'
                        }
                      })}
                    </fieldset>
                    <fieldset className="mb-2">
                      <div className="gamifikasi-header row">
                        <h5 className="m-0 p-2">LEVELS</h5>
                        <div className="switch switch-primary d-inline m-r-10">
                          <input
                            type="checkbox"
                            id="levels"
                            onClick={(e) => {
                              setLevels(e.target.checked)
                              toggleLevel(e.target.checked)
                              setKey(Math.random())
                            }}
                            checked={levels}
                            disabled={!gamification}
                          />
                          <label
                            style={{ top: '7px' }}
                            htmlFor="levels"
                            className="cr"
                          />
                        </div>
                        <label />
                      </div>
                      {dataLevel.map((item, i) => {
                        let ind = dataLevel.findIndex((v) => v.id == item.id)
                        return (
                          <div
                            key={key + item.id}
                            className="input-group mb-3"
                          >
                            <div className="switch switch-primary d-inline m-r-10">
                              <input
                                type="checkbox"
                                id={`status-${item.id}`}
                                onChange={(e) => {
                                  var a = dataLevel
                                  a[ind].active = e.target.checked
                                  setDataPoints(() => a)
                                  
                                }}
                                defaultChecked={item.active}
                              />
                              <label
                                htmlFor={`status-${item.id}`}
                                className="cr"
                              />
                            </div>
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                {item.desc}
                              </span>
                            </div>
                            <input
                              type="number"
                              style={{ paddingLeft: 20, width: 10 }}
                              onChange={(e) => {
                                var a = dataLevel
                                a[ind].value = e.target.value
                                setDataPoints(() => a)
                              }}
                              defaultValue={item.value}
                              className="form-control"
                              placeholder="Recipient's username"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                            />
                            <div className="input-group-append">
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                {str(item.types)}
                              </span>
                            </div>
                          </div>
                        )
                        function str(types) {
                          if (types == 'level-point') {
                            return 'points'
                          } else if (types == 'level-class') {
                            return 'Kelas'
                          } else if (types == 'level-badges') {
                            return 'Badges'
                          } else {
                            return null
                          }
                        }
                      })}
                    </fieldset>
                    <hr />
                    <button
                      type="button"
                      onClick={() => saveSetting()}
                      className="btn btn-danger has-ripple "
                    >
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { useLocation, useHistory } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import RaporTable from './components/rapor-table'
import loadingTypes from 'globals/loading-types'

export default function InputRapor() {
  /* ========================================== Helpers ========================================= */
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()
  
  /* ======================================= Local States ======================================= */
  const [categories, setCategories]         = useState([])
  const [categorySelect, setCategorySelect] = useState(null)
  const [groups, setGroups]                 = useState([])
  const [groupSelect, setGroupSelect]       = useState(null)
  const [semester, setSemester]             = useState([])
  const [semesterSelect, setSemesterSelect] = useState(null)
  const [loadingSearch, setLoadingSearch]   = useState(loadingTypes.idle)  // idle / start / success / error
  const [showMapel, setShowMapel]           = useState(false)
  const [showKelas, setShowKelas]           = useState(false)

  /* ========================================= Functions ======================================== */
  const getDataOptions = async (url = '') => {
    const response = await getRequest(url)
    if (response) return response.data
    return []
  }

  const handleChangeCategory = async (value) => {
    setCategorySelect(value)
    await initDropdownSelect(
      'groups-user-active?group_id=' +
        value.value +
        '&ta_semester_code=' +
        query.get('semester_id'),
      'group',
    )

    let searchQuery = ''
    if (value) {
      searchQuery = `category_id=${value.value}&`
    }
    if (query.get('semester_id')) {
      searchQuery = `${searchQuery}semester_id=${query.get('semester_id')}&`
    }
    if (query.get('group_id')) {
      searchQuery = `${searchQuery}group_id=${query.get('group_id')}`
    }
    history.push(`/input-rapor?${searchQuery}`)
  }

  const handleChangeGroup = (value) => {
    setGroupSelect(value)
    let searchQuery = ''
    if (query.get('category_id')) {
      searchQuery = `category_id=${query.get(
        'category_id',
      )}&semester_id=${query.get('semester_id')}&`
    }
    if (value) {
      searchQuery = `${searchQuery}group_id=${value.value}`
    }
    history.push(`/input-rapor?${searchQuery}`)
  }

  const handleChangeSemester = async (value) => {
    if (query.get('category_id')) {
      await initDropdownSelect(
        'groups-user-active?group_id=' +
          query.get('category_id') +
          '&ta_semester_code=' +
          value.value,
        'group',
      )
      setSemesterSelect(value)
      let searchQuery = ''
      if (query.get('category_id'))
        searchQuery = `category_id=${query.get('category_id')}&`
      if (value) searchQuery = `${searchQuery}semester_id=${value.value}`
      history.push(`/input-rapor?${searchQuery}`)
    } else {
      await initDropdownSelect(
        'groups-user-active?ta_semester_code=' + value.value,
        'group',
      )
      setSemesterSelect(value)
      let searchQuery = ''
      if (query.get('category_id'))
        searchQuery = `category_id=${query.get('category_id')}&`
      if (value) searchQuery = `${searchQuery}semester_id=${value.value}`
      history.push(`/input-rapor?${searchQuery}`)
    }
  }

  const initSelectedItem = (array = [], id, type = '') => {
    for (let item of array) {
      if (item.value == id) {
        if (type === 'category') {
          setCategorySelect({ label: item.label, value: id })
        }

        if (type === 'group') {
          setGroupSelect({ label: item.label, value: id })
        }

        if(type === 'semester') {
          setSemesterSelect({ label: item.label, value: id })
        }

        break
      }
    }
  }

  const initDropdownSelect = async (url, type) => {
    const array = await getDataOptions(url)
    const options = array.map((item) => ({
      label: item.title,
      value: item.id,
    }))
    const renderSemester = array.map((val) => ({
      label: `${val.tahun_mulai}/${val.tahun_selesai} - ${val.title_render} ${
        val.status ? '(Aktif)' : ''
      }`,
      value: val.code,
    }))

    switch (type) {
      case 'category':
        setCategories(options)
        if (query.get('category_id'))
          initSelectedItem(options, query.get('category_id'), type)
        break
      case 'group':
        setGroups(options)
        if (query.get('group_id'))
          initSelectedItem(options, query.get('group_id'), type)
        break
      case 'semester':
        setSemester(renderSemester)
        if (query.get('semester_id'))
          initSelectedItem(renderSemester, query.get('semester_id'), type)
        break
      default:
        setGroups([])
        setCategories([])
        setSemester([])
        break
    }
  }

  const init = async () => {
    await initDropdownSelect('categories-active', 'category')
    await initDropdownSelect('groups-user-active?group_id=', 'group')
    await initDropdownSelect('semester', 'semester')
  }
  
  /* ========================================== Effects ========================================= */
  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (semesterSelect) {
      setShowMapel(true)
    }
    if (categorySelect) {
      setShowKelas(true)
    }
  }, [semesterSelect, categorySelect])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Input Rapor Mata Pelajaran" />
        
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4 mb-3 mb-md-0">
                <span className="f-w-700 f-14">Semester</span>
                <Select
                  className="mt-2"
                  placeholder="Pilih semester"
                  isMulti={false}
                  isDisabled={
                    loadingSearch === loadingTypes.start
                  }
                  onChange={handleChangeSemester}
                  value={semesterSelect}
                  options={semester}
                />
              </div>
              {showMapel && (
                <div className="col-md-4 mb-3 mb-md-0">
                  <span className="f-w-700 f-14">Mata Pelajaran</span>
                  <Select
                    className="mt-2"
                    placeholder="Pilih mata pelajaran"
                    isMulti={false}
                    isDisabled={
                      loadingSearch === loadingTypes.start
                    }
                    value={categorySelect}
                    options={categories}
                    onChange={handleChangeCategory}
                  />
                </div>
              )}
              {showKelas && (
                <div className="col-md-4 mb-3 mb-md-0">
                  <span className="f-w-700 f-14">Kelas</span>
                  <Select
                    className="mt-2"
                    placeholder="Pilih kelas"
                    isMulti={false}
                    isClearable={true}
                    isDisabled={
                      loadingSearch === loadingTypes.start
                    }
                    value={groupSelect}
                    options={groups}
                    onChange={handleChangeGroup}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <RaporTable
          loadingTypes={loadingTypes}
          category_id={query.get('category_id')}
          group_id={query.get('group_id')}
          semester_id={query.get('semester_id')}
          loadingSearch={loadingSearch}
          setLoadingSearch={setLoadingSearch}
        />
      </Container>
    </TemplateAdmin>
  )
}

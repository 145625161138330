const STATE_TYPES = {
  LOADING_IDLE: 'LOADING_IDLE',
  LOADING_START: 'LOADING_START',
  LOADING_ENDED: 'LOADING_ENDED',
  LOADING_SUCCESS: 'LOADING_SUCCESS',
  LOADING_FAILURE: 'LOADING_FAILURE',
  TASK_IDLE: 'TASK_IDLE',
  TASK_START: 'TASK_START',
  TASK_ENDED: 'TASK_ENDED',
  TASK_SUCCESS: 'TASK_SUCCESS',
  TASK_FAILURE: 'TASK_FAILURE',
}

export default STATE_TYPES

import React from 'react'

export default function ListContainer({ children }) {
  return (
    <ul
      className="list-unstyled mb-0 bg-light"
      style={{
        height: '100%',
        overflowY: 'scroll',
      }}
    >
      {children}
    </ul>
  )
}

import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import SubmitButton from 'component/button/Submit'
import InvalidText from 'component/invalid/text'
import { FormSwitch } from 'component/forms'
import InfoDocument from 'component/content-form/info-document'
import useEditContent from './hook'
import {
  FormInputName,
  FormInputUrutan,
  FormSelectSection,
  FormSelectPrecontent,
  FormInputTanggal,
  FormActiveStatus,
  FormDurasi,
  FormActiveKey,
  FormSelectFeedback,
  FormGroupKecil,
  FormInputDesc,
  FormInputEndedAt,
  InfoGdrive,
  FormTextArea,
  FormSetToBeScore,
  FormSelectStudent,
} from 'component/content-form'
import { getUsersContentSpesificStudent } from 'util/array'

export default function EditContentDocument() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const location = useLocation()
  const params = useParams()

  /* ======================================= Consume Hook ======================================= */
  const { getContentTakeAway } = useEditContent()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest } = useBaseContext()
  
  /* ========================================= Constants ======================================== */
  const existingDataContent = location.state?.dataContent
  const existingContentsUrl = existingDataContent?.contents_url
  const sections = location.state?.sectionList ? location.state?.sectionList : []
  const links = [
    { url: '/kelas', name: 'Sub Mata Pelajaran' },
    { url: `/kelas/${location.state?.kelasId}`, name: 'Overview' },
  ]

  /* ======================================= Local States ======================================= */
  const [isContentTakeAwayExist, setIsContentTakeAwayExist] = useState(false)

  // FORM INPUT STATES
  const [existingContents, setExistingContents]         = useState([])
  const [namaKonten, setNamaKonten]                     = useState(existingDataContent?.title || '')
  const [urutan, setUrutan]                             = useState(existingDataContent?.urutan || '')
  const [sectionId, setSectionId]                       = useState(existingDataContent?.section_id || '')
  const [preContentId, setPrecontentId]                 = useState(existingDataContent?.precontent_id || '')
  const [active, setActive]                             = useState(existingDataContent?.active ? 1 : 0)
  const [durasi, setDurasi]                             = useState(existingDataContent?.durasi ? existingDataContent.durasi / 60 : '')
  const [displayAt, setDisplayAt]                       = useState(existingDataContent?.display_at || '')
  const [selectDocumentSource, setSelectDocumentSource] = useState({
    useGdrive: true,
    useUpload: false,
  })
  const [feeds, setFeeds]               = useState([])
  const [feedId, setFeedId]             = useState(null)
  const [isFeedActive, setIsFeedActive] = useState(0)
  const [enableGroup, setEnableGroup]   = useState(!!existingDataContent?.group_status)
  const [maxGroup, setMaxGroup]         = useState(existingDataContent?.group_max || '')
  const [descGroup, setDescGroup]       = useState(existingDataContent?.group_description || '')
  const [endedAt, setEndedAt]           = useState(existingDataContent?.ended_at || '')
  const [description, setDescription]   = useState(existingDataContent?.deskripsi || '')
  const [apresiasi, setApresiasi]       = useState(existingDataContent?.apresiasi || '')
  const [rating, setRating]             = useState(existingDataContent?.rating ? 1 : 0)
  const [toBeScore, setToBeScore]   = useState({
    isScore: existingDataContent.is_score,
    isKeterampilan: existingDataContent.is_keterampilan,
    isPengetahuan: existingDataContent.is_pengetahuan,
    selectedTypeScore: existingDataContent.type_score_id,
    isTypeScoreActive: true,
  })
  const [selectedStudents, setSelectedStudents] = useState(() => getUsersContentSpesificStudent(existingDataContent))

  // FORM FILE STATES
  const [contentSource, setContentSource]           = useState('gdrive')
  const [contentUrlGdrive, setContentUrlGdrive]     = useState('')
  const [contentUrlInternal, setContentUrlInternal] = useState('')
  const [gdriveUrl, setGdriveUrl]                   = useState('')
  const [currentFileName, setcurrentFileName]       = useState('')
  const [isFileChange, setIsFileChange]             = useState(false)
  const [file, setFile]                             = useState(null)

  // FORM VALIDATION STATES
  const [isNamaInvalid, setIsNamaInvalid]         = useState(false)
  const [isUrutanInvalid, setIsUrutanInvalid]     = useState(false)
  const [isSectionInvalid, setIsSectionInvalid]   = useState(false)
  const [isFileInvalid, setIsFileInvalid]         = useState(false)
  const [isFileSizeInvalid, setIsFileSizeInvalid] = useState(false)
  const [isFileTypeInvalid, setIsFileTypeInvalid] = useState(false)
  const [isMaxGroupValid, setIsMaxGroupValid]     = useState(true)
  const [isUploading, setIsUploading]             = useState(false)

  const [enableGroupKey, setEnableGroupKey] = useState(false)
  const [prerequisite, setPrerequisite]     = useState('')

  /* =========================================== Refs =========================================== */
  const refInputName     = useRef()
  const refSelectSection = useRef()
  const refInputUrutan   = useRef()
  const refMaxGroup      = useRef()

  /* ========================================= Functions ======================================== */
  function getExistingContentsBySelectedSection() {
    if (!sectionId) {
      setExistingContents([])
    } else {
      sections.forEach((section) => {
        if (section.id === sectionId) {
          setExistingContents(section.contents)
        }
      })
    }
  }

  async function getFeedsList() {
    const response = await getRequest('feed')
    if (response) {
      const options = response.data.map((item) => ({
        value: item.id,
        label: item.description,
      }))
      setFeeds(options)
      initSelectedFeed(options)
    } else {
      console.error('ERROR', response)
    }
  }

  function initSelectedFeed(feedList) {
    for (let feed of feedList) {
      if (feed.value === existingDataContent.feedback) {
        setFeedId({ value: feed.value, label: feed.label })
        break
      }
    }
  }

  function getExistingFile() {
    switch (existingContentsUrl.source) {
      case 'gdrive':
        handleUseGdrive()
        setGdriveUrl(existingContentsUrl.data)
        break
      case 'internal':
        let { pathname } = new URL(existingContentsUrl.url)
        var n = pathname.lastIndexOf('/')
        var result = pathname.substring(n + 1)
        handleUseInternal()
        setcurrentFileName(result)
        break
      default:
        break
    }
  }

  function handleChangeName(event) {
    setNamaKonten(event.target.value)
  }

  function handleChangeUrutan(event) {
    setUrutan(Math.abs(event.target.value))
  }

  function handleChangeSection(event) {
    setSectionId(event.target.value)
  }

  function handleChangePrecontent(event) {
    setPrecontentId(event.target.value)
  }

  function handleChangeDate(event) {
    setDisplayAt(event.target.value)
  }

  function handleChangeDurasi(event) {
    setDurasi(event.target.value)
  }

  function handleChangeActive() {
    if (active === 1) {
      setActive(0)
    }
    if (active === 0) {
      setActive(1)
    }
  }

  function handleChangeFeedback(val) {
    setFeedId(val)
  }

  function handleUseGdrive() {
    setSelectDocumentSource({
      useGdrive: true,
      useUpload: false,
    })
    setContentSource('gdrive')
  }

  function handleUseInternal() {
    setSelectDocumentSource({
      useGdrive: false,
      useUpload: true,
    })
    setContentSource('internal')
  }

  function validateFileExtension(fileName) {
    let isExtensionValid = false
    const fileExtension = fileName.split('.').pop()
    const correctExtensionList = [
      'pdf',
      'ppt',
      'pptx',
      'doc',
      'docx',
      'xls',
      'xlsx',
    ]
    correctExtensionList.forEach((correctExt) => {
      if (fileExtension === correctExt) {
        isExtensionValid = true
      }
    })

    if (isExtensionValid) {
      setIsFileTypeInvalid(false)
      return 'sesuai'
    } else {
      setIsFileTypeInvalid(true)
      return 'tidak sesuai'
    }
  }
  function handleChangeEnableGroupKey() {
    if (enableGroupKey) {
      setEnableGroupKey(false)
    } else {
      setEnableGroupKey(true)
    }
  }

  function handleFileContent(content) {
    if (typeof content === 'string') {
      setGdriveUrl(content)
      setContentUrlGdrive({ name: null, data: content, source: contentSource })
      setcurrentFileName('')
      setContentUrlInternal('')
      setIsFileChange(true)
    } else if (`${typeof content}` === 'object') {
      const check = validateFileExtension(content.name)
      if (check === 'sesuai') {
        if (content.size <= 5000000) {
          setFile(content)
          setcurrentFileName(content.name)
          setContentUrlInternal({
            name: content.name,
            data: 'internal',
            source: contentSource,
          })
          setGdriveUrl('')
          setContentUrlGdrive('')
          setIsFileChange(true)
          setIsFileSizeInvalid(false)
        } else {
          setIsFileSizeInvalid(true)
        }
      }
    }
  }

  function handleChangeEnableGroup() {
    if (enableGroup) {
      setEnableGroup(false)
      setMaxGroup(
        existingDataContent.group_max ? existingDataContent.group_max : '',
      )
      setIsMaxGroupValid(true)
    } else {
      setEnableGroup(true)
      setMaxGroup(
        existingDataContent.group_max ? existingDataContent.group_max : '',
      )
      if (existingDataContent.group_max) {
        setIsMaxGroupValid(true)
      } else {
        setIsMaxGroupValid(false)
      }
    }
  }

  function handleChangeMaxGroup(event) {
    if (event.target.value) {
      const absolute = Math.abs(event.target.value)
      const integer = Math.round(absolute)
      setMaxGroup(integer)
      if (integer <= 0) {
        setIsMaxGroupValid(false)
      } else {
        setIsMaxGroupValid(true)
      }
    } else {
      setMaxGroup('')
      setIsMaxGroupValid(false)
    }
  }

  function handleChangeDescGroup(event) {
    setDescGroup(event.target.value)
  }

  function handleChangeDesc(event) {
    setDescription(event.target.value)
  }

  function handleChangeEndedAt(event) {
    setEndedAt(event.target.value)
  }

  function validateFileChanges() {
    if (isFileChange) {
      if (contentSource == 'gdrive') {
        return contentUrlGdrive
      }

      if (contentSource == 'internal') {
        return contentUrlInternal
      }
    } else {
      return { name: null, data: 'unchanged', source: null }
    }
  }

  function handleChangeApresiasi(event) {
    setApresiasi(event.target.value)
  }

  function handleChangeRating(value) {
    setRating(value ? 1 : 0)
  }

  function toBeScoreValidation() {
    if (toBeScore.isScore) {
      return (toBeScore.isKeterampilan === true || toBeScore.isPengetahuan === true) && toBeScore.selectedTypeScore
    }

    return true
  }

  function handleValidation() {
    const newUrutan = urutan ? Math.abs(parseInt(urutan)) : 0
    const durasiDetik = durasi * 60
    const feedback = isFeedActive === 1 ? feedId : ''
    const contents_url = validateFileChanges()

    if (
      namaKonten &&
      urutan &&
      sectionId &&
      contents_url &&
      contents_url.data &&
      isMaxGroupValid &&
      toBeScoreValidation()
    ) {
      setIsNamaInvalid(false)
      setIsSectionInvalid(false)
      setIsUrutanInvalid(false)
      setIsFileInvalid(false)
      handleSubmitForm(
        existingDataContent.id,
        contents_url,
        namaKonten,
        durasiDetik,
        sectionId,
        newUrutan,
        active,
        displayAt,
        preContentId,
        feedback.value,
        maxGroup,
        descGroup,
        endedAt,
        description,
        apresiasi,
        rating,
      )
    } else {
      if (!isMaxGroupValid) {
        if (refMaxGroup) {
          refMaxGroup.current.focus()
        }
      }

      if (!sectionId) {
        setIsSectionInvalid(true)
        refSelectSection.current.focus()
      } else {
        setIsSectionInvalid(false)
      }

      if (!contents_url) {
        setIsFileInvalid(true)
      } else {
        setIsFileInvalid(false)
      }

      if (!contents_url.data) {
        setIsFileInvalid(true)
      } else {
        setIsFileInvalid(false)
      }

      if (!urutan) {
        setIsUrutanInvalid(true)
        refInputUrutan.current.focus()
      } else {
        setIsUrutanInvalid(false)
      }

      if (!namaKonten) {
        setIsNamaInvalid(true)
        refInputName.current.focus()
      } else {
        setIsNamaInvalid(false)
      }

      window.notification('Mohon', 'lengkapi data')

      if (!toBeScoreValidation()) {
        window.notification('Mohon', 'lengkapi juga data "Jadikan Sebagai Penilaian"')
      }
    }
  }

  async function handleSubmitForm(
    id,
    contentUrl,
    namaKonten,
    totalDurasi,
    sectionId,
    urutan,
    active,
    displayAt,
    preContentId,
    feedId,
    maxGroup,
    descGroup,
    endedAt,
    description,
    apresiasi,
    rating,
  ) {
    let form = new FormData()
    form.append('id', id)
    form.append('title', namaKonten)
    form.append('content_type', 4)
    form.append('section_id', sectionId)
    form.append('urutan', urutan)
    form.append('active', active)
    form.append('durasi', totalDurasi)
    form.append('rating', rating)
    form.append('precontent_id', preContentId)
    form.append('display_at', displayAt)
    form.append('ended_at', endedAt)
    form.append('deskripsi', description)
    form.append('apresiasi', apresiasi)

    form.append('is_score', !!toBeScore.isScore)

    if (toBeScore.isScore) {
      form.append('is_keterampilan', !!toBeScore.isKeterampilan)
      form.append('is_pengetahuan', !!toBeScore.isPengetahuan)
      form.append('type_score_id', toBeScore.selectedTypeScore)
    }

    if (Array.isArray(selectedStudents) && !!selectedStudents.length) {
      selectedStudents.forEach((selectedStudent, selectedStudentIndex) => {
        form.append(`spesifik_user_id[${selectedStudentIndex}]`, selectedStudent.id)
      })
    }

    if (contentUrl.data != 'unchanged') {
      form.append('contents_url', JSON.stringify(contentUrl))
      if (contentUrl.source === 'internal') {
        const { id } = JSON.parse(localStorage.getItem('user'))
        const profile_id = JSON.parse(localStorage.getItem('profile')).id
        const ext = contentUrl.name.split('.').pop()
        form.append('path', `upload/${profile_id}/classes/contents`)
        form.append('temp_name', `file-temp-${id}-${Date.now()}.${ext}`)
        form.append('file_upload', file)
      }
    }

    if (feedId) {
      form.append('feedback', feedId)
    } else {
      form.append('feedback', '')
    }

    if (enableGroup) {
      form.append('group_status', 1)
    } else {
      form.append('group_status', 0)
    }

    if (maxGroup) {
      form.append('group_max', maxGroup)
    }

    if (descGroup) {
      form.append('group_description', descGroup)
    }

    setIsUploading(true)
    const response = await putRequest('contents', form)
    setIsUploading(false)
    if (response) {
      if (enableGroupKey) {
        let formkey = new FormData()
        formkey.append('description', prerequisite)
        formkey.append('content_id', id)

        const response1 = isContentTakeAwayExist
          ? await putRequest('contentTakeAway', formkey)
          : await postRequest('contentTakeAway', formkey)

        if (response1) {
          window.notification('Materi', 'berhasil diubah', 'success')
          history.push(`/kelas/${location.state?.kelasId}`)
        }
      } else {
        window.notification('Materi', 'berhasil diubah', 'success')
        history.push(`/kelas/${location.state?.kelasId}`)
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getContentTakeAway(existingDataContent.id).then((res) => {
      if (res.data) {
        setPrerequisite(res.data.description)
        setEnableGroupKey(true)
        setIsContentTakeAwayExist(true)
      }
    })

    getExistingFile()
    getFeedsList()
  }, [])

  useEffect(() => {
    getExistingContentsBySelectedSection()
  }, [sectionId])

  useEffect(() => {
    if (existingDataContent.feedback) {
      setIsFeedActive(1)
    }
  }, [existingDataContent.feedback])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Edit Materi Dokumen"
          linkName="Materi Dokumen"
          links={links}
        />
        
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <h5>
                  <i className="fas fa-file mr-1" />
                  Informasi Dokumen
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <form>
                      {/* Input Nama Konten */}
                      <FormInputName
                        label="Nama Dokumen"
                        placeholder="Nama Dokumen"
                        namaKonten={namaKonten}
                        handleChangeName={handleChangeName}
                        isNamaInvalid={isNamaInvalid}
                        refInputName={refInputName}
                      />

                      {/* Form Group Document */}
                      <fieldset className="form-group mb-5 row">
                        <label className="col-sm-3 col-form-label">
                          <span className="mr-1">Dokumen</span>
                        </label>
                        <div className="col-sm-9 row mb-3">
                          <div className="col">
                            {/* Select Source Gdrive*/}
                            <div className="form-check">
                              <input
                                onChange={() => handleUseGdrive()}
                                className="form-check-input"
                                type="radio"
                                name="selectDokumen"
                                id="gdriveUrl"
                                defaultValue={1}
                                defaultChecked={existingContentsUrl.source === 'gdrive'}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="gdriveUrl"
                              >
                                <i className="fab fa-google-drive mr-1" />
                                <span>Google Drive</span>
                              </label>
                            </div>

                            {/* Input URL Gdrive */}
                            {selectDocumentSource.useGdrive ? (
                              <div className="mb-4">
                                <InfoGdrive />
                                <input
                                  type="text"
                                  value={gdriveUrl}
                                  onChange={(event) =>
                                    handleFileContent(event.target.value)
                                  }
                                  className="form-control my-3"
                                  placeholder="Format link: https://drive.google.com/file/d/xyz"
                                />
                                {gdriveUrl ? (
                                  <iframe
                                    src={`${gdriveUrl}/preview`}
                                    style={{
                                      width: '100%',
                                      height: '100vh',
                                      border: 'none',
                                      backgroundColor: '#ddd',
                                    }}
                                  />
                                ) : null}
                              </div>
                            ) : null}

                            {/* Select Source Internal */}
                            <div className="form-check">
                              <input
                                onChange={() => handleUseInternal()}
                                className="form-check-input"
                                type="radio"
                                name="selectDokumen"
                                id="internalFile"
                                defaultValue={0}
                                defaultChecked={
                                  existingContentsUrl.source === 'internal'
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="internalFile"
                              >
                                <i className="fas fa-file mr-1" />
                                <span>Upload file</span>
                              </label>
                            </div>

                            {/* Input File Document */}
                            {selectDocumentSource.useUpload && <InfoDocument />}
                            {selectDocumentSource.useUpload ? (
                              <div className="custom-file my-3">
                                <input
                                  type="file"
                                  accept=".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx"
                                  onChange={(event) =>
                                    handleFileContent(event.target.files[0])
                                  }
                                  className="custom-file-input"
                                  id="inputFile"
                                  style={{
                                    height: 'auto',
                                  }}
                                />
                                <label
                                  style={{
                                    height: 'auto',
                                  }}
                                  className="custom-file-label"
                                  htmlFor="inputFile"
                                >
                                  {currentFileName
                                    ? currentFileName
                                    : 'Pilih file: pdf/ppt/word/excel'}
                                </label>
                                {isFileTypeInvalid ? (
                                  <label className="col small form-text text-danger mt-3">
                                    Hanya mendukung file: pdf, powerpoint, word,
                                    dan excel
                                  </label>
                                ) : null}
                                {isFileSizeInvalid ? (
                                  <label className="col small form-text text-danger mt-3">
                                    Ukuran file melebihi 5 mb.
                                  </label>
                                ) : null}
                              </div>
                            ) : null}
                            {isFileInvalid ? (
                              <InvalidText name={'Dokumen'} />
                            ) : null}
                          </div>
                        </div>
                      </fieldset>

                      {/* Input Urutan */}
                      <FormInputUrutan
                        urutan={urutan}
                        handleChangeUrutan={handleChangeUrutan}
                        isUrutanInvalid={isUrutanInvalid}
                        refInputUrutan={refInputUrutan}
                      />

                      {/* Input deskripsi */}
                      <FormInputDesc
                        description={description}
                        handleChangeDesc={handleChangeDesc}
                      />

                      {/* Input Apresiasi */}
                      <FormTextArea
                        id="formInputApresiasi"
                        label="Apresiasi"
                        placeholder="Apresiasi/pesan setelah siswa menyelesaikan materi (opsional)"
                        className="mb-5"
                        value={apresiasi}
                        handleChange={handleChangeApresiasi}
                      />

                      {/* Select Section */}
                      <FormSelectSection
                        sections={sections}
                        handleChangeSection={handleChangeSection}
                        isSectionInvalid={isSectionInvalid}
                        existingSectionId={sectionId}
                        refSelectSection={refSelectSection}
                      />

                      {/* Select Precontent */}
                      <FormSelectPrecontent
                        existingContents={existingContents}
                        handleChangePrecontent={handleChangePrecontent}
                        existingPrecontentId={preContentId}
                        existingContentId={existingDataContent.id}
                      />

                      {/* Input Tanggal Ditampilkan */}
                      <FormInputTanggal
                        handleChangeDate={handleChangeDate}
                        existingDisplayAt={displayAt}
                      />

                      {/* Input ended at */}
                      <FormInputEndedAt
                        endedAt={endedAt}
                        handleChangeEndedAt={handleChangeEndedAt}
                      />

                      {/* Input Durasi */}
                      <FormDurasi
                        existingDurasi={durasi}
                        handleChangeDurasi={handleChangeDurasi}
                      />

                      {/* Rating */}
                      <FormSwitch
                        id="formRating"
                        label="Rating Materi"
                        value={rating}
                        handleChange={handleChangeRating}
                      />

                      {/* Select Feedback */}
                      <FormSelectFeedback
                        existingFeedId={feedId}
                        existingFeeds={feeds}
                        isFeedActive={isFeedActive}
                        setIsFeedActive={setIsFeedActive}
                        handleChangeFeeds={handleChangeFeedback}
                        getFeedList={getFeedsList}
                      />

                      {/* Swicth Group Kecil */}
                      <FormGroupKecil
                        enableGroup={enableGroup}
                        handleChangeEnableGroup={handleChangeEnableGroup}
                        maxGroup={maxGroup}
                        descGroup={descGroup}
                        handleChangeMaxGroup={handleChangeMaxGroup}
                        handleChangeDescGroup={handleChangeDescGroup}
                        isMaxGroupValid={isMaxGroupValid}
                        refMaxGroup={refMaxGroup}
                      />

                      {/* Select Specific Students */}
                      <FormSelectStudent
                        selectedStudents={selectedStudents}
                        setSelectedStudents={setSelectedStudents}
                        subMapelId={params?.kelasId}
                        originalSelectedStudents={getUsersContentSpesificStudent(existingDataContent)}
                        isDirectSave={false}
                        isWithFooter={false}
                        listSelectedStudentsProps={{
                          listStudentsActions: ['rollback', 'clear-all'],
                        }}
                      />

                      {/* Select To Be Score Or Not */}
                      <FormSetToBeScore
                        value={toBeScore}
                        handleChange={setToBeScore}
                      />

                      {/* Select Status */}
                      <FormActiveStatus
                        existingStatus={active}
                        handleChangeStatus={handleChangeActive}
                      />
                      <FormActiveKey
                        enableGroupKey={enableGroupKey}
                        handleChangeEnableGroupKey={handleChangeEnableGroupKey}
                        prerequisite={prerequisite}
                        setPrerequisite={setPrerequisite}
                      />

                      <div className="form-group">
                        <button
                          onClick={() =>
                            history.push(`/kelas/${location.state?.kelasId}`)
                          }
                          type="button"
                          className="btn btn-outline-danger has-ripple mr-2"
                        >
                          Kembali
                        </button>
                        {isUploading ? (
                          <button
                            disabled={true}
                            type="button"
                            className="btn btn-success mr-2"
                          >
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                            />
                            <span>Simpan</span>
                          </button>
                        ) : (
                          <SubmitButton
                            onClick={() => handleValidation()}
                            text="Simpan"
                          />
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React, { useEffect } from 'react'
import $ from 'jquery'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'

export default function Kalender() {
  /* ====================================== Consume Context ====================================== */
  const { getRequest, postRequest, putRequest, deleteRequest } = useBaseContext()

	/* ========================================= Functions ========================================= */
  async function getCalender() {
    let response = await getRequest('calendar')
    if (response) {
      var data = response.data
      window.$('#calendar').fullCalendar({
        lang: 'id',
        timezone: 'local',
        header: {
          left: 'prev,next today',
          center: 'title',
          right: 'month,agendaWeek,agendaDay',
        },
        droppable: true,
        events: data,
        drop: function () {
          if ($('#drop-remove').is(':checked')) {
            $(this).remove()
          }
        },
        eventAfterRender: function (eventObj, $el) {
          if (eventObj.className.includes('btn-forbidden')) {
            $el.attr('title', 'Kegiatan conference tidak bisa diubah')
          }

          if (eventObj.className.includes('event-editable')) {
            $el.attr('title', eventObj.title)
          }
        },
        dayClick: function (date, jsEvent, view) {
          window.$('#addCalendarEvent').modal('show')
          document.querySelector('.add-date').innerHTML = `${date.format(
            'DD MMMM YYYY',
          )}`
          document.querySelector('.add-date').dataset.value = `${date.format(
            'YYYY-MM-DD',
          )}`
        },
        eventClick: function (calEvent) {
          if (calEvent.className.includes('event-editable')) {
            window.$('#editCalendarEvent').modal('show')
            document.getElementById('editId').value = calEvent.id
            document.getElementById('editTitle').value = calEvent.title
          }
        },
        eventDrop: function (event, delta, revertFunc) {
          Swal.fire({
            html: `
							<h2>Apakah anda yakin?</h2
							<p class="m-0 mb-2 f-20">Ubah waktu kegiatan <strong>${
                event.title
              }</strong>?</p>
							${event.allDay ? `<p class="m-0 f-w-500 f-14">Waktu: Sehari penuh</p>` : ''}
							${
                event.start
                  ? `<p class="m-0 f-w-500 f-14">Mulai: ${event.start.format(
                      'DD MMMM YYYY, HH:mm',
                    )}</p>`
                  : ''
              }
							${
                event.end
                  ? `<p class="m-0 f-w-500 f-14">Selesai: ${event.end.format(
                      'DD MMMM YYYY, HH:mm',
                    )}</p>`
                  : ''
              }
						`,
            icon: 'question',
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Ya, ubah',
            cancelButtonText: 'Tidak',
          }).then(async (result) => {
            if (result.isConfirmed) {
              let form = new FormData()
              form.append('id', event.id)
              form.append('all_day', event.allDay)
              form.append('start_time', event.start)
              if (event.end) {
                form.append('end_time', event.end)
              }
              const response = await putRequest('calendar', form)
              if (response) {
                Swal.fire('Waktu kegiatan diubah', '', 'success')
              }
            } else {
              revertFunc()
            }
          })
        },
        eventResize: function (event, delta, revertFunc) {
          Swal.fire({
            html: `
							<h2>Apakah anda yakin?</h2
							<p class="m-0 mb-2 f-20">Ubah waktu kegiatan <strong>${
                event.title
              }</strong>?</p>
							<p class="m-0 f-w-500 f-14">Mulai: ${event.start.format(
                'DD MMMM YYYY, HH:mm',
              )}</p>
							<p class="m-0 f-w-500 f-14">Selesai: ${event.end.format(
                'DD MMMM YYYY, HH:mm',
              )}</p>
						`,
            icon: 'question',
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Ya, ubah',
            cancelButtonText: 'Tidak',
          }).then(async (result) => {
            if (result.isConfirmed) {
              let form = new FormData()
              form.append('id', event.id)
              form.append('start_time', event.start)
              form.append('end_time', event.end)
              const response = await putRequest('calendar', form)
              if (response) {
                Swal.fire('Waktu kegiatan diubah', '', 'success')
              }
            } else {
              revertFunc()
            }
          })
        },
      })
    }
  }

  async function handleAddSubmit(e) {
    e.preventDefault()
    const name = document.getElementById('addTitle')
    const start = document.getElementById('addStart')
    const end = document.getElementById('addEnd')
    const date = document.querySelector('.add-date')
    const time = document.getElementById('addTime')
    const nameWarning = document.getElementById('addTitleWarning')
    const startWarning = document.getElementById('addStartWarning')
    const endWarning = document.getElementById('addEndWarning')

    switch (time.value) {
      case 'all-day':        
        if (name.value) {
          nameWarning.style.display = 'none'
          let form = new FormData()
          form.append('desc', name.value)
          form.append('all_day', true)
          form.append('start_time', date.dataset.value)
          const response = await postRequest('calendar', form)
          if (response) {            
            resetAddForm()
            window.$('#addCalendarEvent').modal('hide')
            window.$('#calendar').fullCalendar('renderEvent', {
              id: response.data.id,
              title: response.data.desc,
              allDay: response.data.all_day,
              start: response.data.start_time,
              end: response.data.end_time,
              borderColor: '#9ccc65',
              backgroundColor: '#9ccc65',
              textColor: '#fff',
              editable: true,
              className: 'event-editable',
            })
          }
        } else {
          nameWarning.style.display = 'inline'
        }
        break
      case 'with-time':
        if (name.value && start.value && end.value) {
          const startDate = start.value
            ? `${date.dataset.value}T${start.value}`
            : null
          const endDate = end.value
            ? `${date.dataset.value}T${end.value}`
            : null
          nameWarning.style.display = 'none'
          startWarning.style.display = 'none'
          endWarning.style.display = 'none'
          let form = new FormData()
          form.append('desc', name.value)
          form.append('all_day', false)
          form.append('start_time', startDate)
          form.append('end_time', endDate)
          const response = await postRequest('calendar', form)
          if (response) {
            resetAddForm()
            window.$('#addCalendarEvent').modal('hide')
            window.$('#calendar').fullCalendar('renderEvent', {
              id: response.data.id,
              title: response.data.desc,
              allDay: response.data.all_day,
              start: response.data.start_time,
              end: response.data.end_time,
              borderColor: '#9ccc65',
              backgroundColor: '#9ccc65',
              textColor: '#fff',
              editable: true,
              className: 'event-editable',
            })
          }
        } else {
          if (!name.value) {
            nameWarning.style.display = 'inline'
          } else {
            nameWarning.style.display = 'none'
          }
          if (!start.value) {
            startWarning.style.display = 'block'
          } else {
            startWarning.style.display = 'none'
          }
          if (!end.value) {
            endWarning.style.display = 'block'
          } else {
            endWarning.style.display = 'none'
          }
        }
        break
      default:
        break
    }
  }

  async function handleEditSubmit(e) {
    e.preventDefault()
    const id = document.getElementById('editId').value
    const title = document.getElementById('editTitle')
    const editTitleWarning = document.getElementById('editTitleWarning')
    if (title.value) {
      editTitleWarning.style.display = 'none'
      let form = new FormData()
      form.append('id', id)
      form.append('desc', title.value)
      const response = await putRequest('calendar', form)
      if (response) {
        window.$('#editCalendarEvent').modal('hide')
        Swal.fire('Nama kegiatan diubah', '', 'success')
        window.$('#calendar').fullCalendar('removeEvents', response.data.id)
        window.$('#calendar').fullCalendar('renderEvent', {
          id: response.data.id,
          title: response.data.desc,
          allDay: response.data.all_day,
          start: response.data.start_time,
          end: response.data.end_time,
          borderColor: '#9ccc65',
          backgroundColor: '#9ccc65',
          textColor: '#fff',
          editable: true,
          className: 'event-editable',
        })
      }
    } else {
      editTitleWarning.style.display = 'inline'
    }
  }

  function resetAddForm() {
    document.getElementById('addTitle').value = ''
    document.getElementById('addStart').value = ''
    document.getElementById('addEnd').value = ''
    document.getElementById('addTime').value = 'all-day'
    document.getElementById('addTitleWarning').style.display = 'none'
    document.getElementById('addStartWarning').style.display = 'none'
    document.getElementById('addEndWarning').style.display = 'none'
    document.getElementById('addFormTime').style.display = 'none'
  }

  async function handleDelete() {
    const id = document.getElementById('editId').value
    const title = document.getElementById('editTitle')
    Swal.fire({
      html: `
				<h2>Apakah anda yakin?</h2
				<p class="m-0 mb-2 f-20">Hapus kegiatan <strong>${title.value}</strong>?</p>
			`,
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Ya, hapus',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`calendar?id=${id}`)
        if (response) {          
          Swal.fire('Kegiatan berhasil dihapus', '', 'success')
          window.$('#editCalendarEvent').modal('hide')
          window.$('#calendar').fullCalendar('removeEvents', response.data.id)
        }
      }
    })
  }

	/* ========================================== Effects ========================================== */
  useEffect(() => {
    getCalender()
  }, [])

	/* =========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Kalender" />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Kalender</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div
                      id="calendar"
                      className="calendar"
                    />
                  </div>
                </div>

                {/* Modal Add Event */}
                <div
                  className="modal fade"
                  id="addCalendarEvent"
                  data-backdrop="static"
                  data-keyboard="false"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myExtraLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header py-3">
                        <h5 className="modal-title">
                          <i className="feather icon-calendar mr-1" />
                          Tambah Kegiatan
                        </h5>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={handleAddSubmit}>
                          <div className="form-group row">
                            <label className="col-form-label col-sm-3">
                              Nama Kegiatan
                            </label>
                            <div className="col-sm-9">
                              <input
                                id="addTitle"
                                type="text"
                                onChange={(e) => {
                                  if (e.target.value)
                                    window.$('#addTitleWarning').hide()
                                }}
                                className="form-control"
                                placeholder="Nama Kegiatan"
                              />
                              <span
                                id="addTitleWarning"
                                style={{ display: 'none' }}
                                className="col small form-text text-danger"
                              >
                                Nama kegiatan tidak boleh kosong
                              </span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-form-label col-sm-3">
                              Tanggal
                            </label>
                            <div className="col-sm-9 d-flex align-items-center">
                              <p
                                className="add-date m-0"
                                data-value=""
                              ></p>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Waktu
                            </label>
                            <div className="form-group col-sm-9">
                              <select
                                id="addTime"
                                className="form-control"
                                onChange={(e) => {
                                  if (e.target.value === 'all-day') {
                                    window.$('#addFormTime').hide()
                                    document.getElementById(
                                      'addStartWarning',
                                    ).style.display = 'none'
                                    document.getElementById(
                                      'addEndWarning',
                                    ).style.display = 'none'
                                  } else if (e.target.value === 'with-time') {
                                    window.$('#addFormTime').show()
                                  }
                                }}
                              >
                                <option value="all-day">Sehari penuh</option>
                                <option value="with-time">
                                  Tentukan jam
                                </option>
                              </select>
                            </div>
                          </div>
                          <div
                            id="addFormTime"
                            className="form-group row"
                            style={{ display: 'none' }}
                          >
                            <div className="offset-sm-3 col-3 ">
                              <input
                                id="addStart"
                                onChange={(e) => {
                                  if (e.target.value)
                                    window.$('#addStartWarning').hide()
                                }}
                                type="time"
                                className="form-control"
                                placeholder="Waktu Mulai"
                              />
                            </div>
                            <div className="col-3	d-flex align-items-center justify-content-center">
                              sampai
                            </div>
                            <div className="col-3 ">
                              <input
                                id="addEnd"
                                onChange={(e) => {
                                  if (e.target.value)
                                    window.$('#addEndWarning').hide()
                                }}
                                type="time"
                                className="form-control"
                                placeholder="Waktu Selesai"
                              />
                            </div>
                          </div>
                          <div
                            id="addStartWarning"
                            style={{ display: 'none' }}
                            className="form-group row mb-0"
                          >
                            <div className="offset-sm-3 col-sm-9">
                              <span className="col small form-text text-danger">
                                Waktu mulai tidak boleh kosong
                              </span>
                            </div>
                          </div>
                          <div
                            id="addEndWarning"
                            style={{ display: 'none' }}
                            className="form-group row"
                          >
                            <div className="offset-sm-3 col-sm-9">
                              <span className="col small form-text text-danger">
                                Waktu selesai tidak boleh kosong
                              </span>
                            </div>
                          </div>
                          <div className="form-group mb-0 d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-outline-danger mr-2"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={resetAddForm}
                            >
                              Tutup
                            </button>
                            <button
                              type="submit"
                              className="btn btn-success"
                            >
                              Simpan
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End of Modal Add Event*/}

                {/* Modal Edit Event */}
                <div
                  className="modal fade"
                  id="editCalendarEvent"
                  data-backdrop="static"
                  data-keyboard="false"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myExtraLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header py-2">
                        <h5 className="modal-title align-self-center">
                          <i className="feather icon-calendar mr-1" />
                          Ubah Kegiatan
                        </h5>
                        <button
                          onClick={handleDelete}
                          type="button"
                          className="btn btn-icon btn-danger m-0"
                          title="Hapus"
                        >
                          <i className="feather icon-trash-2" />
                        </button>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={handleEditSubmit}>
                          <input
                            id="editId"
                            type="hidden"
                          />
                          <div className="form-group row">
                            <label className="col-form-label col-sm-3">
                              Nama Kegiatan
                            </label>
                            <div className="col-sm-9">
                              <input
                                id="editTitle"
                                type="text"
                                onChange={(e) => {
                                  if (e.target.value)
                                    window.$('#editTitleWarning').hide()
                                }}
                                className="form-control"
                                placeholder="Nama Kegiatan"
                              />
                              <span
                                id="editTitleWarning"
                                style={{ display: 'none' }}
                                className="col small form-text text-danger"
                              >
                                Nama kegiatan tidak boleh kosong
                              </span>
                            </div>
                          </div>
                          <div className="form-group mb-0 d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-outline-danger mr-2"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() =>
                                window.$('#editTitleWarning').hide()
                              }
                            >
                              Tutup
                            </button>
                            <button
                              type="submit"
                              className="btn btn-success"
                            >
                              Simpan
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End of Modal Edit Event*/}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React from 'react'
import { avatarDefaultUser } from 'util/constant'
import useForumDetailItem from 'context/forum-detail-item'

export default function ForumDetailAvatar() {
  const { item } = useForumDetailItem()

  return (
    <ForumDetailAvatarWrapper>
      <img
        src={`${item.login?.user.linkAvatar}`}
        onError={(e) => (e.currentTarget.src = avatarDefaultUser)}
        alt={item.login.user.name}
        className="img-radius mb-2"
        style={{ height: '50px', width: '50px' }}
        data-toggle="tooltip"
        title={item.login.user.name}
      />
    </ForumDetailAvatarWrapper>
  )
}

const ForumDetailAvatarWrapper = ({ children }) => (
  <div className="col-2 col-md-1">{children}</div>
)

import BaseButton from 'component/button/BaseButton'
import BaseModalSelectedStudent from 'component/modal/BaseModalSelectedStudent'
import { useContentStudentSpesific } from 'hooks/useContentStudentSpesific'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * Form Select Student
 * 
 * @param {{
 *  contentId?: string
 *  subMapelId: string
 *  isDirectSave: boolean
 *  isWithFooter: boolean
 *  isWithCheckSavedOrNot: boolean
 *  selectedStudents: Array<Student>
 *  originalSelectedStudents: Array<Student>
 *  setSelectedStudents: (newSelectedStudents: Array<Student>) => void
 *  listSelectedStudentsProps?: import('component/modal/BaseModalSelectedStudent').ListSelectedStudentsProps
 *  onSaveHandler?: () => void
 * }} props 
 */
const FormSelectStudent = ({
  selectedStudents,
  originalSelectedStudents,
  setSelectedStudents,
  contentId,
  subMapelId,
  isDirectSave = false,
  listSelectedStudentsProps,
  isWithCheckSavedOrNot = false,
  isWithFooter = false,
  onSaveHandler,
}) => {
  const [showModalSelectedStudent, setShowModalSelectedStudent] = useState(false)
  const { handler } = useContentStudentSpesific()
  const location = useLocation()

  const onSaveSpesificStudentHandler = (onSuccessHandler) => {
    if (isDirectSave) {
      handler.onSaveSpesificStudentHandler({
        classId: subMapelId,
        contentId: contentId,
        onSuccessHandler: () => {
          setShowModalSelectedStudent(false)
          if (onSuccessHandler && typeof onSuccessHandler === 'function') {
            onSuccessHandler()
          }
        },
        selectedContent: location.state?.dataContent || location.state?.existingData,
        selectedStudents,
      })
    }
  }

  return (
    <div className="form-group mb-5 row">
      <label className="col-sm-3 col-form-label">Spesifik Siswa</label>
      <div className="col-sm-9">
        <BaseButton 
          isMarginRight={false}
          onClick={() => setShowModalSelectedStudent(true)}
        >
          <i className='feather icon-user'></i>
          &nbsp;
          Pilih Siswa{selectedStudents?.length ? ` (${selectedStudents?.length})` : null}
        </BaseButton>
      </div>
      <BaseModalSelectedStudent 
        show={showModalSelectedStudent}
        onCloseHandler={() => setShowModalSelectedStudent(false)}
        selectedStudents={selectedStudents}
        onAddOrRemoveStudent={setSelectedStudents}
        isWithFooter={isWithFooter}
        subMapelId={subMapelId}
        contentId={contentId}
        onSaveHandler={onSaveHandler || onSaveSpesificStudentHandler}
        isWithCheckSavedOrNot={isWithCheckSavedOrNot}
        originalSelectedStudents={originalSelectedStudents}
        listSelectedStudentsProps={{
          listStudentsActions: ['clear-all'],
          ...listSelectedStudentsProps,
        }}
      />
    </div>
  )
}

export default FormSelectStudent

import React, { useEffect, useState } from 'react'
import LoadingSpinner from 'component/loading'
import NotFound from 'component/not-found'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { useBaseContext } from 'context/base'
import useContentPlayerContext from 'context/content-player'

const whiteboardStyles = {
  width: '100%',
  height: '100vh',
  border: 'none',
  backgroundColor: '#ffffff',
  overflow: 'auto',
}

export default function ContentWhiteboard({ isPreview = false, content_text, contents_url, content_source, selectedUser }) {
  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()
  const { activity, content, kelas } = useContentPlayerContext()

  const using_contents_url = contents_url || content?.contents_url

  /* ========================================= Constants ======================================== */
  const boardLocalUrl = `http://localhost:3060`
  const boardDevUrl = `https://board.wakool.academy`
  const boardProdUrl = `https://board.pintaredu.id`
  const prodHost = 'lms.pintaredu.id'

  /* =================================== Consume Local Storage ================================== */
  const { id, name, profile_id } = JSON.parse(localStorage.getItem('user'))

  /* ========================================== Helpers ========================================= */
  const handle = useFullScreenHandle()

  /* ======================================= Local States ======================================= */
  const [isLoading, setIsLoading] = useState(false)
  const [whiteBoardUrl, setWhiteboardUrl] = useState('')

  /* ========================================= Functions ======================================== */
  async function init(content) {
    if (using_contents_url) {
      setIsLoading(true)
      const isWhiteboard = await getWhiteboardId(using_contents_url)
      if (isWhiteboard) setIsLoading(false)
    }
  }

  async function getWhiteboardId(whiteboardId) {
    return new Promise((resolve, reject) => {
      const flag = window.location.host.includes(prodHost) ? 'prod' : 'dev'
      const baseUrl = window.location.host.includes(prodHost)
        ? boardProdUrl
        : boardDevUrl
      const url = isPreview
        ? `${baseUrl}/?whiteboardid=${whiteboardId}&username=${selectedUser?.name || name}&userid=${selectedUser?.id || id}&profileid=${profile_id}`
        : `${baseUrl}/?whiteboardid=${whiteboardId}&username=${selectedUser?.name || name}&userid=${id}&profileid=${profile_id}&contentid=${content.id}&classid=${kelas.id}&flag=${flag}`

      setWhiteboardUrl(url)
      setTimeout(() => {
        resolve(true)
      }, 1000)
    })
  }

  async function completeActivities() {
    if (content) {
      let form = new FormData()
      form.append('content_id', content.id)
      form.append('class_id', kelas.id)
      form.append('is_complete', 1)
      const response = await postRequest(
        'class-content-activities',
        form,
      )
      if (response) {
        activity.setIdDone(content.id)
      } else {
        console.error('ERROR', response)
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    return () => {
      setWhiteboardUrl('')
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (content) init(content)
  }, [content, contents_url, selectedUser])

  /* ========================================== Output ========================================== */
  return (
    <>
      {isLoading ? (
        <div className="col mt-3">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="col mt-3">
          {whiteBoardUrl ? (
            <div>
              <button
                className="btn btn-sm btn-primary mb-3"
                onClick={handle.enter}
              >
                <i className="fas fa-expand mr-1" />
                Fullscreen
              </button>
              <FullScreen handle={handle}>
                <iframe
                  onMouseLeave={completeActivities}
                  src={whiteBoardUrl}
                  style={whiteboardStyles}
                />
              </FullScreen>
            </div>
          ) : (
            <NotFound
              text="Whiteboard"
              icon="fas fa-chalkboard"
            />
          )}
        </div>
      )}
    </>
  )
}

import React from 'react'

export default function Status({ item }) {
  const state = item?.report_transactions_periods.length
    ? item?.report_transactions_periods[0].status
    : 'draft'
  return (
    <>
      {state === 'draft' && (
        <span className="badge badge-secondary">
          Belum dilakukan input penilaian
        </span>
      )}
      {state === 'approved' && (
        <span className="badge badge-success">Telah disetujui</span>
      )}
      {state === 'progress' && (
        <span className="badge badge-info">
          Sedang dalam proses input nilai
        </span>
      )}
      {state === 'submission' && (
        <span className="badge badge-warning">
          Menunggu Persetujuan Kepala Sekolah
        </span>
      )}
      {state === 'rejected' && (
        <span className="badge badge-danger">Rapor Ditolak</span>
      )}
    </>
  )
}

import React, { useState } from 'react';

const Jawaban = ({ index, text, handleDelete, handleText }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [textVal, setTextVal] = useState(text);

  return (
    <li onMouseEnter={() => setShowDelete(true)} onMouseLeave={() => setShowDelete(false)} key={index}>
      <div className="form-group row">
        <div className="col-sm-9">
          <input value={textVal} onChange={(event) => setTextVal(event.target.value)} onBlur={() => handleText(index, textVal)} type="text" className="form-control" placeholder={`Pilihan-${index+1}`} />
        </div>
        <div className="col-sm-3 py-2 d-flex justify-content-between">
          <button onClick={() => handleDelete(index)} type="button" className="btn btn-icon btn-danger" title="Hapus" style={{ visibility: showDelete ? "visible" : "hidden" }}>
            <i className="feather icon-trash-2" />
          </button>
        </div>
      </div>
    </li>
  );
}

export default Jawaban;
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import MatpelAdmin from './index-administrator'
import MatpelInstruktur from './index-instruktur'

export default function MataPelajaran() {
	/* ====================================== Consume Context ====================================== */
  const { getRequest } = useBaseContext()

	/* ======================================== Local States ======================================= */
  const [roleActive, setRoleActive]         = useState('')
  const [listAngkatan, setListAngkatan]     = useState([])
  const [selectAngkatan, setSelectAngkatan] = useState('')

	/* ========================================= Functions ========================================= */
  async function getSemesterCode() {
    try {
      let response = await getRequest(`semester`)
      if (response) {
        let arr = [{ value: '', label: 'Semua' }]
        response.data.forEach((val) => {
          arr.push({
            value: val.code,
            label: `${val.tahun_mulai}/${val.tahun_selesai} - ${
              val.title_render
            } ${val.status ? '(Aktif)' : ''}`,
          })
        })
        setListAngkatan(arr)
      }
    } catch (error) {
      console.error('ERROR', { error })
    }
  }

  function checkRole() {
    const { role_type } = JSON.parse(localStorage.getItem('role_active'))
    setRoleActive(role_type)
  }

	/* ========================================== Effects ========================================== */
  useEffect(() => {
    checkRole()
    getSemesterCode()
  }, [])

	/* =========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Daftar Sub Mata Pelajaran"
          linkName="Sub Mata Pelajaran"
        />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header row align-items-center">
                <div className="col-sm-8">
                  <div style={{ width: '300px' }}>
                    <span className="f-w-700 f-14">Semester</span>
                    <Select
                      placeholder="Pilih Semester..."
                      isMulti={false}
                      options={listAngkatan}
                      value={selectAngkatan}
                      onChange={(option) => {
                        setSelectAngkatan(option)
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className='d-flex justify-content-end'>
                    <Link to="/kelas-add">
                      <button
                        type="button"
                        className="btn btn-primary mt-2 has-ripple"
                      >
                        <i className="feather icon-plus-circle mr-1" />
                        <span>Tambah Sub Mata Pelajaran</span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {roleActive === 'administrator' ||
                roleActive === 'adminprofile' ? (
                  <MatpelAdmin selectAngkatan={selectAngkatan} />
                ) : null}
                {roleActive === 'instruktur' ? (
                  <MatpelInstruktur selectAngkatan={selectAngkatan} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import useGetRoleActive from 'hooks/useGetRoleActive';
import React from 'react'

/**
 * Base Input
 * 
 * @param {{
 *  id: string,
 *  placeholder: string,
 *  label: string,
 *  access: Array,
 *  isWithLabel: boolean,
 *  isLoading: boolean,
 *  isDisabled: boolean,
 *  props: import('react').InputHTMLAttributes,
 * }} props
 */
const BaseInputTextArea = ({ id = 'exampleInputEmail1', placeholder = 'Placeholder', label = 'Label', access = [], isWithLabel = true, isDisabled = false, isLoading = false, props }) => {
  const roleActive = useGetRoleActive();

  const isNotAccess = access.length ? !access.includes(roleActive.type) : false

  return (
    <div className="form-group">
      {isWithLabel ?
        <label htmlFor={id}>{label}</label>
        : null}
      <textarea {...props} className="form-control" disabled={isNotAccess || isDisabled || isLoading} id={id} aria-describedby={`${placeholder}Help`} placeholder={placeholder}></textarea>
    </div>
  )
}

export default BaseInputTextArea
import React, { useState } from 'react'
import moment from 'moment'

import { useBaseContext } from 'context/base'
import { avatarDefaultUser } from 'util/constant'

export default function CommentFeed({
  masterFeedComment,
  getFeedComment,
  stateComment,
  listSuggest,
}) {
  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const { linkAvatar } = JSON.parse(localStorage.getItem('user'))

  /* ========================================== States ========================================== */
  const [comment, setComment] = useState('')

  /* ========================================= Functions ======================================== */
  async function handleCommentSubmit(val) {
    try {
      let form = new FormData()
      form.append('feed_id', val.feed_id)
      if (val.feed_respon_id) {
        form.append('feed_respon_id', val.feed_respon_id)
      }
      form.append('feed_respon_text', comment)
      form.append('content_id', val.content_id)
      form.append('parent_id', val.id)
      const response = await postRequest(`comment-feed-temp`, form)
      if (response) {
        window.notification('Komentar Feedback', 'berhasil dikirim', 'success')
        setComment('')
        getFeedComment(val.content_id, val.id)
      }
    } catch (error) {
      console.error('ERROR', error)
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <div className="pt-4">
      <div className="row justify-content-between mb-2">
        <div className="col-auto">
          <a
            href="#!"
            onClick={(e) => e.preventDefault()}
            className="text-muted text-h-primary"
          >
            Komentar ({stateComment.length})
          </a>
        </div>
      </div>
      {stateComment.length > 0 ? (
        stateComment.map((item, idx) => {
          let dd = moment(item.updatedAt).add(30, 'days').valueOf()
          let dates =
            dd < moment().valueOf()
              ? moment(item.updatedAt).format('DD MMM YYYY')
              : moment(item.updatedAt).fromNow()
          return (
            <div key={idx}>
              <div className="media mb-0">
                <img
                  style={{ width: '30px', height: '30px', objectFit: 'cover' }}
                  src={`${item.user.linkAvatar}`}
                  onError={(e) => (e.currentTarget.src = avatarDefaultUser)}
                  alt="userimage"
                  className="img-radius align-top m-r-15"
                />
                <div className="media-body">
                  <h6 className="mb-0 text-dark">{item.user.name}</h6>
                  <p
                    className="text-muted mb-2"
                    style={{ fontSize: '10px' }}
                  >
                    <i className="feather icon-clock mr-1" />
                    {dates}
                  </p>
                  <p className="m-b-0">{item.feed_respon_text}</p>
                </div>
              </div>
              <hr />
            </div>
          )
        })
      ) : (
        <div className="text-center">
          <p className="text-muted p-2">Belum ada komentar</p>
          <hr />
        </div>
      )}
      <div className="media mb-0">
        <img
          src={`${linkAvatar}`}
          onError={(e) => (e.currentTarget.src = avatarDefaultUser)}
          style={{ width: '40px', height: '40px', objectFit: 'cover' }}
          alt="userimage"
          className="img-radius wid-40 align-top m-r-15"
        />
        <div className="media-body">
          <div className="form-group">
            <input
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              className="form-control"
              id="comment2"
              type="text"
              placeholder="Tulis komentar disini..."
              list="suggestions"
            />
            <datalist id="suggestions">
              {comment != '' &&
                listSuggest.map((item, idx) => {
                  return (
                    <option
                      value={item.feed_respon_text}
                      key={idx}
                    />
                  )
                })}
            </datalist>
            <div className="form-icon">
              <button
                disabled={comment == ''}
                onClick={() => handleCommentSubmit(masterFeedComment)}
                type="button"
                className="btn btn-primary btn-icon"
              >
                <i className="feather icon-message-circle" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

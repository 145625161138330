import localforage from 'localforage'
import { useEffect, useState } from 'react'

export function useStale(initialValue, key, debounceDuration = 750) {
  /* ======================================= Local States ======================================= */
  const [loading, setLoading]     = useState(false)
  const [data, setData]           = useState(null)
  const [isDrafted, setIsDrafted] = useState(false)

  /* ========================================= Constants ======================================== */
  let debounceTimeout

  /* ========================================= Functions ======================================== */
  async function invalidate() {
    await localforage.removeItem(key)
    setData(initialValue)
    setIsDrafted(false)

    const el = document.getElementById(key)
    if (el) el.value = initialValue
  }

  function stale(event) {
    clearTimeout(debounceTimeout)
    const newValue = event?.target?.value
    if (!newValue || newValue === initialValue) {
      invalidate()
    } else {
      setIsDrafted(false)

      debounceTimeout = setTimeout(async () => {
        await localforage.setItem(key, newValue)
        setData(newValue)
        setIsDrafted(true)
      }, debounceDuration)
    }
  }

  async function getStaledData() {
    setLoading(true)
    const cached = await localforage.getItem(key)
    setLoading(false)
    if (cached) {
      setIsDrafted(true)
      setData(cached)
    } else {
      setData(initialValue)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getStaledData()
  }, [])

  /* ========================================== Output ========================================== */
  return {
    loading,
    data,
    isDrafted,
    invalidate,
    stale,
  }
}

import React from 'react'
import useContentPlayerContext from 'context/content-player'

export default function AlertInfo() {
  /* ====================================== Consume Context ===================================== */
  const { content, forum } = useContentPlayerContext()

  /* ========================================== Output ========================================== */
  return (
    ((content.feedback && !content.feed?.feed_temps.length) ||
      (content.group_status && !forum.participated)) && (
      <div
        className="mt-2 border rounded alert alert-warning w-100"
        role="alert"
      >
        <strong>
          <i className="fas fa-info-circle mr-1" />
          Anda wajib
          {content.feedback &&
            !content.feed?.feed_temps.length &&
            ` memberikan feedback`}
          {content.feedback && !content.feed?.feed_temps.length &&
            content.group_status &&
            !forum.participated &&
            ` dan`}
          {content.group_status &&
            !forum.participated &&
            ` terlibat dalam diskusi kecil`}{' '}
          pada materi.
        </strong>
      </div>
    )
  )
}

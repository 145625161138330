import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import Logo from './components/logo'

export default function NewPassword() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const param = useParams()

  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [password, setPassword]     = useState('')
  const [retypePass, setRetypePass] = useState('')

  const [hideNew, setHideNew]       = useState(true)
  const [hideRetype, setHideRetype] = useState(true)
  const [notMatch, setNotMatch]     = useState(false)
  const [loading, setLoading]       = useState(false)

  /* =========================================== Refs =========================================== */
  const refPass   = useRef()
  const refRetype = useRef()

  /* ========================================= Functions ======================================== */
  async function handleSavePassword() {
    if (password) {
      if (password == retypePass) {
        setNotMatch(false)
        setLoading(true)

        var form = new FormData()
        form.append('new_password', password)
        form.append('token', param.token)

        let response = await postRequest('forgot-change-password', form)
        if (response) {
          setLoading(false)
          setTimeout(function () {
            history.push(`/login`)
            window.location.reload()
          }, 3000)
          window.notification(
            'Berhasil',
            'Password baru anda sudah kami simpan, silahkan login untuk masuk menggunakan akun anda',
            'success',
          )
        } else {
          window.notification(
            'Terjadi kesalahan',
            'Mohon coba beberapa saat lagi',
            'error',
          )
        }
      } else {
        refRetype.current.focus()
        setNotMatch(true)
      }
    } else {
      refPass.current.focus()
      window.notification('Gagal', 'Mohon masukan password baru anda', 'error')
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <div className="auth-wrapper bg-primary">
      <div className="auth-content align-items-center text-center">
        <Logo />
        <div className="card">
          <div className="row align-items-center text-center">
            <div className="col-md-12">
              <div className="card-body">
                <div className="mb-4 text-center">
                  <h4 style={{ fontSize: '1.4rem' }}>Masukan password baru</h4>
                </div>
                <form>
                  <div className="row">
                    <div className="col-10 pr-0">
                      <div className="form-group mb-3">
                        <label
                          className="floating-label"
                          htmlFor="NewPass"
                        >
                          New Password
                        </label>
                        <input
                          ref={refPass}
                          onChange={(event) => {
                            setPassword(event.target.value)
                          }}
                          value={password}
                          type={hideNew ? 'password' : 'text'}
                          className="form-control"
                          id="NewPass"
                        />
                      </div>
                    </div>
                    <div className="col-2 pl-0">
                      <div className="form-group mt-2">
                        <button
                          onClick={() => setHideNew(!hideNew)}
                          className="btn btn-light btn-sm rounded-circle"
                          type="button"
                        >
                          <i className="fa fa-eye pt-1" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-10 pr-0">
                      <div className="form-group mb-3">
                        <label
                          className="floating-label"
                          htmlFor="RetypeNewPass"
                        >
                          Retype Password
                        </label>
                        <input
                          ref={refRetype}
                          onChange={(event) => {
                            setRetypePass(event.target.value)
                          }}
                          value={retypePass}
                          type={hideRetype ? 'password' : 'text'}
                          className="form-control"
                          id="RetypeNewPass"
                        />
                        {notMatch && (
                          <span className="text-left small form-text text-danger">
                            Password tidak sama.
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-2 pl-0">
                      <div className="form-group mt-2">
                        <button
                          onClick={() =>
                            setHideRetype(!hideRetype)
                          }
                          className="btn btn-light btn-sm rounded-circle"
                          type="button"
                        >
                          <i className="fa fa-eye pt-1" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    disabled={loading}
                    onClick={() => {
                      handleSavePassword()
                    }}
                    type="button"
                    className="btn btn-block btn-primary mt-4 mb-4"
                  >
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                    )}
                    <span>Save Password</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span
        className="text-light"
        style={{
          position: 'absolute',
          bottom: '7px',
          fontSize: '13px',
          fontWeight: '400',
        }}
      >
        <span>Copyright &copy; 2021</span>
        <a
          className="text-light ml-1"
          href="https://millennia-solusi.id"
          target="_blank"
          style={{
            fontWeight: '300',
          }}
        >
          Millennia Solusi Informatika
        </a>
      </span>
    </div>
  )
}

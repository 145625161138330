import React from 'react'
import { useHistory } from 'react-router-dom'

export default function SiswaItem({ siswa, assignmentId }) {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ========================================= Functions ======================================== */
  const handleClickLihatBtn = () => {
    history.push(`/kelola-assignment/${assignmentId}/detail/${siswa.id}`)
  }

  /* ========================================== Output ========================================== */
  return siswa ? (
    <tr>
      <td>
        {siswa.nim ? (
          siswa.nim
        ) : (
          <span
            className="f-w-300"
            style={{ fontSize: '12px' }}
          >
            Tidak terdaftar
          </span>
        )}
      </td>
      <td>{siswa.name}</td>

      <td>
        <button
          onClick={handleClickLihatBtn}
          type="button"
          className="btn btn-sm btn-info"
        >
          <i className="fas fa-eye mr-1" />
          <span>Lihat Tugas Siswa</span>
        </button>
      </td>
    </tr>
  ) : (
    <tr></tr>
  )
}

import React, { useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { editorConfiguration } from 'util/ckeditor-config'

const Jawaban = ({
  index,
  text,
  correct,
  handleDelete,
  handleCorrect,
  handleText,
}) => {
  /* ======================================= Local States ======================================= */
  const [showDelete, setShowDelete] = useState(false)
  const [textVal, setTextVal] = useState(text)

  /* ========================================== Output ========================================== */
  return (
    <li
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
      key={index}
    >
      <div className="form-group row">
        <div className="col-sm-9">
          <CKEditor
            editor={Editor}
            config={{...editorConfiguration, placeholder: 'Tulis jawaban...'}}
            data={textVal}
            onChange={(_, editor) => {
              const data = editor.getData()
              setTextVal(data)
            }}
            onBlur={(_, editor) => {
              const data = editor.getData()
              handleText(index, data)
            }}
          />
        </div>
        <div className="col-sm-3 py-2 d-flex justify-content-between">
          <div className="form-check d-flex align-items-center">
            <input
              onChange={() => handleCorrect(index)}
              className="form-check-input"
              type="radio"
              name="correct"
              id={index}
              defaultValue={1}
              checked={correct === 1}
            />
            <label
              className="form-check-label mt-1"
              htmlFor={index}
            >
              Benar
            </label>
          </div>
          <button
            onClick={() => handleDelete(index)}
            type="button"
            className="btn btn-icon btn-danger mt-2"
            title="Hapus"
            style={{ visibility: showDelete ? 'visible' : 'hidden' }}
          >
            <i className="feather icon-trash-2" />
          </button>
        </div>
      </div>
    </li>
  )
}

export default Jawaban

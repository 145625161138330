import React from 'react'
import {
  ContentText,
  ContentAudio,
  ContentVideo,
  ContentDocument,
  ContentIframe,
  ContentQuiz,
  ContentSurvey,
  ContentAssignment,
  ContentGraphor,
  ContentWhiteboard,
} from 'component/content'
import NotFound from 'component/not-found'
import TabKeyTakeAway from './tab-key-take-away'
import TabRating from './tab-rating'
import TabHeader from './tab-header'
import TabNotes from './tab-notes'
import SegmentFeedback from './segment-feedback'
import SegmentAlert from './segment-alert'
import useContentPlayerContext from 'context/content-player'
import { MainContentWrapper } from 'context/main-content'

export default function SegmentMainContentPlayer() {
  /* ====================================== Consume Context ===================================== */
  const { content, playlist, activity } = useContentPlayerContext()

  /* ========================================== Output ========================================== */
  return content?.id ? (
    <MainContentWrapper>
      <Col>
        <div className="mb-3">
          <h4>{content.title}</h4>
          {content?.deskripsi && (
            <div className="border rounded p-3 mb-3">
              <h6 className="f-w-500">Instruksi:</h6>
              <p className="m-0">{content.deskripsi}</p>
            </div>
          )}
          {content?.content_type_id === 1 && <ContentText />}
          {content?.content_type_id === 2 && <ContentAudio />}
          {content?.content_type_id === 3 && <ContentVideo />}
          {content?.content_type_id === 4 && <ContentDocument />}
          {content?.content_type_id === 5 && <ContentIframe />}
          {content?.content_type_id === 6 && <ContentQuiz />}
          {content?.content_type_id === 7 && <ContentSurvey />}
          {content?.content_type_id === 8 && <ContentAssignment />}
          {content?.content_type_id === 9 && <ContentGraphor />}
          {content?.content_type_id === 10 && <ContentWhiteboard />}
          <SegmentAlert />
        </div>
        {activity.isContentCompleted && (
          <>
            <TabHeader />
            <TabContentContainer>
              <TabRating />
              <TabKeyTakeAway />
              <TabNotes />
            </TabContentContainer>
          </>
        )}

        <SegmentFeedback />
      </Col>
    </MainContentWrapper>
  ) : (
    <div
      className={`${
        playlist.isShow ? 'col-xl-8' : 'col-xl-12'
      } mb-3 align-items-center justify-content-center d-flex`}
    >
      <NotFound text={'Anda belum memilih materi'} />
    </div>
  )
}

/* ========================================= Components ========================================= */
const TabContentContainer = ({ children }) => (
  <div
    className="tab-content"
    id="pills-tabContent"
  >
    {children}
  </div>
)

const Col = ({ children }) => {
  const { playlist } = useContentPlayerContext()

  return (
    <div className={`${playlist.isShow ? 'col-xl-8' : 'col-xl-12'} mb-3`}>
      {children}
    </div>
  )
}

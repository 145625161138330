import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'

export default function ApprovalShareClass() {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, putRequest, deleteRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [profileClass, setProfileClass] = useState(null)
  const [limit, setLimit]               = useState(10)
  const [offset, setOffset]             = useState(0)
  const [keyword, setKeyword]           = useState('')

  /* ========================================= Functions ======================================== */
  async function getProfileClass() {
    let response = await getRequest(
      `classes-profiles-approval?limit=${limit}&offset=${offset}&keyword=${keyword}`,
    )
    if (response) {
      setProfileClass(response)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getProfileClass()
  }, [limit, offset, keyword])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Sub Mata Pelajaran Approval" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-body">
                <DataTable
                  className={'table table-striped table-bordered nowrap'}
                  data={profileClass?.data}
                  totalRows={profileClass?.totalRecords}
                  show={[10, 20, 30, 40, 50]}
                  onChangeSearch={(val) => {
                    setKeyword(val)
                  }}
                  onChangeLimit={(val) => {
                    setLimit(val)
                  }}
                  onChangePage={(val) => {
                    setOffset(val)
                  }}
                  column={[
                    'Profil Tujuan',
                    'Sub Mata Pelajaran',
                    'Mata Pelajaran',
                    'Status',
                    'Actions',
                  ]}
                  renderItem={(item, index) => (
                    <RenderItem
                      item={item}
                      key={index}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )

  function RenderItem({ item }) {
    var showBtn = true

    if (item.status == 'rejected' || item.status == 'approved') showBtn = false

    async function deleteItem(id) {
      Swal.fire({
        title: 'Apa Anda yakin?',
        text: `Anda akan menolak data ini?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Tidak',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await deleteRequest(
            `classes-profiles-rejected?id=${id}`,
          )
          if (response) {
            Swal.fire(
              'Berhasil!',
              'Sub Mata Pelajaran berhasil ditolak',
              'success',
            ).then(() => window.location.reload())
          } else {
            Swal.fire(
              'Oppsss!',
              'Terjadi kesalahan coba beberapa saat lagi.',
              'error',
            )
          }
        }
      })
    }

    async function approvedItem(id) {
      Swal.fire({
        title: 'Apa Anda yakin?',
        text: `Anda akan menyetujui data ini?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, setuju!',
        cancelButtonText: 'Tidak',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await putRequest(
            `classes-profiles-approved?id=${id}`,
          )
          if (response) {
            Swal.fire(
              'Berhasil!',
              'Sub Mata Pelajaran berhasil disetujui',
              'success',
            ).then(() => window.location.reload())
          } else {
            Swal.fire(
              'Oppsss!',
              'Terjadi kesalahan coba beberapa saat lagi.',
              'error',
            )
          }
        }
      })
    }

    return (
      <tr>
        <td>{item.profile.name}</td>
        <td>{item.class.title}</td>
        <td style={{ whiteSpace: 'normal' }}>
          <div style={{ width: 250 }}>
            {item.class.class_profiles[0].category.title} -{' '}
            {item.class.class_profiles[0].category.categories_type?.type}
          </div>
        </td>
        <td>
          {item.status == 'approved' && (
            <span className="badge badge-success">Disetujui</span>
          )}
          {item.status == 'rejected' && (
            <span className="badge badge-danger">Ditolak</span>
          )}
          {item.status == 'submission' && (
            <span className="badge badge-warning">Menunggu Persetujuan</span>
          )}
        </td>
        <td style={{ verticalAlign: 'inherit', padding: '14px' }}>
          {showBtn && (
            <button
              onClick={() => approvedItem(item.id)}
              className="btn btn-sm btn-success mr-2"
            >
              <span>Setuju</span>
            </button>
          )}
          {showBtn && (
            <button
              onClick={() => deleteItem(item.id)}
              className="btn btn-sm btn-danger mr-2"
            >
              <span>Tolak</span>
            </button>
          )}
        </td>
      </tr>
    )
  }
}

import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d'
import usePreviewRapor from 'context/preview-rapor'

highcharts3d(Highcharts)

export default function FeedbackChartV2() {
  /* ====================================== Consume Context ===================================== */
  const { rapor } = usePreviewRapor()

  /* ========================================== Options ========================================= */
  const chartOpt = {
    chart: {
      type: 'column',
      height: 265,
      options3d: {
        enabled: true,
        alpha: 24,
        beta: 8,
        depth: 34,
        viewDistance: 25,
      },
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      categories: [
        'Memberikan',
        'Belum Menerima',
        'Menerima',
        'Belum Merespon',
      ],
      crosshair: true,
      title: {
        text: '',
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    legend: {
      enabled: false,
    },
    series: [{
      name: "Keaktifan feedback",
      data: [
        rapor.feedback?.countSend,
        rapor.feedback?.countRemains,
        rapor.feedback?.countReceived,
        rapor.feedback?.countRemainsReply,
      ],
      colorByPoint: true,
    }],
  }

  /* ========================================== Output ========================================== */
  return (
    <div id="preview-rapor-block__feedback-chart" className="col-6">
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOpt}
      />
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useBaseContext } from 'context/base'

export default function UserItem({
  item,
  number,
  categoryId,
  reportGroupId,
  nilai,
  semester_id,
  handleChecklist = ({
    event,
    userId,
    taSemesterCode,
    currentListChecklist = [],
    setListChecklist,
    setChecklistAll,
    reportGroupId,
    categoryId,
  }) => {},
  currentListChecklist = [],
  setListChecklist,
  setChecklistAll,
}) {
  /* ========================================== Helpers ========================================= */
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const group_id = query.get('group_id')

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [loadingScoreTemp, setLoadingScoreTemp] = useState('idle')  // idle / start / finish
  const [score, setScore]                       = useState('')
  const [predicate, setPredicate]               = useState('')
  const [description, setDescription]           = useState('')
  const [approvalStatus, setApprovalStatus]     = useState('')
  const [badge, setBadge]                       = useState('')
  const [isChecked, setIsChecked]               = useState(false)
  const [rowData, setRowData]                   = useState(null)

  /* ========================================= Functions ======================================== */
  async function getScoreTemp(user_id, category_id, reportGroupId) {
    const response = await getRequest(
      `report-temp-by-id-group?id=${reportGroupId}&user_id=${user_id}&category_id=${category_id}&ta_semester_code=${semester_id}&group_id=${group_id}`,
    )
    return response ? response.data : null
  }

  function resetStates() {
    setScore('')
    setPredicate('')
    setDescription('')
    setApprovalStatus('')
  }

  async function initScoreData(item, reportGroupId) {
    setLoadingScoreTemp('start')
    const data = await getScoreTemp(
      item.user_id,
      item.categories_id,
      reportGroupId,
    )
    setRowData(data)
    if (typeof data === 'object' && data !== null) {
      const value = JSON.parse(data.value)
      setScore(value.nilai)
      setPredicate(value.predikat)
      setDescription(value.deskripsi)
      if (data.approval_status === 'draft') {
        setBadge('badge-warning')
        setApprovalStatus('Masih dalam proses input')
      } else if (data.approval_status === 'approved') {
        setBadge('badge-success')
        setApprovalStatus('disetujui')
      } else if (data.approval_status === 'rejected') {
        setBadge('badge-danger')
        setApprovalStatus('Nilai ditolak')
      } else if (data.approval_status === 'submission') {
        setBadge('badge-warning')
        setApprovalStatus('Menunggu persetujuan')
      } else {
        setBadge('badge-danger')
        setApprovalStatus('Belum ada proses input')
      }
    } else {
      resetStates()
      setBadge('badge-danger')
      setApprovalStatus('Belum ada proses input')
    }
    setLoadingScoreTemp('finish')
  }

  function initChecked(currentListChecklist = [], item) {
    setIsChecked(false)
    if (currentListChecklist.length > 0) {
      for (let currentChecklist of currentListChecklist) {
        if (currentChecklist.user_id === item.user_id) {
          setIsChecked(true)
          break
        }
      }
    }
  }

  function CheckBoxInput() {
    if (
      rowData?.approval_status === 'submission' ||
      rowData?.approval_status === 'approved'
    ) {
      return (
        <input
          // disabled={rowData?.approval_status==='submission'?false:true}
          type="checkbox"
          className="form-control"
          style={{ width: 16, height: 16, cursor: 'pointer' }}
          checked={isChecked}
          onChange={(event) => {
            handleChecklist({
              event: event,
              userId: item.user_id,
              taSemesterCode: item.ta_semester_code,
              currentListChecklist: currentListChecklist,
              setListChecklist: setListChecklist,
              setChecklistAll: setChecklistAll,
              reportGroupId: reportGroupId,
              categoryId: categoryId,
            })
          }}
        />
      )
    } else {
      return null
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    initScoreData(item, reportGroupId)
  }, [categoryId, item])

  useEffect(() => {
    initChecked(currentListChecklist, item)
  }, [currentListChecklist])

  /* ========================================== Output ========================================== */
  return (
    <tr>
      <td>
        <CheckBoxInput />
      </td>
      <td className="text-center">{number}</td>
      <td className="text-center">{item.user_nim}</td>
      <td className="text-center">{item.user_name}</td>
      <td>
        <span className={`badge ${badge}`}>{approvalStatus}</span>
      </td>
      {loadingScoreTemp === 'idle' && <td colSpan={3}></td>}
      {loadingScoreTemp === 'start' && (
        <td colSpan={3}>
          <div className="d-flex justify-content-center align-items-center">
            <span
              className="spinner-border text-primary"
              role="status"
              style={{
                height: '22px',
                width: '22px',
              }}
            />
          </div>
        </td>
      )}
      {loadingScoreTemp === 'finish' && (
        <>
          <td className="text-center">{score ? score : '-'}</td>
          <td className="text-center">{predicate}</td>
          {nilai.map((item, i) => {
            if (item.all_score.length > 0) {
              return (
                <>
                  {item.all_score.map((items, index) => {
                    return (
                      <td
                        key={`${index}`}
                        title={`${items.class_title} - ${items.content_title}`}
                        className="text-center"
                      >
                        {items.score}
                      </td>
                    )
                  })}
                </>
              )
            } else {
              return (
                <td
                  key={`${i}`}
                  title="-"
                  className="text-center"
                >
                  -
                </td>
              )
            }
          })}
          <td
            className="text-center"
            style={{ whiteSpace: 'normal' }}
          >
            <div style={{ width: 350, textAlign: 'justify' }}>
              {description}
            </div>
          </td>
        </>
      )}
    </tr>
  )
}

import moment from 'moment'
import React from 'react'
import CkeditorReadonly from 'component/ckeditor-readonly'
import NotFound from 'component/not-found'

export default function KeyTakeAways({ content }) {
  return content?.content_key_take_aways?.length ? (
    content?.content_key_take_aways?.map((kta, index_kta) => (
      <div
        key={`notes_map_lv3_` + index_kta}
        className="accordion-collapse collapse show"
      >
        <div
          className="accordion-body ml-2 mr-2"
          key={index_kta}
        >
          <div
            className="card text-dark mb-2"
            style={styles.card}
          >
            <div
              className="card-header"
              style={styles.header}
            >
              Key Take Away
            </div>
            <div className="card-body">
              <CkeditorReadonly data={kta.description} />
              <p className="text-right f-w-700 f-12">
                <i className="fas fa-calendar-alt mr-1" />
                {moment(kta.updatedAt).format('dddd, DD MMMM YYYY')}
              </p>
            </div>
          </div>
        </div>
      </div>
    ))
  ) : (
    <div className="accordion-collapse collapse show">
      <div className="accordion-body p-5">
        <NotFound
          text={'Tidak ada key take away'}
          icon="fas fa-file"
        />
      </div>
    </div>
  )
}

/* =========================================== Styles =========================================== */
const styles = {
  card: {
    border: '1px solid #4680fe',
  },
  header: {
    paddingTop: '10px',
    paddingBottom: '10px',
    borderBottom: '1px solid #4680fe',
  },
}

import React from 'react'

export default function AssignmentPending() {
  return (
    <div className="col-12 mb-3">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-center">
            <div
              className="rounded-circle p-2 my-3"
              style={{
                border: '3px solid #ffba57',
              }}
            >
              <i className="fas fa-info-circle text-warning f-48" />
            </div>
          </div>
          <p className="text-center f-20 mb-0">Mohon Tunggu Ya!</p>
          <p className="text-center f-20">
            Guru anda sedang mengoreksi tugas ini
          </p>
        </div>
      </div>
    </div>
  )
}

import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import Dashboard from 'component/report-submapel/dashboard'
import ListSiswa from 'component/report-submapel/siswa-list-progress'
import Heatmap from 'component/report-submapel/heatmap'
import { Back } from 'component/button'

export default function AktivitasKelasReport() {
  /* ========================================== Helpers ========================================= */
  const location = useLocation()

  /* ======================================= Local States ======================================= */
  const [currentTab, setCurrentTab] = useState('dashboard')

  /* ========================================= Constants ======================================== */
  const links = [
    {
      url: '/laporan-aktifitas-kelas',
      name: 'Laporan Aktifitas Sub Mata Pelajaran',
    },
  ]

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Laporan Aktivitas Sub Mata Pelajaran"
          linkName={location.state?.detailKelas?.title || ''}
          links={links}
        />

        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-body">
                <ul
                  className="nav nav-tabs mb-3"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active text-uppercase"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      onClick={() => setCurrentTab('dashboard')}
                    >
                      Dashboard
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-uppercase"
                      id="profile-tab"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      onClick={() => setCurrentTab('list-siswa')}
                    >
                      Siswa
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-uppercase"
                      id="heatmap-tab"
                      data-toggle="tab"
                      href="#heatmap"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      onClick={() => setCurrentTab('heatmap')}
                    >
                      Heatmap
                    </a>
                  </li>
                </ul>
                <div
                  className="tab-content"
                  id="myTabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <Dashboard />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <ListSiswa kelasTitle={location.state?.detailKelas?.title} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="heatmap"
                    role="tabpanel"
                    aria-labelledby="heatmap-tab"
                  >
                    {currentTab === 'heatmap' && (
                      <Heatmap kelasTitle={location.state?.detailKelas?.title} />
                    )}
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <Back />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

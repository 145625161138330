import React from 'react'
import CkeditorComponent from 'component/ckeditor'
import useContentPlayerContext from 'context/content-player'
import CardNotes from './card-notes'

export default function TabNotes() {
  /* ====================================== Consume Context ===================================== */
  const { tab, notes } = useContentPlayerContext()

  /* ========================================== Output ========================================== */
  return (
    tab.current === 3 && (
      <div
        className="tab-pane fade show active"
        id="pills-notes"
        role="tabpanel"
        aria-labelledby="pills-contact-tab"
      >
        <div className="border bg-light rounded p-2">
          <div className="form-group">
            <label
              htmlFor="nama-kelas"
              className="col-sm-3 col-form-label"
            >
              <span className="mr-1">Catatan</span>
            </label>
            <div className="col-12">
              <CkeditorComponent
                data={notes.data}
                onChange={(data) => notes.set(data)}
              />
              <div className="d-flex justify-content-end mt-2">
                <button
                  disabled={notes.loading}
                  className="btn btn-outline-primary"
                  type="button"
                  onClick={() => notes.create()}
                >
                  {notes.loading ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    >
                      <span className="visually-hidden"></span>
                    </div>
                  ) : (
                    'Simpan'
                  )}
                </button>
              </div>
            </div>
            <div className="col-12 mt-5">
              {notes.list?.map((item, index) => (
                <CardNotes
                  key={`card_notes_` + index}
                  item={item}
                  index={notes.list.length - index}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  )
}

import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { Helmet } from 'react-helmet'

import { useBaseContext } from 'context/base'
import { defaultTitle } from 'util/constant'

const Cryptr = require('cryptr')
const cryptr = new Cryptr('$%^*LMSmJk2#$5')

export default function AuthSetToken() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { setValue, getRequest, postRequest } = useBaseContext()

  /* ========================================= Functions ======================================== */
  async function setToken() {
    var query = window.location.search
    var params = new URLSearchParams(query)
    var token = params.get('token')
    var page = params.get('page')
    var decode = cryptr.decrypt(token)
    var spl = decode.split('&')
    var form = new FormData()
    form.append('username', spl[0])
    form.append('password', spl[1])
    form.append('profile', spl[2])
    let response = await postRequest('auth/signin', form)
    if (response) {
      let tokenDecode = await jwt_decode(response.accessToken)
      localStorage.setItem('user', JSON.stringify(tokenDecode.user))
      localStorage.setItem('profile', JSON.stringify(tokenDecode.profile))
      localStorage.setItem('roles', JSON.stringify(response.roles))
      localStorage.setItem('role_active', JSON.stringify(response.role_active))
      localStorage.setItem('menu_key', JSON.stringify(tokenDecode.menu_key))
      localStorage.setItem('login_id', JSON.stringify(tokenDecode.login_id))
      localStorage.setItem('token', response.accessToken)
      localStorage.setItem('menus_allow', JSON.stringify(response.menus))
      localStorage.setItem('expired', '1')

      var aktif = response.role_active
      if (aktif.role == 'Siswa') {
        pages()
      } else {
        var roles = response.roles
        var id = ''
        roles.forEach((val) => {
          if (val.role == 'Siswa') {
            id = val.id
          }
        })
        await getNewTokenRole(id, page)
      }      
    }
  }

  async function pages() {
    // http://localhost:3030/auth-token?page=quiz&class_id=3a6a5bbe-fbef-492b-9c80-9e39ca47fa5f&content_id=868cd94a-d48f-49ea-99e5-423318db9873&token=82b14c0c67168a822989d7abf533df6093f8fbaff09f239b1aa47c377fb720b06ee06b835211d6473390efddb90a7697d7b3bc473b1e722c9cec537af1ca877209ec637527c7123d104850fcd53a978412dddddd2536a5075c40600ac1b1eeae374c268730931e2c7b37efb668277d9b8be9574b2a184cb427
    var query = window.location.search
    var params = new URLSearchParams(query)
    var pages = params.get('page')
    if (pages == 'quiz') {
      var classId = params.get('class_id')
      var contentId = params.get('content_id')
      let response = await getRequest(
        'content-by-id?content_id=' + contentId,
      )
      if (response) {
        history.push(`/katalog-kelas/${classId}/content-player`, {
          dataContent: response.data,
          classId: classId,
        })
      }
    } else {
      await history.push(pages)
      window.location.reload()
      window.forMobileHide()
    }
  }

  async function getNewTokenRole(id) {
    let form = new FormData()
    form.append('role_id', id)
    let response = await postRequest('change-role', form)
    if (response) {
      let tokenDecode = await jwt_decode(response.accessToken)
      localStorage.setItem('role_active', JSON.stringify(response.role_active))
      localStorage.setItem('menu_key', JSON.stringify(tokenDecode.menu_key))
      localStorage.setItem('login_id', JSON.stringify(tokenDecode.login_id))
      localStorage.setItem('token', response.accessToken)
      localStorage.setItem('menus_allow', JSON.stringify(response.menus))
      setValue('login', true)
      setValue('menus', response.menus)
      pages()
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    setToken()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div
      style={styles.container}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{defaultTitle}</title>
        <link
          rel="canonical"
          href="http://mysite.com/example"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <img
        src="/SMA PINTAR LAZUARDI LOGO.PNG"
        alt="SMA PINTAR LAZUARDI"
        style={styles.image}
        className="img-fluid mb-4"
      />
      <div className="d-flex justify-content-center">
        <div
          className="spinner-border text-light"
          style={styles.loader}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  )
}

/* =========================================== Styles =========================================== */
const styles = {
  container: {
    backgroundColor: '#345DAB',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: { 
    width: 300,
    height: 120,
    alignSelf: 'center'
  },
  loader: {
    width: '2rem',
    height: '2rem'
  }
}
import BaseReactBootstrapModal from 'component/modal/BaseReactBootstrapModal'
import BaseSelect from 'component/select/BaseSelect';
import useInputKindness from 'context/input-kindness';
import React, { useEffect, useState } from 'react'

const ModalInputDimentionElemenSubElemenProject = ({ show = false, handleClose = () => { }, selectedProject = { descriptionProjectData: [], index: 0 }, setDescriptionProjectData, setRerenderCount }) => {
  const { kindnessConfig } = useInputKindness();

  const [data, setData] = useState([])
  const [_, setRerenderCountModal] = useState(0);

  const onChangeHandler = ({ type, indexCellParent, choice }) => {
    const isDataExist = data[selectedProject?.index] || { label: '', value: '', index: selectedProject?.index || 0, proyek_type_scores: [], title: '', description: '' };

    if (!isDataExist.proyek_type_scores[indexCellParent]) {
      isDataExist.proyek_type_scores[indexCellParent] = {};
    }

    switch (type) {
      case 'sub_element':
        isDataExist.proyek_type_scores[indexCellParent].capaian_id = choice?.capaian?.id;
        isDataExist.proyek_type_scores[indexCellParent].sub_element_id = choice?.id;
        isDataExist.proyek_type_scores[indexCellParent].selected_sub_element = choice;
        break;
      case 'element':
        isDataExist.proyek_type_scores[indexCellParent].element_id = choice?.id;
        isDataExist.proyek_type_scores[indexCellParent].selected_element = choice;


        // RESET SUB ELEMENT & GOAL
        if (isDataExist.proyek_type_scores[indexCellParent].selected_sub_element) {
          isDataExist.proyek_type_scores[indexCellParent].capaian_id = '';
          isDataExist.proyek_type_scores[indexCellParent].sub_element_id = '';
          isDataExist.proyek_type_scores[indexCellParent].selected_sub_element = null;
        }
        break;
      default:
        isDataExist.proyek_type_scores[indexCellParent].dimensi_id = choice?.id;
        isDataExist.proyek_type_scores[indexCellParent].selected_dimention = choice;

        // RESET ELEMENT & SUB ELEMENT & GOAL
        if (isDataExist.proyek_type_scores[indexCellParent].selected_element) {
          isDataExist.proyek_type_scores[indexCellParent].element_id = '';
          isDataExist.proyek_type_scores[indexCellParent].selected_element = null;
          isDataExist.proyek_type_scores[indexCellParent].sub_element_id = '';
          isDataExist.proyek_type_scores[indexCellParent].selected_sub_element = null;
          isDataExist.proyek_type_scores[indexCellParent].capaian_id = '';
        }
        break;
    }

    data[selectedProject?.index] = isDataExist

    setDescriptionProjectData(data)
    setRerenderCount(prevCount => prevCount + 1);
  };


  useEffect(() => {
    setData(selectedProject?.descriptionProjectData);
    setRerenderCountModal(prevState => prevState + 1);
  }, [selectedProject, setRerenderCountModal, setData]);


  return (
    <BaseReactBootstrapModal show={show} isWithHeader={false} handleClose={handleClose} headerTitle={`INPUT DIMENSI, ELEMEN, DAN SUB ELEMEN PROYEK`} closeTitle='Tutup' isWithOkButton={false} size='xl'>
      <div className='d-flex justify-content-center align-items-center flex-column mb-4 mt-4'>
        <h4 className='m-0 p-0 mb-1'>INPUT DIMENSI, ELEMEN, DAN SUB ELEMEN PROYEK {selectedProject.index + 1}</h4>
        <p>Daftar input jenis yang akan di jadikan sebuah penilaian bagi murid.</p>
      </div>
      <table className='table'>
        <thead>
          <tr>
            <th>No</th>
            <th>Dimensi 1</th>
            <th>Elemen 1</th>
            <th>Sub Elemen 1</th>
            <th>Capaian Fase E</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) && data.length ? Array.from({ length: kindnessConfig?.totalInputDimentions || 1 }).map((_, indexCellParent) => {
            const commonStylesProp = {
              menu: (baseStyles) => ({
                ...baseStyles,
                wordBreak: 'break-all !important',
                whiteSpace: 'pre-wrap',
                overflowWrap: 'break-word',
              }),
              option: (baseStyles) => ({
                ...baseStyles,
                borderBottom: `.5px solid rgba(214, 214, 214, 0.70)`,
                height: '100%',
              })
            }

            // DIMENTIONS
            const selectedDimention = data?.[selectedProject.index]?.proyek_type_scores?.[indexCellParent]?.selected_dimention || null;

            // ELEMENTS
            const selectedElement = data?.[selectedProject.index]?.proyek_type_scores?.[indexCellParent]?.selected_element || null;

            // SUB ELEMENTS
            const selectedSubElement = data?.[selectedProject.index]?.proyek_type_scores?.[indexCellParent]?.selected_sub_element || null;

            // GOAL
            const selectedGoal = selectedSubElement?.capaian?.name;

            return (
              <tr key={`cell-parent-index${indexCellParent}`}>
                <td width={50}>{indexCellParent + 1}</td>
                <td width={200}><BaseSelect access={['instruktur']} styles={commonStylesProp}
                  options={data?.[selectedProject.index]?.proyek_type_scores?.[indexCellParent]?.dimentions} onChange={(choice) => onChangeHandler({ type: 'master', indexCellParent, choice })}
                  defaultValue={selectedDimention}
                /></td>
                <td width={200}>
                  <BaseSelect access={['instruktur']} styles={commonStylesProp} isDisabled={!selectedDimention?.elements?.length} options={selectedDimention?.elements} onChange={(choice) => onChangeHandler({ type: 'element', indexCellParent, choice })} defaultValue={selectedElement} />
                </td>
                <td width={200}>
                  <BaseSelect access={['instruktur']} styles={commonStylesProp} isDisabled={!selectedElement?.sub_elements?.length} options={selectedElement?.sub_elements} onChange={(choice) => onChangeHandler({ type: 'sub_element', indexCellParent, choice })} defaultValue={selectedSubElement} />
                </td>
                <td width={250}>
                  <textarea className='w-100' rows={5} disabled value={selectedGoal || ''}></textarea>
                </td>
              </tr>
            )
          }) : null}
        </tbody>
      </table>
      <p className='text-muted'><span>*</span> Pastikan data yang Anda masukkan sudah benar.</p>
    </BaseReactBootstrapModal>
  )
}

export default ModalInputDimentionElemenSubElemenProject
import React from 'react'
import useChatContext from 'context/chat'
import ModalInfoGroup from './modal-info-group'

export default function ChatOptions() {
  const { messages, current } = useChatContext()

  return (
    <div className="dropdown">
      <a
        className="btn p-0"
        type="button"
        data-toggle="dropdown"
      >
        <i className="feather icon-more-vertical" />
      </a>
      <div className="dropdown-menu dropdown-menu-right">
        {current.data.type === 'group' && (
          <a
            className="dropdown-item"
            type="button"
            data-toggle="modal"
            data-target="#modalInfoGroup"
          >
            <i className="fas fa-info-circle text-primary" /> Info Group
          </a>
        )}
        <a
          onClick={messages.fetch.newer}
          className="dropdown-item nav-link has-ripple"
          id="pills-kontak-tab"
          type="button"
          data-toggle="pill"
          href="#pills-kontak"
          role="tab"
          aria-controls="pills-kontak"
          aria-selected="false"
          title="Kontak"
        >
          <i className="fas fa-retweet text-primary" /> Refresh
        </a>
      </div>

      <ModalInfoGroup />
    </div>
  )
}

import React from 'react'

export default function ModalRaporTableSkeleton({
  tableConfig,
  bodyPlaceholder,
}) {
  return (
    <div className="rounded dt-responsive table-responsive">
      <table className="table nowrap">
        <tbody>
          <tr>
            {tableConfig.headers.map((header, index) => (
              <th
                key={index}
                style={{ borderTop: 'none' }}
              >
                {header}
              </th>
            ))}
          </tr>
          <tr>
            <td colSpan={tableConfig.totalColumns}>{bodyPlaceholder}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

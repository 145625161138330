import React, { useEffect, useState, useRef } from 'react'
import Select from 'react-select'
import { useBaseContext } from 'context/base'
import InvalidWarning from 'component/invalid/warning'
import Swal from 'sweetalert2'

export default function PathwayFormEdit({
  setIsAdd,
  classId,
  userId,
  setEventChange,
  modalEvent,
  pathwayType,
}) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, putRequest, deleteRequest} = useBaseContext()

  /* ========================================= Constants ======================================== */
  const beforeHelp = 'Kelas tersedia/dapat diakses setelah siswa menyelesaikan kelas-kelas yang ditentukan oleh instruktur.'
  const afterHelp  = 'Rekomendasi kelas-kelas yang dapat diikuti siswa setelah menyelesaikan kelas ini.'

  /* ======================================= Local States ======================================= */
  const [isEdit, setIsEdit]                             = useState(false)
  const [classOptions, setClassOptions]                 = useState([{ value: '', label: 'Data kelas tidak ditemukan.', isDisabled: true }])
  const [pathwayId, setPathwayId]                       = useState('')
  const [detailClasses, setDetailClasses]               = useState([{ value: '-', label: '-' }])
  const [isDetailClassesEmpty, setIsDetailClassesEmpty] = useState(false)
  
  /* =========================================== Refs =========================================== */
  const detailClassesRef = useRef()

  /* ========================================= Functions ======================================== */
  async function getClasses() {
    const response = await getRequest('classes?role_type=administrator')
    if (response) {
      initClasses(response.data)
    }
  }

  async function getExistingPathway() {
    setIsDetailClassesEmpty(false)
    const response = await getRequest(
      `class-pathway?user_id=${userId}&class_id=${classId}&type=${pathwayType}`,
    )
    if (response) {
      if (response.data.length !== 0) {
        initPathwayDetails(response.data)
        setPathwayId(response.pathWay.id)
      }
    } else {
      console.error('ERROR', response)
    }
  }

  function initClasses(classList) {
    let options = []
    classList.forEach((classItem) => {
      if (classItem.id !== classId) {
        options.push({
          value: classItem.id,
          label: classItem.title,
        })
      }
    })
    setClassOptions(options)
  }

  function initPathwayDetails(detail) {
    if (detail.length !== 0) {
      const details = detail.map((c) => {
        return {
          value: c.id,
          label: c.title,
        }
      })
      setDetailClasses(details)
    }
  }

  function handleChangeDetailClasses(classes) {
    if (classes) {
      setDetailClasses(classes)
      setIsDetailClassesEmpty(false)
    } else {
      setDetailClasses([])
    }
  }

  function convertDetailClass() {
    if (detailClasses.length !== 0) {
      const newDetails = detailClasses.map((detail) => {
        return detail.value
      })
      return newDetails
    } else {
      return []
    }
  }

  function formValidation() {
    const newDetails = convertDetailClass()
    if (newDetails.length !== 0) {
      setIsDetailClassesEmpty(false)
      submitForm(pathwayId, pathwayType, newDetails)
    } else {
      if (newDetails.length === 0) {
        setIsDetailClassesEmpty(true)
        detailClassesRef.current.focus()
      } else {
        setIsDetailClassesEmpty(false)
      }
    }
  }

  async function submitForm(pathway_id, type, detail) {
    let form = new FormData()
    form.append('id', pathway_id)
    form.append('type', type)
    form.append('detail', JSON.stringify(detail))

    const response = await putRequest('class-pathway', form)
    if (response) {
      Swal.fire({
        title: 'Berhasil merubah pathway!',
        icon: 'success',
      }).then(() => {
        setIsEdit(false)
        setEventChange('create')
      })
    } else {
      console.error('ERROR', response)
    }
  }

  async function handleDelete() {
    Swal.fire({
      icon: 'warning',
      title: 'Anda akan menghapus pathway ini?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Ya, hapus',
      cancelButtonText: 'Tidak',
      confirmButtonColor: '#ff5252',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(
          `class-pathway?id=${pathwayId}`,
        )
        if (response) {
          Swal.fire({
            title: 'Berhasil menghapus pathway!',
            icon: 'success',
          }).then(() => {
            setIsEdit(false)
            setEventChange('create')
          })
        } else {
          console.error('ERROR', response)
        }
      }
    })
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getClasses()
  }, [])

  useEffect(() => {
    if (modalEvent === null) {
      setIsEdit(false)
    } else {
      if (modalEvent === 'open') {
        getExistingPathway()
      }
    }
  }, [modalEvent])

  useEffect(() => {
    setIsEdit(false)
  }, [userId])

  /* ========================================== Output ========================================== */
  return (
    <form>
      <h5 className="mb-3">
        <span>Type pathway {pathwayType}</span>
        <i
          className="fas fa-question-circle ml-1"
          title={pathwayType === 'after' ? afterHelp : beforeHelp}
        />
      </h5>
      <div className="form-group row ">
        <label className="col-form-label col-sm-3">Kelas</label>
        <div className="col-sm-9">
          <Select
            isDisabled={!isEdit}
            ref={detailClassesRef}
            isMulti
            placeholder="Daftar Kelas"
            options={classOptions}
            value={detailClasses}
            onChange={(opt) => handleChangeDetailClasses(opt)}
          />
          {isDetailClassesEmpty ? (
            <InvalidWarning text="Daftar kelas pathway tidak boleh kosong." />
          ) : null}
        </div>
      </div>
      <div className="form-group row mb-0">
        {isEdit ? (
          <>
            <div className="offset-md-6 col-12 col-sm-6 col-md-3 mb-2">
              <button
                onClick={formValidation}
                type="button"
                className="btn btn-sm btn-success w-100"
              >
                Simpan
              </button>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <button
                type="button"
                className="btn btn-sm btn-outline-danger w-100"
                onClick={() => {
                  setIsEdit(false)
                  getExistingPathway()
                }}
              >
                Batal
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="offset-md-6 col-12 col-sm-6 col-md-3 mb-2">
              <button
                onClick={handleDelete}
                type="button"
                className="btn btn-sm btn-danger w-100"
              >
                Hapus
              </button>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <button
                onClick={() => setIsEdit(true)}
                type="button"
                className="btn btn-sm btn-info w-100"
              >
                Edit
              </button>
            </div>
          </>
        )}
      </div>
    </form>
  )
}

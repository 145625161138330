import React from "react";

export const GraphorGroupReadonlyAnswer = ({ groups =[], showList = false }) => {
  const modifiedGroups = showList ? groups : groups.filter((item) => item.name !== "list");

  return (
    <div className="">
      <div className="d-flex flex-wrap justify-content-center">
        {modifiedGroups.length > 0 ?
          modifiedGroups.map((item, index) => 
            <div 
              key={index} 
              className={`card bg-info px-0 mx-2 ${index > 0 ? "" : ""}`}
              style={{ width: "280px" }}
            >
              <div className="card-header px-2 py-3">
                <h5 className="m-0 text-light">{item.name}</h5>
              </div>
              <div className="card-body p-2">
                {item.list.length > 0 ?
                  item.list.map((detail, index) => 
                    <div key={index} className={`border bg-white p-2 rounded ${index > 0 ? "mt-2" : ""}`}>
                      <p className="m-0 f-w-700">{detail.answer_text}</p>
                    </div>
                  )
                  :
                  null
                }
              </div>
            </div>
          )
          :
          null
        }
      </div>
    </div>
  );
}

export const GraphorGroupReadonlyCorrect = ({ groups =[], showList = false }) => {
  const modifiedGroups = showList ? groups : groups.filter((item) => item.name !== "list");

  return (
    <div className="d-flex flex-wrap justify-content-center">
      {modifiedGroups.length > 0 ?
        modifiedGroups.map((item, index) => 
          <div 
            key={index} 
            className={`card bg-info px-0 mx-2 ${index > 0 ? "" : ""}`}
            style={{ width: "280px" }}
          >
            <div className="card-header px-2 py-3">
              <h5 className="m-0 text-light">{item.name}</h5>
            </div>
            <div className="card-body p-2">
              {item.list.map((detail, index) => 
                <div key={index} className={`border bg-white p-2 rounded ${index > 0 ? "mt-2" : ""}`}>
                  <p className="m-0 f-w-700">{detail.graphor_answer.text}</p>
                </div>
              )}
            </div>
          </div>
        )
        :
        null
      }
    </div>
  );
}
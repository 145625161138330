import React from 'react'
import { noImage } from 'util/constant'
import { host } from 'util/host'

export default function PathwayItem({ pathwayItem }) {
  return (
    <div className="col-md-6 col-lg-4 col-xl-2">
      <a
        className="card btn widget-profile-card-2 border pt-0 px-0"
        title={pathwayItem?.title}
        style={{
          overflow: 'hidden',
        }}
        href={`/katalog-kelas/${pathwayItem?.id}`}
      >
        <img
          className="img-fluid bg-light"
          src={pathwayItem.img ? `${host}${pathwayItem.img}` : noImage}
          alt={pathwayItem?.title}
          style={{
            height: '150px',
            width: '100%',
            objectFit: 'cover',
          }}
          onError={(event) => (event.currentTarget.src = noImage)}
        />
        <div
          className="card-body text-left w-100 pt-3 pb-1 px-3 d-flex flex-column justify-content-between"
          style={{ height: '100px' }}
        >
          <div>
            <h6 className="mb-1">{pathwayItem?.title}</h6>
            <p className="text-muted mb-0">{pathwayItem?.category?.title}</p>
          </div>
        </div>
      </a>
    </div>
  )
}

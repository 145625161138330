import MathJax from 'react-mathjax-preview';


/**
 * FIX REPLACE URL VIDEO
 * 
 * @param {string} text 
 */
export const fixReplaceUrlVideo = (text) => {
  return text?.replaceAll('oembed', 'iframe')
    ?.replaceAll('iframe url', 'iframe style="width: 100%; aspect-ratio: 16 / 9;" src')
    ?.replaceAll('youtu.be', 'youtube.com/embed')
    ?.replaceAll('watch?v=', 'embed/')
}

/**
 * REPLACE MATH TAGS
 * 
 * @param {string} text 
 */
export const replaceMathTags = (text) => {
  const mathTagRegex = /<math\b[^>]*>.*?<\/math>/i;

  if (typeof text === 'string' && text.length) {
    const mathMatches = text.match(mathTagRegex);

    if (mathMatches) {
      const startIndex = text.indexOf(mathMatches[0]);
      const endIndex = startIndex + mathMatches[0].length;

      const beforeMath = fixReplaceUrlVideo(text.substring(0, startIndex));
      const afterMath = fixReplaceUrlVideo(text.substring(endIndex));

      const mathComponent = <MathJax math={mathMatches[0]} style={{ fontSize: 17 }} />;

      return (
        <>
          <div dangerouslySetInnerHTML={{ __html: beforeMath }} className="ckeditor-readonly-container" />
          {mathComponent}
          <div dangerouslySetInnerHTML={{ __html: afterMath }} className="ckeditor-readonly-container" />
        </>
      );
    } else {
      return (
        <div dangerouslySetInnerHTML={{ __html: text }} className="ckeditor-readonly-container" />
      );
    }
  } else {
    return (
      <div dangerouslySetInnerHTML={{ __html: text }} className="ckeditor-readonly-container" />
    );
  }
}


/**
 * Text Elipsis
 * 
 * @param {string} text 
 * @param {number} limit
 * @returns {string}
 */
export const textElipsis = (text, limit = 10) => {
  return text?.length > limit
    ? text.substring(0, limit) + '...'
    : text
}

export const createQueryUrl = ({ queryName, value, seperator = '&' }) => {
  return value ? `${seperator}${queryName}=${value}` : ''
}

export const daftarKataPenghubung = [
  "agar",
  "akan",
  "alhasil",
  "apalagi",
  "atau",
  "ataupun",
  "bahkan",
  "begitu",
  "biarpun",
  "dalam",
  "dan",
  "dapat",
  "dari",
  "demi",
  "dengan",
  "di",
  "dia",
  "hanya",
  "hingga",
  "ia",
  "ialah",
  "jika",
  "juga",
  "ke",
  "kecuali",
  "kelak",
  "kemudian",
  "kemungkinan",
  "kita",
  "lagi",
  "lalu",
  "lebih",
  "maka",
  "malah",
  "masih",
  "melainkan",
  "meskipun",
  "mu",
  "nanti",
  "nggak",
  "nih",
  "nyaris",
  "oleh",
  "pada",
  "padahal",
  "paling",
  "sampai",
  "sebab",
  "sebelum",
  "sejak",
  "selain",
  "sementara",
  "sendiri",
  "seraya",
  "seumpama",
  "sih",
  "supaya",
  "tapi",
  "telah",
  "tentang",
  "terhadap",
  "tetapi",
  "walaupun",
  "walau",
  "ya",
  "yaitu",
  "yang"
];

export function getYoutubeId(link) {
  if (link) {
    return link?.split('=')?.pop()
  } else {
    return ''
  }
}
import React, { useEffect, useState } from 'react'
import { useBaseContext } from 'context/base'
import LoadingSpinner from 'component/loading'
import RenderBadge from './RenderBadge'
import './disabled-image.css'

export default function BadgesCollections({ apiUrl }) {
  /* ===================================== Consoume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [isLoading, setIsLoading] = useState(false)
  const [badges, setBadges] = useState([])

  /* ========================================= Functions ======================================== */
  async function getDataBadge() {
    setIsLoading(true)
    let response = await getRequest(apiUrl)
    if (response) {
      setIsLoading(false)
      setBadges(response.data)
    } else {
      console.error('ERROR', response)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      getDataBadge()
    }
    return () => {
      isSubscribed = false
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card user-profile-list">
          <div className="card-body">
            <div className="row">
              {isLoading ? (
                <div className="col-sm-12 text-center">
                  <br />
                  <LoadingSpinner />
                  <br />
                </div>
              ) : (
                <>
                  {badges.length !== 0
                    ? badges.map((badge, idx) => (
                        <RenderBadge
                          item={badge}
                          index={idx}
                          key={idx}
                        />
                      ))
                    : null}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

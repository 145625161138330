import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import useContentContainerContext from 'context/content-container'
import useContentPlayerContext from 'context/content-player'
import ContentType from 'component/content/content-type'
import BadgeProgress from './badge-progress'

const ContentEnabledSeq = ({ isExpire, title }) => {
  /* ====================================== Consume Context ===================================== */
  const { allContents } = useContentPlayerContext()
  const { content, classId, refSection, currentContentId } = useContentContainerContext()

  /* ========================================== Helpers ========================================= */
  const history    = useHistory()
  const refContent = useRef()

  /* ========================================== States ========================================== */
  const [isSelected, setIsSelected] = useState(false)
  const [isHovered, setIsHovered]   = useState(false)

  // ACTIVITIES STATE
  const [contentActivities, setContentActivities] = useState([])
  const [isCompleted, setIsCompleted]             = useState(false)

  // DISPLAY STATE
  const [isDisabled, setIsDisabled] = useState(true)

  /* ========================================= Functions ======================================== */
  function goToContentPlayer() {
    history.push(`/katalog-kelas/${classId}/content-player`, {
      dataContent: content,
      classId,
    })
  }

  function checkCurrentContent() {
    if (currentContentId === content.id) {
      setIsSelected(true)
      if (refSection.current.ariaExpanded === 'false') {
        refSection.current.click()
      }
    } else {
      setIsSelected(false)
    }
  }

  function checkIsCompleted() {
    const _isComplete = contentActivities.some((ca) => ca.completed_at)
    _isComplete && setIsCompleted(true)
  }

  function checkDisabled() {
    const IndexFromAllContent = allContents.findIndex((ac) => ac.id === content.id)

    // kalau bukan index pertama
    if (IndexFromAllContent) {
      const contentBefore          = allContents[IndexFromAllContent - 1]
      const _isContentBeforePassed = contentBefore.isAssignmentOrQuiz
        ? (contentBefore.isSubmitted || contentBefore.isEvaluated)
        : contentBefore.isCompleted

      _isContentBeforePassed && setIsDisabled(false)
    } else {
      setIsDisabled(false)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    setContentActivities(content.class_content_activities)
  }, [])

  useEffect(() => {
    checkIsCompleted()
  }, [contentActivities])

  useEffect(() => {
    checkCurrentContent()
  }, [currentContentId])

  useEffect(() => {
    checkDisabled()

    const find = allContents.find(ac => ac.id === content.id)
    if (find) {
      if (find.isCompleted) setIsCompleted(true)
    }
  }, [allContents])

  /* ========================================== Output ========================================== */
  return (
    <li
      ref={refContent}
      style={{
        backgroundColor: isSelected
          ? 'rgba(0, 188, 212, 0.1)'
          : 'transparent',
      }}
    >
      {isDisabled ? (
        <div
          className="p-3 f-16 text-left text-dark btn-forbidden border-bottom"
          title="Anda tidak memiliki akses"
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            opacity: '0.65',
          }}
        >
          <div className="row">
            <div className="col-2 col-sm-1">
              <div className="custom-control custom-checkbox mt-1">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={isCompleted}
                  disabled={false}
                  onChange={() => {}}
                />
                <label className="custom-control-label" />
              </div>
            </div>
            <div className="col-10 col-sm-11">
              <span className={`text-dark d-block f-16`}>
                {content.urutan}
                {'. '}
                {content.title}
              </span>
              <span className="d-block">
                <ContentType id={content.content_type_id} />{' '}
                <BadgeProgress />
                {content.durasi ? (
                  <span className="f-12 text-muted ml-2">
                    <i className="feather icon-clock f-10 mr-1" />
                    {Math.round(content.durasi / 60)} menit
                  </span>
                ) : null}
              </span>
              {title && (
                <span
                  style={{ fontSize: '10px' }}
                  className={`${
                    isExpire ? 'badge-danger' : 'badge-warning'
                  } badge`}
                >
                  {title}
                </span>
              )}
            </div>
          </div>
        </div>
      ) : (
        <button
          onClick={goToContentPlayer}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          type="button"
          className="btn btn-sm p-3 f-16 text-left text-dark border-bottom"
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: isHovered
              ? 'rgba(221, 221, 221, 0.2)'
              : 'transparent',
          }}
        >
          <div className="row">
            <div className="col-2 col-sm-1">
              <div className="custom-control custom-checkbox mt-1">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={isCompleted}
                  disabled={false}
                  onChange={() => {}}
                />
                <label className="custom-control-label" />
              </div>
            </div>
            <div className="col-10 col-sm-11">
              <span className={`text-dark d-block f-16`}>
                {content.urutan}
                {'. '}
                {content.title}
              </span>
              <span className="d-block">
                <ContentType id={content.content_type_id} />{' '}
                <BadgeProgress />
                {content.durasi ? (
                  <span className="f-12 text-muted ml-2">
                    <i className="feather icon-clock f-10 mr-1" />
                    {Math.round(content.durasi / 60)} menit
                  </span>
                ) : null}
              </span>
            </div>
          </div>
        </button>
      )}
    </li>
  )
}

export default ContentEnabledSeq

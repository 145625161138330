import React, { useState, useEffect } from 'react'
import moment from 'moment'
import ContentEnabledSeq from './content-enabled-seq'
import ContentDisabled from './content-disabled'
import useContentContainerContext from 'context/content-container'

export default function ContentContainerSeq({ todayDate }) {
  /* ====================================== Consume Context ===================================== */
  const { content } = useContentContainerContext()

  /* ========================================== States ========================================== */
  const [isAvailable, setIsAvailable] = useState(false)
  const [isExpire, setIsExpire]       = useState(false)

  /* ========================================= Constants ======================================== */
  const expiredInfo = isExpire
    ? `Berakhir pada ${moment(content.ended_at).format('DD MMMM YYYY')}`
    : `Tersedia pada ${moment(content.display_at).format('DD MMMM YYYY HH:mm')}`

  const checkStart = (displayAt, todayDate) => new Date(displayAt).getTime() <= todayDate
  const checkEnd   = (endedAt, todayDate) => new Date(endedAt).getTime() < todayDate

  /* ========================================= Functions ======================================== */
  function init() {
    let available = true
    let expire = false
    if (content.display_at)
      available = checkStart(content.display_at, todayDate)
    if (content.ended_at) {
      expire = checkEnd(content.ended_at, todayDate)
      if (expire) available = false
    }
    setIsAvailable(available)
    setIsExpire(expire)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    init()
  }, [content])

  /* ========================================== Output ========================================== */
  return (
    <>
      {isAvailable ? (
        <ContentEnabledSeq />
      ) : (
        <ContentDisabled
          title={expiredInfo}
          isExpire={isExpire}
        />
      )}
    </>
  )
}

import React, { useState, useRef } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { Back, Submit } from 'component/button'
import Jawaban from './jawaban'
import { minimalConfig } from 'util/ckeditor-config'

const AddPilihanGandaSurvey = () => {
  /* ========================================== Helpers ========================================= */
  const history  = useHistory()
  const params   = useParams()
  const location = useLocation()

  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const surveyId   = params.surveyId
  const dataSurvey = location.state.dataSurvey
  const questType  = 0
  const links      = [
    { url: '/survey-engine', name: 'Kelola Survey' },
    {
      url: `/survey-engine/${dataSurvey.id}`,
      name: 'Daftar Pertanyaan',
      state: { existingData: dataSurvey },
    },
  ]

  /* ======================================= Local States ======================================= */
  const [textPertanyaan, setTextPertanyaan] = useState('')
  const [nomorUrut, setNomorUrut]           = useState('')
  const [status, setStatus]                 = useState(0)
  const [listJawaban, setListJawaban]       = useState([
    {
      text  : '',
      active: 1,
    },
  ])

  // FORM VALIDATION STATE
  const [isUrutanInvalid, setIsUrutanInvalid]                 = useState(false)
  const [isTextPertanyaanInvalid, setIsTextPertanyaanInvalid] = useState(false)
  const [isJawabanInvalid, setIsJawabanInvalid]               = useState(false)

  /* =========================================== Refs =========================================== */
  const refNomorUrut = useRef()

  /* ========================================= Functions ======================================== */
  function handleChangeUrutan(event) {
    if (event.target.value) {
      setNomorUrut(Math.abs(event.target.value))
      setIsUrutanInvalid(false)
    } else {
      setNomorUrut('')
    }
  }

  function handleChangeStatus(event) {
    switch (event.target.checked) {
      case true:
        setStatus(1)
        break
      case false:
        setStatus(0)
        break
      default:
        break
    }
  }

  function handleAddJawaban() {
    setListJawaban([
      ...listJawaban,
      {
        text: '',
        active: 1,
      },
    ])
  }

  function handleDeleteJawaban(indexDelete) {
    const newJawabans = listJawaban.filter(
      (jawaban, index) => index !== indexDelete,
    )
    setListJawaban(newJawabans)
  }

  function handleSetText(indexText, value) {
    const newJawabans = listJawaban.map((jawaban, index) => {
      if (index === indexText) {
        return {
          text: value,
          active: 1,
        }
      } else {
        return {
          text: jawaban.text,
          active: 1,
        }
      }
    })
    setListJawaban(newJawabans)
  }

  function handleFormValidation() {
    if (nomorUrut && textPertanyaan && listJawaban.length !== 0) {
      let jawabanKosong = []
      listJawaban.forEach((el, idx) => {
        if (el.text == '') {
          jawabanKosong.push(`no.${idx + 1}`)
        }
      })
      if (jawabanKosong.length == 0) {
        setIsUrutanInvalid(false)
        setIsTextPertanyaanInvalid(false)
        setIsJawabanInvalid(false)
        handleFormSubmit(
          surveyId,
          nomorUrut,
          textPertanyaan,
          questType,
          status,
          listJawaban,
        )
      } else {
        window.notification(
          'Data jawaban tidak boleh kosong',
          'Mohon lengkapi data',
        )
      }
    } else {
      if (listJawaban.length === 0) {
        setIsJawabanInvalid(true)
      } else {
        setIsJawabanInvalid(false)
      }

      if (!textPertanyaan) {
        setIsTextPertanyaanInvalid(true)
      } else {
        setIsTextPertanyaanInvalid(false)
      }

      if (!nomorUrut) {
        setIsUrutanInvalid(true)
        refNomorUrut.current.focus()
      } else {
        setIsUrutanInvalid(false)
      }

      window.notification('Data belum lengkap', 'Mohon lengkapi data')
    }
  }

  async function handleFormSubmit(
    survey_id,
    urutan,
    text,
    quest_type,
    active,
    jawaban,
  ) {
    let form = new FormData()
    form.append('survey_id', survey_id)
    form.append('urutan', urutan)
    form.append('text', text)
    form.append('quest_type', quest_type)
    form.append('active', active)
    form.append('jawaban', JSON.stringify(jawaban))

    const response = await postRequest('surveyquestions', form)
    if (response) {
      window.notification(
        'Sukses',
        'Pertanyaan berhasil ditambahkan',
        'success',
      )
      history.goBack()
    } else {
      console.error('ERROR', response)
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName={`Survey: ${dataSurvey?.title}`}
          linkName="Tambah Pertanyaan"
          links={links}
        />

        <div className="card">
          <div className="card-header">
            <h5 className="modal-title">
              <i className="fas fa-list-ul mr-1" />
              Informasi Pilihan Ganda
            </h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col">
                <form>
                  {/* Input nomor urut */}
                  <div className="form-group mb-3 row">
                    <label
                      className="col-form-label col-sm-3"
                      htmlFor="urutan-pilihan-ganda"
                    >
                      <span>Nomor urut</span>
                      <span className="text-danger ml-1">*</span>
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="number"
                        min={0}
                        value={nomorUrut}
                        onChange={(event) => handleChangeUrutan(event)}
                        ref={refNomorUrut}
                        className={`${
                          isUrutanInvalid ? 'is-invalid' : ''
                        } form-control`}
                        id="urutan-pilihan-ganda"
                        placeholder="Nomor urut"
                      />
                    </div>
                    {isUrutanInvalid ? (
                      <div className="offset-sm-3 col-sm-9">
                        <span className="col small form-text text-danger">
                          Nomor urut tidak boleh kosong.
                        </span>
                      </div>
                    ) : null}
                  </div>

                  {/* Select status pertanyaan */}
                  <div className="form-group mb-3 row">
                    <label
                      className="col-form-label col-sm-3"
                      htmlFor="status-pilihan-ganda"
                    >
                      <span className="mr-1">Status</span>
                    </label>
                    <div className="col-sm-9">
                      <div className="switch switch-primary d-inline m-r-10">
                        <input
                          onChange={(event) => handleChangeStatus(event)}
                          checked={status === 1}
                          type="checkbox"
                          id="switch-status-pilihan-ganda"
                        />
                        <label
                          htmlFor="switch-status-pilihan-ganda"
                          className="cr"
                        ></label>
                      </div>
                      <label htmlFor="switch-status-pilihan-ganda">
                        {status === 1 ? 'Aktif' : 'Tidak Aktif'}
                      </label>
                    </div>
                  </div>

                  {/* Pertanyaan pilihan ganda */}
                  <div className="form-group mb-4 row">
                    <label className="col-form-label col-sm-3">
                      Pertanyaan<span className="text-danger ml-1">*</span>
                    </label>

                    <div className="col-sm-9">
                      {/* input pertanyaan */}
                      <CKEditor
                        editor={Editor}
                        data={textPertanyaan}
                        config={minimalConfig}
                        onChange={(_, editor) => {
                          const data = editor.getData()
                          setTextPertanyaan(data)
                        }}
                      />

                      {isTextPertanyaanInvalid ? (
                        <span className="col small form-text text-danger">
                          Pertanyaan tidak boleh kosong.
                        </span>
                      ) : null}

                      {/* input jawaban */}
                      <ul className="list-unstyled mt-3">
                        {listJawaban.map((jawaban, index) => {
                          return (
                            <Jawaban
                              key={index}
                              index={index}
                              text={jawaban.text}
                              handleDelete={handleDeleteJawaban}
                              handleText={handleSetText}
                            />
                          )
                        })}
                      </ul>

                      {/* Tambah jawaban */}
                      <div className="form-group mb-3">
                        <button
                          onClick={() => handleAddJawaban()}
                          type="button"
                          className="btn btn-sm btn-primary"
                        >
                          <i className="feather icon-plus-circle mr-1" />
                          <span>Tambah Pilihan</span>
                        </button>
                      </div>

                      {isJawabanInvalid ? (
                        <span className="col small form-text text-danger">
                          Pilihan tidak boleh kosong.
                        </span>
                      ) : null}
                    </div>
                  </div>

                  {/* Form keterangan */}
                  <div className="form-group d-flex">
                    <label className="text-muted mr-2">Keterangan:</label>
                    <span className="text-danger mr-1">*</span>
                    <label className="text-muted">Wajib diisi</label>
                  </div>

                  {/* Form button */}
                  <div className="form-group">
                    <Back />
                    <Submit
                      onClick={handleFormValidation}
                      text="Simpan"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

export default AddPilihanGandaSurvey

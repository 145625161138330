import React from "react";
import Content from "./content";

const Section = ({ item, setEventTrigger, ...props }) => {
  function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d > 0 ? d + (d == 1 ? " hari " : " hari ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " jam " : " jam ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " menit " : " menit ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " detik" : " detik") : "";
    var output = d > 0 ? dDisplay : (h > 0 ? hDisplay : (m > 0 ? mDisplay : (s > 0 ? sDisplay : "0")));
    return output;
  }

  return (
    <div className="mb-0 border-top border-right border-left border-bottom">
      <div id={item.id} style={{ backgroundColor: "#f3f3f3" }}>
        <h5 className="row m-0 border-bottom">
          <div
            className="col row text-dark py-2 m-0"
            onClick={(event) => event.preventDefault()}
          >
            <div className="col px-0 d-flex justify-content-between align-items-center" style={{ height: "50px" }}>
              <span className="f-16">
                {item.urutan}
                {". "}
                {item.title}{" "}
                {/* {item.active ? 
                  <span className="badge badge-success ml-2" style={{fontSize: "12px"}}>Aktif</span>
                  : <span className="badge badge-secondary ml-2" style={{fontSize: "12px"}}>Tidak Aktif</span>
                } */}
              </span>
              <span>
                <p className="m-0 f-14 text-secondary text-right">
                  {item.contents.length}{" materi"}
                </p>
                <p className="m-0 f-14 text-secondary text-right">
                  Waktu Pengerjaan Siswa: {secondsToDhms(item.duration_section)}
                </p>
              </span>
            </div>
          </div>
        </h5>
      </div>
      <div className="collapse show">
        <ul className="list-unstyled mb-0">
          {item.contents.length !== 0 ? (
            item.contents.map((content) => <Content key={content.id} item={content} setEventTrigger={setEventTrigger} {...props} />)
          ) : (
            <p className="text-muted p-3 mb-0">Belum ada materi</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Section;

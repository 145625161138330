import React from 'react'

export default function AssignmentSuccess() {
  return (
    <div className="col-12">
      <div className="d-flex justify-content-center">
        <div
          className="rounded-circle p-2 my-3"
          style={styles.success}
        >
          <i className="fas fa-check text-success f-48" />
        </div>
      </div>
      <p className="text-center f-20 mb-0">Selamat!</p>
      <p className="text-center f-20">Anda telah menyelesaikan tugas ini</p>
    </div>
  )
}

const styles = {
  success: {
    border: '3px solid #9ccc65',
  },
}

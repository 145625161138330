import React from 'react'
import CkeditorReadonly from 'component/ckeditor-readonly'
import useContentPlayerContext from 'context/content-player'

export default function TabKeyTakeAway() {
  /* ====================================== Consume Context ===================================== */
  const { tab, keyTakeAway } = useContentPlayerContext()

  /* ========================================== Output ========================================== */
  return tab.current === 2 && (
    <div
      className="tab-pane fade show active"
      id="pills-take"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      {keyTakeAway.data?.description ? (
        <div className="card">
          <div className="card-body">
            <CkeditorReadonly data={keyTakeAway.data?.description} />
          </div>
        </div>
      ) : (
        <div className="card col d-flex flex-column justify-content-center p-5">
          <i
            className="fas fa-sticky-note f-60 text-center mb-3"
            style={{
              color: 'rgb(221, 221, 221)',
            }}
          ></i>
          <p
            className="text-center"
            style={{
              color: 'rgb(221, 221, 221)',
            }}
          >
            Key take away tidak ada!
          </p>
        </div>
      )}
    </div>
  )
}

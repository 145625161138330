import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useBaseContext } from 'context/base'
import STATE_TYPES from 'globals/state-types'
import Informasi from './informasi'
import Materi from './materi'
import NotFound from 'component/not-found'
import ModalPreviewKelas from './modal-preview-kelas'
import ForumDiskusi from 'component/forum-mata-pelajaran/forum-diskusi'
import ContentSummary from 'component/content-summary'
import { noImage } from 'util/constant'

export default function ClassOverviewGranted({
  coverStyles,
  classData,
  classProgress = 0,
  instruktur = [],
  canAccess = STATE_TYPES.TASK_IDLE,
  windowWidth,
  pathwayBefores = [],
  pathwayAfters = [],
  sections = [],
  contents = [],
  contentsInitial = [],
}) {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [previewUrl, setPreviewUrl]       = useState('')
  const [loadingEnroll, setLoadingEnroll] = useState(STATE_TYPES.LOADING_IDLE)

  /* ========================================= Functions ======================================== */
  const renderPreview = () => setPreviewUrl(classData.data.class_url?.data)
  const destroyPreview = () => setPreviewUrl('')

  const insertClassActivity = async (classId) => {
    if (classId) {
      let form = new FormData()
      form.append('class_id', classId)
      const response = await postRequest('class-activities', form)
      return response ? response : null
    } else {
      return null
    }
  }

  const beforeEnroll = async () => {
    Swal.fire({
      title: 'Mulai belajar sekarang?',
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: 'Tidak',
      confirmButtonText: 'Ya, mulai!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingEnroll(STATE_TYPES.LOADING_START)
        Swal.fire({
          title: 'Menyiapkan materi...',
          html: `<div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>`,
          allowOutsideClick: false,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
        })
        const response = await insertClassActivity(classData.data.id)
        if (response) {
          if (contentsInitial.length > 0) {
            history.push(`/katalog-kelas/${classData.data.id}/content-player`, {
              dataContent: { ...contentsInitial[0], id: null },
              classId: classData.data.id,
            })
            setTimeout(() => {
              Swal.close()
            }, 300)
          } else {
            history.go(0) //reload page
          }
        } else {
          Swal.fire({
            title: 'Terjadi kesalahan',
            text: 'Coba beberapa saat lagi',
            icon: 'error',
            allowOutsideClick: false,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: 'Muat Ulang',
          }).then((result) => {
            if (result.isConfirmed) history.go(0) //reload page
          })
        }
      }
    })
  }

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="row mb-3">
        <div className="col-12 mb-2">
          <div className="mb-2 d-flex flex-row align-items-center">
            <h3 className="mb-0">{classData?.data?.title}</h3>
          </div>
        </div>
        <div className="col-md-4 col-xl-3 mb-3 d-flex justify-content-center">
          <img
            src={classData?.data?.img ? `${classData.data.linkImg}` : noImage}
            className="img-fluid rounded bg-light"
            style={coverStyles}
            alt={classData?.data?.title ? classData.data.title : 'My Class'}
            onError={(e) => (e.currentTarget.src = noImage)}
          />
        </div>
        <div className="col-md-8 mb-3">
          <div className="d-flex align-items-center mb-2">
            <i className="fas fa-chart-line mr-2" />
            <span className="d-flex align-items-center w-100">
              Progress:{' '}
              {classProgress === 0 ? (
                <span className="progress ml-1 w-100 hei-20">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `100%`, backgroundColor: '#ecf0f5' }}
                    aria-valuenow={`100`}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <span
                      className="text-dark"
                      style={{ fontSize: '12px', fontWeight: 'bold' }}
                    >
                      {classProgress}%
                    </span>
                  </div>
                </span>
              ) : (
                <>
                  {classProgress === 100 ? (
                    <span className="progress ml-1 w-100 hei-20">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{ width: `${classProgress}%` }}
                        aria-valuenow={`${classProgress}`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                          {classProgress}%
                        </span>
                      </div>
                    </span>
                  ) : (
                    <span className="progress ml-1 w-100 hei-20">
                      <div
                        className="progress-bar bg-info"
                        role="progressbar"
                        style={{ width: `${classProgress}%` }}
                        aria-valuenow={`${classProgress}`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                          {classProgress}%
                        </span>
                      </div>
                    </span>
                  )}
                </>
              )}
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="feather icon-tag mr-2" />
            <span className="mr-2">Mata Pelajaran:</span>
            <span>{classData?.data?.class_profiles[0].category?.title}</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fas fa-chalkboard-teacher mr-1" />
            <span className="mr-1">Guru:</span>
            {instruktur.length > 0 ? (
              <>
                {instruktur.map((val, index) => (
                  <span key={index}>
                    {index !== 0 ? <span>,</span> : null}
                    <span className="ml-1">{val.name}</span>
                  </span>
                ))}
              </>
            ) : null}
          </div>
          <div className="mb-2">
            <div>
              {classData?.data?.linkclass_url ? (
                <>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-info"
                    style={{
                      width: windowWidth <= 1024 ? '100%' : '150px',
                    }}
                    data-toggle="modal"
                    data-target="#preview-kelas-modal"
                    onClick={renderPreview}
                  >
                    <i className="fas fa-eye mr-2" />
                    Preview
                  </button>
                  <ModalPreviewKelas
                    classUrl={classData.data.class_url}
                    uriInternal={classData.data.linkclass_url}
                    url={previewUrl}
                    destroyPreview={destroyPreview}
                  />
                </>
              ) : (
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  style={{
                    width: windowWidth <= 1024 ? '100%' : '150px',
                  }}
                  disabled={true}
                  title="Preview tidak tersedia"
                >
                  <i className="fas fa-eye-slash mr-2" />
                  Preview
                </button>
              )}
              {canAccess === STATE_TYPES.TASK_SUCCESS && (
                <>
                  {classData.classStatus.status === 'none' && (
                    <button
                      type="button"
                      className="btn btn-sm btn-info"
                      title="Mulai Belajar"
                      onClick={
                        loadingEnroll === STATE_TYPES.LOADING_START
                          ? () => {}
                          : beforeEnroll
                      }
                      disabled={
                        loadingEnroll === STATE_TYPES.LOADING_START
                          ? true
                          : false
                      }
                      style={{
                        width: windowWidth <= 1024 ? '100%' : '150px',
                        marginTop: windowWidth <= 1024 ? '10px' : '0',
                        marginLeft: windowWidth <= 1024 ? '0' : '10px',
                      }}
                    >
                      {loadingEnroll === STATE_TYPES.LOADING_START ? (
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                        />
                      ) : (
                        <i className="feather icon-play-circle mr-1" />
                      )}
                      <span>Enroll</span>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="d-flex flex-column mb-2">
            {canAccess === STATE_TYPES.TASK_FAILURE && (
              <>
                {classData?.classStatus?.preclass === 'progress' && (
                  <div className="d-flex align-items-start mb-2">
                    <i
                      className="fas fa-exclamation-triangle text-danger f-10 mr-2"
                      style={{ marginTop: '6px' }}
                    />
                    <span className="text-danger">
                      Selesaikan sub mata pelajaran prasyarat:
                      <a
                        className="text-info f-w-700 ml-2"
                        href={`/katalog-kelas/${classData?.data?.class?.id}`}
                      >
                        {classData?.data?.class?.title}
                      </a>
                    </span>
                  </div>
                )}
                {pathwayBefores.length > 0 && (
                  <div className="d-flex align-items-start">
                    <i
                      className="fas fa-exclamation-triangle text-danger f-10 mr-2"
                      style={{ marginTop: '6px' }}
                    />
                    <span className="text-danger">
                      Pathway belum selesai:
                      {pathwayBefores.map((item, index) => (
                        <a
                          key={index}
                          className="text-info f-w-700 ml-2"
                          href={`/katalog-kelas/${item.id}`}
                        >
                          {index > 1 ? <span>,</span> : null}
                          {item.title}
                        </a>
                      ))}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <ul
            className="nav nav-tabs mb-3"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link text-uppercase has-ripple active"
                id="home-tab"
                data-toggle="tab"
                href="#home"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Informasi
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-uppercase has-ripple"
                id="profile-tab"
                data-toggle="tab"
                href="#profile"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Materi
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-uppercase has-ripple"
                id="contact-tab"
                data-toggle="tab"
                href="#contact"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                Forum Diskusi
              </a>
            </li>
            {/*
            <li className='nav-item'>
              <a
                className='nav-link text-uppercase has-ripple'
                id='notes-tab'
                data-toggle='tab'
                href='#notes'
                role='tab'
                aria-controls='notes'
                aria-selected='false'
              >
                Catatanku
              </a>
            </li>
          */}
            <li className="nav-item">
              <a
                className="nav-link text-uppercase has-ripple"
                id="summary-tab"
                data-toggle="tab"
                href="#summary"
                role="tab"
                aria-controls="summary"
                aria-selected="false"
              >
                Summary
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        className="row tab-content"
        id="myTabContent"
      >
        <div
          className="col tab-pane fade active show"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <Informasi
            classData={classData}
            pathwayAfters={pathwayAfters}
          />
        </div>
        <div
          className="col tab-pane fade"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          {canAccess === STATE_TYPES.TASK_SUCCESS && (
            <>
              {classData.classStatus.status === 'done' ||
              classData.classStatus.status === 'progress' ? (
                <Materi
                  classData={classData}
                  sectionList={sections}
                  allContents={contents}
                />
              ) : (
                <>
                  {classData.classStatus.status === 'none' && (
                    <NotFound
                      text="Materi belum bisa diakses"
                      icon="feather icon-lock"
                    />
                  )}
                </>
              )}
            </>
          )}
          {canAccess === STATE_TYPES.TASK_FAILURE && (
            <NotFound
              text="Materi belum bisa diakses"
              icon="feather icon-lock"
            />
          )}
        </div>
        <div
          className="col tab-pane fade"
          id="contact"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          {canAccess === STATE_TYPES.TASK_SUCCESS && (
            <>
              {classData.classStatus.status === 'done' ||
              classData.classStatus.status === 'progress' ? (
                <ForumDiskusi classId={classData.data.id} />
              ) : (
                <>
                  {classData.classStatus.status === 'none' && (
                    <NotFound
                      text="Forum belum bisa diakses"
                      icon="feather icon-lock"
                    />
                  )}
                </>
              )}
            </>
          )}
          {canAccess === STATE_TYPES.TASK_FAILURE && (
            <NotFound
              text="Forum belum bisa diakses"
              icon="feather icon-lock"
            />
          )}
        </div>
        <div
          className="col tab-pane fade"
          id="summary"
          role="tabpanel"
          aria-labelledby="summary-tab"
        >
          <ContentSummary class_id={classData.data.id} />
        </div>
      </div>
    </>
  )
}

import React from 'react'
import { ChatContextProvider } from 'context/chat'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import MessageList from 'component/chat/message/message-list'
import ContactList from 'component/chat/contact/contact-list'
import CardHeader from './templates/card-header'
import CardBodyWrapper from './templates/card-body-wrapper'
import MessageListWrapper from './templates/message-list-wrapper'
import LeftCol from './templates/left-col'
import Row from './templates/row'
import RightCol from './templates/right-col'
import TabPaneWrapper from './templates/tab-pane-wrapper'
import ChatPane from './templates/chat-pane'

const Chat = () => {
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Chat" />
        <Row>
          <ChatContextProvider>
            <LeftCol>
              <CardHeader />
              <CardBodyWrapper>
                <MessageListWrapper>
                  <MessageList />
                </MessageListWrapper>
                <TabPaneWrapper>
                  <ContactList />
                </TabPaneWrapper>
              </CardBodyWrapper>
            </LeftCol>
            <RightCol>
              <ChatPane />
            </RightCol>
          </ChatContextProvider>
        </Row>
      </Container>
    </TemplateAdmin>
  )
}

export default Chat

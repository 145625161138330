import AnswerCard from './answer-card'
import useMatchedAnswer from 'context/matched-answer'

export default function AnswerCardList() {
  /* ====================================== Consume Context ===================================== */
  const { matched } = useMatchedAnswer()

  /* ========================================== Output ========================================== */
  return matched.map((match, idx) => (
    <AnswerCard
      match={match}
      idx={idx}
      key={`answer_card_${idx}`}
    />
  ))
}

import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import useInputKindness from 'context/input-kindness';
import BaseButton from 'component/button/BaseButton';
import BaseInputTextArea from 'component/input/BaseInputTextArea';
import FormInputDraftItem from './form-input-draft-item';
import useGetRoleActive from 'hooks/useGetRoleActive';

const FormInputDraftGroup = ({ setShowEditUi, userId, parentItem }) => {
  /* --------------------------------- CONTEXT -------------------------------- */
  const { data, kindnessConfig, handlers } = useInputKindness();
  const roleActive = useGetRoleActive();
  const studentScores = data.studentScores

  /* --------------------------------- STATES --------------------------------- */
  const [key, setKey] = useState('proyek1');
  const [updateData, setUpdateData] = useState([]);

  /* -------------------------------- CONSTANT -------------------------------- */
  const isRoleAdminOrHeadmaster = roleActive.type === 'administrator' || roleActive.type === 'kepalasekolah';
  const isStatusSubmissionOrApproved = parentItem?.status === 'submission' || parentItem?.status === 'approved';

  /* -------------------------------- HANDLERS -------------------------------- */
  /**
   * On update data handler
   * 
   * @param {{
   *  projectIndex: number
   *  type: 'note' | 'proyek_scores'
   *  projectScoreData: {
   *    proyek_type_score_id: string
   *    index: number
   *    score: string
   *  }
   * }} params
   */
  const onUpdateDataHandler = ({ projectIndex, type = '', value, projectScoreData }) => {
    const copyUpdateData = [...updateData];

    switch (type) {
      case 'note':
        copyUpdateData[projectIndex].note = value;
        break;
      case 'proyek_scores':
        copyUpdateData[projectIndex].proyek_scores[projectScoreData.index].proyek_type_score_id = projectScoreData.proyek_type_score_id
        copyUpdateData[projectIndex].proyek_scores[projectScoreData.index].score = projectScoreData.score
        break;
      default:
        console.error(`The type ${type} not yet to handle`)
        break;
    }

    setUpdateData(copyUpdateData)
  }


  /**
   * Save Or Approve Report
   * 
   * @param {{
   *  type: 'save' | 'approve' | 'reject' | 'submit'
   * }} params 
   */
  const onSaveOrApproveReport = async ({ type }) => {
    switch (type) {
      case 'save':
        await handlers.saveStudentScores.handler({ data: updateData, userId })
        setShowEditUi(false);
        break;
      case 'submit':
        await handlers.submitReport.handler({ userId });
        break;
      case 'reject':
        await handlers.rejectReport.handler({ userId });
        break;
      case 'approve':
        await handlers.approveReport.handler({ userId });
        break;
      default:
        console.error(`The ${type} doesnt exist or not yet to define`);
        break;
    }
  }

  useEffect(() => {
    // SET INITIAL DATA
    if (Array.isArray(studentScores) && studentScores.length && updateData.length === 0) {
      const necessaryData = studentScores.map((item, projectIndex) => {
        const proyek_type_scores = item?.proyek_type_scores
        const proyek_user_notes = item?.proyek_user_notes

        return {
          proyek_id: item?.id,
          title: item?.title,
          description: item?.description,
          index: projectIndex,
          note: proyek_user_notes?.[0]?.note || '',
          proyek_scores: Array.from({ length: kindnessConfig.totalInputDimentions }).map((_, typeScoreIndex) => ({
            index: typeScoreIndex,
            proyek_type_score_id: proyek_type_scores?.[typeScoreIndex]?.id || '',
            score: proyek_type_scores?.[typeScoreIndex]?.proyek_user_scores?.[0]?.score || '', // keyword like BB, MB
            dimensi: proyek_type_scores?.[typeScoreIndex]?.dimensi || {},
            capaian: proyek_type_scores?.[typeScoreIndex]?.capaian || {},
            isStatusSubmissionOrApproved,
          }))
        }
      })

      setUpdateData(necessaryData)
    }
  }, [studentScores, updateData, setUpdateData, kindnessConfig.totalInputDimentions])

  return (
    <>
      {Array.isArray(studentScores) && !studentScores.length ?
        <p className='text-center'>Data deskripsi proyek tidak ditemukan.</p>
        : null}

      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="justify-content-center"
        fill
      >
        {Array.isArray(updateData) && updateData.length ? updateData.map((item, projectIndex) => {
          const proyek_scores = item?.proyek_scores

          return (
            <Tab eventKey={`proyek${projectIndex + 1}`} title={`Proyek ${item?.title}`} key={`rapor-p5-${item?.id}-projectIndex${projectIndex}`} tabClassName='has-ripple'>
              <div className='card'>
                <div className="card-body">
                  <p><b>Deskripsi Proyek</b>: {item?.description}</p>
                  <br />

                  <div className="bg-light border p-3 rounded">
                    <h6 className='text-center'>Form Input Rapor P5</h6>
                    <form>
                      <BaseInputTextArea
                        placeholder='Catatan'
                        isWithLabel={false}
                        props={{
                          value: item?.note || '',
                          onChange: (e) => {
                            const value = e.target.value;
                            onUpdateDataHandler({ type: 'note', projectIndex, value })
                          }
                        }}
                        isDisabled={handlers.saveStudentScores.loading || isStatusSubmissionOrApproved}
                        access={['instruktur']}
                      />
                      <table className="table">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Capaian Fase dan Dimensi</th>
                            <th className="px-5">Keterangan</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(proyek_scores) && proyek_scores.length ? proyek_scores.map((item, typeScoreIndex) => {
                            return (
                              <FormInputDraftItem
                                key={`input-draft-item${item?.id}-typeScoreIndex${typeScoreIndex}`}
                                typeScoreIndex={typeScoreIndex}
                                item={item}
                                onUpdateDataHandler={onUpdateDataHandler}
                                projectIndex={projectIndex}
                                parentItem={parentItem}
                              />
                            )
                          }) : null}
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
              </div>
            </Tab>
          )
        }) : null}
      </Tabs>
      <div className='d-flex justify-content-center'>
        {/* ADDITIONAL TEACHER BUTTONS */}
        <BaseButton
          access={['instruktur']}
          props={{ className: "btn-sm mr-2" }}
          onClick={() => onSaveOrApproveReport({ type: 'submit' })}
          isLoading={handlers.saveStudentScores.loading}
          isShow={!isRoleAdminOrHeadmaster && (parentItem?.status === 'progress' || parentItem?.status === 'rejected')}
        >
          <i className="fas fa-paper-plane mr-1" />
          Ajukan Rapor
        </BaseButton>

        {/* ADDITIONAL ADMINISTRATOR OR HEADMASTER BUTTONS */}
        <BaseButton
          access={['administrator', 'kepalasekolah']}
          props={{ className: "btn-sm mr-2" }}
          onClick={() => onSaveOrApproveReport({ type: 'approve' })}
          isLoading={handlers.saveStudentScores.loading}
          variant='success'
          isShow={isRoleAdminOrHeadmaster && parentItem?.status === 'submission'}
        >
          <i className="fas fa-check-circle mr-1" />
          Approve Rapor
        </BaseButton>
        <BaseButton
          access={['administrator', 'kepalasekolah']}
          props={{ className: "btn-sm mr-2" }}
          onClick={() => onSaveOrApproveReport({ type: 'reject' })}
          isLoading={handlers.saveStudentScores.loading}
          variant='danger'
          isShow={isRoleAdminOrHeadmaster && ['approved', 'submission'].includes(parentItem?.status)}
        >
          <i className="fas fa-times-circle mr-1" />
          Tolak Rapor
        </BaseButton>

        {parentItem?.status !== 'approved' ?
          <BaseButton
            access={['instruktur']}
            props={{ className: "btn-sm mr-2" }}
            variant='success'
            onClick={() => onSaveOrApproveReport({ type: 'save' })}
            isLoading={handlers.saveStudentScores.loading}
            isDisabled={isStatusSubmissionOrApproved || !studentScores.length}
            isShow={!isRoleAdminOrHeadmaster}
          >
            Simpan
          </BaseButton>
          : null}
        <BaseButton
          props={{ className: "btn btn-sm btn-outline-secondary" }}
          onClick={() => {
            setShowEditUi(false);
          }}
          variant='' // empty string is mean btn-outline or without variant
        >
          Tutup
        </BaseButton>
      </div>
    </>
  );
}

export default FormInputDraftGroup
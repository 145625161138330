import React, { useState } from 'react'
import { detectionDoc } from 'util/global'

export default function KindnessProgram({
  semester,
  userId,
  dataKindness,
  setDataKindness,
  deskripsi,
  catatan,
}) {
  const optionsKeterangan = [
    { value: 'BB', label: 'BB - Belum Berkembang' },
    { value: 'MB', label: 'MB - Mulai Berkembang' },
    { value: 'BSH', label: 'BSH - Berkembang Sesuai Harapan' },
    { value: 'SB', label: 'SB - Sangat Berkembang' },
  ]

  const [judul, setjudul] = useState(
    'Raport Proyek Penguatan Profil Pelajar Pancasila',
  )
  const [kindness, setKindness] = useState([])

  if (dataKindness[0]) {
    const { pathname } = new URL(dataKindness[0].linkFile)
    let type = pathname.split('.').pop()
    const encode = encodeURIComponent(dataKindness[0].linkFile)
    return (
      <div className="row">
        <div className="col-12">
          <iframe
            src={detectionDoc(type, encode)}
            style={{
              border: 'none',
              height: '100vh',
              width: '100%',
              backgroundColor: '#ddd',
            }}
          ></iframe>
        </div>
      </div>
    )
  } else {
    return null
  }
}

import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import Swal from 'sweetalert2'
import moment from 'moment'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'
import Modal from 'component/modal'
import { avatarDefaultUser } from 'util/constant'
import { openUrlNewTab } from 'util/global'

export default function InputRaporAll() {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [groups, setGroups]                 = useState([])
  const [selectGroup, setSelectGroup]       = useState(null)
  const [loadingScores, setLoadingScores]   = useState(false)
  const [limit, setLimit]                   = useState(10)
  const [offset, setOffset]                 = useState(0)
  const [search, setSearch]                 = useState('')
  const [data, setData]                     = useState([])
  const [totalRecords, setTotalRecords]     = useState(0)
  const [roleType, setRoleType]             = useState(null)
  const [historyRapor, setHistoryRapor]     = useState(null)
  const [optionSemester, setOptionSemester] = useState([])
  const [selectSemester, setSelectSemester] = useState(null)

  /* ========================================= Functions ======================================== */
  async function getUserGroupRequired() {
    if (selectGroup && selectSemester) {
      const response = await getRequest(
        `report-wali-users?group_id=${
          selectGroup.value
        }&limit=${limit}&offset=${offset}&search=${search}&ta_semester_code=${
          selectSemester ? selectSemester.value : null
        }`,
      )
      if (response) {
        setData(response.data.users)
        setTotalRecords(response.data.totalRecord)
      }
    } else {
      setData([])
      setTotalRecords(0)
      setLimit(10)
      setOffset(0)
      setSearch('')
    }
  }

  async function getSemesterCode() {
    setLoadingScores(true)
    try {
      let response = await getRequest(`semester`)
      if (response) {
        let arr = []
        response.data.forEach((val) => {
          arr.push({
            value: val.code,
            label: `${val.tahun_mulai}/${val.tahun_selesai} - ${
              val.title_render
            } ${val.status ? '(Aktif)' : ''}`,
          })
        })
        setOptionSemester(arr)
      }
    } catch (error) {
      console.error('ERROR', { error })
    }
    setLoadingScores(false)
  }

  const getGroups = async (ta_semester_code) => {
    setLoadingScores(true)
    const response = await getRequest(
      `semester/${selectSemester ? selectSemester.value : null}/groups-wali`,
    )
    if (response) {
      if (response.data.length > 0) {
        const options = response.data.map((item) => ({
          value: item.id,
          label: item.group,
        }))
        setGroups(options)
      } else {
        setGroups([])
      }
    }
    setLoadingScores(false)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    let roleAktif = localStorage.getItem('role_active')
    if (roleAktif) {
      let js = JSON.parse(roleAktif)
      setRoleType(js.role_type)
    }
    getSemesterCode()
  }, [])  

  useEffect(() => {
    getGroups()
    if (selectSemester == null) {
      setSelectGroup(null)
    }
    getUserGroupRequired()
  }, [selectGroup, limit, offset, search, selectSemester])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Rapor All" />
        
        <div className="card user-profile-list">
          <div className="card-body">
            <div className="row mb-3">
              <div className="col-md-4 mb-3 mb-md-0">
                <span className="f-w-700 f-14">Semester</span>
                <Select
                  className="mt-2"
                  placeholder="Pilih semester..."
                  isMulti={false}
                  options={optionSemester}
                  value={selectSemester}
                  onChange={(option) => {
                    setSelectSemester(option)
                    if (selectSemester) {
                      setSelectGroup(null)
                    }
                  }}
                  isClearable={true}
                  isLoading={loadingScores}
                  isDisabled={loadingScores}
                />
              </div>
              {selectSemester && (
                <div className="col-md-3 mb-3 mb-md-0">
                  <span className="f-w-700 f-14">Kelas</span>
                  <Select
                    className="mt-2"
                    placeholder="Pilih kelas..."
                    isMulti={false}
                    options={groups}
                    value={selectGroup}
                    onChange={(option) => {
                      setSelectGroup(option)
                    }}
                    isClearable={true}
                    isLoading={loadingScores}
                    isDisabled={loadingScores}
                  />
                </div>
              )}
            </div>
            <div className="dt-responsive table-responsive">
              <DataTable
                className={'table table-striped table-bordered nowrap'}
                data={data}
                totalRows={totalRecords}
                show={[10, 20, 30, 40, 50]}
                onChangeSearch={(val) => {
                  console.log('val', val)
                  setSearch(val)
                }}
                onChangeLimit={(val) => {
                  setLimit(val)
                }}
                onChangePage={(val) => {
                  setOffset(val)
                }}
                column={[
                  'Nama',
                  'No. Induk',
                  'Username',
                  'Status',
                  'Catatan',
                  'Actions',
                ]}
                renderItem={(item, i) => (
                  <RenderItem
                    item={item}
                    key={i}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </Container>

      <Modal
        id="modalHistoryRapor"
        title="Riwayat rapor"
      >
        <div
          className="hd-body pr-2"
          style={{
            maxHeight: '350px',
            overflowY: 'auto',
          }}
        >
          {historyRapor ? (
            <>
              {historyRapor.map((history, index) => (
                <div
                  key={index}
                  className="excerpt rounded mb-3"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <span>
                      <i className="feather icon-calendar mr-1 f-14" />
                      {history.createdAt
                        ? moment(history.createdAt).format('H:MM, DD-MM-YYYY')
                        : '-'}
                    </span>
                    <span className="badge badge-danger">Ditolak</span>
                  </div>
                  <h6 className="mb-1">Catatan:</h6>
                  <p className="m-0">{history.note ? history.note : '-'}</p>
                </div>
              ))}
            </>
          ) : (
            <p className="text-muted mb-0">Belum ada riwayat</p>
          )}
        </div>
      </Modal>
    </TemplateAdmin>
  )

  function RenderItem({ item }) {
    const { getRequest } = useBaseContext()

    async function handleGetRapor(item) {
      Swal.fire({
        title: 'Download rapor...',
        html: `<div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>`,
        allowOutsideClick: false,
        showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
      })
      const response = await getRequest(
        `check-file-rapor?user_id=${item.id}&semester_code=${selectSemester?.value}`,
      )
      if (response) {
        const pathBase64 = response.data.downloadPath
        const url = `${pathBase64}`
        openUrlNewTab(url)
        Swal.close()
      } else {
        Swal.fire({
          html: `
            <div>
              <i class="feather icon-clipboard f-64"></i>
              <p class="mb-0 mt-3 f-18 f-w-700">Maaf, rapor belum tersedia</p>
            </div>
                    `,
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'Tutup',
        })
      }
    }

    return (
      <tr>
        <td>
          <div className="d-inline-block align-middle">
            <img
              src={item.avatar ? `${item.linkAvatar}` : avatarDefaultUser}
              alt={item.name}
              className="img-radius align-top m-r-15"
              style={{ width: '40px', height: '40px' }}
              onError={(event) => {
                event.currentTarget.src = avatarDefaultUser
              }}
            />
            <div className="d-inline-block">
              <h6 className="mb-0 mt-2">{item.name}</h6>
            </div>
          </div>
        </td>
        <td>{item.nim ? item.nim : '-'}</td>
        <td>{item.username}</td>
        <td>
          {item.status === 'draft' && (
            <span className="badge badge-secondary">
              Belum dilakukan input penilaian
            </span>
          )}
          {item.status === 'approved' && (
            <span className="badge badge-success">Telah disetujui</span>
          )}
          {item.status === 'progress' && (
            <span className="badge badge-info">
              Sedang dalam progres input nilai
            </span>
          )}
          {item.status === 'submission' && (
            <span className="badge badge-warning">
              Menunggu persetujuan Kepala Sekolah
            </span>
          )}
          {item.status === 'rejected' && (
            <span className="badge badge-danger">Rapor ditolak</span>
          )}
        </td>
        <td>
          {item.catatan && item.catatan.report_master_temps.length > 0 && (
            <button
              onClick={() => setHistoryRapor(item.catatan?.report_master_temps)}
              data-toggle="modal"
              data-target="#modalHistoryRapor"
              className="btn btn-warning has-ripple btn-sm"
            >
              Lihat Catatan
            </button>
          )}
        </td>
        <td>
          <Button item={item} />
        </td>
      </tr>
    )

    function Button({ item }) {
      if (
        roleType == 'kepalasekolah' ||
        roleType == 'administrator' ||
        roleType == 'adminprofile'
      ) {
        return (
          <>
            <button
              type="button"
              className={`${
                item.status == 'approved' || item.status == 'submission'
                  ? 'btn-info'
                  : 'btn-secondary btn-forbidden'
              } btn btn-sm`}
              onClick={() => {                
                window.open(
                  '/preview-rapor/' + item.id + '/' + selectSemester.value,
                  '_blank',
                )
              }}
              title="Lihat, Tolak & Approve Rapor"
            >
              <i className="feather icon-clipboard mr-1" />
              Lihat Rapor
            </button>
          </>
        )
      } else {
        return (
          <>
            <button
              type="button"
              className={`${
                item.status === 'approved' || item.status === 'submission'
                  ? 'btn-secondary btn-forbidden'
                  : 'btn-info'
              } btn btn-sm`}
              onClick={
                item.status === 'approved' || item.status === 'submission'
                  ? () => {}
                  : () => {                      
                      window.open(
                        '/input-rapor-all/' +
                          item.id +
                          '/' +
                          selectSemester.value,
                        '_blank',
                      )
                    }
              }
              disabled={
                item.status === 'approved' || item.status === 'submission'
                  ? true
                  : false
              }
            >
              <i className="feather icon-clipboard mr-1" />
              Isi Rapor
            </button>
            <button
              type="button"
              className={`${
                item.status === 'approved'
                  ? 'btn-primary'
                  : 'btn-secondary btn-forbidden'
              } btn btn-sm ml-2`}
              onClick={
                item.status === 'approved'
                  ? () => handleGetRapor(item)
                  : () => {}
              }
              disabled={item.status !== 'approved'}
            >
              <i className="fas fa-eye mr-1" />
              Lihat Rapor
            </button>
          </>
        )
      }
    }
  }
}

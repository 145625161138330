import React, { useEffect } from 'react'
import { createContext, useContext, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useBaseContext } from 'context/base'

const ContentPlayerContext = createContext()

export function ContentPlayerWrapper({ children }) {
  /* ========================================== Helpers ========================================= */
  const params   = useParams()
  const history  = useHistory()
  const location = useLocation()

  /* ====================================== Consume Context ===================================== */
  const {
    getRequest,
    postRequest,
    putRequest,
    postContentActivities,
    deleteRequest,
  } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const classId = params?.classId || params?.kelasId
  const isSiswa = JSON.parse(localStorage.getItem('role_active'))?.role_type === 'siswa'
  const userId  = JSON.parse(localStorage.getItem('user')).id

  /* ====================================== General States ====================================== */
  const [content, setContent]           = useState([])
  const [tabActive, setTabActive]       = useState(1)
  const [showPlaylist, setShowPlaylist] = useState(true)

  const handleTogglePlaylist = () => setShowPlaylist(!showPlaylist)

  const markContentSubmit = () => {
    setIsContentCompleted(true)
    setContent((_content) => ({
      ..._content,
      is_assignment_evaluated : false,
      is_quiz_evaluated       : false,
      is_survey_submitted     : true,
      is_graphor_submitted    : true,
    }))
    const _allContents = [...allContents]
    const found = _allContents.find(ac => ac.id === content?.id)
    if (found) { 
      found.isSubmitted = true 
      setAllContents(_allContents)
    }
  }

  const markContentComplete = () => {
    setIsContentCompleted(true)
    setContent((_content) => ({
      ..._content,
      is_assignment_evaluated : true,
      is_quiz_evaluated       : true,
    }))
    const _allContents = [...allContents]
    const found = _allContents.find(ac => ac.id === content?.id)
    if (found) {
      found.isSubmitted = true
      found.isCompleted = true
      setAllContents(_allContents)
    }
  }

  /* ============================================================================================ */
  /*                                             KELAS                                            */
  /* ============================================================================================ */
  const [classData, setClassData] = useState({})
  const [displayBy, setDisplayBy] = useState('')
  const [loadingKelas, setLoadingKelas] = useState(true)  

  async function getClassData() {
    const response = await getRequest(`kelas-siswa?class_id=${classId}`)
    setLoadingKelas(false)
    if (response) {
      setClassData(response.data)
      setDisplayBy(response.data.show_units)
    } else {
      console.error("🚀 ~ file: content-player.js:78 ~ getClassData ~ response:", response)
    }
  }

  /* ============================================================================================ */
  /*                                             NOTES                                            */
  /* ============================================================================================ */
  /* ======================================= Notes States ======================================= */
  const [listNotes, setListNotes]       = useState([])
  const [textNotes, setTextNotes]       = useState('')
  const [loadingNotes, setLoadingNotes] = useState(false)

  /* ====================================== Notes Function ====================================== */
  async function getNotesContent() {
    if (!content?.id) return
    let res = await getRequest('personalNote?content_id=' + content.id)
    if (res) {
      setListNotes(res.data)
    }
  }

  async function handleCreateNotes() {
    setLoadingNotes(true)
    let form = {
      note: textNotes,
      content_id: content.id,
    }
    let res = await postRequest('personalNote', form, true, 'membuat notes')
    if (res) {
      setLoadingNotes(false)
      setTextNotes('')
      getNotesContent()
    }
    setLoadingNotes(false)
  }

  async function handleEditNote(item, edited) {
    let form = {
      id: item.id,
      note: edited,
    }
    let res = await putRequest('personalNote', form, true, 'mengubah notes')
    if (res) {
      getNotesContent()
    }
  }

  async function handleDeleteNotes(idNotes) {
    Swal.fire({
      title             : 'Delete notes !',
      text              : 'Apakah yakin ingin menghapus notes',
      icon              : 'question',
      showCancelButton  : true,
      showConfirmButton : true,
      cancelButtonText  : 'Tidak',
      confirmButtonText : 'Ya !',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteRequest(
          'personalNote?id=' + idNotes,
          true,
          'menghapus notes',
        )
        if (res) {
          getNotesContent()
        }
      }
    })
  }

  /* ============================================================================================ */
  /*                                             FILES                                            */
  /* ============================================================================================ */
  /* ======================================== Files State ======================================= */
  const [listFile, setListFile] = useState([])

  /* ====================================== Files Function ====================================== */
  async function getFileClass() {
    const response = await getRequest(
      `classes-file?class_id=${classId}`,
    )
    response && setListFile(response.data)
  }

  /* ============================================================================================ */
  /*                                             FORUM                                            */
  /* ============================================================================================ */
  const [forum, setForum] = useState(null)
  const [groupDescription, setGroupDescription] = useState('')

  var participantsId = [
    ...new Set(
      forum?.forums.reduce((arr, next) => {
        arr.push(next.login.user_id)
        next.forums &&
          next.forums.forEach((n) => {
            arr.push(n.login.user_id)
          })
        return arr
      }, []),
    ),
  ]

  async function getForumByContent(contentId) {
    const groupForum = content?.id && await getRequest(
      `forum-group?content_id=${contentId}`,
    )
    if (groupForum) {
      setForum(groupForum.data)
      setGroupDescription(groupForum.group_description)
    } else {
      setForum(null)
    }
  }

  /* ============================================================================================ */
  /*                                            RATING                                            */
  /* ============================================================================================ */
  const [rating, setRating]               = useState(null)
  const [isRated, setIsRated]             = useState(false)
  const [loadingRating, setLoadingRating] = useState(false)

  async function getUsersRating() {
    if (content.id) {
      setLoadingRating(true)
      const response = await getRequest(
        `users-rating?type=content&value_id=${content.id}`,
      )
      setLoadingRating(false)
      if (response) {
        setRating(response.data)
        checkIsContentRated(response.data.ratings)
      }
    }
  }

  function checkIsContentRated(ratings) {
    if (ratings.length !== 0) {
      const { id } = JSON.parse(localStorage.getItem('user'))
      for (let rating of ratings) {
        if (
          rating.user_id === id &&
          rating.type === 'content' &&
          rating.value_id === content.id
        ) {
          setIsRated(true)
          break
        }
      }
    }
  }

  /* ============================================================================================ */
  /*                                         KEY TAKE AWAY                                        */
  /* ============================================================================================ */
  const [dataKeyTakeAway, setDataKeyTakeAway] = useState(null)

  async function getDataKeyTakeAway() {
    if (!content?.id) return
    let res = await getRequest(
      `contentTakeAway?content_id=${content.id}`,
    )
    if (res) {
      setDataKeyTakeAway(res.data || false)
    }
  }

  /* ============================================================================================ */
  /*                                           ACTIVITY                                           */
  /* ============================================================================================ */
  // id for all type content that finished
  const [idDone, setIdDone] = useState('')

  // ACTIVITY STATE
  const [activityId, setActivityId]                 = useState('')
  const [allContents, setAllContents]               = useState([])
  const [isClassCompleted, setIsClassCompleted]     = useState(false)
  const [isContentCompleted, setIsContentCompleted] = useState(false)
  
  // id for audio, video, quiz, survey, assignemnt when content is finish/submited
  const [mediaIdDone, setMediaIdDone] = useState('')

  async function updateContentDone() {
    const _allContents = [...allContents]
    const found = _allContents?.find(ac => ac.id === idDone)
    if (found) {
      await reportActivity(true)
      found.isCompleted = true
      setAllContents(_allContents)
      checkIsClassCompleted()
      setActivityId('')
      setIdDone('')
      setMediaIdDone('')
    }
    // await getClassData()
  }

  async function initActivity() {
    if (content?.id && isSiswa && classId) {
      await postContentActivities(
        content.id,
        classId,
        content.content_type_id,
        setActivityId,
      )
      await getClassActivities()

      localStorage.setItem('prev_content_id', content.id)
      localStorage.setItem('last_content_type_id', content.content_type_id)
      localStorage.setItem('class_id', classId)
    }
  }

  async function reportActivity(isCompleted = null) {
    const isAssignmentType = content?.content_type_id === 8
    const data = {
      content_id  : content.id,
      class_id    : classId,
      activity_id : activityId,
      is_complete : isCompleted ? 1 : 0,
    }
    activityId && !isAssignmentType && await putRequest("class-content-activities", data)
  }

  async function getClassActivities() {
    // Hanya siswa yang ambil ke class-content-activities, guru tidak perlu
    const m_keys = JSON.parse(localStorage.getItem('menu_key'))
    if (!m_keys.includes('katalog-kelas')) return

    const response = await getRequest(
      `class-activities?class_id=${classId}`,
    )
    if (response) {
      response.data &&
        localStorage.setItem('class_activity_id', response.data.id)
    }
  }

  function initAllContents() {
    let initContents = []
    classData?.sections?.forEach((section) => {
      section.contents.length &&
        section.contents.forEach((content) => {
          let isCompleted = content.class_content_activities.some(
            (cca) => cca.completed_at,
          )
          
          const isSubmitted =
            content.is_assignment_evaluated !== null ||
            content.is_quiz_evaluated !== null

          const isEvaluated =
            content.is_assignment_evaluated || content.is_quiz_evaluated

          initContents.push({
            id    : content.id,
            title : content.title,
            isCompleted,
            isSubmitted,
            isEvaluated,
            isAssignmentOrQuiz : [6,8].includes(content.content_type_id),
          })
        })
    })
    setAllContents(initContents)
  }

  function checkIsClassCompleted() {
    const _isClassCompleted = allContents.every(
      (content) => content.isCompleted,
    )
    setIsClassCompleted(_isClassCompleted)
  }

  async function checkIsContentCompleted() {
    const contentState = allContents.find((ac) => ac.id === content.id)
    const isAssignmentOrQuiz  = [6,8].includes(content.content_type_id)
    const QuizSubmitted       = content.is_quiz_evaluated !== null // null -> blm submit, false -> sudah submit
    const AssignmentSubmitted = content.is_assignment_evaluated !== null // null -> blm submit, false -> sudah submit
    const _isTaskSubmitted    = isAssignmentOrQuiz && (QuizSubmitted || AssignmentSubmitted);

    const isAutoComplete = !isAssignmentOrQuiz // kalau tipenya selain assignment dan quiz

    if (isAutoComplete || contentState?.isCompleted || _isTaskSubmitted) {
      content?.rating && (await getUsersRating())
      await getForumByContent(content.id)
      setIsContentCompleted(true)
    } else {
      setIsContentCompleted(false)
    }
  }

  useEffect(() => {
    isSiswa && getClassData()
  }, [])

  useEffect(() => {
    isSiswa && getFileClass()
  }, [activityId])

  // handle kalau activity sudah selesai
  useEffect(() => {
    activityId && allContents && idDone && updateContentDone()
  }, [activityId, allContents, idDone])

  // POST ACTIVITY trigger when content change
  useEffect(() => {
    if (isSiswa) {
      checkIsContentCompleted()
      setForum(null)
      setRating(null)
      setIsRated(false)
      initActivity()
    }
  }, [content])

  useEffect(() => {
    isSiswa && classData?.sections?.length && initAllContents()
  }, [classData?.sections])

  useEffect(() => {
    if (allContents.length !== 0) {
      checkIsClassCompleted()
      checkIsContentCompleted()
    }
  }, [allContents])

  useEffect(() => {
    if (isClassCompleted) {
      localStorage.setItem('class_completed', 1)
    } else {
      localStorage.setItem('class_completed', 0)
    }
  }, [isClassCompleted])

  /* ============================================================================================ */
  /*                                           FEEDBACK                                           */
  /* ============================================================================================ */
  const markContentFeedback = (data) => {
    setContent(_content => ({
      ..._content,
      feed: data,
    }))
  }

  /* ============================================================================================ */
  /*                                            EFFECTS                                           */
  /* ============================================================================================ */
  // handle perubahan content
  useEffect(() => {
    let content = location?.state?.dataContent || location?.state?.content
    setContent(content)
    setLoadingKelas(false)

    if (location) {
      if (!location.state) {
        history.push('/katalog-kelas')
      } else {
        if (content.feedback) {
          localStorage.setItem('is_feedback_active', 'yes')
        } else {
          localStorage.setItem('is_feedback_active', 'no')
        }
      }
    }
  }, [location])

  /* ======================================= Shared Props ======================================= */
  const sharedProps = {
    content,
    allContents,
    tab: {
      current: tabActive,
      set: setTabActive,
    },
    playlist: {
      isShow: showPlaylist,
      toggle: handleTogglePlaylist,
    },
    activity: {
      id     : activityId,
      init   : initActivity,
      report : reportActivity,
      isAssignmentOrQuiz: 
        [6,8].includes(content.content_type_id),
      isGraphorOrSurvey: 
        [7,9].includes(content.content_type_id),
      isContentCorrected: 
        content.is_assignment_evaluated ||
        content.is_quiz_evaluated,
      isContentSubmitted:
        content.is_assignment_evaluated === false ||
        content.is_quiz_evaluated === false ||
        content.is_graphor_submitted || 
        content.is_survey_submitted,
      check      : checkIsContentCompleted,
      markSubmit : markContentSubmit,
      markComplete : markContentComplete,
      markFeedback: markContentFeedback,
      idDone,
      setIdDone,
      mediaIdDone,
      setMediaIdDone,
      isContentCompleted,
    },
    files: {
      data: listFile,
      set: setListFile,
      fetch: getFileClass,
    },
    kelas: {
      id: classId,
      data: classData,
      loading: loadingKelas,
      fetch: getClassData,
      displayBy,
    },
    forum: {
      data: forum,
      set: setForum,
      list: forum?.forums,
      fetch: getForumByContent,
      desc: groupDescription,
      required: content?.group_status,
      participants: { id: participantsId },
      participated: participantsId.some((uid) => uid === userId),
    },
    rating: {
      data: rating,
      loading: loadingRating,
      fetch: getUsersRating,
      reset: () => (setIsRated(false), setRating(null)),
      isRated,
    },
    notes: {
      data: textNotes,
      loading: loadingNotes,
      set: setTextNotes,
      list: listNotes,
      resetList: () => setListNotes(null),
      fetch: getNotesContent,
      create: handleCreateNotes,
      edit: handleEditNote,
      delete: handleDeleteNotes,
    },
    keyTakeAway: {
      data: dataKeyTakeAway,
      reset: () => setDataKeyTakeAway(null),
      fetch: getDataKeyTakeAway,
    },
  }

  /* ====================================== Return Provider ===================================== */
  return (
    <ContentPlayerContext.Provider value={sharedProps}>
      {children}
    </ContentPlayerContext.Provider>
  )
}

/* ========================================= Return Hook ======================================== */
export default function useContentPlayerContext() {
  return useContext(ContentPlayerContext)
}

import React, { useEffect, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import moment from 'moment'
import 'moment/locale/id'

import { useBaseContext } from 'context/base'
import LoadingSpinner from 'component/loading'
import ForumDiscussItem from 'component/forum/forum-detail-discuss-item'
import { minimalConfig } from 'util/ckeditor-config'
import { avatarDefaultUser } from 'util/constant'

export default function DetailDiskusi({ forumId }) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [data, setData]                                 = useState([])
  const [forum, setForum]                               = useState([])
  const [showReply, setShowReply]                       = useState('')
  const [descDiscuss, setDescDiscuss]                   = useState('')
  const [descReply, setDescReply]                       = useState('')
  const [user, setUser]                                 = useState('')
  const [isLoading, setIsLoading]                       = useState(false)
  const [isLoadingSendDiscuss, setIsLoadingSendDiscuss] = useState(false)
  const [isDiscussEmpty, setIsDiscussEmpty]             = useState(false)

  /* ========================================= Functions ======================================== */
  async function getForum() {
    let response = await getRequest('forum/' + forumId)
    setIsLoading(false)
    if (response) {
      setData(response.data)
      setForum(response.data.forums)
      setUser(response.data.login.user)
    }
  }

  async function sendDiscuss() {
    if (descDiscuss != '') {
      setIsDiscussEmpty(false)
      setIsLoadingSendDiscuss(true)
      let form = new FormData()
      form.append('description', descDiscuss)
      form.append('parent_id', data.id)

      let response = await postRequest('discuss', form)
      setIsLoadingSendDiscuss(false)
      if (response) {
        window.notification('Berhasil', 'Diskusi berhasil dikirim', 'success')
        getForum()
        setDescDiscuss('')
      } else {
        console.error('ERROR', response)
        window.notification(
          'Terjadi kesalahan',
          'Coba beberapa saat lagi',
          'error',
        )
      }
    } else {
      setIsDiscussEmpty(true)
    }
  }

  async function sendReply(id) {
    if (descReply != '') {
      let form = new FormData()
      form.append('description', descReply)
      form.append('parent_id', id)

      let response = await postRequest('discuss', form)

      if (response) {
        window.notification(
          'Berhasil',
          'Komentar berhasil di tambahkan',
          'success',
        )
        getForum()
        setDescReply('')
      } else {
        console.error('ERROR', response)
        window.notification(
          'Terjadi kesalahan',
          'Coba beberapa saat lagi',
          'error',
        )
      }
    }
  }
  
  async function sendLike({ id, like }) {
    let form = new FormData()
    form.append('id', id)
    form.append('likes', like == 1 ? 0 : 1)

    let response = await putRequest('forum', form)
    if (response) {
      getForum()
    } else {
      console.error('ERROR', response)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    setIsLoading(true)
    getForum()
  }, [])

  /* ========================================= Constants ======================================== */
  let dd = moment(data.updatedAt).add(30, 'days').valueOf()
  let date =
    dd < moment().valueOf()
      ? moment(data.updatedAt).format('DD MMM YYYY')
      : moment(data.updatedAt).fromNow()

  /* ========================================== Output ========================================== */
  return (
    <div className="col-sm-12 px-0">
      <div className="mb-5">
        <div className="">
          {isLoading ? (
            <div className="row mb-3">
              <div className="col">
                <LoadingSpinner />
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col">
                <h4 className="mb-1">{data.title}</h4>
                <p className='mb-3'>{data.description}</p>
                <div className='border rounded p-2'>
                  <h6>Penulis:</h6>
                  <div className="d-flex">
                    <img
                      src={user ? `${user.linkAvatar}` : ''}
                      alt={user.name ? user.name : 'User'}
                      className="img-radius mb-2"
                      style={{ height: '50px', width: '50px' }}
                      onError={(e) => (e.currentTarget.src = avatarDefaultUser)}
                    />
                    <div className="ml-2">
                      <p className="mb-0 d-flex align-items-center">
                        <span className="f-w-500">{user.name}</span>
                      </p>
                      <p
                        className="text-muted mb-0"
                        style={{ fontSize: '10px' }}
                      >
                        <i className="feather icon-clock mr-1" />
                        {date}
                      </p>
                      <p
                        className="text-muted"
                        style={{ fontSize: '10px' }}
                      >
                        <i className="fas fa-comments mr-1" />
                        {forum.length} diskusi
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-md-12">
          <h5>Mulai Diskusi</h5>
          <CKEditor
            editor={Editor}
            data={descDiscuss}
            config={minimalConfig}
            onChange={(_, editor) => {
              const data = editor.getData()
              if (data) {
                setIsDiscussEmpty(false)
              }
              setDescDiscuss(data)
            }}
          />
          {isDiscussEmpty ? (
            <span className="col small form-text text-danger">
              Diskusi tidak boleh kosong.
            </span>
          ) : null}
          {isLoadingSendDiscuss ? (
            <button
              disabled={true}
              type="button"
              className="btn btn-forbidden btn-primary mt-3"
            >
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
              />
              <span>Kirim Diskusi</span>
            </button>
          ) : (
            <button
              onClick={() => sendDiscuss()}
              className="btn btn-primary mt-3"
            >
              Kirim Diskusi
            </button>
          )}
        </div>
      </div>
      <h5>Diskusi ({forum.length})</h5>
      <hr className="mb-0" />
      {forum.length !== 0 ? (
        <>
          {forum.map((item) => {
            let dd = moment(item.updatedAt).add(30, 'days').valueOf()
            let date =
              dd < moment().valueOf()
                ? moment(item.updatedAt).format('DD MMM YYYY')
                : moment(item.updatedAt).fromNow()
            return (
              <ForumDiscussItem
                key={item.id}
                item={item}
                date={date}
                sendLike={sendLike}
                setShowReply={setShowReply}
                setDescReply={setDescReply}
                sendReply={sendReply}
                showReply={showReply}
                descReply={descReply}
              />
            )
          })}
        </>
      ) : (
        <div className="d-flex flex-column justify-content-center my-4">
          <i
            className="fas fa-comments f-60 text-center mb-3"
            style={{
              color: '#ccc',
            }}
          />
          <h6
            className="text-center"
            style={{
              color: '#bbb',
            }}
          >
            Belum ada diskusi
          </h6>
        </div>
      )}
    </div>
  )
}

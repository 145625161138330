import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useBaseContext } from 'context/base'

export default function AllScoresType3({ userId, reportGroupId, response }) {
  /* ========================================== Helpers ========================================= */
  const params = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= States ========================================= */
  const [res, setRes] = useState(null)
  const [datax, setDatax] = useState([])

  /* ======================================== Functions ======================================= */
  async function getValue() {
    let us = localStorage.getItem('user')
    let usj = JSON.parse(us)
    var rsp = await getRequest(
      `get-value-score-by-group?user_id=${userId}&group_id=${reportGroupId}&semester_code=${params.semester}`,
    )

    if (rsp) {
      var ss = {}
      if (rsp.data) {
        var d = rsp.data
        ss = {
          user_id: d.user_id,
          categories_id: d.categories_id,
          categories_type_id: d.categories_type_id,
          report_group_id: d.report_group_id,
          createdBy: d.createdBy,
          updatedBy: d.updatedBy,
          value: JSON.parse(d.value),
          ta_semester_code: d.ta_semester_code,
          profile_id: d.profile_id,
        }
        setDatax(JSON.parse(d.value))
        setRes(ss)
      } else {
        ss = {
          user_id: userId,
          categories_id: null,
          categories_type_id: null,
          report_group_id: reportGroupId,
          createdBy: usj.id,
          updatedBy: usj.id,
          value: [],
          ta_semester_code: params.semester,
          profile_id: usj.profile_id,
        }
        setRes(ss)
      }
      response(ss)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getValue()
  }, [])

  useEffect(() => {
    if (res) {
      var r = res
      r.value = datax
      response(r)
    }
  }, [datax])

  /* ========================================== Output ========================================== */
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th style={{ width: 50 }}>No</th>
            <th style={{ width: 300 }}>Ekstrakurikuler</th>
            <th>Deskripsi</th>
          </tr>
        </thead>
        <tbody>
          {datax.map((item, index) => {
            var i = index
            return (
              <tr>
                <td>{++i}</td>
                <td>{item.ekstrakurikuler}</td>
                <td>{item.deskripsi}</td>
              </tr>
            )
          })}
          {datax.length == 0 ? (
            <tr>
              <td colSpan={4}>Tidak ada data</td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  )
}

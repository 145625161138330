import React from 'react'
import useContentPlayerContext from 'context/content-player'
import useMainContent from 'context/main-content'
import { detectionDoc } from 'util/global'

const Document = ({ isPreview = false, content_text, contents_url, content_source }) => {
  /* ====================================== Consume Context ===================================== */
  const { content, activity } = useContentPlayerContext()
  const { shouldSendCCA }     = useMainContent()

  const using_contents_url = contents_url || content.contents_url

  /* ========================================= Functions ======================================== */
  const docUrl = () => {
    if (using_contents_url?.source === 'gdrive') {
      return `${using_contents_url?.url || using_contents_url?.data}`
    } else if (using_contents_url?.source === 'internal') {
      if (using_contents_url?.url) {
        const { pathname } = new URL(using_contents_url?.url)
        let type = pathname.split('.').pop()
        return detectionDoc(type, encodeURIComponent(using_contents_url?.url))
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  function setComplete() {
    shouldSendCCA && activity.setIdDone(content.id)
  }

  /* ========================================== Output ========================================== */
  return (
    <>
      {using_contents_url?.source === 'gdrive' ? (
        <iframe
          key={content.id}
          src={`${docUrl()}/preview`}
          style={{
            width: '100%',
            height: '100vh',
            border: 'none',
            backgroundColor: '#ddd',
          }}
          onLoad={() => {
            if (isPreview === false) {
              setComplete()
            }
          }}
        />
      ) : null}
      {using_contents_url?.source === 'internal' ? (
        <iframe
          key={content.id}
          src={docUrl()}
          style={{
            border: 'none',
            height: '100vh',
            width: '100%',
            backgroundColor: '#ddd',
          }}
          onLoad={() => {
            if (isPreview === false) {
              setComplete()
            }
          }}
        />
      ) : null}
    </>
  )
}

export default Document

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import AssignmentAudio from 'component/content/assignment-type/audio'
import AssignmentVideo from 'component/content/assignment-type/video'
import AssignmentDocument from 'component/content/assignment-type/document'

export default function UploadAssigment() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, putRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [listAssignment, setListAssignment] = useState([])
  const [loading, setLoading]               = useState(false)

  /* ========================================= Functions ======================================== */
  const getAssignment = async () => {
    setLoading(true)
    let res = await getRequest('check-assignment-null')
    if (res) {
      setLoading(false)
      setListAssignment(res.data)
      if (res.data.length == 0) {
        history.push('/dashboard')
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getAssignment()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Upload Ulang Assignment"
          linkName="upload-assignment"
        />

        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header row align-items-center">
                <div className="col-sm-8">
                  <h5>Daftar Assigment </h5>
                </div>
              </div>
              <div className="card-body">
                <div
                  className="accordion"
                  id="accordionExample"
                >
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                      />
                    </div>
                  ) : listAssignment.length > 0 ? (
                    listAssignment.map((i, idx) => (
                      <ListAssignment
                        item={i}
                        key={idx}
                      />
                    ))
                  ) : (
                    <div className="d-flex flex-column align-items-center p-3">
                      <img
                        src="/image/assignment.svg"
                        style={{ width: 400 }}
                      />
                      <h5>Kamu tidak punya tugas untuk diupload</h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )

  function ListAssignment({ item }) {
    /* ====================================== Local States ====================================== */
    const [currentFilename, setCurrentFilename]     = useState('')
    const [previewFile, setPreviewFile]             = useState('')
    const [isFileTypeInvalid, setIsFileTypeInvalid] = useState(false)
    const [file, setFile]                           = useState(null)
    const [isFileEmpty, setIsFileEmpty]             = useState(false)
    const [loadingSubmit, setLoadingSubmit]         = useState(false)

    /* ======================================== Functions ======================================= */
    function validateFileType(fileName, type) {
      const fileExtension = fileName.split('.').pop()
      if (type === 'audio') {
        const audio = 'mp3'
        if (fileExtension !== audio) {
          setIsFileTypeInvalid(true)
          return false
        } else {
          setIsFileTypeInvalid(false)
          return true
        }
      }
      if (type === 'video') {
        const video = 'mp4'
        if (fileExtension !== video) {
          setIsFileTypeInvalid(true)
          return false
        } else {
          setIsFileTypeInvalid(false)
          return true
        }
      }
      if (type === 'document') {
        const document = ['pdf', 'ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx']
        let isDocValid = false
        document.forEach((correctExt) => {
          if (fileExtension === correctExt) {
            isDocValid = true
          }
        })
        if (isDocValid) {
          setIsFileTypeInvalid(false)
          return true
        } else {
          setIsFileTypeInvalid(true)
          return false
        }
      }
    }

    async function handleFile(file, type) {
      if (`${typeof file}` === 'object') {
        const isTypeValid = validateFileType(file.name, type)
        if (isTypeValid) {
          const URL = window.URL || window.webkitURL
          const src = URL.createObjectURL(file)
          setPreviewFile(src)
          setCurrentFilename(file.name)
          setFile(file)
          setIsFileEmpty(false)
        } else {
          setCurrentFilename('')
          setPreviewFile('')
          setFile(null)
        }
      }
    }

    const handleSubmit = () => {
      setLoadingSubmit(true)
      if (!file) {
        setLoadingSubmit(false)
        setIsFileEmpty(true)
      } else {
        Swal.fire({
          title: 'Apa kamu yakin?',
          text: `Kamu akan mengirim tugas!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, kirim!',
          cancelButtonText: 'Periksa kembali',
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoadingSubmit(true)
            const { id } = JSON.parse(localStorage.getItem('user'))
            const url = { data: 'data', name: currentFilename }
            const nameSplit = url.name.split('.')
            const ext = nameSplit[nameSplit.length - 1]
            const profile_id = JSON.parse(localStorage.getItem('profile')).id

            let form = new FormData()
            form.append('path', `upload/${profile_id}/assignments`)
            form.append('temp_name', `file-temp-${id}-${Date.now()}.${ext}`)
            form.append('assignment_users_id', item.assignment_users_id)
            form.append('url', JSON.stringify(url))
            form.append('file_upload', file)
            form.append('assignment_reply_type', item.reply_type)

            let success = putRequest('re-upload-assignment', form)
            if (!success.error) {
              setLoadingSubmit(false)
              Swal.fire({
                icon: 'success',
                text: 'Tugas berhasil dikirim.',
              }).then(async () => {
                getAssignment()
              })
            } else {
              setLoadingSubmit(false)
              Swal.fire({
                icon: 'error',
                text: 'Gagal mengirim tugas, coba beberapa saat lagi',
              })
            }
          }
        })
      }
    }

    /* ========================================= Output ========================================= */
    return (
      <div className="card">
        <div
          className="card-header"
          id="headingOne"
        >
          <h2 className="mb-0">
            <button
              className="btn btn-link btn-block text-left"
              type="button"
            >
              {item.contents}
            </button>
          </h2>
        </div>

        <div
          id="collapseOne"
          className="collapse show"
        >
          <div className="card-body">
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Sub Mapel</label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  value={item.submapel}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Section</label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  value={item.sections}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Content</label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  value={item.contents}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Instruksi</label>
              <div className="col-sm-10">
                <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">
                Upload Assignment
              </label>
              <div className="col-sm-10">
                {item.reply_type === '2' && (
                  <AssignmentAudio
                    handleFile={handleFile}
                    currentFilename={currentFilename}
                    isFileTypeInvalid={isFileTypeInvalid}
                    isFileEmpty={isFileEmpty}
                    previewFile={previewFile}
                  />
                )}
                {item.reply_type === '3' && (
                  <AssignmentVideo
                    handleFile={handleFile}
                    currentFilename={currentFilename}
                    isFileTypeInvalid={isFileTypeInvalid}
                    isFileEmpty={isFileEmpty}
                    previewFile={previewFile}
                  />
                )}
                {item.reply_type === '4' && (
                  <AssignmentDocument
                    handleFile={handleFile}
                    currentFilename={currentFilename}
                    isFileTypeInvalid={isFileTypeInvalid}
                    isFileEmpty={isFileEmpty}
                    previewFile={previewFile}
                  />
                )}
              </div>
            </div>

            <div className="d-flex justify-content-end mt-2">
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleSubmit}
                disabled={loadingSubmit}
              >
                {loadingSubmit ? (
                  <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                  />
                ) : (
                  <i className="fas fa-location-arrow mr-2" />
                )}
                Kirim
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

import React, { useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import { baseStyle, acceptStyle, activeStyle, rejectStyle } from './dropzoneCss'
import { avatarDefaultUser } from 'util/constant'
import { host } from 'util/host'
import { getBase64 } from 'util/base64'

const avatarStyle = {
  width: '150px',
  height: '150px',
  objectFit: 'cover',
  borderRadius: '50%',
}

export default function ModalChangeAvatar({ defaultAvatar, id, profileData }) {
  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [avatar, setAvatar]       = useState(null)
  const [showImage, setShowImage] = useState(defaultAvatar)
  const [loading, setLoading]     = useState(false)
  const [loadingRemove, setLoadingRemove] = useState(false)

  /* ========================================= Functions ======================================== */
  async function changeAvatar() {
    setLoading(true)
    if (avatar) {
      var form = new FormData()
      form.append('id', id)
      form.append('avatar', JSON.stringify(avatar))

      let response = await postRequest('profile-update', form)

      setLoading(false)
      if (response) {
        document.getElementById('closeUpdateAvatar').click()
        Swal.fire({
          icon: 'success',
          text: 'Berhasil mengupload avatar, mohon login ulang untuk menerapkan perubahan menyeluruh!',
        }).then((result) => window.location.reload())
      }
    } else {
      setLoading(false)
      window.notification(
        'Mohon',
        'pilih file gambar untuk mengubah avatar',
        'warning',
      )
    }
  }

  async function removeAvatar() {
    Swal.fire({
      title: 'Apa kamu yakin?',
      text: `Kamu akan menghapus avatar!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingRemove(true)
        var form = new FormData()
        form.append('id', id)
        form.append('avatar', JSON.stringify(avatar))

        let response = await postRequest('profile-update', form)

        if (response) {
          setLoadingRemove(false)
          document.getElementById('closeUpdateAvatar').click()
          Swal.fire({
            icon: 'success',
            text: 'Berhasil menghapus avatar, mohon login ulang untuk menerapkan perubahan menyeluruh!',
          }).then((result) => window.location.reload())
        } else {
          setLoadingRemove(false)
        }
      }
    })
  }

  function Dropzone() {
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      accept: 'image/*',
      maxSize: 1000000,
      multiple: false,
      onDrop: (acceptedFiles, fileRejections) => {
        if (acceptedFiles.length > 0) {
          getBase64(acceptedFiles[0])
            .then((result) => {
              setShowImage(result)
              if (acceptedFiles?.[0]) {
                let avatarClass = result.split(',')
                setAvatar({ name: acceptedFiles[0].name, data: avatarClass[1] })
              } else {
                setAvatar(null)
              }
            })
            .catch((err) => {
              console.error(err)
            })
        }
        if (fileRejections.length > 0) {
          setAvatar(null)
          Swal.fire({
            icon: 'warning',
            text: 'Maksimum gambar 1 MB!',
          })
        }
      },
    })

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isDragActive, isDragReject, isDragAccept],
    )

    return (
      <section className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="border rounded alert alert-warning w-100"
              role="alert"
            >
              <p className="m-0">
                <i className="fas fa-info-circle mr-1" />
                Ukuran gambar maksimal 1MB
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p className="mt-5">Drag or click image here</p>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center pt-3 pt-lg-0">
            {avatar ? (
              <img
                style={avatarStyle}
                src={showImage}
                alt="Avatar"
              />
            ) : (
              <img
                style={avatarStyle}
                src={host + defaultAvatar}
                onError={(e) => (e.currentTarget.src = avatarDefaultUser)}
                alt="Avatar"
              />
            )}
          </div>
        </div>
      </section>
    )
  }

  /* ========================================== Output ========================================== */
  return (
    <div
      className="modal fade"
      id="staticChangeAvatar"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="text-right p-2">
            <button
              id="closeUpdateAvatar"
              onClick={() => {
                setAvatar(null)
              }}
              type="button"
              className="close mr-1"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                className="f-30"
                aria-hidden="true"
              >
                ×
              </span>
            </button>
          </div>
          <div className="modal-body py-0">
            <div className="row mb-4 mt-0">
              <Dropzone />
            </div>
          </div>
          {avatar ? (
            <button
              disabled={loading}
              onClick={() => {
                changeAvatar()
              }}
              className="btn btn-primary rounded-0"
            >
              {loading && (
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                />
              )}
              <span>Ubah Avatar</span>
            </button>
          ) : (
            <button
              disabled={true}
              className="btn btn-primary rounded-0"
            >
              <span>Ubah Avatar</span>
            </button>
          )}

          <button
            disabled={loadingRemove}
            onClick={() => {
              removeAvatar()
            }}
            className="btn btn-danger rounded-0"
          >
            {loadingRemove && (
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
              />
            )}
            <span>Hapus Avatar</span>
          </button>
        </div>
      </div>
    </div>
  )
}

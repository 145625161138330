import React, { useState, useEffect } from 'react';
import InvalidWarning from 'component/invalid/warning'

export default function FormPenilaianEdit({
  handleChangeScore, 
  handleChangeNote, 
  score, 
  note, 
  scoreRef,
  isScoreInvalid,
  handleFormValidation
}) {
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (isEdit) {
      const scoreInput = document.getElementById("score-input");
      scoreInput.focus();
    }
  }, [isEdit]);

  return (
    <form className="col-12 border bg-light rounded py-4 mb-4">
      {isEdit ?
        null
        :
        <button 
          type="button" 
          className="btn btn-icon btn-primary"
          title="Ubah Nilai"
          style={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            zIndex: "100"
          }}
          onClick={() => {
            setIsEdit(true);
          }}
        >
          <i className="fas fa-edit" />
        </button>
      }
      <div className="form-group mb-4">
        <h4 className="text-center">Form Nilai</h4>
      </div>
      <div className="form-group row mb-4">
        <label className="col-form-label col-4 offset-lg-2 col-lg-2">Catatan</label>
        <div className="col-8 col-lg-6">
          <textarea 
            disabled={!isEdit}
            value={note} 
            onChange={(event) => handleChangeNote(event.target.value)} 
            className={`${isEdit ? "text-dark" : "text-secondary"} form-control bg-light`} 
            placeholder="Catatan untuk siswa (opsional)" 
          />
        </div>
      </div>
      <div className="form-group row mb-5">
        <label className="col-form-label col-4 offset-lg-2 col-lg-2">Nilai</label>
        <div className="col-8 col-lg-3 mb-3">
          <input 
            id="score-input"
            disabled={!isEdit}
            ref={scoreRef} 
            value={score} 
            onChange={(event) => handleChangeScore(event.target.value)} 
            type="number" 
            min="0" 
            max="100" 
            className={`${isEdit ? "text-dark" : "text-secondary"} form-control bg-light`} 
            placeholder="0 - 100" 
          />
          {isScoreInvalid ?
            <InvalidWarning text="Nilai berisi angka 0 s.d. 100." />
            : null
          }
        </div>
      </div>
      {isEdit ?
        <div className="form-group d-flex justify-content-center">
          <button onClick={handleFormValidation} type="button" className="btn btn-sm btn-success">
            <span>Simpan Perubahan</span>
          </button>
          <button 
            onClick={() => setIsEdit(false)} 
            type="button" 
            className="btn btn-sm btn-danger ml-3"
          >
            <span>Batal</span>
          </button>
        </div>
        :
        null
      }
    </form>
  );
}
import React from "react";

export default function Modal({ 
  title = "Modal Title", 
  id, 
  size = "", // modal-sm, modal-lg, modal-xl
  onClose = function() {},
  children,
  showFooter = true,
  showCloseFooter = true,
  footer,
  containerStyle
}) {
  if (typeof id === "string" && id !== "") {
    return (
      <div
        className="modal fade"
        id={id}
        data-backdrop="static"
        data-keyboard="false"
        tabIndex={-1}
        role="dialog"
        aria-labelledby={id}
        aria-hidden="true"
        style={containerStyle}
      >
        <div className={`${size} modal-dialog modal-dialog-centered`}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {title}
              </h5>
              <button
                type="button"
                className="close text-danger"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <i className="feather icon-x" />
              </button>
            </div>
            <div className="modal-body">
              {children}
            </div>
            {showFooter ?
              <div className="modal-footer p-2">
                {showCloseFooter ?
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={onClose}
                  >
                    Tutup
                  </button>
                  :
                  null
                }
                {footer}
              </div>
              :
              null
            }
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

import React, { useState, useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { useBaseContext } from 'context/base'

function InputRaporAllType2({ userId, reportGroupId, response }) {
  /* ========================================== Helpers ========================================= */
  const params = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= States ========================================= */
  const [data, setData] = useState([])
  const [res, setRes] = useState(null)

  /* ======================================== Functions ======================================= */
  async function getValue() {
    let us = localStorage.getItem('user')
    let usj = JSON.parse(us)
    var rsp = await getRequest(
      `rapot-mapel-siswa/${userId}?rgroup=${reportGroupId}&semester_code=${params.semester}`,
    )
    if (rsp) {
      var ss = {}
      if (rsp.data) {
        setData(rsp.data)
        var scollect = []
        rsp.data.map((d1, i) => {
          if (d1.categories_types.length == 0) {
            d1.mata_pelajaran.map((m1, mi) => {
              ss = {
                user_id: userId,
                categories_id: m1.categories_id ? m1.categories_id : m1.id,
                categories_type_id: m1.categories_type_id
                  ? m1.categories_type_id
                  : d1.id,
                report_group_id: m1.report_group_id
                  ? m1.report_group_id
                  : reportGroupId,
                createdBy: m1.createdBy ? m1.createdBy : usj.id,
                updatedBy: usj.id,
                value: m1?.value
                  ? JSON.parse(m1.value)
                  : { nilai: 0, predikat: 'D', deskripsi: '' },
                ta_semester_code: params.semester,
                profile_id: usj.profile_id,
              }
              scollect.push(ss)
              response(ss)
            })
          } else {
            d1.categories_types.map((d2, iii) => {
              d2.mata_pelajaran.map((m2, mii) => {
                ss = {
                  user_id: userId,
                  categories_id: m2.categories_id ? m2.categories_id : m2.id,
                  categories_type_id: m2.categories_type_id
                    ? m2.categories_type_id
                    : d2.id,
                  report_group_id: m2.report_group_id
                    ? m2.report_group_id
                    : reportGroupId,
                  createdBy: m2.createdBy ? m2.createdBy : usj.id,
                  updatedBy: usj.id,
                  value: m2?.value
                    ? JSON.parse(m2.value)
                    : { nilai: 0, predikat: 'D', deskripsi: '' },
                  ta_semester_code: params.semester,
                  profile_id: usj.profile_id,
                }
                scollect.push(ss)
                response(ss)
              })
            })
          }
        })
        setRes(scollect)
      }
    }
  }

  function grade(nilai) {
    if (nilai >= 90 && nilai <= 100) {
      return 'A'
    } else if (nilai >= 80 && nilai <= 89) {
      return 'B'
    } else if (nilai >= 70 && nilai <= 79) {
      return 'C'
    } else if (nilai < 70) {
      return 'D'
    } else {
      return 'E'
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getValue()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>No</th>
            <th>Mata Pelajaran</th>
            <th>Status</th>
            <th>Nilai</th>
            <th>Deskripsi</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => {
            return (
              <Fragment key={item?.id + '_fragment_render_mapel_' + i}>
                <tr>
                  <td colSpan={5}>
                    <h5>{item.type}</h5>
                  </td>
                </tr>
                <RenderMapel item={item} />
              </Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )

  function RenderMapel({ item }) {
    if (typeof item.mata_pelajaran != 'undefined') {
      return item.mata_pelajaran.map((mapel, ii) => {
        return (
          <DetailMapel
            key={`detail-mapel-${mapel?.id}-index${ii}`}
            mapel={mapel}
            ii={ii}
          />
        )
      })
    } else {
      return item.categories_types.map((list, iii) => {
        if (
          list.mata_pelajaran.length > 0 &&
          list.mata_pelajaran != undefined
        ) {
          return (
            <>
              <tr key={`${list?.id}-${iii}-render-mapel`}>
                <td
                  colSpan={5}
                  style={{ paddingLeft: 50 }}
                >
                  <h5>{list.type}</h5>
                </td>
              </tr>
              <RenderMapel item={list} />
            </>
          )
        } else {
          return null
        }
      })
    }
  }

  function DetailMapel({ mapel, ii }) {
    function Status() {
      var status = ''
      var existStatus = mapel.approval_status
      if (existStatus == null) {
        status = 'Input nilai belum dilakukan'
      } else if (existStatus == 'draft' || existStatus == 'rejected') {
        status = 'Dalam proses penginputan nilai'
      } else if (existStatus == 'submission') {
        status = 'Menunggu persetujuan Kepala Sekolah'
      } else if (existStatus == 'approved') {
        status = 'Nilai telah disetujui'
      }
      return status
    }

    return (
      <tr>
        <td>{++ii}</td>
        <td>{mapel.title}</td>
        <td>
          <Status />
        </td>
        <td>
          <input
            disabled={true}
            type="number"
            className="form-control"
            defaultValue={mapel?.value ? JSON.parse(mapel.value).nilai : 0}
            placeholder={'Nilai ' + mapel.title}
          />
        </td>
        <td>
          <textarea
            disabled={true}
            className={'form-control'}
            defaultValue={mapel?.value ? JSON.parse(mapel.value).deskripsi : null}
            rows={4}
            placeholder={'Deskripsi '}
          ></textarea>
        </td>
      </tr>
    )
  }
}

export default React.memo(
  InputRaporAllType2,
  (oldProps, newProps) =>
    oldProps.userId === newProps.userId &&
    oldProps.reportGroupId === newProps.reportGroupId,
)

import React from 'react'

export default function TableSurvey({
  table,
  answers,
  generatedKey,
  handleChangeKolomBaris,
  readOnly = false,
}) {
  
  if (readOnly) {
    return (
      <div className="dt-responsive table-responsive">
        <table className={'table'}>
          <thead>
            <tr>
              <th></th>
              {table.kolom.map((val, i) => {
                return (
                  <th
                    key={generatedKey + 'kolomhead' + i}
                    style={{ textAlign: 'center' }}
                  >
                    {val.text}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {table.baris.map((val, i) => {
              return (
                <tr key={generatedKey + 'baris' + i}>
                  <th>{val.text}</th>
                  {table.kolom.map((v, ind) => {
                    var checked = false
                    if (v.id === val.survey_temp.survey_answer_id) {
                      checked = true
                    }

                    return (
                      <td
                        id={generatedKey + 'kolombody' + val.id + ind}
                        key={generatedKey + 'kolombody' + ind}
                        style={{
                          textAlign: 'center',
                          backgroundColor:
                            v.id === val.survey_temp.survey_answer_id
                              ? 'lightyellow'
                              : 'transparent',
                          position: 'relative',
                        }}
                      >
                        <div className="form-check">
                          <input
                            id={generatedKey + 'kolombodyradio' + val.id + ind}
                            key={generatedKey + 'kolombodyradio' + val.id + ind}
                            className="form-check-input"
                            type="radio"
                            name={val.id}
                            defaultChecked={checked}
                            value={v.text}
                            disabled
                          />
                          <label
                            className="form-check-label"
                            style={{
                              position: 'absolute',
                              top: '0',
                              right: '0',
                              left: '0',
                              bottom: '0',
                            }}
                            htmlFor={
                              generatedKey + 'kolombodyradio' + val.id + ind
                            }
                          />
                        </div>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  } else {
    return (
      <div className="dt-responsive table-responsive">
        <table className={'table'}>
          <thead>
            <tr>
              <th></th>
              {table.kolom.map((val, i) => {
                return (
                  <th
                    key={generatedKey + 'kolomhead' + i}
                    style={{ textAlign: 'center' }}
                  >
                    {val.text}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {table.baris.map((val, i) => {
              return (
                <tr key={generatedKey + 'baris' + i}>
                  <th>{val.text}</th>
                  {table.kolom.map((v, ind) => {
                    var index = answers.findIndex(
                      (vv) => vv.survey_quest_id == val.id,
                    )
                    var a = answers.filter((vv) => vv.survey_quest_id == val.id)
                    var checked = false
                    if (a.length > 0 && v.id == a[0].survey_answer_id) {
                      checked = true
                    }
                    return (
                      <td
                        id={generatedKey + 'kolombody' + val.id + ind}
                        key={generatedKey + 'kolombody' + ind}
                        style={{
                          textAlign: 'center',
                          backgroundColor: 'transparent',
                          position: 'relative',
                        }}
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            id={generatedKey + 'kolombodyradio' + val.id + ind}
                            key={generatedKey + 'kolombodyradio' + val.id + ind}
                            type="radio"
                            onChange={() => {
                              handleChangeKolomBaris(index, v.id, val.id)
                            }}
                            name={val.id}
                            checked={checked}
                            value={v.text}
                            onMouseEnter={(e) =>
                              (e.currentTarget.style.cursor = 'pointer')
                            }
                          />
                          <label
                            className="form-check-label"
                            style={{
                              position: 'absolute',
                              top: '0',
                              right: '0',
                              left: '0',
                              bottom: '0',
                              cursor: 'pointer',
                            }}
                            htmlFor={
                              generatedKey + 'kolombodyradio' + val.id + ind
                            }
                            onMouseOver={() => {
                              document.getElementById(
                                generatedKey + 'kolombody' + val.id + ind,
                              ).style.backgroundColor = 'lightyellow'
                            }}
                            onMouseLeave={() => {
                              document.getElementById(
                                generatedKey + 'kolombody' + val.id + ind,
                              ).style.backgroundColor = 'transparent'
                            }}
                          />
                        </div>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

import React from 'react'

export default function InfoAudio() {
  return (
    <div
      className="mt-2 border rounded alert alert-warning w-100"
      role="alert"
    >
      <h6>
        <i className="fas fa-info-circle mr-1" />
        Cara convert file audio menjadi .mp3
      </h6>
      <ol className="mb-0 pl-4">
        <li>
          Masuk ke halaman{' '}
          <a
            href="https://convertio.co/id/audio-converter/"
            target="__blank"
          >
            Convert Audio
          </a>
          .
        </li>
        <li>
          Setelah masuk ke laman web klik <strong>Pilih File </strong>lalu
          upload.
        </li>
        <li>Pada kolom untuk, pilih menu audio dan pilih format .mp3</li>
        <li>
          Pilih <strong>Konversi </strong>. Jika sudah selesai,{' '}
          <strong>Download </strong> file.
        </li>
      </ol>
    </div>
  )
}

// This version is adjust width td

import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useBaseContext } from 'context/base'
import './modal-reject.css'

export default function ModalRejectItem({
  item,
  number,
  listRejects,
  setListRejects,
  semester_id,
}) {
  /* ========================================== Helpers ========================================= */
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const group_id = query.get('group_id')

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [loadingScoreTemp, setLoadingScoreTemp] = useState('idle')  // idle / start / finish
  const [name, setName]                         = useState('')
  const [score, setScore]                       = useState('')
  const [predicate, setPredicate]               = useState('')
  const [description, setDescription]           = useState('')
  const [notes, setNotes]                       = useState('')

  /* ========================================= Functions ======================================== */
  function resetStates() {
    setScore('')
    setPredicate('')
    setDescription('')
    setNotes('')
  }

  async function getScoreTemp(user_id, category_id, reportGroupId) {
    const response = await getRequest(
      `report-temp-by-id-group?id=${reportGroupId}&user_id=${user_id}&category_id=${category_id}&ta_semester_code=${semester_id}&group_id=${group_id}`,
    )
    return response ? response.data : null
  }

  async function initScoreData(item) {
    setLoadingScoreTemp('start')
    const data = await getScoreTemp(
      item.user_id,
      item.category_id,
      item.report_group_id,
    )
    if (typeof data === 'object' && data !== null) {
      const value = JSON.parse(data.value)
      setScore(value.nilai)
      setPredicate(value.predikat)
      setDescription(value.deskripsi)
      setName(data.user_name)
    } else {
      resetStates()
    }
    setLoadingScoreTemp('finish')
  }

  function updateListRejects(notes, userId, currentRejects = []) {
    const newRejects = currentRejects.map((current) => {
      return current.user_id === userId
        ? {
            report_group_id: current.report_group_id,
            user_id: current.user_id,
            ta_semester_code: current.ta_semester_code,
            category_id: current.category_id,
            approval_notes: notes,
          }
        : current
    })
    setListRejects(newRejects)
  }

  function handleChangeNotes(event, userId, currentRejects = []) {
    setNotes(event.target.value)
    updateListRejects(event.target.value, userId, currentRejects)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    initScoreData(item)
  }, [item])

  /* ========================================== Output ========================================== */
  return (
    <>
      {loadingScoreTemp === 'idle' && (
        <tr>
          <td colSpan={6}></td>
        </tr>
      )}
      {loadingScoreTemp === 'start' && (
        <tr>
          <td colSpan={5}>
            <div className="d-flex justify-content-center align-items-center">
              <span
                className="spinner-border"
                role="status"
                style={{
                  height: '22px',
                  width: '22px',
                }}
              />
            </div>
          </td>
          <td>
            <textarea
              className="form-control"
              placeholder="Catatan"
              disabled={true}
            />
          </td>
        </tr>
      )}
      {loadingScoreTemp === 'finish' && (
        <tr>
          <td style={{ width: 50 }}>{number}</td>
          <td style={{ width: 150 }}>{name}</td>
          <td style={{ width: 100 }}>{score}</td>
          <td style={{ width: 50 }}>{predicate}</td>
          <td style={{ whiteSpace: 'unset', width: 450 }}>{description}</td>
          <td style={{ width: 350 }}>
            <textarea
              className="form-control"
              placeholder="Catatan"
              value={notes}
              onChange={(event) =>
                handleChangeNotes(event, item.user_id, listRejects)
              }
            />
          </td>
        </tr>
      )}
    </>
  )
}

import React from 'react'
import BreadCrumb from 'component/breadcrumb'
import useContentPlayerContext from 'context/content-player'

export default function ContentPlayerBreadcumb() {
  const { kelas } = useContentPlayerContext()

  const links = [
    { url: '/katalog-kelas', name: 'Katalog Mata Pelajaran' },
    { url: `/katalog-kelas/${kelas.id}`, name: 'Overview' },
  ]

  return (
    <BreadCrumb
      pageName="Materi"
      links={links}
    />
  )
}

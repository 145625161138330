import React from 'react'
import { useParams } from 'react-router-dom'
import BadgesCollections from 'component/badges-collections'

export default function AktifitasSiswaProgressBadge() {
    const param = useParams()

    return (
        <BadgesCollections apiUrl={`badge-siswa?id=${param.siswaId}`} />
    )
}
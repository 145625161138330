import React from 'react'

export default function Logo() {
  return (
    <a href="/">
      <img
        src="/logo_sma_current_v3_white_scaled.webp"
        alt="Pintar Edu"
        style={{
          width: 500,
          height: 'auto',
          alignSelf: 'center',
          marginBottom: '-110px',
        }}
        className="img-fluid"
      />
    </a>
  )
}

import React from 'react'
import { avatarDefaultUser } from 'util/constant';
import BaseReactBootstrapModal from './BaseReactBootstrapModal';
import BaseButton from 'component/button/BaseButton';
import { textElipsis } from 'util/string';
import DataTableWithFilter from 'component/data-table/indexWithFilter';
import { useBaseModalSelectedStudentHook } from './hooks/useBaseModalSelectedStudentHook';
import { Modal } from 'react-bootstrap';

/**
 * @typedef {Object} ListSelectedStudentsProps
 * @property {Array<Student>} selectedStudents
 * @property {(student: Student) => void} onRemoveStudent
 * @property {() => void} onClearAllStudentHandler
 * @property {() => void} onRollbackStudentHandler
 */

/**
 * Base Modal Selected Student Item Props
 * 
 * @typedef {Object} BaseModalSelectedStudentItemProps
 * @property {number} index
 * @property {Student} item
 * @property {boolean} isAdded
 * @property {() => Array<Student>} onAddStudent 
 * @property {() => Array<Student>} onRemoveStudent 
 */

/**
 * MODAL SELECTED STUDENT
 * 
 * @param {{
 *  show: boolean
 *  originalSelectedStudents: Array<Student>
 *  onCloseHandler: () => void
 *  isWithCloseButton?: boolean
 *  isWithXButton?: boolean
 *  isWithHeader?: boolean
 *  isWithFooter?: boolean
 *  isWithCheckSavedOrNot?: boolean
 *  contentId?: string
 *  subMapelId: string
 *  title?: String
 *  selectedStudents: Array<Student>
 *  dataTableProps: import('./hooks/useBaseModalSelectedStudentHook').DataTableProps
 *  listSelectedStudentsProps?: ListSelectedStudentsProps
 *  onAddOrRemoveStudent: (item: Student | Array<Student>) => void
 *  onSaveHandler: (onSuccessHandler: () => void) => void
 * }} props
 */
const BaseModalSelectedStudent = ({ 
    dataTableProps = {}, 
    selectedStudents, 
    show = false, 
    originalSelectedStudents = 0,
    onCloseHandler = () => {}, 
    isWithCloseButton = false, 
    isWithXButton = true, 
    isWithHeader = false,
    isWithFooter = true,
    isWithCheckSavedOrNot = false,
    contentId = '',
    subMapelId = '',
    title,
    listSelectedStudentsProps = {
      listStudentsActions: ['clear-all']
    },
    onAddOrRemoveStudent,
    onSaveHandler,
  }) => {
    const { data, set, handler } = useBaseModalSelectedStudentHook({ 
      selectedStudents, 
      dataTableProps, 
      subMapelId, 
      onAddOrRemoveStudent, 
      contentId, 
      onCloseHandler,
      isWithCheckSavedOrNot,
      originalSelectedStudents,
      onSaveHandler,
    })

    return (
      <BaseReactBootstrapModal 
        size="lg" show={show} 
        handleClose={handler.onCloseHandler} 
        isWithCloseButton={isWithCloseButton} 
        isWithXButton={isWithXButton} 
        isWithHeader={isWithHeader}
        isWithFooter={false}
        centered
      >
        {title
        ?
          <h4 className='text-center mb-4'>{title}</h4>
        :null}
        <div
          className="hd-body pr-2"
          style={{
            maxHeight: '50rem',
            overflowY: 'auto',
          }}
        >
          <BaseListSelectedStudentsHeader
            onClearAllStudentHandler={handler.onClearAllStudentHandler} 
            onRollbackStudentHandler={handler.onRollbackStudentHandler}
            {...listSelectedStudentsProps}
            selectedStudents={selectedStudents} 
          />
          {Array.isArray(selectedStudents) && !!selectedStudents.length
          ?
          <>
            <ListSelectedStudents 
              onRemoveStudent={handler.onRemoveStudentHandler} 
              selectedStudents={selectedStudents} 
            />
            <hr />
          </>
          :null}

          <DataTableWithFilter
            show={data.limits}
            column={data.column}
            totalRows={data.totalDataStudentsOfClass}
            onChangeSearch={(val) => set.setSearch(val)}
            onChangeLimit={(val) => set.setLimit(val)}
            onChangePage={(val) => set.setOffset(val)}
            className={'table table-striped table-bordered nowrap'}
            data={data.usingData}
            notFoundMessage={!data.selectedClass?.value ? 'Silahkan pilih kelas terlebih dahulu' : 'Tidak ada data yang ditemukan'}
            {...dataTableProps}
            renderItem={(val, index) => (
              <BaseModalSelectedStudentItem
                item={val}
                isAdded={handler.checkIsAddedHandler(val)}
                key={`selected-student-item-index${index}`}
                onAddStudent={() => handler.onAddStudentHandler(val)}
                onRemoveStudent={() => handler.onRemoveStudentHandler(val)}
              />
            )}
            filter1={{
              options: data.optionsClass,
              placeholder: 'Pilih kelas...',
              onChange: (choice) => {
                set.setSelectedClass(choice)
                if (!choice) {
                  set.setListStudentSpesificContent({
                    data: [],
                    totalData: 0,
                  })
                }
              },
              isClearable: true,
            }}
          />
        </div>

        {isWithFooter ?
        <Modal.Footer>
          <div className="d-flex align-items-center" style={{ gap: 5 }}>
            {data.totalStudentRemoved > 0
            ?
              <span>{data.totalStudentRemoved} Siswa akan dihapus dari spesifik konten</span>
            :null}
            <button className="btn btn-success" onClick={handler.onSaveHandler}>Simpan</button>
          </div>
        </Modal.Footer>
        : null}
      </BaseReactBootstrapModal>
    );
}

export default BaseModalSelectedStudent


/**
 * List Selected Students Header
 * 
 * @param {{
 *  selectedStudents: Array<Student>
 *  onRollbackStudentHandler: () => void
 *  onClearAllStudentHandler: () => void
 *  listStudentsActions: Array<'rollback' | 'clear-all'>
 * }} props
*/
export const BaseListSelectedStudentsHeader = ({ selectedStudents, listStudentsActions = ['clear-all'], onRollbackStudentHandler, onClearAllStudentHandler }) => {
  return (
    <div className='d-flex align-items-center mb-2' style={{ columnGap: 5 }}>
      <h5>Daftar Siswa Yang Dipilih: ({selectedStudents?.length})</h5>
    {listStudentsActions.map((action, index) => (
      <div key={`list-students-action-index${index}`}>
        {action === 'rollback'
        ?
          <BaseButton 
            props={{ className: 'btn-sm' }}
            onClick={onRollbackStudentHandler}>
              <i  title="Kembalikan semula" className='feather icon-corner-up-left'></i>
          </BaseButton>
        : null}

        {action === 'clear-all' && !!selectedStudents?.length
        ?
          <BaseButton 
            props={{ className: 'btn-sm' }}
            variant='danger'
            onClick={onClearAllStudentHandler}>
              <i  title="Hapus Semua Siswa" className='feather icon-trash-2'></i>
          </BaseButton>
        : null}
      </div>
    ))}
    </div>
  )
}

/**
 * Base Modal Selected Student Item
 * 
 * @param {BaseModalSelectedStudentItemProps} props
*/
export const BaseModalSelectedStudentItem = ({ item, isAdded, onAddStudent, onRemoveStudent }) => {
  return (
    <tr>
      <td>{item.nim}</td>
      <td>
        <div className="d-inline-block align-middle">
          <img
            src={`${item?.linkAvatar}`}
            alt={item.name}
            className="img-radius align-top m-r-15"
            style={{ width: '40px', height: '40px' }}
            onError={(event) => {
              event.currentTarget.src = avatarDefaultUser
            }}
          />
          <div className="d-inline-block">
            <h6 className="m-b-0 mt-2">{item.name}</h6>
          </div>
        </div>
      </td>
      <td>
        {isAdded
        ?
          <BaseButton 
            props={{
              className: 'btn-sm btn-danger'
            }}
            onClick={onRemoveStudent}
          >
            <i className="fas fa-minus" />
          </BaseButton>
        :
          <BaseButton 
            props={{
              className: 'btn-sm btn-success'
            }}
            onClick={onAddStudent}
          >
            <i className="fas fa-plus" />
          </BaseButton>
        }
      </td>
    </tr>
  )
}


/**
 * LIST SELECTED STUDENTS PROPS
 * 
 * @param {ListSelectedStudentsProps} props 
 */
export const ListSelectedStudents = ({ selectedStudents, onRemoveStudent }) => (
  <div>
    <div
    className='row ml-0 justify-content-center overflow-auto'
    style={{ maxHeight: 250 }}
    >
      {selectedStudents.map((selectedStudent, index) => (
      <div 
        key={`selected-student-${selectedStudent.id}-index${index}`}
        className="col-sm-3 mb-2 d-flex p-2 bg-success rounded align-items-center mx-2"
      >
        <div>
          <img
            src={selectedStudent?.linkAvatar || avatarDefaultUser}
            alt={selectedStudent?.name}
            className="img-radius align-top m-r-15"
            style={{ width: '40px', height: '40px' }}
            onError={(event) => {
              event.currentTarget.src = avatarDefaultUser
            }}
          />
        </div>
        <div className='d-flex align-items-center w-100'>
          <div>
            <h6 title={selectedStudent.name} className='text-truncate mx-0 mt-0 mb-1'>
              {textElipsis(selectedStudent.name, 12)}
            </h6>
            <p className='m-0 p-0'>{selectedStudent.nim}</p>
          </div>
          <div className='d-flex justify-content-end w-100'>
            <div>
              <BaseButton 
                props={{
                  className: 'btn-sm btn-danger'
                }}
                onClick={() => onRemoveStudent(selectedStudent)}
              >
                <i className="fas fa-minus" />
              </BaseButton>
            </div>
            </div>
        </div>
      </div>
      ))}
    </div>
  </div>
)
import React from 'react'

export default function ContentNotesTabs() {
  return (
    <ul
      className="nav nav-tabs mb-3"
      id="content-notes-tabs"
      role="tablist"
    >
      <li className="nav-item">
        <a
          className="nav-link text-uppercase has-ripple active"
          id="kta-tab"
          data-toggle="tab"
          href="#kta"
          role="tab"
          aria-controls="tab"
          aria-selected="true"
        >
          Key Take Away
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link text-uppercase has-ripple"
          id="notes-tab"
          data-toggle="tab"
          href="#notes"
          role="tab"
          aria-controls="notes"
          aria-selected="false"
        >
          Personal Notes
        </a>
      </li>
    </ul>
  )
}

import React from 'react'
import DataTable from 'component/data-table'
import RenderItem from './render-item'

const ListViewKelas = ({ listKelas, kelasLength }) => (
  <div className="col dt-responsive table-responsive">
    <DataTable
      className={'table table-striped table-bordered nowrap'}
      data={listKelas}
      totalRows={kelasLength}
      show={[10, 20, 30, 40, 50]}
      column={[
        'Sub Mata Pelajaran',
        'Mata Pelajaran',
        'Status',
        'Progress',
        'Actions',
      ]}
      renderItem={(item) => (
        <RenderItem
          item={item}
          key={item.id}
        />
      )}
    />
  </div>
)

export default ListViewKelas

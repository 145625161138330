import React from 'react'

export default function FormPenilaianDisabled() {
  return (
    <form className="col-12 border bg-light rounded py-4 mb-4">
      <div className="form-group mb-4">
        <h4 className="text-center">Form Nilai</h4>
      </div>
      <div className="form-group row mb-4">
        <label className="col-form-label col-4 offset-lg-2 col-lg-2">
          Catatan
        </label>
        <div className="col-8 col-lg-6">
          <textarea
            disabled
            className="form-control bg-light btn-forbidden"
            placeholder="Catatan untuk siswa (opsional)"
          />
        </div>
      </div>
      <div className="form-group row mb-5">
        <label className="col-form-label col-4 offset-lg-2 col-lg-2">
          Nilai
        </label>
        <div className="col-8 col-lg-3">
          <input
            disabled
            type="number"
            min="0"
            max="100"
            className="form-control bg-light btn-forbidden"
            placeholder="0 - 100"
          />
        </div>
      </div>
      <div className="form-group d-flex justify-content-center">
        <button
          type="button"
          className="btn btn-forbidden btn-sm btn-success"
          disabled
        >
          <span>Terima Tugas</span>
        </button>
      </div>
    </form>
  )
}

import React, { useState } from 'react'

export default function DraftInfo({ onClick }) {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <small
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`text-underline ${isHovered ? 'text-danger' : 'text-muted'}`}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      {' '}
      {isHovered ? '(Remove Draft)' : '(Local Draft)'}{' '}
    </small>
  )
}

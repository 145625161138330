import React from "react";

import BaseSelect from "component/select/BaseSelect";
import { Col, Row } from "react-bootstrap";

export default function FilterHeader({ headerHook, modalAddHook }) {
  const access = ['administrator']

  return (
    <Row>
      {Array.isArray(headerHook?.data?.optionsAcademicYear) && headerHook?.data?.optionsAcademicYear.length ?
        <Col sm={12} lg={3} className="mb-sm-4 mb-lg-1 mb-md-2">
          <label htmlFor="select-class">Pilih Tahun Ajaran</label>
          <BaseSelect
            id="select-class"
            customRef={headerHook?.customRef?.selectedAcademicYearRef}
            access={access}
            options={headerHook?.data?.optionsAcademicYear}
            placeholder={'Pilih Tahun Ajaran'}
            onChange={(choice) => {
              modalAddHook?.customSetState?.setIsCreatedNewSubjectGroup(false)
              modalAddHook?.customDispatch?.reactSelectDispatch({ type: 'academic-year', value: choice })

              if (!modalAddHook?.data?.isCreatedNewSubjectGroup) {
                headerHook?.customDispatch?.reactSelectDispatch({ type: 'academic-year', value: choice })
              }
            }}
            defaultValue={headerHook?.customState?.reactSelectState?.selectedAcademicYear}
            isDisabled={!headerHook?.data?.optionsAcademicYear?.length}
          />
        </Col>
      : null }

      {headerHook?.customState?.reactSelectState?.selectedAcademicYear && headerHook?.data?.optionsSemester?.length ?
        <Col sm={12} lg={3} className="mb-sm-4 mb-lg-1 mb-md-2">
          <label htmlFor="select-semester">Pilih Semester</label>
          <BaseSelect
            id="select-semester"
            customRef={headerHook?.customRef?.selectedSemesterRef}
            access={access}
            options={headerHook?.data?.optionsSemester}
            placeholder={'Pilih Semester'}
            defaultValue={headerHook?.customState?.reactSelectState?.selectedSemester}
            isDisabled={!headerHook?.data?.optionsAcademicYear?.length}
            onChange={(choice) => {
              modalAddHook?.customSetState?.setIsCreatedNewSubjectGroup(false)
              modalAddHook?.customDispatch?.reactSelectDispatch({ type: 'semester', value: choice })

              if (!modalAddHook?.data?.isCreatedNewSubjectGroup) {
                headerHook?.customDispatch?.reactSelectDispatch({ type: 'semester', value: choice })
              }
            }}
          />
        </Col>
      : null}

      {(headerHook?.customState?.reactSelectState?.selectedAcademicYear && headerHook?.data?.optionsClass?.length) || modalAddHook?.data?.isCreatedNewSubjectGroup ?
        <Col sm={12} lg={3} className="mb-sm-4 mb-lg-1 mb-md-2">
          <label htmlFor="select-class">Pilih Kelas</label>
          <BaseSelect
            id="select-class"
            customRef={headerHook?.customRef?.selectedClassRef}
            access={access}
            options={headerHook?.data?.optionsClass}
            placeholder={'Pilih Kelas'}
            defaultValue={headerHook?.customState?.reactSelectState?.selectedClass}
            onChange={(choice) => {
              modalAddHook?.customSetState?.setIsCreatedNewSubjectGroup(false)
              modalAddHook?.customDispatch?.reactSelectDispatch({ type: 'class', value: choice })

              if (!modalAddHook?.data?.isCreatedNewSubjectGroup) {
                headerHook?.customDispatch?.reactSelectDispatch({ type: 'class', value: choice })
              }
            }}
          />
        </Col>
      : null}
    </Row>
  )
}
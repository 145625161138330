import React from 'react'
import InvalidText from 'component/invalid/text'

const FormInputName = ({
  namaKonten,
  isNamaInvalid,
  handleChangeName,
  refInputName,
  label,
  placeholder,
}) => (
  <div className="form-group mb-5 row">
    <label
      className="col-sm-3 col-form-label"
      htmlFor="namaKonten"
    >
      <span className="mr-1">{label ? label : 'Nama Materi'}</span>
    </label>
    <div className="col-sm-9">
      <input
        value={namaKonten}
        onChange={(event) => handleChangeName(event)}
        ref={refInputName}
        type="text"
        className={`${isNamaInvalid ? 'is-invalid' : ''} form-control`}
        id="namaKonten"
        placeholder={placeholder ? placeholder : 'Nama Materi'}
      />
      {isNamaInvalid ? <InvalidText name={'Nama'} /> : null}
    </div>
  </div>
)

export default FormInputName

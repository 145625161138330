import useGetRoleActive from 'hooks/useGetRoleActive';
import React from 'react'

/**
 * Base Input
 * 
 * @param {{
 *  id: string
 *  placeholder: string
 *  type: string
 *  label: string
 *  className: string
 *  access: Array
 *  isWithLabel: boolean
 *  isDisabled: boolean
 *  isLoading: boolean
 *  props: import('react').InputHTMLAttributes
 * }} props
 */
const BaseInputWithoutGroup = ({ id = 'exampleInputEmail1', placeholder = 'Placeholder', type = 'text', label = 'Label', access = [], isDisabled = false, isLoading = false, isWithLabel = true, className = 'form-control', props }) => {
  const roleActive = useGetRoleActive();

  const isNotAccess = access.length ? !access.includes(roleActive.type) : false

  return (
    <>
      {isWithLabel ?
        <label htmlFor={id}>{label}</label>
        : null}
      <input {...props} type={type} className={className} disabled={isNotAccess || isDisabled || isLoading} id={id} aria-describedby={`${placeholder}Help`} placeholder={placeholder} />
    </>
  )
}

export default BaseInputWithoutGroup
import { Col, Row } from "react-bootstrap";

import BaseDND from "component/dnd/BaseDND";

export default function DNDList({ DNDListHook, headerHook }) {

  return (
    <Row className="mt-3">
      <Col className="rounded-top mx-3" style={{ backgroundColor: '#eeee'}}>
        <div className="d-flex justify-content-center">
          <div style={{ flex: 1 }}>
            <p className="text-center m-0 p-0 py-2" style={{ fontWeight: 800, fontSize: '.9rem' }}>
              Mata Pelajaran
            </p>
          </div>
          <div style={{ flex: 1 }}>
            <p className="text-center m-0 p-0 py-2" style={{ fontWeight: 800, fontSize: '.9rem' }}>
              Aksi
            </p>
          </div>
        </div>
      </Col>
      <Col sm={12} className="mt-3">
        {!headerHook?.customState?.reactSelectState?.selectedClass
        ?
        <p className="text-center">Silahkan Pilih Kelas Terlebih Dahulu.</p>
        : DNDListHook?.data?.items && Array.isArray(DNDListHook?.data?.items) && DNDListHook?.data?.items.length
        ?
        <BaseDND
          items={DNDListHook?.data?.items}
          renderItem={DNDListHook?.render?.item}
          maxDepth={2}
          confirmChange={DNDListHook?.handler?.onConfirmChangeHandler}
          onChange={DNDListHook?.handler?.onChangeHandler}
          idProp="idIndex"
        />
        :
        <p className="text-center">Data Tidak Ditemukan.</p>
        }
      </Col>
    </Row>
  )
}
import React, { useEffect, useState, useRef } from 'react'
import Select from 'react-select'
import { useHistory } from 'react-router-dom'
import { useBaseContext } from 'context/base'
import NotFound from 'component/not-found'
import LoadingSpinner from 'component/loading'
import SelectSemester from 'component/semester/select-semester'
import Swal from 'sweetalert2'
import { listKeterangan } from 'data/rekapNilai'
import BaseButtonInformationModal from 'component/button/BaseButtonInformationModal'
import BaseButton from 'component/button/BaseButton'
import useFile from 'hooks/useFile'

export default function ScoresRecap() {  
  /* ====================================== Consume Context ===================================== */
  const history = useHistory()
  const { getRequest } = useBaseContext()
  const { downloadFile } = useFile();

  /* ======================================= Local States ======================================= */
  const [selectSemester, setSelectSemester] = useState(null)
  const [classes, setClasses]               = useState([])
  const [selectClass, setSelectClass]       = useState(null)
  const [groups, setGroups]                 = useState([])
  const [selectGroup, setSelectGroup]       = useState(null)
  const [refresh, setRefresh]               = useState(true)
  const [isLoadingDownloadExcel, setIsLoadingDownloadExcel] = useState(false)
  
  const [loadingScores, setLoadingScores] = useState(false)
  const [scores, setScores]               = useState([])
  const [headers, setHeaders]             = useState([])

  const [typeScores, setTypeScores] = useState([])

  /* =========================================== Refs =========================================== */
  const isMounted = useRef(true)

  /* ========================================= Functions ======================================== */
  async function handleRedirectContent(nilai) {
    const response = await getRequest(`content-detail?content_id=${nilai.content_id}`)
    const swalLoadingGetContent = Swal.fire({
      title: 'Harap tunggu sebentar...',
      html: `<div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>`,
      allowOutsideClick: false,
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
    })
    if (response) {
      if (response.data.length !== 0) {
        const content = response.data;
        Swal.close(swalLoadingGetContent)

        let routing = {
          url: '',
          data: { existingData: nilai, content }
        }
        switch (nilai.content_type) {
          case 'quiz':
            routing.url = `/kelola-quiz/preview-quiz/${nilai.quiz_id}/answer-siswa/${nilai.id}`
            break;
          case 'assignment':
            routing.url = `/kelas/${nilai.class_id}/content/${content.id}?tab=result`
            routing.data = { existingData: nilai,
              content: {
              ...content,
              urlQueryFetch: `?id=${content.id}&offset=0&limit=10&keyword=${nilai.name}&group_id=${null}`,
            }}
            break;
          default:
            routing.url = `/kelas/${nilai.class_id}/content/${content.id}?tab=result`
            routing.data = { existingData: nilai,
              content: {
              ...content,
              urlQueryFetch: `?id=${content.id}&offset=0&limit=10&keyword=${nilai.name}&group_id=${null}`,
            }}
            break;
        }

        history.push(routing.url, {
          ...routing.data,
          content: {
            ...routing.data?.content,
            selectedStudent: nilai,
          },
        })
      }
    } else {
      Swal.close(swalLoadingGetContent)
    }
      
  }

  const initSemester = () => {
    const semesterActive = JSON.parse(localStorage.getItem('semester'))
    const defaultSemester = {
      value: semesterActive.code,
      label: `${semesterActive.tahun_mulai}/${semesterActive.tahun_selesai} - ${semesterActive.title_render} (Aktif)`,
    }
    if (isMounted.current === true) handleChangeSemester(defaultSemester)
  }

  const getClasses = async (ta_semester_code) => {
    const response = await getRequest(
      `semester/${ta_semester_code}/class`,
    )
    if (response) {
      if (response.data.length > 0) {
        const options = response.data.map((item) => ({
          value: item.id,
          label: item.title,
        }))
        if (isMounted.current === true) setClasses(options)
      } else {
        if (isMounted.current === true) setClasses([])
      }
    }
  }

  const getGroups = async (ta_semester_code) => {
    const response = await getRequest(
      `semester/${ta_semester_code}/groups`,
    )
    if (response) {
      if (response.data.length > 0) {
        const options = response.data.map((item) => ({
          value: item.id,
          label: item.group,
        }))
        if (isMounted.current === true) setGroups(options)
      } else {
        if (isMounted.current === true) setGroups([])
      }
    }
  }

  const getTypeScores = async (ta_semester_code) => {
    const response = await getRequest(
      `semester/${ta_semester_code}/type_score`,
    )
    if (response) {
      if (isMounted.current === true) setTypeScores(response.data)
    }
  }

  const handleChangeSemester = async (option) => {
    setSelectSemester(option)
    setSelectClass(null)
    setSelectGroup(null)
    if (option.value) {
      await getTypeScores(option.value)
      await getClasses(option.value)
      await getGroups(option.value)
    }
  }

  const handleChangeClass = (option) => {
    setSelectClass(option)
  }

  const handleChangeGroup = (option) => {
    setSelectGroup(option)
  }

  const getSemesterScores = async () => {
    setLoadingScores(true)
    let query = `rekap-nilai?class_id=${selectClass.value}`
    if (selectGroup) query += `&group_id=${selectGroup.value}`
    const response = await getRequest(query)
    setLoadingScores(false)
    if (response) {
      setScores(response.data)
      renderHeader(response.header)
      const users = initUsers(response.data, response.header)
      renderBody(users, selectGroup)
      setTimeout(() => {
        initDatatable()
      }, 500)
    }
  }

  function initUsers(users, headers) {
    const initUser = users.map((user) => {
      let nilai = []
      user.type_score.forEach((userTypeScore) => {
        for (let header of headers) {
          if (header.id === userTypeScore.id) {
            if (header.colspan !== 0) {
              if (userTypeScore.nilai.length !== 0) {
                userTypeScore.nilai.forEach((userNilai) => {
                  const commonImportantData = {
                    ...user,
                    class_id: userNilai.class_id,
                    content_id: userNilai.id,
                    content_type: userNilai.tipe,
                    quiz_id: userNilai.quiz_id,
                    content_title: userNilai.title,
                  }

                  if (userNilai.total_score !== null) {
                    nilai.push({
                      score: userNilai.total_score,
                      title: userNilai.title,
                      ...commonImportantData,
                    })
                  } else {
                    if (userNilai.is_done) {
                      nilai.push({
                        score: null,
                        title: userNilai.title,
                        status: true,
                      ...commonImportantData,
                      })
                    } else {
                      nilai.push({
                        score: null,
                        title: userNilai.title,
                      ...commonImportantData,
                      })
                    }
                  }
                })
              } else {
                for (let i = 0; i < header.colspan; i++) {
                  nilai.push({
                    score: '-',
                    title: '-',
                  })
                }
              }
            } else {
              nilai.push({
                score: 'Tidak ada materi',
                title: 'Tidak ada materi',
              })
            }
          }
        }
      })

      return {
        id: user.id,
        name: user.name,
        nim: user.nim,
        nilai: nilai,
        nilai_akhir: user.nilai_akhir
      }
    })
    return initUser
  }

  function appendScript(scriptToAppend, id) {
    const script = document.createElement('script')
    script.id = id
    script.src = scriptToAppend
    document.body.appendChild(script)
  }

  function initDatatable() {
    appendScript('/admin/assets/js/plugins/jquery.dataTables.min.js', 'rekap1')
    appendScript(
      '/admin/assets/js/plugins/dataTables.bootstrap4.min.js',
      'rekap2',
    )
    appendScript('/admin/assets/js/plugins/buttons.colVis.min.js', 'rekap3')
    appendScript('/admin/assets/js/plugins/jszip.min.js', 'rekap6')
    appendScript('/admin/assets/js/plugins/dataTables.buttons.min.js', 'rekap7')
    appendScript('/admin/assets/js/plugins/buttons.html5.js', 'rekap8')
    appendScript('/admin/assets/js/plugins/buttons.bootstrap4.min.js', 'rekap9')
    appendScript('/admin/assets/js/pages/data-export-custom.js', 'rekap10')
  }

  function renderHeader(header) {
    const headers = header.map((item) => {
      return {
        id: item.id,
        type_score: item.type_score,
        percentage: item.percentage,
        colspan: item.colspan !== 0 ? item.colspan : 1,
      }
    })
    headers.push({
      type_score: 'Nilai Akhir',
      colspan: 1
    })
    const headerTable = document.getElementById('scoreHeader')
    const headerTableIndex = document.getElementById('scoreHeaderIndex')
    headerTable.innerHTML = ``

    const title = document.createElement('th')
    const nim = document.createElement('th')
    title.innerText = 'Nama Siswa'
    nim.innerText = 'No. Induk'
    nim.rowSpan = 2
    title.rowSpan = 2
    nim.style = 'vertical-align:middle'
    title.style = 'vertical-align:middle'
    headerTable.append(title, nim)

    headers.forEach((item) => {
      const colHead = document.createElement('th')
      colHead.innerText = item.type_score
      colHead.colSpan = item.colspan !== 0 ? item.colspan : 1

      if (refresh) {
        if (item.colspan !== 0) {
          for (let a = 1; a <= item.colspan; a++) {
            const colHeadIndex = document.createElement('th')
            colHeadIndex.innerText = a
            headerTableIndex.append(colHeadIndex)
          }
        }
      }
      headerTable.append(colHead)
    })
    setRefresh(false)
  }

  const sortHeaders = (headers) => {
    const sorted = headers.sort((a, b) => {
      return a.order - b.order
    })
    return sorted
  }

  function renderBody(users, selectedGroup) {
    const bodyTable = document.getElementById('scoreBody')
    bodyTable.innerHTML = ``

    users.forEach((item) => {
      const row = document.createElement('tr')
      const colName = document.createElement('td')
      const colNim = document.createElement('td')
      colName.innerText = `${item.name}`
      colNim.innerText = `${item.nim}`
      row.append(colName, colNim)
      item.nilai.forEach((nilai) => {
        const col = document.createElement('td')
        const spanCol = document.createElement('span');
        spanCol.className = 'text-underline-hover cursor-pointer';
        spanCol.onclick = function() {
          handleRedirectContent({
            ...nilai,
            selectedGroup
          })
        }
        if (Number.isFinite(nilai.score)) {
          spanCol.innerText = nilai.score
        } else {
          if (nilai?.score === 'x') {
            spanCol.style = 'color: red;'
            spanCol.setAttribute('title', nilai.title)
          }

          if (nilai?.score == null) {
            spanCol.innerText = '-'
          } else {
            spanCol.innerText = nilai.score
          }
        }

        if (nilai.status) {
          col.style = 'font-weight:bold; background: rgb(255, 255, 200)'
          col.setAttribute('title', nilai.title + ' (Belum diperiksa)')
        } else {
          col.setAttribute('title', nilai.title)
        }
        col.append(spanCol)
        row.append(col)
      })

      // nilai akhir
      const colNilaiAkhir = document.createElement('td');
      colNilaiAkhir.setAttribute('title', 'Nilai Akhir');

      const spanColNilaiAkhir = document.createElement('span');
      if (Number.isFinite(item.nilai_akhir)) {
        // spanColNilaiAkhir.className = 'text-underline-hover cursor-pointer';
        spanColNilaiAkhir.innerText = item.nilai_akhir
        // spanColNilaiAkhir.onclick = function() {
        //   handleRedirectContent(item.nilai)
        // }
      } else {
        spanColNilaiAkhir.innerText = item.nilai_akhir
      }
      colNilaiAkhir.append(spanColNilaiAkhir)
      row.append(colNilaiAkhir);
      bodyTable.append(row)
    })
  }

  const downloadExcelHandler = async () => {
    setIsLoadingDownloadExcel(true)
    const response = await getRequest(`rekap-nilai-excel?class_id=${selectClass.value}&group_id=${selectGroup.value}`, true, { responseType: 'blob' })


    if (response) {
      setIsLoadingDownloadExcel(false)
      downloadFile({ blobData: response, fileName: `LMS - ${selectClass?.label}.xlsx` })
    } else {
      setIsLoadingDownloadExcel(false)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    initSemester()

    return () => {
      isMounted.current = false
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="row mb-3">
        <div className="col-md-3 mb-3 mb-md-0">
          <SelectSemester
            label="Semester"
            allSemester={true}
            value={selectSemester}
            onChange={handleChangeSemester}
            isLoading={loadingScores}
            isDisabled={loadingScores}
          />
        </div>
        <div className="col-md-3 mb-3 mb-md-0">
          <span className="f-w-700 f-14">Sub Mata Pelajaran</span>
          <Select
            className="mt-2"
            placeholder="Pilih sub mata pelajaran..."
            isMulti={false}
            options={classes}
            value={selectClass}
            onChange={handleChangeClass}
            isLoading={loadingScores}
            isDisabled={loadingScores}
          />
        </div>
        <div className="col-md-3 mb-3 mb-md-0">
          <span className="f-w-700 f-14">Kelas</span>
          <Select
            className="mt-2"
            placeholder="Pilih kelas..."
            isMulti={false}
            options={groups}
            value={selectGroup}
            onChange={handleChangeGroup}
            isClearable={true}
            isLoading={loadingScores}
            isDisabled={loadingScores}
          />
        </div>
        {selectSemester && selectClass ? (
          <div className="col-md-3 col-xl-2 d-flex flex-column justify-content-end mb-0 mb-md-1">
            <button
              className="btn btn-sm btn-primary w-100"
              onClick={loadingScores ? () => {} : getSemesterScores}
              disabled={loadingScores}
            >
              {loadingScores ? (
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                />
              ) : (
                <i className="fas fa-search mr-1 f-12" />
              )}
              Cari
            </button>
          </div>
        ) : (
          <div className="col-md-3 col-xl-2 d-flex flex-column justify-content-end mb-0 mb-md-1">
            <button
              className="btn btn-sm btn-primary w-100"
              disabled={true}
            >
              <i className="fas fa-search mr-1 f-12" />
              Cari
            </button>
          </div>
        )}
        <div className='position-absolute' style={{ right: 10 }}>
          <BaseButtonInformationModal
            list={listKeterangan}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {typeScores.length > 0 ? (
            <div className="dt-responsive table-responsive rounded mb-3">
              <table className="table nowrap">
                <thead>
                  <tr>
                    <th className="p-2">Jenis Penilaian</th>
                    <th className="p-2">Persentase</th>
                  </tr>
                </thead>
                <tbody>
                  {typeScores.map((typeScore, index) => (
                    <tr key={index}>
                      <td className="p-2">
                        {typeScore['type_score.type_score']}
                      </td>
                      <td className="p-2">
                        {typeScore.percentage}
                        {' %'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {loadingScores ? (
            <LoadingSpinner />
          ) : (
            <>
              {scores.length > 0 ? (
                <div className="dt-responsive table-responsive rounded">
                  <div className='mb-2 d-flex justify-content-between flex-wrap align-items-center'>
                    <div>
                      <BaseButton props={{ className: 'mb-3' }} onClick={downloadExcelHandler} isLoading={isLoadingDownloadExcel} isDisabled={!scores?.length}>
                        <i className="fas fa-file-excel mr-1" />
                        Excel
                      </BaseButton>
                    </div>
                  </div> 
                  <table className="table table-bordered nowrap text-center">
                    <thead>
                      <tr id="scoreHeader">
                        <th>Nama Siswa</th>
                        <th>No. Induk</th>
                        <th>Nilai</th>
                      </tr>
                      <tr id="scoreHeaderIndex"></tr>
                    </thead>
                    <tbody id="scoreBody">
                      <tr>
                        <td
                          className="text-center"
                          colSpan="100%"
                        >
                          Data tidak ditemukan
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <NotFound
                  text="Pilih semester dan sub mata pelajaran untuk menampilkan nilai"
                  icon="fas fa-graduation-cap"
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

const ScoreItem = ({ score, headers }) => {
  return (
    <tr className="p-0 m-0">
      <td className="p-2">{score.name}</td>
      {headers.map((header, index) => (
        <td
          key={index}
          className="p-2 f-w-700"
        >
          {score.details[`${header.id}`].average_score
            ? score.details[`${header.id}`].average_score
            : '-'}
        </td>
      ))}
      <td className="p-2 f-w-700">{score.score_final}</td>
    </tr>
  )
}

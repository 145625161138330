import React from 'react'
import { img } from 'pages/kelas/dropzone-css'

export default function FileUploadPreviewer({ mimeTypes, fileTypes, data }) {

  /* ======================================= Preview Image ====================================== */
  if (mimeTypes.includes('image')) {
    return (
      <img
        src={`${mimeTypes},${data}`}
        alt="preview"
        style={img}
      />
    )
  } 
  
  /* ======================================= Preview Video ====================================== */
  else if (mimeTypes.includes('video')) {
    return (
      <video
        style={{ width: '100%' }}
        controls
      >
        <source
          src={`${mimeTypes},${data}`}
          type={`${fileTypes}`}
        />
        Your browser does not support the video tag.
      </video>
    )
  }
  
  /* ======================================= Preview Audio ====================================== */
  else if (mimeTypes.includes('audio')) {
    return (
      <audio
        style={{ width: '3000px' }}
        controls
      >
        <source
          src={`${mimeTypes},${data}`}
          type={`${fileTypes}`}
        />
        Your browser does not support the audio element.
      </audio>
    )
  }
  
}

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import DataTable from 'component/data-table'
import { Back } from 'component/button'

export default function AktifitasSiswaProfileGroups() {
  /* ========================================== Helpers ========================================= */
  const param = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [groupSiswa, setGroupSiswa]     = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [search, setSearch]             = useState('')
  const [limit, setLimit]               = useState(10)
  const [offset, setOffset]             = useState(0)

  /* ========================================= Functions ======================================== */
  async function getGroupSiswa() {
    const response = await getRequest(
      `groupBySiswa?id=${param.siswaId}&search=${search}&limit=${limit}&offset=${offset}`,
    )
    if (response) {
      setGroupSiswa(response.data)
      setTotalRecords(response.totalRecords)
    }
  }

  /* ======================================== Components ======================================== */
  const RenderItem = ({ item }) => {
    return (
      <tr>
        <td>{item.group.group}</td>
        <td>
          {item.group.active ? (
            <span className="badge bg-success text-white">Aktif</span>
          ) : (
            <span className="badge bg-secondary text-white">Tidak aktif</span>
          )}
        </td>
      </tr>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getGroupSiswa()
  }, [limit, offset, search])

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card user-profile-list">
            <div className="card-body dt-responsive table-responsive">
              <DataTable
                className={'table table-striped table-bordered nowrap'}
                data={groupSiswa}
                totalRows={totalRecords}
                show={[10, 20, 30, 40, 50]}
                onChangeSearch={(val) => {
                  setSearch(val)
                }}
                onChangeLimit={(val) => {
                  setLimit(val)
                }}
                onChangePage={(val) => {
                  setOffset(val)
                }}
                column={['Kelas', 'Status']}
                renderItem={(item, i) => (
                  <RenderItem
                    item={item}
                    index={i}
                    key={i}
                  />
                )}
              />
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col p-2 text-right">
                  <Back />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

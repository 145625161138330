import React from 'react'
import usePreviewRapor from 'context/preview-rapor'

export default function DurationChart() {
  /* ====================================== Consume Context ===================================== */
  const { rapor } = usePreviewRapor()

  /* ========================================== Output ========================================== */
  return (
    <div className="col-4 d-flex justify-content-center align-items-center">
      <div className="m-1 d-flex justify-content-center align-items-center flex-column">
        <img
          className="img-fluid"
          style={{ width: '60%', margin: '0 auto' }}
          src="/image/clock.png"
        />
        <h5 className='pt-3 text-center'>{rapor.res?.totalDuration || 0} jam</h5>
      </div>
    </div>
  )
}

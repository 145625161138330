import React from 'react'
import Content from './content'
import ContentTitle from './content/content-title'
import KeyTakeAways from './key-take-aways'
import PersonalNotes from './personal-notes'
import Section from './section'
import SectionTitle from './section/section-title'
import useContentSummary from 'context/content-summary'

export default function TabsContent() {
  /* ====================================== Consume Context ===================================== */
  const { data } = useContentSummary()

  /* ========================================== Output ========================================== */
  return (
    <div
      className="row tab-content"
      id="summaryTabContents"
    >
      <div
        className="col tab-pane fade active show"
        id="kta"
        role="tabpanel"
        aria-labelledby="kta-tab"
      >
        {data?.sections?.map((section, index) => (
          <Section key={`notes_map_lv1_` + index}>
            <SectionTitle text={section.title} />
            {section?.contents?.map((content, index_content) => (
              <Content key={`notes_map_lv2_` + index_content}>
                <div className="accordion-item">
                  <ContentTitle text={`Section ${section.title} - ${content.title}`} />
                  <KeyTakeAways content={content} />
                </div>
              </Content>
            ))}
          </Section>
        ))}
      </div>
      <div
        className="col tab-pane fade"
        id="notes"
        role="tabpanel"
        aria-labelledby="notes-tab"
      >
        {data?.sections?.map((section, index) => (
          <Section key={`notes_map_lv1_` + index}>
            <SectionTitle text={section.title} />
            {section?.contents?.map((content, index_content) => (
              <Content key={`notes_map_lv2_` + index_content}>
                <div className="accordion-item">
                  <ContentTitle text={`Section ${section.title} - ${content.title}`} />
                  <PersonalNotes content={content} />
                </div>
              </Content>
            ))}
          </Section>
        ))}
      </div>
    </div>
  )
}

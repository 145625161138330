import moment from 'moment'
import React, { useEffect, useState } from 'react'
import useContentContainerContext from 'context/content-container'
import useContentPlayerContext from 'context/content-player'
import ContentDisabled from 'component/content/playlist/player/content-disabled'
import ContentEnabled from 'component/content/playlist/player/content-enabled'
import { getTodayDate } from 'util/global'

export default function ContentContainer() {
  /* ====================================== Consume Context ===================================== */
  const { allContents } = useContentPlayerContext()
  const { content }     = useContentContainerContext()

  /* ========================================== States ========================================== */
  const [isAvailable, setIsAvailable] = useState(false)
  const [isExpire, setIsExpire]       = useState(false)
  
  /* ========================================= Constants ======================================== */
  const todayDate  = getTodayDate(true)

  /* ========================================= Functions ======================================== */
  const checkPre   = (precontent) => {
    const found = allContents.find(ac => ac.id === precontent.id)
    if (!found) return false // kalau gk ada catatan di allContents maka lgsg false
    return found.isAssignmentOrQuiz ? found.isSubmitted : found.isCompleted // cek kalau sblmnya tugas/quiz cek udah submit apa belum
  }
  const checkStart = (displayAt, todayDate) => new Date(displayAt).getTime() <= todayDate   // true is available
  const checkEnd   = (endedAt, todayDate) => new Date(endedAt).getTime() < todayDate        // true is expire

  function init() {
    let available = true
    let expire = false
    if (content.display_at) {
      available = checkStart(content.display_at, todayDate)
      if (available) {
        if (content.content !== null) available = checkPre(content.content)
      }
    } else {
      if (content.content !== null) available = checkPre(content.content)
    }
    if (content.ended_at) {
      expire = checkEnd(content.ended_at, todayDate)
      if (expire) available = false
    }
    setIsAvailable(available)
    setIsExpire(expire)
  }

  function expireMessage() {
    if (content.display_at !== null || content.ended_at !== null) {
      if (isExpire) {
        return `Berakhir pada ${moment(content.ended_at).format(
          'DD MMMM YYYY',
        )}`
      } else {
        return `Tersedia pada ${moment(content.display_at).format(
          'DD MMMM YYYY HH:mm',
        )}`
      }
    } else {
      return null
    }
  }

  function precontentMessage() {
    if (content.content !== null) {
      if (content.content.class_content_activities.length !== 0) {
        return null
      } else {
        return `Selesaikan ${content.content.title}`
      }
    } else {
      return null
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    allContents && init()
  }, [allContents])

  /* ========================================== Output ========================================== */
  return (
    <>
      {isAvailable ? (
        <ContentEnabled />
      ) : (
        <ContentDisabled
          title={expireMessage()}
          isExpire={isExpire}
          precontentMessage={precontentMessage()}
        />
      )}
    </>
  )
}

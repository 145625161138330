import React from 'react'
import {
  PreviewTextAssign,
  PreviewDocAssign,
  PreviewAudioAssign,
  PreviewVideoAssign,
  PreviewYoutubeAssign,
  PreviewUrlAssign,
} from 'component/assignment/preview'
import CkeditorReadonly from 'component/ckeditor-readonly'

const DetailAssignmentModal = ({ dataAssignment, setDetailAssignment }) => {
  return (
    <div
      className="modal fade"
      id="detail-assignment-modal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myExtraLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              <i className="fas fa-pen-alt mr-1" />
              {dataAssignment ? dataAssignment.title : ''}
            </h5>
            <button
              type="button"
              className="close text-danger"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setDetailAssignment(null)}
            >
              <i className="feather icon-x" />
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                {dataAssignment ? (
                  <CkeditorReadonly
                    id={dataAssignment.id}
                    data={dataAssignment.text}
                  />
                ) : null}
              </div>
              {dataAssignment ? (
                <div className="col-12">
                  {dataAssignment.reply_type == 1 ? (
                    <PreviewTextAssign />
                  ) : null}
                  {dataAssignment.reply_type == 2 ? (
                    <PreviewAudioAssign />
                  ) : null}
                  {dataAssignment.reply_type == 3 ? (
                    <PreviewVideoAssign />
                  ) : null}
                  {dataAssignment.reply_type == 4 ? <PreviewDocAssign /> : null}
                  {dataAssignment.reply_type == 5 ? (
                    <PreviewYoutubeAssign />
                  ) : null}
                  {dataAssignment.reply_type == 6 ? <PreviewUrlAssign /> : null}
                </div>
              ) : null}
            </div>
          </div>
          <div className="modal-footer p-2">
            <button
              type="button"
              className="btn btn-outline-danger"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setDetailAssignment(null)}
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailAssignmentModal

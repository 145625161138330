import React, { useEffect, useState } from 'react'

import { useBaseContext } from 'context/base'
import RaporLoading from './rapor-loading'
import RaporSuccess from './rapor-success'
import RaporIdle from './rapor-idle'
import RaporError from './rapor-error'

const RaporTable = ({
  loadingTypes,
  category_id,
  group_id,
  loadingSearch,
  setLoadingSearch,
  semester_id,
}) => {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [result, setResult] = useState(null)

  /* ========================================= Functions ======================================== */
  async function getSiswaByCategory(category_id, group_id, semester_id) {
    if (category_id) {
      let searchQuery = `ta_semester_code=${semester_id}&category_id=${category_id}`
      if (group_id) searchQuery = `${searchQuery}&group_id=${group_id}`
      const response = await getRequest(
        `siswa-by-category?${searchQuery}`,
      )
      return response ? response.data : null
    } else {
      return null
    }
  }

  async function handleSearch(category_id, group_id, semester_id) {
    setLoadingSearch(loadingTypes.start)
    const data = await getSiswaByCategory(category_id, group_id, semester_id)
    setResult(data)
    setLoadingSearch(data === null ? loadingTypes.error : loadingTypes.success)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (category_id) {
      handleSearch(category_id, group_id, semester_id)
    }
  }, [category_id, group_id, semester_id])

  /* ========================================== Output ========================================== */
  return (
    <div className="card">
      <div className="card-body">
        {loadingSearch === loadingTypes.idle && <RaporIdle />}
        {loadingSearch === loadingTypes.start && <RaporLoading />}
        {loadingSearch === loadingTypes.success && (
          <RaporSuccess
            result={result}
            category_id={category_id}
            group_id={group_id}
            semester_id={semester_id}
            handleSearch={handleSearch}
          />
        )}
        {loadingSearch === loadingTypes.error && (
          <RaporError errorMessage="Terjadi kesalahan, coba beberapa saat lagi" />
        )}
      </div>
    </div>
  )
}

export default RaporTable

import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import InvalidText from 'component/invalid/text'

export default function AddFeedback() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  
  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/kelola-feedback', name: 'Kelola Feedback' }]

  /* ======================================= Local States ======================================= */
  const [isPopUp, setIsPopUp]           = useState(false)
  const [typeFeedback, setTypeFeedback] = useState(null)
  const [desc, setDesc]                 = useState('')
  const [status, setStatus]             = useState(1)
  const [listFeedback, setListFeedback] = useState([
    {
      text: '',
      active: 0,
    },
  ])

  // VALIDATION STATE
  const [isDescInvalid, setIsDescInvalid]             = useState(false)
  const [isFeedResponseEmpty, setIsFeedResponseEmpty] = useState(false)

  /* =========================================== Refs =========================================== */
  const descriptionRef = useRef()

  /* ========================================= Functions ======================================== */
  function getSearchParams() {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('popup')) {
      setIsPopUp(true)
    } else {
      setIsPopUp(false)
    }

    if (urlParams.has('typefeed')) {
      switch (urlParams.get('typefeed')) {
        case '1':
          setTypeFeedback(1)
          break
        case '2':
          setTypeFeedback(2)
          break
        case '3':
          setTypeFeedback(3)
          break
        case '4':
          setTypeFeedback(4)
          break
        default:
          break
      }
    } else {
      setTypeFeedback(1)
    }
  }

  function handleBack(isPopUp) {
    if (isPopUp) {
      return window.close()
    } else {
      return history.push('/kelola-feedback')
    }
  }

  function handleChangeDesc(event) {
    if (event.target.value) {
      setIsDescInvalid(false)
    }
    setDesc(event.target.value)
  }

  function handleChangeStatus(event) {
    switch (event.target.checked) {
      case true:
        setStatus(1)
        break
      case false:
        setStatus(0)
        break
      default:
        break
    }
  }

  function handleAddJawaban() {
    setListFeedback([
      ...listFeedback,
      {
        text: '',
        active: 0,
      },
    ])
  }

  function handleSetText(indexText, value) {
    const newFeedback = listFeedback.map((feedback, index) => {
      if (index === indexText) {
        return {
          text: value,
          active: feedback.active,
        }
      } else {
        return {
          text: feedback.text,
          active: feedback.active,
        }
      }
    })
    setListFeedback(newFeedback)
  }

  function handleSetActiveFeedCheckbox(indexCorrect) {
    const newFeedback = listFeedback.map((feedback, index) => {
      if (index === indexCorrect) {
        if (feedback.active == 1) {
          return {
            text: feedback.text,
            active: 0,
          }
        } else {
          return {
            text: feedback.text,
            active: 1,
          }
        }
      } else {
        return {
          text: feedback.text,
          active: feedback.active,
        }
      }
    })
    setListFeedback(newFeedback)
  }

  function handleValidation(type) {
    if (type === 1 || type === 4) {
      if (desc) {
        setIsDescInvalid(false)
        handleSubmitEssay(desc, status, typeFeedback)
      } else {
        setIsDescInvalid(true)
        descriptionRef.current.focus()
        window.notification(
          'Data belum lengkap',
          'Mohon lengkapi data',
          'warning',
        )
      }
    } else {
      if (desc && listFeedback.length !== 0 && listFeedback[0].text !== 0) {
        setIsDescInvalid(false)
        setIsFeedResponseEmpty(false)
        handleSubmitMultipleChoice(desc, status, listFeedback, typeFeedback)
      } else {
        if (!desc) {
          setIsDescInvalid(true)
          descriptionRef.current.focus()
        } else {
          setIsDescInvalid(false)
        }

        if (listFeedback.length === 0) {
          setIsFeedResponseEmpty(true)
        } else {
          setIsFeedResponseEmpty(false)
        }

        window.notification(
          'Data belum lengkap',
          'Mohon lengkapi data',
          'warning',
        )
      }
    }
  }

  function handleDeleteJawaban(indexDelete) {
    const newFeedback = listFeedback.filter(
      (jawaban, index) => index !== indexDelete,
    )
    setListFeedback(newFeedback)
  }

  async function handleSubmitEssay(desc, status, typeFeedback) {
    let form = {
      description: desc,
      feed_type: typeFeedback,
      active: status,
    }

    const response = await postRequest('feed', form)
    if (response) {
      handleBack(isPopUp)
      window.notification('Sukses', 'Feedback berhasil ditambahkan', 'success')
    } else {
      console.error('ERROR', response)
    }
  }

  async function handleSubmitMultipleChoice(
    desc,
    status,
    listFeedback,
    typeFeedback,
  ) {
    let form = {
      description: desc,
      feed_type: typeFeedback,
      active: status,
      feedrespons: JSON.stringify(listFeedback),
    }

    const response = await postRequest('feed', form)
    if (response) {
      handleBack(isPopUp)
      window.notification('Sukses', 'Feedback berhasil ditambahkan', 'success')
    } else {
      console.error('ERROR', response)
    }
  }

  const Jawaban = ({
    index,
    text,
    active,
    handleDelete,
    handleSetActiveFeed,
    handleText,
  }) => {
    const [showDelete, setShowDelete] = useState(false)
    const [textVal, setTextVal] = useState(text)

    return (
      <li
        onMouseEnter={() => setShowDelete(true)}
        onMouseLeave={() => setShowDelete(false)}
        key={index}
      >
        <div className="form-group row">
          <div className="col-sm-9">
            <input
              value={textVal}
              onChange={(event) => setTextVal(event.target.value)}
              onBlur={() => handleText(index, textVal)}
              type="text"
              className="form-control"
              placeholder={`Pilihan-${index + 1}`}
            />
          </div>
          <div className="col-sm-3 py-2 d-flex justify-content-between">
            <div className="form-check d-flex align-items-center">
              <input
                onChange={() => handleSetActiveFeed(index)}
                className="form-check-input"
                // type={typeFeedback == 2 ? "radio" : "checkbox"}
                type="checkbox"
                name="correct"
                id={index}
                defaultValue={1}
                checked={active === 1}
              />
              <label
                className="form-check-label mt-1"
                htmlFor={index}
              >
                Aktif
              </label>
            </div>
            <button
              onClick={() => handleDelete(index)}
              type="button"
              className="btn btn-icon btn-danger"
              title="Hapus"
              style={{ visibility: showDelete ? 'visible' : 'hidden' }}
            >
              <i className="feather icon-trash-2" />
            </button>
          </div>
        </div>
      </li>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getSearchParams()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Tambah Feedback" links={links} />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list  ">
              <div className="card-header">
                <h5>
                  {typeFeedback === 1 ? 'Feedback Essay' : null}
                  {typeFeedback === 2 ? 'Feedback Pilihan Ganda' : null}
                  {typeFeedback === 3
                    ? 'Feedback Pilihan Ganda Kompleks'
                    : null}
                  {typeFeedback === 4 ? 'Feedback Word Cloud' : null}
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <form>
                      <div className="form-group mb-4 row">
                        <label
                          htmlFor="feed-Desc"
                          className="col-form-label col-sm-3 "
                        >
                          Pertanyaan/instruksi
                        </label>
                        <div className="col-sm-9">
                          <input
                            ref={descriptionRef}
                            value={desc}
                            onChange={(event) => handleChangeDesc(event)}
                            type="text"
                            className={`form-control`}
                            id="feed-Desc"
                            placeholder="Pertanyaan/instruksi feedback"
                          />
                          {isDescInvalid ? (
                            <InvalidText name="Pertanyaan/instruksi" />
                          ) : null}
                        </div>
                      </div>

                      {/* Select status pertanyaan */}
                      <div className="form-group mb-3 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="switch-status-feedback"
                        >
                          <span className="mr-1">Status</span>
                        </label>
                        <div className="form-group col-sm-9">
                          <div className="switch switch-primary d-inline m-r-10">
                            <input
                              onChange={(event) => handleChangeStatus(event)}
                              checked={status === 1}
                              type="checkbox"
                              id="switch-status-feedback"
                            />
                            <label
                              htmlFor="switch-status-feedback"
                              className="cr"
                            ></label>
                          </div>
                          <label htmlFor="switch-status-feedback">
                            {status === 1 ? 'Aktif' : 'Tidak Aktif'}
                          </label>
                        </div>
                      </div>
                      {typeFeedback === 2 || typeFeedback === 3 ? (
                        <div className="form-group row">
                          <label className="col-form-label col-sm-3 pt-4">
                            Pilihan Feedback
                          </label>
                          <div className="col-sm-9">
                            <ul className="list-unstyled mt-3">
                              {listFeedback.map((feedback, index) => {
                                return (
                                  <Jawaban
                                    key={index}
                                    index={index}
                                    text={feedback.text}
                                    active={feedback.active}
                                    handleDelete={handleDeleteJawaban}
                                    handleSetActiveFeed={
                                      handleSetActiveFeedCheckbox
                                    }
                                    handleText={handleSetText}
                                  />
                                )
                              })}
                            </ul>

                            <div className="form-group mb-3">
                              <button
                                onClick={() => handleAddJawaban()}
                                type="button"
                                className="btn btn-sm btn-primary"
                              >
                                <i className="feather icon-plus-circle mr-1" />
                                <span>Tambah Pilihan</span>
                              </button>
                            </div>

                            {isFeedResponseEmpty ? (
                              <InvalidText name="Pilihan feedback" />
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </form>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="row">
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-outline-danger mr-2"
                      onClick={() => handleBack(isPopUp)}
                    >
                      {isPopUp ? 'Tutup' : 'Kembali'}
                    </button>
                    <button
                      onClick={() => handleValidation(typeFeedback)}
                      className="btn btn-success"
                    >
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

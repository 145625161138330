import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useBaseContext } from 'context/base'
import { useStaleObj } from 'util/use-stale-obj'
import DraftInfo from 'pages/input-rapor/components/draft-info'

function InputRaporAllType3({
  userId,
  reportGroupId,
  response,
}) {
  /* ========================================== Helpers ========================================= */
  const params = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= States ========================================= */
  const [res, setRes] = useState(null)
  const [datax, setDatax] = useState([])

  /* ======================================== Functions ======================================= */
  async function getValue() {
    let us = localStorage.getItem('user')
    let usj = JSON.parse(us)
    var rsp = await getRequest(
      `get-value-score-by-group?user_id=${userId}&group_id=${reportGroupId}&semester_code=${params.semester}`,
    )

    if (rsp) {
      var ss = {}
      if (rsp.data) {
        var d = rsp.data
        ss = {
          user_id: d.user_id,
          categories_id: d.categories_id,
          categories_type_id: d.categories_type_id,
          report_group_id: d.report_group_id,
          createdBy: d.createdBy,
          updatedBy: d.updatedBy,
          value: JSON.parse(d.value),
          ta_semester_code: d.ta_semester_code,
          profile_id: d.profile_id,
        }
        setDatax(JSON.parse(d.value))
        setRes(ss)
      } else {
        ss = {
          user_id: userId,
          categories_id: null,
          categories_type_id: null,
          report_group_id: reportGroupId,
          createdBy: usj.id,
          updatedBy: usj.id,
          value: [],
          ta_semester_code: params.semester,
          profile_id: usj.profile_id,
        }
        setRes(ss)
      }
      response(ss)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getValue()
  }, [])

  /* ======================================== Local Stale ======================================= */
  return res && <InputEkstrakurikuler initialValue={datax} />

  function InputEkstrakurikuler({ initialValue }) {
    const key = [
      'input_rapor_all_type3',
      params.semester,
      reportGroupId,
      userId,
    ].join('_')

    const { loading, data, isDrafted, invalidate, stale } = useStaleObj(
      initialValue,
      key,
    )

    useEffect(() => {
      if (res) {
        res.value = data
        response(res)
      }
    }, [data])

    return (
      !loading && (
        <div className="table-responsive">
          <div className="d-flex justify-content-between align-items-end">
            <div>{isDrafted && <DraftInfo onClick={invalidate} />}</div>
            <button
              type="button"
              onClick={() => {
                stale([
                  ...data,
                  {
                    ekstrakurikuler: '',
                    deskripsi: '',
                  },
                ])
              }}
              className="btn btn-outline-primary"
            >
              Tambah Ekstrakurikuler
            </button>
          </div>
          <table className="table mt-1 table-bordered">
            <thead>
              <tr>
                <th style={{ width: 50 }}>No</th>
                <th style={{ width: 300 }}>Ekstrakurikuler</th>
                <th>Deskripsi</th>
                <th style={{ width: 50 }}></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                return (
                  <tr key={`input_ekstrakurikuler_` + index}>
                    <td>{index + 1}</td>
                    <td>
                      <input
                        type="text"
                        value={item.ekstrakurikuler}
                        onChange={(val) => {
                          data[index].ekstrakurikuler = val.target.value
                          stale([...data])
                        }}
                        className="form-control"
                        placeholder={'Nama Ekstrakurikuler'}
                      />
                    </td>
                    <td>
                      <textarea
                        onChange={(val) => {
                          data[index].deskripsi = val.target.value
                          stale([...data])
                        }}
                        className={'form-control'}
                        rows={4}
                        value={item.deskripsi}
                        placeholder={'Deskripsi'}
                      ></textarea>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          data.splice(index, 1)
                          stale([...data])
                        }}
                        className={'btn btn-danger btn-sm'}
                      >
                        Hapus
                      </button>
                    </td>
                  </tr>
                )
              })}
              {data?.length == 0 ? (
                <tr>
                  <td colSpan={5}>Tidak ada data</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      )
    )
  }
}

export default React.memo(
  InputRaporAllType3,
  (oldProps, newProps) =>
    oldProps.userId === newProps.userId &&
    oldProps.reportGroupId === newProps.reportGroupId,
)

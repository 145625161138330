import React from 'react'
import CardMeta from 'component/card-meta'

const AssignmentUrl = ({ handleUrl, isUrlEmpty, url }) => {
  /* ========================================= Constants ======================================== */
  const _regEx =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/
  const isCorrectURL = !!url.match(_regEx)

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="form-group row">
        <label
          className="col-sm-3 col-form-label"
          htmlFor="inputTugasUrl"
        >
          URL Tugas:
        </label>
        <div className="col-sm-9">
          <input
            required
            type="url"
            className="form-control"
            value={url}
            onChange={(event) => handleUrl(event.target.value)}
            placeholder="Masukkan tugas berupa URL social media, website, dll"
            id="inputTugasUrl"
          />
          {isUrlEmpty ? (
            <label className="col small form-text text-danger">
              URL tidak boleh kosong.
            </label>
          ) : null}
          {!isUrlEmpty && !isCorrectURL ? (
            <label className="col small form-text text-danger">
              Input harus berupa URL yang valid
            </label>
          ) : null}
        </div>
      </div>
      {url ? (
        <div className="row">
          <div className="col-12">
            <CardMeta url={url} />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default AssignmentUrl

import React from 'react'
import usePreviewRapor from 'context/preview-rapor'

export default function Badges() {
  /* ====================================== Consume Context ===================================== */
  const { rapor } = usePreviewRapor()

  /* ========================================== Output ========================================== */
  return (
    <div className="col-4 m-0 p-0 text-center d-flex justify-content-center align-items-center">
      {rapor.badges.length
        ? rapor.badges
            .map((badgeCategory) => badgeCategory.badges[0])
            .map((badge) => (
              <div
                key={`badge_` + badge.id}
                
                className="text-center m-1"
              >
                <img
                  className="w-75 mb-2"
                  src={badge.linkImage}
                  alt="Badge"
                  onError={(event) =>
                    (event.currentTarget.src =
                      '/image/badges/default_badge.png')
                  }
                />
                <h6 style={{ fontSize: '10px' }}>{badge.desc}</h6>
                <span
                  style={{ fontSize: '10px' }}
                  className="d-flex align-items-center justify-content-center mb-2"
                >
                  ({badge.isComplete !== null ? badge.isComplete.in_point : 0}{' '}
                  Poin)
                  {<i className="fas fa-check-circle ml-1 text-primary" />}
                </span>
              </div>
            ))
        : null}
      {/* {rapor.badges?.length
        ? rapor.badges.slice(0, 3).map((badge, badge_idx) => (
            <div
              key={`badge_` + badge_idx}
              style={{ width: '500px' }}
              className="text-center m-3"
            >
              <img
                className={'w-75 mb-2'}
                src={badge.linkImage}
                alt={'Badge'}
                onError={(event) =>
                  (event.currentTarget.src = '/image/badges/default_badge.png')
                }
              />
              <h6 style={{ fontSize: '7px' }}>{badge.desc}</h6>
              <span
                style={{ fontSize: '7px' }}
                className="d-flex align-items-center justify-content-center mb-2"
              >
                ({badge.isComplete !== null ? badge.isComplete.in_point : 0}{' '}
                Poin)
                {<i className="fas fa-check-circle ml-1 text-primary" />}
              </span>
            </div>
          ))
        : null} */}
    </div>
  )
}

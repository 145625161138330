import React from 'react'

export default function RemoveButton({ handleClick }) {
  return (
    <button
      type      = "button"
      className = "btn btn-sm btn-danger"
      onClick   = {handleClick}
    >
      <i className="fas fa-times" />
    </button>
  )
}

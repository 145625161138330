import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { Submit, BackNotBack } from 'component/button'
import InvalidText from 'component/invalid/text'
import { getBase64 } from 'util/base64'
import './gamifikasi.css'

export default function GamificationBadges() {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest, deleteRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/gamification', name: 'Gamifikasi' }]

  /* ======================================= Local States ======================================= */
  const [badges, setBadges]                     = useState([])
  const [deskripsi, setDeskripsi]               = useState('')
  const [nilai, setNilai]                       = useState('')
  const [image, setImage]                       = useState(null)
  const [imagePreview, setImagePreview]         = useState(null)
  const [titleModal, setTitleModal]             = useState('')
  const [idBadge, setIdBadge]                   = useState('')
  const [parent, setParent]                     = useState('')
  const [isDeskripsiEmpty, setIsDeskripsiEmpty] = useState(false)
  const [isNilaiEmpty, setIsNilaiEmpty]         = useState(false)
  const [imageName, setImageName]               = useState('')
  const [isImageTooLarge, setImageTooLarge]     = useState(false)

  /* ========================================= Functions ======================================== */
  async function getDataBadge() {
    let response = await getRequest('gamification')
    if (response) {
      setBadges(response.data.badges)
    }
  }
  
  function resetForm() {
    setDeskripsi('')
    setNilai('')
    setIsDeskripsiEmpty(false)
    setIsNilaiEmpty(false)
    setImageName('')
    setImageTooLarge(false)
  }

  async function hapus() {
    window.$('.edit-badge').modal('hide')
    Swal.fire({
      title: 'Apa kamu yakin?',
      text: 'Kamu akan menghapus badge ini ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus badge ini!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await deleteRequest('gamification?id=' + idBadge)
        if (response) {
          window.notification('Berhasil', 'berhasil menghapus badge', 'success')
          window.$('.edit-badge').modal('hide')
          getDataBadge()
          Swal.fire('Berhasil!', 'berhasil menghapus badge', 'success')
        } else {
          Swal.fire(
            'Oppsss!',
            'Terjadi kesalahan coba beberapa saat lagi.',
            'error',
          )
        }
      } else {
        window.$('.edit-badge').modal('show')
      }
    })
  }

  async function addSave() {
    if (deskripsi && nilai) {
      setIsDeskripsiEmpty(false)
      setIsNilaiEmpty(false)
      let form = new FormData()
      form.append('desc', deskripsi)
      form.append('value', nilai)
      form.append('active', 1)
      form.append('parent', parent)
      form.append('types', 'badges')
      if (image) {
        form.append('image', JSON.stringify(image))
      }
      let response = await postRequest('gamification', form)
      if (response) {
        window.notification('Berhasil', 'berhasil menambah badge', 'success')
        window.$('.add-badge').modal('hide')
        getDataBadge()
      }
    } else {
      if (!deskripsi) {
        setIsDeskripsiEmpty(true)
      }
      if (!nilai) {
        setIsNilaiEmpty(true)
      }
    }
  }

  async function editSave() {
    if (deskripsi && nilai) {
      setIsDeskripsiEmpty(false)
      setIsNilaiEmpty(false)
      let form = new FormData()
      form.append('desc', deskripsi)
      form.append('value', nilai)
      form.append('id', idBadge)
      form.append('types', 'badges')
      if (image) {
        form.append('image', JSON.stringify(image))
      }
      let response = await putRequest('gamification', form)
      if (response) {
        window.notification('Berhasil', 'berhasil mengubah badge', 'success')
        window.$('.edit-badge').modal('hide')
        getDataBadge()
      }
    } else {
      if (!deskripsi) {
        setIsDeskripsiEmpty(true)
      }
      if (!nilai) {
        setIsNilaiEmpty(true)
      }
    }
  }

  /* ======================================== Components ======================================== */
  function RenderGroupBadges() {
    return badges.map((item, i) => {
      return (
        <fieldset
          key={i}
          className="mb-2 p-2 col-sm-12"
        >
          <div className="row costumize-header m-0 justify-content-between p-2">
            <h5 className="m-0 p-2">{item.desc.toUpperCase()}</h5>
            <button
              onClick={() => {
                setParent(item.id)
                setDeskripsi('')
                setNilai('')
                setTitleModal(item.desc)
                setImage(null)
                setIdBadge(null)
                setImagePreview(null)
                window.$('.add-badge').modal('show')
              }}
              className="btn btn-success btn-sm"
            >
              Tambah badge
            </button>
          </div>
          <div className="row justify-content-left badges p-2 mb-2">
            <RenderBadges
              data={item.child}
              code={item.code}
              header={item.desc}
            />
          </div>
        </fieldset>
      )
    })
  }

  function RenderBadges({ data, header }) {
    return data.map((item, i) => {
      return (
        <div
          key={i}
          style={{ height: '200px', width: '150px' }}
          className="text-center mb-5 m-2 p-2"
        >
          <a
            data-toggle="collapse"
            href="#point1"
            aria-expanded="false"
          >
            <img
              className="w-100"
              src={item.link_image}
              alt="newbie"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = '/image/badges/default_badge.png'
              }}
            />
          </a>
          <h6 style={{ cursor: 'pointer' }}>
            {item.desc}
          </h6>
          <span>({item.value} poin)</span>
          <div
            className=""
            id=""
          >
            <button
              style={{ padding: '4px', fontSize: '10px' }}
              onClick={() => {
                setDeskripsi(item.desc)
                setNilai(item.value)
                setTitleModal(header)
                setImage(null)
                setIdBadge(item.id)
                setImagePreview(item.link_image)
                window.$('.edit-badge').modal('show')
              }}
              className="btn btn-outline-success btn-sm mt-1"
            >
              Edit badge
            </button>
          </div>
        </div>
      )
    })
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getDataBadge()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Customize Badges" links={links}/>
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <RenderGroupBadges />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div
        className="modal fade edit-badge"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title h4"
                id="myLargeModalLabel"
              >
                Edit {titleModal}
              </h5>
              <button
                onClick={resetForm}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  >
                    Deskripsi
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      value={deskripsi}
                      onChange={(e) => {
                        if (e.target.value) {
                          setIsDeskripsiEmpty(false)
                        }
                        setDeskripsi(e.target.value)
                      }}
                      className="form-control"
                      placeholder="Deskripsi"
                    />
                    {isDeskripsiEmpty && <InvalidText name="Deskripsi" />}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  >
                    Nilai
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="number"
                      min={0}
                      value={nilai}
                      onChange={(e) => {
                        if (e.target.value) {
                          setIsNilaiEmpty(false)
                          setNilai(Math.abs(e.target.value))
                        } else {
                          setNilai('')
                        }
                      }}
                      className="form-control"
                      placeholder="Nilai"
                    />
                    {isNilaiEmpty && <InvalidText name="Nilai" />}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  >
                    Gambar <br />
                    (abaikan jika tidak ingin merubahnya)
                  </label>
                  <div className="col-sm-7">
                    <div
                      className="border rounded alert alert-warning w-100"
                      role="alert"
                    >
                      <p className="m-0">
                        <i className="fas fa-info-circle mr-1" />
                        Ukuran gambar maksimal 1MB
                      </p>
                    </div>
                    <div className="custom-file">
                      <input
                        type="file"
                        onChange={(e) => {
                          if (e.target.files[0].size > 1000000) {
                            setImageTooLarge(true)
                            setImageName('')
                          } else {
                            setImageTooLarge(false)
                            setImageName(e.target.files[0].name)
                            getBase64(e.target.files[0])
                              .then((result) => {
                                var r = result.split(',')
                                setImagePreview(
                                  URL.createObjectURL(e.target.files[0]),
                                )
                                setImage({
                                  name: e.target.files[0].name,
                                  data: r[1],
                                })
                              })
                              .catch((err) => {
                                console.error(err)
                              })
                          }
                        }}
                        className="custom-file-input"
                        id="inputGroupFile01"
                        accept="image/x-png,image/gif,image/jpeg"
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="inputGroupFile01"
                      >
                        {imageName ? imageName : 'Upload gambar'}
                      </label>
                    </div>
                    {isImageTooLarge ? (
                      <span className="small form-text text-danger">
                        Ukuran gambar maksimal 1MB
                      </span>
                    ) : null}
                  </div>
                  <div className="col-sm-2">
                    <img
                      src={imagePreview}
                      style={{ width: 70, height: 70 }}
                      onError={(event) =>
                        (event.currentTarget.src =
                          '/image/badges/default_badge.png')
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  ></label>
                  <div className="col-sm-7">
                    <Submit
                      onClick={() => editSave()}
                      text="Simpan"
                    />
                    <BackNotBack
                      text="Tutup"
                      onClick={() => {
                        resetForm()
                        window.$('.edit-badge').modal('hide')
                      }}
                    />
                  </div>
                  <div className="col-sm-2">
                    <button
                      type="button"
                      onClick={() => {
                        hapus()
                      }}
                      className="btn btn-outline-danger"
                    >
                      Hapus
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade add-badge"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title h4"
                id="myLargeModalLabel"
              >
                Tambah {titleModal}
              </h5>
              <button
                onClick={resetForm}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  >
                    Deskripsi
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      value={deskripsi}
                      onChange={(e) => {
                        if (e.target.value) {
                          setIsDeskripsiEmpty(false)
                        }
                        setDeskripsi(e.target.value)
                      }}
                      className="form-control"
                      placeholder="Deskripsi"
                    />
                    {isDeskripsiEmpty && <InvalidText name="Deskripsi" />}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  >
                    Nilai
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="number"
                      min={0}
                      value={nilai}
                      onChange={(e) => {
                        if (e.target.value) {
                          setIsNilaiEmpty(false)
                          setNilai(Math.abs(e.target.value))
                        } else {
                          setNilai('')
                        }
                      }}
                      className="form-control"
                      placeholder="Nilai"
                    />
                    {isNilaiEmpty && <InvalidText name="Nilai" />}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  >
                    Gambar <br />
                    (abaikan jika tidak ingin merubahnya)
                  </label>
                  <div className="col-sm-7">
                    <div
                      className="border rounded alert alert-warning w-100"
                      role="alert"
                    >
                      <p className="m-0">
                        <i className="fas fa-info-circle mr-1" />
                        Ukuran gambar maksimal 1MB
                      </p>
                    </div>
                    <div className="custom-file">
                      <input
                        type="file"
                        onChange={(e) => {
                          if (e.target.files[0].size > 1000000) {
                            setImageTooLarge(true)
                            setImageName('')
                          } else {
                            setImageTooLarge(false)
                            setImageName(e.target.files[0].name)
                            getBase64(e.target.files[0])
                              .then((result) => {
                                var r = result.split(',')
                                setImagePreview(
                                  URL.createObjectURL(e.target.files[0]),
                                )
                                setImage({
                                  name: e.target.files[0].name,
                                  data: r[1],
                                })
                              })
                              .catch((err) => {
                                console.error(err)
                              })
                          }
                        }}
                        className="custom-file-input"
                        id="inputGroupFile01"
                        accept="image/x-png,image/gif,image/jpeg"
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="inputGroupFile01"
                      >
                        {imageName ? imageName : 'Upload gambar'}
                      </label>
                    </div>
                    {isImageTooLarge ? (
                      <span className="small form-text text-danger">
                        Ukuran gambar maksimal 1MB
                      </span>
                    ) : null}
                  </div>
                  <div className="col-sm-2">
                    <img
                      src={imagePreview}
                      onError={(e) =>
                        (e.currentTarget.src = '/public/grandmaster.png')
                      }
                      style={{ width: 70, height: 70 }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  ></label>
                  <div className="col-sm-9">
                    <Submit
                      onClick={() => addSave()}
                      text="Simpan"
                    />
                    <BackNotBack
                      text="Batal"
                      onClick={() => {
                        resetForm()
                        window.$('.add-badge').modal('hide')
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </TemplateAdmin>
  )
}

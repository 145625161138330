import { avatarDefaultUser } from "util/constant"
import ColPrasyarat from "./col-prasyarat"

export default function UserItem({ item, detailHandler = () => {} }) {
  return (
    <tr>
      <td>
        <div className="d-inline-block align-middle">
          <img
            src={`${item?.linkAvatar}`}
            alt={item?.name}
            className="img-radius align-top m-r-15"
            style={{ width: '40px', height: '40px' }}
            onError={(event) => {
              event.currentTarget.src = avatarDefaultUser
            }}
          />
          <div className="d-inline-block">
            <h6 className="m-b-0 mt-2">{item?.name}</h6>
          </div>
        </div>
      </td>
      <td>{item?.nim}</td>
      <td>
        {item?.is_done ?
        item?.score ? (
          <div className="d-inline-block">
            <h6 className="m-b-0 mt-2">{item?.score}</h6>
          </div>
          ) : 
          <span className="badge badge-warning">Belum dinilai</span>
          : !item?.is_done && item?.score
          ?
            <span className="badge badge-warning">Draft ({item?.score})</span>
          :
            <span className="badge badge-secondary">Belum selesai</span>
          }
      </td>
      <td>
        <button
          className="btn btn-sm btn-primary mr-2"
          onClick={detailHandler}
        >
          <i className="fas fa-eye mr-1" />
          Detail
        </button>
      </td>
      <ColPrasyarat item={item} />
    </tr>
  )
}
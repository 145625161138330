import React, { useState } from 'react'
import { avatarDefaultUser } from 'util/constant'
import CkeditorReadonly from 'component/ckeditor-readonly';
export default function ForumTopicItem({ 
  item, 
  date, 
  roleType, 
  userId,
  handlePinTopic, 
  handleEditTopic, 
  handleDeleteTopic, 
  handleDetailTopic 
}) {
  const [isHover, setIsHover] = useState(false)

  return (
    <div 
      className={`${isHover ? 'bg-light' : ''} border rounded`} 
      style={{ marginTop: 20, position: 'relative' }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className='card-body pb-0 pr-0 pl-2 pt-2'>
        {item.pinned === 1 ?
          <i style={{ position: 'absolute', top: '7px', left: '7px' }} className='fas fa-thumbtack text-warning' />
          :
          null
        }
        <div className='row'>
          {roleType === 'administrator' || userId === item.login.user.id || roleType === 'adminprofile' ?
            <div
              className="btn-group"
              style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '100' }}
            >
              <button
                className="py-0 px-1"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                title='Options'
                style={{
                  outline: 'none',
                  border: 'none',
                  backgroundColor: 'transparent'
                }}
              >
                <i className="feather icon-more-vertical" />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                {roleType === 'administrator' || roleType === 'instruktur' || roleType === 'adminprofile' ?
                  <a
                    onClick={() => {
                      if (item.pinned === 1) {
                        handlePinTopic(item, 0)
                      }
                      if (item.pinned === 0) {
                        handlePinTopic(item, 1)
                      }
                    }}
                    className="dropdown-item"
                    type="button"
                  >
                    {item.pinned === 1 ? <i className="fas fa-thumbtack mr-1" /> : <i className="fas fa-thumbtack mr-1" />}
                    <span>{item.pinned === 1 ? 'Hapus penanda' : 'Tandai'}</span>
                  </a>
                  :
                  null
                }
                <a
                  onClick={() => handleEditTopic(item)}
                  className="dropdown-item"
                  type="button"
                  data-toggle="modal"
                  data-target="#editForumModal"
                >
                  <i className="feather icon-edit mr-1" />
                  <span>Edit</span>
                </a>
                <a
                  onClick={() => handleDeleteTopic(item.id)}
                  className="dropdown-item"
                  type="button"
                >
                  <i className="feather icon-trash-2 mr-1" />
                  <span>Hapus</span>
                </a>
              </div>
            </div>
            :
            null
          }
          <div className='col'>
            <div className="p-3 text-left w-100">
              <div className='d-flex'>
                <img
                  src={`${item.login.user.linkAvatar}`}
                  alt={item.login.user.name ? item.login.user.name : 'User'}
                  className="img-radius mr-2"
                  style={{ height: '30px', width: '30px' }}
                  onError={(e) => e.currentTarget.src = avatarDefaultUser}
                />
                <div>
                  <h6 className="mb-1 d-flex align-items-center" style={{ fontSize: 12, fontWeight: '500' }}>
                    <i className="fas fa-user mr-1" style={{ fontSize: "8px" }} />
                    <span>{item.login.user.name}</span>
                  </h6>
                  <h6 style={{ fontSize: "10px", fontWeight: "400" }}><i className="feather icon-clock mr-1" />{date}</h6>
                </div>
              </div>
              <button onClick={() => handleDetailTopic(item.id)} className='btn btn-link p-0 w-100 text-left text-primary overflow-hidden'>
                <h6 className='text-primary' style={{ fontSize: 20 }}>{item.title}</h6>
              </button>
              <CkeditorReadonly  data={item.description} />
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
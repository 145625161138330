import { createContext, useContext, useState, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useBaseContext } from 'context/base'

const PreviewRaporContext = createContext()

export function PreviewRaporWrapper({ children }) {
  /* ========================================== Helpers ========================================= */
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()

  /* ========================================= Constants ======================================== */
  const { userId } = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, putRequest } = useBaseContext()

  /* ========================================== States ========================================== */
  const [fullRes, setFullRes] = useState(null)
  const [isPrint, setIsPrint] = useState(false)

  /* ========================================= Functions ======================================== */
  const fetch = async (isGenerate) => {
    return Promise.all([
      getRequest(`grafik-grup-kecil?user_id=${userId}&period_id=${query.get('period_id')}`, true, {}, isGenerate),
      getRequest(`get-rapot-siswa?user_id=${userId}&period_id=${query.get('period_id')}&semester_code=${query.get('semester')}`, true, {}, isGenerate)
    ]).then(([
      grafikGrupKecil,
      getRapotSiswa,
    ]) => {
      setFullRes({ ...getRapotSiswa, discuss: grafikGrupKecil.data })
    })
  }

  const submit = async () => {
    const path = 'submission-report-block-wali/'+fullRes.reportTransactionsperiod[0].id
    const res = await putRequest(path)
    return res
  }

  const approve = async () => {
    const path = 'approve-report-block-wali/'+fullRes.reportTransactionsperiod[0].id
    const res = await putRequest(path)
    return res
  }
  
  const approveOrtu = async () => {
    const path = 'approve-report-block-ortu/'+fullRes.reportTransactionsperiod[0].id
    const res = await putRequest(path)
    return res
  }
  
  const reject = async (note) => {
    const path = 'reject-report-block-wali/'+fullRes.reportTransactionsperiod[0].id
    const body = { note }
    const res = await putRequest(path, body)
    return res
  }

  const sharedProps = {
    rapor: {
      res      : fullRes,
      user     : fullRes?.Users[0],
      badges   : fullRes?.Badge,
      chart    : fullRes?.activitySiswa,
      profile  : fullRes?.Profiles[0],
      discuss  : fullRes?.discuss,
      feedback : fullRes?.feedbackAssignment,
      blocks   : fullRes?.data,
      period   : {
        data        : fullRes?.reportPeriod[0],
        transaction : fullRes?.reportTransactionsperiod[0],
      },
      printEl: useRef(),
      isPrint,
      setIsPrint,
      fetch,
      approve,
      approveOrtu,
      reject,
      submit,
    },
  }

  return (
    <PreviewRaporContext.Provider value={sharedProps}>
      {children}
    </PreviewRaporContext.Provider>
  )
}

export default function usePreviewRapor() {
  return useContext(PreviewRaporContext)
}

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { noImage } from 'util/constant'

const CardKelas = ({ item }) => {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ========================================= Constants ======================================== */
  const image = `${item.linkImg}`
  
  /* ======================================= Local States ======================================= */
  const [progress, setProgress]   = useState(0)
  const [isStarted, setIsStarted] = useState(false)
  const [isDone, setIsDone]       = useState(false)

  /* ========================================= Functions ======================================== */
  function checkProgress() {
    if (item.total_content > 0) {
      setProgress(item.progress_percent || 0)
    }
  }

  function checkClassStatus() {
    if (item.class_activity !== null) {
      setIsStarted(true)
      if (item.class_activity.completed_at !== null) {
        setIsDone(true)
      } else {
        setIsDone(false)
      }
    } else {
      setIsStarted(false)
      setIsDone(false)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    checkProgress()
  }, [item])

  useEffect(() => {
    checkClassStatus()
  }, [progress])

  /* ========================================== Output ========================================== */
  return (
    <div className="col-md-6 col-lg-4 col-xl-2">
      <button
        type="button"
        className="card btn widget-profile-card-2 border pt-0 px-0"
        title={item.title ? item.title : 'Kelas'}
        style={{
          overflow: 'hidden',
          width: '100%',
        }}
        onClick={() => history.push(`/katalog-kelas/${item.id}`)}
      >
        <img
          className="img-fluid bg-light"
          src={item.img ? image : noImage}
          alt="card-style-1"
          style={{
            height: '150px',
            width: '100%',
            objectFit: 'cover',
          }}
          onError={(event) => (event.currentTarget.src = noImage)}
        />
        <div
          className="card-body text-left w-100 pt-3 pb-1 px-3 d-flex flex-column justify-content-between"
          style={{ minHeight: '150px' }}
        >
          <div>
            {isStarted ? (
              <>
                {isDone ? (
                  <span className="badge badge-success mb-2">Selesai</span>
                ) : (
                  <span className="badge badge-info mb-2">
                    Sedang Dikerjakan
                  </span>
                )}
              </>
            ) : (
              <span className="badge badge-secondary mb-2">Belum Mulai</span>
            )}
            <h6 className="mb-1">{item.title ? item.title : '-'}</h6>
            <p className="text-muted mb-0">
              {item.class_profiles[0].category?.title
                ? item.class_profiles[0].category.title
                : '-'}
            </p>
          </div>
          <div className="progress hei-20">
            {progress == 0 ? (
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `100%`, backgroundColor: '#ecf0f5' }}
                aria-valuenow={`100%`}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <span
                  className="text-dark"
                  style={{ fontSize: '12px', fontWeight: 'bold' }}
                >
                  {progress}%
                </span>
              </div>
            ) : (
              <>
                {progress == 100 ? (
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                    aria-valuenow={`${progress}`}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      {progress}%
                    </span>
                  </div>
                ) : (
                  <div
                    className="progress-bar bg-info"
                    role="progressbar"
                    style={{ width: `${progress || 0}%` }}
                    aria-valuenow={`${progress}`}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      {progress && `${progress}%`}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </button>
    </div>
  )
}

export default CardKelas

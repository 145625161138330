export default function FloatingContainerTop({ children }) {
  return (
    <div
      style={{
        position: 'fixed',
        top: '30px',
        left: '0',
        right: '0',
        zIndex: '1028',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
    >
      {children}
    </div>
  )
}

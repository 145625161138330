import React from 'react'
import useContentPlayerContext from 'context/content-player'
import useForumDetail, { ForumDetailWrapper } from 'context/forum-detail'
import { ForumDetailItemWrapper } from 'context/forum-detail-item'
import ForumDetailHeader from './partials/forum-detail-header'
import ForumDetailNoDiscussion from './partials/forum-detail-no-discussion'
import ForumDetailInputButton from './partials/forum-detail-input-button'
import ForumDetailBtnBalasan from './partials/forum-detail-btn-balasan'
import ForumDetailBtnLike from './partials/forum-detail-btn-like'
import ForumDetailAvatar from './partials/forum-detail-avatar'
import ForumDetailUserDesc from './partials/forum-detail-user-desc'
import ForumDetailReplyForm from './partials/forum-detail-reply-form'
import ForumDetailReplies from './partials/forum-detail-replies'

function ForumDetailWithoutContext() {
  /* ====================================== Consume Context ===================================== */
  const { forum } = useContentPlayerContext()
  const { reply } = useForumDetail()

  /* ========================================== Output ========================================== */
  return (
    <Card>
      <ForumDetailHeader />
      <CardBody>
        <Row mb={3}>
          <Col>
            {forum.list?.length ? (
              forum.list.map((item) => (
                  <ForumDetailItemWrapper item={item} key={`forum-detail-wrapper_`+item.id}>
                    <ForumDetailAvatar />
                    <div className="col-10 col-md-11">
                      <ForumDetailUserDesc />
                      <ForumDetailBtnLike />
                      <ForumDetailBtnBalasan />
                    </div>
                    <Col span={1}></Col>
                    <Col span={11}>
                      {reply.shownId == item.id && (
                        <Row style={{ marginTop: 15 }} >
                          <Col span={1}></Col>
                          <Col span={11}>
                            <ForumDetailReplyForm />
                          </Col>
                          <ForumDetailReplies />
                        </Row>
                      )}
                    </Col>
                  </ForumDetailItemWrapper>
                )
              )
            ) : (
              <ForumDetailNoDiscussion />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <ForumDetailInputButton />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

const Row = ({ children, mb = 0 })     => <div className = {`row mb-${mb}`}>{children}</div>
const Col = ({ children, span = 12 })  => <div className = {`col-md-${span}`}>{children}</div>

const Card     = ({ children }) => <div className = "card">{children}</div>
const CardBody = ({ children }) => <div className = "card-body">{children}</div>

const ForumDetail = () => (
  <ForumDetailWrapper>
    <ForumDetailWithoutContext />
  </ForumDetailWrapper>
)

export default ForumDetail

import React from 'react'
import useChatContext from 'context/chat'
import ContactItem from './contact-item'

const ContactList = () => {
  const { contact } = useChatContext()

  return (
    <ul
      className="list-unstyled mb-0 bg-light"
      style={{
        height    : '100%',
        overflowY : 'scroll',
      }}
    >
      {contact.list.length !== 0
        ? contact.list.map((contact) => (
            <ContactItem
              key     = {contact.id}
              contact = {contact}
            />
          ))
        : null}
    </ul>
  )
}

export default ContactList

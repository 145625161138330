import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'
import BaseRainbowButton from 'component/button/BaseRainbowButton'
import { onClickV2LMSButtonHandler } from 'util/auth'

const GroupSiswa = () => {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [groupSiswaList, setGroupSiswaList] = useState([])
  const [groupLength, setGroupLength]       = useState(0)
  const [limit, setLimit]                   = useState(10)
  const [offset, setOffset]                 = useState(0)
  const [search, setSearch]                 = useState('')

  /* ========================================= Functions ======================================== */
  async function getGroupSiswa() {
    const response = await getRequest(
      `group-siswa?limit=${limit}&offset=${offset}&search=${search}`,
    )
    if (response) {
      setGroupSiswaList(response.data)
      setGroupLength(response.totalRecords)
    } else {
      console.error('ERROR', response)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      getGroupSiswa()
    }

    return () => (isSubscribed = false)
  }, [limit, offset, search])

  /* ======================================== Components ======================================== */
  const RenderItem = ({ item }) => {
    return (
      <tr>
        <td>{item.group.group}</td>
        <td>
          <button
            onClick={() =>
              history.push(`/siswa-group/${item.group.id}/list-siswa`, {
                groupData: item.group,
              })
            }
            type="button"
            className="btn btn-sm btn-primary mr-2"
          >
            <i className="fas fa-user-friends mr-1" />
            Siswa
          </button>
          <button
            onClick={() =>
              history.push(`/siswa-group/${item.group.id}/list-kelas`, {
                groupData: item.group,
              })
            }
            type="button"
            className="btn btn-sm btn-info"
          >
            <i className="fas fa-swatchbook mr-1" />
            Sub Mata Pelajaran
          </button>
        </td>
      </tr>
    )
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Kelas Siswa" />
        
        {/* MOBILE */}
        <div className='d-flex d-sm-none justify-content-center mb-2' style={{ right: 23, top: -100, zIndex: 10 }}>
          <BaseRainbowButton title='Versi 2 Sudah Hadir 🎉!!!' onClick={onClickV2LMSButtonHandler} />
        </div>

        {/* DEKSTOP OR TABLET */}
        <div className='d-md-flex d-none justify-content-end align-items-center mb-2'>
          <BaseRainbowButton title='Versi 2 Sudah Hadir 🎉!!!' onClick={onClickV2LMSButtonHandler} />
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-body">
                <div className="row">
                  <div className="col dt-responsive table-responsive">
                    <DataTable
                      className={'table table-striped table-bordered nowrap'}
                      data={groupSiswaList}
                      totalRows={groupLength}
                      show={[10, 20, 30, 40, 50]}
                      onChangeSearch={(val) => {
                        setSearch(val)
                      }}
                      onChangeLimit={(val) => {
                        setLimit(val)
                      }}
                      onChangePage={(val) => {
                        setOffset(val)
                      }}
                      column={['Kelas', 'Actions']}
                      renderItem={(item) => (
                        <RenderItem
                          item={item}
                          key={item.id}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </Container>
    </TemplateAdmin>
  )
}

export default GroupSiswa

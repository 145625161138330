import React, { useEffect, useState } from 'react'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import LoadingSpinner from 'component/loading'
import NotFound from 'component/not-found'
import ListViewKelas from 'component/kelas/list-kelas'
import CardKelas from 'component/kelas/card-kelas'

const KatalogKelasSiswa = () => {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [kelasList, setKelasList]     = useState([])
  const [kelasLength, setKelasLength] = useState(0)
  const [isLoading, setIsLoading]     = useState(false)

  const [listView, setListView] = useState(false)
  const [gridView, setGridView] = useState(true)

  const [selectedSemester, setSelectedSemester] = useState('')
  const [academicSemester, setAcademicSemester] = useState([])

  const [selectedBlock, setSelectedBlock] = useState('')
  const [academicBlock, setAcademicBlock] = useState([])

  /* ========================================= Functions ======================================== */
  async function getKatalogKelas() {
    setIsLoading(true)
    const response = await getRequest(
      'katalog-kelas?semester_code=' +
        selectedSemester +
        '&period_id=' +
        selectedBlock,
    )
    setIsLoading(false)
    if (response) {
      setKelasList(response.data)
      setKelasLength(response.data.length)
    } else {
      console.error('ERROR', response)
    }
  }

  const getSemesterAll = async () => {
    const response = await getRequest('semester')
    if (response) {
      let options = []
      response.data.map((item) => {
        options.push({
          value: item.code,
          label: `${item.tahun_mulai}/${item.tahun_selesai} - ${
            item.title_render
          }${item.status ? ' (Aktif)' : ''}`,
        })
        if (item.status) setSelectedSemester(item.code)
      })
      setAcademicSemester(options)
    }
  }

  async function getBlockAll() {
    const response = await getRequest(
      `reportPeriod?semester_code=${selectedSemester}`,
    )
    if (response) {
      let options = []
      response.data
        ?.filter((d) => d.status)
        .map((item) => {
          options.push({
            value: item.id,
            label: `${item.name_period}`,
          })
          setSelectedBlock(item.id)
        })
      setAcademicBlock(options)
    }
  }

  function handleListView() {
    setListView(true)
    setGridView(false)
  }

  function handleGridView() {
    setListView(false)
    setGridView(true)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getKatalogKelas()
    return () => {
      setKelasList([])
      setKelasLength(0)
    }
  }, [selectedSemester, selectedBlock])

  useEffect(() => {
    getSemesterAll()
  }, [])

  useEffect(() => {
    getBlockAll()
  }, [selectedSemester])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Katalog Mata Pelajaran" />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header">
                <div className="row">
                  <div className="col-6 col-md-10">
                    <h5>Katalog Mata Pelajaran</h5>
                  </div>
                  <div className="col-6 col-md-2 d-flex justify-content-end">
                    <button
                      onClick={handleGridView}
                      type="button"
                      className="btn btn-icon btn-primary mr-1"
                      title="Tampilan Grid"
                    >
                      <i className="feather icon-grid" />
                    </button>
                    <button
                      onClick={handleListView}
                      type="button"
                      className="btn btn-icon btn-primary"
                      title="Tampilan List"
                    >
                      <i className="fas fa-list-ul" />
                    </button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <select
                      onChange={(val) =>
                        setSelectedSemester(val.target.value)
                      }
                      id="inputState"
                      className={'form-control col-12 col-md-12'}
                    >
                      <option
                        key={'1'}
                        value=""
                      >
                        - Pilih Semester -
                      </option>
                      {academicSemester.map((val, i) => {
                        return (
                          <option
                            key={val.value}
                            selected={selectedSemester == val.value}
                            value={val.value}
                          >
                            {val.label}
                          </option>
                        )
                      })}
                    </select>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <select
                      onChange={(val) => setSelectedBlock(val.target.value)}
                      id="inputState1"
                      className={'form-control col-12 col-md-12'}
                    >
                      <option
                        key={'1'}
                        value=""
                      >
                        - Pilih Block -
                      </option>
                      {academicBlock.map((val, i) => {
                        return (
                          <option
                            key={val.value}
                            selected={selectedBlock == val.value}
                            value={val.value}
                          >
                            {val.label}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {/* List view */}
                {listView ? (
                  <div className="row">
                    {isLoading ? (
                      <div className="col">
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <>
                        {kelasList.length !== 0 ? (
                          <ListViewKelas
                            listKelas={kelasList.map((kl) => kl.class)}
                            kelasLength={kelasLength}
                          />
                        ) : null}
                        {kelasList.length === 0 ? (
                          <NotFound text="Daftar kelas tidak ditemukan." />
                        ) : null}
                      </>
                    )}
                  </div>
                ) : null}

                {/* Grid view */}
                {gridView ? (
                  <div className="row">
                    {isLoading ? (
                      <div className="col">
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <>
                        {kelasList.length !== 0 ? (
                          kelasList.map((kelas) => (
                            <CardKelas
                              key={kelas.class.id}
                              item={kelas.class}
                            />
                          ))
                        ) : (
                          <NotFound text="Daftar kelas tidak ditemukan." />
                        )}
                      </>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

export default KatalogKelasSiswa

const validExtension = {
  audio: ['mp3'],
  video: ['mp4'],
  document: ['pdf', 'ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx'],
}

const validFileSize = {
  document: 5,
}

export const isGDrive = url => {
  if (url) {
    return url?.includes('drive.google.com') || url?.includes('docs.google.com')
  } else {
    return false
  }
}
export const isSoundcloud = url => {
  if (url) {
    return url?.includes('soundcloud.com')
  } else {
    return false
  }
}

/**
 * Is File Type Invalid
 * 
 * @param {{
*  file: File,
*  type: FormInputType,
*  customValidExtension?: Array<string>,
* }} params
*/
export function isFileTypeInvalid({ file, type, customValidExtension }) {
  if (file) {
    const fileExtension = file?.name?.split('.')?.pop()

    let listValidExtension

    if (customValidExtension) {
      listValidExtension = customValidExtension
    } else {
      listValidExtension = validExtension[type]
    }
  
    if (!listValidExtension?.includes(fileExtension)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

/**
 * Is File Size Invalid
 * 
 * @param {{
 *  file: File,
 *  type: FormInputType,
 *  customSizeInMB?: number,
 * }} params
 */
export function isFileSizeInvalid({ file, type, customSizeInMB }) {
  if (file) {
    let maxFileSizeInMB
    let maxFileSizeInBytes

    if (customSizeInMB) {
      maxFileSizeInMB = customSizeInMB
    } else {
      maxFileSizeInMB = validFileSize[type]
    }

    maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024
  
    if (file?.size > maxFileSizeInBytes) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
import React, { useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import BackButton from 'component/button/Back'
import SubmitButton from 'component/button/Submit'
import { FormActiveStatus, FormInputUrutan } from 'component/content-form'

export default function EditSection() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const location = useLocation()

  /* ====================================== Consume Context ===================================== */
  const { putRequest } = useBaseContext()
  
  /* ========================================= Constants ======================================== */
  const { title, id, urutan, active } = location.state.dataSection
  const kelasId = location.state.kelasId
  const links = [
    { url: '/kelas', name: 'Sub Mata Pelajaran' },
    { url: `/kelas/${location.state.kelasId}`, name: 'Overview' },
  ]

  /* ======================================= Local States ======================================= */
    // INPUT DATA
  const [namaSection, setNamaSection]     = useState(title ? title : '')
  const [activeStatus, setActive]         = useState(active ? 1 : 0)
  const [urutanSection, setUrutanSection] = useState(urutan ? urutan : '')

  // FORM VALIDATION
  const [invalidName, setInvalidName]     = useState(false)
  const [invalidUrutan, setInvalidUrutan] = useState(false)

  /* =========================================== Refs =========================================== */
  const refInputName   = useRef()
  const refInputUrutan = useRef()

  /* ========================================= Functions ======================================== */
  function handleNamaSectionChange(event) {
    setNamaSection(event.target.value)
  }

  function handleUrutanChange(event) {
    setUrutanSection(event.target.value)
  }

  function handleChangeActive() {
    if (activeStatus === 1) {
      setActive(0)
    }
    if (activeStatus === 0) {
      setActive(1)
    }
  }

  function handleValidation() {
    const newUrutan = urutanSection ? Math.abs(parseInt(urutanSection)) : 0

    if (namaSection && urutanSection) {
      setInvalidName(false)
      setInvalidUrutan(false)
      handleSubmitForm(namaSection, kelasId, newUrutan, id, activeStatus)
    } else {
      if (!urutanSection) {
        setInvalidUrutan(true)
        refInputUrutan.current.focus()
      } else {
        setInvalidUrutan(false)
      }

      if (!namaSection) {
        setInvalidName(true)
        refInputName.current.focus()
      } else {
        setInvalidName(false)
      }

      window.notification('Data belum lengkap', 'Mohon lengkapi data')
    }
  }

  async function handleSubmitForm(title, class_id, urutan, id, active) {
    let form = new FormData()
    form.append('id', id)
    form.append('title', title)
    form.append('class_id', class_id)
    form.append('urutan', urutan)
    form.append('active', active)

    const response = await putRequest('sections', form)
    if (response) {
      window.notification('Section', 'berhasil diubah', 'success')
      history.goBack()
    } else {
      console.error('ERROR', response)
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Edit Section"
          linkName="Section"
          links={links}
        />

        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <h5>
                  <i className="fas fa-hashtag mr-1" />
                  Informasi Section
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <form>
                      {/* Form input name */}
                      <div className="form-group mb-5 row">
                        <label
                          className="col-sm-3 col-form-label"
                          htmlFor="namaSection"
                        >
                          <span className="mr-1">Nama Section</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            ref={refInputName}
                            type="text"
                            value={namaSection}
                            onChange={(event) => handleNamaSectionChange(event)}
                            className={`${
                              invalidName ? 'is-invalid' : ''
                            } form-control`}
                            id="namaSection"
                            placeholder="Nama section"
                          />
                          {invalidName ? (
                            <label className="col small form-text text-danger">
                              Nama tidak boleh kosong.
                            </label>
                          ) : null}
                        </div>
                      </div>

                      {/* Form input urutan */}
                      <FormInputUrutan
                        urutan={urutanSection}
                        handleChangeUrutan={handleUrutanChange}
                        refInputUrutan={refInputUrutan}
                        isUrutanInvalid={invalidUrutan}
                      />

                      {/* Form select status */}
                      <FormActiveStatus
                        existingStatus={activeStatus}
                        handleChangeStatus={handleChangeActive}
                      />

                      <div className="form-group">
                        <BackButton />
                        <SubmitButton
                          onClick={() => handleValidation()}
                          text="Simpan"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

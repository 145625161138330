/**
 * mapel in english = subject
 * 
 * 
 * [
      {
        id: 6, 
        category_id: id || null, <-- kalo null artinya group mapel, kalo ada berarti dia mapel bukan group mapel
        name: 'Bahasa Indonesia' 
      },
      {
        id: 1, 
        name: 'Ilmu Pengetahuan Alam',
        children: [
          { id: 2, name: 'Fisika' },
          { id: 5, name: 'Kimia' },
        ],
      },
    ]
 */

import { useCallback, useEffect, useState } from "react";
import { useBaseContext } from "context/base";

import Badge from "component/badge";
import BaseButton from "component/button/BaseButton";
import Swal from "sweetalert2";

export default function useOrderSubjectReportDNDList({ headerHook }) {
  const [items, setItems] = useState([])
  const [isOrderOnEdit, setIsOrderOnEdit] = useState(false)

  /* --------------------------------- CONTEXT -------------------------------- */
  const { getRequest, putRequest, deleteRequest } = useBaseContext()

  /* --------------------------------- HANDLER -------------------------------- */
  const swalLoaderHandler = (text = 'Sedang diproses...') => {
    Swal.fire({
      title: text,
      html: `<div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
              </div>`,
      allowOutsideClick: false,
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
    })
  }

  const swalSuccessHandler = (text = 'Berhasil Diproses', callback = (result) => {}) => {
    Swal.fire({
      title: text,
      icon: 'success',
      allowOutsideClick: false,
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#2778c4',
    }).then(result => {
      callback(result)
    })
  }

  const swalFailHandler = (title = 'Proses Data', text = 'Gagal Memproses Data') => {
    Swal.fire(
      title,
      text,
      'error',
    )
  }

  const onConfirmChangeHandler = ({ dragItem, destinationParent }) => {
    if (destinationParent) {
      // Prevent subject group to be children
      if ((!dragItem?.category_id && destinationParent?.category_id) || (!dragItem?.category_id && !destinationParent?.category_id)) {
        return false;
      }
  
      // Prevent subject have an children
      if (dragItem?.category_id && destinationParent?.category_id) {
        return false;
      }
    }

    return true
  }

  const onChangeHandler = ({ items: newItems }) => {
    setItems(newItems)
    if (!isOrderOnEdit) {
      setIsOrderOnEdit(true)
    }
  }

  const onEditSubjectCategoryMapelHandler = async (item) => {
    const { value: newCategorySubjectName, isConfirmed: isConfirmedEdit } = await Swal.fire({
      title: "Edit Kategori Mapel",
      input: "text",
      inputLabel: "Nama Kategori Mapel",
      inputPlaceholder: "Masukkan Nama Kategori Mapel",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value.length) {
            resolve();
          } else {
            resolve("Nama Kategori Mapel tidak boleh kosong");
          }
        });
      },
      inputValue: item.name,
      confirmButtonText: 'Simpan',
      confirmButtonColor: '#9ccc65'
    });

    if (isConfirmedEdit) {
      Swal.fire({
        title: 'Loading',
        text: 'Harap Tunggu...',
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        },
        willClose: () => {
          Swal.hideLoading()
        }
      })

      const response = await putRequest(`edit-kategori-mapel-rapor/${item?.id}`, {
        name: newCategorySubjectName
      })

      if (response) {
        Swal.close()
        swalSuccessHandler('Berhasil Mengedit Kategori Mapel', () => {
          getListHandler(headerHook?.customState?.reactSelectState)
        })
      } else {
        Swal.close()
      }
    }
  }

  const onRemoveSubjectCategoryMapelHandler = async (item) => {
    const { isConfirmed } = await Swal.fire({
      title: `Apakah Anda Yakin untuk menghapus ${item.name}`,
      showCancelButton: true,
      confirmButtonText: 'Ya! Yakin',
      cancelButtonText: "Tidak",
    });

    if (isConfirmed) {
      Swal.fire({
        title: 'Loading',
        text: 'Harap Tunggu...',
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        },
        willClose: () => {
          Swal.hideLoading()
        }
      })

      const response = await deleteRequest(`delete-kategori-mapel-rapor/${item?.id}`)

      if (response) {
        Swal.close()
        swalSuccessHandler('Berhasil Menghapus Kategori Mapel', () => {
          getListHandler(headerHook?.customState?.reactSelectState)
        })
      } else {
        Swal.close()
      }
    }
  }

  const onSaveDNDListHandler = async () => {
    Swal.fire({
      title: 'Loading',
      text: 'Harap Tunggu...',
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {
        Swal.hideLoading()
      }
    })

    const response = await putRequest(`save-report-order-mapel`, {
      data: items
    })

    if (response) {
      Swal.close()
      setIsOrderOnEdit(false)
      swalSuccessHandler('Berhasil Menyimpan Urutan Kategori Mapel', () => {
        getListHandler(headerHook?.customState?.reactSelectState)
      })
    } else {
      Swal.close()
    }
  }

  /**
   * Recursive DND Manipulated Data
   * 
   * @param {Array} items 
   */
  const recursiveDNDManipulatedData = (items, indexParent) => {
    if (Array.isArray(items) && items.length) {
      return items.map((item, index) => {
        const data = {
          ...item,
          idIndex: indexParent > -1 ? `${index}-${indexParent}` : index,
        }

        if (Array.isArray(data?.children) && data?.children?.length) {
          data.children = recursiveDNDManipulatedData(item?.children, index)
        }

        return data
      })
    }
  }

  const getListOptionHandler = async (data) => {
    try {
      Swal.fire({
        title: 'Loading',
        text: 'Harap Tunggu...',
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        },
        willClose: () => {
          Swal.hideLoading()
        }
      })
      let response

      if (data?.selectedType === 'all') {
        response = await getRequest(`list-report-order-mapel?ta_tahun_code=${data?.selectedAcademicYear?.value}`)
      } else {
        const isQueryClassId = data?.selectedClass ? `&group_id=${data?.selectedClass?.value}` : ''
        const isQuerySemesterId = data?.selectedSemester ? `&ta_semester_code=${data?.selectedSemester?.value}` : ''
        response = await getRequest(`list-report-order-mapel?ta_tahun_code=${data?.selectedAcademicYear?.value}${isQueryClassId}${isQuerySemesterId}`)
      }

      if (response) {
        const DNDManipulatedData = recursiveDNDManipulatedData(response?.data)

        setItems(DNDManipulatedData)

        Swal.close()
      } else {
        Swal.close()
      }
    } catch (error) {
    Swal.close() 
    }
  }

  const getListHandler = async ({ selectedType, selectedAcademicYear, selectedClass, selectedSemester }) => {
    const selectedTypeVar = selectedType || headerHook?.customState?.reactSelectState?.selectedType
    const selectedAcademicYearVar = selectedAcademicYear || headerHook?.customState?.reactSelectState?.selectedAcademicYear
    const selectedClassVar = selectedClass || headerHook?.customState?.reactSelectState?.selectedClass
    const selectedSemesterVar = selectedSemester || headerHook?.customState?.reactSelectState?.selectedSemester

    const data = {
      selectedType: selectedTypeVar,
      selectedAcademicYear: selectedAcademicYearVar,
      selectedSemester: selectedSemesterVar,
      selectedClass: selectedClassVar,
    }

    if (selectedTypeVar) {
      if (selectedTypeVar?.value === 'class' && selectedAcademicYearVar && selectedClassVar && selectedSemesterVar) {
        getListOptionHandler(data)
      } else if (selectedTypeVar?.value === 'all' && selectedAcademicYearVar) {
        getListOptionHandler(data)
      }
    }
  }

  /* --------------------------------- RENDER --------------------------------- */
  const renderItem = useCallback(({ item, collapseIcon }) => {
    return (
      <div className="d-flex justify-content-between border-bottom" style={{ cursor: 'pointer' }}>
        <div style={{ flex: 1 }}>
          {collapseIcon}
          <span>{ item?.name } { !item?.category_id ? <Badge className="ml-3">Kategori</Badge> : null}</span>
        </div>
        {!item?.category_id
        ?
        <div style={{ flex: 1 }} className="d-flex justify-content-end">
          <div className="ml-2">
            <BaseButton title={`Edit group Informatika`} props={{ className: 'btn btn-sm' }} onClick={onEditSubjectCategoryMapelHandler.bind(this, item)}>
              <i className="feather icon-edit" style={{ fontSize: '15px'}}></i>
            </BaseButton>
          </div>
          <div className="ml-2">
            <BaseButton title={`Hapus group Informatika`} props={{ className: 'btn btn-sm' }} variant="danger" onClick={onRemoveSubjectCategoryMapelHandler.bind(this, item)}>
              <i className="feather icon-trash" style={{ fontSize: '15px'}}></i>
            </BaseButton>
          </div>
        </div>
        : null}
      </div>
    )
  }, [items])

  useEffect(() => {
    getListHandler({})
  }, [
    headerHook?.customState?.reactSelectState?.selectedType?.value,
    headerHook?.customState?.reactSelectState?.selectedAcademicYear?.value,
    headerHook?.customState?.reactSelectState?.selectedClass?.value,
    headerHook?.customState?.reactSelectState?.selectedSemester?.value,
  ])

  useEffect(() => {
    const preventUserReloadBrowser = (event) => {
      event.preventDefault();
    };

    if (isOrderOnEdit) {
      window.addEventListener('beforeunload', preventUserReloadBrowser);
      return () => {
        window.removeEventListener('beforeunload', preventUserReloadBrowser);
      };
    }
  }, [isOrderOnEdit])

  return {
    data: {
      items,
      isOrderOnEdit,
    },
    handler: {
      onConfirmChangeHandler,
      onChangeHandler,
      onSaveDNDListHandler,
      onEditSubjectCategoryMapelHandler,
      getListHandler
    },
    render: {
      item: renderItem,
    },
  }
}
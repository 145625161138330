import React from 'react'
import Section from './section'
import SectionSequential from './section-sequential'
import SectionDisabled from './section-disabled'
import EmptySection from './empty-section'

export default function Materi({ classData, sectionList, allContents }) {
  return (
    <div className="col-12 row mb-4 mx-0 px-0">
      <div className="col">
        <h5>Daftar Materi :</h5>
        <div
          className="accordion border rounded"
          id="konten-accordion"
        >
          {classData?.classStatus?.status === 'progress' ||
          classData?.classStatus?.status === 'done' ? (
            <>
              {classData.data.show_units === 'any_order' ||
              classData.data.show_units === null ? (
                <>
                  {sectionList.length !== 0 ? (
                    sectionList.map((section, index) => (
                      <Section
                        key={section.id}
                        item={section}
                        index={index}
                        allContents={allContents}
                      />
                    ))
                  ) : (
                    <EmptySection />
                  )}
                </>
              ) : null}
              {classData.data.show_units === 'sequential_order' ? (
                <>
                  {sectionList.length !== 0 ? (
                    sectionList.map((section, index) => (
                      <SectionSequential
                        key={section.id}
                        item={section}
                        index={index}
                        allContents={allContents}
                      />
                    ))
                  ) : (
                    <EmptySection />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <>
              {sectionList.length !== 0 ? (
                sectionList.map((section, index) => (
                  <SectionDisabled
                    key={section.id}
                    item={section}
                    index={index}
                  />
                ))
              ) : (
                <EmptySection />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

import React from 'react'

export default function MessageListWrapper({ children }) {
  return (
    <div
      className       = "tab-pane fade active show"
      id              = "pills-pesan"
      role            = "tabpanel"
      aria-labelledby = "pills-pesan-tab"
      style           = {{ height: '100%' }}
    >
      {children}
    </div>
  )
}

import React, { useEffect } from 'react'

export default function HtmlContent({ html, index }) {
  if(html){
    let btn = `<button id="btn" class="btn btn-primary has-ripple mt-2" onclick="
    function hi(a=${index}){
      let img = document.getElementById('img-'+a)

      img.style.objectFit = 'contain';
      if(img.className.includes('rotate-0')){
        if(img.height>img.width){
          img.style.height = img.width+'px'
          img.style.width = img.width+'px'
        }else{
          img.style.height = img.height+'px';
          img.style.width = img.height+'px';
        }
        img.className = 'rotate-90';
      }else if(img.className.includes('rotate-90')){
        img.style.width = '100%'
        img.style.height = 'auto';
        img.className = 'rotate-180';
      }else if(img.className.includes('rotate-180')){
        if(img.height>img.width){
          img.style.height = img.width+'px'
          img.style.width = img.width+'px'
        }else{
          img.style.height = img.height+'px';
          img.style.width = img.height+'px';
        }
        img.className = 'rotate-270';
      }else if(img.className.includes('rotate-270')){
        img.style.width = '100%'
        img.style.height = 'auto';
        img.className = 'rotate-0';
      }else {
        if(img.height>img.width){
          img.style.height = img.width+'px'
          img.style.width = img.width+'px'
        }else{
          img.style.height = img.height+'px';
          img.style.width = img.height+'px';
        }
        img.className = 'rotate-90'
      }
    };hi()">Putar Gambar  <i class="fas fa-redo"></i></button>`
    html = html.replace('<img', `${btn} <img id="img-${index}"`)
    html = html.replace('<oembed url=','<iframe allowfullscreen frameborder="0" src=')
    html = html.replace('</oembed>','</iframe>')
    html = html.replace('https://www.youtube.com/watch?v=','https://www.youtube.com/embed/')
    html = html.replace('https://youtu.be/','https://www.youtube.com/embed/')
  }
  // useEffect(()=>{
  //   let figure=document.getElementsByTagName('figure')
  //   for(let a=0;a<figure.length;a++){
  //     figure[a].innerHTML += `<button id="btn" class="btn btn-primary has-ripple mt-2" onclick="
  //     function hi(a=${a}){
  //       let figure=document.getElementsByTagName('figure')
  //       let img = figure[a].getElementsByTagName('img')[0] 
  //       img.style.objectFit = 'contain';
  //       if(img.className.includes('rotate-0')){
  //         if(img.height>img.width){
  //           img.style.height = img.width+'px'
  //           img.style.width = img.width+'px'
  //         }else{
  //           img.style.height = img.height+'px';
  //           img.style.width = img.height+'px';
  //         }
  //         img.className = 'rotate-90';
  //       }else if(img.className.includes('rotate-90')){
  //         img.style.width = '100%'
  //         img.style.height = 'auto';
  //         img.className = 'rotate-180';
  //       }else if(img.className.includes('rotate-180')){
  //         if(img.height>img.width){
  //           img.style.height = img.width+'px'
  //           img.style.width = img.width+'px'
  //         }else{
  //           img.style.height = img.height+'px';
  //           img.style.width = img.height+'px';
  //         }
  //         img.className = 'rotate-270';
  //       }else if(img.className.includes('rotate-270')){
  //         img.style.width = '100%'
  //         img.style.height = 'auto';
  //         img.className = 'rotate-0';
  //       }else {
  //         if(img.height>img.width){
  //           img.style.height = img.width+'px'
  //           img.style.width = img.width+'px'
  //         }else{
  //           img.style.height = img.height+'px';
  //           img.style.width = img.height+'px';
  //         }
  //         img.className = 'rotate-90'
  //       }
  //     };hi()">Putar Gambar  <i class="fas fa-redo"></i></button>`
  //   }
  // },[])
  return (
    <div className='html-content' dangerouslySetInnerHTML={{ __html: html }} />
  )
}
import React, { useState, useEffect } from 'react'
import YouTube from 'react-youtube'
import moment from 'moment'

import { useBaseContext } from 'context/base'
import useMainContent from 'context/main-content'
import HtmlContent from 'component/html-content'
import CkeditorReadonly from 'component/ckeditor-readonly'
import DataTable from 'component/data-table'
import LoadingSpinner from 'component/loading'
import { RatingFull, RatingStars, InputRating } from 'component/rating'
import CardMeta from 'component/card-meta'
import AssignmentSuccess from './assignment-success'
import AssignmentPending from './assignment-pending'
import { avatarDefaultUser } from 'util/constant'
import { detectionDoc } from 'util/global'

export const AssignmentDone = ({ content, isAssignmentPending }) => {
  /* ====================================== Consume Context ===================================== */
  const { comment }    = useMainContent()
  const { getRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const feedbackCompleted = content.feed?.feed_temps.filter(
    (item) => item.content_id == content.id,
  )

  /* ========================================== States ========================================== */
  const [assignmentUser, setAssignmentUser] = useState(null)
  const [assignment, setAssignment] = useState(null)
  const [myScore, setMyScore] = useState(null)
  const [assignmentUsers, setAssignmentUsers] = useState([])
  const [assignmentUsersRows, setAssignmentUsersRows] = useState(0)
  const [offset, setOffset] = useState(0)
  const [keyword, setKeyword] = useState('')
  const [user, setUser] = useState({ name: '', email: '' })
  const [showMyAssign, setShowMyAssign] = useState(false)
  const [showMyScore, setShowMyScore] = useState(false)
  const [showAllAssign, setShowAllAssign] = useState(false)
  const [loadingMyAssign, setLoadingMyAssign] = useState(false)
  const [loadingMyScore, setLoadingMyScore] = useState(false)
  const [loadingAllAssign, setLoadingAllAssign] = useState(false)

  /* ========================================= Functions ======================================== */
  async function handleShowMyAssign() {
    if (!showMyAssign) {
      setOffset(0)
      setKeyword('')
      setShowMyScore(false)
      setShowAllAssign(false)
      setLoadingMyScore(false)
      setLoadingAllAssign(false)
      setLoadingMyAssign(true)
      await getAssignmentUser()
      setLoadingMyAssign(false)
    }
    setShowMyAssign(true)
  }

  async function handleShowMyScore() {
    if (!showMyScore) {
      setOffset(0)
      setKeyword('')
      setShowMyAssign(false)
      setShowAllAssign(false)
      setLoadingMyAssign(false)
      setLoadingAllAssign(false)
      setLoadingMyScore(true)
      await getMyScore()
      setLoadingMyScore(false)
    }
    setShowMyScore(true)
  }

  async function handleShowAllAssign() {
    if (!showAllAssign) {
      setOffset(0)
      setKeyword('')
      setShowMyAssign(false)
      setShowMyScore(false)
      setLoadingMyAssign(false)
      setLoadingMyScore(false)
      setLoadingAllAssign(true)
      await getAssignmentAll()
      setLoadingAllAssign(false)
    }
    setShowAllAssign(true)
  }

  async function getAssignmentUser() {
    const response = await getRequest(
      `assignment-siswa-answer?content_id=${content.id}&assignment_id=${content.contents_url}`,
    )
    if (response) {
      setAssignmentUser(response.data.assigment_users[0])
      setUser({ name: response.data.name, email: response.data.email })
      setAssignment(response.assignment)
      comment.setCount(response.validFeedback)
    }
  }

  async function getAssignmentAll() {
    const response = await getRequest(
      `assigmentusers-bycontent?id=${content.id}&offset=${offset}&keyword=${keyword}`,
    )
    if (response) {
      setAssignmentUsers(response.data)
      setAssignmentUsersRows(response.totalRows)
    }
  }

  async function getMyScore() {
    const response = await getRequest(
      `assignment-myscore?content_id=${content.id}`,
    )
    if (response) {
      if (response.data) {
        setMyScore(response.data)
      } else {
        setMyScore(null)
      }
    }
  }

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) handleShowMyAssign()

    return () => {
      isSubscribed = false
    }
  }, [])

  useEffect(() => {
    if (showAllAssign) {
      getAssignmentAll()
    }
  }, [offset, keyword])

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <ul
            className="nav nav-tabs mb-3"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item">
              <a
                onClick={handleShowMyAssign}
                className="nav-link has-ripple active"
                id="pills-tugas-tab"
                data-toggle="pill"
                href="#pills-tugas"
                role="tab"
                aria-controls="pills-tugas"
                aria-selected="true"
              >
                Tugas
              </a>
            </li>
            {content.komentar_tugas ? (
              <li className="nav-item">
                <a
                  onClick={handleShowAllAssign}
                  className="nav-link has-ripple"
                  id="pills-tugasall-tab"
                  data-toggle="pill"
                  href="#pills-tugasall"
                  role="tab"
                  aria-controls="pills-tugasall"
                  aria-selected="false"
                >
                  Tugas Siswa Lain
                </a>
              </li>
            ) : null}
            <li className="nav-item">
              <a
                onClick={handleShowMyScore}
                className="nav-link has-ripple"
                id="pills-nilai-tab"
                data-toggle="pill"
                href="#pills-nilai"
                role="tab"
                aria-controls="pills-nilai"
                aria-selected="false"
              >
                Nilai Saya
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        {loadingMyAssign ? (
          <div className="col-12 pt-4">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {showMyAssign && (
              <div className="col-12 mb-3">
                <div className="row">
                  {isAssignmentPending ? (
                    <AssignmentPending />
                  ) : (
                    <AssignmentSuccess />
                  )}
                  <div className="col-12">
                    {content.komentar_tugas ? (
                      <>
                        {content.komentar_wajib ? (
                          <>
                            <div
                              className="mt-2 border rounded alert alert-warning w-100"
                              role="alert"
                            >
                              <strong>
                                <i className="fas fa-info-circle mr-1" />
                                Anda wajib memberikan {
                                  content.komentar_wajib
                                }{' '}
                                feedback terhadap tugas siswa lain.
                              </strong>
                            </div>
                            {comment.count >= content.komentar_wajib ? (
                              <div
                                className="mt-2 border rounded alert alert-success w-100"
                                role="alert"
                              >
                                <strong>
                                  <i className="fas fa-check-circle mr-1" />
                                  Anda sudah memberikan {comment.count} feedback
                                  terhadap tugas siswa lain.
                                </strong>
                              </div>
                            ) : null}
                            {comment.count < content.komentar_wajib ? (
                              <>
                                {comment.count === 0 ? (
                                  <div
                                    className="mt-2 border rounded alert alert-danger w-100"
                                    role="alert"
                                  >
                                    <strong>
                                      <i className="fas fa-info-circle mr-1" />
                                      Anda belum memberikan feedback terhadap
                                      tugas siswa lain..
                                    </strong>
                                  </div>
                                ) : (
                                  <div
                                    className="mt-2 border rounded alert alert-info w-100"
                                    role="alert"
                                  >
                                    <strong>
                                      <i className="fas fa-info-circle mr-1" />
                                      Anda sudah memberikan {comment.count}{' '}
                                      feedback terhadap tugas siswa lain..
                                    </strong>
                                  </div>
                                )}
                              </>
                            ) : null}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                  <div className="col-12">
                    {content.feedback ? (
                      <>
                        {feedbackCompleted?.length > 0 ? (
                          <div
                            className="mt-2 border rounded alert alert-success w-100"
                            role="alert"
                          >
                            <strong>
                              <i className="fas fa-check-circle mr-1" />
                              Anda sudah memberikan feedback terhadap materi.
                            </strong>
                          </div>
                        ) : (
                          <div
                            className="mt-2 border rounded alert alert-danger w-100"
                            role="alert"
                          >
                            <strong>
                              <i className="fas fa-info-circle mr-1" />
                              Anda belum memberikan feedback terhadap materi.
                            </strong>
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="border rounded p-3">
                  {assignment && (
                    <CkeditorReadonly
                      data={assignment.text}
                      id={assignment.id}
                    />
                  )}
                  <hr />
                  <p className="f-w-700 f-16">Tugas saya:</p>
                  {assignmentUser ? (
                    <>
                      {assignment ? (
                        <>
                          {assignment.reply_type == 1 ? (
                            <TextAnswer answer={assignmentUser} />
                          ) : null}
                          {assignment.reply_type == 2 ? (
                            <AudioAnswer answer={assignmentUser} />
                          ) : null}
                          {assignment.reply_type == 3 ? (
                            <VideoAnswer answer={assignmentUser} />
                          ) : null}
                          {assignment.reply_type == 4 ? (
                            <DocumentAnswer
                              answer={assignmentUser}
                              assignmentTitle={assignment.title}
                              siswaName={user.name}
                            />
                          ) : null}
                          {assignment.reply_type == 5 ? (
                            <YoutubeAnswer answer={assignmentUser} />
                          ) : null}
                          {assignment.reply_type == 6 ? (
                            <UrlAnswer answer={assignmentUser} />
                          ) : null}
                          <div
                              className="border rounded mt-3 px-3 accordion"
                              id="accordionPembahasan"
                            >
                              <div className="row">
                                <div
                                  className="col-12 py-3 w-100"
                                  id="headingOne"
                                  style={{
                                    backgroundColor: '#f8f9fa',
                                  }}
                                  onMouseEnter={(event) => {
                                    event.currentTarget.style.backgroundColor =
                                      '#f6f6f6'
                                  }}
                                  onMouseLeave={(event) => {
                                    event.currentTarget.style.backgroundColor =
                                      '#f8f9fa'
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: '500',
                                      fontSize: '16px',
                                      width: '100%',
                                    }}
                                  >
                                    <a
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#collapseOne"
                                      aria-expanded="false"
                                      aria-controls="collapseOne"
                                      className="collapsed w-100 d-flex justify-content-between align-items-center"
                                    >
                                      <span>Pembahasan</span>
                                      <i className="fas fa-chevron-down" />
                                    </a>
                                  </span>
                                </div>
                                <div
                                  className="col-12 my-3 collapse"
                                  id="collapseOne"
                                  aria-labelledby="headingOne"
                                  data-parent="#accordionPembahasan"
                                >
                                  <HtmlContent html={assignment.pembahasan} />
                                </div>
                              </div>
                            </div>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
            )}
          </>
        )}
        {loadingAllAssign ? (
          <div className="col-12 pt-4">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {showAllAssign && (
              <div className="col-12 mb-3 user-profile-list">
                <AssignmentUsersAll
                  assignmentUsers={assignmentUsers}
                  totalRows={assignmentUsersRows}
                  setOffset={setOffset}
                  setKeyword={setKeyword}
                  content={content}
                />
                {/* {assignmentUsers.length !== 0 ?
                  :
                  null
                } */}
              </div>
            )}
          </>
        )}
        {loadingMyScore ? (
          <div className="col-12 pt-4">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {showMyScore && (
              <div className="col-12 mb-3">
                <div className="border rounded">
                  <div className="p-3">
                    <div className="row">
                      <div className="col-5 col-md-2">
                        <div className="row">
                          <div className="col-10">
                            <span style={{ fontWeight: '500' }}>Nilai</span>
                          </div>
                          <div className="col-2 pr-0 d-flex justify-content-end">
                            <span>:</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-7 col-md-8">
                        {myScore ? (
                          <span>
                            {myScore.score !== null ? (
                              <span style={{ fontWeight: '500' }}>
                                {myScore.score}
                              </span>
                            ) : (
                              <span className="badge badge-secondary">
                                Tugas belum diperiksa
                              </span>
                            )}
                          </span>
                        ) : (
                          <span
                            className="spinner-border spinner-border-sm mr-2"
                            role="status"
                          />
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 col-md-2">
                        <div className="row">
                          <div className="col-10">
                            <span style={{ fontWeight: '500' }}>Catatan</span>
                          </div>
                          <div className="col-2 pr-0 d-flex justify-content-end">
                            <span>:</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-7 col-md-8">
                        {myScore ? 
                          myScore.note ? <CkeditorReadonly data={myScore.note} /> : <span>-</span>
                         : (
                          <span
                            className="spinner-border spinner-border-sm mr-2"
                            role="status"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export const TextAnswer = ({ answer }) => {
  return <HtmlContent html={answer.text} />
}

export const AudioAnswer = ({ answer }) => {
  const data = answer.url?.data || answer.url
  const url = data

  if (data) {
    return (
      <audio
        style={{ width: '100%' }}
        controls
        controlsList="nodownload"
        onContextMenu={(event) => event.preventDefault()}
      >
        <source
          src={url}
          type="audio/mpeg"
        />
        Your browser does not support the audio tag.
      </audio>
    )
  } else {
    return null
  }
}

export const VideoAnswer = ({ answer }) => {
  const data = answer.url?.data || answer?.url

  if (data) {
    return (
      <video
        width="100%"
        style={{ maxHeight: '100vh' }}
        controls
        disablePictureInPicture
        controlsList="nodownload"
        onContextMenu={(event) => event.preventDefault()}
      >
        <source
          src={data}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    )
  } else {
    return null
  }
}

export const DocumentAnswer = ({ answer, assignmentTitle, siswaName }) => {
  const { getRequest } = useBaseContext()

  async function download() {
    const response = await getRequest(`checkfile?url=${answer.url}`)
    if (response) {
      window.location.href = answer.url
    }
  }
  const { pathname } = new URL(answer.url)
  let type = pathname.split('.').pop()
  const encode = encodeURIComponent(answer.url)
  return (
    <div className="row">
      <div className="col-12">
        <iframe
          src={detectionDoc(type, encode)}
          style={{
            border: 'none',
            height: '100vh',
            width: '100%',
            backgroundColor: '#ddd',
          }}
        ></iframe>
      </div>
      <div className="col-12 d-flex justify-content-center">
        <button
          onClick={download}
          type="button"
          className="btn btn-outline-info"
        >
          <i className="fas fa-download mr-2" />
          <span>Download Tugas</span>
        </button>
      </div>
    </div>
  )
}

export const YoutubeAnswer = ({ answer }) => {
  const ytId = answer.url?.data

  if (ytId) {
    return (
      <YouTube
        videoId={ytId}
        opts={{
          width: '100%',
          height: '450px',
        }}
      />
    )
  } else {
    return null
  }
}

export const UrlAnswer = ({ answer }) => {
  if (answer.url) {
    return (
      <div>
        <a
          href={answer.url?.data}
          target="_blank"
        >
          {answer.url?.data}
        </a>
        <br />
        <br />
        {answer.url?.data ? <CardMeta url={answer.url?.data} /> : null}
      </div>
    )
  } else {
    return null
  }
}

const AssignmentUsersAll = ({
  assignmentUsers,
  totalRows,
  setOffset,
  setKeyword,
  content,
}) => {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()
  const { comment } = useMainContent()

  /* ========================================= Constants ======================================== */
  const { id, name, linkAvatar } = JSON.parse(localStorage.getItem('user'))

  /* ========================================== States ========================================== */
  const [detail, setDetail]                         = useState(null)
  const [discuss, setDiscuss]                       = useState([])
  const [sendDiscuss, setSendDiscuss]               = useState('')
  const [loadingSendDiscuss, setLoadingSendDiscuss] = useState(false)
  const [showReply, setShowReply]                   = useState(null)
  const [sendReply, setSendReply]                   = useState('')
  const [loadingSendReply, setLoadingSendReply]     = useState(false)
  const [rating, setRating]                         = useState(null)
  const [isRated, setIsRated]                       = useState(false)

  /* ========================================= Functions ======================================== */
  async function handleDetail(id) {
    const response = await getDiscuss(id)
    if (response) {
      setDetail(response)
    }
  }

  async function getDiscuss(id) {
    const response = await getRequest(
      `assigmentusers-discuss?id=${id}&content_id=${content.id}`,
    )
    if (response) {
      setDiscuss(response.data)
      checkIsRated(response.data)
      return response.assignmentUser
    } else {
      return null
    }
  }

  async function handleSendDiscuss() {
    let form = new FormData()
    form.append('description', sendDiscuss)
    form.append('assigment_users_id', detail.id)
    form.append('content_id', content.id)
    setLoadingSendDiscuss(true)
    const response = await postRequest(`assigmentusers-discuss`, form)
    setLoadingSendDiscuss(false)
    if (response) {
      comment.increment()
      setSendDiscuss('')
      await handleDetail(detail.id)
      window.notification('Feedback', 'berhasil dikirim!', 'success')
    }
  }

  async function handleValidation() {
    if (sendDiscuss && detail.id && loadingSendDiscuss === false) {
      if (!isRated) {
        if (rating) {
          let form = new FormData()
          form.append('type', 'assigment')
          form.append('value_id', detail.id)
          form.append('rating', rating)
          await postRequest('users-rating', form)
          await handleSendDiscuss()
        } else {
          window.notification('Mohon', 'isi rating anda!', 'warning')
        }
      } else {
        await handleSendDiscuss()
      }
    }
  }

  function handleShowReply(item) {
    setShowReply(showReply === item.id ? '' : item.id)
    setSendReply('')
  }

  async function handleSendReply(parentId) {
    if (sendReply && detail.id && loadingSendReply === false && parentId) {
      let form = new FormData()
      form.append('description', sendReply)
      form.append('assigment_users_id', detail.id)
      form.append('content_id', content.id)
      form.append('parent_id', parentId)
      setLoadingSendReply(true)
      const response = await postRequest(`assigmentusers-discuss`, form)
      setLoadingSendReply(false)
      if (response) {
        setSendReply('')
        await getDiscuss(detail.id)
        window.notification('Balasan', 'berhasil dikirim!', 'success')
      }
    }
  }

  function checkIsRated(discuss) {
    if (discuss.length !== 0) {
      const { id } = JSON.parse(localStorage.getItem('user'))
      let rated = false
      for (let item of discuss) {
        if (item.rating !== null && item.user_id === id) {
          rated = true
          break
        }
      }
      setIsRated(rated)
    } else {
      setIsRated(false)
    }
  }

  async function handleChangeRating(rating) {
    setRating(rating)
  }

  function handleCloseModal() {
    setDetail(null)
    setSendDiscuss('')
    setLoadingSendDiscuss(false)
    setShowReply(null)
    setSendReply('')
    setLoadingSendReply(false)
    setIsRated(false)
    setRating(null)
  }

  const AssignmentUserItem = ({ item, setDetail }) => {
    var currentAssigment = item.assigment_users.find((i) => i.d_login == null)
    return (
      <tr>
        <td>
          <div className="d-inline-block align-middle">
            <img
              src={`${item.linkAvatar}`}
              alt={item.name}
              className="img-radius align-top m-r-15"
              style={{ width: '40px', height: '40px' }}
              onError={(event) => {
                event.currentTarget.src = avatarDefaultUser
              }}
            />
            <div className="d-inline-block">
              <h6 className="m-b-0 mt-2">{item.name}</h6>
            </div>
          </div>
        </td>
        <td>{item.nim}</td>
        <td>{currentAssigment ? 'Sudah mengerjakan' : 'Belum mengerjakan'}</td>
        <td>
          {currentAssigment && (
            <button
              className="btn btn-sm btn-primary"
              data-toggle="modal"
              data-target="#tugasKomentar"
              onClick={() => setDetail(currentAssigment.id)}
            >
              <i className="fas fa-comments mr-1" />
              Feedback
            </button>
          )}
        </td>
      </tr>
    )
  }

  return (
    <div className="dt-responsive table-responsive">
      <DataTable
        className={'table table-striped table-bordered nowrap'}
        data={assignmentUsers}
        totalRows={totalRows}
        show={[5]}
        onChangeSearch={(val) => setKeyword(val)}
        onChangeLimit={(val) => {}}
        onChangePage={(val) => setOffset(val)}
        column={['Nama', 'No. Induk', 'Status', 'Feedback']}
        renderItem={(val, index) => (
          <AssignmentUserItem
            item={val}
            key={index}
            setDetail={handleDetail}
          />
        )}
      />
      <div
        className="modal fade"
        id="tugasKomentar"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="tugasKomentasLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              {detail ? (
                <div className="d-flex align-items-center">
                  <img
                    src={`${detail.user.linkAvatar}`}
                    alt={detail.user.name ? detail.user.name : 'User'}
                    className="img-radius"
                    style={{ height: '30px', width: '30px' }}
                    onError={(e) => (e.currentTarget.src = avatarDefaultUser)}
                  />
                  <p className="m-0 ml-2 f-w-700 f-18 d-flex align-items-center">
                    {detail.user.name}
                  </p>
                </div>
              ) : (
                <div className="d-flex">
                  <img
                    src={avatarDefaultUser}
                    alt="User"
                    className="img-radius mb-2"
                    style={{ height: '30px', width: '30px' }}
                  />
                  <div className="ml-2"></div>
                </div>
              )}
              <button
                type="button"
                className="close text-danger"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              >
                <i className="feather icon-x" />
              </button>
            </div>
            <div className="modal-body">
              {detail && (
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        {detail.assigment.reply_type == 1 && (
                          <TextAnswer answer={detail} />
                        )}
                        {detail.assigment.reply_type == 2 && (
                          <AudioAnswer answer={detail} />
                        )}
                        {detail.assigment.reply_type == 3 && (
                          <VideoAnswer answer={detail} />
                        )}
                        {detail.assigment.reply_type == 4 && (
                          <DocumentAnswer
                            answer={detail}
                            assignmentTitle={detail.assigment.title}
                            siswaName={detail.user.name}
                          />
                        )}
                        {detail.assigment.reply_type == 5 && (
                          <YoutubeAnswer answer={detail} />
                        )}
                        {detail.assigment.reply_type == 6 && (
                          <UrlAnswer answer={detail} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                {detail ? (
                  detail.user.id === id ? null : (
                    <div className="col-12">
                      <h5>Berikan feedback:</h5>
                      <div className="row">
                        <div className="col-2 col-xl-1">
                          <img
                            src={`${linkAvatar}`}
                            alt={name ? name : 'User'}
                            className="img-radius"
                            style={{ height: '40px', width: '40px' }}
                            onError={(e) =>
                              (e.currentTarget.src = avatarDefaultUser)
                            }
                          />
                        </div>
                        <div className="col-10 col-xl-11 pl-0 pl-xl-2">
                          <p className="">Berikan feedback untuk tugas ini</p>
                          {isRated ? null : (
                            <InputRating
                              starColor="#ffba57"
                              handleChange={handleChangeRating}
                            />
                          )}
                          <textarea
                            value={sendDiscuss}
                            onChange={(e) => setSendDiscuss(e.target.value)}
                            className="form-control mt-3"
                            placeholder="Tulis feedback anda..."
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        {sendDiscuss ? (
                          <button
                            onClick={handleValidation}
                            className={`${
                              loadingSendDiscuss ? 'btn-forbidden' : ''
                            } btn btn-primary mt-3`}
                            disabled={!!loadingSendDiscuss}
                          >
                            {loadingSendDiscuss && (
                              <span
                                className="spinner-border spinner-border-sm mr-1"
                                role="status"
                              />
                            )}
                            <span>Kirim Feedback</span>
                          </button>
                        ) : (
                          <button
                            disabled
                            className="btn btn-forbidden btn-primary mt-3"
                          >
                            Kirim Feedback
                          </button>
                        )}
                      </div>
                    </div>
                  )
                ) : null}
              </div>
              <div className="row">
                <div className="col">
                  <h5 className="m-0">Feedback ({discuss.length})</h5>
                  {/* <hr className='mb-0' /> */}
                  {detail ? (
                    <RatingFull
                      rating={detail.rating}
                      rating_1={detail.rating_1}
                      rating_2={detail.rating_2}
                      rating_3={detail.rating_3}
                      rating_4={detail.rating_4}
                      rating_5={detail.rating_5}
                      totalRating={detail.count_rating}
                      maxRating={5}
                      starColor="#ffba57"
                    />
                  ) : null}
                  {discuss.length !== 0 ? (
                    <>
                      {discuss.map((item, index) => {
                        // setLike(item.likes)
                        let dd = moment(item.createdAt)
                          .add(30, 'days')
                          .valueOf()
                        let date =
                          dd < moment().valueOf()
                            ? moment(item.createdAt).format('DD MMM YYYY')
                            : moment(item.createdAt).fromNow()
                        return (
                          <div
                            key={item.id}
                            className={`${
                              index === 0 ? 'border-top mt-2' : ''
                            } border-bottom`}
                          >
                            <div className="card-body">
                              <div className="row">
                                <div className="col">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={`${item.user.linkAvatar}`}
                                      alt={
                                        item.user.name ? item.user.name : 'User'
                                      }
                                      className="img-radius mb-2"
                                      style={{ height: '30px', width: '30px' }}
                                      onError={(e) =>
                                        (e.currentTarget.src =
                                          avatarDefaultUser)
                                      }
                                    />
                                    <div className="ml-2">
                                      <p className="mb-0 d-flex align-items-center">
                                        <span className="f-w-500">
                                          {item.user.name}
                                        </span>
                                      </p>
                                      <div>
                                        <span>
                                          <RatingStars
                                            rating={item.rating}
                                            starSize="10px"
                                            maxRating={5}
                                            starColor="#ffba57"
                                          />
                                        </span>
                                        <span
                                          className="text-muted ml-2 mb-2"
                                          style={{ fontSize: '10px' }}
                                        >
                                          <i className="feather icon-clock mr-1" />
                                          {date}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <p>{item.description}</p>
                                  <button
                                    onClick={() => handleShowReply(item)}
                                    type="button"
                                    className="btn btn-link btn-sm text-secondary px-0"
                                  >
                                    <i className="fas fa-comment f-10" />
                                    <span className="f-12 ml-1">
                                      {item.assigment_users_discusses.length}{' '}
                                      Balasan
                                    </span>
                                  </button>
                                  {showReply === item.id ? (
                                    <>
                                      <div className="row mt-3">
                                        <div className="offset-1 col-2 col-xl-1">
                                          <img
                                            src={`${linkAvatar}`}
                                            alt={name ? name : 'User'}
                                            className="img-radius"
                                            style={{
                                              height: '40px',
                                              width: '40px',
                                            }}
                                            onError={(e) =>
                                              (e.currentTarget.src =
                                                avatarDefaultUser)
                                            }
                                          />
                                        </div>
                                        <div className="col-9 col-xl-10 pl-0">
                                          <textarea
                                            value={sendReply}
                                            onChange={(e) =>
                                              setSendReply(e.target.value)
                                            }
                                            className="form-control"
                                            placeholder="Tulis balasan..."
                                          />
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-end">
                                        {sendReply ? (
                                          <button
                                            onClick={() =>
                                              handleSendReply(item.id)
                                            }
                                            className={`${
                                              loadingSendReply
                                                ? 'btn-forbidden'
                                                : ''
                                            } btn btn-primary mt-3`}
                                            disabled={
                                              !!loadingSendReply
                                            }
                                          >
                                            {loadingSendReply && (
                                              <span
                                                className="spinner-border spinner-border-sm mr-1"
                                                role="status"
                                              />
                                            )}
                                            <span>Kirim Balasan</span>
                                          </button>
                                        ) : (
                                          <button
                                            disabled
                                            className="btn btn-forbidden btn-primary mt-3"
                                          >
                                            Kirim Balasan
                                          </button>
                                        )}
                                      </div>
                                      {item.assigment_users_discusses.length !==
                                        0 && (
                                        <div className="row mt-4">
                                          {item.assigment_users_discusses.map(
                                            (reply, index) => {
                                              let dd = moment(reply.createdAt)
                                                .add(30, 'days')
                                                .valueOf()
                                              let date =
                                                dd < moment().valueOf()
                                                  ? moment(
                                                      reply.createdAt,
                                                    ).format('DD MMM YYYY')
                                                  : moment(
                                                      reply.createdAt,
                                                    ).fromNow()
                                              return (
                                                <div
                                                  key={index}
                                                  className="offset-1 col-11 pt-2 border-top"
                                                >
                                                  <div className="d-flex align-items-center">
                                                    <img
                                                      src={`${reply.user.linkAvatar}`}
                                                      alt={
                                                        reply.user.name
                                                          ? reply.user.name
                                                          : 'User'
                                                      }
                                                      className="img-radius mb-2"
                                                      style={{
                                                        height: '30px',
                                                        width: '30px',
                                                      }}
                                                      onError={(e) =>
                                                        (e.currentTarget.src =
                                                          avatarDefaultUser)
                                                      }
                                                    />
                                                    <div className="ml-2">
                                                      <p className="mb-0 d-flex align-items-center">
                                                        <span className="f-w-500">
                                                          {reply.user.name}
                                                        </span>
                                                      </p>
                                                      <p
                                                        className="text-muted mb-2"
                                                        style={{
                                                          fontSize: '10px',
                                                        }}
                                                      >
                                                        <i className="feather icon-clock mr-1" />
                                                        {date}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <p>{reply.description}</p>
                                                </div>
                                              )
                                            },
                                          )}
                                        </div>
                                      )}
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  ) : (
                    <div className="d-flex flex-column justify-content-center my-4">
                      <i
                        className="fas fa-comments f-60 text-center mb-3"
                        style={{
                          color: '#ccc',
                        }}
                      />
                      <h6
                        className="text-center"
                        style={{
                          color: '#bbb',
                        }}
                      >
                        Belum ada feedback
                      </h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{ border: 'none' }}
              className="modal-footer p-2"
            >
              <button
                type="button"
                className="btn btn-outline-danger"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              >
                Tutup
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

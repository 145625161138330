import React from 'react'
import InvalidText from 'component/invalid/text'

const FormInputUrutan = ({
  urutan,
  handleChangeUrutan,
  isUrutanInvalid,
  refInputUrutan,
}) => (
  <div className="form-group mb-5 row">
    <label
      className="col-sm-3 col-form-label"
      htmlFor="urutanContent"
    >
      Nomor Urut
    </label>
    <div className="col-sm-2">
      <input
        type="number"
        min={0}
        value={urutan}
        onChange={(event) => handleChangeUrutan(event)}
        ref={refInputUrutan}
        className={`${isUrutanInvalid ? 'is-invalid' : ''} form-control`}
        id="urutanContent"
        placeholder="Misal: 1"
      />
    </div>
    {isUrutanInvalid ? (
      <div className="offset-sm-3 col-sm-9">
        <InvalidText name={'Urutan'} />
      </div>
    ) : null}
  </div>
)

export default FormInputUrutan

import React from 'react'

const FormDurasi = ({ existingDurasi, handleChangeDurasi }) => {
  return (
    <div className="form-group mb-5 row">
      <label className="col-sm-3 col-form-label">Durasi</label>
      <div className="col-sm-3 d-flex align-items-center">
        <input
          type="number"
          min="0"
          className="form-control"
          placeholder="Misal: 60"
          value={existingDurasi}
          onChange={(event) => handleChangeDurasi(event)}
        />
        <label className="ml-2 mt-2">menit</label>
      </div>
    </div>
  )
}

export default FormDurasi

import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import Modal from 'component/modal'
import { FormInput, FormSwitch2, FormSelect } from 'component/forms'
import SemesterItem from './semester-item'
import NotFound from 'component/not-found'
import LoadingSpinner from 'component/loading'
import { useBaseContext } from 'context/base'

export default function Semesters() {
  /* ========================================== Helpers ========================================= */
  const params = useParams()
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest, deleteRequest } = useBaseContext()

  /* =========================================== Refs =========================================== */
  const mounted = useRef(true)
  const { role_type } = JSON.parse(localStorage.getItem('role_active'))

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/academic-year', name: 'Tahun Ajaran' }]

  /* ======================================= Local States ======================================= */
  const [data, setData]               = useState([])
  const [loadingData, setLoadingData] = useState('idle') // idle / start / success / error
  const [reloadStart, setReloadStart] = useState(false)

  /*START MODAL SEMESTER*/
  const [semesterType, setSemesterType]   = useState('') // 1: ganjil, 2: genap
  const [semesterExtra, setSemesterExtra] = useState('') // primary, non-primary
  const [editCode, setEditCode]           = useState('')
  const [modalTitle, setModalTitle]       = useState('')
  const [startDate, setStartDate]         = useState('')
  const [endDate, setEndDate]             = useState('')
  const [semester_code, setsemester_code] = useState('')
  const [name_period, setname_period]     = useState('')

  const [status, setStatus]                   = useState(0)
  const [statusGenerate, setStatusGenerate]   = useState(0)
  const { id } = JSON.parse(localStorage.getItem('profile'))
  const [raporDate, setRaporDate]             = useState('')
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  /*END MODAL SEMESTER*/

  /* ========================================= Functions ======================================== */
  const reloadPage = () => {
    setReloadStart(true)
    window.location.reload()
  }

  async function getData() {
    setLoadingData('start')
    if (role_type !== 'administrator' && role_type !== 'adminprofile')
      history.push('/')
    const response = await getRequest(
      `semester?equal_ta_tahun_code=${params.ta_tahun_code}`,
    )
    if (response) {
      if (mounted.current === true) {
        setLoadingData('success')
        setData(response.data)
      }
    } else {
      setLoadingData('error')
    }
  }

  function handleChangeSemesterType(event) {
    setSemesterType(event.target.value)
  }

  function handleChangeSemesterExtra(event) {
    setSemesterExtra(event.target.value)
  }

  function handleEditSemester(item) {
    let title = ''
    let type = ''
    if (item.type === 1) type = 'Ganjil'
    if (item.type === 2) type = 'Genap'
    if (item.code.length === 5) title = `Semester ${type} (utama)`
    if (item.code.length === 6)
      title = `Semester ${type} #${item.code[item.code.length - 1]} (tambahan)`
    setEditCode(item.code)
    setStatus(item.status ? 1 : 0)
    setModalTitle(title)
    setStartDate(item.start_date)
    setEndDate(item.end_date)
    setRaporDate(item.rapor_date_start)
    setsemester_code(item.code)
  }

  function handleChangeStartdate(event) {
    setStartDate(event.target.value)
  }

  function handleChangeEnddate(event) {
    setEndDate(event.target.value)
  }

  function handleChangeStatus(value) {
    setStatus(value ? 1 : 0)
  }

  function handleChangeStatusGenerate(value) {
    setStatusGenerate(value ? 1 : 0)
  }

  function handleChangeRaporDate(event) {
    setStartDate(event.target.value)
  }
  function handleChangeRaporDate1(event) {
    setEndDate(event.target.value)
  }

  function handleChangeRaporDate2(event) {
    setRaporDate(event.target.value)
  }

  function handleChangeSemesterCode(event) {
    setsemester_code(event.target.value)
  }

  function handleChangeNamePeriod(event) {
    setname_period(event.target.value)
  }

  async function handleSubmitModalAdd() {
    let form = new FormData()
    form.append('ta_tahun_code', params.ta_tahun_code)
    form.append('type', semesterType)
    form.append('start_date', startDate)
    form.append('end_date', endDate)
    form.append('status', status)
    form.append('extra', semesterExtra)
    form.append('rapor_date_start', raporDate)
    form.append('status_generate',statusGenerate)
    setIsLoadingSubmit(true)
    const response = await postRequest('semester', form)
    if (response) {
      await getData()
      window.$('#modalAddSemester').modal('hide')
      handleCloseModal()
      window.notification('', 'Berhasil menyimpan semester', 'success')
    }
  }

  async function handleSubmitModalEdit() {
    let form = new FormData()
    form.append('code', editCode)
    form.append('start_date', startDate)
    form.append('end_date', endDate)
    form.append('status', status)
    form.append('rapor_date_start', raporDate)
    setIsLoadingSubmit(true)
    const response = await putRequest('semester', form)
    if (response) {
      await getData()
      window.$('#modalEditSemester').modal('hide')
      handleCloseModal()
      window.notification('', 'Berhasil menyimpan semester', 'success')
    }
  }

  async function handleSubmitModalEditPeriod() {
    let form = new FormData()

    form.append('semester_code', semester_code)
    form.append('start_date', startDate)
    form.append('end_date', endDate)
    form.append('status', status)
    form.append('name_period', name_period)

    setIsLoadingSubmit(true)
    const response = await postRequest('reportPeriod', form)
    if (response) {
      await getData()
      window.$('#modalTambahBlock').modal('hide')
      handleCloseModal()
      window.notification('', 'Berhasil menyimpan Report Period', 'success')
    }
  }

  function handleCloseModal() {
    setModalTitle('')
    setEditCode('')
    setStartDate('')
    setEndDate('')
    setStatus(0)
    setStatusGenerate(0)
    setIsLoadingSubmit(false)
    setSemesterType('')
    setSemesterExtra('')
    setRaporDate('')
  }

  async function handleDeleteSemester(item) {
    let title = ''
    let type = ''
    if (item.type === 1) type = 'Ganjil'
    if (item.type === 2) type = 'Genap'
    if (item.code.length === 5) title = `Semester ${type} (utama)`
    if (item.code.length === 6)
      title = `Semester ${type} #${item.code[item.code.length - 1]} (tambahan)`
    Swal.fire({
      icon: 'warning',
      title: `Anda akan menghapus ${title}`,
      text: 'Apakah anda yakin?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Menghapus semester...',
          html: `<div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>`,
          allowOutsideClick: false,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
        })
        const response = await deleteRequest(`semester?code=${item.code}`)
        if (response) {
          await getData()
          Swal.fire({
            icon: 'success',
            text: `${title} berhasil dihapus`,
          })
        } else {
          Swal.close()
        }
      }
    })
  }

  async function init() {
    await getData()
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    setLoadingData('idle')
    init()
    return () => {
      mounted.current = false
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Semester" links={links} />
        <div className="card">
          <div className="card-header">
            <button
              className="btn btn-primary"
              type="button"
              data-toggle="modal"
              data-target="#modalAddSemester"
            >
              <i className="feather icon-plus-circle mr-2" />
              Tambah Semester
            </button>
          </div>
          <div className="card-body">
            <div className="container">
              <div className="row">
                {loadingData === 'start' && (
                  <div className="col">
                    <LoadingSpinner />
                  </div>
                )}
                {loadingData === 'success' && (
                  <>
                    {data.length > 0 ? (
                      data.map((item, index) => (
                        <SemesterItem
                          key={index}
                          item={item}
                          index={index}
                          handleEdit={handleEditSemester}
                          handleDelete={handleDeleteSemester}
                        />
                      ))
                    ) : (
                      <NotFound
                        text="Semester belum dibuat"
                        icon="fas fa-graduation-cap"
                      />
                    )}
                  </>
                )}
                {loadingData === 'error' && (
                  <div className="col d-flex flex-column justify-content-center align-items-center">
                    <NotFound text="Terjadi kesalahan" />
                    <button
                      className="btn btn-sm btn-primary mt-3"
                      onClick={reloadStart ? () => {} : reloadPage}
                      disabled={reloadStart}
                    >
                      {reloadStart ? (
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                        />
                      ) : (
                        <i className="fas fa-redo-alt mr-1" />
                      )}
                      Muat Ulang
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Tambah Semester"
          id="modalAddSemester"
          showCloseFooter={true}
          showFooter={true}
          onClose={handleCloseModal}
          footer={
            isLoadingSubmit ? (
              <button
                disabled
                type="button"
                className="btn btn-primary ml-2"
              >
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                />
                Simpan
              </button>
            ) : (
              <>
                {semesterType !== '' &&
                semesterExtra !== '' &&
                startDate !== '' &&
                endDate !== '' &&
                raporDate !== '' ? (
                  <button
                    onClick={handleSubmitModalAdd}
                    type="button"
                    className="btn btn-primary ml-2"
                  >
                    Simpan
                  </button>
                ) : (
                  <button
                    disabled
                    type="button"
                    className="btn btn-primary ml-2"
                  >
                    Simpan
                  </button>
                )}
              </>
            )
          }
        >
          <form>
            <FormSelect
              labelForm="Semester"
              value={semesterType}
              id="formSelectSemesterType"
              options={[
                { value: '1', label: 'Ganjil' },
                { value: '2', label: 'Genap' },
              ]}
              placeholder="Pilih semester..."
              onChange={handleChangeSemesterType}
              required={true}
            />
            <FormSelect
              labelForm="Jenis"
              value={semesterExtra}
              id="formSelectSemesterExtra"
              options={[
                { value: 'primary', label: 'Semester utama' },
                { value: 'non-primary', label: 'Semester tambahan' },
              ]}
              placeholder="Pilih jenis..."
              onChange={handleChangeSemesterExtra}
              required={true}
            />
            <FormInput
              id="addSemesterStartDate"
              type="date"
              labelForm="Mulai"
              value={startDate}
              onChange={handleChangeStartdate}
              required={true}
            />
            <FormInput
              id="addSemesterEndDate"
              type="date"
              labelForm="Selesai"
              value={endDate}
              onChange={handleChangeEnddate}
              required={true}
            />
            <FormInput
              id="addSemesterRaporDate"
              type="date"
              labelForm="Tgl. Pengisian Rapor"
              required={true}
              disabled={!endDate}
              value={raporDate}
              min={startDate}
              max={endDate}
              onChange={handleChangeRaporDate2}
            />
            {id && id !== '958fec43-23ca-4a75-97b0-874f48afb239' ? (
              <FormSwitch2
                id="addSemesterGeneratePeriod"
                label="Generate Period"
                value={statusGenerate}
                handleChange={handleChangeStatusGenerate}
              />
            ): null}
          </form>
        </Modal>
        <Modal
          title={modalTitle}
          id="modalEditSemester"
          onClose={handleCloseModal}
          footer={
            isLoadingSubmit ? (
              <button
                disabled
                type="button"
                className="btn btn-primary ml-2"
              >
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                />
                Simpan
              </button>
            ) : (
              <>
                {editCode !== '' &&
                startDate !== '' &&
                endDate !== '' &&
                raporDate !== '' ? (
                  <button
                    onClick={handleSubmitModalEdit}
                    type="button"
                    className="btn btn-primary ml-2"
                  >
                    Simpan
                  </button>
                ) : (
                  <button
                    disabled
                    type="button"
                    className="btn btn-primary ml-2"
                  >
                    Simpan
                  </button>
                )}
              </>
            )
          }
        >
          <form>
            <FormInput
              id="formEditSemesterStartDate"
              type="date"
              labelForm="Mulai"
              value={startDate}
              onChange={handleChangeStartdate}
              required={true}
            />
            <FormInput
              id="formEditSemesterEndDate"
              type="date"
              labelForm="Selesai"
              value={endDate}
              onChange={handleChangeEnddate}
              required={true}
            />
            <FormInput
              id="editSemesterRaporDate"
              type="date"
              labelForm="Tgl. Pengisian Rapor"
              required={true}
              disabled={!endDate}
              value={raporDate}
              min={startDate}
              max={endDate}
              onChange={handleChangeRaporDate2}
            />
            <FormSwitch2
              id="formEditSemesterStatus"
              label="Status"
              value={status}
              handleChange={handleChangeStatus}
            />
          </form>
        </Modal>

        <Modal
          title={modalTitle}
          id="modalTambahBlock"
          onClose={handleCloseModal}
          footer={
            isLoadingSubmit ? (
              <button
                disabled
                type="button"
                className="btn btn-primary ml-2"
              >
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                />
                Simpan
              </button>
            ) : (
              <>
                {editCode !== '' &&
                startDate !== '' &&
                endDate !== '' &&
                raporDate !== '' ? (
                  <button
                    onClick={handleSubmitModalEditPeriod}
                    type="button"
                    className="btn btn-primary ml-2"
                  >
                    Simpan
                  </button>
                ) : (
                  <button
                    disabled
                    type="button"
                    className="btn btn-primary ml-2"
                  >
                    Simpan
                  </button>
                )}
              </>
            )
          }
        >
          <form>
            <FormInput
              id="formEditSemesterCode"
              type="text"
              onChange={handleChangeSemesterCode}
              value={semester_code}
              readonly="true"
              labelForm="Semester Code"
              required={true}
            />
            <FormInput
              id="formEditSemesterEndDate"
              type="text"
              onChange={handleChangeNamePeriod}
              value={name_period}
              labelForm="Nama period"
              required={true}
            />
            <FormInput
              id="editSemesterRaporDate"
              type="date"
              labelForm="Mulai"
              required={true}
              disabled={!endDate}
              value={startDate}
              min={startDate}
              max={endDate}
              onChange={handleChangeRaporDate}
            />
            <FormInput
              id="editSemesterRaporDate"
              type="date"
              labelForm="Selesai"
              required={true}
              disabled={!endDate}
              value={endDate}
              min={startDate}
              max={endDate}
              onChange={handleChangeRaporDate1}
            />
            <FormSwitch2
              id="formEditSemesterStatus"
              label="Status"
              value={status}
              handleChange={handleChangeStatus}
            />
          </form>
        </Modal>
      </Container>
    </TemplateAdmin>
  )
}

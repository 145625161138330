import React from 'react'

export default function InfoDocument() {
  return (
    <div
      className="mt-2 border rounded alert alert-warning w-100"
      role="alert"
    >
      <h6>
        <i className="fas fa-info-circle mr-1" />
        Upload File
      </h6>
      <ol className="mb-0 pl-4">
        <li>Ukuran file maksimal 5 mb.</li>
      </ol>
    </div>
  )
}

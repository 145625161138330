import React, { useState, useEffect } from 'react'
import loadingTypes from 'globals/loading-types'
import FormInputDraftGroup from './form-input-draft-group'
import BaseButton from 'component/button/BaseButton'
import useInputKindness from 'context/input-kindness'
import LoadingSpinnerV2 from 'component/loadingV2'
import useGetRoleActive from 'hooks/useGetRoleActive'
import CheckboxBase from 'component/checkbox/CheckboxBase'
import BaseInputTextAreaWithoutGroup from 'component/input/BaseInputTextAreaWithoutGroup'

export default function RaporUserItem({
  totalColumns = 0,
  item,
  number,
  index,
  categoryId,
  setHistoryRapor,
  onChangeChecboxHandler,
  selectedCheckbox,
  isWithCheckbox,
  isMultipleReject,
  noteRejectHandler
}) {
  /* ====================================== Consume Context ===================================== */
  const roleActive = useGetRoleActive();

  const { handlers } = useInputKindness();

  /* ========================================= Constants ======================================== */
  const badgesConfig = {
    submission: {
      status: 'Menunggu persetujuan Kepala Sekolah',
      className: 'badge-warning',
    },
    approved: {
      status: 'Telah disetujui',
      className: 'badge-success',
    },
    progress: {
      status: 'Sedang dalam progress input nilai',
      className: 'badge-info',
    },
    rejected: {
      status: 'Rapor ditolak',
      className: 'badge-danger',
    },
    draft: {
      status: 'Belum dilakukan input penilaian',
      className: 'badge-secondary',
    },
  }

  const isEditable = item?.status !== 'submission' || item?.status !== 'approved';
  const isAdministartorOrHeadmaster = ['administrator', 'kepalasekolah'].includes(roleActive.type);

  /* ======================================= Local States ======================================= */
  const [loadingScoreTemp, setLoadingScoreTemp] = useState(loadingTypes.idle)
  const [showEditUi, setShowEditUi] = useState(false)

  /* ========================================= Functions ======================================== */
  async function initScoreData(item) {
    setLoadingScoreTemp(loadingTypes.finish)
  }

  async function getStudentScoresHandler() {
    setShowEditUi(true)

    await handlers.getStudentScores.handler({ userId: item?.id });
  }

  const isItemChecked = selectedCheckbox?.find(c => c?.id === item?.id);

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    initScoreData(item)
  }, [categoryId, item])

  /* ========================================== Output ========================================== */
  return (
    <tr>
      {isAdministartorOrHeadmaster && isWithCheckbox ? item?.status === 'approved' || item?.status === 'submission' ?
        <td>
          <CheckboxBase onChange={(e) => onChangeChecboxHandler({
            type: 'selectOne',
            isChecked: e.target.checked,
            value: item
          })}
            isChecked={isItemChecked}
          />
        </td>
        : <td></td> : null}
      <td>{number}</td>
      <td title={item.nim}>{item.name}</td>
      {loadingScoreTemp === loadingTypes.idle && (
        <td colSpan={totalColumns - 2}></td>
      )}
      {loadingScoreTemp === loadingTypes.start && (
        <td colSpan={totalColumns - 2}>
          <div className="d-flex justify-content-center align-items-center">
            <span
              className="spinner-border text-primary"
              role="status"
              style={{
                height: '22px',
                width: '22px',
              }}
            />
          </div>
        </td>
      )}
      {loadingScoreTemp === loadingTypes.finish && (
        <>
          {showEditUi ? (
            <>
              <td colSpan={totalColumns - 2}>
                {handlers.getStudentScores.loading ?
                  <LoadingSpinnerV2 />
                  :
                  <FormInputDraftGroup setShowEditUi={setShowEditUi} userId={item.id} parentItem={item} />
                }
              </td>
            </>
          ) : (
            <>
              <td>
                <span
                  className={`badge ${item?.status !== null ? badgesConfig[item?.status].className : badgesConfig.draft.className}`}
                >
                  {item?.status !== null ? badgesConfig[item?.status].status : badgesConfig.draft.status}
                </span>
              </td>
              <td>
                {Array.isArray(item?.history) && item?.history?.length ?
                  <BaseButton
                    onClick={() => setHistoryRapor(item?.history)}
                    props={{
                      className: "btn-sm",
                      'data-toggle': "modal",
                      'data-target': "#modalHistoryRapor",
                    }}
                    variant='warning'
                    isDisabled={!isEditable}
                  >Catatan</BaseButton>
                  : null}
              </td>
              <td>
                {isMultipleReject ?
                  <BaseInputTextAreaWithoutGroup isWithLabel={false} placeholder='Catatan untuk walikelas...' props={{ onChange: (e) => noteRejectHandler({ itemIndex: index, value: e.target.value }), value: selectedCheckbox[index].note || '' }} />
                  :
                  <BaseButton
                    onClick={getStudentScoresHandler}
                    props={{ className: "btn-sm" }}
                    variant='primary'
                    isDisabled={!isEditable}
                  >{roleActive.type === 'administrator' || roleActive.type === 'kepalasekolah' ? 'Lihat' : 'Input'} Rapor P5</BaseButton>
                }
              </td>
            </>
          )}
        </>
      )}
    </tr>
  )
}

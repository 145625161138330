import React, { useState } from 'react'
import { useBaseContext } from 'context/base'
import {
  RatingFull,
  InputRating,
  RatingStars,
} from 'component/rating'
import LoadingSpinner from 'component/loading'
import moment from 'moment'
import Swal from 'sweetalert2'
import { avatarDefaultUser } from 'util/constant'
import useContentPlayerContext from 'context/content-player'

export default function RatingContent({
  rating,
  rating_1,
  rating_2,
  rating_3,
  rating_4,
  rating_5,
  totalRating,
  contentId,
}) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [ratings, setRatings]             = useState([])
  const [showDetail, setShowDetail]       = useState(false)
  const [loadingRating, setLoadingRating] = useState(false)

  /* ========================================= Functions ======================================== */
  async function handleDetail() {
    if (showDetail) {
      setShowDetail(false)
      setRatings([])
    } else {
      await getDetail()
      setShowDetail(true)
    }
  }

  async function getDetail() {
    setLoadingRating(true)
    const response = await getRequest(
      `users-rating?type=content&value_id=${contentId}`,
    )
    setLoadingRating(false)
    if (response) {
      setRatings(response.data.ratings)
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <>
      <RatingFull
        rating={rating}
        rating_1={rating_1}
        rating_2={rating_2}
        rating_3={rating_3}
        rating_4={rating_4}
        rating_5={rating_5}
        totalRating={totalRating}
        maxRating={5}
        starColor="#ffba57"
      />
      <h6>Rating ({totalRating})</h6>
      <button
        onClick={handleDetail}
        disabled={loadingRating}
        className="btn btn-link align-self-start p-0 text-secondary"
      >
        {loadingRating ? (
          <span
            className="spinner-border spinner-border-sm mr-1"
            role="status"
          />
        ) : null}
        {showDetail ? 'Sembunyikan' : 'Tampilkan semua rating...'}
      </button>
      {showDetail ? (
        <>
          {loadingRating ? (
            <LoadingSpinner />
          ) : (
            <>
              {ratings.length !== 0 ? (
                <div className="border rounded mt-3">
                  {ratings.map((item, index) => {
                    let dd = moment(item.createdAt).add(30, 'days').valueOf()
                    let date =
                      dd < moment().valueOf()
                        ? moment(item.createdAt).format('DD MMM YYYY')
                        : moment(item.createdAt).fromNow()
                    return (
                      <div
                        key={item.id}
                        className={`${index === 0 ? '' : 'border-top'}`}
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <div className="d-flex align-items-center">
                                <img
                                  src={`${item.user.linkAvatar}`}
                                  alt={item.user.name ? item.user.name : 'User'}
                                  className="img-radius mb-2"
                                  style={{ height: '30px', width: '30px' }}
                                  onError={(e) =>
                                    (e.currentTarget.src = avatarDefaultUser)
                                  }
                                />
                                <div className="ml-2">
                                  <p className="mb-0 d-flex align-items-center">
                                    <span className="f-w-500">
                                      {item.user.name}
                                    </span>
                                  </p>
                                  <div>
                                    <span>
                                      <RatingStars
                                        rating={item.rating}
                                        starSize="10px"
                                        maxRating={5}
                                        starColor="#ffba57"
                                      />
                                    </span>
                                    <span
                                      className="text-muted ml-2 mb-2"
                                      style={{ fontSize: '10px' }}
                                    >
                                      <i className="feather icon-clock mr-1" />
                                      {date}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <p className="m-0">{item.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center my-4">
                  <i
                    className="fas fa-comments f-60 text-center mb-3"
                    style={{
                      color: '#ccc',
                    }}
                  />
                  <h6
                    className="text-center"
                    style={{
                      color: '#bbb',
                    }}
                  >
                    Belum ada rating
                  </h6>
                </div>
              )}
            </>
          )}
        </>
      ) : null}
    </>
  )
}

export const FormRating = () => {
  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()
  const { activity, forum, content, rating: ratingStore } = useContentPlayerContext()

  /* =================================== Consume Local Storage ================================== */
  const { name, linkAvatar } = JSON.parse(localStorage.getItem('user'))

  /* ======================================= Local States ======================================= */
  const [rating, setRating]           = useState(null)
  const [description, setDescription] = useState('')
  const [loadingSend, setLoadingSend] = useState(false)

  /* ========================================= Functions ======================================== */
  function handleChangeRating(rating) {
    setRating(rating)
  }

  const shouldSendCCA = () => {
    const _isForumPassed = !forum.required || forum.participated
    const _isTaskPassed = 
      activity.isAssignmentOrQuiz
        ? activity.isContentCorrected
        : activity.isContentCompleted

    return _isForumPassed && _isTaskPassed
  }

  async function handleSubmit() {
    setLoadingSend(true)
    let form = new FormData()
    form.append('type', 'content')
    form.append('value_id', content.id)
    form.append('rating', rating)
    form.append('description', description)
    const response = await postRequest('users-rating', form)
    setLoadingSend(false)
    if (response) {
      setRating(null)
      setDescription('')
      shouldSendCCA() && await activity.report(true)
      await ratingStore.fetch()
      Swal.fire({
        icon: 'success',
        title: 'Rating berhasil dikirim',
      })
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <div className="row my-3">
      <div className="col-2 col-xl-1">
        <img
          src={`${linkAvatar}`}
          alt={name ? name : 'User'}
          className="img-radius"
          style={{ height: '40px', width: '40px' }}
          onError={(e) => (e.currentTarget.src = avatarDefaultUser)}
        />
      </div>
      <div className="col-10 col-xl-11 pl-0 pl-xl-2">
        <p className="">Berikan rating untuk materi ini</p>
        <InputRating
          starColor="#ffba57"
          handleChange={handleChangeRating}
        />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="form-control mt-3"
          placeholder="Bagaimana ulasan anda untuk materi ini..."
        />
      </div>
      <div className="col-12 d-flex justify-content-end">
        {description && rating ? (
          <button
            onClick={handleSubmit}
            className={`${
              loadingSend ? 'btn-forbidden' : ''
            } btn btn-primary mt-3`}
            disabled={loadingSend}
          >
            {loadingSend && (
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
              />
            )}
            <span>Kirim Rating</span>
          </button>
        ) : (
          <button
            disabled
            className="btn btn-forbidden btn-primary mt-3"
          >
            Kirim Rating
          </button>
        )}
      </div>
    </div>
  )
}

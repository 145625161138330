import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useBaseContext } from 'context/base'

export default function AllScoresType2({
  userId,
  reportGroupId,
  response,
  responseApproval,
}) {
  /* ========================================== Helpers ========================================= */
  const params = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= States ========================================= */
  const [data, setData] = useState([])
  const [res, setRes] = useState(null)

  /* ======================================== Functions ======================================= */
  async function getValue() {
    let us = localStorage.getItem('user')
    let usj = JSON.parse(us)
    var rsp = await getRequest(
      `rapot-mapel-siswa/${userId}?rgroup=${reportGroupId}&semester_code=${params.semester}`,
    )

    if (rsp) {
      var ss = {}
      if (rsp.data) {
        setData(rsp.data)
        var scollect = []
        rsp.data.map((d1, i) => {
          if (d1.categories_types.length == 0) {
            d1.mata_pelajaran.map((m1, mi) => {
              ss = {
                user_id: userId,
                categories_id: m1.categories_id ? m1.categories_id : m1.id,
                categories_type_id: m1.categories_type_id
                  ? m1.categories_type_id
                  : d1.id,
                report_group_id: m1.report_group_id
                  ? m1.report_group_id
                  : reportGroupId,
                createdBy: m1.createdBy ? m1.createdBy : usj.id,
                updatedBy: usj.id,
                value: m1.value
                  ? JSON.parse(m1.value)
                  : { nilai: 0, predikat: 'D', deskripsi: '' },
                ta_semester_code: params.semester,
                profile_id: usj.profile_id,
                approval_status: m1.approval_status,
              }
              scollect.push(ss)
              response(ss)
              responseApproval(m1.approval_status)
            })
          } else {
            d1.categories_types.map((d2, iii) => {
              d2.mata_pelajaran.map((m2, mii) => {
                ss = {
                  user_id: userId,
                  categories_id: m2.categories_id ? m2.categories_id : m2.id,
                  categories_type_id: m2.categories_type_id
                    ? m2.categories_type_id
                    : d2.id,
                  report_group_id: m2.report_group_id
                    ? m2.report_group_id
                    : reportGroupId,
                  createdBy: m2.createdBy ? m2.createdBy : usj.id,
                  updatedBy: usj.id,
                  value: m2.value
                    ? JSON.parse(m2.value)
                    : { nilai: 0, predikat: 'D', deskripsi: '' },
                  ta_semester_code: params.semester,
                  profile_id: usj.profile_id,
                  approval_status: m2.approval_status,
                }
                scollect.push(ss)
                response(ss)
                responseApproval(m2.approval_status)
              })
            })
          }
        })
        setRes(scollect)
      }
    }
  }
  function grade(nilai) {
    if (nilai >= 90 && nilai <= 100) {
      return 'A'
    } else if (nilai >= 80 && nilai <= 89) {
      return 'B'
    } else if (nilai >= 70 && nilai <= 79) {
      return 'C'
    } else if (nilai < 70) {
      return 'D'
    } else {
      return 'E'
    }
  }
  useEffect(() => {
    getValue()
  }, [])
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>No</th>
            <th>Mata Pelajaran</th>
            <th>Status</th>
            <th>Nilai</th>
            <th>Deskripsi</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => {
            return (
              <>
                <tr>
                  <td colSpan={6}>
                    <h5>{item.type}</h5>
                  </td>
                </tr>
                <RenderMapel item={item} />
              </>
            )
          })}
        </tbody>
      </table>
    </div>
  )
  function RenderMapel({ item }) {
    if (typeof item.mata_pelajaran != 'undefined') {
      return item.mata_pelajaran.map((mapel, ii) => {
        return (
          <DetailMapel
            mapel={mapel}
            ii={ii}
          />
        )
      })
    } else {
      return item.categories_types.map((list, iii) => {
        return (
          <>
            <tr>
              <td
                colSpan={6}
                style={{ paddingLeft: 50 }}
              >
                <h5>{list.type}</h5>
              </td>
            </tr>
            <RenderMapel item={list} />
          </>
        )
      })
    }
    return null
  }
  function DetailMapel({ mapel, ii }) {
    const [pred, setPred] = useState(null)
    var rr = res
    var index = 0
    if (rr) {
      index = rr.findIndex(
        (x) =>
          x.categories_id == mapel.id && x.report_group_id == reportGroupId,
      )
      // setPred(rr[index].value.predikat)
    }

    function Status() {
      var status = ''
      var existStatus = mapel.approval_status
      var color = '#F2A46050'
      if (existStatus == null) {
        status = 'Input nilai belum dilakukan'
        color = '#C0C0C050'
      } else if (existStatus == 'draft' || existStatus == 'rejected') {
        status = 'Dalam proses penginputan nilai'
      } else if (existStatus == 'submission') {
        status = 'Menunggu persetujuan Kepala Sekolah'
      } else if (existStatus == 'approved') {
        status = 'Nilai telah disetujui'
        color = '#6AF69950'
      }
      return { status, color }
    }
    // var pred=rr[index].value.predikat
    return (
      <tr style={{ backgroundColor: Status().color }}>
        <td>{++ii}</td>
        <td>{mapel.title}</td>
        <td>{Status().status}</td>
        <td>{rr ? rr[index]?.value.nilai : 0}</td>
        <td style={{ whiteSpace: 'normal' }}>
          <div style={{ width: 350, textAlign: 'justify' }}>
            {rr ? rr[index]?.value.deskripsi : null}
          </div>
        </td>
      </tr>
    )
  }
}

import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import 'datatables.net-dt/js/dataTables.dataTables'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'

export default function Roles() {
	/* ========================================== Helpers ========================================== */
  const history = useHistory()

	/* ====================================== Consume Context ====================================== */
  const { getRequest, deleteRequest } = useBaseContext()

	/* ======================================== Local States ======================================= */
  const [data, setData]                 = useState([])
  const [limit, setLimit]               = useState(10)
  const [offset, setOffset]             = useState(0)
  const [keyword, setKeyword]           = useState('')
  const [totalRecords, setTotalRecords] = useState(0)

	/* ========================================= Functions ========================================= */
  async function getRoles() {
    const response = await getRequest(
      `roles?search=${keyword}&limit=${limit}&offset=${offset}`,
    )    
    if (response) {      
      setData(response.data)
      setTotalRecords(response.totalRecords)
    }
  }

	/* ========================================== Effects ========================================== */
  useEffect(() => {
    getRoles()    
  }, [keyword, limit, offset])

	/* =========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Roles" />

        <div className="row">
          {/* [ Form Validation ] start */}
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header">
                <Link to={'/roles-add'}>
                  <button
                    type="button"
                    className="btn btn-primary"
                  >
                    <i className="feather icon-plus-circle mr-1" />
                    <span>Tambah Role</span>
                  </button>
                </Link>
              </div>
              <div className="card-body">
                <div className="dt-responsive table-responsive">
                  <DataTable
                    className={'table table-striped table-bordered nowrap'}
                    data={data}
                    totalRows={totalRecords}
                    show={[10, 20, 30, 40, 50]}
                    onChangeSearch={(val) => {
                      setKeyword(val)
                    }}
                    onChangeLimit={(val) => {
                      setLimit(val)
                    }}
                    onChangePage={(val) => {
                      setOffset(val)
                    }}
                    column={['Role', 'Status', 'Actions']}
                    renderItem={(item, index) => (
                      <RenderItem
                        item={item}
                        key={index}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )

  function RenderItem({ item }) {
    async function deleteItem(id) {
      Swal.fire({
        title: 'Apa anda yakin?',
        text: `Anda akan menghapus role ${item.role}!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Tidak',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await deleteRequest(`roles?id=${id}`)
          if (response) {
            Swal.fire('Berhasil!', 'Role berhasil dihapus.', 'success')
            getRoles()
          } else {
            Swal.fire(
              'Oppsss!',
              'Terjadi kesalahan coba beberapa saat lagi.',
              'error',
            )
          }
        }
      })
    }

    return (
      <tr>        
        <td>{item.role === 'Instruktur' ? 'Guru' : item.role}</td>
        <td>
          {item.active ? (
            <span className="badge badge-success">Aktif</span>
          ) : (
            <span className="badge badge-secondary">Tidak Aktif</span>
          )}
        </td>
        <td>
          <button
            onClick={() =>
              history.push('/roles-edit', {
                id: item.id,
                type: item.role_type,
                role: item.role,
                active: item.active,
                menu: item.roletomenus,
              })
            }
            className="btn btn-success mr-2 btn-sm"
          >
            <i className="feather icon-edit" /> Edit
          </button>
          <button
            onClick={() =>
              history.push('/roles-hak-akses', {
                id: item.id,
                type: item.role_type,
                role: item.role,
                active: item.active,
                menu: item.roletomenus,
              })
            }
            className="btn btn-info mr-2 btn-sm"
          >
            <i className="fas fa-clipboard-check" /> Hak Akses
          </button>
          <button
            onClick={() => deleteItem(item.id)}
            className="btn btn-danger btn-sm"
          >
            <i className="feather icon-trash-2" /> Hapus
          </button>
        </td>
      </tr>
    )
  }
}

import React, { useState } from 'react'
import { useBaseContext } from 'context/base'
import LoadingButton from './buttons/loading'
import CheckButton from './buttons/check'
import RemoveButton from './buttons/remove'

export default function TeacherRow({ num, teacher }) {
  /* ================================= teacher Example ================================= */
  // const teacher = {
  //   "id"         : "0987798b-66cb-4e4c-a67f-1374189598be",
  //   "group_id"   : "036708a5-7b1a-4266-aafb-fb27d735c91f",
  //   "user_id"    : "eaa85954-4d4d-49c4-96a5-4d9d10e4238a",
  //   "c_login"    : "a6892d8e-0e3a-418e-973f-c98f45a697ad",
  //   "profile_id" : "958fec43-23ca-4a75-97b0-874f48afb239",
  //   "role"       : null,
  //   "user"       : {
  //     "linkAvatar"            : "https://lms-lazuardi.nos.wjv-1.neo.id/upload/958fec43-23ca-4a75-97b0-874f48afb239/users/eaa85954-4d4d-49c4-96a5-4d9d10e4238a.jpeg?AWSAccessKeyId=00d59489b643f7c278b5&Expires=1670814174&Signature=193EuQUxw0eXiXFBWM0vxTaaR5s%3D",
  //     "linkSignature"         : "https://lms-lazuardi.nos.wjv-1.neo.id/upload/958fec43-23ca-4a75-97b0-874f48afb239/signatures/eaa85954-4d4d-49c4-96a5-4d9d10e4238a.png?AWSAccessKeyId=00d59489b643f7c278b5&Expires=1670814174&Signature=6ZPEp5AZC9PFTuTB6kFOtkmHHo0%3D",
  //     "id"                    : "eaa85954-4d4d-49c4-96a5-4d9d10e4238a",
  //     "name"                  : "Annisa Utama Berliana",
  //     "description"           : "guru matematika",
  //     "nim"                   : "000009",
  //     "avatar"                : "958fec43-23ca-4a75-97b0-874f48afb239/users/eaa85954-4d4d-49c4-96a5-4d9d10e4238a.jpeg",
  //     "gender"                : "P",
  //     "birthdate"             : "1996-08-18",
  //     "location"              : "jl.pete no 41 rt 03/ rw 07, srengseng sawah, jagakarsa, jakarta selatan",
  //     "role_id"               : null,
  //     "email"                 : "annisaoricha@gmail.com",
  //     "phone"                 : "0858-1470-1384",
  //     "remember_token"        : null,
  //     "firebase_token_mobile" : null,
  //     "firebase_token_web"    : null,
  //     "username"              : "annisa",
  //     "password"              : "$2a$08$gx/yLFfCpl5AXrE/uzc9IO8sgdYSp7cJ/7Iz.Gg6W4UmI4h9SN8ie",
  //     "active"                : true,
  //     "c_login"               : "b8e3f1c3-eac9-41e1-9cd2-c29a6119cd9b",
  //     "u_login"               : "7b914e99-5cc6-443d-9cc2-1d1c9f537dc7",
  //     "d_login"               : null,
  //     "profile_id"            : "958fec43-23ca-4a75-97b0-874f48afb239",
  //     "point"                 : 0,
  //     "badge"                 : 0,
  //     "level"                 : 0,
  //     "ta_tahun_code"         : "2122",
  //     "signature"             : "958fec43-23ca-4a75-97b0-874f48afb239/signatures/eaa85954-4d4d-49c4-96a5-4d9d10e4238a.png"
  //   }
  // }

  /* ====================================== Consume Context ===================================== */
  const { putRequest } = useBaseContext()

  /* ========================================= States ========================================= */
  const [rowLoading, setRowLoading] = useState(false)
  const [role, setRole]             = useState(teacher.role)

  /* ========================================= Handlers ========================================= */
  const toggleGuruUtama = async () => {
    const toggle = role === 'Guru Utama' ? null : 'Guru Utama'

    const form = {
      id: teacher.id,
      role: toggle,
    }
    
    setRowLoading(true)
    const res = await putRequest('set-teachers-role', form)
    if (res.message === 'berhasil set role data') {
      setRole(toggle || 'Guru Pembantu')
    }
    setRowLoading(false)
  }

  /* ======================================== Components ======================================== */
  const TeacherRowAction = () =>
    rowLoading ? (
      <LoadingButton role={role} />
    ) : role === 'Guru Utama' ? (
      <RemoveButton handleClick={toggleGuruUtama} />
    ) : (
      <CheckButton handleClick={toggleGuruUtama} />
    )

  /* ========================================= Output ========================================= */
  return (
    <tr>
      <td>{num}</td>
      <td>{teacher.user.nim}</td>
      <td>{teacher.user.name}</td>
      <td>{teacher.user.username}</td>
      <td className='text-center'>
        <TeacherRowAction />
      </td>
    </tr>
  )
}

import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import BackButton from 'component/button/Back'
import SubmitButton from 'component/button/Submit'
import InvalidText from 'component/invalid/text'
import { FormSwitch } from 'component/forms'
import useEditContent from './hook'
import {
  FormInputName,
  FormInputUrutan,
  FormSelectSection,
  FormSelectPrecontent,
  FormInputTanggal,
  FormActiveStatus,
  FormDurasi,
  FormSelectFeedback,
  FormGroupKecil,
  FormActiveKey,
  FormInputDesc,
  FormInputEndedAt,
  FormTextArea,
  FormSetToBeScore,
  FormSelectStudent,
} from 'component/content-form'
import { getUsersContentSpesificStudent } from 'util/array'

export default function EditContentIframe() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const location = useLocation()
  const params = useParams()

  /* ======================================= Consume Hook ======================================= */
  const { getContentTakeAway } = useEditContent()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const existingDataContent = location.state?.dataContent
  const existingContentsUrl = existingDataContent?.contents_url
  const sections = location.state.sectionList ? location.state.sectionList : []
  const links = [
    { url: '/kelas', name: 'Sub Mata Pelajaran' },
    { url: `/kelas/${location.state.kelasId}`, name: 'Overview' },
  ]

  /* ======================================= Local States ======================================= */
  const [isContentTakeAwayExist, setIsContentTakeAwayExist] = useState(false)

  // FORM INPUT STATES
  const [existingContents, setExistingContents] = useState([])
  const [namaKonten, setNamaKonten]             = useState(existingDataContent?.title || '')
  const [urutan, setUrutan]                     = useState(existingDataContent?.urutan || '')
  const [sectionId, setSectionId]               = useState(existingDataContent?.section_id || '')
  const [preContentId, setPrecontentId]         = useState(existingDataContent?.precontent_id || '')
  const [active, setActive]                     = useState(existingDataContent?.active ? 1 : 0)
  const [durasi, setDurasi]                     = useState(existingDataContent?.durasi ? existingDataContent.durasi / 60 : '')
  const [displayAt, setDisplayAt]               = useState(existingDataContent?.display_at || '')
  const [feeds, setFeeds]                       = useState([])
  const [feedId, setFeedId]                     = useState(null)
  const [isFeedActive, setIsFeedActive]         = useState(0)
  const [enableGroup, setEnableGroup]           = useState(!!existingDataContent?.group_status)
  const [maxGroup, setMaxGroup]                 = useState(existingDataContent?.group_max || '')
  const [descGroup, setDescGroup]               = useState(existingDataContent?.group_description || '')
  const [endedAt, setEndedAt]                   = useState(existingDataContent?.ended_at || '')
  const [description, setDescription]           = useState(existingDataContent?.deskripsi || '')
  const [apresiasi, setApresiasi]               = useState(existingDataContent?.apresiasi || '')
  const [rating, setRating]                     = useState(existingDataContent?.rating ? 1 : 0)
  const [toBeScore, setToBeScore]   = useState({
    isScore: existingDataContent.is_score,
    isKeterampilan: existingDataContent.is_keterampilan,
    isPengetahuan: existingDataContent.is_pengetahuan,
    selectedTypeScore: existingDataContent.type_score_id,
    isTypeScoreActive: true,
  })
  const [selectedStudents, setSelectedStudents] = useState(() => getUsersContentSpesificStudent(existingDataContent))

  // FORM INPUT IFRAME
  const [inputEmbed, setInputEmbed] = useState(existingContentsUrl || '')

  // FORM VALIDATION STATES
  const [isNamaInvalid, setIsNamaInvalid]       = useState(false)
  const [isUrutanInvalid, setIsUrutanInvalid]   = useState(false)
  const [isSectionInvalid, setIsSectionInvalid] = useState(false)
  const [isIframeInvalid, setIsIframeInvalid]   = useState(false)
  const [isMaxGroupValid, setIsMaxGroupValid]   = useState(true)

  const [enableGroupKey, setEnableGroupKey] = useState(false)
  const [prerequisite, setPrerequisite]     = useState('')

  /* =========================================== Refs =========================================== */
  const refInputName     = useRef()
  const refSelectSection = useRef()
  const refInputUrutan   = useRef()
  const refInputIframe   = useRef()
  const refMaxGroup      = useRef()

  /* ========================================= Functions ======================================== */
  function getExistingContentsBySelectedSection() {
    if (!sectionId) {
      setExistingContents([])
    } else {
      sections.forEach((section) => {
        if (section.id === sectionId) {
          setExistingContents(section.contents)
        }
      })
    }
  }

  function handleChangeEnableGroupKey() {
    if (enableGroupKey) {
      setEnableGroupKey(false)
    } else {
      setEnableGroupKey(true)
    }
  }

  async function getFeedsList() {
    const response = await getRequest('feed')
    if (response) {
      const options = response.data.map((item) => ({
        value: item.id,
        label: item.description,
      }))
      setFeeds(options)
      initSelectedFeed(options)
    } else {
      console.error('ERROR', response)
    }
  }

  function initSelectedFeed(feedList) {
    for (let feed of feedList) {
      if (feed.value === existingDataContent.feedback) {
        setFeedId({ value: feed.value, label: feed.label })
        break
      }
    }
  }

  function handleChangeName(event) {
    setNamaKonten(event.target.value)
  }

  function handleChangeUrutan(event) {
    setUrutan(Math.abs(event.target.value))
  }

  function handleChangeSection(event) {
    setSectionId(event.target.value)
  }

  function handleChangePrecontent(event) {
    setPrecontentId(event.target.value)
  }

  function handleChangeDate(event) {
    setDisplayAt(event.target.value)
  }

  function handleChangeActive() {
    if (active === 1) {
      setActive(0)
    }
    if (active === 0) {
      setActive(1)
    }
  }

  function handleWebAddressChange(value) {
    setInputEmbed(value)
  }

  function handleChangeDurasi(event) {
    setDurasi(event.target.value)
  }

  function handleChangeFeedback(val) {
    setFeedId(val)
  }

  function handleChangeEnableGroup() {
    if (enableGroup) {
      setEnableGroup(false)
      setMaxGroup(
        existingDataContent.group_max ? existingDataContent.group_max : '',
      )
      setIsMaxGroupValid(true)
    } else {
      setEnableGroup(true)
      setMaxGroup(
        existingDataContent.group_max ? existingDataContent.group_max : '',
      )
      if (existingDataContent.group_max) {
        setIsMaxGroupValid(true)
      } else {
        setIsMaxGroupValid(false)
      }
    }
  }

  function handleChangeMaxGroup(event) {
    if (event.target.value) {
      const absolute = Math.abs(event.target.value)
      const integer = Math.round(absolute)
      setMaxGroup(integer)
      if (integer <= 0) {
        setIsMaxGroupValid(false)
      } else {
        setIsMaxGroupValid(true)
      }
    } else {
      setMaxGroup('')
      setIsMaxGroupValid(false)
    }
  }

  function handleChangeDescGroup(event) {
    setDescGroup(event.target.value)
  }

  function handleChangeDesc(event) {
    setDescription(event.target.value)
  }

  function handleChangeEndedAt(event) {
    setEndedAt(event.target.value)
  }

  function handleChangeApresiasi(event) {
    setApresiasi(event.target.value)
  }

  function handleChangeRating(value) {
    setRating(value ? 1 : 0)
  }

  function toBeScoreValidation() {
    if (toBeScore.isScore) {
      return (toBeScore.isKeterampilan === true || toBeScore.isPengetahuan === true) && toBeScore.selectedTypeScore
    }

    return true
  }

  function handleValidation() {
    const contents_url = inputEmbed
    const newUrutan = urutan ? Math.abs(parseInt(urutan)) : 0
    const durasiDetik = durasi * 60
    const feedback = isFeedActive === 1 ? feedId : ''

    if (namaKonten && urutan && sectionId && contents_url && isMaxGroupValid && toBeScoreValidation()) {
      setIsNamaInvalid(false)
      setIsSectionInvalid(false)
      setIsUrutanInvalid(false)
      setIsIframeInvalid(false)
      handleSubmitForm(
        existingDataContent.id,
        contents_url,
        namaKonten,
        durasiDetik,
        sectionId,
        newUrutan,
        active,
        displayAt,
        preContentId,
        feedback.value,
        maxGroup,
        descGroup,
        endedAt,
        description,
        apresiasi,
        rating,
      )
    } else {
      if (!isMaxGroupValid) {
        if (refMaxGroup) {
          refMaxGroup.current.focus()
        }
      }

      if (!sectionId) {
        setIsSectionInvalid(true)
        refSelectSection.current.focus()
      }

      if (!urutan) {
        setIsUrutanInvalid(true)
        refInputUrutan.current.focus()
      } else {
        setIsUrutanInvalid(false)
      }

      if (!contents_url) {
        setIsIframeInvalid(true)
        refInputIframe.current.focus()
      } else {
        setIsIframeInvalid(false)
      }

      if (!namaKonten) {
        setIsNamaInvalid(true)
        refInputName.current.focus()
      } else {
        setIsNamaInvalid(false)
      }

      window.notification('Mohon', 'lengkapi data')

      if (!toBeScoreValidation()) {
        window.notification('Mohon', 'lengkapi juga data "Jadikan Sebagai Penilaian"')
      }
    }
  }

  async function handleSubmitForm(
    id,
    contentUrl,
    namaKonten,
    totalDurasi,
    sectionId,
    urutan,
    active,
    displayAt,
    preContentId,
    feedId,
    maxGroup,
    descGroup,
    endedAt,
    description,
    apresiasi,
    rating,
  ) {
    let form = new FormData()
    form.append('id', id)
    form.append('title', namaKonten)
    form.append('content_type', 5)
    form.append('section_id', sectionId)
    form.append('urutan', urutan)
    form.append('active', active)
    form.append('durasi', totalDurasi)
    form.append('contents_url', contentUrl)
    form.append('rating', rating)
    form.append('precontent_id', preContentId)
    form.append('display_at', displayAt)
    form.append('ended_at', endedAt)
    form.append('deskripsi', description)
    form.append('apresiasi', apresiasi)

    form.append('is_score', !!toBeScore.isScore)

    if (toBeScore.isScore) {
      form.append('is_keterampilan', !!toBeScore.isKeterampilan)
      form.append('is_pengetahuan', !!toBeScore.isPengetahuan)
      form.append('type_score_id', toBeScore.selectedTypeScore)
    }

    if (Array.isArray(selectedStudents) && !!selectedStudents.length) {
      selectedStudents.forEach((selectedStudent, selectedStudentIndex) => {
        form.append(`spesifik_user_id[${selectedStudentIndex}]`, selectedStudent.id)
      })
    }

    if (feedId) {
      form.append('feedback', feedId)
    } else {
      form.append('feedback', '')
    }

    if (enableGroup) {
      form.append('group_status', 1)
    } else {
      form.append('group_status', 0)
    }

    if (maxGroup) {
      form.append('group_max', maxGroup)
    }

    if (descGroup) {
      form.append('group_description', descGroup)
    }

    const response = await putRequest('contents', form)
    if (response) {
      if (enableGroupKey) {
        let formkey = new FormData()
        formkey.append('description', prerequisite)
        formkey.append('content_id', id)

        const response1 = isContentTakeAwayExist
          ? await putRequest('contentTakeAway', formkey)
          : await postRequest('contentTakeAway', formkey)

        if (response1) {
          window.notification('Materi', 'berhasil diubah', 'success')
          setInputEmbed('')
          history.push(`/kelas/${location.state.kelasId}`)
        }
      } else {
        window.notification('Materi', 'berhasil diubah', 'success')
        setInputEmbed('')
        history.push(`/kelas/${location.state.kelasId}`)
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getContentTakeAway(existingDataContent.id).then((res) => {
      if (res.data) {
        setPrerequisite(res.data.description)
        setEnableGroupKey(true)
        setIsContentTakeAwayExist(true)
      }
    })

    getFeedsList()
  }, [])

  useEffect(() => {
    getExistingContentsBySelectedSection()
  }, [sectionId])

  useEffect(() => {
    if (existingDataContent.feedback) {
      setIsFeedActive(1)
    }
  }, [existingDataContent.feedback])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Edit Materi iFrame"
          linkName="Materi iFrame"
          links={links}
        />
        
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <h5>
                  <i className="fas fa-code mr-1" />
                  Informasi iFrame
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <form>
                      {/* Input Nama Konten */}
                      <FormInputName
                        label="Nama Konten"
                        placeholder="Nama Konten"
                        namaKonten={namaKonten}
                        handleChangeName={handleChangeName}
                        isNamaInvalid={isNamaInvalid}
                        refInputName={refInputName}
                      />

                      {/* Form Group iFrame */}
                      <div className="form-group row mb-5">
                        <label
                          className="col-sm-3 col-form-label"
                          htmlFor="inputWebAddress"
                        >
                          <span className="mr-1">Web Address (URL)</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            ref={refInputIframe}
                            type="text"
                            className={`${
                              isIframeInvalid ? 'is-invalid' : ''
                            } form-control`}
                            id="inputWebAddress"
                            value={inputEmbed}
                            onChange={(event) =>
                              handleWebAddressChange(event.target.value)
                            }
                            placeholder="Input URL web: https://example.com"
                          />
                          {isIframeInvalid ? (
                            <InvalidText name="Web Address" />
                          ) : null}
                          {inputEmbed ? (
                            <iframe
                              src={inputEmbed}
                              className="rounded mt-3"
                              style={{
                                border: '1px solid #cccccc',
                                backgroundColor: '#eeeeee',
                                width: '100%',
                                height: '650px',
                                display: 'block',
                              }}
                            />
                          ) : null}
                        </div>
                      </div>

                      {/* Input Urutan */}
                      <FormInputUrutan
                        urutan={urutan}
                        handleChangeUrutan={handleChangeUrutan}
                        isUrutanInvalid={isUrutanInvalid}
                        refInputUrutan={refInputUrutan}
                      />

                      {/* Input deskripsi */}
                      <FormInputDesc
                        description={description}
                        handleChangeDesc={handleChangeDesc}
                      />

                      {/* Input Apresiasi */}
                      <FormTextArea
                        id="formInputApresiasi"
                        label="Apresiasi"
                        placeholder="Apresiasi/pesan setelah siswa menyelesaikan materi (opsional)"
                        className="mb-5"
                        value={apresiasi}
                        handleChange={handleChangeApresiasi}
                      />

                      {/* Select Section */}
                      <FormSelectSection
                        sections={sections}
                        handleChangeSection={handleChangeSection}
                        isSectionInvalid={isSectionInvalid}
                        existingSectionId={sectionId}
                        refSelectSection={refSelectSection}
                      />

                      {/* Select Precontent */}
                      <FormSelectPrecontent
                        existingContents={existingContents}
                        handleChangePrecontent={handleChangePrecontent}
                        existingPrecontentId={preContentId}
                        existingContentId={existingDataContent.id}
                      />

                      {/* Input Tanggal Ditampilkan */}
                      <FormInputTanggal
                        handleChangeDate={handleChangeDate}
                        existingDisplayAt={displayAt}
                      />

                      {/* Input ended at */}
                      <FormInputEndedAt
                        endedAt={endedAt}
                        handleChangeEndedAt={handleChangeEndedAt}
                      />

                      {/* Input Durasi */}
                      <FormDurasi
                        existingDurasi={durasi}
                        handleChangeDurasi={handleChangeDurasi}
                      />

                      {/* Rating */}
                      <FormSwitch
                        id="formRating"
                        label="Rating Materi"
                        value={rating}
                        handleChange={handleChangeRating}
                      />

                      {/* Select Feedback */}
                      <FormSelectFeedback
                        existingFeeds={feeds}
                        existingFeedId={feedId}
                        isFeedActive={isFeedActive}
                        setIsFeedActive={setIsFeedActive}
                        handleChangeFeeds={handleChangeFeedback}
                        getFeedList={getFeedsList}
                      />

                      {/* Swicth Group Kecil */}
                      <FormGroupKecil
                        enableGroup={enableGroup}
                        handleChangeEnableGroup={handleChangeEnableGroup}
                        maxGroup={maxGroup}
                        descGroup={descGroup}
                        handleChangeMaxGroup={handleChangeMaxGroup}
                        handleChangeDescGroup={handleChangeDescGroup}
                        isMaxGroupValid={isMaxGroupValid}
                        refMaxGroup={refMaxGroup}
                      />

                      {/* Select Specific Students */}
                      <FormSelectStudent
                        selectedStudents={selectedStudents}
                        setSelectedStudents={setSelectedStudents}
                        subMapelId={params?.kelasId}
                        originalSelectedStudents={getUsersContentSpesificStudent(existingDataContent)}
                        isDirectSave={false}
                        isWithFooter={false}
                        listSelectedStudentsProps={{
                          listStudentsActions: ['rollback', 'clear-all'],
                        }}
                      />

                      {/* Select To Be Score Or Not */}
                      <FormSetToBeScore
                        value={toBeScore}
                        handleChange={setToBeScore}
                      />

                      {/* Select Status */}
                      <FormActiveStatus
                        existingStatus={active}
                        handleChangeStatus={handleChangeActive}
                      />

                      <FormActiveKey
                        enableGroupKey={enableGroupKey}
                        handleChangeEnableGroupKey={handleChangeEnableGroupKey}
                        prerequisite={prerequisite}
                        setPrerequisite={setPrerequisite}
                      />

                      <div className="form-group">
                        <BackButton />
                        <SubmitButton
                          onClick={() => handleValidation()}
                          text="Simpan"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Select from 'react-select'
import Swal from 'sweetalert2'
import 'datatables.net'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import LoadingSpinner from 'component/loading'
import DataTable from 'component/data-table'
import { avatarDefaultUser } from 'util/constant'

export default function Users() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, deleteRequest } = useBaseContext()

  /* =================================== Consume Local Storage ================================== */
  const { role_type } = JSON.parse(localStorage.getItem('role_active'))

  /* ======================================= Local States ======================================= */
  const [data, setData] = useState([])
  const [totalRecord, setTotalRecord] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  // Filter States
  const [listRole, setListRole] = useState([])
  const [listAngkatan, setListAngkatan] = useState([])
  const [selectRole, setSelectRole] = useState(null)
  const [selectAngkatan, setSelectAngkatan] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState({ label: 'Aktif', value: 'active' })
  const [showSelectAngkatan, setShowSelectAngkatan] = useState(false)

  // Search States
  const [keyword, setKeyword] = useState('')
  const [role, setrole] = useState('')
  const [angkatan, setAngkatan] = useState('')
  const [status, setStatus] = useState('active')
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)

  /* ========================================= Functions ======================================== */
  async function getUsers() {
    const response = await getRequest(
      `users?&limit=${limit}&offset=${offset}&keyword=${keyword}&roles=${role}&ta_tahun_code=${angkatan}&status=${status}`,
    )
    setIsLoading(false)
    if (response) {
      setData(response.data)
      setTotalRecord(response.totalRecords)
    }
  }

  function handleFilter() {
    if (selectRole != null) {
      if (selectRole.length != 0) {
        let arr = []
        selectRole.forEach((val) => {
          arr.push(val.value)
        })
        setrole(arr.length > 0 ? arr.join('_') : arr.toString())
      }
    } else {
      setrole('')
    }

    if (selectAngkatan != null) {
      setAngkatan(selectAngkatan.value)
    } else {
      setAngkatan('')
    }

    if (selectedStatus != null) {
      setStatus(selectedStatus.value)
    } else {
      setStatus('')
    }
  }

  async function getRoles() {
    try {
      let response = await getRequest(`roles`)
      if (response) {
        let arr = []
        response.data.forEach((val) => {
          arr.push({ value: val.id, label: val.role })
        })
        setListRole(arr)
      }
    } catch (error) {
      console.error('ERROR', { error })
    }
  }

  async function getTahunAngkatan() {
    try {
      let response = await getRequest(`academic-year`)
      if (response) {
        let arr = [{ value: '', label: 'Semua' }]
        response.data.forEach((val) => {
          arr.push({ value: val.code, label: val.description })
        })
        setListAngkatan(arr)
      }
    } catch (error) {
      console.error('ERROR', { error })
    }
  }

  function showRole(item) {
    if (item === 'b582ec01-4140-4c70-a964-b6c7433860ef') {
      return 'Instruktur'
    } else if (item === '2472ce9d-1a39-4948-b730-c44ce6e0a442') {
      return 'Administrator'
    } else if (item === '13fba530-2218-4997-8f47-b7d7767031da') {
      return 'Siswa'
    } else {
      return item
    }
  }

  /* ======================================== Components ======================================== */
  function RenderItem({ item, index }) {
    const objRole = item.role.reduce(
      (a, { id, name, profile_id, role, role_type }) => {
        a[profile_id] = a[profile_id] || { profile_id, role: [] }
        return {
          ...a,
          ...{
            [profile_id]: {
              profile: name,
              role: a[profile_id].role.concat({ role, role_type }),
            },
          },
        }
      },
      {},
    )
    const newRole = Object.values(objRole)

    async function deleteItem(id) {
      Swal.fire({
        title: 'Apa anda yakin?',
        text: `Anda akan menghapus user ${item.name}!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Tidak',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await deleteRequest(`users?id=${id}`)
          if (response) {
            Swal.fire('Berhasil!', 'User dihapus', 'success').then(() =>
              history.go(0),
            )
          } else {
            Swal.fire(
              'Oppsss!',
              'Terjadi kesalahan coba beberapa saat lagi.',
              'error',
            )
          }
        }
      })
    }

    function showBadges(item) {
      if (item === 'administrator') {
        return 'badge bg-success text-white'
      } else if (item === 'instruktur') {
        return 'badge bg-warning text-white'
      } else if (item === 'siswa') {
        return 'badge bg-info text-white'
      } else {
        return 'badge bg-danger text-white'
      }
    }

    const ManageSiswa = ({ user }) => {
      const [isOrangtua, setIsOrangtua] = useState(false)

      useEffect(() => {
        for (const role of user.role) {
          if (role.role_type === 'orangtua') {
            setIsOrangtua(true)
            break
          }
        }
      }, [user])

      return (
        <>
          {isOrangtua ? (
            <button
              className="btn btn-sm btn-warning mr-2"
              type="button"
              onClick={() =>
                history.push(`/manage-siswa/${user.id}`, { user: user })
              }
            >
              <i className="feather icon-user mr-1" />
              <span>Siswa</span>
            </button>
          ) : null}
        </>
      )
    }

    return (
      <tr>
        <td>
          <div className="d-inline-block align-middle">
            <img
              src={item.link_avatar ? `${item.link_avatar}` : avatarDefaultUser}
              alt={item.name}
              className="img-radius align-top m-r-15"
              style={{ width: '40px', height: '40px' }}
              onError={(event) => {
                event.currentTarget.src = avatarDefaultUser
              }}
            />
            <div className="d-inline-block">
              <h6 className="m-b-0 mt-2">{item.name}</h6>
            </div>
          </div>
        </td>
        <td>{item?.nim || <i className="feather icon-minus" />}</td>
        <td>
          {item?.username || <i className="feather icon-minus" />}
        </td>
        <td>
          {item.email || <i className="feather icon-minus" />}
        </td>
        <td>
          {newRole.map((v, ii) => {
            return (
              <div>
                <span
                  key={ii}
                  className="badge bg-dark text-white"
                >
                  {v.profile}
                </span>{' '}
                :
                {v.role.map((val, i) => {
                  return (
                    <span
                      key={i}
                      className={`${showBadges(val.role_type)} text-white ml-1`}
                    >
                      {val.role === 'Instruktur' ? 'Guru' : val.role}
                    </span>
                  )
                })}
              </div>
            )
          })}
        </td>
        <td>
          {item.active != null ? (
            item.active ? (
              <span className="badge badge-success">Aktif</span>
            ) : (
              <span className="badge badge-secondary">Tidak Aktif</span>
            )
          ) : (
            <i className="feather icon-minus" />
          )}
        </td>
        <td>
          {role_type === 'administrator' || role_type === 'adminprofile' ? (
            <button
              onClick={() =>
                history.push('/users-add-profile', {
                  id: item.id,
                  role: item.role,
                  taTahunCode: item.ta_tahun_code,
                })
              }
              className="btn btn-primary btn-sm mr-2"
            >
              <i className="feather icon-users" /> Profile
            </button>
          ) : (
            <button
              disabled
              className="btn btn-primary btn-sm btn-forbidden mr-2"
              title="Anda tidak memiliki akses"
            >
              <i className="feather icon-users" /> Profile
            </button>
          )}
          {role_type === 'administrator' ? (
            <button
              onClick={() =>
                history.push('/users-edit', {
                  id: item.id,
                  name: item.name,
                  description: item.description,
                  nim: item.nim,
                  gender: item.gender,
                  birthdate: item.birthdate,
                  location: item.location,
                  role: item.role,
                  email: item.email,
                  phone: item.phone,
                  username: item.username,
                  active: item.active,
                  avatar: item.avatar,
                  linkAvatar: item.link_avatar,
                  taTahunCode: item.ta_tahun_code,
                })
              }
              className="btn btn-success btn-sm mr-2"
            >
              <i className="feather icon-edit" /> Edit
            </button>
          ) : (
            <button
              disabled
              className="btn btn-success btn-sm btn-forbidden mr-2"
              title="Anda tidak memiliki akses"
            >
              <i className="feather icon-edit" /> Edit
            </button>
          )}
          <ManageSiswa user={item} />
          {role_type === 'administrator' ? (
            <button
              onClick={() => deleteItem(item.id)}
              className="btn btn-danger btn-sm"
            >
              <i className="feather icon-trash-2" /> Hapus
            </button>
          ) : (
            <button
              disabled
              className="btn btn-danger btn-sm btn-forbidden"
              title="Anda tidak memiliki akses"
            >
              <i className="feather icon-trash-2" /> Hapus
            </button>
          )}
        </td>
      </tr>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    setIsLoading(true)
  }, [])

  useEffect(() => {
    getUsers()
  }, [offset, limit, keyword, role, angkatan, status])

  useEffect(() => {
    getRoles()
    getTahunAngkatan()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Data User"
          linkName="Users"
        />

        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              {role_type === 'administrator' || role_type === 'adminprofile' ? (
                <div className="card-header">
                  <Link to="/users-add">
                    <button
                      type="button"
                      className="btn btn-primary has-ripple"
                    >
                      <i className="feather icon-user-plus" /> Tambah User{' '}
                      <span
                        className="ripple ripple-animate"
                        style={{
                          height: '109.297px',
                          width: '109.297px',
                          animationDuration: '0.7s',
                          animationTimingFunction: 'linear',
                          background: 'rgb(255, 255, 255)',
                          opacity: '0.4',
                          top: '-40.6485px',
                          left: '-1.6485px',
                        }}
                      />
                    </button>
                  </Link>
                </div>
              ) : null}
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-sm-5">
                    <span className="f-w-700 f-14">Role</span>
                    <Select
                      className="mt-2"
                      placeholder="Pilih Role..."
                      isMulti={true}
                      options={listRole}
                      value={selectRole}
                      onChange={(option) => {
                        setSelectRole(option)
                        if (option) {
                          let check = option.filter(
                            (item) => item.label.toLowerCase() == 'siswa',
                          )
                          if (check.length > 0) {
                            setShowSelectAngkatan(true)
                            getTahunAngkatan()
                          } else {
                            setShowSelectAngkatan(false)
                            setSelectAngkatan(null)
                          }
                        } else {
                          setShowSelectAngkatan(false)
                          setSelectAngkatan(null)
                        }
                      }}
                    />
                  </div>
                  <div className="col-sm-2">
                    <span className="f-w-700 f-14">Status</span>
                    <Select
                      className="mt-2"
                      placeholder="Pilih Status..."
                      isMulti={false}
                      options={[
                        {
                          label: 'Aktif',
                          value: 'active',
                        },
                        {
                          label: 'Tidak Aktif',
                          value: 'inactive',
                        },
                      ]}
                      value={selectedStatus}
                      onChange={(option) => {
                        setSelectedStatus(option)
                      }}
                    />
                  </div>
                  {showSelectAngkatan && (
                    <div className="col-sm-4">
                      <span className="f-w-700 f-14">Tahun Angkatan</span>
                      <Select
                        className="mt-2"
                        placeholder="Pilih Tahun Angkatan..."
                        isMulti={false}
                        options={listAngkatan}
                        value={selectAngkatan}
                        onChange={(option) => {
                          setSelectAngkatan(option)
                        }}
                      />
                    </div>
                  )}
                  <div className="col-sm-2 pt-2 pb-2 d-flex justify-content-center align-items-end">
                    <button
                      onClick={handleFilter}
                      className="btn btn-primary btn-sm w-100"
                    >
                      <i className="fas fa-search mr-1 f-12" />
                      Cari
                    </button>
                  </div>
                </div>

                {isLoading ? (
                  <div className="row">
                    <div className="col">
                      <LoadingSpinner />
                    </div>
                  </div>
                ) : (
                  <div className="dt-responsive table-responsive">
                    <DataTable
                      className={'table table-striped table-bordered nowrap'}
                      data={data}
                      totalRows={totalRecord}
                      show={[10, 20, 30, 40, 50]}
                      onChangeSearch={(val) => setKeyword(val)}
                      onChangeLimit={(val) => setLimit(val)}
                      onChangePage={(val) => setOffset(val)}
                      column={[
                        'Nama',
                        'No. Induk',
                        'Username',
                        'Email',
                        'Role',
                        'Status',
                        'Actions',
                      ]}
                      renderItem={(val, index) => (
                        <RenderItem
                          item={val}
                          key={index}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React, { useMemo } from 'react'
import { Redirect } from 'react-router-dom'

import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DashboardSiswa from 'pages/dashboard/siswa'
import DashboardTeacher from 'pages/dashboard/teacher'
import Administrator from 'pages/dashboard/Administrator'

export default function Dashboard() {
  /* =================================== Consume Local Storage ================================== */
  const roleActive = JSON.parse(localStorage.getItem('role_active'))?.role_type

  /* ======================================== Components ======================================== */
  const Dashboard = useMemo(() => {
    if (roleActive == 'administrator') {
      return <Administrator />
    } else if (roleActive == 'instruktur') {
      return <DashboardTeacher />
    } else if (roleActive == 'siswa') {
      return <DashboardSiswa />
    } else if (roleActive == 'orangtua') {
      return <Redirect to="/siswa" />
    } else {
      return (
        <div className="card">
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="text-center">
                  <img
                    src="/admin/assets/images/maintance/maintance.png"
                    alt="Maintenance"
                    className="img-fluid"
                  />
                  <h5 className="text-muted my-4">
                    Dashboard belum tersedia untuk{' '}
                    {JSON.parse(localStorage.getItem('role_active')).role},
                    silahkan kunjungi menu lain yang tersedia.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }, [])

  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Dashboard" />
        {Dashboard}
        {/* <Dashboard /> */}
      </Container>
    </TemplateAdmin>
  )
}

import React from 'react'
import moment from 'moment'
import HtmlContent from 'component/html-content'
import { avatarDefaultUser } from "util/constant"

export default function ForumDiscussItem({ item, date, sendLike, setShowReply, setDescReply, sendReply, showReply, descReply}) {
  const { linkAvatar, name } = JSON.parse(localStorage.getItem('user'))
  
  return (
    <div className="border-bottom">
      <div className="card-body">
        <div className="row">
          <div className="col">
            <div className='d-flex align-items-center'>
              <img
                src={`${item.login.user.linkAvatar}`}
                alt={item.login.user.name ? item.login.user.name : 'User'}
                className="img-radius mb-2"
                style={{ height: '30px', width: '30px' }}
                onError={(e) => e.currentTarget.src = avatarDefaultUser}
              />
              <div className='ml-2'>
                <p className="mb-0 d-flex align-items-center">
                  <span className='f-w-500'>{item.login.user.name}</span>
                </p>
                <p className="text-muted mb-2" style={{ fontSize: "10px" }} ><i className="feather icon-clock mr-1" />{date}</p>
              </div>
            </div>
            <HtmlContent html={item.description} />
            {/* <button onClick={() => {
              sendLike({ id: item.id, like: item.likes })
            }} type="button" className={`btn ${item.likes == 1 ? 'btn-success' : 'btn-outline-success'} btn-sm`}><i className="fas fa-thumbs-up" /> {item.likes} suka</button> */}
            <button 
              onClick={() => {
                setShowReply(showReply == item.id ? '' : item.id)
                setDescReply('')
              }} 
              type="button" 
              className="btn btn-link btn-sm text-secondary px-0"
            >
                <i className="fas fa-comment f-10" /> 
                <span className='f-12 ml-1'>{item.forums.length} Balasan</span>
            </button>
          </div>
          {/* <div className="col-md-1"></div> */}
          <div className="col-12">

            {showReply == item.id ? <div className="row" style={{ marginTop: 15 }}>
              {/* <div className="col-md-1"></div> */}
              <div className="offset-1 col-11">
                <div className='d-flex align-items-center'>
                  <img
                    src={`${linkAvatar}`}
                    alt={name ? name : 'User'}
                    className="img-radius mr-2"
                    style={{ height: '30px', width: '30px' }}
                    onError={(e) => e.currentTarget.src = avatarDefaultUser}
                  />
                  <div className="input-group">
                    <input value={descReply} onChange={(v) => setDescReply(v.target.value)} type="text" className="form-control" placeholder="Berikan balasan" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                    <div className="input-group-append">
                      <button onClick={() => sendReply(item.id)} className="btn  btn-primary" type="button">Kirim</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column w-100 offset-1 col-11">
                {item.forums.map((val) => {
                  let dd = moment(val.updatedAt).add(30, "days").valueOf();
                  let date = dd < moment().valueOf() ? moment(val.updatedAt).format("DD MMM YYYY") : moment(val.updatedAt).fromNow()
                  return (
                    <div key={val.id} className="row mt-4 mb-3">
                      <div className="col">
                        <div className='d-flex align-items-center'>
                          <img
                            src={`${val.login.user.linkAvatar}`}
                            alt={val.login.user.name ? val.login.user.name : 'User'}
                            className="img-radius mb-2"
                            style={{ height: '30px', width: '30px' }}
                            onError={(e) => e.currentTarget.src = avatarDefaultUser}
                          />
                          <div className='ml-2'>
                            <p className="mb-0 d-flex align-items-center">
                              {/* <i className="fas fa-user mr-1" style={{ fontSize: "8px" }} /> */}
                              <span className='f-w-500'>{val.login.user.name}</span>
                            </p>
                            <p className="text-muted mb-2" style={{ fontSize: "10px" }} ><i className="feather icon-clock mr-1" />{date}</p>
                          </div>
                        </div>
                        <p>{val.description}</p>
                        {/* <button type="button" className="btn btn-outline-success"><i className="fas fa-thumbs-up"/> 200 suka</button>
                                                                          <button type="button" style={{marginLeft:10}} className="btn btn-outline-secondary"><i className="fas fa-reply"/> 1 balasan</button> */}
                      </div>
                      <div className='col-12 py-0'><hr className='m-0' /></div>
                    </div>
                  )
                })}
              </div>
            </div> : null}
          </div>
        </div>
      </div>
    </div>
  )
}
import React, { useEffect, useState, useRef } from 'react';
import { useBaseContext } from 'context/base';
import useChatContext from 'context/chat'
import AttachmentButton from './buttons/attachment';
import CancelButton from './buttons/cancel';
import ChatDropzone from './chat-dropzone';
import ChatItem from './chat-item';
import ChatOptions from './chat-options';
import BtnCloseReply from './partials/btn-close-reply';
import BtnGetOlderMessage from './partials/btn-get-older-message';
import ChatReplyPreviewer from './partials/chat-reply-previewer';
import LoadNewerMsgLoader from './partials/load-newer-msg-loader';
import "./styles.css";

const ChatRoom = () => {
  const { name } = JSON.parse(localStorage.getItem("user"))
  
  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()
  const { messages, current, contact, reply } = useChatContext()

  /* ======================================== Chat States ======================================= */
  const [title, setTitle]           = useState('')
  const [textToSend, setTextToSend] = useState('')
  const bottomChat                  = useRef()

  const [chatId, setChatId]                   = useState('')  
  const [userActive, setUserActive]           = useState(false)
  const [isAttachingFile, setIsAttachingFile] = useState(true)

  /* ======================================== Dropzone States ======================================= */
  const [fileTypes, setFileTypes]         = useState(null)
  const [fileToUpload, setFileToUpload]   = useState(null)
  const [fileMimeTypes, setFileMimeTypes] = useState('text')

  const dropzoneStates = {
    fileTypes,
    setFileTypes,
    fileToUpload,
    setFileToUpload,
    fileMimeTypes,
    setFileMimeTypes,
  }

  async function displayChat() {
    if (current.data.type === "group") {
      setTitle(current.data.title)

      const _toDisplay = messages.list.find(ml => ml.id === current.data.id)
      if (_toDisplay) {
        setChatId(_toDisplay._id)
      } else {
        const data = await messages.fetch.id(current.data)
        messages.add({...current.data, ...data})
        setChatId(data._id)
      }
      const isUserActive = contact.list.some(cl => cl.id === current.data.id)
      setUserActive(isUserActive);
    } else if (current.data.type === "siswa") {
      let participants = [];
      if (current.data._id) {
        const _message = messages.list.find((_ml) => _ml._id === current.data._id)
        setChatId(_message._id)
        participants = _message.participants
      } else {
        const data = await messages.fetch.id(current.data)
        messages.add({...current.data, ...data})
        setChatId(data._id)
        participants = data.participants
      }

      const sender       = participants?.find(p => p.name !== name)
      let   senderName   = sender?.name
      const isUserActive = contact.list.some(cl => cl.id === sender?.id) // true kalau ada lawan bicara di kontak
      
      if (current.data.title === name) {
        setTitle(senderName);
      } else {
        setTitle(current.data.title);
      }
      
      setUserActive(isUserActive);
    }
  }

  async function sendChat() {
    const id       = current.data.type === 'siswa' ? chatId : current.data.id
    const textName = textToSend
    const media    = fileToUpload ? JSON.stringify(fileToUpload) : null

    const form = {
      id,
      media,
      type      : current.data.type,
      title     : current.data.title,
      text      : textName,
      type_chat : fileTypes,
      nama      : name,
      reply_id  : reply?.data?._id,
    }
  
    current.loading.set(true)
    const response = await postRequest('chat', form)
    current.loading.set(false)

    if (response) {
      messages.push(chatId, response.data)
      !setChatId && setChatId(chatId)
      setTextToSend('')
      setFileToUpload(null)
      setIsAttachingFile(false)
      reply.reset()
    }
  }

  const cancelButtonHandler = () => {
    setIsAttachingFile(false)
    setFileToUpload(null)
    setFileMimeTypes('text')
    setFileTypes('text')
    setTextToSend('')
  }

  const attachButtonHandler = () => {
    setIsAttachingFile(true)
  }

  const handleTyping = (value) => {
    if (!isAttachingFile) setFileTypes('text')
    setTextToSend(value)
  }

  const scrollToLastChild = () => {
    bottomChat.current.scrollIntoView({ block: 'end' })
  }

  const resetInput = () => {
    setIsAttachingFile(false)
    setFileToUpload(null)
    setFileMimeTypes('text')
    setTextToSend('')
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (current.data) {
      displayChat()
      resetInput()
    }
  }, [current.data])

  useEffect(() => {
    resetInput()
  }, [messages.list])

  useEffect(() => {
    scrollToLastChild()
  }, [chatId])
  

  /* ======================================== Components ======================================== */
  const InactiveChat = ({ type }) => {
    if (type === 'group') {
      return (
        <span className="badge badge-secondary mt-1">
          Kelas tidak aktif/dihapus dari sistem
        </span>
      )
    } else if (type === 'siswa') {
      return (
        <span className="badge badge-secondary mt-1">
          User tidak aktif/dihapus dari sistem
        </span>
      )
    } else {
      return null
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <div 
      className="card chat-card"
      style={{ height: "100vh" }}
    >
      <>
        <div className="card-header d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column">
            <h6 className="m-0">{title}</h6>
            {userActive ? null : <InactiveChat type={current.data.type} />}
          </div>
          <ChatOptions />
        </div>
        <div className="card-body">
          <div
            className="bg-light rounded p-3" 
            style={{
              height    : "88%",
              overflowY : "auto",
              overflowX : "hidden",
            }}
          >
            <i 
              className="fas fa-comments f-32" 
              style={{
                color    : "#e9e9e9",
                position : "absolute",
                top      : "48%",
                left     : "48%"
              }} 
            />
            <div className='row m-b-20 justify-content-center'>
              <BtnGetOlderMessage />
            </div>
            
            {current.data.chats.length !== 0 ?
              current.data.chats.map((chatItem, cidx) => 
                <ChatItem 
                  key      = {`chatItem__${chatItem._id}__${cidx}`}
                  chatItem = {chatItem}
                  chatId   = {chatId}
                />)
              : null
            }
            
            <LoadNewerMsgLoader />

            <span ref={bottomChat}></span>
          </div>
          {userActive ?
            <div className="form-group m-t-15">
              <div id="chat-room__text-input">
                { 
                  isAttachingFile ? (
                    <CancelButton
                      handler={cancelButtonHandler}
                    />
                  ) : (
                    <AttachmentButton
                      handler={attachButtonHandler}
                    />
                  )
                }

                {
                  (isAttachingFile || reply.data) && (
                    <div id="chat-room__drop-zone" style={{ width: '100%' }}>
                      {
                        reply.data && (
                          <div id="reply-container">
                            <BtnCloseReply />
                            <ChatReplyPreviewer chatItem={reply.data} />
                          </div>
                        )
                      }
                      { isAttachingFile && <ChatDropzone dropzoneStates={dropzoneStates} /> }
                    </div>
                  )
                }

                <textarea
                  disabled    = {current.loading.status}
                  name        = "task-insert"
                  className   = "form-control bg-light rounded px-2 mr-2 ml-2"
                  style       = {{resize: "none"}}
                  placeholder = "Tulis pesan"
                  value       = {textToSend}
                  onChange    = {(event) => handleTyping(event.target.value)}
                />
                
                <div>
                  {current.loading.status ?
                    <button disabled type="button" className="btn btn-primary btn-icon">
                      <span className="spinner-border spinner-border-sm" role="status" />
                    </button>
                    :
                    <button 
                      disabled={!textToSend && !fileToUpload}
                      onClick={sendChat}
                      type="button"
                      className="btn btn-primary btn-icon"
                      title="Kirim"
                    >
                      <i className="fas fa-paper-plane" />
                    </button>
                  }
                </div>
              </div>
            </div>
            :
            <div className="form-group m-t-15">
              <textarea
                disabled
                name        = "task-insert"
                className   = "form-control btn-forbidden bg-light rounded px-2"
                style       = {{resize: "none"}}
                placeholder = "User ini tidak aktif"
              />
              <div
                style={{
                  position : "absolute",
                  top      : "10px",
                  right    : "20px"
                }}
              >
                <button disabled type="button" className="btn btn-forbidden btn-primary btn-icon" title="Kirim">
                  <i className="fas fa-paper-plane mr-1" />
                </button>
              </div>
            </div>
          }
        </div>
      </>
    </div>
  );
}

export default ChatRoom

import React from 'react'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'

export default function Pages() {
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName='Group' />
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header"></div>
              <div className="card-body"></div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React from 'react';

const NotFound = ({ text, icon }) => {
  return (
    <div className="col d-flex flex-column justify-content-center align-items-center">
      <i style={{ color: "#ccc" }} className={`${icon ? icon : 'fas fa-theater-masks'} f-80 text-center`} />
      <span style={{ color: "#bbb" }} className="f-16 f-w-500 text-center mt-2">{text ? text : 'Not found'}</span>
    </div>
  );
}

export default NotFound;
import React, { useEffect, useState } from 'react'

import { useBaseContext } from 'context/base'
import LoadingSpinner from 'component/loading'
import ErrorUI from 'component/error-ui'
import RaporSkeleton from './rapor-skeleton'
import RaporSuccess from './rapor-success'

const RaporTable = ({
  loadingTypes,
  category_id,
  group_id,
  loadingSearch,
  setLoadingSearch,
  semester_id,
}) => {
  /* ========================================= Constants ======================================== */
  const tableConfig = {
    headers: [
      'No.',
      'Nama',
      'Nilai LMS (Sistem)',
      'Nilai Rapor',
      'Predikat',
      'Deskripsi',
      'Status',
      'Catatan',
      'Actions',
    ],
    totalColumns: 9,
  }

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()
  
  /* ======================================= Local States ======================================= */
  const [result, setResult] = useState(null)
  
  /* ========================================= Functions ======================================== */
  async function getSiswaByCategory(category_id, group_id, semester_id) {
    if (category_id) {
      let searchQuery = `ta_semester_code=${semester_id}&category_id=${category_id}`
      if (group_id) searchQuery = `${searchQuery}&group_id=${group_id}`
      const response = await getRequest(
        `siswa-by-category?${searchQuery}`,
      )
      return response ? response.data : null
    } else {
      return null
    }
  }

  async function handleSearch(category_id, group_id, semester_id) {
    setLoadingSearch(loadingTypes.start)
    const data = await getSiswaByCategory(category_id, group_id, semester_id)
    setResult(data)
    setLoadingSearch(data === null ? loadingTypes.error : loadingTypes.success)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (category_id) {
      handleSearch(category_id, group_id, semester_id)
    }
  }, [category_id, group_id, semester_id])

  /* ========================================== Output ========================================== */
  return (
    <div className="card">
      <div className="card-body">
        {loadingSearch === loadingTypes.idle && (
          <RaporSkeleton
            tableConfig={tableConfig}
            title="Pilih Mata Pelajaran"
            bodyPlaceholder="Pilih mata pelajaran"
          />
        )}
        {loadingSearch === loadingTypes.start && (
          <RaporSkeleton
            tableConfig={tableConfig}
            title="Memuat..."
            bodyPlaceholder={<LoadingSpinner />}
          />
        )}
        {loadingSearch === loadingTypes.error && (
          <RaporSkeleton
            tableConfig={tableConfig}
            title="Terjadi Kesalahan :("
            bodyPlaceholder={<ErrorUI />}
          />
        )}
        {loadingSearch === loadingTypes.success && (
          <RaporSuccess
            tableConfig={tableConfig}
            result={result}
            semester_id={semester_id}
            category_id={category_id}
            group_id={group_id}
            handleSearch={handleSearch}
          />
        )}
      </div>
    </div>
  )
}

export default RaporTable

import CkeditorReadonly from 'component/ckeditor-readonly'
import React from 'react'
import { formatDate } from 'util/date'

export default function FormPenilaianEditHistory({ hooks }) {
  //========================= nilaiList example ===============================
  // const nilaiList = [
  //   {
  //     id        : '2e607c32-526d-4567-a77a-d316fdc10a58',
  //     score     : 100,
  //     note      : 'Coba3 Perfect',
  //     createdAt : ISO_Date()
  //   },
  // ]

  const { nilaiList } = hooks

  const NilaiList = () =>
    nilaiList.map((nilai, idx) => {
      return (
        <tr key={`form-penilaian-edit-history-` + idx}>
          <td
            className="text-center"
            scope="row"
          >
            {++idx}
          </td>
          <td>{nilai.created_by.name}</td>
          <td>{formatDate(nilai.createdAt)}</td>
          <td>{nilai.score}</td>
          <td><CkeditorReadonly data={nilai.note}/></td>
        </tr>
      )
    })

  return (
    <div>
      <h4 className="text-center pb-4">History</h4>
      {nilaiList?.length ? (
        <div style={{overflowX: 'scroll'}}>
          <table className="table table-sm">
            <thead className="thead-dark">
              <tr>
                <th
                  className="text-center"
                  scope="col"
                >
                  No
                </th>
                <th scope="col">Guru</th>
                <th scope="col">Tanggal</th>
                <th scope="col">Nilai</th>
                <th scope="col">Catatan Guru</th>
              </tr>
            </thead>
            <tbody>
              <NilaiList />
            </tbody>
          </table>
        </div>
      ) : (
        <p className='text-center'>Belum ada history</p>
      )}
    </div>
  )
}

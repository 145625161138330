import React from 'react'
import ForumDetail from 'component/forum/forum-detail'
import ForumGroupDisabled from 'component/forum/forum-group-disabled'
import useContentPlayerContext from 'context/content-player'

export default function SegmentForum() {
  /* ====================================== Consume Context ===================================== */
  const { activity } = useContentPlayerContext()

  /* ========================================== Output ========================================== */
  return activity.isContentCompleted ? (
    <ForumDetail />
  ) : (
    <ForumGroupDisabled message="Diskusi dapat diakses setelah konten selesai" />
  )
}

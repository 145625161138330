import React, { useEffect, useState } from 'react'

import { useBaseContext } from 'context/base'
import useContentPlayerContext from 'context/content-player'
import useMainContent from 'context/main-content'
import useFeedbackForm from 'context/feedback-form'
import LoadingSpinner from 'component/loading'
import AddFeedPG from './add'
import EditFeedPG from './edit'

export default function FeedbackPilihanGanda() {
  /* ====================================== Consume Context ===================================== */
  const { postRequest, putRequest } = useBaseContext()
  const { content, activity }       = useContentPlayerContext()
  const { shouldSendCCA }           = useMainContent()
  const { feed, event }             = useFeedbackForm()

  /* ========================================== States ========================================== */
  const [isUserSubmited, setIsUserSubmited] = useState(false)

  // FORM FEEDBACK
  const [feedResponId, setFeedResponId] = useState('')
  const [feedTempId, setFeedTempId] = useState('')
  const [isFeedResponIdEmpty, setIsFeedResponIdEmpty] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  /* ========================================= Functions ======================================== */
  function handleChangeFeedResponId(value) {
    setFeedResponId(value)
  }

  function formValidation() {
    if (feedResponId) {
      setIsFeedResponIdEmpty(false)
      formSubmit()
    } else {
      if (!feedResponId) {
        setIsFeedResponIdEmpty(true)
      }
    }
  }

  async function formSubmit() {
    let form = new FormData()
    let response = null
    form.append('feed_respon_id', feedResponId)
    form.append('feed_type', 2)
    form.append('content_id', content.id)
    setIsLoading(true)
    if (isUserSubmited) {
      form.append('feed_temp_id', feedTempId)
      response = await putRequest('feed-temp', form)
    } else {
      form.append('feed_id', feed.data.id)
      response = await postRequest('feed-temp', form)
    }
    if (response) {
      event.report(content.id)
      setIsUserSubmited(true)
      setIsLoading(false)
      event.set('submit-feed')
      shouldSendCCA && (await activity.report(true))
    } else {
      console.error('ERROR', response)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (feed.temps.length) {
      setIsUserSubmited(true)
      setFeedTempId(feed.temps[0].id)
      setFeedResponId(feed.temps[0].feed_respon_id)
    }
  }, [feed])

  /* ======================================= Shared Props ======================================= */
  const sharedProps = {
    isFeedResponIdEmpty,
    isLoading,
    formValidation,
    handleChangeFeedResponId,
  }

  /* ========================================== Output ========================================== */
  return (
    <form className="mt-3 border p-3 rounded">
      <h5>Berikan feedback terhadap materi:</h5>
      {event.loading ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {isUserSubmited ? (
            <EditFeedPG sharedProps={sharedProps} />
          ) : (
            <AddFeedPG sharedProps={sharedProps} />
          )}
        </>
      )}
    </form>
  )
}

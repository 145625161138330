import React from 'react'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import FileSiswa from 'component/file-siswa'
import Info from './info'
import Groups from './groups'

export default function AktifitasSiswaProfile() {
  /* ========================================= Constants ======================================== */
  const links = [{ url: 'laporan-aktifitas-siswa', name: 'Aktivitas Siswa' }]

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Profile" links={links} />
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <ul
                  className="nav nav-pills bg-white"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active text-uppercase"
                      data-toggle="tab"
                      href="#info"
                      role="tab"
                    >
                      Profile
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#groups"
                      role="tab"
                    >
                      Kelas
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#files"
                      role="tab"
                    >
                      Files
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="tab-content"
              id="myTabContent"
            >
              <div
                className="tab-pane fade show active"
                id="info"
                role="tabpanel"
              >
                <Info />
              </div>
              <div
                className="tab-pane fade show"
                id="groups"
                role="tabpanel"
              >
                <Groups />
              </div>
              <div
                className="tab-pane fade show"
                id="files"
                role="tabpanel"
              >
                <div className="card user-profile-list">
                  <div className="card-body">
                    <FileSiswa />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

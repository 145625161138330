import React from 'react'

export default function NotFound() {
  return (
    <div className="auth-wrapper maintance">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="text-center">
              <img
                src="admin/assets/images/maintance/404.png"
                alt=""
                className="img-fluid"
              />
              <h5 className="text-muted my-4">Oops! Page not found!</h5>
              <form action="/dashboard">
                <button className="btn waves-effect waves-light btn-primary mb-4">
                  <i className="feather icon-refresh-ccw mr-2" />
                  Reload
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import LoadingSpinner from 'component/loading'
import { avatarDefaultUser } from 'util/constant'

export default function SiswaListOrangtua() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* =================================== Consume Local Storage ================================== */
  const { id } = JSON.parse(localStorage.getItem('user'))

  /* ======================================= Local States ======================================= */
  const [isLoading, setIsLoading] = useState(false)
  const [siswas, setSiswas]       = useState([])

  /* ========================================= Functions ======================================== */
  async function checkRole() {
    const { role_type } = JSON.parse(localStorage.getItem('role_active'))
    if (role_type !== 'orangtua') {
      history.push('/dashboard')
    } else {
      await getSiswa()
    }
  }

  async function getSiswa() {
    const response = await getRequest(`parent-to-siswa?user_id=${id}`)
    setIsLoading(false)
    if (response) {
      setSiswas(response.data)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    setIsLoading(true)
    checkRole()
  }, [])

  /* ========================================== Styles ========================================== */
  const myStyle = {
    background: '#fff',
    color: '#404040',
    position: 'fixed',
    display: 'flex',
    fontWeight: '400',
    justifyContent: 'space-between',
    zindex: '98',
    bottom: '25px',
    right: '30px',
    fontSize: '15px',
    padding: '10px 20px',
    borderRadius: '30px',
    boxShadow: '0 1px 15px rgba(32, 33, 36, 0.28)',
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Siswa" />

        <div className="card">
          <div className="card-header">
            <h5>Daftar siswa</h5>
          </div>
          <div className="card-body">
            {isLoading ? (
              <div className="row">
                <div className="col">
                  <LoadingSpinner />
                </div>
              </div>
            ) : (
              <div className="row">
                {siswas.length !== 0 ? (
                  <>
                    {siswas.map((item, index) => (
                      <SiswaItem
                        key={index}
                        data={item}
                      />
                    ))}
                  </>
                ) : (
                  <div className="col d-flex flex-column justify-content-center">
                    <i
                      className="fas fa-user f-60 text-center mb-3"
                      style={{
                        color: '#ccc',
                      }}
                    />
                    <h6
                      className="text-center"
                      style={{
                        color: '#bbb',
                      }}
                    >
                      Siswa belum terdaftar
                    </h6>
                  </div>
                )}
              </div>
            )}
          </div>
          <a
            style={myStyle}
            href="https://wa.me/082267219008"
            target="_blank"
            title="Show Chat"
          >
            <svg
              width="20"
              viewBox="0 0 24 24"
            >
              <defs />
              <path
                fill="#eceff1"
                d="M20.5 3.4A12.1 12.1 0 0012 0 12 12 0 001.7 17.8L0 24l6.3-1.7c2.8 1.5 5 1.4 5.8 1.5a12 12 0 008.4-20.3z"
              />
              <path
                fill="#4caf50"
                d="M12 21.8c-3.1 0-5.2-1.6-5.4-1.6l-3.7 1 1-3.7-.3-.4A9.9 9.9 0 012.1 12a10 10 0 0117-7 9.9 9.9 0 01-7 16.9z"
              />
              <path
                fill="#fafafa"
                d="M17.5 14.3c-.3 0-1.8-.8-2-.9-.7-.2-.5 0-1.7 1.3-.1.2-.3.2-.6.1s-1.3-.5-2.4-1.5a9 9 0 01-1.7-2c-.3-.6.4-.6 1-1.7l-.1-.5-1-2.2c-.2-.6-.4-.5-.6-.5-.6 0-1 0-1.4.3-1.6 1.8-1.2 3.6.2 5.6 2.7 3.5 4.2 4.2 6.8 5 .7.3 1.4.3 1.9.2.6 0 1.7-.7 2-1.4.3-.7.3-1.3.2-1.4-.1-.2-.3-.3-.6-.4z"
              />
            </svg>{' '}
            Hubungi CS
          </a>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

const SiswaItem = ({ data }) => {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ========================================= Functions ======================================== */
  function handleDetail() {
    history.push(`/siswa/${data.user.id}`, { siswa: data.user })
  }

  /* ========================================== Output ========================================== */
  return (
    <div className="col-12 col-md-6 col-xl-4 mb-4">
      <button
        onClick={handleDetail}
        className="btn text-left card mb-0 d-flex flex-row p-3 w-100 border rounded"
        title={data.user.name}
        type="button"
      >
        <img
          src={`${data.user.linkAvatar}`}
          alt={data.user.name}
          className="img-radius align-top m-r-20"
          style={{ width: '65px', height: '65px' }}
          onError={(event) => {
            event.currentTarget.src = avatarDefaultUser
          }}
        />
        <div className="d-inline-block">
          <h6
            style={{ fontWeight: '500' }}
            className="mb-0 mt-1"
          >
            {data.user.name}
          </h6>
          <p
            className="mb-1 text-muted f-8"
            style={{ fontStyle: 'italic' }}
          >
            No. Induk {data.user.nim ? data.user.nim : '-'}
          </p>
          <p className="m-b-0">
            <i className="fas fa-envelope text-primary mr-1" />
            {data.user.email ? data.user.email : '-'}
          </p>
          <p className="m-b-0">
            <i className="fas fa-phone text-primary mr-1" />
            {data.user.phone ? data.user.phone : '-'}
          </p>
        </div>
      </button>
    </div>
  )
}

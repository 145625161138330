import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import usePreviewRapor from 'context/preview-rapor'

export default function TimelineChart() {
  /* ====================================== Consume Context ===================================== */
  const { rapor } = usePreviewRapor()

  /* ========================================== Options ========================================= */
  const chartOpt = {
    chart: {
      type: 'column',
      height: 265
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      categories: rapor.chart?.categories?.length ? rapor.chart?.categories : [...Array(24)].map((_, idx) => idx+':00'),
      crosshair: true,
      title: {
        text: '',
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    legend: {
      enabled: false,
    },
    series: rapor.chart?.series.length ? rapor.chart?.series : [
      {
        name: rapor.user?.name,
        data: [...Array(24).fill(0)]
      }
    ],
  }

  /* ========================================== Output ========================================== */
  return (
    <div className="col-6 text-center">
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOpt}
      />
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { useBaseContext } from 'context/base'
import Swal from 'sweetalert2'

export default function SurveyGroup({ dataSurvey }) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest, deleteRequest } = useBaseContext()
  
  /* ======================================= Local States ======================================= */
  const [surveyGroup, setSurveyGroup] = useState({ baris: [], kolom: []})
  const [surveyId]                    = useState(dataSurvey.id)
  const [baris, setBaris]             = useState('')
  const [kolom, setKolom]             = useState('')
  const [urutan, setUrutan]           = useState('')
  
  /* ========================================= Functions ======================================== */
  async function getSurveyGroup() {
    let response = await getRequest(`survey-group?id=${surveyId}`)
    if (response) {
      var data = response.data
      setSurveyGroup({
        baris: data.baris,
        kolom: data.kolom,
      })
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getSurveyGroup()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col">
                  <h6 className="m-0">Pertanyaan Pilihan Group</h6>
                </div>
              </div>
            </div>
            <div className="card-body">
              <button
                onClick={() => {
                  window.$('#modal-survey-group').modal('show')
                  window.$('#exampleModalLabel').html('Tambah baris')
                  window.$('.kolom').hide()
                  window.$('.baris').show()
                  setBaris('')
                  setKolom(null)
                  setUrutan(0)
                }}
                className={'btn btn-primary'}
                style={{ width: 150 }}
              >
                Tambah baris <i className={'fas' + ' fa-angle-down'}></i>
              </button>
              &nbsp;&nbsp;
              <button
                onClick={() => {
                  window.$('#modal-survey-group').modal('show')
                  window.$('#exampleModalLabel').html('Tambah kolom')
                  window.$('.kolom').show()
                  window.$('.baris').hide()
                  setBaris(null)
                  setKolom('')
                  setUrutan(0)
                }}
                className={'btn btn-outline-primary'}
                style={{ width: 150 }}
              >
                Tambah kolom <i className={'fas' + ' fa-angle-right'}></i>
              </button>
              <br />
              <br />
              <div className="dt-responsive table-responsive">
                <table className={'table'}>
                  <thead>
                    <tr>
                      <th>
                        {surveyGroup.baris.length > 0 &&
                        surveyGroup.kolom.length > 0 ? (
                          <button
                            type={'button'}
                            onClick={() => {
                              window
                                .$('#modal-survey-group-modifikasi')
                                .modal('show')
                            }}
                            className={'btn btn-warning'}
                          >
                            Modifikasi
                          </button>
                        ) : null}                        
                      </th>
                      {surveyGroup.kolom.map((val, i) => {
                        return (
                          <th
                            key={i}
                            style={{ textAlign: 'center' }}
                          >
                            {val.text}
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {surveyGroup.baris.map((val, i) => {
                      return (
                        <tr key={i}>
                          <td>{val.text}</td>
                          {surveyGroup.kolom.map((ii, idx) => {
                            return (
                              <td
                                key={idx}
                                style={{ textAlign: 'center' }}
                              >
                                <input
                                  type="radio"
                                  name={'id_' + val.id}
                                />
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade "
        id="modal-survey-group"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="exampleModalLabel"
              >
                Modal title
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group row baris">
                  <label
                    htmlFor="inputPassword"
                    className="col-sm-2 col-form-label"
                  >
                    Text
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      value={baris}
                      onChange={(e) => setBaris(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group row kolom">
                  <label
                    htmlFor="inputPassword"
                    className="col-sm-2 col-form-label"
                  >
                    Text
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      value={kolom}
                      onChange={(e) => setKolom(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputPassword"
                    className="col-sm-2 col-form-label"
                  >
                    Urutan
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="number"
                      defaultValue={urutan}
                      onChange={(e) => setBaris(e.target.urutan)}
                      className="form-control"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Tutup
              </button>
              <button
                onClick={() => simpan()}
                type="button"
                className="btn btn-primary"
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade "
        id="modal-survey-group-modifikasi"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="exampleModalLabel"
              >
                Modifikasi
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5>Baris</h5>
              <table className={'table'}>
                <thead>
                  <tr>
                    <th>Text</th>
                    <th>Urutan</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {surveyGroup.baris.map((val, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <input
                            type="text"
                            className={'form-control'}
                            defaultValue={val.text}
                            name={`baris_text[${val.id}]`}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className={'form-control'}
                            defaultValue={val.urutan}
                            name={`baris_urutan[${val.id}]`}
                          />
                        </td>
                        <td>
                          <button
                            onClick={() => editGroups('baris', val.id)}
                            className={'btn btn-outline-warning' + ' btn-sm'}
                          >
                            <i className={'fas fa-pencil-alt'}></i>
                          </button>
                          &nbsp;&nbsp;
                          <button
                            onClick={() => deleteGroups('baris', val.id)}
                            className={'btn btn-outline-danger' + ' btn-sm'}
                          >
                            <i className={'fas fa-trash-alt'}></i>
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <h5>Kolom</h5>
              <table className={'table'}>
                <thead>
                  <tr>
                    <th>Text</th>
                    <th>Urutan</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {surveyGroup.kolom.map((val, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <input
                            type="text"
                            className={'form-control'}
                            defaultValue={val.text}
                            name={`kolom_text[${val.id}]`}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className={'form-control'}
                            defaultValue={val.urutan}
                            name={`kolom_urutan[${val.id}]`}
                          />
                        </td>
                        <td>
                          <button
                            onClick={() => editGroups('kolom', val.id)}
                            className={'btn btn-outline-warning' + ' btn-sm'}
                          >
                            <i className={'fas fa-pencil-alt'}></i>
                          </button>
                          &nbsp;&nbsp;
                          <button
                            onClick={() => deleteGroups('kolom', val.id)}
                            className={'btn btn-outline-danger' + ' btn-sm'}
                          >
                            <i className={'fas' + ' fa-trash-alt'}></i>
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Tutup
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  async function editGroups(type, id) {
    window.$('#modal-survey-group-modifikasi').modal('hide')
    Swal.fire({
      title: 'Konfirmasi',
      text: 'Kamu yakin akan mengubah data ini?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ubah!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        var text = window.$(`input[name="${type}_text[${id}]"]`).val()
        var urutan = window.$(`input[name="${type}_urutan[${id}]"]`).val()
        let response = await putRequest('survey-group', {
          type: type,
          id: id,
          text: text,
          urutan: urutan,
        })
        if (response) {
          getSurveyGroup()
        }
        window.$('#modal-survey-group-modifikasi').modal('show')
      } else {
        window.$('#modal-survey-group-modifikasi').modal('show')
      }
    })
  }
  async function simpanModifikasi() {
    var a = window.$('#form-baris').serialize()
  }
  async function deleteGroups(type, id) {
    window.$('#modal-survey-group-modifikasi').modal('hide')
    Swal.fire({
      title: 'Konfirmasi',
      text: 'Kamu yakin akan menghapus data ini?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await deleteRequest(
          `survey-group?type=${type}&id=${id}`,
        )
        if (response) {
          getSurveyGroup()
        }
        window.$('#modal-survey-group-modifikasi').modal('show')
      } else {
        window.$('#modal-survey-group-modifikasi').modal('show')
      }
    })
  }
  async function simpan() {
    if (kolom || baris) {
      let response = await postRequest('survey-group', {
        kolom: kolom,
        baris: baris,
        urutan: 0,
        survey_id: surveyId,
      })
      if (response) {
        setKolom('')
        setBaris('')
        setUrutan(0)
        getSurveyGroup()
        window.$('#modal-survey-group').modal('hide')
      }
    } else {
      window.notification(
        'Terjadi kesalahan',
        'Mohon lengkapi forms data',
        'error',
      )
    }
  }
}

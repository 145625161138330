import React, { useEffect, useState, useRef } from 'react'

import { useBaseContext } from 'context/base'
import useContentPlayerContext from 'context/content-player'
import useMainContent from 'context/main-content'
import useFeedbackForm from 'context/feedback-form'
import LoadingSpinner from 'component/loading'
import AddFeedEssay from './add'
import EditFeedEssay from './edit'

export default function FeedbackEssay() {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest } = useBaseContext()
  const { content, activity }                   = useContentPlayerContext()
  const { event, feed }                         = useFeedbackForm()
  const { shouldSendCCA }                       = useMainContent()

  /* ========================================== States ========================================== */
  const [isUserSubmited, setIsUserSubmited] = useState(false)

  // FORM FEEDBACK
  const [feedText, setFeedText]               = useState('')
  const [feedTempId, setFeedTempId]           = useState('')
  const [isFeedTextEmpty, setIsFeedTextEmpty] = useState(false)
  const [isLoading, setIsLoading]             = useState(false)
  
  // FEEDBACK COMMENT
  const [feedComment, setFeedComment]             = useState([])
  const [masterFeedComment, setMasterFeedComment] = useState('')
  const [listSuggest, setListSuggest]             = useState([])

  /* =========================================== Refs =========================================== */
  const refFeedText = useRef()

  /* ========================================= Functions ======================================== */
  function handleChangeFeedText(value) {
    setFeedText(value)
    if (value) {
      setIsFeedTextEmpty(false)
    }
  }

  function formValidation() {
    if (feedText) {
      setIsFeedTextEmpty(false)
      formSubmit()
    } else {
      if (!feedText) {
        setIsFeedTextEmpty(true)
        refFeedText.current.focus()
      }
    }
  }

  async function formSubmit() {
    let form = new FormData()
    let response = null
    form.append('feed_respon_text', feedText)
    form.append('feed_type', feed.type)
    form.append('content_id', content.id)
    setIsLoading(true)
    if (isUserSubmited) {
      form.append('feed_temp_id', feedTempId)
      response = await putRequest('feed-temp', form)
    } else {
      form.append('feed_id', feed.data.id)
      response = await postRequest('feed-temp', form)
    }
    if (response) {
      activity.setIdDone(content.id)
      setIsUserSubmited(true)
      setIsLoading(false)
      event.set('submit-feed')
      shouldSendCCA && (await activity.report(true))
    } else {
      console.error('ERROR', response)
    }
  }

  async function getFeedComment(content_id, parent_id) {
    try {
      let response = await getRequest(
        `feed-temp-comment?parent_id=${parent_id}&content_id=${content_id}`,
      )
      if (response) {
        setFeedComment(response.data.comments)
        setMasterFeedComment(response.data.data)
      }
    } catch (error) {
      console.error('ERROR: ', error)
    }
  }

  async function getSuggestionsByUser() {
    try {
      const response = await getRequest(`feed-temp-suggestion?content_id=`)
      if (response) setListSuggest(response.data)
    } catch (error) {
      console.error('ERROR: ', error)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (feed.temps.length) {
      setIsUserSubmited(true)
      setFeedText(feed.temps[0].feed_respon_text)
      setFeedTempId(feed.temps[0].id)
      getFeedComment(content.id, feed.temps[0].id)
      getSuggestionsByUser()
    }
  }, [feed.data])

  /* ======================================= Shared Props ======================================= */
  const sharedProps = {
    refFeedText,
    feedText,
    handleChangeFeedText,
    isFeedTextEmpty,
    isLoading,
    formValidation,
    feedComment,
    masterFeedComment,
    getFeedComment,
    listSuggest,
  }

  /* ========================================== Output ========================================== */
  return (
    <form className="mt-3 border p-3 rounded">
      <h5>Berikan feedback terhadap materi:</h5>
      {event.loading ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : isUserSubmited ? (
        <EditFeedEssay sharedProps={sharedProps} />
      ) : (
        <AddFeedEssay sharedProps={sharedProps} />
      )}
    </form>
  )
}

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/id'
import { useBaseContext } from 'context/base'
import LoadingSpinner from 'component/loading'
import { avatarDefaultUser } from 'util/constant'

export default function GroupContent() {
  /* ========================================== Helpers ========================================= */
  const { contentId } = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [data, setData] = useState([])

  const [isLoading, setIsLoading]             = useState(false)
  const [isForumEmpty, setIsForumEmpty]       = useState(false)
  const [forumDetailData, setForumDetailData] = useState(null)
  const [forums, setForums]                   = useState([])
  const [descGroup, setDescGroup]             = useState('')

  /* ========================================= Functions ======================================== */
  async function getForum() {
    setIsLoading(true)
    const response = await getRequest(`forum?content_id=${contentId}`)
    setIsLoading(false)
    if (response) {
      if (response.data.length === 0) {
        setIsForumEmpty(true)
      } else {
        setIsForumEmpty(false)
      }
      setData(response.data)
      setDescGroup(response.group_description)
    }
  }

  async function getForumDetail(forumId) {
    setIsLoading(true)
    setForumDetailData(null)
    const response = await getRequest('forum/' + forumId)
    setIsLoading(false)
    if (response) {
      setForumDetailData(response.data)
      setForums(response.data.forums)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getForum()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div className="row">
      <div className="col-12 col-md-6 col-xl-3 mb-4 mb-md-0">
        <div
          id="filters-side"
          className="button-group scroll-div border-right border-left"
          style={{
            height: '100%',
            overflowY: 'auto',
          }}
        >
          {isForumEmpty ? (
            <div className="col d-flex flex-column justify-content-center h-100">
              <h6
                className="text-center"
                style={{
                  color: '#ccc',
                }}
              >
                Belum ada group
              </h6>
            </div>
          ) : (
            <>
              {data.map((item) => (
                <GroupItem
                  key={item.id}
                  item={item}
                />
              ))}
            </>
          )}
        </div>
      </div>
      <div className="col-12 col-md-6 col-xl-9 p-4 p-md-3">
        <h5 style={{ fontWeight: '500' }}>{descGroup}</h5>
        <p>{forumDetailData ? forumDetailData.title : ''}</p>
        <hr />
        {isLoading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: '100%' }}
          >
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {forums.length !== 0 ? (
              <>
                {forums.map((forum) => (
                  <ForumDetail
                    key={forum.id}
                    item={forum}
                  />
                ))}
              </>
            ) : (
              <div className="col d-flex flex-column justify-content-center h-100">
                <i
                  className="fas fa-comment-dots f-60 text-center mb-3"
                  style={{
                    color: '#ccc',
                  }}
                />
                <h6
                  className="text-center"
                  style={{
                    color: '#ccc',
                  }}
                >
                  Belum ada diskusi
                </h6>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )

  function GroupItem({ item }) {
    const [isHover, setIsHover] = useState(false)
    let dd = moment(item.updatedAt).add(30, 'days').valueOf()
    let date =
      dd < moment().valueOf()
        ? moment(item.updatedAt).format('DD MMM YYYY')
        : moment(item.updatedAt).fromNow()

    return (
      <>
        <button
          className="button btn btn-block text-left m-0 border-0 btn-outline-secondary has-ripple"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onClick={() => getForumDetail(item.id)}
        >
          <h6
            style={{ fontSize: '14px' }}
            className={isHover ? 'text-light' : 'text-dark'}
          >
            {item.title}
          </h6>
          <h6
            style={{ fontSize: '10px', fontWeight: '400' }}
            className={isHover ? 'text-light' : 'text-dark'}
          >
            <i className="feather icon-clock mr-1" />
            {date}
          </h6>
          {/* <h6 style={{ fontSize: "12px", fontWeight: '400' }} className={isHover ? "text-light" : "text-dark"}>{item.description}</h6> */}
        </button>
        <hr className="m-0" />
      </>
    )
  }

  function ForumDetail({ item }) {
    let dd = moment(item.updatedAt).add(30, 'days').valueOf()
    let date =
      dd < moment().valueOf()
        ? moment(item.updatedAt).format('DD MMM YYYY')
        : moment(item.updatedAt).fromNow()
    const [showReply, setShowReply] = useState('')
    
    return (
      <div
        className="row pt-2 pb-4 mb-2 mx-md-2 border-bottom rounded"
        key={item.id}
      >
        <div className="col-2 col-md-1">
          <img
            src={`${item.login.user.linkAvatar}`}
            alt={item.login.user.name}
            className="img-radius mb-2"
            style={{ width: '50px', height: '50px' }}
            data-toggle="tooltip"
            title={item.login.user.name}
            onError={(event) => (event.currentTarget.src = avatarDefaultUser)}
          />
        </div>
        <div className="col-10 col-md-11">
          <p className="mb-0 f-w-500">{item.login.user.name}</p>
          <p
            className="text-muted"
            style={{ fontSize: '10px' }}
          >
            <i className="feather icon-clock" /> {date}
          </p>
          <p>{item.description}</p>
          <button
            type="button"
            className={`btn ${
              item.likes == 1 ? 'btn-success' : 'btn-outline-success'
            } btn-sm`}
            disabled
          >
            <i className="fas fa-thumbs-up mr-1" />
            {item.likes}
          </button>
          <button
            onClick={() => {
              setShowReply(showReply == item.id ? '' : item.id)
            }}
            type="button"
            style={{ marginLeft: 10 }}
            className="btn btn-outline-secondary btn-sm"
          >
            <i className="fas fa-reply" /> {item.forums.length} balasan
          </button>
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-11">
          {showReply == item.id ? (
            <div
              className="row"
              style={{ marginTop: 15 }}
            >
              <div className="d-flex flex-column w-100 col-12">
                {item.forums.map((val) => {
                  let dd = moment(val.updatedAt).add(30, 'days').valueOf()
                  let date =
                    dd < moment().valueOf()
                      ? moment(val.updatedAt).format('DD MMM YYYY')
                      : moment(val.updatedAt).fromNow()
                  return (
                    <div
                      key={val.id}
                      className="row mt-3 bg-light rounded p-3 p-md-2"
                    >
                      <div className="col-2 col-md-1">
                        <img
                          src={`${val.login.user.linkAvatar}`}
                          alt={val.login.user.name}
                          className="img-radius mb-2"
                          style={{ width: '50px', height: '50px' }}
                          data-toggle="tooltip"
                          title={val.login.user.name}
                          onError={(event) =>
                            (event.currentTarget.src = avatarDefaultUser)
                          }
                        />
                      </div>
                      <div className="col-10 col-md-11">
                        <p className="mb-0">{val.login.user.name}</p>
                        <p
                          className="text-muted"
                          style={{ fontSize: '10px' }}
                        >
                          <i className="feather icon-clock" /> {date}
                        </p>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

import React, { Fragment } from "react"

export default function BaseTableSemester({ semesterName = 1, data = [], listMapel = [] }) {
  // const listMapel = ['PAI', 'PPKN', 'BINDO', 'MTK', 'FISIKA', 'KIMIA', 'BIOLOGI', 'SOSIOLOGI', 'EKONOMI', 'SEJARAH', 'GEOGRAFI', 'BING', 'PJOK', 'INFORMATIKA', 'SBDP', 'BAHASA ASING']

  const totalLengthOtherFeatureForListMapel = listMapel.length

  return (
    <div className="dt-responsive table-responsive overflow-auto container-base-table-semester">
      <table className="table base-table-semester">
        <thead>
          <tr className="base-table-semester__first-header">
            <th rowSpan={3}>No</th>
            <th rowSpan={3}>NISN / NIPD</th>
            <th rowSpan={3} style={{ width: 200 }}>NAMA</th>
          </tr>
          <tr className="base-table-semester__mapel-average">
            <th colSpan={totalLengthOtherFeatureForListMapel} className="text-center">Mata Pelajaran</th>
            <th rowSpan={2}>RATA-RATA</th>
          </tr>
          <tr className="base-table-semester__list-mapel">
            {listMapel?.map((mapel, index) => (
              <th key={`list-mapel-semester-${semesterName}-index${index}`}>
                { mapel }
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((d, index) => (
            <tr key={`list-mapel-${d?.id}-semester-${semesterName}-index${index}`}>
              <td>{ index + 1}</td>
              <td style={{ wordBreak: 'break-word', whiteSpace: 'normal', width: 180 }}>{d?.nim}</td>
              <td style={{ wordBreak: 'break-word', whiteSpace: 'normal', width: 200 }}>{d?.name}</td>
              {d?.score?.map((sc, scIndex) => (
                <Fragment key={`list-score-mapel-${sc?.id}-semester-${semesterName}-index${scIndex}-parentIndex${index}`}>
                  <td>{sc?.score || '-'}</td>
                </Fragment>
              ))}
              <td>{d?.average || '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
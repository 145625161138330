import React from 'react'
import FeedbackForm from 'component/feedback-siswa/feedback-form'
import useContentPlayerContext from 'context/content-player'

export default function SegmentFeedback() {
  /* ====================================== Consume Context ===================================== */
  const { activity, content } = useContentPlayerContext()
  
  /* ========================================== Output ========================================== */
  return (
    <>
      {/* if feedback required, Immediately show feedback form for content text, document, and iFrame */}
      {content.feedback && [1, 2, 3, 4, 5, 7, 9].includes(content.content_type_id) && <FeedbackForm />}

      {/* if feedback required, Show feedback form when media finished or quiz/assignment/survey finished */}
      {content.feedback && [6, 8].includes(content.content_type_id) && (activity.isContentSubmitted || activity.isContentCorrected) && <FeedbackForm />}
    </>
  )  
}

import React from 'react'

export default function TabPaneWrapper({ children }) {
  return (
    <div
      className       = "tab-pane fade"
      id              = "pills-kontak"
      role            = "tabpanel"
      aria-labelledby = "pills-kontak-tab"
      style           = {{ height: '100%' }}
    >
      {children}
    </div>
  )
}

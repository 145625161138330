import React from 'react'
import Select from 'react-select'
import { popUpWindow } from 'util/global'

const FormSelectQuiz = ({
  handleChangeQuiz,
  existingQuiz,
  existingQuizId,
  isQuizInvalid,
  refSelectQuiz,
  getQuizList,
}) => (
  <div className="form-group row mb-5">
    <label className="col-form-label col-sm-3">Quiz</label>
    <div className="row col-sm-9">
      {/* Select Quiz */}
      <div className="col-sm-8">
        <Select
          ref={refSelectQuiz}
          className=""
          isSearchable={true}
          isMulti={false}
          onMenuOpen={() => getQuizList()}
          placeholder="Pilih Quiz"
          noOptionsMessage={() => 'Data quiz tidak dtemukan'}
          isClearable={true}
          options={existingQuiz}
          value={existingQuizId}
          onChange={handleChangeQuiz}
        />
        {isQuizInvalid ? (
          <span className="col small form-text text-danger">
            Harap pilih quiz.
          </span>
        ) : null}
      </div>

      {/* Add new quiz */}
      <div className="col-sm-4">
        <button
          type="button"
          className="btn btn-primary btn-sm mt-2 mt-md-1"
          onClick={() => popUpWindow('/kelola-quiz', window, 960, 600)}
        >
          <i className="feather icon-settings mr-1" />
          <span>Kelola Quiz</span>
        </button>
      </div>
    </div>
  </div>
)

export default FormSelectQuiz

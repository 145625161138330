import React from 'react'
import useContentPlayerContext from 'context/content-player'
import useFeedbackForm from 'context/feedback-form'

export default function FeedFormAlert() {
  /* ====================================== Consume Context ===================================== */
  const { content } = useContentPlayerContext()
  const { feed } = useFeedbackForm()

  /* ========================================= Constants ======================================== */
  const userId = JSON.parse(localStorage.getItem('user')).id

  const _isFeedRequired   = content.feedback
  const _isGivingFeedback = feed.temps.some((temp) => temp.user_id === userId)

  return (
    _isFeedRequired &&
    !_isGivingFeedback && (
      <div
        className="mt-2 border rounded alert alert-danger w-100"
        role="alert"
      >
        <strong>
          <i className="fas fa-info-circle mr-1" />
          Anda belum memberikan feedback pada materi
        </strong>
      </div>
    )
  )
}

import React from 'react'

export default function BackButton({ hooks }) {
  const { setIsHistory } = hooks
  
  return (
    <button
      type="button"
      className="btn mr-2 btn-icon btn-sm btn-danger"
      title="History"
      onClick={() => setIsHistory(false)}
    >
      <i className="fas fa-times" />
    </button>
  )
}

import React from 'react'
import Actions from './actions'
import HistoryButton from './history-button'
import Profile from './profile'
import Status from './status'

export default function Item({ item, setHistoryRapor, selectSemester, selectedBlock, selectedGroup }) {    
  return (
    <tr>
      <td><Profile item={item} /></td>
      <td>{item?.nim || '-'}</td>
      <td>{item?.username}</td>
      <td><Status item={item} /></td>
      <td>
        <HistoryButton
          item={item}
          setHistoryRapor={setHistoryRapor}
        />
      </td>
      <td><Actions item={item} selectSemester={selectSemester} selectedBlock={selectedBlock} selectedGroup={selectedGroup} /></td>
    </tr>
  )
}

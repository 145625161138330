import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import Select from 'react-select'

import { useBaseContext } from 'context/base'
import NotFound from 'component/not-found'
import LoadingSpinner from 'component/loading'

export default function Scores({ userId }) {
  /* ========================================== Helpers ========================================= */
  const params = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* =================================== Consume Local Storage ================================== */
  const { role_type } = JSON.parse(localStorage.getItem('role_active'))

  /* ======================================= Local States ======================================= */
  /* Dropdown Select States */
  const [semesters, setSemesters] = useState()
  const [selected, setSelected]   = useState(null)

  /* Scores States */
  const [loadingScores, setLoadingScores] = useState(false)
  const [scores, setScores]               = useState([])

  /* Type Scores States */
  const [typeScores, setTypeScores] = useState([])

  /* =========================================== Refs =========================================== */
  const isMounted = useRef(true)

  /* ========================================= Functions ======================================== */
  const getSemesters = async () => {
    let query
    if (role_type === 'siswa') {
      query = `semester?tahun_angkatan=true`
    }
    if (
      role_type === 'administrator' ||
      role_type === 'instruktur' ||
      role_type === 'orangtua' ||
      role_type === 'adminprofile'
    ) {
      query = `semester?user_id=${userId || params.siswaId}&tahun_angkatan=true`
    }
    const response = await getRequest(query)
    if (response) {
      const options = response.data.map((item) => ({
        value: item.code,
        label: `${item.tahun_mulai}/${item.tahun_selesai} - ${
          item.title_render
        }${item.status ? ' (Aktif)' : ''}`,
      }))
      if (isMounted.current === true) {
        setSemesters(options)
      }
    }
  }

  function initUsers(classes) {
    return classes.map((kelas) => {
      return {
        id    : kelas.id,
        name  : kelas.title,
        nilai : kelas.scores.sort((a, b) => a.id - b.id).map((_) => _.value),
      }
    })
  }

  function appendScript(scriptToAppend, id) {
    const script = document.createElement('script')
    script.id = id
    script.src = scriptToAppend
    document.body.appendChild(script)
  }

  function initDatatable() {
    appendScript('/admin/assets/js/plugins/jquery.dataTables.min.js', 'rekap1')
    appendScript(
      '/admin/assets/js/plugins/dataTables.bootstrap4.min.js',
      'rekap2',
    )
    appendScript('/admin/assets/js/plugins/buttons.colVis.min.js', 'rekap3')
    appendScript('/admin/assets/js/plugins/jszip.min.js', 'rekap6')
    appendScript('/admin/assets/js/plugins/dataTables.buttons.min.js', 'rekap7')
    appendScript('/admin/assets/js/plugins/buttons.html5.js', 'rekap8')
    appendScript('/admin/assets/js/plugins/buttons.bootstrap4.min.js', 'rekap9')
    appendScript('/admin/assets/js/pages/data-export-custom.js', 'rekap10')
  }

  const getSemesterScores = async (semesterCode) => {
    setLoadingScores(true)
    let query =
      role_type === 'siswa'
        ? `rekap-nilai-siswa?semester=${semesterCode}`
        : `rekap-nilai-siswa?user_id=${params.siswaId || params.userId}&semester=${semesterCode}`
    const response = await getRequest(query)
    setLoadingScores(false)
    if (response) {
      setScores(response.data.classes)
      setTypeScores(response.header.sort((a, b) => a.id - b.id))
      renderHeader(response.header)
      const users = initUsers(response.data.classes)
      renderBody(users)
      setTimeout(() => {
        initDatatable()
      }, 500)
    }
  }

  function renderHeader(header) {
    const headers = header.sort((a, b) => a.id - b.id)
    const headerTable = document.getElementById('scoreHeader')
    headerTable.innerHTML = ``

    const title = document.createElement('th')
    title.innerText = 'Submapel'
    title.style = 'vertical-align:middle'
    headerTable.append(title)

    headers.forEach((item) => {
      const colHead = document.createElement('th')
      colHead.innerText = item.type_score
      headerTable.append(colHead)
    })
  }

  const init = async () => {
    const { code, tahun_mulai, tahun_selesai, title_render } = JSON.parse(
      localStorage.getItem('semester'),
    )
    const defaultSemester = {
      value: code,
      label: `${tahun_mulai}/${tahun_selesai} - ${title_render} (Aktif)`,
    }
    handleChangeSemester(defaultSemester)
  }

  const handleChangeSemester = async (option) => {
    setSelected(option)
    await getSemesterScores(option.value)
  }

  function renderBody(row = []) {
    const bodyTable = document.getElementById('scoreBody')
    bodyTable.innerHTML = ``

    row.forEach((item) => {
      const row = document.createElement('tr')
      const colName = document.createElement('td')
      colName.innerText = `${item.name}`
      row.append(colName)
      item.nilai.forEach((nilai) => {
        const col = document.createElement('td')
        col.innerText = `${nilai}`
        row.append(col)
      })
      bodyTable.append(row)
    })
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getSemesters()
    init()

    return () => {
      isMounted.current = false
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div className="card">
      <div className="card-header py-3">
        <div className="row">
          <div className="col-sm-6 col-xl-4">
            <span className="f-w-700 f-14">Semester</span>
            <Select
              className="mt-2"
              placeholder="Pilih semester..."
              isMulti={false}
              options={semesters}
              value={selected}
              onChange={handleChangeSemester}
              isLoading={loadingScores}
              isDisabled={loadingScores}
            />
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            {typeScores.length > 0 ? (
              <div className="dt-responsive table-responsive rounded mb-3">
                <table className="table nowrap">
                  <thead>
                    <tr>
                      <th className="p-2">Jenis Penilaian</th>
                      <th className="p-2">Persentase</th>
                    </tr>
                  </thead>
                  <tbody>
                    {typeScores.map((typeScore, index) => (
                      <tr key={index}>
                        <td className="p-2">
                          {typeScore.type_score}
                        </td>
                        <td className="p-2">
                          {typeScore.percentage}
                          {' %'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}
          </div>
        </div>
        
        <div className="row">
          <div className="col-12">
            {loadingScores ? (
              <LoadingSpinner />
            ) : (
              <>
                <div className="dt-responsive table-responsive rounded">
                  <table className="table table-bordered nowrap text-center">
                    <thead>
                      <tr id="scoreHeader">
                        <th>Sub Mata Pelajaran</th>
                      </tr>
                      <tr id="scoreHeaderIndex"></tr>
                    </thead>
                    <tbody id="scoreBody">
                      <tr>
                        <td
                          className="text-center"
                          colSpan="100%"
                        >
                          Data tidak ditemukan
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {!scores.length > 0 && (
                  <NotFound
                    text="Tidak ada data"
                    icon="fas fa-graduation-cap"
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

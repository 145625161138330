import React from 'react'
import ForumDiskusi from 'component/forum-mata-pelajaran/forum-diskusi'
import useContentPlayerContext from 'context/content-player'

const ModalForum = () => {
  const { kelas } = useContentPlayerContext()

  return (
    <div
      id="modalForum"
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="modalForumTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="modalForumTitle"
            >
              Forum Diskusi
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <ForumDiskusi classId={kelas.id} isClassEnrolled={true} />
          </div>
          <div className="modal-footer p-2">
            <button
              type="button"
              className="btn btn-outline-danger"
              data-dismiss="modal"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalForum

import React, { useRef, useState } from 'react'
import ContentContainer from './content-container'
import { ContentContainerWrapper } from 'context/content-container'

const Section = ({ item, index, currentContentId }) => {
  /* =========================================== Refs =========================================== */
  const refSection = useRef()

  /* ======================================= Local States ======================================= */
  const [isHovered, setIsHovered] = useState(false)

  /* ========================================== Output ========================================== */
  return (
    <div className="mb-0">
      <div
        id={item.id}
        style={{
          backgroundColor: isHovered ? '#f6f6f6' : '#f8f9fa',
        }}
      >
        <h5
          className="row m-0"
          style={{ width: '100%' }}
        >
          <a
            ref={refSection}
            type="button"
            className="col row collapsed text-dark ml-0 p-3 border-bottom"
            data-toggle="collapse"
            data-target={'#content' + index}
            aria-expanded="false"
            aria-controls={'content' + index}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="col-2 col-md-1 col-xl-2">
              <i className="fas fa-chevron-down" />
            </div>
            <div className="col-10 col-md-11 col-xl-10">
              <span className="f-16 f-w-500">
                {item.urutan}
                {'. '}
                {item.title}
              </span>
            </div>
          </a>
        </h5>
      </div>
      <div
        id={'content' + index}
        className="collapse"
        aria-labelledby={item.id}
        data-parent="#konten-accordion"
      >
        <ul className="list-unstyled mb-0">
          {item.contents.length !== 0 ? (
            item.contents.map((content) => (
              <ContentContainerWrapper
                key={content.id}
                content={content}
                classId={item.class_id}
                refSection={refSection}
                currentContentId={currentContentId}
              >
                <ContentContainer />
              </ContentContainerWrapper>
            ))
          ) : (
            <p className="text-muted p-3 mb-0">Belum ada konten.</p>
          )}
        </ul>
      </div>
    </div>
  )
}

export default Section

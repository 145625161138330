import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { editorConfiguration, editorConfigurationWithoutImage, minimalConfig } from 'util/ckeditor-config'

const CkeditorComponent = ({
  data = '',
  configuration = 'full',
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
}) => {
  function getConfig(config = '') {
    switch (config) {
      case 'full':
        return editorConfiguration
      case 'full-without-image':
        return editorConfigurationWithoutImage
      case 'minimal':
        return minimalConfig
      default:
        return editorConfiguration
    }
  }

  return (
    <CKEditor
      editor={Editor}
      data={data}
      config={getConfig(configuration)}
      onChange={(_, editor) => {
        const data = editor.getData()
        onChange(data)
      }}
      onBlur={(_, editor) => {
        const data = editor.getData()
        onBlur(data)
      }}
      onFocus={(_, editor) => {
        const data = editor.getData()
        onFocus(data)
      }}
    />
  )
}

export default CkeditorComponent

import React from 'react'
import Select from 'react-select'
import { popUpWindow } from 'util/global'

const FormSelectFeedback = ({
  existingFeeds,
  existingFeedId,
  handleChangeFeeds,
  isFeedActive,
  setIsFeedActive,
  getFeedList,
}) => (
  <div className="form-group row mb-5">
    <label className="col-sm-3 col-form-label">Feedback Materi</label>
    <div className="form-group col-sm-9">
      <div className="switch switch-primary d-inline m-r-10">
        <input
          onChange={() => {
            if (isFeedActive === 1) {
              setIsFeedActive(0)
            }
            if (isFeedActive === 0) {
              setIsFeedActive(1)
            }
          }}
          checked={isFeedActive === 1}
          type="checkbox"
          id="switch-status-feeds"
        />
        <label
          htmlFor="switch-status-feeds"
          className="cr"
        ></label>
      </div>
      <label htmlFor="switch-status-feeds">
        {isFeedActive === 1 ? 'Aktif' : 'Tidak aktif'}
      </label>

      {/* Select Feedback */}
      {isFeedActive === 1 ? (
        <div className="row">
          <div className="col-sm-8">
            <Select
              className="mt-2"
              isSearchable={true}
              isMulti={false}
              onMenuOpen={() => getFeedList()}
              placeholder="Pilih feedback"
              noOptionsMessage={() => 'Data feedback tidak dtemukan'}
              isClearable={true}
              options={existingFeeds}
              value={existingFeedId}
              onChange={handleChangeFeeds}
            />
          </div>
          <div className="col-sm-4">
            <div className="btn-group">
              <button
                className="btn btn-sm btn-primary dropdown-toggle mt-2"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="feather icon-plus-circle mr-1" />
                <span>Tambah Feedback</span>
              </button>
              <div className="dropdown-menu">
                <a
                  onClick={() => {
                    popUpWindow(
                      '/kelola-feedback-add?popup=true&typefeed=1',
                      window,
                      800,
                      600,
                    )
                  }}
                  className="dropdown-item"
                  type="button"
                >
                  <i className="fas fa-pen mr-1" />
                  <span>Essay</span>
                </a>
                <a
                  onClick={() => {
                    popUpWindow(
                      '/kelola-feedback-add?popup=true&typefeed=2',
                      window,
                      800,
                      600,
                    )
                  }}
                  className="dropdown-item"
                  type="button"
                >
                  <i className="fas fa-list-ul mr-1" />
                  <span>Pilihan Ganda</span>
                </a>
                <a
                  onClick={() => {
                    popUpWindow(
                      '/kelola-feedback-add?popup=true&typefeed=3',
                      window,
                      800,
                      600,
                    )
                  }}
                  className="dropdown-item"
                  type="button"
                >
                  <i className="fas fa-check-square mr-1" />
                  <span>Pilihan Ganda Kompleks</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  </div>
)

export default FormSelectFeedback

import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { Back } from 'component/button'
import LoadingSpinner from 'component/loading'
import NotFound from 'component/not-found'
import CardKelas from 'component/kelas/card-kelas'
import ListViewKelas from 'component/kelas/list-kelas'

const ListKelas = () => {
  /* ========================================== Helpers ========================================= */
  const params = useParams()
  const location = useLocation()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const groupId   = params.groupId
  const groupData = location.state.groupData
  const links     = [{ url: '/siswa-group', name: 'Kelas Siswa' }]

  /* ======================================= Local States ======================================= */
  const [kelasList, setKelasList]     = useState([])
  const [kelasLength, setKelasLength] = useState(0)
  const [isLoading, setIsLoading]     = useState(false)

  const [listView, setListView] = useState(false)
  const [gridView, setGridView] = useState(true)

  const [selectedSemester, setSelectedSemester] = useState('')
  const [academicSemester, setAcademicSemester] = useState([])

  /* ========================================= Functions ======================================== */
  async function getGroupClassSiswa() {
    setIsLoading(true)
    const response = await getRequest(
      `group-class-siswa?group_id=${groupId}&semester_code=${selectedSemester}`,
    )
    setIsLoading(false)
    if (response) {
      setKelasList(response.data)
      setKelasLength(response.data.length)
    } else {
      console.error('ERROR', response)
    }
  }

  const getSemesterAll = async () => {
    const response = await getRequest('semester')
    if (response) {
      let options = []
      response.data.map((item) => {
        options.push({
          value: item.code,
          label: `${item.tahun_mulai}/${item.tahun_selesai} - ${
            item.title_render
          }${item.status ? ' (Aktif)' : ''}`,
        })
        if (item.status) setSelectedSemester(item.code)
      })
      setAcademicSemester(options)
    }
  }

  function handleListView() {
    setListView(true)
    setGridView(false)
  }

  function handleGridView() {
    setListView(false)
    setGridView(true)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getGroupClassSiswa()

    return () => {
      setKelasList([])
      setKelasLength(0)
    }
  }, [selectedSemester])

  useEffect(() => {
    getSemesterAll()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName={`${groupData?.group}: Daftar Mata Pelajaran`}
          linkName="Daftar Mata Pelajaran"
          links={links}
        />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header">
                <div className="row">
                  <div className="col-6 col-md-10">
                    <Back />
                  </div>
                  <div className="col-6 col-md-2 d-flex justify-content-end">
                    <button
                      onClick={handleGridView}
                      type="button"
                      className="btn btn-icon btn-primary"
                      title="Tampilan Grid"
                    >
                      <i className="feather icon-grid" />
                    </button>
                    <button
                      onClick={handleListView}
                      type="button"
                      className="btn btn-icon btn-primary ml-1"
                      title="Tampilan List"
                    >
                      <i className="fas fa-list-ul" />
                    </button>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <select
                    onChange={(val) => setSelectedSemester(val.target.value)}
                    id="inputState"
                    className={'form-control col-6 col-md-4'}
                  >
                    <option
                      key={'1'}
                      value=""
                    >
                      - Pilih Semester -
                    </option>
                    {academicSemester.map((val, i) => {
                      return (
                        <option
                          key={val.value}
                          selected={selectedSemester == val.value}
                          value={val.value}
                        >
                          {val.label}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className="card-body">
                {/* Tampilan Grid */}
                {gridView ? (
                  <div className="row">
                    {isLoading ? (
                      <div className="col">
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <>
                        {kelasList.length !== 0 ? (
                          kelasList.map((kelas, index) => (
                            <CardKelas
                              key={index}
                              item={kelas}
                            />
                          ))
                        ) : (
                          <NotFound text="Belum ada sub mata pelajaran" />
                        )}
                      </>
                    )}
                  </div>
                ) : null}

                {/* Tampilan List */}
                {listView ? (
                  <div className="row">
                    {isLoading ? (
                      <div className="col">
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <>
                        {kelasList.length !== 0 ? (
                          <ListViewKelas
                            listKelas={kelasList}
                            kelasLength={kelasLength}
                          />
                        ) : null}
                        {kelasList.length === 0 ? (
                          <NotFound text="Belum ada kelas." />
                        ) : null}
                      </>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

export default ListKelas

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useBaseContext } from 'context/base'
import { useStale } from 'util/use-stale'
import DraftInfo from 'pages/input-rapor/components/draft-info'

function InputRaporAllType6({
  userId,
  reportGroupId,
  response,
}) {
  /* ========================================== Helpers ========================================= */
  const params = useParams()

  /* ===================================== Consume Context ==================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= States ========================================= */
  const [res, setRes] = useState(null)

  /* ======================================== Functions ======================================= */
  async function getValue() {
    let us = localStorage.getItem('user')
    let usj = JSON.parse(us)
    var rsp = await getRequest(
      `get-value-score-by-group?user_id=${userId}&group_id=${reportGroupId}&semester_code=${params.semester}`,
    )

    if (rsp) {
      var ss = {}
      if (rsp.data) {
        var d = rsp.data
        ss = {
          user_id: d.user_id,
          categories_id: d.categories_id,
          categories_type_id: d.categories_type_id,
          report_group_id: d.report_group_id,
          createdBy: d.createdBy,
          updatedBy: d.updatedBy,
          value: JSON.parse(d.value),
          ta_semester_code: d.ta_semester_code,
          profile_id: d.profile_id,
        }
        setRes(ss)
      } else {
        ss = {
          user_id: userId,
          categories_id: null,
          categories_type_id: null,
          report_group_id: reportGroupId,
          createdBy: usj.id,
          updatedBy: usj.id,
          value: {
            catatan: '',
          },
          ta_semester_code: params.semester,
          profile_id: usj.profile_id,
        }
        setRes(ss)
      }
      response(ss)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getValue()
  }, [])

  /* ========================================== Output ========================================== */
  return res && <InputCatatanWaliKelas initialState={res?.value.catatan} />

  /* ======================================== Components ======================================== */
  function InputCatatanWaliKelas({ initialState }) {
    const key = [
      'input_rapor_all_type6',
      params.semester,
      reportGroupId,
      userId,
    ].join('_')

    const { loading, data, isDrafted, invalidate, stale } = useStale(
      initialState,
      key,
    )

    useEffect(() => {
      if (data) {
        res.value.catatan = data
        response(res)
      }
    }, [data])

    return (
      !loading && (
        <div style={{ marginBottom: 20 }}>
          {isDrafted && <DraftInfo onClick={invalidate} />}
          <textarea
            id={key}
            onChange={(event) => {
              stale(event)
            }}
            className={'form-control'}
            rows={4}
            defaultValue={data}
            placeholder={'Catatan Wali Kelas'}
          ></textarea>
        </div>
      )
    )
  }
}

export default React.memo(InputRaporAllType6)
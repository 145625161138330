import React from 'react'
import useContentPlayerContext from 'context/content-player'
import AlertGrupKecil from 'pages/katalog-kelas-siswa/content-player/partials/segment-alert/alert-grup-kecil'

export default function ForumDetailHeader() {
  const { forum } = useContentPlayerContext()

  return (
    <div className="card-header pb-2">
      <h5
        style={styles.h5}
        className="m-0"
      >
        Group Kecil
      </h5>
      &nbsp;&nbsp;&nbsp;
      <AlertGrupKecil />
      <p
        style={styles.desc}
        className="m-0 mt-2"
      >
        {forum.desc || ''}
      </p>
      <p
        style={styles.title}
        className="m-0 text-secondary font-italic"
      >
        {forum.data ? forum.data.title : ''}
      </p>
    </div>
  )
}

const styles = {
  h5    : { fontSize: '22px' },
  desc  : { fontSize: '22px' },
  title : { fontSize: '14px' },
}

/*
  in this version on progress and top 5 is disabled
*/

import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/id'
import { useDropzone } from 'react-dropzone'
import { useBaseContext } from 'context/base'
import Modal from 'component/modal'
import CkeditorComponent from 'component/ckeditor'
import CkeditorReadonly from 'component/ckeditor-readonly'
import { getBase64 } from 'util/base64'
import Swal from 'sweetalert2'

import {
  acceptStyle,
  activeStyle,
  baseStyle,
  img,
  rejectStyle,
  thumb2,
  thumbInner,
  thumbsContainer,
} from 'pages/kelas/dropzone-css'
import YouTube from 'react-youtube'
import { noImage } from 'util/constant'
import './style.css'

export default function DashboardSiswa() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()

  /* =========================================== Refs =========================================== */
  const refModal = useRef()

  /* ======================================= Local States ======================================= */
  const [lastContent, setLastContent] = useState([])
  const [lastBadge, setLastBadge]     = useState([])
  const [lastLevel, setLastLevel]     = useState([])
  const [point, setPoint]             = useState([])
  
  const [onProgress, setOnProgress]        = useState([])
  const [top5, setTop5]                    = useState([])
  const [kindness, setKindness]            = useState(null)
  const [dataText, setDataText]            = useState('')
  const [imageToLarge, setImageTooLarge]   = useState(false)
  const [files, setFiles]                  = useState([])
  const [image, setImage]                  = useState('')
  const [description, setDescription]      = useState('')
  const [source, setSource]                = useState('internal')
  const [previewVideo, setPreviewVideo]    = useState('')
  const [previewYotube, setPreviewYoutube] = useState('')
  const [postVideo, setPostVideo]          = useState('')
  const [btnLoading, setBtnLoading]        = useState(false)

  /* ========================================= Functions ======================================== */
  async function getData() {
    Swal.fire({
      title: 'Loading',
      text: 'Harap Tunggu...',
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {
        Swal.hideLoading()
      }
    })

    let response = await getRequest('dashboard-students')
    if (response) {
      Swal.close()
      var data = response.data

      data.last_content.map((item) => {
        item.content_type = {
          created_at: item.createdAt,
          id: item.contents_type_id,
          slug: item.slug,
          title: item.title,
          updated_at: item.updatedAt,
          url: 'https://lms-lazuardi.nos.wjv-1.neo.id/upload/11fc3ee8-7048-4318-9840-3d299e147140/classes/contents/6c4439b5-dfb3-4e1b-b1e8-b0492076a957.mp4?AWSAccessKeyId=00d59489b643f7c278b5&Expires=1667190057&Signature=%2BvvgWSudg7C8vWz2rWR5LZCWs6Y%3D',
        }
        item.class_content_activities = []
      })
      setLastContent(data.last_content)
      setLastBadge(data.last_badge)
      setLastLevel(data.last_level)
      setPoint(data.total_point)
      setOnProgress(data.on_progress)
      setTop5(data.top_5)
      var p = 0
      var b = 0
      data.total_point.forEach((val) => {
        p = p + val.total_point
        b = b + val.total_badge
      })
    } else {
      Swal.close()
    }
  }
  
  async function goToContentPlayer(item, classId) {
    history.push(`/katalog-kelas/${classId}/content-player`, {
      classId: classId,
      dataContent: item[0],
    })
  }

  // GET DATA KINDNESS
  async function getDataKindness() {
    let res = await getRequest('kindness/detail?status=true')
    if (res) {
      setKindness(res.data)
    }
  }

  // HANDLE SUBMIT KINDNES
  async function handleSave() {
    setBtnLoading(true)
    let form = {
      kindness_program_id: kindness?.id,
    }
    if (kindness?.type == 'text') {
      if (dataText) {
        let obj = {
          data: dataText,
          type: kindness?.type,
          source: null,
        }
        form.value = JSON.stringify(obj)
      } else {
        setBtnLoading(false)
        return window.notification('Maaf', 'Jawaban tidak boleh kosong')
      }
    } else if (kindness?.type == 'image') {
      if (image) {
        let obj2 = {
          data: JSON.stringify(image),
          type: kindness?.type,
          source: 'internal',
        }
        form.description = description
        form.value = JSON.stringify(obj2)
      } else {
        setBtnLoading(false)
        return window.notification('Maaf', 'Gambar tidak boleh kosong')
      }
    } else if (kindness?.type == 'video') {
      if (source !== 'internal' && !postVideo) {
        setBtnLoading(false)
        return window.notification('Maaf', 'Upload video telebih dahulu')
      } else if (source !== 'youtube' && !previewYotube) {
        setBtnLoading(false)
        return window.notification('Maaf', 'Url video telebih dahulu')
      } else {
        let obj3 = {
          data:
            source == 'internal' ? JSON.stringify(postVideo) : previewYotube,
          type: kindness?.type,
          source: source,
        }
        form.description = description
        form.value = JSON.stringify(obj3)
      }
    }

    let post = await postRequest(
      'kindness/post',
      form,
      true,
      'Berhasil',
      'menyimpan data',
    )
    if (post) {
      setPreviewVideo('')
      setPreviewYoutube('')
      setPostVideo({})
      setDescription('')
      setImage('')
      setDataText('')
      setFiles([])
      setBtnLoading(false)
      refModal.current.click()
    }
    setBtnLoading(false)
  }

  // DROPZONE
  function Dropzone() {
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      accept: 'image/*',
      maxSize: 1000000,
      multiple: false,
      onDrop: (acceptedFiles, fileRejections) => {
        if (acceptedFiles.length > 0) {
          setImageTooLarge(false)
          setFiles(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              }),
            ),
          )
          getBase64(acceptedFiles[0])
            .then((result) => {
              let avatarClass = result.split(',')
              setImage({ name: acceptedFiles[0].name, data: avatarClass[1] })
            })
            .catch((err) => {
              console.error(err)
            })
        }
        if (fileRejections.length > 0) {
          setImageTooLarge(true)
        }
      },
    })

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isDragActive, isDragReject, isDragAccept],
    )

    const Thumbs = () => {
      return (
        <div style={thumb2}>
          <div style={thumbInner}>
            {image && (
              <img
                src={`data:image/jpeg;base64,${image.data}`}
                alt="preview"
                style={img}
              />
            )}
          </div>
        </div>
      )
    }

    useEffect(
      () => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file) => URL.revokeObjectURL(file.preview))
      },
      [files],
    )

    return (
      <>
        <div className="col-sm-12">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p className="mt-5">Drag or click to select file for add avatar</p>
          </div>
          <div
            className="border rounded alert alert-warning w-100 mt-2"
            role="alert"
          >
            <p className="m-0">
              <i className="fas fa-info-circle mr-1" />
              Ukuran gambar maksimal 1MB
            </p>
          </div>
          {files.length > 0 && (
            <aside style={thumbsContainer}>
              <Thumbs />
            </aside>
          )}
        </div>
      </>
      // <section className="container row">
      // </section>
    )
  }
  
  function getVideo(item) {
    if (typeof item === 'string') {
      let preview = item.split('=')
      setPreviewYoutube(preview[1]) // untuk preview video yutube
    } else if (`${typeof item}` === 'object') {
      getBase64(item)
        .then((result) => {
          let interVideo = result.split(',')
          setPreviewVideo(result)
          setPostVideo({
            name: item.name,
            data: interVideo[1],
            source: 'internal',
          })
        })
        .catch((err) => {
          console.error(err)
        })
    } else {
    }
  }

  useEffect(() => {
    let isSubscribed = true
    if (
      JSON.parse(localStorage.getItem('role_active'))?.role_type === 'siswa'
    ) {
      if (isSubscribed) {
        getData()
        getDataKindness()
      }
    }

    return () => (isSubscribed = false)
  }, [])

  return (
    <div
      id="dashboard-siswa"
      className="row align-items-stretch"
    >
      {lastContent.length ? (
        <div className="col col-xs-12 rounded w-100">
          <div
            id="dashboard-siswa__last-activity"
            className="card table-card"
            onClick={() =>
              goToContentPlayer(lastContent, lastContent[0].class_id)
            }
          >
            <div className="card-header text-center f-w-700">
              <span className="notif-default">Aktivitas Terakhir</span>
              <span className="notif-go">LANJUTKAN!!! 💪</span>
            </div>
            <img
              className="card-img-top"
              src={lastContent[0].submapel.img || noImage}
              alt="Card image cap"
            />
            <div className="card-body d-flex align-items-center justify-content-center">
              <span className="notif-default text-center">
                <p className="m-0 f-22 f-w-700">
                  {lastContent[0].submapel.title}
                </p>
                <p className="m-0 f-14">
                  {lastContent[0].section.title} ~ {lastContent[0].content}
                </p>
              </span>
              <span
                className="notif-go f-w-700"
                style={{ fontSize: '1.75rem' }}
              >
                <span>GO!</span>
                <i className="fa fa-road" />
              </span>
            </div>
            <div className="card-footer notif-default">
              <p className="m-0 f-w-700 f-10 text-center">
                <i className="feather icon-calendar mr-1 f-14" />
                {moment(lastContent[0].updated_at).format('DD MMM YYYY HH:mm')}
              </p>
            </div>
          </div>
        </div>
      ) : null}

      {lastBadge.length ? (
        <div className="col col-xs-12">
          <div className="card table-card">
            <div className="card-header text-center">
              <h5>Badge Terbaru</h5>
            </div>
            <div
              className="card-body p-0 d-flex justify-content-center align-items-center"
              style={{ textAlign: 'center' }}
            >
              <img
                className="img-fluid"
                src={lastBadge[0].linkImage}
                alt="Badge"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src = '/image/badges/default_badge.png'
                }}
              />
            </div>
            <div className="card-footer">
              <h5 className="f-10 text-center">
                {lastBadge[0].desc_parent} - {lastBadge[0].desc}
              </h5>
            </div>
          </div>
        </div>
      ) : null}

      {lastLevel.length ? (
        <div className="col col-xs-12">
          <div className="card table-card">
            <div className="card-header text-center">
              <h5>Level</h5>
            </div>
            <div
              className="card-body p-0 d-flex justify-content-center align-items-center"
              style={{ textAlign: 'center' }}
            >
              <div style={{ padding: 20 }}>
                <i className="feather icon-award h1 text-muted" />
                <h1>{lastLevel[0].level}</h1>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="col-xl-6 col-md-12">
        <div className="card table-card">
          <div className="card-header">
            <h5>Perolehan Point & Badge</h5>
          </div>
          <div className="card-body p-0">
            <div className="table-responsive">
              <table className="table table-hover mb-0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Points</th>
                    <th>Badges</th>
                  </tr>
                </thead>
                <tbody>
                  {point.map((val, index) => {
                    return (
                      <tr key={index}>
                        <td>{val.desc}</td>
                        <td>{val.total_point}</td>
                        <td>{val.total_badge}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="col-xl-6 col-md-12">
        <div className="card table-card">
          <div className="card-header">
            <h5>On Progress</h5>
          </div>
          <div
            className="card-body p-0 justify-content-center dt-responsive table-responsive"
            style={{ textAlign: 'center' }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th>Mata Pelajaran</th>
                  <th>Progress</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {onProgress.map((val, index) => {
                  return (
                    <tr key={index}>
                      <td>{val.title}</td>
                      <td>
                        {val?.progress_percent}{' '}%
                      </td>
                      <td>
                        <button
                          onClick={() =>
                            history.push(`/katalog-kelas/${val.id}`)
                          }
                          type="button"
                          className="btn btn-success"
                        >
                          <i className="fas fa-angle-right"></i>
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-md-12">
        <div className="card table-card">
          <div className="card-header">
            <h5>Top 5</h5>
          </div>
          <div
            className="card-body p-0 justify-content-center dt-responsive table-responsive"
            style={{ textAlign: 'center' }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th>NIM</th>
                  <th>Nama Lengkap</th>
                  <th>Badge</th>
                  <th>Point</th>
                </tr>
              </thead>
              <tbody>
                {top5.map((val, index) => {
                  return (
                    <tr key={index}>
                      <td>{val.nim}</td>
                      <td>{val.name}</td>
                      <td>
                        <h5>{val.total_badge}</h5>
                      </td>
                      <td>
                        <h5>{val.total_point}</h5>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
      <Modal
        showCloseFooter={false}
        size="modal-lg"
        id={'kindnessModal'}
        title={kindness?.title}
        footer={
          <>
            <button
              ref={refModal}
              type="button"
              className="btn btn-outline-danger"
              data-dismiss="modal"
              aria-label="Close"
            >
              Tutup
            </button>
            <button
              type="button"
              disabled={btnLoading}
              className="btn btn-outline-primary"
              onClick={() => {
                handleSave()
              }}
            >
              {btnLoading ? (
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
              ) : (
                'Simpan'
              )}
            </button>
          </>
        }
      >
        <div
          className="hd-body pr-2"
          style={{
            maxHeight: '350px',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <div className="mb-5">
            <div className="card">
              <div className="card-body">
                <CkeditorReadonly data={kindness?.description} />
              </div>
            </div>
            <div
              className="alert alert-primary"
              role="alert"
            >
              Answer
            </div>
            <div className="border bg-light rounded p-5">
              {kindness?.type == 'text' && (
                <div className="form-group row">
                  <label
                    htmlFor="nama-kelas"
                    className="col-sm-3 col-form-label"
                  >
                    <span className="mr-1">Deskripsi</span>
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-9">
                    <CkeditorComponent
                      onChange={(data) => setDataText(data)}
                      data={dataText}
                    />
                  </div>
                </div>
              )}
              {kindness?.type == 'image' && (
                <>
                  <div className="form-group row">
                    <label
                      htmlFor="nama-kelas"
                      className="col-sm-3 col-form-label"
                    >
                      <span className="mr-1">Upload</span>
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <Dropzone />
                    </div>
                  </div>
                </>
              )}
              {imageToLarge && (
                <span className="col offset-md-3 small form-text text-danger">
                  Ukuran gambar maksimal 1MB
                </span>
              )}
              {kindness?.type == 'video' && (
                <>
                  <div className="form-group row">
                    <label
                      htmlFor="nama-kelas"
                      className="col-sm-3 col-form-label"
                    >
                      <span className="mr-1">Source</span>
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-8">
                      <select
                        className="form-control"
                        defaultValue={'internal'}
                        onChange={(e) => {
                          setSource(e.target.value)
                          setPreviewVideo('')
                          setPreviewYoutube('')
                        }}
                      >
                        <option value={'internal'}>Internal</option>
                        <option value={'youtube'}>Youtube</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="nama-kelas"
                      className="col-sm-3 col-form-label"
                    >
                      <span className="mr-1">
                        {source == 'internal' ? 'Uplaod' : 'Url'}
                      </span>
                      <span className="text-danger">*</span>
                    </label>
                    <div className="custom-file col-sm-8">
                      {source == 'internal' ? (
                        <>
                          <input
                            accept=".mp4"
                            onChange={(val) => getVideo(val.target.files[0])}
                            type="file"
                            className="custom-file-input"
                            id="validatedCustomFile"
                            required
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="validatedCustomFile"
                          >
                            Choose video...
                          </label>
                        </>
                      ) : (
                        <>
                          <input
                            className="form-control"
                            type={'text'}
                            placeholder="https://www.youtube.com/watch?v=_25DTNEFue4"
                            onChange={(e) => getVideo(e.target.value)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {previewVideo && (
                    <div className="form-group row">
                      <label
                        htmlFor="nama-kelas"
                        className="col-sm-3 col-form-label"
                      >
                        <span className="mr-1">Preview</span>
                      </label>
                      <div className="col-sm-8">
                        <video
                          width="400px"
                          height="200px"
                          controls
                          controlsList="nodownload"
                        >
                          <source
                            src={previewVideo}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  )}
                  {previewYotube && (
                    <div className="form-group row">
                      <label
                        htmlFor="nama-kelas"
                        className="col-sm-3 col-form-label"
                      >
                        <span className="mr-1">Preview</span>
                      </label>
                      <div className="col-sm-8">
                        <YouTube
                          opts={{
                            width: '330px',
                            height: '200px',
                          }}
                          videoId={previewYotube}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              {kindness?.type == 'image' || kindness?.type == 'video' ? (
                <>
                  <div className="form-group row">
                    <label
                      htmlFor="nama-kelas"
                      className="col-sm-3 col-form-label"
                    >
                      <span className="mr-1">Deskripsi</span>
                    </label>
                    <div className="col-sm-8">
                      <textarea
                        placeholder="Deskripsi"
                        value={description}
                        className="form-control"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

import React from 'react'
import LoadingSpinner from 'component/loading'
import TableIntervalPredikat from 'component/rapor/table-interval-predikat'

export default function RaporLoading() {
  return (
    <>
      <div className="row">
        <div className="col">
          <h2 className="f-24">Memuat...</h2>
          <div className="text-secondary">
            <span>
              <i className="fas fa-graduation-cap mr-1" />
              Memuat...
            </span>
            <span className="ml-3">
              <i className="fas fa-user-graduate mr-1" />
              {'0'}
              {' siswa'}
            </span>
            <span className="ml-3">
              <i className="fas fa-user-friends mr-1" />
              Kelas: Memuat...
            </span>
          </div>
          <hr />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h5>A. Tabel Interval Predikat</h5>
          <TableIntervalPredikat />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h5>B. Pengetahuan</h5>
          <div className="w-100">
            <button
              className="btn btn-sm btn-primary mr-2"
              disabled={true}
            >
              <i className="fas fa-check-circle mr-1" />
              Setuju
            </button>
            <button
              className="btn btn-sm btn-outline-danger"
              disabled={true}
            >
              <i className="fas fa-times-circle mr-1" />
              Tolak
            </button>
          </div>
          <div className="table-responsive rounded mt-2">
            <table className="table nowrap">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      className="form-control"
                      style={{ width: 16, height: 16 }}
                      disabled={true}
                    />
                  </th>
                  <th>No.</th>
                  <th>Nama</th>
                  <th>Nilai</th>
                  <th>Predikat</th>
                  <th>Deskripsi</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    colSpan={7}
                    className="text-center"
                  >
                    <LoadingSpinner />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h5>C. Keterampilan</h5>
          <div className="w-100">
            <button
              className="btn btn-sm btn-primary mr-2"
              disabled={true}
            >
              <i className="fas fa-check-circle mr-1" />
              Setuju
            </button>
            <button
              className="btn btn-sm btn-outline-danger"
              disabled={true}
            >
              <i className="fas fa-times-circle mr-1" />
              Tolak
            </button>
          </div>
          <div className="table-responsive rounded mt-2">
            <table className="table nowrap">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      className="form-control"
                      style={{ width: 16, height: 16 }}
                      disabled={true}
                    />
                  </th>
                  <th>No.</th>
                  <th>Nama</th>
                  <th>Nilai</th>
                  <th>Predikat</th>
                  <th>Deskripsi</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    colSpan={7}
                    className="text-center"
                  >
                    <LoadingSpinner />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

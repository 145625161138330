
import React from 'react'

const SaWarning = () => {
  return (
    <div className="sa">
      <div className="sa-warning">
        <div className="sa-warning-body"></div>
        <div className="sa-warning-dot"></div>
      </div>
    </div>
  )
}

export default SaWarning
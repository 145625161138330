import React, { useState, useEffect } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import Select from 'react-select'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'

const DetailQuiz = () => {
  /* ========================================== Helpers ========================================= */
  const location = useLocation()
  const history = useHistory()
  const params = useParams()
  const dataQuiz = location.state.existingData || {}

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/kelola-quiz', name: 'Kelola Quiz' }]

  /* ======================================= Local States ======================================= */
  const [dataSiswa, setDataSiswa] = useState([])
  const [groups, setGroups]       = useState([])
  const [group, setGroup]         = useState({ value: null, label: 'Semua Kelas' })
  const [limit, setLimit]         = useState(10)
  const [offset, setOffset]       = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [search, setSearch]       = useState('')

  /* ========================================= Functions ======================================== */
  async function getDataSiswa() {
    const response = await getRequest(
      `quiz-detail?id=${params.quizId}&group_id=${group?.value || ''}&limit=${limit}&offset=${offset}&search=${search}`,
    )
    if (response) {
      setDataSiswa(response.data)
      setTotalRows(response.totalRows)
    } else {
      console.error('ERROR', response)
    }
  }

  async function getGroups() {
    const response = await getRequest(
      `group-by-quiz?quiz_id=${params.quizId}`,
    )
    if (response) {
      if (response.data.length !== 0) {
        const groups = response.data.map((group) => ({
          value: group.id,
          label: group.group,
        }))
        setGroups([{ value: null, label: 'Semua Kelas' }, ...groups])
      }
    }
  }

  function handlePreviewAnswerSiswa(dataSiswa) {
    history.push(
      `/kelola-quiz/preview-quiz/${params.quizId}/answer-siswa/${dataSiswa.id}`,
      { existingData: dataSiswa, quizData: location.state.existingData },
    )
  }

  function handleStatus(item) {
    if (item.is_complete == null) {
      return (
        <span className={`badge bg-secondary text-white`}>
          Belum mengerjakan
        </span>
      )
    } 
    else if (item.is_checked > 0) {
      return (
        <>
          <span className={`badge bg-success text-white`}>
            Sudah mengerjakan
          </span>
          <br />
          <span className={`badge bg-warning text-white`}>
            {item.is_checked} Soal belum diperiksa
          </span>
        </>
      )
    } else {
      if (item.is_complete_nilai) {
        return (
          <span className={`badge bg-success text-white`}>
            Sudah mengerjakan
          </span>
        )
      } else {
        return (
          <span className={`badge bg-info text-white`}>Sudah mengerjakan</span>
        )
      }
    }
  }

  function handleNilai(item) {
    if (item.nilai <= 49) {
      return <span className="font-weight-bold text-danger">{item.nilai}</span>
    } else {
      if (item.nilai > 69) {
        return (
          <span className="font-weight-bold text-success">{item.nilai}</span>
        )
      } else {
        return (
          <span className="font-weight-bold text-warning">{item.nilai}</span>
        )
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getGroups()
  }, [])

  useEffect(() => {
    getDataSiswa()
  }, [group, limit, offset, search])

  /* ======================================== Components ======================================== */
  const ClassPicker = () => (
    <>
      <h5>Pilih Kelas: </h5>
      <Select
        placeholder="Kelas"
        options={groups}
        onChange={(item) => setGroup(item)}
        className="mb-3"
        defaultValue={group || { value: null, label: 'Semua Kelas' }}
      />
    </>
  )

  const RenderItem = ({ item, handlePreviewAnswerSiswa }) => {
    return (
      <tr>
        <td>{item.nim ? item.nim : '-'}</td>
        <td>{item.name ? item.name : '-'}</td>
        <td>{item.content_title ? item.content_title : '-'}</td>
        <td>{item.class_title ? item.class_title : '-'}</td>
        <td>{item.nilai !== null ? handleNilai(item) : '-'}</td>
        <td>{handleStatus(item)}</td>
        <td>
          {item.is_complete ? (
            <button
              type="button"
              className="btn btn-sm btn-info"
              onClick={() => handlePreviewAnswerSiswa(item)}
            >
              <i className="feather icon-check-square mr-1" />
              <span>Periksa</span>
            </button>
          ) : (
            '-'
          )}
        </td>
      </tr>
    )
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName={`Periksa Quiz: ${dataQuiz?.title || ''}`}
          linkName="Daftar Quiz Siswa"
          links={links}
        />

        <div className="row">
          <div className="col">
            <div className="card user-profile-list">
              <div className="card-header">
                <div className="row">
                  <div className="col">
                    <h6>{dataQuiz?.title || '-'}</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p className="mb-0">
                      {dataQuiz?.description || ''}
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col dt-responsive table-responsive">
                    <ClassPicker />
                    <DataTable
                      className={'table table-striped table-bordered nowrap'}
                      data={dataSiswa}
                      totalRows={totalRows}
                      show={[10, 20, 30, 40, 50]}
                      onChangeSearch={(val) => {
                        setSearch(val)
                      }}
                      onChangeLimit={(val) => {
                        setLimit(val)
                      }}
                      onChangePage={(val) => {
                        setOffset(val)
                      }}
                      column={[
                        'No. Induk',
                        'Nama Siswa',
                        'Materi Quiz',
                        'Sub Mata Pelajaran',
                        'Nilai',
                        'Status',
                        'Actions',
                      ]}
                      renderItem={(siswa, index) => (
                        <RenderItem
                          item={siswa}
                          key={index}
                          handlePreviewAnswerSiswa={handlePreviewAnswerSiswa}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="row">
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => history.push('/kelola-quiz')}
                    >
                      Kembali
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

export default DetailQuiz

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import BaseSelect from 'component/select/BaseSelect'
import moment from 'moment'

import { useBaseContext } from 'context/base'
import PieChart from 'component/highchart/pie-chart'
import LoadingSpinner from 'component/loading'
import Modal from 'component/modal'
import { avatarDefaultUser } from 'util/constant'

export default function DashboardReportSiswa() {
  /* ========================================== Helpers ========================================= */
  const param = useParams()
  
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* =================================== Consume Local Storage ================================== */
  const { role_type } = JSON.parse(localStorage.getItem('role_active'))

  /* ======================================= Local States ======================================= */
  const [detailSiswa, setDetailSiswa]         = useState(null)
  // const [dataChart, setDataChart]             = useState(null)
  const [dataPie, setDataPie]                 = useState(null)
  const [dataFeedback, setDataFeedback]       = useState(null)
  const [isLoading, setIsLoading]             = useState(false)
  const [windowWidth, setWidth]               = useState(window.innerWidth)
  const [grafikGrupKecil, setGrafikGrupKecil] = useState([])
  const [activity, setActivity]               = useState([])
  const [optionsSemester, setOptionsSemester] = useState([])
  const [selectedOptionSemester, setSelectedOptionSemester] = useState({})
  const [isLoadingOptionsSemester, setIsLoadingOptionsSemester] = useState(false)

  /* ========================================= Functions ======================================== */
  function updateDimensions() {
    setWidth(window.innerWidth)
  }

  /**
   * Add Query Semester
   * 
   * @param {string} url 
   */
  function addQuerySemester(url) {
    let data = url.substring() // copy string to manipulate

    if (selectedOptionSemester?.value !== 'all') {
      data += `&semester=${selectedOptionSemester?.value}`
    }

    return data
  }

  async function getListSemester() {
    setIsLoadingOptionsSemester(true)
    const response = await getRequest(
      `semester`,
    )
    if (response) {
      const data = response?.data

      if (Array.isArray(data) && data?.length) {
        const manipulatedDataToOptionsReactSelect = data?.map(d => {
          const semesterActive = d?.status

          if (semesterActive) {
            const optionReactSelect = {
              label: `${d?.tahun_mulai}/${d?.tahun_selesai} - ${d?.title_render} (Aktif)`,
              value: d?.code,
            }

            setSelectedOptionSemester(optionReactSelect)
            return optionReactSelect
          } else {
            return {
              label: `${d?.tahun_mulai}/${d?.tahun_selesai} - ${d?.title_render}`,
              value: d?.code,
            }
          }
        })

        const semesterListOption = [{ label: 'Semua', value: 'all' }].concat(manipulatedDataToOptionsReactSelect)

        setOptionsSemester(semesterListOption)
      }

      setIsLoadingOptionsSemester(false)
    } else {
      setIsLoadingOptionsSemester(false)
    }
  }

  async function getDetailSiswa() {
    setIsLoading(true)

    const url = addQuerySemester(`report-aktifitas-siswa/dashboard?user_id=${param.siswaId}`)

    const response = await getRequest(url)
    if (response) {
      setIsLoading(false)
      setDetailSiswa(response.data.siswa)
      // setDataChart(response.data.chart)
      setDataPie([
        {
          name: 'Sedang dikerjakan',
          y: parseInt(response.data.siswa.on_progress),
        },
        {
          name: 'Belum mulai',
          y: parseInt(response.data.siswa.tunda),
        },
        {
          name: 'Selesai',
          y: parseInt(response.data.siswa.finished),
        },
      ])
      setDataFeedback(response.data.feedbackAssignment)
    }
  }

  async function getGrafikGrupKecil() {
    const url = addQuerySemester(`grafik-grup-kecil?user_id=${param.siswaId}`)

    const response = await getRequest(url)
    if (response) {
      setGrafikGrupKecil(response.data)
    }
  }

  async function getActivity() {
    const url = addQuerySemester(`time-activity-siswa?filterBy=content&user_id=${param?.siswaId}`)

    let res = await getRequest(url)
    if (res) {
      setActivity(res.data)
    }
  }

  function secondsToHms(seconds) {
    seconds = Number(seconds)
    const hour = Math.floor(seconds / 3600)
    const minutes = Math.floor(seconds / 60)
    const output =
      hour > 0
        ? `${hour} jam`
        : minutes > 0
        ? `${minutes} menit`
        : `${seconds} detik`
    return output
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      getListSemester()      
      window.addEventListener('resize', updateDimensions)
    }
    return () => {
      isSubscribed = false
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  useEffect(() => {
    if (selectedOptionSemester?.value) {
      getDetailSiswa()
      getGrafikGrupKecil()
      getActivity()
    }
  }, [selectedOptionSemester])

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              {isLoading ? (
                <div className="row">
                  <div className="col">
                    <LoadingSpinner />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row mb-2 mb-md-3">
                      <div
                        className="col-8"
                        style={{
                          display: 'flex',
                          flexDirection: windowWidth >= 768 ? 'row' : 'column',
                          alignItems: windowWidth >= 768 ? 'start' : 'center',
                        }}
                      >
                        <img
                          style={{
                            width: windowWidth >= 768 ? '75px' : '220px',
                            height: windowWidth >= 768 ? '75px' : '220px',
                            objectFit: 'cover',
                            borderRadius: '50%',
                          }}
                          className="mb-3 mb-md-0"
                          src={detailSiswa ? detailSiswa.linkAvatar : ''}
                          alt={detailSiswa ? detailSiswa.name : 'User'}
                          onError={(e) =>
                            (e.currentTarget.src = avatarDefaultUser)
                          }
                        />
                        <div className="ml-md-3">
                          <h5>{detailSiswa ? detailSiswa.name : ''}</h5>
                          <h6>{detailSiswa ? detailSiswa.email : ''}</h6>
                          <p>{detailSiswa ? detailSiswa.description : ''}</p>
                        </div>
                      </div>
                      <div className='col-4'>
                        <BaseSelect isDisabled={isLoadingOptionsSemester || isLoading} placeholder="Filter Semester..." options={optionsSemester} defaultValue={selectedOptionSemester} onChange={setSelectedOptionSemester} />
                      </div>
                    </div>
                    <div className="row mt-2 mb-2 justify-content-center">
                      <div className="col-md-6 col-xl-4">
                        <div className="card widget-statstic-card">
                          <div className="card-body">
                            <div className="card-header-left mb-3">
                              <h5 className="mb-0">Sub Mata Pelajaran</h5>
                              <p className="p-t-10 m-b-0 text-primary">
                                Sedang dikerjakan
                              </p>
                            </div>
                            <i className="fas fa-hourglass-half st-icon bg-primary"></i>
                            <div className="text-left">
                              <h3 className="d-inline-block m-0">
                                {detailSiswa ? detailSiswa.on_progress : 0}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-4">
                        <div className="card widget-statstic-card">
                          <div className="card-body">
                            <div className="card-header-left mb-3">
                              <h5 className="mb-0">Sub Mata Pelajaran</h5>
                              <p className="p-t-10 m-b-0 text-secondary">
                                Belum mulai
                              </p>
                            </div>
                            <i className="fas fa-history st-icon bg-secondary"></i>
                            <div className="text-left">
                              <h3 className="d-inline-block m-0">
                                {detailSiswa ? detailSiswa.tunda : 0}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-4">
                        <div className="card widget-statstic-card">
                          <div className="card-body">
                            <div className="card-header-left mb-3">
                              <h5 className="mb-0">Sub Mata Pelajaran</h5>
                              <p className="p-t-10 m-b-0 text-success">
                                Selesai
                              </p>
                            </div>
                            <i className="fas fa-check-circle st-icon bg-success"></i>
                            <div className="text-left">
                              <h3 className="d-inline-block m-0">
                                {detailSiswa ? detailSiswa.finished : 0}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-3">
                        <div className="card widget-statstic-card">
                          <div className="card-body">
                            <div className="card-header-left mb-3">
                              <h5 className="mb-0">Feedback</h5>
                              <p className="p-t-10 m-b-0 text-primary">
                                Memberi feedback
                              </p>
                            </div>
                            <i className="fas fa-comment st-icon bg-primary"></i>
                            <div className="text-left">
                              <h3 className="d-inline-block m-0">
                                {dataFeedback ? dataFeedback.countSend : 0}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-3">
                        <div className="card widget-statstic-card">
                          <div className="card-body">
                            <div className="card-header-left mb-3">
                              <h5 className="mb-0">Feedback</h5>
                              <p className="p-t-10 m-b-0 text-success">
                                Menerima feedback
                              </p>
                            </div>
                            <i className="feather icon-users st-icon bg-success"></i>
                            <div className="text-left">
                              <h3 className="d-inline-block m-0">
                                {dataFeedback ? dataFeedback.countReceived : 0}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-3">
                        <div className="card widget-statstic-card">
                          <div className="card-body">
                            <div className="card-header-left mb-3">
                              <h5 className="mb-0">Feedback</h5>
                              <p className="p-t-10 m-b-0 text-danger">
                                Belum memberikan feedback
                              </p>
                            </div>
                            <i className="fas fa-comment-slash st-icon bg-danger"></i>
                            <div className="text-left d-flex justify-content-between">
                              <h3 className="d-inline-block m-0">
                                {dataFeedback ? dataFeedback.countRemains : 0}
                              </h3>
                              {dataFeedback ? (
                                dataFeedback.countRemains > 0 ? (
                                  <>
                                    <button
                                      data-toggle="modal"
                                      data-target="#overviewFeedRemainsModal"
                                      className="btn btn-sm btn-outline-danger"
                                    >
                                      Detail
                                    </button>
                                    <Modal
                                      title="Belum memberikan feedback tugas"
                                      id="overviewFeedRemainsModal"
                                      size="modal-lg"
                                    >
                                      <div className="user-profile-list">
                                        <div className="table-responsive p-3">
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th>Materi</th>
                                                <th>Section</th>
                                                <th>Sub Mapel</th>
                                                <th>Feedback tersisa</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {dataFeedback.remains.map(
                                                (item, index) => {
                                                  const kelasName =
                                                    item.class_title
                                                  const getMataPelajaranLink = (
                                                    role,
                                                  ) => {
                                                    return role ===
                                                      'administrator' ||
                                                      role === 'instruktur' ||
                                                      role === 'adminprofile'
                                                      ? `/kelas/${item.class_id}`
                                                      : role === 'siswa'
                                                      ? `/katalog-kelas/${item.class_id}`
                                                      : ''
                                                  }
                                                  return (
                                                    <tr key={index}>
                                                      <td>
                                                        {item.content_title}
                                                      </td>
                                                      <td>
                                                        {item.section_title}
                                                      </td>
                                                      <td>
                                                        {item.class_title}
                                                      </td>
                                                      <td>
                                                        {item.komentar_wajib}
                                                        {role_type ===
                                                        'orangtua' ? null : (
                                                          <div className="overlay-edit">
                                                            <a
                                                              href={getMataPelajaranLink(
                                                                role_type,
                                                              )}
                                                              className="btn btn-icon btn-warning"
                                                              title={`Lihat ${kelasName}`}
                                                            >
                                                              <i className="fas fa-eye" />
                                                            </a>
                                                          </div>
                                                        )}
                                                      </td>
                                                    </tr>
                                                  )
                                                },
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </Modal>
                                  </>
                                ) : null
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-3">
                        <div className="card widget-statstic-card">
                          <div className="card-body">
                            <div className="card-header-left mb-3">
                              <h5 className="mb-0">Feedback</h5>
                              <p className="p-t-10 m-b-0 text-warning">
                                Belum merespon feedback
                              </p>
                            </div>
                            <i className="feather icon-corner-up-right st-icon bg-warning"></i>
                            <div className="text-left d-flex justify-content-between">
                              <h3 className="d-inline-block m-0">
                                {dataFeedback
                                  ? dataFeedback.countRemainsReply
                                  : 0}
                              </h3>
                              {dataFeedback ? (
                                dataFeedback.countRemainsReply > 0 ? (
                                  <>
                                    <button
                                      data-toggle="modal"
                                      data-target="#overviewFeedReplyRemailsModal"
                                      className="btn btn-sm btn-outline-warning"
                                    >
                                      Detail
                                    </button>
                                    <div
                                      className="modal fade"
                                      id="overviewFeedReplyRemailsModal"
                                      data-backdrop="static"
                                      data-keyboard="false"
                                      tabIndex={-1}
                                      role="dialog"
                                      aria-labelledby="overviewFeedReplyRemailsModal"
                                      aria-hidden="true"
                                    >
                                      <div
                                        className={`modal-dialog modal-dialog-centered`}
                                      >
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h5 className="modal-title">
                                              Feedback tugas belum direspon
                                            </h5>
                                            <button
                                              type="button"
                                              className="close text-danger"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <i className="feather icon-x" />
                                            </button>
                                          </div>
                                          <div className="modal-body p-0">
                                            {dataFeedback.remainsReply.map(
                                              (item, index) => {
                                                let dd = moment(item.created_at)
                                                  .add(30, 'days')
                                                  .valueOf()
                                                let date =
                                                  dd < moment().valueOf()
                                                    ? moment(
                                                        item.created_at,
                                                      ).format('DD MMM YYYY')
                                                    : moment(
                                                        item.created_at,
                                                      ).fromNow()

                                                function getMataPelajaranLink(
                                                  role,
                                                ) {
                                                  return role ===
                                                    'administrator' ||
                                                    role === 'instruktur' ||
                                                    role === 'adminprofile'
                                                    ? `/kelas/${item.class_id}`
                                                    : role === 'siswa'
                                                    ? `/katalog-kelas/${item.class_id}`
                                                    : ''
                                                }

                                                return (
                                                  <div
                                                    key={index}
                                                    className="col-12 pt-3 px-4 border-bottom"
                                                    style={{
                                                      transition: '0.3s',
                                                    }}
                                                    onMouseEnter={(e) =>
                                                      (e.currentTarget.style.backgroundColor =
                                                        '#f8f8f8')
                                                    }
                                                    onMouseLeave={(e) =>
                                                      (e.currentTarget.style.backgroundColor =
                                                        'transparent')
                                                    }
                                                  >
                                                    <div className="d-flex align-items-center">
                                                      <div className="">
                                                        <p className="mb-0">
                                                          <span className="f-w-500 text-dark p-0">
                                                            {item.user_name}
                                                          </span>
                                                        </p>
                                                        <div className="d-flex">
                                                          <p
                                                            className="text-muted mr-2 mb-2"
                                                            style={{
                                                              fontSize: '10px',
                                                            }}
                                                          >
                                                            <i className="feather icon-clock mr-1" />
                                                            {date}
                                                          </p>
                                                          {role_type ===
                                                          'orangtua' ? (
                                                            <span
                                                              className="text-muted"
                                                              style={{
                                                                fontSize:
                                                                  '10px',
                                                              }}
                                                            >
                                                              <i className="feather icon-link mr-1" />
                                                              {item.class_title}
                                                              /
                                                              {
                                                                item.section_title
                                                              }
                                                              /
                                                              {
                                                                item.content_title
                                                              }
                                                            </span>
                                                          ) : (
                                                            <a
                                                              href={getMataPelajaranLink(
                                                                role_type,
                                                              )}
                                                              className="text-dark"
                                                              style={{
                                                                fontSize:
                                                                  '10px',
                                                              }}
                                                            >
                                                              <i className="feather icon-link mr-1" />
                                                              {item.class_title}
                                                              /
                                                              {
                                                                item.section_title
                                                              }
                                                              /
                                                              {
                                                                item.content_title
                                                              }
                                                            </a>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <p>{item.discuss}</p>
                                                  </div>
                                                )
                                              },
                                            )}
                                          </div>
                                          <div className="modal-footer p-2">
                                            <button
                                              type="button"
                                              className="btn btn-outline-danger"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              Tutup
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card widget-statstic-card">
                          <div className="card-body">
                            <div className="card-header-left mb-3">
                              <h5 className="mb-0">Durasi</h5>
                              <p className="p-t-10 m-b-0 text-warning">
                                Total waktu belajar
                              </p>
                            </div>
                            <i className="fas fa-clock st-icon bg-warning"></i>
                            <div className="text-left">
                              <h3 className="d-inline-block m-0">
                                {detailSiswa
                                  ? secondsToHms(detailSiswa.durasi)
                                  : 0}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card widget-statstic-card">
                          <div className="card-body">
                            <div className="card-header-left mb-3">
                              <h5 className="mb-0">Badge</h5>
                              <p className="p-t-10 m-b-0 text-warning">
                                Perolehan Badge
                              </p>
                            </div>
                            <i className="fas fa-medal st-icon bg-warning"></i>
                            <div className="text-left">
                              <h3 className="d-inline-block m-0">
                                {detailSiswa
                                  ? detailSiswa.badges_collections
                                  : 0}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 mb-2">
                      <div className="col-sm-12 col-md-12 col-xl-6 mt-3">
                        {/* <LineChart
                          series={dataChart ? dataChart.series : []}
                          categories={dataChart ? dataChart.categories : []} 
                          yTitle="Materi"
                        /> */}
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={{
                            chart: {
                              type: 'column',
                            },
                            title: {
                              text: 'Aktivitas Belajar Siswa',
                            },
                            subtitle: {
                              text: '',
                            },
                            xAxis: {
                              categories: activity.categories,
                              crosshair: true,
                            },
                            yAxis: {
                              min: 0,
                              title: {
                                text: '',
                              },
                            },
                            tooltip: {
                              headerFormat:
                                '<span style="font-size:10px">{point.key}</span><table>',
                              pointFormat:
                                '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                              footerFormat: '</table>',
                              shared: true,
                              useHTML: true,
                            },
                            plotOptions: {
                              column: {
                                pointPadding: 0.2,
                                borderWidth: 0,
                              },
                            },
                            series: activity.series,
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-12 col-xl-6 mt-3">
                        <PieChart series={dataPie ? dataPie : []} />
                      </div>
                    </div>
                    <div className="row mt-2 mb-2">
                      <div className="col-sm-12 col-md-12 col-xl-6 mt-3">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={{
                            chart: {
                              type: 'column',
                            },
                            title: {
                              text: 'Grup Kecil yang diikuti',
                            },
                            subtitle: {
                              text: '',
                            },
                            xAxis: {
                              categories: grafikGrupKecil.categories_ikuti_grup,
                              crosshair: true,
                            },
                            yAxis: {
                              min: 0,
                              title: {
                                text: '',
                              },
                            },
                            tooltip: {
                              headerFormat:
                                '<span style="font-size:10px">{point.key}</span><table>',
                              pointFormat:
                                '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                              footerFormat: '</table>',
                              shared: true,
                              useHTML: true,
                            },
                            plotOptions: {
                              column: {
                                pointPadding: 0.2,
                                borderWidth: 0,
                              },
                            },
                            series: grafikGrupKecil.series_ikuti_grup,
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-12 col-xl-6 mt-3">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={{
                            chart: {
                              type: 'column',
                            },
                            title: {
                              text: 'Balasan Grup Kecil',
                            },
                            subtitle: {
                              text: '',
                            },
                            xAxis: {
                              categories:
                                grafikGrupKecil.categories_balasan_grup,
                              crosshair: true,
                            },
                            yAxis: {
                              min: 0,
                              title: {
                                text: '',
                              },
                            },
                            tooltip: {
                              headerFormat:
                                '<span style="font-size:10px">{point.key}</span><table>',
                              pointFormat:
                                '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                              footerFormat: '</table>',
                              shared: true,
                              useHTML: true,
                            },
                            plotOptions: {
                              column: {
                                pointPadding: 0.2,
                                borderWidth: 0,
                              },
                            },
                            series: grafikGrupKecil.series_balasan_grup,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import React, { useState, useEffect, useRef } from "react";

const getStars = (starsCount) => {
  let stars = [];
  for (let count = 1; count <= starsCount; count++) {
    stars.push(count);
  }
  return stars;
}

export const RatingFull = ({ 
  rating,
  rating_1,
  rating_2,
  rating_3,
  rating_4,
  rating_5, 
  totalRating, 
  maxRating = 5, 
  starColor = "gold" 
  }) => {
  const [stars, setStars] = useState([]);
  const [mutedStars, setMutedStars] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    if (rating) {
      const colorStarCount = Math.round(parseFloat(rating));
      const blankStarCount = maxRating - colorStarCount;
      const colorStars = getStars(colorStarCount);
      const blankStars = getStars(blankStarCount);
      if (isSubscribed) {
        setStars(colorStars);
        setMutedStars(blankStars);
      }
    } else {
      if (isSubscribed) {
        const blankStars = getStars(maxRating);
        setStars([]);
        setMutedStars(blankStars);
      }
    }
    return () => { isSubscribed = false; }
  }, [rating]);

  return (
    <div className="row mb-3">
      <div className="col-12 col-md-4 d-flex flex-column align-items-center justify-content-center">
        <div>
          <span className="f-60">{rating ? rating : "0.0"}</span>
          <span className="f-16 ml-1 text-secondary">/{maxRating}</span>
        </div>
        <div className="mb-2">
          {stars.length !== 0 &&
            <>{stars.map((star, index) => <i key={index} style={{ color: `${starColor}` }} className="fas fa-star f-18 mr-1" />)}</>
          }
          {mutedStars.length !== 0 &&
            <>{mutedStars.map((star, index) => <i key={index} className="fas fa-star text-muted f-18 mr-1" />)}</>
          }
        </div>
        <span className="f-12 text-secondary">({totalRating}) Rating</span>
      </div>
      <div className="col-12 col-md-8 mt-3 mt-sm-0 d-flex flex-column justify-content-center">
        <div className="d-flex align-items-center">
          <div style={{ width: "44px" }}>
            <i style={{ color: `${starColor}` }} className="fas fa-star f-14 mr-1" />
            <span>5</span>
          </div>
          <div style={{ width: "78%", height: "8px" }} className="progress">
            <div className="progress-bar bg-success" role="progressbar" style={{ width: `${parseInt(rating_5)/parseInt(totalRating)*100}%` }} aria-valuenow={`${parseInt(rating_5)/parseInt(totalRating)*100}`} aria-valuemin="0" aria-valuemax="100" />
          </div>
          <span className="ml-3">{rating_5}</span>
        </div>
        <div className="d-flex align-items-center">
          <div style={{ width: "44px"}}>
            <i style={{ color: `${starColor}` }} className="fas fa-star f-14 mr-1" />
            <span>4</span>
          </div>
          <div style={{ width: "78%", height: "8px" }} className="progress">
          <div className="progress-bar bg-success" role="progressbar" style={{ width: `${parseInt(rating_4)/parseInt(totalRating)*100}%` }} aria-valuenow={`${parseInt(rating_4)/parseInt(totalRating)*100}`} aria-valuemin="0" aria-valuemax="100" />
          </div>
          <span className="ml-3">{rating_4}</span>
        </div>
        <div className="d-flex align-items-center">
          <div style={{ width: "44px"}}>
            <i style={{ color: `${starColor}` }} className="fas fa-star f-14 mr-1" />
            <span>3</span>
          </div>
          <div style={{ width: "78%", height: "8px" }} className="progress">
          <div className="progress-bar bg-success" role="progressbar" style={{ width: `${parseInt(rating_3)/parseInt(totalRating)*100}%` }} aria-valuenow={`${parseInt(rating_3)/parseInt(totalRating)*100}`} aria-valuemin="0" aria-valuemax="100" />
          </div>
          <span className="ml-3">{rating_3}</span>
        </div>
        <div className="d-flex align-items-center">
          <div style={{ width: "44px"}}>
            <i style={{ color: `${starColor}` }} className="fas fa-star f-14 mr-1" />
            <span>2</span>
          </div>
          <div style={{ width: "78%", height: "8px" }} className="progress">
          <div className="progress-bar bg-success" role="progressbar" style={{ width: `${parseInt(rating_2)/parseInt(totalRating)*100}%` }} aria-valuenow={`${parseInt(rating_2)/parseInt(totalRating)*100}`} aria-valuemin="0" aria-valuemax="100" />
          </div>
          <span className="ml-3">{rating_2}</span>
        </div>
        <div className="d-flex align-items-center">
          <div style={{ width: "44px"}}>
            <i style={{ color: `${starColor}` }} className="fas fa-star f-14 mr-1" />
            <span>1</span>
          </div>
          <div style={{ width: "78%", height: "8px" }} className="progress">
          <div className="progress-bar bg-success" role="progressbar" style={{ width: `${parseInt(rating_1)/parseInt(totalRating)*100}%` }} aria-valuenow={`${parseInt(rating_1)/parseInt(totalRating)*100}`} aria-valuemin="0" aria-valuemax="100" />
          </div>
          <span className="ml-3">{rating_1}</span>
        </div>
      </div>
    </div>
  );
}

export const RatingStars = ({ rating, starSize = "13px", maxRating = 5, starColor = "gold" }) => {
  const [stars, setStars] = useState([]);
  const [mutedStars, setMutedStars] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    if (rating) {
      const colorStarCount = Math.round(parseFloat(rating));
      const blankStarCount = maxRating - colorStarCount;
      const colorStars = getStars(colorStarCount);
      const blankStars = getStars(blankStarCount);
      if (isSubscribed) {
        setStars(colorStars);
        setMutedStars(blankStars);
      }
    } else {
      if (isSubscribed) {
        const blankStars = getStars(maxRating);
        setStars([]);
        setMutedStars(blankStars);
      }
    }
    return () => { isSubscribed = false; }
  }, [rating]);

  return (
    <>
      {stars.length !== 0 &&
        <>{stars.map((star, index) => <i key={index} style={{ fontSize: `${starSize}`, color: `${starColor}` }} className="fas fa-star mr-1" />)}</>
      }
      {mutedStars.length !== 0 &&
        <>{mutedStars.map((star, index) => <i key={index} style={{ fontSize: `${starSize}` }} className="fas fa-star text-muted mr-1" />)}</>
      }
    </>
  );
}

export const InputRating = ({ starColor, handleChange }) => {
  const [rating, setRating] = useState("");
  const [hover, setHover] = useState("");
  const mutedColor = "#868e96";
  const star1 = useRef();
  const star2 = useRef();
  const star3 = useRef();
  const star4 = useRef();
  const star5 = useRef();

  function updateColor(id) {
    switch(id) {
      case "star1":
        star1.current.style.color = starColor;
        star2.current.style.color = mutedColor;
        star3.current.style.color = mutedColor;
        star4.current.style.color = mutedColor;
        star5.current.style.color = mutedColor;
        break;
      case "star2":
        star1.current.style.color = starColor;
        star2.current.style.color = starColor;
        star3.current.style.color = mutedColor;
        star4.current.style.color = mutedColor;
        star5.current.style.color = mutedColor;
        break;
      case "star3":
        star1.current.style.color = starColor;
        star2.current.style.color = starColor;
        star3.current.style.color = starColor;
        star4.current.style.color = mutedColor;
        star5.current.style.color = mutedColor;
        break;
      case "star4":
        star1.current.style.color = starColor;
        star2.current.style.color = starColor;
        star3.current.style.color = starColor;
        star4.current.style.color = starColor;
        star5.current.style.color = mutedColor;
        break;
      case "star5":
        star1.current.style.color = starColor;
        star2.current.style.color = starColor;
        star3.current.style.color = starColor;
        star4.current.style.color = starColor;
        star5.current.style.color = starColor;
        break;
      default: 
        star1.current.style.color = mutedColor;
        star2.current.style.color = mutedColor;
        star3.current.style.color = mutedColor;
        star4.current.style.color = mutedColor;
        star5.current.style.color = mutedColor;
        break;
    }
  }

  function handleHover(event) {
    setHover(event.currentTarget.id);
  }

  function handleUnHover() {
    setHover(rating);
  }

  function handleClick(event) {
    setRating(event.currentTarget.id);
    switch(event.currentTarget.id) {
      case "star1":
        handleChange(1);
        break;
      case "star2":
        handleChange(2);
        break;
      case "star3":
        handleChange(3);
        break;
      case "star4":
        handleChange(4);
        break;
      case "star5":
        handleChange(5);
        break;
      default: 
        break;
    }
  }

  useEffect(() => {
    if (hover) {
      updateColor(hover);
    } else {
      updateColor(rating);
    }
  }, [hover, rating]);

  return (
    <>
      <i onClick={handleClick} onMouseEnter={handleHover} onMouseLeave={handleUnHover} ref={star1} id="star1" style={{ color: `${mutedColor}`, cursor: "pointer" }} className="fas fa-star f-24" />
      <i onClick={handleClick} onMouseEnter={handleHover} onMouseLeave={handleUnHover} ref={star2} id="star2" style={{ color: `${mutedColor}`, cursor: "pointer" }} className="fas fa-star f-24" />
      <i onClick={handleClick} onMouseEnter={handleHover} onMouseLeave={handleUnHover} ref={star3} id="star3" style={{ color: `${mutedColor}`, cursor: "pointer" }} className="fas fa-star f-24" />
      <i onClick={handleClick} onMouseEnter={handleHover} onMouseLeave={handleUnHover} ref={star4} id="star4" style={{ color: `${mutedColor}`, cursor: "pointer" }} className="fas fa-star f-24" />
      <i onClick={handleClick} onMouseEnter={handleHover} onMouseLeave={handleUnHover} ref={star5} id="star5" style={{ color: `${mutedColor}`, cursor: "pointer" }} className="fas fa-star f-24" />
    </>
  );
}
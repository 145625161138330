import React from 'react'
import useChatContext from 'context/chat'

export default function AttachmentButton({ handler }) {
  const { current } = useChatContext()

  return (
    <button
      disabled={current.loading.status}
      type="button"
      className="btn btn-primary btn-icon"
      title="Attach File"
      onClick={handler}
    >
      <i className="fas fa-paperclip" />
    </button>
  )
}

import React from 'react'

const FormSelectPrecontent = ({
  existingContents,
  handleChangePrecontent,
  existingPrecontentId,
  existingContentId,
}) => (
  <div className="form-group mb-5 row">
    <label className="col-sm-3 col-form-label">Materi Prasyarat</label>
    <div className="col-sm-9">
      <select
        value={existingPrecontentId}
        onChange={(event) => handleChangePrecontent(event)}
        className="form-control"
      >
        <option value={''}>-- Materi --</option>
        {existingContents.length !== 0 ? (
          existingContents.map((content) => (
            <option
              key={content.id}
              value={content.id}
              disabled={existingContentId === content.id}
            >
              {content.title}
            </option>
          ))
        ) : (
          <option disabled={true}>Data materi tidak ditemukan</option>
        )}
      </select>
    </div>
  </div>
)

export default FormSelectPrecontent

import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes'
import BaseContextWrapper from 'context/base'

export default function BaseRoutes() {
  return (
    <BrowserRouter>
      <BaseContextWrapper>
        <Routes />
      </BaseContextWrapper>
    </BrowserRouter>
  )
}

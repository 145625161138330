import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import BackButton from 'component/button/Back'
import SubmitButton from 'component/button/Submit'
import { appendScript } from 'util/global'

export default function RolesEdit() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const location = useLocation()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, putRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/roles', name: 'Roles' }]

  /* ======================================= Local States ======================================= */
  const [role, setRole]         = useState(`${location.state.role}`)
  const [active, setActive]     = useState(location.state.active)
  const [type, setType]         = useState(location.state.type)
  const [menuRole, setMenuRole] = useState([])

  // Validation States
  const [isInValidRole, setIsInValidRole]     = useState(false)
  const [isInValidActive, setIsInValidActive] = useState(false)
  const [isInValidType, setIsInValidType]     = useState(false)

  /* =========================================== Refs =========================================== */
  const refFormRole = useRef()
  const refFormType = useRef()

  /* ========================================= Functions ======================================== */
  function initialSelectMenu(modified) {
    let newModified = modified
    newModified.forEach((menu) => {
      location.state.menu.forEach((stateMenu) => {
        if (menu.value === stateMenu.menu_id) {
          menu.isChecked = true
        }
      })

      if (menu.children.length !== 0) {
        menu.children.forEach((child) => {
          location.state.menu.forEach((stateMenu) => {
            if (child.value === stateMenu.menu_id) {
              child.isChecked = true
            }
          })
        })
      }
    })
    return newModified
  }

  async function getMenuRole() {
    try {
      const { data } = await getRequest('menu-role')
      const modified = await data.map((menu) => {
        return {
          value: menu.value,
          label: menu.label,
          isChecked: false,
          children:
            menu.children.length !== 0
              ? menu.children.map((child) => {
                  return {
                    value: child.value,
                    label: child.label,
                    isChecked: false,
                    children: [],
                  }
                })
              : [],
        }
      })
      const modifiedWithInitialState = await initialSelectMenu(modified)
      setMenuRole(modifiedWithInitialState)
    } catch (error) {
      console.error(error)
    }
  }

  async function updateRole() {
    let menuSelected = []
    menuRole.forEach((menu) => {
      if (menu.isChecked === true) {
        menuSelected.push(menu.value)
      }

      if (menu.children.length !== 0) {
        menu.children.forEach((child) => {
          if (child.isChecked === true) {
            menuSelected.push(child.value)
          }
        })
      }
    })
    if (role && type) {
      var form = new FormData()
      form.append('id', location.state.id)
      form.append('role', role)
      form.append('role_type', type)
      form.append('active', active)
      form.append('menu', JSON.stringify(menuSelected))

      let response = await putRequest('roles', form)
      if (response) {
        window.notification('Sukses', 'Data role berhasil diupdate', 'success')
        history.push('roles')
      } else {
        window.notification(
          'Terjadi kesalahan',
          'Coba beberapa saat lagi !',
          'error',
        )
      }
    } else {
      if (!active) {
        setIsInValidActive(true)
      } else {
        setIsInValidActive(false)
      }

      if (!type) {
        setIsInValidType(true)
        refFormType.current.focus()
      } else {
        setIsInValidType(false)
      }

      if (!role) {
        setIsInValidRole(true)
        refFormRole.current.focus()
      } else {
        setIsInValidRole(false)
      }

      window.notification(
        'Data belum lengkap',
        'Mohon lengkapi data',
        'warning',
      )
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    appendScript('/admin/assets/js/plugins/jquery.validate.min.js')
    appendScript('/admin/assets/js/pages/form-validation.js')
    getMenuRole()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Edit Role"
          links={links}
        />

        <div className="row">
          {/* [ Form Validation ] start */}
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Edit Role</h5>
              </div>
              <div className="card-body">
                <form
                  id="validation-form123"
                  action="#!"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <div className="form-group">
                            <label className="form-label">Role</label>
                            <input
                              ref={refFormRole}
                              value={role}
                              onChange={(val) => setRole(val.target.value)}
                              type="text"
                              className={
                                isInValidRole
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                              name="role"
                              placeholder="Masukan Role"
                              required={true}
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-label">Jenis Role</label>
                            <select
                              ref={refFormType}
                              onChange={(e) => setType(e.target.value)}
                              value={type}
                              className={
                                isInValidType
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                              required
                            >
                              <option value="">- Pilih jenis role -</option>
                              <option value="kepalasekolah">
                                Kepala Sekolah
                              </option>
                              <option value="administrator">
                                Administrator
                              </option>
                              {/* <option value="adminprofile">
                                Admin Profile
                              </option> */}
                              <option value="instruktur">Instruktur</option>
                              <option value="siswa">Siswa</option>
                              <option value="orangtua">Orang Tua</option>
                            </select>
                          </div>
                          <fieldset className="form-group">
                            <div className="row">
                              <label
                                htmlFor="active"
                                className="col-sm-3 col-form-label"
                              >
                                Active
                              </label>
                              <div className="col-sm-9">
                                <div className="form-check">
                                  <input
                                    checked={active === true}
                                    onChange={(val) => setActive(true)}
                                    className={
                                      isInValidActive
                                        ? 'form-check-input is-invalid'
                                        : 'form-check-input'
                                    }
                                    type="radio"
                                    name="active"
                                    id="active"
                                    defaultValue="true"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="active"
                                  >
                                    Aktif
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    checked={active === false}
                                    onChange={(val) => setActive(false)}
                                    className={
                                      isInValidActive
                                        ? 'form-check-input is-invalid'
                                        : 'form-check-input'
                                    }
                                    type="radio"
                                    name="active"
                                    id="no-active"
                                    defaultValue="false"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="no-active"
                                  >
                                    Tidak Aktif
                                  </label>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        <div className="row ml-1">
                          <div className="col-lg-12">
                            <BackButton />
                            <SubmitButton
                              onClick={() => updateRole()}
                              text="Simpan"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* [ Form Validation ] end */}
        </div>
        {/* end content */}
      </Container>
    </TemplateAdmin>
  )
}

import { useState } from 'react'
import { useParams } from 'react-router-dom'

import TemplateAdmin from 'component/admin/TemplateAdmin'
import BaseButton from 'component/button/BaseButton'
import LoadingSpinner from 'component/loading'
import BaseSelect from 'component/select/BaseSelect'
import { Container, Header } from 'component/template'
import NotFound from 'pages/404'
import GradeListContentItem from './components/GradeListContentItem'
import { useGradeListContentHook } from './hooks/useGradeListContentHook'
import { CONTENT_TYPE } from 'util/enum'

export default function GradeListContent() {
	/* ========================================== Helpers ========================================== */
  const params = useParams()
  const { data, handler } = useGradeListContentHook()

  const [isHovered, setIsHovered] = useState(false)

  const showActionBySelectedGrade = content => content?.content_grade_contents?.find(c => c?.content_grade?.id === data.listContentsGrade?.selectedFilterGrade?.value)

  return (
    <TemplateAdmin>
      <Container>
        <Header 
          pageName="Daftar Materi"
          links={[
            { url: '/kelas', name: 'Sub Mata Pelajaran' },
            { url: `/kelas/${params?.kelasId}#grade`, name: 'Grade' },
          ]}
          linkName='Materi'
        />

        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header">
                <h4>Daftar Materi</h4>

                <div className='mb-4'>
                  <BaseButton
                    props={{
                      className: "btn btn-outline-danger mr-2"
                    }}
                    variant='transparent'
                    onClick={handler.onBackHandler}
                  >
                    Kembali
                  </BaseButton>
                </div>

                <div className='row'>
                  <div className='col-sm-12 col-md-4'>
                    <label>Pilih Grade</label>
                    <BaseSelect {...{
                      access: data.access,
                      options: data.listContentsGrade.optionsFilterGrade,
                      value: data.listContentsGrade.selectedFilterGrade,
                      onChange: handler.onSelectedFilterGradeListContentHandler,
                      isClearable: false,
                      placeholder: 'Pilih Grade...'
                    }} />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ul className="list-unstyled">
                {data.listContentsGrade.loading ? (
                  <LoadingSpinner />
                ) : data.listContentsGrade.data?.length !== 0 ? (
                  data.listContentsGrade.data?.map((section, index) => (
                    <div 
                      key={`list-materi-grade${section?.id}-index${index}`}
                      className="mb-0 border-top border-right border-left border-bottom"
                    >
                      <div
                        style={{
                          backgroundColor: isHovered ? '#f6f6f6' : '#f8f9fa',
                        }}
                      >
                        <h5 className="row m-0 border-bottom">
                          <div
                            className="col row text-dark py-2 m-0"
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                          >
                            <div
                              className="col px-0 d-flex justify-content-between align-items-center"
                              style={{ height: '50px' }}
                            >
                              <span className="f-16">
                                {section?.urutan}
                                {'. '}
                                {section?.title}{' '}
                              </span>
                              <span
                                style={{
                                  fontWeight: '400',
                                }}
                              >
                                {section?.contents.length}
                                {' materi'}
                              </span>
                            </div>
                          </div>
                        </h5>
                      </div>
                      <div className="collapse show">
                        <ul className="list-unstyled mb-0">
                          {section?.contents.length !== 0 ? (
                            section?.contents.map((content, index) => (
                              <GradeListContentItem
                                key={content.id}
                                item={content}
                                index={index}
                                access={data.access}
                                isShowAction={!!data.listContentsGrade?.selectedFilterGrade?.value}
                                isShowActionCreate={showActionBySelectedGrade(content) && !content?.content_grade_contents?.length}
                                isSelectedGradeExistInContent={!!showActionBySelectedGrade(content)}
                                onClickCreateOrEditChildContentHandler={handler.onClickCreateOrEditChildContentHandler}
                                onClickRemoveChildContentHandler={handler.onRemoveGradeFromContentHandler}
                              />
                            ))
                          ) : (
                            <p className="text-muted p-3 mb-0">Belum ada materi.</p>
                          )}
                        </ul>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="row">
                    <NotFound
                      icon="fas fa-file"
                      text="Materi belum ada"
                    />
                  </div>
                )}
              </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <BaseReactBootstrapModal
          show={data.modalRemoveContentGrade.showModalRemove}
          handleClose={handler.onCloseRemoveChildContentHandler}
          isWithHeader={false}
          centered
          isWithFooter
          okTitle='Hapus'
          okButtonProps={{
            className: 'btn btn-danger'
          }}
          isWithCloseButton={false}
          handleOk={handler.onRemoveGradeFromContentHandler}
        >
          <h5 className='text-center mb-4'>Hapus Grade Dari Materi</h5>
          <div className='form-group'>
            <label>Pilih Grade <span className='text-danger'>*</span></label>
            <BaseSelect {...{
              access: data.access,
              options: data.modalRemoveContentGrade.optionsFilterGrade,
              placeholder: 'Pilih grade...',
              onChange: (choice) => {
                dispatch.dispatchModalRemoveContentGrade({ payload: {
                  selectedFilterGrade: choice,
                  errorMessage: ''
                }})
              },
              isClearable: true,
              isInvalid: !!data.modalRemoveContentGrade?.errorMessage,
            }} />
            {!!data.modalRemoveContentGrade?.errorMessage
            ?
             <small className='text-danger'>{data.modalRemoveContentGrade.errorMessage}</small>
            :null}
          </div>
          <Keterangan className="mt-5" />
        </BaseReactBootstrapModal> */}
      </Container>
    </TemplateAdmin>
  )
}

import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Select from 'react-select'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import TableUsers from './components/table-users'
import ModalRaporDetail from './components/modal-rapor-detail'
import loadingTypes from 'globals/loading-types'

export default function RaporTeacherDownload() {
  /* ========================================= Constants ======================================== */
  const initialRaporModal = {
    user: {
      id: '',
      name: '',
      nim: '',
      avatar: '',
      linkAvatar: '',
      ta_tahun_code: '',
    },
    angkatan: { code: '', tahunMulai: '' },
    semesters: [],
  }

  /* ========================================== Helpers ========================================= */
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [groups, setGroups]                       = useState([])
  const [groupSelect, setGroupSelect]             = useState(null)
  const [limit, setLimit]                         = useState(10)
  const [offset, setOffset]                       = useState(0)
  const [search, setSearch]                       = useState('')
  const [loadingState, setLoadingState]           = useState(loadingTypes.idle)
  const [loadingModalState, setLoadingModalState] = useState(loadingTypes.idle)
  const [raporModal, setRaporModal]               = useState(initialRaporModal)

  /* ========================================= Functions ======================================== */
  const getDataOptions = async (url = '') => {
    const response = await getRequest(url)
    if (response) return response.data
    return []
  }

  const handleChangeGroup = (value) => {
    if (value) {
      setOffset(0)
      setSearch('')
    }
    setGroupSelect(value)
    const urlQuery = value ? `group_id=${value.value}` : ''
    history.push(`/rapor-list?${urlQuery}`)
  }

  const initSelectedItem = (array = [], id, type = '') => {
    const selected = array.find((item) => item.value === id)
    if (type === 'group')
      setGroupSelect(selected ? { label: selected.label, value: id } : null)
  }

  const initDropdownSelect = async (url, type) => {
    const array = await getDataOptions(url)
    const options = array.map((item) => ({ label: item.title, value: item.id }))
    switch (type) {
      case 'group':
        setGroups(options)
        if (query.get('group_id'))
          initSelectedItem(options, query.get('group_id'), type)
        break
      default:
        setGroups([])
        break
    }
  }

  const init = async () => {
    await initDropdownSelect('groups-user-active', 'group')
  }

  const getRaporDetail = async (userId) => {
    const response = await getRequest(
      `report-siswa-detail?user_id=${userId}`,
    )
    return response
      ? {
          user: response.data.user,
          angkatan: response.data.angkatan,
          semesters: response.data.semesters,
        }
      : null
  }

  const onShowModal = async (userId) => {
    setLoadingModalState(loadingTypes.start)
    const raporDetail = await getRaporDetail(userId)
    setRaporModal(raporDetail ? raporDetail : initialRaporModal)
    setLoadingModalState(
      raporDetail ? loadingTypes.success : loadingTypes.error,
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    init()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Download Rapor" />
        
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4 mb-3 mb-md-0">
                <span className="f-w-700 f-14">Kelas</span>
                <Select
                  className="mt-2"
                  placeholder="Pilih kelas"
                  isMulti={false}
                  isClearable={true}
                  isDisabled={
                    loadingState === loadingTypes.start
                  }
                  value={groupSelect}
                  options={groups}
                  onChange={handleChangeGroup}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <TableUsers
                  loadingTypes={loadingTypes}
                  group_id={query.get('group_id')}
                  limit={limit}
                  offset={offset}
                  search={search}
                  setLimit={setLimit}
                  setOffset={setOffset}
                  setSearch={setSearch}
                  setLoadingState={setLoadingState}
                  onShowModal={onShowModal}
                />
                <ModalRaporDetail
                  loadingTypes={loadingTypes}
                  loadingStatus={loadingModalState}
                  detailRapor={raporModal}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import imageCompression from 'browser-image-compression'

export default class ImageHelper {
  async resizeBase64Image(base64Image) {
    const options = {
      maxSizeMB: 0.2, // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: 1392, // compress file ratio (default: undefined)
      useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
      maxIteration: 10, // optional, max number of iteration to compress the image (default: 10)
    }

    const compressImage = await imageCompression(base64Image, options)
    const base64 = await imageCompression.getDataUrlFromFile(compressImage)

    return base64
  }
}

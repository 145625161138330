import React, { useState, useEffect } from 'react';
import InvalidWarning from 'component/invalid/warning'
import useFeedbackForm from 'context/feedback-form'

export default function AddFeedKompleks({ sharedProps }) {
  /* =================================== Consume Shared Props =================================== */
  const {
    isFeedResponIdEmpty,
    isLoading,
    formValidation,
    handleChangeFeedResponIds,
  } = sharedProps

  /* ====================================== Consume Context ===================================== */
  const { feed } = useFeedbackForm()

  /* ========================================= Constants ======================================== */
  const feedResponses = feed.responses;

  /* ========================================== Output ========================================== */
  return (
    <>
      <label>{feed.data ? feed.data.description : ""}{" (Pilih satu atau lebih)"}</label>
      <div className="form-group mb-4">
        {feedResponses?.length !== 0 ?
          feedResponses.map((feedResponse) => {
            return (
              <div key={feedResponse.id} className="custom-control custom-checkbox mb-2">
                <input 
                  type="checkbox" 
                  id={feedResponse.id} 
                  name="pg-feedback" 
                  className="custom-control-input" 
                  value={feedResponse.id}
                  onChange={(event) => handleChangeFeedResponIds(event.target.value, event.target.checked)}
                />
                <label className="custom-control-label" htmlFor={feedResponse.id}>{feedResponse.text}</label>
              </div>
            );
          })
          :
          null
        }
        {isFeedResponIdEmpty ?
          <InvalidWarning text="Feedback tidak boleh kosong" />
          : null
        }
      </div>
      <div className="form-group row mb-0">
        <div className="col-12 col-md-2">
          {isLoading ?
            <button type="button" className="btn btn-sm btn-primary w-100" disabled>
              <span className="spinner-border spinner-border-sm mr-1" role="status" />
              <span>Kirim</span>
            </button>
            :
            <button onClick={formValidation} type="button" className="btn btn-sm btn-primary w-100">
              <i className="fas fa-paper-plane mr-1" />
              <span>Kirim</span>
            </button>
          }
        </div>
      </div>
    </>
  );
}

import React from 'react'

import useOrderSubjectReportDNDList from "pages/order-subject-report/hooks/useOrderSubjectReportDNDList";
import useOrderSubjectReportHeader from "pages/order-subject-report/hooks/useOrderSubjectReportHeader";
import useOrderSubjectReportModalAdd from "pages/order-subject-report/hooks/useOrderSubjectReportModalAdd";

import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import OrderSubjectReportHeader from 'pages/order-subject-report/components/Header'
import BaseButton from 'component/button/BaseButton'
import DNDList from 'pages/order-subject-report/components/DNDList'
import ModalAddOrderSubjectReport from 'pages/order-subject-report//components/modal/ModalAddOrderSubjectReport';


export default function OrderSubjectReport() {
  const access = ['administrator']

  const headerHook = useOrderSubjectReportHeader()
  const DNDListHook = useOrderSubjectReportDNDList({ headerHook })
  const modalAddHook = useOrderSubjectReportModalAdd({ DNDListHook, headerHook })

  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Urutan Mata Pelajaran Rapor" />
        <div className="card user-profile-list">
          <div className="card-header">
            <div className='d-flex justify-content-between'>
              <div>
                <BaseButton access={access} onClick={() => modalAddHook.handler.toggleModalAddOrderSubjectReportHandler(true)}>
                  <i className="feather icon-plus-circle mr-1" />
                  Tambah Kategori Mapel
                </BaseButton>
              </div>
              {headerHook?.customState?.reactSelectState?.selectedClass
              ?
              <div>
                <BaseButton access={access} variant='success' onClick={DNDListHook.handler.onSaveDNDListHandler}>
                  <i className="feather icon-save mr-1" />
                  Simpan
                </BaseButton>
              </div>
              : null}
            </div>
          </div>
          <div className="card-body">
            <OrderSubjectReportHeader modalAddHook={modalAddHook} headerHook={headerHook} />
            <DNDList DNDListHook={DNDListHook} headerHook={headerHook} />
          </div>
        </div>
      </Container>
      <ModalAddOrderSubjectReport
        onCloseHandler={() => modalAddHook.handler.toggleModalAddOrderSubjectReportHandler(false)}
        modalAddHook={modalAddHook}
        headerHook={headerHook}
      />
    </TemplateAdmin>
  )
}

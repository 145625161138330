import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { Back } from 'component/button'
import BarChart from 'component/highchart/bar-chart'
import WordCloud from 'component/highchart/word-cloud-chart'
import LoadingSpinner from 'component/loading'

const DetailSurvey = () => {
  /* ========================================== Helpers ========================================= */
  const location = useLocation()
  const params   = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/survey-engine', name: 'Kelola Survey' }]
  const dataSurvey = location.state.existingData || {}

  /* ======================================= Local States ======================================= */
  const [resultSurvey, setResultSurvey] = useState([])
  const [isLoading, setIsLoading]       = useState(false)

  /* ========================================= Functions ======================================== */
  async function getSurveyResults() {
    setIsLoading(true)
    const response = await getRequest(
      `survey-dashboard?survey_id=${params.surveyId}`,
    )
    if (response) {
      setResultSurvey(response.data)
      setIsLoading(false)
    } else {
      console.error('ERROR', response)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getSurveyResults()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Hasil Survey"
          links={links}
        />

        <div className="row">
          <div className="col">
            <div className="card user-profile-list">
              <div className="card-header">
                <div className="row">
                  <div className="col">
                    <h6>
                      {dataSurvey.title ? dataSurvey.title : 'Tidak ada judul'}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p>
                      {dataSurvey.description ? dataSurvey.description : ''}
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {isLoading ? (
                  <div className="col-sm-12 text-center">
                    <br />
                    <LoadingSpinner />
                    <br />
                  </div>
                ) : (
                  <>
                    <RenderGroupPg />
                    <RenderWordCloud />
                    <RenderPg />
                  </>
                )}
              </div>
              <div className="card-footer">
                <div className="row">
                  <div className="col">
                    <Back />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )

  function RenderGroupPg() {
    if (
      resultSurvey.length > 0 &&
      resultSurvey[0].group.categories.length > 0 &&
      resultSurvey[0].group.series.length > 0
    ) {
      var item = resultSurvey[0].group
      // alert(item.categories.length)
      return (
        <BarChart
          categories={item.categories}
          series={item.series}
        />
      )
    }
    return null
  }

  function RenderWordCloud() {
    const [readMore, setReadMore] = useState(-1)
    const [wordCloud, setWordCloud] = useState(-1)
    if (resultSurvey.length > 0 && resultSurvey[0].essay.length > 0) {
      var i = resultSurvey[0].essay
      return i.map((item, idx) => {
        return (
          <>
            {item.answer.length > 0 ? (
              <>
                {wordCloud !== idx ? (
                  <>
                    <h5 className="mt-3 mb-3">
                      Pertanyaan: {item.label.replace(/<[^>]*>/g, '')}
                    </h5>
                    <strong>Jawaban Siswa:</strong>
                    {item.answer.map((item, index) => {
                      if (index < 10 || idx == readMore) {
                        return (
                          <p className="mt-2 ml-3">
                            {index + 1}. {item}
                            <br />
                          </p>
                        )
                      } else {
                        return null
                      }
                    })}
                  </>
                ) : (
                  <WordCloud
                    string={item.str}
                    label={item.label}
                    key={idx}
                  />
                )}
                {/* Button Tampilkan worldlock */}
                <div className="d-flex justify-content-center align-items-center mb-5">
                  {item.answer.length > 10 && idx !== wordCloud ? (
                    <button
                      onClick={() => {
                        if (idx == readMore) {
                          setReadMore(-1)
                        } else {
                          setReadMore(idx)
                        }
                      }}
                      className="btn btn-secondary mr-2"
                    >
                      Tampilkan lebih {readMore == idx ? 'sedikit' : 'banyak'}
                    </button>
                  ) : null}
                  <button
                    onClick={() => {
                      if (wordCloud === idx) {
                        setWordCloud(-1)
                      } else {
                        setWordCloud(idx)
                      }
                    }}
                    className="btn btn-primary"
                  >
                    Tampilkan{' '}
                    {wordCloud === idx ? 'Jawaban Siswa' : 'WordCloud'}
                  </button>
                </div>
              </>
            ) : (
              <p className="text-muted p-3 mb-0">
                Belum ada jawaban dari siswa.
              </p>
            )}
          </>
        )
      })
    }
    return null
  }

  function RenderPg() {
    if (resultSurvey.length > 0) {
      return (
        <div className={'row'}>
          {resultSurvey[0].pg.map((val, index) => {
            return (
              <div
                key={index}
                className={'col-md-6'}
              >
                <BarChart
                  categories={val.categories}
                  series={val.series}
                />
              </div>
            )
          })}
        </div>
      )
    }
    return null
  }
}

export default DetailSurvey

import React, { useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { useForm } from 'react-hook-form'
import BaseButton from 'component/button/BaseButton'
import { useBaseContext } from 'context/base'
import Swal from 'sweetalert2'
import { useGlobalGradeHandlerHook } from '../overview-grade/hooks/useGlobalGradeHandlerHook'

export default function AddGrade() {
  const history  = useHistory()
  const params = useParams()
  const { handler } = useGlobalGradeHandlerHook()

  const refInputName = useRef()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()


  const links = [
    { url: '/kelas', name: 'Sub Mata Pelajaran' },
    { url: `/kelas/${params?.kelasId}`, name: 'Overview' },
  ]

  const onBackHandler = () => {
    history.replace(`/kelas/${params?.kelasId}#grade`)
  }
  const onSubmit = data => handler.addGradeHandler({
    kelasId: params?.kelasId,
    name: data.name,
    onSuccessHandler: onBackHandler
  })
  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Tambah Grade"
          linkName="Grade"
          links={links}
        />
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <h5>
                  <i className="fas fa-hashtag mr-1" />
                  Informasi Grade
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group mb-5 row">
                        <label
                          className="col-sm-3 col-form-label"
                          htmlFor="name"
                        >
                          <span className="mr-1">Nama Grade</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            ref={refInputName}
                            type="text"
                            {...register("name", { required: true })}
                            autoFocus
                            autoComplete='off'
                            className={`${
                              errors.name ? 'is-invalid' : ''
                            } form-control`}
                            id="name"
                            placeholder="Masukkan Nama Grade Disini..."
                          />
                          {errors.name ? (
                            <label className="col small form-text text-danger">
                              Nama tidak boleh kosong.
                            </label>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group">
                        <BaseButton
                          props={{
                            className: "btn btn-outline-danger mr-2"
                          }}
                          variant='transparent'
                          onClick={onBackHandler}
                        >
                          Kembali
                        </BaseButton>
                        <BaseButton
                          type='submit'
                          variant='success'
                        >
                          Simpan
                        </BaseButton>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

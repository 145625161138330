import React, { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useBaseContext } from 'context/base'
import DraftInfo from './draft-info'
import { useStale } from 'util/use-stale'

export default function FormInputDraft({
  item,
  categoryId,
  reportGroupId,
  badgesConfig,
  scoreLms = '',
  score = 0,
  predicate = '',
  description = '',
  notes = '',
  setShowEditUi,
  setScore,
  setPredicate,
  setDescription,
  isRaport,
  status,
  kelas,
  initScoreData = async (item, reportGroupId, badgesConfig) => { },
}) {
  /* ========================================== Helpers ========================================= */
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const group_id = query.get('group_id')

  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  /* ======================================= Local Stales ======================================= */
  const key = ['input-rapor', query.get('semester_id'), query.get('category_id'), query.get('group_id'), item?.user_id, status].join('_')
  const { loading, data: desc, invalidate, stale, isDrafted } = useStale(description, key)
  const [descV2, setDescV2] = useState(description);

  /* ========================================= Functions ======================================== */
  const calculatePredikat = (score) => {
    score = parseInt(score)
    if (score >= 90) {
      return 'A'
    } else if (score >= 80 && score <= 89) {
      return 'B'
    } else if (score >= 70 && score <= 79) {
      return 'C'
    } else {
      return 'D'
    }
  }

  const validateNumber = (number) => {
    const num = parseInt(number)
    return num >= 0 && num <= 100
  }

  const handleChangeScore = (event) => {
    const value = event.target.value
    if (value || Number.isInteger(value)) {
      // Number.isInteger buat handle 0 biar gk kena falsy coercion
      if (validateNumber(value)) {
        setScore(value)
        setPredicate(calculatePredikat(value))
      }
    } else {
      setScore('')
      setPredicate('')
    }
  }

  // const handleChangeDescription = (event) => setDescription(event.target.value)

  const sendDraft = async ({ status }) => {
    setIsLoadingSubmit(true)
    let form = new FormData()
    form.append('score', score)
    form.append('predicate', predicate)
    form.append('description', descV2)
    form.append('category_id', categoryId)
    form.append('report_group_id', reportGroupId)
    form.append('user_id', item?.user_id)
    form.append('ta_semester_code', query.get('semester_id'))
    form.append('status_approval', status)
    form.append('group_id', group_id)
    await postRequest('draft-report-temp', form)
    invalidate()
    setShowEditUi(false)
    await initScoreData(item, reportGroupId, badgesConfig)
  }

  const textAreaMemo = useMemo(() => {
    return <textarea
      id={key}
      className="form-control mt-2"
      style={{ padding: '5px' }}
      placeholder="Deskripsi"
      defaultValue={descV2}
      onChange={(e) => {
        setDescV2(e.target.value)
      }}
      disabled={
        status == 'Pengetahuan'
          ? isLoadingSubmit
          : true
      }
    />
  }, [setDescV2, status, descV2])

  /* ========================================== Output ========================================== */
  return !loading && (
    <div className="bg-light border p-3 rounded">
      <h6>Form Input Draft Nilai</h6>
      <form>
        <div className="form-group row">
          <label className="col-2 col-form-label">Nilai Rapor</label>
          <div className="col-10 d-flex">
            <input
              type="number"
              className="form-control"
              style={{ width: '65px', padding: '5px' }}
              placeholder="Nilai"
              value={score}
              onChange={handleChangeScore}
              disabled={isLoadingSubmit}
            />
            <button
              type="button"
              className="btn btn-primary btn-sm mt-2 ml-4"
              disabled={scoreLms === ''}
              onClick={
                scoreLms === ''
                  ? () => { }
                  : () => handleChangeScore({ target: { value: scoreLms } })
              }
            >
              Gunakan Nilai LMS
            </button>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-2 col-form-label">Predikat</label>
          <div className="col-10 d-flex align-items-center">
            <span>
              <strong>{predicate ? predicate : '-'}</strong>
            </span>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-2 col-form-label">Deskripsi {isDrafted && <DraftInfo onClick={invalidate} />} </label>
          <div className="col-10">
            {textAreaMemo}
          </div>
        </div>
        <div className="mt-2">
          {score === '' ? (
            <button
              type="button"
              className="btn btn-sm btn-primary mr-2"
              disabled={true}
            >
              Kirim Draft
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-sm btn-primary mr-2"
                disabled={isLoadingSubmit}
                onClick={() => {
                  sendDraft({ status: 'draft' })
                }}
              >
                {isLoadingSubmit ? (
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  />
                ) : null}
                Simpan
              </button>
              <button
                type="button"
                className="btn btn-sm btn-success mr-2"
                disabled={isLoadingSubmit}
                onClick={() => {
                  sendDraft({ status: 'submission' })
                }}
              >
                {isLoadingSubmit ? (
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  />
                ) : null}
                Simpan & Ajukan
              </button>
            </>
          )}
          <button
            type="button"
            className="btn btn-sm btn-outline-secondary"
            onClick={() => {
              setShowEditUi(false)
              initScoreData(item, reportGroupId, badgesConfig)
            }}
          >
            Batal
          </button>
        </div>
      </form>
    </div>
  )
}

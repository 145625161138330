import React, { useState, useRef, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import Scores from 'component/scores'

export default function ScoresDetail() {
  /* ========================================== Helpers ========================================= */
  const history    = useHistory()
  const { userId } = useParams()
  
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/scores', name: 'Nilai Siswa' }]

  /* ======================================= Local States ======================================= */
  const [siswa, setSiswa] = useState(null)

  /* =========================================== Refs =========================================== */
  const isMounted = useRef(true)

  /* ========================================= Functions ======================================== */
  const getSiswa = async () => {
    const response = await getRequest(`siswaById?id=${userId}`)
    if (response) {
      if (isMounted.current === true) setSiswa(response.data)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getSiswa()
    return () => {
      isMounted.current = false
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Nilai Siswa"
          linkName={siswa?.name || ''}
          links={links}
        />

        {siswa ? (
          <div className="card">
            <div className="card-header py-3">
              <button
                onClick={() => history.goBack()}
                className="btn btn-outline-danger"
              >
                Kembali
              </button>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 dt-responsive table-responsive rounded">
                  <table className="table nowrap m-0">
                    <thead>
                      <tr>
                        <th className="p-2">Nama</th>
                        <td className="p-2">{siswa.name}</td>
                      </tr>
                      <tr>
                        <th className="p-2">No. Induk</th>
                        <td className="p-2">{siswa.nim}</td>
                      </tr>
                      <tr>
                        <th className="p-2">Tahun Masuk/Angkatan</th>
                        <td className="p-2">
                          {siswa.ta_tahun_code
                            ? `20${siswa.ta_tahun_code.slice(0, 2)}`
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <th className="p-2">Alamat</th>
                        <td className="p-2">
                          {siswa.location ? siswa.location : '-'}
                        </td>
                      </tr>
                      <tr>
                        <th className="p-2">Telepon</th>
                        <td className="p-2">
                          {siswa.phone ? siswa.phone : '-'}
                        </td>
                      </tr>
                      <tr>
                        <th className="p-2">Email</th>
                        <td className="p-2">
                          {siswa.email ? siswa.email : '-'}
                        </td>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <Scores userId={userId} />
      </Container>
    </TemplateAdmin>
  )
}

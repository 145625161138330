import React from 'react'
import useContentPlayerContext from 'context/content-player'

export default function TabHeader() {
  /* ====================================== Consume Context ===================================== */
  const { tab } = useContentPlayerContext()

  /* ========================================== Output ========================================== */
  return (
    <ul
      className="nav nav-pills mb-3"
      id="pills-tab"
      role="tablist"
    >
      <li className="nav-item">
        <a
          onClick={() => tab.set(1)}
          className={`nav-link ${tab.current == 1 && 'active'}`}
          id="pills-rating-tab"
          data-bs-toggle="pill"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
        >
          Rating
        </a>
      </li>
      <li className="nav-item">
        <a
          onClick={() => tab.set(2)}
          className={`nav-link ${tab.current == 2 && 'active'}`}
          id="pills-take-tab"
          data-bs-toggle="pill"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false"
        >
          Key Take Away
        </a>
      </li>
      <li className="nav-item">
        <a
          onClick={() => tab.set(3)}
          className={`nav-link ${tab.current == 3 && 'active'}`}
          id="pills-notes-tab"
          data-bs-toggle="pill"
          role="tab"
          aria-controls="pills-contact"
          aria-selected="false"
        >
          Catatan
        </a>
      </li>
    </ul>
  )
}

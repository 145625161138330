import React, { useState } from 'react'
import useContentPlayerContext from 'context/content-player'

export default function RatingAppreciation() {
  /* ====================================== Consume Context ===================================== */
  const { content } = useContentPlayerContext()

  /* ======================================= Local States ======================================= */
  const [isClose, setClose] = useState(false)
  const [isHover, setIsHover] = useState(false)

  /* ========================================= Functions ======================================== */
  function handleClose() {
    setClose(true)
  }

  /* ========================================== Output ========================================== */
  return (
    <>
      {content.apresiasi ? (
        <div
          className={`p-3 rounded badge-light-success border mb-0 ${
            isHover ? 'card' : ''
          }`}
          style={{
            position: 'relative',
            display: isClose ? 'none' : 'block',
          }}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <p className="mb-0 mr-1 text-dark f-14">{content.apresiasi}</p>
          <button
            type="button"
            title="Tutup"
            className="p-0"
            style={{
              position: 'absolute',
              top: '7px',
              right: '10px',
              border: 'none',
              outline: 'none',
              backgroundColor: 'transparent',
            }}
            onClick={handleClose}
          >
            <i className="fas fa-times text-dark" />
          </button>
        </div>
      ) : null}
    </>
  )
}

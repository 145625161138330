import React from 'react'

export default function Register() {
  return (
    <div className="auth-wrapper bg-primary">
      <div className="auth-content">
        <div className="row align-items-center text-center">
          <div className="col-md-12">
            <h4 className="mb-3 f-w-400">Sign up</h4>
            <div className="form-group mb-3">
              <label
                className="floating-label"
                htmlFor="Profile"
              >
                Profile
              </label>
              <input
                type="text"
                className="form-control"
                id="Profile"
                placeholder
              />
            </div>
            <div className="form-group mb-3">
              <label
                className="floating-label"
                htmlFor="Email"
              >
                Email address
              </label>
              <input
                type="text"
                className="form-control"
                id="Email"
                placeholder
              />
            </div>
            <div className="form-group mb-3">
              <label
                className="floating-label"
                htmlFor="Fullname"
              >
                Fullname
              </label>
              <input
                type="text"
                className="form-control"
                id="Fullname"
                placeholder
              />
            </div>
            <div className="form-group mb-3">
              <label
                className="floating-label"
                htmlFor="Username"
              >
                Username
              </label>
              <input
                type="text"
                className="form-control"
                id="Username"
                placeholder
              />
            </div>
            <div className="form-group mb-4">
              <label
                className="floating-label"
                htmlFor="Password"
              >
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="Password"
                placeholder
              />
            </div>
            <button className="btn btn-primary btn-block mb-4">Sign up</button>
            <p className="mb-2">
              Already have an account?{' '}
              <a
                href="/login"
                className="f-w-400"
              >
                Signin
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

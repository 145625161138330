import { createContext, useContext, useState } from 'react'
import useContentPlayerContext from 'context/content-player'

const MainContentContext = createContext()

export function MainContentWrapper({ children }) {
  /* ====================================== Consume Context ===================================== */
  const { allContents, activity, content, forum } = useContentPlayerContext()

  /* ======================================= Local States ======================================= */
  const [validComment, setValidComment] = useState(0)

  /* ========================================= Constants ======================================== */
  // Check IsStudent
  const _isStudent = JSON.parse(localStorage.getItem('role_active'))?.role_type === 'siswa'
  
  // Check Feedback
  const isFeedbackActive  = content.feedback
  const isFeedbackSubmit  = !!content.feed?.feed_temps.length // force boolean pake `!!`
  const _isFeedbackPassed = !isFeedbackActive || isFeedbackSubmit

  // Check Forum (Grup Diskusi)
  const _isForumPassed = !forum.required || forum.participated

  // Check Assignment/Quiz if completed
  const _isAssignmentOrQuizPassed = !activity.isAssignmentOrQuiz || activity.isContentCorrected

  // Check Graphor/Survey if submitted
  const _isGraphorOrSurveyPassed = !activity.isGraphorOrSurvey || activity.isContentSubmitted

  // Check komentar ke siswa lain
  const isCommentRequired  = content.komentar_tugas
  const numRequiredComment = content.komentar_wajib
  const _isCommentPassed   = !isCommentRequired || (validComment >= numRequiredComment)

  // Check sidebar checklist
  const _isChecked = allContents.find(ac => ac.id === content.id)?.isCompleted

  // Check rating (jaga2 kalau butuh)
  // const _isRatingPassed = !content.rating || rating.isRated

  // Tentukan apakah harus kirim cca is_complete: 1
  const shouldSendCCA =
  _isStudent &&
  _isFeedbackPassed &&
  _isForumPassed &&
  _isAssignmentOrQuizPassed &&
  _isGraphorOrSurveyPassed &&
  _isCommentPassed &&
  !_isChecked
  
  /* ======================================= Shared Values ====================================== */
  const sharedStates = {
    shouldSendCCA,
    comment: {
      count     : validComment,
      setCount  : setValidComment,
      increment : (count) => setValidComment(count + 1),
    },
  }

  /* ====================================== Export Provider ===================================== */
  return (
    <MainContentContext.Provider value={sharedStates}>
      {children}
    </MainContentContext.Provider>
  )
}

/* ========================================= Export Hook ======================================== */
export default function useMainContent() {
  return useContext(MainContentContext)
}

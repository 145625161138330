import React, { useEffect } from 'react'

export default function DefaultNoAuth() {
  /* ========================================= Functions ======================================== */
  function redirect() {
    setTimeout(() => {
      window.location.replace('/login')
    }, 500)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      redirect()
    }

    return () => {
      isSubscribed = false
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div style={styles.container}>
      <div className="d-flex justify-content-center">
        <div
          className="spinner-border text-light"
          style={styles.loader}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  )
}

/* =========================================== Styles =========================================== */
const styles = {
  container: {
    backgroundColor: '#345DAB',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: { width: '2rem', height: '2rem' },
}

import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { Back } from 'component/button'
import HtmlContent from 'component/html-content'
import PilihanGroup from './PilihanGroup'

export default function DetailSurvey() {
  // INFO
  // quest_type = 0 => pilihan ganda
  // quest_type = 1 => uraian
  // quest_type = 2 => pilihan group

  /* ========================================== Helpers ========================================= */
  const location = useLocation()
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, deleteRequest } = useBaseContext()
  
  /* ========================================= Constants ======================================== */
  const links = [{ url: '/survey-engine', name: 'Kelola Survey' }]
  const dataSurvey = location.state.existingData || {}

  /* ======================================= Local States ======================================= */
  const [questions, setQuestions] = useState([])

  /* ========================================= Functions ======================================== */
  async function getQuestions() {
    const response = await getRequest(
      `surveyquestions?survey_id=${dataSurvey.id}`,
    )
    if (response) {
      const filterQuestions = response.data.filter(
        (question) => question.quest_type !== 2,
      )
      setQuestions(filterQuestions)
    } else {
      console.error('ERROR', response)
    }
  }

  function handleAdd(event, questType) {
    event.preventDefault()
    switch (questType) {
      case 'pilihan-ganda':
        history.push(`/survey-engine/${dataSurvey.id}/add-pilihan-ganda`, {
          dataSurvey: dataSurvey,
        })
        break
      case 'uraian':
        history.push(`/survey-engine/${dataSurvey.id}/add-uraian`, {
          dataSurvey: dataSurvey,
        })
        break
      default:
        break
    }
  }

  function handleEdit(questionData, questType) {
    switch (questType) {
      case 0:
        history.push(`/survey-engine/edit-pilihan-ganda/${questionData.id}`, {
          existingData: questionData,
          dataSurvey: dataSurvey,
        })
        break
      case 1:
        history.push(`/survey-engine/edit-uraian/${questionData.id}`, {
          existingData: questionData,
          dataSurvey: dataSurvey,
        })
        break
      default:
        break
    }
  }

  function handleDelete(questionId, urutan) {
    Swal.fire({
      title: 'Apa anda yakin?',
      text: `Anda akan menghapus pertanyaan nomor ${urutan}!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(
          `surveyquestions?id=${questionId}`,
        )
        if (response) {
          Swal.fire({
            icon: 'success',
            text: 'Pertanyaan berhasil dihapus.',
          }).then(() => history.go(0))
        } else {
          Swal.fire(
            'Oppsss!',
            'Terjadi kesalahan coba beberapa saat lagi.',
            'error',
          )
        }
      }
    })
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getQuestions()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName={dataSurvey?.title}
          linkName="Daftar Pertanyaan"
          links={links}
        />

        <PilihanGroup dataSurvey={dataSurvey} />

        <div className="row">
          <div className="col">
            <div className="card user-profile-list">
              <div className="card-header">
                <div className="row">
                  <div className="col">
                    <h6 className="m-0">Pertanyaan Pilihan Ganda dan Uraian</h6>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="btn-group mr-2 mb-3">
                      <button
                        className="btn  btn-primary dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="feather icon-plus-circle mr-1" />
                        <span>Tambah Pertanyaan</span>
                      </button>
                      <div className="dropdown-menu">
                        <a
                          onClick={(event) => handleAdd(event, 'pilihan-ganda')}
                          className="dropdown-item"
                          type="button"
                        >
                          <i className="fas fa-list-ul mr-1" />
                          <span>Pilihan Ganda</span>
                        </a>
                        <a
                          onClick={(event) => handleAdd(event, 'uraian')}
                          className="dropdown-item"
                          type="button"
                        >
                          <i className="fas fa-pen mr-1" />
                          <span>Uraian</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <QuestionTable
                      questions={questions}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                    />
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="row">
                  <div className="col">
                    <Back />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

const QuestionTable = ({ questions = [], handleEdit, handleDelete }) => {
  return (
    <div className="dt-responsive table-responsive">
      <table className={'table'}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Pertanyaan</th>
            <th>Jenis Pertanyaan</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        {questions.length !== 0 ? (
          <tbody>
            {questions.map((val, i) => {
              return (
                <tr key={i}>
                  <td>{val.urutan}</td>
                  <td>
                    <HtmlContent html={val.text} />
                    {val.quest_type === 0 && (
                      <ul className="list-unstyled">
                        {val.survey_answers.map((vv, i) => {
                          return (
                            <li
                              className="mb-1 d-flex align-items-center"
                              key={i}
                            >
                              <input
                                type="radio"
                                name={`n${val.id}`}
                              />
                              <p className="ml-1 mb-0">{vv.text}</p>
                            </li>
                          )
                        })}
                      </ul>
                    )}
                  </td>
                  <td>
                    {val.quest_type === 0 && (
                      <span>
                        <i className="fas fa-list-ul mr-1" />
                        Pilihan Ganda
                      </span>
                    )}
                    {val.quest_type === 1 && (
                      <span>
                        <i className="fas fa-pen mr-1" />
                        Uraian
                      </span>
                    )}
                    {/* {val.quest_type === 2 && <span><i className='feather icon-check-square mr-1' />Pilihan Group</span>} */}
                  </td>
                  <td>
                    {val.active ? (
                      <span className="badge badge-success">Aktif</span>
                    ) : (
                      <span className="badge badge-secondary">Tidak Aktif</span>
                    )}
                  </td>
                  <td>
                    <button
                      onClick={() => handleEdit(val, val.quest_type)}
                      type="button"
                      title="Edit"
                      className="btn btn-icon btn-success mr-2"
                    >
                      <i className="feather icon-edit" />
                    </button>
                    <button
                      onClick={() => handleDelete(val.id, val.urutan)}
                      type="button"
                      title="Hapus"
                      className="btn btn-icon btn-danger mr-2"
                    >
                      <i className="feather icon-trash-2" />
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td
                colSpan={5}
                className="text-center"
              >
                Belum ada pertanyaan
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  )
}

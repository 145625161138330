import React, { useState } from 'react'

const AssignmentVideo = ({
  handleFile,
  currentFilename,
  isFileTypeInvalid,
  isFileEmpty,
  previewFile,
}) => {
  /* ======================================= Local States ======================================= */
  const [isFileBroken, setIsFileBroken] = useState(false)

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="custom-file">
        <input
          type="file"
          onChange={(event) => handleFile(event.target.files[0], 'video')}
          accept=".mp4"
          className={
            isFileEmpty ? 'custom-file-input is-invalid' : 'custom-file-input'
          }
          id="upload-video"
        />
        <label
          className="custom-file-label"
          htmlFor="upload-video"
        >
          {currentFilename ? currentFilename : 'Pilih file mp4'}
        </label>
        {isFileTypeInvalid ? (
          <label className="col small form-text text-danger">
            Type file video harus mp4.
          </label>
        ) : null}
        {isFileEmpty ? (
          <label className="col small form-text text-danger">
            File tidak boleh kosong.
          </label>
        ) : null}
        {previewFile ? (
          <>
            <p className="mt-3">Preview:</p>
            <video
              key={previewFile}
              style={{ width: '100%', maxHeight: '480px' }}
              controls
              controlsList="nodownload"
              disablePictureInPicture
              onContextMenu={(event) => event.preventDefault()}
              onError={() => setIsFileBroken(true)}
            >
              {/* <source src={`data:video/mp4;base64,${currentFileBase64}`} type="video/mp4" /> */}
              <source
                src={`${previewFile}`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            {isFileBroken ? (
              <label className="col small form-text text-danger">
                File tidak terbaca, pastikan file tidak rusak.
              </label>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  )
}

export default AssignmentVideo

import BaseReactBootstrapModal from 'component/modal/BaseReactBootstrapModal'
import React from 'react'

const MultipleRejectRaporP5Modal = ({ show, handleClose, children, handleOk }) => {
  return (
    <BaseReactBootstrapModal
      show={show}
      handleClose={handleClose}
      size={'lg'}
      isWithHeader={false}
      isWithFooter={true}
      isWithOkButton={true}
      closeTitle='Tolak Rapor'
      okTitle='Tutup'
      handleOk={handleOk}
    >
      {children}
    </BaseReactBootstrapModal>
  )
}

export default MultipleRejectRaporP5Modal
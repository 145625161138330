import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import BackButton from 'component/button/Back'
import SubmitButton from 'component/button/Submit'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { editorConfiguration } from 'util/ckeditor-config'
import { FormSwitch } from 'component/forms'
import {
  FormInputName,
  FormInputUrutan,
  FormSelectSection,
  FormSelectPrecontent,
  FormInputTanggal,
  FormDurasi,
  FormActiveStatus,
  FormActiveKey,
  FormSelectFeedback,
  FormGroupKecil,
  FormInputEndedAt,
  FormInputDesc,
  FormTextArea,
  FormSetToBeScore,
  FormSelectStudent,
} from 'component/content-form'

export default function AddContentText() {
  /* ========================================== Helpers ========================================= */
  const history  = useHistory()
  const location = useLocation()
  const params = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const links = [
    { url: '/kelas', name: 'Mata Pelajaran' },
    { url: `/kelas/${location.state.kelasId}`, name: 'Overview' },
  ]

  /* ======================================= Local States ======================================= */
  const [namaKonten, setNamaKonten]     = useState('')
  const [sectionId, setSectionId]       = useState('')
  const [preContentId, setPrecontentId] = useState('')
  const [active, setActive]             = useState(0)
  const [durasi, setDurasi]             = useState('')
  const [displayAt, setDisplayAt]       = useState('')
  const [contentText, setContentText]   = useState('')
  const [urutan, setUrutan]             = useState('')
  const [sections, setSections]         = useState(location.state.sectionList || [])

  const [existingContents, setExistingContents] = useState([])
  const [feeds, setFeeds]                   = useState([])
  const [feedId, setFeedId]                 = useState(null)
  const [isFeedActive, setIsFeedActive]     = useState(0)
  const [enableGroup, setEnableGroup]       = useState(false)
  const [enableGroupKey, setEnableGroupKey] = useState(false)
  const [maxGroup, setMaxGroup]             = useState('')
  const [descGroup, setDescGroup]           = useState('')
  const [prerequisite, setPrerequisite]     = useState('')
  const [endedAt, setEndedAt]               = useState('')
  const [description, setDescription]       = useState('')
  const [apresiasi, setApresiasi]           = useState('')
  const [rating, setRating]                 = useState(1)
  const [toBeScore, setToBeScore]   = useState({
    isScore: false,
    isKeterampilan: false,
    isPengetahuan: false,
    selectedTypeScore: '',
    isTypeScoreActive: true,
  })
  const [selectedStudents, setSelectedStudents] = useState([])

  // FORM VALIDATION STATES
  const [isNamaInvalid, setIsNamaInvalid]       = useState(false)
  const [isUrutanInvalid, setIsUrutanInvalid]   = useState(false)
  const [isSectionInvalid, setIsSectionInvalid] = useState(false)
  const [isContenInvalid, setIsContentInvalid]  = useState(false)
  const [isMaxGroupValid, setIsMaxGroupValid]   = useState(true)

  // FORM REF
  const refInputName     = useRef()
  const refSelectSection = useRef()
  const refInputUrutan   = useRef()
  const refMaxGroup      = useRef()

  /* ========================================= Functions ======================================== */
  function getExistingContentsBySelectedSection() {
    if (!sectionId) {
      setExistingContents([])
    } else {
      sections.forEach((section) => {
        if (section.id === sectionId) {
          setExistingContents(section.contents)
        }
      })
    }
  }

  async function getFeedsList() {
    const response = await getRequest('feed')
    if (response) {
      const options = response.data.map((item) => ({
        value: item.id,
        label: item.description,
      }))
      setFeeds(options)
    } else {
      console.error('ERROR', response)
    }
  }

  function handleChangeName(event) {
    setNamaKonten(event.target.value)
  }

  function handleChangeUrutan(event) {
    setUrutan(event.target.value)
  }

  function handleChangeSection(event) {
    setSectionId(event.target.value)
  }

  function handleChangePrecontent(event) {
    setPrecontentId(event.target.value)
  }

  function handleChangeActive() {
    if (active === 1) {
      setActive(0)
    }
    if (active === 0) {
      setActive(1)
    }
  }

  function handleChangeActiveKey() {
    if (active === 1) {
      setActive(0)
    }
    if (active === 0) {
      setActive(1)
    }
  }

  function handleChangeDate(event) {
    setDisplayAt(event.target.value)
  }

  function handleChangeDurasi(event) {
    setDurasi(event.target.value)
  }

  function handleChangeFeedback(val) {
    setFeedId(val)
  }

  function handleChangeDesc(event) {
    setDescription(event.target.value)
  }

  function handleChangeEndedAt(event) {
    setEndedAt(event.target.value)
  }

  function handleChangeEnableGroup() {
    if (enableGroup) {
      setEnableGroup(false)
      setMaxGroup('')
      setIsMaxGroupValid(true)
    } else {
      setEnableGroup(true)
      setIsMaxGroupValid(false)
    }
  }

  function handleChangeEnableGroupKey() {
    if (enableGroupKey) {
      setEnableGroupKey(false)
    } else {
      setEnableGroupKey(true)
    }
  }

  function handleChangeMaxGroup(event) {
    if (event.target.value) {
      const absolute = Math.abs(event.target.value)
      const integer = Math.round(absolute)
      setMaxGroup(integer)
      if (integer <= 0) {
        setIsMaxGroupValid(false)
      } else {
        setIsMaxGroupValid(true)
      }
    } else {
      setMaxGroup('')
      setIsMaxGroupValid(false)
    }
  }

  function handleChangeDescGroup(event) {
    setDescGroup(event.target.value)
  }

  function handleChangeApresiasi(event) {
    setApresiasi(event.target.value)
  }

  function handleChangeRating(value) {
    setRating(value ? 1 : 0)
  }

  function toBeScoreValidation() {
    if (toBeScore.isScore) {
      return (toBeScore.isKeterampilan === true || toBeScore.isPengetahuan === true) && toBeScore.selectedTypeScore
    }

    return true
  }

  function handleValidation() {
    const newUrutan = urutan ? Math.abs(parseInt(urutan)) : 0
    const durasiDetik = durasi * 60
    const feedback = isFeedActive === 1 ? feedId : ''

    if (namaKonten && sectionId && contentText && isMaxGroupValid && toBeScoreValidation()) {
      setIsNamaInvalid(false)
      setIsUrutanInvalid(false)
      setIsSectionInvalid(false)
      setIsContentInvalid(false)
      handleSubmitForm(
        contentText,
        namaKonten,
        durasiDetik,
        sectionId,
        newUrutan,
        active,
        displayAt,
        preContentId,
        feedback.value,
        maxGroup,
        descGroup,
        endedAt,
        description,
        apresiasi,
        rating,
      )
    } else {
      if (!isMaxGroupValid) {
        if (refMaxGroup) {
          refMaxGroup.current.focus()
        }
      }

      if (!sectionId) {
        setIsSectionInvalid(true)
        refSelectSection.current.focus()
      } else {
        setIsSectionInvalid(false)
      }

      if (!urutan) {
        setIsUrutanInvalid(true)
        refInputUrutan.current.focus()
      } else {
        setIsUrutanInvalid(false)
      }

      if (!contentText) {
        setIsContentInvalid(true)
      }

      if (!namaKonten) {
        setIsNamaInvalid(true)
        refInputName.current.focus()
      } else {
        setIsNamaInvalid(false)
      }

      window.notification('Mohon', 'lengkapi data', 'warning')

      if (!toBeScoreValidation()) {
        window.notification('Mohon', 'lengkapi juga data "Jadikan Sebagai Penilaian"')
      }
    }
  }

  async function handleSubmitForm(
    contentText,
    title,
    durasi,
    sectionId,
    urutan,
    active,
    displayAt,
    preContentId,
    feedId,
    maxGroup,
    descGroup,
    endedAt,
    description,
    apresiasi,
    rating,
  ) {
    let form = new FormData()

    form.append('content_type', 1)
    form.append('content_text', contentText)
    form.append('title', title)
    form.append('durasi', durasi)
    form.append('section_id', sectionId)
    form.append('urutan', urutan)
    form.append('active', active)
    form.append('rating', rating)

    form.append('is_score', !!toBeScore.isScore)

    if (toBeScore.isScore) {
      form.append('is_keterampilan', !!toBeScore.isKeterampilan)
      form.append('is_pengetahuan', !!toBeScore.isPengetahuan)
      form.append('type_score_id', toBeScore.selectedTypeScore)
    }

    if (Array.isArray(selectedStudents) && !!selectedStudents.length) {
      selectedStudents.forEach((selectedStudent, selectedStudentIndex) => {
        form.append(`spesifik_user_id[${selectedStudentIndex}]`, selectedStudent.id)
      })
    }

    if (feedId) {
      form.append('feedback', feedId)
    }

    if (displayAt) {
      form.append('display_at', displayAt)
    }

    if (endedAt) {
      form.append('ended_at', endedAt)
    }

    if (description) {
      form.append('deskripsi', description)
    }

    if (preContentId) {
      form.append('precontent_id', preContentId)
    }

    if (enableGroup) {
      form.append('group_status', 1)
      if (maxGroup) {
        form.append('group_max', maxGroup)
      }

      if (descGroup) {
        form.append('group_description', descGroup)
      }
    }

    if (apresiasi) {
      form.append('apresiasi', apresiasi)
    }

    const response = await postRequest('contents', form)
    if (response) {
      if (enableGroupKey) {
        let formkey = new FormData()
        formkey.append('description', prerequisite)
        formkey.append('content_id', response.data.id)

        const response1 = await postRequest('contentTakeAway', formkey)
        if (response1) {
          window.notification('Materi', 'berhasil ditambahkan', 'success')
          history.goBack()
        }
      } else {
        window.notification('Materi', 'berhasil ditambahkan', 'success')
        history.goBack()
      }
    } else {
      console.error('ERROR', response)
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getFeedsList()
  }, [])

  useEffect(() => {
    getExistingContentsBySelectedSection()
  }, [sectionId])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Tambah Materi Teks"
          linkName="Materi Teks"
          links={links}
        />
        
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <h5>
                  <i className="fas fa-file-alt mr-1" />
                  Informasi Teks
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <form>
                      {/* Input title */}
                      <FormInputName
                        label="Nama Materi"
                        placeholder="Nama Materi"
                        namaKonten={namaKonten}
                        refInputName={refInputName}
                        isNamaInvalid={isNamaInvalid}
                        handleChangeName={handleChangeName}
                      />

                      {/* Input text content */}
                      <div className="form-group mb-5 row">
                        <label className="col-sm-3 col-form-label">
                          <span className="mr-1">Materi</span>
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <div className="col-sm-9">
                          <CKEditor
                            editor={Editor}
                            data={contentText}
                            config={editorConfiguration}
                            onChange={(_, editor) => {
                              const data = editor.getData()
                              setContentText(data)
                            }}
                          />
                          {isContenInvalid ? (
                            <label className="col small form-text text-danger">
                              Materi tidak boleh kosong.
                            </label>
                          ) : null}
                        </div>
                      </div>

                      {/* Input urutan */}
                      <FormInputUrutan
                        urutan={urutan}
                        isUrutanInvalid={isUrutanInvalid}
                        refInputUrutan={refInputUrutan}
                        handleChangeUrutan={handleChangeUrutan}
                      />

                      {/* Input deskripsi */}
                      <FormInputDesc
                        description={description}
                        handleChangeDesc={handleChangeDesc}
                      />

                      {/* Input Apresiasi */}
                      <FormTextArea
                        id="formInputApresiasi"
                        label="Apresiasi"
                        placeholder="Apresiasi/pesan setelah siswa menyelesaikan materi (opsional)"
                        className="mb-5"
                        value={apresiasi}
                        handleChange={handleChangeApresiasi}
                      />

                      {/* Select section */}
                      <FormSelectSection
                        sections={sections}
                        isSectionInvalid={isSectionInvalid}
                        handleChangeSection={handleChangeSection}
                        existingSectionId={sectionId}
                        refSelectSection={refSelectSection}
                      />

                      {/* Select precontent */}
                      <FormSelectPrecontent
                        existingContents={existingContents}
                        handleChangePrecontent={handleChangePrecontent}
                        existingPrecontentId={preContentId}
                      />

                      {/* Input display at */}
                      <FormInputTanggal
                        handleChangeDate={handleChangeDate}
                        existingDisplayAt={displayAt}
                      />

                      {/* Input ended at */}
                      <FormInputEndedAt
                        endedAt={endedAt}
                        handleChangeEndedAt={handleChangeEndedAt}
                      />

                      {/* Input durasi */}
                      <FormDurasi
                        existingDurasi={durasi}
                        handleChangeDurasi={handleChangeDurasi}
                      />

                      {/* Rating */}
                      <FormSwitch
                        id="formRating"
                        label="Rating Materi"
                        value={rating}
                        handleChange={handleChangeRating}
                      />

                      {/* Select Feedback */}
                      <FormSelectFeedback
                        existingFeeds={feeds}
                        existingFeedId={feedId}
                        isFeedActive={isFeedActive}
                        setIsFeedActive={setIsFeedActive}
                        handleChangeFeeds={handleChangeFeedback}
                        getFeedList={getFeedsList}
                      />

                      {/* Swicth Group Kecil */}
                      <FormGroupKecil
                        enableGroup={enableGroup}
                        handleChangeEnableGroup={handleChangeEnableGroup}
                        maxGroup={maxGroup}
                        descGroup={descGroup}
                        handleChangeMaxGroup={handleChangeMaxGroup}
                        handleChangeDescGroup={handleChangeDescGroup}
                        isMaxGroupValid={isMaxGroupValid}
                        refMaxGroup={refMaxGroup}
                      />

                      {/* Select Specific Students */}
                      <FormSelectStudent 
                        selectedStudents={selectedStudents}
                        setSelectedStudents={setSelectedStudents}
                        subMapelId={params?.kelasId}
                        isWithCheckSavedOrNot={false}
                      />

                      {/* Select To Be Score Or Not */}
                      <FormSetToBeScore
                        value={toBeScore}
                        handleChange={setToBeScore}
                      />

                      {/* Select active status */}
                      <FormActiveStatus
                        existingStatus={active}
                        handleChangeStatus={handleChangeActive}
                      />

                      <FormActiveKey
                        enableGroupKey={enableGroupKey}
                        handleChangeEnableGroupKey={handleChangeEnableGroupKey}
                        prerequisite={prerequisite}
                        setPrerequisite={setPrerequisite}
                      />

                      <div className="form-group">
                        <BackButton />
                        <SubmitButton
                          onClick={() => handleValidation()}
                          text="Simpan"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

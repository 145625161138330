import { useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import FileUploadPreviewer from './partials/file-upload-previewer'
import ImageHelper from 'util/image-compression'
import { getBase64 } from 'util/base64'
import {
  baseStyle,
  activeStyle,
  acceptStyle,
  rejectStyle,
  thumbsContainer,
  thumb2,
} from 'pages/kelas/dropzone-css'

export default function ChatDropzone({ dropzoneStates }) {
  /* ========================================== Helpers ========================================= */
  const imageHelper = new ImageHelper()

  /* ====================================== Dropzone States ===================================== */
  const {
    fileTypes,
    setFileTypes,
    fileToUpload,
    setFileToUpload,
    fileMimeTypes,
    setFileMimeTypes,
  } = dropzoneStates

  /* ========================================== States ========================================== */
  const [files, setFiles] = useState([])

  /* ======================================== useDropzone ======================================= */
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept   : ['image/*', 'video/*', 'audio/*'],
    maxSize  : 1024*1024*25,
    multiple : false,
    onDrop   : (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > 0) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        )
        getBase64(acceptedFiles[0])
          .then((result) => {
            let fileClass = result.split(',')
            const [ _mime ] = fileClass
            
            setFileTypes(acceptedFiles[0].type)
            setFileMimeTypes(_mime)
            
            if (_mime.includes('image')) {
              imageHelper.resizeBase64Image(acceptedFiles[0]).then((output) => {
                setFileToUpload({ name: acceptedFiles[0].name, data: output.split(',')[1] })
              })
            } else {
              setFileToUpload({ name: acceptedFiles[0].name, data: fileClass[1] })
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
      if (fileRejections.length > 0) {
        setFileTypes(null)
        setFileToUpload(null)
        setFileMimeTypes('text')
      }
    },
  })

  /* ========================================== Styles ========================================== */
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  )

  /* ========================================== Effects ========================================= */
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview))
    },
    [files],
  )

  /* ========================================= Partials ========================================= */
  const Thumbs = () => {
    const thumbsStyles = {
      display   : 'flex',
      minWidth  : '0',
      maxWidth  : '100%',
      maxHeight : '400px',
      overflowX : 'auto',
    }

    const containerStyles = {
      height         : 'auto',
      border         : '0px solid transparent',
      width          : '100%',
      justifyContent : 'center'
    }

    return (
      <div style={{ ...thumb2, ...containerStyles}}>
        <div style={thumbsStyles}>
          {fileToUpload && 
            <FileUploadPreviewer 
              mimeTypes = {fileMimeTypes}
              fileTypes = {fileTypes}
              data      = {fileToUpload.data}
            />
          }
        </div>
      </div>
    )
  }

  const ThumbContainer = () =>
    files.length > 0 && (
      <div style={{ ...thumbsContainer, marginTop: 0 }}>
        <Thumbs />
      </div>
    )

  const FooterContainer = () => {
    // Notifier Templates
    const successTemplate = `Berhasil memilih file <strong>${fileTypes}</strong>!`
    const initTemplate    = 'Ukuran file maksimal 25 MB'

    const notifier   = fileToUpload ? successTemplate : initTemplate
    const alertTypes = fileToUpload ? 'success' : 'warning'
    const iconType   = fileToUpload ? 'check' : 'info'

    return (
      <div
        className={`border rounded alert alert-${alertTypes} w-100 mt-2`}
        role="alert"
      >
        <p className="m-0">
          <i className={`fas fa-${iconType}-circle mr-1`} />
          <span dangerouslySetInnerHTML={{ __html: notifier }}></span>
        </p>
      </div>
    )
  }
  
  /* ========================================== Output ========================================== */
  return (
    <>
      <div 
        className="card text-white col-sm-12"
        style={styles.attachContainer}
      >
        <div className="card-header">
          <h4 className="card-title text-center mb-0">Upload File</h4>
        </div>
        <div className="card-body">
          <ThumbContainer />
          { !fileToUpload && (
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p className="mt-5">
                Seret atau klik untuk pilih file yang akan diupload
              </p>
            </div>
          )}
          <FooterContainer />
        </div>
      </div>
    </>
  )
}

/* =========================================== Styles =========================================== */
const styles = {
  attachContainer: {
    border: '1px solid gray',
    boxShadow: '0 0 0 1px gray',
    marginBottom: '0px',
  }
}

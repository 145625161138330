import { useBaseContext } from 'context/base'

const useEditContent = () => {
  const { getRequest } = useBaseContext()

  const getContentTakeAway = async (content_id) => {
    const params = '?content_id=' + content_id
    const res = await getRequest('contentTakeAway' + params)
    return res
  }

  return {
    getContentTakeAway,
  }
}

export default useEditContent

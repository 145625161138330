import React from 'react'
import useContentPlayerContext from 'context/content-player'
import ModalFile from './modal-file'
import ModalFileBtn from './modal-file-btn'
import ModalForum from './modal-forum'
import ModalForumBtn from './modal-forum-btn'
import Row from './row'

export default function SegmentHeader() {
  /* ====================================== Consume Context ===================================== */
  const { playlist } = useContentPlayerContext()

  /* ========================================== Output ========================================== */
  return (
    <div className="card-header">
      <Row>
        <div className="col-12 col-xl-3 mb-2 pr-xl-0">
          <ModalFileBtn />
          <ModalFile />
        </div>
        <div className="col-12 col-xl-3 mb-2 pr-xl-0">
          <ModalForumBtn />
          <ModalForum />
        </div>
        <div className="col-12 offset-xl-3 col-xl-3 text-right">
          <div className="ml-3 d-inline-block">
            <div className="switch switch-primary d-inline m-r-10">
              <input
                onChange={playlist.toggle}
                checked={playlist.isShow}
                type="checkbox"
                id="switch-playlist"
              />
              <label
                htmlFor="switch-playlist"
                className="cr"
              ></label>
            </div>
            <label htmlFor="switch-playlist">
              {playlist.isShow
                ? 'Sembunyikan daftar materi'
                : 'Tampilkan daftar materi'}
            </label>
          </div>
        </div>
      </Row>
    </div>
  )
}

import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { useEffect } from 'react';
import { readonlyEditorConfig } from 'util/ckeditor-config';

export default function CkeditorReadonlyV2({ text }) {

  useEffect(() => {
    const tagA = document.querySelectorAll('.containerCkeditorReadonly2 a')
    tagA.forEach(link => {
      link.addEventListener('click', (e) => {
          link.setAttribute('contenteditable', 'false');
      });

      link?.setAttribute('contenteditable', 'false');
    });

    const tagIMG = document.querySelectorAll('.containerCkeditorReadonly2 .image .ck-widget .ck-widget_with-resizer')
    tagIMG.forEach(el => {
      el.addEventListener('click', (e) => {
          el.setAttribute('contenteditable', 'false');
      });

      el?.setAttribute('contenteditable', 'false');
    });
  }, [])

  return (
    <div className='containerCkeditorReadonly2'>
      <CKEditor
        editor={Editor}
        data={text}
        config={readonlyEditorConfig}
        isDisabled={true}
        disabled={true}
        isReadOnly={true}
        readOnly={true}
      />
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import BackButton from 'component/button/Back'
import SubmitButton from 'component/button/Submit'

export default function KelasKategoriAdd() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [parents, setParents]   = useState([])
  const [parentId, setParentId] = useState(0)
  const [active, setActive]     = useState('')
  const [title, setTitle]       = useState('')
  const [types, setTypes]       = useState([])
  const [type, setType]         = useState('')

  /* ========================================= Functions ======================================== */
  async function handleSaveCategory() {
    if (title && active) {
      const form = new FormData()
      form.append('title', title)
      form.append('parent_id', parentId.toString())
      form.append('active', active)

      if (type) {
        form.append('category_type', type)
      }

      const response = await postRequest('categories', form)
      if (response) {
        window.notification(
          'Mata Pelajaran',
          'berhasil di tambahkan',
          'success',
        )
        history.push('/kelas-kategori')
      }
    } else {
      window.notification('Mohon', 'lengkapi data', 'warning')
    }
  }

  async function getCategoriesType() {
    let response = await getRequest('categories-type')
    if (response) {
      setTypes(response.data)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getCategoriesType()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Tambah Mata Pelajaran"
          links={[{ url: 'kelas-kategori', name: 'Mata Pelajaran' }]}
        />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="form-group">
                    <div className="row">
                      <label className="col-sm-4 col-xl-2 col-form-label">
                        Nama Mata Pelajaran
                      </label>
                      <div className="col-sm-8 col-xl-10">
                        <input
                          value={title}
                          onChange={(val) => setTitle(val.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Nama Mata Pelajaran"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <label className="col-sm-4 col-xl-2 col-form-label">
                        Tipe Mata Pelajaran
                      </label>
                      <div className="col-sm-8 col-xl-10">
                        <select
                          className="form-control"
                          onChange={(val) => setType(val.target.value)}
                        >
                          <option>- Pilih Tipe Mata Pelajaran -</option>
                          {types.map((val, i) => {
                            if (val.categories_types.length > 0) {
                              return (
                                <>
                                  <optgroup label={val.type}>
                                    {val.categories_types.map((v, i) => {
                                      return (
                                        <option value={v.id}>{v.type}</option>
                                      )
                                    })}
                                  </optgroup>
                                </>
                              )
                            } else {
                              return <option value={val.id}>{val.type}</option>
                            }
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <fieldset className="form-group">
                    <div className="row">
                      <label className="col-sm-4 col-xl-2 col-form-label">
                        Status
                      </label>
                      <div className="col-sm-8 col-xl-10">
                        <div className="form-check">
                          <input
                            onChange={(val) => setActive(val.target.value)}
                            className="form-check-input"
                            type="radio"
                            name="active"
                            id="active"
                            defaultValue="true"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="active"
                          >
                            Aktif
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            onChange={(val) => setActive(val.target.value)}
                            className="form-check-input"
                            type="radio"
                            name="active"
                            id="no-active"
                            defaultValue="false"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="no-active"
                          >
                            Tidak Aktif
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <br />
                  <BackButton />
                  <SubmitButton
                    text="Simpan"
                    onClick={() => handleSaveCategory()}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

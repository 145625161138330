import { isFileTypeInvalidHandler } from 'component/content/hooks/useFormInputContent'
import InfoGdrive from './info-gdrive'
import PreviewVideoLink from './preview-video-link'
import { getYoutubeId } from 'util/string'

/**
 * Form Input Video Props
 * 
 * @param {BaseContentFormProps} props
 */
export default function FormInputVideo({ 
  value, 
  setValue, 
  isInvalid, 
  isRequired, 
  errorMessage,
}) {
  const internalSource = '0'
  const umumSource = '1'

  const fileTypeInvalid = isFileTypeInvalidHandler(value, 'video')

  return (
    <div className="form-group mb-5 row">
      <label className="col-sm-3 col-form-label">
        <span className="mr-1">Video</span>
        {isRequired
        ?
          <span className="text-danger">*</span>
        : null }
      </label>
      <div className="col-sm-9">
        <div className="col">
          <div className="form-check">
            <input
              onChange={e => setValue({
                ...value,
                content_source: e.target.value
              })}
              className="form-check-input"
              type="radio"
              name="selectVideo"
              id="umumUrl"
              defaultValue={umumSource}
              defaultChecked={value?.content_source == umumSource}
            />
            <label
              className="form-check-label"
              htmlFor="umumUrl"
            >
              <i className="feather icon-link mr-1" />
              <span>Link</span>
              <span className="ml-1">
                (Google Drive, Youtube, atau link umum)
              </span>
            </label>
          </div>
          {value?.content_source == umumSource ? (
            <div className="mb-4">
              <InfoGdrive />
              <input
                type="text"
                value={value?.link}
                onChange={e => setValue({
                  ...value,
                  link: e.target.value,
                  isChanged: true,
                })}
                className="form-control my-3"
                placeholder="Link: https://youtube.com/watch?v=xyz atau https://drive.google.com/file/d/xyz"
              />
              {value?.link ? (
                <PreviewVideoLink link={value?.link} youtubeId={getYoutubeId(value?.link)} />
              ) : null}
            </div>
          ) : null}
          <div className="form-check">
            <input
              onChange={e => setValue({
                ...value,
                content_source: e.target.value
              })}
              className="form-check-input"
              type="radio"
              name="selectVideo"
              id="uploadVideo"
              defaultValue={internalSource}
              checked={value?.content_source == internalSource}
            />
            <label
              className="form-check-label"
              htmlFor="uploadVideo"
            >
              <i className="fas fa-file-video mr-1" />
              <span>Upload file video (mp4)</span>
            </label>
          </div>
          {value?.content_source == internalSource ? (
            <div className="custom-file my-3">
              <input
                type="file"
                accept="video/*"
                onChange={e => setValue({
                  ...value,
                  file: e.target.files?.[0],
                  isChanged: true,
                })}
                className="custom-file-input"
                id="input-upload-video"
              />
              <label
                className="custom-file-label"
                htmlFor="input-upload-video"
              >
                {value?.file?.name
                  ? value?.file?.name
                  : 'Pilih file video mp4'}
              </label>
            </div>
          ) : null}
          {isInvalid ? (
            <label className="small form-text mt-3 text-danger">
              {fileTypeInvalid ? 'Type file video harus mp4.' : errorMessage || 'Video tidak boleh kosong.'}
            </label>
          ) : null}
        </div>
      </div>
    </div>
  )
}
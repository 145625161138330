import React from 'react'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import Scores from 'component/scores'

export default function ScoresSiswa() {
  const { name, nim, phone, email, location, ta_tahun_code } = JSON.parse(
    localStorage.getItem('user'),
  )

  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Nilai Siswa" />

        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 dt-responsive table-responsive rounded">
                <table className="table nowrap m-0">
                  <thead>
                    <tr>
                      <th className="p-2">Nama</th>
                      <td className="p-2">{name}</td>
                    </tr>
                    <tr>
                      <th className="p-2">No. Induk</th>
                      <td className="p-2">{nim}</td>
                    </tr>
                    <tr>
                      <th className="p-2">Tahun Masuk/Angkatan</th>
                      <td className="p-2">
                        {ta_tahun_code ? `20${ta_tahun_code.slice(0, 2)}` : '-'}
                      </td>
                    </tr>
                    <tr>
                      <th className="p-2">Alamat</th>
                      <td className="p-2">{location ? location : '-'}</td>
                    </tr>
                    <tr>
                      <th className="p-2">Telepon</th>
                      <td className="p-2">{phone ? phone : '-'}</td>
                    </tr>
                    <tr>
                      <th className="p-2">Email</th>
                      <td className="p-2">{email ? email : '-'}</td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Scores />
      </Container>
    </TemplateAdmin>
  )
}

export const listKeterangan = [
  <div><span className='text-danger font-weight-bolder'>x</span> = Siswa tidak mendapatkan materi.</div>,
  <div><span className="font-weight-bolder">-</span> = Siswa belum mengerjakan / menunggu penilaian.</div>,
  // <div><span className="font-weight-bolder px-2 py-1" style={{ backgroundColor: 'rgb(255,255,206)' }}>-</span> = Menunggu penilaian.</div>,
]

export const listKeteranganTabPeriksa = [
  <div>(nilai) = Siswa sudah selesai mengerjakan dan mendapatkan nilai.</div>,
  <div><span className="badge badge-secondary">Belum selesai</span> = Siswa belum selesai mengerjakan.</div>,
  <div><span className="badge badge-warning">Belum dinilai</span> = Siswa sudah mengerjakan tapi belum dinilai.</div>,
  <div><span className="badge badge-warning">Draft (nilai)</span> = Siswa belum mengerjakan tapi sudah dinilai.</div>,
]
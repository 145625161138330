import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { Back } from 'component/button'
import DataTable from 'component/data-table'
import { avatarDefaultUser } from 'util/constant'

const ListSiswa = () => {
  /* ========================================== Helpers ========================================= */
  const params = useParams()
  const location = useLocation()

  /* ========================================= Constants ======================================== */
  const groupId = params.groupId
  const groupData = location.state.groupData
  const links = [{ url: '/siswa-group', name: 'Group Siswa' }]

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [siswaList, setSiswaList]     = useState([])
  const [siswaLength, setSiswaLength] = useState(0)
  const [limit, setLimit]             = useState(10)
  const [offset, setOffset]           = useState(0)
  const [search, setSearch]           = useState('')
  
  /* ========================================= Functions ======================================== */
  async function getGroupSiswa() {
    const response = await getRequest(
      `group-list-siswa?group_id=${groupId}&limit=${limit}&offset=${offset}&search=${search}`,
    )
    if (response) {
      setSiswaList(response.data)
      setSiswaLength(response.totalRecords)
    }
  }

  /* ======================================== Components ======================================== */
  const RenderItem = ({ item }) => {
    return (
      <tr>
        <td>
          <div className="d-flex align-items-center">
            <img
              src={`${item.user.linkAvatar}`}
              alt={item.user.name}
              className="img-radius align-top m-r-15"
              style={{ width: '40px', height: '40px' }}
              onError={(event) => {
                event.currentTarget.src = avatarDefaultUser
              }}
            />
            <h6 className="m-b-0">
              {item.user.name ? item.user.name : <i className="fas fa-minus" />}
            </h6>
          </div>
        </td>
        <td>
          {item.user.nim ? item.user.nim : <i className="fas fa-minus" />}
        </td>
        <td>
          {item.user.phone ? item.user.phone : <i className="fas fa-minus" />}
        </td>
        <td>
          {item.user.email ? item.user.email : <i className="fas fa-minus" />}
        </td>
      </tr>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      getGroupSiswa()
    }

    return () => (isSubscribed = false)
  }, [limit, offset, search])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName={`${groupData?.group}: Daftar Siswa`}
          linkName="Daftar Siswa"
          links={links}
        />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header">
                <div className="row">
                  <div className="col-6 col-md-10">
                    <Back />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col dt-responsive table-responsive">
                    <DataTable
                      className={'table table-striped table-bordered nowrap'}
                      data={siswaList}
                      totalRows={siswaLength}
                      show={[10, 20, 30, 40, 50]}
                      onChangeSearch={(val) => {
                        setSearch(val)
                      }}
                      onChangeLimit={(val) => {
                        setLimit(val)
                      }}
                      onChangePage={(val) => {
                        setOffset(val)
                      }}
                      column={['Nama', 'No. Induk', 'No. Telepon', 'Email']}
                      renderItem={(item) => (
                        <RenderItem
                          item={item}
                          key={item.user.id}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

export default ListSiswa

// meta-kelola yang baru ini membuat daftar kontennya bisa diklik jadi tidak kesubmapel doang
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import Modal from 'component/modal'
import Badge from 'component/badge'
import './meta-kelola.css'
import LoadingSpinner from 'component/loading'
import Swal from 'sweetalert2'

export default function ModalMetaKelola({
  type  = '',   // quiz, tugas, feedback, survey, graphor
  id    = '',
  title = '',
}) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================== States ========================================== */
  const [res, setRes]             = useState()
  const [isLoading, setIsLoading] = useState(false)

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    setIsLoading(true)

    if (type === 'quiz') {
      id && getRequest(`quiz-detail-submapel?quiz_id=${id}`)
        .then((res) => {
          setIsLoading(false)
          setRes(res)
        })
        .catch(console.log)
      }
    else if (type === 'survey') {
      id && getRequest(`survey-detail-submapel?survey_id=${id}`)
        .then((res) => {
          setIsLoading(false)
          setRes(res)
        })
        .catch(console.log)
    }
    else if (type === 'tugas') {
      id && getRequest(`assignment-detail-submapel?assignment_id=${id}`)
        .then((res) => {
          setIsLoading(false)
          setRes(res)
        })
        .catch(console.log)
    }
    else if (type === 'graphor') {
      id && getRequest(`graphors-detail-submapel?graphor_id=${id}`)
        .then((res) => {
          setIsLoading(false)
          setRes(res)
        })
        .catch(console.log)
    }
    else if (type === 'feedback') {
      id && getRequest(`feed-detail-submapel?feedback_id=${id}`)
        .then((res) => {
          setIsLoading(false)
          setRes(res)
        })
        .catch(console.log)
    }
  }, [type, id])

  /* ========================================== Output ========================================== */
  return (
    <Modal
      id="kelola-meta-modal"
      {...{ title }}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : res?.data?.length ? (
        <div className="modal-meta-kelola__container">
          {res?.data?.map((item) => (
            <Submapel
              key={item.id}
              item={item}
            />
          ))}
        </div>
      ) : (
        <div className='modal-meta-kelola__empty'>
          <div className='modal-meta-kelola__empty__icon'>📑</div>
          { type } tidak digunakan dalam submapel manapun
        </div>
      )}
    </Modal>
  )
}

/* ========================================= Components ========================================= */
function Submapel({ item }) {
  const history = useHistory()

  const isDuplicated  = item.class_profiles?.[0]?.status === 'duplicate'
  const isOriginal    = item.class_profiles?.[0]?.is_owner && item.class_profiles?.[0]?.status  === 'approved'
  const isShared      = !item.class_profiles?.[0]?.is_owner && item.class_profiles?.[0]?.status === 'approved'

  return (
    <div 
      className="modal-meta-kelola__submapel" 
      data-dismiss="modal"
    >
      <div
      className="modal-meta-kelola__submapel__title"
      onClick={() => history.push('/kelas/' + item.id)}
      >
        Submapel ~ {item.title}
      </div>
      <div className="modal-meta-kelola__submapel__info">
        <Badge variant={item.active ? 'success' : 'danger '}>
          {item.active ? 'Aktif' : 'Tidak Aktif'}
        </Badge>
        {isOriginal && <Badge variant="primary">Original</Badge>}
        {isDuplicated && <Badge variant="info">Duplikat</Badge>}
        {isShared && <Badge variant="dark">Shared</Badge>}
      </div>
      <hr />
      <ol type="a">
        {item.sections?.map((sectionItem) => <Section key={sectionItem.id} sectionItem={sectionItem} classId={item.id} />)}
      </ol>
    </div>
  )
}

function Section({ sectionItem, classId }) {
  return (
    <li className="modal-meta-kelola__section">
      <div className="modal-meta-kelola__section__title">
        Section ~ {sectionItem.title} <small>({sectionItem.active ? 'Aktif' : 'Tidak Aktif'})</small>
      </div>
      <ol>
        {sectionItem.contents?.map((contentItem) => <Content key={contentItem.id} classId={classId} contentItem={contentItem} />)}
      </ol>
    </li>
  )
}

function Content({ contentItem, classId }) {
  const history = useHistory()
  const { getRequest } = useBaseContext()

  async function handleRedirectContent() {
    const response = await getRequest(`content-detail?content_id=${contentItem.id}`)
    const swalLoadingGetContent = Swal.fire({
      title: 'Harap tunggu sebentar...',
      html: `<div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>`,
      allowOutsideClick: false,
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
    })
    if (response) {
      if (response.data.length !== 0) {
        const content = response.data;
        history.push(`/kelas/${classId}/content/${contentItem.id}?tab=content`, { content })
        Swal.close(swalLoadingGetContent)
      }
    } else {
      Swal.close(swalLoadingGetContent)
    }
  }

  return (
    <li className="modal-meta-kelola__content">
      <div
        className="modal-meta-kelola__content__title"
        onClick={handleRedirectContent}
      >
        {contentItem.title} <small>({contentItem.active ? 'Aktif' : 'Tidak Aktif'})</small>
      </div>
    </li>
  )
}

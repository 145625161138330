import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import RaporLoading from './rapor-loading'
import RaporSuccess from './rapor-success'
import RaporIdle from './rapor-idle'
import RaporError from './rapor-error'

const RaporTable = ({
  loadingTypes,
  category_id,
  group_id,
  semester_id,
  semester,
  classId,
  subMapel,
  loadingSearch,
  setLoadingSearch,
  blockSelect,
}) => {
  /* ========================================== Helpers ========================================= */
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [result, setResult] = useState(null)

  /* ========================================= Functions ======================================== */
  async function getSiswaByCategory(classId, group_id, semester_id) {
    if (!blockSelect?.value || !group_id) return
    if (category_id) {
      let searchQuery = `ta_semester_code=${semester_id}&class_id=${query.get('classes_id')}&group_id=${group_id}&period_id=${blockSelect?.value}`
      const response = await getRequest(`get-list-siswa?${searchQuery}`)
      return response ? response.data : null
    } else {
      return null
    }
  }

  async function handleSearch(classId, group_id, semester_id) {
    setLoadingSearch(loadingTypes.start)
    const data = await getSiswaByCategory(classId, group_id, semester_id)
    setResult(data)
    setLoadingSearch(data === null ? loadingTypes.error : loadingTypes.success)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (classId) {
      handleSearch(classId, group_id, semester_id)
    }
  }, [classId, group_id, semester_id])

  /* ========================================== Output ========================================== */
  return (
    <div className="card">
      <div className="card-body">
        {loadingSearch === loadingTypes.idle && <RaporIdle />}
        {loadingSearch === loadingTypes.start && <RaporLoading />}
        {loadingSearch === loadingTypes.success && (
          <RaporSuccess
            result={result}
            classId={classId}
            category_id={category_id}
            group_id={group_id}
            semester_id={semester_id}
            semester={semester}
            subMapel={subMapel}
            handleSearch={handleSearch}
          />
        )}
        {loadingSearch === loadingTypes.error && (
          <RaporError errorMessage="Terjadi kesalahan, coba beberapa saat lagi" />
        )}
      </div>
    </div>
  )
}

export default RaporTable

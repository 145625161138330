import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Select from 'react-select'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'

export default function AktifitasSiswaTimeActivity() {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()
  
  /* ======================================= Local States ======================================= */
  const [activity, setActivity]   = useState([])
  const [listGroup, setListGroup] = useState([])
  const [filterBy, setFilterBy]   = useState('login')
  const [groupId, setGroupId]     = useState('')

  /* ========================================= Functions ======================================== */
  async function getListGroup() {
    let res = await getRequest(`groups`)
    if (res) {
      let optionsGroup = []
      if (res.data.length >= 0) {
        res.data.map((item) => {
          optionsGroup.push({ label: item.group, value: item.id })
        })
      }
      setGroupId(optionsGroup[0])
      setListGroup(optionsGroup)
    }
  }

  async function getActivity() {
    if (groupId) {
      let res = await getRequest(
        `time-activity-siswa?filterBy=${filterBy}&group_id=${groupId?.value}`,
      )
      if (res) {
        setActivity(res.data)
      }
    }
  }

  async function getAllData() {
    await getListGroup()
    await getActivity()
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getAllData()
  }, [])

  useEffect(() => {
    getActivity()
  }, [filterBy, groupId])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Laporan" linkName="Laporan Aktivitas Siswa" />
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header">
                <div className="row">
                  <label className="col-2 col-md-2 col-form-label">
                    Filter
                  </label>
                  <div className="col-3 col-md-4">
                    <select
                      className="form-control"
                      value={filterBy}
                      onChange={(event) => {
                        setFilterBy(event.target.value)
                      }}
                    >
                      <option value="login">Aktivitas Login</option>
                      <option value="content">Aktivitas Belajar</option>
                    </select>
                  </div>
                </div>
                <div className="row mt-3">
                  <label className="col-2 col-md-2 col-form-label">
                    Kelas Siswa
                  </label>
                  <div className="col-3 col-md-4">
                    <Select
                      name="colors"
                      options={listGroup}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={groupId}
                      onChange={(v) => setGroupId(v)}
                      noOptionsMessage={() => 'Kelas tidak ditemukan'}
                      placeholder={'Pilih kelas'}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="dt-responsive table-responsive">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        type: 'column',
                      },
                      title: {
                        text: `Aktivitas ${
                          filterBy == 'login' ? 'Login' : 'Belajar'
                        } Siswa`,
                      },
                      subtitle: {
                        text: '',
                      },
                      xAxis: {
                        categories: activity.categories,
                        crosshair: true,
                      },
                      yAxis: {
                        min: 0,
                        title: {
                          text: '',
                        },
                      },
                      tooltip: {
                        headerFormat:
                          '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat:
                          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true,
                      },
                      plotOptions: {
                        column: {
                          pointPadding: 0.2,
                          borderWidth: 0,
                        },
                      },
                      series: activity.series,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

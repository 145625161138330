import React, { useEffect, useState } from 'react'
import LoadingSpinner from 'component/loading'
import ErrorUI from 'component/error-ui'
import RaporSkeleton from './rapor-skeleton'
import RaporSuccess from './rapor-success'
import { useBaseContext } from 'context/base'

const RaporTable = ({
  loadingTypes,
  taTahun,
  category_id,
  group_id,
  loadingSearch,
  setLoadingSearch,
  semester_id,
  class_id,
  period_id,
  subMapelName,
}) => {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const tableConfig = {
    headers: [
      'No.',
      'Nama',
      'Nilai LMS (Sistem)',
      'Nilai Keterampilan',
      'Predikat',
      'Nilai Pengetahuan',
      'Predikat',
      'Status',
      'Catatan',
      'Actions',
    ],
    totalColumns: 9,
  }

  /* ======================================= Local States ======================================= */
  const [result, setResult] = useState(null)
  
  /* ========================================= Functions ======================================== */
  async function getSiswaByCategory(class_id, group_id, semester_id) {
    if (category_id) {
      let searchQuery = `ta_semester_code=${semester_id}&class_id=${class_id}&group_id=${group_id}`      
      const response = await getRequest(`siswa-by-kelas?${searchQuery}`)
      return response ? response.data : null
    } else {
      return null
    }
  }

  async function handleSearch(class_id, group_id, semester_id, period_id) {
    setLoadingSearch(loadingTypes.start)
    const data = await getSiswaByCategory(class_id, group_id, semester_id)
    setResult(data)
    setLoadingSearch(data === null ? loadingTypes.error : loadingTypes.success)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (group_id && class_id) {
      handleSearch(class_id, group_id, semester_id, period_id)
    }
  }, [class_id])

  /* ========================================== Output ========================================== */
  return (
    <div className="card">
      <div className="card-body">
        {loadingSearch === loadingTypes.idle && (
          <RaporSkeleton
            tableConfig={tableConfig}
            title="Pilih Sub Mata Pelajaran"
            bodyPlaceholder="Pilih sub mata pelajaran"
          />
        )}
        {loadingSearch === loadingTypes.start && (
          <RaporSkeleton
            tableConfig={tableConfig}
            title="Memuat..."
            bodyPlaceholder={<LoadingSpinner />}
          />
        )}
        {loadingSearch === loadingTypes.error && (
          <RaporSkeleton
            tableConfig={tableConfig}
            title="Terjadi Kesalahan :("
            bodyPlaceholder={<ErrorUI />}
          />
        )}
        {loadingSearch === loadingTypes.success && (
          <RaporSuccess
            tableConfig={tableConfig}
            result={result}
            taTahun={taTahun}
            semester_id={semester_id}            
            category_id={category_id}
            group_id={group_id}
            class_id={class_id}
            period_id={period_id}
            subMapelName={subMapelName}
            handleSearch={handleSearch}
          />
        )}
      </div>
    </div>
  )
}

export default RaporTable

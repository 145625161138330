import React from 'react'
import useFeedbackForm from 'context/feedback-form'
import FeedbackEssay from '../feedback-essay/feedback-essay'
import FeedbackKompleks from '../feedback-kompleks/feedback-kompleks'
import FeedbackPilihanGanda from '../feedback-pilihan-ganda/feedback-pilihan-ganda'

export default function FeedForm() {
  const { feed } = useFeedbackForm()

  switch (feed.type) {
    case 1:
      return <FeedbackEssay />
    case 2:
      return <FeedbackPilihanGanda />
    case 3:
      return <FeedbackKompleks />
    case 4:
      return <FeedbackEssay />
    default:
      return null
  }
}

import { useBaseContext } from "context/base"
import { useReducer } from "react"
import { popUpWindow } from "util/global"

/**
 * @typedef {Object} ListSurveyState
 * @property {BaseSelectFormatData} selectedFilter - The selected survey filter.
 * @property {Array<BaseSelectFormatData>} optionsFilter - The options for the survey filter.
 * @property {Array} data - The list of contents survey data.
 * @property {Object} meta - Metadata related to pagination and filtering.
 * @property {boolean} loading - Indicates if the data is currently being loaded.
*/

/**
 * @typedef {Object} Action
 * @property {'set_meta' | string} [type] - The type of action to perform.
 * @property {any} payload - The payload containing the data to update the state.
 */

/**
 * List Contents  Reducer
 * @param {ListSurveyState} state - The current state of the contents survey list.
 * @param {Action} action - The action to perform on the state.
 * @returns {ListSurveyState} The updated state.
 */
const listSurveyReducer = (state, action) => {
  switch (action.type) {
    case 'set_meta':
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload
        }
      }
    default:
      return {
        ...state,
        ...action.payload,
      }
  }
}

export function useSurveyHook() {
  const { getRequest } = useBaseContext()

  /**
   * @type {[ListSurveyState, (params: Action) => void]}
  */
  const [listSurvey, dispatchListSurvey] = useReducer(listSurveyReducer, {
    selectedFilter: null,
    optionsFilter: [],
    errorMessage: '',
    data: [],
    meta: {
      search: '',
      limit: 10,
      offset: 0,
      totalData: 0,
    },
    loading: false,
  })

  /**
   * Get Survey Handler
   * 
   * @param {{
   *  setInitSelectValue: (listOptions: Array<BaseSelectFormatData>) => void
   * }} getSurveyParams 
   */
  const getSurveyHandler = async (getSurveyParams) => {
    dispatchListSurvey({ payload: { loading: true } })
    const response = await getRequest('survey')
    if (response) {
      dispatchListSurvey({ payload: { loading: false } })

      const options = response?.data?.map((item) => ({
        value: item.id,
        label: item.title,
      }))

      const responseData = {
        ...listSurvey,
        optionsFilter: options,
        data: response?.data,
        meta: {
          ...listSurvey.meta,
          ...response.meta
        },
        loading: false,
      }

      if (getSurveyParams?.setInitSelectValue && typeof getSurveyParams?.setInitSelectValue === 'function') {
        getSurveyParams.setInitSelectValue(options)
      }
      dispatchListSurvey({ payload: responseData })
    } else {
      dispatchListSurvey({ payload: { loading: false } })
    }
  }

  const popUpWindowKelolaSurveyHandler = () => popUpWindow('/survey-engine', window, 960, 600)
  return {
    data: {
      listSurvey,
    },
    dispatch: {
      dispatchListSurvey,
    },
    handler: {
      getSurveyHandler,
      popUpWindowKelolaSurveyHandler,
    }
  }
}
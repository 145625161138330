import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useBaseContext } from 'context/base'
import { RatingFull, RatingStars } from 'component/rating'
import LoadingSpinner from 'component/loading'
import { avatarDefaultUser } from 'util/constant'

export default function RatingMateri({ contentId }) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [rating, setRating]               = useState(null)
  const [ratings, setRatings]             = useState([])
  const [loading, setLoading]             = useState(false)
  const [loadingRating, setLoadingRating] = useState(false)
  const [showDetail, setShowDetail]       = useState(false)

  /* ========================================= Functions ======================================== */
  async function getUsersRating() {
    const response = await getRequest(
      `users-rating?type=content&value_id=${contentId}`,
    )
    setLoading(false)
    if (response) {
      setRating(response.data)
      setRatings(response.data.ratings)
    }
  }

  async function handleDetail() {
    if (showDetail) {
      setShowDetail(false)
      setRatings([])
    } else {
      setLoadingRating(true)
      await getUsersRating()
      setLoadingRating(false)
      setShowDetail(true)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    let subscribed = true
    if (subscribed) {
      setLoading(true)
      getUsersRating()
    }
    return () => {
      subscribed = false
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div className="row">
      {loading ? (
        <div className="col-12">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="col-12">
          {rating ? (
            <div className="row mb-3">
              <div className="col-12 col-xl-6">
                <RatingFull
                  rating={rating.ratingAvg}
                  rating_1={rating.rating_1}
                  rating_2={rating.rating_2}
                  rating_3={rating.rating_3}
                  rating_4={rating.rating_4}
                  rating_5={rating.rating_5}
                  totalRating={rating.totalRating}
                  maxRating={5}
                  starColor="#ffba57"
                />
              </div>
              <div className="col-12 col-xl-6">
                <h6 className="mt-xl-3">Rating ({rating.totalRating})</h6>
                <button
                  onClick={handleDetail}
                  disabled={loadingRating}
                  className="btn btn-link align-self-start p-0 text-secondary"
                >
                  {loadingRating ? (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  ) : null}
                  {showDetail ? 'Sembunyikan' : 'Tampilkan semua rating...'}
                </button>
                {showDetail ? (
                  <>
                    {loadingRating ? (
                      <LoadingSpinner />
                    ) : (
                      <>
                        {ratings.length !== 0 ? (
                          <div className="border rounded mt-3">
                            {ratings.map((item, index) => {
                              // setLike(item.likes)
                              let dd = moment(item.createdAt)
                                .add(30, 'days')
                                .valueOf()
                              let date =
                                dd < moment().valueOf()
                                  ? moment(item.createdAt).format('DD MMM YYYY')
                                  : moment(item.createdAt).fromNow()
                              return (
                                <div
                                  key={item.id}
                                  className={`${
                                    index === 0 ? '' : 'border-top'
                                  }`}
                                >
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col">
                                        <div className="d-flex align-items-center">
                                          <img
                                            src={`${item.user.linkAvatar}`}
                                            alt={
                                              item.user.name
                                                ? item.user.name
                                                : 'User'
                                            }
                                            className="img-radius mb-2"
                                            style={{
                                              height: '30px',
                                              width: '30px',
                                            }}
                                            onError={(e) =>
                                              (e.currentTarget.src =
                                                avatarDefaultUser)
                                            }
                                          />
                                          <div className="ml-2">
                                            <p className="mb-0 d-flex align-items-center">
                                              <span className="f-w-500">
                                                {item.user.name}
                                              </span>
                                            </p>
                                            <div>
                                              <span>
                                                <RatingStars
                                                  rating={item.rating}
                                                  starSize="10px"
                                                  maxRating={5}
                                                  starColor="#ffba57"
                                                />
                                              </span>
                                              <span
                                                className="text-muted ml-2 mb-2"
                                                style={{ fontSize: '10px' }}
                                              >
                                                <i className="feather icon-clock mr-1" />
                                                {date}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <p className="m-0">
                                          {item.description}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        ) : (
                          <div className="d-flex flex-column justify-content-center my-4">
                            <i
                              className="fas fa-comments f-60 text-center mb-3"
                              style={{
                                color: '#ccc',
                              }}
                            />
                            <h6
                              className="text-center"
                              style={{
                                color: '#bbb',
                              }}
                            >
                              Belum ada rating
                            </h6>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

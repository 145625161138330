import React, {
  useCallback,
  useContext,
  createContext,
  useEffect,
  useState,
} from 'react'
import { useBaseContext } from 'context/base'
import Swal from 'sweetalert2';

const InputKindnessContext = createContext()

export function InputKindnessWrapper({ children, selectedClass, selectedSemester }) {
  const totalInputDimentions = 6;
  const totalInputProjects = 3;

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest } = useBaseContext()

  /* ========================================== States ========================================== */
  const [listKindness, setListKindness] = useState([]);
  const [studentScores, setStudentScores] = useState([]);

  const [triggerGetStudentScores, setTriggerGetStudentScores] = useState(false)

  const [isLoadingListKindness, setisLoadingListKindness] = useState(false);
  const [isLoadingSaveProjectDescription, setIsLoadingSaveProjectDescription] = useState(false);
  const [isLoadingSaveStudentScores, setIsLoadingSaveStudentScores] = useState(false);
  const [isLoadingGetStudentScores, setIsLoadingGetStudentScores] = useState(false);
  const [isLoadingSubmitReport, setIsLoadingSubmitReport] = useState(false);
  const [isLoadingRejectedReport, setIsLoadingRejectedReport] = useState(false)
  const [isLoadingApprovedReport, setIsLoadingApprovedReport] = useState(false)

  /* ========================================= Functions ======================================== */
  const listKindnessReactSelectFormatData = (data, index) => {
    const newData = { label: data?.name, value: data?.id, index };
    return newData
  }


  const mappingListKindnessData = ({ data, selectedListKindnessData }) => {
    try {
      const tempData = [];
      Array.from({ length: totalInputDimentions }).forEach((_, index) => {
        const isSelectedListKindnessDataExist = data?.find(x => x?.id === selectedListKindnessData?.[index]?.dimensi_id);

        const elements = isSelectedListKindnessDataExist?.elements

        const formattedReactSelectData = data?.map((d, idx) => ({ ...d, label: d?.name, value: d?.id, index: idx, elements: d?.elements?.map((d, idx) => ({ ...d, label: d?.name, value: d?.id, index: idx, sub_elements: d?.sub_elements?.map((d, idx) => ({ ...d, label: d?.name, value: d?.id, index: idx })) })) }));

        tempData[index] = {
          ...isSelectedListKindnessDataExist,
          dimentions: formattedReactSelectData,
        };

        if (isSelectedListKindnessDataExist) {
          tempData[index] = {
            ...tempData[index],
            dimensi_id: selectedListKindnessData?.[index]?.dimensi_id || undefined,
            element_id: selectedListKindnessData?.[index]?.elemen_id || undefined,
            sub_element_id: selectedListKindnessData?.[index]?.sub_elemen_id || undefined,
            capaian_id: selectedListKindnessData?.[index]?.capaian_id || undefined,
            selected_dimention: {
              label: selectedListKindnessData?.[index]?.dimensi?.name,
              value: selectedListKindnessData?.[index]?.dimensi?.id,
              elements,
            }
          }
        }

        const selected_element = elements?.find(e => e?.id === selectedListKindnessData?.[index]?.elemen_id);

        if (selected_element) {
          tempData[index] = {
            ...tempData[index],
            selected_element: {
              label: selected_element?.name,
              value: selected_element?.id,
              sub_elements: selected_element?.sub_elements,
            }
          }

          const selected_sub_element = selected_element?.sub_elements?.find(e => e?.id === selectedListKindnessData?.[index]?.sub_elemen_id);

          if (selected_sub_element) {
            tempData[index] = {
              ...tempData[index],
              selected_sub_element: {
                label: selected_sub_element?.name,
                value: selected_sub_element?.id,
                capaian: selected_sub_element?.capaian,
              }
            }
          }
        }

        if (Array.isArray(elements) && elements.length) {
          elements.forEach((e, indexElement) => {
            const subElements = e?.sub_elements;

            const formattedElementsReactSelectData = listKindnessReactSelectFormatData(e, indexElement);

            tempData[index].elements[indexElement] = {
              ...e,
              ...formattedElementsReactSelectData,
            }


            if (Array.isArray(subElements) && subElements.length) {
              subElements.forEach((s, indexSubElement) => {

                const formattedSubElementsReactSelectData = listKindnessReactSelectFormatData(s, indexSubElement);

                tempData[index].elements[indexElement].sub_elements[indexSubElement] = {
                  ...s,
                  ...formattedSubElementsReactSelectData,
                }
              })
            }
          })
        }
      })

      return tempData
    } catch (error) {
      window.notification(
        'Error Mapping List Kindness Data',
        error?.message || 'Something went wrong',
        'error',
      )
    }
  }

  const getListKindness = useCallback(async () => {
    try {
      if (selectedClass !== null) {
        let res = await getRequest('report-p5/list-all-jenis-penilaian')
        if (res?.data) {
          return res?.data;
        }
      } else {
        return [];
      }
    } catch (error) {
      window.notification(
        'Error Get List Kindness',
        error?.message || 'Something went wrong',
        'error',
      )
      return [];
    }

  }, [selectedClass, setListKindness, getRequest])

  const getListProjects = useCallback(async () => {
    setListKindness([]);
    setisLoadingListKindness(true);
    try {
      if (selectedClass !== null && selectedSemester != null) {
        let res = await getRequest(`report-p5/${selectedClass?.value}/${selectedSemester?.value}/list-proyek`)
        if (res) {
          const listKindnessData = await getListKindness();

          const projectData = res?.data || []

          const manipulatedProjectData = Array.from({ length: totalInputProjects })?.map((_, index) => {
            const necessaryData = {};

            necessaryData.title = projectData?.[index]?.title;
            necessaryData.description = projectData?.[index]?.description;
            necessaryData.index = index;

            necessaryData.proyek_type_scores = mappingListKindnessData({ data: listKindnessData, selectedListKindnessData: projectData?.[index]?.proyek_type_scores })

            return necessaryData;
          });

          setListKindness(manipulatedProjectData)
          setisLoadingListKindness(false);
        }
      } else {
        setisLoadingListKindness(false);
        setListKindness([]);
      }
    } catch (error) {
      window.notification(
        'Error Get List Projects - ',
        error?.message || 'Something went wrong',
        'error',
      )
      setisLoadingListKindness(false);
      setListKindness([]);
    }

  }, [selectedClass, selectedSemester])

  const getStudentScoresHandler = useCallback(async ({ userId }) => {
    setStudentScores([]);
    setIsLoadingGetStudentScores(true);
    try {
      if (selectedClass !== null && selectedSemester != null) {
        let res = await getRequest(`report-p5/${selectedClass?.value}/${selectedSemester?.value}/${userId}/score-siswa`);
        if (res) {
          const data = res?.data || []

          setStudentScores(data);
          setIsLoadingGetStudentScores(false);
        }
      } else {
        setIsLoadingGetStudentScores(false);
        setStudentScores([]);
      }
    } catch (error) {
      window.notification(
        'Error Get Student Score - ',
        error?.message || 'Something went wrong',
        'error',
      )
      setIsLoadingGetStudentScores(false);
      setStudentScores([]);
    }

  }, [getRequest, selectedClass, selectedSemester])


  const onSaveProjectDescriptionHandler = async (data) => {
    Swal.fire({
      title: 'Konfirmasi',
      text: 'Anda akan menyimpan Deskripsi Proyek ini?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Simpan!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setTriggerGetStudentScores(false);
        setIsLoadingSaveProjectDescription(true)
        const response = await postRequest('report-p5/save-proyek', { proyek: data })

        if (response) {
          setTriggerGetStudentScores(true);
          setIsLoadingSaveProjectDescription(false);
          window.notification('Berhasil menyimpan data deskripsi proyek!', '', 'success')
          getListProjects();
        } else {
          setTriggerGetStudentScores(false);
          setIsLoadingSaveProjectDescription(false)
        }
      }
    });
  }

  const onSaveStudentScores = async ({ data, userId }) => {
    setTriggerGetStudentScores(false);
    setIsLoadingSaveStudentScores(false)

    const necessaryBackendData = data.map((item) => {
      return {
        proyek_id: item.proyek_id,
        note: item.note || null,
        proyek_scores: item.proyek_scores.map((item) => ({
          proyek_type_score_id: item.proyek_type_score_id,
          score: item.score || null, // keyword like BB, MB
        }))
      }
    });

    const response = await postRequest(`report-p5/${selectedClass.value}/${selectedSemester.value}/${userId}/save-score-siswa`,
      {
        proyek: necessaryBackendData
      }
    );

    setTriggerGetStudentScores(true);
    if (response) {
      setIsLoadingSaveStudentScores(false);
      window.notification('Berhasil menyimpan data deskripsi proyek!', '', 'success')
    } else {
      setIsLoadingSaveStudentScores(false)
    }
  }

  const onSubmitReportHandler = async ({ userId }) => {
    Swal.fire({
      title: 'Apa Anda yakin akan mengajukan rapor sekarang?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Ajukan!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setTriggerGetStudentScores(false);
        setIsLoadingSubmitReport(true)
        Swal.fire({
          title: 'Mengajukan rapor...',
          html: `<div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                  </div>`,
          allowOutsideClick: false,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
        })
        const response = await putRequest(`report-p5/${selectedClass.value}/${selectedSemester.value}/${userId}/submission-rapor-siswa`)

        if (response) {
          setIsLoadingSubmitReport(false);
          Swal.fire({
            title: 'Rapor berhasil diajukan',
            icon: 'success',
            allowOutsideClick: false,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: 'Ok',
          }).then(result => {
            if (result.isConfirmed) {
              window.notification('Berhasil menyimpan data deskripsi proyek!', '', 'success')
              setTriggerGetStudentScores(true);
            }
          })
        } else {
          setTriggerGetStudentScores(false);
          setIsLoadingSubmitReport(false);
          Swal.fire(
            'Proses Gagal',
            'Mengajukan rapor gagal diproses.',
            'error',
          )
        }
      }
    });
  }

  const onRejectedRaporHandler = async ({ userId }) => {
    Swal.fire({
      icon: 'warning',
      title: `Tolak rapor?`,
      confirmButtonText: 'Ya, tolak!',
      confirmButtonColor: '#ff5252',
      cancelButtonText: 'Tidak',
      showCancelButton: true,
      input: 'textarea',
      inputPlaceholder: 'Catatan untuk walikelas...',
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      preConfirm: (inputValue) => {
        return inputValue
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoadingRejectedReport(true);

        let res = await putRequest(`report-p5/${selectedClass.value}/${selectedSemester.value}/${userId}/reject-rapor-siswa`, { note: result.value })
        if (res) {
          Swal.fire({
            icon: 'success',
            title: `Rapor berhasil ditolak`,
          }).then(result => {
            if (result.isConfirmed) {
              setTriggerGetStudentScores(true);
              setIsLoadingRejectedReport(false);
            }
          })
        } else {
          setTriggerGetStudentScores(false);
          setIsLoadingRejectedReport(false);
        }
      }
    })
  }

  const onApproveReportHandler = async ({ userId }) => {
    Swal.fire({
      title: 'Approve rapor?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2778c4',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Ya!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setTriggerGetStudentScores(false);
        setIsLoadingApprovedReport(true)
        Swal.fire({
          title: 'Approved rapor...',
          html: `<div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                  </div>`,
          allowOutsideClick: false,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
        })
        const response = await putRequest(`report-p5/${selectedClass.value}/${selectedSemester.value}/${userId}/approve-rapor-siswa`)

        if (response) {
          setIsLoadingApprovedReport(false);
          Swal.fire({
            title: 'Rapor telah disetujui',
            icon: 'success',
            allowOutsideClick: false,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: 'Ok',
            confirmButtonColor: '#2778c4',
          }).then(result => {
            if (result.isConfirmed) {
              setTriggerGetStudentScores(true);
              window.notification('Berhasil menyetujui rapor!', '', 'success')
            }
          })
        } else {
          setTriggerGetStudentScores(false);
          setIsLoadingApprovedReport(false);
          Swal.fire(
            'Proses Gagal',
            'Approved rapor gagal diproses.',
            'error',
          )
        }
      }
    });
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getListProjects();
  }, [selectedClass, selectedSemester, getListProjects])

  /* ======================================= Shared States ====================================== */
  const sharedStates = {
    listKindness,
    isLoadingListKindness,
    kindnessConfig: {
      totalInputDimentions,
      totalInputProjects,
    },
    data: {
      studentScores,
      triggerGetStudentScores,
      selectedClass,
      selectedSemester,
    },
    handlers: {
      saveProjectDescription: {
        handler: onSaveProjectDescriptionHandler,
        loading: isLoadingSaveProjectDescription,
      },
      saveStudentScores: {
        handler: onSaveStudentScores,
        loading: isLoadingSaveStudentScores,
      },
      getStudentScores: {
        handler: getStudentScoresHandler,
        loading: isLoadingGetStudentScores,
      },
      submitReport: {
        handler: onSubmitReportHandler,
        loading: isLoadingSubmitReport,
      },
      rejectReport: {
        handler: onRejectedRaporHandler,
        loading: isLoadingRejectedReport,
      },
      approveReport: {
        handler: onApproveReportHandler,
        loading: isLoadingApprovedReport,
      },
    },
    utils: {
      setTriggerGetStudentScores
    }
  }

  /* ====================================== Output Provider ===================================== */
  return (
    <InputKindnessContext.Provider value={sharedStates}>
      {children}
    </InputKindnessContext.Provider>
  )
}

/* ======================================= Output Wrapper ======================================= */
export default function useInputKindness() {
  return useContext(InputKindnessContext)
}

import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import FormInputDraft from './form-input-draft'
import loadingTypes from 'globals/loading-types'

export default function RaporUserItem({
  totalColumns = 0,
  item,
  number,
  categoryId,
  semester_id,
  reportGroupId,
  isRaport,
  status,
}) {
  /* ========================================== Helpers ========================================= */
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query    = useQuery()
  const group_id = query.get('group_id')

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const badgesConfig = {
    submission: {
      status: 'submission',
      className: 'badge-warning',
    },
    approved: {
      status: 'approved',
      className: 'badge-success',
    },
    rejected: {
      status: 'rejected',
      className: 'badge-danger',
    },
    draft: {
      status: 'draft',
      className: 'badge-warning',
    },
  }

  /* ======================================= Local States ======================================= */
  const [loadingScoreTemp, setLoadingScoreTemp] = useState(loadingTypes.idle)
  const [isEditable, setIsEditable]             = useState(false)
  const [showEditUi, setShowEditUi]             = useState(false)
  const [scoreLms, setScoreLms]                 = useState('')
  const [kelas, setKelas]                       = useState('')
  const [score, setScore]                       = useState('')
  const [predicate, setPredicate]               = useState('')
  const [description, setDescription]           = useState('')
  const [approvalStatus, setApprovalStatus]     = useState('')
  const [notes, setNotes]                       = useState('')
  const [badge, setBadge]                       = useState('')

  /* ========================================= Functions ======================================== */
  async function getScoreTemp(user_id, category_id, reportGroupId) {
    const response = await getRequest(
      `report-temp-by-id-group?id=${reportGroupId}&user_id=${user_id}&category_id=${category_id}&ta_semester_code=${semester_id}&group_id=${group_id}`,
    )
    if (response)
      setScoreLms(
        response.scoreLms.finalLmsScore === null
          ? ''
          : response.scoreLms.finalLmsScore,
      )
    if (response) setKelas(response.kelas)
    return response ? response.data : null
  }

  function resetStates() {
    setScore('')
    setPredicate('')
    setDescription('')
    setApprovalStatus('no score')
  }

  function initApproval(approvalStatus, badgesConfig, approvalNotes) {
    switch (approvalStatus) {
      case badgesConfig.approved.status:
        setBadge(badgesConfig.approved.className)
        setIsEditable(false)
        setApprovalStatus(approvalStatus)
        setNotes('-')
        break
      case badgesConfig.rejected.status:
        setBadge(badgesConfig.rejected.className)
        setIsEditable(true)
        setApprovalStatus(approvalStatus)
        setNotes(approvalNotes ? approvalNotes : '-')
        break
      case badgesConfig.draft.status:
        setBadge(badgesConfig.draft.className)
        setIsEditable(true)
        setApprovalStatus(approvalStatus)
        setNotes(approvalNotes ? approvalNotes : '-')
        break
      case badgesConfig.submission.status:
        setBadge(badgesConfig.submission.className)
        setIsEditable(false)
        setApprovalStatus('diajukan')
        setNotes(approvalNotes ? approvalNotes : '-')
        break
      default:
        setBadge('badge-secondary')
        setIsEditable(true)
        setApprovalStatus('no-score')
        setNotes('-')
        break
    }
  }

  async function initScoreData(item, reportGroupId, badgesConfig) {
    setLoadingScoreTemp(loadingTypes.start)
    const data = await getScoreTemp(
      item.user_id,
      item.categories_id,
      reportGroupId,
    )
    if (typeof data === 'object' && data !== null) {
      const value = JSON.parse(data.value)
      setScore(value.nilai)
      setPredicate(value.predikat)
      setDescription(value.deskripsi)
      initApproval(data.approval_status, badgesConfig, data.approval_notes)
    } else {
      resetStates()
      setBadge('badge-secondary')
      setIsEditable(true)
    }
    setLoadingScoreTemp(loadingTypes.finish)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    initScoreData(item, reportGroupId, badgesConfig)
  }, [categoryId, item])

  return (
    <tr>
      <td>{number}</td>
      <td title={item.user_nim}>{item.user_name}</td>
      {loadingScoreTemp === loadingTypes.idle && (
        <td colSpan={totalColumns - 2}></td>
      )}
      {loadingScoreTemp === loadingTypes.start && (
        <td colSpan={totalColumns - 2}>
          <div className="d-flex justify-content-center align-items-center">
            <span
              className="spinner-border text-primary"
              role="status"
              style={{
                height: '22px',
                width: '22px',
              }}
            />
          </div>
        </td>
      )}
      {loadingScoreTemp === loadingTypes.finish && (
        <>
          {showEditUi ? (
            <>
              <td colSpan={totalColumns - 2}>
                <FormInputDraft
                  item={item}
                  categoryId={categoryId}
                  reportGroupId={reportGroupId}
                  badgesConfig={badgesConfig}
                  scoreLms={scoreLms}
                  score={score}
                  predicate={predicate}
                  description={description}
                  notes={notes}
                  setShowEditUi={setShowEditUi}
                  setScore={setScore}
                  setPredicate={setPredicate}
                  setDescription={setDescription}
                  initScoreData={initScoreData}
                  isRaport={isRaport}
                  status={status}
                  kelas={kelas}
                />
              </td>
            </>
          ) : (
            <>
              <td>{scoreLms === '' ? '-' : scoreLms}</td>
              <td>{score === '' ? '-' : score}</td>
              <td>{predicate === '' ? '-' : predicate}</td>
              <td style={{ whiteSpace: 'normal' }}>
                <div style={{ width: 350, textAlign: 'justify' }}>
                  {description === '' ? '-' : description}
                </div>
              </td>
              <td>
                <span className={`badge ${badge}`}>{approvalStatus}</span>
              </td>
              <td>{notes === '' ? '-' : notes}</td>
              <td>
                {isEditable ? (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => setShowEditUi(true)}
                  >
                    Input Draft
                  </button>
                ) : (
                  '-'
                )}
              </td>
            </>
          )}
        </>
      )}
    </tr>
  )
}

import React, { useEffect, useState } from 'react'
import { useBaseContext } from 'context/base'
import TableSurvey from './questions/pilihan-group-survey'
import QuestionSurvey from './questions/question-survey'
import { SurveyDone } from './components/survey-components'
import NotFound from 'component/not-found'
import LoadingSpinner from 'component/loading'
import Swal from 'sweetalert2'
import useContentPlayerContext from 'context/content-player'

const ContentSurvey = ({ isPreview = false, content_text, contents_url, content_source }) => {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest} = useBaseContext()
  const { content, kelas, activity: activityStore } = useContentPlayerContext()
  const { shouldSendCCA } = useContentPlayerContext()

  const using_contents_url = contents_url || content.contents_url

  // GET DATA STATE
  const [survey, setSurvey]       = useState(null)
  const [questions, setQuestions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [key, setKey]             = useState(0)

  // CLASS ACTIVITIES
  const [activities, setActivities]     = useState([])
  const [isSurveyDone, setIsSurveyDone] = useState(false)
  const [table, setTable]               = useState(null)

  // SUBMIT DATA STATE
  const [answers, setAnswers] = useState([])

  /* ========================================= Functions ======================================== */
  function getRandomInt(max) {
    setKey(Math.floor(Math.random() * max))
  }

  async function getSurvey() {
    setIsLoading(true)
    const response = await getRequest(
      `survey-siswa?id=${using_contents_url?.survey_id}`,
    )

    setIsLoading(false)
    if (response) {
      const pilihanGroupQuestions = response.kolombaris
        ? response.kolombaris
        : null
      const otherQuestions = response.data ? response.data.survey_quests : []
      setSurvey(response.data)
      setQuestions(otherQuestions)
      initAnswers(otherQuestions, pilihanGroupQuestions)
      setTable(pilihanGroupQuestions)
    } else {
      console.error('ERROR', response)
    }
  }

  function initAnswers(questions, tbl) {
    let answers = []
    questions.forEach((question) =>
      answers.push({
        survey_quest_id: question.id,
        survey_id: question.survey_id,
        survey_answer_id: null,
        survey_answer_text: null,
      }),
    )
    if (tbl != null) {
      tbl.baris.forEach((question) =>
        answers.push({
          survey_quest_id: question.id,
          text: question.text,
          survey_id: question.survey_id,
          survey_answer_id: null,
          survey_answer_text: null,
        }),
      )
    }
    setAnswers(answers)
  }

  function handleChangePilihanGanda(event, answerData) {
    const updateAnswers = answers.map((answer) => {
      if (answerData.survey_quest_id === answer.survey_quest_id) {
        return {
          survey_quest_id: answer.survey_quest_id,
          survey_id: answer.survey_id,
          survey_answer_id: answerData.id,
          survey_answer_text: null,
        }
      } else {
        return answer
      }
    })
    setAnswers(updateAnswers)
  }

  function handleChangeEssay(event, questionData) {
    const updateAnswers = answers.map((answer) => {
      if (questionData.id === answer.survey_quest_id) {
        return {
          survey_quest_id: questionData.id,
          survey_id: questionData.survey_id,
          survey_answer_id: null,
          survey_answer_text: event.target.value,
        }
      } else {
        return answer
      }
    })
    setAnswers(updateAnswers)
  }

  function handleChangeKolomBaris(index, jawaban_id, quest_id) {
    const updateAnswers = answers.map((answer) => {
      if (quest_id === answer.survey_quest_id) {
        return {
          survey_quest_id: answer.survey_quest_id,
          survey_id: answer.survey_id,
          survey_answer_id: jawaban_id,
          survey_answer_text: null,
        }
      } else {
        return answer
      }
    })
    setAnswers(updateAnswers)
  }

  async function completeActivities() {
    let form = new FormData()
    form.append('activity_id', activityStore.id)
    form.append('content_id', content.id)
    form.append('class_id', kelas.id)
    form.append('is_complete', shouldSendCCA ? 1 : 0)
    const response = await putRequest('class-content-activities', form)
    if (response) {
      activityStore.markSubmit()
      shouldSendCCA && activityStore.setIdDone(content.id)
      setIsSurveyDone(true)
      activityStore.setMediaIdDone(content.id)
    } else {
      console.error('ERROR', response)
    }
  }

  function handleSubmitAnswer() {
    Swal.fire({
      title: 'Apa kamu yakin?',
      text: `Kamu akan mengirim jawaban!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, kirim!',
      cancelButtonText: 'Periksa kembali',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let form = new FormData()
        form.append('answer', JSON.stringify(answers))
        form.append('content_id', content.id)
        const response = await postRequest('survey-answer', form)

        if (response) {
          Swal.fire({
            icon: 'success',
            text: 'Jawaban berhasil dikirim.',
          }).then(async () => {
            await completeActivities()
          })
        } else {
          console.error('ERROR', response)
          window.notification(
            'Terjadi kesalahan',
            'Coba beberapa saat lagi',
            'error',
          )
        }
      }
    })
  }

  function checkIsSurveyDone() {
    if (activities.length !== 0) {
      activities.forEach(async (activity) => {
        if (activity.completed_at !== null) {
          setIsSurveyDone(true)
          activityStore.setMediaIdDone(content.id)
        }
      })
    } else {
      setIsSurveyDone(false)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getSurvey()
    getRandomInt()
  }, [])

  useEffect(() => {
    if (isPreview === false) {
      setActivities(content.class_content_activities)
    }
  }, [content])

  useEffect(() => {
    if (isPreview === false) {
      checkIsSurveyDone()
    }
  }, [activities])

  /* ========================================== Output ========================================== */
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {survey !== null || table !== null ? (
            <>
              {isSurveyDone ? (
                <>
                  <SurveyDone
                    content={content}
                    generatedKey={key}
                  />
                </>
              ) : (
                <>
                  <br />
                  {table ? (
                    <TableSurvey
                      table={table}
                      answers={answers}
                      generatedKey={key}
                      handleChangeKolomBaris={handleChangeKolomBaris}
                      readOnly={false}
                    />
                  ) : null}
                  <ul className="list-unstyled p-2">
                    {questions.length !== 0
                      ? questions.map((question, index) => (
                          <QuestionSurvey
                            index={index}
                            question={question}
                            key={question.id}
                            handleChangePilihanGanda={handleChangePilihanGanda}
                            handleChangeEssay={handleChangeEssay}
                          />
                        ))
                      : null}
                  </ul>
                  {isPreview === false ? (
                    <div className="form-group row">
                      {/* Submit Answers */}
                      <div className="offset-md-3 offset-lg-4 col-md-6 col-lg-4">
                        <button
                          onClick={() => handleSubmitAnswer()}
                          type="button"
                          className="btn btn-success"
                          style={{ width: '100%' }}
                        >
                          <i className="fas fa-check-circle mr-2" />
                          <span>Kirim Jawaban</span>
                        </button>
                      </div>
                    </div>
                  ) : null}
                </>
              )}
            </>
          ) : (
            <NotFound text="Data survey tidak ditemukan" />
          )}
        </>
      )}
    </>
  )
}

export default ContentSurvey

import React from 'react'

export default function Loader() {
  return (
    <tr>
      <td
        colSpan   = {5}
        className = "text-center"
      >
        <h4>
          Memuat...
        </h4>
        <div
          className = "mt-4 mb-4 spinner-border text-warning"
          role      = "status"
        ></div>
        <h5>
          Ditunggu Ya!
        </h5>
      </td>
    </tr>
  )
}

import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import BackButton from 'component/button/Back'
import SubmitButton from 'component/button/Submit'
import { FormSwitch } from 'component/forms'
import useEditContent from './hook'
import {
  FormInputName,
  FormInputUrutan,
  FormSelectSection,
  FormSelectPrecontent,
  FormInputTanggal,
  FormSelectAssignment,
  FormActiveStatus,
  FormActiveKey,
  FormSelectFeedback,
  FormGroupKecil,
  FormInputDesc,
  FormInputEndedAt,
  FormTextArea,
  FormStatusKomentarTugas,
  FormCheckTypeRapor,
  FormSelectStudent,
} from 'component/content-form'
import { getUsersContentSpesificStudent } from 'util/array'

export default function EditContentAudio() {
  /* ======================================= Consume Hook ======================================= */
  const { getContentTakeAway } = useEditContent()

  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const location = useLocation()
  const { contentId, kelasId } = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest } = useBaseContext()

  /* =========================================== Refs =========================================== */
  const isMounted = useRef(true)

  /* ========================================= Constants ======================================== */
  const links = [
    { url: '/kelas', name: 'Sub Mata Pelajaran' },
    { url: `/kelas/${location.state.kelasId}`, name: 'Overview' },
  ]
  const existingContentData = location.state.existingData
  const sections = location.state.sectionList ? location.state.sectionList : []
  const contentType = 8

  /* ======================================= Local States ======================================= */
  const [isContentTakeAwayExist, setIsContentTakeAwayExist] = useState(false)
  const [existingContents, setExistingContents]             = useState([])

  const [assignList, setAssignList]         = useState([])
  const [namaKonten, setNamaKonten]         = useState(existingContentData?.title || '')
  const [urutan, setUrutan]                 = useState(existingContentData?.urutan || '')
  const [sectionId, setSectionId]           = useState(existingContentData?.section_id || '')
  const [preContentId, setPrecontentId]     = useState(existingContentData?.precontent_id || '')
  const [active, setActive]                 = useState(existingContentData?.active ? 1 : 0)
  const [displayAt, setDisplayAt]           = useState(existingContentData?.display_at || '')
  const [assignmentId, setAssignmentId]     = useState(null)
  const [feeds, setFeeds]                   = useState([])
  const [feedId, setFeedId]                 = useState(null)
  const [isFeedActive, setIsFeedActive]     = useState(0)
  const [enableGroup, setEnableGroup]       = useState(!!existingContentData?.group_status)
  const [maxGroup, setMaxGroup]             = useState(existingContentData?.group_max || '')
  const [descGroup, setDescGroup]           = useState(existingContentData?.group_description || '')
  const [endedAt, setEndedAt]               = useState(existingContentData?.ended_at || '')
  const [description, setDescription]       = useState(existingContentData?.deskripsi || '')
  const [apresiasi, setApresiasi]           = useState(existingContentData?.apresiasi || '')
  const [komentarTugas, setKomentarTugas]   = useState(existingContentData?.komentar_tugas ? 1 : 0)
  const [komentarWajib, setKomentarWajib]   = useState(existingContentData?.komentar_wajib || '')
  const [rating, setRating]                 = useState(existingContentData?.rating ? 1 : 0)
  const [isPengetahuan, setIsPengetahuan]   = useState(!!existingContentData?.is_pengetahuan)
  const [isKeterampilan, setIsKeterampilan] = useState(!!existingContentData?.is_keterampilan)
  const [selectedStudents, setSelectedStudents] = useState(() => getUsersContentSpesificStudent(existingContentData))

  const [enableGroupKey, setEnableGroupKey] = useState(false)
  const [prerequisite, setPrerequisite]     = useState('')

    // FORM VALIDATION STATES
  const [isNamaInvalid, setIsNamaInvalid]       = useState(false)
  const [isUrutanInvalid, setIsUrutanInvalid]   = useState(false)
  const [isSectionInvalid, setIsSectionInvalid] = useState(false)
  const [isAssignInvalid, setIsAssignInvalid]   = useState(false)
  const [isMaxGroupValid, setIsMaxGroupValid]   = useState(true)
  const [loadingSubmit, setLoadingSubmit]       = useState(false)

  /* =========================================== Refs =========================================== */
  const refInputName = useRef()
  const refSelectSection = useRef()
  const refInputUrutan = useRef()
  const refSelectAssign = useRef()
  const refMaxGroup = useRef()

  /* ========================================= Functions ======================================== */
  function getExistingContentsBySelectedSection() {
    if (!sectionId) {
      setExistingContents([])
    } else {
      sections.forEach((section) => {
        if (section.id === sectionId) {
          setExistingContents(section.contents)
        }
      })
    }
  }

  async function getAssignmentList() {
    const response = await getRequest('assignment?isTitleOnly=true')
    if (response) {
      const options = response.data.map((item) => ({
        value: item.id,
        label: item.title,
      }))
      setAssignList(options)
      initSelectedAssignment(options)
    }
  }

  async function getFeedsList() {
    const response = await getRequest('feed')
    if (response) {
      const options = response.data.map((item) => ({
        value: item.id,
        label: item.description,
      }))
      setFeeds(options)
      initSelectedFeed(options)
    }
  }

  function initSelectedFeed(feedList) {
    for (let feed of feedList) {
      if (feed.value === existingContentData.feedback) {
        setFeedId({ value: feed.value, label: feed.label })
        break
      }
    }
  }

  function initSelectedAssignment(assignmentList) {
    // INIT EXISTING ASSIGNMENT
    for (let tugas of assignmentList) {
      if (tugas.value === existingContentData.contents_url) {
        setAssignmentId({ value: tugas.value, label: tugas.label })
        break
      }
    }
  }

  function handleChangeName(event) {
    setNamaKonten(event.target.value)
  }

  function handleChangeUrutan(event) {
    setUrutan(Math.abs(event.target.value))
  }

  function handleChangeSection(event) {
    setSectionId(event.target.value)
  }

  function handleChangePrecontent(event) {
    setPrecontentId(event.target.value)
  }

  function handleChangeDate(event) {
    setDisplayAt(event.target.value)
  }

  function handleChangeActive() {
    if (active === 1) {
      setActive(0)
    }
    if (active === 0) {
      setActive(1)
    }
  }

  function handleChangeAssignment(val) {
    setAssignmentId(val)
  }

  function handleChangeFeedback(val) {
    setFeedId(val)
  }

  function handleChangeEnableGroup() {
    if (enableGroup) {
      setEnableGroup(false)
      setMaxGroup(
        existingContentData.group_max ? existingContentData.group_max : '',
      )
      setIsMaxGroupValid(true)
    } else {
      setEnableGroup(true)
      setMaxGroup(
        existingContentData.group_max ? existingContentData.group_max : '',
      )
      if (existingContentData.group_max) {
        setIsMaxGroupValid(true)
      } else {
        setIsMaxGroupValid(false)
      }
    }
  }

  function handleChangeMaxGroup(event) {
    if (event.target.value) {
      const absolute = Math.abs(event.target.value)
      const integer = Math.round(absolute)
      setMaxGroup(integer)
      if (integer <= 0) {
        setIsMaxGroupValid(false)
      } else {
        setIsMaxGroupValid(true)
      }
    } else {
      setMaxGroup('')
      setIsMaxGroupValid(false)
    }
  }

  function handleChangeDescGroup(event) {
    setDescGroup(event.target.value)
  }

  function handleChangeDesc(event) {
    setDescription(event.target.value)
  }

  function handleChangeEndedAt(event) {
    setEndedAt(event.target.value)
  }

  function handleChangeApresiasi(event) {
    setApresiasi(event.target.value)
  }

  function handleChangeKomentarTugas() {
    if (komentarTugas === 1) {
      setKomentarTugas(0)
    }
    if (komentarTugas === 0) {
      setKomentarTugas(1)
    }
  }

  function handleChangeEnableGroupKey() {
    if (enableGroupKey) {
      setEnableGroupKey(false)
    } else {
      setEnableGroupKey(true)
    }
  }

  function handleChangeKomentarWajib(event) {
    if (event.target.value >= 0) {
      setKomentarWajib(event.target.value)
    } else {
      setKomentarWajib('')
    }
  }

  function handleChangeRating(value) {
    setRating(value ? 1 : 0)
  }

  function handleChangeIsPengetahuan(event) {
    setIsPengetahuan(event.target.checked)
  }

  function handleChangeIsKeterampilan(event) {
    setIsKeterampilan(event.target.checked)
  }

  function handleFormValidation() {
    const feedback = isFeedActive === 1 ? feedId : ''

    if (namaKonten && urutan && sectionId && assignmentId && isMaxGroupValid) {
      setIsNamaInvalid(false)
      setIsSectionInvalid(false)
      setIsUrutanInvalid(false)
      setIsAssignInvalid(false)
      handleFormSubmit(
        contentId,
        namaKonten,
        contentType,
        sectionId,
        urutan,
        active,
        displayAt,
        preContentId,
        assignmentId.value,
        feedback.value,
        maxGroup,
        descGroup,
        endedAt,
        description,
        apresiasi,
        komentarTugas,
        komentarWajib,
        rating,
        isPengetahuan,
        isKeterampilan,
      )
    } else {
      if (!isMaxGroupValid) {
        if (refMaxGroup) {
          refMaxGroup.current.focus()
        }
      }

      if (!sectionId) {
        setIsSectionInvalid(true)
        refSelectSection.current.focus()
      } else {
        setIsSectionInvalid(false)
      }

      if (!urutan) {
        setIsUrutanInvalid(true)
        refInputUrutan.current.focus()
      } else {
        setIsUrutanInvalid(false)
      }

      if (!assignmentId) {
        setIsAssignInvalid(true)
        refSelectAssign.current.focus()
      } else {
        setIsAssignInvalid(false)
      }

      if (!namaKonten) {
        setIsNamaInvalid(true)
        refInputName.current.focus()
      } else {
        setIsNamaInvalid(false)
      }

      window.notification('Mohon', 'lengkapi data')
    }
  }

  async function handleFormSubmit(
    contentId,
    namaKonten,
    contentType,
    sectionId,
    urutan,
    active,
    displayAt,
    preContentId,
    contentsUrl,
    feedId,
    maxGroup,
    descGroup,
    endedAt,
    description,
    apresiasi,
    komentarTugas,
    komentarWajib,
    rating,
    isPengetahuan,
    isKeterampilan,
  ) {
    let form = new FormData()
    form.append('id', contentId)
    form.append('title', namaKonten)
    form.append('content_type', contentType)
    form.append('section_id', sectionId)
    form.append('urutan', urutan)
    form.append('active', active)
    form.append('contents_url', contentsUrl)
    form.append('komentar_tugas', komentarTugas)
    form.append('rating', rating)
    form.append('precontent_id', preContentId)
    form.append('display_at', displayAt)
    form.append('ended_at', endedAt)
    form.append('deskripsi', description)
    form.append('apresiasi', apresiasi)
    form.append('is_pengetahuan', isPengetahuan)
    form.append('is_keterampilan', isKeterampilan)

    if (Array.isArray(selectedStudents) && !!selectedStudents.length) {
      selectedStudents.forEach((selectedStudent, selectedStudentIndex) => {
        form.append(`spesifik_user_id[${selectedStudentIndex}]`, selectedStudent.id)
      })
    }

    if (feedId) {
      form.append('feedback', feedId)
    } else {
      form.append('feedback', '')
    }

    if (enableGroup) {
      form.append('group_status', 1)
    } else {
      form.append('group_status', 0)
    }

    if (maxGroup) {
      form.append('group_max', maxGroup)
    }

    if (descGroup) {
      form.append('group_description', descGroup)
    }

    if (komentarWajib) {
      form.append('komentar_wajib', Math.round(komentarWajib))
    } else {
      form.append('komentar_wajib', 0)
    }

    setLoadingSubmit(true)

    const response = await putRequest('contents', form)
    if (response) {
      if (enableGroupKey) {
        let formkey = new FormData()
        formkey.append('description', prerequisite)
        formkey.append('content_id', contentId)

        const response1 = isContentTakeAwayExist
          ? await putRequest('contentTakeAway', formkey)
          : await postRequest('contentTakeAway', formkey)

        if (response1) {
          window.notification('Materi', 'berhasil diubah', 'success')
          history.goBack()
        }
      } else {
        window.notification('Materi', 'berhasil diubah', 'success')
        history.goBack()
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (isMounted.current) {
      getExistingContentsBySelectedSection()
    }
  }, [sectionId])

  useEffect(() => {
    getContentTakeAway(contentId).then((res) => {
      if (res.data) {
        setPrerequisite(res.data.description)
        setEnableGroupKey(true)
        setIsContentTakeAwayExist(true)
      }
    })

    if (isMounted.current) {
      getAssignmentList()
      getFeedsList()
    }

    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (existingContentData.feedback) {
      if (isMounted.current) {
        setIsFeedActive(1)
      }
    }
  }, [existingContentData.feedback])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Edit Tugas"
          linkName="Tugas"
          links={links}
        />
        
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <h5>
                  <i className="fas fa-pen-alt mr-1" />
                  Informasi Tugas
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <form>
                      {/* Input Nama Konten */}
                      <FormInputName
                        label="Nama Tugas"
                        placeholder="Nama Tugas"
                        namaKonten={namaKonten}
                        handleChangeName={handleChangeName}
                        isNamaInvalid={isNamaInvalid}
                        refInputName={refInputName}
                      />

                      {/* Select Assignment */}
                      <FormSelectAssignment
                        assignmentList={assignList}
                        handleChangeAssignment={handleChangeAssignment}
                        existingAssignmentId={assignmentId}
                        isAssignmentInvalid={isAssignInvalid}
                        refSelectAssignment={refSelectAssign}
                        getAssignmentList={getAssignmentList}
                      />

                      <FormCheckTypeRapor
                        handleChangeIsPengetahuan={handleChangeIsPengetahuan}
                        handleChangeIsKeterampilan={handleChangeIsKeterampilan}
                        isPengetahuan={isPengetahuan}
                        isKeterampilan={isKeterampilan}
                      />

                      {/* Komentar Tugas */}
                      <FormStatusKomentarTugas
                        status={komentarTugas}
                        handleChange={handleChangeKomentarTugas}
                        komentarWajib={komentarWajib}
                        handleChangeKomentarWajib={handleChangeKomentarWajib}
                      />

                      {/* Input Urutan */}
                      <FormInputUrutan
                        urutan={urutan}
                        handleChangeUrutan={handleChangeUrutan}
                        isUrutanInvalid={isUrutanInvalid}
                        refInputUrutan={refInputUrutan}
                      />

                      {/* Input deskripsi */}
                      <FormInputDesc
                        description={description}
                        handleChangeDesc={handleChangeDesc}
                      />

                      {/* Input Apresiasi */}
                      <FormTextArea
                        id="formInputApresiasi"
                        label="Apresiasi"
                        placeholder="Apresiasi/pesan setelah siswa menyelesaikan materi (opsional)"
                        className="mb-5"
                        value={apresiasi}
                        handleChange={handleChangeApresiasi}
                      />

                      {/* Select Section */}
                      <FormSelectSection
                        sections={sections}
                        handleChangeSection={handleChangeSection}
                        isSectionInvalid={isSectionInvalid}
                        refSelectSection={refSelectSection}
                        existingSectionId={sectionId}
                      />

                      {/* Select Precontent */}
                      <FormSelectPrecontent
                        existingContents={existingContents}
                        handleChangePrecontent={handleChangePrecontent}
                        existingContentId={contentId}
                        existingPrecontentId={preContentId}
                      />

                      {/* Input Tanggal Ditampilkan */}
                      <FormInputTanggal
                        existingDisplayAt={displayAt}
                        handleChangeDate={handleChangeDate}
                      />

                      {/* Input ended at */}
                      <FormInputEndedAt
                        endedAt={endedAt}
                        handleChangeEndedAt={handleChangeEndedAt}
                      />

                      {/* Rating */}
                      <FormSwitch
                        id="formRating"
                        label="Rating Materi"
                        value={rating}
                        handleChange={handleChangeRating}
                      />

                      {/* Select feedback */}
                      <FormSelectFeedback
                        existingFeeds={feeds}
                        existingFeedId={feedId}
                        isFeedActive={isFeedActive}
                        setIsFeedActive={setIsFeedActive}
                        handleChangeFeeds={handleChangeFeedback}
                        getFeedList={getFeedsList}
                      />

                      {/* Swicth Group Kecil */}
                      <FormGroupKecil
                        enableGroup={enableGroup}
                        handleChangeEnableGroup={handleChangeEnableGroup}
                        maxGroup={maxGroup}
                        descGroup={descGroup}
                        handleChangeMaxGroup={handleChangeMaxGroup}
                        handleChangeDescGroup={handleChangeDescGroup}
                        isMaxGroupValid={isMaxGroupValid}
                        refMaxGroup={refMaxGroup}
                      />

                      {/* Select Specific Students */}
                      <FormSelectStudent 
                        selectedStudents={selectedStudents}
                        setSelectedStudents={setSelectedStudents}
                        subMapelId={kelasId}
                        originalSelectedStudents={getUsersContentSpesificStudent(existingContentData)}
                        isDirectSave={false}
                        isWithFooter={false}
                        listSelectedStudentsProps={{
                          listStudentsActions: ['rollback', 'clear-all'],
                        }}
                      />

                      {/* Select Status */}
                      <FormActiveStatus
                        existingStatus={active}
                        handleChangeStatus={handleChangeActive}
                      />

                      <FormActiveKey
                        enableGroupKey={enableGroupKey}
                        handleChangeEnableGroupKey={handleChangeEnableGroupKey}
                        prerequisite={prerequisite}
                        setPrerequisite={setPrerequisite}
                      />

                      <div className="form-group">
                        <BackButton />
                        {loadingSubmit ? (
                          <button
                            disabled={true}
                            type="button"
                            className="btn btn-success mr-2"
                          >
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                            />
                            <span>Simpan</span>
                          </button>
                        ) : (
                          <SubmitButton
                            onClick={() => handleFormValidation()}
                            text="Simpan"
                          />
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React, { useEffect, useState } from 'react'

import { useBaseContext } from 'context/base'
import useContentPlayerContext from 'context/content-player'
import useMainContent from 'context/main-content'
import CkeditorReadonly from 'component/ckeditor-readonly'
import LoadingSpinner from 'component/loading'

const ContentText = ({ isPreview = false, content_text, contents_url, content_source }) => {
  /* ====================================== Consume Context ===================================== */
  const { getRequest }        = useBaseContext()
  const { content, activity } = useContentPlayerContext()
  const { shouldSendCCA }     = useMainContent()

  /* ======================================= Local States ======================================= */
  const [isLoading, setIsLoading]     = useState(false)
  const [contentText, setContentText] = useState('')

  /* ========================================= Functions ======================================== */
  async function getContentById() {
    const response = await getRequest(
      `content-by-id?content_id=${content.id}`,
    )
    
    setIsLoading(false)
    if (response) {
      const text = response.data.content_text
        ?.replaceAll('oembed', 'iframe')
        ?.replaceAll('iframe url', 'iframe style="width: 100%; aspect-ratio: 16 / 9;" src')
        ?.replaceAll('youtu.be', 'youtube.com/embed')
        ?.replaceAll('watch?v=', 'embed/')
      setContentText(text || '')
    }

    if (isPreview === false) {
      shouldSendCCA && activity.setIdDone(content.id)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    setIsLoading(true)
    getContentById()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <>
      {isLoading ? (
        <div className="col mt-3">
          <LoadingSpinner />
        </div>
      ) : null}
      <CkeditorReadonly data={content_text || contentText} />
    </>
  )
}

export default ContentText

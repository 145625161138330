import React, { useEffect, useMemo, useState } from 'react'
import BackButton from './back-button'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { editorConfigurationWithoutImage } from 'util/ckeditor-config'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import CkeditorReadonly from 'component/ckeditor-readonly'

export default function FormPenilaianEditTemplate({ hooks, assignmentData }) {
  const {
    isEdit,
    setIsEdit,
    isHistory,
    setIsHistory,
    note,
    score,
    register, // useForm
    handleSubmit,
    setLoading,
    putRequest,
    loading,
  } = hooks


  const [newNote, setNewNote] = useState(() => note)
  const [newScore, setNewScore] = useState(() => score)


  return (
    <>
      <div style={styles.buttons}>
        <button
          type="button"
          className="btn mr-2 btn-icon btn-primary"
          title="Ubah Nilai"
          onClick={() => {
            setIsEdit(true)
          }}
        >
          <i className="fas fa-edit" />
        </button>
        {
          isHistory ? (
            <BackButton hooks={hooks}/>
          ) : (
            <button
              type="button"
              className="btn mr-2 btn-icon btn-info"
              title="History"
              onClick={() => setIsHistory(true)}
            >
              <i className="fas fa-history" />
            </button>
          )
        }
      </div>

      <div className="form-group mb-4">
        <h4 className="text-center">Form Nilai</h4>
      </div>
      <div className="form-group row mb-4">
        <label className="col-form-label col-4 offset-lg-2 col-lg-2">
          Catatan
        </label>
        <div className="col-8 col-lg-6 mt-1">
          {!isEdit ?
            <CkeditorReadonly data={note} />
          :
            <CKEditor
              editor={Editor}
              config={{ ...editorConfigurationWithoutImage, placeholder: 'Catatan untuk siswa (opsional)' }}
              data={newNote}
              onChange={(e, editor) => {
                const data = editor.getData()
                setNewNote(data)
              }}
            />
          }
        </div>
      </div>
      <div className="form-group row mb-5">
        <label className="col-form-label col-4 offset-lg-2 col-lg-2">
          Nilai
        </label>
        <div className="col-8 col-lg-3 mb-3">
          <input
            onChange={event => {
              const value = event.target.value
              setNewScore(value)
            }}
            id="score-input"
            defaultValue={newScore}
            disabled={!isEdit}
            type="number"
            min="0"
            max="100"
            className={`${
              isEdit ? 'text-dark' : 'text-secondary'
            } form-control bg-light`}
            placeholder="0 - 100"
          />
        </div>
      </div>

      {isEdit ?
      
      <div className="form-group d-flex justify-content-center">
      {loading ? (
        <button
          type="button"
          className="btn btn-forbidden btn-sm btn-success"
          disabled
        >
          <span
            className="spinner-border spinner-border-sm mr-1"
            role="status"
          />
          <span>Simpan</span>
        </button>
      ) : (
        <button
          onClick={() => {
            handleSubmit({newNote, newScore})
          }}
          type="button"
          disabled={!Number.isFinite(score)}
          className={`${score ? '' : 'btn-forbidden'} btn btn-sm btn-success`}
        >
          <span>Simpan</span>
        </button>
      )}
      <button
        onClick={() => setIsEdit(false)}
        type="button"
        className="btn btn-sm btn-danger ml-3"
      >
        <span>Batal</span>
      </button>
    </div> : null}
    </>
  )
}

const styles = {
  buttons: {
    position : 'absolute',
    top      : '1rem',
    right    : '1rem',
    zIndex   : '100',
  }
}

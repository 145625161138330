import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'
import Modal from 'component/modal'
import { FormInput, FormSwitch2 } from 'component/forms'

export default function ReportPeriod() {
  /* =========================================== Refs =========================================== */
  const mounted = useRef(true)

  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const params = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest, deleteRequest } = useBaseContext()

  /* =================================== Consume Local Storage ================================== */
  const { role_type } = JSON.parse(localStorage.getItem('role_active'))

  /* ======================================= Local States ======================================= */
  const [data, setData]                 = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [limit, setLimit]               = useState(10)
  const [offset, setOffset]             = useState(0)
  const [search, setSearch]             = useState('')

  const [semester_code, setsemester_code] = useState('')
  const [name_period, setname_period]     = useState('')
  const [raporDate, setRaporDate]         = useState('')
  const [status, setStatus]               = useState(0)
  const [startDate, setStartDate]         = useState('')
  const [endDate, setEndDate]             = useState('')

  /*START MODAL ACADEMIC YEAR STATES*/
  const [years, setYears]                     = useState([])
  const [startYear, setStartYear]             = useState(null)
  const [endYear, setEndYear]                 = useState(null)
  const [modalType, setModalType]             = useState('') // modalType: add/edit
  const [description, setDescription]         = useState('')
  const [semesterCode, setsemesterCode]       = useState('')
  const [id, setId]                           = useState('')
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  /*END MODAL ACADEMIC YEAR STATES*/

  /* ========================================= Functions ======================================== */
  async function getData() {
    if (role_type !== 'administrator' && role_type !== 'adminprofile')
      history.push('/')
    const url = `reportPeriod?semester_code=${params.ta_tahun_code}&search=${search}&limit=${limit}&offset=${offset}`
    const response = await getRequest(url)
    if (response) {
      if (mounted.current === true) {
        setData(response.data)
        setTotalRecords(response.totalRecords)
      }
    }
  }

  async function initModal(modalType, item) {
    if (modalType === 'edit') {
      setId(item.id)
      setname_period(item.name_period)
      setsemester_code(item.semester_code)
      setEndDate(moment(item.end_date).format('Y-MM-DD'))
      setStartDate(moment(item.start_date).format('Y-MM-DD'))
      setStatus(item.status)
    } else {
      setEndDate(params.endDate)
      setStartDate(params.startdate)
    }

    setModalType(modalType)
  }

  function handleChangeRaporDate(event) {
    setStartDate(event.target.value)
  }

  function handleChangeRaporDate1(event) {
    setEndDate(event.target.value)
  }

  function handleChangeStatus(value) {
    setStatus(value ? 1 : 0)
  }

  function handleChangeId(value) {
    setId(value ? 1 : 0)
  }

  function handleChangeDescription(event) {
    setDescription(event.target.value)
  }
  function handleChangeNamePeriod(event) {
    setname_period(event.target.value)
  }

  async function handleSubmitModal() {
    let form = new FormData()
    let response = null
    form.append('semester_code', params.ta_tahun_code)
    form.append('start_date', startDate)
    form.append('end_date', endDate)
    form.append('status', status)
    form.append('name_period', name_period)

    setIsLoadingSubmit(true)
    if (modalType === 'add') {
      response = await postRequest('reportPeriod', form)
    } else if (modalType === 'edit') {
      form.append('id', id)
      response = await putRequest('reportPeriod', form)
    }
    if (response) {
      await getData()
      window.$('#modalAcamedicYear').modal('hide')
      setDescription('')
      setStartYear(null)
      setEndYear(null)
      window.notification('', 'Berhasil menyimpan Report Period', 'success')
    }
    setIsLoadingSubmit(false)
  }
  function handleChangeSemesterCode(event) {
    setsemester_code(event.target.value)
  }

  function handleCloseModal() {
    setname_period('')
    setIsLoadingSubmit(false)
  }

  async function handleDelete(item) {
    Swal.fire({
      html: `
        <div class="text-dark border bg-light text-center f-w-700 p-2 mb-3 border rounded">
          <h5 class="text-dark">
            <i class="feather icon-alert-triangle mr-2"></i>PERINGATAN
          </h5>
          <p class="mb-1">Report Period akan dihapus dari sistem!</p>
        </div>
        <h4 class="my-0">Hapus Report Period?</h4>
      `,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(
          `reportPeriod?id=${item.id}`,
        )
        if (response) {
          Swal.fire({
            icon: 'success',
            text: 'Report Period berhasil dihapus',
          }).then(() => window.location.reload())
        }
      }
    })
  }

  function handleDetailSemester(item) {
    history.push(`/academic-year/${item.code}`)
  }

  function generateYear(startYear, maxYear) {
    const max = maxYear - startYear
    let years = []
    for (let count = 0; count <= max; count++) {
      const year = startYear + count
      years.push({ value: year, label: `${year}` })
    }
    return years
  }

  useEffect(() => {
    getData()
  }, [semesterCode, limit, offset, search])

  useEffect(() => {
    const year = generateYear(2000, 2100)
    if (mounted.current === true) setYears(year)

    return () => {
      mounted.current = false
    }
  }, [])

  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Report Period" />
        <div className="card user-profile-list">
          <div className="card-header">
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#modalAcamedicYear"
              onClick={() => initModal('add')}
            >
              <i className="feather icon-plus-circle mr-1" />
              Tambah Block
            </button>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col dt-responsive table-responsive">
                <DataTable
                  className={'table table-striped table-bordered nowrap'}
                  data={data}
                  totalRows={totalRecords}
                  show={[10, 20, 30, 40, 50]}
                  onChangeSearch={(val) => setSearch(val)}
                  onChangeLimit={(val) => setLimit(val)}
                  onChangePage={(val) => setOffset(val)}
                  column={[
                    'Semester Code',
                    'nama Period',
                    'Mulai',
                    'Selesai',
                    'Status',
                    'Actions',
                  ]}
                  renderItem={(item, index) => (
                    <RenderItem
                      key={index}
                      item={item}
                      initModal={initModal}
                      handleDelete={handleDelete}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          id="modalAcamedicYear"
          title={
            modalType === 'edit'
              ? 'Edit Report Period'
              : 'Tambah Report Period'
          }
          showFooter={true}
          showCloseFooter={true}
          onClose={handleCloseModal}
          footer={
            isLoadingSubmit ? (
              <button
                disabled
                type="button"
                className="btn btn-primary ml-2"
              >
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                />
                Simpan
              </button>
            ) : (
              <>
                {params.startdate && params.endDate ? (
                  <button
                    onClick={handleSubmitModal}
                    type="button"
                    className="btn btn-primary ml-2"
                  >
                    Simpan
                  </button>
                ) : (
                  <button
                    disabled
                    type="button"
                    className="btn btn-primary ml-2"
                  >
                    Simpan
                  </button>
                )}
              </>
            )
          }
        >
          <form>
            <input
              type="hidden"
              name="id"
              value={id}
              onChange={handleChangeId}
            />

            <FormInput
              id="formEditSemesterCode"
              type="text"
              onChange={handleChangeSemesterCode}
              value={params.ta_tahun_code}
              readonly="true"
              labelForm="Semester Code"
              required={true}
            />
            <FormInput
              id="formEditSemesterEndDate"
              type="text"
              onChange={handleChangeNamePeriod}
              value={name_period}
              labelForm="Nama period"
              required={true}
            />
            <FormInput
              id="editSemesterRaporDate"
              type="date"
              labelForm="Mulai"
              required={true}
              disabled={!params.endDate}
              value={startDate}
              min={params.startdate}
              max={params.endDate}
              onChange={handleChangeRaporDate}
            />
            <FormInput
              id="editSemesterRaporDate"
              type="date"
              labelForm="Selesai"
              required={true}
              disabled={!params.endDate}
              value={endDate}
              min={params.startdate}
              max={params.endDate}
              onChange={handleChangeRaporDate1}
            />
            <FormSwitch2
              id="formEditSemesterStatus"
              label="Status"
              value={status}
              handleChange={handleChangeStatus}
            />
          </form>
        </Modal>
      </Container>
    </TemplateAdmin>
  )
}

const RenderItem = ({
  item,
  initModal,
  handleDelete,
}) => {
  return (
    <tr>
      <td>{item.semester_code}</td>
      <td>{item.name_period ? item.name_period : '-'}</td>
      <td>{moment(item.start_date).format('DD MMMM YYYY')}</td>
      <td>{moment(item.end_date).format('DD MMMM YYYY')}</td>
      <td>{item.status == true ? 'Aktif' : 'Tidak Aktif'}</td>
      <td>
        <button
          type="button"
          className="btn btn-sm btn-success mr-2"
          data-toggle="modal"
          data-target="#modalAcamedicYear"
          onClick={() => initModal('edit', item)}
        >
          <i className="feather icon-edit mr-1" />
          Edit
        </button>
        <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={() => handleDelete(item)}
        >
          <i className="feather icon-trash-2 mr-1" />
          Hapus
        </button>
      </td>
    </tr>
  )
}

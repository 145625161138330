import React, { useState } from 'react'
import useChatContext from 'context/chat'

const ContactItem = ({ contact }) => {
  const { messages, current } = useChatContext()

  const [isHover, setIsHover] = useState(false)
  const defaultImage =
    contact.type === 'group'
      ? `/admin/assets/images/user/blank-avatar-group.svg`
      : `/admin/assets/images/user/blank-avatar-user.svg`

  return (
    <li
      className="border-bottom"
      style={{ width: '100%', backgroundColor: '#FFFFFF' }}
    >
      <button
        className="px-0 btn d-flex align-items-center text-left"
        style={{
          width: '100%',
          backgroundColor: isHover ? 'rgba(221,221,221,0.2)' : 'transparent',
        }}
        title={contact.name ? contact.name : 'Kontak'}
        onMouseEnter = {() => setIsHover(true)}
        onMouseLeave = {() => setIsHover(false)}
        onClick      = {async () => {
          if (contact.type === 'group') {
            const _chatToDisplay = messages.list.find(msg => msg.id === contact.id && msg.type === 'group')
            _chatToDisplay && current.set({
              ..._chatToDisplay,
              type: 'group',
            })

            if (!_chatToDisplay) {
              current.set({
                id           : contact.id,
                title        : contact.name,
                type         : 'group',
                chats        : [],
                participants : [],
              })
            }
          } else if (contact.type === 'siswa') {
            const chat = messages.list.find((_ml) => _ml.type === 'siswa' && _ml.id === contact.id)

            if (!chat) {
              current.set({
                id           : contact.id,
                title        : contact.name,
                type         : 'siswa',
                chats        : [],
                participants : [],
              })
            } else {
              current.set(chat)
            }
          }
        }}
      >
        <div className="col-auto p-r-0">
          <div className="u-img">
            <img
              src={contact.type === 'group' ? defaultImage : `${contact.image}`}
              onError={(event) => (event.currentTarget.src = defaultImage)}
              alt={contact.name ? contact.name : 'Kontak'}
              className="img-radius profile-img"
            />
          </div>
        </div>
        <div className="col d-flex flex-column justify-content-center align-items-start">
          <h6 className="m-b-5">{contact.name ? contact.name : '-'}</h6>
          {contact.type === 'group' ? (
            <span className="badge badge-light-primary">
              <i className="fas fa-users" />
            </span>
          ) : null}
        </div>
      </button>
    </li>
  )
}

export default ContactItem

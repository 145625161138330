import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useBaseContext } from 'context/base'
import LineChart from 'component/highchart/line-chart'
import PieChart from 'component/highchart/pie-chart'
import LoadingSpinner from 'component/loading'
import { timeConvert } from 'util/global'
import { noImage } from 'util/constant'

export default function DashboardReportSubmapel() {
  /* ========================================== Helpers ========================================= */
  const param = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [detailKelas, setDetailKelas] = useState(null)
  const [dataChart, setDataChart]     = useState(null)
  const [dataPie, setDataPie]         = useState(null)
  const [isLoading, setIsLoading]     = useState(false)

  /* ========================================= Functions ======================================== */
  async function getDetailKelas() {
    setIsLoading(true)
    const response = await getRequest(
      `report-kelas-dashboard?id=${param.kelasId}`,
    )
    setIsLoading(false)
    if (response) {
      setDetailKelas(response.data.classes)
      setDataChart(response.data.chart)
      setDataPie(response.data.pie)
    } else {
      console.error('ERROR', response)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getDetailKelas()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <>
      {isLoading ? (
        <div className="row">
          <div className="col">
            <LoadingSpinner />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-sm-12">
            <div className="row mb-3 mb-md-5">
              <div className="col-12">
                <h3>{detailKelas ? detailKelas.title : ''}</h3>
              </div>
              <div className="col-md-4 col-xl-3 mb-3 mb-md-0">
                <img
                  className="img-fluid rounded bg-light"
                  src={detailKelas ? detailKelas.linkImg : noImage}
                  alt={detailKelas ? detailKelas.title : 'Mata Pelajaran'}
                  onError={(e) => (e.currentTarget.src = noImage)}
                />
              </div>
              <div className="col-md-8 col-xl-9">
                <div
                  className="rounded border p-2"
                  style={{ overflowY: 'auto', height: '100%' }}
                >
                  <h5>Deskripsi :</h5>
                  <p className="f-16 mb-0">
                    {detailKelas ? detailKelas.description : '-'}
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6 col-xl-3">
                <div className="card bg-c-yellow order-card">
                  <div className="card-body">
                    <h6 className="text-white">Total Siswa</h6>
                    <h2 className="text-white">
                      {detailKelas ? detailKelas.total_siswa : 0}
                    </h2>
                    <i className="card-icon fas fa-users"></i>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card bg-c-red order-card">
                  <div className="card-body">
                    <h6 className="text-white">Total Guru</h6>
                    <h2 className="text-white">
                      {detailKelas ? detailKelas.jml_instruktur : 0}
                    </h2>
                    <i className="card-icon fas fa-user-tie"></i>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card bg-c-green order-card">
                  <div className="card-body">
                    <h6 className="text-white">Siswa selesai</h6>
                    <h2 className="text-white">
                      {detailKelas ? detailKelas.total_selesai : 0}
                    </h2>
                    <i className="card-icon fas fa-check-circle"></i>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card bg-info order-card">
                  <div className="card-body">
                    <h6 className="text-white">Siswa sedang mengerjakan</h6>
                    <h2 className="text-white">
                      {detailKelas ? detailKelas.in_progress : 0}
                    </h2>
                    <i className="card-icon fas fa-pencil-alt"></i>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card bg-c-purple order-card">
                  <div className="card-body">
                    <h6 className="text-white">Total waktu pengerjaan</h6>
                    <h5
                      style={{ fontSize: '21px' }}
                      className="text-white"
                    >
                      {detailKelas ? timeConvert(detailKelas.durasi) : 0}
                    </h5>
                    <i className="card-icon fas fa-clock"></i>
                  </div>
                </div>
              </div>
              <div className="col-xl-1" />
            </div>
            <div className="row mt-2 mb-2">
              <div className="col-sm-12 col-md-12 col-xl-6 mt-3">
                <LineChart
                  series={dataChart ? dataChart.series : []}
                  categories={dataChart ? dataChart.categories : []}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-xl-6 mt-3">
                <PieChart series={dataPie ? dataPie : []} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import STATE_TYPES from 'globals/state-types'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import ClassOverviewLoading from './class-overview-loading'
import ClassOverviewError from './class-overview-error'
import ClassOverviewGranted from './class-overview-granted'
import './overview.css'

const OverviewKelasSiswa = () => {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const { classId } = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/katalog-kelas', name: 'Katalog Mata Pelajaran' }]
  const initCoverStyles = {
    width: '100%',
    objectFit: 'cover',
  }

  /* ======================================= Local States ======================================= */
  const [windowWidth, setWidth]             = useState(window.innerWidth)
  const [windowHeight, setHeight]           = useState(window.innerHeight)
  const [coverStyles, setCoverStyles]       = useState(initCoverStyles)
  const [loadingClass, setLoadingClass]     = useState(STATE_TYPES.LOADING_IDLE)
  const [hasAccess, setHasAccess]           = useState(STATE_TYPES.TASK_IDLE)
  const [canAccess, setCanAccess]           = useState(STATE_TYPES.TASK_IDLE)
  const [pathwayBefores, setPathwayBefores] = useState([])
  const [pathwayAfters, setPathwayAfters]   = useState([])
  const [classData, setClassData]           = useState(null)
  const [classProgress, setClassProgress]   = useState(0)
  const [instruktur, setInstruktur]         = useState([])
  const [sectionList, setSectionList]       = useState([])
  const [contents, setContents]             = useState([])
  const [allContents, setAllContents]       = useState([])

  /* ========================================= Functions ======================================== */
  const updateDimensions = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)

    if (window.innerWidth >= 1280) {
      setCoverStyles({
        height: '250px',
        width: '100%',
        objectFit: 'cover',
      })
    } else if (window.innerWidth >= 768) {
      setCoverStyles({
        height: '200px',
        width: '100%',
        objectFit: 'cover',
      })
    } else {
      setCoverStyles({
        width: '100%',
        objectFit: 'cover',
      })
    }
  }

  const unlockClass = () => setCanAccess(STATE_TYPES.TASK_SUCCESS)

  const lockClass = () => setCanAccess(STATE_TYPES.TASK_FAILURE)

  const initContents = (sections = []) => {
    if (sections.length > 0) {
      let initContents = []
      let initContentsUnmodified = []
      sections.forEach((section) => {
        if (section.contents.length !== 0) {
          section.contents.forEach((content) => {
            let isCompleted = false
            if (content.class_content_activities.length !== 0) {
              content.class_content_activities.forEach((activity) => {
                if (activity.completed_at !== null) {
                  isCompleted = true
                }
              })
            }
            initContents.push({
              id: content.id,
              title: content.title,
              isCompleted,
              type_id: content?.content_type_id,
              // isEvaluated: null -> blm ngerjakan, false -> blm dikoreksi, true -> sudah dikoreksi
              isEvaluated:
                content?.is_assignment_evaluated || content?.is_quiz_evaluated,
              isSubmitted:
                content?.is_assignment_evaluated !== null ||
                content?.is_quiz_evaluated !== null,
              isAssignmentOrQuiz: [6, 8].includes(content?.content_type_id),
            })
            initContentsUnmodified.push(content)
          })
        }
      })
      setAllContents(initContents)
      setContents(initContentsUnmodified)
    }
  }

  const checkPreclassStatus = (classData) => {
    const classStatus = classData.classStatus
    if (classStatus.preclass === 'none' || classStatus.preclass === 'done') {
      if (classStatus.pathwayBefore.incompletes.length === 0) {
        unlockClass()
        setSectionList(classData.data.sections)
        initContents(classData.data.sections)
      } else {
        lockClass()
      }
      setPathwayBefores(classStatus.pathwayBefore.incompletes)
      setPathwayAfters([
        ...classStatus.pathwayAfter.incompletes,
        ...classStatus.pathwayAfter.completes,
      ])
    } else if (classStatus.preclass === 'progress') {
      lockClass()
    }
  }

  const checkAccess = async (classData) => {
    setHasAccess(STATE_TYPES.TASK_START)
    if (classData.message === 'Anda memiliki akses') {
      setHasAccess(STATE_TYPES.TASK_SUCCESS)
      setClassProgress(classData.classStatus.progress_percent || 0)
      setInstruktur(classData.instruktur)
      checkPreclassStatus(classData)
    } else {
      setHasAccess(STATE_TYPES.TASK_FAILURE)
    }
  }

  const getClass = async (classId) => {
    const response = await getRequest(`kelas-siswa?class_id=${classId}`)
    return response ? response : null
  }

  const resetStates = () => {
    setLoadingClass(STATE_TYPES.LOADING_IDLE)
    setHasAccess(STATE_TYPES.TASK_IDLE)
    setCanAccess(STATE_TYPES.TASK_IDLE)
    setAllContents([])
    setContents([])
    setSectionList([])
    setClassData(null)
  }

  const init = async (classId) => {
    setLoadingClass(STATE_TYPES.LOADING_START)
    const classData = await getClass(classId)
    setClassData(classData)
    if (typeof classData === 'object' && classData !== null) {
      setLoadingClass(STATE_TYPES.LOADING_SUCCESS)
      checkAccess(classData)
    } else {
      setLoadingClass(STATE_TYPES.LOADING_FAILURE)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    resetStates()
    init(classId)
    window.addEventListener('resize', updateDimensions)
    return () => {
      window.removeEventListener('resize', updateDimensions)
      resetStates()
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName={classData?.data?.title || ''}
          linkName="Overview"
          links={links}
        />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-body">
                {loadingClass === STATE_TYPES.LOADING_START ? (
                  <ClassOverviewLoading
                    coverStyles={coverStyles}
                    windowWidth={windowWidth}
                  />
                ) : (
                  <>
                    {loadingClass === STATE_TYPES.LOADING_SUCCESS && (
                      <>
                        {hasAccess === STATE_TYPES.TASK_SUCCESS && (
                          <ClassOverviewGranted
                            coverStyles={coverStyles}
                            windowWidth={windowWidth}
                            classProgress={classProgress}
                            instruktur={instruktur}
                            classData={classData}
                            canAccess={canAccess}
                            pathwayBefores={pathwayBefores}
                            pathwayAfters={pathwayAfters}
                            sections={sectionList}
                            contents={allContents}
                            contentsInitial={contents}
                          />
                        )}
                        {hasAccess === STATE_TYPES.TASK_FAILURE && (
                          <ClassOverviewError
                            coverStyles={coverStyles}
                            errorMessage="Anda tidak memiliki akses"
                          />
                        )}
                      </>
                    )}
                    {loadingClass === STATE_TYPES.LOADING_FAILURE && (
                      <ClassOverviewError
                        coverStyles={coverStyles}
                        windowWidth={windowWidth}
                        errorMessage="Terjadi kesalahan, muat ulang halaman"
                      />
                    )}
                  </>
                )}
              </div>
              <div className="card-footer">
                <button
                  onClick={() => history.goBack()}
                  type="button"
                  className="btn btn-outline-danger"
                >
                  <i className="fas fa-chevron-left mr-2" />
                  Kembali
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

export default OverviewKelasSiswa

/*
(Yadi - Team MSI)
This version:
-prevent looping interval
-quiz system is 1 page

(25-03-24):
-while student send answer to server and suddenly error, the question card of answer background will change to red
*/

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Swal from 'sweetalert2'
import moment from 'moment'

import { useBaseContext } from 'context/base'
import useContentPlayerContext from 'context/content-player'
import useMainContent from 'context/main-content'
import Modal from 'component/modal'
import LoadingSpinner from 'component/loading'
import Question from './questions/question'
import { QuizDone, ReviewButtons } from './components/quiz-components'
import STATE_TYPES from 'globals/state-types'
import CkeditorReadonly from 'component/ckeditor-readonly'
import FlipCountdown from '@rumess/react-flip-countdown';
import useScrollElementByRef from 'hooks/useScrollElementByRef'
import useDeviceDetect from 'hooks/useDeviceDetect'

const ContentQuiz = ({ isPreview = false }) => {

  /* ================================== Consume Context & Hook ================================== */
  const { getRequest, postRequest, putRequest } = useBaseContext()
  const { content, kelas, activity }            = useContentPlayerContext()
  const { shouldSendCCA }                       = useMainContent()
  const { elementRef: scrollStartElementRef, isScrollToElementReached } = useScrollElementByRef()
  const { isMobile } = useDeviceDetect()

  /* ========================================= Constants ======================================== */
  const user   = JSON.parse(localStorage.getItem('user'))
  const userId = user.id

  /* ========================================== States ========================================== */
  const [quizDesccription, setQuizDescription]    = useState(null)
  const [questions, setQuestions]                 = useState([])
  const [loadingQuiz, setLoadingQuiz]             = useState('')
  const [quizStatus, setQuizStatus]               = useState('')
  const [timerStatus, setTimerStatus]             = useState('')
  const [answers, setAnswers]                     = useState([])
  const [loadingSubmit, setLoadingSubmit]         = useState(false)
  const [questionIndex, setQuestionIndex]         = useState(0)
  const [answerText, setAnswerText]               = useState('')
  const [isDone, setIsDone]                       = useState(false)
  const [historyAssignment, setHistoryAssignment] = useState([])
  const [endTime, setEndTime] = useState('')
  const [quizTempResponse, setQuizTempResponse] = useState(null)

  /* ========================================= Functions ======================================== */
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const insertQuizTemp = async ({
    quiz_id,
    question_id,
    answer_id,
    answer_text,
    score,
    content_id,
    isNext = true
  }) => {
    let form = new FormData()
    form.append('quiz_id', quiz_id)
    form.append('question_id', question_id)
    form.append('answer_id', answer_id ? answer_id : '')
    form.append('answer_text', answer_text !== null ? answer_text : '')
    form.append('score', score !== null ? score : '')
    form.append('content_id', content_id)
    let res = await postRequest('quiz-temp', form)
    if (res) {
      setQuizTempResponse(res)
      return true
    } else {
      return false
    }
  }

  const insertQuizTempGraphor = async ({
    graphor_id,
    quiz_id,
    question_id,
    answer_text,
    content_id,
    isNext = true
  }) => {
    let form = new FormData()
    form.append('graphor_id', graphor_id)
    form.append('quiz_id', quiz_id)
    form.append('question_id', question_id)
    form.append('answer_text', JSON.stringify(answer_text))
    form.append('content_id', content_id)
    let res = await postRequest('quiz-temp-graphor', form)
    if (res) {
      setQuizTempResponse(res)
      return true
    } else {
      return false
    }
  }

  function makeQuestionIsSavedOrNotByCurrentQuestionIndex({index, isSuccessSendAnswer, body}) {
    if (questions?.[index]) {
      let copyQuestionsData = [...questions]
      if (isSuccessSendAnswer) {
        copyQuestionsData[index].isSuccessSaved = true
      } else {
        copyQuestionsData[index].isSuccessSaved = false
        copyQuestionsData[index].dataForResendAnswer = body
      }

      setQuestions(copyQuestionsData)
    }
  }

  async function handleChangeGraphors(questions, answersS, isNext = true) {
    let a = answers
    const index = a.findIndex((val) => val.question_id == questions.id)
    const body = {
      graphor_id: questions.value_id,
      quiz_id: a[index].quiz_id,
      question_id: a[index].question_id,
      answer_text: answersS,
      content_id: content.id,
      isNext
    }
    a[index] = {
      question_id: a[index].question_id,
      quiz_id: a[index].quiz_id,
      answer_id: null,
      answer_text: answersS,
      quest_type: questions?.quest_type,
      value_id: questions.value_id,
      score: null,
    }
    // setAnswers(a)
    if (!isPreview) {
      const isSuccessSendAnswer = await insertQuizTempGraphor(body)
      makeQuestionIsSavedOrNotByCurrentQuestionIndex({index, isSuccessSendAnswer, body})
    }
  }

  async function handleChangePilihanGanda(answerData, isNext = true) {
    let answerList = answers
    const index = answerList.findIndex(
      (val) => val.question_id === answerData.question_id,
    )
    const body = {
      question_id: answerList[index].question_id,
      quiz_id: answerList[index].quiz_id,
      answer_id: answerData.id,
      answer_text: null,
      score: answerData.nilai,
      content_id: content.id,
      isNext
    }
    answerList[index] = {
      ...body,
      quest_type: answerList[index].quest_type,
      value_id: answerList[index].value_id,
    }
    setAnswers(answerList)
    if (!isPreview) {
      const isSuccessSendAnswer = await insertQuizTemp(body)
      makeQuestionIsSavedOrNotByCurrentQuestionIndex({index, isSuccessSendAnswer, body})
    }
  }

  async function handleChangeEssay(event, questionData, isNext = true) {
    let answerList = answers
    const index = answerList?.findIndex(
      (val) => val?.question_id === questionData?.id,
    )

    if (index > -1) {
      const body = {
        question_id: answerList?.[index]?.question_id,
        quiz_id: answerList?.[index]?.quiz_id,
        answer_id: null,
        answer_text: event,
        score: null,
        content_id: content.id,
        isNext
      }
      answerList[index] = {
        ...body,
        quest_type: answerList?.[index]?.quest_type,
        value_id: answerList?.[index]?.value_id,
      }
      setAnswers(answerList)
      if (!isPreview) {
        const isSuccessSendAnswer = await insertQuizTemp(body)
        makeQuestionIsSavedOrNotByCurrentQuestionIndex({index, isSuccessSendAnswer, body})
      }
    }
  }

  async function resendAnswerHandler(question) {
    Swal.fire({
      title: 'Loading',
      text: 'Harap Tunggu...',
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {
        Swal.hideLoading()
      }
    })

    const resendAnswerData = question?.dataForResendAnswer
    switch (question?.quest_type) {
      case 0: // <- question PG type
      case 1: // <- question Essay type
        let answerList = answers
        const indexPGAndEssay = answerList?.findIndex(
          (val) => val?.question_id === question?.id,
        )
        const isSuccessSendAnswerPGAndEssay = await insertQuizTemp(resendAnswerData)
        if (isSuccessSendAnswerPGAndEssay && indexPGAndEssay > -1) {
          makeQuestionIsSavedOrNotByCurrentQuestionIndex({ index: indexPGAndEssay, isSuccessSendAnswer: isSuccessSendAnswerPGAndEssay, body: resendAnswerData })
        }
        Swal.close()
        break;
      case 2:
        let a = answers
        const indexGraphor = a.findIndex((val) => val.question_id == question?.id)
        const isSuccessSendAnswerGraphor = await insertQuizTempGraphor(resendAnswerData)
        if (isSuccessSendAnswerGraphor && indexGraphor > -1) {
          makeQuestionIsSavedOrNotByCurrentQuestionIndex({ index: indexGraphor, isSuccessSendAnswer: isSuccessSendAnswerGraphor, body: resendAnswerData })
        }
        Swal.close()
        break;
      default:
        window.notification('', `Terjadi kesalahan saat mengirim ulang jawaban, coba kembali.`, 'warning')
        Swal.close()
        break;
    }
  }

  const completeActivity = async (
    contentId,
    classId,
    activityId,
    is_remedial,
    is_pg_only = false,
  ) => {
    let form = new FormData()
    form.append('activity_id', activityId)
    form.append('content_id', contentId)
    form.append('class_id', classId)
    form.append(
      'is_complete',
      is_pg_only && is_remedial ? 0 : shouldSendCCA ? 1 : 0,
    )
    const response = await postRequest('class-content-activities', form)
    if (response) {
      const completeQuiz = () => {
        activity.markComplete()
        activity.setIdDone(contentId)
        activity.setMediaIdDone(contentId)
      }

      // is_remedial && setQuestionIndex(0)
      is_pg_only && !is_remedial
        ? completeQuiz()
        : activity.setIdDone(contentId + 'justAnotherRandomString') // ngetrigger update sidebar
      !is_pg_only && !is_remedial && activity.markSubmit()
    }
  }

  const submitQuizUser = async (contentId, userId) => {
    Swal.fire({
      title: 'Mengirim jawaban quiz...',
      html: `<div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>`,
      allowOutsideClick: false,
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
    })
    setLoadingSubmit(true)
    await updateTimer(contentId)
    stopTimeoutChecker()
    let form = new FormData()
    form.append('content_id', contentId)
    const response = await postRequest('quiz-user', form)
    if (response) {
      scrollToTop()
      window.clockQuiz = null
      if (response?.data?.is_remedial) {
        setQuizStatus(STATE_TYPES.TASK_IDLE)
        setLoadingQuiz(STATE_TYPES.LOADING_IDLE)
        setIsDone(false)
        setLoadingSubmit(false)
        const d = new Date()
        const _historyAssignment = [...historyAssignment]
        _historyAssignment.push({
          note: `Nilai: ${response.data.total_score}, Remidi Pilihan Ganda`,
          tanggal_tolak: `${d.getFullYear()}-${
            d.getMonth() + 1
          }-${d.getDate()}`,
        })
        setHistoryAssignment(_historyAssignment)
        response.data.is_pg_only && Swal.fire({
          icon: 'error',
          text: `Nilai kamu masih ${response.data.total_score}, coba lagi ya! 💪💪💪`,
        })
      } else {
        setQuizStatus(STATE_TYPES.TASK_ENDED)
      }
      localStorage.removeItem(`quiz_timer_c=${contentId}_u=${userId}`)
      await completeActivity(
        contentId,
        kelas.id,
        activity.id,
        response.data.is_remedial,
        response.data.is_pg_only,
      )
      const swalInput =
        historyAssignment.length >= 2 && response.data.is_pg_only
          ? {
              icon: 'error',
              text: `Nilai kamu masih ${response.data.total_score}, maaf tidak bisa remidi lagi ya 🙏`,
            }
          : {
              icon: 'success',
              text: 'Jawaban berhasil dikirim! 👌',
            }
      !response.data.is_remedial && Swal.fire(swalInput)
    }
  }

  const handleSubmitAnswers = async (contentId, userId) => {
    const isAllQuestionAnswered = isAllQuestionAnsweredHandler()
    if (isAllQuestionAnswered) {
      Swal.fire({
        title: 'Kumpulkan jawaban sekarang?',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Periksa kembali',
        confirmButtonText: 'Ya, kumpulkan!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await submitQuizUser(contentId, userId)
        }
      })
    }
  }

  const isAllQuestionAnsweredHandler = () =>{
    if (Array.isArray(questions) && questions.length) {
      const isThereQuestionNotAnswered = !!quizTempResponse?.questionNumberNotAnswered?.length

      if (isThereQuestionNotAnswered) {
        window.notification('', `Jawaban soal no ${quizTempResponse?.questionNumberNotAnswered?.join(',')} belum terisi!`, 'warning')
        return
      }

      return true
    }

    // if questions data not found
    window.notification('', 'Pertanyaan tidak tersedia!', 'warning')
  }

  function handleCompleted(isNext = true) {
    if (answerText) {
      if (answerText.question_id) {
        handleChangePilihanGanda(answerText, isNext)
      } else if (answerText.questions) {
        handleChangeGraphors(answerText?.questions, answerText?.l, isNext)
      } else {
        handleChangeEssay(answerText, questions[questionIndex], isNext)
      }
    } else {
      window.notification('', 'Jawaban tidak boleh kosong', 'warning')
    }
  }

  function handleCompletedDirect(answerText, questionIndex) {
    if (answerText) {
      if (answerText?.question_id) {
        handleChangePilihanGanda(answerText, false)
      } else if (answerText?.questions) {
        handleChangeGraphors(answerText?.questions, answerText?.l, false)
      } else {
        handleChangeEssay(answerText, questions[questionIndex], false)
      }
    }
  }

  const initAnswersUser = (questions = []) => {
    if (questions.length > 0) {
      return questions.map((question) => ({
        question_id: question.id,
        quiz_id: question.quiz_id,
        answer_id: null,
        answer_text: null,
        quest_type: question.quest_type,
        value_id: question.value_id,
        score: null,
      }))
    } else {
      return []
    }
  }

  const getQuizData = async (quizId, contentId) => {
    const response = await getRequest(`quiz-user?id=${quizId}&content_id=${contentId}`)
    if (response) {
      setQuizTempResponse(response)

      return response.data
    }
    return null
  }

  const getQuizDescription = async (quizId) => {
    const response = await getRequest(
      `quiz-description?quiz_id=${quizId}`,
    )
    if (response) return response.data
    return null
  }

  const getQuizUser = async (quizId, contentId, userId) => {
    const response = await getRequest(
      `quiz-user-only?user_id=${userId}&quiz_id=${quizId}&content_id=${contentId}`,
    )
    return !!response.data
  }

  const getQuizTemp = async (contentId, question) => {
    const response = await getRequest(
      `quiz-temp?content_id=${contentId}`,
    )
    if (response) {
      let temp = []
      question.map((item) => {
        let f = response.data.findIndex((i) => i.question_id == item.id)
        temp.push(response.data[f])
      })
      return temp
    }
    return []
  }

  const initQuizData = async (quizId, contentId) => {
    setLoadingQuiz(STATE_TYPES.LOADING_START)
    const quiz = await getQuizData(quizId, contentId)
    var question = questions
    if (question?.length == 0) {
      question = quiz?.questions || []
    }
    const quizTemps = await getQuizTemp(contentId, question)
    if (typeof quizTemps === 'object' && quizTemps.length > 0) {
      setAnswers(quizTemps)
    } else {
      const answers = initAnswersUser(question)
      setAnswers(answers)
    }
    setQuestions(question)
    setTimeout(() => {
      setLoadingQuiz(STATE_TYPES.LOADING_SUCCESS)
    }, 300)
  }

  const initQuizPreview = useCallback(async (quizId, contentId) => {
    setLoadingQuiz(STATE_TYPES.LOADING_START)
    const quiz = await getQuizData(quizId, contentId)
    const questions = quiz ? quiz.questions : []
    const answers = initAnswersUser(questions)
    setAnswers(answers)
    setQuestions(questions)
    setTimeout(() => {
      setLoadingQuiz(STATE_TYPES.LOADING_SUCCESS)
    }, 300)
  }, [])

  const renderStopwatch = useMemo(() => {

    const onTimeoutChecker = async () => {
      const contentId = content?.id
      if (quizDesccription?.time) {
        let timer = JSON.parse(
          localStorage.getItem(`quiz_timer_c=${contentId}_u=${userId}`),
        )
        if (timer) {
          const { end_time, is_timeout } = timer
          const now = Date.now()
          const endTime = new Date(end_time).getTime()
          if (is_timeout || now > endTime) {
            stopTimeoutChecker()
            await onTimeout(userId, contentId, quizDesccription)
          }
        }
      }
    }

    if (timerStatus === STATE_TYPES.TASK_START && endTime) {
      return <div className={isScrollToElementReached ? 'sticky-timer-active' : 'sticky-timer-inactive'}>
        <FlipCountdown
        size={isMobile ? 'small' : 'medium'}
        hideYear
        hideMonth
        hideDay
        endAt={endTime}
        onTimeUp={onTimeoutChecker}
        />
      </div>
    }
  }, [timerStatus, endTime, isScrollToElementReached, isMobile])

  const stopTimeoutChecker = () => {
    try {
      if (window.quizTimer) {
        clearInterval(window.quizTimer)
        window.quizTimer = null
      }
    } catch (error) {
      
    }
  }

  const getTimer = async (contentId) => {
    const response = await getRequest(
      `quiz-timer?content_id=${contentId}`,
    )
    if (response) {
      return response?.data
        ? {
            content_id: response.data.content_id,
            user_id: response.data.user_id,
            end_time: response.data.end_time,
            is_timeout: response.data.is_timeout,
          }
        : null
    } else {
      return null
    }
  }

  const setTimer = async (contentId, duration) => {
    let form = new FormData()
    form.append('content_id', contentId)
    form.append('start_time', new Date().toISOString())
    form.append('duration', duration)
    const response = await postRequest(`quiz-timer`, form)
    if (response) {
      return response.data
        ? {
            content_id: response.data.content_id,
            user_id: response.data.user_id,
            end_time: response.data.end_time,
            is_timeout: response.data.is_timeout,
          }
        : null
    } else {
      return null
    }
  }

  const updateTimer = async (contentId) => {
    const timeout = '1'
    let form = new FormData()
    form.append('content_id', contentId)
    form.append('is_timeout', timeout)
    const response = await putRequest(`quiz-timer`, form)
    if (response) {
      return response.data
        ? {
            content_id: response.data.content_id,
            user_id: response.data.user_id,
            end_time: response.data.end_time,
            is_timeout: response.data.is_timeout,
          }
        : null
    } else {
      return null
    }
  }

  const onTimeout = async (userId, contentId, quizDesccription) => {
    stopTimeoutChecker()
    if (quizDesccription?.time) {
      const timer = await updateTimer(contentId)
      if (typeof timer === 'object' && timer !== null) {
        setTimerStatus(STATE_TYPES.TASK_ENDED)
        window.clockQuiz = null
        localStorage.setItem(
          `quiz_timer_c=${contentId}_u=${userId}`,
          JSON.stringify(timer),
        )
        Swal.fire({
          title: 'Quiz telah berakhir!',
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'Tutup',
          showConfirmButton: false,
        })

        await handleCompleted()
        await submitQuizUser(contentId, userId)
      }
    }
  }

  const handleStartQuiz = async (quizId, contentId, userId, duration) => {
    Swal.fire({
      title: `Mulai ${historyAssignment.length ? 'Remidi' : 'Quiz'} Sekarang?`,
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Tidak',
      showConfirmButton: true,
      confirmButtonText: 'Ya, mulai!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await initQuizData(quizId, contentId)
        const timer = await setTimer(contentId, duration)
        if (typeof timer === 'object' && timer !== null) {
          localStorage.setItem(
            `quiz_timer_c=${timer.content_id}_u=${timer.user_id}`,
            JSON.stringify(timer),
          )
          setTimerStatus(STATE_TYPES.TASK_START)
          setTimeout(async () => {
            setEndTime(timer.end_time)
          }, 1000)
        }
      }
    })
  }

  const initTimer = useCallback(async (status, contentId, quizId, userId,quizDesccription) => {
    setTimerStatus(status ? STATE_TYPES.TASK_ENDED : STATE_TYPES.TASK_START)
    if (!status) {
      const timer = await getTimer(contentId)
      if (typeof timer === 'object' && timer !== null) {
        localStorage.setItem(
          `quiz_timer_c=${timer.content_id}_u=${timer.user_id}`,
          JSON.stringify(timer),
        )

        if (timer?.is_timeout) {
          await initQuizData(quizId, contentId)
          setTimerStatus(STATE_TYPES.TASK_ENDED)
          await onTimeout(userId, contentId, quizDesccription)
        } else {
          await initQuizData(quizId, contentId)
          if (quizDesccription?.time) {
            const now = Date.now()
            const endTime = new Date(timer.end_time).getTime()

            setTimeout(async () => {
              if (now < endTime) {
                setTimerStatus(STATE_TYPES.TASK_START)
                setTimeout(async () => {
                  setEndTime(timer.end_time)
                }, 1000)
              } else {
                await onTimeout(userId, contentId, quizDesccription)
              }
            }, 300)
          }
          else {
            setTimerStatus(STATE_TYPES.TASK_START)
          }
        }
      } else {
        setLoadingQuiz(STATE_TYPES.LOADING_IDLE)
      }
    }
  }, [])

  const init = async (isPreview, quizId, contentId, userId) => {
    setLoadingQuiz('')
    if (!isPreview) {
      const quiz = await getQuizDescription(quizId)
      await getUserHistory(quizId, contentId)
      setQuizDescription(quiz)
      setQuizStatus('')
      const status = await getQuizUser(quizId, contentId, userId)
      setQuizStatus(status ? STATE_TYPES.TASK_ENDED : STATE_TYPES.TASK_IDLE)
      activity.setMediaIdDone(status ? contentId : '')
      await initTimer(status, contentId, quizId, userId, quiz)
    } else {
      const quiz = await getQuizDescription(quizId)
      setQuizDescription(quiz)
      await initQuizPreview(quizId, contentId)
      await getUserHistory(quizId, contentId)
    }
  }

  const getUserHistory = async (quizId, contentId) => {
    const menu_key_list = JSON.parse(localStorage.getItem('menu_key'))

    // Handling get quiz-rejected hanya kalau sebagai siswa saja
    if (menu_key_list && !menu_key_list.includes('katalog-kelas')) {
      return
    } else {
      setHistoryAssignment([])
      const response = await getRequest(
        `quiz-rejected?quiz_id=${quizId}&content_id=${contentId}`,
      )
      if (response) {
        setHistoryAssignment(response.data)
      } else {
        setHistoryAssignment([])
      }
    }
  }

  const questionRenderMemo = useMemo(() => {
    if (Array.isArray(questions) && questions.length) {
      return questions.map((question, questionIndex) => 
        <Question
        question={questions[questionIndex]}
        key={questions[questionIndex].id}
        handleChangeGraphors={(v) => {
          handleCompletedDirect(v, questionIndex)
        }
        }
        handleChangePilihanGanda={(v) => {
          handleCompletedDirect(v, questionIndex)
        }
        }
        handleAnswerText={(v) => {
          handleCompletedDirect(v, questionIndex)
        }
        }
        resendAnswerHandler={resendAnswerHandler}
        questionIndex={questionIndex}
        answerList={answers}
        readOnly={false}
        isPreview={isPreview}
      />
      )
    }
  }, [questions])

  useEffect(() => {
    init(isPreview, content.contents_url.quiz_id, content.id, userId)

    if (!isPreview) {
      const preventUserReloadBrowser = (event) => {
        event.preventDefault();
      };
      window.addEventListener('beforeunload', preventUserReloadBrowser);
      return () => {
        window.removeEventListener('beforeunload', preventUserReloadBrowser);
      };
    }
  }, [content, isPreview])
  return (
    <>
      {!isPreview ? (
        <>
          {quizStatus === '' ? (
            <LoadingSpinner />
          ) : (
            <>
              {historyAssignment.length ? (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    {!content.is_quiz_evaluated ? (
                      <span className="badge badge-danger">
                        <i className="fas fa-exclamation-triangle mr-1" />
                        Quiz anda ditolak!
                      </span>
                    ) : (
                      <span />
                    )}
                    <button
                      className="btn btn-sm btn-primary"
                      data-toggle="modal"
                      data-target="#modalHistoryAssignment"
                    >
                      Riwayat
                    </button>
                    <Modal
                      id="modalHistoryAssignment"
                      title="Riwayat History"
                    >
                      <div
                        className="hd-body pr-2"
                        style={{
                          maxHeight: '350px',
                          overflowY: 'auto',
                        }}
                      >
                        {historyAssignment.map((history, index) => (
                          <div
                            key={index}
                            className="excerpt rounded mb-3"
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <span>
                                <i className="feather icon-calendar mr-1 f-14" />
                                {history.upload_at
                                  ? moment(history.upload_at).format(
                                      'H:MM, DD-MM-YYYY',
                                    )
                                  : '-'}
                              </span>
                              <span className="badge badge-danger">
                                Ditolak
                              </span>
                            </div>
                            <h6 className="mb-1">Catatan:</h6>
                              {history.note ? <CkeditorReadonly data={history.note} /> : <p className="m-0">'-'</p>}
                          </div>
                        ))}
                      </div>
                    </Modal>
                  </div>
                  <div
                    className="mt-2 border rounded alert alert-light text-dark w-100"
                    role="alert"
                  >
                    <strong>
                      <i className="fas fa-info-circle mr-1" />
                      Catatan
                    </strong>
                    <p className="m-0">
                      {historyAssignment[0].note
                        ? <CkeditorReadonly data={historyAssignment[0].note} /> : <p className="m-0">'-'</p>}
                    </p>
                  </div>
                </>
              ) : null}
              {quizStatus === STATE_TYPES.TASK_ENDED && <QuizDone />}
              {quizStatus === STATE_TYPES.TASK_IDLE && (
                <>
                {
                  !quizDesccription?.active ?
                  <div className='row justify-content-center mb-3'>
                    <span className="badge badge-warning">
                      <i className="fas fa-exclamation-triangle mr-1" />
                      Quiz belum aktif!
                    </span>
                  </div>
                  : null
                }
                  <div className="row mb-3">
                    <div className="col">
                      <div className="bg-light rounded border d-flex flex-column align-items-center p-3">
                        <div
                          className="border rounded alert alert-light text-dark w-100"
                          role="alert"
                        >
                          <strong>
                            <i className="fas fa-info-circle mr-1" />
                            Perhatian
                          </strong>
                          <ul className="m-0 p-0 ml-3">
                            <li>
                              <p className="m-0">
                                Mohon perhatikan jumlah soal dan waktu
                                pengerjaan di bawah ini.
                              </p>
                            </li>
                            <li>
                              <p className="m-0">
                                Waktu pengerjaan tidak dapat{' '}
                                <strong>dihentikan</strong> atau{' '}
                                <strong>diulang</strong> saat quiz sudah
                                dimulai.
                              </p>
                            </li>
                            <li>
                              <p className="m-0">
                                Jika waktu sudah habis, anda tidak dapat mengisi
                                atau mengubah jawaban quiz. Quiz masih bisa
                                dikumpulkan.
                              </p>
                            </li>
                            <li>
                              <p className="m-0">
                                Klik tombol{' '}
                                <strong>
                                  Mulai{' '}
                                  {historyAssignment.length ? 'Remidi' : 'Quiz'}
                                </strong>{' '}
                                untuk memulai.
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="text-dark">
                          <span className="f-14">
                            <i className="fas fa-question-circle mr-1" />
                            <strong>
                              {quizDesccription
                                ? quizDesccription.total_questions
                                : 0}
                            </strong>{' '}
                            Soal
                          </span>
                          <span className="ml-3 f-14">
                            <i className="fas fa-clock mr-1" />
                            Waktu pengerjaan{' '}
                            <strong>
                              {quizDesccription
                                ? (quizDesccription.time / 60 || <i className="fas fa-infinity"></i>)
                                : 0}
                            </strong>{' '}
                            menit
                          </span>
                        </div>
                        {quizStatus === STATE_TYPES.TASK_IDLE &&
                        loadingQuiz === STATE_TYPES.LOADING_IDLE &&
                        quizDesccription ? (
                          <button
                            className="btn btn-sm btn-primary mt-3"
                            onClick={() =>
                              handleStartQuiz(
                                content.contents_url.quiz_id,
                                content.id,
                                userId,
                                quizDesccription.time,
                              )
                            }
                            disabled={!quizDesccription?.active}
                          >
                            Mulai {historyAssignment.length ? 'Remidi' : 'Quiz'}
                          </button>

                        ) : (
                          <>
                            {loadingQuiz === STATE_TYPES.LOADING_START && (
                              <button
                                className="btn btn-sm btn-primary btn-forbidden mt-3"
                                disabled={true}
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                />
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {historyAssignment.length ?
                    loadingQuiz !== STATE_TYPES.LOADING_SUCCESS && (
                      <ReviewButtons />
                    ) : null}
                  <div className="row">
                    <div className="col">
                      {loadingQuiz === STATE_TYPES.LOADING_START && (
                        <LoadingSpinner />
                      )}
                      {loadingQuiz === STATE_TYPES.LOADING_SUCCESS && (
                        <>
                          {loadingSubmit ? (
                            <ul className="list-unstyled">
                              {questions.length ?
                                questions.map((question, index) => (
                                  <Question
                                    question={question}
                                    key={question.id}
                                    handleChangeGraphors={(v) =>
                                      handleChangeGraphors(v.questions, v.l)
                                    }
                                    questionIndex={index}
                                    answerList={answers}
                                    readOnly={true}
                                    isPreview={isPreview}
                                  />
                                )) : null}
                            </ul>
                          ) : (
                            <>
                              {timerStatus === STATE_TYPES.TASK_START && (
                                <>
                                  <div className="d-flex justify-content-center my-3">
                                    {/* <div className="clock" /> */}
                                    {renderStopwatch}
                                  </div>
                                  {isDone ? (
                                    <ul className="list-unstyled mb-5">
                                      <div className="col">
                                        <div className="d-flex justify-content-center">
                                          <div
                                            className="rounded-circle p-2 my-3"
                                            style={{
                                              border: '3px solid #9ccc65',
                                            }}
                                          >
                                            <i className="fas fa-check text-success f-48" />
                                          </div>
                                        </div>
                                        <p className="text-center f-20 mb-0">
                                          Selesai!
                                        </p>
                                        <p className="text-center f-20">
                                          Apakah Anda sudah yakin dengan semua
                                          jawaban quiz ini?
                                        </p>
                                      </div>
                                    </ul>
                                  ) : (
                                    <ul ref={scrollStartElementRef} className="list-unstyled">
                                      {questionRenderMemo}
                                    </ul>
                                  )}
                                  <div className="w-100 d-flex justify-content-end align-items-center">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={
                                        loadingSubmit
                                          ? () => {}
                                          : () =>
                                              handleSubmitAnswers(
                                                content.id,
                                                userId,
                                                content.feedback,
                                                activity.id,
                                                kelas.id,
                                              )
                                      }
                                      disabled={loadingSubmit}
                                    >
                                      {loadingSubmit ? (
                                        <span
                                          className="spinner-border spinner-border-sm mr-1"
                                          role="status"
                                        />
                                      ) : (
                                        <i className="fas fa-location-arrow mr-2" />
                                      )}
                                      <span>Kirim Jawaban</span>
                                    </button>
                                  </div>
                                </>
                              )}

                              {isDone && (
                                <div className="mt-4">
                                  <ul className="list-unstyled">
                                    {questions.length ?
                                      questions.map((question, index) => (
                                        <Question
                                          question={question}
                                          key={question.id}
                                          handleChangeGraphors={(v) =>
                                            handleChangeGraphors(
                                              v.questions,
                                              v.l,
                                            )
                                          }
                                          questionIndex={index}
                                          answerList={answers}
                                          readOnly={true}
                                          isPreview={isPreview}
                                        />
                                      )) : null}
                                  </ul>
                                </div>
                              )}
                              {timerStatus === STATE_TYPES.TASK_ENDED && (
                                <>
                                  <div className="d-flex justify-content-center my-2">
                                    <div
                                      className="border rounded alert alert-danger text-dark w-100"
                                      role="alert"
                                    >
                                      <p className="f-16 m-0">
                                        Maaf, waktu sudah habis. Silahkan
                                        kumpulkan jawaban dengan menekan tombol{' '}
                                        <strong>Kirim Jawaban</strong>.
                                      </p>

                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={
                                          loadingSubmit
                                            ? () => {}
                                            : () =>
                                                handleSubmitAnswers(
                                                  content.id,
                                                  userId,
                                                  content.feedback,
                                                  activity.id,
                                                  kelas.id,
                                                )
                                        }
                                        disabled={loadingSubmit}
                                      >
                                        {loadingSubmit ? (
                                          <span
                                            className="spinner-border spinner-border-sm mr-1"
                                            role="status"
                                          />
                                        ) : (
                                          <i className="fas fa-location-arrow mr-2" />
                                        )}
                                        <span>Kirim Jawaban</span>
                                      </button>
                                    </div>
                                  </div>
                                  <ul className="list-unstyled">
                                    {questions.length ?
                                      questions.map((question, index) => (
                                        <Question
                                          question={question}
                                          key={question.id}
                                          handleChangeGraphors={(v) =>
                                            handleChangeGraphors(
                                              v.questions,
                                              v.l,
                                            )
                                          }
                                          questionIndex={index}
                                          answerList={answers}
                                          readOnly={true}
                                          isPreview={isPreview}
                                        />
                                      )) : null}
                                  </ul>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <div className="row">
          <div className="col">
            {loadingQuiz === STATE_TYPES.LOADING_START && <LoadingSpinner />}
            {loadingQuiz === STATE_TYPES.LOADING_SUCCESS && (
              <ul className="list-unstyled">
                {questions.length ?
                  questions.map((question, index) => (
                    <Question
                      question={question}
                      key={question.id}
                      handleChangeGraphors={(v) =>
                        handleChangeGraphors(v.questions, v.l)
                      }
                      handleChangePilihanGanda={(v) =>
                        handleChangePilihanGanda(v)
                      }
                      handleAnswerText={(v, q) => handleChangeEssay(v, q)}
                      questionIndex={index}
                      answerList={answers}
                      readOnly={false}
                      isPreview={isPreview}
                    />
                  )) : null}
              </ul>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default ContentQuiz

import React from 'react'

export default function TypeScoreForm({
  listTypeScore = [],
  typeScore = '',
  isTypeScoreActive = false,
  isOptionalJenisPenilaian = true,
  handleActiveTypeScore = () => {},
  handleChangeTypeScore = () => {},
}) {
  return (
    <div className="form-group row mb-3">
      <label className="col-form-label col-sm-3">
        Jenis Penilaian<span className="text-danger ml-1"></span>
      </label>
      {isOptionalJenisPenilaian
      ?
      <div className="col-sm-9">
        <div className="switch switch-primary d-inline m-r-10">
          <input
            onChange={handleActiveTypeScore}
            checked={isTypeScoreActive}
            type="checkbox"
            id="switchGrade"
          />
          <label
            htmlFor="switchGrade"
            className="cr"
          ></label>
        </div>
        <label htmlFor="switchGrade">
          {isTypeScoreActive ? 'Aktif' : 'Tidak aktif'}
        </label>
      </div>
      : null }
      {isTypeScoreActive ? (
        <div className={`${isOptionalJenisPenilaian ? 'offset-sm-3' : ''} col-sm-9 col-lg-4`}>
          <select
            value={typeScore}
            onChange={handleChangeTypeScore}
            className={`form-control`}
          >
            <option value={''}>-- Jenis penilaian --</option>
            {listTypeScore.length !== 0 ? (
              <>
                {listTypeScore.map((item, index) => (
                  <option
                    value={item.id}
                    key={index}
                  >
                    {item.type_score}
                  </option>
                ))}
              </>
            ) : null}
          </select>
        </div>
      ) : null}
    </div>
  )
}

import React, { useEffect, useState } from 'react'

import { useBaseContext } from 'context/base'
import useContentPlayerContext from 'context/content-player'
import useMainContent from 'context/main-content'
import useFeedbackForm from 'context/feedback-form'
import LoadingSpinner from 'component/loading'
import AddFeedKompleks from './add'
import EditFeedKompleks from './edit'

export default function FeedbackKompleks() {
  /* ====================================== Consume Context ===================================== */
  const { postRequest, putRequest} = useBaseContext()
  const { content, activity }      = useContentPlayerContext()
  const { shouldSendCCA }          = useMainContent()
  const { feed, event }            = useFeedbackForm()

  /* ========================================== States ========================================== */
  const [isUserSubmited, setIsUserSubmited] = useState(false)

  // FORM FEEDBACK
  const [feedResponIds, setFeedResponIds]             = useState([])
  const [isFeedResponIdEmpty, setIsFeedResponIdEmpty] = useState(false)
  const [isLoading, setIsLoading]                     = useState(false)

  /* ========================================= Functions ======================================== */
  function handleChangeFeedResponIds(value, checked) {
    if (checked) {
      feedResponIds.push(value)
    } else {
      const index = feedResponIds.indexOf(value)
      feedResponIds.splice(index, 1)
    }
  }

  function formValidation() {
    if (feedResponIds.length !== 0) {
      setIsFeedResponIdEmpty(false)
      formSubmit()
    } else {
      if (feedResponIds.length === 0) {
        setIsFeedResponIdEmpty(true)
      }
    }
  }

  async function formSubmit() {
    let form = new FormData()
    let response = null
    form.append('feed_type', 3)
    form.append('content_id', content.id)
    setIsLoading(true)
    if (isUserSubmited) {
      const oldTemps = feed.temps.map((temp) => {
        return {
          id: temp.id,
          feed_respon_id: temp.feed_respon_id,
          feed_id: temp.feed_id,
        }
      })
      const newTemps = feedResponIds.map((responId) => {
        return {
          feed_respon_id: responId,
          feed_id: feed.data.id,
        }
      })
      form.append('old_feed_temps', JSON.stringify(oldTemps))
      form.append('new_feed_temps', JSON.stringify(newTemps))
      response = await putRequest('feed-temp', form)
    } else {
      form.append('feed_respon_ids', JSON.stringify(feedResponIds))
      form.append('feed_id', feed.data.id)
      response = await postRequest('feed-temp', form)
    }
    if (response) {
      event.report(content.id)
      setIsUserSubmited(true)
      setIsLoading(false)
      event.set('submit-feed')
      shouldSendCCA && (await activity.report(true))
    } else {
      console.error('ERROR', response)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (feed.temps?.length) {
      setIsUserSubmited(true)

      const responIds = feed.temps?.map((temp) => {
        return temp.feed_respon_id
      })
      setFeedResponIds(responIds)
    }
  }, [feed])

  /* ======================================= Shared Props ======================================= */
  const sharedProps = {
    isFeedResponIdEmpty,
    isLoading,
    formValidation,
    handleChangeFeedResponIds,
    setIsFeedResponIdEmpty,
  }

  /* ========================================== Output ========================================== */
  return (
    <form className="mt-3 border p-3 rounded">
      <h5>Berikan feedback terhadap materi:</h5>
      {event.loading ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {isUserSubmited ? (
            <EditFeedKompleks sharedProps={sharedProps} />
          ) : (
            <AddFeedKompleks sharedProps={sharedProps} />
          )}
        </>
      )}
    </form>
  )
}

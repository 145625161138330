import React, { useState, useEffect } from 'react'
import moment from 'moment'
import ContentEnabled from 'component/content/playlist/overview/content-enabled'
import ContentDisabled from 'component/content/playlist/overview/content-disabled'
import useContentContainerContext, { ContentContainerWrapper } from 'context/content-container'
import { getTodayDate } from 'util/global'

const Section = ({ item, index, allContents }) => {
  /* ======================================= Local States ======================================= */
  const [isHovered, setIsHovered] = useState(false)

  /* ========================================= Constants ======================================== */
  const todayDate = getTodayDate(true)

  /* ========================================== Output ========================================== */
  return (
    <div className="mb-0">
      <div
        id={item.id}
        style={{
          backgroundColor: isHovered ? '#f6f6f6' : '#f8f9fa',
        }}
      >
        <h5 className="row m-0">
          <a
            type="button"
            className="col collapsed text-dark p-3 border-bottom"
            data-toggle="collapse"
            data-target={'#content' + index}
            aria-expanded="false"
            aria-controls={'content' + index}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="row">
              <div className="col-10 col-sm-11">
                <div className="row">
                  <span className="col-12 col-md-9 col-xl-10 f-16 f-w-500">
                    {item.urutan}
                    {'. '}
                    {item.title}
                  </span>
                  <span className="col-12 col-md-3 col-xl-2 text-right f-14 f-w-300">
                    {item.contents.length} materi
                  </span>
                </div>
              </div>
              <div className="col-2 col-sm-1 d-flex justify-content-end">
                <i className="fas fa-chevron-down" />
              </div>
            </div>
          </a>
        </h5>
      </div>
      <div
        id={'content' + index}
        className="collapse"
        aria-labelledby={item.id}
        data-parent="#konten-accordion"
      >
        <ul className="list-unstyled mb-0">
          {item.contents.length !== 0 ? (
            item.contents.map((content, index) => {
              return (
                <ContentContainerWrapper
                  key={content.id}
                  content={content}
                  classId={item.class_id}
                  allContents={allContents}
                >
                  <ContentContainer todayDate={todayDate} />
                </ContentContainerWrapper>
              )
            })
          ) : (
            <p className="text-muted p-3 mb-0">Belum ada materi.</p>
          )}
        </ul>
      </div>
    </div>
  )
}

const ContentContainer = ({ todayDate }) => {
  /* ====================================== Consume Context ===================================== */
  const { allContents, content, classId } = useContentContainerContext()

  /* ========================================== States ========================================== */
  const [isAvailable, setIsAvailable] = useState(false)
  const [isExpire, setIsExpire]       = useState(false)

  /* ========================================= Functions ======================================== */
  const checkPre   = (precontent) => {
    const found = allContents.find(ac => ac.id === precontent.id)

    if (!found) { return false } // kalau gk ada catatan di allContents maka lgsg false
    else {
      return found.isAssignmentOrQuiz ? found.isSubmitted : found.isCompleted // cek kalau sblmnya tugas/quiz cek udah submit apa belum
    }
  }
  const checkStart = (displayAt, todayDate) => new Date(displayAt).getTime() <= todayDate
  const checkEnd   = (endedAt, todayDate) => new Date(endedAt).getTime() < todayDate

  function init() {
    let available = true
    let expire = false
    if (content.display_at) {
      available = checkStart(content.display_at, todayDate)
      if (available) {
        if (content.content !== null) available = checkPre(content.content)
      }
    } else {
      if (content.content !== null) available = checkPre(content.content)
    }
    if (content.ended_at) {
      expire = checkEnd(content.ended_at, todayDate)
      if (expire) available = false
    }
    setIsAvailable(available)
    setIsExpire(expire)
  }

  function expireMessage() {
    if (content.display_at !== null || content.ended_at !== null) {
      if (isExpire) {
        return `Berakhir pada ${moment(content.ended_at).format(
          'DD MMMM YYYY',
        )}`
      } else {
        return `Tersedia pada ${moment(content.display_at).format(
          'DD MMMM YYYY HH:mm',
        )}`
      }
    } else {
      return null
    }
  }

  function precontentMessage() {
    if (content.content !== null) {
      if (content.content.class_content_activities.length !== 0) {
        return null
      } else {
        return `Selesaikan ${content.content.title}`
      }
    } else {
      return null
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    allContents && init()
  }, [allContents])

  /* ========================================== Output ========================================== */
  return (
    <>
      {isAvailable ? (
        <ContentEnabled
          item={content}
          classId={classId}
        />
      ) : (
        <ContentDisabled
          item={content}
          classId={classId}
          title={expireMessage()}
          isExpire={isExpire}
          precontentMessage={precontentMessage()}
        />
      )}
    </>
  )
}

export default Section

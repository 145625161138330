import React, { useState } from 'react'
import { ContentContainerWrapper } from 'context/content-container'
import ContentContainerSequential from 'component/content/playlist/overview/content-container-sequential'
import { getTodayDate } from 'util/global'

const SectionSequential = ({ item, index, allContents }) => {
  /* ======================================= Local States ======================================= */
  const [isHovered, setIsHovered] = useState(false)

  /* ========================================= Constants ======================================== */
  const todayDate = getTodayDate(true)

  /* ========================================== Output ========================================== */
  return (
    <div className="mb-0">
      <div
        id={item.id}
        style={{ backgroundColor: isHovered ? '#f6f6f6' : '#f8f9fa' }}
      >
        <h5 className="row m-0">
          <a
            type="button"
            className="col collapsed text-dark p-3 border-bottom"
            data-toggle="collapse"
            data-target={'#content' + index}
            aria-expanded="false"
            aria-controls={'content' + index}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="row">
              <div className="col-10 col-sm-11">
                <div className="row">
                  <span className="col-12 col-md-9 col-xl-10 f-16 f-w-500">
                    {item.urutan}
                    {'. '}
                    {item.title}
                  </span>
                  <span className="col-12 col-md-3 col-xl-2 text-right f-14 f-w-300">
                    {item.contents.length} materi
                  </span>
                </div>
              </div>
              <div className="col-2 col-sm-1 d-flex justify-content-end">
                <i className="fas fa-chevron-down" />
              </div>
            </div>
          </a>
        </h5>
      </div>
      <div
        id={'content' + index}
        className="collapse"
        aria-labelledby={item.id}
        data-parent="#konten-accordion"
      >
        <ul className="list-unstyled mb-0">
          {item.contents.length !== 0 ? (
            item.contents.map((content, index) => (
              <ContentContainerWrapper
                key={index}
                content={content}
                classId={item.class_id}
                sectionUrutan={item.urutan}
              >
                <ContentContainerSequential
                  todayDate={todayDate}
                  allContents={allContents}
                />
              </ContentContainerWrapper>
            ))
          ) : (
            <p className="text-muted p-3 mb-0">Belum ada materi.</p>
          )}
        </ul>
      </div>
    </div>
  )
}

export default SectionSequential

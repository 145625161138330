import React, { useEffect } from 'react'
import LoadingSpinner from 'component/loading'
import {
  ContentText,
  ContentAudio,
  ContentVideo,
  ContentDocument,
  ContentIframe,
  ContentQuiz,
  ContentSurvey,
  ContentAssignment,
  ContentGraphor,
  ContentWhiteboard,
} from 'component/content'
import useContentPlayerContext from 'context/content-player'
import { MainContentWrapper } from 'context/main-content'
import BaseLinkify from 'component/linkify/BaseLinkify'

export default function PreviewContent({ content_text, contents_url, content_source, selectedUser }) {
  /* ====================================== Consume Context ===================================== */
  const { content, isLoading } = useContentPlayerContext()

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    setTimeout(() => {
      if (content?.deskripsi) {
        var a = window
          .$('#instruksi')
          .height(window.$('textarea')[0]?.scrollHeight)
      }
    }, 1000)
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <>
      {isLoading ? (
        <div className="col-12 mt-3">
          <LoadingSpinner />
        </div>
      ) : (
        <MainContentWrapper>
          {content && (
            <>
              {content?.deskripsi && (
                <div className="border rounded p-3 mb-3">
                  <h6 className="f-w-500">Instruksi:</h6>
                  <BaseLinkify
                    id="instruksi"
                  >
                    {content.deskripsi}
                  </BaseLinkify>
                </div>
              )}
              {content?.content_type_id === 1 && <ContentText isPreview content_text={content_text} contents_url={contents_url} content_source={content_source} />}
              {content?.content_type_id === 2 && <ContentAudio isPreview content_text={content_text} contents_url={contents_url} content_source={content_source} />}
              {content?.content_type_id === 3 && <ContentVideo isPreview content_text={content_text} contents_url={contents_url} content_source={content_source} />}
              {content?.content_type_id === 4 && <ContentDocument isPreview content_text={content_text} contents_url={contents_url} content_source={content_source} />}
              {content?.content_type_id === 5 && <ContentIframe isPreview content_text={content_text} contents_url={contents_url} content_source={content_source} />}
              {content?.content_type_id === 6 && <ContentQuiz isPreview content_text={content_text} contents_url={contents_url} content_source={content_source} />}
              {content?.content_type_id === 7 && <ContentSurvey isPreview content_text={content_text} contents_url={contents_url} content_source={content_source} />}
              {content?.content_type_id === 8 && <ContentAssignment isPreview content_text={content_text} contents_url={contents_url} content_source={content_source} />}
              {content?.content_type_id === 9 && <ContentGraphor isPreview content_text={content_text} contents_url={contents_url} content_source={content_source} />}
              {content?.content_type_id === 10 && <ContentWhiteboard isPreview content_text={content_text} contents_url={contents_url} content_source={content_source} selectedUser={selectedUser} />}
            </>
          )}
        </MainContentWrapper>
      )}
    </>
  )
}

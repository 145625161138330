import React from 'react'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { Submit } from 'component/button'

export default function ProfileSttingUser() {
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Profile Setting"
          linkName="Users"
        />

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>User settings</h5>
              </div>
              <div className="card-body">
                <form>
                  <div className="form-group row">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Cara pendaftaran
                    </label>
                    <div className="col-sm-3">
                      <select
                        className="form-control"
                        id="inputEmail3"
                        placeholder="Nama Profile"
                      >
                        <option>Manual oleh admin</option>
                        <option>Langsung dan diaktivasi oleh admin</option>
                        <option>Langsung dan email verifikasi</option>
                        <option>Langsung</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Default user role
                    </label>
                    <div className="col-sm-3">
                      <select
                        className="form-control"
                        id="inputEmail3"
                        placeholder="Nama Profile"
                      >
                        <option>Administrator</option>
                        <option>Pengajar</option>
                        <option>Siswa</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Default group
                    </label>
                    <div className="col-sm-3">
                      <select
                        className="form-control"
                        id="inputEmail3"
                        placeholder="Nama Profile"
                      >
                        <option>Kelas 1</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Term Of Service
                    </label>
                    <div className="col-sm-3">
                      <textarea
                        className="form-control"
                        id="inputEmail3"
                        placeholder="Term of service"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    ></label>
                    <div className="col-sm-4">
                      <Submit
                        onClick={() => {}}
                        text="Simpan"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'

export default function RolesHakAkses() {
  /* ========================================== Helpers ========================================= */
  const history  = useHistory()
  const location = useLocation()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, putRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [activeMenu, setActiveMenu] = useState([])
  const [emapping, setEMapping]     = useState([])
  const [key, setKey]               = useState(Math.random())

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/roles', name: 'Roles' }]

  /* ========================================= Functions ======================================== */
  async function getMenuRole() {
    try {      
      const response = await getRequest(
        'menu-role?type=' + location.state.type,
      )
      var dd = mapping(response.data, activeMenu)
      setEMapping(dd)
      var act = []
      location.state.menu.forEach((val) => {
        act.push(val.menu_id)
      })
      setActiveMenu(act)
    } catch (error) {
      console.error(error)
    }
  }

  function mapping(data, menu_active) {
    var dt = []
    data.forEach((val) => {
      var id = val.id
      var act = menu_active.filter((v) => v === id)
      var d = {
        id: val.id,
        menu: val.menu,
        active: act.length > 0 ? 1 : 0,
        children: mapping(val.children, menu_active),
      }
      dt.push(d)
    })
    return dt
  }

  async function saveMenu() {
    var form = new FormData()
    form.append('id', location.state.id)
    form.append('role', location.state.role)
    form.append('menu', JSON.stringify(activeMenu))
    let response = await putRequest('roles', form)
    if (response) {
      let roleActive = JSON.parse(localStorage.getItem('role_active'))
      window.notification('Success !', 'Data role berhasil diupdate', 'success')
      history.goBack()
    } else {
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi !',
        'error',
      )
    }
  }

  async function cekMenu(status, id, children) {
    var a = []
    let j = await setChildrenStatus(status, children, a)
    if (status) {
      let act = activeMenu
      act.push(id)
      a.forEach((v) => {
        act.push(v)
      })
      await setActiveMenu(() => act)
      setKey(Math.random())
    } else {
      let act = activeMenu
      var f = act.findIndex((val) => val === id)
      let index = act.indexOf(id)
      if (index > -1) {
        act.splice(index, 1)
      }
      a.forEach((v) => {
        let index1 = act.indexOf(v)
        if (index1 > -1) {
          act.splice(index1, 1)
        }
      })
      await setActiveMenu(() => act)
      setKey(Math.random())
    }
  }

  async function setChildrenStatus(status, children, a) {
    children.forEach((val) => {
      a.push(val.id)
      setChildrenStatus(status, val.children, a)
    })
    return a
  }

  /* ======================================== Components ======================================== */
  function RenderMapping({ item, i, lote }) {
    let c = activeMenu.indexOf(item.id)
    let active = c > -1
    return (
      <>
        <tr key={Math.random()}>
          <td style={{}}>
            <div
              className="form-group"
              style={{ marginLeft: lote * 40 }}
            >
              <div className="switch switch-primary d-inline m-r-10">
                <input
                  type="checkbox"
                  id={`status-${item.id}`}
                  onChange={(e) =>
                    cekMenu(e.target.checked, item.id, item.children)
                  }
                  defaultChecked={active}
                />
                <label
                  htmlFor={`status-${item.id}`}
                  className="cr"
                />
              </div>
              <label>{item.menu}</label>
            </div>
          </td>
        </tr>
        {item.children.map((val, i) => (
          <RenderMapping
            key={i}
            item={val}
            i={i}
            lote={lote + 1}
          />
        ))}
      </>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    var act = []
    location.state.menu.forEach((val) => {
      act.push(val.menu_id)
    })
    setActiveMenu(act)
    getMenuRole()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Roles"
          linkName={
            location.state.role === 'Instruktur' ? 'Guru' : location.state.role
          }
          links={links}
        />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>
                  {location.state.role === 'Instruktur'
                    ? 'Guru'
                    : location.state.role}
                </h5>
                <button
                  type="button"
                  onClick={() => saveMenu()}
                  className="btn btn-success float-right"
                >
                  Simpan
                </button>
              </div>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Menu</th>
                    </tr>
                  </thead>
                  <tbody>
                    {emapping.map((val, i) => (
                      <RenderMapping
                        key={i + key}
                        item={val}
                        i={i}
                        lote={1}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React, { useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { editorConfiguration } from 'util/ckeditor-config'
import CardMeta from 'component/card-meta'

export const PreviewTextAssign = () => {
  return (
    <CKEditor
      editor={Editor}
      data="<p>Tulis tugas...</p>"
      config={editorConfiguration}
    />
  )
}

export const PreviewAudioAssign = () => {
  return (
    <div className="custom-file">
      <input
        type="file"
        accept="audio/*"
        className="custom-file-input"
        id="upload-audio"
      />
      <label
        className="custom-file-label"
        htmlFor="upload-audio"
      >
        Pilih file audio (mp3)
      </label>
    </div>
  )
}

export const PreviewVideoAssign = () => {
  return (
    <div className="custom-file">
      <input
        type="file"
        accept="video/*"
        className="custom-file-input"
        id="upload-video"
      />
      <label
        className="custom-file-label"
        htmlFor="upload-video"
      >
        Pilih file video (mp4)
      </label>
    </div>
  )
}

export const PreviewDocAssign = () => {
  return (
    <div className="custom-file">
      <input
        type="file"
        className="custom-file-input"
        id="upload-doc"
      />
      <label
        className="custom-file-label"
        htmlFor="upload-doc"
      >
        Pilih file dokumen (doc/xls/ppt/pdf)
      </label>
    </div>
  )
}

export const PreviewYoutubeAssign = () => {
  return (
    <div className="form-group row">
      <label
        className="col-sm-3 col-form-label"
        htmlFor="input-youtube"
      >
        URL Youtube :
      </label>
      <div className="col-sm-9">
        <input
          type="text"
          className="form-control"
          placeholder="Contoh URL: https://youtube.com/watch?v=xyz"
          id="input-youtube"
        />
      </div>
    </div>
  )
}

export const PreviewUrlAssign = () => {
  const [url, setUrl] = useState('')

  React.useEffect(() => {
    return () => {
      setUrl('')
    }
  }, [])

  return (
    <div className="form-group row">
      <label
        className="col-sm-3 col-form-label"
        htmlFor="inputTugasUrl"
      >
        URL Tugas:
      </label>
      <div className="col-sm-9">
        <input
          type="text"
          className="form-control"
          placeholder="Masukkan tugas berupa URL social media, website, dll"
          id="inputTugasUrl"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
      </div>
      {url ? (
        <div className="col-12 mt-3">
          <CardMeta url={url} />
        </div>
      ) : null}
    </div>
  )
}

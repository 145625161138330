import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import BackButton from 'component/button/Back'
import SubmitButton from 'component/button/Submit'

export default function KelasKategoriEdit() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const location = useLocation()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, putRequest} = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [parents, setParents]   = useState([])
  const [title, setTitle]       = useState(location.state.title)
  const [parentId, setParentId] = useState(``)
  const [types, setTypes]       = useState([])
  const [type, setType]         = useState(location.state.category_type)
  const [active, setActive]     = useState(`${location.state.active}`)

  /* ========================================= Functions ======================================== */
  async function handleSaveCategory() {
    if (title && active) {
      const form = new FormData()
      form.append('title', title)
      form.append('active', active)
      form.append('category_type', type)
      form.append('id', location.state.id)
      let response = await putRequest('categories', form)
      if (response) {
        window.notification('Mata Pelajaran', 'berhasil diubah', 'success')
        history.push('kelas-kategori')
      }
    } else {
      window.notification('Mohon', 'lengkapi data', 'warning')
    }
  }

  async function getCategoriesType() {
    let response = await getRequest('categories-type')
    if (response) {
      setTypes(response.data)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getCategoriesType()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Edit Mata Pelajaran"
          links={[{ url: 'kelas-kategori', name: 'Mata Pelajaran' }]}
        />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="form-group">
                    <div className="row">
                      <label className="col-sm-4 col-xl-2 col-form-label">
                        Nama Mata Pelajaran
                      </label>
                      <div className="col-sm-8 col-xl-10">
                        <input
                          type="text"
                          value={title}
                          onChange={(event) => setTitle(event.target.value)}
                          className="form-control"
                          placeholder="Nama Mata Pelajaran"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <label className="col-sm-4 col-xl-2 col-form-label">
                        Tipe Mata Pelajaran
                      </label>
                      <div className="col-sm-8 col-xl-10">
                        <select
                          value={type}
                          className="form-control"
                          onChange={(val) => setType(val.target.value)}
                        >
                          <option>- Pilih Tipe Mata Pelajaran -</option>
                          {types.map((val, idx) => {
                            if (val.categories_types.length > 0) {
                              return (
                                <>
                                  <optgroup label={val.type}>
                                    {val.categories_types.map((v, i) => {
                                      return (
                                        <option
                                          key={`${i}_${idx}_${v.id}`}
                                          value={v.id}
                                        >
                                          {v.type}
                                        </option>
                                      )
                                    })}
                                  </optgroup>
                                </>
                              )
                            } else {
                              return <option value={val.id}>{val.type}</option>
                            }
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-group">
                                            <label htmlFor="perentCategory">Parent</label>
                                            <select className="form-control" value={parentId} onChange={(event) => setParentId(event.target.value)} id="perentCategory">
                                                <option value="0">No parent</option>
                                                {parents.map((parent, index) => {
                                                    return (
                                                        <option key={index} value={parent.id}>{parent.title}</option>
                                                    )
                                                })}
                                            </select>
                                        </div> */}
                  <fieldset className="form-group">
                    <div className="row">
                      <label className="col-sm-4 col-xl-2 col-form-label">
                        Status
                      </label>
                      <div className="col-sm-8 col-xl-10">
                        <div className="form-check">
                          <input
                            checked={active.toString() === `true`}
                            onChange={(val) => setActive(val.target.value)}
                            className="form-check-input"
                            type="radio"
                            name="active"
                            id="active"
                            defaultValue="true"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="active"
                          >
                            Aktif
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            checked={active.toString() === `false`}
                            onChange={(val) => setActive(val.target.value)}
                            className="form-check-input"
                            type="radio"
                            name="active"
                            id="no-active"
                            defaultValue="false"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="no-active"
                          >
                            Tidak Aktif
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <br />
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <BackButton />
                      <SubmitButton
                        text="Simpan"
                        onClick={() => handleSaveCategory()}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React from 'react'

export default function LeftCol({ children }) {
  return (
    <div className="col-xl-3">
      <div
        className="card user-Messages-card"
        style={{ height: '100vh' }}
      >
        {children}
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import FormInputDraft from './form-input-draft'
import moment from 'moment'
import Modal from 'component/modal'
import { useLocation } from 'react-router-dom'
import { useBaseContext } from 'context/base'
import loadingTypes from 'globals/loading-types'

export default function RaporUserItem({
  totalColumns = 0,
  item,
  number,
  taTahun,
  categoryId,
  semester_id,
  class_id,
  period_id,
  reportGroupId,
  catatan,
  setCatatan,
  updateAt,
  setUpdateAt,
}) {
  /* ========================================== Helpers ========================================= */
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const badgesConfig = {
    submission: {
      status: 'submission',
      className: 'badge-warning',
    },
    approved: {
      status: 'approved',
      className: 'badge-success',
    },
    rejected: {
      status: 'rejected',
      className: 'badge-danger',
    },
    draft: {
      status: 'draft',
      className: 'badge-warning',
    },
  }

  /* ======================================= Local States ======================================= */
  const [loadingScoreTemp, setLoadingScoreTemp] = useState(loadingTypes.idle)
  const [isEditable, setIsEditable] = useState(false)
  const [showEditUi, setShowEditUi] = useState(false)
  const [scoreLms, setScoreLms] = useState('')
  const [scoreK, setScoreK] = useState('')
  const [scoreP, setScoreP] = useState('')
  const [predicateK, setPredicateK] = useState('')
  const [predicateP, setPredicateP] = useState('')
  const [description, setDescription] = useState('')
  const [approvalStatus, setApprovalStatus] = useState('')
  const [notes, setNotes] = useState('')
  const [badge, setBadge] = useState('')
  const [dataScore, setDataScore] = useState([])

  /* ========================================= Functions ======================================== */
  async function getScoreTemp(
    user_id,
    category_id,
    reportGroupId,
    class_id,
    semester_id,
    period_id
  ) {
    const response = await getRequest(
      `report-by-id-block?user_id=${user_id}&class_id=${class_id}&ta_semester_code=${semester_id}&ta_tahun_code=${taTahun}&period_id=${period_id}`,
    )
    setDataScore(response)
    return response ? response.data : null
  }

  function resetStates() {
    setScoreK('')
    setScoreP('')
    setPredicateK('')
    setPredicateP('')
    setDescription('')
    setApprovalStatus('no score')
  }
  const calculatePredikat = (score) => {
    score = parseInt(score)
    if (score >= 90) {
      return 'A'
    } else if (score >= 80 && score <= 89) {
      return 'B'
    } else if (score >= 70 && score <= 79) {
      return 'C'
    } else {
      return 'D'
    }
  }

  function initApproval(approvalStatus, badgesConfig, approvalNotes) {
    switch (approvalStatus) {
      case badgesConfig.approved.status:
        setBadge(badgesConfig.approved.className)
        setIsEditable(false)
        setApprovalStatus(approvalStatus)
        setNotes('-')
        break
      case badgesConfig.rejected.status:
        setBadge(badgesConfig.rejected.className)
        setIsEditable(true)
        setApprovalStatus(approvalStatus)
        setNotes(approvalNotes ? approvalNotes : '-')
        break
      case badgesConfig.draft.status:
        setBadge(badgesConfig.draft.className)
        setIsEditable(true)
        setApprovalStatus(approvalStatus)
        setNotes(approvalNotes ? approvalNotes : '-')
        break
      case badgesConfig.submission.status:
        setBadge(badgesConfig.submission.className)
        setIsEditable(false)
        setApprovalStatus('diajukan')
        setNotes(approvalNotes ? approvalNotes : '-')
        break
      default:
        setBadge('badge-secondary')
        setIsEditable(true)
        setApprovalStatus('no-score')
        setNotes('-')
        break
    }
  }

  async function initScoreData(
    item,
    reportGroupId,
    badgesConfig,
    class_id,
    semester_id,
    period_id,
  ) {
    setLoadingScoreTemp(loadingTypes.start)
    const data = await getScoreTemp(
      item.user_id,
      item.categories_id,
      reportGroupId,
      class_id,
      semester_id,
      period_id,
    )
    if (typeof data === 'object' && data !== null) {
      setScoreK(data.nilai_keterampilan)
      setScoreP(data.nilai_pengetahuan)
      initApproval(data.status, badgesConfig, '-')
    } else {
      resetStates()
      setBadge('badge-secondary')
      setIsEditable(true)
    }
    setLoadingScoreTemp(loadingTypes.finish)
  }

  useEffect(() => {
    const period_id = query.get(
      'period_id',
    )

    if (period_id) {
      initScoreData(item, reportGroupId, badgesConfig, class_id, semester_id, period_id)
    }
  }, [item])

  return (
    <>
      <tr>
        <td>{number}</td>
        <td title={item.user_nim}>{item.user_name}</td>
        {loadingScoreTemp === loadingTypes.idle && (
          <td colSpan={totalColumns - 2}></td>
        )}
        {loadingScoreTemp === loadingTypes.start && (
          <td colSpan={totalColumns - 2}>
            <div className="d-flex justify-content-center align-items-center">
              <span
                className="spinner-border text-primary"
                role="status"
                style={{
                  height: '22px',
                  width: '22px',
                }}
              />
            </div>
          </td>
        )}
        {loadingScoreTemp === loadingTypes.finish && (
          <>
            {showEditUi ? (
              <>
                <td colSpan={totalColumns - 2}>
                  <FormInputDraft
                    item={item}
                    categoryId={categoryId}
                    taTahun={taTahun}
                    semester_id={semester_id}
                    class_id={class_id}
                    period_id={period_id}
                    reportGroupId={reportGroupId}
                    badgesConfig={badgesConfig}
                    scoreLms={scoreLms}
                    scoreK={scoreK}
                    scoreP={scoreP}
                    predicateK={predicateK}
                    predicateP={predicateP}
                    description={description}
                    notes={notes}
                    setShowEditUi={setShowEditUi}
                    setScoreK={setScoreK}
                    setScoreP={setScoreP}
                    setPredicateK={setPredicateK}
                    setPredicateP={setPredicateP}
                    setDescription={setDescription}
                    initScoreData={initScoreData}
                  />
                </td>
              </>
            ) : (
              <>
                <td>{scoreLms === '' ? '-' : scoreLms}</td>
                <td>{scoreK === '' ? '-' : scoreK}</td>
                <td>{scoreK === '' ? '-' : calculatePredikat(scoreK)}</td>
                <td>{scoreP === '' ? '-' : scoreP}</td>
                <td>{scoreP === '' ? '-' : calculatePredikat(scoreP)}</td>
                <td>
                  <span className={`badge ${badge}`}>{approvalStatus}</span>
                </td>
                <td>
                  {dataScore.data?.catatan === null ? (
                    '-'
                  ) : (
                    <button
                      onClick={() => {
                        setCatatan(dataScore.data?.catatan)
                        setUpdateAt(dataScore.data?.updatedAt)
                      }}
                      data-toggle="modal"
                      data-target="#modalCatatanReject"
                      className="btn btn-warning has-ripple btn-sm"
                    >
                      Lihat Catatan
                    </button>
                  )}
                </td>
                <td>
                  {isEditable ? (
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => setShowEditUi(true)}
                    >
                      Input Draft
                    </button>
                  ) : (
                    '-'
                  )}
                </td>
              </>
            )}
          </>
        )}
      </tr>
      <Modal
        id="modalCatatanReject"
        title="Catatan Reject Rapor Period"
      >
        <div
          className="hd-body pr-2"
          style={{
            maxHeight: '350px',
            overflowY: 'auto',
          }}
        >
          <div
            key={dataScore.data ? dataScore.data.user_id : '-'}
            className="excerpt rounded mb-3"
          >
            <div className="d-flex align-items-center justify-content-between">
              <span>
                <i className="feather icon-calendar mr-1 f-14" />
                {updateAt ? moment(updateAt).format('H:MM, DD-MM-YYYY') : '-'}
              </span>
              <span className="badge badge-danger">Ditolak</span>
            </div>
            <h6 className="mb-1">Catatan:</h6>
            <p className="m-0">{catatan ? catatan : 'kosong'}</p>
          </div>
        </div>
      </Modal>
    </>
  )
}

import React from 'react'

export default function TableUserItem({
  id,
  name,
  nim,
  username,
  onShowModal = (userId) => {},
}) {
  return (
    <tr>
      <td>{name}</td>
      <td>{nim}</td>
      <td>{username}</td>
      <td>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={() => onShowModal(id)}
          data-toggle="modal"
          data-target="#modalRaporDetail"
        >
          <i className="feather icon-clipboard mr-1" />
          Rapor
        </button>
      </td>
    </tr>
  )
}

import React, { useState } from 'react'

export default function SyaratMatpel({ prerequisite }) {
  /* ======================================= Local States ======================================= */
  const [isHovered, setIsHovered] = useState(false)
  
  /* ========================================== Output ========================================== */
  return (
    <div className="mb-0">
      <div
        id="syaratAccordion"
        style={{
          backgroundColor: isHovered ? '#f6f6f6' : '#f8f9fa',
        }}
      >
        <h5 className="row m-0 border rounded">
          <a
            type="button"
            className="col row collapsed text-dark m-0 p-3"
            data-toggle="collapse"
            data-target={'#syaratItem'}
            aria-expanded="false"
            aria-controls={'syaratItem'}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="col-10 col-lg-11 pl-0">
              <span className="f-16">Syarat</span>
            </div>
            <div className="col-2 col-lg-1 d-flex justify-content-end pr-0">
              <i className="fas fa-chevron-down" />
            </div>
          </a>
        </h5>
      </div>
      <div
        id={'syaratItem'}
        className="collapse border"
        aria-labelledby={'syaratAccordion'}
        data-parent="#syaratAccordion"
      >
        <div
          className="overview-matpel-siswa p-3 mb-0"
          dangerouslySetInnerHTML={{ __html: prerequisite }}
        />
      </div>
    </div>
  )
}

import React, { useState, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { Back, Submit } from 'component/button'
import MediaNotice from 'pages/quiz/media-notice'
import Jawaban from './jawaban'
import { editorConfiguration } from 'util/ckeditor-config'

const FormAddPilihanGanda = () => {
  /* ========================================== Helpers ========================================= */
  const history    = useHistory()
  const { quizId } = useParams()

  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const questType = 0
  const links = [
    { url: '/kelola-quiz', name: 'Kelola QUiz' },
    { url: `/kelola-quiz/${quizId}`, name: 'Daftar Pertanyaan ' },
  ]

  /* ====================================== Initial States ====================================== */
  const initialStates = {
    textPertanyaan: '',
    listJawaban: [
      {
        text: '',
        correct: 0,
        active: 1,
        nilai: 0,
      },
    ]
  }
  
  /* ======================================= Local States ======================================= */
  const [textPertanyaan, setTextPertanyaan] = useState(initialStates.textPertanyaan)
  const [nomorUrut, setNomorUrut]           = useState('')
  const [status, setStatus]                 = useState(0)
  const [listJawaban, setListJawaban]       = useState(initialStates.listJawaban)
  const [pembahasan, setPembahasan]         = useState('')

  // FORM VALIDATION STATE
  const [isUrutanInvalid, setIsUrutanInvalid]                 = useState(false)
  const [isTextPertanyaanInvalid, setIsTextPertanyaanInvalid] = useState(false)
  const [isCorrectInvalid, setIsCorrectInvalid]               = useState(false)

  /* =========================================== Refs =========================================== */
  const refNomorUrut = useRef()

  /* ========================================= Functions ======================================== */
  function handleChangeUrutan(event) {
    setNomorUrut(Math.abs(event.target.value))
  }

  function handleChangeStatus(event) {
    switch (event.target.checked) {
      case true:
        setStatus(1)
        break
      case false:
        setStatus(0)
        break
      default:
        break
    }
  }

  function handleAddJawaban() {
    setListJawaban([
      ...listJawaban,
      {
        text: '',
        correct: 0,
        active: 1,
        nilai: 0,
      },
    ])
  }

  function handleDeleteJawaban(indexDelete) {
    const newJawabans = listJawaban.filter(
      (jawaban, index) => index !== indexDelete,
    )
    setListJawaban(newJawabans)
  }

  function handleSetText(indexText, value) {
    const newJawabans = listJawaban.map((jawaban, index) => {
      if (index === indexText) {
        return {
          text: value,
          correct: jawaban.correct,
          active: 1,
          nilai: jawaban.nilai,
        }
      } else {
        return {
          text: jawaban.text,
          correct: jawaban.correct,
          active: 1,
          nilai: jawaban.nilai,
        }
      }
    })
    setListJawaban(newJawabans)
  }

  function handleSetCorrect(indexCorrect) {
    const newJawabans = listJawaban.map((jawaban, index) => {
      if (index === indexCorrect) {
        return {
          text: jawaban.text,
          correct: 1,
          active: 1,
          nilai: 1,
        }
      } else {
        return {
          text: jawaban.text,
          correct: 0,
          active: 1,
          nilai: 0,
        }
      }
    })
    setListJawaban(newJawabans)
  }

  function validateCorrect() {
    let isCorrectExist = false

    if (listJawaban.length !== 0) {
      listJawaban.forEach((jawab) => {
        if (jawab.correct === 1) {
          isCorrectExist = true
        }
      })
    }

    return isCorrectExist
  }

  function handleFormValidation() {
    const isCorrect = validateCorrect()
    if (nomorUrut && textPertanyaan && isCorrect) {
      setIsUrutanInvalid(false)
      setIsTextPertanyaanInvalid(false)
      setIsCorrectInvalid(false)
      handleFormSubmit(
        quizId,
        nomorUrut,
        textPertanyaan,
        questType,
        status,
        listJawaban,
        pembahasan,
      )
    } else {
      if (!isCorrect) {
        setIsCorrectInvalid(true)
      } else {
        setIsCorrectInvalid(false)
      }

      if (!textPertanyaan) {
        setIsTextPertanyaanInvalid(true)
      } else {
        setIsTextPertanyaanInvalid(false)
      }

      if (!nomorUrut) {
        setIsUrutanInvalid(true)
        refNomorUrut.current.focus()
      } else {
        setIsUrutanInvalid(false)
      }

      window.notification('Data belum lengkap', 'Mohon lengkapi data')
    }
  }

  async function handleFormSubmit(
    quiz_id,
    urutan,
    text,
    quest_type,
    active,
    jawaban,
    pembahasan,
  ) {
    let form = new FormData()
    form.append('quiz_id', quiz_id)
    form.append('urutan', urutan)
    form.append('text', text)
    form.append('quest_type', quest_type)
    form.append('active', active)
    form.append('jawaban', JSON.stringify(jawaban))
    if (pembahasan) {
      form.append('pembahasan', pembahasan)
    }

    const response = await postRequest('questions', form)
    if (response) {
      window.notification(
        'Sukses',
        'Pertanyaan berhasil ditambahkan',
        'success',
      )
      history.goBack()
    } else {
      console.error('ERROR', response)
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Tambah Pertanyaan" links={links} />
          
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <h5 className="modal-title">
                    <i className="fas fa-list-ul mr-1" />
                    Informasi Pilihan Ganda
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <form>
                        {/* Input nomor urut */}
                        <div className="form-group mb-3 row">
                          <label
                            className="col-form-label col-sm-3"
                            htmlFor="urutan-pilihan-ganda"
                          >
                            <span>Nomor urut</span>
                            <span className="text-danger ml-1">*</span>
                          </label>
                          <div className="col-sm-3">
                            <input
                              type="number"
                              min={0}
                              value={nomorUrut}
                              onChange={(event) => handleChangeUrutan(event)}
                              ref={refNomorUrut}
                              className={`${
                                isUrutanInvalid ? 'is-invalid' : ''
                              } form-control`}
                              id="urutan-pilihan-ganda"
                              placeholder="Nomor urut"
                            />
                          </div>
                          {isUrutanInvalid ? (
                            <div className="offset-sm-3 col-sm-9">
                              <span className="col small form-text text-danger">
                                Nomor urut tidak boleh kosong.
                              </span>
                            </div>
                          ) : null}
                        </div>

                        {/* Select status pertanyaan */}
                        <div className="form-group mb-3 row">
                          <label
                            className="col-form-label col-sm-3"
                            htmlFor="status-pilihan-ganda"
                          >
                            <span className="mr-1">Status</span>
                          </label>
                          <div className="col-sm-9 form-group">
                            <div className="switch switch-primary d-inline m-r-10">
                              <input
                                onChange={(event) => handleChangeStatus(event)}
                                checked={status === 1}
                                type="checkbox"
                                id="switch-status-pilihan-ganda"
                              />
                              <label
                                htmlFor="switch-status-pilihan-ganda"
                                className="cr"
                              ></label>
                            </div>
                            <label htmlFor="switch-status-pilihan-ganda">
                              {status === 1 ? 'Aktif' : 'Tidak Aktif'}
                            </label>
                          </div>
                        </div>

                        {/* Pertanyaan pilihan ganda */}
                        <div className="form-group row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Pertanyaan
                            <span className="text-danger ml-1">*</span>
                          </label>

                          {/* input pertanyaan */}
                          <div className="col-sm-9">
                            <MediaNotice />
                            <CKEditor
                              editor={Editor}
                              data={textPertanyaan}
                              config={{...editorConfiguration, placeholder: 'Tulis pertanyaan...'}}
                              onChange={(_, editor) => {
                                const data = editor.getData()
                                setTextPertanyaan(data)
                              }}
                            />
                            {isTextPertanyaanInvalid ? (
                              <span className="col small form-text text-danger">
                                Pertanyaan tidak boleh kosong.
                              </span>
                            ) : null}

                            {/* input jawaban */}
                            <ul className="list-unstyled mt-3">
                              <label>Pilihan Jawaban</label>
                              {listJawaban.map((jawaban, index) => {
                                return (
                                  <Jawaban
                                    key={index}
                                    index={index}
                                    text={jawaban.text}
                                    correct={jawaban.correct}
                                    handleDelete={handleDeleteJawaban}
                                    handleCorrect={handleSetCorrect}
                                    handleText={handleSetText}
                                  />
                                )
                              })}
                            </ul>

                            {/* Tambah jawaban */}
                            <div className="form-group mb-3">
                              <button
                                onClick={() => handleAddJawaban()}
                                type="button"
                                className="btn btn-sm btn-primary"
                              >
                                <i className="feather icon-plus-circle mr-1" />
                                <span>Tambah Jawaban</span>
                              </button>
                            </div>

                            {isCorrectInvalid ? (
                              <span className="col small form-text text-danger">
                                Harap pilih jawaban yang benar.
                              </span>
                            ) : null}
                          </div>
                        </div>

                        {/* Input Pembahasan */}
                        <div className="form-group row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Pembahasan
                          </label>
                          <div className="col-sm-9">
                            <CKEditor
                              editor={Editor}
                              data={pembahasan}
                              config={{...editorConfiguration, placeholder: 'Masukkan pembahasan...'}}
                              onChange={(_, editor) => {
                                const data = editor.getData()
                                setPembahasan(data)
                              }}
                            />
                          </div>
                        </div>

                        {/* Form keterangan */}
                        <div className="form-group d-flex">
                          <label className="text-muted mr-2">Keterangan:</label>
                          <span className="text-danger mr-1">*</span>
                          <label className="text-muted">Wajib diisi</label>
                        </div>

                        {/* Form button */}
                        <div className="form-group">
                          <Back />
                          <Submit
                            onClick={handleFormValidation}
                            text="Simpan"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Container>
    </TemplateAdmin>
  )
}

export default FormAddPilihanGanda

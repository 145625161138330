import React from 'react'

const FormInputDesc = ({ description, handleChangeDesc }) => {
  return (
    <div className="form-group mb-5 row">
      <label
        className="col-sm-3 col-form-label"
        htmlFor="deskripsiKonten"
      >
        <span className="mr-1">Instruksi</span>
      </label>
      <div className="col-sm-9">
        <textarea
          value={description}
          onChange={(event) => handleChangeDesc(event)}
          type="text"
          className={`form-control`}
          id="deskripsiKonten"
          placeholder="Instruksi materi (opsional)"
        />
      </div>
    </div>
  )
}

export default FormInputDesc

import localforage from 'localforage'
import { useEffect, useState } from 'react'

export function useStaleObj(initialValue, key) {
  /* ======================================= Local States ======================================= */
  const [loading, setLoading]     = useState(false)
  const [data, setData]           = useState(null)
  const [isDrafted, setIsDrafted] = useState(false)

  /* ========================================= Functions ======================================== */
  async function invalidate() {
    await localforage.removeItem(key)
    const initial = await localforage.getItem(key + '_initial')
    const parsed = JSON.parse(initial)
    if (Array.isArray(parsed)) {
      setData([...parsed])
    } else {
      setData({ ...parsed })
    }
    setIsDrafted(false)
  }

  function stale(newValue) {
    if (!newValue || newValue === initialValue) {
      invalidate()
    } else {
      localforage.setItem(key, JSON.stringify(newValue)).then(() => {
        setData(newValue)
        setIsDrafted(true)
      })
    }
  }

  async function getStaledData() {
    setLoading(true)
    const cached = await localforage.getItem(key)
    setLoading(false)
    if (cached) {
      setIsDrafted(true)
      setData(JSON.parse(cached))
    } else {
      await localforage.setItem(key + '_initial', JSON.stringify(initialValue))
      setData(initialValue)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getStaledData()
  }, [])

  /* ========================================== Output ========================================== */
  return {
    loading,
    data,
    isDrafted,
    invalidate,
    stale,
  }
}

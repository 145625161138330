import moment from 'moment/moment'
import React from 'react'
import Modal from 'component/modal'
import useQuizEval from 'context/quiz-eval'
import { replaceMathTags } from 'util/string'
import CkeditorReadonly from 'component/ckeditor-readonly'

export default function QuizEvalModal() {
  /* ====================================== Consume Context ===================================== */
  const { quiz } = useQuizEval()

  /* ========================================== Output ========================================== */
  return (
    <Modal
      id="modalHistoryAssignment"
      title="Riwayat Penolakan"
    >
      <div
        className="hd-body pr-2"
        style={styles.container}
      >
        {quiz.history.data ? (
          quiz.history.data.map((history, index) => (
            <div
              key={index}
              className="excerpt rounded mb-3"
            >
              <div className="d-flex align-items-center justify-content-between">
                <span>
                  <i className="feather icon-calendar mr-1 f-14" />
                  {moment(history.history.tanggal_tolak).format('HH:mm:ss, DD-MM-YYYY')}
                </span>
              </div>
              <h6 className="my-1">Catatan:</h6>
              {history?.history?.note ? <CkeditorReadonly data={history?.history?.note} /> : <p>-</p>}
            </div>
          ))
        ) : (
          <p>Tidak ada data</p>
        )}
      </div>
    </Modal>
  )
}

const styles = {
  container: {
    maxHeight: '350px',
    overflowY: 'auto',
  },
}

import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useContentContainerContext from 'context/content-container'
import ContentType from 'component/content/content-type'
import BadgeProgress from '../player/badge-progress'

const ContentEnabledSeq = ({ allContents }) => {
  /* ====================================== Consume Context ===================================== */
  const { content, classId, sectionUrutan } = useContentContainerContext()

  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ========================================== States ========================================== */
  const [isHovered, setIsHovered] = useState(false)

  // ACTIVITIES STATE
  const [contentActivities, setContentActivities] = useState([])
  const [isCompleted, setIsCompleted]             = useState(false)

    // DISPLAY STATE
  const [isDisabled, setIsDisabled]         = useState(true)
  const [isFirstContent, setIsFirstContent] = useState(false)

  /* ========================================= Functions ======================================== */
  function goToContentPlayer() {
    history.push(`/katalog-kelas/${classId}/content-player`, {
      dataContent: content,
      classId: classId,
    })
  }

  function checkIsCompleted() {
    const _isCompleted = contentActivities.some((ca) => ca.completed_at)
    _isCompleted && setIsCompleted(true)
  }

  function checkDisabled() {
    const indexFromAllContents = allContents.findIndex(
      (ac) => ac.id === content.id,
    )

    if (indexFromAllContents) {
      const indexContentBefore = indexFromAllContents - 1
      const contentBefore = allContents[indexContentBefore]
      const isAssignmentOrQuiz = [6, 8].includes(contentBefore.type_id)

      // handle content sebelumnya tipe tugas/quiz sudah disubmit maka bisa lanjut
      isAssignmentOrQuiz &&
        (contentBefore.isSubmitted || contentBefore.isEvaluated) &&
        setIsDisabled(false)

      // handle content sebelumnya selain tipe tugas/quiz kalau sudah selesai maka bisa lanjut
      !isAssignmentOrQuiz && contentBefore.isCompleted && setIsDisabled(false)
    } else {
      setIsDisabled(false)
    }
  }

  function checkFirstContent() {
    if (sectionUrutan === 1 && content.urutan === 1) {
      setIsFirstContent(true)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    setContentActivities(content.class_content_activities)
  }, [])

  useEffect(() => {
    checkIsCompleted()
    checkDisabled()
  }, [contentActivities])

  useEffect(() => {
    checkDisabled()
  }, [allContents])

  useEffect(() => {
    checkFirstContent()
  }, [sectionUrutan])

  /* ========================================== Output ========================================== */
  return (
    <li>
      {isDisabled && !isFirstContent ? (
        <div
          title="Anda tidak memiliki akses"
          className="btn-forbidden p-3 f-16 text-left text-dark border-bottom"
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            opacity: '0.65',
          }}
        >
          <div className="row">
            <div className="col-10 col-sm-11">
              <span className={`text-dark d-block f-16`}>
                {content.urutan}
                {'. '}
                {content.title}
                <i className="fas fa-ban ml-1 text-danger" />
              </span>
              <span className="d-block">
                <ContentType id={content.content_type_id} /> <BadgeProgress />
                {content.durasi ? (
                  <span className="f-12 text-muted ml-2">
                    <i className="feather icon-clock f-10 mr-1" />
                    {Math.round(content.durasi / 60)} menit
                  </span>
                ) : null}
              </span>
            </div>
            <div className="col-2 col-sm-1 d-flex justify-content-center">
              <div className="custom-control custom-checkbox mt-1">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={isCompleted}
                  disabled={false}
                  onChange={() => {}}
                />
                <label className="custom-control-label" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <button
          onClick={goToContentPlayer}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          type="button"
          className="btn btn-sm p-3 f-16 text-left text-dark"
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: isHovered
              ? 'rgba(221, 221, 221, 0.2)'
              : 'transparent',
          }}
        >
          <div className="row">
            <div className="col-10 col-sm-11">
              <span className={`text-dark d-block f-16`}>
                {content.urutan}
                {'. '}
                {content.title}
              </span>
              <span className="d-block">
                <ContentType id={content.content_type_id} /> <BadgeProgress />
                {content.durasi ? (
                  <span className="f-12 text-muted ml-2">
                    <i className="feather icon-clock f-10 mr-1" />
                    {Math.round(content.durasi / 60)} menit
                  </span>
                ) : null}
              </span>
            </div>
            <div className="col-2 col-sm-1 d-flex justify-content-center">
              <div className="custom-control custom-checkbox mt-1">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={isCompleted}
                  disabled={false}
                  onChange={() => {}}
                />
                <label className="custom-control-label" />
              </div>
            </div>
          </div>
        </button>
      )}
    </li>
  )
}

export default ContentEnabledSeq

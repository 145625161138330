import React, { useEffect, useState } from 'react'
import InputCardDescriptionProject from './input-card-description-project'
import ModalInputDimentionElemenSubElemenProject from './modal-input-dimenstion-elemen-subelemen-project'
import useInputKindness from 'context/input-kindness'
import LoadingSpinner from 'component/loading'
import BaseButton from 'component/button/BaseButton'
import useGetRoleActive from 'hooks/useGetRoleActive'

const DeskripsiProyek = ({ selectedClass, selectedSemester }) => {
  /* ----------------------------- CONTEXT & STATE ---------------------------- */
  const { isLoadingListKindness, listKindness, kindnessConfig, handlers } = useInputKindness();
  const roleActive = useGetRoleActive();

  const [_, setRerenderCount] = useState(0);
  const [descriptionProjectData, setDescriptionProjectData] = useState([])
  const [selectedProject, setSelectedProject] = useState({ descriptionProjectData: [], index: 0, title: null, description: null })
  const [isShowModalInputDimention, setIsShowModalInputDimention] = useState(false)

  /* -------------------------------- CONSTANT -------------------------------- */
  const isRoleAdminOrHeadmaster = roleActive.type === 'administrator' || roleActive.type === 'kepalasekolah'


  /* -------------------------------- HANDLERS -------------------------------- */
  const onSaveProjectDescriptionHandler = async (e) => {
    e.preventDefault();

    const data = Array.from({ length: kindnessConfig.totalInputProjects }).map((_, index) => {
      let necessaryData = {};

      necessaryData.title = descriptionProjectData?.[index]?.title;
      necessaryData.description = descriptionProjectData?.[index]?.description;
      necessaryData.group_id = selectedClass?.value || null;
      necessaryData.ta_semester_code = selectedSemester?.value || null;

      necessaryData.proyek_type_scores = [];

      descriptionProjectData?.[index]?.proyek_type_scores.forEach(p => {
        if (p?.dimensi_id) {
          necessaryData.proyek_type_scores.push({
            dimensi_id: p.dimensi_id,
            elemen_id: p.element_id,
            sub_elemen_id: p.sub_element_id,
            capaian_id: p.capaian_id,
          })
        }
      });

      return necessaryData;
    });

    await handlers.saveProjectDescription.handler(data);
  }

  useEffect(() => {
    setDescriptionProjectData(listKindness)
    setRerenderCount(prevState => prevState + 1)
    setSelectedProject({ descriptionProjectData: [], index: 0 });
  }, [listKindness, setDescriptionProjectData])


  /* --------------------------------- RENDER -------------------------------- */
  if (isLoadingListKindness && Array.isArray(listKindness) && listKindness.length === 0) {
    return <div className='mb-4'>
      <LoadingSpinner />
    </div>
  } else if (!isLoadingListKindness && Array.isArray(listKindness) && listKindness.length) {
    return (
      <div className="card">
        <div className="card-body">
          <span className="f-w-700 f-14">Deskripsi Proyek</span>
          <form className="d-block mt-4" onSubmit={onSaveProjectDescriptionHandler}>
            <div className='row'>
              {Array.from({ length: kindnessConfig?.totalInputProjects || 1 }).map((_, index) => (
                <div className='col-md-4' key={`input-card-proyek-index${index}`}>
                  <InputCardDescriptionProject index={index} setDescriptionProjectData={setDescriptionProjectData} descriptionProjectData={descriptionProjectData} setSelectedProject={setSelectedProject} setIsShowModalInputDimention={setIsShowModalInputDimention} setRerenderCount={setRerenderCount} />
                </div>
              ))}
              <div className='col-md-12'>
                <div className='d-flex justify-content-end'>
                  <BaseButton isShow={isRoleAdminOrHeadmaster ? false : true} type='submit' variant='success' access={['instruktur']} isLoading={handlers.saveProjectDescription.loading} sizeLoading={1.5}>Simpan</BaseButton>
                </div>
              </div>
            </div>

            <ModalInputDimentionElemenSubElemenProject selectedProject={selectedProject} show={isShowModalInputDimention} handleClose={() => setIsShowModalInputDimention(false)} setDescriptionProjectData={setDescriptionProjectData} setRerenderCount={setRerenderCount} />
          </form>
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export default DeskripsiProyek
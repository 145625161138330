import React from 'react'
import { noImage } from 'util/constant'
import LoadingSpinner from 'component/loading'

export const Spinner = () => (
  <span
    className="spinner-border spinner-border-sm mr-1"
    role="status"
    style={{
      height: '12px',
      width: '12px',
    }}
  />
)

export default function ClassOverviewLoading({ coverStyles, windowWidth }) {
  return (
    <>
      <div className="row mb-3">
        <div className="col-12 mb-2">
          <div className="mb-2 d-flex flex-row align-items-center">
            <span
              className="spinner-border spinner-border-sm mr-3"
              role="status"
              style={{
                height: '18px',
                width: '18px',
              }}
            />
            <h3 className="mb-0">Memuat...</h3>
          </div>
        </div>
        <div className="col-md-4 col-xl-3 mb-3 d-flex justify-content-center">
          <img
            src={noImage}
            className="img-fluid rounded bg-light"
            style={coverStyles}
            alt="My Class"
          />
        </div>
        <div className="col-md-8 mb-3">
          <div className="d-flex align-items-center mb-2">
            <i className="fas fa-chart-line mr-2" />
            <span className="d-flex align-items-center w-100">
              Progress:{' '}
              <span className="progress ml-1 w-100 hei-20">
                <div
                  className="progress-bar"
                  style={{ width: `100%`, backgroundColor: '#ecf0f5' }}
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <span
                    className="text-dark"
                    style={{ fontSize: '12px', fontWeight: 'bold' }}
                  >
                    <Spinner />
                  </span>
                </div>
              </span>
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="feather icon-tag mr-2" />
            <span className="mr-2">Mata Pelajaran:</span>
            <Spinner />
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fas fa-chalkboard-teacher mr-1" />
            <span className="mr-2">Guru:</span>
            <Spinner />
          </div>
          <div className="mb-2">
            <button
              className="btn btn-sm btn-secondary"
              disabled={true}
              style={{
                width: windowWidth <= 1024 ? '100%' : '150px',
              }}
            >
              <Spinner />
              Preview
            </button>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <span className="nav-link text-uppercase text-secondary active">
                Informasi
              </span>
            </li>
            <li className="nav-item">
              <span className="nav-link text-uppercase text-secondary">
                Materi
              </span>
            </li>
            <li className="nav-item">
              <span className="nav-link text-uppercase text-secondary">
                Forum Diskusi
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col fade active show">
          <LoadingSpinner />
        </div>
      </div>
    </>
  )
}

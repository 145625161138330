import React, { useState } from 'react'
import moment from 'moment'
import CkeditorReadonly from 'component/ckeditor-readonly'
import CkeditorComponent from 'component/ckeditor'
import useContentPlayerContext from 'context/content-player'

export default function CardNotes({ item, index }) {
  /* ====================================== Consume Context ===================================== */
  const { notes } = useContentPlayerContext()

  /* ========================================== States ========================================== */
  const [editNote, setEditNote]         = useState(false)
  const [editNoteData, setEditNoteData] = useState(item.note)

  /* ========================================== Output ========================================== */
  return (
    <div
      className="card rounded"
      style={styles.card}
    >
      <div
        className="card-header d-flex justify-content-between align-items-center"
        style={styles.header}
      >
        <span className="f-w-700 f-14">
          <i className="fas fa-thumbtack text-dark mr-1" /> Catatan #{index}
        </span>
        <div>
          {editNote ? (
            <>
              <button
                className="btn btn-sm btn-primary has-ripple mr-2"
                type="button"
                onClick={() => {
                  notes.edit(item, editNoteData)
                  setEditNote(false)
                }}
              >
                <i className="fas fa-save" />
              </button>
              <button
                className="btn btn-sm btn-danger has-ripple"
                type="button"
                onClick={() => setEditNote(false)}
              >
                <i className="fas fa-times" />
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-sm btn-info has-ripple mr-2"
                type="button"
                onClick={() => setEditNote(true)}
              >
                <i className="fas fa-edit" />
              </button>
              <button
                className="btn btn-sm btn-danger has-ripple"
                type="button"
                onClick={() => notes.delete(item.id)}
              >
                <i className="fas fa-trash" />
              </button>
            </>
          )}
        </div>
      </div>

      <div className="card-body">
        {editNote ? (
          <CkeditorComponent
            data={editNoteData}
            onChange={(data) => setEditNoteData(data)}
          />
        ) : (
          <CkeditorReadonly data={item.note} />
        )}
      </div>

      <div
        className="card-footer text-muted"
        style={styles.footer}
      >
        <p className="text-right f-w-700 f-12">
          {editNote ? (
            <>
              <i className="fas fa-edit mr-1" />
              Sedang mengedit...
            </>
          ) : (
            <>
              <i className="fas fa-calendar-alt mr-1" />
              {moment(item.updatedAt).format('dddd, DD MMMM YYYY')} /
              <i className="fas fa-clock ml-1 mr-1" />
              {moment(item.updatedAt).format('HH:mm')}
            </>
          )}
        </p>
      </div>
    </div>
  )
}

/* =========================================== Styles =========================================== */
const styles = {
  card: {
    border: '1px solid #ccced1',
    paddingBottom: '0px',
  },
  header: {
    background: '#dedede',
    paddingTop: '7px',
    paddingBottom: '7px',
    borderBottom: '1px solid #ccced1',
  },
  footer: {
    paddingBottom: '0px',
  },
}

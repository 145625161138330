import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useBaseContext } from 'context/base'

function InputRaporAllType7({
  userId,
  reportGroupId,
  response,
}) {
  /* ========================================== Helpers ========================================= */
  const params = useParams()

  /* ===================================== Consume Context ==================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= States ========================================= */
  const [res, setRes] = useState(null)
  const [isShowGraduationDescription, setIsShowGraduationDescription] = useState(false)

  /* ======================================== Functions ======================================= */
  async function getValue() {
    let us = localStorage.getItem('user')
    let usj = JSON.parse(us)
    var rsp = await getRequest(
      `get-value-score-by-group?user_id=${userId}&group_id=${reportGroupId}&semester_code=${params.semester}`,
    )

    if (rsp) {
      var ss = {}
      if (rsp.data) {
        var d = rsp.data
        ss = {
          user_id: d.user_id,
          categories_id: d.categories_id,
          categories_type_id: d.categories_type_id,
          report_group_id: d.report_group_id,
          createdBy: d.createdBy,
          updatedBy: d.updatedBy,
          value: JSON.parse(d.value) || false,
          ta_semester_code: d.ta_semester_code,
          profile_id: d.profile_id,
        }
        setRes(ss)
        setIsShowGraduationDescription(ss.value)
      } else {
        ss = {
          user_id: userId,
          categories_id: null,
          categories_type_id: null,
          report_group_id: reportGroupId,
          createdBy: usj.id,
          updatedBy: usj.id,
          value: false,
          ta_semester_code: params.semester,
          profile_id: usj.profile_id,
        }
        setRes(ss)
      }
      response(ss)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getValue()
  }, [])

  /* ========================================== Output ========================================== */
  return res && <InputCatatanWaliKelas />

  /* ======================================== Components ======================================== */
  function InputCatatanWaliKelas() {
    const onChangeHandler = () => {
      setIsShowGraduationDescription(prevState => !prevState)
    }

    useEffect(() => {
      res.value = isShowGraduationDescription
      response(res)
    }, [isShowGraduationDescription]);

    return (
      <div style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>
        <div className="custom-control custom-switch">
          <input type="checkbox" className="custom-control-input" id="customSwitch1" onChange={onChangeHandler} checked={isShowGraduationDescription} />
          <label className="custom-control-label" htmlFor="customSwitch1">{isShowGraduationDescription ? 'Ya' : 'Tidak'}</label>
        </div>
      </div>
    )
  }
}

export default React.memo(InputRaporAllType7)
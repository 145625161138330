import React from 'react'
import Swal from 'sweetalert2'
import { useBaseContext } from 'context/base'
import { openUrlNewTab } from 'util/global'

export default function ModalRaporTable({ tableConfig, detailRapor }) {
  return (
    <div className="rounded dt-responsive table-responsive">
      <table className="table nowrap">
        <tbody>
          <tr>
            {tableConfig.headers.map((header, index) => (
              <th
                key={index}
                style={{ borderTop: 'none' }}
              >
                {header}
              </th>
            ))}
          </tr>
          {detailRapor.semesters.map((semester, index) => (
            <ModalRaporTableItem
              key={index}
              number={index + 1}
              semester={semester}
              userId={detailRapor.user.id}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

const ModalRaporTableItem = ({ semester, number, userId }) => {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= Functions ======================================== */
  const handleGetRapor = async (code, userId) => {
    Swal.fire({
      title: 'Download rapor...',
      html: `<div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>`,
      allowOutsideClick: false,
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
    })
    const response = await getRequest(
      `check-file-rapor?user_id=${userId}&semester_code=${code}`,
    )
    if (response) {
      const pathBase64 = response.data.downloadPath
      const url = `${pathBase64}`
      openUrlNewTab(url)
      Swal.close()
    } else {
      Swal.fire({
        html: `
          <div>
            <i class="feather icon-clipboard f-64"></i>
            <p class="mb-0 mt-3 f-18 f-w-700">Maaf, rapor belum tersedia</p>
          </div>
        `,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Tutup',
      })
    }
  }

  const handleGetRaporKindness = async (code, userId) => {
    Swal.fire({
      title: 'Download rapor...',
      html: `<div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>`,
      allowOutsideClick: false,
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
    })
    const response = await getRequest(
      `check-file-rapor-p5?user_id=${userId}&semester_code=${code}`,
    )
    if (response) {
      const pathBase64 = response.data.downloadPath
      const url = `${pathBase64}`
      openUrlNewTab(url)
      Swal.close()
    } else {
      Swal.fire({
        html: `
          <div>
            <i class="feather icon-clipboard f-64"></i>
            <p class="mb-0 mt-3 f-18 f-w-700">Maaf, rapor belum tersedia</p>
          </div>
        `,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Tutup',
      })
    }
  }

  return (
    <tr>
      <td>{number}</td>
      <td>{semester.titleRender}</td>
      <td>
        {semester.isAvailable ? (
          <>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => handleGetRapor(semester.code, userId)}
            >
              <i className="fas fa-eye mr-1" />
              Lihat Rapor
            </button>

            <button
              type="button"
              className="btn btn-sm btn-warning"
              onClick={() => handleGetRaporKindness(semester.code, userId)}
            >
              <i className="fas fa-eye mr-1" />
              Raport Proyek
            </button>
          </>
        ) : (
          <button
            type="button"
            className="btn btn-sm btn-secondary btn-forbidden"
            disabled={true}
          >
            <i className="fas fa-eye mr-1" />
            Lihat Rapor
          </button>
        )}
      </td>
    </tr>
  )
}

import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import BaseButton from 'component/button/BaseButton';
import { useState, useEffect } from 'react';
import { readonlyEditorConfigV2 } from 'util/ckeditor-config';
import $ from 'jquery';
import moment from 'moment';

export default function CkeditorReadonlyV3({ text, showActionImage = false, answer }) {
  const [imageIndex, setImageIndex] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);  
  const [totalImage, setTotalImage] = useState(0);

  useEffect(() => {
    const tagA = document.querySelectorAll('.containerCkeditorReadonly2 a');
    tagA.forEach(link => link.setAttribute('contenteditable', 'false'));

    const tagIMG = document.querySelectorAll('.containerCkeditorReadonly2 .image .ck-widget .ck-widget_with-resizer');
    tagIMG.forEach(el => el.setAttribute('contenteditable', 'false'));

    $('#scaleRange').on('input', function () {
      const scaleValue = $(this).val();
      setScale(scaleValue);
    });
  }, [imageIndex]);

  useEffect(() => {
    const $selectedImage = $('#ckeditor-5-v3 figure');
    setTotalImage($selectedImage.length);

    const $spesificImage = $selectedImage.eq(imageIndex - 1);
    $spesificImage.css('transform', `rotate(${rotation}deg) scale(${scale})`);
  }, [rotation, scale, imageIndex]);

  function rotateImage(degree) {
    setRotation((prevRotation) => prevRotation + degree);
  }

  function downloadImage() {
    const selectedImage = $('#ckeditor-5-v3 figure img').eq(imageIndex - 1);
    const imageUrl = selectedImage.attr('src');

    if (imageUrl) {
      const link = document.createElement('a');
      link.href = imageUrl;
      link.download = `${answer?.user ? `${answer?.user?.name}-${imageIndex}_${moment().format('DD-MMM-YYYY')}.jpg` : `image-${imageIndex}.jpg`}`; // Ganti format sesuai kebutuhan
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("Gambar tidak ditemukan untuk diunduh!");
    }
  }

  function downloadAllImages() {
    const images = $('#ckeditor-5-v3 figure img');
    if (images.length === 0) {
      alert("Tidak ada gambar yang tersedia untuk diunduh!");
      return;
    }
    
    images.each((index, img) => {
      const imageUrl = $(img).attr('src');
      const link = document.createElement('a');
      link.href = imageUrl;
      link.download = `${answer?.user ? `${answer?.user?.name}-${index + 1}_${moment().format('DD-MMM-YYYY')}.jpg` : `image-${index + 1}.jpg`}`; // Ganti format sesuai kebutuhan
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  useEffect(() => {
    if (totalImage > 0) {
      $('#action-image').removeClass('d-none').addClass('d-flex')
    }
  }, [totalImage])

  return (
    <div id="ckeditor-5-v3">
      {
        showActionImage
        ?
        <>
          <div id="action-image" className='d-none align-items-center flex-column justify-content-center mb-2' style={{ gap: 10 }}>
            <div>
              <h6>Rotasi dan Ubah ukuran gambar</h6>
            </div>
            <div className='d-flex'>
              {[90].map(deg => (
                <BaseButton key={deg} onClick={() => rotateImage(deg)} props={{ className: 'btn-sm btn-secondary' }}>
                  <i className="icon feather icon-rotate-cw" />
                </BaseButton>
              ))}
            </div>
            <div>
              <label htmlFor="scaleRange" className='mr-2'>Ukuran Gambar:</label>
              <input
                style={{ verticalAlign: 'middle' }}
                type="range"
                id="scaleRange"
                min="0.1"
                max="3"
                step="0.1"
                defaultValue="1"
              />
            </div>
            <div>
              <label htmlFor='index-image'>Gambar ke-</label>
              <input
                id='index-image'
                type="number"
                min="1"
                max={`${totalImage || 1}`}
                value={imageIndex}
                onChange={(e) => setImageIndex(+e.target.value)}
              />
              <span className={`${totalImage > 0 ? 'text-success': 'text-danger'}`}>(total {totalImage})</span>
            </div>
            <div className='d-flex'>
              <BaseButton onClick={downloadImage} props={{ className: 'btn-sm btn-warning mr-2' }}>
                Download Gambar Terpilih
              </BaseButton>
              <BaseButton onClick={downloadAllImages} props={{ className: 'btn-sm btn-success' }}>
                Download Semua Gambar
              </BaseButton>
            </div>
          </div>
          <hr />
          </>
        : null
      }
      <div className="containerCkeditorReadonly2">
        <CKEditor
          editor={Editor}
          data={text}
          config={readonlyEditorConfigV2}
          isDisabled={true}
          disabled={true}
          isReadOnly={true}
          readOnly={true}
        />
      </div>
    </div>
  );
}

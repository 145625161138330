import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { useBaseContext } from 'context/base'

export default function GroupDetailWali({ idGroup }) {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [options, setOptions] = useState([])
  const [wali, setWali]       = useState(null)
  const [name, setName]       = useState('')
  const [select, setSelect]   = useState(true)

  /* ========================================= Functions ======================================== */
  async function getTeachers() {
    let response = await getRequest('teachers-wali')
    if (response) {
      var opt = []
      response.data.map((val) => {
        var a = {
          value: val.id,
          label: `${val.name} (${val.nim})`,
        }
        opt.push(a)
      })
      setOptions(opt)
    }
  }

  async function groupById() {
    let response = await getRequest('groups?id=' + idGroup)
    if (response) {
      var wl = response.data.groups_walis
      if (wl.length > 0) {
        setWali(wl[0].user.id)
        setName(`${wl[0].user.name} (${wl[0].user.nim})`)
        setSelect(false)
      }
    }
  }

  async function saveWaliKelas() {
    Swal.fire({
      title: 'Konfirmasi',
      text: 'Apakah anda ingin set guru ini sebagai wali kelas?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        var form = new FormData()
        form.append('user_id', wali)
        form.append('group_id', idGroup)
        let response = await postRequest('wali', form)
        if (response) {
          Swal.fire('Berhasil', 'Berhasil set!', 'success')
          setSelect(false)
          groupById()
        }
      }
    })
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getTeachers()
    groupById()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div>
      <form>
        <div className="form-group row">
          <label
            htmlFor="inputEmail3"
            className="col-sm-2 col-form-label"
          >
            Wali Kelas
          </label>
          <div className="col-sm-3">
            {select ? (
              <Select
                options={options}
                onChange={(val) => {
                  setWali(val.value)
                  setName(val.label)
                }}
              />
            ) : (
              <>
                <label>{name}</label>{' '}
                <button
                  onClick={() => setSelect(true)}
                  className={'btn btn-primary btn-sm'}
                >
                  Ubah
                </button>
              </>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label
            htmlFor="inputEmail3"
            className="col-sm-2 col-form-label"
          ></label>
          <div className="col-sm-3">
            <button
              type="button"
              onClick={() => {
                if (name != '' && select == true) {
                  setSelect(false)
                } else {
                  history.goBack()
                }
              }}
              className="btn btn-outline-danger has-ripple"
            >
              Kembali
              <span
                className="ripple ripple-animate"
                style={{
                  height: '87.8281px',
                  width: '87.8281px',
                  animationDuration: '0.7s',
                  animationTimingFunction: 'linear',
                  background: 'rgb(255, 255, 255)',
                  opacity: '0.4',
                  top: '-26.9062px',
                  left: '-13.3125px',
                }}
              />
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              type="button"
              onClick={() => {
                saveWaliKelas()
              }}
              className="btn btn-success has-ripple"
            >
              Simpan
              <span
                className="ripple ripple-animate"
                style={{
                  height: '90.8281px',
                  width: '90.8281px',
                  animationDuration: '0.7s',
                  animationTimingFunction: 'linear',
                  background: 'rgb(255, 255, 255)',
                  opacity: '0.4',
                  top: '-26.4062px',
                  left: '0.65625px',
                }}
              />
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

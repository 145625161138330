import React, { useEffect, useState, useRef } from 'react'
import useContentPlayerContext from 'context/content-player'
import useContentContainerContext from 'context/content-container'
import ContentType from 'component/content/content-type'
import BadgeProgress from './badge-progress'

const ContentDisabled = ({ title, isExpire, precontentMessage }) => {
  /* ====================================== Consume Context ===================================== */
  const { activity } = useContentPlayerContext()
  const { content }  = useContentContainerContext()

  /* =========================================== Refs =========================================== */
  const refContent = useRef()

  /* ======================================= Local States ======================================= */
  const [contentActivities, setContentActivities] = useState([])
  const [isCompleted, setIsCompleted] = useState(false)

  /* ========================================= Functions ======================================== */
  function checkIsCompleted() {
    contentActivities.forEach((activity) => {
      if (activity.completed_at !== null) {
        setIsCompleted(true)
      }
    })
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    setContentActivities(content.class_content_activities)
  }, [])

  useEffect(() => {
    checkIsCompleted()
  }, [contentActivities])

  useEffect(() => {
    if (activity.idDone === content.id) {
      setIsCompleted(true)
    }
  }, [activity.idDone])

  /* ========================================== Output ========================================== */
  return (
    <li ref={refContent}>
      <div
        className="p-3 f-16 text-left text-dark btn-forbidden border-bottom"
        title="Anda tidak memiliki akses"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
          opacity: '0.65',
        }}
      >
        <div className="row">
          <div className="col-2 mr-0 pr-0">
            <div className="custom-control custom-checkbox mt-1">
              <input
                type="checkbox"
                className="custom-control-input"
                checked={isCompleted}
                disabled={false}
                onChange={() => {}}
              />
              <label className="custom-control-label" />
            </div>
          </div>
          <div className="col-10 pl-0 ml-0">
            <span className={`text-dark d-block f-16`}>
              {content.urutan}
              {'. '}
              {content.title}
            </span>
            <span className="d-block">
              <ContentType id={content.content_type_id} /> <BadgeProgress />
              {content.durasi ? (
                <span className="f-12 text-muted ml-2">
                  <i className="feather icon-clock f-10 mr-1" />
                  {Math.round(content.durasi / 60)} menit
                </span>
              ) : null}
            </span>
            {title ? (
              <span
                style={{ fontSize: '10px' }}
                className={`${
                  isExpire ? 'badge-danger' : 'badge-warning'
                } badge mr-2`}
              >
                {title}
              </span>
            ) : null}
            {precontentMessage ? (
              <span
                style={{ fontSize: '10px' }}
                className={`badge-warning badge`}
              >
                {precontentMessage}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </li>
  )
}

export default ContentDisabled

import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { editorConfiguration } from 'util/ckeditor-config'

/**
 * Form Input Materi Props
 * 
 * @param {BaseContentFormProps} props
 */
export default function FormInputMateri({ 
  value, 
  setValue, 
  isInvalid, 
  isRequired, 
  errorMessage
}) {
  return (
    <div className="form-group mb-5 row">
      <label className="col-sm-3 col-form-label">
        <span className="mr-1">Materi</span>
        {isRequired
        ?
          <span className="text-danger">*</span>
        : null }
      </label>
      <div className="col-sm-9">
        <CKEditor
          editor={Editor}
          data={value}
          config={editorConfiguration}
          onChange={(_, editor) => {
            /**
             * @type string
             */
            const data = editor.getData()
            setValue(data)
          }}
        />
        {isInvalid ? (
          <label className="small form-text text-danger">
            {errorMessage || 'Materi tidak boleh kosong.'}
          </label>
        ) : null}
      </div>
    </div>
  )
}
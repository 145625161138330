import React, { useEffect, useState, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import YouTube from 'react-youtube'
import { useDropzone } from 'react-dropzone'

import { useBaseContext } from 'context/base'
import { Container, Header } from 'component/template'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import InvalidText from 'component/invalid/text'
import { getBase64 } from 'util/base64'
import {
  baseStyle,
  activeStyle,
  acceptStyle,
  rejectStyle,
  thumbsContainer,
  thumb,
  thumbInner,
  img,
} from './dropzone-css'
import CkeditorComponentV2 from 'component/ckeditor/indexV2'
import OverviewContentsCreator from './overview-contents-creator'
import DataTableGroupInstructor from 'component/group-teacher/datatable-group-instructor'

export default function KelasEdit() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const location = useLocation()
  const dataKelas = location.state.dataKelas
  
  /* ====================================== Consume Context ===================================== */
  const { getRequest, putRequest } = useBaseContext()

  /* ====================================== Initial States ====================================== */
  const initialStates = {
    category: `${
      dataKelas.category
        ? dataKelas.category.id
        : dataKelas.class_profiles[0].category
        ? dataKelas.class_profiles[0].category.id
        : ''
    }`,
    image: dataKelas.linkImg ? dataKelas.linkImg: null,
    postVideo: {
      name: 'data lama',
      data: null,
      source: 'internal',
    },
    preClassId: dataKelas.preclass_id ? dataKelas.preclass_id : '',
    displayAt: dataKelas.display_at ? dataKelas.display_at : '',
    showUnit: dataKelas.show_units ? dataKelas.show_units : ''
  }

  /* ======================================= Local States ======================================= */
  const [files, setFiles]                  = useState([])
  const [allCategories, setAllCategories]  = useState([])
  const [allIdClass, setAllIdClass]        = useState([])
  const [youtube, setYoutube]              = useState('')
  const [previewYutube, setPreviewYoutube] = useState('')
  const [internal, setVideo]               = useState(null)
  const [dateNow, setDateNow]              = useState(null)

  //================ STATE FORMS ===================
  const [name, setName]                 = useState(dataKelas.title)
  const [description, setDesc]          = useState(dataKelas.description)
  const [publicStatus, setStatus]       = useState(`${dataKelas.public_status}`)
  const [image, setImage]               = useState(initialStates.image)
  const [categoryId, setCategory]       = useState(initialStates.category)
  const [categoryName, setCategoryName] = useState('')
  const [prices, setPrices]             = useState('')
  const [postVideo, setPostVideo]       = useState(initialStates.postVideo)
  const [prerequisite, setPrerequisite] = useState(dataKelas.prerequisite)
  const [preClassId, setPreClass]       = useState(initialStates.preClassId)
  const [preClassName, setPreClassName] = useState('')
  const [displayAt, setDisplayAt]       = useState(initialStates.displayAt)
  const [showUnit, setShowUnit]         = useState(initialStates.showUnit)
  const [showUnitName, setShowUnitName] = useState('')

  const [forceCreateDeleteGroupInstructorState, setForceCreateDeleteGroupInstructorState] = useState([])

  //============== STATE VALIDATION =================
  const [isValidName, setIsValidName]                 = useState(false)
  const [isValidDesc, setIsValidDesc]                 = useState(false)
  const [isValidCategory, setIsValidCategory]         = useState(false)
  const [isImageTooLarge, setImageTooLarge]           = useState(false)
  const [isValidShowUnit, setIsValideShowUnit]        = useState(false)
  const [widthProgress, setwidthProgress]             = useState('32')
  const [isRequired, setIsRequired]                   = useState(false)
  const [disableButtonSubmit, setdisableButtonSubmit] = useState(true)

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/kelas', name: 'Sub Mata Pelajaran' }]

  /* ======================================== Components ======================================== */
  function Dropzone() {
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      accept: 'image/*',
      disabled: dataKelas.is_owner == false,
      maxSize: 1000000,
      multiple: false,
      onDrop: (acceptedFiles, fileRejections) => {
        if (acceptedFiles.length > 0) {
          setImageTooLarge(false)
          setFiles(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              }),
            ),
          )
          getBase64(acceptedFiles[0])
            .then((result) => {
              let avatarClass = result.split(',')
              setImage({ name: acceptedFiles[0].name, data: avatarClass[1] })
            })
            .catch((err) => {
              console.error(err)
            })
        }
        if (fileRejections.length > 0) {
          setImageTooLarge(true)
        }
      },
    })

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isDragActive, isDragReject, isDragAccept],
    )

    const Thumbs = () => {
      return (
        <div style={thumb}>
          <div style={thumbInner}>
            {image == null ? (
              <img
                src={'images?q=bm9fY292ZXJfY2xhc3MuanBn'}
                alt="no-cover"
              />
            ) : (
              <>
                {typeof image === 'string' && (
                  <img
                    src={image}
                    onError={(ev) => {
                      ev.target.src = 'image/no-image.png'
                    }}
                    alt="gambar-Mata Pelajaran"
                    style={img}
                  />
                )}
                {typeof image === 'object' && (
                  <img
                    src={`data:image/jpeg;base64,${image.data}`}
                    alt="preview"
                    style={img}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )
    }

    useEffect(
      () => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file) => URL.revokeObjectURL(file.preview))
      },
      [files],
    )

    return (
      <>
        <label
          htmlFor="avatar-kelas"
          className="col-sm-3 col-form-label"
        >
          Upload gambar
        </label>
        <div className="col-sm-9">
          <div
            className="border rounded alert alert-warning w-100"
            role="alert"
          >
            <p className="m-0">
              <i className="fas fa-info-circle mr-1" />
              Ukuran gambar maksimal 1MB
            </p>
          </div>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p className="mt-5">Drag or click to select file for add avatar</p>
          </div>
          <aside style={thumbsContainer}>
            <Thumbs />
          </aside>
        </div>
      </>
    )
  }

  /* ========================================= Functions ======================================== */
  function isAdmin() {
    return JSON.parse(localStorage.getItem('role_active'))
    ?.role_type === 'administrator' ||
    JSON.parse(localStorage.getItem('role_active'))
      ?.role_type === 'adminprofile'
  }


  async function getSelectedTeachers() {
    if (isAdmin()) {
      const response = await getRequest(`group-teachers-selected?class_id=${dataKelas?.id}`)
      if (response) {
        setForceCreateDeleteGroupInstructorState(response.data)
      }
    }
  }

  async function getCategories() {
    try {
      const { data } = await getRequest('categories')
      let arr = []
      data.map((x) => {
        arr.push({
          id: x.id,
          title: x.title,
        })
        if (x.id == dataKelas.category_id) {
          setCategoryName(x.title)
        }
      })
      setAllCategories(arr)
    } catch (error) {
      console.error(error)
    }
  }

  async function getAllIdClass() {
    try {
      const { data } = await getRequest(
        'classes?role_type=administrator',
      )
      let arr = []
      data.map((kelas) => {
        arr.push({
          id: kelas.id,
          title: kelas.title,
        })
        if (kelas.id == dataKelas.preclass_id) {
          setPreClassName(kelas.title)
        }
      })
      setAllIdClass(arr)
    } catch (error) {
      console.error(error)
    }
  }

  function hendleShowFormVideo() {
    if (dataKelas.class_url) {
      if (dataKelas.class_url.source === 'youtube') {
        setPreviewYoutube(dataKelas.class_url.data)
        setYoutube(
          `https://www.youtube.com/watch?v=${dataKelas.class_url.data}`,
        )
        setPostVideo({
          name: 'data lama',
          data: dataKelas.class_url.data,
          source: dataKelas.class_url.source,
        })
      }
      if (dataKelas.class_url.source === 'internal') {
        setVideo({ name: 'internal', data: dataKelas.linkclass_url })
        setPostVideo({
          name: 'data lama',
          data: dataKelas.class_url.data,
          source: dataKelas.class_url.source,
        })
      }
    } else {
      setPostVideo({ name: 'data lama', data: null, source: 'internal' })
    }
  }

  function getVideo(item) {
    if (typeof item === 'string') {
      let preview = item.split('=')
      setPreviewYoutube(preview[1]) //untuk preview video yutube
      setYoutube(item) //untuk nama di form video yutube
      setPostVideo({ name: null, data: item, source: 'youtube' }) //untuk post ke api
      setVideo(null) //untuk menghapus data video internal yang lama
    } else if (`${typeof item}` === 'object') {
      getBase64(item)
        .then((result) => {
          let interVideo = result.split(',')
          setVideo({ name: item.name, data: result })
          setPostVideo({
            name: item.name,
            data: interVideo[1],
            source: 'internal',
          })
          setYoutube('')
        })
        .catch((err) => {
          console.error(err)
        })
    } else {
      setPostVideo({
        name: 'data lama',
        data: null,
        source: dataKelas.class_url.source,
      })
      setVideo(null)
    }
  }

  // validationShowUnit
  function handleValidShowUnit() {
    if (showUnit) {
      setIsValideShowUnit(false)
      document.getElementById('preview-kelas').click()
    } else {
      setIsValideShowUnit(true)
      window.notification('Mohon', 'lengkapi data', 'warning')
    }
  }

  function hendleValidation() {
    if (name && description && categoryId) {
      setIsValidName(false)
      setIsValidDesc(false)
      setIsValidCategory(false)
      setwidthProgress('65')
      setIsRequired(false)
      document.getElementById('detail-kelas').click()
    } else {
      if (!name) {
        setIsValidName(true)
      }
      if (!description) {
        setIsValidDesc(true)
      }
      if (!categoryId) {
        setIsValidCategory(true)
      }
      setIsRequired(true)
      window.notification('Mohon', 'lengkapi data', 'warning')
    }
  }

  function handleformsOptional() {
    if (name && description && categoryId) {
      setwidthProgress('100')
      setdisableButtonSubmit(false)
    } else {
      window.notification('Mohon', 'lengkapi data', 'warning')
    }
  }

  async function handleSubmitData() {
    let form = new FormData()
    form.append('id', dataKelas.id)
    form.append('title', name)
    form.append('description', description)
    form.append('public_status', publicStatus === 'true')
    form.append('category_id', categoryId)
    form.append('prices', prices ? parseInt(prices) : 0)
    form.append('prerequisite', prerequisite)
    form.append('preclass_id', preClassId)
    form.append('display_at', displayAt)
    form.append('show_units', showUnit)

    if (Array.isArray(forceCreateDeleteGroupInstructorState) && forceCreateDeleteGroupInstructorState.length) {
      forceCreateDeleteGroupInstructorState.forEach((forceCreateDeleteGroupInstructorStateItem, index) => {
        if (forceCreateDeleteGroupInstructorStateItem?.id) {
          form.append(`group_teacher_id[${index}]`, forceCreateDeleteGroupInstructorStateItem.id)
        }
      })
    }

    if (typeof image !== 'string' && image !== null) {
      form.append('img', JSON.stringify(image))
    }
    if (postVideo.name !== 'data lama') {
      form.append('intro_video', JSON.stringify(postVideo))
    }
    form.append('active', publicStatus === 'true')
    
    const response = await putRequest('classes', form)
    if (response) {
      window.notification('Sub Mata Pelajaran', 'berhasil diubah', 'success')
      history.push('/kelas')
    }
  }

  function setInnerHtml() {
    return {
      __html: prerequisite ? prerequisite : '<p>Content tidak ditemukan</p>',
    }
  }

  function getDateNow() {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()

    today = `${yyyy}-${mm}-${dd}`
    setDateNow(today)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getSelectedTeachers()
  }, [dataKelas])

  useEffect(() => {
    showUnit === 'any_order'
      ? setShowUnitName('Tampilkan semua')
      : setShowUnitName('Tampilkan secara berurutan')
  }, [])

  useEffect(() => {
    getCategories()
    getAllIdClass()
    hendleShowFormVideo()
    getDateNow()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Edit Sub Mata Pelajaran"
          links={links}
        />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Informasi Sub Mata Pelajaran</h5>
              </div>
              <div className="card-body p-4">
                <div className="bt-wizard">
                  <ul className="nav nav-pills nav-fill mb-3">
                    <li className="nav-item">
                      <a
                        id="data-kelas"
                        href="#progress-t-tab1"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        <span className={isRequired ? 'text-danger' : ''}>
                          Data Sub Mata Pelajaran
                        </span>
                        {isRequired && <i className="text-danger">*</i>}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        id="detail-kelas"
                        onClick={() => {
                          hendleValidation()
                        }}
                        href="#progress-t-tab2"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        <span className={isValidShowUnit ? 'text-danger' : ''}>
                          Detail Sub Mata Pelajaran
                        </span>
                        {isValidShowUnit && <i className="text-danger">*</i>}
                      </a>
                    </li>
                    {
                      isAdmin()
                        ?
                        <li className="nav-item">
                          <a
                            id="preview-kelas"
                            onClick={() => {
                              handleformsOptional()
                              handleValidShowUnit()
                            }}
                            href="#progress-t-tab3"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Guru
                          </a>
                        </li>
                        : null
                    }
                    <li className="nav-item">
                      <a
                        id="preview-kelas"
                        onClick={() => {
                          handleformsOptional()
                          handleValidShowUnit()
                        }}
                        href="#progress-t-tab4"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Pratinjau Sub Mata Pelajaran
                      </a>
                    </li>
                  </ul>
                  <div
                    className="bt-wizard progress mb-3"
                    style={{ height: '6px' }}
                  >
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      aria-valuenow={0}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: `${widthProgress}%` }}
                    />
                  </div>

                  <div className="tab-content">
                    <div
                      className="tab-pane active show"
                      id="progress-t-tab1"
                    >
                      <form className="ml-1 mr-2">
                        <div className="form-group row">
                          <label
                            htmlFor="nama-kelas"
                            className="col-sm-3 col-form-label"
                          >
                            <span className="mr-1">Nama</span>
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <input
                              value={name}
                              disabled={
                                dataKelas.is_owner == false
                              }
                              onChange={(val) => setName(val.target.value)}
                              className={`form-control ${
                                isValidName ? 'is-invalid' : ''
                              }`}
                              type="text"
                              id="nama-kelas"
                              placeholder="Masukan nama sub mata pelajaran"
                            />
                            {isValidName ? (
                              <InvalidText name={'Nama Sub Mata Pelajaran'} />
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="kategori-kelas"
                            className="col-sm-3 col-form-label"
                          >
                            <span className="mr-1">Mata Pelajaran</span>
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <select
                              value={categoryId}
                              onChange={(event) => {
                                setCategory(event.target.value)
                                setCategoryName(
                                  event.target.options[
                                    event.target.selectedIndex
                                  ].text,
                                )
                              }}
                              className={`form-control ${
                                isValidCategory ? 'is-invalid' : ''
                              }`}
                              id="kategori-kelas"
                            >
                              <option value={''}>
                                Pilih Mata Pelajaran ...
                              </option>
                              {allCategories.map((parent, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={parent.id}
                                  >
                                    {parent.title}
                                  </option>
                                )
                              })}
                            </select>
                            {isValidCategory ? (
                              <InvalidText name={'Mata Pelajaran'} />
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="deskripsi-kelas"
                            className="col-sm-3 col-form-label"
                          >
                            <span className="mr-1">Deskripsi</span>
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              disabled={
                                dataKelas.is_owner == false
                              }
                              id="deskripsi-kelas"
                              value={description}
                              onChange={(val) => setDesc(val.target.value)}
                              rows={3}
                              className={`form-control ${
                                isValidDesc ? 'is-invalid' : ''
                              }`}
                              placeholder="Masukan deskripsi sub mata pelajaran"
                            />
                            {isValidDesc ? (
                              <InvalidText
                                name={'Deskripsi Sub Mata Pelajaran'}
                              />
                            ) : null}
                          </div>
                        </div>
                        <fieldset className="form-group mb-3">
                          <div className="row">
                            <label className="col-sm-3 col-form-label">
                              Status
                            </label>
                            <div className="col-sm-9">
                              <div className="custom-control custom-radio">
                                <input
                                  disabled={
                                    dataKelas.is_owner == false
                                  }
                                  onChange={(val) =>
                                    setStatus(val.target.value)
                                  }
                                  type="radio"
                                  className="custom-control-input"
                                  style={{ zIndex: 9999 }}
                                  id="customControlValidation2"
                                  name="active"
                                  defaultValue="true"
                                  defaultChecked={
                                    dataKelas.public_status
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customControlValidation2"
                                >
                                  Aktif
                                </label>
                              </div>
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  disabled={
                                    dataKelas.is_owner == false
                                  }
                                  onChange={(val) =>
                                    setStatus(val.target.value)
                                  }
                                  type="radio"
                                  className="custom-control-input"
                                  style={{ zIndex: 9999 }}
                                  id="customControlValidation3"
                                  name="active"
                                  defaultValue="false"
                                  defaultChecked={!dataKelas.public_status}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customControlValidation3"
                                >
                                  Tidak Aktif
                                </label>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <div className="form-group row">
                          <Dropzone />
                          {isImageTooLarge ? (
                            <span className="col offset-md-3 small form-text text-danger">
                              Ukuran gambar maksimal 1MB
                            </span>
                          ) : null}
                        </div>
                      </form>
                      <div className="form-group mb-5">
                        <label className="text-muted">Keterangan:</label>
                        <div
                          className="ml-2"
                          style={{ lineHeight: '0' }}
                        >
                          <span className="text-danger mr-1">*</span>
                          <label className="text-muted">Wajib diisi</label>
                        </div>
                      </div>
                      <div className="row btn-page ml-1 mr-2">
                        <div className="col text-right">
                          <button
                            onClick={() => {
                              hendleValidation()
                            }}
                            className="btn btn-primary button-next"
                          >
                            Lanjut
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* OPTIONAL DATA */}
                    <div
                      className="tab-pane"
                      id="progress-t-tab2"
                    >
                      <form>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Video intro
                          </label>
                          <div className="col-sm-9">
                            <ul
                              style={{ background: 'none' }}
                              className="nav nav-pills mb-3"
                              id="pills-tab"
                              role="tablist"
                            >
                              <li className="nav-item">
                                <a
                                  className={`nav-link ${
                                    postVideo.source == 'internal'
                                      ? 'active'
                                      : ''
                                  }`}
                                  id="pills-video-tab"
                                  data-toggle="pill"
                                  href="#pills-video"
                                  role="tab"
                                  aria-controls="pills-video"
                                  aria-selected="false"
                                >
                                  Use video
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className={`nav-link ${
                                    postVideo.source == 'youtube'
                                      ? 'active'
                                      : ''
                                  }`}
                                  id="pills-youtube-tab"
                                  data-toggle="pill"
                                  href="#pills-youtube"
                                  role="tab"
                                  aria-controls="pills-youtube"
                                  aria-selected="true"
                                >
                                  Use Youtube
                                </a>
                              </li>
                            </ul>
                            <div
                              className="tab-content"
                              id="pills-tabContent"
                            >
                              <div
                                className={`tab-pane fade ${
                                  postVideo.source == 'internal'
                                    ? 'show active'
                                    : ''
                                }`}
                                id="pills-video"
                                role="tabpanel"
                                aria-labelledby="pills-video-tab"
                              >
                                <div className="custom-file">
                                  <input
                                    disabled={
                                      dataKelas.is_owner == false
                                    }
                                    accept=".mp4"
                                    onChange={(val) =>
                                      getVideo(val.target.files[0])
                                    }
                                    type="file"
                                    className="custom-file-input"
                                    id="validatedCustomFile"
                                    required
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="validatedCustomFile"
                                  >
                                    {internal
                                      ? internal.name
                                      : 'Choose video...'}
                                  </label>
                                </div>
                              </div>
                              <div
                                className={`tab-pane fade ${
                                  postVideo.source == 'youtube'
                                    ? 'show active'
                                    : ''
                                }`}
                                id="pills-youtube"
                                role="tabpanel"
                                aria-labelledby="pills-youtube-tab"
                              >
                                <input
                                  disabled={
                                    dataKelas.is_owner == false
                                  }
                                  value={youtube}
                                  onChange={(val) => getVideo(val.target.value)}
                                  type="text"
                                  id="intro-video"
                                  className={`form-control mb-3`}
                                  placeholder="https://www.youtube.com/watch?v=xyz"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Prasyarat
                          </label>
                          <div className="col-sm-9">
                            <CkeditorComponentV2
                              disabled={
                                dataKelas.is_owner == false
                              }
                              data={prerequisite}
                              onChange={setPrerequisite}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="preclassId"
                            className="col-sm-3 col-form-label"
                          >
                            Sub Mapel Prasyarat
                          </label>
                          <div className="col-sm-9">
                            <select
                              disabled={
                                dataKelas.is_owner == false
                              }
                              id="preclassId"
                              value={preClassId}
                              onChange={(event) => {
                                setPreClass(event.target.value)
                                setPreClassName(
                                  event.target.options[
                                    event.target.selectedIndex
                                  ].text,
                                )
                              }}
                              className="form-control mb-3"
                            >
                              <option value="">
                                Pilih Sub Mata Pelajaran ...
                              </option>
                              {allIdClass.map((item, index) => {
                                if (item.id === dataKelas.id) {
                                  return (
                                    <option
                                      disabled
                                      key={index}
                                      value={item.id}
                                    >
                                      {item.title}
                                    </option>
                                  )
                                } else {
                                  return (
                                    <option
                                      key={index}
                                      value={item.id}
                                    >
                                      {item.title}
                                    </option>
                                  )
                                }
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="displayAt"
                            className="col-sm-3 col-form-label"
                          >
                            Tanggal tampil
                          </label>
                          <div className="col-sm-9">
                            <input
                              disabled={
                                dataKelas.is_owner == false
                              }
                              id="displayAt"
                              min={dateNow ? dateNow : null}
                              value={displayAt}
                              onChange={(val) => setDisplayAt(val.target.value)}
                              type="date"
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* Rules */}
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Menampilkan materi
                            <span className="text-danger"> *</span>
                          </label>
                          <div className="col-sm-9">
                            <select
                              disabled={
                                dataKelas.is_owner == false
                              }
                              id="showUnit"
                              // ref={showUnitRef}
                              value={showUnit}
                              onSelect={(e) => {
                                setShowUnitName(
                                  e.target.options[e.target.selectedIndex].text,
                                )
                              }}
                              onChange={(e) => {
                                setShowUnitName(
                                  e.target.options[e.target.selectedIndex].text,
                                )
                                setShowUnit(e.target.value)
                              }}
                              className={`form-control ${
                                isValidShowUnit ? 'is-invalid' : ''
                              }`}
                            >
                              <option value="">Pilih</option>
                              <option value="any_order">Tampilkan semua</option>
                              <option value="sequential_order">
                                Tampilkan secara berurutan
                              </option>
                            </select>
                            {isValidShowUnit ? (
                              <InvalidText name={'Menampilkan Materi'} />
                            ) : null}
                          </div>
                        </div>
                      </form>
                      <br />
                      <div className="row justify-content-between btn-page ml-1 mr-2">
                        <button
                          onClick={() => {
                            document.getElementById('data-kelas').click()
                          }}
                          className="btn btn-primary button-previous"
                        >
                          Sebelumnya
                        </button>
                        <button
                          onClick={() => {
                            handleValidShowUnit()
                          }}
                          className="btn btn-primary button-next"
                        >
                          Lanjut
                        </button>
                      </div>
                    </div>

                    {/* Tab Guru */}
                    {isAdmin()
                    ?
                      <div
                        className="tab-pane"
                        id="progress-t-tab3"
                      >
                        <OverviewContentsCreator
                        forceCreateDeleteGroupInstructorState={forceCreateDeleteGroupInstructorState}
                        setForceCreateDeleteGroupInstructorState={setForceCreateDeleteGroupInstructorState}
                        forceShowCreateDeleteGroupInstructor={true} />
                      </div>
                    : null}

                    {/* PREVIEW DATA */}
                    <div
                      className="tab-pane"
                      id="progress-t-tab4"
                    >
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Nama Sub Mata Pelajaran
                        </label>
                        <div className="col-sm-9">
                          <input
                            value={name ? name : '-'}
                            type="text"
                            className="form-control p-2"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Mata Pelajaran
                        </label>
                        <div className="col-sm-9">
                          <input
                            value={categoryName ? categoryName : '-'}
                            type="text"
                            className="form-control p-2"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Deskripsi
                        </label>
                        <div className="col-sm-9">
                          <textarea
                            value={description ? description : '-'}
                            rows={3}
                            className="form-control p-2"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Status Sub Mata Pelajaran
                        </label>
                        <div className="col-sm-9">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="status-review-active"
                              name="active-review"
                              defaultValue="true"
                              checked={publicStatus === 'true'}
                              disabled
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="status-review-active"
                            >
                              Aktif
                            </label>
                          </div>
                          <div className="custom-control custom-radio mb-3">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="status-review-no-active"
                              name="active-review"
                              defaultValue="false"
                              checked={publicStatus === 'false'}
                              disabled
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="status-review-no-active"
                            >
                              Tidak Aktif
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Avatar Sub Mata Pelajaran
                        </label>
                        <div className="col-sm-9">
                          {image ? (
                            <div style={thumb}>
                              <div style={thumbInner}>
                                {typeof image === 'string' && (
                                  <img
                                    src={image}
                                    onError={(ev) => {
                                      ev.target.src = 'image/no-image.png'
                                    }}
                                    alt="gambar-Mata Pelajaran"
                                    style={img}
                                  />
                                )}
                                {typeof image === 'object' && (
                                  <img
                                    src={
                                      image.data
                                        ? `data:image/jpeg;base64,${image.data}`
                                        : process.env.PUBLIC_URL +
                                          'image/no-image.png'
                                    }
                                    alt="preview"
                                    style={img}
                                  />
                                )}
                              </div>
                            </div>
                          ) : (
                            <i className="fas fa-minus-circle fa-2x mt-2 text-danger"></i>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Video intro
                        </label>
                        <div className="col-sm-9">
                          {postVideo.data ? (
                            postVideo.source == 'youtube' ? (
                              <YouTube
                                opts={{
                                  width: '330px',
                                  height: '200px',
                                }}
                                videoId={previewYutube}
                              />
                            ) : (
                              <video
                                key={internal.data}
                                width="300px"
                                height="200px"
                                controls
                                controlsList="nodownload"
                              >
                                <source
                                  src={internal.data}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            )
                          ) : (
                            <i className="fas fa-minus-circle fa-2x mt-2 text-danger"></i>
                          )}
                        </div>
                      </div>
                      {
                        isAdmin()
                          ?
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Guru
                            </label>
                            <div className="col-sm-9">
                              <button
                                type="button"
                                data-toggle="modal"
                                data-target="#showGroupGuru"
                                className={`${
                                  forceCreateDeleteGroupInstructorState?.length ? '' : 'btn-forbidden'
                                } btn btn-info`}
                                disabled={!forceCreateDeleteGroupInstructorState?.length}
                              >
                                <i className="fas fa-eye fa-lg mr-1 mt-1" />
                                <span>Tampilkan Group Guru</span>
                              </button>
                            </div>
                          </div>
                          : null
                      }

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Prasyarat
                        </label>
                        <div className="col-sm-9">
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#showPrasyarat"
                            className={`${
                              prerequisite ? '' : 'btn-forbidden'
                            } btn btn-info`}
                            disabled={!prerequisite}
                          >
                            <i className="fas fa-eye fa-lg mr-1 mt-1" />
                            <span>Tampilkan prasyarat</span>
                          </button>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Sub Mapel Prasyarat
                        </label>
                        <div className="col-sm-9">
                          <input
                            value={preClassId ? preClassName : 'Tidak aktif'}
                            type="text"
                            className="form-control p-2"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Tanggal tampil
                        </label>
                        <div className="col-sm-9">
                          <input
                            value={
                              displayAt
                                ? moment(displayAt).format('DD MMMM YYYY')
                                : 'Tidak aktif'
                            }
                            type="text"
                            className="form-control p-2"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Menampilkan Materi
                        </label>
                        <div className="col-sm-9">
                          <input
                            value={showUnit ? showUnitName : 'Tidak aktif'}
                            type="text"
                            className="form-control p-2"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="row btn-page mt-5">
                        <div className="col text-right">
                          <button
                            type="button"
                            onClick={() => handleSubmitData()}
                            style={{
                              cursor: disableButtonSubmit
                                ? 'not-allowed'
                                : 'pointer',
                            }}
                            className="btn btn-success"
                            disabled={disableButtonSubmit}
                          >
                            Simpan
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Modal Group Guru --> */}
        {isAdmin()
        ?
          <div
            className="modal fade"
            id="showGroupGuru"
            data-backdrop="static"
            data-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <DataTableGroupInstructor
                    showForceCreateDeleteGroupInstructorStateData={true}
                    forceCreateDeleteGroupInstructorState={forceCreateDeleteGroupInstructorState}
                    actions={['addDeleteEntry']}
                    withHeader={false}
                    forceShowCreateDeleteGroupInstructor={true}
                    setForceCreateDeleteGroupInstructorState={setForceCreateDeleteGroupInstructorState}
                  />
                </div>
                <div className="modal-footer p-2">
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Tutup
                  </button>
                </div>
              </div>
            </div>
          </div>
        : null }


        {/* <!-- Modal Prasyarat --> */}
        <div
          className="modal fade"
          id="showPrasyarat"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div
                  className="my-3"
                  dangerouslySetInnerHTML={setInnerHtml()}
                  style={{ overflow: 'hidden' }}
                />
              </div>
              <div className="modal-footer p-2">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

export const CONTENT_TYPE = {
  TEXT: 1,
  AUDIO: 2,
  VIDEO: 3,
  DOCUMENT: 4,
  IFRAME: 5,
  QUIZZ: 6,
  SURVEY: 7,
  ASSIGNMENT: 8,
  GRAPHIC_ORGANIZER: 9,
  WHITEBOARD: 10,
}

export const CONTENT_TYPE_TEXT = {
  [CONTENT_TYPE.TEXT]: 'Teks',
  [CONTENT_TYPE.AUDIO]:'Audio',
  [CONTENT_TYPE.VIDEO]:'Video',
  [CONTENT_TYPE.DOCUMENT]:'Dokumen',
  [CONTENT_TYPE.IFRAME]:'iFrame',
  [CONTENT_TYPE.QUIZZ]:'Quiz',
  [CONTENT_TYPE.SURVEY]:'Survey',
  [CONTENT_TYPE.ASSIGNMENT]:'Tugas',
  [CONTENT_TYPE.GRAPHIC_ORGANIZER]:'Graphic Organizer',
  [CONTENT_TYPE.WHITEBOARD]: 'Whiteboard',
}

export const GRAPHOR_TYPE = {
  SORTING: 1,
  GROUP: 2,
}

export const ASSIGNMENT_TYPE = {
  TEXT: '1',
  AUDIO: '2',
  VIDEO: '3',
  DOCUMENT: '4',
  YOUTUBE: '5',
  URL: '6',
}

export const FEEDBACK_TYPE = {
  ESSAY: 1,
  PG: 2,
  PG_KOMPLEKS: 3,
  WORDCLOUD: 4,
}

export const SURVEY_TYPE = {
  PG: 0,
  ESSAY: 1, // without ckeditor
}
import React from 'react'
import { Formik } from 'formik';

import BaseButton from 'component/button/BaseButton';
import BaseReactBootstrapModal from 'component/modal/BaseReactBootstrapModal'
import { Col, Form, Row } from 'react-bootstrap'
import BaseSelect from 'component/select/BaseSelect';


const ModalAddOrderSubjectReport = ({ onCloseHandler, modalAddHook, headerHook }) => {
  const onChangeHandler = (name, value, setFieldValue) => {
    setFieldValue(name, value)
    modalAddHook?.customDispatch?.reactSelectDispatch({ type: name === 'selectedClass' ? 'class' : name, value: value })
  }

  return (
    <BaseReactBootstrapModal
      show={modalAddHook?.data?.isShowModalAddOrderSubjectReport}
      handleClose={onCloseHandler}
      isWithHeader={false}
    >
      <Formik
      validationSchema={modalAddHook?.schema?.addHeaderGroupSchema}
      onSubmit={modalAddHook?.handler?.addNewSubjectCategoryMapelHandler}
      initialValues={{
        selectedAcademicYear: modalAddHook?.customState?.reactSelectState?.selectedAcademicYear || headerHook?.customState?.reactSelectState?.selectedAcademicYear,
        selectedSemester: modalAddHook?.customState?.reactSelectState?.selectedSemester || headerHook?.customState?.reactSelectState?.selectedSemester,
        selectedClass: modalAddHook?.customState?.reactSelectState?.selectedClass || headerHook?.customState?.reactSelectState?.selectedClass,
        // selectedAcademicYear: modalAddHook?.data?.optionsAcademicYear?.length ? modalAddHook?.customState?.reactSelectState?.selectedAcademicYear : {},
        // selectedSemester: modalAddHook?.data?.optionsSemester?.length ? modalAddHook?.customState?.reactSelectState?.selectedSemester : {},
        // selectedClass: null,
        name: ''
      }}
      >
        {({ handleSubmit, values, touched, errors, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} className=''>
                <h3 className='text-center'>Tambah Kategori Mapel</h3>
              </Form.Group>
              <Form.Group as={Col} md="12" controlid="academic-year">
                <Form.Label>Pilih Tahun Ajaran</Form.Label>
                {modalAddHook?.data?.optionsAcademicYear?.length ?
                  <BaseSelect
                    name="academic-year"
                    isInvalid={touched?.selectedAcademicYear && !!errors?.selectedAcademicYear}
                    options={modalAddHook?.data?.optionsAcademicYear}
                    defaultValue={modalAddHook?.data?.isCreatedNewSubjectGroup ? headerHook?.customState?.reactSelectState?.selectedAcademicYear : modalAddHook?.customState?.reactSelectState?.selectedAcademicYear}
                    // defaultValue={values?.selectedAcademicYear}
                    placeholder={'Silahkan pilih tahun ajaran'}
                    onChange={choice => {
                      modalAddHook?.customSetState?.setIsCreatedNewSubjectGroup(false)
                      onChangeHandler('academic-year', choice, setFieldValue)
                    }}
                  />
                : null }
                {touched?.selectedAcademicYear && !!errors?.selectedAcademicYear ?
                  <div className="text-danger">{errors?.selectedAcademicYear}</div>
                : null}
              </Form.Group>
              <Form.Group as={Col} md="12" controlid="semester">
                <Form.Label>Pilih Semester</Form.Label>
                {modalAddHook?.data?.optionsSemester?.length ?
                  <BaseSelect
                    name="semester"
                    isInvalid={touched?.selectedSemester && !!errors?.selectedSemester}
                    options={modalAddHook?.data?.optionsSemester}
                    defaultValue={modalAddHook?.data?.isCreatedNewSubjectGroup ? headerHook?.customState?.reactSelectState?.selectedSemester : modalAddHook?.customState?.reactSelectState?.selectedSemester}
                    // defaultValue={values?.selectedSemester}
                    placeholder={'Silahkan pilih semester'}
                    onChange={choice => onChangeHandler('semester', choice, setFieldValue)}
                  />
                : null }
                {touched?.selectedSemester && !!errors?.selectedSemester ?
                  <div className="text-danger">{errors?.selectedSemester}</div>
                : null}
              </Form.Group>
              <Form.Group as={Col} md="12" controlid="selectedClass">
                <Form.Label>Pilih Kelas</Form.Label>
                {modalAddHook?.data?.optionsClass?.length ?
                  <BaseSelect
                    name="selectedClass"
                    isInvalid={touched?.selectedClass && !!errors?.selectedClass}
                    options={modalAddHook?.data?.optionsClass}
                    defaultValue={modalAddHook?.data?.isCreatedNewSubjectGroup ? headerHook?.customState?.reactSelectState?.selectedClass : modalAddHook?.customState?.reactSelectState?.selectedClass}
                    // defaultValue={values?.selectedClass}
                    placeholder={'Silahkan pilih kelas'}
                    onChange={choice => onChangeHandler('selectedClass', choice, setFieldValue)}
                  />
                : null }
                {touched?.selectedClass && !!errors?.selectedClass ?
                  <div className="text-danger">{errors?.selectedClass}</div>
                : null}
              </Form.Group>
              <Form.Group as={Col} md="12" controlid="name">
                <Form.Label>Nama Kategori Mapel</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  placeholder='Silahkan masukkan nama kategori mapel'
                  onChange={(e) => onChangeHandler('name', e.target.value, setFieldValue)}
                  isValid={touched.name && !errors.name}
                  autoComplete='off'
                />
                {touched?.name && !!errors?.name ?
                  <div className="text-danger">{errors?.name}</div>
                : null}
              </Form.Group>
            </Row>
            <div className='w-100 d-flex justify-content-center'>
              <BaseButton isMarginRight={false} variant='success' type="submit">Tambahkan</BaseButton>
            </div>
          </Form>
        )}
      </Formik>
    </BaseReactBootstrapModal>
  )
}

export default ModalAddOrderSubjectReport
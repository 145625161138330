import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { useBaseContext } from 'context/base'
import { getToken, firebaseApp } from 'util/firebase'
import { blockedNotifPopUp } from 'util/global'
import { host } from 'util/host'

export default function LandingPage() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, localStorageClear } = useBaseContext()

  /* ======================================== Components ======================================== */
  const Logo = () => {
    const profile = JSON.parse(localStorage.getItem('profile'))
    if (profile) {
      return (
        <img
          src={`${profile.logo}`}
          style={{ width: 300, height: 'auto', alignSelf: 'center' }}
          className="img-fluid mb-4"
          onError={(e) =>
            (e.currentTarget.src = '/logo_sma_current_v3_white_scaled.webp')
          }
        />
      )
    } else {
      return <h1 className="text-light mb-4">Loading</h1>
    }
  }

  /* ========================================= Functions ======================================== */
  async function cekToken() {
    let response = await getRequest('verify-token', false)
    if (response) {
      history.push('dashboard')

      // Check firebase compatibility
      if (firebaseApp.messaging.isSupported()) {
        // check notification
        if (!('Notification' in window)) {
          console.error('This browser does not support desktop notification')
        } else {
          const overlayElement = document.createElement('div')
          overlayElement.innerHTML = `
            <div class="notif-overlay">
              <div class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          `
          document.body.append(overlayElement)
          Notification.requestPermission().then(async (permit) => {
            if (permit === 'granted') {
              await initToken()
              document.querySelector('.notif-overlay')?.remove()
            } else if (permit === 'denied') {
              document.querySelector('.notif-overlay')?.remove()
              blockedNotifPopUp()
            } else {
              document.querySelector('.notif-overlay')?.remove()
            }
          })

          setTimeout(
            () => document.querySelector('.notif-overlay')?.remove(),
            500,
          )
        }
      } else {
        // Notify notification not supported
        window.$.notify(
          {
            icon: 'feather icon-alert-circle',
            title: 'Pemberitahuan',
            message:
              'Browser yang anda gunakan tidak mendukung fitur notifikasi',
          },
          {
            element: 'body',
            type: 'success',
            allow_dismiss: true,
            placement: {
              from: 'top',
              align: 'center',
            },
            spacing: 10,
            z_index: 999999,
            delay: 0,
            url_target: '_blank',
            mouse_over: false,
            remove: true,
            template: `
              <div data-notify="container" class="bg-light border col-xs-11 col-sm-3 alert alert-{0}" role="alert">
                <button type="button" aria-hidden="true" class="close text-dark" data-notify="dismiss">×</button>
                <span data-notify="icon" class="text-warning mr-1"></span> 
                <span data-notify="title" class="text-dark f-14 f-w-700">{1}</span> 
                <div data-notify="message" class="text-dark">{2}</div>
              </div>
            `,
          },
        )
      }
    } else {
      localStorageClear()
      window.location.replace('/login')
    }
  }

  async function initToken() {
    const fbToken = await getToken()
    if (fbToken) {
      localStorage.setItem('firebase_token', fbToken)
    }
  }

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      cekToken()
    }

    return () => {
      isSubscribed = false
    }
  }, [])

  return (
    <div
      style={{
        backgroundColor: '#345DAB',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>LMS</title>
        <link
          rel="canonical"
          href="http://mysite.com/example"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Logo host={host} />
      <div className="d-flex justify-content-center">
        <div
          className="spinner-border text-light"
          style={{ width: '2rem', height: '2rem' }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  )
}

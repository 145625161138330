import React, { useState } from 'react'

export default function MediaNotice() {
  /* ======================================= Local States ======================================= */
  const [show, setShow] = useState(false)

  /* ========================================= Functions ======================================== */
  function handleShow() {
    setShow(!show)
  }

  /* ========================================== Output ========================================== */
  return (
    <>
      {show ? (
        <div
          style={{ position: 'relative' }}
          className="mt-2 border rounded alert alert-warning w-100"
          role="alert"
        >
          <h6>
            <i className="fas fa-info-circle mr-1" />
            Provider media yang mendukung:
          </h6>
          <ul className="mb-0 pl-4">
            <li>Youtube</li>
            <li>Spotify</li>
            <li>Dailymotion</li>
            <li>Vimeo</li>
          </ul>
          <button
            onClick={handleShow}
            style={{
              position: 'absolute',
              bottom: '5px',
              right: '17px',
            }}
            className="btn btn-sm p-0"
            title="Sembunyikan"
          >
            <i className="fas fa-angle-up f-20" />
          </button>
        </div>
      ) : (
        <div
          className="mt-2 border rounded alert alert-warning w-100 d-flex justify-content-between align-items-center"
          role="alert"
        >
          <h6 className="m-0">
            <i className="fas fa-info-circle mr-1" />
            Provider media yang mendukung
          </h6>
          <button
            onClick={handleShow}
            className="btn btn-sm p-0"
            title="Tampilkan"
          >
            <i className="fas fa-angle-down f-20" />
          </button>
        </div>
      )}
    </>
  )
}

import BaseInput from 'component/input/BaseInput';
import React from 'react';
import BaseButton from 'component/button/BaseButton';
import BaseInputTextArea from 'component/input/BaseInputTextArea';

const InputCardDescriptionProject = ({ index, descriptionProjectData, setDescriptionProjectData, setSelectedProject, setIsShowModalInputDimention, setRerenderCount }) => {
  const copyDescriptionProjectData = [...descriptionProjectData];

  const onChangeHandler = (event, type) => {
    const value = event.target.value;

    const isDataExist = copyDescriptionProjectData?.[index] || { label: '', value: '', index: 0, proyek_type_scores: [], title: '', description: '' };

    isDataExist[type] = value;

    copyDescriptionProjectData[index] = isDataExist;
    setDescriptionProjectData(copyDescriptionProjectData);
    setRerenderCount(prevCount => prevCount + 1)
  };


  return (
    <>
      <div className="card">
        <div className="card-body">
          <BaseButton variant='warning' props={{ className: 'btn-sm position-absolute', style: { right: '28px' } }} onClick={() => { setSelectedProject({ index, descriptionProjectData }); setIsShowModalInputDimention(true) }}><i className="card-icon fas fa-list"></i></BaseButton>
          <div className="col-md-12 mb-3 col-md-0 mt-3">
            <BaseInput
              label={`Judul Proyek ${index + 1}`}
              access={['instruktur']}
              id={`project-title-${index}`}
              placeholder={`Judul Proyek ${index + 1}`}
              props={{ onChange: (e) => onChangeHandler(e, 'title'), value: copyDescriptionProjectData?.[index]?.title || '' }}
            />
          </div>
          <div className="col-md-12 mb-3 col-md-0">
            {/* <BaseInput
              label={`Deskripsi Proyek`}
              id={`project-description-${index}`}
              access={['instruktur']}
              placeholder={`Deskripsi Proyek`}
              props={{ onChange: (e) => onChangeHandler(e, 'description') }}
            /> */}
            {/* <label htmlFor={`project-description-${index}`}>Deskripsi Proyek</label>
            <CKEditor
              id={`project-description-${index}`}
              ref={refCkeditor}
              editor={Editor}
              data={''}
              config={editorConfigurationWithoutImage}
              onChange={(_, editor) => {
                const data = editor.getData()
                onChangeHandler({ target: { value: data } }, 'description')
              }}
            /> */}
            <BaseInputTextArea
              label={`Deskripsi Proyek`}
              id={`project-description-${index}`}
              access={['instruktur']}
              placeholder={`Deskripsi Proyek`}
              props={{ onChange: (e) => onChangeHandler(e, 'description'), value: copyDescriptionProjectData?.[index]?.description || '' }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InputCardDescriptionProject;

import { FormInputAudio, FormInputDocument, FormInputGraphicOrganizer, FormInputMateri, FormInputQuiz, FormInputSurvey, FormInputTugas, FormInputVideo, FormInputWebAddress } from 'component/content-form'
import FormInputGrade from 'component/content-form/form-grade'
import { Controller } from 'react-hook-form'
import useFormInputContent from './hooks/useFormInputContent'

/**
 * Form Input Content Props
 * 
 * @param {ListBaseFormInputContentProps} props
 */
export default function FormInputContent({ data }) {
  const { handler } =  useFormInputContent()

  return data.map((d, index) => (
      <Controller
        control={d.control}
        name={d.type}
        key={`form-input-type-${d.type}-index${index}`}
        rules={{
          required: d.props?.isRequired,
          validate: d.props?.validate ? d.props?.validate : value => handler.isValidByInputTypeHandler({
              type: d.type,
              isRequiredProp: d.props?.isRequired,
              value,
            }),
        }}
        defaultValue={d.props?.value}
        render={({ field: { onChange }}) => {
          const commonProps = {
            ...d.props,
            setValue: newValue => {
              d.props?.setValue(newValue)
              onChange({
                target: {
                  name: d.type,
                  value: newValue
                }
              })
            }
          }
  
          if (d.props?.isShow) {
            return null
          } else {
            switch (d.type) {
              case 'grade':
                return <FormInputGrade {...commonProps} />
              case 'materi':
                return <FormInputMateri {...commonProps} />
              case 'audio':
                return <FormInputAudio {...commonProps} />
              case 'video':
                return <FormInputVideo {...commonProps} />
              case 'document':
                return <FormInputDocument {...commonProps} />
              case 'web-address':
                return <FormInputWebAddress {...commonProps} />
              case 'quiz':
                return <FormInputQuiz {...commonProps} />
              case 'survey':
                return <FormInputSurvey {...commonProps} />
              case 'tugas':
                return <FormInputTugas {...commonProps} />
              case 'graphic-organizer':
                return <FormInputGraphicOrganizer {...commonProps} />
              default:
                return null
            }
          }
        }}
      />
    )
  )
}
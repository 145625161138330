import { isFileTypeInvalidHandler } from 'component/content/hooks/useFormInputContent'
import InfoAudio from './info-audio'
import InfoGdrive from './info-gdrive'
import PreviewAudioLink from './preview-audio-link'

/**
 * Form Input Audio Props
 * 
 * @param {BaseContentFormProps} props
 */
export default function FormInputAudio({ 
  value, 
  setValue, 
  isInvalid, 
  isRequired, 
  errorMessage,
}) {
  const internalSource = '0'
  const umumSource = '1'

  const fileTypeInvalid = isFileTypeInvalidHandler(value, 'audio')

  return (
    <div className="form-group mb-5 row">
      <label className="col-sm-3 col-form-label">
        <span className="mr-1">Audio</span>
        {isRequired
        ?
          <span className="text-danger">*</span>
        : null }
      </label>
      <div className="col-sm-9">
        <div className="col">
          <div className="form-check">
            <input
              onChange={e => setValue({
                ...value,
                content_source: e.target.value
              })}
              className="form-check-input"
              type="radio"
              name="selectAudio"
              id="soundcloud"
              defaultValue={umumSource}
              defaultChecked={value?.content_source == umumSource}
            />
            <label
              className="form-check-label"
              htmlFor="soundcloud"
            >
              <i className="feather icon-link mr-1" />
              <span>Link</span>
              <span className="ml-1">
                (Google Drive, Soundcloud, atau link umum)
              </span>
            </label>
          </div>
          {value?.content_source == umumSource ? (
            <div className="mb-4">
              <InfoGdrive />
              <input
                type="text"
                value={value?.link}
                onChange={e => setValue({
                  ...value,
                  link: e.target.value,
                  isChanged: true,
                })}
                className="form-control my-3"
                placeholder="Link: https://drive.google.com/file/d/xyz atau https://soundcloud.com/user/xyz  atau link umum"
              />
              {value?.link ? (
                <PreviewAudioLink link={value?.link} />
              ) : null}
            </div>
          ) : null}
          <div className="form-check">
            <input
              onChange={e => setValue({
                ...value,
                content_source: e.target.value
              })}
              className="form-check-input"
              type="radio"
              name="selectAudio"
              id="uploadAudio"
              defaultValue={internalSource}
              checked={value?.content_source == internalSource}
            />
            <label
              className="form-check-label"
              htmlFor="uploadAudio"
            >
              <i className="fas fa-file-audio mr-1" />
              <span>Upload file audio (mp3)</span>
            </label>
          </div>
          {value?.content_source == internalSource ? (
            <div className="custom-file">
              <input
                type="file"
                accept="audio/*"
                onChange={e => setValue({
                  ...value,
                  file: e.target.files?.[0],
                  isChanged: true,
                })}
                className="custom-file-input"
                id="input-upload-audio"
                style={{ position: 'absolute' }}
              />
              <label
                className="custom-file-label"
                htmlFor="input-upload-audio"
                style={{
                  position: 'relative',
                  width: '100%',
                }}
              >
                {value?.file?.name
                  ? value?.file?.name
                  : 'Pilih file audio'}
              </label>
              {fileTypeInvalid ? (
                <div>
                  <label className="col small form-text text-danger">
                    Type file audio harus mp3.
                  </label>
                  <InfoAudio />
                </div>
              ) : null}
            </div>
          ) : null}
          {isInvalid ? (
            <label className="small form-text mt-3 text-danger">
              {fileTypeInvalid ? 'Type file video harus mp3.' : errorMessage || 'Audio tidak boleh kosong.'}
            </label>
          ) : null}
        </div>
      </div>
    </div>
  )
}
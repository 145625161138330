import React from 'react'

export default function CheckButton({ handleClick }) {
  return (
    <button
      type      = "button"
      className = "btn btn-sm btn-primary"
      onClick   = {handleClick}
    >
      <i className="fas fa-check" />
    </button>
  )
}

import React from 'react'
import { useLocation } from 'react-router-dom'
import usePreviewRapor from 'context/preview-rapor'
import { avatarDefaultUser } from 'util/constant'
import styles from '../../styles'

export default function Kop() {
  /* ====================================== Consume Context ===================================== */
  const { rapor } = usePreviewRapor()
  const lsProfile = JSON.parse(localStorage.getItem('profile'))

  const Kelas = () => {
    /* ========================================== Helpers ========================================= */
    const useQuery = () => new URLSearchParams(useLocation().search)
    const query = useQuery()

    /* ======================================== Constants ======================================= */
    const semesterCode = query.get('semester').slice(0, 4)
    const diff = semesterCode - rapor.user?.ta_tahun_code

    /* ========================================= Output ========================================= */
    return diff === 0 ? 'X' : diff === 101 || diff === 102 ? 'XI' : 'XII'
  }

  /* ========================================== Output ========================================== */
  return (
    <>
      <div
        className="bg-header-kop"
        style={{
          ...styles.header,
          backgroundImage:
            lsProfile?.header_period || rapor.profile?.header_period
              ? `url(${
                  rapor.profile?.header_period || lsProfile.header_period
                })`
              : 'url(/image/bg-header2.png)',
        }}
      >
        <div 
          className='img-thumbnail rounded-circle border border-4 border-primary' 
          style={styles.avatar}>
            <div style={{
              background: rapor.user?.avatar ? `url(${rapor.user.linkAvatar})` : `url(${avatarDefaultUser})`,
              ...styles.avatarInner,
            }} />
          </div>
      </div>

      <div
        className="card mt-1 mb-1"
        style={styles.card}
      >
        <div className="card-body">
          <div className="row mt-3">
            <div className="col-6 text-center">
              <h3 className="text-capitalize">{rapor.user?.name}</h3>
              <h5 className="text-uppercase">NISN : {rapor.user?.nim}</h5>
            </div>
            <div className="col-6 text-center">
              <h3 className="text-uppercase">
                Kelas - {rapor.res ? <Kelas /> : '~'}
              </h3>
              <h5 className="text-uppercase">
                {rapor.profile?.name} - {rapor.profile?.location}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

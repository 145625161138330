/**
 * This new version wich added clear all notification
 */

import React, { useEffect, useState, useRef } from 'react'
import { useBaseContext } from 'context/base'
import FloatingFeedbackAlertItem from './item'
import BaseButton from 'component/button/BaseButton'

export default function FloatingFeedbackAlert() {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [contents, setContents] = useState([])
  const [count, setCount]       = useState(0)

  /* =========================================== Refs =========================================== */
  let isMounted = useRef(true)

  /* ========================================= Functions ======================================== */
  async function getFeedStatus() {
    const response = await getRequest('feed-status')
    if (response) {
      if (response.data.length !== 0) {
        if (isMounted.current === true) {
          setContents(response.data)
          setCount(response.countRemaining)
        }
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getFeedStatus()
    return () => {
      isMounted.current = false
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <>
      {count !== 0 ? (
        <div
          className="alert alert-warning shadow shake-animation f-16 f-w-700"
          style={{ width: '360px' }}
          onMouseEnter={(e) => e.currentTarget.classList.add('shake-animation')}
          onMouseLeave={(e) =>
            e.currentTarget.classList.remove('shake-animation')
          }
        >
          <i className="fas fa-exclamation-circle mr-1" />
          Anda belum memberikan
          <button
            className="btn btn-link p-0 ml-1 text-dark f-16 f-w-700"
            data-toggle="collapse"
            href="#contentFeedbackAlerts"
            role="button"
            aria-expanded="false"
            aria-controls="contentFeedbackAlerts"
          >
            {count} feedback
          </button>
          <div
            className="multi-collapse mt-2 collapse pr-2"
            id="contentFeedbackAlerts"
            style={{ maxHeight: '300px', overflowY: 'auto' }}
          >
            {contents.map((content, index) => (
              <FloatingFeedbackAlertItem
                key={index}
                item={content}
              />
            ))}
          </div>
          {/* <BaseButton variant='danger' props={{className: 'btn-sm mt-3'}}>
            <i className='feather icon-trash-2'></i> Hapus Semua Notifikasi
          </BaseButton> */}
        </div>
      ) : null}
    </>
  )
}

import React, { Fragment, useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import DataTable from 'component/data-table'
import InvalidText from 'component/invalid/text'

export default function DataTableGroupInstructor({
  kelasId,
  handleTeachers,
  handleShowRole,
  groupActive = true,
  withAction = true,
  withHeader = true,
  forceCreateDeleteGroupInstructorState,
  setForceCreateDeleteGroupInstructorState,
  showForceCreateDeleteGroupInstructorStateData = false,
  forceShowCreateDeleteGroupInstructor = false,
  actions=['addDeleteEntry', 'overview', 'atur-role', 'edit', 'delete']
}) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest, deleteRequest } = useBaseContext()

  /* ===================================== Datatable States ===================================== */
  const [data, setData]                 = useState(null)
  const [totalRecords, setTotalRecords] = useState(0)
  const [search, setSearch]             = useState('')
  const [limit, setLimit]               = useState(10)
  const [offset, setOffset]             = useState(0)
  const [columns, setColumns] = useState(['Grup', 'Guru', 'Status'])

  /* ======================================= Group States ======================================= */
  const [addGroup, setAddGroup]   = useState('')
  const [addStatus, setAddStatus] = useState('1')
  const [idGroup, setEditIdGroup] = useState('')

  const [editGroup, setEditGroup]   = useState('')
  const [editStatus, setEditStatus] = useState('1')

  const [isValidAddGroup, setIsValidAddGroup]   = useState(false)
  const [isValidEditGroup, setIsValidEditGroup] = useState(false)

  async function getData() {
    if (!showForceCreateDeleteGroupInstructorStateData) {
      let query = `limit=${limit}&offset=${offset}&search=${search}`
      if (groupActive) query = query + `&active=1`
      if (kelasId) query = query + `&class_id=${kelasId}`
      const response = await getRequest(`group-teachers?${query}`)
      if (response) {
        setData(response.data)
        setTotalRecords(response.totalRecords)
      }
    }
  }

  function setDataForceCreateDeleteGroupInstructorState() {
    if (showForceCreateDeleteGroupInstructorStateData) {
      setData(forceCreateDeleteGroupInstructorState)
      setTotalRecords(forceCreateDeleteGroupInstructorState.length)
    }
  }

  async function handleDeleteData(groupid) {
    Swal.fire({
      title : 'Apa anda yakin?',
      text  : 'Anda akan menghapus grup ini ',
      icon  : 'warning',
      showCancelButton   : true,
      confirmButtonColor : '#3085d6',
      cancelButtonColor  : '#d33',
      confirmButtonText  : 'Ya, hapus!',
      cancelButtonText   : 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`groups?id=${groupid}`)
        if (response) {
          getData()
          Swal.fire('', 'Group berhasil dihapus.', 'success')
        }
      }
    })
  }

  async function handleInsertToGroupClass(groupId) {
    let form = new FormData()
    form.append('group_id', groupId)
    form.append('class_id', kelasId)

    let response = await postRequest(`groups-class`, form)
    if (response) {
      window.notification('', 'Group berhasil ditambahkan ke kelas', 'success')
      getData()
    }
  }

  async function handleRemoveFromGroupClass(data) {
    let idGroupClass
    data.forEach((item) => {
      idGroupClass = item.id
    })

    let response = await deleteRequest(
      `groups-class?id=${idGroupClass}`,
    )
    if (response) {
      window.notification('', 'Group berhasil dihapus dari kelas', 'success')
      getData()
    }
  }

  async function handleAddSubmit() {
    if (addGroup) {
      let form = new FormData()
      form.append('group', addGroup)
      form.append('active', addStatus)
      form.append('group_type', 'teachers')
      let response = await postRequest('groups', form)
      if (response) {
        window.notification('', 'Group berhasil dibuat', 'success')
        document.getElementById('closeModal').click()
        getData()
        setAddGroup('')
      }
    } else {
      setIsValidAddGroup(true)
      window.notification('', 'Mohon lengkapi data', 'warning')
    }
  }

  async function handleEditSubmit() {
    if (editGroup) {
      let form = new FormData()
      form.append('id', idGroup)
      form.append('group', editGroup)
      form.append('active', editStatus)
      form.append('group_type', 'teachers')
      let response = await putRequest('groups', form)
      if (response) {
        window.notification('', 'Group berhasil diubah', 'success')
        document.getElementById('closeModalEdit').click()
        getData()
        setEditGroup('')
      }
    } else {
      setIsValidEditGroup(true)
      window.notification('', 'Mohon lengkapi data', 'warning')
    }
  }

  function isAddedForceCreateDeleteGroupInstructorCustomHandler(item) {
    if (Array.isArray(forceCreateDeleteGroupInstructorState) && forceCreateDeleteGroupInstructorState.length) {
      const copyState = [...forceCreateDeleteGroupInstructorState]
      const isExist = copyState.findIndex(i => i?.id === item?.id)

      return isExist > -1
    }

    return false
  }

  function addedForceCreateDeleteGroupInstructorCustomHandler(item) {
    setForceCreateDeleteGroupInstructorState([...forceCreateDeleteGroupInstructorState, item])
  }

  function toggleForceCreateDeleteGroupInstructorCustomHandler(item) {
    if (forceShowCreateDeleteGroupInstructor) {
      if (Array.isArray(forceCreateDeleteGroupInstructorState) && forceCreateDeleteGroupInstructorState.length) {
        const copyState = [...forceCreateDeleteGroupInstructorState]

        const isExist = isAddedForceCreateDeleteGroupInstructorCustomHandler(item)

        if (isExist) {
          const modifiedItems = copyState.filter(i => i?.id !== item?.id)
          setForceCreateDeleteGroupInstructorState(modifiedItems)
        } else {
          addedForceCreateDeleteGroupInstructorCustomHandler(item)
        }
        return
      }
      addedForceCreateDeleteGroupInstructorCustomHandler(item)
    }
  }

  useEffect(() => {
    setDataForceCreateDeleteGroupInstructorState()
  }, [setDataForceCreateDeleteGroupInstructorState, showForceCreateDeleteGroupInstructorStateData, forceCreateDeleteGroupInstructorState])

  useEffect(() => {
    if (withAction) {
      setColumns([...columns, 'Actions'])
    }
  }, [withAction])

  useEffect(() => {
    let isMounted = true
    if (isMounted) getData()

    return () => {
      isMounted = false
    }
  }, [search, limit, offset])

  return (
    <>
      <DataTable
        className={'table table-striped table-bordered nowrap'}
        data={data}
        totalRows={totalRecords}
        withHeader={withHeader}
        show={[10, 20, 30, 40, 50]}
        onChangeSearch={(val) => {
          setSearch(val)
        }}
        onChangeLimit={(val) => {
          setLimit(val)
        }}
        onChangePage={(val) => {
          setOffset(val)
        }}
        column={columns}
        renderItem={(item, i) => (
          <RenderItem
            item={item}
            key={i}
            handleInsertToGroupClass={handleInsertToGroupClass}
            handleRemoveFromGroupClass={handleRemoveFromGroupClass}
            handleDeleteData={handleDeleteData}
            setEditIdGroup={setEditIdGroup}
            setEditGroup={setEditGroup}
            setEditStatus={setEditStatus}
            handleTeachers={handleTeachers}
            handleShowRole={handleShowRole}
            kelasId={kelasId}
            withAction={withAction}
            forceShowCreateDeleteGroupInstructor={forceShowCreateDeleteGroupInstructor}
            toggleForceCreateDeleteGroupInstructorCustomHandler={toggleForceCreateDeleteGroupInstructorCustomHandler}
            isAddedForceCreateDeleteGroupInstructorCustomHandler={isAddedForceCreateDeleteGroupInstructorCustomHandler}
            actions={actions}
          />
        )}
      />
      
      {/* ======ADD MODAL===== */}
      <div
        className="modal fade"
        id="add-modal-group"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Tambah Grup</h5>
              <button
                onClick={() => {
                  setAddGroup('')
                  setIsValidAddGroup(false)
                }}
                id="closeModal"
                type="button"
                className="close text-danger"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <div className="form-group row mb-4">
                          <label
                            className="col-sm-3 col-form-label"
                            htmlFor="addNameGroupInput"
                          >
                            <span className="mr-1">Nama grup</span>
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              value={addGroup}
                              onChange={(val) => setAddGroup(val.target.value)}
                              className={`${
                                isValidAddGroup && 'is-invalid'
                              } form-control`}
                              id="addNameGroupInput"
                              placeholder="Nama grup"
                            />
                            {isValidAddGroup && <InvalidText name="Nama group" />}
                          </div>
                        </div>
                        <fieldset className="form-group mb-4">
                          <div className="row">
                            <label className="col-sm-3 col-form-label">
                              <span className="mr-1">Status</span>
                            </label>
                            <div className="col-sm-9">
                              <div className="form-check">
                                <input
                                  onChange={(val) =>
                                    setAddStatus(val.target.value)
                                  }
                                  className="form-check-input"
                                  type="radio"
                                  name="active"
                                  id="activeGroupAdd"
                                  defaultValue="1"
                                  checked={addStatus == '1'}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="activeGroupAdd"
                                >
                                  Aktif
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  onChange={(val) =>
                                    setAddStatus(val.target.value)
                                  }
                                  className="form-check-input"
                                  type="radio"
                                  name="active"
                                  id="inactiveGroupAdd"
                                  defaultValue="0"
                                  checked={addStatus == '0'}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inactiveGroupAdd"
                                >
                                  Tidak Aktif
                                </label>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer p-2">
              <button
                type="button"
                onClick={() => {
                  setAddGroup('')
                  setIsValidAddGroup(false)
                }}
                className="btn btn-danger mr-2"
                data-dismiss="modal"
              >
                Tutup
              </button>
              <button
                type="button"
                onClick={() => handleAddSubmit()}
                className="btn btn-primary"
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* =======END MODAL ADD====== */}

      {/* ======EDIT MODAL===== */}
      <div
        className="modal fade"
        id="edit-modal-group"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Grup</h5>
              <button
                id="closeModalEdit"
                onClick={() => {
                  setEditGroup('')
                  setIsValidEditGroup(false)
                }}
                type="button"
                className="close text-danger"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <div className="form-group row mb-4">
                          <label
                            className="col-sm-3 col-form-label"
                            htmlFor="editNameGroupInput"
                          >
                            <span className="mr-1">Nama grup</span>
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              value={editGroup}
                              onChange={(val) => setEditGroup(val.target.value)}
                              className={`${
                                isValidEditGroup && 'is-invalid'
                              } form-control`}
                              id="editNameGroupInput"
                              placeholder="Nama grup"
                            />
                            {isValidEditGroup && <InvalidText name="Nama grup" />}
                          </div>
                        </div>
                        <fieldset className="form-group mb-4">
                          <div className="row">
                            <label className="col-sm-3 col-form-label">
                              <span className="mr-1">Status</span>
                            </label>
                            <div className="col-sm-9">
                              <div className="form-check">
                                <input
                                  onChange={(val) =>
                                    setEditStatus(val.target.value)
                                  }
                                  className="form-check-input"
                                  type="radio"
                                  name="active-edit"
                                  id="activeGroup"
                                  defaultValue="1"
                                  checked={editStatus == '1'}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="activeGroup"
                                >
                                  Aktif
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  onChange={(val) =>
                                    setEditStatus(val.target.value)
                                  }
                                  className="form-check-input"
                                  type="radio"
                                  name="active-edit"
                                  id="inactiveGroup"
                                  defaultValue="0"
                                  checked={editStatus == '0'}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inactiveGroup"
                                >
                                  Tidak Aktif
                                </label>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer p-2">
              <button
                type="button"
                onClick={() => {
                  setEditGroup('')
                  setIsValidEditGroup(false)
                }}
                className="btn btn-danger mr-2"
                data-dismiss="modal"
              >
                Tutup
              </button>
              <button
                type="button"
                onClick={() => handleEditSubmit()}
                className="btn btn-primary"
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* =======END MODAL EDIT====== */}
    </>
  )
}

const RenderItem = ({
  item,
  handleInsertToGroupClass,
  handleRemoveFromGroupClass,
  setEditGroup,
  setEditIdGroup,
  setEditStatus,
  handleTeachers,
  handleShowRole,
  handleDeleteData,
  kelasId,
  withAction = true,
  forceShowCreateDeleteGroupInstructor = false,
  toggleForceCreateDeleteGroupInstructorCustomHandler,
  isAddedForceCreateDeleteGroupInstructorCustomHandler,
  actions = ['addDeleteEntry', 'overview', 'atur-role', 'edit', 'delete']
}) => {
  return (
    <tr>
      {/* <td>{index + 1}</td> */}
      <td>{item.group}</td>
      {/* <td>{item.id}</td> */}
      <td>
        <ul className="ml-0 pl-0">
          {item.groupusers?.every((groupuser) => !groupuser?.user?.id) && (
            <li className='text-muted'>(Belum Ada Guru yang Ditambahkan)</li>
          )}
          {item.groupusers.map((val, index) => {
            const Badge = () =>
              val.role ? (
                <sup>
                  <span className="badge badge-primary"> Utama </span>
                </sup>
              ) : null
            return (
              val?.user && (
                <li key={index}>
                  {val?.user?.name} <Badge />{' '}
                </li>
              )
            )
          })}
        </ul>
      </td>
      <td>
        {item.active ? (
          <span className="badge badge-success">Aktif</span>
        ) : (
          <span className="badge badge-secondary">Tidak Aktif</span>
        )}
      </td>
      {withAction
      ?
        <td>
          {actions.map((action, index) => (
            <Fragment key={`action-item-index${index}`}>
              {kelasId && action === 'addDeleteEntry' ? (
                <>
                  {item.groupclasses.length == 0 ? (
                    <button
                      title="Tambah Grup ke Sub Mata Pelajaran"
                      onClick={() => handleInsertToGroupClass(item.id)}
                      type="button"
                      className="btn btn-sm btn-outline-success mr-2"
                    >
                      <i className="fas fa-plus" />
                    </button>
                  ) : (
                    <button
                      title="Hapus Grup dari Sub Mata Pelajaran"
                      onClick={() => handleRemoveFromGroupClass(item.groupclasses)}
                      type="button"
                      className="btn btn-sm btn-outline-danger mr-2"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  )}
                </>
              ) : null}
              {forceShowCreateDeleteGroupInstructor && action === 'addDeleteEntry' ? (
                <>
                  {!isAddedForceCreateDeleteGroupInstructorCustomHandler(item) ? (
                    <button
                      title="Tambah Grup ke Sub Mata Pelajaran"
                      onClick={() => toggleForceCreateDeleteGroupInstructorCustomHandler(item)}
                      type="button"
                      className="btn btn-sm btn-outline-success mr-2"
                    >
                      <i className="fas fa-plus" />
                    </button>
                  ) : (
                    <button
                      title="Hapus Grup dari Sub Mata Pelajaran"
                      onClick={() => toggleForceCreateDeleteGroupInstructorCustomHandler(item)}
                      type="button"
                      className="btn btn-sm btn-outline-danger mr-2"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  )}
                </>
              ) : null}

              {action === 'edit'
              ?
                <button
                  title="Edit"
                  onClick={() => {
                    setEditGroup(item.group)
                    setEditStatus(item.active ? '1' : '0')
                    setEditIdGroup(item.id)
                  }}
                  data-toggle="modal"
                  data-target="#edit-modal-group"
                  type="button"
                  className="btn btn-sm btn-success mr-2"
                >
                  <i className="feather icon-edit" />
                  {kelasId ? '' : <span className="ml-2">Edit</span>}
                </button>
              : null }

              {action === 'overview'
              ?
                <button
                  title="Overview"
                  onClick={() => {
                    handleTeachers(item)
                  }}
                  type="button"
                  className="btn btn-sm btn-warning mr-2"
                >
                  <i className="fas fa-eye" />
                  {kelasId ? '' : <span className="ml-2">Overview</span>}
                </button>
              : null }

              {action === 'atur-role'
              ?
                <button
                  title="Atur Role"
                  onClick={() => handleShowRole(item)}
                  type="button"
                  data-id={item.id}
                  className="btn btn-sm btn-primary mr-2"
                >
                  <i className="feather icon-settings" />
                  {kelasId ? '' : <span className="ml-2">Atur Role</span>}
                </button>
              : null}

              {action === 'delete'
              ?
                <button
                  title="Hapus"
                  onClick={() => {
                    handleDeleteData(item.id)
                  }}
                  type="button"
                  data-id={item.id}
                  className="btn btn-sm btn-danger sweet-multiple"
                >
                  <i className="feather icon-trash-2" />
                  {kelasId ? '' : <span className="ml-2">Hapus</span>}
                </button>
              : null }
            </Fragment>
          ))}
          {/* <div className="overlay-edit">
                </div> */}
        </td>
      : null }
    </tr>
  )
}

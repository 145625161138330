import React, { useState, useEffect, useCallback } from "react";
import { useBaseContext } from "context/base";
import DataTable from "component/data-table";
import "datatables.net";
import ReactSelect from "react-select";
// import './highChart.css'

export default function DashboardAdmin() {
  const { getRequest } = useBaseContext();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [siswaTop5, setSiswaTop5] = useState([]);
  const [listAssigmentUser, setListAssignmentUser] = useState([]);
  const [optionsProfile, setOptionsProfile] = useState([]);
  const [totalAssigmentUser, setTotalAssigmentUser] = useState([]);
  const [optionsClasses, setOptionsClasses] = useState([]);
  const [filterProfile, setFilterProfile] = useState({label: 'Filter berdasarkan profile', value: ''});
  const [filterClass, setFilterClass] = useState({label: 'Filter berdasarkan sub mapel', value: ''});
  const [filterStatus, setFilterStatus] = useState({label: 'Filter berdasarkan status', value: 0});
  const getDashboard = useCallback(async () => {
    let res = await getRequest(
      `dashboard-assignment?limit=${limit}&offset=${offset}&profile_id=${filterProfile?.value}&sub_id=${filterClass?.value}&is_complete=${filterStatus.value}&search=${keyword}`
    );
    if (res) {
      setListAssignmentUser(res.data);
      setTotalAssigmentUser(res.totalRecord);
      let arr = [];
      res.profile.map((i) => arr.push({ value: i.id, label: i.name }));
      setOptionsProfile(arr);
      let arrClass = [];
      res.classes.map((i) => arrClass.push({ value: i.id, label: i.title }));
      setOptionsClasses(arrClass);
    }
  }, [limit, offset, filterProfile?.value, filterClass?.value, filterStatus?.value, keyword]);

  useEffect(() => {
    getDashboard();
  }, [getDashboard]);
  async function getDashboardAdministrator() {
    let response = await getRequest("dashboard-administrator");
    if (response) {
      // setUserAktif(response.data.total_user)
      // setMapel(response.data.kelas.chart)
      // setNewKelas(response.data.kelas.new_kelas.jml)
      // setNewContent(response.data.kelas.new_content.jml)
      // setTotalKonten(response.data.total_konten)
      // setSiswaOnProgress(response.data.siswa_progress.jml)
      setSiswaTop5(response.data.top_point);
      // setClassCategory(response.data.total_category)
      // setTotal({
      //     jml_total_user: response.data.jml_total_user,
      //     jml_total_kelas: response.data.jml_total_kelas,
      //     jml_total_konten: response.data.jml_total_konten
      // })
    }
  }
  useEffect(() => {
    let isSubscribed = true;
    if (
      JSON.parse(localStorage.getItem("role_active"))?.role_type ===
      "administrator"
    ) {
      if (isSubscribed) {
        getDashboardAdministrator();
      }
    }

    return () => (isSubscribed = false);
  }, []);

  const handleResetFilter = () =>{
    setFilterProfile({label: 'Filter berdasarkan profile', value: ''})
    setFilterClass({label: 'Filter berdasarkan sub mapel', value: ''})
    setFilterStatus({label: 'Filter berdasarkan status', value: 0})
  }
  return (
    <div className="row">
      {/* <div className="col-md-4">
                <div className="card">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <HighchartsReact highcharts={Highcharts} options={{
                                    chart: {
                                        plotBackgroundColor: null,
                                        plotBorderWidth: null,
                                        plotShadow: false,
                                        type: 'pie',
                                    },
                                    title: {
                                        text: 'Sebaran user berdasarkan role'
                                    },
                                    tooltip: {
                                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                                    },
                                    accessibility: {
                                        point: {
                                            valueSuffix: '%'
                                        }
                                    },
                                    plotOptions: {
                                        pie: {
                                            allowPointSelect: true,
                                            cursor: 'pointer',
                                            dataLabels: {
                                                enabled: true,
                                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                                            }
                                        }
                                    },
                                    series: [{
                                        name: 'Users',
                                        colorByPoint: true,
                                        data: userAktif
                                    }]
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer bg-c-yellow">
                        <div className="row align-items-center">
                            <div className="col-9">
                                <p className="text-white m-b-0">User Aktif ({total.jml_total_user} user)</p>
                            </div>
                            <div className="col-3 text-right">
                                <i className="feather icon-trending-up text-white f-16" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-8">
                <div className="card">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-md-8">
                                <HighchartsReact highcharts={Highcharts} options={{
                                    chart: {
                                        plotBackgroundColor: null,
                                        plotBorderWidth: null,
                                        plotShadow: false,
                                        type: 'pie'
                                    },
                                    title: {
                                        text: 'Data Mata Pelajaran'
                                    },
                                    tooltip: {
                                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                                    },
                                    accessibility: {
                                        point: {
                                            valueSuffix: '%'
                                        }
                                    },
                                    plotOptions: {
                                        pie: {
                                            allowPointSelect: true,
                                            cursor: 'pointer',
                                            colors: ['#00ACC2', '#20C997'],
                                            dataLabels: {
                                                enabled: true,
                                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                                            }
                                        }
                                    },
                                    series: [{
                                        name: 'Mata Pelajaran',
                                        colorByPoint: true,
                                        data: mapel
                                    }]
                                }} />
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row align-items-center">
                                                    <div className="col-8">
                                                        <h4 className="text-c-yellow">{newKelas}</h4>
                                                        <h6 className="text-muted m-b-0">Mata Pelajaran Baru</h6>
                                                    </div>
                                                    <div className="col-4 text-right">
                                                        <i className="feather icon-bar-chart-2 f-28" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row align-items-center">
                                                    <div className="col-8">
                                                        <h4 className="text-c-yellow">{newContent}</h4>
                                                        <h6 className="text-muted m-b-0">Materi Baru</h6>
                                                    </div>
                                                    <div className="col-4 text-right">
                                                        <i className="feather icon-bar-chart-2 f-28" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer bg-c-yellow">
                        <div className="row align-items-center">
                            <div className="col-9">
                                <p className="text-white m-b-0">Mata pelajaran ({total.jml_total_kelas} mata pelajaran)</p>
                            </div>
                            <div className="col-3 text-right">
                                <i className="feather icon-trending-up text-white f-16" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-5">
                <div className="card">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <HighchartsReact highcharts={Highcharts} options={{
                                    chart: {
                                        plotBackgroundColor: null,
                                        plotBorderWidth: null,
                                        plotShadow: false,
                                        type: 'pie'
                                    },
                                    title: {
                                        text: 'Sebaran Materi Berdasarkan Jenis Materi'
                                    },
                                    tooltip: {
                                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                                    },
                                    accessibility: {
                                        point: {
                                            valueSuffix: '%'
                                        }
                                    },
                                    plotOptions: {
                                        pie: {
                                            allowPointSelect: true,
                                            cursor: 'pointer',
                                            dataLabels: {
                                                enabled: true,
                                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                                            }
                                        }
                                    },
                                    series: [{
                                        name: 'Konten',
                                        colorByPoint: true,
                                        data: totalKonten
                                    }]
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer bg-c-yellow">
                        <div className="row align-items-center">
                            <div className="col-9">
                                <p className="text-white m-b-0">Total Materi ({total.jml_total_konten} Materi)</p>
                            </div>
                            <div className="col-3 text-right">
                                <i className="feather icon-trending-up text-white f-16" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            {/* <div className="row align-items-center"> */}
            <div className="col-md-12 dt-responsive table-responsive">
              <h5>Top 5 dalam 30 hari terakhir</h5>
              <br />
              <table className="table m-b-0 f-14 b-solid requid-table">
                <thead>
                  <tr className="text-uppercase">
                    <th>Top</th>
                    <th>Nim</th>
                    <th>Nama</th>
                    <th>Poin</th>
                  </tr>
                </thead>
                <tbody className="text-muted">
                  {siswaTop5.map((val, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{val.nim}</td>
                        <td>{val.name}</td>
                        <td>{val.total_point}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
            <h5>List siswa upload ulang tugas</h5>
            
            <button className="btn btn-danger" onClick={handleResetFilter}>Reset</button>
            </div>
           
            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label>Profile</label>
                  <ReactSelect
                    options={optionsProfile}
                    value={filterProfile}
                    onChange={(e) => setFilterProfile(e)}
                    placeholder="Filter berdasarkan profile"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label>Sub Mapel</label>
                  <ReactSelect
                    options={optionsClasses}
                    value={filterClass}
                    onChange={(e) => setFilterClass(e)}
                    placeholder="Filter berdasarkan sub mapel"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label>Status</label>
                  <ReactSelect
                    options={[
                      { label: "Complete", value: 1 },
                      { label: "Uncomplete", value: 2 },
                    ]}
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e)}
                    placeholder="Filter berdasarkan status"
                  />
                </div>
              </div>
              {/* <div className="col-3">
              <div className="form-group">
              <button className="btn btn-primary">Reset</button>
              </div>
                
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-md-12 dt-responsive table-responsive">
                <DataTable
                  //   className={"table table-striped table-bordered nowrap"}
                  data={listAssigmentUser}
                  totalRows={totalAssigmentUser}
                  show={[10, 20, 30, 40, 50]}
                  onChangeSearch={(val) => setKeyword(val)}
                  onChangeLimit={(val) => setLimit(val)}
                  onChangePage={(val) => setOffset(val)}
                  column={[
                    "Nama",
                    "No. Induk",
                    "Status",
                    "Sub Mapel",
                    "Sections",
                    "Contents",
                  ]}
                  renderItem={(val, index) => (
                    <tr>
                      <td>{val.name}</td>
                      <td>{val.nim}</td>
                      <td>
                        {parseInt(val.complete_reupload) === 1 ? (
                          <span className="badge badge-success">Complete</span>
                        ) : (
                          <span className="badge badge-danger">Uncomplete</span>
                        )}
                      </td>
                      <td>{val.submapel}</td>
                      <td>{val.sections}</td>
                      <td>{val.contents}</td>
                    </tr>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="col-md-3">
                <div className="card">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <HighchartsReact highcharts={Highcharts} options={{
                                    chart: {
                                        plotBackgroundColor: null,
                                        plotBorderWidth: 0,
                                        plotShadow: false
                                    },
                                    title: {
                                        text: 'Sub Mata Pelajaran by Mata Pelajaran',
                                        align: 'center',
                                        verticalAlign: 'middle',
                                        y: 60
                                    },
                                    tooltip: {
                                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                                    },
                                    accessibility: {
                                        point: {
                                            valueSuffix: '%'
                                        }
                                    },
                                    plotOptions: {
                                        pie: {
                                            dataLabels: {
                                                enabled: true,
                                                distance: -50,
                                                style: {
                                                    fontWeight: 'bold',
                                                    color: 'white'
                                                }
                                            },
                                            startAngle: -90,
                                            endAngle: 90,
                                            center: ['50%', '75%'],
                                            size: '110%'
                                        }
                                    },
                                    series: [{
                                        type: 'pie',
                                        name: 'Browser share',
                                        innerSize: '50%',
                                        data: classCategory
                                    }]
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer bg-c-yellow">
                        <div className="row align-items-center">
                            <div className="col-9">
                                <p className="text-white m-b-0">Sub Mata Pelajaran berdasarkan Mata Pelajaran</p>
                            </div>
                            <div className="col-3 text-right">
                                <i className="feather icon-trending-up text-white f-16" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <h4 className="text-c-yellow">{siswaOnProgress}</h4>
                                <h6 className="text-muted m-b-0"></h6>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer bg-c-yellow">
                        <div className="row align-items-center">
                            <div className="col-9">
                                <p className="text-white m-b-0">Siswa on Progress</p>
                            </div>
                            <div className="col-3 text-right">
                                <i className="feather icon-trending-up text-white f-16" />
                            </div>
                        </div>
                    </div>
                </div>

            </div> */}
    </div>
  );
}

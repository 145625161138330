import React from 'react'
import useChatContext from 'context/chat'

export default function BtnCloseReply() {
  const { reply } = useChatContext()
  return (
    <i
      className="fas fa-times-circle"
      id="reply-container__close-button"
      onClick={() => reply.reset()}
    />
  )
}

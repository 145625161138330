// BY: https://codepen.io/zainzafar/pen/wvQyGwG

import React, { useEffect } from "react";
import "./BaseRainbowButton.css"

export default function BaseRainbowButton({ title = 'Rainbow Button', ...props }) {

  function onMouseMoveHandler(e, root) {
    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    root.style.setProperty("--gradient-button-rainbow-v2-pos-x", `${x}%`);
    root.style.setProperty("--gradient-button-rainbow-v2-pos-y", `${y}%`);
  }

  function onMouseOutHandler(root) {
    root.style.setProperty("--gradient-button-rainbow-v2-pos-x", `50%`);
    root.style.setProperty("--gradient-button-rainbow-v2-pos-y", `50%`);
  }

  useEffect(() => {
    const root = document.querySelector(":root");
    const button = document.querySelector(".button-rainbow-v2");
    console.log(root, button)

    if (root && button) {
      button.addEventListener("mousemove", e => onMouseMoveHandler(e, root));
      button.addEventListener("mouseout", () => onMouseOutHandler(root));

      return () => {
        button.removeEventListener("mousemove", e => onMouseMoveHandler(e, root));
        button.removeEventListener("mouseout", () => onMouseOutHandler(root));
      }
    }
  }, [])

  return (
    <button className="button-rainbow-v2" {...props}>
      <div className="neon">
        <div className="gradient">
        </div>
      </div>
      <div className="border">
        <div className="gradient"></div>
      </div>
      <div className="content">
        {title}
      </div>
    </button>
  )
}
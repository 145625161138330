import React from 'react'
import TableIntervalPredikat from 'component/rapor/table-interval-predikat'

export default function RaporSkeleton({
  tableConfig = { headers: [], totalColumns: 0 },
  title = '',
  bodyPlaceholder,
}) {
  return (
    <>
      <div className="row">
        <div className="col">
          <h2 className="f-24">{title}</h2>
          <div className="text-secondary">
            <span>
              <i className="fas fa-graduation-cap mr-1" />
              Semester/Tahun Ajaran
            </span>
            <span className="ml-3">
              <i className="fas fa-user-graduate mr-1" />
              {'0'}
              {' siswa'}
            </span>
            <span className="ml-3">
              <i className="fas fa-user-friends mr-1" />
              Kelas: -
            </span>
          </div>
          <hr />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h5>A. Tabel Interval Predikat</h5>
          <TableIntervalPredikat />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          {/* <h5>B. Pengetahuan</h5> */}
          <div className="table-responsive rounded mt-2">
            <table className="table nowrap">
              <thead>
                <tr>
                  {tableConfig.headers.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    colSpan={tableConfig.totalColumns}
                    className="text-center"
                  >
                    {bodyPlaceholder}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import useContentPlayerContext from 'context/content-player'
import useContentContainerContext from 'context/content-container'
import ContentType from 'component/content/content-type'
import BadgeProgress from './badge-progress'

const ContentEnabled = () => {
  /* ====================================== Consume Context ===================================== */
  const { activity } = useContentPlayerContext()
  const { content, classId, refSection, currentContentId } =
    useContentContainerContext()

  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* =========================================== Refs =========================================== */
  const refContent = useRef()

  /* ======================================= Local States ======================================= */
  const [isSelected, setIsSelected] = useState(false)
  const [isHovered, setIsHovered]   = useState(false)

  // ACTIVITIES STATE
  const [contentActivities, setContentActivities] = useState([])
  const [isCompleted, setIsCompleted]             = useState(false)

  /* ========================================= Functions ======================================== */
  function goToContentPlayer() {
    history.push(`/katalog-kelas/${classId}/content-player`, {
      dataContent: content,
      classId: classId,
    })
  }

  function checkCurrentContent() {
    if (currentContentId === content.id) {
      setIsSelected(true)
      if (refSection.current.ariaExpanded === 'false') {
        refSection.current.click()
      }
    } else {
      setIsSelected(false)
    }
  }

  function checkIsCompleted() {
    const _isCompleted = contentActivities.some((ca) => ca.completed_at)
    setIsCompleted(_isCompleted)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    setContentActivities(content.class_content_activities)
  }, [])

  useEffect(() => {
    checkIsCompleted()
  }, [contentActivities])

  useEffect(() => {
    checkCurrentContent()
  }, [currentContentId])

  useEffect(() => {
    if (activity.idDone === content.id) {
      setIsCompleted(true)
    }
  }, [activity.idDone])

  /* ========================================== Output ========================================== */
  return (
    <li
      ref={refContent}
      style={{
        backgroundColor: isSelected
          ? 'rgba(0, 188, 212, 0.1)'
          : 'transparent',
      }}
    >
      <button
        onClick={goToContentPlayer}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        type="button"
        className="btn btn-sm p-3 f-16 text-left text-dark border-bottom"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: isHovered
            ? 'rgba(221, 221, 221, 0.2)'
            : 'transparent',
        }}
      >
        <div className="row">
          <div className="col-2 mr-0 pr-0">
            <div className="custom-control custom-checkbox mt-1">
              <input
                type="checkbox"
                className="custom-control-input"
                checked={isCompleted}
                disabled={false}
                onChange={() => {}}
              />
              <label className="custom-control-label" />
            </div>
          </div>
          <div className="col-10 pl-0 ml-0">
            <span className={`text-dark d-block f-16`}>
              {content.urutan}
              {'. '}
              {content.title}
            </span>
            <span className="d-block">
              {/* <i className={`${checkContentType(content.content_type_id)} ${isSelected ? "text-primary" : "text-muted"} f-12 mr-1`} /> */}
              <ContentType id={content.content_type_id} /> <BadgeProgress />
              {/* <span className="f-12 text-muted">{content.durasi/60} menit</span> */}
              {content.durasi ? (
                <span className="f-12 text-muted ml-2">
                  <i className="feather icon-clock f-10 mr-1" />
                  {Math.round(content.durasi / 60)} menit
                </span>
              ) : null}
            </span>
          </div>
        </div>
      </button>
    </li>
  )
}

export default ContentEnabled

import ContentType from "component/content/content-type";
import ContentDuration from "component/content/content-type/content-duration";
import { useState } from "react";

/**
 * Grade List Content Item Props
 * 
 * @param {{
 *  item: Object
 *  index: number
 *  access?: boolean
 *  isShowAction?: boolean
 *  isShowActionCreate?: boolean
 *  isShowActionEdit?: boolean
 *  isSelectedGradeExistInContent?: boolean
 *  onClickCreateOrEditChildContentHandler: (item: Object) => void
 *  onClickRemoveChildContentHandler: (item: Object) => void
 * }} props
 * @returns 
 */
export default function GradeListContentItem({ 
  item, 
  index, 
  access,
  isShowAction = true,
  isShowActionCreate = true,
  isShowActionEdit = true,
  isSelectedGradeExistInContent = false,
  onClickCreateOrEditChildContentHandler, 
  onClickRemoveChildContentHandler 
}) {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <li>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="p-3 f-16 text-left text-dark"
        style={{
          width: '100%',
          height: '75px',
          backgroundColor: isHovered
            ? 'rgba(221, 221, 221, 0.2)'
            : 'transparent',
        }}
      >
        <div className="row">
          <div className="col d-flex justify-content-between align-items-center">
            <span
              style={{
                display: 'inline-block',
              }}
            >
              <span className={`text-dark d-block f-16`}>
                {item?.urutan}
                {'. '}
                {item?.title}{' '}
                {Array.isArray(item?.content_grade_contents) && item?.content_grade_contents?.length
                ?
                  item?.content_grade_contents?.map((g, index) => (
                    <span key={`list-grade${g?.id}-index${index}`} className="badge badge-primary mr-1">Grade {g?.content_grade?.name}</span>
                  ))
                : null}
              </span>
              <span className="d-block">
                <ContentType id={item?.content_type_id} />
                {item?.durasi
                ?
                  <ContentDuration durasi={item?.durasi} />
                : null}
              </span>
            </span>
            
            {isShowAction
            ?
              <span>
                {isShowActionCreate
                ?
                  <button
                    type="button"
                    className={`btn btn-sm btn-primary mr-2`}
                    onClick={() => onClickCreateOrEditChildContentHandler(item)}
                    disabled={access}
                  >
                    <i className="feather icon-edit-2 mr-1" />
                    <span>Buat</span>
                  </button>
                :null}

                {isSelectedGradeExistInContent
                ?
                <>
                {isShowActionEdit
                ?
                  <button
                    type="button"
                    className={`btn btn-sm btn-warning mr-2`}
                    onClick={() => onClickCreateOrEditChildContentHandler(item, true)}
                    disabled={access}
                  >
                    <i className="feather icon-edit mr-1" />
                    <span>Edit</span>
                  </button>
                :null}
                  <button
                    type="button"
                    className={`btn btn-sm btn-danger mr-2`}
                    onClick={() => onClickRemoveChildContentHandler(item)}
                    disabled={access}
                  >
                    <i className="feather icon-trash mr-1" />
                    <span>Hapus</span>
                  </button>
                </>
                :null}
              </span>
            :null}
          </div>
        </div>
      </div>
    </li>
  )
}
import React, { useEffect, useState } from 'react'
import { useBaseContext } from 'context/base'
import moment from 'moment'
import Swal from 'sweetalert2'
import useChatContext from 'context/chat'
import FileChatPreviewer from './partials/file-chat-previewer'
import ChatReplyPreviewer from './partials/chat-reply-previewer'

const ChatItem = ({ chatItem, chatId }) => {
  /* ====================================== Consume Context ===================================== */
  const { deleteRequest } = useBaseContext()
  const { messages, reply } = useChatContext()

  /* ====================================== Constant & Ref ====================================== */
  const defaultAvatar = '/admin/assets/images/user/blank-avatar-user.svg'
  
  /* ========================================== States ========================================== */
  const [isMessageFromMe, setIsMessageFromMe] = useState(false)
  const [timestamp, setTimestamp]             = useState('')

  /* ========================================= Functions ======================================== */
  function checkIsMessageFromUser() {
    const { id } = JSON.parse(localStorage.getItem('user'))
    if (chatItem.user_id === id) {
      setIsMessageFromMe(true)
    } else {
      setIsMessageFromMe(false)
    }
  }

  function checkIsMessageToday() {
    const todayDate = moment(new Date()).format('DD-MM-YYYY')
    const chatDate = moment(chatItem.timestamps).format('DD-MM-YYYY')
    if (chatDate === todayDate) {
      setTimestamp(moment(chatItem.timestamps).format('HH:mm'))
    } else {
      setTimestamp(moment(chatItem.timestamps).format('DD/MM/YYYY, HH:mm'))
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    checkIsMessageFromUser()
    checkIsMessageToday()
  }, [chatItem])

  /* ======================================== Components ======================================== */
  const Options = () => {
    async function deleteChat() {
      Swal.fire({
        title              : 'Apa anda yakin?',
        text               : `Anda akan menghapus chat ini!`,
        icon               : 'warning',
        showCancelButton   : true,
        confirmButtonColor : '#3085d6',
        cancelButtonColor  : '#d33',
        confirmButtonText  : 'Ya, hapus!',
        cancelButtonText   : 'Tidak',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await deleteRequest(
            `chat?_id=${chatItem._id}`,
          )
          if (response) {
            Swal.fire('Berhasil', 'Chat berhasil dihapus.', 'success').then(
              () => messages.delete(chatId, chatItem._id),
            )
          } else {
            Swal.fire(
              'Oppsss!',
              'Terjadi kesalahan coba beberapa saat lagi.',
              'error',
            ).then(() => console.error('ERROR', response))
          }
        }
      })
    }

    const ChevronDown = () => {
      return isMessageFromMe ? (
        <i className="feather icon-chevron-down text-light" />
      ) : (
        <i className="feather icon-chevron-down text-bold" />
      )
    }

    return (
      <div className="btn-group">
        <button
          type          = "button"
          className     = "btn p-0"
          data-toggle   = "dropdown"
          aria-haspopup = "true"
          aria-expanded = "false"
        >
          <ChevronDown />
        </button>
        <div className={
          "dropdown-menu dropdown-menu-" 
          + (isMessageFromMe ? 'right' : 'left')
        }>
          <a
            onClick   = {() => { reply.set(chatItem) }}
            className = "dropdown-item"
            type      = "button"
          >
            <i className="fas fa-reply mr-1" />
            Balas
          </a>
          {
            isMessageFromMe && (
              <a
                onClick   = {deleteChat}
                className = "dropdown-item"
                type      = "button"
              >
                <i className="feather icon-trash-2 mr-1" />
                Hapus
              </a>
            )
          }
        </div>
      </div>
    )
  }

  const ChatMessage = () => {
    return (
      <>
        {chatItem.reply_from && (
          <ChatReplyPreviewer chatItem={chatItem.reply_from} isMessageFromMe={isMessageFromMe} />
        )}
        <FileChatPreviewer
          mimeTypes = {chatItem.type_text}
          link      = {chatItem.media}
        />
        <div>{chatItem.text}</div>
      </>
    )
  }

  return (
    <>
      {isMessageFromMe ? (
        <div
          className="row m-b-20 send-chat"
        >
          <div className="col">
            <div className="msg pt-1">
              <div className="d-flex justify-content-between align-items-center">
                <Options />
                <p className="text-right f-w-700 ml-3 mb-0 f-10">Anda</p>
              </div>
              <p className="mb-2 f-14"><ChatMessage /></p>
              <p className="text-light m-b-0 f-10">{timestamp}</p>
            </div>
          </div>
          <div className="col-auto p-l-0">
            <img
              src={defaultAvatar}
              style={{ display: 'none' }}
              alt={chatItem.name}
              className="img-radius wid-40 mt-1"
            />
          </div>
        </div>
      ) : (
        <div
          className="row m-b-20 received-chat"
        >
          <div className="col-auto p-r-0 d-flex flex-column justify-content-center align-items-center">
            <img
              src={defaultAvatar}
              style={{ display: 'none' }}
              alt={chatItem.name}
              className="img-radius wid-40"
            />
          </div>
          <div className="col">
            <div className="msg">
              <div className="d-flex justify-content-between align-items-center">
                <p className="f-w-700 mb-0 f-10">{chatItem.name}</p>
                <Options />
              </div>
              <p className="mb-2"><ChatMessage /></p>
              <p className="text-muted m-b-0 f-10">{timestamp}</p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ChatItem

import React, { useEffect, useState } from 'react'
import { useBaseContext } from 'context/base'
import {
  GraphorGroupReadonlyAnswer,
  GraphorGroupReadonlyCorrect,
} from 'component/graphor/graphor-group-readonly'

export default function GraphorGroup({ item }) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [realAnswers, setRealAnswers] = useState([])
  const [jawabanSiswa, setJawabanSiswa] = useState([])

  /* ========================================= Functions ======================================== */
  async function getDataById() {
    let response = await getRequest('graphors?id=' + item.value_id)
    if (response) {
      if (response.column) {
        let groups = []
        for (const [key, value] of Object.entries(response.column)) {
          groups.push({
            name: key,
            list: value.map((val) => ({
              graphor_answer: {
                text: val,
              },
            })),
          })
        }
        setRealAnswers(groups)
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (item?.jawaban_essay_siswa) {
      getDataById()
      var data = JSON.parse(item?.jawaban_essay_siswa)
      let answers = []
      for (const [key, value] of Object.entries(data)) {
        answers.push({
          name: key,
          list: value.map((val) => ({
            answer_text: val,
          })),
        })
      }
      setJawabanSiswa(answers)
    }
  }, [item])

  /* ========================================== Output ========================================== */
  return (
    <>
      <h5 className="text-center">Jawaban Siswa</h5>
      <GraphorGroupReadonlyAnswer groups={jawabanSiswa} />
      <br />
      <br />
      <h5 className="text-center">Jawaban Benar</h5>
      <GraphorGroupReadonlyCorrect groups={realAnswers} />
    </>
  )
}

import React,{useState} from "react";
import TableIntervalPredikat from "component/rapor/table-interval-predikat";
import RaporUserItem from "./rapor-user-item";

export default function RaporSuccess({ 
  tableConfig = { headers: [], totalColumns: 0 }, 
  result, 
  taTahun, 
  category_id,
  semester_id,
  class_id,
  period_id,
  subMapelName,
  handleSearch = async (category_id, group_id) => {}, 
}) {
  /* ========================================= Constants ======================================== */
  const reportGroupId = {
    keterampilan: 'cc60064e-0b84-4c2d-8afd-4bd9f703694a',
    pengetahuan: '5ebcf01d-9217-4578-a5c6-17f84b28c9ed',
  };
  const emptyPlaceholder = 'Tidak ada siswa terdaftar di mata pelajaran';

  /* ======================================= Local States ======================================= */
  const [catatan, setCatatan] = useState('');
  const [updateAt, setUpdateAt] = useState('');

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className='row'>
        <div className='col'>
          <h2 className='f-24'>{subMapelName? subMapelName.label:"-"}</h2>
          <div className='text-secondary'>
            <span>
              <i className='fas fa-graduation-cap mr-1' />
              {result?.semester} {result?.tahun_ajaran}
            </span>
            <span className='ml-3'>
              <i className='fas fa-user-graduate mr-1' />
              {result?.users.length}
              {" siswa"}
            </span>
            {result?.group_title ? (
              <span className='ml-3'>
                <i className='fas fa-user-friends mr-1' />
                Kelas: {result.group_title}
              </span>
            ) : null}
          </div>
          <hr />
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col'>
          <h5>A. Tabel Interval Predikat</h5>
          <TableIntervalPredikat />
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col'>
          {/* <h5>B. Pengetahuan</h5> */}
          <div className='table-responsive rounded mt-2'>
            <table className='table nowrap'>
              <thead>
                <tr>
                  {tableConfig.headers.map((header, index) => <th key={index}>{header}</th>)}
                </tr>
              </thead>
              <tbody>
                {result?.users?.length > 0 ? (
                  <>
                    {result.users.map((user, index) => (
                      <RaporUserItem
                        key={`${index}-${user.user_id}-${user.categories_id}-pengetahuan`}
                        totalColumns={tableConfig.totalColumns}
                        item={user}
                        number={index+1}
                        taTahun={taTahun}
                        semester_id={semester_id}
                        categoryId={category_id}
                        class_id={class_id}
                        period_id={period_id}
                        reportGroupId={reportGroupId.pengetahuan}
                        catatan={catatan}
                        setCatatan={setCatatan}
                        updateAt={updateAt}
                        setUpdateAt={setUpdateAt}
                      />
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={tableConfig.totalColumns} className='text-center'>
                      {emptyPlaceholder}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

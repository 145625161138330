import React, { useMemo } from 'react'

import { Col, Nav, Row, Tab } from 'react-bootstrap'
import ConfigQuiz from 'pages/config/components/contents/ConfigQuiz'

/**
 * Tabs
 * 
 * @param {{ items: Array }} props 
 * @returns JSX
 */
export default function Tabs({ items }) {
  /* --------------------------------- RENDER --------------------------------- */
  const renderTabPane = useMemo(() => {
    return items.map(l => {
      switch (l.key) {
        case 'quiz':
          return (
          <Tab.Pane key={l.key} eventKey={l.key} className='mt-5'>
            <ConfigQuiz />
          </Tab.Pane>
          )
        default:
          return (
          <Tab.Pane key={l.key} eventKey={l.key} className='mt-5'>
            <p className='mt-5'>Data tidak di temukan.</p>
          </Tab.Pane>
          )
      }
    })
  }, [items])

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey={items[0].key}>
      <Row>
        {items.map(l => (
          <Col key={l.key} style={{ backgroundColor: 'inherit' }}>
            <Nav variant='tabs' className="flex-column">
              <Nav.Item className='shadow-none'>
                <Nav.Link eventKey={l.key}>{l.label}</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        ))}
      </Row>
      <Row>
        <Col sm={12}>
          <Tab.Content>
            {renderTabPane}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  )
}
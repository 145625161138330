import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'

export default function KelolaGradeAdd() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [grade, setGrade]           = useState('')
  const [persentasi, setPersentasi] = useState(0)
  const [status, setStatus]         = useState(1)
  const [tipePenilaian, setTipePenilaian]   = useState('')

  /* ========================================= Functions ======================================== */
  async function onSubmitForm(data) {
    if (
      grade != '' &&
      persentasi != '' &&
      status != '' &&
      tipePenilaian != '' &&
      /^[0-9]+$/.test(persentasi)
    ) {
      let form = new FormData()
      form.append('type_score', grade)
      form.append('type_score_type', tipePenilaian)
      form.append('active', status)
      form.append('percentage', persentasi)
      let response = await postRequest('type-score', form)
      if (response) {
        window.notification(200, 'Data grade berhasil tersimpan', 'success')
        history.goBack()
      }
    } else {
      window.notification(400, 'Harap isi semua form', 'error')
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Tambah Grade"
          linkName="Tambah"
          links={[{ url: '/kelola-grade', name: 'Kelola Grade' }]}
        />
        
        <div className="row">
          {/* [ Form Validation ] start */}
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Tambah Grade</h5>
              </div>
              <div className="card-body">
                <form>
                  <div className="form-group">
                    <label className="form-label">Grade</label>
                    <input
                      type="text"
                      onChange={(e) => setGrade(e.target.value)}
                      className="form-control col-md-4"
                      name="role"
                      placeholder="Nama Grade"
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">Persentasi</label>
                    <input
                      type="number"
                      onChange={(e) => setPersentasi(e.target.value)}
                      className="form-control col-md-4"
                      name="role"
                      placeholder="Persentasi"
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">Tipe Penilaian</label>
                    <select
                      className="form-control col-md-4"
                      onChange={(e) => setTipePenilaian(e.target.value)}
                      name=""
                    >
                      <option>Pilih Tipe Penilaian</option>
                      <option value={'harian'}>Harian</option>
                      <option value={'ujian_tulis'}>Ujian Tulis</option>
                      <option value={'ujian_praktik'}>Ujian Praktik</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Status</label>
                    <select
                      className="form-control col-md-4"
                      defaultValue={1}
                      onChange={(e) => setStatus(e.target.value)}
                      name=""
                    >
                      <option value={1}>Aktif</option>
                      <option value={0}>Tidak Aktif</option>
                    </select>
                  </div>
                  <button
                    type="button"
                    onClick={(e) => {
                      history.goBack()
                    }}
                    className="btn btn-outline-danger mr-2"
                  >
                    Kembali
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault()
                      onSubmitForm()
                    }}
                    className="btn  btn-primary"
                  >
                    Simpan
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React, { useState, useEffect } from 'react'

export default function UserItem({
  item,
  user,
  number,
  context,
  categoryId,
  reportGroupId,
  nilai,
  nilaiK,
  nilaiP,
  semester_id,
  handleChecklist = ({
    event,
    item,
    taSemesterCode,
    currentListChecklist = [],
    setListChecklist,
    setChecklistAll,
  }) => {},
  currentListChecklist = [],
  setListChecklist,
  setChecklistAll,
}) {

  /* ======================================= Local States ======================================= */
  const [loadingScoreTemp, setLoadingScoreTemp] = useState('idle')  // idle / start / finish
  const [score, setScore]                       = useState('')
  const [predicate, setPredicate]               = useState('')
  const [description, setDescription]           = useState('')
  const [approvalStatus, setApprovalStatus]     = useState('')
  const [badge, setBadge]                       = useState('')
  const [isChecked, setIsChecked]               = useState(false)
  const [rowData, setRowData]                   = useState(null)

  /* ========================================= Functions ======================================== */
  async function getScoreTemp(user_id, category_id, reportGroupId) {
    const response = await context.getRequest(
      `report-temp-by-id-group?id=${reportGroupId}&user_id=${user_id}&category_id=${category_id}&ta_semester_code=${semester_id}`,
    )
    return response ? response.data : null
  }

  const calculatePredikat = (score) => {
    score = parseInt(score)
    if (score >= 90) {
      return 'A'
    } else if (score >= 80 && score <= 89) {
      return 'B'
    } else if (score >= 70 && score <= 79) {
      return 'C'
    } else {
      return 'D'
    }
  }

  function resetStates() {
    setScore('')
    setPredicate('')
    setDescription('')
    setApprovalStatus('')
  }

  async function initScoreData(item, reportGroupId) {
    setLoadingScoreTemp('start')
    const data = item
    setRowData(data)
    if (typeof data === 'object' && data !== null) {
      setScore(data.score_final)
      setPredicate('-')
      setDescription('-')
      if (data.status === 'draft') {
        setBadge('badge-warning')
        setApprovalStatus('Masih dalam proses input')
      } else if (data.status === 'approved') {
        setBadge('badge-success')
        setApprovalStatus('disetujui')
      } else if (data.status === 'rejected') {
        setBadge('badge-danger')
        setApprovalStatus('Nilai ditolak')
      } else if (data.status === 'submission') {
        setBadge('badge-warning')
        setApprovalStatus('Menunggu persetujuan')
      } else {
        setBadge('badge-danger')
        setApprovalStatus('Belum ada proses input')
      }
    } else {
      resetStates()
      setBadge('badge-danger')
      setApprovalStatus('Belum ada proses input')
    }
    setLoadingScoreTemp('finish')
  }

  function initChecked(currentListChecklist = [], item) {
    setIsChecked(false)
    if (currentListChecklist.length > 0) {
      for (let currentChecklist of currentListChecklist) {
        if (currentChecklist.user_id === item?.user_id) {
          setIsChecked(true)
          break
        }
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    initScoreData(item, reportGroupId)
  }, [categoryId, item])

  useEffect(() => {
    initChecked(currentListChecklist, item)
  }, [currentListChecklist])

  /* ======================================== Components ======================================== */
  function CheckBoxInput() {
    if (item?.status === 'submission' || item?.status === 'approved') {
      return (
        <input
          disabled={item?.status !== 'submission'}
          type="checkbox"
          className="form-control"
          style={{ width: 16, height: 16, cursor: 'pointer' }}
          checked={isChecked}
          onChange={(event) => {
            handleChecklist({
              event: event,
              item: { ...item, user },
              taSemesterCode: item?.ta_semester_code,
              currentListChecklist: currentListChecklist,
              setListChecklist: setListChecklist,
              setChecklistAll: setChecklistAll,
            })
          }}
        />
      )
    } else {
      return null
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <tr>
      <td>
        <CheckBoxInput />
      </td>
      <td className="text-center">{number}</td>
      <td className="text-center">{user.nim}</td>
      <td className="text-center">{user.name}</td>
      <td>
        <span className={`badge ${badge}`}>{approvalStatus}</span>
      </td>
      {loadingScoreTemp === 'idle' && <td colSpan={3}></td>}
      {loadingScoreTemp === 'start' && (
        <td colSpan={3}>
          <div className="d-flex justify-content-center align-items-center">
            <span
              className="spinner-border text-primary"
              role="status"
              style={{
                height: '22px',
                width: '22px',
              }}
            />
          </div>
        </td>
      )}
      {loadingScoreTemp === 'finish' && (
        <>
          <td className="text-center">{nilaiK ? nilaiK : '-'}</td>
          <td className="text-center">
            {calculatePredikat(nilaiK ? nilaiK : 0)}
          </td>
          <td className="text-center">{nilaiP ? nilaiP : '-'}</td>
          <td className="text-center">
            {calculatePredikat(nilaiP ? nilaiP : 0)}
          </td>
        </>
      )}
    </tr>
  )
}

import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import ContentType from 'component/content/content-type'
import ContentDuration from 'component/content/content-type/content-duration'
import BadgeProgress from 'component/badge/progress'
import BaseButton from 'component/button/BaseButton'
import useGetRoleActive from 'hooks/useGetRoleActive'

const Content = ({ item, setEventTrigger, siswaId, classId, isGuruPengampu }) => {

  /* ====================================== Consume Context ===================================== */
  const { postRequest, putRequest } = useBaseContext()

  /* =================================== Consume Local Storage ================================== */
  const { role_type } = JSON.parse(localStorage.getItem('role_active'))
  const roleActive = useGetRoleActive()

  /* ======================================= Local States ======================================= */
  const [isHovered, setIsHovered] = useState(false)
  const [isStarted, setIsStarted] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [isLoadingFixed, setIsLoadingFixed] = useState(false);

  /* ========================================= Functions ======================================== */
  function checkContentStatus() {
    let complete = false
    item.class_content_activities.forEach((activity) => {
      if (activity.completed_at !== null) {
        complete = true
      }
    })
    setIsCompleted(complete)
  }

  async function confirmCheckComplete() {
    setEventTrigger('')
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
      input: 'number',
      inputLabel: 'Masukkan nilai:',
      // confirmButtonColor: "#9ccc65",
      titleText: 'Tandai materi selesai',
      text: `Konfirmasi ${item.title} selesai?`,
      inputAttributes: {
        min: 0,
        max: 100,
      },
      icon: 'question',
      inputValidator: (value) => {
        if (!value) {
          return 'Silahkan masukkan nilainya terlebih dahulu!'
        } else if (+value < 0) {
          return 'Nilai tidak boleh kurang dari 0'
        } else if (+value > 100) {
          return 'Nilai tidak boleh lebih dari 100'
        }
      }
    }).then(async (event) => {
      if (event.isConfirmed) {
        await completeContentActivity(event.value)
      }
      if (event.isDismissed) {
        setEventTrigger('')
      }
    })
  }

  async function completeContentActivity(score) {
    if (item.id) {
      let form = new FormData()
      form.append('score', score)
      form.append('class_id', classId)
      form.append('user_id', siswaId)
      form.append('content_id', item.id)
      form.append('is_complete', 1)
      form.append('completed_by', 'instructor')
      const response = await postRequest(
        'class-content-activities',
        form,
      )
      if (response) {
        setIsCompleted(true)
        setEventTrigger('content-activity-update')
      } else {
        console.error({ response })
      }
    }
  }

  /* -------------------------------- HANDLERS -------------------------------- */
  const onFixDataHandler = async () => {
    Swal.fire({
      title: 'Apakah Anda yakin untuk sinkronisasi data?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2778c4',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Ya!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Menyinkronkan data...',
          html: `<div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                  </div>`,
          allowOutsideClick: false,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
        })

        setIsLoadingFixed(true);
        if (item.id) {
          const payload = {
            user_id: siswaId,
            class_id: classId,
            content_id: item?.id,
          };

          const response = await putRequest(
            'sync-class-activities',
            payload,
          )
          if (response) {
            setIsLoadingFixed(false);

            Swal.fire({
              title: 'Berhasil Sinkronisasi Data',
              icon: 'success',
              allowOutsideClick: false,
              showCloseButton: false,
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: 'Ok',
              confirmButtonColor: '#2778c4',
            }).then(result => {
              if (result.isConfirmed) {
                setEventTrigger('content-activity-update')
              }
            })
          } else {
            setIsLoadingFixed(false);
            console.error({ response })
          }
        } else {
          setIsLoadingFixed(false);
        }
      }
    });
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (item.class_content_activities.length !== 0) {
      setIsStarted(true)
      checkContentStatus()
    } else {
      setIsStarted(false)
    }
  }, [item.class_content_activities, item])

  /* ========================================== Output ========================================== */
  return (
    <li>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="p-3 f-16 text-left text-dark"
        style={{
          width: '100%',
          height: '75px',
          backgroundColor: isHovered
            ? 'rgba(221, 221, 221, 0.2)'
            : 'transparent',
        }}
      >
        <div className="row">
          <div className="col d-flex justify-content-between align-items-center">
            <span
              style={{
                display: 'inline-block',
              }}
            >
              <span className={`text-dark d-block f-16`}>
                {item.urutan}
                {'. '}
                {item.title}{' '}
                <BadgeProgress {...{ content: item }} />
              </span>
              <span className="d-block">
                <ContentType id={item.content_type_id} />
                <ContentDuration durasi={item.durasi} />
              </span>
            </span>
            {role_type === 'administrator' ||
              role_type === 'adminprofile' ||
              isGuruPengampu ? (
              <span className="d-flex align-items-center" style={{ gap: 10 }}>
                <BaseButton props={{ className: 'btn-sm', title: 'Sinkronisasi data' }} onClick={onFixDataHandler} isLoading={isLoadingFixed} isShow={roleActive.type === 'administrator'}><i className='fas fa-tools'></i></BaseButton>
                <div className="custom-control custom-checkbox">
                  <input
                    id={item.id}
                    type="checkbox"
                    className="custom-control-input"
                    disabled={isStarted}
                    checked={isCompleted}
                    onChange={() => {
                      if (isCompleted === false) {
                        confirmCheckComplete()
                      }
                    }}
                    title="Tandai selesai"
                  />
                  <label
                    htmlFor={item.id}
                    className="custom-control-label"
                    style={{ width: '100%', height: '100%' }}
                    title="Tandai selesai"
                  />
                </div>
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </li>
  )
}

export default Content

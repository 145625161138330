import React from 'react'
import useContentPlayerContext from 'context/content-player'
import EmptySection from './empty-section'
import Section from '../section'
import SectionSequential from '../section-sequential'

export default function SegmentPlaylist() {
  /* ====================================== Consume Context ===================================== */
  const { content, allContents, playlist, kelas, activity } = useContentPlayerContext()

  /* ========================================== Output ========================================== */
  return (
    <div className="col-xl-4">
      <h5 style={{ display: playlist.isShow ? 'block' : 'none' }}>Materi :</h5>
      <div
        style={{ display: playlist.isShow ? 'block' : 'none' }}
        className="accordion border rounded"
        id="konten-accordion"
      >
        {kelas.displayBy === 'any_order' && (
          <>
            {kelas.data?.sections?.length !== 0 ? (
              kelas.data?.sections?.map((section, index) => (
                <Section
                  key={section?.id}
                  item={section}
                  index={index}
                  currentContentId={content?.id}
                  idDone={activity.idDone}
                />
              ))
            ) : (
              <EmptySection />
            )}
          </>
        )}
        {kelas.displayBy === 'sequential_order' && (
          <>
            {kelas.data?.sections?.length !== 0 ? (
              kelas.data?.sections?.map((section, index) => (
                <SectionSequential
                  key={section.id}
                  item={section}
                  index={index}
                  currentContentId={content.id}
                  idDone={activity.idDone}
                  allContents={allContents}
                />
              ))
            ) : (
              <EmptySection />
            )}
          </>
        )}
        {!kelas.displayBy && (
          <>
            {kelas.data?.sections?.length ? (
              kelas.data?.sections.map((section, index) => (
                <Section
                  key={section.id}
                  item={section}
                  index={index}
                  currentContentId={content?.id}
                />
              ))
            ) : (
              <EmptySection />
            )}
          </>
        )}
      </div>
    </div>
  )
}


const useFile = () => {
  /**
   * 
   * @param {{
   *  blobData: any,
   *  fileName: string,
   * }} params
   */
  function downloadFile({ blobData, fileName }) {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";

    const url = window.URL.createObjectURL(blobData);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  return {
    downloadFile
  }
}

export default useFile
import React, { useEffect, useState } from 'react'
import { useBaseContext } from 'context/base'
import DataTable from 'component/data-table'
import TableUserItem from './table-user-item'

const TableUsers = ({
  loadingTypes,
  setLoadingState,
  onShowModal,
  onShowModalBlock,
}) => {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const tableConfig = {
    headers: ['Nama', 'No. Induk', 'Username', 'Actions'],
    limits: [10, 20, 30, 40, 50],
    totalColumns: 4,
  }

  /* ======================================= Local States ======================================= */
  const [users, setUsers] = useState([])
  const [totalRecord, setTotalRecord] = useState(0)

  /* ========================================= Functions ======================================== */
  async function getSiswaList() {
    const url = `report-siswa-list`
    const response = await getRequest(url)
    return response
      ? { users: response.data.users, totalRecord: response.data.totalRecord }
      : null
  }

  async function init() {
    setLoadingState(loadingTypes.start)
    const data = await getSiswaList()
    setUsers(data === null ? [] : data.users)
    setTotalRecord(data === null ? 0 : data.totalRecord)
    setLoadingState(data === null ? loadingTypes.error : loadingTypes.success)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    init()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div className="user-profile-list dt-responsive table-responsive mt-3">
      <DataTable
        className={'table table-striped table-bordered nowrap'}
        data={users}
        totalRows={totalRecord}
        show={tableConfig.limits}
        onChangeSearch={(val) => {}}
        onChangeLimit={(val) => {}}
        onChangePage={(val) => {}}
        column={tableConfig.headers}
        renderItem={(item, index) => (
          <TableUserItem
            key={index}
            id={item.id}
            name={item.name}
            nim={item.nim}
            username={item.username}
            onShowModal={onShowModal}
            onShowModalBlock={onShowModalBlock}
          />
        )}
      />
    </div>
  )
}

export default TableUsers

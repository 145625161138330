import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import 'datatables.net'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'

export default function ProfileSuperAdmin() {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, putRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [data, setData] = useState([])

  /* ========================================= Functions ======================================== */
  async function getProfile() {
    let response = await getRequest('profile')
    if (response != null) {
      setData(response.data)
      window.$('#tables').DataTable()
    } else {
      setData(null)
    }
  }

  /* ======================================== Components ======================================== */
  function RenderItem({ item }) {
    return (
      <tr>
        <td>{item.code ? item.code : <i className="feather icon-minus" />}</td>
        <td>{item.name ? item.name : <i className="feather icon-minus" />}</td>
        <td>{item.desc ? item.desc : <i className="feather icon-minus" />}</td>
        <td>
          {item.active != null ? (
            item.active ? (
              <span className="badge badge-light-success">Aktif</span>
            ) : (
              <span className="badge badge-light-danger">Tidak Aktif</span>
            )
          ) : (
            <i className="feather icon-minus" />
          )}
        </td>
        <td>
          {item.active ? (
            <button
              onClick={() => setActive(0, item.id)}
              type={'button'}
              className="btn btn-warning btn-sm"
            >
              Non Aktifkan
            </button>
          ) : (
            <button
              type={'button'}
              onClick={() => setActive(1, item.id)}
              className="btn btn-info btn-sm"
            >
              Aktifkan
            </button>
          )}
        </td>
      </tr>
    )

    async function setActive(status, id) {
      let form = new FormData()
      form.append('active', status)
      form.append('profile_id', id)
      let response = await putRequest('profile-update', form)
      if (response) {
        getProfile()
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getProfile()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Profile" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <Link to="/add-profiles">
                  <button
                    type="button"
                    className="btn btn-primary m-b-20 has-ripple"
                  >
                    <i className="feather icon-user-plus" /> Tambah Profile{' '}
                    <span
                      className="ripple ripple-animate"
                      style={{
                        height: '109.297px',
                        width: '109.297px',
                        animationDuration: '0.7s',
                        animationTimingFunction: 'linear',
                        background: 'rgb(255, 255, 255)',
                        opacity: '0.4',
                        top: '-40.6485px',
                        left: '-1.6485px',
                      }}
                    />
                  </button>
                </Link>
              </div>
              <div className="card-body">
                <div className="dt-responsive table-responsive">
                  <table
                    id="tables"
                    className="table table-striped table-bordered nowrap"
                  >
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Profile</th>
                        <th>Deskripsi</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data ? (
                        data.map((val, i) => (
                          <RenderItem
                            item={val}
                            key={i}
                          />
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={11}
                            style={{ textAlign: 'center' }}
                          >
                            Data Users tidak ditemukan
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Code</th>
                        <th>Profile</th>
                        <th>Deskripsi</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

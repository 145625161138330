import React from 'react'

export default function TableIntervalPredikat() {
  return (
    <div className="table-responsive">
      <table className="table table-bordered nowrap">
        <tbody>
          <tr className="text-center">
            <th>D = Kurang</th>
            <th>C = Cukup</th>
            <th>B = Baik</th>
            <th>A = Sangat Baik</th>
          </tr>
          <tr className="text-center">
            <td>{'< 70'}</td>
            <td>70 &#8804; 79</td>
            <td>80 &#8804; 89</td>
            <td>90 &#8804; 100</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

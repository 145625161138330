import React, { useEffect } from 'react'

import useContentPlayerContext, { ContentPlayerWrapper } from 'context/content-player'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container } from 'component/template'
import LoadingSpinner from 'component/loading'
import ContentPlayerHeader from './partials/content-player-header'
import SegmentForum from './partials/segment-forum'
import SegmentHeader from './partials/segment-header'
import SegmentPlaylist from './partials/segment-playlist'
import SegmentMainContentPlayer from './partials/segment-main-content-player'

const ContentPlayer = () => {
  /* ===================================== Consume Contexts ===================================== */
  const { content, tab, kelas, notes, keyTakeAway } = useContentPlayerContext()

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (notes.list === null && tab.current == 3) {
      notes.fetch()
    }
    if (keyTakeAway.data === null && tab.current == 2) {
      keyTakeAway.fetch()
    }
  }, [tab.current])

  useEffect(() => {
    notes.fetch()
    keyTakeAway.fetch()
  }, [content?.id])

  useEffect(() => {
    notes.resetList()
    keyTakeAway.reset()
  }, [content?.id])
  

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <ContentPlayerHeader />
        <Row>
          <Col>
            <Card>
              <SegmentHeader />
              <CardBody>
                {kelas.loading && <LoadingSpinner />}
                {kelas.loading || (
                  <Row>
                    <SegmentMainContentPlayer />
                    <SegmentPlaylist />
                  </Row>
                )}
              </CardBody>
            </Card>
            <SegmentForum />
          </Col>
        </Row>
      </Container>
    </TemplateAdmin>
  )
}

/* ========================================= Components ========================================= */
const Row      = ({ children }) => <div className = "row">{children}</div>
const Col      = ({ children }) => <div className = "col-sm-12">{children}</div>
const Card     = ({ children }) => <div className = "card">{children}</div>
const CardBody = ({ children }) => <div className = "card-body">{children}</div>

const ContentPlayerSiswa = () => (
  <ContentPlayerWrapper>
    <ContentPlayer />
  </ContentPlayerWrapper>
)

export default ContentPlayerSiswa

import React, { useEffect } from 'react'
import useContentPlayerContext from 'context/content-player'
import useMainContent from 'context/main-content'

const ContentIFrame = ({ isPreview = false, content_text, contents_url, content_source }) => {
  /* ====================================== Consume Context ===================================== */
  const { content, activity } = useContentPlayerContext()
  const { shouldSendCCA } = useMainContent()

  const using_contents_url = contents_url || content.contents_url

  useEffect(() => {
    if (isPreview === false) {
      shouldSendCCA && activity.setIdDone(content.id)
    }
  }, [shouldSendCCA, activity]);

  /* ========================================== Output ========================================== */
  return (
    <>
      <iframe
        key={content.id}
        src={using_contents_url}
        style={{
          width: '100%',
          height: '100vh',
          border: 'none',
        }}
      />
    </>
  )
}

export default ContentIFrame

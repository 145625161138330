import moment from 'moment'
import React, { useEffect, useState } from 'react'

const FormInputEndedAt = ({ handleChangeEndedAt, endedAt }) => {
  /* ======================================= Local States ======================================= */
  const [dateNow, setDateNow] = useState('')

  /* ========================================= Functions ======================================== */
  function getDateNow() {
    var today = new Date()
    var dd    = String(today.getDate()).padStart(2, '0')
    var mm    = String(today.getMonth() + 1).padStart(2, '0')  // January is 0!
    var yyyy  = today.getFullYear()

    today = `${yyyy}-${mm}-${dd}`
    setDateNow(today)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getDateNow()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div className="form-group mb-5 row">
      <label className="col-sm-3 col-form-label">Tanggal Selesai</label>
      <div className="col-sm-3 d-flex flex-row">
        <input
          style={{ width: '100%' }}
          type="datetime-local"
          min={dateNow}
          onChange={(event) => handleChangeEndedAt(event)}
          value={moment(endedAt).format('YYYY-MM-DDTHH:mm:ss')}
          className="form-control"
        />
      </div>
    </div>
  )
}

export default FormInputEndedAt

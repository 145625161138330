import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

export default function RenderItem({ item }) {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ======================================= Local States ======================================= */
  const [progress, setProgress]   = useState(0)
  const [isStarted, setIsStarted] = useState(false)
  const [isDone, setIsDone]       = useState(false)

  /* ========================================= Functions ======================================== */
  function checkProgress() {
    if (item.total_content > 0) {
      const prog = Math.round(
        (parseInt(item.in_progress) / parseInt(item.total_content)) * 100,
      )
      setProgress(prog)
    }
  }

  function checkClassStatus() {
    if (item.class_activity !== null) {
      setIsStarted(true)
      if (item.class_activity.completed_at !== null) {
        setIsDone(true)
      } else {
        setIsDone(false)
      }
    } else {
      setIsStarted(false)
      setIsDone(false)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    checkClassStatus()
    checkProgress()
  }, [item])

  /* ========================================== Output ========================================== */
  return (
    <tr title={item.title || 'Kelas'}>
      <td>{item.title || '-'}</td>
      <td>{item.class_profiles[0].category?.title || '-'}</td>
      <td>
        {isStarted ? (
          <>
            {isDone ? (
              <span className="badge badge-success mb-2">Selesai</span>
            ) : (
              <span className="badge badge-info mb-2">Sedang Dikerjakan</span>
            )}
          </>
        ) : (
          <span className="badge badge-secondary mb-2">Belum Mulai</span>
        )}
      </td>
      <td>
        <div className="progress hei-20">
          {progress == 0 ? (
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `100%`, backgroundColor: '#ecf0f5' }}
              aria-valuenow={`100%`}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span
                className="text-dark"
                style={{ fontSize: '12px', fontWeight: 'bold' }}
              >
                {progress}%
              </span>
            </div>
          ) : (
            <>
              {progress == 100 ? (
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: `${progress}%` }}
                  aria-valuenow={`${progress}`}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                    {progress}%
                  </span>
                </div>
              ) : (
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  style={{ width: `${progress}%` }}
                  aria-valuenow={`${progress}`}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                    {progress}%
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </td>
      <td>
        <i className="feather icon-more-horizontal" />
        <div className="overlay-edit d-flex justify-content-start">
          <button
            onClick={() => history.push(`/katalog-kelas/${item.id}`)}
            type="button"
            title="Lihat Sub Mata Pelajaran"
            className="btn btn-icon btn-info"
          >
            <i className="fas fa-arrow-right" />
          </button>
        </div>
      </td>
    </tr>
  )
}

import React, { useRef, useState } from 'react'
import { useBaseContext } from 'context/base'

export default function ChangePassword() {
  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [loading, setLoading] = useState(false)

  const [oldPassword, setOldPassword]       = useState('')
  const [newPassword, setNewPassword]       = useState('')
  const [retypePassword, setretypePassword] = useState('')

  const [hideOldPass, setHideOldPass]     = useState(true)
  const [hideNewPass, setHideNewPass]     = useState(true)
  const [hideRtypePass, setHideRtypePass] = useState(true)

  /* =========================================== Refs =========================================== */
  const refOldPassword    = useRef()
  const refNewPassword    = useRef()
  const refRetypePassword = useRef()

  /* ========================================= Functions ======================================== */
  async function saveNewPassword() {
    if (oldPassword && newPassword) {
      if (newPassword == retypePassword) {
        setLoading(true)
        var form = new FormData()
        form.append('password_lama', oldPassword)
        form.append('password_baru', newPassword)

        let response = await postRequest('change-password', form)
        if (response.message == 'password tidak cocok') {
          setLoading(false)
          refOldPassword.current.focus()
          window.notification(
            'Gagal',
            'Password lama anda salah, silahkan masukan kembali password lama anda',
            'error',
          )
        } else {
          setLoading(false)
          window.notification(
            'Berhasil',
            'Password baru anda sudah kami simpan, silahkan login untuk masuk menggunakan akun anda',
            'success',
          )
          window.location.reload()
        }
      } else {
        refRetypePassword.current.focus()
        window.notification(
          'Gagal',
          'Konfirmasi password harus sama dengan password baru anda',
          'error',
        )
      }
    } else {
      if (!oldPassword) {
        refOldPassword.current.focus()
        window.notification(
          'Gagal',
          'Kata sandi lama tidak boleh kosong',
          'error',
        )
      } else if (!newPassword) {
        refNewPassword.current.focus()
        window.notification(
          'Gagal',
          'Kata sandi baru tidak boleh kosong',
          'error',
        )
      }
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <div className="card">
      <div className="card-body d-flex align-items-center justify-content-between">
        <h5 className="mb-0">Ubah Kata Sandi</h5>
      </div>
      <div className="card-body border-top">
        <form>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label font-weight-bolder">
              Kata sandi lama
            </label>
            <div className="col-sm-9 row">
              <div className="col-10 pr-0">
                <div className="form-group mb-3">
                  <input
                    ref={refOldPassword}
                    value={oldPassword}
                    onChange={(val) => {
                      setOldPassword(val.target.value)
                    }}
                    placeholder="Masukan kata sandi lama"
                    type={hideOldPass ? 'password' : 'text'}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-2 pl-0">
                <div className="form-group mt-2">
                  <button
                    onClick={() => setHideOldPass(!hideOldPass)}
                    className="btn btn-light btn-sm rounded-circle"
                    type="button"
                  >
                    {hideOldPass ? (
                      <i className="fa fa-eye pt-1" />
                    ) : (
                      <i className="fa fa-eye-slash pt-1" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="form-group row">
            <label className="col-sm-3 col-form-label font-weight-bolder">
              Kata sandi baru
            </label>
            <div className="col-sm-9 row">
              <div className="col-10 pr-0">
                <div className="form-group mb-3">
                  <input
                    ref={refNewPassword}
                    value={newPassword}
                    onChange={(val) => {
                      setNewPassword(val.target.value)
                    }}
                    placeholder="Masukan kata sandi baru"
                    type={hideNewPass ? 'password' : 'text'}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-2 pl-0">
                <div className="form-group mt-2">
                  <button
                    onClick={() => setHideNewPass(!hideNewPass)}
                    className="btn btn-light btn-sm rounded-circle"
                    type="button"
                  >
                    {hideNewPass ? (
                      <i className="fa fa-eye pt-1" />
                    ) : (
                      <i className="fa fa-eye-slash pt-1" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label font-weight-bolder">
              Konfirmasi kata sandi
            </label>
            <div className="col-sm-9 row">
              <div className="col-10 pr-0">
                <div className="form-group mb-3">
                  <input
                    ref={refRetypePassword}
                    value={retypePassword}
                    onChange={(val) => {
                      setretypePassword(val.target.value)
                    }}
                    placeholder="Masukan ulang kata sandi"
                    type={hideRtypePass ? 'password' : 'text'}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-2 pl-0">
                <div className="form-group mt-2">
                  <button
                    onClick={() =>
                      setHideRtypePass(!hideRtypePass)
                    }
                    className="btn btn-light btn-sm rounded-circle"
                    type="button"
                  >
                    {hideRtypePass ? (
                      <i className="fa fa-eye pt-1" />
                    ) : (
                      <i className="fa fa-eye-slash pt-1" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row mt-5 mr-1">
            <label className="col-sm-3 col-form-label" />
            <div className="col-sm-9 text-right">
              <button
                disabled={loading}
                onClick={() => {
                  saveNewPassword()
                }}
                type="button"
                className="btn btn-primary"
              >
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  />
                )}
                <span>Simpan</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useBaseContext } from 'context/base'
import submitHandler from './handler/submit'

export default function useFormPenilaianEdit(assignmentData) {
  const { putRequest } = useBaseContext()
  const { register } = useForm()
  const { assignmentUser } = assignmentData
  const { score, note } = assignmentUser

  //========================= States ==========================================
  const [isEdit, setIsEdit] = useState(false)
  const [isHistory, setIsHistory] = useState(false)
  const [loading, setLoading] = useState(false)

  //========================= Handlers ========================================
  const handlers = {
    handleSubmit: (data) =>
      submitHandler(data?.newNote, data?.newScore, putRequest, setLoading, setIsEdit, assignmentData),
  }

  //========================= Effects =========================================
  useEffect(() => {
    const scoreInputEl = document.querySelector('#score-input')
    isEdit && scoreInputEl && scoreInputEl.focus()
  }, [isEdit])

  //========================= Returns =========================================
  return {
    isEdit,
    setIsEdit,
    isHistory,
    setIsHistory,
    score,
    note,
    loading,
    register, // useForm
    nilaiList: assignmentUser.assigment_users_histories,
    setLoading,
    putRequest,
    ...handlers,
  }
}
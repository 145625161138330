import React from 'react'

export default function FormTextArea({
  id = 'formTextarea',
  label = 'Label',
  value = '',
  placeholder = 'Input text',
  className = '',
  handleChange = () => {},
}) {
  return (
    <div className={`${className} form-group row`}>
      <label
        className="col-sm-3 col-form-label"
        htmlFor={id}
      >
        <span className="mr-1">{label}</span>
      </label>
      <div className="col-sm-9">
        <textarea
          value={value}
          onChange={(e) => handleChange(e)}
          type="text"
          className={`form-control`}
          id={id}
          placeholder={placeholder}
        />
      </div>
    </div>
  )
}

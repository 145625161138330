import React from 'react'
import Select from 'react-select'
import { popUpWindow } from 'util/global'

const FormSelectSurvey = ({
  handleChangeSurvey,
  surveyList,
  existingSurveyId,
  isSurveyInvalid,
  refSelectSurvey,
  getSurveyList,
}) => (
  <div className="form-group row mb-5">
    <label className="col-form-label col-sm-3">Survey</label>
    <div className="row col-sm-9">
      {/* Select Survey */}
      <div className="col-sm-8">
        <Select
          ref={refSelectSurvey}
          className=""
          isSearchable={true}
          isMulti={false}
          onMenuOpen={() => getSurveyList()}
          placeholder="Pilih Survey"
          noOptionsMessage={() => 'Data survey tidak dtemukan'}
          isClearable={true}
          options={surveyList}
          value={existingSurveyId}
          onChange={handleChangeSurvey}
        />
        {isSurveyInvalid ? (
          <span className="col small form-text text-danger">
            Harap pilih survey.
          </span>
        ) : null}
      </div>

      {/* Manage survey */}
      <div className="col-sm-4">
        <button
          type="button"
          className="btn btn-primary btn-sm mt-2 mt-md-1"
          onClick={() => popUpWindow('/survey-engine', window, 960, 600)}
        >
          <i className="feather icon-settings mr-1" />
          <span>Kelola survey</span>
        </button>
      </div>
    </div>
  </div>
)

export default FormSelectSurvey

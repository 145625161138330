import React from 'react'
import YouTube from 'react-youtube'
import { useBaseContext } from 'context/base'
import HtmlContent from 'component/html-content'
import CardMeta from 'component/card-meta'
import { detectionDoc } from 'util/global'

export const TextAnswer = ({ answer }) => {
  return <HtmlContent html={answer.text} />
}

export const AudioAnswer = ({ answer }) => {
  const data = answer.url.data

  return (
    <audio
      style={{ width: '100%' }}
      controls
      controlsList="nodownload"
      onContextMenu={(event) => event.preventDefault()}
    >
      <source
        src={data}
        type="audio/mpeg"
      />
      Your browser does not support the audio tag.
    </audio>
  )
}

export const VideoAnswer = ({ answer }) => {
  const data = answer.url.data

  return (
    <video
      width="100%"
      controls
      disablePictureInPicture
      controlsList="nodownload"
      onContextMenu={(event) => event.preventDefault()}
    >
      <source
        src={data}
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
  )
}

export const DocumentAnswer = ({ answer, assignmentTitle, siswaName }) => {
  const { getRequest } = useBaseContext()

  const encode = encodeURIComponent(answer.url.data)
  async function download() {
    const response = await getRequest(`checkfile?url=${answer.url.data}`)
    if (response) {
      window.location.href = answer.url.data
    }
  }
  return (
    <div className="row">
      <div className="col-12">
        <iframe
          key={answer.id}
          title={answer.id}
          src={detectionDoc(answer.url.type, encode)}
          style={{
            border: 'none',
            height: '100vh',
            width: '100%',
            backgroundColor: '#ddd',
          }}
        />
      </div>
      <div className="col-12 d-flex justify-content-center">
        <button
          onClick={async () => {
            await download()
          }}
          type="button"
          className="btn btn-outline-info"
        >
          <i className="fas fa-download mr-2" />
          <span>Unduh Tugas</span>
        </button>
      </div>
    </div>
  )
}

export const YoutubeAnswer = ({ answer }) => {
  const ytId = answer.url.data

  return (
    <YouTube
      videoId={ytId}
      opts={{
        width: '100%',
        height: '450px',
      }}
    />
  )
}

export const UrlAnswer = ({ answer }) => {
  return (
    <div>
      <a
        href={answer.url.data}
        target="_blank"
      >
        {answer.url.data}
      </a>
      <br />
      <br />
      {answer.url.data ? <CardMeta url={answer.url.data} /> : null}
    </div>
  )
}

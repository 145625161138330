import React from 'react'

export default function ContentDuration({ durasi }) {
  return (
    <>
      {durasi !== 0 ? (
        <>
          {durasi >= 60 ? (
            <span className="f-12 text-muted ml-2">
              <i className="feather icon-clock f-10 mr-1" />
              <span>{Math.round(durasi / 60)} menit</span>
            </span>
          ) : (
            <span className="f-12 text-muted ml-2">
              <i className="feather icon-clock f-10 mr-1" />
              <span>{durasi} detik</span>
            </span>
          )}
        </>
      ) : null}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useBaseContext } from 'context/base'
import {
  GraphorSortReadonlyAnswer,
  GraphorSortReadonlyCorrect,
} from 'component/graphor/graphor-sort-readonly'

export default function GraphorSort({ item }) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

    /* ======================================= Local States ======================================= */
  const [list, setList]               = useState([])
  const [realAnswers, setRealAnswers] = useState([])

  /* ========================================= Functions ======================================== */
  async function getDataById() {
    let response = await getRequest('graphors?id=' + item.value_id)
    if (response) {
      setRealAnswers(response.answer)
    }
  }
  
  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (item?.jawaban_essay_siswa) {
      getDataById()
      var l = JSON.parse(item?.jawaban_essay_siswa)
      const answers = l.map((item) => ({ answer_text: item }))
      setList(answers)
    }
  }, [item])

  /* ========================================== Output ========================================== */
  return (
    <div className="row">
      <div className="col-md-6 mb-3 mb-md-0">
        <h5 className="text-center">Jawaban Siswa</h5>
        <GraphorSortReadonlyAnswer
          list={list}
          correctList={realAnswers}
        />
      </div>
      <div className="col-md-6">
        <h5 className="text-center">Jawaban Benar</h5>
        <GraphorSortReadonlyCorrect correctList={realAnswers} />
      </div>
    </div>
  )
}

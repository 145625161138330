import React from 'react'
import Select from 'react-select'
import { popUpWindow } from 'util/global'

const FormSelectGraphor = ({
  handleChange,
  graphorList,
  graphor,
  isInvalid,
  refSelect,
  getList,
}) => (
  <div className="form-group row mb-5">
    <label className="col-form-label col-sm-3">Graphic Organizer</label>
    <div className="row col-sm-9">
      {/* Select Graphor */}
      <div className="col-sm-8">
        <Select
          ref={refSelect}
          className=""
          isSearchable={true}
          isMulti={false}
          onMenuOpen={() => getList()}
          placeholder="Pilih Graphic Organizer"
          noOptionsMessage={() => 'Data tidak dtemukan'}
          isClearable={true}
          options={graphorList}
          value={graphor}
          onChange={handleChange}
        />
        {isInvalid ? (
          <span className="col small form-text text-danger">
            Harap pilih graphic organizer.
          </span>
        ) : null}
      </div>

      {/* Add new graphor */}
      <div className="col-sm-4">
        <button
          type="button"
          className="btn btn-primary btn-sm mt-2 mt-md-1"
          onClick={() =>
            popUpWindow('/graphic-organizer', window, 960, 600)
          }
        >
          <i className="feather icon-settings mr-1" />
          <span>Kelola Graphic Organizer</span>
        </button>
      </div>
    </div>
  </div>
)

export default FormSelectGraphor

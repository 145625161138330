export default function Actions({
  item,
  selectSemester,
  selectedBlock,
  selectedGroup,
}) {
  /* =================================== Consume Local Storage ================================== */
  let roleType = JSON.parse(localStorage.getItem('role_active'))?.role_type

  /* ========================================= Constants ======================================== */
  const data = item?.report_transactions_periods.length
    ? item?.report_transactions_periods[0]
    : null
  const status = data?.status || 'draft'

  /* ========================================== Output ========================================== */
  if (
    roleType == 'kepalasekolah' ||
    roleType == 'administrator' ||
    roleType == 'adminprofile'
  ) {
    return (
      <>
        <button
          type="button"
          className={`${
            status == 'approved' || status == 'submission'
              ? 'btn-info'
              : 'btn-secondary btn-forbidden'
          } btn btn-sm`}
          onClick={() => {
            window.open(
              '/preview-rapor-block/' +
                item?.id +
                '?period_id=' +
                selectedBlock?.value +
                '&semester=' +
                selectSemester?.value,
              '_blank',
            )
          }}
          title="Lihat, Tolak & Approve Rapor"
        >
          <i className="feather icon-clipboard mr-1" />
          Lihat Rapor
        </button>
      </>
    )
  } else {
    return (
      <>
        {selectedGroup?.is_wali && (
          <button
            type="button"
            className={` ${
              status === 'approved' || status === 'submission'
                ? 'btn-secondary btn-forbidden'
                : 'btn-info'
            } btn btn-sm`}
            onClick={
              status === null
                ? () => {}
                : () => {
                    window.open(
                      '/input-rapor-block-all/' +
                        item?.id +
                        '?period_id=' +
                        selectedBlock?.value +
                        '&semester=' +
                        selectSemester?.value,
                      '_blank',
                    )
                  }
            }
            disabled={['approved'].includes(status)}
          >
            <i className="feather icon-clipboard mr-1" />
            Isi Rapor
          </button>
        )}
        <button
          type="button"
          className={`${
            status === 'approved'
              ? 'btn-primary'
              : 'btn-secondary btn-forbidden'
          } btn btn-sm ml-2`}
          onClick={
            status === null
              ? () => {}
              : () => {
                  window.open(
                    '/preview-rapor-block/' +
                      item?.id +
                      '?period_id=' +
                      selectedBlock?.value +
                      '&semester=' +
                      selectSemester?.value,
                    '_blank',
                  )
                }
          }
        >
          <i className="fas fa-eye mr-1" />
          Lihat Rapor
        </button>
      </>
    )
  }
}

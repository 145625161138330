import React, { createContext, useContext } from 'react'

const MatchedAnswerContext = createContext()

export function MatchedAnswerWrapper({ children, question_id, answers, matched }) {
  /* ============================================================================================ */
  /*                                         PROPS EXAMPLE                                        */
  /* ============================================================================================ */
  // const matched = [
  //   {
  //     id          : 'c9f7ad2a-91ef-4f53-9bec-11dcc21b8b17',
  //     answer_id   : null,
  //     answer_text : '<p>lorem ipsum test end</p>',
  //     content_id  : '6a07dd76-3fd8-47e8-9c04-25ee60d504b1',
  //     evaluatedAt : null,
  //     note        : null,
  //     score       : null,
  //     user_id     : '0a4f177b-7a8b-4250-a8f7-fcf139eefa0d',
  //     question_id : '02e8ec0f-84d4-4130-b0fa-99251e3ab736',
  //     quiz_id     : '3b52be2b-9f6a-4822-8281-35b1691afbf0',
  //     createdAt   : "2022-12-08T02:24:34.428Z",
  //   },
  // ]
  // const answers = [
  //   {
  //     id          : '043babd7-d95c-4607-a393-70aac5961fa3',
  //     correct     : 1,
  //     nilai       : 3,
  //     question_id : '9a1ec9d4-cd2f-427b-9cbd-0d1f66aa7916',
  //     text        : '<p></p>',
  //   },
  // ]

  /* ============================================================================================ */
  /*                                           FUNCTIONS                                          */
  /* ============================================================================================ */
  const findAnswer        = (answer_id) => answers.find((x) => x.id === answer_id)
  const findCorrectAnswer = () => answers.find(x => x.correct === 1)

  /* ============================================================================================ */
  /*                                         SHARED STATES                                        */
  /* ============================================================================================ */
  const shared = {
    question_id,
    answers,
    matched,
    findAnswer,
    findCorrectAnswer,
  }

  /* ============================================================================================ */
  /*                                        EXPORT PROVIDER                                       */
  /* ============================================================================================ */
  return (
    <MatchedAnswerContext.Provider value={shared}>
      {children}
    </MatchedAnswerContext.Provider>
  )
}

/* ============================================================================================== */
/*                                           EXPORT HOOK                                          */
/* ============================================================================================== */
export default function useMatchedAnswer() {
  return useContext(MatchedAnswerContext)
}

import { useContentStudentSpesific } from 'hooks/useContentStudentSpesific'
import { createContext, useContext, useState } from 'react'

const OverviewContentContext = createContext()

export function OverviewContentContextWrapper({ children }) {
  const { handler } = useContentStudentSpesific()

  const [selectedStudents, setSelectedStudents] = useState([])
  const [selectedContent, setSelectedContent] = useState(null)
  const [showModalSelectedStudent, setShowModalSelectedStudent] = useState(false)
  const [originalSelectedStudents, setOriginalSelectedStudents] = useState([])

  /**
   * Toggle Show Modal Selected Student Handler
   * 
   * @param {boolean} condition 
   * @param {Object} selectedContent 
   */
  const toggleShowModalSelectedStudentHandler = (condition, selectedContent) => {
    if (Array.isArray(selectedContent?.content_specific_users) && selectedContent?.content_specific_users?.length) {
      const selectedStudentsBySelectedContent = selectedContent?.content_specific_users?.map(c => c?.user)
      setSelectedStudents(selectedStudentsBySelectedContent)
      setOriginalSelectedStudents(selectedStudentsBySelectedContent)
    }
    
    if (condition === true) {
      setSelectedContent(selectedContent)
    }

    if (condition === false) {
      setSelectedContent(null)
      setSelectedStudents([])
      setOriginalSelectedStudents([])
    }

    setShowModalSelectedStudent(condition)
  }

  const onSaveSpesificStudentHandler = ({ classId, contentId, onSuccessHandler = () => {} }) => {
    handler.onSaveSpesificStudentHandler({
      classId,
      contentId,
      selectedContent,
      selectedStudents,
      onSuccessHandler: () => {
        setShowModalSelectedStudent(false)
        if (onSuccessHandler && typeof onSuccessHandler === 'function') {
          onSuccessHandler()
        }
      },
    })
  }

  /* ======================================= Shared Props ======================================= */
  const sharedProps = {
    data: {
      selectedStudents,
      selectedContent,
      showModalSelectedStudent,
      originalSelectedStudents,
    },
    set: {
      setSelectedStudents,
      setSelectedContent,
      setShowModalSelectedStudent,
      setOriginalSelectedStudents,
    },
    handler: {
      toggleShowModalSelectedStudentHandler,
      onSaveSpesificStudentHandler,
    },
  }

  /* ====================================== Return Provider ===================================== */
  return (
    <OverviewContentContext.Provider value={sharedProps}>
      {children}
    </OverviewContentContext.Provider>
  )
}

/* ========================================= Return Hook ======================================== */
export default function useOverviewContentContext() {
  return useContext(OverviewContentContext)
}

import React, { useEffect, useState } from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import DataTable from 'component/data-table'
import InvalidText from 'component/invalid/text'
import { StyleButton } from 'component/button'

export default function KelasOverviewFileKelas({ kelasId }) {
  /* ========================================== Helpers ========================================= */
  const handleFullScreen = useFullScreenHandle()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest, deleteRequest } = useBaseContext()
  
  /* ======================================= Local States ======================================= */
  const [dataFiles, setDataFiles]             = useState({ kelasList : [], totalRecords: 0})
  const [isLoadingViewer, setIsLoadingViewer] = useState(true)

  //=========== ADD FROMS FILE ==========
  const [addFileName, setAddFileName] = useState('')
  const [addStatus, setAddStatus]     = useState(0)
  const [addFile, setAddFile]         = useState('')
  const [headerFile, setHeaderFile]   = useState('Choose file ...')  //untuk mengisi nama form file
  const [file, setFile]               = useState(null)

  //=========== EDIT FROMS FILE ==========
  const [editFileId, setEditFileId]         = useState('')
  const [editFileName, setEditFileName]     = useState('')
  const [editStatus, setEditStatus]         = useState(0)
  const [editFile, setEditFile]             = useState('')
  const [headerFileEdit, setHeaderFileEdit] = useState('Choose file ...')  //untuk mengisi nama form file
  const [fileEdit, setFileEdit]             = useState(null)

  //=========== STATE VALIDATION =========
  const [isAddFileNameInvalid, setIsAddFileNameInvalid]   = useState(false)
  const [isAddFileInvalid, setIsAddFileInvalid]           = useState(false)
  const [isEditFileNameInvalid, setIsEditFileNameInvalid] = useState(false)
  const [isEditFileInvalid, setIsEditFileInvalid]         = useState(false)

  //=========== STATE PREVIEW =============
  const [previewImage, setPreviewImage] = useState('')
  const [previewPdf, setPreviewPdf]     = useState('')
  const [previewVideo, setPreviewVideo] = useState('')
  const [previewAudio, setPreviewAudio] = useState('')
  const [previewDoc, setPreviewDoc]     = useState('')

  //=========== STATE DATA TABLE =============
  const [search, setSearch] = useState('')
  const [limit, setLimit]   = useState(10)
  const [offset, setOffset] = useState(0)

  /* ========================================= Functions ======================================== */
  async function getFiles() {
    const response = await getRequest(
      `classes-file?class_id=${kelasId}&search=${search}&limit=${limit}&offset=${offset}`,
    )
    if (response) {
      setDataFiles({
        kelasList: response.data,
        totalRecords: response.totalRecords,
      })
    }
  }

  const LoadingSpinner = () => {
    return (
      <div className="d-flex justify-content-center">
        <div
          className="spinner-grow my-5"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }

  const RenderItem = ({ item }) => {
    async function deleteItem(id) {
      Swal.fire({
        title: 'Apa anda yakin?',
        text: 'Anda akan menghapus file ini',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Tidak',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await deleteRequest(`classes-file?id=${id}`)
          if (response) {
            Swal.fire('Berhasil!', 'File dihapus', 'success')
            await getFiles()
          } else {
            Swal.fire(
              'Oppsss!',
              'Terjadi kesalahan coba beberapa saat lagi.',
              'error',
            )
          }
        }
      })
    }

    async function handleDownload(item) {
      const checkFile = await getRequest(
        `checkfile?url=${item.download_file}`,
      )
      if (checkFile) {
        window.location.href = item.download_file
      }
    }

    return (
      <tr>
        <td>
          {item.nama_file ? (
            item.nama_file
          ) : (
            <i className="feather icon-minus" />
          )}
        </td>
        <td>{item.type ? item.type : <i className="feather icon-minus" />}</td>
        <td>
          <button
            onClick={() => getDataEditFile(item)}
            data-toggle="modal"
            data-target="#edit-modal"
            type="button"
            className="btn btn-sm btn-success mr-2"
          >
            <i className="feather icon-edit mr-1" />
            Edit
          </button>
          <button
            onClick={() => handleDownload(item)}
            type="button"
            className="btn btn-sm btn-info mr-2"
          >
            <i className="fas fa-download mr-1" />
            Download
          </button>
          <button
            onClick={() => deleteItem(item.id)}
            type="button"
            className="btn btn-sm btn-danger sweet-multiple"
          >
            <i className="feather icon-trash-2 mr-1" />
            Hapus
          </button>
        </td>
      </tr>
    )
  }

  function handleFile({ file, status }) {
    if (status == 'add') {
      setFile(file)
      setHeaderFile(file.name)
      setAddFile({ name: file.name, data: 'data' })
    }
    if (status == 'edit') {
      setFileEdit(file)
      setHeaderFileEdit(file.name)
      setEditFile({ name: file.name, data: 'edit' })
    }
  }

  function hendleRemove(status) {
    if (status == 'add') {
      setAddFileName('')
      setAddStatus(0)
      setAddFile('')
      setHeaderFile('Choose file ...')
      setFile(null)
    }
    if (status == 'preview') {
      setPreviewImage('')
      setPreviewPdf('')
      setPreviewVideo('')
      setPreviewAudio('')
      setPreviewDoc('')
      setIsLoadingViewer(true)
    }
    if (status == 'edit') {
      setEditFileId('')
      setEditFileName('')
      setEditStatus(0)
      setEditFile('')
      setHeaderFileEdit('Choose file ...')
      setFileEdit(null)
    }
  }

  function handleValidation(item) {
    if (item == 'add') {
      if (addFileName && addFile) {
        setIsAddFileNameInvalid(false)
        setIsAddFileInvalid(false)
        handleSubmitForm(addFileName, addStatus, addFile)
      } else {
        if (!addFileName) {
          setIsAddFileNameInvalid(true)
        }
        if (!addFile) {
          setIsAddFileInvalid(true)
        }
        window.notification('Mohon', 'lengkapi form', 'warning')
      }
    }

    if (item == 'edit') {
      if (editFileName) {
        setIsEditFileNameInvalid(false)
        setIsEditFileInvalid(false)
        handleSubmitFormEdit(editFileId, editFileName, editStatus, editFile)
      } else {
        if (!editFileName) {
          setIsEditFileNameInvalid(true)
        }
        if (!editFile) {
          setIsEditFileInvalid(true)
        }
        window.notification('Mohon', 'lengkapi form', 'warning')
      }
    }
  }

  //================ HANDLE MODAL ADD ========================
  async function handleSubmitForm(addFileName, addStatus, addFile) {
    const { id } = JSON.parse(localStorage.getItem('user'))
    const profile_id = JSON.parse(localStorage.getItem('profile')).id
    const ext = addFile.name.split('.').pop()
    let form = new FormData()
    form.append('nama_file', addFileName)
    form.append('file_url', JSON.stringify(addFile))
    form.append('class_id', kelasId)
    form.append('public_status', addStatus.toString())
    form.append('path', `upload/${profile_id}/classes/files`)
    form.append('temp_name', `file-temp-${id}-${Date.now()}.${ext}`)
    form.append('file_upload', file)

    const response = await postRequest('classes-file', form)
    if (response) {
      window.notification('File', 'berhasil ditambahkan', 'success')
      window.$('#add-modal').modal('hide')
      hendleRemove('add')
      await getFiles()
    } else {
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }
  //====================END HANDLE MODAL ADD=========================

  //=====================HANDLE MODAL EDIT===========================
  function getDataEditFile(item) {
    setEditFileId(item.id)
    setEditFileName(item.nama_file)
    setEditStatus(item.public_status ? 1 : 0)
    setEditFile('data tidak berubah')
    setHeaderFileEdit(`${item.nama_file}.${item.type}`)
  }
  async function handleSubmitFormEdit(
    editFileId,
    editFileName,
    editStatus,
    editFile,
  ) {
    let form = new FormData()
    form.append('id', editFileId)
    form.append('nama_file', editFileName)
    form.append('class_id', kelasId)
    form.append('public_status', editStatus.toString())

    if (editFile != 'data tidak berubah') {
      const { id } = JSON.parse(localStorage.getItem('user'))
      const profile_id = JSON.parse(localStorage.getItem('profile')).id
      const ext = editFile.name.split('.').pop()
      form.append('file_url', JSON.stringify(editFile))
      form.append('path', `upload/${profile_id}/classes/files`)
      form.append('temp_name', `file-temp-${id}-${Date.now()}.${ext}`)
      form.append('file_upload', fileEdit)
    }

    const response = await putRequest('classes-file', form)
    if (response) {
      window.notification('File', 'berhasil diubah', 'success')
      window.$('#edit-modal').modal('hide')
      hendleRemove('edit')
      await getFiles()
    } else {
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    let mounted = true
    if (mounted) getFiles()

    return () => {
      mounted = false
    }
  }, [search, limit, offset])

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card user-profile-list">
            <div className="card-header d-flex justify-content-between">
              <button
                className="btn btn-primary has-ripple mr-2"
                data-toggle="modal"
                data-target="#add-modal"
              >
                <i className="feather icon-plus-circle mr-1" />
                <span>Tambah File</span>
                <StyleButton />
              </button>
              {/* <button
                                onClick={() => history.push("/kelas")}
                                type="button"
                                className="btn btn-outline-danger"
                            >
                                <i className="feather icon-chevron-left mr-1" />
                                <span>Daftar Mata Pelajaran</span>
                            </button> */}
            </div>
            <div className="card-body">
              <div className="dt-responsive table-responsive">
                <DataTable
                  className={'table table-striped table-bordered nowrap'}
                  data={dataFiles.kelasList}
                  totalRows={dataFiles.totalRecords}
                  show={[10, 20, 30, 40, 50]}
                  onChangeSearch={(val) => {
                    setSearch(val)
                  }}
                  onChangeLimit={(val) => {
                    setLimit(val)
                  }}
                  onChangePage={(val) => {
                    setOffset(val)
                  }}
                  column={['nama file', 'type', 'actions']}
                  renderItem={(dataFiles, index) => (
                    <RenderItem
                      item={dataFiles}
                      index={index}
                      key={dataFiles.id}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ======ADD MODAL===== */}
      <div
        className="modal fade"
        id="add-modal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Informasi file</h5>
              <button
                id="closeModal"
                onClick={() => hendleRemove('add')}
                type="button"
                className="close text-danger"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group row mb-4">
                      <label
                        className="col-sm-3 col-form-label"
                        htmlFor="nama-file"
                      >
                        <span className="mr-1">Nama file</span>
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          value={addFileName}
                          onChange={(event) =>
                            setAddFileName(event.target.value)
                          }
                          className={`${
                            isAddFileNameInvalid ? 'is-invalid' : ''
                          } form-control`}
                          id="nama-file"
                          placeholder="Nama file"
                        />
                        {isAddFileNameInvalid ? (
                          <InvalidText name={'Nama file'} />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <fieldset className="form-group mb-4">
                      <div className="row">
                        <label className="col-sm-3 col-form-label">
                          <span className="mr-1">Status</span>
                        </label>
                        <div className="col-sm-6">
                          <div className="form-check">
                            <input
                              onChange={(val) => setAddStatus(val.target.value)}
                              className="form-check-input"
                              type="radio"
                              name="active"
                              id="active"
                              defaultValue={1}
                              checked={parseInt(addStatus) == 1}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="active"
                            >
                              Aktif
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              onChange={(val) => setAddStatus(val.target.value)}
                              className="form-check-input"
                              type="radio"
                              name="active"
                              id="no-active"
                              defaultValue={0}
                              checked={parseInt(addStatus) == 0}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="no-active"
                            >
                              Tidak Aktif
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <div className="form-group row mb-4">
                      <label className="col-sm-3 col-form-label">
                        <span className="mr-1">Upload file</span>
                        <span className="text-danger">*</span>
                      </label>
                      <div className="custom-file col-sm-6 ml-2">
                        <input
                          onChange={(event) =>
                            handleFile({
                              file: event.target.files[0],
                              status: 'add',
                            })
                          }
                          type="file"
                          className={`${
                            isAddFileInvalid ? 'is-invalid' : ''
                          } custom-file-input`}
                          id="inputGroupFile01"
                        />
                        <label
                          style={{ overflow: 'hidden' }}
                          className="custom-file-label"
                          htmlFor="inputGroupFile01"
                        >
                          {headerFile}
                        </label>
                        {isAddFileInvalid ? <InvalidText name={'File'} /> : ''}
                      </div>
                    </div>

                    <div className="form-group mb-4">
                      <label className="text-muted">Keterangan:</label>
                      <div
                        className="ml-2"
                        style={{ lineHeight: '0' }}
                      >
                        <span className="text-danger mr-1">*</span>
                        <label className="text-muted">Wajib diisi</label>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer p-2">
              <button
                type="button"
                className="btn btn-danger mr-2"
                data-dismiss="modal"
                onClick={() => hendleRemove('add')}
              >
                Tutup
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleValidation('add')}
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* =======END MODAL ADD====== */}

      {/* ========EDIT MODAL========/*/}
      <div
        className="modal fade"
        id="edit-modal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ubah informasi file</h5>
              <button
                onClick={() => hendleRemove('edit')}
                id="closeModalEdit"
                type="button"
                className="close text-danger"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group row mb-4">
                      <label
                        className="col-sm-3 col-form-label"
                        htmlFor="nama-file-edit"
                      >
                        <span className="mr-1">Nama file</span>
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          value={editFileName}
                          onChange={(event) =>
                            setEditFileName(event.target.value)
                          }
                          className={`${
                            isEditFileNameInvalid ? 'is-invalid' : ''
                          } form-control`}
                          id="nama-file-edit"
                          placeholder="Nama file"
                        />
                        {isEditFileNameInvalid ? (
                          <InvalidText name={'Nama file'} />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <fieldset className="form-group mb-4">
                      <div className="row">
                        <label className="col-sm-3 col-form-label">
                          <span className="mr-1">Status</span>
                        </label>
                        <div className="col-sm-6">
                          <div className="form-check">
                            <input
                              onChange={(val) =>
                                setEditStatus(val.target.value)
                              }
                              className="form-check-input"
                              type="radio"
                              name="active-edit"
                              id="activeFile"
                              defaultValue={1}
                              checked={parseInt(editStatus) == 1}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="activeFile"
                            >
                              Aktif
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              onChange={(val) =>
                                setEditStatus(val.target.value)
                              }
                              className="form-check-input"
                              type="radio"
                              name="active-edit"
                              id="inactiveFile"
                              defaultValue={0}
                              checked={parseInt(editStatus) == 0}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inactiveFile"
                            >
                              Tidak Aktif
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <div className="form-group row mb-4">
                      <label className="col-sm-3 col-form-label">
                        <span className="mr-1">Upload file</span>
                        <span className="text-danger">*</span>
                      </label>
                      <div className="custom-file col-sm-6 ml-2">
                        <input
                          onChange={(event) =>
                            handleFile({
                              file: event.target.files[0],
                              status: 'edit',
                            })
                          }
                          type="file"
                          className={`${
                            isEditFileInvalid ? 'is-invalid' : ''
                          } custom-file-input`}
                          id="inputGroupFile02"
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="inputGroupFile02"
                        >
                          {headerFileEdit}
                        </label>
                        {isEditFileInvalid ? <InvalidText name={'File'} /> : ''}
                      </div>
                    </div>

                    <div className="form-group mb-4">
                      <label className="text-muted">Keterangan:</label>
                      <div
                        className="ml-2"
                        style={{ lineHeight: '0' }}
                      >
                        <span className="text-danger mr-1">*</span>
                        <label className="text-muted">Wajib diisi</label>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer p-2">
              <button
                onClick={() => hendleRemove('edit')}
                type="button"
                className="btn btn-danger mr-2"
                data-dismiss="modal"
              >
                Tutup
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleValidation('edit')}
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ========END EDIT MODAL========/*/}

      {/* ======PREVIEW MODAL===== */}
      <div
        className="modal fade"
        id="preview-modal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Preview file</h5>
              <button
                id="closeModal"
                type="button"
                onClick={() => hendleRemove('preview')}
                className="close text-danger"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center">
              {isLoadingViewer ? <LoadingSpinner /> : null}

              {previewImage && (
                <img
                  style={{ visibility: isLoadingViewer ? 'hidden' : 'visible' }}
                  className="w-50"
                  src={previewImage}
                  alt="imagePreview"
                  onLoad={() => {
                    setIsLoadingViewer(false)
                  }}
                />
              )}

              {previewPdf && (
                <>
                  <div className="text-left">
                    <button
                      onClick={handleFullScreen.enter}
                      type="button"
                      className="btn btn-primary btn-sm mb-3"
                    >
                      <i className="feather icon-maximize mr-1" />
                      <span>Fullscreen</span>
                    </button>
                  </div>
                  <FullScreen handle={handleFullScreen}>
                    <embed
                      // src={`https://docs.google.com/viewer?url=${previewPdf}&embedded=true`}
                      src={previewPdf}
                      style={{
                        visibility: isLoadingViewer ? 'hidden' : 'visible',
                        border: 'none',
                        height: '100vh',
                        width: '100%',
                      }}
                      onLoad={() => {
                        setIsLoadingViewer(false)
                      }}
                    />
                  </FullScreen>
                </>
              )}

              {previewVideo && (
                <video
                  style={{ visibility: isLoadingViewer ? 'hidden' : 'visible' }}
                  className="w-75"
                  controls
                  controlsList="nodownload"
                  onContextMenu={(event) => event.preventDefault()}
                  onLoadedData={() => {
                    setIsLoadingViewer(false)
                  }}
                >
                  <source
                    src={previewVideo}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              )}

              {previewAudio && (
                <div className="p-3 m-3">
                  <audio
                    style={{
                      visibility: isLoadingViewer ? 'hidden' : 'visible',
                    }}
                    className="w-75"
                    controls
                    controlsList="nodownload"
                    onContextMenu={(event) => event.preventDefault()}
                    onLoadedData={() => {
                      setIsLoadingViewer(false)
                    }}
                  >
                    <source
                      src={previewAudio}
                      type="audio/mpeg"
                    />
                    Your browser does not support the audio tag.
                  </audio>
                </div>
              )}
            </div>
            <div className="modal-footer p-2">
              <button
                type="button"
                className="btn btn-danger mr-2"
                data-dismiss="modal"
                onClick={() => hendleRemove('preview')}
              >
                Tutup
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* =======END MODAL PREVIEW====== */}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'

export default function Group() {
  /* ========================================= Helpers ======================================== */
  const history = useHistory()

  /* ===================================== Consume Context ==================================== */
  const { getRequest, deleteRequest } = useBaseContext()

  /* ====================================== Local States ====================================== */
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [totalRecord, setTotalRecord] = useState(0)
  const [data, setData] = useState([])
  const [year, setYear] = useState('')
  const [activeYear, setActiveYear] = useState('')
  const [academicYear, setAcademicYear] = useState([])

  /* ======================================== Functions ======================================= */
  async function getData() {
    let response = await getRequest(
      `groups?limit=${limit}&offset=${offset}&search=${search}&ta_tahun_code=${year}`,
    )
    if (response) {
      setData(response.data)
      setTotalRecord(response.totalRecords)
    }
  }

  async function getAcademicYear() {
    let response = await getRequest(`academic-year`)
    if (response) {
      setAcademicYear(response.data)
    }
  }

  async function getActiveSemester() {
    let response = localStorage.getItem('semester')
    if (response) {
      var json = JSON.parse(response)
      setActiveYear(json.ta_tahun_code)
      setYear(json.ta_tahun_code)
    }
  }

  /* ======================================== Components ======================================== */
  function RenderItem({ item }) {
    async function deleteItem(id) {
      Swal.fire({
        title: 'Apa anda yakin?',
        text: `Anda akan menghapus ${item.group}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Tidak',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await deleteRequest(`groups?id=${id}`)
          if (response) {
            Swal.fire('Berhasil!', 'Kelas dihapus.', 'success')
            await getData()
          } else {
            Swal.fire(
              'Oppsss!',
              'Terjadi kesalahan coba beberapa saat lagi.',
              'error',
            )
          }
        }
      })
    }

    return (
      <tr>
        <td>{item.ta_tahun != null ? item.ta_tahun.description : null}</td>
        <td>{item.group}</td>
        <td>
          {item.groups_walis.length > 0
            ? `${item.groups_walis[0].user.name} (${item.groups_walis[0].user.nim})`
            : 'Belum terisi'}
        </td>
        <td>
          {item.active ? (
            <span className="badge badge-success">Aktif</span>
          ) : (
            <span className="badge badge-secondary">Tidak Aktif</span>
          )}
        </td>
        <td>
          <button
            onClick={async () => {
              localStorage.setItem('group_ta_tahun_code', item.ta_tahun_code)
              history.push(`group-detail/${item.id}`, { item })
            }}
            type="button"
            className="btn btn-sm mr-2 btn-warning"
          >
            <i className="fas fa-eye mr-1" />
            <span>Overview</span>
          </button>
          <button
            onClick={() => {
              deleteItem(item.id)
            }}
            type="button"
            className="btn btn-sm mr-2 btn-danger"
          >
            <i className="feather icon-trash-2 mr-1" />
            <span>Hapus</span>
          </button>
        </td>
      </tr>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getActiveSemester()
    getAcademicYear()
  }, [])

  useEffect(() => {
    getData()
  }, [limit, offset, search, year])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Kelas" />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header">
                <div className="position-sticky">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <button
                        onClick={() => {
                          history.push('./group-add')
                        }}
                        type="button"
                        className="btn btn-primary"
                      >
                        <i className="feather icon-plus-circle" /> Tambah
                        Kelas
                      </button>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <select
                        onChange={(val) => setYear(val.target.value)}
                        className="form-control col-md-6 float-right"
                      >
                        <option value="">- Tampilkan semua -</option>
                        {academicYear.map((val, i) => {
                          return (
                            <option
                              selected={val.code == activeYear}
                              value={val.code}
                            >
                              {val.description}{' '}
                              {val.code == activeYear ? '(Aktif)' : null}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="dt-responsive table-responsive">
                  <DataTable
                    className={'table table-striped table-bordered nowrap'}
                    data={data}
                    totalRows={totalRecord}
                    show={[10, 20, 30, 40, 50]}
                    onChangeSearch={(val) => {
                      setSearch(val)
                    }}
                    onChangeLimit={(val) => {
                      setLimit(val)
                    }}
                    onChangePage={(val) => {
                      setOffset(val)
                    }}
                    column={[
                      'Tahun Ajaran',
                      'Nama Kelas',
                      'Wali Kelas',
                      'Status',
                      'Actions',
                    ]}
                    renderItem={(item, i) => (
                      <RenderItem
                        item={item}
                        key={i}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

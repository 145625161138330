import { useEffect, useReducer, useState } from 'react'
import { useBaseContext } from "context/base"
import { createQueryUrl } from "util/string"
import Swal from 'sweetalert2'
import _ from 'lodash'

/**
 * @typedef {Object} DataTableProps
 * @property {Array<Student>?} data
 * @property {'id' | 'name' | 'linkAvatar' | 'nim'=} comparePropName
 * @property {Array<string>=} column
 * @property {number=} totalRows
 * @property {Array<number>=} limits
 * @property {(search: string) => void=} onChangeSearch
 * @property {(selectedPage: number) => void=} onChangePage
 * @property {(selectedLimit: number) => void=} onChangeLimit
 */

/**
  * 
  * @param {{
  *  data: Array
  *  meta: Object
  *  loading: boolean
  * }} state
  * @param {{
  *  type?: 'set_meta' | 'set_data' | 'set_loading'
  *  payload: any
  * }} action
*/
const listUsersGradeReducer = (state, action) => {
  switch (action.type) {
    case 'set_data':
      return {
        ...state,
        data: action.payload
      }
    case 'set_meta':
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload
        }
      }
    case 'set_loading':
      return {
        ...state,
        loading: action.payload
      }
    default:
      return {
        ...state,
        ...action.payload,
      }
  }
}


/**
 * Base Modal Selected Student Hook
 * 
 * @param {{
 *  dataTableProps: DataTableProps
 *  selectedGrade: GradeItem
 *  contentId?: string
 *  subMapelId: string
 *  onAddOrRemoveStudent: (item: Student | Array<Student>) => void
 *  onCloseHandler: () => void
 *  onSaveHandler: () => void
 *  isWithCheckSavedOrNot?: boolean
 *  isListStudentHasAdded?: boolean
* }} params 
*/
export function useBaseModalSelectedStudentHookV2(params) {
  const { limits = [10, 20, 30], column = ['NO. INDUK', 'NAMA', 'ACTION'], data = [] } = params.dataTableProps
  const { putRequest } = useBaseContext()

  const [selectedClass, setSelectedClass] = useState('')

  const { getRequest } = useBaseContext()

  const [optionsClass, setOptionsClass] = useState()

  const [listUsersGrade, dispatchListUsersGrade] = useReducer(listUsersGradeReducer, {
    data: [],
    meta: {
      search: '',
      limit: 10,
      offset: 0,
      totalData: 0,
    },
    loading: false,
  })

  const getListUsersGradeHandler = async () => {
    dispatchListUsersGrade({ type: 'set_loading', payload: true })
    const querySearch = createQueryUrl({ queryName: 'search', value: listUsersGrade.meta.search })
    const queryGroupId = createQueryUrl({ queryName: 'group_id', value: selectedClass?.value })
    const response = await getRequest(`users-content-grade?content_grade_id=${params.selectedGrade?.id}&required=${params?.isListStudentHasAdded === true ? 1 : 0}${queryGroupId}&limit=${listUsersGrade.meta.limit}&offset=${listUsersGrade.meta.offset}${querySearch}`)

    if (response) {
      const responseData = {
        data: response.data,
        meta: {
          ...listUsersGrade.meta,
          ...response.meta
        },
        loading: false
      }
      dispatchListUsersGrade({ payload: responseData })
    } else {
      dispatchListUsersGrade({ type: 'set_loading', payload: false })
    }
  }


  /**
  * Get List Class By Sub Mapel Id
  * 
  * @param {{
  *   sub_mapel_id
  * }} props
  */
  const getOptionsListClassBySubMapelIdHandler = async(props) => {
    const response = await getRequest(`group-by-mapel?class_id=${props.sub_mapel_id}`)

    if (response) {
      const options = response?.data?.map(d => ({
        label: d?.group,
        value: d?.id
      }))
      setOptionsClass(options)
    }
  }

  /**
   * On Add Student Handler
   * 
   * @param {{
   *  isAdded?: boolean,
   *  student: Student
   *  onSuccessHandler?: () => void
   * }} params
   */
  const onAddOrRemoveStudentHandler = async ({ isAdded, student, onSuccessHandler }) => {
    const conjunction1 = isAdded ? 'menghapus' : 'menambahkan'
    const conjunction2 = isAdded ? 'dari' : 'ke dalam'
    const action1 = isAdded ? 'hapus' : 'tambahkan'

    Swal.fire({
      title: 'Kamu yakin?',
      text: `Kamu ingin ${conjunction1} siswa ini ${conjunction2} Grade ${params.selectedGrade.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Ya, ${action1}!`,
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.showLoading()
        const form = new FormData()
        form.append('content_grade_id', params.selectedGrade.id)
        form.append('user_id', student.id)
        const response = await putRequest('set-users-content-grade', form)
        if (response) {
          Swal.hideLoading()
          if (onSuccessHandler && typeof onSuccessHandler === 'function') {
            onSuccessHandler()
          }
          window.notification(
            'Berhasil',
            `${conjunction1} siswa ${conjunction2} Grade ${params.selectedGrade.name}`,
            'success',
          )
        } else {
          Swal.hideLoading()
        }
      }
    })
  }

  useEffect(() => {
    if (params?.subMapelId) {
      getOptionsListClassBySubMapelIdHandler({
        sub_mapel_id: params.subMapelId
      })
    }
  }, [params?.subMapelId])

  useEffect(() => {
    if (params?.selectedGrade?.id) {
      getListUsersGradeHandler()
    }
  }, [params?.selectedGrade?.id, listUsersGrade.meta.limit, listUsersGrade.meta.offset, listUsersGrade.meta.search, selectedClass])

  const usingData = Array.isArray(data) && !!data?.length ? data  : listUsersGrade.data

  return {
    data: {
      limits,
      column,
      optionsClass,
      usingData,
      selectedClass,
      listUsersGrade,
    },
    dispatch: {
      dispatchListUsersGrade,
    },
    set: {
      setSelectedClass,
    },
    handler: {
      onAddOrRemoveStudentHandler,
      getListUsersGradeHandler,
    }
  }
}
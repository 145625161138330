import React, { useState } from 'react'
import GraphorSort from 'pages/quiz/component-quiz-answer-siswa/GraphorSort'
import GraphorGroup from 'pages/quiz/component-quiz-answer-siswa/GraphorGroup'
import CkeditorReadonly from 'component/ckeditor-readonly'
import AssignmentPending from '../assignment-pending'
import useQuizComponent from './hook'
import useContentPlayerContext from 'context/content-player'
import Tab from './partials/tab'
import { reorder } from 'util/array'
import "./style.css"
import Badge from 'component/badge'

export const QuizDone = () => {
  /* ================================== Consume Context & Hook ================================== */
  const { content } = useContentPlayerContext()

  /* ========================================== Output ========================================== */
  return (
    <div>
      <div className="row">
        <div className="col">
          {content.is_quiz_evaluated ? (
            <>
              <div className="d-flex justify-content-center">
                <div
                  className="rounded-circle p-2 my-3"
                  style={{
                    border: '3px solid #9ccc65',
                  }}
                >
                  <i className="fas fa-check text-success f-48" />
                </div>
              </div>
              <p className="text-center f-20 mb-0">Selamat!</p>
              <p className="text-center f-20">
                Anda telah menyelesaikan quiz ini
              </p>
            </>
          ) : (
            <AssignmentPending />
          )}
        </div>
      </div>
      <ReviewButtons />
    </div>
  )
}

export const ReviewButtons = () => {
  const quiz = useQuizComponent()

  return <div className="row">
    <div className="col-12 offset-md-3 col-md-6 offset-lg-4 col-lg-4 mb-3">
      <button
        onClick={quiz.toggle.data}
        className={`${
          quiz.loading.data ? 'btn-forbidden' : ''
        } btn btn-primary w-100`}
        disabled={quiz.loading.data}
      >
        {quiz.loading.data && (
          <span
            className="spinner-border spinner-border-sm mr-1"
            role="status"
          />
        )}
        {quiz.shown.data ? 'Sembunyikan Jawaban' : 'Lihat Jawaban Saya'}
      </button>
    </div>

    {quiz.shown.data && (
      <>
        <Tab quiz={quiz} />
        <QuizScore quiz={quiz} />
        <div className="col-12 mb-3">
          <div className="border rounded">
            {quiz.questions.length && quiz.user && (
              <QuestionList 
                {...{ quiz }}
                item={quiz.questions}
                quizUser={quiz.user}
                isQuizOnlyPG={quiz.isPgOnly}
              />
            )}
          </div>
        </div>
      </>
    )}
  </div>
}

const QuizScore = ({ quiz }) => {
  const scoreData = Number.isInteger(quiz.shown.index) ? quiz.history[quiz.shown.index] : quiz.user
  return (
    <div className="col-12 mb-3">
      <div className="border rounded">
        {scoreData && (
          <div className="p-3">
            <div className="row">
              <div className="col-5 col-md-2">
                <div className="row">
                  <div className="col-10">
                    <span style={{ fontWeight: '500' }}>Nilai</span>
                  </div>
                  <div className="col-2 pr-0 d-flex justify-content-end">
                    <span>:</span>
                  </div>
                </div>
              </div>
              <div className="col-7 col-md-8">
                <span>
                  {quiz.isPgOnly ? (
                    <>
                      {scoreData.total_score <= 49 ? (
                        <span className="text-danger font-weight-bold">
                          {scoreData.total_score}
                        </span>
                      ) : (
                        <>
                          {scoreData.total_score > 69 ? (
                            <span className="text-success font-weight-bold">
                              {scoreData.total_score}
                            </span>
                          ) : (
                            <span className="text-warning font-weight-bold">
                              {scoreData.total_score}
                            </span>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {!scoreData.u_login ? (
                        <span className="badge badge-secondary">
                          Quiz belum diperiksa
                        </span>
                      ) : (
                        <>
                          {scoreData.total_score <= 49 ? (
                            <span className="text-danger font-weight-bold">
                              {scoreData.total_score}
                            </span>
                          ) : (
                            <>
                              {scoreData.total_score > 69 ? (
                                <span className="text-success font-weight-bold">
                                  {scoreData.total_score}
                                </span>
                              ) : (
                                <span className="text-warning font-weight-bold">
                                  {scoreData.total_score}
                                </span>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-5 col-md-2">
                <div className="row">
                  <div className="col-10">
                    <span style={{ fontWeight: '500' }}>Catatan</span>
                  </div>
                  <div className="col-2 pr-0 d-flex justify-content-end">
                    <span>:</span>
                  </div>
                </div>
              </div>
              <div className="col-7 col-md-8">
                {scoreData.note ? <CkeditorReadonly data={scoreData.note} /> : <span>-</span>}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export const QuestionList = ({ quiz, item, quizUser, isQuizOnlyPG }) => {
  return (
    <div className="overview-matpel-siswa p-3 mb-0">
      {reorder(item, quiz.getQuestionOrder()).map((question, index) => (
        <QuestionItem
          key={index}
          index={index}
          item={question}
          quizUser={quizUser}
          isQuizOnlyPG={isQuizOnlyPG}
        />
      ))}
    </div>
  )
}

const QuestionItem = ({ item, index, quizUser, isQuizOnlyPG }) => {
  return (
    <>
      <div
        style={{ position: 'relative', overflow: 'hidden' }}
        className="mb-4 border rounded px-3 pb-3 pt-5 bg-light"
      >
        <span
          className="bg-primary text-light"
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            padding: '5px',
            borderBottomRightRadius: '8.5px',
          }}
        >
          <span className="mr-1 f-w-500">Pertanyaan</span>
          <span className="f-w-500">{item ? ++index : '0'}</span>
        </span>
        <div className="d-flex">
          <CkeditorReadonly data={item.text} />
        </div>
        <div
          style={{ marginLeft: '1px' }}
          className="mb-2 mt-2 row"
        >
          {item.quest_type === 1 ? (
            <>
              <div className="col-12">
                <span
                  style={{ fontWeight: '500' }}
                  className="p-1"
                >
                  Jawaban saya:{' '}
                </span>
              </div>
              <div className="col-12">
                {item.quest_type === 1 && (
                  <AnswerEssay
                    item={item}
                    key={`essay${index}`}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="col-12">
              {item.quest_type === 0 && (
                <AnswerPG
                  item={item}
                  key={`pg${index}`}
                  quizUser={quizUser}
                  isQuizOnlyPG={isQuizOnlyPG}
                />
              )}
            </div>
          )}
          {item.quest_type === 2 && (
            <div className="col-12">
              <AnswerGraphors
                item={item}
                key={`graphors${index}`}
                quizUser={quizUser}
              />
            </div>
          )}
        </div>

        {/* Nilai Soal */}
        {quizUser.u_login && (
          <>
            {(item.quest_type === 1 || item.quest_type === 2) && (
              <div className="border rounded p-2">
                <span className="mr-1 d-block">
                  <span className="f-w-700">
                    Poin soal: {item.quiz_temps[0]?.score}
                  </span>
                </span>
                <div className="text-dark col-12 px-0">
                  <span className="mr-1 f-w-300 f-10 d-block">
                    *Keterangan: bobot soal {item.answers[0].nilai} poin
                  </span>
                </div>
              </div>
            )}
          </>
        )}

        {/* Pembahasan Soal */}
        {isQuizOnlyPG ? (
          <>
            {item.pembahasan && (
              <Pembahasan
                item={item}
                index={index}
              />
            )}
          </>
        ) : (
          <>
            {item.pembahasan && (
              <Pembahasan
                item={item}
                index={index}
              />
            )}
          </>
        )}
      </div>
    </>
  )
}

const AnswerGraphors = ({ item, quizUser }) => {
  if (item.graphor_type == 1) {
    item['jawaban_essay_siswa'] =
      item.quiz_temps.length > 0
        ? item.quiz_temps?.[0]?.answer_text
        : JSON.stringify(['Siswa tidak menjawab'])
    return <GraphorSort item={item} />
  } else if (item.graphor_type == 2) {
    item['jawaban_essay_siswa'] =
      item.quiz_temps.length > 0
        ? item.quiz_temps?.[0]?.answer_text
        : JSON.stringify({ list: ['Siswa tidak menjawab'] })
    return <GraphorGroup item={item} />
  } else {
    return null
  }
}

const AnswerPG = ({ item, quizUser, isQuizOnlyPG }) => {
  const quizTemp = item?.quiz_temps?.[0] || ([...item?.quiz_match_answers]?.reverse())?.[0]

  const isUserDidntAnswer = item?.answers?.find(a => a?.id === quizTemp?.answer_id)
  return (
    <ul className="quiz-components list-unstyled ml-0">
      {(!isUserDidntAnswer) ?
        <div className='mb-3'>
                <Badge variant='danger'>Siswa tidak menjawab</Badge>
            </div>
       :null}
      {item.answers.map((answer, index) => {
        return (
          <li
            key={index}
            className="d-flex mb-2 pl-2 align-items-center"
            style={{
              backgroundColor: quizTemp
                ? quizTemp.answer_id === answer.id
                  ? answer.correct ? 'lightyellow' : '#ffcccb'
                  : 'transparent'
                : 'transparent',
              border: quizTemp
                ? quizTemp.answer_id === answer.id
                  ? answer.correct ? '1px solid #e3eaef' : '1px solid #ff8f8f'
                  : '1px solid transparent'
                : '1px solid transparent',
            }}
          >
            {isQuizOnlyPG ? (
              <>
              {answer.correct === 1 ? (
                <i className="fas fa-check-circle text-success" />
              ) : (
                <i
                  className="fas fa-times-circle text-danger"
                  style={{
                    visibility:
                      quizTemp?.answer_id === answer.id ? 'visible' : 'hidden',
                  }}
                />
              )}
            </>
            ) : (
              <>
                  {answer.correct === 1 ? (
                    <i className="fas fa-check-circle text-success" />
                  ) : (
                    <i
                      className="fas fa-check-circle"
                      style={{ visibility: 'hidden' }}
                    />
                  )}
              </>
            )}
            <div className="ml-2 form-check d-flex align-items-center">
              <input
                disabled={true}
                className="form-check-input"
                type="radio"
                name={answer.question_id}
                id={answer.id}
                value={answer.text}
                checked={quizTemp && quizTemp.answer_id === answer.id}
              />
              <CkeditorReadonly
                className="form-check-label"
                data={answer.text}
              />
            </div>
          </li>
        )
      })}
    </ul>
  )
}

const AnswerEssay = ({ item }) => {
  const quizTemp = 
    item?.quiz_temps?.length
    ? item?.quiz_temps?.[0]?.answer_text
    : item?.quiz_match_answers?.length 
    ? ([...item?.quiz_match_answers]?.reverse())?.[0]?.answer_text
    : ''

  return (
    <>
      {quizTemp ? (
        <div className="p-1">
          <CkeditorReadonly data={quizTemp} />
        </div>
      ) : (
        <div className="p-1 text-danger">
          <i className="fas fa-times-circle mr-1" />
          <span>Siswa tidak mengisi</span>
        </div>
      )}
    </>
  )
}

const Pembahasan = ({ item, index }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <>
      {item.pembahasan && (
        <div className="mb-0 mt-3 bg-white">
          <div
            id={`questPembahasan${index}`}
            style={{
              backgroundColor: isHovered ? '#f6f6f6' : '#fff',
            }}
          >
            <h5 className="row m-0 border rounded">
              <a
                type="button"
                className="col row collapsed text-dark m-0 p-3"
                data-toggle="collapse"
                data-target={`#pembahasanQuest${index}`}
                aria-expanded="false"
                aria-controls={`pembahasanQuest${index}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <div className="col-10 col-lg-11 pl-0">
                  <span
                    className="f-16"
                    style={{ fontWeight: '500' }}
                  >
                    Pembahasan
                  </span>
                </div>
                <div className="col-2 col-lg-1 pr-0 d-flex justify-content-end">
                  <i className="fas fa-chevron-down" />
                </div>
              </a>
            </h5>
          </div>
          <div
            id={`pembahasanQuest${index}`}
            className="p-3 collapse border"
            aria-labelledby={`questPembahasan${index}`}
            data-parent={`#questPembahasan${index}`}
          >            
            <CkeditorReadonly data={item.pembahasan} />
          </div>
        </div>
      )}
    </>
  )
}

import React from 'react'

const PreviewVideoLink = ({ link = '', youtubeId = '' }) => {
  if (link.includes('drive.google.com')) {
    return (
      <iframe
        src={`${link}/preview`}
        style={{
          width: '100%',
          height: '400px',
          border: 'none',
          backgroundColor: '#ddd',
        }}
      />
    )
  } else if (link.includes('youtube.com')) {
    return (
      <iframe
        src={`https://youtube.com/embed/${youtubeId}`}
        style={{
          border: 'none',
          backgroundColor: '#ddd',
          height: '400px',
          width: '100%',
        }}
      />
    )
  } else {
    return (
      <video
        width="100%"
        controls={true}
        muted={false}
        disablePictureInPicture
        controlsList="nodownload"
        onContextMenu={(e) => e.preventDefault()}
      >
        <source
          src={link}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    )
  }
}

export default PreviewVideoLink

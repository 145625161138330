import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'
import 'moment/locale/id'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'
import { openUrlNewTab } from 'util/global'
import { textElipsis } from 'util/string'

export default function VideoConference() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  
  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, deleteRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [limit, setLimit]                       = useState(10)
  const [offset, setOffset]                     = useState(0)
  const [search, setSearch]                     = useState('')
  const [data, setData]                         = useState([])
  const [totalRecords, setTotalRecords]         = useState(0)
  const [currentProfile, setCurrentProfile]     = useState('')
  const [accessConference, setAccessConference] = useState(true)

  /* ========================================= Constants ======================================== */
  const currentDateTime = Date.now()

  /* ========================================= Functions ======================================== */
  async function getData() {
    let response = await getRequest(
      `webinars?limit=${limit}&offset=${offset}&search=${search}`,
    )
    if (response) {
      setData(response.data)
      setCurrentProfile(response.current_profile)
      setTotalRecords(response.totalRecords)
    }
  }

  async function deleteItem(id) {
    Swal.fire({
      title: 'Konfirmasi',
      text: 'Anda akan menghapus conference ini?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await deleteRequest(`webinars?id=${id}`)
        if (response) {
          getData()
          Swal.fire('Berhasil', 'Conference dihapus!', 'success')
        } else {
          Swal.fire(
            'Oppsss!',
            'Terjadi kesalahan coba beberapa saat lagi.',
            'error',
          )
        }
      }
    })
  }

  async function joinMeeting(id) {
    const response = await getRequest('join-meeting?meeting_id=' + id)
    if (response) {
      history.push('zoom-player', { token: response.data.token })
    }
  }

  async function joinViaUrl(id) {
    const response = await getRequest('join-meeting?meeting_id=' + id)
    if (response) {
      if (response.join_url) {
        openUrlNewTab(response.join_url)
      }
    }
  }

  async function notifyUsers(item) {
    Swal.fire({
      title: 'Pengingat Conference',
      text: 'Kirim notifikasi pengingat ke peserta?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, kirim!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let form = new FormData()

        if (item.group_id) {
          form.append('recipient', 'group')
          form.append('type', 'webinar')
          form.append('notif_body', `${item.topic}-${item.agenda}`)
          form.append('value_id', item.group_id)
        } else {
          form.append('recipient', 'global')
          form.append('type', 'webinar')
          form.append('notif_body', `${item.topic}-${item.agenda}`)
        }

        const response = await postRequest('notif', form)

        if (response) {
          Swal.fire(
            'Berhasil!',
            'Notifikasi pengingat berhasil dikirim.',
            'success',
          )
        } else {
          Swal.fire(
            'Oppsss!',
            'Terjadi kesalahan coba beberapa saat lagi.',
            'error',
          )
        }
      }
    })
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getData()
  }, [limit, offset, search])

  useEffect(() => {
    let menus = JSON.parse(localStorage.getItem('menu_key'))
    let finder = menus.find((i) => i === 'conference-access')
    if (finder !== undefined) {
      setAccessConference(false)
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Video Conference" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list  ">
              <div className="card-header">
                <button
                  type="button"
                  className={
                    accessConference
                      ? 'btn btn-primary btn-forbidden'
                      : 'btn btn-primary'
                  }
                  disabled={accessConference}
                  onClick={() => history.push('/video-conference-add')}
                >
                  <i className="feather icon-plus-circle mr-1" />
                  <span>Tambah Conference</span>
                </button>
              </div>
              <div className="card-body">
                <div className="dt-responsive table-responsive">
                  <DataTable
                    className={'table table-striped table-bordered nowrap'}
                    data={data}
                    totalRows={totalRecords}
                    show={[10, 20, 30, 40, 50]}
                    onChangeSearch={(val) => {
                      setSearch(val)
                    }}
                    onChangeLimit={(val) => {
                      setLimit(val)
                    }}
                    onChangePage={(val) => {
                      setOffset(val)
                    }}
                    column={[
                      'Topik',
                      'Agenda',
                      'Waktu',
                      'Durasi',
                      'Password',
                      'Peserta',
                      'Join',
                      'Actions',
                    ]}
                    renderItem={(item, i) => {
                      const duration = item.duration * 60 * 1000
                      const webinarTime = new Date(item.start_time).getTime()
                      const webinarDuration = duration + webinarTime
                      return (
                        <tr key={item.id}>
                          <td title={item.topic} data-toggle="tooltip" data-placement="bottom">
                            {currentProfile !== item.profile_id && (
                              <span className="badge bg-primary text-light mr-1">
                                Shared
                              </span>
                            )}
                            {webinarDuration < currentDateTime && (
                              <span className="badge badge-secondary mr-1">
                                Selesai
                              </span>
                            )}
                            {item.topic ? textElipsis(item.topic, 12) : '-'}
                          </td>
                          <td title={item.agenda}>
                            {item.agenda ? textElipsis(item.agenda, 12) : '-'}
                          </td>
                          <td>
                            {moment(item.start_time).format(
                              'dddd, DD/MM/YYYY HH:mm',
                            )}
                          </td>
                          <td>{item.duration + ' menit'}</td>
                          <td>{item.password}</td>
                          <td>
                            {item.webinar_profiles.length > 0 &&
                            item.webinar_profiles[0].group
                              ? item.webinar_profiles[0].group.group
                              : 'Umum'}
                          </td>
                          <td>
                            {item.active &&
                            item.zoom_status &&
                            webinarDuration >= currentDateTime ? (
                              <>
                              <div class="dropdown">
                                <button class="btn btn-warning dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                  Bergabung
                                </button>
                                <div class="dropdown-menu">
                                  <div className='dropdown-item'>
                                    <button
                                      type="button"
                                      onClick={() => joinMeeting(item.id)}
                                      className="btn btn-sm btn-info mr-1 w-100"
                                    >
                                      <i className="feather icon-video mr-1" />
                                      Join
                                    </button>
                                  </div>
                                  <div className='dropdown-item'>
                                    <button
                                      type="button"
                                      onClick={() => joinViaUrl(item.id)}
                                      className="btn btn-sm btn-primary mr-1 w-100"
                                    >
                                      <i className="feather icon-video mr-1" />
                                      Join via zoom
                                    </button>
                                  </div>
                                  <div className='dropdown-item'>
                                    <button
                                      disabled={
                                        accessConference ||
                                        currentProfile !== item.profile_id
                                      }
                                      type="button"
                                      className={`btn btn-sm btn-warning w-100 ${
                                        currentProfile !== item.profile_id
                                          ? 'btn-forbidden'
                                          : ''
                                      }`}
                                      onClick={() => notifyUsers(item)}
                                    >
                                      <i className="fas fa-bell mr-1" />
                                      Notify
                                    </button>
                                  </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <span className="badge badge-secondary">
                                Tidak Aktif
                              </span>
                            )}
                          </td>
                          <td>
                            <button
                              disabled={
                                accessConference ||
                                currentProfile !== item.profile_id
                              }
                              type="button"
                              className={`btn btn-sm btn-success mr-2 ${
                                currentProfile !== item.profile_id
                                  ? 'btn-forbidden'
                                  : ''
                              }`}
                              title="Edit"
                              onClick={() =>
                                history.push(
                                  `/video-conference-edit/` + item.id,
                                )
                              }
                            >
                              <i className="feather icon-edit mr-1" />
                              Edit
                            </button>

                            <button
                              disabled={
                                accessConference ||
                                currentProfile !== item.profile_id
                              }
                              type="button"
                              className={`btn btn-sm btn-danger ${
                                currentProfile !== item.profile_id
                                  ? 'btn-forbidden'
                                  : ''
                              }`}
                              title="Hapus"
                              onClick={() => deleteItem(item.id)}
                            >
                              <i className="feather icon-trash-2 mr-1" />
                              Hapus
                            </button>
                          </td>
                        </tr>
                      )
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import { createContext, useContext } from 'react'

const ContentContainerContext = createContext()

export function ContentContainerWrapper({
  children,
  ...props
}) {
  const sharedStates = { ...props }

  return (
    <ContentContainerContext.Provider value={sharedStates}>
      {children}
    </ContentContainerContext.Provider>
  )
}

export default function useContentContainerContext() {
  return useContext(ContentContainerContext)
}

import React, { useState } from 'react'
import NotFound from 'component/not-found'

export default function ErrorUI({
  errorMessage = 'Terjadi kesalahan, coba beberapa saat lagi',
  icon = 'fas fa-redo-alt',
}) {
  const [isReloading, setIsReloading] = useState(false)
  const reloadPage = () => {
    setIsReloading(true)
    window.location.reload()
  }
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <NotFound text={errorMessage} />
      <button
        className="btn btn-sm btn-primary mt-3"
        onClick={isReloading ? () => {} : reloadPage}
        disabled={isReloading}
      >
        {isReloading ? (
          <span
            className="spinner-border spinner-border-sm mr-1"
            role="status"
          />
        ) : (
          <i className={`${icon} mr-1`} />
        )}
        Muat Ulang
      </button>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useContentContainerContext from 'context/content-container'
import ContentType from 'component/content/content-type'
import BadgeProgress from '../player/badge-progress'

const ContentEnabled = () => {
  /* ====================================== Consume Context ===================================== */
  const { content, classId } = useContentContainerContext()

  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ========================================== States ========================================== */
  const [isHovered, setIsHovered] = useState(false)

  // ACTIVITIES STATE
  const [contentActivities, setContentActivities] = useState([])
  const [isCompleted, setIsCompleted] = useState(false)

  /* ========================================= Functions ======================================== */
  function goToContentPlayer() {
    history.push(`/katalog-kelas/${classId}/content-player`, {
      dataContent: content,
      classId,
    })
  }

  function checkIsCompleted() {
    contentActivities.forEach((activity) => {
      if (activity.completed_at !== null) {
        setIsCompleted(true)
      }
    })
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    setContentActivities(content.class_content_activities)
  }, [])

  useEffect(() => {
    checkIsCompleted()
  }, [contentActivities])

  /* ========================================== Output ========================================== */
  return (
    <li>
      <button
        onClick={goToContentPlayer}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        type="button"
        className="btn btn-sm p-3 f-16 text-left text-dark border-bottom"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: isHovered
            ? 'rgba(221, 221, 221, 0.2)'
            : 'transparent',
        }}
      >
        <div className="row">
          <div className="col-10 col-sm-11">
            <span className={`text-dark d-block f-16`}>
              {content.urutan}
              {'. '}
              {content.title}
            </span>
            <span className="d-block">
              <ContentType id={content.content_type_id} /> <BadgeProgress />
              {content.durasi ? (
                <span className="f-12 text-muted ml-2">
                  <i className="feather icon-clock f-10 mr-1" />
                  {Math.round(content.durasi / 60)} menit
                </span>
              ) : null}
            </span>
          </div>
          <div className="col-2 col-sm-1 d-flex justify-content-center">
            <div className="custom-control custom-checkbox mt-1">
              <input
                type="checkbox"
                className="custom-control-input"
                checked={isCompleted}
                disabled={false}
                onChange={() => {}}
              />
              <label className="custom-control-label" />
            </div>
          </div>
        </div>
      </button>
    </li>
  )
}

export default ContentEnabled

import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useBaseContext } from 'context/base'

export default function DashboardTeacher() {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [group, setGroup] = useState({
    categories: [],
    series: [],
  })
  
  /* ========================================= Functions ======================================== */
  async function getDashboard() {
    let response = await getRequest('dashboard-teachers')
    if (response) {
      setGroup({
        categories: response.data.group.categories,
        series: response.data.group.series,
      })
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    let isSubscribed = true
    if (
      JSON.parse(localStorage.getItem('role_active'))?.role_type ===
      'instruktur'
    ) {
      if (isSubscribed) {
        getDashboard()
      }
    }

    return () => (isSubscribed = false)
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-md-12">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: 'column',
                    },
                    title: {
                      text: 'Jumlah kelas berdasarkan group',
                    },
                    subtitle: {
                      text: '',
                    },
                    xAxis: {
                      categories: group.categories,
                      crosshair: true,
                    },
                    yAxis: {
                      min: 0,
                      title: {
                        text: '',
                      },
                    },
                    tooltip: {
                      headerFormat:
                        '<span style="font-size:10px">{point.key}</span><table>',
                      pointFormat:
                        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                      footerFormat: '</table>',
                      shared: true,
                      useHTML: true,
                    },
                    plotOptions: {
                      column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                      },
                    },
                    series: group.series,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

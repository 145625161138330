import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useBaseContext } from 'context/base'
import BarChart from 'component/highchart/bar-chart'
import WordCloud from 'component/highchart/word-cloud-chart'
import LoadingSpinner from 'component/loading'
import PgChart from './pg-chart'

const DetailSurvey = () => {
  /* ========================================== Helpers ========================================= */
  const location = useLocation()
  const { content } = location.state

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [resultSurvey, setResultSurvey] = useState([])
  const [isLoading, setIsLoading]       = useState(false)

  /* ========================================= Functions ======================================== */
  async function getSurveyResults() {
    setIsLoading(true)
    const response = await getRequest(
      `survey-dashboard?survey_id=${content.contents_url.survey_id}&content_id=${content.id}`,
    )
    if (response) {
      setResultSurvey(response.data)
      setIsLoading(false)
    } else {
      console.error('ERROR', response)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getSurveyResults()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div>
      {isLoading ? (
        <div className="col-sm-12 text-center">
          <br />
          <LoadingSpinner />
          <br />
        </div>
      ) : (
        <>
          <RenderGroupPg />
          <RenderWordCloud />
          <RenderPg />
        </>
      )}
    </div>
  )

  function RenderGroupPg() {
    const [mode, setMode] = useState('bar')

    if (
      resultSurvey.length > 0 &&
      resultSurvey[0].group.categories.length > 0 &&
      resultSurvey[0].group.series.length > 0
    ) {
      var item = resultSurvey[0].group

      return (
        <div>
          <ul className="nav nav-tabs">
            <li className="nav-item nav-pills">
              <a
                className={`nav-link ${mode === 'bar' && 'active'}`}
                onClick={() => setMode('bar')}
                style={{ cursor: 'pointer' }}
              >
                Bar Chart
              </a>
            </li>
            <li className="nav-item nav-pills">
              <a
                className={`nav-link ${mode !== 'bar' && 'active'}`}
                onClick={() => setMode('pie')}
                style={{ cursor: 'pointer' }}
              >
                Pie Chart
              </a>
            </li>
          </ul>

          {mode === 'bar' ? (
            <BarChart
              categories={item.categories}
              series={item.series}
            />
          ) : (
            <div className="d-flex">
              {item.categories.map((categ, categ_idx) => {
                return (
                  <PgChart
                    key={`pg_chart_` + categ_idx}
                    categories={[categ]}
                    series={item.series.map((s) => ({
                      ...s,
                      data: [s.data[categ_idx]],
                    }))}
                  />
                )
              })}
            </div>
          )}
        </div>
      )
    }
    return null
  }

  function RenderWordCloud() {
    const [readMore, setReadMore] = useState(-1)
    const [wordCloud, setWordCloud] = useState(-1)
    if (resultSurvey.length > 0 && resultSurvey[0].essay.length > 0) {
      var i = resultSurvey[0].essay
      return i.map((item, idx) => {
        return (
          <>
            {item.answer.length > 0 ? (
              <>
                {wordCloud !== idx ? (
                  <>
                    <h5 className="mt-3 mb-3">
                      Pertanyaan: {item.label.replace(/<[^>]*>/g, '')}
                    </h5>
                    <strong>Jawaban Siswa:</strong>
                    {item.answer.map((item, index) => {
                      if (index < 10 || readMore === idx) {
                        return (
                          <p className="mt-2 ml-3">
                            {index + 1}. {item}
                            <br />
                          </p>
                        )
                      } else {
                        return null
                      }
                    })}
                  </>
                ) : (
                  <WordCloud
                    string={item.str}
                    label={item.label}
                    key={idx}
                  />
                )}
                <div className="d-flex justify-content-center align-items-center mb-5">
                  {item.answer.length > 10 && idx !== wordCloud ? (
                    <button
                      onClick={() => {
                        if (idx == readMore) {
                          setReadMore(-1)
                        } else {
                          setReadMore(idx)
                        }
                      }}
                      className="btn btn-secondary mr-2"
                    >
                      Tampilkan lebih {readMore === idx ? 'sedikit' : 'banyak'}
                    </button>
                  ) : null}
                  <button
                    onClick={() => {
                      if (wordCloud === idx) {
                        setWordCloud(-1)
                      } else {
                        setWordCloud(idx)
                      }
                    }}
                    className="btn btn-primary"
                  >
                    Tampilkan{' '}
                    {wordCloud === idx ? 'Jawaban Siswa' : 'WordCloud'}
                  </button>
                </div>
              </>
            ) : (
              <p className="text-muted p-3 mb-0">
                Belum ada jawaban dari siswa.
              </p>
            )}
          </>
        )
      })
    }
    return null
  }

  function RenderPg() {
    const [mode, setMode] = useState('bar')
    if (resultSurvey.length > 0) {
      return (
        <div
          style={{
            borderTop: '1px solid lightgray',
            marginTop: '2rem',
            paddingTop: '1rem',
          }}
        >
          <ul className="nav nav-tabs">
            <li className="nav-item nav-pills">
              <a
                className={`nav-link ${mode === 'bar' && 'active'}`}
                onClick={() => setMode('bar')}
                style={{ cursor: 'pointer' }}
              >
                Bar Chart
              </a>
            </li>
            <li className="nav-item nav-pills">
              <a
                className={`nav-link ${mode !== 'bar' && 'active'}`}
                onClick={() => setMode('pie')}
                style={{ cursor: 'pointer' }}
              >
                Pie Chart
              </a>
            </li>
          </ul>
          {resultSurvey[0].pg.map((val, index) => {
            return (
              <div
                key={index}
                className={'col-md-6'}
              >
                {mode === 'bar' ? (
                  <BarChart
                    categories={val.categories}
                    series={val.series}
                  />
                ) : (
                  <PgChart
                    categories={val.categories}
                    series={val.series}
                  />
                )}
              </div>
            )
          })}
        </div>
      )
    }
    return null
  }
}

export default DetailSurvey

import LoadingSpinnerV2 from 'component/loadingV2';
import useGetRoleActive from 'hooks/useGetRoleActive'
import React from 'react'

/**
 * Base Button
 * 
 * @param {{
 *  children: import('react').ReactNode,
 *  onClick: function,
 *  access: Array,
 *  type: string,
 *  props: import('react').ButtonHTMLAttributes,
 *  variant: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info' | 'light' | 'dark' | 'transparent',
 *  isDisabled: boolean,
 *  isLoading: boolean,
 *  isShow: boolean,
 *  sizeLoading: number,
 *  isMarginRight: boolean,
 * }} props
 */
const BaseButton = ({ children = '', onClick = () => { }, access = [], type = 'button', variant = 'primary', isDisabled = false, isLoading = false, sizeLoading = '2rem', isShow = true, isMarginRight = true, props }) => {
  const roleActive = useGetRoleActive();

  const isNotAccess = access.length ? !access.includes(roleActive.type) : false

  if (isShow) {
    return (
      <button
        {...props}
        type={type}
        onClick={onClick}
        className={`btn ${variant ? `btn-${variant}` : ''} has-ripple ${isMarginRight ? 'mr-2' : ''} ${props?.className}`}
        disabled={isNotAccess || isDisabled || isLoading}
      >
        {isLoading ?
          <LoadingSpinnerV2 size={sizeLoading} />
          :
          children
        }
      </button>
    )
  } else {
    return null;
  }
}

export default BaseButton

import React, { useState, useEffect } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { Back } from 'component/button'
import PreviewQuestionQuiz from './preview-question'

const DetailQuiz = () => {
  /* ========================================== Helpers ========================================= */
  const location = useLocation()
  const history  = useHistory()
  const params   = useParams()
  const quizId   = params.quizId
  const dataQuiz = location.state.existingData
    ? location.state.existingData
    : {}

  /* ====================================== Consume Context ===================================== */
  const { getRequest, deleteRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [questions, setQuestions] = useState([])
  const [previewQuestion, setPreviewQuestion] = useState({})

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/kelola-quiz', name: 'Kelola Quiz' }]

  /* ========================================= Functions ======================================== */
  async function getQuestions() {
    const response = await getRequest(
      `questions-quiz?quiz_id=${quizId}`,
    )
    if (response) {
      setQuestions(response.data)
    } else {
      console.error('ERROR', response)
    }
  }

  function handleAdd(event, questType) {
    event.preventDefault()
    switch (questType) {
      case 'pilihan-ganda':
        history.push(`/kelola-quiz/${dataQuiz.id}/add-pilihan-ganda`)
        break
      case 'uraian':
        history.push(`/kelola-quiz/${dataQuiz.id}/add-uraian`)
        break
      case 'graphor':
        history.push(`/kelola-quiz/${dataQuiz.id}/add-graphor`)
        break
      default:
        break
    }
  }

  function handleEdit(questType, questionData) {
    switch (questType) {
      case 0:
        history.push(`/kelola-quiz/edit-pilihan-ganda/${questionData.id}`, {
          existingData: questionData,
        })
        break
      case 1:
        history.push(`/kelola-quiz/edit-uraian/${questionData.id}`, {
          existingData: questionData,
        })
        break
      case 2:
        history.push(`/kelola-quiz/edit-graphor/${questionData.id}`, {
          existingData: questionData,
        })
        break
      default:
        break
    }
  }

  function handleDelete(questionId, urutan) {
    Swal.fire({
      title: 'Apa anda yakin?',
      text: `Anda akan menghapus pertanyaan nomor ${urutan}!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(
          `questions?id=${questionId}`,
        )
        if (response) {
          Swal.fire({
            icon: 'success',
            text: 'Pertanyaan berhasil dihapus.',
          }).then(() => history.go(0))
        } else {
          Swal.fire(
            'Oppsss!',
            'Terjadi kesalahan coba beberapa saat lagi.',
            'error',
          )
        }
      }
    })
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getQuestions()
  }, [])

  /* ======================================== Components ======================================== */
  const RenderItem = ({ item, handleEdit, handleDelete }) => {
    return (
      <tr>
        <td>{item.urutan ? item.urutan : ''}</td>
        <td>
          {item.quest_type === 0 ? (
            <span>
              <i className="feather icon-check-square mr-1" />
              Pilihan ganda
            </span>
          ) : null}
          {item.quest_type === 1 ? (
            <span>
              <i className="fas fa-pen mr-1" />
              Uraian
            </span>
          ) : null}
          {item.quest_type === 2 ? (
            <span>
              <i className="fas fa-object-ungroup mr-1" />
              Graphic Organizer
            </span>
          ) : null}
        </td>
        <td
          className="quiz-question-preview"
          dangerouslySetInnerHTML={{ __html: item.text }}
        />
        <td>
          {item.active ? (
            <span className="badge badge-success">Aktif</span>
          ) : (
            <span className="badge badge-secondary">Tidak Aktif</span>
          )}
        </td>
        <td>
          <button
            onClick={() => handleEdit(item.quest_type, item)}
            type="button"
            className="btn btn-sm btn-success mr-2"
          >
            <i className="feather icon-edit mr-1" />
            Edit
          </button>
          <button
            type="button"
            className="btn btn-sm btn-warning mr-2"
            data-toggle="modal"
            data-target="#preview-question-quiz-modal"
            onClick={() => setPreviewQuestion(item)}
          >
            <i className="fas fa-eye mr-1" />
            Preview
          </button>
          <button
            onClick={() => handleDelete(item.id, item.urutan)}
            type="button"
            className="btn btn-sm btn-danger mr-2"
          >
            <i className="feather icon-trash-2 mr-1" />
            Hapus
          </button>
        </td>
      </tr>
    )
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Daftar Soal"
          linkName={dataQuiz?.title || ''}
          links={links}
        />

        <div className="row">
          <div className="col">
            <div className="card user-profile-list">
              <div className="card-header">
                <div className="row">
                  <div className="col">
                    <h4>{dataQuiz.title ? dataQuiz.title : '-'}</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p className="m-0">
                      {dataQuiz.description ? dataQuiz.description : ''}
                    </p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="btn-group mr-2">
                      <button
                        className="btn  btn-primary dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="feather icon-plus-circle mr-1" />
                        <span>Tambah Soal</span>
                      </button>
                      <div className="dropdown-menu">
                        <a
                          onClick={(event) => handleAdd(event, 'pilihan-ganda')}
                          className="dropdown-item"
                          type="button"
                        >
                          <i className="feather icon-check-square mr-1" />
                          <span>Pilihan Ganda</span>
                        </a>
                        <a
                          onClick={(event) => handleAdd(event, 'uraian')}
                          className="dropdown-item"
                          type="button"
                        >
                          <i className="fas fa-pen mr-1" />
                          <span>Uraian</span>
                        </a>
                        <a
                          onClick={(event) => handleAdd(event, 'graphor')}
                          className="dropdown-item"
                          type="button"
                        >
                          <i className="fas fa-object-ungroup mr-1" />
                          <span>Graphic Organizer (Drag & Drop)</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="dt-responsive table-responsive">
                      <div className="dt-responsive table-responsive">
                        <table
                          className={
                            'table table-striped table-bordered nowrap'
                          }
                        >
                          <thead>
                            <tr>
                              <th>Nomor</th>
                              <th>Tipe</th>
                              <th>Pertanyaan</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {questions.length > 0 ? (
                              questions.map((item) => (
                                <RenderItem
                                  item={item}
                                  key={item.id}
                                  handleEdit={handleEdit}
                                  handleDelete={handleDelete}
                                />
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={5}
                                  className="text-center"
                                >
                                  Belum ada pertanyaan
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="row">
                  <div className="col">
                    <Back />
                  </div>
                </div>
              </div>
            </div>

            {/* Preview question modal */}
            <PreviewQuestionQuiz dataQuestion={previewQuestion} />
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

export default DetailQuiz

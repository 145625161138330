import React, { useEffect, useState, useRef } from 'react'
import useChatContext from 'context/chat'
import { host } from 'util/host'

export default function ModalInfoGroup() {
  /* ====================================== Consume Context ===================================== */
  const { current, contact } = useChatContext()
  
  /* ======================================= States & Refs ====================================== */
  const [activeUsers, setActiveUsers] = useState([])
  const mounted = useRef(true)
  
  /* ========================================= Constants ======================================== */
  const chat     = current.data
  const contacts = contact.list
  const { id, linkAvatar } = JSON.parse(localStorage.getItem('user'))

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (chat) {
      let active = []
      chat.participants.forEach((participant) => {
        if (contacts) {
          if (contacts.length !== 0) {
            contacts.forEach((contact) => {
              if (participant.id === contact.id) {
                active.push(contact)
              }
            })
          }
        }
        if (participant.id === id) {
          active.push({
            id: id,
            image: linkAvatar,
            name: participant.name,
            type: 'siswa',
          })
        }
      })
      if (mounted.current === true) setActiveUsers(active)
    }
  }, [chat])

  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  /* ======================================== Components ======================================== */
  const Member = ({ participant }) => {
    const defaultAvatar = '/admin/assets/images/user/blank-avatar-user.svg'
    return (
      <li className="row p-2 m-0 border-bottom">
        <div className="col-auto m-0">
          <div className="u-img">
            <img
              src={`${host}${participant.image}`}
              onError={(event) => (event.currentTarget.src = defaultAvatar)}
              alt={participant.name ? participant.name : 'Kontak'}
              className="img-radius profile-img"
              style={{ width: '40px', height: '40px' }}
            />
          </div>
        </div>
        <div className="col m-0 d-flex flex-column justify-content-center align-items-start">
          <h6 className="m-b-5">{participant.name ? participant.name : '-'}</h6>
        </div>
      </li>
    )
  }

  /* ========================================== Output ========================================== */
  return (
    <div
      id="modalInfoGroup"
      className="modal fade"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalInfoGroupTitle"
      aria-modal="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="modalInfoGroupTitle"
            >
              Anggota
            </h5>
            <span className="badge badge-light-primary float-right">
              {activeUsers.length}
            </span>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body p-0">
            {activeUsers.length !== 0 ? (
              <ul className="m-0 p-0">
                {activeUsers.map((participant, idx) => (
                  <Member
                    key={`chatItem__${participant.id}__${idx}`}
                    participant={participant}
                  />
                ))}
              </ul>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

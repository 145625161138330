import React from 'react'

const SubmitButton = ({ text, onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="btn btn-success has-ripple mr-2"
    >
      {text}
    </button>
  )
}

export default SubmitButton

import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import CkeditorComponent from 'component/ckeditor'
import { Back } from 'component/button'
import InvalidText from 'component/invalid/text'

export default function GraphicOrganizerGroupAdd() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()
  
  /* ========================================= Constants ======================================== */
  const grid = 8
  const links = [{ url: '/graphic-organizer', name: 'Graphic Organizer' }]

  /* ======================================= Local States ======================================= */
  const [text, setText]           = useState('')
  const [textGroup, setTextGroup] = useState('')
  const [randId, setRandId]       = useState(makeid(5))

  const [name, setName]             = useState('')
  const [status, setStatus]         = useState(0)
  const [group, setGroup]           = useState([])
  const [listGroup, setListGroup]   = useState({})
  const [pembahasan, setPembahasan] = useState('')

  // Validation State
  const [isGroupInValid, setIsGroupInValid] = useState(false)
  const [isNameInValid, setIsNameInValid]   = useState(false)
  
  /* =========================================== Refs =========================================== */
  const refName  = useRef()
  const refList  = useRef()
  const refGroup = useRef()

  /* ================================= COMPONENTS DRAG AND DROP ================================= */
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 1,
    margin: `0 0 ${grid}px 0`,
    borderRadius: '5px',
    // change background colour if dragging
    background: isDragging ? 'lightyellow' : 'white',
    boxShadow: isDragging
      ? '1px 1px 8px rgba(200,200,200,0.5)'
      : '1px 1px 3px rgba(200,200,200,0.5)',
    border: isDragging ? '1px solid #ddd' : 'none',
    color: 'black',
    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone   = Array.from(destination)
    const [removed]   = sourceClone.splice(droppableSource.index, 1)
    destClone.splice(droppableDestination.index, 0, removed)

    const result = listGroup
    result[droppableSource.droppableId]      = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    var source = result.source
    var destination = result.destination
    if (source.droppableId === destination.droppableId) {
      var l = reorder(
        listGroup[source.droppableId],
        source.index,
        destination.index,
      )
      setRandId(makeid(5))
      var a = listGroup
      a[source.droppableId] = l
      setListGroup(a)      
    } else {
      var listSource = listGroup[source.droppableId]
      var listDestination = listGroup[destination.droppableId]
      const result = move(listSource, listDestination, source, destination)
      setListGroup(result)
      setRandId(makeid(5))
    }
  }

  function makeid(length) {
    var result = ''
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }
  /* ================================ END COMPONENT DRAG AND DROP =============================== */

  /* ========================================= Functions ======================================== */
  function handleChangeName(event) {
    setName(event.target.value)
  }

  function handleChangeStatus(event) {
    switch (event.target.checked) {
      case true:
        setStatus(1)
        break
      case false:
        setStatus(0)
        break
      default:
        break
    }
  }

  function handleAddList(e) {
    e.preventDefault()
    let a = listGroup
    if (listGroup['list'] == undefined) {
      a.list = [text]
      setListGroup(a)
      setRandId(makeid(5))
    } else {
      a['list'].push(text)
      setRandId(makeid(5))
    }
    setText('')
  }

  function handleAddListEnterKey(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      let a = listGroup
      if (listGroup['list'] == undefined) {
        a.list = [text]
        setListGroup(a)
        setRandId(makeid(5))
      } else {
        a['list'].push(text)
        setRandId(makeid(5))
      }
      setText('')
    }
  }

  function handleAddGroup(e) {
    e.preventDefault()
    let t = group
    t.push(textGroup)
    setGroup(() => t)
    setTextGroup('')

    let a = listGroup
    if (listGroup[textGroup] == undefined) {
      a[textGroup] = []
      setListGroup(a)
      setRandId(makeid(5))      
    }
    setTextGroup('')
    setRandId(makeid(5))
  }

  function handleAddGroupEnterKey(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      let t = group
      t.push(textGroup)
      setGroup(() => t)
      setTextGroup('')

      let a = listGroup
      if (listGroup[textGroup] == undefined) {
        a[textGroup] = []
        setListGroup(a)
        setRandId(makeid(5))
      }
      setTextGroup('')
      setRandId(makeid(5))
    }
  }

  function handleFormsValidation() {
    if (name && Object.keys(listGroup).length > 1) {
      setIsNameInValid(false)
      setIsGroupInValid(false)
      handleSubmit(name, status, listGroup, pembahasan)
    } else {
      if (!name) {
        refName.current.focus()
        setIsNameInValid(true)
      } else {
        setIsNameInValid(false)
      }
      if (Object.keys(listGroup).length < 1) {
        refGroup.current.focus()
        setIsGroupInValid(true)
      } else {
        setIsGroupInValid(false)
        if (listGroup['list'] == undefined) {
          window.notification(
            'Group telah di buat',
            'Silahkan isi data list untuk mengisi list group',
          )
          refList.current.focus()
        }
      }
      window.notification('Data belum lengkap', 'Mohon lengkapi data')
    }
  }

  async function handleSubmit(name, status, listGroup, pembahasan) {
    let form = {
      description: name,
      graphor_type: 2,
      active: status,
      data: listGroup,
      pembahasan: pembahasan,
    }
    const response = await postRequest('graphors', form)
    if (response) {      
      window.notification(
        'Berhasil',
        'Graphic Organizer Grouping Berhasil ditambahkan',
        'success',
      )
      history.push('/graphic-organizer')
    } else {
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }  

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Graphic Organizer: Grouping"
          linkName="Tambah Grouping"
          links={links}
        />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Tambah Grouping</h5>
              </div>
              <div className="card-body">
                <div className={'row p-3'}>
                  <div className={'col-md-12'}>
                    <form>
                      <div className="form-group row">
                        <label
                          htmlFor="nama-list"
                          className="col-sm-3 col-form-label "
                        >
                          Nama
                        </label>
                        <div className="col-sm-9">
                          <input
                            ref={refName}
                            value={name}
                            onChange={(event) => handleChangeName(event)}
                            type="text"
                            className={`form-control`}
                            id="nama-list"
                            placeholder="Judul grup list"
                          />
                          {isNameInValid && <InvalidText name={'Nama group'} />}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="tambah-list"
                          className="col-sm-3 col-form-label "
                        >
                          Tambah item
                        </label>
                        <div className="col-sm-9">
                          <div className="input-group mb-3">
                            <input
                              ref={refList}
                              onKeyDown={(event) =>
                                handleAddListEnterKey(event)
                              }
                              value={text}
                              onChange={(e) => setText(e.target.value)}
                              id="tambah-list"
                              type="text"
                              className={`form-control`}
                              placeholder="Tambah item list untuk group"
                            />
                            {text.length > 0 && (
                              <div className="input-group-append">
                                <button
                                  onClick={(event) => handleAddList(event)}
                                  className="btn  btn-primary"
                                  type="button"
                                >
                                  <i className="fas fa-plus" />
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="tambah-group"
                          className="col-sm-3 col-form-label "
                        >
                          Tambah grup
                        </label>
                        <div className="col-sm-9">
                          <div className="input-group mb-3">
                            <input
                              ref={refGroup}
                              onKeyDown={(event) =>
                                handleAddGroupEnterKey(event)
                              }
                              value={textGroup}
                              onChange={(e) => setTextGroup(e.target.value)}
                              id="tambah-group"
                              type="text"
                              className={`${
                                isGroupInValid && 'is-invalid'
                              } form-control`}
                              placeholder="Tambah group list"
                            />
                            {textGroup.length > 0 && (
                              <div className="input-group-append">
                                <button
                                  onClick={(event) => handleAddGroup(event)}
                                  className="btn  btn-primary"
                                  type="button"
                                >
                                  <i className="fas fa-plus" />
                                </button>
                              </div>
                            )}
                          </div>
                          {isGroupInValid && <InvalidText name={'Group'} />}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="pembahasan"
                          className="col-sm-3 col-form-label"
                        >
                          Pembahasan
                        </label>
                        <div className="col-sm-9">
                          <CkeditorComponent
                            data={pembahasan}
                            configuration="full"
                            onChange={setPembahasan}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="check-list"
                          className="col-sm-3 col-form-label "
                        >
                          Status
                        </label>
                        <div className="col-sm-9">
                          <div className="switch switch-primary d-inline m-r-10">
                            <input
                              onChange={(event) => handleChangeStatus(event)}
                              checked={status === 1}
                              type="checkbox"
                              id="switch-p-1"
                              defaultChecked
                            />
                            <label
                              htmlFor="switch-p-1"
                              className="cr"
                            />
                          </div>
                          <label>
                            {status === 1 ? 'Aktif' : 'Tidak Aktif'}
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <hr />
                <div className="form-group mb-2">
                  <label className="text-muted">Keterangan:</label>
                  <div
                    className="ml-2"
                    style={{ lineHeight: '0' }}
                  >
                    <span className="text-danger mr-1">*</span>
                    <label className="text-muted">
                      Drag & drop daftar list ke group untuk mengisi list group
                    </label>
                  </div>
                </div>
                <div className={'row p-3'}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={'list'}>
                      {(provided, snapshot) => (
                        <div className="col-sm-4 p-1">
                          <div
                            className="card text-white bg-success"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            <div className="card-header font-weight-bold">
                              LIST
                            </div>
                            <div className="card-body p-2">
                              {listGroup['list'] != undefined
                                ? listGroup['list'].map((item, index) => {
                                    return (
                                      <Draggable
                                        key={randId + makeid(10)}
                                        draggableId={item}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style,
                                            )}
                                            onMouseEnter={(e) =>
                                              (e.currentTarget.style.backgroundColor =
                                                'lightyellow')
                                            }
                                            onMouseLeave={(e) =>
                                              (e.currentTarget.style.backgroundColor =
                                                '#fff')
                                            }
                                          >
                                            <div className="row">
                                              <div className="col-1">
                                                <button
                                                  type={'button'}
                                                  onClick={() => {
                                                    var a = listGroup
                                                    a['list'].splice(index, 1)
                                                    setListGroup(a)
                                                    setRandId(makeid(5))
                                                  }}
                                                  className={
                                                    'btn btn-danger btn-sm'
                                                  }
                                                >
                                                  <i
                                                    className={'fas fa-trash'}
                                                  ></i>
                                                </button>
                                              </div>
                                              <b className="col-10 col-md-8 col-xl-10 ml-2 pt-1">
                                                {item}
                                              </b>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    )
                                  })
                                : null}
                              {provided.placeholder}
                            </div>
                          </div>
                        </div>
                      )}
                    </Droppable>
                    <DRable />
                  </DragDropContext>
                </div>
              </div>
              <div className="card-footer">
                <div className="row">
                  <div className="col">
                    <Back />
                    <button
                      onClick={() => handleFormsValidation()}
                      className="btn btn-success"
                    >
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
  function DRable() {
    return group.map((val, i) => {
      return (
        <Droppable
          droppableId={`${val}`}
          key={i}
        >
          {(provided, snapshot) => (
            <div className="col-sm-4 p-1">
              <div
                className="card text-white bg-info"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div className="card-header">
                  <b>{val.toUpperCase()}</b>
                  <button
                    onClick={() => {
                      var index = group.indexOf(val)
                      var gr = group
                      gr.splice(index, 1)
                      setGroup(gr)
                      var l = listGroup
                      delete l[val]
                      setListGroup(l)
                      setRandId(makeid(5))
                    }}
                    className={'btn btn-danger btn-sm float-right'}
                  >
                    Hapus
                  </button>
                </div>
                <div className="card-body p-2">
                  {listGroup[val] != undefined
                    ? listGroup[val].map((item, index) => (
                        <Draggable
                          key={randId + item}
                          draggableId={item}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}
                              onMouseEnter={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  'lightyellow')
                              }
                              onMouseLeave={(e) =>
                                (e.currentTarget.style.backgroundColor = '#fff')
                              }
                            >
                              <div className="row">
                                <div className="col-1">
                                  <button
                                    type={'button'}
                                    onClick={() => {
                                      var a = listGroup
                                      a[val].splice(index, 1)
                                      setListGroup(a)
                                      setRandId(makeid(5))
                                    }}
                                    className={'btn btn-danger btn-sm'}
                                  >
                                    <i className={'fas fa-trash'}></i>
                                  </button>
                                </div>
                                <b className="col-8 col-xl-10 ml-2 pt-1">
                                  {item}
                                </b>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))
                    : null}
                  {provided.placeholder}
                </div>
              </div>
            </div>
          )}
        </Droppable>
      )
    })
  }
}

import React from 'react';

export default function ForumGroupDisabled({ message }) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row mb-3">
          <div className="col d-flex flex-column justify-content-center">
            <i
              className="fas fa-comment-slash f-60 text-center mb-3"
              style={{
                color: "#ccc"
              }}
            />
            <h6
              className="text-center"
              style={{
                color: "#ddd"
              }}
            >{message}</h6>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="input-group">
              <input disabled type="text" className="form-control" placeholder="Tambah diskusi" />
              <div className="input-group-append">
                <button disabled className="btn  btn-primary" type="button">Kirim</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
import BaseButton from "component/button/BaseButton"
import { Fragment, useEffect, useState } from "react"

import Semester1 from "pages/scores/tabContent/tabContentLedger/Semester1"
import Semester2 from "pages/scores/tabContent/tabContentLedger/Semester2"
import Semester3 from "pages/scores/tabContent/tabContentLedger/Semester3"
import Semester4 from "pages/scores/tabContent/tabContentLedger/Semester4"
import Semester5 from "pages/scores/tabContent/tabContentLedger/Semester5"
import Semester6 from "pages/scores/tabContent/tabContentLedger/Semester6"

import Rekapitulasi from "pages/scores/tabContent/tabContentLedger/Rekapitulasi"

import NotFound from "component/not-found"
import BaseSelect from "component/select/BaseSelect"
import { useBaseContext } from "context/base"
import useFile from "hooks/useFile"
import moment from "moment"
import BaseButtonInformationModal from "component/button/BaseButtonInformationModal"

const Ledger = ({ }) => {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()
  const { downloadFile } = useFile();

  const [isLoadingSemesters, setIsLoadingSemesters] = useState(false)
  const [isLoadingOptionsTahunAngkatan, setIsLoadingOptionsTahunAngkatan] = useState(false)
  const [isLoadingDownloadAllSemester, setIsLoadingDownloadAllSemester] = useState(false)

  const [optionsTahunAngkatan, setOptionsTahunAngkatan] = useState([])

  const [selectedTahunAngkatan, setSelectedTahunAngkatan] = useState(null)
  const [semesters, setSemesters] = useState([])

  const listKeterangan = [
    <div><span className="font-weight-bolder">x</span> = Siswa tidak masuk mata pelajaran.</div>,
    <div><span className="font-weight-bolder">-</span> = Siswa masuk mata pelajaran tapi belum ada nilai.</div>,
  ]

  const renderSemester = (number, semester) => {
    switch (number) {
      case 1:
        return <Semester1 semester={semester} ta_tahun_code={selectedTahunAngkatan?.value} />
      case 2:
        return <Semester2 semester={semester} ta_tahun_code={selectedTahunAngkatan?.value} />
      case 3:
        return <Semester3 semester={semester} ta_tahun_code={selectedTahunAngkatan?.value} />
      case 4:
        return <Semester4 semester={semester} ta_tahun_code={selectedTahunAngkatan?.value} />
      case 5:
        return <Semester5 semester={semester} ta_tahun_code={selectedTahunAngkatan?.value} />
      case 6:
        return <Semester6 semester={semester} ta_tahun_code={selectedTahunAngkatan?.value} />
    }
  }

  async function getTahunAngkatan() {
    setIsLoadingOptionsTahunAngkatan(true)
    try {
      let response = await getRequest(`academic-year`)
      if (response) {
        let arr = []
        response.data.forEach((val) => {
          arr.push({ value: val.code, label: val.description })
        })
        setOptionsTahunAngkatan(arr)
        setIsLoadingOptionsTahunAngkatan(false)
      } else {
        setIsLoadingOptionsTahunAngkatan(false)
      }
    } catch (error) {
      setIsLoadingOptionsTahunAngkatan(false)
      console.error('ERROR', { error })
    }
  }

  async function downloadAllSemester() {
    setIsLoadingDownloadAllSemester(true)
    try {
      const response = await getRequest(`ledger-download?ta_tahun_code=${selectedTahunAngkatan?.value}`, true, {
        responseType: 'blob',
      })

      if (response) {
        downloadFile({ blobData: response, fileName: `LMS_Ledger_${selectedTahunAngkatan?.label.replaceAll('/', "-")}_${moment().format('DD-MM-YYYY')}.xlsx` })
        setIsLoadingDownloadAllSemester(false)
      } else {
        setIsLoadingDownloadAllSemester(false)
      }
    } catch (error) {
      setIsLoadingDownloadAllSemester(false)
      console.error('ERROR', { error })
    }
  }

  const getSemesters = async (ta_tahun_code) => {
    setIsLoadingSemesters(true)

    try {
      const response = await getRequest(
        `semester?ta_tahun_code=${ta_tahun_code}&order=asc`  
      )
      if (response) {
        const data = response?.data

        if (Array.isArray(data) && !!data.length) {
          setSemesters(data)
        }
        setIsLoadingSemesters(false)
      } else {
        setIsLoadingSemesters(false)
      }
    } catch (error) {
      setIsLoadingSemesters(false)
    }
  }

  const onChangeHandler = (selectedTahunAngkatan) => {
    setSelectedTahunAngkatan(selectedTahunAngkatan)
    getSemesters(selectedTahunAngkatan?.value)
  }

  useEffect(() => {
    getTahunAngkatan()
  }, [])

  return (
    <div>
      <div className="row gap-5">
        <div className="col-md-12 mb-3 mb-md-0">
          <div className="d-flex justify-content-between">
            <div style={{flex: 0.3}}>
              <span className="f-w-700 f-14">Tahun Angkatan</span>
              <BaseSelect
                className="mt-2"
                placeholder="Pilih tahun angkatan..."
                isMulti={false}
                options={optionsTahunAngkatan}
                value={selectedTahunAngkatan}
                onChange={onChangeHandler}
                isLoading={isLoadingOptionsTahunAngkatan}
                styles={{
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    zIndex: '9999999 !important',
                  }),
                }}
              />
            </div>
            <div>
              <BaseButtonInformationModal
                list={listKeterangan}
              />
            </div>
          </div>
        </div>
      </div>
      {!isLoadingSemesters && Array.isArray(semesters) && !!semesters.length
      ?
      <div className="row mt-md-4 mt-3 mb-2 mb-md-3 mb-2">
        <div className="col col-12 col-md-2">
          <BaseButton
            variant="success"
            props={{
              className: 'w-100',
            }}
            isLoading={isLoadingDownloadAllSemester}
            onClick={downloadAllSemester}
          >
            Export
          </BaseButton>
        </div>
      </div>
      : null}
      {isLoadingSemesters
      ?
      <div className="row">
        <div className="col col-12">
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h4 className='mb-3'>Memuat...</h4>
            <div className="spinner-border text-warning" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            <h5 className='mt-3'>Ditunggu ya!</h5>
          </div>
        </div>
      </div>
      : !isLoadingSemesters && Array.isArray(semesters) && !!semesters.length
      ?
      <div className="row">
        <div className="col col-12 mb-4">
        <ul
          className="nav nav-tabs mb-3"
          id="tabScores"
          role="tablist"
        >
          {semesters.map((_, index) => {
            const number = index + 1

            return (
              <Fragment
                key={`list-semester-ledger-index${index}`}
              >
                {index === 0
                ?
                <li className="nav-item">
                  <a
                    className="nav-link active text-uppercase"
                    id={`semester${number}Tab`}
                    data-toggle="tab"
                    href={`#semester${number}`}
                    role="tab"
                    aria-controls={`semester${number}`}
                    aria-selected="true"
                  >
                    Semester {number}
                  </a>
                </li>
                :
                <li className="nav-item">
                  <a
                    className="nav-link text-uppercase"
                    id={`semester${number}Tab`}
                    data-toggle="tab"
                    href={`#semester${number}`}
                    role="tab"
                    aria-controls={`semester${number}`}
                    aria-selected="false"
                  >
                    Semester {number}
                  </a>
                </li>
                }
              </Fragment>
            )
          })}
          <li className="nav-item">
            <a
              className="nav-link text-uppercase"
              id="rekapitulasiTab"
              data-toggle="tab"
              href="#rekapitulasi"
              role="tab"
              aria-controls="rekapitulasi"
              aria-selected="false"
            >
              Rekapitulasi Semester 1{semesters.length ? `~${semesters.length}` : ''}
            </a>
          </li>
        </ul>
        <div
          className="tab-content"
          id="tabScoresContent"
        >
          {semesters.map((semester, index) => {
            const number = index + 1

            return (
              <Fragment
                key={`list-semester-ledger-index${index}`}
              >
                {index === 0
                ?
                <div
                  className="tab-pane fade show active user-profile-list"
                  id={`semester${number}`}
                  role="tabpanel"
                  aria-labelledby={`semester${number}Tab`}
                >
                  {renderSemester(number, semester)}
                </div>
                :
                <div
                  className="tab-pane fade user-profile-list"
                  id={`semester${number}`}
                  role="tabpanel"
                  aria-labelledby={`semester${number}Tab`}
                >
                  {renderSemester(number, semester)}
                </div>
                }
              </Fragment>
            )
          })}
          <div
            className="tab-pane fade user-profile-list"
            id="rekapitulasi"
            role="tabpanel"
            aria-labelledby="rekapitulasiTab"
          >
            <Rekapitulasi ta_tahun_code={selectedTahunAngkatan?.value} total_semester={semesters?.length} />
          </div>
        </div>
        </div>
        {/* <div className="col col-12">
          <span className="text-danger">*</span>Keterangan:
          <ul style={{ listStyle: 'none' }}>
            <li><strong>x</strong> = Siswa tidak masuk mata pelajaran.</li>
            <li><strong>-</strong> = Siswa masuk mata pelajaran tapi belum ada nilai.</li>
          </ul>
        </div> */}
      </div>
      : !isLoadingSemesters && selectedTahunAngkatan && !semesters?.length
      ?
      <div className="row">
        <div className="col col-12">
          <NotFound
            text="Data Tidak Ditemukan"
            icon="fas fa-question"
          />
        </div>
      </div>
      :
      <div className="row">
        <div className="col col-12">
          <NotFound
            text="Pilih tahun ajaran untuk menampilkan data ledger"
            icon="fas fa-graduation-cap"
          />
        </div>
      </div>
      }
    </div>
  )
}

export default Ledger

import React from 'react'
import Select from 'react-select'
import { popUpWindow } from 'util/global'

const FormSelectAssignment = ({
  handleChangeAssignment,
  assignmentList,
  existingAssignmentId,
  isAssignmentInvalid,
  refSelectAssignment,
  getAssignmentList,
}) => (
  <div className="form-group row mb-5">
    <label className="col-form-label col-sm-3">Tugas</label>
    <div className="row col-sm-9">
      {/* Select Assignment */}
      <div className="col-sm-8">
        <Select
          ref={refSelectAssignment}
          isSearchable={true}
          isMulti={false}
          onMenuOpen={() => getAssignmentList()}
          placeholder="Pilih tugas"
          noOptionsMessage={() => 'Data tugas tidak dtemukan'}
          isClearable={true}
          options={assignmentList}
          value={existingAssignmentId}
          onChange={handleChangeAssignment}
        />
        {isAssignmentInvalid ? (
          <span className="col small form-text text-danger">
            Harap pilih tugas.
          </span>
        ) : null}
      </div>

      {/* Add new assignment */}
      <div className="col-sm-4">
        <button
          type="button"
          className="btn btn-primary btn-sm mt-2 mt-md-1"
          onClick={() =>
            popUpWindow('/kelola-assignment/add?popup=true', window, 800, 600)
          }
        >
          <i className="feather icon-plus-circle mr-1" />
          <span>Tambah Tugas</span>
        </button>
      </div>
    </div>
  </div>
)

export default FormSelectAssignment

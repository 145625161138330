import React from 'react'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'

export default function SertifikasiSiswa() {
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Sertifikasi Siswa" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Sertifikasi Siswa</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-lg-3">
                    <div className="card bg-light">
                      <div className="card-body text-center">
                        <h5 className="">Sertifikat</h5>
                        <h6>Sahrul Gunawan</h6>
                        <i className="fas fa-award h1 text-warning" />
                        <p className="my-3 text-muted">11/12/2020</p>
                        <hr />
                        <div className="row align-items-center">
                          <div className="col-sm-6 text-left">
                            <button
                              type="button"
                              className="btn btn-outline-primary has-ripple"
                            >
                              <i className="feather icon-share-2 mr-1" />
                              Bagikan
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="card bg-light">
                      <div className="card-body text-center">
                        <h5 className="">Sertifikat</h5>
                        <h6>Sahrul Gunawan</h6>
                        <i className="fas fa-award h1 text-warning" />
                        <p className="my-3 text-muted">11/12/2020</p>
                        <hr />
                        <div className="row align-items-center">
                          <div className="col-sm-6 text-left">
                            <button
                              type="button"
                              className="btn btn-outline-primary has-ripple"
                            >
                              <i className="feather icon-share-2 mr-1" />
                              Bagikan
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="card bg-light">
                      <div className="card-body text-center">
                        <h5 className="">Sertifikat</h5>
                        <h6>Sahrul Gunawan</h6>
                        <i className="fas fa-award h1 text-warning" />
                        <p className="my-3 text-muted">11/12/2020</p>
                        <hr />
                        <div className="row align-items-center">
                          <div className="col-sm-6 text-left">
                            <button
                              type="button"
                              className="btn btn-outline-primary has-ripple"
                            >
                              <i className="feather icon-share-2 mr-1" />
                              Bagikan
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="card bg-light">
                      <div className="card-body text-center">
                        <h5 className="">Sertifikat</h5>
                        <h6>Sahrul Gunawan</h6>
                        <i className="fas fa-award h1 text-warning" />
                        <p className="my-3 text-muted">11/12/2020</p>
                        <hr />
                        <div className="row align-items-center">
                          <div className="col-sm-6 text-left">
                            <button
                              type="button"
                              className="btn btn-outline-primary has-ripple"
                            >
                              <i className="feather icon-share-2 mr-1" />
                              Bagikan
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import { createContext, useContext, useState } from 'react'

const ForumDetailItemContext = createContext()

export function ForumDetailItemWrapper({ item, children }) {
  const [replyLoading, setReplyLoading] = useState(false)
  const [likeLoading, setLikeLoading]   = useState(false)

  const sharedStates = {
    item: {
      reply: {
        loading    : replyLoading,
        setLoading : setReplyLoading,
      },
      like: {
        loading    : likeLoading,
        setLoading : setLikeLoading,
      },
      ...item,
    },
  }

  return (
    <ForumDetailItemContext.Provider value={sharedStates}>
      <div className="row pt-2 pb-4 mb-2 mx-md-2 border-bottom rounded">
        {children}
      </div>
    </ForumDetailItemContext.Provider>
  )
}

export default function useForumDetailItem() {
  return useContext(ForumDetailItemContext)
}

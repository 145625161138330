import React from 'react'
import usePreviewRapor from 'context/preview-rapor'
import useGetRoleActive from 'hooks/useGetRoleActive'

export default function Status() {
  /* ====================================== Consume Context ===================================== */
  const { rapor } = usePreviewRapor()

  const status = rapor.period.transaction?.status || "Belum Input"
  const {type} = useGetRoleActive()

  return (
    <>
      {status === 'Belum Input' && (
        <div className="alert alert-secondary f-w-700">
          <i className="fa fa-sticky-note" aria-hidden="true" /> Belum dilakukan input penilaian
        </div>
      )}
      {status === 'draft' && (
        <div className="alert alert-secondary f-w-700">
          <i className="fas fa-book" /> Draft
        </div>
      )}
      {status === 'approved' && (
        <div className="alert alert-success f-w-700">
          <i className="fas fa-check-circle" /> Telah disetujui guru
        </div>
      )}
      {status === 'progress' && (
        <div className="alert alert-info f-w-700">
          <i className="fas fa-keyboard" /> Sedang Dalam Proses Input Nilai
        </div>
      )}
      {status === 'submission' && (
        <div className="alert alert-warning f-w-700">
          <i className="fas fa-exclamation-circle" /> Menunggu Persetujuan Kepala Sekolah
        </div>
      )}
      {status === 'rejected' && (
        <div className="alert alert-danger f-w-700">
          <i className="fas fa-times-circle" /> Rapor Ditolak
        </div>
      )}
    </>
  )
}

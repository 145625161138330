import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import ModalMetaKelola from 'component/modal/meta-kelola'
import DataTableWithFilter from 'component/data-table/indexWithFilter'
import useGetData from 'hooks/useGetData'

export default function Quiz() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const { subMapel } = useGetData({ getListSubMapel: true })

  /* ====================================== Consume Context ===================================== */
  const { getRequest, deleteRequest } = useBaseContext()

  /* =================================== Consume Local Storage ================================== */
  const { id } = JSON.parse(localStorage.getItem('user'))
  const { role_type } = JSON.parse(localStorage.getItem('role_active'))

  /* ======================================= Local States ======================================= */
  const [quizList, setQuizList] = useState([])
  const [quizLength, setQuizLength] = useState(0)
  const [quizSelected, setQuizSelected] = useState(null) // yg nampil di modal
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [search, setSearch] = useState('')
  const [subMapelReactSelectOptions, setSubMapelReactSelectOptions] = useState([])
  const [selectedSubMapel, setSelectedSubMapel] = useState({})

  /* ========================================= Functions ======================================== */
  async function getQuizList() {
    const filterSubMapel = selectedSubMapel?.value ? `&class_id=${selectedSubMapel?.value}` : ''

    if (search !== '') {
      const response = await getRequest(`quiz?search=${search}${filterSubMapel}`)
      if (response) {
        setQuizList(response.data)
        setQuizLength(response.totalRecord)
      }
    } else {
      const response = await getRequest(
        `quiz?limit=${limit}&offset=${offset}${filterSubMapel}`,
      )
      if (response) {
        setQuizList(response.data)
        setQuizLength(response.totalRecord)
      }
    }
  }

  function handlePreviewQuiz(quizData) {
    history.push(`/kelola-quiz/preview-quiz/${quizData.id}`, {
      existingData: quizData,
    })
  }

  function handleAddQuiz() {
    history.push('/kelola-quiz/add')
  }

  function handleEditQuiz(quizData) {
    history.push(`/kelola-quiz/edit/${quizData.id}`, { existingData: quizData })
  }

  function handleDetailQuiz(quizData) {
    history.push(`/kelola-quiz/${quizData.id}`, { existingData: quizData })
  }

  function handleDeleteQuiz(quizId, quizTitle) {
    Swal.fire({
      title: 'Apa kamu yakin?',
      text: `Kamu akan menghapus ${quizTitle}!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`quiz?id=${quizId}`)
        if (response) {
          Swal.fire({
            icon: 'success',
            text: 'Quiz berhasil dihapus.',
          }).then(() => history.go(0))
        } else {
          Swal.fire(
            'Oppsss!',
            'Terjadi kesalahan coba beberapa saat lagi.',
            'error',
          )
        }
      }
    })
  }

  const manipulatedListSubMapelReactSelectOptionsHandler = () => {
    const data = subMapel.data.map(s => ({ label: s?.title, value: s?.id }))

    setSubMapelReactSelectOptions(data);
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getQuizList()
  }, [limit, offset, search, selectedSubMapel])

  useEffect(() => {
    if (!subMapel.loading && subMapel.data.length && !subMapelReactSelectOptions.length) {
      manipulatedListSubMapelReactSelectOptionsHandler()
    }
  }, [subMapel])

  /* ======================================== Components ======================================== */
  const RenderItem = ({
    item,
    handleEditQuiz,
    handleDetailQuiz,
    handleDeleteQuiz,
    handlePreviewQuiz,
    role_type,
  }) => {
    return (
      <tr>
        <td>{item.title ? item.title : ''}</td>
        <td>
          {item.active ? (
            <span className="badge badge-success">Aktif</span>
          ) : (
            <span className="badge badge-secondary">Tidak Aktif</span>
          )}
        </td>
        <td>
          {item.type_score ? (
            <>
              {item.type_score.active ? (
                <span>{item.type_score.type_score}</span>
              ) : (
                <span>Tidak aktif</span>
              )}
            </>
          ) : (
            <span>Tidak aktif</span>
          )}
        </td>
        <td>
          {role_type === 'administrator' ||
            item.mentor_id === id ||
            role_type === 'adminprofile' ? (
            <>
              <button
                onClick={() => setQuizSelected(item)}
                type="button"
                className="btn btn-sm mr-2 btn-dark"
                data-toggle="modal"
                data-target="#kelola-meta-modal"
              >
                <i className="feather icon-info mr-1" />
                Info
              </button>
              <button
                onClick={() => handleEditQuiz(item)}
                type="button"
                className="btn btn-sm mr-2 btn-success"
                data-toggle="modal"
                data-target="#quiz-edit-modal"
              >
                <i className="feather icon-edit mr-1" />
                Edit
              </button>
              <button
                onClick={() => handlePreviewQuiz(item)}
                type="button"
                className="btn btn-sm mr-2 btn-warning"
              >
                <i className="feather icon-check-square mr-1" />
                Periksa
              </button>
              <button
                onClick={() => handleDetailQuiz(item)}
                type="button"
                className="btn btn-sm mr-2 btn-info"
              >
                <i className="fas fa-list-ol mr-1" />
                Soal
              </button>
              <button
                onClick={() => handleDeleteQuiz(item.id, item.title)}
                type="button"
                className="btn btn-sm mr-2 btn-danger"
              >
                <i className="feather icon-trash-2 mr-1" />
                Hapus
              </button>
            </>
          ) : (
            <>
              <button
                disabled
                title="Anda tidak memiliki akses"
                type="button"
                className="btn btn-sm mr-2 btn-success btn-forbidden"
              >
                <i className="feather icon-edit mr-1" />
                Edit
              </button>
              <button
                onClick={() => handlePreviewQuiz(item)}
                type="button"
                className="btn btn-sm mr-2 btn-warning"
              >
                <i className="feather icon-check-square mr-1" />
                Periksa
              </button>
              <button
                onClick={() => handleDetailQuiz(item)}
                type="button"
                className="btn btn-sm mr-2 btn-info"
              >
                <i className="fas fa-list-ol mr-1" />
                Soal
              </button>
              <button
                disabled
                title="Anda tidak memiliki akses"
                type="button"
                className="btn btn-sm mr-2 btn-danger btn-forbidden"
              >
                <i className="feather icon-trash-2 mr-1" />
                Hapus
              </button>
            </>
          )}
        </td>
      </tr>
    )
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Kelola Quiz" />

        <div className="row">
          <div className="col">
            <div className="card user-profile-list">
              <div className="card-header">
                <div className="row">
                  <div className="col">
                    <button
                      onClick={() => handleAddQuiz()}
                      type="button"
                      className="btn btn-primary"
                    >
                      <i className="feather icon-plus-circle mr-1" />
                      <span>Tambah Quiz</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col dt-responsive table-responsive">
                    <DataTableWithFilter
                      className={'table table-striped table-bordered nowrap'}
                      data={quizList}
                      totalRows={quizLength}
                      show={[10, 20, 30, 40, 50]}
                      onChangeSearch={(val) => {
                        setSearch(val)
                      }}
                      onChangeLimit={(val) => {
                        setLimit(val)
                      }}
                      onChangePage={(val) => {
                        setOffset(val)
                      }}
                      column={[
                        'Nama Quiz',
                        'Status',
                        'Jenis Penilaian',
                        'Actions',
                      ]}
                      renderItem={(dataQuiz) => (
                        <RenderItem
                          item={dataQuiz}
                          key={dataQuiz.id}
                          handleEditQuiz={handleEditQuiz}
                          handleDetailQuiz={handleDetailQuiz}
                          handleDeleteQuiz={handleDeleteQuiz}
                          handlePreviewQuiz={handlePreviewQuiz}
                          role_type={role_type}
                        />
                      )}
                      filter1={{
                        options: subMapelReactSelectOptions,
                        placeholder: 'Filter submapel...',
                        onChange: (choice) => setSelectedSubMapel(choice),
                        isClearable: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalMetaKelola
          type='quiz'
          title={quizSelected?.title}
          id={quizSelected?.id}
        />
      </Container>
    </TemplateAdmin>
  )
}

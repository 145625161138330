import React from 'react'
import useMatchedAnswer from 'context/matched-answer'

export default function MatchedAnswersBtn() {
  /* ====================================== Consume Context ===================================== */
  const { question_id } = useMatchedAnswer()

  /* ========================================= Variables ======================================== */
  const data_target = `#modalMatchedAnswers_${question_id}`
  const button_text = `Matched Answers`

  /* ========================================== Output ========================================== */
  return (
    <button
      className   = "bg-info text-light"
      data-toggle = "modal"
      data-target = {data_target}
      style       = {styles.btn}
    >
      <span className="p-1 f-w-500">
        {button_text}
      </span>
    </button>
  );
}

/* =========================================== Styles =========================================== */
const styles = {
  btn: {
    position               : 'absolute',
    top                    : '0',
    right                  : '0',
    padding                : '5px',
    borderBottomLeftRadius : '8.5px',
    border                 : '1px solid transparent'
  },
};

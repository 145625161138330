import React, { useState } from 'react'
import { noImage } from 'util/constant'
import NotFound from 'component/not-found'

export default function ClassOverviewError({
  coverStyles,
  windowWidth,
  errorMessage = 'Terjadi kesalahan, muat ulang halaman',
}) {
  /* ======================================= Local States ======================================= */
  const [reloadStart, setReloadStart] = useState(false)

  /* ========================================= Functions ======================================== */
  const reloadPage = () => {
    setReloadStart(true)
    window.location.reload()
  }

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="row mb-3">
        <div className="col-12 mb-2">
          <div className="mb-2 d-flex flex-row align-items-center">
            <h3 className="mb-0"></h3>
          </div>
        </div>
        <div className="col-md-4 col-xl-3 mb-3 d-flex justify-content-center">
          <img
            src={noImage}
            className="img-fluid rounded bg-light"
            style={coverStyles}
            alt="My Class"
          />
        </div>
        <div className="col-md-8 mb-3">
          <div className="d-flex align-items-center mb-2">
            <i className="fas fa-chart-line mr-2" />
            <span className="d-flex align-items-center w-100">
              Progress:{' '}
              <span className="progress ml-1 w-100 hei-20">
                <div
                  className="progress-bar"
                  style={{ width: `100%`, backgroundColor: '#ecf0f5' }}
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <span
                    className="text-dark"
                    style={{ fontSize: '12px', fontWeight: 'bold' }}
                  >
                    0%
                  </span>
                </div>
              </span>
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="feather icon-tag mr-2" />
            <span className="mr-2">Mata Pelajaran: -</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fas fa-chalkboard-teacher mr-1" />
            <span className="mr-2">Guru: -</span>
          </div>
          <div className="mb-2">
            <button
              className="btn btn-sm btn-secondary"
              disabled={true}
              style={{
                width: windowWidth <= 1024 ? '100%' : '150px',
              }}
            >
              <i className="fas fa-eye mr-2" />
              Preview
            </button>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <span className="nav-link text-uppercase text-secondary active">
                Informasi
              </span>
            </li>
            <li className="nav-item">
              <span className="nav-link text-uppercase text-secondary">
                Materi
              </span>
            </li>
            <li className="nav-item">
              <span className="nav-link text-uppercase text-secondary">
                Forum Diskusi
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col fade active show d-flex flex-column justify-content-center align-items-center">
          <NotFound text={errorMessage} />
          <button
            className="btn btn-sm btn-primary mt-3"
            onClick={reloadStart ? () => {} : reloadPage}
            disabled={reloadStart}
          >
            {reloadStart ? (
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
              />
            ) : (
              <i className="fas fa-redo-alt mr-1" />
            )}
            Muat Ulang
          </button>
        </div>
      </div>
    </>
  )
}

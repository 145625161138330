import React from 'react'

export default function FeedbackDisabled({ message }) {
  return (
    <div className="row mb-3">
      <div className="col d-flex flex-column justify-content-center">
        <i
          className="fas fa-comment-dots f-60 text-center mb-3"
          style={{
            color: '#ccc',
          }}
        />
        <h6
          className="text-center"
          style={{
            color: '#ddd',
          }}
        >
          {message}
        </h6>
      </div>
    </div>
  )
}

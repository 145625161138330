import React, { useMemo } from 'react'
import Select from 'react-select'
import useQuizEval from 'context/quiz-eval'

export default function QuizEvalGroupPicker() {
  /* ====================================== Consume Context ===================================== */
  const { submapel } = useQuizEval()

  const renderSelect = useMemo(() => {
    return <Select
      placeholder="Kelas"
      options={submapel.data.all}
      onChange={(item) => submapel.handle.set(item)}
      className="mb-3"
      defaultValue={submapel.data.selected}
    />
  }, [submapel.data.selected, submapel.data.all])
  /* ========================================== Output ========================================== */
  return (
    <>
      <h5>Pilih Kelas: </h5>
      {renderSelect}
    </>
  )
}

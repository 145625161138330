import CkeditorReadonly from "component/ckeditor-readonly"
import { formatDate } from "util/date"
import useMatchedAnswer from "context/matched-answer"

export default function AnswerCard({ match, idx }) {
  /* ====================================== Consume Context ===================================== */
  const { findAnswer, findCorrectAnswer } = useMatchedAnswer()

  /* ========================================= Variables ======================================== */
  const current_answer = findAnswer(match.answer_id)
  const correct_answer = findCorrectAnswer()
  const max_score      = correct_answer?.nilai || 1
  const current_score  = match?.score / max_score / 3
  
  /* ====================================== To Be Injected ====================================== */
  const card_bg        = current_score ? `rgba(156,204,101,${current_score})` : `rgba(255,0,0,.1)`
  const jawaban_siswa  = match?.answer_text || current_answer?.text
  const created_at     = match?.createdAt && formatDate(match.createdAt)
  const note           = match?.note
  const score          = `${match?.score || 0} dari ${max_score}`

  /* ========================================== Output ========================================== */
  return (
    <div
      className="card text-dark"
      style={{ background: card_bg }}
    >
      <div className="card-header">
        <strong>Match {++idx}</strong>
      </div>
      <div className="card-body">
        <h6 className="card-subtitle mb-2 text-muted">{created_at}</h6>
        <h6 className="card-title">
          Jawaban Siswa: <CkeditorReadonly data={jawaban_siswa} />
        </h6>
        <p className="card-text">
          <strong>Catatan Guru:</strong> <br />
          {note ? <CkeditorReadonly data={note} /> : 'Tidak ada catatan'}
        </p>
      </div>
      <div className="card-footer">score: {score}</div>
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import moment from 'moment'

import { useBaseContext } from 'context/base'
import useContentPlayerContext from 'context/content-player'
import LoadingSpinner from 'component/loading'
import Modal from 'component/modal'
import CkeditorReadonly from 'component/ckeditor-readonly'
import AssignmentText from './assignment-type/text'
import AssignmentAudio from './assignment-type/audio'
import AssignmentVideo from './assignment-type/video'
import AssignmentDocument from './assignment-type/document'
import AssignmentYoutube from './assignment-type/youtube'
import AssignmentUrl from './assignment-type/url'
import { AssignmentDone } from './components/assignment-components'
import HtmlContent from 'component/html-content'

const ContentAssignment = ({ isPreview = false }) => {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest } = useBaseContext()
  const { content, kelas, activity } = useContentPlayerContext()

  /* ======================================= Local States ======================================= */
  const [activity_id, setActivityId] = useState('')
  const [class_id, setClassId] = useState('')

  // GET DATA STATE
  const [isLoading, setIsLoading] = useState(false)
  const [loadingCheckDone, setLoadingCheckDone] = useState(false)
  const [assignment, setAssignment] = useState(null)
  const [historyAssignment, setHistoryAssignment] = useState([])

  // ACTIVITY STATE
  const [isAssignmentDone, setIsAssignmentDone] = useState(false)
  const [isAssignmentPending, setIsAssignmentPending] = useState(false)

  // TEXT INPUT STATE
  const [text, setText] = useState('')
  const [isTextInvalid, setIsTextInvalid] = useState(false)

  // YOUTUBE INPUT STATE
  const [youtubeId, setYoutubeId] = useState('')
  const [isYoutubeEmpty, setIsYoutubeEmpty] = useState(false)

  // URL INPUT STATE
  const [assignmentUrl, setAssignmentUrl] = useState('')
  const [isUrlEmpty, setIsUrlEmpty] = useState(false)

  // FILE INPUT STATE
  const [currentFilename, setCurrentFilename] = useState('')
  const [file, setFile] = useState(null)
  const [previewFile, setPreviewFile] = useState('')
  const [isFileTypeInvalid, setIsFileTypeInvalid] = useState(false)
  const [isFileEmpty, setIsFileEmpty] = useState(false)

  // Loading Insert State
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  /* ========================================= Functions ======================================== */
  async function handleFile(file, type) {
    if (`${typeof file}` === 'object') {
      const isTypeValid = validateFileType(file.name, type)
      if (isTypeValid) {
        const URL = window.URL || window.webkitURL
        const src = URL.createObjectURL(file)
        setPreviewFile(src)
        setCurrentFilename(file.name)
        setFile(file)
      } else {
        setCurrentFilename('')
        setPreviewFile('')
        setFile(null)
      }
    }
  }

  function validateFileType(fileName, type) {
    const fileExtension = fileName.split('.').pop()
    if (type === 'audio') {
      const audio = 'mp3'
      if (fileExtension !== audio) {
        setIsFileTypeInvalid(true)
        return false
      } else {
        setIsFileTypeInvalid(false)
        return true
      }
    }
    if (type === 'video') {
      const video = 'mp4'
      if (fileExtension !== video) {
        setIsFileTypeInvalid(true)
        return false
      } else {
        setIsFileTypeInvalid(false)
        return true
      }
    }
    if (type === 'document') {
      const document = ['pdf', 'ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx']
      let isDocValid = false
      document.forEach((correctExt) => {
        if (fileExtension === correctExt) {
          isDocValid = true
        }
      })
      if (isDocValid) {
        setIsFileTypeInvalid(false)
        return true
      } else {
        setIsFileTypeInvalid(true)
        return false
      }
    }
  }

  function handleYoutubeUrl(url) {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    const match = url.match(regExp)
    const ytId = match && match[7].length == 11 ? match[7] : false
    setYoutubeId(ytId)
  }

  function handleAssignmentUrl(url) {
    if (url) setIsUrlEmpty(false)
    setAssignmentUrl(url)
  }

  async function getAssignment() {
    setIsLoading(true)
    setAssignment(null)
    const response = await getRequest(
      `assignment-siswa?id=${content.contents_url}`,
    )
    if (response) {
      setAssignment(response.data)
      setIsLoading(false)
    }
  }

  async function completeActivities() {
    if (content.feedback === null) {
      let form = new FormData()
      form.append('activity_id', activity_id)
      form.append('content_id', content.id)
      form.append('class_id', class_id)
      form.append('is_complete', 0)
      const response = await putRequest(
        'class-content-activities',
        form,
      )
      if (response) {
        // gak ngetrigger centang, tapi ngetrigger getContentDone() untuk refresh state
        //setIdDone(content.id + 'justAnotherRandomString');
        // kasih tau komponen kalau tugas ini akan/sedang dikoreksi
        activity.setIdDone(content.id + 'justAnotherRandomString')
        setIsAssignmentPending(true)
      } else {
        console.error('ERROR', response)
      }
    } else {
      //setMediaIdFinish(content.id)
      activity.setIdDone(content.id + 'justAnotherRandomString')
      activity.setMediaIdDone(content.id)
      setIsAssignmentPending(true)
    }
  }

  function handleConfirmSubmit() {
    Swal.fire({
      title: 'Apa kamu yakin?',
      text: `Kamu akan mengirim tugas!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, kirim!',
      cancelButtonText: 'Periksa kembali',
    }).then(async (result) => {
      if (result.isConfirmed) {
        handleFormValidation()
      }
    })

    const _regEx =
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/
    const isCorrectURL = !!assignmentUrl.match(_regEx)
    /*
    if (isCorrectURL) {
      Swal.fire({
        title              : 'Apa kamu yakin?',
        text               : `Kamu akan mengirim tugas!`,
        icon               : 'warning',
        showCancelButton   : true,
        confirmButtonColor : '#3085d6',
        cancelButtonColor  : '#d33',
        confirmButtonText  : 'Ya, kirim!',
        cancelButtonText   : 'Periksa kembali'
      }).then(async (result) => {
        if (result.isConfirmed) {
          handleFormValidation();
        }
      });
    }
    else {
      Swal.fire({
        dangerMode         : true,
        title              : 'Cek Lagi Ya!',
        text               : `Input harus berupa URL yang valid`,
        icon               : 'error',        
        confirmButtonColor : '#d33',
        confirmButtonText  : 'Oke, Saya Koreksi Lagi',
      })
    }
    */
  }

  function handleFormValidation() {
    if (assignment.reply_type == 1) {
      if (text) {
        setIsTextInvalid(false)
        handleSubmitAnswer()
      } else {
        setIsTextInvalid(true)
      }
    } else if (assignment.reply_type == 5) {
      if (youtubeId) {
        const url = { data: youtubeId, name: null }
        handleSubmitAnswer(url)
        setIsYoutubeEmpty(false)
      } else {
        setIsYoutubeEmpty(true)
      }
    } else if (assignment.reply_type == 6) {
      if (assignmentUrl) {
        setIsUrlEmpty(false)
        handleSubmitAnswer(assignmentUrl)
      } else {
        setIsUrlEmpty(true)
      }
    } else {
      if (file) {
        const url = { data: 'data', name: currentFilename }
        setIsFileTypeInvalid(false)
        setIsFileEmpty(false)
        handleSubmitAnswer(url)
      } else {
        setIsFileEmpty(true)
      }
    }
  }

  async function handleSubmitAnswer(url) {
    setLoadingSubmit(true)
    let form = new FormData()
    form.append('assignment_reply_type', assignment.reply_type)
    form.append('assignment_id', assignment.id)
    form.append('content_id', content.id)
    if (assignment.reply_type == 1) {
      form.append('text', text)
    } else if (
      assignment.reply_type == 2 ||
      assignment.reply_type == 3 ||
      assignment.reply_type == 4
    ) {
      const { id } = JSON.parse(localStorage.getItem('user'))
      const profile_id = JSON.parse(localStorage.getItem('profile')).id
      const nameSplit = url.name.split('.')
      const ext = nameSplit[nameSplit.length - 1]
      form.append('path', `upload/${profile_id}/assignments`)
      form.append('temp_name', `file-temp-${id}-${Date.now()}.${ext}`)
      form.append('url', JSON.stringify(url))
      form.append('file_upload', file)
    } else if (assignment.reply_type == 5) {
      form.append('url', JSON.stringify(url))
    } else if (assignment.reply_type == 6) {
      form.append('url', url)
    }

    const response = await postRequest('assignment-siswa', form)
    if (response) {
      activity.markSubmit()
      Swal.fire({
        icon: 'success',
        text: 'Tugas berhasil dikirim.',
      }).then(async () => {
        await completeActivities()
        setHistoryAssignment([])
        resetForm()
      })
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Gagal mengirim tugas, coba beberapa saat lagi',
      })
    }
  }

  function resetForm() {
    setCurrentFilename('')
    setFile(null)
    setPreviewFile('')
    setText('')
    setYoutubeId('')
    setIsFileTypeInvalid(false)
    setIsTextInvalid(false)
    setIsFileEmpty(false)
    setIsYoutubeEmpty(false)
    setLoadingSubmit(false)
    setAssignmentUrl('')
    setIsUrlEmpty(false)
  }

  async function checkAssignmentDone() {
    setLoadingCheckDone(true)
    setIsAssignmentDone(false)
    setIsAssignmentPending(false)
    const response = await getRequest(
      `assignment-done?content_id=${content.id}`,
    )
    if (response) {
      if (response?.data.length && response?.data[0].is_done) {
        setIsAssignmentDone(true)
      } else if (response?.data.length && !response?.data[0].is_done) {
        setIsAssignmentPending(true)

        if (response.data.length !== 0) {
          response.data[0].is_done
            ? setIsAssignmentDone(true)
            : setIsAssignmentPending(true)
          response.data[0].is_done && activity.setMediaIdDone(content.id)
        } else {
          setIsAssignmentDone(false)
        }
        setLoadingCheckDone(false)
      }
    }
  }

  async function getAssignmentHistory() {
    setHistoryAssignment([])
    const response = await getRequest(
      `assignment-rejected?content_id=${content.id}`,
    )
    if (response) {
      setHistoryAssignment(response.data.history)
    } else {
      setHistoryAssignment([])
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    return () => {
      setIsAssignmentDone(false)
    }
  }, [])

  useEffect(() => {
    getAssignment()
    setIsAssignmentPending(false)
    if (isPreview === false) {
      checkAssignmentDone()
      getAssignmentHistory()
    }
  }, [content])

  useEffect(() => {
    if (isPreview === false) {
      setActivityId(activity.id)
    }
  }, [activity.id])

  useEffect(() => {
    if (isPreview === false) {
      setClassId(kelas.id)
    }
  }, [kelas.id])

  useEffect(() => {
    resetForm()
  }, [assignment])

  useEffect(() => {
    if (file) {
      setIsFileEmpty(false)
    }
  }, [file])

  useEffect(() => {
    if (youtubeId) {
      setIsYoutubeEmpty(false)
    }
  }, [youtubeId])

  /* ========================================== Output ========================================== */
  return (
    <>
      {isLoading && loadingCheckDone ? (
        <LoadingSpinner />
      ) : (
        <>
          {assignment ? (
            <>
              {historyAssignment.length !== 0 ? (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="badge badge-danger">
                      <i className="fas fa-exclamation-triangle mr-1" />
                      Tugas anda ditolak!
                    </span>
                    <button
                      className="btn btn-sm btn-primary"
                      data-toggle="modal"
                      data-target="#modalHistoryAssignment"
                    >
                      Riwayat
                    </button>
                    <Modal
                      id="modalHistoryAssignment"
                      title="Riwayat tugas"
                    >
                      <div
                        className="hd-body pr-2"
                        style={{
                          maxHeight: '350px',
                          overflowY: 'auto',
                        }}
                      >
                        {historyAssignment.map((history, index) => (
                          <div
                            key={index}
                            className="excerpt rounded mb-3"
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <span>
                                <i className="feather icon-calendar mr-1 f-14" />
                                {history.upload_at
                                  ? moment(history.upload_at).format(
                                    'H:MM, DD-MM-YYYY',
                                  )
                                  : '-'}
                              </span>
                              <span className="badge badge-danger">
                                Ditolak
                              </span>
                            </div>
                            <h6 className="mb-1">Catatan:</h6>
                            {history.note ? <CkeditorReadonly data={history.note} /> : <p>-</p>}
                          </div>
                        ))}
                      </div>
                    </Modal>
                  </div>
                  <div
                    className="mt-2 border rounded alert alert-light text-dark w-100"
                    role="alert"
                  >
                    <strong>
                      <i className="fas fa-info-circle mr-1" />
                      Catatan
                    </strong>
                    {historyAssignment[0].note ? <CkeditorReadonly data={historyAssignment[0].note} /> : <p>-</p>}
                  </div>
                </>
              ) : null}

              {isAssignmentDone || isAssignmentPending ? (
                <AssignmentDone
                  content={content}
                  isAssignmentPending={isAssignmentPending}
                />
              ) : (
                <>
                  {assignment.text ? (
                    <CkeditorReadonly
                      id={assignment.id}
                      data={assignment.text}
                    />
                  ) : null}
                  {assignment.reply_type == 1 ? (
                    <AssignmentText
                      text={text}
                      setText={setText}
                      isTeksInvalid={isTextInvalid}
                    />
                  ) : null}
                  {assignment.reply_type == 2 ? (
                    <AssignmentAudio
                      handleFile={handleFile}
                      currentFilename={currentFilename}
                      isFileTypeInvalid={isFileTypeInvalid}
                      isFileEmpty={isFileEmpty}
                      previewFile={previewFile}
                    />
                  ) : null}
                  {assignment.reply_type == 3 ? (
                    <AssignmentVideo
                      handleFile={handleFile}
                      currentFilename={currentFilename}
                      isFileTypeInvalid={isFileTypeInvalid}
                      isFileEmpty={isFileEmpty}
                      previewFile={previewFile}
                    />
                  ) : null}
                  {assignment.reply_type == 4 ? (
                    <AssignmentDocument
                      handleFile={handleFile}
                      currentFilename={currentFilename}
                      isFileTypeInvalid={isFileTypeInvalid}
                      isFileEmpty={isFileEmpty}
                    />
                  ) : null}
                  {assignment.reply_type == 5 ? (
                    <AssignmentYoutube
                      handleYoutubeUrl={handleYoutubeUrl}
                      youtubeId={youtubeId}
                      isYoutubeEmpty={isYoutubeEmpty}
                    />
                  ) : null}
                  {assignment.reply_type == 6 ? (
                    <AssignmentUrl
                      url={assignmentUrl}
                      isUrlEmpty={isUrlEmpty}
                      handleUrl={handleAssignmentUrl}
                    />
                  ) : null}
                  {isPreview === false && !isAssignmentPending && (
                    <div className="offset-md-3 offset-lg-4 col-md-6 col-lg-4 mt-5">
                      {loadingSubmit ||
                        content.is_assignment_evaluated === false ? (
                        <button
                          disabled={true}
                          type="button"
                          className="btn btn-primary w-100"
                        >
                          {loadingSubmit && (
                            <span
                              className="spinner-border spinner-border-sm mr-2"
                              role="status"
                            />
                          )}
                          <span>Kirim Tugas</span>
                        </button>
                      ) : (
                        <button
                          onClick={handleConfirmSubmit}
                          type="button"
                          className="btn btn-primary w-100"
                        >
                          <i className="fas fa-location-arrow mr-2" />
                          <span>Kirim Tugas</span>
                        </button>
                      )}
                    </div>
                  )}

                  {historyAssignment.length ?
                    <div
                      className="border rounded mt-3 px-3 accordion"
                      id="accordionPembahasan"
                    >
                      <div className="row">
                        <div
                          className="col-12 py-3 w-100"
                          id="headingOne"
                          style={{
                            backgroundColor: '#f8f9fa',
                          }}
                          onMouseEnter={(event) => {
                            event.currentTarget.style.backgroundColor =
                              '#f6f6f6'
                          }}
                          onMouseLeave={(event) => {
                            event.currentTarget.style.backgroundColor =
                              '#f8f9fa'
                          }}
                        >
                          <span
                            style={{
                              fontWeight: '500',
                              fontSize: '16px',
                              width: '100%',
                            }}
                          >
                            <a
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="false"
                              aria-controls="collapseOne"
                              className="collapsed w-100 d-flex justify-content-between align-items-center"
                            >
                              <span>Pembahasan</span>
                              <i className="fas fa-chevron-down" />
                            </a>
                          </span>
                        </div>
                        <div
                          className="col-12 my-3 collapse"
                          id="collapseOne"
                          aria-labelledby="headingOne"
                          data-parent="#accordionPembahasan"
                        >
                          <HtmlContent html={assignment.pembahasan} />
                        </div>
                      </div>
                    </div>
                    : null}
                </>
              )}
            </>
          ) : (
            <LoadingSpinner />
          )}
        </>
      )}
    </>
  )
}

export default ContentAssignment

import React from 'react'

export default function FormGroupKecil({
  enableGroup,
  handleChangeEnableGroup,
  maxGroup,
  descGroup,
  handleChangeMaxGroup,
  handleChangeDescGroup,
  isMaxGroupValid,
  refMaxGroup,
}) {
  return (
    <div className="mb-5">
      <div className="form-group row mb-2">
        <label className="col-sm-3 col-form-label">Grup Kecil</label>
        <div className="form-group col-sm-9">
          <div className="switch switch-primary d-inline m-r-10">
            <input
              onChange={handleChangeEnableGroup}
              checked={enableGroup}
              type="checkbox"
              id="switch-group-kecil"
            />
            <label
              htmlFor="switch-group-kecil"
              className="cr"
            ></label>
          </div>
          <label htmlFor="switch-group-kecil">
            {enableGroup ? 'Aktif' : 'Tidak aktif'}
          </label>
        </div>
      </div>
      {enableGroup ? (
        <>
          <div className="form-group row">
            <label className="offset-sm-3 col-sm-2 col-form-label">
              Maks. Anggota
            </label>
            <div className="col-sm-3">
              <input
                value={maxGroup}
                onChange={handleChangeMaxGroup}
                type="number"
                min={2}
                className="form-control"
                placeholder="Maks. anggota"
                ref={refMaxGroup}
              />
            </div>
            {isMaxGroupValid ? null : (
              <span className="offset-sm-5 col-12 small form-text text-danger">
                Anggota tidak boleh kosong atau kurang dari 2.
              </span>
            )}
          </div>
          <div className="form-group row">
            <label className="offset-sm-3 col-sm-2 col-form-label">
              Deskripsi
            </label>
            <div className="col-sm-7">
              <textarea
                value={descGroup}
                onChange={handleChangeDescGroup}
                className="form-control"
                placeholder="Topik pembahasan group"
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}

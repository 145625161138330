import React, { useState, useEffect } from 'react'
import InvalidWarning from 'component/invalid/warning'
import { avatarDefaultUser } from 'util/constant'
import useFeedbackForm from 'context/feedback-form'

export default function EditFeedKompleks({ sharedProps }) {
  /* =================================== Consume Shared Props =================================== */
  const {
    isFeedResponIdEmpty,
    isLoading,
    formValidation,
    handleChangeFeedResponIds,
    setIsFeedResponIdEmpty,
  } = sharedProps

  /* ====================================== Consume Context ===================================== */
  const { feed, event } = useFeedbackForm()

  /* ========================================= Constants ======================================== */
  const feedResponses = feed.responses
  const feedTemps = feed.temps
  const { name, linkAvatar } = JSON.parse(localStorage.getItem('user'))
  const fallbackAvatar = avatarDefaultUser

  /* ========================================== States ========================================== */
  const [isEdit, setIsEdit] = useState(false)
  const [feedTempsResponIds, setFeedTempsResponIds] = useState([])

  function initFeedTemps() {
    if (feedTemps.length !== 0) {
      const temps = feedTemps.map((temp) => temp.feed_respon_id)
      setFeedTempsResponIds(temps)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    initFeedTemps()
  }, [feed.data])

  return (
    <>
      <label>
        {feed.data ? feed.data.description : ''}
        {' (Pilih satu atau lebih)'}
      </label>
      {isEdit ? (
        <>
          <div className="form-group mb-4">
            {feedResponses.length &&
              feedResponses.map((feedResponse) => (
                <div
                  key={feedResponse.id}
                  className="custom-control custom-checkbox mb-2"
                >
                  <input
                    type="checkbox"
                    id={feedResponse.id}
                    name="pg-feedback"
                    className="custom-control-input"
                    value={feedResponse.id}
                    onChange={(event) =>
                      handleChangeFeedResponIds(
                        event.target.value,
                        event.target.checked,
                      )
                    }
                    defaultChecked={
                      feedTempsResponIds.includes(feedResponse.id)
                        ? true
                        : false
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={feedResponse.id}
                  >
                    {feedResponse.text}
                  </label>
                </div>
              ))}
            {isFeedResponIdEmpty && (
              <InvalidWarning text="Feedback tidak boleh kosong" />
            )}
          </div>
          <div className="form-group row mb-0">
            <div className="col-12 col-md-2 pr-md-0 mb-2 mb-md-0">
              {isLoading ? (
                <button
                  type="button"
                  className="btn btn-sm btn-primary w-100"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  />
                  <span>Ubah</span>
                </button>
              ) : (
                <button
                  onClick={formValidation}
                  type="button"
                  className="btn btn-sm btn-primary w-100"
                >
                  <i className="fas fa-paper-plane mr-1" />
                  <span>Ubah</span>
                </button>
              )}
            </div>
            <div className="col-12 col-md-2">
              <button
                type="button"
                className="btn btn-sm btn-danger w-100"
                onClick={() => {
                  setIsEdit(false)
                  event.set('submit-feed')
                  setIsFeedResponIdEmpty(false)
                }}
              >
                <span>Batal</span>
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="media mt-2 mb-0">
          <img
            src={`${linkAvatar}`}
            alt={name}
            className="img-radius wid-30 align-top m-r-15"
            onError={(event) => (event.currentTarget.src = fallbackAvatar)}
          />
          <div className="media-body">
            <h6 className="mb-0 text-dark">{name}</h6>
            <div className="m-b-0">
              <div className="form-group mt-2 mb-0">
                {feedResponses.length !== 0
                  ? feedResponses.map((feedResponse) => {
                      return (
                        <div
                          key={feedResponse.id}
                          className="custom-control custom-checkbox mb-2"
                        >
                          <input
                            type="checkbox"
                            name="pg-feedback"
                            className="custom-control-input"
                            checked={
                              feedTempsResponIds.includes(feedResponse.id)
                                ? true
                                : false
                            }
                            disabled
                          />
                          <label className="custom-control-label">
                            {feedResponse.text}
                          </label>
                        </div>
                      )
                    })
                  : null}
              </div>
              <a
                onClick={() => setIsEdit(true)}
                type="button"
                className="text-muted text-h-primary"
              >
                <small>
                  <i className="feather icon-edit mx-1" />
                  Ubah
                </small>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

import React from 'react'

const AssignmentDocument = ({
  handleFile,
  currentFilename,
  isFileTypeInvalid,
  isFileEmpty,
}) => (
  <div className="custom-file">
    <input
      type="file"
      accept=".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx"
      onChange={(event) => handleFile(event.target.files[0], 'document')}
      className={
        isFileEmpty ? 'custom-file-input is-invalid' : 'custom-file-input'
      }
      id="upload-doc"
    />
    <label
      className="custom-file-label"
      htmlFor="upload-doc"
    >
      {currentFilename ? currentFilename : 'Pilih file: word/pdf/excel/ppt'}
    </label>
    {isFileTypeInvalid ? (
      <label className="col small form-text text-danger">
        File harus dalam bentuk word/pdf/excel/ppt.
      </label>
    ) : null}
    {isFileEmpty ? (
      <label className="col small form-text text-danger">
        File tidak boleh kosong.
      </label>
    ) : null}
  </div>
)

export default AssignmentDocument

import React from 'react'
import useChatContext from 'context/chat'

export default function LoadNewerMsgLoader() {
  const { messages } = useChatContext()

  return messages.loading.newer ? (
    <div className="row m-t-20 m-b-20 justify-content-center">
      <div
        className="spinner-border text-secondary"
        role="status"
      ></div>
    </div>
  ) : null
}

import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import SiswaProgress from 'component/report-submapel/siswa-list-progress'
import DashboardReport from 'component/report-submapel/dashboard'
import ForumDiskusi from 'component/forum-mata-pelajaran/forum-diskusi'
import Contents from './overview-content'
import OverviewFileKelas from './overview-file-kelas'
import ContentsCreator from './overview-contents-creator'
import RekapNilaiV2 from './overview-rekap-nilai/rekap-nilai-v2'
import GrafikGrupKecil from './overview-grafik-grup-kecil'
import { defaultTitle } from 'util/constant'
import OverviewGrade from './overview-grade'

export default function Overview() {
  /* ========================================== Helpers ========================================= */
  const location    = useLocation()
  const { kelasId } = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [dataKelas, setDataKelas] = useState({ title: '-' })
  const [profileId, setProfileId] = useState('')

  /* =================================== Consume Local Storage ================================== */
  const profile = JSON.parse(localStorage.getItem('profile'))
  const _isAdministrator = JSON.parse(localStorage.getItem('role_active'))?.role_type === 'administrator' || JSON.parse(localStorage.getItem('role_active')).role_type === 'adminprofile'
  const _isGuruUtama     = JSON.parse(localStorage.getItem('guru_utama'))

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/kelas', name: 'Sub Mata Pelajaran' }]

  /* =========================================== Refs =========================================== */
  const refContents        = useRef()
  const refContentsCreator = useRef()
  const refProgressSiswa   = useRef()
  const refFiles           = useRef()
  const refRules           = useRef()
  const refReports         = useRef()
  const refGrafik          = useRef()
  const refGrade          = useRef()

  /* ========================================= Functions ======================================== */
  function setIsGuruUtama(value) {
    localStorage.setItem('guru_utama', value)
  }

  async function getKelasDetail() {
    const response = await getRequest(`classes?id=${kelasId}`)
    if (response) {
      setIsGuruUtama(response.role_user?.role === 'Guru Utama')
      if (
        response.data.class_profiles.length > 0 &&
        response.data.class_profiles[0].status == 'duplicate'
      ) {
        setProfileId(response.data.class_profiles[0].profile_id)
      } else {
        setProfileId(response.data?.profile_id)
      }
      setDataKelas({
        title: response.data.title,
      })
    } else {
      console.error('ERROR', response)
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }

  function checkHash() {
    if (location.hash.includes('#files')) {
      refFiles.current.click()
    } else if (location.hash.includes('#contentsCreator')) {
      refContentsCreator.current.click()
    } else if (location.hash.includes('#contents')) {
      refContents.current.click()
    } else if (location.hash.includes('#users')) {
      refProgressSiswa.current.click()
    } else if (location.hash.includes('#rules')) {
      refRules.current.click()
    } else if (location.hash.includes('#reports')) {
      refReports.current.click()
    } else if (location.hash.includes('#grafik')) {
      refGrafik.current.click()
    } else if (location.hash.includes('#grade')) {
      refGrade.current.click()
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getKelasDetail()
  }, [])

  useEffect(() => {
    checkHash()
  }, [location])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Helmet>
        {profile ? (
          <title>
            LMS · {dataKelas.title}
          </title>
        ) : (
          <title>{defaultTitle}</title>
        )}
      </Helmet>
      <Container>
        <Header
          pageName={dataKelas?.title || ''}
          linkName="Overview"
          links={links}
        />

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <ul
                  className="nav nav-pills bg-white"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      ref={refContents}
                      className="nav-link active text-uppercase"
                      data-toggle="tab"
                      href={`#contents`}
                      role="tab"
                      aria-controls="user1"
                      aria-selected="true"
                    >
                      Materi
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      ref={refReports}
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#reports"
                      role="tab"
                      aria-controls="user5"
                      aria-selected="false"
                    >
                      Laporan
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#scores"
                      role="tab"
                      aria-controls="scores"
                      aria-selected="false"
                    >
                      Rekap Nilai
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      ref={refProgressSiswa}
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#users"
                      role="tab"
                      aria-controls="user2"
                      aria-selected="false"
                    >
                      Progress Siswa
                    </a>
                  </li>
                  {(JSON.parse(localStorage.getItem('role_active'))
                    ?.role_type === 'administrator' ||
                    JSON.parse(localStorage.getItem('role_active'))
                      ?.role_type === 'adminprofile') && (
                    <li className="nav-item">
                      <a
                        ref={refContentsCreator}
                        className="nav-link text-uppercase"
                        data-toggle="tab"
                        href="#contentsCreator"
                        role="tab"
                        aria-controls="user2"
                        aria-selected="false"
                      >
                        Guru
                      </a>
                    </li>
                  )}
                  <li className="nav-item">
                    <a
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#forum"
                      role="tab"
                      aria-controls="user6"
                      aria-selected="false"
                    >
                      Forum Diskusi
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      ref={refFiles}
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#files"
                      role="tab"
                      aria-controls="user3"
                      aria-selected="false"
                    >
                      File Pendukung
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      ref={refGrafik}
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#grafik"
                      role="tab"
                      aria-controls="user4"
                      aria-selected="false"
                    >
                      Grafik Grup Kecil
                    </a>
                  </li>
                  {_isAdministrator | _isGuruUtama ? (
                  <li className="nav-item">
                    <a
                      ref={refGrade}
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#grade"
                      role="tab"
                      aria-controls="user8"
                      aria-selected="false"
                    >
                      Grade
                    </a>
                  </li>
                      ) : null}
                </ul>
              </div>
            </div>
            <div
              className="tab-content"
              id="myTabContent"
            >
              <div
                className="tab-pane fade show active"
                id="contents"
                role="tabpanel"
                aria-labelledby="user1-tab"
              >
                <Contents
                  kelasId={kelasId}
                  access={profileId != profile.id}
                />
              </div>
              <div
                className="tab-pane fade show"
                id="contentsCreator"
                role="tabpanel"
                aria-labelledby="user1-tab"
              >
                <ContentsCreator kelasId={kelasId} />
              </div>
              <div
                className="tab-pane fade show"
                id="users"
                role="tabpanel"
                aria-labelledby="user1-tab"
              >
                <div className="card user-profile-list">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <SiswaProgress kelasTitle={dataKelas.title} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade show"
                id="files"
                role="tabpanel"
                aria-labelledby="user1-tab"
              >
                <OverviewFileKelas kelasId={kelasId} />
              </div>
              <div
                className="tab-pane fade show"
                id="forum"
                role="tabpanel"
                aria-labelledby="user1-tab"
              >
                <div className="card user-profile-list">
                  <div className="card-body">
                    <div className="row">
                      <ForumDiskusi
                        isClassEnrolled={true}
                        classId={kelasId}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade show"
                id="reports"
                role="tabpanel"
                aria-labelledby="user1-tab"
              >
                <div className="card user-profile-list">
                  <div className="card-body">
                    <DashboardReport />
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade show"
                id="scores"
                role="tabpanel"
                aria-labelledby="scores-tab"
              >
                <RekapNilaiV2 dataKelas={dataKelas} />
              </div>
              <div
                className="tab-pane fade show"
                id="grafik"
                role="tabpanel"
                aria-labelledby="scores-tab"
              >
                <div className="card user-profile-list">
                  <div className="card-body">
                    <GrafikGrupKecil />
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade show"
                id="grade"
                role="tabpanel"
                aria-labelledby="scores-tab"
              >
                <OverviewGrade kelasId={kelasId} access={profileId != profile.id} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

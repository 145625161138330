import React, { useState, useEffect } from 'react'

import { useBaseContext } from 'context/base'
import DataTable from 'component/data-table'
import LoadingSpinner from 'component/loading'
import HtmlContent from 'component/html-content'
import {
  GraphorGroupReadonlyAnswer,
  GraphorGroupReadonlyCorrect,
} from 'component/graphor/graphor-group-readonly'
import {
  GraphorSortReadonlyAnswer,
  GraphorSortReadonlyCorrect,
} from 'component/graphor/graphor-sort-readonly'

export default function EvaluasiContentList({ user, contents, answers }) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [limit, setLimit]   = useState(10)
  const [offset, setOffset] = useState(0)
  const [search, setSearch] = useState('')

    // Graphor answers detail
  const [isLoading, setIsLoading]           = useState(false)
  const [showDetail, setShowDetail]         = useState(false)
  const [detailData, setDetailData]         = useState(null)
  const [jawabanSiswa, setJawabanSiswa]     = useState([])
  const [realAnswer, setRealAnswer]         = useState([])
  const [showPembahasan, setShowPembahasan] = useState(false)

  /* ========================================= Functions ======================================== */
  async function getGraphorAnswer(item) {
    setIsLoading(true)
    setShowDetail(true)
    setDetailData(item)
    const response = await getRequest(
      `siswa-graphor-hasil?content_id=${item.id}&graphor_id=${item.graphor_id}&user_id=${user.id}`,
    )
    setIsLoading(false)

    if (response) {
      setJawabanSiswa(response.data)
      setRealAnswer(response.answer)
    }
  }

  function resetDetail() {
    setShowDetail(false)
    setJawabanSiswa([])
    setRealAnswer([])
    setShowPembahasan(false)
  }

  function handleBlurModal(e) {
    if (e.target.id === 'evaluasiList') {
      resetDetail()
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    document.body.addEventListener('click', handleBlurModal)
    return () => {
      document.body.removeEventListener('click', handleBlurModal)
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div
      id="evaluasiList"
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="evaluasiListTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title d-flex align-items-center"
              id="evaluasiListTitle"
            >
              <i className="fas fa-user mr-1 f-12" />
              {user ? user.name : ''}
            </h5>
            <button
              onClick={() => {
                window.$('#evaluasiList').modal('hide')
                resetDetail()
              }}
              type="button"
              className="close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {showDetail ? (
              <div>
                <div className="row mb-3">
                  <div className="col-md-6 d-flex align-items-center">
                    <h6>
                      {detailData ? (
                        <>
                          {detailData.title}
                          {detailData.graphor_type === 1 && ' (Sorting)'}
                          {detailData.graphor_type === 2 && ' (Grouping)'}
                        </>
                      ) : (
                        ''
                      )}
                    </h6>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <button
                      onClick={() => setShowDetail(false)}
                      className="btn btn-outline-danger"
                    >
                      <span>Kembali</span>
                    </button>
                  </div>
                </div>
                <div className="row">
                  {detailData ? (
                    <>
                      {detailData.graphor_pembahasan && (
                        <div className="col-12 mb-3 d-flex flex-column align-items-center">
                          <button
                            onClick={() => setShowPembahasan(!showPembahasan)}
                            type="button"
                            className="btn btn-primary"
                          >
                            {showPembahasan
                              ? 'Sembunyikan Pembahasan'
                              : 'Lihat Pembahasan'}
                          </button>
                          {showPembahasan && (
                            <div className="border rounded p-2 mt-3 w-100">
                              <HtmlContent
                                html={detailData.graphor_pembahasan}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  ) : null}
                  <div className="col-12">
                    {detailData ? (
                      <>
                        {detailData.graphor_type === 1 && (
                          <>
                            <h5 className="text-center mb-3">Jawaban Siswa</h5>
                            <GraphorSortReadonlyAnswer
                              list={jawabanSiswa}
                              correctList={answers}
                            />
                            <h5 className="text-center my-3">Jawaban Benar</h5>
                            <GraphorSortReadonlyCorrect correctList={answers} />
                          </>
                        )}
                        {detailData.graphor_type === 2 && (
                          <>
                            <h5 className="text-center mb-3">Jawaban Siswa</h5>
                            <GraphorGroupReadonlyAnswer
                              groups={jawabanSiswa}
                              showList={false}
                            />
                            <h5 className="text-center mt-4 mb-3">
                              Jawaban Benar
                            </h5>
                            <GraphorGroupReadonlyCorrect
                              groups={realAnswer}
                              showList={false}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <LoadingSpinner />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="dt-responsive table-responsive">
                <DataTable
                  className={'table table-striped table-bordered nowrap'}
                  data={contents}
                  totalRows={contents.length}
                  show={[10, 20, 30, 40, 50]}
                  onChangeSearch={(val) => {
                    setSearch(val)
                  }}
                  onChangeLimit={(val) => {
                    setLimit(val)
                  }}
                  onChangePage={(val) => {
                    setOffset(val)
                  }}
                  column={['Judul Materi', 'Sub Mata Pelajaran', 'Actions']}
                  renderItem={(item, index) => (
                    <RenderItem
                      item={item}
                      key={index}
                      handleDetail={getGraphorAnswer}
                    />
                  )}
                />
              </div>
            )}
          </div>
          <div className="modal-footer p-2">
            <button
              type="button"
              onClick={() => {
                window.$('#evaluasiList').modal('hide')
                resetDetail()
              }}
              className="btn btn-outline-danger mr-2"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const RenderItem = ({ item, handleDetail }) => {
  return (
    <tr>
      <td>{item.title}</td>
      <td>{item.class_title}</td>
      <td>
        <button
          onClick={() => handleDetail(item)}
          className="btn btn-sm btn-info"
        >
          <span>Detail</span>
        </button>
      </td>
    </tr>
  )
}

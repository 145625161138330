import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import SubmitButton from 'component/button/Submit'
import InvalidText from 'component/invalid/text'
import { FormSwitch } from 'component/forms'
import { getMediaDuration } from 'util/global'
import useEditContent from './hook'
import {
  FormInputName,
  FormInputUrutan,
  FormSelectSection,
  FormSelectPrecontent,
  FormInputTanggal,
  FormActiveStatus,
  FormSelectFeedback,
  FormGroupKecil,
  FormInputDesc,
  FormActiveKey,
  FormInputEndedAt,
  FormVideoNav,
  PreviewVideoLink,
  InfoGdrive,
  FormTextArea,
  FormSetToBeScore,
  FormSelectStudent,
} from 'component/content-form'
import { getUsersContentSpesificStudent } from 'util/array'

export default function EditContentVideo() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const location = useLocation()
  const params = useParams()

  /* ======================================= Consume Hook ======================================= */
  const { getContentTakeAway } = useEditContent()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const dataContent = location.state?.dataContent
  const contentUrl = dataContent.contents_url
  const sections = location.state.sectionList ? location.state.sectionList : []
  const links = [
    { url: '/kelas', name: 'Sub Mata Pelajaran' },
    { url: `/kelas/${location.state.kelasId}`, name: 'Overview' },
  ]

  /* ======================================= Local States ======================================= */
  const [isContentTakeAwayExist, setIsContentTakeAwayExist] = useState(false)
  
  // FORM INPUT STATES
  const [existingContents, setExistingContents]   = useState([])
  const [namaKonten, setNamaKonten]               = useState(dataContent?.title || '')
  const [urutan, setUrutan]                       = useState(dataContent?.urutan || '')
  const [sectionId, setSectionId]                 = useState(dataContent?.section_id || '')
  const [preContentId, setPrecontentId]           = useState(dataContent?.precontent_id || '')
  const [active, setActive]                       = useState(dataContent?.active ? 1 : 0)
  const [displayAt, setDisplayAt]                 = useState(dataContent?.display_at || '',)
  const [selectVideoSource, setSelectVideoSource] = useState({ link: true, internal: false })
  const [feeds, setFeeds]                         = useState([])
  const [feedId, setFeedId]                       = useState(null)
  const [isFeedActive, setIsFeedActive]           = useState(0)
  const [enableGroup, setEnableGroup]             = useState(!!dataContent?.group_status)
  const [maxGroup, setMaxGroup]                   = useState(dataContent?.group_max || '')
  const [descGroup, setDescGroup]                 = useState(dataContent?.group_description || '')
  const [endedAt, setEndedAt]                     = useState(dataContent?.ended_at || '')
  const [description, setDescription]             = useState(dataContent?.deskripsi || '')
  const [vidshowNav, setVidshowNav]               = useState(dataContent?.vidshow_nav ? 1 : 0)
  const [apresiasi, setApresiasi]                 = useState(dataContent?.apresiasi || '',)
  const [rating, setRating]                       = useState(dataContent?.rating ? 1 : 0)
  const [toBeScore, setToBeScore]   = useState({
    isScore: dataContent.is_score,
    isKeterampilan: dataContent.is_keterampilan,
    isPengetahuan: dataContent.is_pengetahuan,
    selectedTypeScore: dataContent.type_score_id,
    isTypeScoreActive: true,
  })
  const [selectedStudents, setSelectedStudents] = useState(() => getUsersContentSpesificStudent(dataContent))

  // FORM FILE STATES
  const [contentsUrlLink, setContentsUrlLink]       = useState('')
  const [contentUrlInternal, setContentUrlInternal] = useState('')
  const [linkContent, setLink]                      = useState('')
  const [youtubeId, setYoutubeId]                   = useState('')
  const [currentFileName, setcurrentFileName]       = useState('')
  const [isFileChange, setIsFileChange]             = useState(false)
  const [isUploading, setIsUploading]               = useState(false)
  const [files, setFiles]                           = useState(null)
  const [duration, setDuration]                     = useState(0)

  // FORM VALIDATION STATES
  const [isNamaInvalid, setIsNamaInvalid]         = useState(false)
  const [isUrutanInvalid, setIsUrutanInvalid]     = useState(false)
  const [isSectionInvalid, setIsSectionInvalid]   = useState(false)
  const [isFileInvalid, setIsFileInvalid]         = useState(false)
  const [isFileTypeInvalid, setIsFileTypeInvalid] = useState(false)
  const [isMaxGroupValid, setIsMaxGroupValid]     = useState(true)

  const [enableGroupKey, setEnableGroupKey] = useState(false)
  const [prerequisite, setPrerequisite]     = useState('')

  /* =========================================== Refs =========================================== */
  const refInputName     = useRef()
  const refSelectSection = useRef()
  const refInputUrutan   = useRef()
  const refMaxGroup      = useRef()

  function getExistingContentsBySelectedSection() {
    if (!sectionId) {
      setExistingContents([])
    } else {
      sections.forEach((section) => {
        if (section.id === sectionId) {
          setExistingContents(section.contents)
        }
      })
    }
  }

  function handleChangeEnableGroupKey() {
    if (enableGroupKey) {
      setEnableGroupKey(false)
    } else {
      setEnableGroupKey(true)
    }
  }

  async function getFeedsList() {
    const response = await getRequest('feed')
    if (response) {
      const options = response.data.map((item) => ({
        value: item.id,
        label: item.description,
      }))
      setFeeds(options)
      initSelectedFeed(options)
    } else {
      console.error('ERROR', response)
    }
  }

  function initSelectedFeed(feedList) {
    for (let feed of feedList) {
      if (feed.value === dataContent.feedback) {
        setFeedId({ value: feed.value, label: feed.label })
        break
      }
    }
  }

  function getExistingFile() {
    switch (contentUrl.source) {
      case 'youtube':
        handleUseLink()
        setLink(`https://youtube.com/watch?v=${contentUrl.data}`)
        break
      case 'internal':
        handleUseInternalFile()
        setcurrentFileName(`${dataContent.id}.mp4`)
        break
      case 'gdrive':
        handleUseLink()
        setLink(contentUrl.data)
      case 'umum':
        handleUseLink()
        setLink(contentUrl.data)
      default:
        break
    }
  }

  function handleChangeName(event) {
    setNamaKonten(event.target.value)
  }

  function handleChangeUrutan(event) {
    setUrutan(Math.abs(event.target.value))
  }

  function handleChangeSection(event) {
    setSectionId(event.target.value)
  }

  function handleChangePrecontent(event) {
    setPrecontentId(event.target.value)
  }

  function handleChangeDate(event) {
    setDisplayAt(event.target.value)
  }

  function handleChangeActive() {
    if (active === 1) {
      setActive(0)
    }
    if (active === 0) {
      setActive(1)
    }
  }

  function handleUseLink() {
    setSelectVideoSource({
      link: true,
      internal: false,
    })
  }

  function handleUseInternalFile() {
    setSelectVideoSource({
      link: false,
      internal: true,
    })
  }

  function validateFileExtension(fileName) {
    const fileExtension = fileName.split('.').pop()
    const correctExtension = 'mp4'
    if (fileExtension !== correctExtension) {
      setIsFileTypeInvalid(true)
      return 'tidak sesuai'
    } else {
      setIsFileTypeInvalid(false)
      return 'sesuai'
    }
  }

  async function handleFileContent(content) {
    if (typeof content === 'string') {
      setLink(content)
      if (content.includes('drive.google.com')) {
        setContentsUrlLink({ name: null, data: content, source: 'gdrive' })
      } else if (content.includes('youtube.com')) {
        setContentsUrlLink({ name: null, data: content, source: 'youtube' })
      } else {
        setContentsUrlLink({ name: null, data: content, source: 'umum' })
      }
      setcurrentFileName('')
      setContentUrlInternal('')
      setIsFileChange(true)
    } else if (`${typeof content}` === 'object') {
      const check = validateFileExtension(content.name)
      if (check === 'sesuai') {
        setIsFileChange(true)
        setFiles(content)
        setContentUrlInternal({
          name: content.name,
          data: 'internal',
          source: 'internal',
        })
        setLink('')
        setContentsUrlLink('')
        setcurrentFileName(content.name)
        let getDur = await getMediaDuration(content)
        let durSec = Math.round(getDur)
        setDuration(durSec)
      }
    }
  }

  function handleChangeFeedback(val) {
    setFeedId(val)
  }

  function handleFileValidation() {
    if (isFileChange) {
      if (selectVideoSource.link === true) {
        return contentsUrlLink
      } else if (selectVideoSource.internal === true) {
        return contentUrlInternal
      }
    } else {
      return 'unchanged'
    }
  }

  function handleChangeEnableGroup() {
    if (enableGroup) {
      setEnableGroup(false)
      setMaxGroup(dataContent.group_max ? dataContent.group_max : '')
      setIsMaxGroupValid(true)
    } else {
      setEnableGroup(true)
      setMaxGroup(dataContent.group_max ? dataContent.group_max : '')
      if (dataContent.group_max) {
        setIsMaxGroupValid(true)
      } else {
        setIsMaxGroupValid(false)
      }
    }
  }

  function handleChangeMaxGroup(event) {
    if (event.target.value) {
      const absolute = Math.abs(event.target.value)
      const integer = Math.round(absolute)
      setMaxGroup(integer)
      if (integer <= 0) {
        setIsMaxGroupValid(false)
      } else {
        setIsMaxGroupValid(true)
      }
    } else {
      setMaxGroup('')
      setIsMaxGroupValid(false)
    }
  }

  function handleChangeDescGroup(event) {
    setDescGroup(event.target.value)
  }

  function handleChangeDesc(event) {
    setDescription(event.target.value)
  }

  function handleChangeEndedAt(event) {
    setEndedAt(event.target.value)
  }

  function handleChangeVidshowNav() {
    if (vidshowNav === 1) {
      setVidshowNav(0)
    }
    if (vidshowNav === 0) {
      setVidshowNav(1)
    }
  }

  function handleChangeApresiasi(event) {
    setApresiasi(event.target.value)
  }

  function handleChangeRating(value) {
    setRating(value ? 1 : 0)
  }

  function toBeScoreValidation() {
    if (toBeScore.isScore) {
      return (toBeScore.isKeterampilan === true || toBeScore.isPengetahuan === true) && toBeScore.selectedTypeScore
    }

    return true
  }

  function handleValidation() {
    const contents_url = handleFileValidation()
    const newUrutan = urutan ? Math.abs(parseInt(urutan)) : 0
    const feedback = isFeedActive === 1 ? feedId : ''

    if (namaKonten && urutan && sectionId && contents_url && isMaxGroupValid && toBeScoreValidation()) {
      setIsNamaInvalid(false)
      setIsSectionInvalid(false)
      setIsUrutanInvalid(false)
      setIsFileInvalid(false)
      handleSubmitForm(
        dataContent.id,
        contents_url,
        namaKonten,
        sectionId,
        newUrutan,
        active,
        displayAt,
        preContentId,
        feedback.value,
        maxGroup,
        descGroup,
        endedAt,
        description,
        vidshowNav,
        apresiasi,
        rating,
      )
    } else {
      if (!isMaxGroupValid) {
        if (refMaxGroup) {
          refMaxGroup.current.focus()
        }
      }

      if (!sectionId) {
        setIsSectionInvalid(true)
        refSelectSection.current.focus()
      } else {
        setIsSectionInvalid(false)
      }

      if (!urutan) {
        setIsUrutanInvalid(true)
        refInputUrutan.current.focus()
      } else {
        setIsUrutanInvalid(false)
      }

      if (!contents_url || contents_url.data === '') {
        setIsFileInvalid(true)
      } else {
        setIsFileInvalid(false)
      }

      if (!namaKonten) {
        setIsNamaInvalid(true)
        refInputName.current.focus()
      } else {
        setIsNamaInvalid(false)
      }

      window.notification('Mohon', 'lengkapi data')

      if (!toBeScoreValidation()) {
        window.notification('Mohon', 'lengkapi juga data "Jadikan Sebagai Penilaian"')
      }
    }
  }

  async function handleSubmitForm(
    id,
    contentUrl,
    namaKonten,
    sectionId,
    urutan,
    active,
    displayAt,
    preContentId,
    feedId,
    maxGroup,
    descGroup,
    endedAt,
    description,
    vidshowNav,
    apresiasi,
    rating,
  ) {
    let form = new FormData()
    form.append('duration', duration)
    form.append('id', id)
    form.append('content_type', 3)
    form.append('title', namaKonten)
    form.append('section_id', sectionId)
    form.append('urutan', urutan)
    form.append('active', active)
    form.append('vidshow_nav', vidshowNav)
    form.append('rating', rating)
    form.append('precontent_id', preContentId)
    form.append('display_at', displayAt)
    form.append('ended_at', endedAt)
    form.append('deskripsi', description)
    form.append('apresiasi', apresiasi)

    form.append('is_score', !!toBeScore.isScore)

    if (toBeScore.isScore) {
      form.append('is_keterampilan', !!toBeScore.isKeterampilan)
      form.append('is_pengetahuan', !!toBeScore.isPengetahuan)
      form.append('type_score_id', toBeScore.selectedTypeScore)
    }

    if (Array.isArray(selectedStudents) && !!selectedStudents.length) {
      selectedStudents.forEach((selectedStudent, selectedStudentIndex) => {
        form.append(`spesifik_user_id[${selectedStudentIndex}]`, selectedStudent.id)
      })
    }

    if (contentUrl != 'unchanged') {
      form.append('contents_url', JSON.stringify(contentUrl))
      if (contentUrl.source === 'internal') {
        const { id } = JSON.parse(localStorage.getItem('user'))
        const profile_id = JSON.parse(localStorage.getItem('profile')).id
        form.append('path', `upload/${profile_id}/classes/contents`)
        form.append('temp_name', `file-temp-${id}-${Date.now()}.mp4`)
        form.append('file_upload', files)
      }
    }

    if (feedId) {
      form.append('feedback', feedId)
    } else {
      form.append('feedback', '')
    }

    if (enableGroup) {
      form.append('group_status', 1)
    } else {
      form.append('group_status', 0)
    }

    if (maxGroup) {
      form.append('group_max', maxGroup)
    }

    if (descGroup) {
      form.append('group_description', descGroup)
    }

    setIsUploading(true)
    const response = await putRequest('contents', form)
    setIsUploading(false)
    if (response) {
      if (enableGroupKey) {
        let formkey = new FormData()
        formkey.append('description', prerequisite)
        formkey.append('content_id', id)

        const response1 = isContentTakeAwayExist
          ? await putRequest('contentTakeAway', formkey)
          : await postRequest('contentTakeAway', formkey)

        if (response1) {
          window.notification('Materi', 'berhasil diubah', 'success')
          history.push(`/kelas/${location.state.kelasId}`)
        }
      } else {
        window.notification('Materi', 'berhasil diubah', 'success')
        history.push(`/kelas/${location.state.kelasId}`)
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getContentTakeAway(dataContent.id).then((res) => {
      if (res.data) {
        setPrerequisite(res.data.description)
        setEnableGroupKey(true)
        setIsContentTakeAwayExist(true)
      }
    })

    getFeedsList()
    getExistingFile()
  }, [])

  useEffect(() => {
    getExistingContentsBySelectedSection()
  }, [sectionId])

  useEffect(() => {
    setYoutubeId(linkContent.split('=').pop())
  }, [linkContent])

  useEffect(() => {
    if (dataContent.feedback !== null) {
      setIsFeedActive(1)
    }
  }, [dataContent.feedback])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Edit Materi Video"
          linkName="Materi Video"
          links={links}
        />
        
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <h5>
                  <i className="fas fa-play-circle mr-1" />
                  Informasi Video
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <form>
                      {/* Input Nama Konten */}
                      <FormInputName
                        label="Nama Video"
                        placeholder="Nama Video"
                        namaKonten={namaKonten}
                        isNamaInvalid={isNamaInvalid}
                        handleChangeName={handleChangeName}
                        refInputName={refInputName}
                      />

                      {/* Video Form Group */}
                      <fieldset className="form-group row mb-5">
                        <label className="col-sm-3 col-form-label">
                          <span className="mr-1">Video</span>
                        </label>
                        <div className="col-sm-9 row mb-3">
                          <div className="col">
                            {/* Select Source Youtube*/}
                            <div className="form-check">
                              <input
                                onChange={() => handleUseLink()}
                                className="form-check-input"
                                type="radio"
                                name="selectVideoSource"
                                id="youtubeUrl"
                                defaultValue={1}
                                defaultChecked={
                                  contentUrl.source !== 'internal'
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="youtubeUrl"
                              >
                                <i className="feather icon-link mr-1" />
                                <span>Link</span>
                                <span className="ml-1">
                                  (Google Drive, Youtube, atau link umum)
                                </span>
                              </label>
                            </div>

                            {/* Input URL */}
                            {selectVideoSource.link ? (
                              <div className="mb-4">
                                <InfoGdrive />
                                <input
                                  type="text"
                                  value={linkContent}
                                  onChange={(event) =>
                                    handleFileContent(event.target.value)
                                  }
                                  className="form-control my-3"
                                  placeholder="Link: https://youtube.com/watch?v=xyz atau https://drive.google.com/file/d/xyz atau link umum"
                                />
                                {linkContent ? (
                                  <PreviewVideoLink
                                    link={linkContent}
                                    youtubeId={youtubeId}
                                  />
                                ) : null}
                              </div>
                            ) : null}

                            {/* Select Source Internal */}
                            <div className="form-check">
                              <input
                                onChange={() => handleUseInternalFile()}
                                className="form-check-input"
                                type="radio"
                                name="selectVideoSource"
                                id="internalFile"
                                defaultValue={0}
                                defaultChecked={
                                  contentUrl.source === 'internal'
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="internalFile"
                              >
                                <i className="fas fa-file-video mr-1" />
                                <span>Upload file</span>
                                <span className="ml-1">(mp4)</span>
                              </label>
                            </div>

                            {/* Input File Video */}
                            {selectVideoSource.internal ? (
                              <div className="custom-file my-3">
                                <input
                                  type="file"
                                  onChange={(event) =>
                                    handleFileContent(event.target.files[0])
                                  }
                                  className="custom-file-input"
                                  id="inputFileVideo"
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="inputFileVideo"
                                  style={{
                                    height: 'fit-content',
                                  }}
                                >
                                  {currentFileName
                                    ? currentFileName
                                    : 'Pilih file video mp4'}
                                </label>
                                {isFileTypeInvalid ? (
                                  <label className="col small form-text text-danger">
                                    Type file video harus mp4.
                                  </label>
                                ) : null}
                              </div>
                            ) : null}
                            {isFileInvalid ? (
                              <InvalidText name={'Video'} />
                            ) : null}
                          </div>
                        </div>
                      </fieldset>

                      {/* Switch vidshow nav */}
                      <FormVideoNav
                        vidshowNav={vidshowNav}
                        handleChangeVidNav={handleChangeVidshowNav}
                      />

                      {/* Input Urutan */}
                      <FormInputUrutan
                        urutan={urutan}
                        handleChangeUrutan={handleChangeUrutan}
                        isUrutanInvalid={isUrutanInvalid}
                        refInputUrutan={refInputUrutan}
                      />

                      {/* Input deskripsi */}
                      <FormInputDesc
                        description={description}
                        handleChangeDesc={handleChangeDesc}
                      />

                      {/* Input Apresiasi */}
                      <FormTextArea
                        id="formInputApresiasi"
                        label="Apresiasi"
                        placeholder="Apresiasi/pesan setelah siswa menyelesaikan materi (opsional)"
                        className="mb-5"
                        value={apresiasi}
                        handleChange={handleChangeApresiasi}
                      />

                      {/* Select Section */}
                      <FormSelectSection
                        sections={sections}
                        handleChangeSection={handleChangeSection}
                        isSectionInvalid={isSectionInvalid}
                        existingSectionId={sectionId}
                        refSelectSection={refSelectSection}
                      />

                      {/* Select Precontent */}
                      <FormSelectPrecontent
                        existingContents={existingContents}
                        handleChangePrecontent={handleChangePrecontent}
                        existingPrecontentId={preContentId}
                        existingContentId={dataContent.id}
                      />

                      {/* Input Tanggal Ditampilkan */}
                      <FormInputTanggal
                        handleChangeDate={handleChangeDate}
                        existingDisplayAt={displayAt}
                      />

                      {/* Input ended at */}
                      <FormInputEndedAt
                        endedAt={endedAt}
                        handleChangeEndedAt={handleChangeEndedAt}
                      />

                      {/* Rating */}
                      <FormSwitch
                        id="formRating"
                        label="Rating Materi"
                        value={rating}
                        handleChange={handleChangeRating}
                      />

                      {/* Select Feedback */}
                      <FormSelectFeedback
                        existingFeedId={feedId}
                        existingFeeds={feeds}
                        isFeedActive={isFeedActive}
                        setIsFeedActive={setIsFeedActive}
                        handleChangeFeeds={handleChangeFeedback}
                        getFeedList={getFeedsList}
                      />

                      {/* Swicth Group Kecil */}
                      <FormGroupKecil
                        enableGroup={enableGroup}
                        handleChangeEnableGroup={handleChangeEnableGroup}
                        maxGroup={maxGroup}
                        descGroup={descGroup}
                        handleChangeMaxGroup={handleChangeMaxGroup}
                        handleChangeDescGroup={handleChangeDescGroup}
                        isMaxGroupValid={isMaxGroupValid}
                        refMaxGroup={refMaxGroup}
                      />

                      {/* Select Specific Students */}
                      <FormSelectStudent
                        selectedStudents={selectedStudents}
                        setSelectedStudents={setSelectedStudents}
                        subMapelId={params?.kelasId}
                        originalSelectedStudents={getUsersContentSpesificStudent(dataContent)}
                        isDirectSave={false}
                        isWithFooter={false}
                        listSelectedStudentsProps={{
                          listStudentsActions: ['rollback', 'clear-all'],
                        }}
                      />

                      {/* Select To Be Score Or Not */}
                      <FormSetToBeScore
                        value={toBeScore}
                        handleChange={setToBeScore}
                      />

                      {/* Select Status */}
                      <FormActiveStatus
                        existingStatus={active}
                        handleChangeStatus={handleChangeActive}
                      />

                      <FormActiveKey
                        enableGroupKey={enableGroupKey}
                        handleChangeEnableGroupKey={handleChangeEnableGroupKey}
                        prerequisite={prerequisite}
                        setPrerequisite={setPrerequisite}
                      />

                      <div className="form-group">
                        <button
                          onClick={() =>
                            history.push(`/kelas/${location.state.kelasId}`)
                          }
                          type="button"
                          className="btn btn-outline-danger has-ripple mr-2"
                        >
                          Kembali
                        </button>
                        {isUploading ? (
                          <button
                            disabled={true}
                            type="button"
                            className="btn btn-success mr-2"
                          >
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                            />
                            <span>Simpan</span>
                          </button>
                        ) : (
                          <SubmitButton
                            onClick={() => handleValidation()}
                            text="Simpan"
                          />
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

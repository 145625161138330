import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Select from 'react-select'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import SiswaList from './siswa-list'

export default function DaftarTugasSiswa() {
  /* ========================================== Helpers ========================================= */
  const history          = useHistory()
  const { assignmentId } = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/kelola-assignment', name: 'Kelola Tugas' }]

  /* ======================================= Local States ======================================= */
  // SISWA ASSIGNMENT
  const [assignment, setAssignment] = useState(null)
  const [siswaList, setSiswaList]   = useState([])

  // FILTER
  const [groupList, setGroupList]             = useState([])
  const [classList, setClassList]             = useState([])
  const [filterGroup, setFilterGroup]         = useState(null)
  const [filterClass, setFilterClass]         = useState(null)
  const [isFilterLoading, setIsFilterLoading] = useState(false)

  // TABLE STATE
  const [totalRecord, setTotalRecord] = useState(0)
  const [limit, setLimit]             = useState(10)
  const [offset, setOffset]           = useState(0)
  const [keyword, setKeyword]         = useState('')

  /* ========================================= Functions ======================================== */
  async function getSiswaMultiGroup() {
    let filterGroupStr = ''
    let filterClassStr = ''

    if (filterGroup !== null) {
      const groupIds = filterGroup.map((obj) => obj.value)
      filterGroupStr = groupIds.join('+')
    }

    if (filterClass !== null) {
      const classIds = filterClass.map((obj) => obj.value)
      filterClassStr = classIds.join('+')
    }

    const response = await getRequest(
      `assignment-siswa-group-multi?assignment_id=${assignmentId}&keyword=${keyword}&limit=${limit}&offset=${offset}&groups=${filterGroupStr}&classes=${filterClassStr}`,
    )
    setIsFilterLoading(false)
    if (response) {
      if (response.data.length !== 0) {
        setSiswaList(response.data)
      } else {
        setSiswaList([])
      }
      setAssignment(response.assignment)
      setGroupOptions(response.groupUser)
      setClassOptions(response.classList)
      setTotalRecord(response.totalRecord)
    }
  }

  function setGroupOptions(groups) {
    if (groupList.length === 0) {
      let combine = []
      groups.forEach((group) => {
        combine.push({
          value: group.id,
          label: group.group,
        })
      })
      setGroupList(combine)
    }
  }

  function setClassOptions(classes) {
    if (classList.length === 0) {
      let combine = []
      classes.forEach((kelas) => {
        combine.push({
          value: kelas.id,
          label: kelas.title,
        })
      })
      setClassList(combine)
    }
  }

  async function handleFilter() {
    setIsFilterLoading(true)
    setOffset(0)
    getSiswaMultiGroup()
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getSiswaMultiGroup()
  }, [keyword, limit, offset])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Daftar Siswa" links={links} />
        <div className="card user-profile-list">
          <div className="card-header">
            <h5>{assignment ? assignment.title : '-'}</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4 mb-2 group-select">
                <Select
                  isMulti
                  options={groupList}
                  placeholder="Filter berdasarkan Kelas"
                  onChange={(val) => {
                    setFilterGroup(val)
                  }}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 mb-2">
                <Select
                  isMulti
                  options={classList}
                  placeholder="Filter berdasarkan Mata Pelajaran"
                  onChange={(val) => setFilterClass(val)}
                />
              </div>
              <div className="col-6 col-lg-2 col-xl-1 mb-2 d-flex align-items-center">
                {isFilterLoading ? (
                  <button
                    className="btn btn-sm btn-primary w-100 d-flex justify-content-center align-items-center"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm mr-2"
                      role="status"
                    />
                    <span>Filter</span>
                  </button>
                ) : (
                  <button
                    onClick={handleFilter}
                    type="button"
                    className="btn btn-sm btn-primary w-100"
                  >
                    <span>Filter</span>
                  </button>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col dt-responsive table-responsive">
                <SiswaList
                  siswaList={siswaList}
                  assignmentId={assignmentId}
                  totalRecord={totalRecord}
                  setLimit={setLimit}
                  setOffset={setOffset}
                  setKeyword={setKeyword}
                />
              </div>
            </div>
          </div>
          <div className="card-footer">
            <button
              onClick={() => history.push('/kelola-assignment')}
              type="button"
              className="btn btn-outline-danger"
            >
              <i className="fas fa-chevron-left mr-2" />
              <span>Kelola Tugas</span>
            </button>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

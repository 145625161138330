import React, { useEffect, useState } from 'react'
import { useBaseContext } from 'context/base'
import TeacherRow from './teacher-row'
import Loader from './teacher-row/loader'

export default function TableSettingRoles({ detailGroup }) {
  /* ======================================= Consume Props ====================================== */
  const { idGroup } = detailGroup

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================== States ========================================== */
  const [loading, setLoading]                   = useState(true)
  const [teachersWithRole, setTeachersWithRole] = useState([])

  /* ========================================= Functions ======================================== */
  async function getTeachersRole() {
    const res = await getRequest(`group-teachers-role?id=${idGroup}`)
    setTeachersWithRole(res.data.groupusers)
    setLoading(false)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getTeachersRole()
  }, [])

  /* ======================================== Components ======================================== */
  const TeacherRows = () =>
    loading ? (
      <Loader />
    ) : (
      teachersWithRole.map((teacher, idx) => (
        <TeacherRow
          teacher = {teacher}
          num     = {idx + 1}
          key     = {`teacher_row_` + idx}
        />
      ))
    )

  /* ========================================== Output ========================================== */
  return (
    <table className='table table-small'>
      <thead>
        <tr>
          <th>No</th>
          <th>No Induk</th>
          <th>Nama</th>
          <th>Username</th>
          <th className='text-center'>Guru Utama</th>
        </tr>
      </thead>
      <tbody>
        <TeacherRows />
      </tbody>
    </table>
  )
}

import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Highcharts from 'highcharts'
import Heatmap from 'highcharts/modules/heatmap'
import HighchartsReact from 'highcharts-react-official'
import { useBaseContext } from 'context/base'
import LoadingSpinner from 'component/loading'

Heatmap(Highcharts)
export default function KeaktifanHeatmap({ kelasTitle }) {
  /* ========================================== Helpers ========================================= */
  const param   = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================== States ========================================== */
  const [displayBy, setDisplayBy] = useState('jam')
  const [response, setResponse]   = useState(null)
  const [loading, setLoading]     = useState(false)
  const [data, setData]           = useState({
    yCategories : [],
    xCategories : [],
    series      : [],
  })

  /* ========================================= Functions ======================================== */
  const secondToHours   = (second) => Math.ceil(second / 3600)
  const secondToMinutes = (second) => Math.ceil(second / 60)

  const minutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60)
    const minLeft = minutes % 60
    if (hours) { return `${hours} jam ${minLeft} menit` }
    else { return `${minLeft} menit` }
  }

  function getPointCategoryName(point, dimension) {
    var series = point.series,
        isY    = dimension === 'y',
        axis   = series[isY ? 'yAxis' : 'xAxis']
    return axis.categories[point[isY ? 'y' : 'x']]
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    async function getHeatMap() {
      setLoading(true)
      let response = await getRequest(`heatmap-class1?class_id=${param.kelasId}`)
      setLoading(false)
      if (response) {        
        setResponse(response.data)
      }
    }
    getHeatMap()
  }, [])

  useEffect(() => {
    if (response) {
      const series = response.series.map((series) => {
        const [x, y, v] = series
        return [x, y, displayBy === 'jam' ? secondToHours(v) : secondToMinutes(v)]
      })
      setData({
        yCategories: response.yCategories,
        xCategories: response.xCategories,
        series,
      })
    }
  }, [displayBy, response])

  /* ========================================== Configs ========================================= */
  let height = data.yCategories.length * 45 + 100
  height = height >= 300 ? height : 300

  const highchartConfig = {
    chart: {
      height: `${height}px`,
      type: 'heatmap',
      marginTop: 40,
      marginBottom: 100,
      plotBorderWidth: 1,
    },
    title: {
      text: `Heat Map Keaktifan Siswa Kelas ` + kelasTitle,
    },
    xAxis: {
      categories: data.xCategories,
      title: 'Siswa'
    },
    yAxis: {
      categories: data.yCategories,
      title: 'Content',
      reversed: true,
    },
    accessibility: {
      point: {
        descriptionFormatter: function (point) {
          var ix = point.index + 1,
            xName = getPointCategoryName(point, 'x'),
            yName = getPointCategoryName(point, 'y'),
            val = point.value
          return (
            ix + '. ' + xName + ' sales ' + yName + ', ' + val + '.'
          )
        },
      },
    },
    colorAxis: {
      min: 0,
      minColor: '#FFFFFF',
      maxColor: Highcharts.getOptions().colors[0],
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      margin: 0,
      verticalAlign: 'top',
      y: 25,
      symbolHeight: data.yCategories.length * 45,
    },
    tooltip: {
      formatter: function () {
        return (
          '<b>' +
          getPointCategoryName(this.point, 'x') +
          '</b>: <br><b>' +
          '</b>' +
          getPointCategoryName(this.point, 'y') +
          ' selama ' +
          '<b>' +
          (
            displayBy === 'menit' 
              ? minutesToHours(this.point.value) 
              : (this.point.value + ' jam')) 
          +
          '</b>'
        )
      },
    },
    series: [
      {
        name: 'Keaktifan',
        borderWidth: 1,
        data: data.series,
        dataLabels: {
          enabled: true,
          color: '#000000',
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            yAxis: {
              labels: {
                formatter: function () {
                  return this.value.charAt(0)
                },
              },
            },
          },
        },
      ],
    },
  }

  /* ========================================== Output ========================================== */
  return (
    <div className="feedback-heatmap">
      {(
        <>
          <div className='row' style={styles.firstRow}>
            <div className='col-md-4 offset-md-8 col-sm-12'>
              <ul className="nav nav-pills nav-fill">
                <li className="nav-item">
                  <a className={`nav-link ${ displayBy === 'jam' && 'active' }`} aria-current="page" onClick={() => setDisplayBy('jam')}>
                    Tampilkan Berdasarkan Jam
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${ displayBy === 'menit' && 'active' }`} onClick={() => setDisplayBy('menit')}>
                  Tampilkan Berdasarkan Menit
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {!loading ? <>
            <div className='row'>
              <div className="col f-w-700 d-flex align-items-center justify-content-center" style={styles.legend.y}>
                Content
              </div>
              <div className='col' style={styles.highchartContainer}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={highchartConfig}
                />
              </div>
            </div>
            <div className='mt-0 f-11 text-center f-w-700' style={styles.legend.x}>
              List Siswa
            </div>
          </>
          : <LoadingSpinner />}
        </>
      )}
    </div>
  )
}

const styles = {
  firstRow: {
    borderBottom: '1px solid lightgray',
    paddingBottom: '7px',
    marginBottom: '7px',
  },
  highchartContainer: {
    padding: 0,
    margin: 0,
  },
  legend: {
    x: {
      color: '#666666',
    },
    y: {
      maxWidth: '10px',
      color: '#666666',
      writingMode: 'vertical-lr', 
      textOrientation: 'mixed',
      transform: 'rotateZ(180deg)',
    }
  }
}

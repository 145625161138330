import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DetailInfo from './DetailInfo'
import DetailUser from './DetailUser'
import DetailKelas from './DetailKelas'
import DetailWali from './DetailWali'
import HeatMap from './HeatMap'

export default function GroupDetail() {
  /* ========================================== Helpers ========================================= */
  const location    = useLocation()
  var   { idGroup } = useParams()
  const dataGroup   = location.state?.item

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Kelas"
          linkName={`Detail Kelas ${dataGroup?.group}`}
          links={[{ url: '/group', name: 'Kelas' }]}
        />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header">
                <h5>Detail Kelas {dataGroup?.group}</h5>
              </div>
              <div className="card-body">
                <>
                  <ul
                    className="nav nav-tabs mb-3"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active text-uppercase"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Info
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-uppercase"
                        id="wali-tab"
                        data-toggle="tab"
                        href="#wali"
                        role="tab"
                        aria-controls="wali"
                        aria-selected="true"
                      >
                        Wali Kelas
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link  text-uppercase"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        Siswa
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-uppercase"
                        id="contact-tab"
                        data-toggle="tab"
                        href="#contact"
                        role="tab"
                        aria-controls="contact"
                        aria-select    ed="false"
                      >
                        Sub Mata Pelajaran
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-uppercase"
                        id="contact-tab"
                        data-toggle="tab"
                        href="#heatmap"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                      >
                        Heatmap Feedback
                      </a>
                    </li>
                  </ul>
                  <div
                    className="tab-content"
                    id="myTabContent"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <DetailInfo idGroup={idGroup} />
                    </div>
                    <div
                      className="tab-pane fade show"
                      id="wali"
                      role="tabpanel"
                      aria-labelledby="wali-tab"
                    >
                      <DetailWali idGroup={idGroup} />
                    </div>
                    <div
                      className="tab-pane fade "
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <DetailUser idGroup={idGroup} />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="contact"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                    >
                      <DetailKelas idGroup={idGroup} />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="heatmap"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                    >
                      <HeatMap
                        idGroup={idGroup}
                        groupName={dataGroup?.group}
                      />
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

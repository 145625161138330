import React, { useCallback, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useBaseContext } from 'context/base'
import BaseInput from 'component/input/BaseInput'
import BaseButton from 'component/button/BaseButton'
import Swal from 'sweetalert2'

const ConfigQuiz = () => {
  /* ====================================== Consume Context ====================================== */
  const { getRequest, putRequest } = useBaseContext()

  const [data, setData] = useState([])
  const [mutatedData, setMutatedData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)


  /* --------------------------------- HANDLER -------------------------------- */
  /**
   * On Change
   * 
   * @param {{
   *  type: 'min' | 'max'
   *  index: number
   *  value: any
   * }} params
   */
  const onChangeHandler = ({ type, index, value }) => {
    const copyMutatedData = [...mutatedData]
    const copySelectedMutatedData = copyMutatedData[index]

    switch (type) {
      case 'min':
        copySelectedMutatedData.min = parseInt(value)
        break;
      case 'max':
        copySelectedMutatedData.max = parseInt(value)
        break;
      default:
        console.error('Uknown Type')
        break;
    }

    copyMutatedData[index] = copySelectedMutatedData

    setMutatedData(copyMutatedData)
  }

  const getDataHandler = useCallback(async () => {
    setIsLoading(true)

    try {
      let res = await getRequest('config/list-quiz-limit')
      if (res?.data?.length) {

        setData(res?.data)
        const manipulatedMutatedData = res?.data?.map(d => ({
          id: d?.id,
          min: d?.min,
          max: d?.max,
        }))

        setMutatedData(manipulatedMutatedData)

        setIsLoading(false)
        return res?.data;
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      window.notification(
        'Error Get List Kindness',
        error?.message || 'Something went wrong',
        'error',
      )
      return [];
    }

  }, [])

  const onSubmitHandler = async (e) => {
    e.preventDefault()

    Swal.fire({
      title: 'Konfirmasi',
      text: 'Anda akan menyimpan data konfigurasi Quiz ini?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Simpan!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoadingSubmit(true)
        const response = await putRequest('config/update-quiz-limit', { quizLimit: mutatedData })

        if (response) {
          getDataHandler();
          setIsLoadingSubmit(false);
          window.notification('Berhasil menyimpan data konfigurasi quiz!', '', 'success')
        } else {
          setIsLoadingSubmit(false)
        }
      }
    });
  }

  useEffect(() => {
    getDataHandler()
  }, [])
  return (
    <Form onSubmit={onSubmitHandler} className='border p-3'>
      <fieldset>
        <legend><strong>Percobaan Quiz</strong></legend>
        <p className='m-0'>Konfigurasi nilai setiap percobaan quiz yang terdaftar di aplikasi.</p>
        <hr />
        {data.length ? data.map((d, index) => (
          <div key={d?.id} className='mb-5'>
            <h6>{d?.keterangan}</h6>
            <BaseInput type='number' isLoading={isLoadingSubmit || isLoading} label='Min:' id={`min-${d?.id}`} access={['administrator', 'superadministrator']} props={{ min: 0, defaultValue: mutatedData?.[index]?.min, onChange: (e) => onChangeHandler({ type: 'min', index, value: e.target.value }) }} />
            <BaseInput type='number' isLoading={isLoadingSubmit || isLoading} label='Max:' id={`max-${d?.id}`} access={['administrator', 'superadministrator']} props={{ min: 0, defaultValue: mutatedData?.[index]?.max, onChange: (e) => onChangeHandler({ type: 'max', index, value: e.target.value }) }} />
          </div>
        )) : null}
        <BaseButton isLoading={isLoadingSubmit || isLoading} variant="primary" type="submit">
          Simpan
        </BaseButton>
      </fieldset>
    </Form>
  )
}

export default ConfigQuiz
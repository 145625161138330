import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

import useFeedbackForm from 'context/feedback-form'
import InvalidWarning from 'component/invalid/warning'
import { editorConfiguration } from 'util/ckeditor-config'

export default function AddFeedEssay({ sharedProps }) {
  /* =================================== Consume Shared Props =================================== */
  const {
    feedText,
    handleChangeFeedText,
    isFeedTextEmpty,
    isLoading,
    formValidation,
  } = sharedProps

  /* ====================================== Consume Context ===================================== */
  const { feed } = useFeedbackForm()

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="form-group">
        <label>{feed.data ? feed.data.description : ''}</label>
        <CKEditor
          editor={Editor}
          data={feedText}
          config={editorConfiguration}
          onChange={(event, editor) => {
            const data = editor.getData()
            handleChangeFeedText(data)
          }}
        />
        {isFeedTextEmpty && (
          <InvalidWarning text="Feedback tidak boleh kosong" />
        )}
      </div>
      <div className="form-group row">
        <div className="col-12 offset-md-10 col-md-2">
          {isLoading ? (
            <button
              type="button"
              className="btn btn-sm btn-primary w-100"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
              />
              <span>Kirim</span>
            </button>
          ) : (
            <button
              onClick={formValidation}
              type="button"
              className="btn btn-sm btn-primary w-100"
            >
              <i className="fas fa-paper-plane mr-1" />
              <span>Kirim</span>
            </button>
          )}
        </div>
      </div>
    </>
  )
}

import { useBaseContext } from "context/base"
import { useReducer } from "react"
import { popUpWindow } from "util/global"

/**
 * @typedef {Object} ListQuizState
 * @property {BaseSelectFormatData} selectedFilter - The selected quiz filter.
 * @property {Array<BaseSelectFormatData>} optionsFilter - The options for the quiz filter.
 * @property {Array} data - The list of contents quiz data.
 * @property {Object} meta - Metadata related to pagination and filtering.
 * @property {boolean} loading - Indicates if the data is currently being loaded.
*/

/**
 * @typedef {Object} Action
 * @property {'set_meta' | string} [type] - The type of action to perform.
 * @property {any} payload - The payload containing the data to update the state.
 */

/**
 * List Contents  Reducer
 * @param {ListQuizState} state - The current state of the contents quiz list.
 * @param {Action} action - The action to perform on the state.
 * @returns {ListQuizState} The updated state.
 */
const listQuizReducer = (state, action) => {
  switch (action.type) {
    case 'set_meta':
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload
        }
      }
    default:
      return {
        ...state,
        ...action.payload,
      }
  }
}

export function useQuizHook() {
  const { getRequest } = useBaseContext()

  /**
   * @type {[ListQuizState, (params: Action) => void]}
  */
  const [listQuiz, dispatchListQuiz] = useReducer(listQuizReducer, {
    selectedFilter: null,
    optionsFilter: [],
    errorMessage: '',
    data: [],
    meta: {
      search: '',
      limit: 10,
      offset: 0,
      totalData: 0,
    },
    loading: false,
  })

  /**
   * Get Quiz Handler
   * 
   * @param {{
   *  setInitSelectValue: (listOptions: Array<BaseSelectFormatData>) => void
   * }} getQuizParams 
   */
  const getQuizHandler = async (getQuizParams) => {
    dispatchListQuiz({ payload: { loading: true } })
    const response = await getRequest('quiz')
    if (response) {
      dispatchListQuiz({ payload: { loading: false } })

      const options = response?.data?.map((item) => ({
        value: item.id,
        label: item.title,
      }))

      const responseData = {
        ...listQuiz,
        optionsFilter: options,
        data: response?.data,
        meta: {
          ...listQuiz.meta,
          ...response.meta
        },
        loading: false,
      }

      if (getQuizParams?.setInitSelectValue && typeof getQuizParams?.setInitSelectValue === 'function') {
        getQuizParams.setInitSelectValue(options)
      }
      dispatchListQuiz({ payload: responseData })
    } else {
      dispatchListQuiz({ payload: { loading: false } })
    }
  }

  const popUpWindowKelolaQuizHandler = () => popUpWindow('/kelola-quiz', window, 960, 600)
  return {
    data: {
      listQuiz,
    },
    dispatch: {
      dispatchListQuiz,
    },
    handler: {
      getQuizHandler,
      popUpWindowKelolaQuizHandler,
    }
  }
}
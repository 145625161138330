export const RenderIcon = (text) => {
  //KONTEN KELAS
  if (text.item == 'Konten teks') {
    return <i className="fas fa-pencil-alt bg-danger update-icon" />
  } else if (text.item == 'Konten soundcloud') {
    return <i className="fab fa-soundcloud bg-danger update-icon" />
  } else if (text.item == 'Konten mp3') {
    return <i className="fas fa-volume-up bg-danger update-icon" />
  } else if (text.item == 'Konten video Youtube') {
    return <i className="fab fa-youtube bg-danger update-icon" />
  } else if (text.item == 'Konten mp4') {
    return <i className="fas fa-film bg-danger update-icon" />
  } else if (text.item == 'Konten pdf') {
    return <i className="fas fa-file-pdf bg-danger update-icon" />
  } else if (text.item == 'Konten Survey') {
    return <i className="fas fa-tasks bg-danger update-icon" />
  } else if (text.item == 'Konten iFrame') {
    return <i className="fas fa-laptop-code bg-danger update-icon" />
  } else if (text.item == 'Konten assignment teks') {
    return <i className="fas fa-pencil-alt bg-success update-icon" />
  } else if (text.item == 'Konten assignment audio') {
    return <i className="fas fa-volume-up bg-success update-icon" />
  } else if (text.item == 'Konten assignment video') {
    return <i className="fas fa-film bg-success update-icon" />
  } else if (text.item == 'Konten assignment Youtube') {
    return <i className="fab fa-youtube bg-success update-icon" />
  } else if (text.item == 'Konten assignment dokumen') {
    return <i className="fas fa-file-alt bg-success update-icon" />
  } else if (text.item == 'Konten dokumen google drive') {
    return <i className="fab fa-google-drive bg-warning update-icon" />
  } else if (
    text.item == 'Konten Quiz Bahasa Inggris' ||
    'Konten Quiz Matematika'
  ) {
    return <i className="fas fa-clipboard-check bg-primary update-icon" />
  } else {
    return <i className="fas fa-exclamation-triangle bg-warning update-icon" />
  }
}

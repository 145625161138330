import React from 'react'
import useForumDetail from 'context/forum-detail'
import useForumDetailItem from 'context/forum-detail-item'

export default function ForumDetailUserDesc() {
  /* ====================================== Consume Context ===================================== */
  const { tanggal } = useForumDetail()
  const { item }    = useForumDetailItem()

  /* ========================================= Constants ======================================== */
  const date = tanggal.generate(item.updatedAt)

  /* ========================================== Output ========================================== */
  return (
    <>
      <p
        className="mb-0"
        style={{ fontWeight: '500' }}
      >
        {item.login.user.name}
      </p>
      <p
        className="text-muted"
        style={{ fontSize: '10px' }}
      >
        <i className="feather icon-clock" /> {date}
      </p>
      <p>{item.description}</p>
    </>
  )
}

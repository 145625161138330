import React from 'react'
import usePreviewRapor from 'context/preview-rapor'

export default function Title() {
  /* ====================================== Consume Context ===================================== */
  const { rapor } = usePreviewRapor()

  /* ========================================= Functions ======================================== */
  function formatDate(date) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(date).toLocaleDateString('id-ID', options)
  }

  return (
    <div
      className="card mb-1"
      style={{ height: '140px' }}
    >
      <div className="card-body ">
        <div className="row">
          <div className="col-12 text-center">
            <h3 className="text-uppercase">Laporan capaian hasil belajar</h3>
            <h5 className="text-uppercase">{rapor.period.data?.name_period}</h5>
            <h5 className="text-uppercase">
              {formatDate(rapor.period.data?.start_date)} -{' '}
              {formatDate(rapor.period.data?.end_date)}
            </h5>
          </div>
        </div>
      </div>
    </div>
  )
}


const useGetRoleActive = () => {
  const roleActive = localStorage.getItem('role_active');
  const roleActiveParse = roleActive ? JSON.parse(roleActive) : '';

  return {
    type: roleActiveParse?.role_type
  }
}

export default useGetRoleActive
import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import BackButton from 'component/button/Back'
import SubmitButton from 'component/button/Submit'
import { FormSwitch } from 'component/forms'
import {
  FormInputName,
  FormInputUrutan,
  FormSelectSection,
  FormSelectPrecontent,
  FormInputTanggal,
  FormActiveKey,
  FormActiveStatus,
  FormSelectFeedback,
  FormGroupKecil,
  FormInputDesc,
  FormInputEndedAt,
  FormTextArea,
  FormSelectGraphor,
  FormSetToBeScore,
  FormSelectStudent,
} from 'component/content-form'

export default function AddContentGraphor() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const location = useLocation()
  const params = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const links = [
    { url: '/kelas', name: 'Sub Mata Pelajaran' },
    { url: `/kelas/${location.state.kelasId}`, name: 'Overview' },
  ]
  const contentType = 9
  const sections = location.state.sectionList ? location.state.sectionList : []

  /* ======================================= Local States ======================================= */
  const [existingContents, setExistingContents] = useState([])
  const [graphors, setGraphors]         = useState([])
  const [namaKonten, setNamaKonten]     = useState('')
  const [urutan, setUrutan]             = useState('')
  const [sectionId, setSectionId]       = useState('')
  const [preContentId, setPrecontentId] = useState('')
  const [active, setActive]             = useState(0)
  const [durasi, setDurasi]             = useState('')
  const [displayAt, setDisplayAt]       = useState('')
  const [feeds, setFeeds]               = useState([])
  const [feedId, setFeedId]             = useState(null)
  const [isFeedActive, setIsFeedActive] = useState(0)
  const [enableGroup, setEnableGroup]   = useState(false)
  const [maxGroup, setMaxGroup]         = useState('')
  const [descGroup, setDescGroup]       = useState('')
  const [endedAt, setEndedAt]           = useState('')
  const [description, setDescription]   = useState('')
  const [apresiasi, setApresiasi]       = useState('')
  const [graphor, setGraphor]           = useState(null)
  const [rating, setRating]             = useState(1)

  const [enableGroupKey, setEnableGroupKey] = useState(false)
  const [prerequisite, setPrerequisite]     = useState('')

  // FORM VALIDATION STATES
  const [isNamaInvalid, setIsNamaInvalid]       = useState(false)
  const [isUrutanInvalid, setIsUrutanInvalid]   = useState(false)
  const [isSectionInvalid, setIsSectionInvalid] = useState(false)
  const [isMaxGroupValid, setIsMaxGroupValid]   = useState(true)
  const [isGraphorInValid, setIsGraphorInvalid] = useState(false)
  const [toBeScore, setToBeScore]   = useState({
    isScore: false,
    isKeterampilan: false,
    isPengetahuan: false,
    selectedTypeScore: '',
    isTypeScoreActive: true,
  })
  const [selectedStudents, setSelectedStudents] = useState([])

  /* =========================================== Refs =========================================== */
  const refInputName     = useRef()
  const refSelectSection = useRef()
  const refInputUrutan   = useRef()
  const refMaxGroup      = useRef()
  const refGraphor       = useRef()

  function getExistingContentsBySelectedSection() {
    if (!sectionId) {
      setExistingContents([])
    } else {
      sections.forEach((section) => {
        if (section.id === sectionId) {
          setExistingContents(section.contents)
        }
      })
    }
  }

  async function getGraphorList() {
    const response = await getRequest('graphors?status=active')
    if (response) {
      if (response.data.length !== 0) {
        initGroupedOptions(response.data)
      }
    } else {
      console.error('ERROR', response)
    }
  }

  function initGroupedOptions(graphors) {
    let sorting = []
    let grouping = []

    graphors.forEach((graphor) => {
      if (graphor.type === 'sorting') {
        sorting.push({ value: graphor.id, label: graphor.description })
      }
      if (graphor.type === 'group') {
        grouping.push({ value: graphor.id, label: graphor.description })
      }
    })

    setGraphors([
      { label: 'Sorting', options: sorting },
      { label: 'Grouping', options: grouping },
    ])
  }

  async function getFeedsList() {
    const response = await getRequest('feed')
    if (response) {
      const options = response.data.map((item) => ({
        value: item.id,
        label: item.description,
      }))
      setFeeds(options)
    } else {
      console.error('ERROR', response)
    }
  }

  function handleChangeGraphor(value) {
    if (value) {
      setGraphor(value)
      setIsGraphorInvalid(false)
    } else {
      setGraphor(null)
    }
  }

  function handleChangeEnableGroupKey() {
    if (enableGroupKey) {
      setEnableGroupKey(false)
    } else {
      setEnableGroupKey(true)
    }
  }

  function handleChangeName(event) {
    setNamaKonten(event.target.value)
  }

  function handleChangeUrutan(event) {
    setUrutan(Math.abs(event.target.value))
  }

  function handleChangeSection(event) {
    setSectionId(event.target.value)
  }

  function handleChangePrecontent(event) {
    setPrecontentId(event.target.value)
  }

  function handleChangeDate(event) {
    setDisplayAt(event.target.value)
  }

  function handleChangeActive() {
    if (active === 1) {
      setActive(0)
    }
    if (active === 0) {
      setActive(1)
    }
  }

  function handleChangeFeedback(val) {
    setFeedId(val)
  }

  function handleChangeEnableGroup() {
    if (enableGroup) {
      setEnableGroup(false)
      setMaxGroup('')
      setIsMaxGroupValid(true)
    } else {
      setEnableGroup(true)
      setIsMaxGroupValid(false)
    }
  }

  function handleChangeMaxGroup(event) {
    if (event.target.value) {
      const absolute = Math.abs(event.target.value)
      const integer = Math.round(absolute)
      setMaxGroup(integer)
      if (integer <= 0) {
        setIsMaxGroupValid(false)
      } else {
        setIsMaxGroupValid(true)
      }
    } else {
      setMaxGroup('')
      setIsMaxGroupValid(false)
    }
  }

  function handleChangeDescGroup(event) {
    setDescGroup(event.target.value)
  }

  function handleChangeDesc(event) {
    setDescription(event.target.value)
  }

  function handleChangeEndedAt(event) {
    setEndedAt(event.target.value)
  }

  function handleChangeApresiasi(event) {
    setApresiasi(event.target.value)
  }

  function handleChangeRating(value) {
    setRating(value ? 1 : 0)
  }

  function toBeScoreValidation() {
    if (toBeScore.isScore) {
      return (toBeScore.isKeterampilan === true || toBeScore.isPengetahuan === true) && toBeScore.selectedTypeScore
    }

    return true
  }

  function handleFormValidation() {
    const durasiDetik = durasi * 60
    const feedback = isFeedActive === 1 ? feedId : ''

    if (namaKonten && urutan && sectionId && graphor && isMaxGroupValid && toBeScoreValidation()) {
      setIsNamaInvalid(false)
      setIsSectionInvalid(false)
      setIsUrutanInvalid(false)
      setIsGraphorInvalid(false)
      handleFormSubmit(
        namaKonten,
        contentType,
        sectionId,
        urutan,
        active,
        displayAt,
        preContentId,
        graphor.value,
        feedback.value,
        durasiDetik,
        maxGroup,
        descGroup,
        endedAt,
        description,
        apresiasi,
        rating,
      )
    } else {
      if (!isMaxGroupValid) {
        if (refMaxGroup) {
          refMaxGroup.current.focus()
        }
      }

      if (!sectionId) {
        setIsSectionInvalid(true)
        refSelectSection.current.focus()
      } else {
        setIsSectionInvalid(false)
      }

      if (!urutan) {
        setIsUrutanInvalid(true)
        refInputUrutan.current.focus()
      } else {
        setIsUrutanInvalid(false)
      }

      if (!graphor) {
        setIsGraphorInvalid(true)
        refGraphor.current.focus()
      } else {
        setIsGraphorInvalid(false)
      }

      if (!namaKonten) {
        setIsNamaInvalid(true)
        refInputName.current.focus()
      } else {
        setIsNamaInvalid(false)
      }

      window.notification('Data belum lengkap', 'Mohon lengkapi data')

      if (!toBeScoreValidation()) {
        window.notification('Mohon', 'lengkapi juga data "Jadikan Sebagai Penilaian"')
      }
    }
  }

  async function handleFormSubmit(
    namaKonten,
    contentType,
    sectionId,
    urutan,
    active,
    displayAt,
    preContentId,
    contentsUrl,
    feedId,
    durasi,
    maxGroup,
    descGroup,
    endedAt,
    description,
    apresiasi,
    rating,
  ) {
    let form = new FormData()
    form.append('title', namaKonten)
    form.append('content_type', contentType)
    form.append('section_id', sectionId)
    form.append('urutan', urutan)
    form.append('active', active)
    form.append('contents_url', contentsUrl)
    // form.append("durasi", durasi);
    form.append('rating', rating)

    form.append('is_score', !!toBeScore.isScore)

    if (toBeScore.isScore) {
      form.append('is_keterampilan', !!toBeScore.isKeterampilan)
      form.append('is_pengetahuan', !!toBeScore.isPengetahuan)
      form.append('type_score_id', toBeScore.selectedTypeScore)
    }

    if (Array.isArray(selectedStudents) && !!selectedStudents.length) {
      selectedStudents.forEach((selectedStudent, selectedStudentIndex) => {
        form.append(`spesifik_user_id[${selectedStudentIndex}]`, selectedStudent.id)
      })
    }

    if (displayAt) {
      form.append('display_at', displayAt)
    }

    if (endedAt) {
      form.append('ended_at', endedAt)
    }

    if (description) {
      form.append('deskripsi', description)
    }

    if (preContentId) {
      form.append('precontent_id', preContentId)
    }

    if (feedId) {
      form.append('feedback', feedId)
    }

    if (enableGroup) {
      form.append('group_status', 1)
      if (maxGroup) {
        form.append('group_max', maxGroup)
      }
      if (descGroup) {
        form.append('group_description', descGroup)
      }
    }

    if (apresiasi) {
      form.append('apresiasi', apresiasi)
    }

    const response = await postRequest('contents', form)
    if (response) {
      if (enableGroupKey) {
        let formkey = new FormData()
        formkey.append('description', prerequisite)
        formkey.append('content_id', response.data.id)

        const response1 = await postRequest('contentTakeAway', formkey)
        if (response1) {
          window.notification('Materi', 'berhasil ditambahkan', 'success')
          history.push(`/kelas/${location.state.kelasId}`)
        }
      } else {
        window.notification('Materi', 'berhasil ditambahkan', 'success')
        history.push(`/kelas/${location.state.kelasId}`)
      }
    } else {
      console.error('ERROR', response)
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getExistingContentsBySelectedSection()
  }, [sectionId])

  useEffect(() => {
    getGraphorList()
    getFeedsList()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Tambah Graphic Organizer"
          linkName="Graphic Organizer"
          links={links}
        />
        
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <h5>
                  <i className="fas fa-object-group mr-1" />
                  Informasi Graphic Organizer
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <form>
                      {/* Input Nama Konten */}
                      <FormInputName
                        label="Nama Graphic Organizer"
                        placeholder="Nama Graphic Organizer"
                        namaKonten={namaKonten}
                        handleChangeName={handleChangeName}
                        isNamaInvalid={isNamaInvalid}
                        refInputName={refInputName}
                      />

                      <FormSelectGraphor
                        refSelect={refGraphor}
                        graphor={graphor}
                        graphorList={graphors}
                        getList={getGraphorList}
                        handleChange={handleChangeGraphor}
                        isInvalid={isGraphorInValid}
                      />

                      {/* Input Urutan */}
                      <FormInputUrutan
                        urutan={urutan}
                        handleChangeUrutan={handleChangeUrutan}
                        isUrutanInvalid={isUrutanInvalid}
                        refInputUrutan={refInputUrutan}
                      />

                      {/* Input deskripsi */}
                      <FormInputDesc
                        description={description}
                        handleChangeDesc={handleChangeDesc}
                      />

                      {/* Input Apresiasi */}
                      <FormTextArea
                        id="formInputApresiasi"
                        label="Apresiasi"
                        placeholder="Apresiasi/pesan setelah siswa menyelesaikan materi (opsional)"
                        className="mb-5"
                        value={apresiasi}
                        handleChange={handleChangeApresiasi}
                      />

                      {/* Select Section */}
                      <FormSelectSection
                        sections={sections}
                        handleChangeSection={handleChangeSection}
                        isSectionInvalid={isSectionInvalid}
                        refSelectSection={refSelectSection}
                      />

                      {/* Select Precontent */}
                      <FormSelectPrecontent
                        existingContents={existingContents}
                        handleChangePrecontent={handleChangePrecontent}
                      />

                      {/* Input Tanggal Ditampilkan */}
                      <FormInputTanggal
                        existingDisplayAt={displayAt}
                        handleChangeDate={handleChangeDate}
                      />

                      {/* Input ended at */}
                      <FormInputEndedAt
                        endedAt={endedAt}
                        handleChangeEndedAt={handleChangeEndedAt}
                      />

                      {/* Input durasi */}
                      {/* <FormDurasi 
                        existingDurasi={durasi}
                        handleChangeDurasi={handleChangeDurasi}
                      /> */}

                      {/* Rating */}
                      <FormSwitch
                        id="formRating"
                        label="Rating Materi"
                        value={rating}
                        handleChange={handleChangeRating}
                      />

                      {/* Select feedback */}
                      <FormSelectFeedback
                        existingFeeds={feeds}
                        existingFeedId={feedId}
                        isFeedActive={isFeedActive}
                        setIsFeedActive={setIsFeedActive}
                        handleChangeFeeds={handleChangeFeedback}
                        getFeedList={getFeedsList}
                      />

                      {/* Swicth Group Kecil */}
                      <FormGroupKecil
                        enableGroup={enableGroup}
                        handleChangeEnableGroup={handleChangeEnableGroup}
                        maxGroup={maxGroup}
                        descGroup={descGroup}
                        handleChangeMaxGroup={handleChangeMaxGroup}
                        handleChangeDescGroup={handleChangeDescGroup}
                        isMaxGroupValid={isMaxGroupValid}
                        refMaxGroup={refMaxGroup}
                      />

                      {/* Select Specific Students */}
                      <FormSelectStudent 
                        selectedStudents={selectedStudents}
                        setSelectedStudents={setSelectedStudents}
                        subMapelId={params?.kelasId}
                        isWithCheckSavedOrNot={false}
                      />

                      {/* Select To Be Score Or Not */}
                      <FormSetToBeScore
                        value={toBeScore}
                        handleChange={setToBeScore}
                      />

                      {/* Select Status */}
                      <FormActiveStatus
                        existingStatus={active}
                        handleChangeStatus={handleChangeActive}
                      />

                      <FormActiveKey
                        enableGroupKey={enableGroupKey}
                        handleChangeEnableGroupKey={handleChangeEnableGroupKey}
                        prerequisite={prerequisite}
                        setPrerequisite={setPrerequisite}
                      />

                      <div className="form-group">
                        <BackButton />
                        <SubmitButton
                          onClick={() => handleFormValidation()}
                          text="Simpan"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

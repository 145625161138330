import React from 'react'

export default function EmptySection() {
  return (
    <div className="mb-0">
      <div
        style={{
          backgroundColor: '#f8f9fa',
        }}
      >
        <h5 className="row m-0 border-bottom border-top">
          <a
            type="button"
            className="col row collapsed text-dark p-3"
            data-toggle="collapse"
          >
            <div className="col-2 col-lg-1">
              <i className="fas fa-chevron-down text-muted" />
            </div>
            <div className="col-10 col-lg-9">
              <span className="f-16 text-muted">Konten belum tersedia.</span>
            </div>
            <span
              className="col text-right"
              style={{ fontWeight: 'normal' }}
            >
              0 konten
            </span>
          </a>
        </h5>
      </div>
      <div
        className="collapse"
        data-parent="#konten-accordion"
      >
        <ul className="list-unstyled mb-0">
          <p className="text-muted p-3 mb-0">Belum ada konten.</p>
        </ul>
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Select from 'react-select'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import RaporTable from './components/rapor-table'
import loadingTypes from 'globals/loading-types'

export default function InputRaporPeriod() {
  /* ========================================== Helpers ========================================= */
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [classes, setClasses] = useState([])
  const [classesSelect, setClassesSelect] = useState(null)
  const [block, setBlock] = useState([])
  const [blockSelect, setBlockSelect] = useState(null)
  const [showBlock, setShowBlock] = useState(false)
  const [taTahunCode, setTaTahunCode] = useState()

  const [categories, setCategories] = useState([])
  const [categorySelect, setCategorySelect] = useState(null)
  const [groups, setGroups] = useState([])
  const [groupSelect, setGroupSelect] = useState(null)
  const [semester, setSemester] = useState([])
  const [semesterSelect, setSemesterSelect] = useState()
  const [loadingSearch, setLoadingSearch] = useState(loadingTypes.idle)  // idle / start / success / error
  const [showMapel, setShowMapel] = useState(false)
  const [showKelas, setShowKelas] = useState(false)

  /* ========================================= Functions ======================================== */
  const getDataOptions = async (url = '') => {
    const response = await getRequest(url)
    if (response) return response.data
    return []
  }

  const handleChangeClasses = async (value) => {
    setClassesSelect(value)
    let searchQuery = ''
    if (value) {
      searchQuery = `category_id=${value.category}&classes_id=${value.value}&`
    }
    if (query.get('group_id')) {
      searchQuery = `${searchQuery}&group_id=${query.get('group_id')}&`
    }
    if (query.get('semester_code')) {
      searchQuery = `${searchQuery}semester_code=${query.get('semester_code')}&`
    }
    if (query.get('taTahun')) {
      searchQuery = `${searchQuery}taTahun=${query.get('taTahun')}&`
    }
    if (query.get('period_id')) {
      searchQuery = `${searchQuery}period_id=${query.get('period_id')}`
    }
    history.push(`/input-rapor-period?${searchQuery}`)
  }

  const handleChangeBlock = async (value) => {
    if (value?.value) {
      setBlockSelect(value)
      let searchQuery = ''
      if (query.get('category_id')) {
        searchQuery = `category_id=${query.get('category_id')}&`
      }
      if (query.get('classes_id')) {
        searchQuery = `${searchQuery}classes_id=${query.get('classes_id')}&`
      }
      if (query.get('semester_code')) {
        searchQuery = `${searchQuery}semester_code=${query.get('semester_code')}&`
      }
      if (query.get('taTahun')) {
        searchQuery = `${searchQuery}taTahun=${query.get('taTahun')}&`
      }
      if (value?.value) {
        searchQuery = `${searchQuery}period_id=${value.value}`
      }
      if (query.get('group_id')) {
        searchQuery = `${searchQuery}&group_id=${query.get('group_id')}`
      }
      await initDropdownSelect(
        `groups-user-active-period?period_id=${value?.value
        }&ta_semester_code=${query.get('semester_code')}`,
        'group',
      )
      history.push(`/input-rapor-period?${searchQuery}`)
    }
  }

  const handleChangeGroup = async (value) => {
    if (value?.value) {
      setGroupSelect(value)
      let searchQuery = ''
      if (query.get('category_id')) {
        searchQuery = `category_id=${query.get('category_id')}&`
      }
      if (query.get('classes_id')) {
        searchQuery = `${searchQuery}classes_id=${query.get('classes_id')}&`
      }
      if (query.get('semester_code')) {
        searchQuery = `${searchQuery}semester_code=${query.get('semester_code')}&`
      }
      if (query.get('taTahun')) {
        searchQuery = `${searchQuery}taTahun=${query.get('taTahun')}&`
      }
      if (query.get('period_id')) {
        searchQuery = `${searchQuery}period_id=${query.get('period_id')}`
      }
      if (value) {
        searchQuery = `${searchQuery}&group_id=${value.value}`
      }
      await initDropdownSelect(
        'katalog-kelas?semester_code=' +
        semesterSelect?.value +
        '&period_id=' +
        blockSelect?.value +
        '&group_id=' +
        value.value,
        'subMapel',
      )
      history.push(`/input-rapor-period?${searchQuery}`)
    }
  }

  const handleChangeSemester = async (value) => {
    setTaTahunCode(value.taTahun)
    if (query.get('period_id')) {
      await initDropdownSelect(
        `reportPeriod?period_id=${query.get('period_id')}&semester_code=${value.value ? value.value : ''
        }`,
        'block',
      )
      setSemesterSelect(value)
      let searchQuery = ''
      if (query.get('period_id'))
        searchQuery = `period_id=${query.get('period_id')}&`
      if (value)
        searchQuery = `${searchQuery}semester_code=${value.value}&taTahun=${value.taTahun}`
      history.push(`/input-rapor-period?${searchQuery}`)
    } else {
      await initDropdownSelect(
        `reportPeriod?semester_code=${value.value ? value.value : ''}`,
        'block',
      )
      setSemesterSelect(value)
      let searchQuery = ''
      if (query.get('period_id'))
        searchQuery = `period_id=${query.get('period_id')}&`
      if (value)
        searchQuery = `${searchQuery}semester_code=${value.value}&taTahun=${value.taTahun}`
      history.push(`/input-rapor-period?${searchQuery}`)
    }
  }

  const initSelectedItem = (array = [], id, type = '') => {
    for (let item of array) {
      if (item.value == id) {
        if (type === 'category')
          setCategorySelect({ label: item.label, value: id })
        if (type === 'block') setBlockSelect({ label: item.label, value: id })
        if (type === 'group') setGroupSelect({ label: item.label, value: id })
        break
      }
    }
  }

  const initDropdownSelect = async (url, type) => {
    const array = await getDataOptions(url)
    let options
    if (type == 'block') {
      options = array.map((item) => ({
        label: item.name_period,
        value: item.id,
      }))
    } else if (type == 'subMapel') {
      options = array.map((item) => ({
        label: item.class.title,
        value: item.class.id,
        category: item.class.category_id,
      }))
    } else {
      options = array.map((item) => ({
        label: item.title,
        value: item.id,
      }))
    }
    const renderSemester = array.map((val) => ({
      label: `${val.tahun_mulai}/${val.tahun_selesai} - ${val.title_render} ${val.status ? '(Aktif)' : ''
        }`,
      value: val.code,
      taTahun: val.ta_tahun_code,
    }))

    switch (type) {
      case 'block':
        options = [{ label: 'Pilih Block', value: '' }, ...options]
        setBlock(options)
        if (query.get('period_id'))
          initSelectedItem(options, query.get('period_id'), type)
        break
      case 'subMapel':
        setClasses(options)
        if (query.get('classes_id'))
          initSelectedItem(options, query.get('classes_id'), type)
        break
      case 'group':
        setGroups(options)
        if (query.get('group_id'))
          initSelectedItem(options, query.get('group_id'), type)
        break
      case 'semester':
        setSemester(renderSemester)
        if (query.get('semester_id'))
          initSelectedItem(renderSemester, query.get('semester_id'), type)
        break
      default:
        setGroups([])
        setCategories([])
        setSemester([])
        break
    }
  }

  const init = async () => {
    await initDropdownSelect('semester', 'semester')
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (semesterSelect) {
      setShowBlock(true)
    }

    if (blockSelect) {
      setShowKelas(true)
    }

    if (groupSelect) {
      setShowMapel(true)
    }
  }, [semesterSelect, groupSelect, classesSelect, blockSelect])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Input Rapor Sub Mata Pelajaran" />

        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3 mb-3 mb-md-0">
                <span className="f-w-700 f-14">Semester</span>
                <Select
                  className="mt-2"
                  placeholder="Pilih semester"
                  isMulti={false}
                  isDisabled={
                    loadingSearch === loadingTypes.start
                  }
                  onChange={handleChangeSemester}
                  value={semesterSelect}
                  options={semester}
                />
              </div>
              {showBlock && (
                <div className="col-md-3 mb-3 mb-md-0">
                  <span className="f-w-700 f-14">Block</span>
                  <Select
                    className="mt-2"
                    placeholder="Pilih Block"
                    isMulti={false}
                    isDisabled={
                      loadingSearch === loadingTypes.start
                    }
                    value={blockSelect}
                    options={block}
                    onChange={handleChangeBlock}
                  />
                </div>
              )}
              {showKelas && (
                <div className="col-md-3 mb-3 mb-md-0">
                  <span className="f-w-700 f-14">Kelas</span>
                  <Select
                    className="mt-2"
                    placeholder="Pilih kelas"
                    isMulti={false}
                    isDisabled={
                      loadingSearch === loadingTypes.start
                    }
                    value={groupSelect}
                    options={groups}
                    onChange={handleChangeGroup}
                  />
                </div>
              )}
              {showMapel && (
                <div className="col-md-3 mb-3 mb-md-0">
                  <span className="f-w-700 f-14">Sub Mata Pelajaran</span>
                  <Select
                    className="mt-2"
                    placeholder="Pilih sub mata pelajaran"
                    isMulti={false}
                    isDisabled={
                      loadingSearch === loadingTypes.start
                    }
                    value={classesSelect}
                    options={classes}
                    onChange={handleChangeClasses}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <RaporTable
          loadingTypes={loadingTypes}
          taTahun={query.get('taTahun')}
          category_id={query.get('category_id')}
          group_id={query.get('group_id')}
          semester_id={query.get('semester_code')}
          class_id={query.get('classes_id')}
          period_id={query.get('period_id')}
          subMapelName={classesSelect}
          loadingSearch={loadingSearch}
          setLoadingSearch={setLoadingSearch}
        />
      </Container>
    </TemplateAdmin>
  )
}

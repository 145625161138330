import React, { useEffect, useState } from 'react'
import { useBaseContext } from 'context/base'
import LoadingSpinner from 'component/loading'
import ErrorUI from 'component/error-ui'
import RaporSkeleton from './rapor-skeleton'
import RaporSuccess from './rapor-success'
import useInputKindness from 'context/input-kindness'
import debounce from 'lodash.debounce'

const RaporTable = ({
  loadingTypes,
  category_id,
  group_id,
  loadingSearch,
  setLoadingSearch,
  semester_id,
  semester,
  kelas,
}) => {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()
  const { data } = useInputKindness();

  /* ========================================= Constants ======================================== */
  const tableConfig = {
    headers: [
      'No.',
      'Nama',
      'Status',
      'Catatan',
      'Actions',
    ],
    totalColumns: 5,
  }
  const show = [10, 20, 30, 40, 50]

  /* ======================================= Local States ======================================= */
  const [result, setResult] = useState(null)
  const [search, setSearch] = useState('')
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(show[0])
  const [start, setStart] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  const [activePage, setActivePage] = useState(1)

  /* ========================================= Functions ======================================== */
  async function getSiswaByCategory(category_id, group_id, semester_id) {
    if (group_id) {
      const response = await getRequest(
        `report-p5/${group_id}/${semester_id}/list-siswa?limit=${limit}&offset=${offset}&search=${search}`,
      )
      return response ? response.data : null
    } else {
      return null
    }
  }

  async function handleSearch(category_id, group_id, semester_id) {
    setLoadingSearch(loadingTypes.start)
    const data = await getSiswaByCategory(category_id, group_id, semester_id)
    setResult(data)
    setLoadingSearch(data === null ? loadingTypes.error : loadingTypes.success)
  }

  /* -------------------------------- HANDLERS -------------------------------- */
  const debouncedSearch = debounce(async (value) => {
    setStart(0)
    setSearch(value)
  }, 600);

  const onSearchHandler = (val) => {
    debouncedSearch(val.target.value)
  }

  /* --------------------------------- RENDERS -------------------------------- */
  function RenderPagination() {
    var sisa = totalPage - activePage
    var kanan = sisa >= 1 ? activePage + 1 : 0
    var kiri = activePage === 1 ? 0 : activePage - 1

    return (
      <ul className="pagination justify-content-end">
        {activePage === 1 ? (<li className="page-item disabled"><button className="page-link" type="button">Previous</button></li>) : <li className="page-item" onClick={() => {
          var a = activePage
          var k = --a
          setActivePage(k)
          return setOffset((k * limit) - limit)
        }}><button className="page-link" type="button">Previous</button></li>}
        {kiri > 0 ? (<li className="page-item"><button onClick={() => {
          setActivePage(kiri)
          return setOffset((kiri * limit) - limit)
        }} className="page-link" type="button">{kiri}</button></li>) : null}

        <li className="page-item active"><span className="page-link">{activePage}<span className="sr-only">(current)</span></span>
        </li>

        {kanan > 0 ? (<li className="page-item"><button onClick={() => {
          setActivePage(kanan)
          return setOffset((kanan * limit) - limit)
        }} className="page-link" type="button">{kanan}</button></li>) : null}


        {activePage === totalPage ? (<li className="page-item disabled"><button className="page-link" type="button">Next</button></li>) : (<li className="page-item" onClick={() => {
          var a = activePage
          var k = ++a
          setActivePage(k)
          return setOffset((k * limit) - limit)
        }}><button className="page-link" type="button">Next</button></li>)}
      </ul>
    )

  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (group_id) {
      handleSearch(category_id, group_id, semester_id)
    }
  }, [category_id, group_id, semester_id, search, limit, offset])

  useEffect(() => {
    if (data.triggerGetStudentScores) {
      handleSearch(category_id, group_id, semester_id)
    }
  }, [data.triggerGetStudentScores])

  /* ========================================== Output ========================================== */
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col">
            <div className="text-secondary">
              <span>
                <i className="fas fa-graduation-cap mr-1" />
                {semester?.label || '-'}
              </span>
              <span className="ml-3">
                <i className="fas fa-user-graduate mr-1" />
                {result?.users.length || '-'}
                {' siswa'}
              </span>
              {kelas?.label ? (
                <span className="ml-3">
                  <i className="fas fa-user-friends mr-1" />
                  Kelas: {kelas.label || 0}
                </span>
              ) : null}
            </div>
            <hr />
          </div>
        </div>
        <div className="row justify-content-between mb-4 ml-2 mr-2">
          <div>
            <select onChange={(val) => {
              setLimit(val.target.value)
              setStart(0)
              // setData('')
              return setLimit(val.target.value)
            }} id="inputState" className="form-control">
              {show.map((val, i) => (
                <option key={i} value={val}>{val}</option>
              ))}
            </select>
          </div>
          <div>
            <input type="text" onChange={onSearchHandler} className="form-control" placeholder="Search" />
          </div>
        </div>
        {loadingSearch === loadingTypes.idle && (
          <RaporSkeleton
            tableConfig={tableConfig}
            title="Pilih Mata Pelajaran"
            bodyPlaceholder="Pilih kelas terlebih dahulu"
          />
        )}
        {loadingSearch === loadingTypes.start && (
          <RaporSkeleton
            tableConfig={tableConfig}
            title="Memuat..."
            bodyPlaceholder={<LoadingSpinner />}
          />
        )}
        {loadingSearch === loadingTypes.error && (
          <RaporSkeleton
            tableConfig={tableConfig}
            title="Terjadi Kesalahan :("
            bodyPlaceholder={<ErrorUI />}
          />
        )}
        {loadingSearch === loadingTypes.success && (
          <RaporSuccess
            tableConfig={tableConfig}
            result={result}
            semester_id={semester_id}
            category_id={category_id}
            group_id={group_id}
            handleSearch={handleSearch}
            semester={semester}
            kelas={kelas}
            setSearch={setSearch}
            setOffset={setOffset}
            setLimit={setLimit}
            setStart={setStart}
            setTotalPage={setTotalPage}
            setActivePage={setActivePage}
            limit={limit}
            start={start}
            totalPage={totalPage}
            activePage={activePage}
          />
        )}

        <div className="row justify-content-between mt-4 ml-2 mr-2">
          <div>
            Showing {((activePage - 1) * limit) + 1} to {(activePage * limit) >= result?.users?.length ? result?.users?.length : activePage * limit} of {result?.users?.length} entries
          </div>
          <div>
            <RenderPagination />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RaporTable

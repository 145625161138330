import { createContext, useContext, useEffect, useState } from 'react'
import { useBaseContext } from 'context/base'
import { messaging, getToken, firebaseApp } from 'util/firebase'
import { blockedNotifPopUp } from 'util/global'

const ChatContext = createContext()

export function ChatContextProvider({ children }) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()

  /* ========================================== States ========================================== */
  const [contactList, setContactList]     = useState([])
  const [messageList, setMessageList]     = useState([]) // states untuk semua chat
  const [chatToDisplay, setChatToDisplay] = useState(null) // states untuk chat yg dilihat
  const [isChatExist, setIsChatExist]     = useState(false)
  const [reply, setReply]                 = useState(null)

  /* ====================================== Loading States ====================================== */
  const [loadingCurrent, setLoadingCurrent]   = useState(false)
  const [loadingOlderMsg, setLoadingOlderMsg] = useState(false)
  const [loadingNewerMsg, setLoadingNewerMsg] = useState(false)

  /* ========================================= Functions ======================================== */
  async function getMessageList() {
    const response = await getRequest('chat-list')
    response && setMessageList(response.data)
  }

  async function getContactList() {
    const response = await getRequest('chat-siswa-contact')
    response && setContactList(response.data)
  }

  function checkIsChatExist() {
    if (messageList.length !== 0) {
      let _isChatExist = false

      if (chatToDisplay.type === 'group') {
        messageList.forEach((message) => {
          if (message.id === chatToDisplay.id) {
            _isChatExist = true
          }
        })
      } else if (chatToDisplay.type === 'siswa') {
        messageList.forEach((message) => {
          if (message.type === 'siswa') {
            message.participants.forEach((participant) => {
              if (participant.id === chatToDisplay.id) {
                _isChatExist = true
              }
            })
          }
        })
      }
      setIsChatExist(!!_isChatExist)
    }
  }

  async function initNotification() {
    // Check firebase compatibility
    if (firebaseApp.messaging.isSupported()) {
      if (!('Notification' in window)) {
        console.error('This browser does not support desktop notification')
      } else {
        const permit = Notification.permission
        switch (permit) {
          case 'granted':
            if (localStorage.getItem('firebase_token') === null) {
              await initToken()
              break
            } else {
              console.error('User fb token already registered.')
              break
            }
          case 'denied':
            blockedNotifPopUp()
            break
          case 'default':
            reqNotifPopUp()
            break
          default:
            break
        }
      }
    } else {
      // Notify notification not supported
      window.$.notify(
        {
          icon: 'feather icon-alert-circle',
          title: 'Pemberitahuan',
          message: 'Browser yang anda gunakan tidak mendukung fitur notifikasi',
        },
        {
          element: 'body',
          type: 'success',
          allow_dismiss: true,
          spacing: 10,
          z_index: 999999,
          delay: 0,
          url_target: '_blank',
          mouse_over: false,
          remove: true,
          placement: {
            from: 'top',
            align: 'center',
          },
          template: `
          <div
            data-notify = "container"
            class       = "bg-light border col-xs-11 col-sm-3 alert alert-{0}"
            role        = "alert"
          >
            <button
              type        = "button"
              aria-hidden = "true"
              class       = "close text-dark"
              data-notify = "dismiss"
            >
              ×
            </button>
            <span
              data-notify = "icon"
              class       = "text-warning mr-1"
            ></span>
            <span
              data-notify = "title"
              class       = "text-dark f-14 f-w-700"
            >
              {1}
            </span>
            <div
              data-notify = "message"
              class       = "text-dark"
            >
              {2}
            </div>
          </div>        
          `,
        },
      )
    }
  }

  async function reqNotifPopUp() {
    const container = document.createElement('div')
    container.innerHTML = `
        <h3 class="text-dark">Akses Notifikasi</h3>
        <i class="fas fa-bell text-warning f-64 my-2"></i>
        <p class="f-16 mb-0">LMS SMA Pintar membutuhkan akses notifikasi, izinkan?</p>
    `
    window
      .swal({
        content: container,
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ['Lain kali', 'Izinkan'],
      })
      .then((permit) => {
        if (permit) {
          const overlayElement = document.createElement('div')
          overlayElement.innerHTML = `
            <div class="notif-overlay">
              <div class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
        `
          document.body.append(overlayElement)
          Notification.requestPermission().then(async (permit) => {
            if (permit === 'granted') {
              await initToken()
              document.querySelector('.notif-overlay')?.remove()
            } else if (permit === 'denied') {
              document.querySelector('.notif-overlay')?.remove()
              blockedNotifPopUp()
            } else {
              document.querySelector('.notif-overlay')?.remove()
            }
          })

          setTimeout(() => document.querySelector(".notif-overlay")?.remove(), 500)
        }
      })
  }

  async function initToken() {
    const fbToken = await getToken()
    if (fbToken) {
      localStorage.setItem('firebase_token', fbToken)
      postRequest('firebase-token', {
        fb_token_web: fbToken,
      })
    }
  }

  function pushToMessageList(chatId, newChat) {
    const _messageList    = [...messageList]
    
    const findMessageIdxById = (id) => _messageList.findIndex(_ml => _ml._id === id)
    const chatToAppendIdx    = findMessageIdxById(chatId)

    const findMessageById = (id) => _messageList.find(_ml => _ml._id === id)
    const chatToAppend    = findMessageById(chatId)

    if (chatToAppend) {
      Array.isArray(newChat)
        ? newChat.forEach((nc) => chatToAppend.chats.push(nc))
        : chatToAppend.chats.push(newChat)
      
      _messageList.splice(chatToAppendIdx, 1) // Reorder _messageList
      _messageList.unshift(chatToAppend)
      setMessageList(_messageList)
    }
  }

  function deleteFromMessageList(chatId, chatItemId) {
    const _messageList          = [...messageList]
    const findMessageById       = (id) => _messageList.find(_ml => _ml._id === id)
    const _chatToModify         = findMessageById(chatId)
    const chatItemToDeleteIndex = _chatToModify.chats.findIndex(_ch => _ch._id === chatItemId)
    _chatToModify.chats.splice(chatItemToDeleteIndex, 1)
    
    setMessageList(_messageList)
  }

  function unshiftToMessageList(chatId, newChats) {
    const _messageList    = [...messageList]
    const findMessageById = (id) => _messageList.find(_ml => _ml._id === id)
    const chatToUnshift   = findMessageById(chatId)    
    if (chatToUnshift) {
      chatToUnshift.chats.unshift(...newChats)
      setMessageList(_messageList)
    }
  }
  
  async function getOlderRoomMessage(limit = 10) {
    const { _id, chats } = chatToDisplay
    const lastChatId    = chats.length && chats[0]._id

    const q = { _id, lastChatId, limit }
    console.table(q)
    const queries = Object.entries(q).map(([ key, value ]) => `${key}=${value}`).join('&')
    const path = 'chat-list-update?'+queries

    setLoadingOlderMsg(true)
    const response = await getRequest(path)
    setLoadingOlderMsg(false)
    
    if (response) {
      const { data } = response
      unshiftToMessageList(data._id, data.chats)
    }
  }

  async function getNewerRoomMessage() {
    const { _id, chats } = chatToDisplay
    const latestChatId   = chats.length ? chats[chats.length - 1]._id : null

    const q = { _id, latestChatId }
    const queries = Object.entries(q).map(([ key, value ]) => `${key}=${value}`).join('&')
    const path = 'chat-list-latest?'+queries

    setLoadingNewerMsg(true)
    const response = await getRequest(path)
    setLoadingNewerMsg(false)

    if (response) {
      const { data } = response
      data && pushToMessageList(data._id, data.chats)
    }
  }

  async function getNewChatRoomId(_contact) {
    const _data = { 
      id    : _contact.id,
      title : _contact.title,
      type  : _contact.type,
    }
    // NOTE: response.data => { _id, participants }
    const response = await postRequest('chat-new', _data)
    return response.data
  }

  function addNewMessageList(newChat) {
    // NOTE: 
    // newChat ==> { _id, id, title, type, participants } 
    // _id & participants dari BE via getNewChatRoomId()
    const _messageList = [...messageList]
    _messageList.unshift(newChat)
    setMessageList(_messageList)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    initNotification()
    getMessageList()
    getContactList()
  }, [])

  useEffect(() => {
    if (chatToDisplay) {
      checkIsChatExist()
    }
  }, [chatToDisplay])

  /* ================================ Firebase Messaging Listener =============================== */
  if (firebaseApp.messaging.isSupported()) {
    messaging.onMessage((payload) => {
      if (payload.data.type === 'chat') {
        const { value: chatId, newChat: unparsed } = payload.data
        const newChat = JSON.parse(unparsed)
        const { id: userId } = JSON.parse(localStorage.getItem('user'))
        userId !== payload.data.senderId && pushToMessageList(chatId, newChat)
      }
    })
  }
  else {
    console.error(`Firebase Messaging Not Supported`)
  }

  /* ======================================= Shared States ====================================== */
  const sharedStates = {
    current: {
      data  : chatToDisplay,
      set   : setChatToDisplay,
      exist : {
        is  : isChatExist,
        set : setIsChatExist,
      },
      loading: {
        status : loadingCurrent,
        set    : setLoadingCurrent,
      }
    },
    contact: {
      list: contactList,
    },
    messages: {
      list   : messageList,
      push   : pushToMessageList,
      delete : deleteFromMessageList,
      add    : addNewMessageList,
      fetch: {
        older : getOlderRoomMessage,
        newer : getNewerRoomMessage,
        id    : getNewChatRoomId,
      },
      loading: {
        older : loadingOlderMsg,
        newer : loadingNewerMsg,
      },
    },
    reply: {
      data  : reply,
      set   : setReply,
      reset : () => setReply(null)
    }
  }

  /* ====================================== Export Provider ===================================== */
  return (
    <ChatContext.Provider value={sharedStates}>{children}</ChatContext.Provider>
  )
}

/* ========================================= Export Hook ======================================== */
export default function useChatContext() {
  return useContext(ChatContext)
}

import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import LoadingSpinner from 'component/loading'
import HtmlContent from 'component/html-content'
import AssignmentSiswaItem from './assignment-siswa-item'
import DetailAssignmentModal from './detail-assignment-modal'
import './assignment-detail.css'

export default function DetailAssignmentSiswa() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const { assignmentId, userId } = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const links = [
    { url: '/kelola-assignment', name: 'Kelola Tugas' },
    { url: `/kelola-assignment/${assignmentId}`, name: 'Daftar Siswa' },
  ]

  /* ======================================= Local States ======================================= */
  const [isLoading, setIsLoading]             = useState(false)
  const [assignment, setAssignment]           = useState(null)
  const [name, setName]                       = useState('')
  const [nim, setNim]                         = useState('')
  const [answers, setAnswers]                 = useState([])
  const [assignmentModal, setAssignmentModal] = useState(null)

  /* ========================================= Functions ======================================== */
  async function getDetailAssignmentSiswa() {
    setIsLoading(true)
    const response = await getRequest(
      `assignment-siswa-answer?user_siswa_id=${userId}&assignment_id=${assignmentId}`,
    )
    setIsLoading(false)
    if (response) {
      if (response.data) {
        setAssignment(response.assignment)
        setName(response.data.name)
        setNim(response.data.nim)
        if (response.data.assigment_users.length !== 0) {
          setAnswers(response.data.assigment_users)
        } else {
          setAnswers([])
        }
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getDetailAssignmentSiswa()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Detail Tugas Siswa" links={links} />
        
        <div className="card">
          <div className="card-header">
            {isLoading ? null : (
              <>
                <p className="f-w-700 f-16 mb-0">{name ? name : '-'}</p>
                <p className="f-w-400 f-14 text-muted mb-0">
                  {'No. Induk: '}
                  {nim ? nim : 'tidak terdaftar'}
                </p>
              </>
            )}
          </div>
          <div className="card-body">
            {isLoading ? (
              <div className="row">
                <div className="col">
                  <LoadingSpinner />
                </div>
              </div>
            ) : (
              <>
                {assignment ? (
                  <>
                    <div className="row mb-3">
                      <div className="col-12">
                        <h5>{assignment.title}</h5>
                        <HtmlContent html={assignment.text} />
                      </div>
                      {assignment.pembahasan ? (
                        <div className="col-12 mb-4">
                          <div className="mb-0">
                            <div
                              id="pembahasanAcordion"
                              className="bg-light"
                            >
                              <h5 className="row m-0 border rounded">
                                <a
                                  type="button"
                                  className="col row collapsed text-dark m-0 p-3"
                                  data-toggle="collapse"
                                  data-target={'#pembahasanItem'}
                                  aria-expanded="false"
                                  aria-controls={'pembahasanItem'}
                                >
                                  <div className="col-10 col-lg-11 pl-0">
                                    <span className="f-16">Pembahasan</span>
                                  </div>
                                  <div className="col-2 col-lg-1 d-flex justify-content-end pr-0">
                                    <i className="fas fa-chevron-down" />
                                  </div>
                                </a>
                              </h5>
                            </div>
                            <div
                              id={'pembahasanItem'}
                              className="collapse border p-3"
                              aria-labelledby={'pembahasanAcordion'}
                              data-parent="#pembahasanAcordion"
                            >
                              <HtmlContent html={assignment.pembahasan} />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="row">
                      {answers.length !== 0 ? (
                        <div className="col-12 dt-responsive table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Nama Materi</th>
                                <th>Nilai</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {answers.map((answer, index) => (
                                <AssignmentSiswaItem
                                  key={index}
                                  answer={answer}
                                  setAssignmentModal={setAssignmentModal}
                                  getDetailAssignmentSiswa={
                                    getDetailAssignmentSiswa
                                  }
                                />
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="col d-flex flex-column justify-content-center">
                          <i
                            className="fas fa-pencil-ruler f-60 text-center mb-3"
                            style={{
                              color: '#ccc',
                            }}
                          />
                          <h6
                            className="text-center"
                            style={{
                              color: '#ddd',
                            }}
                          >
                            Siswa belum mengumpulkan tugas
                          </h6>
                        </div>
                      )}
                    </div>
                    {assignmentModal ? (
                      <DetailAssignmentModal
                        setAssignmentModal={setAssignmentModal}
                        answerId={assignmentModal.id}
                        answer={assignmentModal}
                        replyType={assignment.reply_type}
                        assignmentTitle={assignment.title}
                        siswaName={name}
                        siswaNim={nim}
                      />
                    ) : null}
                  </>
                ) : null}
              </>
            )}
          </div>

          <div className="card-footer">
            <button
              onClick={() => {
                history.goBack()
              }}
              type="button"
              className="btn btn-outline-danger"
            >
              <i className="fas fa-chevron-left mr-2" />
              <span>Kembali</span>
            </button>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

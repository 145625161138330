import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import BaseSelect from 'component/select/BaseSelect'
import { useGlobalGradeHandlerHook } from 'pages/kelas/overview-grade/hooks/useGlobalGradeHandlerHook'
import { useEffect, useState } from 'react'
import { editorConfiguration } from 'util/ckeditor-config'

/**
 * Form Input Materi Props
 * 
 * @param {BaseContentFormProps} props
 */
export default function FormInputGrade({ 
  value, 
  setValue, 
  isInvalid, 
  isRequired, 
  kelasId, 
  errorMessage,
  isInitSelectedValueFromFormSelect = false,
}) {
  const { handler } = useGlobalGradeHandlerHook()
  const [optionsFilterGradeFormatData, setOptionsFilterGradeFormatData] = useState([])

  const getListGradesContentHandler = async () => {
    const response = await handler.getListGradesContentHandler({ search: '', limit: 9999, offset: 0, kelasId })

    if (response) {
      const firstData = response?.optionsFilterGradeFormatData?.[0]
      setOptionsFilterGradeFormatData(response?.optionsFilterGradeFormatData)

      if (isInitSelectedValueFromFormSelect && firstData) {
        setValue(firstData)
      }
    }
  }

  useEffect(() => {
    if (kelasId) {
      getListGradesContentHandler()
    }
  }, [kelasId])
  return (
    <div className="form-group mb-5 row">
      <label className="col-sm-3 col-form-label">
        <span className="mr-1">Pilih Grade</span>
        {isRequired
        ?
          <span className="text-danger">*</span>
        : null }
      </label>
      <div className="col-sm-9">
        <BaseSelect
          placeholder="Pilih Grade Terlebih Dahulu..."
          isClearable={true}
          options={optionsFilterGradeFormatData}
          value={value}
          onChange={setValue}
          isInvalid={isInvalid}
        />
        {isInvalid ? (
          <label className="small form-text text-danger">
            {errorMessage || 'Pilih grade terlebih dahulu.'}
          </label>
        ) : null}
      </div>
    </div>
  )
}
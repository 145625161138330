import React from 'react'
import useContentPlayerContext from 'context/content-player'

export default function AlertGrupKecil() {
  /* ====================================== Consume Context ===================================== */
  const { forum } = useContentPlayerContext()

  /* ========================================== Output ========================================== */
  return forum.required ? (
    forum.participated ? (
      <span
        className="border rounded alert alert-success"
        role="alert"
      >
        <strong>
          <i className="fas fa-check-circle mr-1" />
          Anda sudah terlibat
        </strong>
      </span>
    ) : (
      <span
        className="border rounded alert alert-danger"
        role="alert"
      >
        <strong>
          <i className="fas fa-info-circle mr-1" />
          Anda belum terlibat
        </strong>
      </span>
    )
  ) : (
    <span
      className="border rounded alert alert-dark"
      role="alert"
    >
      <strong>
        <i className="fas fa-info-circle mr-1" />
        Bukan Prasyarat
      </strong>
    </span>
  )
}

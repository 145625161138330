import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import ReactSelect from 'react-select'
import YouTube from 'react-youtube'
import moment from 'moment'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import CkeditorReadonly from 'component/ckeditor-readonly'
import DataTable from 'component/data-table'
import Modal from 'component/modal'

export default function OverviewKindness() {
  /* ========================================== Helpers ========================================= */
  const location = useLocation()
  let { detail } = location.state
  let { id } = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [limit, setLimit]                        = useState(10)
  const [offset, setOffset]                      = useState(0)
  const [search, setSearch]                      = useState('')
  const [detailKindness, setDetailKindness]      = useState({})
  const [dataKindnessUsers, setDataKindnessUser] = useState([])
  const [totalKindness, setTotalKindness]        = useState(0)
  const [optionSiswa, setOptionSiswa]            = useState([])
  const [valueSiswa, setValueSiswa]              = useState('')

  /* ========================================= Functions ======================================== */
  const getListUsers = useCallback(async () => {
    let res = await getRequest(
      `kindness-profile?limit=${limit}&offset=${offset}&kindness_program_id=${id}&search=${search}&user_id=${valueSiswa}`,
    )
    if (res) {
      let data = res.data
      for (var item of data) {
        item.value = JSON.parse(item.value)
      }
      setDataKindnessUser(res.data)
      setTotalKindness(res.totalData)
    }
  }, [limit, offset, search, id, valueSiswa])

  const getAllSiswa = useCallback(async () => {
    let res = await getRequest('siswa-all')
    if (res) {
      let arr = []
      res.data.map((item) => arr.push({ label: item.name, value: item.id }))
      setOptionSiswa(arr)
    }
  }, [])

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (id) {
      getListUsers()
    }
  }, [getListUsers])

  useEffect(() => {
    getAllSiswa()
  }, [getAllSiswa])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Profile"
          linkName="Overview"
          links={[{ url: '/kindness-program', name: 'Kindness Program' }]}
        />

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <ul
                  className="nav nav-pills bg-white"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active text-uppercase"
                      data-toggle="tab"
                      href="#info"
                      role="tab"
                    >
                      Overview
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#groups"
                      role="tab"
                    >
                      Periksa
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="tab-content"
              id="myTabContent"
            >
              <div
                className="tab-pane fade show active row"
                id="info"
                role="tabpanel"
              >
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h3>{detail?.title}</h3>
                      <CkeditorReadonly data={detail?.description} />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade show row"
                id="groups"
                role="tabpanel"
              >
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-sm-5">
                          <span className="f-w-700 f-14">Siswa</span>
                          <ReactSelect
                            onChange={(e) => setValueSiswa(e.value)}
                            placeholder="Filter berdasarkan siswa"
                            options={optionSiswa}
                          />
                        </div>
                      </div>
                      <div className="dt-responsive table-responsive">
                        <DataTable
                          className={
                            'table table-striped table-bordered nowrap'
                          }
                          column={['Siswa', 'tanggal', 'actions']}
                          show={[10, 20, 30, 40, 50]}
                          onChangeSearch={(val) => {
                            setSearch(val)
                          }}
                          onChangeLimit={(val) => {
                            setLimit(val)
                          }}
                          onChangePage={(val) => {
                            setOffset(val)
                          }}
                          data={dataKindnessUsers}
                          totalRows={totalKindness}
                          renderItem={(item, i) => (
                            <RenderItem
                              item={item}
                              index={i}
                              key={item.id}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Modal
        showCloseFooter={true}
        size="modal-lg"
        id={'kindnessModal'}
        title={detailKindness?.kindness_program?.title}
      >
        <div
          className="hd-body pr-2"
          style={{
            maxHeight: '350px',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {RenderAnswers()}
        </div>
      </Modal>
    </TemplateAdmin>
  )

  function RenderAnswers() {
    if (detailKindness?.value?.type == 'image') {
      return (
        <Fragment>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <CkeditorReadonly
                      data={detailKindness?.kindness_program?.description}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="alert alert-success"
              role="alert"
            >
              Jawaban
            </div>
            <div className="row">
              <div className="col-12 border bg-light rounded py-4">
                <div className="form-group row mb-4">
                  <label className="col-form-label col-4 offset-lg-2 col-lg-2">
                    Image
                  </label>
                  <div className="col-8 col-lg-6">
                    <div className="thumbnail mb-4">
                      <div className="thumb">
                        <a data-lightbox="1">
                          <img
                            src={detailKindness?.linkUrl}
                            alt=""
                            className="img-fluid img-thumbnail"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <label className="col-form-label col-4 offset-lg-2 col-lg-2">
                    Deskripsi
                  </label>
                  <div className="col-8 col-lg-6">
                    <textarea
                      className="form-control"
                      value={detailKindness?.description}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else if (detailKindness?.value?.type == 'video') {
      return (
        <Fragment>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <CkeditorReadonly
                      data={detailKindness?.kindness_program?.description}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="alert alert-success"
              role="alert"
            >
              Jawaban
            </div>
            <div className="row">
              <div className="col-12 border bg-light rounded py-4">
                <div className="form-group row mb-4">
                  <label className="col-form-label col-4 offset-lg-2 col-lg-2">
                    Video
                  </label>
                  <div className="col-8 col-lg-6">
                    {detailKindness?.value?.source == 'internal' ? (
                      <video
                        width="400px"
                        height="200px"
                        controls
                        controlsList="nodownload"
                      >
                        <source
                          src={detailKindness?.linkUrl}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <YouTube
                        opts={{
                          width: '330px',
                          height: '200px',
                        }}
                        videoId={detailKindness?.value?.data}
                      />
                    )}
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <label className="col-form-label col-4 offset-lg-2 col-lg-2">
                    Deskripsi
                  </label>
                  <div className="col-8 col-lg-6">
                    <textarea
                      className="form-control"
                      value={detailKindness?.description}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else if (detailKindness?.value?.type == 'text') {
      return (
        <Fragment>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <CkeditorReadonly
                      data={detailKindness?.kindness_program?.description}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="alert alert-success"
              role="alert"
            >
              Jawaban
            </div>
            <div className="row">
              <div className="col-12 border bg-light rounded py-4">
                <div className="form-group row mb-4">
                  <label className="col-form-label col-4 offset-lg-2 col-lg-2">
                    Catatan
                  </label>
                  <div className="col-8 col-lg-6">
                    <CkeditorReadonly data={detailKindness?.value?.data} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else {
      return null
    }
  }

  function RenderItem({ item }) {
    return (
      <tr>
        <td>
          <div className="d-inline-block align-middle">
            <img
              onError={(e) =>
                (e.currentTarget.src = '/image/blank-avatar-user.svg')
              }
              src={item?.user?.linkAvatar}
              className="img-radius align-top m-r-15"
              style={{ width: '40px', height: '40px' }}
            />
            <div className="d-inline-block">
              <h6 className="m-b-0 mt-2">{item?.user?.name}</h6>
            </div>
          </div>
        </td>
        <td>{moment(item?.createdAt).format('DD MMMM YYYY')}</td>
        <td>
          <button
            className="btn btn-primary"
            type="button"
            data-toggle="modal"
            data-target="#kindnessModal"
            onClick={() => setDetailKindness(item)}
          >
            {' '}
            <i className="far fa-eye mr-1"></i>Lihat
          </button>
        </td>
      </tr>
    )
  }
}

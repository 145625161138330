import React from 'react'

export default function EmptySection() {
  return (
    <div className="mb-0">
      <div
        style={{
          backgroundColor: '#f8f9fa',
        }}
      >
        <h5 className="row m-0">
          <div
            className="col collapsed text-dark p-3 btn-forbidden"
            style={{ opacity: '0.65' }}
          >
            <div className="row">
              <div className="col-10 col-sm-11">
                <div className="row">
                  <span className="col-12 col-md-9 col-xl-10 f-16 f-w-500">
                    Materi belum tersedia.
                  </span>
                  <span className="col-12 col-md-3 col-xl-2 text-right f-14 f-w-300">
                    0 materi
                  </span>
                </div>
              </div>
              <div className="col-2 col-sm-1 d-flex justify-content-end">
                <i className="fas fa-chevron-down" />
              </div>
            </div>
          </div>
        </h5>
      </div>
      <div
        className="collapse"
        data-parent="#konten-accordion"
      >
        <ul className="list-unstyled mb-0">
          <p className="text-muted p-3 mb-0">Belum ada materi.</p>
        </ul>
      </div>
    </div>
  )
}

import { useEffect } from "react"
import { useHistory } from 'react-router-dom'

export default function useConfig() {
  const history = useHistory()

  function checkUserHasAccessConfig() {
    const menu_keys = localStorage.getItem('menu_key')
    const menu_keysJSON = menu_keys && JSON.parse(menu_keys)

    if (Array.isArray(menu_keysJSON) && menu_keysJSON.length) {
      if (!menu_keysJSON.includes('config')) {
        history.push('/dashboard')
      }
    }
  }
  /* ---------------------------------- DATA ---------------------------------- */
  const listConfigs = [
    {
      label: 'Quiz',
      key: 'quiz',
    },
  ]

  useEffect(() => {
    checkUserHasAccessConfig()
  }, [])

  return {
    data: {
      listConfigs
    }
  }
}
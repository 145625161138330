import React from 'react'
import BreadCrumb from 'component/breadcrumb'

export default function Header({ pageName, linkName, links }) {
  return pageName ? (
    <div className="page-header">
      <div className="page-block">
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="page-header-title">
              <h5 className="m-b-10">{pageName}</h5>
            </div>
            <BreadCrumb
              pageName={linkName || pageName}
              links={links}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null
}

import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { Back } from 'component/button'
import InvalidText from 'component/invalid/text'
import CkeditorComponent from 'component/ckeditor'

export default function GraphicOrganizerSortAdd() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ========================================= Functions ======================================== */
  const [randId, setRandId]         = useState(makeid(5))
  const [list, setList]             = useState([])
  const [text, setText]             = useState('')
  const [name, setName]             = useState('')
  const [status, setStatus]         = useState(0)
  const [pembahasan, setPembahasan] = useState('')

  // Validation state
  const [isListInValid, setIsListInValid] = useState(false)
  const [isNameInValid, setIsNameInValid] = useState(false)
  
  /* =========================================== Refs =========================================== */
  const refName = useRef()
  const refList = useRef()

  /* ========================================= Constants ======================================== */
  const grid = 8
  const links = [{ url: '/graphic-organizer', name: 'Graphic Organizer' }]

  /* ========================================= Functions ======================================== */
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    borderRadius: '5px',
    // change background colour if dragging
    background: isDragging ? 'lightyellow' : '#f5f5f5',
    border: isDragging ? '1px solid #ddd' : 'none',
    color: 'black',
    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    var source = result.source
    var destination = result.destination
    var l = reorder(list, source.index, destination.index)
    setRandId(makeid(5))
    setList(l)
  }

  function makeid(length) {
    var result = ''
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  function handleAddTextToList(e) {
    e.preventDefault()
    var t = list
    t.push(text)
    setText('')
    setList(() => t)
    setRandId(makeid(5))
  }

  function handleChangeStatus(event) {
    switch (event.target.checked) {
      case true:
        setStatus(1)
        break
      case false:
        setStatus(0)
        break
      default:
        break
    }
  }

  function handleChangeName(event) {
    setName(event.target.value)
  }

  function handleEnter(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      var t = list
      t.push(text)
      setText('')
      setList(() => t)
      setRandId(makeid(5))
    }
  }

  function handleValidation() {
    if (name && list.length > 0) {
      setIsNameInValid(false)
      setIsListInValid(false)
      handleSubmit(name, list, status, pembahasan)
    } else {
      if (!name) {
        setIsNameInValid(true)
        refName.current.focus()
      } else {
        setIsNameInValid(false)
      }

      if (list.length == 0) {
        setIsListInValid(true)
        refList.current.focus()
      } else {
        setIsListInValid(false)
      }
      window.notification('Data belum lengkap', 'Mohon lengkapi data')
    }
  }

  async function handleSubmit(name, list, status, pembahasan) {
    let form = {
      description: name,
      graphor_type: 1,
      active: status,
      data: list,
      pembahasan: pembahasan,
    }

    const response = await postRequest('graphors', form)
    if (response) {
      window.notification(
        'Berhasil',
        'Graphic Organizer Sorting Berhasil ditambahkan',
        'success',
      )
      history.push('/graphic-organizer')
    } else {
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Graphic Organizer: Sorting"
          linkName="Tambah Sorting"
          links={links}
        />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Tambah Sorting</h5>
              </div>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col-md-6'}>
                    <form>
                      <div className="form-group row">
                        <label
                          htmlFor="nama-list"
                          className="col-sm-3 col-form-label "
                        >
                          Nama
                        </label>
                        <div className="col-sm-9">
                          <input
                            ref={refName}
                            value={name}
                            onChange={(event) => handleChangeName(event)}
                            type="text"
                            className={`${
                              isNameInValid && 'is-invalid'
                            } form-control`}
                            id="nama-list"
                            placeholder="Judul list sorting"
                          />
                          {isNameInValid && <InvalidText name={'Nama list'} />}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="tambah-list"
                          className="col-sm-3 col-form-label "
                        >
                          Tambah item
                        </label>
                        <div className="col-sm-9">
                          <div className="input-group mb-3">
                            <input
                              onKeyDown={(event) => handleEnter(event)}
                              ref={refList}
                              value={text}
                              onChange={(e) => setText(e.target.value)}
                              id="tambah-list"
                              type="text"
                              className={`${
                                isListInValid && 'is-invalid'
                              } form-control`}
                              placeholder="Tambah item list sorting"
                            />
                            {text.length > 0 && (
                              <div className="input-group-append">
                                <button
                                  onClick={(event) =>
                                    handleAddTextToList(event)
                                  }
                                  className="btn  btn-primary"
                                  type="button"
                                >
                                  <i className="fas fa-plus" />
                                </button>
                              </div>
                            )}
                          </div>
                          {isListInValid && <InvalidText name={'List'} />}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="pembahasan"
                          className="col-sm-3 col-form-label"
                        >
                          Pembahasan
                        </label>
                        <div className="col-sm-9">
                          <CkeditorComponent
                            data={pembahasan}
                            configuration="full"
                            onChange={setPembahasan}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="check-list"
                          className="col-sm-3 col-form-label "
                        >
                          Status
                        </label>
                        <div className="col-sm-9">
                          <div className="switch switch-primary d-inline m-r-10">
                            <input
                              onChange={(event) => handleChangeStatus(event)}
                              checked={status === 1}
                              type="checkbox"
                              id="switch-p-1"
                              defaultChecked
                            />
                            <label
                              htmlFor="switch-p-1"
                              className="cr"
                            />
                          </div>
                          <label>
                            {status === 1 ? 'Aktif' : 'Tidak Aktif'}
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className={'col-md-6'}>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId={'list'}>
                        {(provided, snapshot) => (
                          <div
                            className="card bg-white col-md-12"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            <div className="card-header">
                              <h5>List</h5>
                              <hr />
                            </div>
                            <div className="card-body">
                              {list.map((item, index) => {
                                return (
                                  <Draggable
                                    key={randId + makeid(10)}
                                    draggableId={item}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style,
                                        )}
                                        onMouseEnter={(e) =>
                                          (e.currentTarget.style.backgroundColor =
                                            'lightyellow')
                                        }
                                        onMouseLeave={(e) =>
                                          (e.currentTarget.style.backgroundColor =
                                            '#f5f5f5')
                                        }
                                      >
                                        <div className="row">
                                          <div className="col-1">
                                            <button
                                              type={'button'}
                                              onClick={() => {
                                                var a = list
                                                delete a[index]
                                                setRandId(makeid(5))
                                                setList(a)
                                              }}
                                              className={
                                                'btn btn-danger btn-sm'
                                              }
                                            >
                                              <i className={'fas fa-trash'}></i>
                                            </button>
                                          </div>
                                          <b className="col-8 col-lg-10 ml-2 pt-1">
                                            {item}
                                          </b>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              })}
                              {provided.placeholder}
                            </div>
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="row">
                  <div className="col">
                    <Back />
                    <button
                      onClick={() => handleValidation()}
                      className="btn btn-success"
                    >
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

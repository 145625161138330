import React, { memo } from 'react'
import { ContentSummaryWrapper } from 'context/content-summary'

import Tabs from './partials/tabs'
import TabsContent from './partials/tabs-content'

function ContentSummary({ class_id }) {
  return (
    <ContentSummaryWrapper class_id={class_id}>
      <Tabs />
      <TabsContent />
    </ContentSummaryWrapper>
  )
}
export default memo(ContentSummary)

import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import lodash from 'lodash'
import { useBaseContext } from 'context/base'
import {
  TextAnswer,
  AudioAnswer,
  VideoAnswer,
  DocumentAnswer,
  YoutubeAnswer,
  UrlAnswer,
} from './assignment-answer'
import FormPenilaian from './form-penilaian'
import FormPenilaianEdit from './form-penilaian-edit'

export default function DetailAssignmentModal({
  setAssignmentModal,
  answerId,
  answer,
  replyType,
  assignmentTitle,
  siswaName,
  siswaNim,
}) {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { putRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [score, setScore]                     = useState('')
  const [note, setNote]                       = useState('')
  const [isScoreInvalid, setIsScoreInvalid]   = useState(false)
  const [hasBeenEvaluate, setHasBeenEvaluate] = useState(false)
  
  /* =========================================== Refs =========================================== */
  const scoreRef = useRef()

  /* ========================================= Functions ======================================== */
  function checkIsEvaluated() {
    if (answer) {
      if (answer.score) {
        setHasBeenEvaluate(true)
        setScore(parseInt(answer.score))
        setNote(answer.note)
      } else {
        setHasBeenEvaluate(false)
        setScore('')
        setNote('')
      }
    }
  }

  function handleChangeScore(score) {
    if (score) {
      setScore(parseInt(score))
    } else {
      setScore('')
    }
  }

  function handleChangeNote(note) {
    setNote(note)
  }

  function handleFormValidation() {
    const scoreValid = lodash.inRange(score, 0, 101)

    if (scoreValid && score) {
      setIsScoreInvalid(false)
      if (hasBeenEvaluate) {
        handleUbahNilai()
      } else {
        handleTerimaTugas()
      }
    } else {
      setIsScoreInvalid(true)
      scoreRef.current.focus()
    }
  }

  function handleTerimaTugas() {
    Swal.fire({
      title: '<h4>Apa anda akan menerima tugas ini?</h4>',
      html: `
        <p class="f-16 f-w-500">Nilai: <span class="text-dark f-w-700">${score}</span><p>
        ${
          note
            ? `<p class="f-16 f-w-500">Catatan: <span class="text-dark f-w-300">${note}</span><p>`
            : ''
        }
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, terima tugas!',
      cancelButtonText: 'Periksa kembali',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await submitForm()
      }
    })
  }

  function handleUbahNilai() {
    Swal.fire({
      title: '<h4>Simpan perubahan nilai?</h4>',
      html: `
        <p class="f-16 f-w-500">Nilai: <span class="text-dark f-w-700">${score}</span><p>
        ${
          note
            ? `<p class="f-16 f-w-500">Catatan: <span class="text-dark f-w-300">${note}</span><p>`
            : ''
        }
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, simpan!',
      cancelButtonText: 'Periksa kembali',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await submitForm()
      }
    })
  }

  async function submitForm() {
    let form = new FormData()
    form.append('assignment_users_id', answer.id)
    form.append('score', score)
    form.append('note', note)
    const response = await putRequest('assignment-siswa-answer', form)
    if (response) {
      Swal.fire('Berhasil', 'Tugas berhasil diterima.', 'success').then(() =>
        history.go(0),
      )
    } else {
      Swal.fire(
        'Oppsss!',
        'Terjadi kesalahan coba beberapa saat lagi.',
        'error',
      )
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    checkIsEvaluated()
  }, [answerId])

  /* ========================================== Output ========================================== */
  return (
    <div
      className="modal fade"
      id="detail-assignment-modal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myExtraLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <div>
              <h5 className="modal-title mb-2">
                {assignmentTitle ? assignmentTitle : '-'}
              </h5>
              <p className="f-w-500 f-14 mb-0">{siswaName ? siswaName : '-'}</p>
              <p className="f-w-400 f-12 text-muted mb-0">
                {'No. Induk: '}
                {siswaNim ? siswaNim : 'tidak terdaftar'}
              </p>
              <p className="f-w-400 f-12 text-muted mb-0">
                {'ID tugas: '}
                {answerId ? answerId : '-'}
              </p>
            </div>
            <button
              type="button"
              className="close text-danger"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setAssignmentModal(null)}
            >
              <i className="feather icon-x" />
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                {answer ? (
                  <>
                    {replyType === '1' ? <TextAnswer answer={answer} /> : null}
                    {replyType === '2' ? <AudioAnswer answer={answer} /> : null}
                    {replyType === '3' ? <VideoAnswer answer={answer} /> : null}
                    {replyType === '4' ? (
                      <DocumentAnswer
                        answer={answer}
                        assignmentTitle={assignmentTitle}
                        siswaName={siswaName}
                      />
                    ) : null}
                    {replyType === '5' ? (
                      <YoutubeAnswer answer={answer} />
                    ) : null}
                    {replyType === '6' ? <UrlAnswer answer={answer} /> : null}
                  </>
                ) : null}
              </div>
              <div className="col-12 mt-4">
                {hasBeenEvaluate ? (
                  <FormPenilaianEdit
                    score={score}
                    note={note}
                    scoreRef={scoreRef}
                    isScoreInvalid={isScoreInvalid}
                    handleChangeScore={handleChangeScore}
                    handleChangeNote={handleChangeNote}
                    handleFormValidation={handleFormValidation}
                  />
                ) : (
                  <FormPenilaian
                    score={score}
                    note={note}
                    scoreRef={scoreRef}
                    isScoreInvalid={isScoreInvalid}
                    handleChangeScore={handleChangeScore}
                    handleChangeNote={handleChangeNote}
                    handleFormValidation={handleFormValidation}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer p-2">
            <button
              type="button"
              className="btn btn-outline-danger"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setAssignmentModal(null)}
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

import BaseSelect from "component/select/BaseSelect";
import { useTugasHook } from "hooks/content/useTugasHook";
import { useEffect } from "react";

/**
 * Form Input Web Address Props
 * 
 * @param {BaseContentFormProps & {
 *  id: string
 * }} props
 */
export default function FormInputTugas({ 
  value, 
  setValue, 
  isInvalid, 
  isRequired, 
  errorMessage,
  isInitSelectedValueFromFormSelect,
  id,
}) {
  const { data, handler, } = useTugasHook()

  /**
   * Set Init Select Value
   * 
   * @param {Array<BaseSelectFormatData>} listOptions 
   */
  const setInitSelectValue = (listOptions) => {
    if (isInitSelectedValueFromFormSelect && Array.isArray(listOptions) && listOptions.length) {
      const isValueExist = listOptions.find(opt => opt.value === id)

      if (isValueExist) {
        setValue(isValueExist)
      }
    }
  }

  useEffect(() => {
    handler.getTugasHandler({ setInitSelectValue })
  }, [])

  return (
    <div className="form-group mb-5 row">
      <label className="col-sm-3 col-form-label" htmlFor='inputTugas'>
        <span className="mr-1">Tugas</span>
        {isRequired
        ?
          <span className="text-danger">*</span>
        : null }
      </label>
      <div className="row col-sm-9">
        <div className="col-sm-8">
          <BaseSelect
            isSearchable={true}
            isMulti={false}
            onMenuOpen={handler.getTugasHandler}
            placeholder="Pilih Tugas"
            noOptionsMessage={() => 'Data tugas tidak dtemukan'}
            isClearable={true}
            options={data.listTugas.optionsFilter}
            value={value}
            onChange={setValue}
          />

          {isInvalid ? (
            <label className="small form-text text-danger">
              {errorMessage || 'Tugas tidak boleh kosong.'}
            </label>
          ) : null}
        </div>

        <div className="col-sm-4">
          <button
            type="button"
            className="btn btn-primary btn-sm mt-2 mt-md-1"
            onClick={handler.popUpWindowKelolaTugasHandler}
          >
            <i className="feather icon-settings mr-1" />
            <span>Kelola Tugas</span>
          </button>
        </div>
      </div>
    </div>
  )
}
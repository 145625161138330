import React, { useState } from 'react'
import { useBaseContext } from 'context/base'

export default function FormInputDraft({
  item,
  categoryId,
  taTahun,
  semester_id,
  class_id,
  period_id,
  reportGroupId,
  badgesConfig,
  scoreK = 0,
  scoreP = 0,
  predicateK = '',
  predicateP = '',
  description = '',
  setShowEditUi,
  setScoreK,
  setScoreP,
  setPredicateK,
  setPredicateP,
  initScoreData = async () => {},
}) {
  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  /* ============================================================================================ */
  /*              HITUNG NILAI LMS, UNTUK MENGHANDLE CLICK BUTTON `GUNAKAN NILAI LMS`             */
  /* ============================================================================================ */
  const scoreReducer = (state, payload) =>
    state + (payload.average_score * payload.percentage) / 100
  const calculatedScore = {
    pengetahuan: Math.round(
      item.nilai.allScoresPengetahuan?.reduce(scoreReducer, 0),
    ),
    keterampilan: Math.round(
      item.nilai.allScoresKeterampilan?.reduce(scoreReducer, 0),
    ),
  }

  /* ========================================= Functions ======================================== */
  const calculatePredikat = (score) => {
    score = parseInt(score)
    if (score >= 90) {
      return 'A'
    } else if (score >= 80 && score <= 89) {
      return 'B'
    } else if (score >= 70 && score <= 79) {
      return 'C'
    } else {
      return 'D'
    }
  }

  const validateNumber = (number) => {
    const num = parseInt(number)
    return num >= 0 && num <= 100
  }

  const handleChangeScoreK = (event) => {
    const value = event.target.value
    if (Number.isInteger(parseInt(value))) {
      if (validateNumber(value)) {
        setScoreK(value)
        setPredicateK(calculatePredikat(value))
      }
    } else {
      setScoreK('')
      setPredicateK('')
    }
  }
  const handleChangeScoreP = (event) => {
    const value = event.target.value
    if (Number.isInteger(parseInt(value))) {
      if (validateNumber(value)) {
        setScoreP(value)
        setPredicateP(calculatePredikat(value))
      }
    } else {
      setScoreP('')
      setPredicateP('')
    }
  }

  const sendDraft = async ({
    scoreK,
    scoreP,
    taTahun,
    semester_id,
    class_id,
    period_id,
    reportGroupId,
    userId,
    item,
    badgesConfig,
    status,
  }) => {    
    setIsLoadingSubmit(true)
    let form = new FormData()

    if (period_id) {
      form.append('period_id', period_id)
    }

    form.append('score_final', 0)
    form.append('class_id', class_id)
    form.append('user_id', userId)
    form.append('ta_semester_code', semester_id)
    form.append('ta_tahun_code', taTahun)
    form.append('score_temp', 0)
    form.append('nilai_keterampilan', scoreK)
    form.append('nilai_pengetahuan', scoreP)
    form.append('status_approval', status)
    await postRequest('report-period', form)
    setShowEditUi(false)
    await initScoreData(
      item,
      reportGroupId,
      badgesConfig,
      class_id,
      semester_id,
      period_id,
    )
  }

  /* ========================================== Output ========================================== */
  return (
    <div className="bg-light border p-3 rounded">
      <h6>Form Input Draft Nilai</h6>
      <form>
        <div className="form-group row">
          <label className="col-2 col-form-label">Nilai Keterampilan</label>
          <div className="col-4 d-flex">
            <input
              type="number"
              className="form-control"
              style={{ width: '65px', padding: '5px' }}
              placeholder="Nilai"
              value={scoreK}
              onChange={handleChangeScoreK}
              disabled={isLoadingSubmit}
            />
            <button
              type="button"
              className="btn btn-primary btn-sm mt-2 ml-4"
              disabled={!item.nilai.allScoresKeterampilan}
              onClick={() =>
                handleChangeScoreK({
                  target: { value: calculatedScore.keterampilan },
                })
              }
            >
              Gunakan Nilai LMS
            </button>
          </div>
          <label className="col-2 col-form-label">Nilai Pengetahuan</label>
          <div className="col-4 d-flex">
            <input
              type="number"
              className="form-control"
              style={{ width: '65px', padding: '5px' }}
              placeholder="Nilai"
              value={scoreP}
              onChange={handleChangeScoreP}
              disabled={isLoadingSubmit}
            />
            <button
              type="button"
              className="btn btn-primary btn-sm mt-2 ml-4"
              disabled={!item.nilai.allScoresPengetahuan}
              onClick={() =>
                handleChangeScoreP({
                  target: { value: calculatedScore.pengetahuan },
                })
              }
            >
              Gunakan Nilai LMS
            </button>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Predikat Keterampilan</label>
          <div className="col-3 d-flex align-items-center">
            <span>
              <strong>{predicateK ? predicateK : '-'}</strong>
            </span>
          </div>

          <label className="col-3 col-form-label">Predikat Pengetahuan</label>
          <div className="col-3 d-flex align-items-center">
            <span>
              <strong>{predicateP ? predicateP : '-'}</strong>
            </span>
          </div>
        </div>

        <div className="mt-2">
          {scoreK === '' && scoreP === '' ? (
            <button
              type="button"
              className="btn btn-sm btn-primary mr-2"
              disabled={true}
            >
              Kirim Draft
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-sm btn-primary mr-2"
                disabled={isLoadingSubmit || !Number.isFinite(parseInt(scoreK)) || !Number.isFinite(parseInt(scoreP))}
                onClick={() => {
                  sendDraft({
                    scoreK: scoreK,
                    scoreP: scoreP,
                    predicateK: predicateK,
                    predicateP: predicateP,
                    description: description,
                    categoryId: categoryId,
                    taTahun: taTahun,
                    reportGroupId: reportGroupId,
                    userId: item.user_id,
                    semester_id: semester_id,
                    class_id: class_id,
                    period_id: period_id,
                    item: item,
                    badgesConfig: badgesConfig,
                    status: 'draft',
                  })
                }}
              >
                {isLoadingSubmit ? (
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  />
                ) : null}
                Simpan
              </button>
              <button
                type="button"
                className="btn btn-sm btn-success mr-2"
                disabled={isLoadingSubmit || !Number.isFinite(parseInt(scoreK)) || !Number.isFinite(parseInt(scoreP))}
                onClick={() => {
                  sendDraft({
                    scoreP: scoreP,
                    scoreK: scoreK,
                    predicateK: predicateK,
                    predicateP: predicateP,
                    description: description,
                    categoryId: categoryId,
                    taTahun: taTahun,
                    reportGroupId: reportGroupId,
                    userId: item.user_id,
                    semester_id: semester_id,
                    class_id: class_id,
                    period_id: period_id,
                    item: item,
                    badgesConfig: badgesConfig,
                    status: 'submission',
                  })
                }}
              >
                {isLoadingSubmit ? (
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  />
                ) : null}
                Simpan & Ajukan
              </button>
            </>
          )}
          <button
            type="button"
            className="btn btn-sm btn-outline-secondary"
            onClick={() => {
              setShowEditUi(false)
              initScoreData(
                item,
                reportGroupId,
                badgesConfig,
                class_id,
                semester_id,
                period_id,
              )
            }}
          >
            Batal
          </button>
        </div>
      </form>
    </div>
  )
}

import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import Select from 'react-select'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'
import Modal from 'component/modal'
import { FormTextArea } from 'component/forms'

export default function AcademicYear() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest, deleteRequest} = useBaseContext()

  /* =================================== Consume Local Storage ================================== */
  const { role_type } = JSON.parse(localStorage.getItem('role_active'))

  /* =========================================== Refs =========================================== */
  const mounted = useRef(true)

  /* ======================================= Local States ======================================= */
  const [data, setData]                 = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [limit, setLimit]               = useState(10)
  const [offset, setOffset]             = useState(0)
  const [search, setSearch]             = useState('')

  const [years, setYears]                     = useState([])
  const [startYear, setStartYear]             = useState(null)
  const [endYear, setEndYear]                 = useState(null)
  const [template, setTemplate]               = useState(null)
  const [modalType, setModalType]             = useState('')     // modalType: add/edit
  const [description, setDescription]         = useState('')
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  /* ========================================= Constants ======================================== */
  const options = [
    { value: 'Raport Versi 1', label: 'Kurikulum K13' },
    { value: 'Raport Versi 2', label: 'Kurikulum Merdeka' },
  ]

  /* ========================================= Functions ======================================== */
  async function getData() {
    if (role_type !== 'administrator' && role_type !== 'adminprofile')
      history.push('/')
    const url = `academic-year?search=${search}&limit=${limit}&offset=${offset}`
    const response = await getRequest(url)
    if (response) {
      if (mounted.current === true) {
        setData(response.data)
        setTotalRecords(response.totalRecords)
      }
    }
  }

  async function initModal(modalType, item) {
    if (modalType === 'edit') {
      const startYear = `20${item.code[0]}${item.code[1]}`
      const endYear = `20${item.code[2]}${item.code[3]}`
      setStartYear({ value: parseInt(startYear), label: startYear })
      setEndYear({ value: parseInt(endYear), label: endYear })
      setDescription(item.description)
    }
    setModalType(modalType)
  }

  function handleChangeDescription(event) {
    setDescription(event.target.value)
  }

  async function handleSubmitModal() {
    let form = new FormData()
    let response = null
    const newCode = `${startYear.label.slice(2)}${endYear.label.slice(2)}`
    form.append('code', newCode)
    form.append('description', description)
    form.append('template_raport', template.value)
    setIsLoadingSubmit(true)
    if (modalType === 'add') {
      response = await postRequest('academic-year', form)
    } else if (modalType === 'edit') {
      response = await putRequest('academic-year', form)
    }
    if (response) {
      await getData()
      window.$('#modalAcamedicYear').modal('hide')
      setDescription('')
      setStartYear(null)
      setEndYear(null)
      window.notification('', 'Berhasil menyimpan tahun ajaran', 'success')
    }
    setIsLoadingSubmit(false)
  }

  function handleCloseModal() {
    setDescription('')
    setStartYear(null)
    setEndYear(null)
    setIsLoadingSubmit(false)
  }

  async function handleDelete(item) {
    const startYear = `20${item.code[0]}${item.code[1]}`
    const endYear = `20${item.code[2]}${item.code[3]}`
    Swal.fire({
      html: `
        <div class="text-dark border bg-light text-center f-w-700 p-2 mb-3 border rounded">
          <h5 class="text-dark">
            <i class="feather icon-alert-triangle mr-2"></i>PERINGATAN
          </h5>
          <p class="mb-1">Tahun ajaran akan dihapus dari sistem!</p>
        </div>
        <h4 class="my-0">Hapus tahun ajaran ${startYear}/${endYear}?</h4>
      `,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(
          `academic-year?code=${item.code}`,
        )
        if (response) {
          Swal.fire({
            icon: 'success',
            text: 'Tahun ajaran berhasil dihapus',
          }).then(() => window.location.reload())
        }
      }
    })
  }

  function handleDetailSemester(item) {
    history.push(`/academic-year/${item.code}`)
  }

  function generateYear(startYear, maxYear) {
    const max = maxYear - startYear
    let years = []
    for (let count = 0; count <= max; count++) {
      const year = startYear + count
      years.push({ value: year, label: `${year}` })
    }
    return years
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getData()
  }, [limit, offset, search])

  useEffect(() => {
    const year = generateYear(2000, 2100)
    if (mounted.current === true) setYears(year)

    return () => {
      mounted.current = false
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Tahun Ajaran" />
        <div className="card user-profile-list">
          <div className="card-header">
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#modalAcamedicYear"
              onClick={() => initModal('add')}
            >
              <i className="feather icon-plus-circle mr-1" />
              Tambah Tahun Ajaran
            </button>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col dt-responsive table-responsive">
                <DataTable
                  className={'table table-striped table-bordered nowrap'}
                  data={data}
                  totalRows={totalRecords}
                  show={[10, 20, 30, 40, 50]}
                  onChangeSearch={(val) => setSearch(val)}
                  onChangeLimit={(val) => setLimit(val)}
                  onChangePage={(val) => setOffset(val)}
                  column={[
                    'Tahun Mulai',
                    'Tahun Selesai',
                    'Kode',
                    'Deskripsi',
                    'Template Raport',
                    'Actions',
                  ]}
                  renderItem={(item, index) => (
                    <RenderItem
                      key={index}
                      item={item}
                      initModal={initModal}
                      handleDelete={handleDelete}
                      handleDetailSemester={handleDetailSemester}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          id="modalAcamedicYear"
          title={
            modalType === 'edit' ? 'Edit Tahun Ajaran' : 'Tambah Tahun Ajaran'
          }
          showFooter={true}
          showCloseFooter={true}
          onClose={handleCloseModal}
          footer={
            isLoadingSubmit ? (
              <button
                disabled
                type="button"
                className="btn btn-primary ml-2"
              >
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                />
                Simpan
              </button>
            ) : (
              <>
                {startYear && endYear ? (
                  <button
                    onClick={handleSubmitModal}
                    type="button"
                    className="btn btn-primary ml-2"
                  >
                    Simpan
                  </button>
                ) : (
                  <button
                    disabled
                    type="button"
                    className="btn btn-primary ml-2"
                  >
                    Simpan
                  </button>
                )}
              </>
            )
          }
        >
          <form>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">Tahun Mulai</label>
              <div className="col-sm-9">
                <Select
                  options={years}
                  value={startYear}
                  isMulti={false}
                  isClearable={true}
                  placeholder="Tahun Mulai"
                  onChange={(value) => setStartYear(value)}
                  isDisabled={modalType === 'edit'}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">Tahun Selesai</label>
              <div className="col-sm-9">
                <Select
                  options={years}
                  value={endYear}
                  isMulti={false}
                  isClearable={true}
                  placeholder="Tahun Selesai"
                  onChange={(value) => setEndYear(value)}
                  isDisabled={modalType === 'edit'}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">
                Template Raport
              </label>
              <div className="col-sm-9">
                <Select
                  value={template}
                  onChange={(value) => setTemplate(value)}
                  options={options}
                />
              </div>
            </div>
            <FormTextArea
              id="modalAcamedicYearDescription"
              value={description}
              label="Deskripsi"
              placeholder="Deskripsi tahun ajaran"
              handleChange={handleChangeDescription}
            />
          </form>
        </Modal>
      </Container>
    </TemplateAdmin>
  )
}

const RenderItem = ({
  item,
  initModal,
  handleDelete,
  handleDetailSemester,
}) => {
  const startYear = `20${item.code[0]}${item.code[1]}`
  const endYear = `20${item.code[2]}${item.code[3]}`

  const templateRaportMapper = (template_raport) =>
    template_raport === 'Raport Versi 1' 
      ? 'Kurikulum K13'
      : template_raport === 'Raport Versi 2' 
      ? 'Kurikulum Merdeka'
      : '-'

  return (
    <tr>
      <td>{startYear}</td>
      <td>{endYear}</td>
      <td>{item.code}</td>
      <td>{item.description ? item.description : '-'}</td>
      <td>{templateRaportMapper(item.template_raport)}</td>
      <td>
        <button
          type="button"
          className="btn btn-sm btn-info mr-2"
          onClick={() => handleDetailSemester(item)}
        >
          <i className="fas fa-graduation-cap mr-1" />
          Semester
        </button>
        <button
          type="button"
          className="btn btn-sm btn-success mr-2"
          data-toggle="modal"
          data-target="#modalAcamedicYear"
          onClick={() => initModal('edit', item)}
        >
          <i className="feather icon-edit mr-1" />
          Edit
        </button>
        <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={() => handleDelete(item)}
        >
          <i className="feather icon-trash-2 mr-1" />
          Hapus
        </button>
      </td>
    </tr>
  )
}

import React, { useState, } from 'react'
import { useBaseContext } from 'context/base'

import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import TableUsers from './components/table-users'
import ModalRaporDetail from './components/modal-rapor-detail'
import ModalRaporBlockDetail from './components/modal-rapor-block-detail'
import loadingTypes from 'globals/loading-types'

export default function MyRapor() {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()
  
  /* ========================================= Constants ======================================== */
  const initialRaporModal = {
    user: {
      id: '',
      name: '',
      nim: '',
      avatar: '',
      linkAvatar: '',
      ta_tahun_code: '',
    },
    angkatan: { code: '', tahunMulai: '' },
    semesters: [],
  }
  
  const initialRaporModalBlock = {
    user: {
      id: '',
      name: '',
      nim: '',
      avatar: '',
      linkAvatar: '',
      ta_tahun_code: '',
    },
    angkatan: { code: '', tahunMulai: '' },
    semesters: [],
    semestersBlock: [],
  }

  /* ======================================= Local States ======================================= */
  const [loadingState, setLoadingState]           = useState(loadingTypes.idle)
  const [loadingModalState, setLoadingModalState] = useState(loadingTypes.idle)
  const [raporModal, setRaporModal]               = useState(initialRaporModal)
  const [raporModalBlock, setRaporModalBlock]     = useState(initialRaporModalBlock)

  /* ========================================= Functions ======================================== */
  const getRaporDetail = async (userId) => {
    const response = await getRequest(
      `report-siswa-detail?user_id=${userId}`,
    )
    return response
      ? {
          user: response.data.user,
          angkatan: response.data.angkatan,
          semesters: response.data.semesters,
        }
      : null
  }

  const getRaporBlockDetail = async (userId) => {
    const response = await getRequest(
      `report-block-siswa-detail?user_id=${userId}`,
    )
    return response
      ? {
          user: response.data.user,
          angkatan: response.data.angkatan,
          semesters: response.data.semesters,
          semestersBlock: response.data.semestersBlock,
        }
      : null
  }

  const onShowModal = async (userId) => {
    setLoadingModalState(loadingTypes.start)
    const raporDetail = await getRaporDetail(userId)
    setRaporModal(raporDetail ? raporDetail : initialRaporModal)
    setLoadingModalState(
      raporDetail ? loadingTypes.success : loadingTypes.error,
    )
  }

  const onShowModalBlock = async (userId) => {
    setLoadingModalState(loadingTypes.start)
    const raporDetail = await getRaporBlockDetail(userId)
    setRaporModalBlock(raporDetail ? raporDetail : initialRaporModalBlock)
    setLoadingModalState(
      raporDetail ? loadingTypes.success : loadingTypes.error,
    )
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Download Rapor" />

        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <TableUsers
                  loadingTypes={loadingTypes}
                  setLoadingState={setLoadingState}
                  onShowModal={onShowModal}
                  onShowModalBlock={onShowModalBlock}
                />
                <ModalRaporDetail
                  loadingTypes={loadingTypes}
                  loadingStatus={loadingModalState}
                  detailRapor={raporModal}
                />
                <ModalRaporBlockDetail
                  loadingTypes={loadingTypes}
                  loadingStatus={loadingModalState}
                  detailRapor={raporModalBlock}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

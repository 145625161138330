import React from 'react'

const styles = {
  cursor: 'pointer',
}

export default function FormCheckTypeRapor({
  handleChangeIsPengetahuan,
  handleChangeIsKeterampilan,
  isPengetahuan,
  isKeterampilan,
}) {
  return (
    <div className="form-group mb-5 row">
      <label className="col-sm-3 col-form-label">Penilaian</label>
      <div className="col-sm-9 d-flex align-items-center">
        <div className="form-group form-check mb-0">
          <input
            type="checkbox"
            className="form-check-input"
            id="inputCheckPengetahuan"
            style={styles}
            checked={isPengetahuan}
            onChange={handleChangeIsPengetahuan}
          />
          <label
            style={styles}
            className="form-check-label"
            htmlFor="inputCheckPengetahuan"
          >
            Pengetahuan
          </label>
        </div>
        <div className="form-group form-check ml-4 mb-0">
          <input
            type="checkbox"
            className="form-check-input"
            id="inputCheckKeterampilan"
            style={styles}
            checked={isKeterampilan}
            onChange={handleChangeIsKeterampilan}
          />
          <label
            style={styles}
            className="form-check-label"
            htmlFor="inputCheckKeterampilan"
          >
            Keterampilan
          </label>
        </div>
      </div>
    </div>
  )
}

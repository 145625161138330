import React from "react";

import Nestable, {NestableProps} from "react-nestable";

/**
 * 
 * @param {NestableProps} props 
 * @returns 
 */
export default function BaseDND(props) {
  return (
    <Nestable
      {...props}
    />
  )
}

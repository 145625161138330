import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import Overview from './overview'
import WaktuBelajar from './waktu-belajar'
import KelasTestCompletion from './kelas-test-completion'
import { Container, Header } from 'component/template'

export default function AktifitasSiswaInfografik() {  
  /* ========================================== Helpers ========================================= */
  const location = useLocation()

  /* ========================================= Constants ======================================== */
  const { dataSiswa } = location.state

  /* ========================================= Functions ======================================== */
  function callPieChartKelas() {
    window.Highcharts.chart('chart-pie-kelas', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      colors: ['#4680ff', '#343a40'],
      title: {
        text: 'Kelas selesai: 0%',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: 'Persentase',
          colorByPoint: true,
          size: '90%',
          innerSize: '60%',
          data: [
            {
              name: 'In progress',
              y: 70,
            },
            {
              name: 'Not started',
              y: 30,
            },
          ],
        },
      ],
    })
  }

  function callPieChartTest() {
    window.Highcharts.chart('chart-pie-test', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      colors: ['#4680ff', '#343a40'],
      title: {
        text: 'Test lulus: 10%',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: 'Persentase',
          colorByPoint: true,
          size: '90%',
          innerSize: '60%',
          data: [
            {
              name: 'Lulus',
              y: 10,
            },
            {
              name: 'Tidak lulus',
              y: 90,
            },
          ],
        },
      ],
    })
  }

  function callBarChartPoints() {
    window.Highcharts.chart('chart-bar-rankning', {
      chart: {
        type: 'column',
      },
      title: {
        text: '1st',
      },
      xAxis: {
        categories: ['Points'],
        labels: {
          enabled: false,
        },
        crosshair: true,
      },
      yAxis: {
        labels: {
          enabled: false,
        },
        max: 1,
        title: {
          enabled: false,
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: 'Points',
          data: [1],
        },
      ],
    })
  }

  /* ========================================= Constants ======================================== */
  const links = [{
    url: '/laporan-aktifitas-siswa',
    name: 'Aktivitas Siswa'
  }]
  
  /* ========================================== Effects ========================================= */
  useEffect(() => {
    callPieChartKelas()
    callPieChartTest()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Laporan Infografik Siswa"
          linkName="Infografik"
          links={links}
        />
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-body">
                <Overview siswa={dataSiswa} />
                <WaktuBelajar siswa={dataSiswa} />
                <KelasTestCompletion />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import React, { useState, useRef } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { Back, Submit } from 'component/button'
import { minimalConfig } from 'util/ckeditor-config'

const AddUraianSurvey = () => {
  /* ========================================== Helpers ========================================= */
  const history  = useHistory()
  const params   = useParams()
  const location = useLocation()

  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const surveyId = params.surveyId
  const dataSurvey = location.state.dataSurvey
  const links = [
    { url: '/survey-engine', name: 'Kelola Survey' },
    {
      url: `/survey-engine/${dataSurvey.id}`,
      name: 'Daftar Pertanyaan',
      state: { existingData: dataSurvey },
    },
  ]
  const questType = 1

  /* ======================================= Local States ======================================= */
  const [textPertanyaan, setTextPertanyaan] = useState('')
  const [nomorUrut, setNomorUrut]           = useState('')
  const [status, setStatus]                 = useState(0)

  // FORM VALIDATION STATE
  const [isUrutanInvalid, setIsUrutanInvalid]                 = useState(false)
  const [isTextPertanyaanInvalid, setIsTextPertanyaanInvalid] = useState(false)

  /* =========================================== Refs =========================================== */
  const refNomorUrut = useRef()

  /* ========================================= Functions ======================================== */
  function handleChangeUrutan(event) {
    if (event.target.value) {
      setNomorUrut(Math.abs(event.target.value))
      setIsUrutanInvalid(false)
    } else {
      setNomorUrut('')
    }
  }

  function handleChangeStatus(event) {
    switch (event.target.checked) {
      case true:
        setStatus(1)
        break
      case false:
        setStatus(0)
        break
      default:
        break
    }
  }

  function handleFormValidation() {
    if (nomorUrut && textPertanyaan) {
      const arrayJawaban = [{ text: null, active: 1 }]
      setIsUrutanInvalid(false)
      setIsTextPertanyaanInvalid(false)
      handleFormSubmit(
        surveyId,
        questType,
        nomorUrut,
        textPertanyaan,
        status,
        arrayJawaban,
      )
    } else {
      if (!textPertanyaan) {
        setIsTextPertanyaanInvalid(true)
      } else {
        setIsTextPertanyaanInvalid(false)
      }

      if (!nomorUrut) {
        setIsUrutanInvalid(true)
        refNomorUrut.current.focus()
      } else {
        setIsUrutanInvalid(false)
      }

      window.notification('Data belum lengkap', 'Mohon lengkapi data')
    }
  }

  async function handleFormSubmit(
    survey_id,
    quest_type,
    urutan,
    text,
    active,
    jawaban,
  ) {
    let form = new FormData()
    form.append('survey_id', survey_id)
    form.append('quest_type', quest_type)
    form.append('urutan', urutan)
    form.append('text', text)
    form.append('active', active)
    form.append('jawaban', JSON.stringify(jawaban))

    const response = await postRequest('surveyquestions', form)
    if (response) {
      window.notification(
        'Sukses',
        'Pertanyaan berhasil ditambahkan',
        'success',
      )
      history.goBack()
    } else {
      console.error('ERROR', response)
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName={`Survey: ${dataSurvey?.title}`}
          linkName="Tambah Pertanyaan"
          links={links}
        />
        
        <div className="card">
          <div className="card-header">
            <h5 className="modal-title">
              <i className="fas fa-pen mr-1" />
              Informasi Pertanyaan Uraian
            </h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col">
                <form>
                  {/* Input urutan pertanyaan */}
                  <div className="form-group mb-3 row">
                    <label
                      className="col-form-label col-sm-3"
                      htmlFor="nomor-pertanyaan"
                    >
                      <span>Nomor urut</span>
                      <span className="text-danger ml-1">*</span>
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="number"
                        min={1}
                        value={nomorUrut}
                        onChange={(event) => handleChangeUrutan(event)}
                        ref={refNomorUrut}
                        className={`${
                          isUrutanInvalid ? 'is-invalid' : ''
                        } form-control`}
                        id="nomor-pertanyaan"
                        placeholder="Nomor urut"
                      />
                    </div>
                    {isUrutanInvalid ? (
                      <div className="offset-sm-3 col-sm-9">
                        <span className="col small form-text text-danger">
                          Nomor urut tidak boleh kosong.
                        </span>
                      </div>
                    ) : null}
                  </div>

                  {/* Select status pertanyaan */}
                  <div className="form-group mb-3 row">
                    <label
                      className="col-form-label col-sm-3"
                      htmlFor="status-pertanyaan"
                    >
                      <span className="mr-1">Status</span>
                    </label>
                    <div className="form-group col-sm-9">
                      <div className="switch switch-primary d-inline m-r-10">
                        <input
                          onChange={(event) => handleChangeStatus(event)}
                          checked={status === 1}
                          type="checkbox"
                          id="switch-status-pertanyaan"
                        />
                        <label
                          htmlFor="switch-status-pertanyaan"
                          className="cr"
                        ></label>
                      </div>
                      <label htmlFor="switch-status-pertanyaan">
                        {status === 1 ? 'Aktif' : 'Tidak Aktif'}
                      </label>
                    </div>
                  </div>

                  {/* Form group question*/}
                  <div className="form-group mb-4 row">
                    <label className="col-form-label col-sm-3">
                      Pertanyaan<span className="text-danger ml-1">*</span>
                    </label>

                    <div className="col-sm-9">
                      {/* input question */}
                      <CKEditor
                        editor={Editor}
                        data={textPertanyaan}
                        config={minimalConfig}
                        onChange={(_, editor) => {
                          const data = editor.getData()
                          setTextPertanyaan(data)
                        }}
                      />
                      {isTextPertanyaanInvalid ? (
                        <span className="col small form-text text-danger">
                          Pertanyaan tidak boleh kosong.
                        </span>
                      ) : null}
                    </div>
                  </div>

                  {/* Form keterangan */}
                  <div className="form-group d-flex">
                    <label className="text-muted mr-2">Keterangan:</label>
                    <span className="text-danger mr-1">*</span>
                    <label className="text-muted">Wajib diisi</label>
                  </div>

                  {/* Form button */}
                  <div className="form-group mb-0">
                    <Back />
                    <Submit
                      onClick={handleFormValidation}
                      text="Simpan"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

export default AddUraianSurvey

import React from 'react'
import AnswerCardList from './answer-card-list'
import useMatchedAnswer from 'context/matched-answer'

export default function AnswerCardWrapper() {
  /* ====================================== Consume Context ===================================== */
  const { matched } = useMatchedAnswer()

  /* ========================================= Variables ======================================== */
  const isMatchExist = matched.length

  /* ========================================== Output ========================================== */
  return isMatchExist ? (
    <div className="card-groups">
      <AnswerCardList />
    </div>
  ) : (
    <p>Tidak Ada Data</p>
  )
}

import React from 'react'
import AnswerSurvey from './answer-survey'
import HtmlContent from 'component/html-content'
import './question.css'

const QuestionSurvey = ({
  question,
  handleChangePilihanGanda,
  handleChangeEssay,
  index,
  readOnly = false,
}) => {
  if (readOnly) {
    return (
      <li className="mb-5">
        <div className="d-flex">
          <span className="mr-2">
            {index + 1}
            {'.'}
          </span>
          <HtmlContent html={question.text} />
        </div>
        {question ? (
          <>
            {question.quest_type === 0 && (
              <ul className="list-unstyled ml-3">
                {question.survey_answers.length !== 0
                  ? question.survey_answers.map((answer) => (
                      <AnswerSurvey
                        key={answer.id}
                        answer={answer}
                        handleChangeAnswer={handleChangePilihanGanda}
                        surveyTemp={question.survey_temp}
                        readOnly={readOnly}
                      />
                    ))
                  : null}
              </ul>
            )}
            {question.quest_type === 1 && (
              <textarea
                disabled
                value={question.survey_temp.survey_answer_text}
                className="form-control"
                style={{ resize: 'none' }}
              />
            )}
          </>
        ) : null}
      </li>
    )
  } else {
    return (
      <li className="mb-5">
        <div className="d-flex">
          <span className="mr-2">
            {index + 1}
            {'.'}
          </span>
          <HtmlContent
            html={question.text}
            index={index}
          />
        </div>
        {question ? (
          <>
            {question.quest_type === 0 ? (
              <ul className="list-unstyled ml-3">
                {question.survey_answers.length !== 0
                  ? question.survey_answers.map((answer) => (
                      <AnswerSurvey
                        key={answer.id}
                        answer={answer}
                        handleChangeAnswer={handleChangePilihanGanda}
                      />
                    ))
                  : null}
              </ul>
            ) : null}
            {question.quest_type === 1 ? (
              <div className="form-group ml-3">
                <textarea
                  onBlur={(event) => handleChangeEssay(event, question)}
                  className="form-control"
                  placeholder="Tulis jawaban anda..."
                />
              </div>
            ) : null}
          </>
        ) : null}
      </li>
    )
  }
}

export default QuestionSurvey

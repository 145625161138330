import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import useContentPlayerContext from 'context/content-player'
import useMainContent from 'context/main-content'
import LoadingSpinner from 'component/loading'
import GraphorSort from './questions/GraphorsSort'
import GraphorGroup from './questions/GraphorsGroup'
import { GraphorDone } from './components/graphor-components'

/*
  Graphor type
  1: Sort
  2: Group
*/

export default function ContentGraphor({ isPreview = false, content, content_text, contents_url, content_source }) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest } = useBaseContext()
  const contentPlayerCtx = useContentPlayerContext()
  const mainContentCtx   = useMainContent()

  /* ========================================= Constants ======================================== */
  const usedContent = content || contentPlayerCtx?.content

  const using_contents_url = contents_url || usedContent?.contents_url

  const shouldSendCCA = mainContentCtx?.shouldSendCCA

  /* ======================================= Local States ======================================= */
  const [dg, setDG]                       = useState(null)
  const [answers, setAnswers]             = useState([])
  const [realAnswers, setRealAnswers]     = useState([])
  const [isLoading, setIsLoading]         = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [myAnswers, setMyAnswers]         = useState(null)

    /*Type Sort*/
  const [list, setList] = useState([])

    /*Type Group*/
  const [group, setGroup]         = useState([])
  const [listGroup, setListGroup] = useState(null)

    /*
    Activity Status: 
    checking / done / progress
  */
  const [activity, setActivity] = useState('')

  /* ========================================= Functions ======================================== */
  function shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

  function initTypeSort(array) {
    let dt = []
    let s = shuffle(array)
    s.map((val) => {
      dt.push({
        text: val,
      })
    })
    
    setAnswers(() => dt)
    const newList = dt.map((val) => val.text)
    setList(newList)
    setRealAnswers(array)
  }

  function initTypeGroup(column) {
    let groupName = []
    var list = []
    Object.keys(column).forEach((grup) => {
      if (column[grup].length > 0) {
        for (var a = 0; a < column[grup].length; a++) {
          list.push(column[grup][a])
        }
        column[grup] = []
      }
      if (grup != 'list') {
        groupName.push(grup)
      }
    })
    var randomList = shuffle(list)
    column['list'] = randomList
    setListGroup(column)
    setGroup(groupName)
  }

  async function getGraphor() {
    let response = await getRequest(
      'graphors?id=' + using_contents_url,
    )
    if (response) {
      setDG(response.data)
      if (response.data.graphor_type == 1) {
        initTypeSort(response.answer)
      } else if (response.data.graphor_type == 2) {
        initTypeGroup(response.column)
      }
      getAnswersUser(response.data.graphor_type)
    }
  }

  function checkIsDone(array = [], type) {
    let status = false
    if (type === 1) {
      if (array.length !== 0) {
        status = true
      } else {
        status = false
      }
    } else if (type === 2) {
      for (let item of array) {
        if (item.list.length !== 0) {
          status = true
          break
        }
      }
    }
    return status
  }

  async function getAnswersUser(graphorType) {
    const response = await getRequest(
      `siswa-graphor-hasil?content_id=${usedContent.id}&graphor_id=${using_contents_url}`,
    )
    if (response) {
      const status = checkIsDone(response.data, graphorType)
      setActivity(status ? 'done' : 'progress')
    }
  }

  function handleChangeGraphors(v) {
    setMyAnswers(v.l)
  }

  function GraphorQuestions() {
    if (dg !== null) {
      if (dg.graphor_type == 1 && answers.length > 0) {
        return (
          <GraphorSort
            questions={{ answers: { answers: answers } }}
            handleChangeGraphors={handleChangeGraphors}
            list={list}
            setList={setList}
          />
        )
      } else if (dg.graphor_type == 2) {
        return (
          <GraphorGroup
            questions={{ value_id: using_contents_url }}
            handleChangeGraphors={handleChangeGraphors}
            group={group}
            listGroup={listGroup}
            setListGroup={setListGroup}
          />
        )
      } else {
        return null
      }
    } else {
      return null
    }
  }

  async function completeActivities() {
    if (usedContent.feedback === null) {
      contentPlayerCtx?.activity.markSubmit()
      let form = new FormData()
      form.append('activity_id', contentPlayerCtx?.activity.id)
      form.append('content_id', usedContent.id)
      form.append('class_id', contentPlayerCtx?.kelas.id)
      form.append('is_complete', shouldSendCCA ? 1 : 0)
      const response = await putRequest(
        'class-content-activities',
        form,
      )
      shouldSendCCA && contentPlayerCtx?.activity.setIdDone(usedContent.id)
      setActivity('done')
    } else {
      shouldSendCCA && contentPlayerCtx?.activity.setMediaIdDone(usedContent.id)
      setActivity('done')
    }
  }

  async function handleSubmitAnswer() {
    var form = new FormData()
    form.append('content_id', usedContent.id)
    form.append('id_graphor', using_contents_url)
    form.append('answers', JSON.stringify(myAnswers))
    if (myAnswers != null) {
      Swal.fire({
        title: 'Apa kamu yakin?',
        text: `Kamu akan mengirim jawaban!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, kirim!',
        cancelButtonText: 'Periksa kembali',
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoadingSubmit(true)
          let response = await postRequest('siswa-graphor', form)
          if (response) {
            await completeActivities()
            window.notification('Jawaban', 'berhasil dikirim', 'success')
          }
        }
      })
    } else {
      setLoadingSubmit(false)
      window.notification(
        'Jawaban Kosong, ',
        'Harap isi jawaban dari graphic organizer',
        'warning',
      )
    }
  }

  async function init() {
    setMyAnswers(null)
    setActivity('checking')
    setLoadingSubmit(false)
    setIsLoading(true)
    await getGraphor()
    setIsLoading(false)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    !isPreview && shouldSendCCA && completeActivities()
  }, [shouldSendCCA])

  useEffect(() => {
    init()
  }, [usedContent.id])

  /* ========================================== Output ========================================== */
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {isPreview === false ? (
            <>
              {activity === 'checking' ? (
                <LoadingSpinner />
              ) : (
                <>
                  {activity === 'done' && (
                    <GraphorDone
                      graphor={dg}
                      content={usedContent}
                      answers={realAnswers}
                    />
                  )}
                  {activity === 'progress' && (
                    <>
                      <GraphorQuestions />
                      <div className="form-group row mt-3">
                        <div className="offset-md-3 offset-lg-4 col-md-6 col-lg-4">
                          {loadingSubmit ? (
                            <button
                              type="button"
                              className="btn btn-primary"
                              style={{ width: '100%' }}
                              disabled={true}
                            >
                              <span
                                className="spinner-border spinner-border-sm mr-1"
                                role="status"
                              />
                              <span>Kirim Jawaban</span>
                            </button>
                          ) : (
                            <button
                              onClick={handleSubmitAnswer}
                              type="button"
                              className="btn btn-primary"
                              style={{ width: '100%' }}
                            >
                              <i className="fas fa-check-circle mr-2" />
                              <span>Kirim Jawaban</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <GraphorQuestions />
          )}
        </>
      )}
    </>
  )
}

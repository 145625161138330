import React, { useState } from 'react'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import TableIntervalPredikat from 'component/rapor/table-interval-predikat'
import UserItem from './user-item'
import ModalReject from './modal-reject'

export default function RaporSuccess({
  result,
  category_id,
  group_id,
  semester_id,
  handleSearch = async (category_id, group_id) => {},
}) {
  /* ====================================== Consume Context ===================================== */
  const { putRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const reportGroupId = {
    keterampilan: 'cc60064e-0b84-4c2d-8afd-4bd9f703694a',
    pengetahuan: '5ebcf01d-9217-4578-a5c6-17f84b28c9ed',
  }
  const modalConfig = {
    pengetahuan: {
      id: 'modalRejectPengetahuan',
      title: 'Pengetahuan',
    },
    keterampilan: {
      id: 'modalRejectKeterampilan',
      title: 'Keterampilan',
    },
  }

  /* ======================================= Local States ======================================= */
  const [listChecklistPengetahuans, setListChecklistPengetahuans]             = useState([])
  const [checklistAllPengetahuan, setChecklistAllPengetahuan]                 = useState(false)
  const [listChecklistKeterampilans, setListChecklistKeterampilans]           = useState([])
  const [checklistAllKeterampilan, setChecklistAllKeterampilan]               = useState(false)
  const [loadingModalRejectPengetahuan, setLoadingModalRejectPengetahuan]     = useState('idle')  // idle / start / success / error
  const [listRejectPengetahuans, setListRejectPengetahuans]                   = useState([])
  const [loadingModalRejectKeterampilans, setLoadingModalRejectKeterampilans] = useState('idle')  // idle / start / success / error
  const [listRejectKeterampilans, setListRejectKeterampilans]                 = useState([])

  /* ========================================= Functions ======================================== */
  const handleChecklistAll = ({
    event,
    users = [],
    reportGroupId,
    categoryId,
    setListChecklist,
    setChecklistAll,
  }) => {
    const selected = users.map((user) => ({
      report_group_id: reportGroupId,
      user_id: user.user_id,
      ta_semester_code: user.ta_semester_code,
      category_id: categoryId,
    }))
    setListChecklist(event.target.checked ? selected : [])
    setChecklistAll(event.target.checked)
  }

  const handleChecklist = ({
    event,
    userId,
    taSemesterCode,
    currentListChecklist = [],
    setListChecklist,
    setChecklistAll,
    reportGroupId,
    categoryId,
  }) => {
    const checked = event.target.checked
    const list = currentListChecklist
    if (checked) {
      const newList = [
        ...list,
        {
          report_group_id: reportGroupId,
          user_id: userId,
          ta_semester_code: taSemesterCode,
          category_id: categoryId,
        },
      ]
      setListChecklist(newList)
      if (newList.length === result.users.length) setChecklistAll(true)
    } else {
      const newList = list.filter((listItem) => listItem.user_id !== userId)
      setListChecklist(newList)
      setChecklistAll(false)
    }
  }

  const handleApprove = (listApprove = []) => {
    Swal.fire({
      title: 'Setujui nilai?',
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Memproses nilai...',
          html: `<div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>`,
          allowOutsideClick: false,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
        })
        let form = new FormData()
        form.append('report_temps', JSON.stringify(listApprove))
        const response = await putRequest('approve-report-temp', form)
        if (response) {
          Swal.fire({
            title: 'Nilai berhasil disetujui',
            icon: 'success',
          })
        } else {
          Swal.close()
        }
        await handleSearch(category_id, group_id, semester_id)
      }
    })
  }

  const initModalReject = (
    listSelect,
    setListReject,
    setLoadingModalReject,
  ) => {
    setLoadingModalReject('start')
    setListReject(listSelect.map((item) => ({ ...item, approval_notes: '' })))
  }

  const closeModalReject = (setListReject, setLoadingModalReject) => {
    setLoadingModalReject('idle')
    setListReject([])
  }

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="row">
        <div className="col">
          <h2 className="f-24">{result?.category_title}</h2>
          <div className="text-secondary">
            <span>
              <i className="fas fa-graduation-cap mr-1" />
              {result?.semester} {result?.tahun_ajaran}
            </span>
            <span className="ml-3">
              <i className="fas fa-user-graduate mr-1" />
              {result?.users.length}
              {' siswa'}
            </span>
            {result?.group_title ? (
              <span className="ml-3">
                <i className="fas fa-user-friends mr-1" />
                Kelas: {result.group_title}
              </span>
            ) : null}
          </div>
          <hr />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h5>A. Tabel Interval Predikat</h5>
          <TableIntervalPredikat />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h5>B. Pengetahuan</h5>
          {listChecklistPengetahuans.length > 0 ? (
            <div className="w-100">
              <button
                className="btn btn-sm btn-primary mr-2"
                onClick={() => handleApprove(listChecklistPengetahuans)}
              >
                <i className="fas fa-check-circle mr-1" />
                Setuju
              </button>
              <button
                className="btn btn-sm btn-outline-danger"
                data-toggle="modal"
                data-target={`#${modalConfig.pengetahuan.id}`}
                onClick={() =>
                  initModalReject(
                    listChecklistPengetahuans,
                    setListRejectPengetahuans,
                    setLoadingModalRejectPengetahuan,
                  )
                }
              >
                <i className="fas fa-times-circle mr-1" />
                Tolak
              </button>
              <ModalReject
                id={modalConfig.pengetahuan.id}
                title={modalConfig.pengetahuan.title}
                listSelected={listChecklistPengetahuans}
                listRejects={listRejectPengetahuans}
                setListRejects={setListRejectPengetahuans}
                loadingModal={loadingModalRejectPengetahuan}
                setLoadingModalReject={setLoadingModalRejectPengetahuan}
                onClose={closeModalReject}
                category_id={category_id}
                semester_id={semester_id}
                group_id={group_id}
                handleSearch={handleSearch}
                report_group_id={reportGroupId.pengetahuan}
              />
            </div>
          ) : (
            <div className="w-100">
              <button
                className="btn btn-sm btn-primary mr-2"
                disabled={true}
              >
                <i className="fas fa-check-circle mr-1" />
                Setuju
              </button>
              <button
                className="btn btn-sm btn-outline-danger"
                disabled={true}
              >
                <i className="fas fa-times-circle mr-1" />
                Tolak
              </button>
            </div>
          )}
          <div className="table-responsive rounded mt-2">
            <table className="table table-bordered nowrap table-rekap-nilai table-fixed">
              <thead>
                <tr>
                  <th>
                    {result?.users?.length > 0 ? (
                      <input
                        type="checkbox"
                        className="form-control"
                        style={{ width: 16, height: 16, cursor: 'pointer' }}
                        checked={checklistAllPengetahuan}
                        onChange={(event) => {
                          handleChecklistAll({
                            event: event,
                            users: result.users,
                            reportGroupId: reportGroupId.pengetahuan,
                            categoryId: category_id,
                            setListChecklist: setListChecklistPengetahuans,
                            setChecklistAll: setChecklistAllPengetahuan,
                          })
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        className="form-control"
                        style={{ width: 16, height: 16 }}
                        disabled={true}
                      />
                    )}
                  </th>
                  <th colSpan={0}>No.</th>
                  <th>Nim</th>
                  <th>Nama</th>
                  <th>Status</th>
                  <th>Nilai</th>
                  <th>Predikat</th>
                  {result.headerPengetahuan.map((item, index) => {
                    return (
                      <th
                        key={`${index}`}
                        colSpan={item.colspan}
                      >
                        {item.type_score}
                      </th>
                    )
                  })}
                  <th>Deskripsi</th>
                </tr>
              </thead>
              <tbody>
                {result?.users?.length > 0 ? (
                  <>
                    {result.users.map((user, index) => (
                      <UserItem
                        key={`${index}-${user.user_id}-${user.categories_id}-pengetahuan`}
                        number={index + 1}
                        item={user}
                        nilai={user.nilai.allScoresPengetahuan}
                        categoryId={user.categories_id}
                        semester_id={semester_id}
                        reportGroupId={reportGroupId.pengetahuan}
                        handleChecklist={handleChecklist}
                        currentListChecklist={listChecklistPengetahuans}
                        setListChecklist={setListChecklistPengetahuans}
                        setChecklistAll={setChecklistAllPengetahuan}
                      />
                    ))}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      className="text-center"
                    >
                      Tidak ada siswa terdaftar di mata pelajaran
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h5>C. Keterampilan</h5>
          {listChecklistKeterampilans.length > 0 ? (
            <div className="w-100">
              <button
                className="btn btn-sm btn-primary mr-2"
                onClick={() => handleApprove(listChecklistKeterampilans)}
              >
                <i className="fas fa-check-circle mr-1" />
                Setuju
              </button>
              <button
                className="btn btn-sm btn-outline-danger"
                data-toggle="modal"
                data-target={`#${modalConfig.keterampilan.id}`}
                onClick={() =>
                  initModalReject(
                    listChecklistKeterampilans,
                    setListRejectKeterampilans,
                    setLoadingModalRejectKeterampilans,
                  )
                }
              >
                <i className="fas fa-times-circle mr-1" />
                Tolak
              </button>
              <ModalReject
                id={modalConfig.keterampilan.id}
                title={modalConfig.keterampilan.title}
                listSelected={listChecklistKeterampilans}
                listRejects={listRejectKeterampilans}
                setListRejects={setListRejectKeterampilans}
                loadingModal={loadingModalRejectKeterampilans}
                setLoadingModalReject={setLoadingModalRejectKeterampilans}
                onClose={closeModalReject}
                semester_id={semester_id}
                category_id={category_id}
                group_id={group_id}
                handleSearch={handleSearch}
                report_group_id={reportGroupId.keterampilan}
              />
            </div>
          ) : (
            <div className="w-100">
              <button
                className="btn btn-sm btn-primary mr-2"
                disabled={true}
              >
                <i className="fas fa-check-circle mr-1" />
                Setuju
              </button>
              <button
                className="btn btn-sm btn-outline-danger"
                disabled={true}
              >
                <i className="fas fa-times-circle mr-1" />
                Tolak
              </button>
            </div>
          )}
          <div className="table-responsive rounded mt-2">
            <table className="table table-bordered nowrap table-rekap-nilai table-fixed">
              <thead>
                <tr>
                  <th>
                    {result?.users?.length > 0 ? (
                      <input
                        type="checkbox"
                        className="form-control"
                        style={{ width: 16, height: 16, cursor: 'pointer' }}
                        checked={checklistAllKeterampilan}
                        onChange={(event) => {
                          handleChecklistAll({
                            event: event,
                            users: result.users,
                            reportGroupId: reportGroupId.keterampilan,
                            categoryId: category_id,
                            setListChecklist: setListChecklistKeterampilans,
                            setChecklistAll: setChecklistAllKeterampilan,
                          })
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        className="form-control"
                        style={{ width: 16, height: 16 }}
                        disabled={true}
                      />
                    )}
                  </th>
                  <th>No.</th>
                  <th>Nim</th>
                  <th>Nama</th>
                  <th>Status</th>
                  <th>Nilai</th>
                  <th>Predikat</th>
                  {result.headerKeterampilan.map((item, index) => {
                    return (
                      <th
                        key={`${index}`}
                        colSpan={item.colspan}
                      >
                        {item.type_score}
                      </th>
                    )
                  })}
                  <th>Deskripsi</th>
                </tr>
              </thead>
              <tbody>
                {result?.users?.length > 0 ? (
                  <>
                    {result.users.map((user, index) => (
                      <UserItem
                        key={`${index}-${user.user_id}-${user.categories_id}-keterampilan`}
                        number={index + 1}
                        item={user}
                        nilai={user.nilai.allScoresKeterampilan}
                        categoryId={user.categories_id}
                        semester_id={semester_id}
                        reportGroupId={reportGroupId.keterampilan}
                        handleChecklist={handleChecklist}
                        currentListChecklist={listChecklistKeterampilans}
                        setListChecklist={setListChecklistKeterampilans}
                        setChecklistAll={setChecklistAllKeterampilan}
                      />
                    ))}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      className="text-center"
                    >
                      Tidak ada siswa terdaftar di mata pelajaran
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

import React from 'react'

const FormSelectSection = ({
  sections,
  handleChangeSection,
  isSectionInvalid,
  existingSectionId,
  refSelectSection,
}) => (
  <div className="form-group mb-5 fill row">
    <label
      className="col-sm-3 col-form-label"
      htmlFor="sectionParent"
    >
      <span className="mr-1">Pilih Section</span>
    </label>
    <div className="col-sm-9">
      <select
        onChange={(event) => handleChangeSection(event)}
        ref={refSelectSection}
        className={`${isSectionInvalid ? 'is-invalid' : ''} form-control`}
        id="sectionParent"
        defaultValue={existingSectionId}
      >
        <option value={''}>-- Section --</option>
        {sections.length !== 0 ? (
          sections.map((section) => (
            <option
              key={section.id}
              value={section.id}
            >
              {section.title}
            </option>
          ))
        ) : (
          <option disabled={true}>Data section tidak ditemukan</option>
        )}
      </select>
      {isSectionInvalid ? (
        <label className="col small form-text text-danger">
          Harap pilih section.
        </label>
      ) : null}
    </div>
  </div>
)

export default FormSelectSection

import React from 'react'

export default function FloatingFeedbackAlertItem({ item }) {
  return (
    <a
      className="alert alert-light mb-2 p-1 d-flex flex-column"
      href={`/katalog-kelas/${item.id_sub_mata_pelajaran}`}
      title={item.content_title}
    >
      <p className="m-0 text-dark">{item.content_title}</p>
      <p className="m-0 text-secondary">{item.sub_mata_pelajaran}</p>
    </a>
  )
}

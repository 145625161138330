import React, { useState } from 'react'
import { useBaseContext } from 'context/base'
import DataTable from 'component/data-table'
import LoadingButton from './table-setting-roles/teacher-row/buttons/loading'

/* ======================================= Main Component ======================================= */
export default function DatatableInstructors({
  detailGroup,
  dataTeachers,
  totalRecordsTeacher,
  getDataTeacher,
  setSearch,
  setLimit,
  setOffset,
}) {
  /* ====================================== Consume Context ===================================== */
  const { postRequest, deleteRequest } = useBaseContext()

  /* ========================================= Functions ======================================== */
  async function removeFromGroup({ group_id, idGroupUser }) {
    const response = await deleteRequest(
      `group-users?id=${idGroupUser}`,
    )
    if (response) {
      window.notification('', 'Berhasil menghapus dari group', 'success')
      getDataTeacher(group_id)
    }
  }

  async function addToGroup({ group_id, user_id }) {
    var form = new FormData()
    form.append('group_id', group_id)
    form.append('user_id', user_id)
    let response = await postRequest('group-users', form)
    if (response) {
      window.notification('', 'Berhasil menambahkan ke group', 'success')
      getDataTeacher(group_id)
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <DataTable
      className={'table table-striped table-bordered nowrap'}
      data={dataTeachers}
      totalRows={totalRecordsTeacher}
      show={[10, 20, 30, 40, 50]}
      onChangeSearch={(val) => {
        setSearch(val)
      }}
      onChangeLimit={(val) => {
        setLimit(val)
      }}
      onChangePage={(val) => {
        setOffset(val)
      }}
      column={['No. Induk', 'Nama', 'Username', 'Actions']}
      renderItem={(item, i) => (
        <RenderItemTeacher
          item={item}
          key={i}
          addToGroup={addToGroup}
          removeFromGroup={removeFromGroup}
          detailGroup={detailGroup}
        />
      )}
    />
    )
}

const RenderItemTeacher = ({
  item,
  addToGroup,
  removeFromGroup,
  detailGroup,
}) => {
  const [loading, setLoading] = useState(false)

  /* ========================================== Output ========================================== */
  return (
    <tr>
      <td>{item.nim}</td>
      <td>{item.name}</td>
      <td>{item.username}</td>
      <td>
        {loading && <LoadingButton />}
        {!loading &&
          (item.id_group_teachers == null ? (
            <button
              onClick={() => {
                addToGroup({ group_id: detailGroup.idGroup, user_id: item.id })
                setLoading(true)
              }}
              title="Tambah ke group"
              type="button"
              className="btn btn-sm btn-success"
            >
              <i className="fas fa-plus" />
            </button>
          ) : (
            <button
              onClick={() => {
                removeFromGroup({
                  group_id: detailGroup.idGroup,
                  idGroupUser: item.id_group_teachers,
                })
                setLoading(true)
              }}
              title="hapus dari kelas"
              type="button"
              className="btn btn-sm btn-danger"
            >
              <i className="fas fa-minus" />
            </button>
          ))}
      </td>
    </tr>
  )
}

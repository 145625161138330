import React, { useEffect, useState } from 'react'

import { useBaseContext } from 'context/base'
import DataTable from 'component/data-table'
import TableUserItem from './table-user-item'

const TableUsers = ({
  loadingTypes,
  group_id,
  limit,
  setLimit,
  offset,
  setOffset,
  search,
  setSearch,
  setLoadingState,
  onShowModal,
}) => {
  /* ========================================= Constants ======================================== */
  const tableConfig = {
    headers: ['Nama', 'No. Induk', 'Username', 'Actions'],
    limits: [10, 20, 30, 40, 50],
    totalColumns: 4,
  }

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [users, setUsers] = useState([])
  const [totalRecord, setTotalRecord] = useState(0)

  /* ========================================= Functions ======================================== */
  async function getSiswaList({ group_id, limit, offset, search }) {
    const url = `report-siswa-list?group_id=${group_id}&limit=${limit}&offset=${offset}&search=${search}`
    const response = await getRequest(url)
    return response
      ? { users: response.data.users, totalRecord: response.data.totalRecord }
      : null
  }

  async function init({ group_id, limit, offset, search }) {
    setLoadingState(loadingTypes.start)
    const data = await getSiswaList({ group_id, limit, offset, search })
    setUsers(data === null ? [] : data.users)
    setTotalRecord(data === null ? 0 : data.totalRecord)
    setLoadingState(data === null ? loadingTypes.error : loadingTypes.success)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    init({
      group_id: group_id ? group_id : '',
      limit: limit ? limit : '',
      offset: offset ? offset : '',
      search: search ? search : '',
    })
  }, [group_id, limit, offset, search])

  /* ========================================== Output ========================================== */
  return (
    <div className="user-profile-list dt-responsive table-responsive mt-3">
      <DataTable
        className={'table table-striped table-bordered nowrap'}
        data={users}
        totalRows={totalRecord}
        show={tableConfig.limits}
        onChangeSearch={(val) => {
          setSearch(val)
        }}
        onChangeLimit={(val) => {
          setLimit(val)
        }}
        onChangePage={(val) => {
          setOffset(val)
        }}
        column={tableConfig.headers}
        renderItem={(item, index) => (
          <TableUserItem
            key={index}
            id={item.id}
            name={item.name}
            nim={item.nim}
            username={item.username}
            onShowModal={onShowModal}
          />
        )}
      />
    </div>
  )
}

export default TableUsers

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useBaseContext } from 'context/base'
import DraftInfo from 'pages/input-rapor/components/draft-info'
import { useStale } from 'util/use-stale'

function InputRaporAllType1({
  userId,
  reportGroupId,
  response,
  semesterType
}) {
  /* ========================================== Helpers ========================================= */
  const params = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [res, setRes] = useState(null)

  /* ======================================== Functions ======================================= */
  async function getValue() {
    let us = localStorage.getItem('user')
    let usj = JSON.parse(us)
    var rsp = await getRequest(
      `get-value-score-by-group?user_id=${userId}&group_id=${reportGroupId}&semester_code=${params.semester}`,
    )

    if (rsp) {
      var ss = {}
      if (rsp.data) {
        var d = rsp.data
        ss = {
          user_id: d.user_id,
          categories_id: d.categories_id,
          categories_type_id: d.categories_type_id,
          report_group_id: d.report_group_id,
          createdBy: d.createdBy,
          updatedBy: d.updatedBy,
          value: JSON.parse(d.value),
          ta_semester_code: d.ta_semester_code,
          profile_id: d.profile_id,
        }
        setRes(ss)
      } else {
        ss = {
          user_id: userId,
          categories_id: null,
          categories_type_id: null,
          report_group_id: reportGroupId,
          createdBy: usj.id,
          updatedBy: usj.id,
          value: [],
          ta_semester_code: params.semester,
          profile_id: usj.profile_id,
        }
        setRes(ss)
      }
      response(ss)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getValue()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th style={{ width: 200 }}>Dimensi</th>
            <th>Deskripsi</th>
          </tr>
        </thead>
        <tbody>
          {semesterType.length ?
            Array.from({ length: semesterType === 'odd' ? 3 : semesterType === 'even' ? 6 : 0 }).map((_, index) => (
              <tr key={`input-sikap-${index}`}>
                <td>
                  {res && <InputSikap initialState={res?.value?.[index]?.dimensi || ''} index={index} placeholder='Dimensi' inputType='dimensi' />}
                </td>
                <td>
                  {res && <InputSikap initialState={res?.value?.[index]?.deskripsi || ''} index={index} placeholder='Deskripsi' inputType='deskripsi' />}
                </td>
              </tr>
            )) : null}
        </tbody>
      </table>
    </div>
  )

  function InputSikap({ initialState, placeholder, index, inputType }) {
    const key = [
      'input_rapor_all_type1',
      params.semester,
      reportGroupId,
      userId,
      placeholder,
      index,
    ].join('_')

    const { loading, data, isDrafted, invalidate, stale } = useStale(
      initialState,
      key,
    )

    useEffect(() => {
      if (data) {
        const inputDimensiType = 'dimensi';
        const inputDeskripsiType = 'deskripsi';
        const existingItem = res.value?.[index];

        if (existingItem) {
          if (inputType === inputDimensiType) {
            existingItem.dimensi = data;
          } else if (inputType === inputDeskripsiType) {
            existingItem.deskripsi = data;
          }
        } else {
          const newItem = {
            dimensi: inputType === inputDimensiType ? data : '',
            deskripsi: inputType === inputDeskripsiType ? data : '',
          };
          res.value.push(newItem);
        }

        response(res);
      }
    }, [data, index, placeholder, res.value, response]);

    return (
      !loading && (
        <>
          {isDrafted && <DraftInfo onClick={invalidate} />}
          <textarea
            id={key}
            onChange={(event) => {
              stale(event)
            }}
            className={'form-control'}
            rows={4}
            defaultValue={data}
            placeholder={placeholder}
          />
        </>
      )
    )
  }
}

export default React.memo(
  InputRaporAllType1,
  (oldProps, newProps) =>
    oldProps.userId === newProps.userId &&
    oldProps.reportGroupId === newProps.reportGroupId,
)

import React from 'react'

const BackButton = (props) => {
  return (
    <button
      onClick={props.onClick}
      type="button"
      className="btn btn-outline-danger has-ripple mr-2"
    >
      {props.text}
    </button>
  )
}

export default BackButton

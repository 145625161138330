import React, { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

export default function GraphorsSort({
  questions,
  handleChangeGraphors,
  list,
  setList,
}) {
  /* ========================================= Constants ======================================== */
  const grid = 8

  /* ======================================= Local States ======================================= */
  const [randId, setRandId] = useState(makeid(5))

  /* ========================================= Functions ======================================== */
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    borderRadius: '5px',
    // borderStyle:'ridge',
    borderRadius: '10px',
    // change background colour if dragging
    background: isDragging ? 'lightyellow' : 'white',
    boxShadow: isDragging
      ? '1px 1px 8px rgba(200,200,200,0.5)'
      : '1px 1px 3px rgba(200,200,200,0.5)',
    border: isDragging ? 'none' : '1px solid #ddd',
    color: 'black',
    // styles we need to apply on draggables
    ...draggableStyle,
  })

  function makeid(length) {
    var result = ''
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    var source = result.source
    var destination = result.destination
    var l = reorder(list, source.index, destination.index)
    setRandId(makeid(5))
    setList(l)
    handleChangeGraphors({
      questions,
      l,
    })
  }

  return (
    <div className="row">
      <div className="col-md-12">
        {list.length !== 0 ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'list'}>
              {(provided, snapshot) => (
                <div                  
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <div>
                    {list.map((item, index) => {
                      return (
                        <Draggable
                          key={randId + makeid(10)}
                          draggableId={item}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}
                              onMouseEnter={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  'lightyellow')
                              }
                              onMouseLeave={(e) =>
                                (e.currentTarget.style.backgroundColor = '#fff')
                              }
                            >
                              <div>
                                <b>{item}</b>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : null}
      </div>
    </div>
  )
}

import React, { useEffect, useState, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'

import { useBaseContext } from 'context/base'
import { Container, Header } from 'component/template'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import BackButton from 'component/button/Back'
import SubmitButton from 'component/button/Submit'
import { appendScript } from 'util/global'
import { getBase64 } from 'util/base64'

const baseStyle = {
  flex: 1,
  height: '180px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
}

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

export default function UsersEdit() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const location = useLocation()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, putRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [allRoles, setRoles]              = useState([])
  const [roles, setUserRoles]             = useState([])
  const [academicYears, setAcademicYears] = useState([])
  const [name, setName]                   = useState(location.state.name || '')
  const [description, setDesc]            = useState(location.state.description || '')
  const [nim, setNim]                     = useState(location.state.nim || '')
  const [gender, setGender]               = useState(location.state.gender || '')
  const [birthdate, setBirth]             = useState(location.state.birthdate || '')
  const [locations, setLocation]          = useState(location.state.location || '')
  const [email, setEmail]                 = useState(location.state.email || '')
  const [phone, setPhone]                 = useState(location.state.phone || '')
  const [username, setUsername]           = useState(location.state.username || '')
  const [active, setActive]               = useState(location.state.active != null ? `${location.state.active}` : '')
  const [files, setFiles]                 = useState([])
  const [avatar, setAvatar]               = useState(null)
  const [rolesChecked, setRolesChecked]   = useState([])
  const [isRoleSiswa, setIsRoleSiswa]     = useState(false)
  const [taTahunCode, setTaTahunCode]     = useState(location.state.taTahunCode || '')
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [imageSignature, setImageSignature]     = useState(null)
  const [isImageTooLarge, setImageTooLarge]     = useState(false)

  // Validation States
  const [isvalidname, setValidName]         = useState(false)
  const [isvalidemail, setValidEmail]       = useState(false)
  const [isvalidrole, setValidRole]         = useState(false)
  const [isvalidbirth, setValidBirth]       = useState(false)
  const [isvalidusername, setValidUsername] = useState(false)

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/users', name: 'Users' }]

  /* ======================================== Components ======================================== */
  function Dropzone() {
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      accept: 'image/*',
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        )
        getBase64(acceptedFiles[0])
          .then((result) => {
            let avatarClass = result.split(',')
            setAvatar({ name: acceptedFiles[0].name, data: avatarClass[1] })
          })
          .catch((err) => {
            console.error(err)
          })
      },
    })

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isDragActive, isDragReject, isDragAccept],
    )

    const thumbs = files.map((file) => (
      <div
        style={thumb}
        key={file.name}
      >
        <div style={thumbInner}>
          <img
            src={file.preview}
            style={img}
            alt="Avatar"
          />
        </div>
      </div>
    ))
    useEffect(
      () => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file) => URL.revokeObjectURL(file.preview))
      },
      [files],
    )

    return (
      <section className="container">
        <label className="row pl-3">Avatar</label>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p className="mt-5">Drag or click to select file for update avatar</p>
        </div>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </section>
    )
  }

  function DropzoneTTD() {
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      accept: 'image/png',
      maxSize: 512000,
      multiple: false,
      onDrop: (acceptedFiles, fileRejections) => {
        if (acceptedFiles.length > 0) {
          getBase64(acceptedFiles[0])
            .then((result) => {
              setImageTooLarge(false)
              let avatarClass = result.split(',')
              setImageSignature({
                name: acceptedFiles[0].name,
                data: avatarClass[1],
              })
            })
            .catch((err) => {
              console.error(err)
            })
        }
        if (fileRejections.length > 0) {
          setImageTooLarge(true)
        }
      },
    })

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isDragActive, isDragReject, isDragAccept],
    )

    const Thumbs = () => {
      return (
        <div style={thumb}>
          <div style={thumbInner}>
            {imageSignature && (
              <img
                src={`data:image/jpeg;base64,${imageSignature.data}`}
                alt="preview"
                style={img}
              />
            )}
          </div>
        </div>
      )
    }

    return (
      <>
        <div className="col-sm-12 p-0">
          <div
            className="border rounded alert alert-warning w-100 d-flex row align-items-center m-0 mb-2"
            role="alert"
          >
            <i className="fas fa-info-circle mr-1" />
            <p className="m-0 ml-1">
              Ukuran gambar maksimal 512kb <br />
              Format file wajib .png <br />
              Background putih/transparant{' '}
            </p>
          </div>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p className="mt-5">Drag or click to select file</p>
          </div>
          <aside style={thumbsContainer}>
            <Thumbs />
          </aside>
        </div>
      </>
    )
  }

  /* ========================================= Functions ======================================== */
  async function getRoles() {
    let response = await getRequest('roles')
    if (response != null) {
      setRoles(response.data)
      initCheckedRoles(response.data)
    } else {
      setRoles(null)
    }
  }

  function initCheckedRoles(roles) {
    const existingRoles = location.state.role
    let rolesId = []
    const initRoles = roles.map((role) => {
      let initRole = {
        id: role.id,
        role_type: role.role_type,
        checked: false,
      }
      existingRoles.forEach((existing) => {
        if (existing.id === role.id) {
          rolesId.push(role.id)
          initRole.checked = true
        }
      })
      return initRole
    })
    setRolesChecked(initRoles)
    setUserRoles(rolesId)
  }

  async function getAcademicYears() {
    const response = await getRequest('academic-year')
    if (response) {
      if (response.data.length > 0) {
        const options = response.data.map((item) => {
          const startYear = `20${item.code[0]}${item.code[1]}`
          return {
            value: item.code,
            label: `${startYear}`,
          }
        })
        setAcademicYears(options)
      }
    }
  }

  function handleChangeRole(status, roleId) {
    let updateRoleChecked = []
    let updateRoles = []
    rolesChecked.forEach((item) => {
      if (roleId === item.id) {
        if (status) {
          updateRoleChecked.push({
            id: item.id,
            role_type: item.role_type,
            checked: true,
          })
          updateRoles.push(item.id)
        } else {
          updateRoleChecked.push({
            id: item.id,
            role_type: item.role_type,
            checked: false,
          })
        }
      } else {
        updateRoleChecked.push(item)
        if (item.checked === true) updateRoles.push(item.id)
      }
    })
    setRolesChecked(updateRoleChecked)
    setUserRoles(updateRoles)
  }

  function checkIfRoleIsSiswa() {
    if (rolesChecked.length > 0) {
      for (let role of rolesChecked) {
        if (role.role_type === 'siswa') {
          if (role.checked === true) {
            setIsRoleSiswa(true)
            break
          } else {
            setIsRoleSiswa(false)
            break
          }
        }
      }
    }
  }

  function handleChangeTahunCode(event) {
    setTaTahunCode(event.target.value)
  }

  function handleFormPhoneNumber(val) {
    var x = val.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/)
    setPhone(!x[2] ? x[1] : `${x[1]}-${x[2]}` + (x[3] ? '-' + x[3] : ''))
  }

  function handleChangeUsername(event) {
    setUsername(event.target.value.toLowerCase())
  }

  async function updateUser() {
    if (name && email && username && birthdate) {
      setLoadingSubmit(true)
      var form = new FormData()
      form.append('id', location.state.id)
      form.append('name', name)
      form.append('description', description)
      form.append('nim', nim)
      form.append('gender', gender)
      form.append('birthdate', birthdate)
      form.append('location', locations)
      form.append('role_id', JSON.stringify(roles))
      form.append('email', email)
      form.append('phone', phone)
      form.append('username', username)
      form.append('active', active)
      if (avatar) {
        form.append('avatar', JSON.stringify(avatar))
      }

      if (imageSignature) {
        form.append('signature', JSON.stringify(imageSignature))
      }

      let response = await putRequest('users', form)
      setLoadingSubmit(false)
      if (response) {
        if (response.message === '') {
          window.notification('User', 'berhasil disimpan', 'success')
          history.push('/users')
        }

        if (response.message === 'username sudah digunakan') {
          window.notification(
            'Username sudah digunakan,',
            'mohon masukan username berbeda',
            'warning',
          )
        } else if (response.message === 'nim sudah digunakan') {
          window.notification(
            'No. Induk sudah digunakan,',
            'mohon masukan No. Induk berbeda',
            'warning',
          )
        } else if (response.message === 'Gagal insert data') {
          window.notification(
            'Terjadi kesalahan',
            'Coba beberapa saat lagi',
            'error',
          )
        }
      }
    } else {
      if (!name) {
        setValidName(true)
      }
      if (!email) {
        setValidEmail(true)
      }
      if (!username) {
        setValidUsername(true)
      }
      if (!birthdate) {
        setValidBirth(true)
      }
      window.notification(
        'Data belum lengkap',
        'Mohon lengkapi data',
        'warning',
      )
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    appendScript('/admin/assets/js/plugins/jquery.validate.min.js')
    appendScript('/admin/assets/js/pages/form-validation.js')
    getRoles()
    getAcademicYears()
  }, [])

  useEffect(() => {
    checkIfRoleIsSiswa()
  }, [rolesChecked])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Edit User"
          links={links}
        />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Edit User</h5>
              </div>
              <div className="card-body">
                <form
                  id="validation-form123"
                  action="#!"
                >
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="mb-0">
                          <span>Nama</span>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <input
                          value={name}
                          onChange={(val) => setName(val.target.value)}
                          type="text"
                          className={`form-control ${
                            isvalidname ? 'is-invalid' : ''
                          }`}
                          placeholder="Enter name"
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-0">Description</label>
                        <textarea
                          value={description}
                          onChange={(val) => setDesc(val.target.value)}
                          rows={3}
                          className="form-control"
                          placeholder="Enter description"
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <label className="mb-0">
                          <span>No. Induk</span>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <input
                          value={nim}
                          onChange={(val) =>
                            // setNim(val.target.value.replace(/\D/g, ''))
                            setNim(val.target.value)
                          }
                          type="text"
                          className="form-control"
                          placeholder="Enter NIM"
                          maxLength={50}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="gender">Gender</label>
                        <select
                          value={gender}
                          onChange={(val) => setGender(val.target.value)}
                          className="form-control"
                          id="gender"
                        >
                          <option value="">Pilih gender ...</option>
                          <option value="L">Laki-laki</option>
                          <option value="P">Perempuan</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="mb-0">
                          <span>Tanggal Lahir</span>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <input
                          value={birthdate}
                          onChange={(val) => setBirth(val.target.value)}
                          type="date"
                          className={`form-control ${
                            isvalidbirth ? 'is-invalid' : ''
                          }`}
                          placeholder="Enter birthdate"
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-0">Alamat</label>
                        <textarea
                          value={locations}
                          onChange={(val) => setLocation(val.target.value)}
                          rows={3}
                          className="form-control"
                          placeholder="Enter detail address"
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <label className="mb-0">
                          <span>Email</span>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <input
                          value={email}
                          onChange={(val) => setEmail(val.target.value)}
                          type="email"
                          className={`form-control ${
                            isvalidemail ? 'is-invalid' : ''
                          }`}
                          placeholder="Example: example@mail.com"
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-0">Telepon</label>
                        <input
                          value={phone}
                          onChange={(val) =>
                            handleFormPhoneNumber(val.target.value)
                          }
                          type="text"
                          className="form-control"
                          placeholder="Example: +62 88800000"
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-0">
                          <span>Username</span>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <input
                          value={username}
                          onChange={handleChangeUsername}
                          type="text"
                          className={`form-control ${
                            isvalidusername ? 'is-invalid' : ''
                          }`}
                          placeholder="Enter username"
                        />
                      </div>
                      <fieldset className="form-group">
                        <div className="row">
                          <label
                            htmlFor="inputPassword3"
                            className="col-sm-3 col-form-label"
                          >
                            Status
                          </label>
                          <div className="col-sm-9">
                            <div className="form-check">
                              <input
                                checked={active === `true`}
                                onChange={(val) => setActive(val.target.value)}
                                className="form-check-input"
                                type="radio"
                                name="active"
                                id="active"
                                defaultValue="true"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="active"
                              >
                                Aktif
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                checked={active === `false`}
                                onChange={(val) => setActive(val.target.value)}
                                className="form-check-input"
                                type="radio"
                                name="active"
                                id="no-active"
                                defaultValue="false"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="no-active"
                              >
                                Tidak Aktif
                              </label>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-md-6">
                      <Dropzone />
                      {location?.state?.role?.find(role => role?.role_type === 'orangtua') ? (
                        <div className='container'>
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label font-weight-bolder">
                              Tanda Tangan Digital
                            </label>
                            <div className="col-sm-12">
                              <DropzoneTTD />
                              {isImageTooLarge ? (
                                <span className="col offset-md-3 small form-text text-danger">
                                  Ukuran gambar maksimal 512kb
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row ml-1">
                    <BackButton />
                    {loadingSubmit ? (
                      <button
                        disabled
                        type="button"
                        className="btn btn-success"
                      >
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                        />
                        Simpan
                      </button>
                    ) : (
                      <SubmitButton
                        onClick={() => updateUser()}
                        text="Simpan"
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

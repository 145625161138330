import React from 'react'
import useContentPlayerContext from 'context/content-player'
import ContentPlayerBreadcumb from './content-player-breadcumb'

export default function ContentPlayerHeader() {
  /* ====================================== Consume Context ===================================== */
  const { kelas } = useContentPlayerContext()

  /* ========================================== Output ========================================== */
  return (
    <div className="page-header">
      <div className="page-block">
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="page-header-title">
              <h5 className="m-b-10">{kelas.data ? kelas.data.title : '-'}</h5>
            </div>
            <ContentPlayerBreadcumb />
          </div>
        </div>
      </div>
    </div>
  )
}

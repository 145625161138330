import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Select from 'react-select'
import { useBaseContext } from 'context/base'

export default function OverviewGrafikGrupKecil() {
  /* ========================================= Helpers ======================================== */
  const { kelasId } = useParams()

  /* ====================================== Consume Context ===================================== */  
  const { getRequest } = useBaseContext()

  /* =================================== Consume Local Storage ================================== */
  const { ta_tahun_code } = JSON.parse(localStorage.getItem('user'))

  /* ======================================= Local States ======================================= */
  const [user, setUser]                   = useState([])
  const [categories, setCategories]       = useState([])
  const [series, setSeries]               = useState([])
  const [valueTypeUser, setValueTypeUser] = useState(null)

  /* ========================================= Functions ======================================== */
  async function getUser() {
    let optionsUser = []
    const response = await getRequest(
      'users?ta_tahun_code=' + ta_tahun_code,
    )
    if (response) {
      let dd = response.data
      if (dd.length >= 0) {
        dd.map((item) => {
          optionsUser.push({ label: item.name, value: item.id })
        })
      }
      setUser(optionsUser)
      setValueTypeUser(optionsUser[0])
    }
  }

  async function getGrafikGrupKecil() {
    if (valueTypeUser) {
      const response = await getRequest(
        `grafik-grup-kecil-guru?user_id=${valueTypeUser?.value}&class_id=${kelasId}`,
      )
      if (response) {
        setCategories(response.data.categories)
        setSeries(response.data.series)
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getUser()
  }, [])

  useEffect(() => {
    getGrafikGrupKecil()
  }, [valueTypeUser])

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="row">
        <label className="col-2 col-md-2 col-form-label">Siswa</label>
        <div className="col-3 col-md-4">
          <Select
            name="colors"
            options={user}
            className="basic-multi-select mt-2 "
            classNamePrefix="select"
            value={valueTypeUser}
            onChange={(v) => setValueTypeUser(v)}
            noOptionsMessage={() => 'Siswa tidak ditemukan'}
            placeholder={'Pilih siswa'}
          />
        </div>
      </div>
      <div className="dt-responsive table-responsive mt-2">
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: 'column',
            },
            title: {
              text: `Aktivitas Grup Kecil Siswa`,
            },
            subtitle: {
              text: '',
            },
            xAxis: {
              categories: categories,
              crosshair: true,
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
              },
            },
            tooltip: {
              headerFormat:
                '<span style="font-size:10px">{point.key}</span><table>',
              pointFormat:
                '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true,
            },
            plotOptions: {
              column: {
                pointPadding: 0.2,
                borderWidth: 0,
              },
            },
            series: series,
          }}
        />
      </div>
    </>
  )
}

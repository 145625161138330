import { useEffect, useReducer, useRef, useState } from "react"
import { useBaseContext } from "context/base"

/**
 * 
 * @param {any} state 
 * @param {{
 *  type: 'type' | 'academic-year' | 'class' | 'semester',
 *  value: string,
 * }} action 
 */
function reactSelectReducer(state, action) {
  const { type, value } = action

  switch (type) {
    case 'type':
      return {
        ...state,
        selectedType: value,
        selectedClass: null,
      }
    case 'academic-year':
      return {
        ...state,
        selectedAcademicYear: value,
      }
    case 'class':
      return {
        ...state,
        selectedClass: value,
      }
    case 'semester':
      return {
        ...state,
        selectedSemester: value
      }
    case 'all':
      return {
        ...state,
        ...value
      }
    default:
      throw Error('Unknown reactSelect action')
  }
}

export default function useOrderSubjectReportHeader() {
  /* --------------------------------- CONTEXT -------------------------------- */
  const { getRequest } = useBaseContext()

  const selectedAcademicYearRef = useRef()
  const selectedSemesterRef = useRef()
  const selectedClassRef = useRef()

  async function getActiveYear() {
    let active = localStorage.getItem('semester')
    if (active) {
      const json = JSON.parse(active)
      return json.ta_tahun_code
    }
  }

  /* ---------------------------------- STATE ---------------------------------- */
  const [optionsType, setOptionsType] = useState([
    {
      label: 'Semua Kelas',
      value: 'all',
    },
    {
      label: 'Kelas',
      value: 'class'
    }
  ])

  const [optionsAcademicYear, setOptionsAcademicYear] = useState([])

  const [optionsClass, setOptionsClass] = useState([])

  const [optionsSemester, setOptionsSemester] = useState([])

  const [reactSelectState, reactSelectDispatch] = useReducer(reactSelectReducer, {
    selectedType: optionsType?.[1],
    selectedAcademicYear: null,
    selectedClass: null,
    selectedSemester: null,
  })

  /* --------------------------------- HANDLER -------------------------------- */
  /**
   * Reset State Handler
   * 
   * @param {'academic-year' | 'class' | 'semester'} type 
   */
  const resetStateHandler = (type) => {
    switch (type) {
      case 'academic-year':
        setOptionsAcademicYear([])
        reactSelectDispatch({ type: 'academic-year', value: null })
        break;
      case 'class':
        setOptionsClass([])
        reactSelectDispatch({ type: 'class', value: null })
        break;
      case 'semester':
        setOptionsSemester([])
        reactSelectDispatch({ type: 'semester', value: null })
        break;
      default:
        throw Error('Unknown Type')
    }
  }

  async function getAcademicYearHandler() {
    const semesterActiveYearCode = await getActiveYear()

    const response = await getRequest(`academic-year`)
    if (response) {
      const manipulatedReactSelectData = response?.data?.map(item => {
        const data = {
          label: `${item?.description}${semesterActiveYearCode == item?.code ? ' (Aktif)' : ''}`,
          value: item?.code
        }

        if (semesterActiveYearCode == item?.code) {
          reactSelectDispatch({ type: 'academic-year', value: data })
        }

        return data
      })

      setOptionsAcademicYear(manipulatedReactSelectData)
    }
  }

  async function getClassHandler() {
    resetStateHandler('class')
    if (reactSelectState?.selectedType?.value === 'class' && reactSelectState?.selectedAcademicYear) {
      const response = await getRequest(`groups?ta_tahun_code=${reactSelectState?.selectedAcademicYear?.value}`)
      if (response) {
        const manipulatedReactSelectData = response?.data?.map(item => {
          const data = {
            label: item?.group,
            value: item?.id
          }
  
          return data
        })
  
        setOptionsClass(manipulatedReactSelectData)

        getSemesterHandler()
      }
    }
  }

  async function getSemesterHandler() {
    resetStateHandler('semester')
    if (reactSelectState?.selectedType?.value === 'class' && reactSelectState?.selectedAcademicYear) {
      const response = await getRequest(`semester?ta_tahun_code=${reactSelectState?.selectedAcademicYear?.value}`)
      if (response) {
        const manipulatedReactSelectData = response?.data?.map(item => {
          const isActiveLabel = item?.status ? ' (Aktif)' : ''
          const label = `${item?.tahun_mulai}/${item?.tahun_selesai} - ${item?.title_render}${isActiveLabel}`
          const data = {
            label,
            value: item?.code
          }
  
          if (item?.status) {
            reactSelectDispatch({ type: 'semester', value: data })
          }

          return data
        })
  
        setOptionsSemester(manipulatedReactSelectData)
      }
    }
  }

  useEffect(() => {
    getAcademicYearHandler()
  }, [])

  useEffect(() => {
    getClassHandler()
  }, [reactSelectState?.selectedType, reactSelectState?.selectedAcademicYear?.value])

  return {
    data: {
      optionsType,
      optionsClass,
      optionsSemester,
      optionsAcademicYear
    },
    customState: {
      reactSelectState,
    },
    customRef: {
      selectedAcademicYearRef,
      selectedSemesterRef,
      selectedClassRef,
    },
    customDispatch: {
      reactSelectDispatch,
    },
  }
}
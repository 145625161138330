import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/id'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import LoadingSpinner from 'component/loading'
import NotFound from 'component/not-found'
import BaseButton from 'component/button/BaseButton'
import Swal from 'sweetalert2'

export default function Notification() {
  /* =========================================== Refs =========================================== */
  let isMounted = useRef(true)

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [notifications, setnotifications] = useState([])
  const [loading, setLoading] = useState(false)
  const [isLoadingRemoveAllNotification, setIsLoadingRemoveAllNotification] = useState(false)
  const [offset, setOffset] = useState(0)

  /* ========================================= Constants ======================================== */
  const limit = 10

  /* ========================================= Functions ======================================== */
  const getNotifications = async (offset) => {
    setLoading(true)
    const response = await getRequest(
      `notification?limit=${limit}&offset=${offset}`,
    )
    setLoading(false)
    if (response) {
      if (isMounted.current === true)
        setnotifications([...notifications, ...response.data])
    }
  }

  const removeAllNotificationsHandler = async () => {
    Swal.fire({
      title: 'Konfirmasi',
      text: 'Apakah Anda Yakin Untuk Menghapus Semua Notifikasi ini?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus Semua!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoadingRemoveAllNotification(true)
        const response = await getRequest('notification-allclear')

        if (response) {
          setIsLoadingRemoveAllNotification(false);
          window.notification('Berhasil menghapus semua notifikasi!', '', 'success')
          getNotifications(offset);
        } else {
          setIsLoadingRemoveAllNotification(false)
        }
      }
    });
  }

  function atEnd() {
    var c = [
      document.scrollingElement.scrollHeight,
      document.body.scrollHeight,
      document.body.offsetHeight,
    ].sort(function (a, b) {
      return b - a
    }) // select longest candidate for scrollable length
    return window.innerHeight + window.scrollY + 2 >= c[0] // compare with scroll position + some give
  }

  async function checkScrollPosition() {
    if (atEnd()) {
      setOffset(10 + offset)
    }
  }

  async function setReadNotif(id) {
    await getRequest(`notification-read?id=${id}`)
  }

  /* ======================================== Components ======================================== */
  const RenderNotif = ({ item }) => {
    const [path, setPath] = useState('/')
    const [title, setTitle] = useState('')

    function handleConvertDateMoment(item) {
      return moment(item).format('DD MMM YYYY, HH-mm-ss')
    }

    function getNotifLinkObject(notif) {
      /*
        notif_code:
          *101 = added siswa to kelas
          *102 = removed siswa from kelas
          *103 = added sub mata pelajaran to kelas
          *104 = removed sub mata pelajaran from kelas
          *200 = invited to video conference
          *300 = new feedback assignment
          *600 = new forum discussion
          *701 = assignment accepted
          *702 = assignment rejected
          *800 = new chats
          *900 = Quiz
          *1000 = Rapor Nilai Submission
          *1001 = Rapor Nilai Reject
          *1100 = Rapor Wali Submission
          *1101 = Rapor Wali Reject
      */
      let path
      switch (notif.notif_code) {
        case '101':
          if (notif.role_id === 'administrator') path = '/group'
          if (notif.role_id === 'instruktur') path = '/group'
          if (notif.role_id === 'siswa') path = '/siswa-group'
          setPath(path ? path : '/')
          setTitle('Kelas')
          break
        case '102':
          if (notif.role_id === 'administrator') path = '/group'
          if (notif.role_id === 'instruktur') path = '/group'
          if (notif.role_id === 'siswa') path = '/siswa-group'
          setPath(path ? path : '/')
          setTitle('Kelas')
          break
        case '103':
          if (notif.role_id === 'administrator') path = '/group'
          if (notif.role_id === 'instruktur') path = '/group'
          if (notif.role_id === 'siswa') path = '/siswa-group'
          setPath(path ? path : '/')
          setTitle('Sub Mata Pelajaran')
          break
        case '104':
          if (notif.role_id === 'administrator') path = '/group'
          if (notif.role_id === 'instruktur') path = '/group'
          if (notif.role_id === 'siswa') path = '/siswa-group'
          setPath(path ? path : '/')
          setTitle('Sub Mata Pelajaran')
          break
        case '200':
          if (notif.role_id === 'administrator') path = '/video-conference'
          if (notif.role_id === 'instruktur') path = '/video-conference'
          if (notif.role_id === 'siswa') path = '/siswa-video-konferensi'
          setPath(path ? path : '/')
          setTitle('Video Conference')
          break
        case '300':
          if (notif.role_id === 'siswa')
            path = `/katalog-kelas/${notif.params ? notif.params.class_id : ''}`
          setPath(path ? path : '/')
          setTitle('Feedback Tugas')
          break
        case '600':
          path = notif.params ? `/forum/${notif.params.forum_id}` : '/forum'
          setPath(path ? path : '/')
          setTitle('Forum')
          break
        case '701':
          if (notif.role_id === 'administrator')
            path = `/kelas/${notif.params ? notif.params.class_id : ''}`
          if (notif.role_id === 'instruktur')
            path = `/kelas/${notif.params ? notif.params.class_id : ''}`
          if (notif.role_id === 'siswa')
            path = `/katalog-kelas/${notif.params ? notif.params.class_id : ''}`
          setPath(path ? path : '/')
          setTitle('Tugas')
          break
        case '702':
          if (notif.role_id === 'administrator')
            path = `/kelas/${notif.params ? notif.params.class_id : ''}`
          if (notif.role_id === 'instruktur')
            path = `/kelas/${notif.params ? notif.params.class_id : ''}`
          if (notif.role_id === 'siswa')
            path = `/katalog-kelas/${notif.params ? notif.params.class_id : ''}`
          setPath(path ? path : '/')
          setTitle('Tugas')
          break
        case '800':
          path = '/chats'
          setPath(path ? path : '/')
          setTitle('Chat')
          break
        case '900':
          if (notif.role_id === 'administrator')
            path = `/kelas/${notif.params ? notif.params.class_id : ''}`
          if (notif.role_id === 'instruktur')
            path = `/kelas/${notif.params ? notif.params.class_id : ''}`
          if (notif.role_id === 'siswa')
            path = `/katalog-kelas/${notif.params ? notif.params.class_id : ''}`
          setPath(path ? path : '/')
          setTitle('Kuis')
          break
        case '1000':
          path = `/rapor-approval?category_id=${notif.params?.category_id}&semester_id=${notif.params?.ta_semester_code}&group_id=${notif.params?.group_id}`
          setPath(path ? path : '/')
          setTitle('Rapor')
          break
        case '1001':
          path = `/input-rapor?category_id=${notif.params?.category_id}&semester_id=${notif.params?.ta_semester_code}&group_id=${notif.params?.group_id}`
          setPath(path ? path : '/')
          setTitle('Rapor')
          break
        case '1100':
          path = `/preview-rapor/${notif.params?.user_id}/${notif.params?.ta_semester_code}`
          setPath (path ? path : '/')
          setTitle('Rapor')
          break
        case '1101':
          path = `/input-rapor-all/${notif.params?.user_id}/${notif.params?.ta_semester_code}`
          setPath (path ? path : '/')
          setTitle('Rapor')
          break
        default:
          setPath('/')
          setTitle('Notifikasi')
          break
      }
    }

    useEffect(() => {
      getNotifLinkObject(item)
    }, [item])

    return (
      <div className="row p-b-30">
        <div className="col-auto text-right update-meta">
          <p
            className="text-muted m-b-0 d-inline-flex"
            style={{ width: '6rem' }}
          >
            {handleConvertDateMoment(item.createdAt)}
          </p>
          <i className="fas fa-bell bg-warning update-icon" />
        </div>
        <div className="col">
          <Link
            to={path}
            onClick={() => (item.read === false ? setReadNotif(item._id) : {})}
            className="btn btn-light text-left w-100"
          >
            <div className="mb-2 d-flex justify-content-between align-items-center">
              <h6 className="m-0">{title}</h6>
              {item.read === false ? (
                <span className="badge badge-danger">New</span>
              ) : null}
            </div>
            <p className="text-muted m-b-0">{item.notif_message}</p>
          </Link>
        </div>
      </div>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getNotifications(offset)
    window.addEventListener('scroll', checkScrollPosition)
    return () => {
      window.removeEventListener('scroll', checkScrollPosition)
    }
  }, [offset])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Notifikasi" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card latest-update-card">
              <div className="card-body px-md-5">
                <br />
                <br />
                <BaseButton onClick={removeAllNotificationsHandler} isShow={notifications.length > 0} isLoading={isLoadingRemoveAllNotification} variant='danger' props={{className: 'btn-sm my-3'}}>
                  <i className='feather icon-trash-2'></i> Hapus Semua Notifikasi
                </BaseButton>
                {
                  loading
                  ? <LoadingSpinner />
                  : notifications.length === 0 ? (
                    <NotFound
                      text="Tidak ada notifikasi"
                      icon="fas fa-bell"
                    />
                  ) : (
                    <div className="latest-update-box">
                      {notifications.map((item, idx) => (
                        <RenderNotif
                          item={item}
                          key={idx}
                        />
                      ))}
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

import { useBaseContext } from 'context/base'
import { useCallback, useEffect, useState } from 'react'

const useGetData = ({ getListSubMapel = false }) => {
  /* --------------------------------- HELPERS -------------------------------- */
  const { getRequest } = useBaseContext()

  /* --------------------------------- STATES --------------------------------- */
  const [subMapelData, setSubMapelData] = useState([])
  const [isLoadingSubMapelData, setIsLoadingListSubMapelData] = useState(false)

  /* -------------------------------- HANDLERS -------------------------------- */
  const getListSubMapelHandler = useCallback(async () => {
    setIsLoadingListSubMapelData(true)

    const response = await getRequest('list-class')

    if (response?.data?.length) {
      setIsLoadingListSubMapelData(false)
      setSubMapelData(response?.data)
    } else {
      setIsLoadingListSubMapelData(false)
    }
  }, []);

  useEffect(() => {
    if (getListSubMapel && !subMapelData.length) {
      getListSubMapelHandler()
    }
  }, [subMapelData])


  return {
    subMapel: {
      data: subMapelData,
      loading: isLoadingSubMapelData,
    }
  }
}

export default useGetData
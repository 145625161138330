import { useBaseContext } from "context/base"
import { useState } from "react"
import Swal from "sweetalert2"
import { createQueryUrl } from "util/string"

export function useGlobalGradeHandlerHook() {
  const { getRequest, putRequest, postRequest } = useBaseContext()

  const [detailData, setDetailData] = useState(null)
  const [isLoadingListGradesContent, setIsLoadingListGradesContent] = useState(false)

  /**
   * Get List Grades ContentHandler
   * 
   * @param {{
   * search?: string
   * limit: number
   * offset: number
   * kelasId: number
   * }} params
   */
  async function getListGradesContentHandler({ search, limit, offset, kelasId }) {
    setIsLoadingListGradesContent(true)

    const querySearch = createQueryUrl({ queryName: 'search', value: search })

    const response = await getRequest(
      `content-grade?class_id=${kelasId}&offset=${offset}&limit=${limit}${querySearch}`,
    )
    if (response) {
      /**
       * @type GradeItem
       */
      const data = response?.data
      setIsLoadingListGradesContent(false)

      if (Array.isArray(data) && data.length) {
        const optionsFilterGradeFormatData = data.map(d => ({
          label: d.name,
          value: d.id
        }))

        return {
          ...response,
          optionsFilterGradeFormatData
        }
      }
      return {
        ...response,
        optionsFilterGradeFormatData: []
      }
    } else {
      setIsLoadingListGradesContent(false)
    }
  }

  const getDetailGradeContentHandler = async ({ content_grade_id, content_id }) => {
    const response = await getRequest(`content-grade-contents-detail?content_grade_id=${content_grade_id}&content_id=${content_id}`)

    if (response) {
      return response
    }
  }

  /**
   * Toggle Show Modal Selected Student Handler
   * 
   * @param {boolean} condition 
   * @param {Object} selectedContent 
   */
  const getDetailDataHandler = async (gradeId) => {
    Swal.fire({
      title: 'Harap tunggu sebentar...',
      html: `<div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>`,
      allowOutsideClick: false,
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
    })

    const response = await getRequest(`content-grade-detail?content_grade_id=${gradeId}`)

    if (response) {
      setDetailData(response.data)
      Swal.close()
    } else {
      Swal.close()
    }
  }

  /**
  * Add Grade Handler
  * 
  * @param {{
  *  kelasId: string
  *  name: string
  *  onSuccessHandler?: () => void
  * }} payload 
  */
  const addGradeHandler = async (payload) => {
    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('class_id', payload.kelasId)

    Swal.fire({
      title: 'Harap tunggu sebentar...',
      html: `<div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>`,
      allowOutsideClick: false,
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
    })
    const response = await postRequest(`content-grade`, formData)

    if (response) {
      Swal.close()
      window.notification('Berhasil', 'Grade berhasil ditambahkan', 'success')
      if (payload?.onSuccessHandler && typeof payload?.onSuccessHandler === 'function') {
        payload.onSuccessHandler()
      }
    } else {
      Swal.close()
    }
  }

  /**
   * Update Grade Handler
   * 
   * @param {{
   *  id: string
   *  name: string
   *  onSuccessHandler?: () => void
   * }} payload 
   */
  const updateGradeHandler = async (payload) => {
    Swal.fire({
      title: 'Harap tunggu sebentar...',
      html: `<div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>`,
      allowOutsideClick: false,
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
    })

    const formData = new FormData()
    formData.append("id", payload.id)
    formData.append("name", payload.name)

    const response = await putRequest(`content-grade`, formData)

    if (response) {
      Swal.close()
      window.notification('Berhasil', 'Grade berhasil diubah', 'success')
      if (payload?.onSuccessHandler && typeof payload?.onSuccessHandler === 'function') {
        payload.onSuccessHandler()
      }
    } else {
      Swal.close()
    }
  }

  return {
    data: {
      detailData,
      isLoadingListGradesContent,
    },
    handler: {
      getDetailDataHandler,
      addGradeHandler,
      updateGradeHandler,
      getListGradesContentHandler,
      getDetailGradeContentHandler,
    }
  }
}
import { useBaseContext } from "context/base"
import useQuery from "hooks/useQuery"
import { useGlobalGradeHandlerHook } from "pages/kelas/overview-grade/hooks/useGlobalGradeHandlerHook"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import Swal from "sweetalert2"
import { CONTENT_TYPE } from "util/enum"
import { isGDrive, isSoundcloud } from "util/file"

/**
 * Object yang memetakan tipe konten ke array input form yang diperlukan.
 * 
 * @type {Object<string, Array<FormInputType>>}
 */
const listNecessaryFormInputByContentTypeId = {
  [CONTENT_TYPE.TEXT]: ['materi'],
  [CONTENT_TYPE.AUDIO]: ['audio'],
  [CONTENT_TYPE.VIDEO]: ['video'],
  [CONTENT_TYPE.DOCUMENT]: ['document'],
  [CONTENT_TYPE.IFRAME]: ['web-address'],
  [CONTENT_TYPE.QUIZZ]: ['quiz'],
  [CONTENT_TYPE.ASSIGNMENT]: ['tugas'],
  [CONTENT_TYPE.SURVEY]: ['survey'],
  [CONTENT_TYPE.GRAPHIC_ORGANIZER]: ['graphic-organizer'],
}

const getExt = (fileName) => fileName && `.${fileName?.split('.')?.pop()}`

/**
 * Use Grade List Selected Content Create
 * 
 * @param {{
 *  isInitNecessaryFormInputByContentTypeId: string
 * }} hookParams 
 */
export function useGradeListSelectedContentCreate(hookParams) {
  const { isInitNecessaryFormInputByContentTypeId = true} = hookParams || {}

  const { handler } = useGlobalGradeHandlerHook()
  const { putRequest } = useBaseContext()
  const params = useParams()
  const history = useHistory()
  const query = useQuery()
  const gradeName = query.get('grade-name')
  const contentId = params?.contentId
  const gradeId = params?.gradeId

  /**
   * @type {[Array<FormInputType>, import("react").Dispatch<import("react").SetStateAction<FormInputType[]>>]}
   */
  const [necessaryFormInputByContentTypeId, setNecessaryFormInputByContentTypeId] = useState([])

  /**
   * @type {[GradeItem, import("react").Dispatch<import("react").SetStateAction<GradeItem[]>>]}
   */
  const [detailGrade, setDetailGrade] = useState(null)

  const [detailGradeContent, setDetailGradeContent] = useState(null)

  const links = [
    { url: '/kelas', name: 'Sub Mata Pelajaran' },
    { url: `/kelas/${params?.kelasId}#grade`, name: 'Grade' },
    { url: `/kelas/${params?.kelasId}/grade/${gradeId}/list-content?grade-name=${gradeName}`, name: 'Daftar Materi' },
  ]

  /**
   * Get Default Value By Form Input Type Edit
   * 
   * @param {{
   *  type: FormInputType
   * }} params 
   */
  const getDefaultValueByFormInputTypeEditHandler = ({ type }) => {
    switch (type) {
      case 'materi':
        return detailGradeContent?.content_text || undefined
      case 'audio':
      case 'video':
      case 'document':
        const content_source = detailGradeContent?.contents_url?.source === 'internal' ? '0' : '1'
        const data = {
          content_source,
          link: '',
          file: null,
          isChanged: false,
        }

        if (content_source === '0') {
          data.file = {
            name: `${detailGradeContent?.id}_${detailGrade?.id}${getExt(detailGradeContent?.contents_url?.data)}`,
          }
        } else if (content_source === '1') {
          data.link = detailGradeContent?.contents_url?.url
        }
        return data
      case 'web-address':
        return detailGradeContent?.contents_url || undefined
      default:
        return undefined
    }
  }

  /**
   * Get Default Value By Form Input Type Create
   * 
   * @param {{
   *  type: FormInputType
   * }} params 
   */
  const getDefaultValueByFormInputTypeCreateHandler = ({ type }) => {
    switch (type) {
      case 'materi':
      case 'web-address':
      case 'quiz':
        return undefined
      case 'audio':
      case 'video':
      case 'document':
        return {
          content_source: '1',
          isChanged: false,
        }
      default:
        return undefined
    }
  }

  /**
   * Get Detail Grade Content Handler
   * 
   * @param {{
   *  gradeId: string
   *  contentId: string
   * }} params 
   */
  const getDetailGradeContentHandler = async ({ gradeId, contentId }) => {
    Swal.fire({
      title: 'Loading',
      text: 'Harap Tunggu Sebentar...',
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {
        Swal.hideLoading()
      }
    })
    const response = await handler.getDetailGradeContentHandler({ content_grade_id: gradeId, content_id: contentId })

    if (response) {
      Swal.close()
      const data = response?.data
      setDetailGrade(response?.content_grade)
      setDetailGradeContent(data)

      if (data?.content_type_id && listNecessaryFormInputByContentTypeId?.[data.content_type_id]) {
        setNecessaryFormInputByContentTypeId(listNecessaryFormInputByContentTypeId[data.content_type_id])
      }
    } else {
      Swal.close()
    }
  }

  const onBackHandler = () => {
    history.replace(`/kelas/${params?.kelasId}/grade/${gradeId}/list-content?grade-name=${gradeName}`)
  }

  /**
   * Submit Handler
   * 
   * @param {Object<string, any>} data
   * @param {{
   *  contentId?: string
   *  grade?: GradeItem
   *  onSuccessHandler?: (response: any) => void
   * } | import("react").SyntheticEvent} otherParam
   */
  const onSubmitHandler = async (data, otherParam) => {
    Swal.fire({
      title: 'Kamu yakin?',
      html: `Kamu ingin membuat konten ke dalam <strong>Grade ${otherParam.grade?.name || detailGrade.name}</strong>?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Ya!`,
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Loading',
          text: 'Harap Tunggu Sebentar...',
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
            Swal.hideLoading()
          }
        })

        const formData = new FormData()
        formData.append('class_id', params?.kelasId)
        formData.append('content_grade_id', otherParam.grade?.id || detailGrade.id)
        formData.append('content_id', otherParam?.contentId || params?.contentId)

        if (Array.isArray(necessaryFormInputByContentTypeId) && necessaryFormInputByContentTypeId.length) {
          necessaryFormInputByContentTypeId.forEach(type => {
            const internalSource = '0'
            const umumSource = '1'
  
            const value = data?.[type]
  
            switch (type) {
              case 'grade':
                formData.append('content_grade_id', value?.value)
                break
              case 'materi':
                formData.append('content_text', value)
                break
              case 'video':
              case 'audio':
              case 'document':
                const source = isGDrive(value?.link) ? 'gdrive' : isSoundcloud(value?.link) ? 'soundcloud' : 'umum'
  
                if (value?.isChanged) {
                  if (value?.content_source == internalSource) {
                    const { id } = JSON.parse(localStorage.getItem('user'))
                    const profile_id = JSON.parse(localStorage.getItem('profile')).id
                    formData.append('path', `upload/${profile_id}/classes/contents`)
                    formData.append('temp_name', `file-temp-${id}-${Date.now()}${getExt(value?.file?.name)}`)
                    formData.append('file_upload', value?.file)
                    formData.append('contents_url', JSON.stringify({
                      name: value?.file?.name, data: 'internal', source: 'internal'
                    }))
                  } else if (value?.content_source == umumSource) {
                    formData.append('contents_url', JSON.stringify({
                      name: null, data: value?.link, source
                    }))
                  }
                }
                break
              case 'web-address':
                formData.append('contents_url', value)
                break
              case 'tugas':
              case 'graphic-organizer':
                formData.append('contents_url', value?.value)
                break
              case 'survey':
              case 'quiz':
                formData.append('contents_url', JSON.stringify({
                  [`${type}_id`]: value?.value
                }))
                break
            }
          })
        }

        const response = await putRequest(`content-grade-contents`, formData)
    
        if (response) {
          window.notification('Berhasil', `Membuat konten ke dalam Grade ${otherParam.grade?.name || detailGrade.name}!`, 'success')
          Swal.close()
          onBackHandler()

          if (otherParam?.onSuccessHandler && typeof otherParam?.onSuccessHandler === 'function') {
            otherParam?.onSuccessHandler(response)
          }
        } else {
          Swal.close()
        }
      }
    })
  }

  useEffect(() => {
    if (isInitNecessaryFormInputByContentTypeId) {
      getDetailGradeContentHandler({
        gradeId,
        contentId,
      })
    }
  }, [isInitNecessaryFormInputByContentTypeId])

  return {
    data: {
      detailGrade,
      detailGradeContent,
      links,
      necessaryFormInputByContentTypeId,
      gradeName,
    },
    set: {
      setDetailGrade,
      setDetailGradeContent,
      setNecessaryFormInputByContentTypeId,
    },
    handler: {
      getDetailGradeContentHandler,
      getDefaultValueByFormInputTypeEditHandler,
      getDefaultValueByFormInputTypeCreateHandler,
      onBackHandler,
      onSubmitHandler,
    }
  }
}
import { useState } from 'react'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import SaWarning from 'component/icons/sweet-alert/SaWarning'
import { editorConfigurationWithoutImage } from 'util/ckeditor-config'
import BaseReactBootstrapModal from '../BaseReactBootstrapModal'


/**
 * REJECT MODAL
 * 
 * @param {{
 *  title: string,
 *  placeholder: string,
 *  onReject: function,
 *  item: object,
 *  show: boolean,
 *  handleClose: function,
 *  isWithCloseButton: boolean,
 *  isWithXButton: boolean,
 *  isWithHeader: boolean,
 * }} props
 */
const RejectModalWithRBModalComponent = ({ title, placeholder = 'Catatan untuk siswa...', onReject = () => {}, item = {}, show = false, handleClose = () => {}, isWithCloseButton = true, isWithXButton = true, isWithHeader = true }) => {
  const [inputValue, setInputValue] = useState('');

  /* --------------------------------- HANDLER -------------------------------- */
  const rejectHandler = () => {
    onReject({...item, inputValue});
    setInputValue('');
  }

  /* --------------------------------- RENDER --------------------------------- */
  const renderFooter = () => {
    return <div className='mt-4 d-flex justify-content-center' style={{ gap: 10 }}>
    <button className='btn btn-danger rounded' onClick={() => {
      rejectHandler()
      handleClose()
    }}>Ya, Tolak!</button>
    <button className='btn btn-secondary rounded' onClick={handleClose}>Tidak</button>
    </div>
  }

  return (
    <BaseReactBootstrapModal
      enforceFocus={false}
      size="md"
      show={show}
      handleClose={handleClose}
      isWithCloseButton={isWithCloseButton}
      isWithXButton={isWithXButton}
      isWithHeader={isWithHeader}
    >
      <div
        className="hd-body pr-2"
        style={{
          maxHeight: '450px',
          overflowY: 'auto',
        }}
      >
        <div className='d-flex justify-content-center'>
          <SaWarning />
        </div>

        <h3 className='text-center mb-4'>Tolak {title} {item?.name}?</h3>

        <CKEditor
          editor={Editor}
          config={{...editorConfigurationWithoutImage, placeholder}}
          data={inputValue}
          onChange={(_, editor) => {
            const data = editor.getData()
            setInputValue(data);
          }}
        />

        {renderFooter()}
      </div>
    </BaseReactBootstrapModal>
  );
}

export default RejectModalWithRBModalComponent
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { useBaseContext } from 'context/base'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { editorConfigurationWithoutImage } from 'util/ckeditor-config'
import { CKEditor } from '@ckeditor/ckeditor5-react'

export default function FormPenilaian({
  assignmentUser,
  getAssignmentUsers,
  handleCloseModal,
}) {
  /* ====================================== Consume Context ===================================== */
  const { putRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [score, setScore] = useState('')
  const [note, setNote] = useState('')
  const [loading, setLoading] = useState(false)

  /* ========================================= Functions ======================================== */
  function handleChangeScore(val) {
    if (val >= 0 && val <= 100) {
      setScore(val)
    }
  }

  async function handleSubmit() {
    if (+score < 0) {
      window.notification('Nilai', 'tidak boleh kurang dari 0', 'warning')
      return
    } else if (+score > 100) {
      window.notification('Nilai', 'tidak boleh lebih dari 100', 'warning')
      return
    }

    Swal.fire({
      title: '<h4>Apa data yang Anda masukkan sudah benar?</h4>',
      html: `
        <p class="f-16 f-w-500">Nilai: <span class="text-dark f-w-700">${score}</span><p>
        ${note
          ? `<p class="f-16 f-w-500">Catatan: <span class="text-dark f-w-300">${note}</span><p>`
          : ''
        }
      `,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, sudah!',
      cancelButtonText: 'Periksa kembali',
      customClass: {
        content: 'd-flex'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        let form = new FormData()
        form.append('user_id', assignmentUser.user_id)
        form.append('content_id', assignmentUser.content_id)
        form.append('score', Math.abs(score))
        form.append('note', note)
        setLoading(true)
        const response = await putRequest(
          'content-score-detail-siswa',
          form,
        )
        setLoading(false)
        if (response) {
          window.$('#tugasKomentar').modal('hide')
          handleCloseModal()
          await getAssignmentUsers()
          window.notification('Nilai', 'diterima!', 'success')
        }
      }
    })
  }

  /* ========================================== Output ========================================== */
  return (
    <form className="col-12 border bg-light rounded py-4 mb-4">
      <div className="form-group mb-4">
        <h4 className="text-center">Form Nilai</h4>
      </div>
      <div className="form-group row mb-4">
        <label className="col-form-label col-4 offset-lg-2 col-lg-2">
          Catatan
        </label>
        <div className="col-8 col-lg-6 mt-1">
          <CKEditor
            editor={Editor}
            config={{ ...editorConfigurationWithoutImage, placeholder: 'Catatan untuk siswa (opsional)' }}
            data={note}
            onChange={(_, editor) => {
              const data = editor.getData()
              setNote(data)
            }}
          />
        </div>
      </div>
      <div className="form-group row mb-5">
        <label className="col-form-label col-4 offset-lg-2 col-lg-2">
          Nilai
        </label>
        <div className="col-8 col-lg-3">
          <input
            value={score}
            onChange={(event) => handleChangeScore(event.target.value)}
            type="number"
            min="0"
            max="100"
            className="form-control bg-light"
            placeholder="0 - 100"
          />
        </div>
      </div>
      <div className="form-group d-flex justify-content-center">
        {loading ? (
          <button
            type="button"
            className="btn btn-forbidden btn-sm btn-success"
            disabled
          >
            <span
              className="spinner-border spinner-border-sm mr-1"
              role="status"
            />
            <span>Simpan</span>
          </button>
        ) : (
          <button
            onClick={handleSubmit}
            type="button"
            disabled={!score}
            className={`${score ? '' : 'btn-forbidden'} btn btn-sm btn-success`}
          >
            <span>Simpan</span>
          </button>
        )}
      </div>
    </form>
  )
}

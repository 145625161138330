export default {
  header: {
    height: '320px',
    backgroundAttachment: 'cover',
  },
  avatar: {
    height: '222px',
    width: '222px',
    position: 'absolute',
    zIndex: '1',
    top: '120px',
    left: '150px',
  },
  avatarInner: {
    position: 'relative',
    overflow: 'hidden',
    paddingBottom: '100%',
    // border: '2px solid lightgray',
    borderRadius: '50%',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    width: '100%',
  },
  name: {
    maxHeight: '30%',
    maxWidth: '30%',
    position: 'absolute',
    zIndex: '1',
    top: '210px',
    right: '150px',
  },
  card: { height: '140px' },
}

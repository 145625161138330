import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useBaseContext } from 'context/base'

export default function GroupDetailInfo({ idGroup }) {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, putRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [group, setGroup]         = useState('')
  const [status, setStatus]       = useState('1')
  const [classType, setClassType] = useState('')

  // is invalid forms
  const [isInvalidGroup, setIsInvalidGroup]   = useState(false)
  const [isInvalidStatus, setIsInvalidStatus] = useState(false)
  const [isInvalidType, setIsInvalidType]     = useState(false)
  
  const [year, setYear]                 = useState('')
  const [academicYear, setAcademicYear] = useState([])
  const [activeYear, setActiveYear]     = useState({})

  /* =========================================== Refs =========================================== */
  const refFormGroup = useRef()
  const refFormStatus = useRef()
  const refFormType = useRef()

  /* ========================================= Functions ======================================== */
  async function getDetailData() {
    let response = await getRequest(`groups?id=${idGroup}`)
    if (response) {
      let data = response.data
      if (data != null) {
        setClassType(data.class_type)
        setGroup(data.group)
        setStatus(data.active ? '1' : '0')
        setYear(data.ta_tahun_code)
        if (data.active) {
          window.$('#inputState').val('1')
        } else {
          window.$('#inputState').val('0')
        }
        if (data.class_type == 'regular') {
          window.$('#inputType').val('regular')
        } else if (data.class_type == 'paket') {
          window.$('#inputType').val('paket')
        }
      }
    }
  }

  async function getAcademicYear() {
    let response = await getRequest(`academic-year`)
    if (response) {
      setAcademicYear(response.data)
    }
  }

  async function getActiveYear() {
    let active = localStorage.getItem('semester')
    if (active) {
      var json = JSON.parse(active)
      setActiveYear(json.ta_tahun_code)
      setYear(json.ta_tahun_code)
    }
  }

  async function saveGroup() {
    if (group && status && classType) {
      setIsInvalidGroup(false)
      setIsInvalidStatus(false)
      let form = new FormData()
      form.append('group', group)
      form.append('active', status)
      form.append('id', idGroup)
      form.append('ta_tahun_code', year)
      form.append('class_type', classType)
      localStorage.setItem('group_ta_tahun_code', year)
      let response = await putRequest('groups', form)
      if (response) {
        if (response.data.length > 0) {
          window.notification('Data kelas', 'berhasil disimpan', 'success')
        }
      }
    } else {
      if (!status) {
        setIsInvalidStatus(true)
        refFormStatus.current.focus()
      } else {
        setIsInvalidStatus(false)
      }

      if (!group) {
        setIsInvalidGroup(true)
        refFormGroup.current.focus()
      } else {
        setIsInvalidGroup(false)
      }

      if (!classType) {
        setIsInvalidType(true)
        refFormType.current.focus()
      } else {
        setIsInvalidType(false)
      }

      window.notification('Mohon', 'lengkapi data', 'warning')
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getDetailData()
    getAcademicYear()
    getActiveYear()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <>
      <form
        id="validation-form123"
        action="#!"
      >
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="form-group">
                  <label className="form-label">Nama Kelas</label>
                  <input
                    ref={refFormGroup}
                    value={group}
                    onChange={(val) => setGroup(val.target.value)}
                    className={
                      isInvalidGroup
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    type="text"
                    name="role"
                    placeholder="Nama Kelas"
                    required={true}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Tahun Ajaran</label>
                  <select
                    onChange={(val) => setYear(val.target.value)}
                    defaultValue={year}
                    id="tacode"
                    className="form-control"
                  >
                    <option value="">- Pilih Tahun Ajaran -</option>
                    {academicYear.map((val, i) => {
                      return (
                        <option
                          key={val.code}
                          selected={val.code == year}
                          value={val.code}
                        >
                          {val.description}{' '}
                          {activeYear == val.code ? '(Aktif)' : null}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label className="form-label">Status</label>
                  <select
                    ref={refFormStatus}
                    onChange={(val) => setStatus(val.target.value)}
                    id="inputState"
                    className={
                      isInvalidStatus
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                  >
                    <option value={'1'}>Aktif</option>
                    <option value={'0'}>Tidak Aktif</option>
                  </select>
                </div>
                <div className="form-group">
                  <label className="form-label">Tipe Kelas</label>
                  <select
                    ref={refFormType}
                    onChange={(val) => setClassType(val.target.value)}
                    id="inputType"
                    className={
                      isInvalidType ? 'form-control is-invalid' : 'form-control'
                    }
                  >
                    <option value={''}>- Pilih tipe kelas -</option>
                    <option value={'regular'}>Regular</option>
                    <option value={'paket'}>Paket</option>
                  </select>
                </div>
                <button
                  type="button"
                  onClick={() => history.goBack()}
                  className="btn btn-outline-danger has-ripple"
                >
                  Kembali
                  <span
                    className="ripple ripple-animate"
                    style={{
                      height: '87.8281px',
                      width: '87.8281px',
                      animationDuration: '0.7s',
                      animationTimingFunction: 'linear',
                      background: 'rgb(255, 255, 255)',
                      opacity: '0.4',
                      top: '-26.9062px',
                      left: '-13.3125px',
                    }}
                  />
                </button>
                &nbsp;&nbsp;&nbsp;
                <button
                  type="button"
                  onClick={() => saveGroup()}
                  className="btn btn-success has-ripple"
                >
                  Simpan
                  <span
                    className="ripple ripple-animate"
                    style={{
                      height: '90.8281px',
                      width: '90.8281px',
                      animationDuration: '0.7s',
                      animationTimingFunction: 'linear',
                      background: 'rgb(255, 255, 255)',
                      opacity: '0.4',
                      top: '-26.4062px',
                      left: '0.65625px',
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

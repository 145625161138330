import React, { useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import ReactWordcloud from 'react-wordcloud'

import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'

const words = [
  {
    text: 'lieur',
    value: 64,
  },
  {
    text: 'hese pisan',
    value: 11,
  },
  {
    text: 'patuh',
    value: 16,
  },
  {
    text: 'teungarti',
    value: 17,
  },
  {
    text: 'Gampang',
    value: 64,
  },
  {
    text: 'Susah',
    value: 11,
  },
  {
    text: 'hese',
    value: 16,
  },
  {
    text: 'g ngerti',
    value: 17,
  },
]

const binatang = ['Kucing', 'Sapi', 'Ular', 'Kangguru', 'Kelinci', 'Ayam']

export default function WordCloud() {
  /* ========================================= Functions ======================================== */
  function table() {
    window
      .$('#table1 .childgrid tr, #table2 .childgrid tr,#table3 .childgrid tr')
      .draggable({
        helper: function () {
          var selected = window.$('.childgrid tr.selectedRow')
          if (selected.length === 0) {
            selected = window.$(this).addClass('selectedRow')
          }
          var container = window.$('<div/>').attr('id', 'draggingContainer')
          container.append(selected.clone().removeClass('selectedRow'))
          return container
        },
      })

    window
      .$('#table1 .childgrid, #table2 .childgrid,#table3 .childgrid')
      .droppable({
        drop: function (event, ui) {
          window.$(this).append(ui.helper.children())
          window.$('.selectedRow').remove()
        },
      })

    window.$(document).on('click', '.childgrid tr', function () {
      window.$(this).toggleClass('selectedRow')
    })
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    table()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Word Cloud" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Word Cloud</h5>
              </div>
              <div className="card-body">
                <ReactWordcloud words={words} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Drag N Drop</h5>
              </div>
              <div className="card-body">
                <div className="card">
                  <DragNDrop />
                  <div className="card-body">
                    This is some text within a card body.
                  </div>
                </div>
                <div className={'row'}>
                  <div className="col-sm-4">
                    <div className="card text-white bg-primary ">
                      <div className="card-header">Herbivora</div>
                      <div className="card-body"></div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="card text-white bg-warning ">
                      <div className="card-header">Karnivora</div>
                      <div className="card-body"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Table Drag n Drop</h5>
              </div>
              <div className="card-body">
                <div className={'row'}>
                  <div className={'col-sm-3'}>
                    <div
                      id="table1"
                      className="bitacoratable"
                    >
                      <h5>Pilih</h5>
                      <table
                        id="table1"
                        className="table childgrid"
                      >
                        <tr className="draggable_tr">
                          <td>1</td>
                          <td>Suka Berimajinasi</td>
                        </tr>
                        <tr className="draggable_tr">
                          <td>2</td>
                          <td>Menyukai Tantangan</td>
                        </tr>
                        <tr className="draggable_tr">
                          <td>3</td>
                          <td>Mudah Untuk Beradaptasi</td>
                        </tr>
                        <tr className="draggable_tr">
                          <td>4</td>
                          <td>Mudah Merasa Bosan</td>
                        </tr>
                        <tr className="draggable_tr">
                          <td>5</td>
                          <td>Kadang Menjadi Seseorang yang Misterius</td>
                        </tr>
                        <tr className="draggable_tr">
                          <td>6</td>
                          <td>Committed to learning</td>
                        </tr>
                        <tr className="draggable_tr">
                          <td>7</td>
                          <td>Balances intuition and analysis</td>
                        </tr>
                        <tr className="draggable_tr">
                          <td>8</td>
                          <td>Situationally collaborative</td>
                        </tr>
                        <tr className="draggable_tr">
                          <td>9</td>
                          <td>Formally articulate</td>
                        </tr>
                        <tr className="draggable_tr">
                          <td>10</td>
                          <td>Resilient</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className={'col-sm-3'}>
                    <div
                      id="table2"
                      className="bitacoratable"
                    >
                      <h5>Kreatif</h5>
                      <table
                        id="table2"
                        className="table childgrid"
                      >
                        <tr className="draggable_tr">
                          <td>6</td>
                          <td>Student 6</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className={'col-sm-3'}>
                    <div
                      id="table3"
                      className="bitacoratable"
                    >
                      <h5>Inovatif</h5>
                      <table
                        id="table2"
                        className="table childgrid"
                      ></table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

// fake data generator
const getItems = (count) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k}`,
    content: `item ${k}`,
  }))

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 10,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 250,
})
class DragNDrop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: getItems(10),
    }
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index,
    )
    this.setState({
      items,
    })
  }
  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.state.items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      {item.content}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
}

import moment from 'moment'
import { createContext, useContext, useState } from 'react'

import { useBaseContext } from 'context/base'
import useContentPlayerContext from 'context/content-player'

const ForumDetailContext = createContext()

export function ForumDetailWrapper({ children }) {
  /* ====================================== Consume Context ===================================== */
  const { postRequest, putRequest } = useBaseContext()
  const { content, activity, forum } = useContentPlayerContext()

  /* ========================================= Functions ======================================== */
  const generateDate = (updatedAt) => {
    let dd = moment(updatedAt).add(30, 'days').valueOf()
    return dd < moment().valueOf()
      ? moment(updatedAt).format('DD MMM YYYY')
      : moment(updatedAt).fromNow()
  }

  const shouldSendCCA = () => {
    const _isFeedbackPassed = !content.feedback || !!content.feed?.feed_temps.length // force boolean pake `!!`

    const _isTaskPassed = 
      activity.isAssignmentOrQuiz
        ? activity.isContentCorrected
        : activity.isContentCompleted
    
    return _isFeedbackPassed && _isTaskPassed
  }

  /* ============================================================================================ */
  /*                                          DISCUSSION                                          */
  /* ============================================================================================ */
  const [discussion, setDiscussion]         = useState('')
  const [discussLoading, setDiscussLoading] = useState('')

  async function sendDiscussion() {
    if (discussion !== '') {
      setDiscussLoading(true)
      let form = new FormData()
      form.append('description', discussion)
      form.append('parent_id', forum.data.id)
      const response = await postRequest('discuss', form)
      if (response) {
        if (shouldSendCCA()) {
          await activity.report(true)
          activity.setIdDone(content.id)
        }
        forum.fetch(content.id)
        setDiscussion('')
      } else {
        console.error('ERROR', response)
        window.notification(
          'Terjadi kesalahan',
          'Coba beberapa saat lagi',
          'error',
        )
      }
      setDiscussLoading(false)
    }
  }

  /* ============================================================================================ */
  /*                                             REPLY                                            */
  /* ============================================================================================ */
  const [reply, setReply]         = useState('')
  const [showReply, setShowReply] = useState('')

  async function sendReply(id) {
    if (reply !== '') {
      let form = new FormData()
      form.append('description', reply)
      form.append('parent_id', id)

      const response = await postRequest('discuss', form)
      if (response) {
        if (shouldSendCCA()) {
          await activity.report(true)
          activity.setIdDone(content.id)
        }
        forum.fetch(content.id)
        setReply('')
      } else {
        console.error('ERROR', response)
        window.notification(
          'Terjadi kesalahan',
          'Coba beberapa saat lagi',
          'error',
        )
      }
    }
  }

  /* ============================================================================================ */
  /*                                             LIKE                                             */
  /* ============================================================================================ */
  async function sendLike({ id, like }) {
    let form = new FormData()
    form.append('id', id)
    form.append('likes', like == 1 ? 0 : 1)

    const response = await putRequest('forum', form)
    if (response) {
      forum.fetch(content.id)
    } else {
      console.error('ERROR', response)
    }
  }

  /* ============================================================================================ */
  /*                                         SHARED STATES                                        */
  /* ============================================================================================ */
  const sharedStates = {
    discussion: {
      data    : discussion,
      loading : discussLoading,
      send    : sendDiscussion,
      set     : setDiscussion,
    },
    like: {
      send : sendLike,
    },
    reply: {
      data       : reply,
      send       : sendReply,
      set        : setReply,
      reset      : () => setReply(''),
      shownId    : showReply,
      setShownId : setShowReply,
    },
    tanggal: {
      generate : generateDate,
    },
  }

  return (
    <ForumDetailContext.Provider value={sharedStates}>
      {children}
    </ForumDetailContext.Provider>
  )
}

export default function useForumDetail() {
  return useContext(ForumDetailContext)
}

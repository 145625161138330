import CkeditorComponentV2 from 'component/ckeditor/indexV2'
import React from 'react'

export default function InputRaporBlockAllNote({ note, setNote }) {
  return (
    <div className="mx-5 mb-5">
      <h5>Catatan Wali Kelas</h5>
      {/* <textarea
        defaultValue={note}
        onChange={(e) => {
          setNote(e.target.value)
        }}
        className={'form-control'}
        rows={4}
        placeholder={'Catatan Wali Kelas'}
      ></textarea> */}
      <CkeditorComponentV2
        data={note}
        onChange={setNote}
        otherConfigs={{
          placeholder: 'Catatan Wali Kelas'
        }}
      />
    </div>
  )
}

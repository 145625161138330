import React from 'react'

export default function RaporSkeleton({
  tableConfig = { headers: [], totalColumns: 0 },
  bodyPlaceholder,
}) {
  return (
    <>
      <div className="row mt-3">
        <div className="col">
          <div className="table-responsive rounded mt-2">
            <table className="table nowrap">
              <thead>
                <tr>
                  {tableConfig.headers.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    colSpan={tableConfig.totalColumns}
                    className="text-center"
                  >
                    {bodyPlaceholder}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

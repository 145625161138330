import { useBaseContext } from "context/base"
import useQuery from "hooks/useQuery"
import { useGlobalGradeHandlerHook } from "pages/kelas/overview-grade/hooks/useGlobalGradeHandlerHook"
import { useEffect, useReducer, useState } from "react"
import { useHistory, useParams } from 'react-router-dom'
import Swal from "sweetalert2"
import { createQueryUrl } from "util/string"
import { useGradeListSelectedContentCreate } from "../content/create/hooks/useGradeListSelectedContentCreate"
import { CONTENT_TYPE } from "util/enum"

/**
 * @typedef {Object} ListContentsGradeState
 * @property {BaseSelectFormatData} selectedFilterGrade - The selected grade filter.
 * @property {Array<BaseSelectFormatData>} optionsFilterGrade - The options for the grade filter.
 * @property {Array} data - The list of contents grade data.
 * @property {Object} meta - Metadata related to pagination and filtering.
 * @property {boolean} loading - Indicates if the data is currently being loaded.
 */

/**
 * @typedef {Object} ModalRemoveContentGradeState
 * @property {string=} errorMessage - The selected grade filter for removal.
 * @property {BaseSelectFormatData} selectedFilterGrade - The selected grade filter for removal.
 * @property {Array<BaseSelectFormatData>} optionsFilterGrade - The options for the grade filter in the modal.
 * @property {boolean} showModalRemove - Indicates if the remove modal is visible.
 * @property {any} selectedContent - The content selected for removal.
 */

/**
 * @typedef {Object} Action
 * @property {'set_meta' | string} [type] - The type of action to perform.
 * @property {any} payload - The payload containing the data to update the state.
 */

/**
 * List Contents Grade Reducer
 * @param {ListContentsGradeState} state - The current state of the contents grade list.
 * @param {Action} action - The action to perform on the state.
 * @returns {ListContentsGradeState} The updated state.
 */
const listContentsGradeReducer = (state, action) => {
  switch (action.type) {
    case 'set_meta':
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload
        }
      }
    default:
      return {
        ...state,
        ...action.payload,
      }
  }
}

/**
 * Modal Remove Content Grade Reducer
 * @param {ModalRemoveContentGradeState} state - The current state of the modal for removing content grade.
 * @param {Action} action - The action to perform on the state.
 * @returns {ModalRemoveContentGradeState} The updated state.
 */
const modalRemoveContentGradeReducer = (state, action) => {
  return {
    ...state,
    ...action.payload,
  }
}

export function useGradeListContentHook() {
  const { getRequest, deleteRequest } = useBaseContext()
  const { handler } = useGlobalGradeHandlerHook()
  const params = useParams()
  const history = useHistory()
  const query = useQuery()
  const { handler: listSelectedContentCreateHandler } = useGradeListSelectedContentCreate({ isInitNecessaryFormInputByContentTypeId: false })

  const [profileId, setProfileId] = useState('')
  const profile = JSON.parse(localStorage.getItem('profile'))

  /**
   * @type {[ModalRemoveContentGradeState, (params: Action) => void]}
  */
  const [modalRemoveContentGrade, dispatchModalRemoveContentGrade] = useReducer(modalRemoveContentGradeReducer, {
    selectedFilterGrade: null,
    optionsFilterGrade: [],
    showModalRemove: false,
    selectedContent: null,
  })

  /**
   * @type {[ListContentsGradeState, (params: Action) => void]}
  */
  const [listContentsGrade, dispatchListContentsGrade] = useReducer(listContentsGradeReducer, {
    selectedFilterGrade: null,
    optionsFilterGrade: [],
    errorMessage: '',
    data: [],
    meta: {
      search: '',
      limit: 10,
      offset: 0,
      totalData: 0,
    },
    loading: false,
  })

  const getListContentsGradeHandler = async () => {
    dispatchListContentsGrade({ payload: { loading: true } })
    const querySearch = createQueryUrl({ queryName: 'search', value: listContentsGrade.meta.search })
    const response = await getRequest(`content-grade-contents?class_id=${params?.kelasId}&limit=${listContentsGrade.meta.limit}&offset=${listContentsGrade.meta.offset}${querySearch}`)

    if (response) {
      const responseData = {
        data: response?.data,
        meta: {
          ...listContentsGrade.meta,
          ...response.meta
        },
        loading: false
      }
      dispatchListContentsGrade({ payload: responseData })
    } else {
      dispatchListContentsGrade({ payload: { loading: false } })
    }
  }

  const getListGradesContentHandler = async () => {
    /**
     * @type {{
     *  data: Array<GradeItem>
     * }}
     */
    const response = await handler.getListGradesContentHandler({ kelasId: params?.kelasId, offset: 0, limit: 99999, search: null })

    if (response) {
      dispatchListContentsGrade({ payload: {
        optionsFilterGrade: response?.optionsFilterGradeFormatData
      }})
    }
  }

  async function getKelasDetail() {
    const response = await getRequest(`classes?id=${params?.kelasId}`)
    if (response) {
      if (
        response.data.class_profiles.length > 0 &&
        response.data.class_profiles[0].status == 'duplicate'
      ) {
        setProfileId(response.data.class_profiles[0].profile_id)
      } else {
        setProfileId(response.data?.profile_id)
      }
    } else {
      console.error('ERROR', response)
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }

  const onBackHandler = () => {
    history.replace(`/kelas/${params?.kelasId}#grade`)
  }

  /**
   * On Selected Filter Grade List Content Handler
   * 
   * @param {BaseSelectFormatData} gradeItemFormatSelect 
   */
  const onSelectedFilterGradeListContentHandler = gradeItemFormatSelect => {
    dispatchListContentsGrade({
      payload: {
        selectedFilterGrade: gradeItemFormatSelect
      }
    })
    history.replace(`/kelas/${params?.kelasId}/grade/${gradeItemFormatSelect?.value}/list-content?grade-name=${gradeItemFormatSelect?.label}`)
  }

  /**
   * Click Create Or Edit Child Content Handler
   * 
   * @param {Object} content 
   */
  const onClickCreateOrEditChildContentHandler = (content, isOnEdit) => {
    if (content?.content_type_id === CONTENT_TYPE.WHITEBOARD) {
      listSelectedContentCreateHandler.onSubmitHandler({
        ['whiteboard']: undefined
      }, {
        grade: {
          id: listContentsGrade.selectedFilterGrade.value,
          name: listContentsGrade.selectedFilterGrade.label,
        },
        contentId: content?.id,
        onSuccessHandler: getListContentsGradeHandler
      })
    } else {
      history.push(`/kelas/${params?.kelasId}/grade/${listContentsGrade.selectedFilterGrade?.value}/content/${content?.id}/${isOnEdit ? 'edit' : 'create'}?grade-name=${listContentsGrade.selectedFilterGrade?.label}`)
    }
  }

  const onClickRemoveChildContentHandler = content => {
    /**
     * @type {Array<BaseSelectFormatData>}
     */
    const optionsFilterGrade = content?.content_grade_contents?.map(c => ({
      label: c?.content_grade?.name,
      value: c?.content_grade?.id,
    }))
    dispatchModalRemoveContentGrade({
      payload: {
        optionsFilterGrade,
        selectedContent: content,
        showModalRemove: true
      }
    })
  }

  const onCloseRemoveChildContentHandler = content => {
    dispatchModalRemoveContentGrade({
      payload: {
        selectedFilterGrade: null,
        optionsFilterGrade: [],
        selectedContent: null,
        showModalRemove: false
      }
    })
  }

  const onRemoveGradeFromContentHandler = content => {
    Swal.fire({
      title: 'Apa anda yakin?',
      html: `Anda akan menghapus materi ${content?.title} pada grade ${listContentsGrade.selectedFilterGrade?.label}!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Loading',
          text: 'Sedang menghapus grade...',
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
            Swal.hideLoading()
          }
        })
        const response = await deleteRequest(`content-grade-contents?content_id=${content?.id}&content_grade_id=${listContentsGrade?.selectedFilterGrade?.value}`)

        if (response) {
          getListContentsGradeHandler()
          window.notification('Berhasil', `Menghapus Grade ${listContentsGrade.selectedFilterGrade?.label}!`, 'success')
          Swal.close()
        } else {
          Swal.close()
        }
      }
    })
  }

  useEffect(() => {
    const gradeName = query.get('grade-name')

    if (gradeName && params?.gradeId) {
      onSelectedFilterGradeListContentHandler({
        label: gradeName,
        value: params?.gradeId
      })
    }
  }, [])

  useEffect(() => {
    if (params?.kelasId) {
      getKelasDetail()
      getListGradesContentHandler()
    }
  }, [params?.kelasId])

  useEffect(() => {
    if (params?.kelasId) {
      getListContentsGradeHandler()
    }
  }, [params?.kelasId, listContentsGrade.meta.limit, listContentsGrade.meta.offset, listContentsGrade.meta.search])

  return {
    data: {
      profileId,
      access: profileId != profile?.id,
      listContentsGrade,
      modalRemoveContentGrade,
    },
    dispatch: {
      dispatchListContentsGrade,
      dispatchModalRemoveContentGrade,
    },
    set: {},
    handler: {
      onBackHandler,
      onClickCreateOrEditChildContentHandler,
      onClickRemoveChildContentHandler,
      onCloseRemoveChildContentHandler,
      onRemoveGradeFromContentHandler,
      onSelectedFilterGradeListContentHandler,
    }
  }
}
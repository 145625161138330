import React from 'react'
import { Link } from 'react-router-dom'

export default function BreadCrumb({
  pageName = '',
  links = [{ url: '/dashboard', name: '', hidden: true, state: null }],
}) {
  return (
    <ul className="breadcrumb">
      <li className="breadcrumb-item">
        <Link to="/dashboard">
          <i className="feather icon-home" />
        </Link>
      </li>
      {links.length !== 0
        ? links.map((link) => {
            if (!link.hidden) {
              return (
                <li
                  key={link.name}
                  className="breadcrumb-item"
                >
                  {link.url ? (
                    <Link
                      to={
                        link.state
                          ? { pathname: link.url, state: link.state }
                          : link.url
                      }
                    >
                      {link.name}
                    </Link>
                  ) : (
                    <p className="text-light">{link.name}</p>
                  )}
                </li>
              )
            } else {
              return null
            }
          })
        : null}
      <li className="breadcrumb-item">
        <p className="text-light f-w-700">{pageName}</p>
      </li>
    </ul>
  )
}

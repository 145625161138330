import React from 'react'
import Modal from 'component/modal'
import useMatchedAnswer from 'context/matched-answer'
import AnswerCardWrapper from './answer-card-wrapper'

export default function MatchedAnswersModal() {
  /* ====================================== Consume Context ===================================== */
  const { question_id } = useMatchedAnswer()

  /* ========================================= Variables ======================================== */
  const modalId = `modalMatchedAnswers_${question_id}`

  /* ========================================== Output ========================================== */
  return (
    <Modal
      id={modalId}
      title="Matched Answers"
    >
      <div
        className="hd-body pr-2"
        style={styles.modal}
      >
        <AnswerCardWrapper />
      </div>
    </Modal>
  )
}

/* =========================================== Styles =========================================== */
const styles = {
  modal: {
    maxHeight: '350px',
    overflowY: 'auto',
  }
}

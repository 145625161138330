import React, { useState } from 'react'
import moment from 'moment'
import { useBaseContext } from 'context/base'
import Modal from 'component/modal'
import { useHistory } from 'react-router-dom'

export default function SemesterItem({
  item,
  handleEdit,
  index,
  handleDelete,
}) {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ===================================== Destructure Props ==================================== */
  const { type, start_date, end_date, code, status, rapor_date_start } = item

  /* ====================================== Consume Context ===================================== */
  const { postRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [finalScore, setFinalScore] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [affectedFinalScores, setAffectedFinalScores] = useState(0)

  /* ========================================= Functions ======================================== */
  function handleChangeFinalScore(event) {
    setFinalScore(event.target.checked)
  }

  async function handleSubmit() {
    let form = new FormData()
    form.append('semester_code', item.code)
    if (finalScore) {
      form.append('final_score', 1)
    } else {
      form.append('final_score', 0)
    }
    setLoadingSubmit(true)
    const response = await postRequest('semester-data', form)
    setLoadingSubmit(false)
    if (response) {
      setShowResult(true)
      setAffectedFinalScores(response.data.scoresTotalAffetedRows)
    }
  }

  function closeModal() {
    setFinalScore(false)
    setLoadingSubmit(false)
    setShowResult(false)
    setAffectedFinalScores(0)
  }

  function handleDetailSemester(item) {
    history.push(
      `/report-period/${item.code}/${item.start_date}/${item.end_date}`,
    )
  }

  /* ========================================== Output ========================================== */
  return (
    <div
      className="col-12 p-3 border rounded d-flex justify-content-between"
      style={{
        backgroundColor: 'transparent',
        transition: '0.2s',
        marginTop: index > 0 ? '1rem' : '0',
      }}
      onMouseEnter={(e) =>
        (e.currentTarget.style.backgroundColor = 'lightyellow')
      }
      onMouseLeave={(e) =>
        (e.currentTarget.style.backgroundColor = 'transparent')
      }
    >
      <div>
        <h6
          style={{
            fontWeight: code.length === 5 ? '700' : '400',
            color: code.length === 5 ? '#345DAB' : '#373a3c',
          }}
        >
          {'Semester '}
          {code.length === 5 && (
            <>
              {type === 1 && 'Ganjil (utama)'}
              {type === 2 && 'Genap (utama)'}
            </>
          )}
          {code.length === 6 && (
            <>
              {type === 1 && `Ganjil #${code[code.length - 1]} (tambahan)`}
              {type === 2 && `Genap #${code[code.length - 1]} (tambahan)`}
            </>
          )}
          {status ? (
            <span className="badge badge-success ml-2">Aktif</span>
          ) : null}
        </h6>
        <p className="mb-0">
          <i className="fas fa-calendar-alt mr-2" />
          {'Mulai: '}
          {moment(start_date).locale('id').format('DD MMMM YYYY')}
        </p>
        <p className="mb-0">
          <i className="fas fa-calendar-alt mr-2" />
          {'Selesai: '}
          {moment(end_date).locale('id').format('DD MMMM YYYY')}
        </p>
        <p className="mb-0">
          <i className="fas fa-clipboard-list mr-2" />
          {'Tgl. Pengisian Rapor: '}
          {rapor_date_start ? (
            <>{moment(rapor_date_start).locale('id').format('DD MMMM YYYY')}</>
          ) : (
            '-'
          )}
        </p>
        {status ? (
          <button
            className="btn btn-sm btn-outline-dark mt-2"
            data-toggle="modal"
            data-target={`#modalProsesData${item.code}`}
          >
            <i className="fas fa-file-export mr-1" />
            Proses Data
          </button>
        ) : null}
        {status ? (
          <button
            className="btn btn-sm btn-outline-dark mt-2"
            onClick={() => handleDetailSemester(item)}
          >
            <i className="fas fa-file mr-1" />
            List Block
          </button>
        ) : null}
      </div>
      <div className="d-flex align-items-center">
        {status ? (
          <button
            title="Tambah Block"
            className="btn btn-icon btn-warning mr-2"
            data-toggle="modal"
            data-target="#modalTambahBlock"
            onClick={() => handleEdit(item)}
          >
            <i className="feather icon-plus" />
          </button>
        ) : (
          <button
            title="Tambah Block"
            disabled={true}
            className="btn btn-icon btn-warning mr-2"
            data-toggle="modal"
            data-target="#modalTambahBlock"
            onClick={() => handleEdit(item)}
          >
            <i className="feather icon-plus" />
          </button>
        )}

        <button
          title="Edit"
          className="btn btn-icon btn-primary mr-2"
          data-toggle="modal"
          data-target="#modalEditSemester"
          onClick={() => handleEdit(item)}
        >
          <i className="feather icon-edit" />
        </button>
        {status ? (
          <button
            title="Semester ini sedang aktif, tidak bisa menghapus semester"
            className="btn btn-icon btn-danger btn-forbidden"
            disabled={true}
          >
            <i className="feather icon-trash-2" />
          </button>
        ) : (
          <button
            title="Hapus"
            className="btn btn-icon btn-danger"
            onClick={() => handleDelete(item)}
          >
            <i className="feather icon-trash-2" />
          </button>
        )}
      </div>
      <Modal
        title={`Proses Data Semester ${type === 1 ? 'Ganjil' : ''}${
          type === 2 ? 'Genap' : ''
        }`}
        id={`modalProsesData${item.code}`}
        onClose={closeModal}
        footer={
          loadingSubmit ? (
            <button
              disabled
              type="button"
              className="btn btn-primary ml-2"
            >
              <span
                className="spinner-border spinner-border-sm mr-2"
                role="status"
              />
              Proses Data
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary ml-2"
            >
              Proses Data
            </button>
          )
        }
      >
        <form>
          <div className="form-group form-check">
            <input
              id={`formCheck${item.code}`}
              type="checkbox"
              className="form-check-input"
              onChange={handleChangeFinalScore}
              checked={finalScore}
            />
            <label
              htmlFor={`formCheck${item.code}`}
              className="form-check-label"
              style={{ marginTop: '1px' }}
            >
              Nilai Akhir
            </label>
          </div>
          {showResult ? (
            <span className="badge badge-info">
              <i className="fas fa-check-circle mr-1" />
              {affectedFinalScores} nilai berhasil diproses
            </span>
          ) : null}
        </form>
      </Modal>
    </div>
  )
}

import React from 'react'
import YouTube from 'react-youtube'

const ModalPreviewKelas = ({ classUrl, url, destroyPreview, uriInternal }) => {
  return (
    <div
      className="modal fade"
      id="preview-kelas-modal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myExtraLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          {!classUrl && (
            <div className="modal-header">
              <h5>
                <i className="fas fa-info-circle mr-1" /> INFO
              </h5>
            </div>
          )}
          <div className="modal-body">
            {classUrl?.source === 'internal' && (
              <>
                {classUrl ? (
                  <video
                    width="100%"
                    controls
                    disablePictureInPicture
                    controlsList="nodownload"
                    // onContextMenu={(event) => event.preventDefault()}
                  >
                    <source
                      src={`${uriInternal}`}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : null}
              </>
            )}
            {classUrl?.source === 'youtube' && (
              <>
                {url ? (
                  <YouTube
                    opts={{
                      width: '100%',
                      height: '450px',
                    }}
                    videoId={url}
                  />
                ) : null}
              </>
            )}
            {!classUrl && 'Tidak ada video'}
          </div>
          <div className="modal-footer p-2">
            <button
              type="button"
              className="btn btn-outline-danger"
              data-dismiss="modal"
              aria-label="Close"
              onClick={destroyPreview}
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalPreviewKelas

import React from 'react'

export default function HistoryButton({ item, setHistoryRapor }) {
  return (
    <button
      onClick={() => setHistoryRapor(item.report_transactions_period_temp)}
      data-toggle="modal"
      data-target="#modalHistoryRapor"
      className="btn btn-warning has-ripple btn-sm"
    >
      Lihat Catatan
    </button>
  )
}

import React from 'react'
import { avatarDefaultUser } from 'util/constant'

export default function Profile({ item }) {
  return (
    <div className="d-flex align-items-center justify-content-start">
      <div
        className="img-radius align-top m-r-15"
        style={{ width: '40px', height: '40px' }}
      >
        <div
          style={{
            background: item?.avatar
              ? `url(${item?.linkAvatar})`
              : `url(${avatarDefaultUser})`,
            ...styles.avatar,
          }}
        />
      </div>
      <div className="d-inline-block">
        <h6 className="mb-0 mt-2">{item?.name}</h6>
      </div>
    </div>
  )
}

const styles = {
  avatar: {
    position: 'relative',
    overflow: 'hidden',
    paddingBottom: '100%',
    border: '2px solid lightgray',
    borderRadius: '50%',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    width: '100%',
  },
}

import React from 'react'
import LoadingSpinner from 'component/loading'
import RatingAppreciation from './rating-appreciation'
import RatingContent, { FormRating } from './rating-content'
import useContentPlayerContext from 'context/content-player'

export default function TabRating() {
  /* ====================================== Consume Context ===================================== */
  const { tab, content, activity, rating } = useContentPlayerContext()

  /* ========================================== Output ========================================== */
  return tab.current === 1 && (
    <div
      className="tab-pane fade show active"
      id="pills-rating"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      {/* After Content Completed */}
      {activity.isContentCompleted && (
        <div>
          <RatingAppreciation />
          {content.rating && (
            <div className="my-3 p-3 border rounded d-flex flex-column">
              {rating.loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  {rating.data && (
                    <>
                      <h5>Rating materi:</h5>
                      {!rating.isRated && <FormRating />}
                      <RatingContent
                        rating={rating.data.ratingAvg}
                        rating_1={rating.data.rating_1}
                        rating_2={rating.data.rating_2}
                        rating_3={rating.data.rating_3}
                        rating_4={rating.data.rating_4}
                        rating_5={rating.data.rating_5}
                        totalRating={rating.data.totalRating}
                        contentId={content.id}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
